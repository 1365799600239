import _ from 'lodash';
import { actionTypes } from '~/app/modules/QuestionsManagement/components/MappedQuestion/actions';
import enums from "~/app/helpers/enums";

const initialState = {
  sortBy: '_id',
  sortDirection: enums.sortDirections.desc,
  filters: {},
  selectedMappingQuestions: [],
  mapping: [{}],
  selectedQuestion: {},
  report: [
    { _id: '43', questionId: '43', languageCode: 'ENG', countryCode: 'US', name: 'GENDER',
      text: 'What is your gender?', questionIsMapped: true, surveysCount: 37772,
      answers: [{ _id: '1', text: 'Male' }, { _id: '2', text: 'Female' }],
    },
    { _id: '1249', questionId: '1249', languageCode: 'ENG', countryCode: 'US', name: 'Age_and_Gender_of_Children',
      text: 'Please indicate the age and gender of your child or children:', questionIsMapped: true, surveysCount: 40150,
      answers: [
        { _id: '3', text: 'Boy 0-7 years old' },
        { _id: '4', text: 'Girl 0-7 years old' },
        { _id: '5', text: 'Boy 8+ years old' },
        { _id: '6', text: 'Girl 8+ years old' }
      ],
    },
    { _id: '99951368', questionId: '43', languageCode: 'ENG', countryCode: 'US', name: 'GENDER',
      text: 'What is your gender?', questionIsMapped: false, surveysCount: 25000,
      answers: [{ _id: '7', text: 'Male' }, { _id: '8', text: 'Female' }],
    },
    { _id: '39117', questionId: '1249', languageCode: 'ENG', countryCode: 'US', name: 'STANDARD_KID_AGE_GENDER',
      text: 'Please indicate the age and gender of your child or children:', questionIsMapped: true, surveysCount: 38000,
      answers: [
        { _id: '9', text: 'Boy 0-7 years old' },
        { _id: '10', text: 'Girl 0-7 years old' },
        { _id: '11', text: 'Boy 8-15 years old' },
        { _id: '12', text: 'Girl 8-15 years old' },
        { _id: '13', text: 'Boy 16+ years old' },
        { _id: '14', text: 'Girl 16+ years old' }
      ],
    },
    { _id: '33092', questionId: '43', languageCode: 'ENG', countryCode: 'US', name: 'GENDER 3',
      text: 'Please indicate your gender.', questionIsMapped: false, surveysCount: 300,
      answers: [{ _id: '15', text: 'Male' }, { _id: '16', text: 'Female' }],
    },
  ],

  isLoading: false,
  isLoadingCSV: false,
};

const resetPaginationState = {
  pageNumber: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetSorting:
      return {
        ...state,
        ...resetPaginationState,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };

    case actionTypes.SetFilter:
      const { field, value } = action.payload;
      const curFilters = state.filters;
      if (value) {
        return {
          ...state,
          ...resetPaginationState,
          filters: {
            ...curFilters,
            [field]: value,
          },
        };
      } else {
        return {
          ...state,
          ...resetPaginationState,
          filters: _.omit(curFilters, field),
        };
      }

    case actionTypes.SortReport:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FilterReport:
      return {
        ...state,
        isLoading: true,
      }

    case actionTypes.GenerateReport:
      return {
        ...state,
        pageNumber: 1,
        pageSize: 20,
        report: [],
        totalCount: 0,
        isLoading: true,
      };

    case actionTypes.FetchReportSuccess:
      return {
        ...state,
        report: action.payload.data,
        totalCount: action.payload.totalCount,
        stats: action.payload.stats,
        isLoading: false,
      };

    case actionTypes.FetchReportError:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.AddNewMapping:
      return {
        ...state,
        mapping: action.payload,
      };

    case actionTypes.SetSelectedQuestion:
      return {
        ...state,
        selectedQuestion: action.payload,
      };

    default:
      return state;
  }
};
