import { actionTypes } from '~/app/modules/Translations/components/Features/actions';

const initialState = {
  report: [],
  products: [],
  languages: [],
  filters: {},
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetFeaturesSuccess:
      return {
        ...state,
        report: action.payload,
        isLoading: false,
      };

    case actionTypes.GetFeatures:
      return {
        ...state,
        report: [],
        isLoading: true,
      };

    case actionTypes.GetFeaturesFailure:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.UpdateFeature:
    case actionTypes.CreateFeature:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.UpdateFeatureSuccess:
    case actionTypes.UpdateFeatureFailure:
    case actionTypes.CreateFeatureSuccess:
    case actionTypes.CreateFeatureFailure:
    case actionTypes.GetAvailableParamsFailure:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GetAvailableParams:
      return {
        ...state,
        isLoading: true,
        products: [],
        languages: [],
      };

    case actionTypes.GetAvailableParamsSuccess:
      return {
        ...state,
        isLoading: false,
        products: action.payload?.products,
        languages: action.payload?.languages,
      };

    case actionTypes.SetFiltersForTable:
      return {
        ...state,
        filters: action.payload,
      };

    default:
      return state;
  }
};
