import { useDispatch, useSelector } from "react-redux";
import { actions } from '~/app/modules/Translations/components/Products/actions';
import React, {useEffect} from "react";
import { Modal } from "react-bootstrap";
import { CloseOutlined } from "@material-ui/icons";
import { Button } from "@material-ui/core";

const ModalForPublishing = ({ product, setShown, shown, setSelectedRow }) => {
  const dispatch = useDispatch();
  const productTranslation = useSelector((state) => state.products.productTranslation);

  useEffect(() => {
    if (product?._id) {
      dispatch(actions.getProductTranslation(product._id));
    }
  }, [product, dispatch]);

  const handleCloseModal = () => {
    setShown(false);
    setSelectedRow({});
  }

  const handleConfirmPublishing = () => {
    handleCloseModal();
    setSelectedRow({});
    dispatch(actions.createProductTranslation(product._id));
  }

  const renderEmptyValue = (languageStats) => {
    if (!languageStats?.length || languageStats.every((languageStat) => !languageStat._id)) {
      return (
        <div className="d-flex justify-content-center">
          <div className="font-weight-boldest min-w-150px mr-3">
            Newly Verified Keys :
          </div>
          <div className="min-w-40px">0</div>
        </div>
      );
    }

    return <div />;
  }

  const renderLanguageAndPublishingKeyCount = (languageStat, index) => {
    if (!languageStat || !languageStat._id) {
      return <div key={index} />;
    }

    const newKeyTranslations = languageStat?.keyTranslations?.filter((keyTranslation) => !keyTranslation.publishedAt);

    const verifiedKeysCount = newKeyTranslations?.length + languageStat?.aliasKeysThatNeedPublishing?.length
    return (
      <div className="mt-5" key={languageStat._id}>
        <div className="d-flex">
          <div className="font-weight-boldest min-w-150px mr-3">Language:</div>
          <div>{languageStat.englishTitle}</div>
        </div>
        <div className="d-flex">
          <div className="font-weight-boldest min-w-150px mr-3">
           Newly Verified Keys :
          </div>
          <div className="min-w-40px">{verifiedKeysCount}</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Modal
        scrollable
        centered
        size="lg"
        show={shown}
        onHide={handleCloseModal}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            Publish Product
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={handleCloseModal}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body className="px-0">
          <div className="d-flex flex-column align-items-center justify-content-center py-5">
            <div>Please confirm you want to publish the following product</div>
              <div className="d-flex">
                <div className="min-w-150px ml-6 mr-3 font-weight-boldest">Product:</div>
                <div>{product?.name}</div>
              </div>
              <div>
                {renderEmptyValue(productTranslation)}
                {productTranslation?.length && productTranslation.map(renderLanguageAndPublishingKeyCount)}
              </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button
              color="default"
              variant="contained"
              className="mr-3 bg-light-primary text-primary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleConfirmPublishing}
              className="bg-primary text-white mr-5"
            >
              Confirm
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalForPublishing;
