import _ from 'lodash';
import { actionTypes } from '~/app/modules/QuestionsManagement/components/ProfilerOrder/actions';
import enums from "~/app/helpers/enums";

const initialState = {
  sortBy: 'clickDate',
  sortDirection: enums.sortDirections.asc,
  filters: {},
  languageCode: '',
  countryCode: '',
  languageCodes: [{ name: 'English', code: 'ENG' }, { name: 'Portuguese', code: 'POR' }],
  countryCodes: [{ name: 'United States', code: 'US' }, { name: 'Brazil', code: 'BR' }],

  report: [],
  defaultReport: [],
  totalCount: 0,
  stats: null,

  isLoading: false,
  isLoadingCSV: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetSorting:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };

    case actionTypes.SetFilter:
      const { field, value } = action.payload;
      const curFilters = state.filters;
      if (value) {
        return {
          ...state,
          filters: {
            ...curFilters,
            [field]: value,
          },
        };
      } else {
        return {
          ...state,
          filters: _.omit(curFilters, field),
        };
      }

    case actionTypes.SortReport:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FilterReport:
      return {
        ...state,
        isLoading: true,
      }

    case actionTypes.GenerateReport:
      return {
        ...state,
        pageNumber: 1,
        pageSize: 20,
        report: [],
        defaultReport: [],
        totalCount: 0,
        isLoading: true,
      };

    case actionTypes.FetchReportSuccess:
      return {
        ...state,
        defaultReport: action.payload.data,
        report: action.payload.data,
        totalCount: action.payload.totalCount,
        stats: action.payload.stats,
        isLoading: false,
      };

    case actionTypes.FetchReportError:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GenerateReportAsCsv:
      return {
        ...state,
        isLoadingCSV: true,
      };

    case actionTypes.GenerateReportAsCsvSuccess:
      return {
        ...state,
        isLoadingCSV: false,
      };

    case actionTypes.SetLanguageCode:
      return {
        ...state,
        languageCode: action.payload,
      };

    case actionTypes.SetCountryCode:
      return {
        ...state,
        countryCode: action.payload,
      };

    case actionTypes.SetReportValue:
      return {
        ...state,
        report: action.payload,
      };

    case actionTypes.SetDefaultReportValue:
      return {
        ...state,
        defaultReport: action.payload,
      };

    case actionTypes.SetReportData:
      return {
        ...state,
        report: action.payload,
        defaultReport: action.payload,
      };

    default:
      return state;
  }
};
