import config from '~/config';
import fetch from '~/redux/fetch';
import flatten from 'flat';
import enums from '~/app/helpers/enums';

const FETCH_REPORT_URL = '/report';
const FETCH_COHORT_REPORT_URL = '/cohort-report';
const FETCH_MEDIA_SPEND_REPORT_URL = '/media-spend-report';

const reportEndpointMappings = {
  [enums.reportBuilderType.ACTIVITY]: FETCH_REPORT_URL,
  [enums.reportBuilderType.COHORT]: FETCH_COHORT_REPORT_URL,
  [enums.reportBuilderType.MEDIA_SPEND]: FETCH_MEDIA_SPEND_REPORT_URL,
};

const FETCH_REPORT_CSV_URL = '/report-csv';
const FETCH_COHORT_REPORT_CSV_URL = '/cohort-report-csv';
const FETCH_MEDIA_SPEND_REPORT_CSV_URL = '/media-spend-report-csv';

const reportCsvEndpointMappings = {
  [enums.reportBuilderType.ACTIVITY]: FETCH_REPORT_CSV_URL,
  [enums.reportBuilderType.COHORT]: FETCH_COHORT_REPORT_CSV_URL,
  [enums.reportBuilderType.MEDIA_SPEND]: FETCH_MEDIA_SPEND_REPORT_CSV_URL,
};

const fetchReport = async (params) => {
  const response = await fetch(`${config.apiBaseUrl}${reportEndpointMappings[params.reportType]}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  const { report, sortBy, sortDirection } = await response.json();
  return { report, sortBy, sortDirection };
}

const fetchReportAsCsvURL = (params) => {
  const storage = JSON.parse(window.localStorage?.getItem('persist:st-admin-auth'));

  let url = `${config.apiBaseUrl}${reportCsvEndpointMappings[params.reportType]}/${storage.authToken?.substring(1, storage.authToken?.length - 1)}`;
  const newParams = {...params};

  if (params?.reportType) {
    delete newParams.reportType;
  }

  const flattenParams = flatten(newParams);
  if (Object.keys(flattenParams).length) {
    Object.keys(flattenParams).forEach((key, index) => {
      if (index === 0) {
        url = `${url}?${key}=${flattenParams[key]}`;
      } else {
        url = `${url}&${key}=${flattenParams[key]}`;
      }
    });
  }

  return url;
};

const fetchReportAsCsv = async (params) => {
  var a = document.createElement('a');
  a.href = `${fetchReportAsCsvURL(params)}`;
  a.download = 'surveys.csv';
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export default {
  fetchReport,
  fetchReportAsCsv,
};
