import React from 'react';
import { useSelector } from 'react-redux';
import {
  Card, CardContent, Grid
} from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";

function IdentityInformationTable({ entities }) {
  const columns = [
    { dataField: "attribute", text: "Attribute" },
    { dataField: "information", text: "Information" },
  ];

  return (
    <>
      <div style={{ width: '400px' }}>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          borderless={true}
          classes="table table-vertical-top overflow-hidden"
          bootstrap4
          size="sm"
          responsive="lg"
          headerClasses="bg-light rounded"
          remote
          data={entities}
          columns={columns}
          keyField="attribute"
        >
        </BootstrapTable>
      </div>
    </>
  );
};

const VeriffCard = () => {
  const { veriffDecision } = useSelector(state => state.user.userInformation.user);
  const verifffInformation = [
    { attribute :"Date of Birth", information: veriffDecision.person.dateOfBirth },
    { attribute :"Document Type", information: veriffDecision.document.type },
    { attribute :"Document Number", information: veriffDecision.document.number },
  ];

  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded pb-20">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          Identity Information
        </h3>
        <IdentityInformationTable entities={verifffInformation} />
      </CardContent>
    </Card>
  );
};

const FaceTecCard = () => {
  const { facetecIdInfo } = useSelector(state => state.user.userInformation.user);

  const documentMeta = Object.entries(facetecIdInfo.templateInfo).map((entry) => ({
    attribute: entry[0],
    information: entry[1],
  }));

  const scannedInfo = [];
  (facetecIdInfo.scannedValues?.groups || []).forEach((group) => {
    group.fields.forEach((field) => {
      scannedInfo.push({
        attribute: `${group.groupKey}/${field.fieldKey}`,
        information: field.value,
      });
    });
  });

  const userConfirmedInfo = [];
  (facetecIdInfo.userConfirmedValues?.groups || []).forEach((group) => {
    group.fields.forEach((field) => {
      userConfirmedInfo.push({
        attribute: `${group.groupKey}/${field.fieldKey}`,
        information: field.value,
      });
    });
  });

  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded pb-20">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          Identity Information
        </h3>
        <h4>Document meta</h4>
        <IdentityInformationTable entities={documentMeta} />
        <h4>Scanned</h4>
        <IdentityInformationTable entities={scannedInfo} />
        <h4>User Confirmed</h4>
        <IdentityInformationTable entities={userConfirmedInfo} />
      </CardContent>
    </Card>
  );
};

const Identity = () => {
  const user = useSelector(state => state.user?.userInformation?.user);
  if (!user) {
    return null;
  }

  const { veriffDecision, facetecIdInfo } = user;

  if (!veriffDecision && !facetecIdInfo) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        { facetecIdInfo ? (
          <FaceTecCard />
        ) : (
          <VeriffCard />
        )}
      </Grid>
    </Grid>
  );
};

export default Identity;
