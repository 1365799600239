import config from '~/config';
import fetch from '~/redux/fetch';
import { dataURLtoFile } from "~/app/helpers";
const SAVE_SCREENSHOT_URL = (keyId) => `/translations/keys/${keyId}/attachment`;

const FETCH_KEYS_URL = '/translations/keys';
const FETCH_AVAILABLE_PARAMS_URL = '/translations/features/new';
const CREATE_FEATURE_URL = '/translations/features';
const FIND_KEYS_URL = '/translations/keys';
const UPDATE_KEY_TRANSLATION = (keyTranslationId) => `/translations/key-translations/${keyTranslationId}`;
const UPDATE_LINK_KEY = (keyId) => `/translations/keys/${keyId}`;

const getKeys = async () => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_KEYS_URL}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
};

const getAvailableParams = async () => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_AVAILABLE_PARAMS_URL}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
};

const createFeature = async ({ productId, name, targetedLanguages, keyIds }) => {
  const response = await fetch(`${config.apiBaseUrl}${CREATE_FEATURE_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ productId, name, targetedLanguages, keyIds }),
  });
  return response.json();
};

const saveKeyScreenshot = async({ keyId, attachment, fileName }) => {
  const data = new FormData();
  const file = dataURLtoFile(attachment, fileName);
  data.append("file", file);

  const response = await fetch(`${config.apiBaseUrl}${SAVE_SCREENSHOT_URL(keyId)}`, {
    method: 'POST',
    body: data,
  });
  return response.json();
}

const updateKeyTranslation = async({ keyTranslationId, text, status }) => {
  const response = await fetch(`${config.apiBaseUrl}${UPDATE_KEY_TRANSLATION(keyTranslationId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text, status }),
  });
  return response.json();
}

const findKeys = async ({ key, text, keysForLinkKey, keyId }) => {
  const response = await fetch(`${config.apiBaseUrl}${FIND_KEYS_URL}?key=${key}&text=${text}&keysForLinkKey=${keysForLinkKey}&keyId=${keyId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const updateLinkKey = async({ keyId, aliasOfKeyId }) => {
  const response = await fetch(`${config.apiBaseUrl}${UPDATE_LINK_KEY(keyId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ aliasOfKeyId }),
  });
  return response.json();
}

export default {
  getKeys,
  getAvailableParams,
  createFeature,
  saveKeyScreenshot,
  updateKeyTranslation,
  findKeys,
  updateLinkKey,
};
