import { actionTypes } from '~/app/modules/Rewards/components/RewardsManagement/actions';
import _ from "lodash";
import enums from "~/app/helpers/enums";

const initialState = {
  data: [],
  filterAndSortedData: [],
  currentReward: {},
  fundsHistory: [],
  needToLoadFundsHistory: false,

  isLoading: false,
  currentIsLoading: false,
  rewardsTexts: [],
  updatingIsLoading: false,
  fundsHistoryIsLoading: false,
  rewardProviders: undefined,
  rewardProvidersIsLoading: false,
  useCountryFieldSort: true,

  pageNumber: 1,
  pageSize: 20,
  sortBy: 'name',
  totalCount: 0,
  sortDirection: enums.sortDirections.asc,
  filters: {},
  foundKeys: [],
};

const handleSortAndFilterForData = (state, data) => {
  const newData = data.filter((item) => {
    if (Object.keys(state.filters)?.length) {
      return Object.keys(state.filters).every((filterKey) => {
        if (filterKey === 'countryList') {
          return _.difference(state.filters[filterKey],item[filterKey]).length === 0
        }
        return item[filterKey]?.toLowerCase()?.includes(state.filters[filterKey]?.toLowerCase());
      })
    }
    return true;
  }).sort((a,b) => {
    if (state.sortBy === 'checkedAt') {
      if (state.sortDirection === enums.sortDirections.asc) {
        return (new Date(a[state.sortBy]).getTime() - new Date(b[state.sortBy]).getTime());
      }
      return (new Date(b[state.sortBy]).getTime() - new Date(a[state.sortBy]).getTime());
    }
    if (state.sortBy === 'countryList') {
      if (state.sortDirection === enums.sortDirections.asc) {
        return (a[state.sortBy].sort()?.[0] || '').localeCompare((b[state.sortBy].sort()?.[0] || ''));
      }
      return (b[state.sortBy].sort()?.[0] || '').localeCompare((a[state.sortBy].sort()?.[0] || ''));
    }
    if (typeof a[state.sortBy] === 'number') {
      if (state.sortDirection === enums.sortDirections.asc) {
        return (a[state.sortBy] || 0) - (b[state.sortBy] || 0);
      }
      return (b[state.sortBy] || 0) - (a[state.sortBy] || 0);
    }
    if (state.sortDirection === enums.sortDirections.asc) {
      return (a[state.sortBy] || '')?.toLowerCase()?.localeCompare((b[state.sortBy]?.toLowerCase() || ''));
    }
    return (b[state.sortBy] || '')?.toLowerCase()?.localeCompare((a[state.sortBy]?.toLowerCase()  || ''));
  });

  return {
    filterAndSortedData: newData.slice((state.pageNumber - 1) * state.pageSize, state.pageNumber * state.pageSize),
    totalCount: newData.length,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetRewards:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GetRewardsFailure:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GetRewardsSuccess:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        ...handleSortAndFilterForData(state, action.payload),
      };

    case actionTypes.GetCurrentReward:
      return {
        ...state,
        currentIsLoading: true,
      };

    case actionTypes.GetCurrentRewardFailure:
      return {
        ...state,
        currentIsLoading: false,
      };

    case actionTypes.GetCurrentRewardSuccess:
      return {
        ...state,
        currentIsLoading: false,
        currentReward: action.payload,
        needToLoadFundsHistory: true,
      };

    case actionTypes.ChangeCurrentRewardFields:
      return {
        ...state,
        currentReward: {
          ...state.currentReward,
          ...action.payload,
        }
      };

    case actionTypes.GetRewardsTranslationsSuccess:
      return {
        ...state,
        rewardsTexts: action.payload,
      };

    case actionTypes.UpdateCurrentReward:
      return {
        ...state,
        updatingIsLoading: true,
      };

    case actionTypes.UpdateCurrentRewardSuccess:
    case actionTypes.UpdateCurrentRewardFailure:
      return {
        ...state,
        updatingIsLoading: false,
      };

    case actionTypes.GetFundsHistory:
      return {
        ...state,
        fundsHistoryIsLoading: true,
        needToLoadFundsHistory: false,
      };

    case actionTypes.GetFundsHistorySuccess:
      return {
        ...state,
        fundsHistoryIsLoading: false,
        fundsHistory: action.payload,
      };

    case actionTypes.GetFundsHistoryFailure:
      return {
        ...state,
        fundsHistoryIsLoading: false,
      };

    case actionTypes.GetRewardProviders:
      return {
        ...state,
        rewardProvidersIsLoading: true,
        rewardProviders: undefined,
      };

    case actionTypes.GetRewardProvidersSuccess:
      return {
        ...state,
        rewardProvidersIsLoading: false,
        rewardProviders: action.payload,
      };

    case actionTypes.GetRewardProvidersFailure:
      return {
        ...state,
        rewardProvidersIsLoading: false,
      };

    case actionTypes.SetUseCountryFieldSort:
      return {
        ...state,
        useCountryFieldSort: action.payload,
      };

    case actionTypes.SetSorting:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };

    case actionTypes.SetFilter:
      const { field, value } = action.payload;
      const curFilters = state.filters;
      if (value) {
        return {
          ...state,
          filters: {
            ...curFilters,
            [field]: value,
          },
        };
      } else {
        return {
          ...state,
          filters: _.omit(curFilters, field),
        };
      }

    case actionTypes.UpdateReport:
      return {
        ...state,
        ...handleSortAndFilterForData(state, state.data),
      }

    case actionTypes.FindKeysSuccess:
      return {
        ...state,
        foundKeys: action.payload,
      };

    case actionTypes.CleanFoundKeys:
      return {
        ...state,
        foundKeys: [],
      };

    case actionTypes.SetPagination:
      return {
        ...state,
        pageSize: action.payload.pageSize,
        pageNumber: action.payload.pageNumber,
      };

    default:
      return state;
  }
};
