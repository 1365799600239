const actionTypes = {
  SetFormKey: 'atomsManagement/set-form-key',
  FetchAtomSettings: 'atomsManagement/fetch-atom-settings',
  FetchAtomSettingsSuccess: 'atomsManagement/fetch-atom-settings-success',
  FetchAtomSettingsFailure: 'atomsManagement/fetch-atom-settings-failure',
  ChangeFormField: 'atomsManagement/change-form-field',
  SetCurrentAsDefault: 'atomsManagement/set-current-as-default',
  SaveCurrentAtomSettings: 'atomsManagement/save-current-atom-settings',
  SaveAtomSettingsSuccess: 'atomsManagement/save-atom-settings-success',
  SaveAtomSettingsFailure: 'atomsManagement/save-atom-settings-failure',

  CloseErrorAlert: 'atomsManagement/close-error-alert',
  CloseSuccessAlert: 'atomsManagement/close-success-alert',
};

const actions = {
  setFormKey: (key) => ({ type: actionTypes.SetFormKey, payload: key }),
  fetchAtomSettings: () => ({ type: actionTypes.FetchAtomSettings }),
  fetchAtomSettingsSuccess: (data) => ({ type: actionTypes.FetchAtomSettingsSuccess, payload: data }),
  fetchAtomSettingsFailure: (error) => ({ type: actionTypes.FetchAtomSettingsFailure, payload: error }),
  changeFormField: (data) => ({ type: actionTypes.ChangeFormField, payload: data }),
  setCurrentAsDefault: () => ({ type: actionTypes.SetCurrentAsDefault }),
  saveCurrentAtomSettings: () => ({ type: actionTypes.SaveCurrentAtomSettings }),
  saveAtomSettingsSuccess: (data) => ({ type: actionTypes.SaveAtomSettingsSuccess, payload: data }),
  saveAtomSettingsFailure: (error) => ({ type: actionTypes.SaveAtomSettingsFailure, payload: error }),

  closeErrorAlert: () => ({ type: actionTypes.CloseErrorAlert }),
  closeSuccessAlert: () => ({ type: actionTypes.CloseSuccessAlert }),
};

export {
  actionTypes,
  actions,
};
