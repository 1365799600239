import React, { useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import { sortCaret } from '~/_metronic/_helpers';
import { actions } from '~/app/modules/QuestionsManagement/components/MappedQuestion/actions';
import {
  onTableChangeHelper,
  usePaginationFilteringAndSorting,
  textFilterOnBlurHelper,
  useDidMount,
} from '~/app/helpers';
import { Star } from "@material-ui/icons";
import {Modal} from "react-bootstrap";
import {Button} from "@material-ui/core";
import _ from 'lodash';
import enums from "~/app/helpers/enums";

const sortableColumn = {
  sort: true, sortCaret,
};

const renderIdField = (row) => {
  return (
    <div className="d-flex" style={{ marginLeft: row?.questionIsMapped ? '-22px' : '0' }}>
      {row?.questionIsMapped && (
        <span style={{ marginRight: '3px' }} className="svg-icon svg-icon-warning h-20">
          <Star style={{ color: '#ffa701' }}/>
        </span>
      )}
      <div>{row._id}</div>
    </div>
  );
};

const columns = () => [{
  ...sortableColumn,
  dataField: '_id', text: 'Internal Question ID',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "100px" } }),
  formatter: (_id, row) => renderIdField(row),
  style: { minWidth: '150px', width: '150px', paddingLeft: '20px', textAlign: 'left'},
  headerStyle: { paddingLeft: '20px' },
}, {
  ...sortableColumn,
  dataField: 'name', text: 'Question Name',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "150px" } }),
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'text', text: 'Question Text',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "150px" } }),
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'languageCode', text: 'Language',
  style: { width: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'countryCode', text: 'Country',
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'answers', text: 'Number Of Answers',
  formatter: (answers) => (answers?.length || 0),
  style: { width: '120px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'surveysCount', text: 'Number Of Surveys',
  style: { width: '120px', textAlign: 'left' },
}];

const MappingQuestionsModal = ({ index, shown, setShown }) => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'mappedQuestion', actions);
  const report = useSelector(state => state.mappedQuestion.report, shallowEqual);
  const mapping = useSelector(state => state.mappedQuestion.mapping);
  const [selectedRow, setSelectedRow] = useState(undefined);

  const didMount = useDidMount();

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: ['_id', 'questionId', 'name', 'text', 'type', 'inProfiler', 'mapped'],
    didMount,
  });

  const reportColumns = columns();

  const selectRow = {
    mode: 'radio',
    clickToExpand: true,
    onSelect: (row) => {
      setSelectedRow(row);
    },
  };

  const handleCloseModal = () => {
    setShown(false);
  }

  const handleSelectQuestion = () => {
    const newMapping = _.cloneDeep(mapping);
    newMapping[index - 1] = selectedRow;
    dispatch(actions.addNewMapping(newMapping));
    handleCloseModal();
  }

  return (
    <Modal
      scrollable
      centered
      size="xl"
      show={shown}
      onHide={handleCloseModal}
    >
      <Modal.Header className="font-weight-boldest">Mapping #{index}</Modal.Header>
      <Modal.Body>
        <BootstrapTable
          classes="table table-head-custom table-vertical-center overflow-hidden"
          headerClasses="table table-vertical-top"
          bordered={false}
          bootstrap4
          responsive
          keyField="_id"
          columns={reportColumns}
          data={report}
          filter={ filterFactory() }
          selectRow={selectRow}
          defaultSorted={[{
            dataField: '_id',
            order: sortDirection || enums.sortDirections.desc,
          }]}
          onTableChange={onTableChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            color="secondary"
            variant="contained"
            className="max-h-30px bg-light-secondary text-secondary"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="ml-3 max-h-30px bg-light-primary text-primary"
            onClick={handleSelectQuestion}
            disabled={!selectedRow}
          >
            Select Question
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default MappingQuestionsModal;
