import React, { useEffect, useState } from 'react';
import { Card, CardContent, FormControl, MenuItem, Select } from "@material-ui/core";
import cs from 'classnames';
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { actions } from "~/app/modules/Survey/actions";
import TopInformationSection from "./components/TopInformationSection";
import { format, addDays } from 'date-fns';
import { Grid, Tooltip } from "@material-ui/core";
import {DoneAll, Tune, Group, Info, OpenInNew, CloseOutlined} from '@material-ui/icons';
import { Modal } from "react-bootstrap";
import Chart from './components/Chart';
import { KeyboardDatePicker } from "@material-ui/pickers";
import PieChart from "./components/PieChart";
import ColumnChart from "./components/ColumnChart";
import AreaChart from "./components/AreaChart";
import enums from "~/app/helpers/enums";

const dateFormat = (date) => {
  if (!date) {
    return '-';
  }

  const newDate = new Date(date).toLocaleString("en-US", {timeZone: 'America/Los_Angeles'});
  const currentDate = new Date(newDate);
  const dateInFormat = format(currentDate, 'dd/MM/yyyy  HH:mm:ss a').split('  ');
  return (
    <>
      <div>{dateInFormat[0]}</div>
      <div>{dateInFormat[1]} (PST)</div>
    </>
  );
}

const DateField = ({ date, title }) => (
  <div className="d-flex justify-content-center align-items-center flex-column ml-3">
    <div>
      {title}
    </div>
    <div
      className={"rounded w-135px min-h-45px bg-light-info text-info d-flex align-items-center justify-content-center flex-column py-1 px-3"}
    >
      {dateFormat(date)}
    </div>
  </div>
);

const InformationRow = ({ title, value }) => (
  <div className="w-250px d-flex justify-content-between align-items-center mb-2">
    <div>{title}</div>
    <div className="bg-light-secondary w-100px text-center rounded py-2">{value}</div>
  </div>
)

const InformationColumn = ({ title, value, infoColor, withTooltip, tooltipTitle, withClick }) => (
  <div className={cs('d-flex flex-column justify-content-center align-items-center', {
    'min-w-100px': !infoColor,
    'mr-3': !infoColor,
    'mr-1': infoColor,
    'min-w-70px': infoColor,
    'cursor-pointer': withClick,
  })}>
    {withTooltip ? (
      <Tooltip arrow placement="top" title={tooltipTitle || ''}>
        <div>
          <div className={cs('text-center', { 'text-info': infoColor })}>{title}</div>
          <div className={cs('text-center font-weight-bolder', { 'text-info': infoColor })}>{value}</div>
        </div>
      </Tooltip>
    ) : (
      <>
        <div className={cs('text-center', { 'text-info': infoColor })}>{title}</div>
        <div className={cs('text-center font-weight-bolder', { 'text-info': infoColor })}>{value}</div>
      </>
    )}
  </div>
)

const URLModal = ({ url, shown, setShown }) => {
  return (
    <>
      <Modal
        scrollable
        centered
        size="md"
        show={shown}
        onHide={() => setShown(false)}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            Live Link
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={() => setShown(false)}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex my-10 justify-content-center mx-5">{url}</div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
}

const TopCard = () => {
  const [shown, setShown] = useState(false);
  const surveyInformation = useSelector((state) => state.survey.surveyInformation);
  const clicksSinceLastLead = useSelector((state) => state.survey.clicksSinceLastLead);

  const handleClickOnLiveLink = () => {
    setShown(true);
  }

  const calculateQualifiedClicks = (survey) => {
    const qualifiedClicks = survey?.clicksStats?.clicksCount - survey?.clicksStats?.stoppedClicksCount
      - survey?.clicksStats?.preliminaryFailedClicksCount;
    if (isNaN(qualifiedClicks)) {
      return '-';
    }

    return qualifiedClicks;
  }

  const providerCR = surveyInformation?.statistics?.cr ?
    `${((surveyInformation?.statistics?.cr) * 100).toFixed(2)}%` : '-';

  const validClicksCount = surveyInformation?.clicksStats?.clicksCount
    - surveyInformation?.clicksStats?.stoppedClicksCount - surveyInformation?.clicksStats?.preliminaryFailedClicksCount
    || 0;

  const calculateCR = () => {
    if (!surveyInformation?.clicksStats || !Object.keys(surveyInformation?.clicksStats).length) {
      return '-';
    }
    const conversionRate = validClicksCount + surveyInformation?.clicksStats?.stoppedClicksCount > 0
      ? +(surveyInformation?.clicksStats?.leadsCount
        / validClicksCount).toFixed(2) : 0;

    return `${(conversionRate * 100).toFixed(0)}%`;
  };

  const calculateCRTooltip = () => {
    if (!validClicksCount || !surveyInformation?.clicksStats) {
      return;
    }

    const { desktopClick, mobileClick } = surveyInformation.clicksStats;

    const desktopClicks = (desktopClick || 0) / validClicksCount;
    const mobileClicks = (mobileClick || 0) / validClicksCount;

    return (
      <div className="p-2">
        <div className="d-flex justify-content-between font-size-lg mb-2">
          Desktop
          <div className="ml-2">{desktopClicks ? `${(desktopClicks * 100).toFixed(0)}%` : '-'}</div>
        </div>
        <div className="d-flex justify-content-between font-size-lg">
          Mobile
          <div className="ml-2">{mobileClicks ? `${(mobileClicks * 100).toFixed(0)}%` : '-'}</div>
        </div>
      </div>
    )
  }

  const handleClickOnSurveyClicks = (surveyId) => () => {
    window.localStorage.setItem('clicksReportFilters', JSON.stringify({
      useClickDate: false,
      useLeadDate: false,
      filters: { surveyId }
    }));
  };

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <TopInformationSection surveyInformation={surveyInformation} alignChildren='start'>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row mb-2">
              <DateField date={surveyInformation.createDate} title="Created Date" />
              <DateField date={surveyInformation.endDate} title="End Date" />
            </div>
            <div className="d-flex flex-row">
              <DateField date={surveyInformation.activatedAt} title="Activation Date" />
              <DateField date={surveyInformation.inactivatedAt} title="Inactivation Date" />
            </div>
          </div>
        </TopInformationSection>
        <Grid container spacing={1} className="ml-5 mt-3">
          <Grid item xs={12} md={6} lg={3}>
            <InformationRow title="CPI" value={surveyInformation?.cpi?.toFixed(2) || '-'} />
            <InformationRow
              title="Mobile Responsive"
              value={surveyInformation.mobileResponsive ? 'True' : 'False'}
            />
            <InformationRow
              title="Mobile Only"
              value={surveyInformation.mobileOnly ? 'True' : 'False'}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <InformationRow
              title="Provider LOI"
              value={surveyInformation?.statistics?.lengthOfInterview?.toFixed(2) || '-'}
            />
            <InformationRow title="Provider CR" value={providerCR}/>
            <InformationRow
              title="Provider EPC"
              value={surveyInformation?.statistics?.epc?.toFixed(2) || '-'}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <div
                  className="d-flex align-items-center mb-2"
                >
                  <div className="w-100px mr-4">Live link</div>
                  <div
                    onClick={handleClickOnLiveLink}
                    className="cursor-pointer bg-light-primary text-primary px-2 py-2 rounded"
                  >
                    Show live link
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <div className="w-100px mr-4">Groups</div>
                  <div className="d-flex max-w-600px flex-wrap">
                    {surveyInformation.surveyGroupsIds && surveyInformation.surveyGroupsIds.map((group) => (
                      <Link key={group} to={`/surveys/surveyGroupsIds/${group}`} target="_blank">
                        <div className="bg-light-primary rounded text-primary px-2 py-2 mr-2 mb-1">
                          {group}
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              {(surveyInformation?.forceInactive || !surveyInformation.active) && (
                <div className="d-flex flex-column">
                <div>NOT ACTIVE Reason:</div>
                <div className="p-3 bg-light-secondary rounded">
                  {surveyInformation?.forceInactive ? surveyInformation?.forceInactiveReason : 'Provider Closed the Survey'}
                </div>
              </div>)}
            </div>
          </Grid>
        </Grid>
        <div className="border mt-10 mb-5 border-bottom-light-secondary" />
        <div className="d-flex">
          <InformationColumn title="Targeted Users" value={surveyInformation.targetedUsers || 0} />
          <Link
            target="_blank"
            to="/clicks"
            className="my-auto"
            onClick={handleClickOnSurveyClicks(surveyInformation._id)}
          >
            <InformationColumn
              withClick
              title="Survey Clicks"
              value={surveyInformation?.clicksStats?.clicksCount || 0}
            />
          </Link>
          <InformationColumn
            title="Clicks Since Last Lead"
            value={clicksSinceLastLead || 0}
          />
          <InformationColumn title="Stopped Clicks" value={surveyInformation?.clicksStats?.stoppedClicksCount || 0} />
          <InformationColumn
            title="Preliminary Failed"
            value={surveyInformation?.clicksStats?.preliminaryFailedClicksCount || 0}
          />
          <InformationColumn title="Qualified Clicks" value={calculateQualifiedClicks(surveyInformation)} />
          <InformationColumn title="DQ Clicks" value={surveyInformation?.clicksStats?.dqCount || 0} />
          <InformationColumn title="Leads" value={surveyInformation?.clicksStats?.leadsCount || 0} />
          <InformationColumn title="Reversals" value={surveyInformation?.clicksStats?.reversalsClickCount || 0} />
          <div className="border w-1px border-right-light-secondary ml-10" />
          <InformationColumn
            withTooltip tooltipTitle={calculateCRTooltip()}
            title="CR"
            value={calculateCR()}
            infoColor
          />
          <InformationColumn title="EPC" value={`$${surveyInformation?.epc || 0}`} infoColor />
          <InformationColumn
            title="Revenue"
            value={`$${surveyInformation?.clicksStats?.totalRevenue?.toFixed(2) || 0}`}
            infoColor
          />
          <InformationColumn title="Actual LOI" value={surveyInformation.successLOI || 0} infoColor />
          <InformationColumn title="DQ LOI" value={surveyInformation.dqLOI || 0} infoColor />
          <InformationColumn
            title="Rank 21"
            value={surveyInformation?.preCalculatedRanks?.rank21?.toFixed(2) || 0}
            infoColor />
          <InformationColumn
            title="Rank 17"
            value={surveyInformation?.preCalculatedRanks?.rank17?.toFixed(2) || 0}
            infoColor
          />
        </div>
      </CardContent>
      <URLModal shown={shown} setShown={setShown} url={surveyInformation.liveLink} />
    </Card>
  );
};

const findDetailedDataIcon = (link) => {
  switch (link) {
    case 'qualifications':
      return <DoneAll />;
    case 'quotas':
      return <Tune />;
    case 'targeted-users':
      return <Group />;

    default:
      return <Info />;
  }
}

const DetailedDataField = ({ title, link, color, surveyId }) => (
  <Link target="_blank" to={`/surveys/${surveyId}/${link}`}>
    <div className={`rounded mb-5 d-flex align-items-center h-40px px-3 py-2 bg-light-${color} text-${color}`}>
      <span className={`svg-icon h-20 mr-5 svg-icon-${color}`}>
        {findDetailedDataIcon(link)}
      </span>
      {title}
      <span className={`svg-icon h-20 ml-auto svg-icon-${color}`}>
       <OpenInNew />
      </span>
    </div>
  </Link>
)

const DetailedDataCard = () => {
  const surveyInformation = useSelector((state) => state.survey.surveyInformation);
  const { _id, provider } = surveyInformation;
  const providerComponent = () => {
    switch(provider) {

      case "ssiRex":   return <DetailedDataField color="success" link="ssirex-quotas-filters" title="SSI REX quotas and filters" surveyId={_id} />;
      case "tolunaIpes":   return <DetailedDataField color="success" link="toluna-ipes-details" title="Toluna IPES details" surveyId={_id} />;
      case "prodege":   return <DetailedDataField color="success" link="prodege-quotas" title="Prodege quotas" surveyId={_id} />;

      default: return <DetailedDataField color="success" link="quotas" title="Quotas" surveyId={_id} />
    }
  }
  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded">
        <div className="text-primary">Detailed Data</div>
        <div className="my-3 border border-bottom-light-secondary h-1px w-100" />
        <DetailedDataField color="warning" link="qualifications" title="Qualifications" surveyId={_id} />
        { providerComponent() }
        <DetailedDataField color="primary" link="targeted-users" title="Targeted Users" surveyId={_id} />
        <DetailedDataField color="info" link="raw-data" title="Survey Raw Data" surveyId={_id} />
      </CardContent>
    </Card>
  );
};

const optionsForFilterInChart = ['Today', 'Yesterday', 'Last 7 days', 'Custom'].map((option) => (
  <MenuItem key={option} value={option}>
    {option}
  </MenuItem>
));

const Survey = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const surveyInformation = useSelector((state) => state.survey.surveyInformation);
  const clicksPerformanceStartDate = useSelector((state) => state.survey.clicksPerformanceStartDate);
  const clicksPerformanceEndDate = useSelector((state) => state.survey.clicksPerformanceEndDate);
  const crStartDate = useSelector((state) => state.survey.crStartDate);
  const crEndDate = useSelector((state) => state.survey.crEndDate);
  const revenueStartDate = useSelector((state) => state.survey.revenueStartDate);
  const revenueEndDate = useSelector((state) => state.survey.revenueEndDate);
  const crStats = useSelector((state) => state.survey.crStats);
  const revenueStats = useSelector((state) => state.survey.revenueStats);
  const surveyStats = useSelector((state) => state.survey.surveyStats);
  const clicksStoppedReasons = useSelector((state) => state.survey.clicksStoppedReasons);
  const dqStats = useSelector((state) => state.survey.dqStats);
  const originStats = useSelector((state) => state.survey.originStats);
  const clicksPerformance = useSelector((state) => state.survey.clicksPerformance);
  const preliminaryFailedClicks = useSelector((state) => state.survey.preliminaryFailedClicks);
  const lastClickAndLead = useSelector((state) => state.survey.lastClickAndLead);
  const [selectedOptionCR, setSelectedOptionCr] = useState('Today');
  const [selectedOptionRevenue, setSelectedOptionRevenue] = useState('Today');
  const [selectedOptionClicksPerformance, setSelectedOptionClicksPerformance] = useState('Today');
  const [selectedPieChartType, setSelectedPieChartType] = useState('Stopped Clicks');

  useEffect(() => {
    dispatch(actions.getSurvey(params.surveyId));
    dispatch(actions.getSurveyStats(params.surveyId));
    dispatch(actions.getClicksStoppedReasons(params.surveyId));
    dispatch(actions.getPreliminaryFailedClicks(params.surveyId));
    dispatch(actions.getLastClickAndLead(params.surveyId));
    dispatch(actions.getSurveyDQStats(params.surveyId));
    dispatch(actions.getSurveyOriginStats(params.surveyId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (crStartDate && crEndDate && params.surveyId) {
      const dateFilter = {
        startDate: crStartDate,
        endDate: crEndDate,
      }
      dispatch(actions.getSurveyCRStats(params.surveyId, dateFilter));
    }
  }, [crEndDate, crStartDate, dispatch, params.surveyId])

  useEffect(() => {
    if (revenueStartDate && revenueEndDate && params.surveyId) {
      const dateFilter = {
        startDate: revenueStartDate,
        endDate: revenueEndDate,
      }
      dispatch(actions.getSurveyRevenueStats(params.surveyId, dateFilter));
    }
  }, [revenueStartDate, revenueEndDate, dispatch, params.surveyId])

  useEffect(() => {
    if (clicksPerformanceStartDate && clicksPerformanceEndDate && params.surveyId) {
      const dateFilter = {
        startDate: clicksPerformanceStartDate,
        endDate: clicksPerformanceEndDate,
      }
      dispatch(actions.getClicksPerformance(params.surveyId, dateFilter));
    }
  }, [clicksPerformanceStartDate, clicksPerformanceEndDate, dispatch, params.surveyId])

  if (!surveyInformation._id) {
    return null;
  }

  const changeOptionForCRChart = (e) => {
    setSelectedOptionCr(e.target.value);
    switch (e.target.value) {
      case 'Today':
        const todayDate = format(new Date(), enums.DATE_FORMAT);
        dispatch(actions.setCRStartDate(todayDate))
        dispatch(actions.setCREndDate(todayDate))
        break;

      case 'Yesterday':
        const yesterdayDate = format(addDays(new Date(), -1), enums.DATE_FORMAT);
        dispatch(actions.setCRStartDate(yesterdayDate))
        dispatch(actions.setCREndDate(yesterdayDate))
        break;

      case 'Last 7 days':
        const oneDayAgoDate = format(new Date(), enums.DATE_FORMAT);
        const sevenDayAgoDate = format(addDays(new Date(), -7), enums.DATE_FORMAT);
        dispatch(actions.setCRStartDate(sevenDayAgoDate))
        dispatch(actions.setCREndDate(oneDayAgoDate))
        break;

      default:
        break;
    }
  }

  const changeOptionForRevenueChart = (e) => {
    setSelectedOptionRevenue(e.target.value);
    switch (e.target.value) {
      case 'Today':
        const todayDate = format(new Date(), enums.DATE_FORMAT);
        dispatch(actions.setRevenueStartDate(todayDate))
        dispatch(actions.setRevenueEndDate(todayDate))
        break;

      case 'Yesterday':
        const yesterdayDate = format(addDays(new Date(), -1), enums.DATE_FORMAT);
        dispatch(actions.setRevenueStartDate(yesterdayDate))
        dispatch(actions.setRevenueEndDate(yesterdayDate))
        break;

      case 'Last 7 days':
        const oneDayAgoDate = format(new Date(), enums.DATE_FORMAT);
        const sevenDayAgoDate = format(addDays(new Date(), -7), enums.DATE_FORMAT);
        dispatch(actions.setRevenueStartDate(sevenDayAgoDate))
        dispatch(actions.setRevenueEndDate(oneDayAgoDate))
        break;

      default:
        break;
    }
  }

  const changeOptionForClicksPerformanceChart = (e) => {
    setSelectedOptionClicksPerformance(e.target.value);
    switch (e.target.value) {
      case 'Today':
        const todayDate = format(new Date(), enums.DATE_FORMAT);
        dispatch(actions.setClicksPerformanceStartDate(todayDate))
        dispatch(actions.setClicksPerformanceEndDate(todayDate))
        break;

      case 'Yesterday':
        const yesterdayDate = format(addDays(new Date(), -1), enums.DATE_FORMAT);
        dispatch(actions.setClicksPerformanceStartDate(yesterdayDate))
        dispatch(actions.setClicksPerformanceEndDate(yesterdayDate))
        break;

      case 'Last 7 days':
        const oneDayAgoDate = format(new Date(), enums.DATE_FORMAT);
        const sevenDayAgoDate = format(addDays(new Date(), -7), enums.DATE_FORMAT);
        dispatch(actions.setClicksPerformanceStartDate(sevenDayAgoDate))
        dispatch(actions.setClicksPerformanceEndDate(oneDayAgoDate))
        break;

      default:
        break;
    }
  }


  const findCRCategoriesOrValues = (type) => {
    if (!crStats || !crStats.length) {
      return [];
    }
    if (type === 'categories') {
      return crStats?.map((cr) => (cr.title));
    }
    return crStats?.map((stat) => (stat.cr));

  }

  const findRevenueCategoriesOrValues = (type) => {
    if (!revenueStats || !revenueStats.length) {
      return [];
    }

    if (type === 'categories') {
      return revenueStats?.map((cr) => (cr.title));
    }
    return revenueStats?.map((stat) => (stat.revenue.toFixed(2)));
  }

  const findSurveyStatsCategoriesOrValues = (type) => {
    if (!surveyStats || !Object.keys(surveyStats).length) {
      return [];
    }
    if (type === 'categories') {
      return Object.keys(surveyStats);
    }

    return Object.values(surveyStats);
  }

  const findOriginStatsCategoriesOrValues = (type) => {
    if (!originStats || !Object.keys(originStats).length) {
      return [];
    }
    if (type === 'categories') {
      return originStats.map((stat) => (stat._id));
    }

    return originStats.map((stat) => (stat.clicksCount));
  }

  const findPreliminaryFailedCategoriesOrValues = (type) => {
    if (!preliminaryFailedClicks || !preliminaryFailedClicks.length) {
      return [];
    }
    if (type === 'categories') {
      return preliminaryFailedClicks.map((val) => val._id);
    }

    return preliminaryFailedClicks.map((val) => val.clicksCount);
  }

  const findStoppedReasonsCategoriesOrValues = (type) => {
    if (!clicksStoppedReasons || !clicksStoppedReasons.length) {
      return [];
    }
    if (type === 'categories') {
      return clicksStoppedReasons.map((click) => (click._id));
    }
    return clicksStoppedReasons.map((click) => (click.clicksCount));
  }

  const findDQCategoriesOrValues = (type) => {
    if (!dqStats || !dqStats.length) {
      return [];
    }
    if (type === 'categories') {
      return dqStats.filter((click) => (!!click._id)).map((click) => (click._id));
    }
    return dqStats.filter((click) => (!!click.clicksCount)).map((click) => (click.clicksCount));
  }

  const findClicksPerformanceCategoriesOrValues = (type) => {
    if (!clicksPerformance || !Object.keys(clicksPerformance).length) {
      return [];
    }
    if (type === 'categories') {
      return clicksPerformance.categories;
    }
    return clicksPerformance.data;
  }

  const DatePickersForCustomOption = ({ option,startValue, endValue, onChangeStartValue, onChangeEndValue }) => {
    if (option !== 'Custom') {
      return null;
    }

    return (
      <>
        <KeyboardDatePicker
          autoOk
          className="mr-2"
          id="start-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="none"
          label="From"
          value={startValue}
          onChange={(_, v) => {dispatch(onChangeStartValue(v))}}
        />
        <KeyboardDatePicker
          autoOk
          id="end-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="none"
          label="To"
          value={endValue}
          onChange={(_, v) => {dispatch(onChangeEndValue(v))}}
        />
      </>
    )
  }

  return (
    <div>
      <Card className="mb-2">
        <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center">
          <h2>Survey Data</h2>
        </CardContent>
      </Card>
      <TopCard />
      <Grid container spacing={1} className="mt-1">
        <Grid item md={12} lg={4}>
          <DetailedDataCard />
        </Grid>
        <Grid item md={12} lg={4}>
          <Chart
            id="cr-chart"
            categories={findCRCategoriesOrValues('categories')}
            data={findCRCategoriesOrValues()}
            title="CR"
            baseColor="success"
            className="border h-100 pb-2 border-light-dark rounded"
            valueFormat="%"
          >
            <div className="d-flex justify-content-between pr-5">
              <FormControl margin="normal" className="w-100px mr-2">
                <Select
                  id="select-for-cr"
                  value={selectedOptionCR}
                  onChange={changeOptionForCRChart}
                >
                  {optionsForFilterInChart}
                </Select>
              </FormControl>
              <DatePickersForCustomOption
                startValue={crStartDate}
                endValue={crEndDate}
                onChangeEndValue={actions.setCREndDate}
                onChangeStartValue={actions.setCRStartDate}
                option={selectedOptionCR}
              />
            </div>
          </Chart>
        </Grid>
        <Grid item md={12} lg={4}>
          <Chart
            id="revenue-chart"
            categories={findRevenueCategoriesOrValues('categories')}
            data={findRevenueCategoriesOrValues()}
            title="Revenue"
            baseColor="primary"
            className="border h-100 pb-2 border-light-dark rounded"
            valueFormat="$"
          >
            <div className="d-flex justify-content-between pr-5">
              <FormControl margin="normal" className="w-100px mr-2">
                <Select
                  id="select-for-revenue"
                  value={selectedOptionRevenue}
                  onChange={changeOptionForRevenueChart}
                >
                  {optionsForFilterInChart}
                </Select>
              </FormControl>
              <DatePickersForCustomOption
                startValue={revenueStartDate}
                endValue={revenueEndDate}
                onChangeEndValue={actions.setRevenueEndDate}
                onChangeStartValue={actions.setRevenueStartDate}
                option={selectedOptionRevenue}
              />
            </div>
          </Chart>
        </Grid>
        <Grid item md={12} lg={4}>
          <PieChart
            onClick={setSelectedPieChartType}
            data={findSurveyStatsCategoriesOrValues()}
            categories={findSurveyStatsCategoriesOrValues('categories')}
            title="Total Clicks Stats"
            id="clicks-pie-charts"
            className="border pb-2 border-light-dark rounded"
          />
        </Grid>
        <Grid item md={12} lg={8}>
          {selectedPieChartType === 'Stopped Clicks' && (
            <ColumnChart
              data={findStoppedReasonsCategoriesOrValues()}
              categories={findStoppedReasonsCategoriesOrValues('categories')}
              className="border pb-2 border-light-dark rounded"
              title="Stopped Reasons"
              id="stopped-reasons-column-chart"
            />
          )}
          {selectedPieChartType === 'DQ' && (
            <ColumnChart
              data={findDQCategoriesOrValues()}
              categories={findDQCategoriesOrValues('categories')}
              className="border pb-2 border-light-dark rounded"
              title="DQ Reasons"
              customTooltipTitle
              provider={surveyInformation.provider}
              id="dq-reasons-column-chart"
              toolbarShow
            />
          )}
          {selectedPieChartType === 'Preliminary Failed' && (
            <PieChart
              fullData={preliminaryFailedClicks}
              legendPosition="left"
              showOnlyValue
              data={findPreliminaryFailedCategoriesOrValues()}
              categories={findPreliminaryFailedCategoriesOrValues('categories')}
              title="Preliminary Failed"
              id="preliminary-failed-pie-charts"
              className="border pb-2 border-light-dark rounded"
            />
          )}
        </Grid>
        <Grid item md={12} lg={8}>
          <AreaChart
            data={findClicksPerformanceCategoriesOrValues()}
            categories={findClicksPerformanceCategoriesOrValues('categories')}
            className="border pb-2 border-light-dark rounded"
            title="Clicks Performance"
            id="clicks-performance-chart"
            lastClick={lastClickAndLead?.lastClick?.clickDate}
            lastLead={lastClickAndLead?.lastLead?.clickDate}
          >
            <div className="d-flex justify-content-between pr-5">
              <FormControl margin="normal" className="w-100px mr-2">
                <Select
                  id="select-for-revenue"
                  value={selectedOptionClicksPerformance}
                  onChange={changeOptionForClicksPerformanceChart}
                >
                  {optionsForFilterInChart}
                </Select>
              </FormControl>
              <DatePickersForCustomOption
                startValue={clicksPerformanceStartDate}
                endValue={clicksPerformanceEndDate}
                onChangeEndValue={actions.setClicksPerformanceEndDate}
                onChangeStartValue={actions.setClicksPerformanceStartDate}
                option={selectedOptionClicksPerformance}
              />
            </div>
          </AreaChart>
        </Grid>
        <Grid item md={12} lg={4}>
          <PieChart
            onClick={() => {}}
            data={findOriginStatsCategoriesOrValues()}
            categories={findOriginStatsCategoriesOrValues('categories')}
            title="Clicks Origin Stats"
            id="origin-clicks-pie-charts"
            className="border h-100 pb-2 border-light-dark rounded"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Survey;
