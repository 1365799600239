import config from '~/config';
import fetch from '~/redux/fetch';
import { fetchReportHelper } from '~/app/helpers';

const getUserInformationURL = (userId) => {
  return `/users/${userId}/information`;
}

const getUserInformation = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserInformationURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getUserClicksURL = (userId) => {
  return `/users/${userId}/clicks`;
}

const getUserClicks = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserClicksURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getUserEarningsURL = (userId) => {
  return `/users/${userId}/earnings`;
}

const getUserEarnings = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserEarningsURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getUserOrdersURL = (userId) => {
  return `/users/${userId}/orders`;
}

const getUserOrders = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserOrdersURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const updateUserRestrictionsURL = (userId) => {
  return `/users/${userId}/restrictions`;
}

const updateUserRestrictions = async ({ userId, params }) => {
  const response = await fetch(`${config.apiBaseUrl}${updateUserRestrictionsURL(userId)}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });
  return response.json();
}

const shadowUnbanUser = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}/users/${userId}/shadow-unban`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const updateMassOrderingWhitelistStatusURL = (userId) => {
  return `/users/${userId}/mass-ordering-users`;
}

const updateMassOrderingWhitelistStatus = async ({ userId, params }) => {
  const response = await fetch(`${config.apiBaseUrl}${updateMassOrderingWhitelistStatusURL(userId)}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });
  return response.json();
}

const deleteUserURL = (userId) => {
  return `/users/${userId}`;
}

const deleteUser = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${deleteUserURL(userId)}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getUserProfilerQuestionsURL = (userId) => {
  return `/users/${userId}/profiler-questions`;
}

const getUserProfilerQuestions = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserProfilerQuestionsURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const updateUserIsSingleSurveyShouldBeShownURL = (userId) => {
  return `/users/${userId}/update-single-survey-should-be-shown`;
}

const updateUserIsSingleSurveyShouldBeShown = async ({ userId, params }) => {
  const response = await fetch(`${config.apiBaseUrl}${updateUserIsSingleSurveyShouldBeShownURL(userId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });
  return response.json();
}

const releaseUserOrderURL = (orderId) => {
  return `/orders/${orderId}`;
}

const releaseUserOrder = async (orderId) => {
  const response = await fetch(`${config.apiBaseUrl}${releaseUserOrderURL(orderId)}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}
const getUserEmailsURL = (userId) => {
  return `/users/${userId}/emails`;
}

const getUserEmails = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserEmailsURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getUserEmailsStatsURL = (userId) => {
  return `/users/${userId}/emails-stats`;
}

const getUserEmailsStats = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserEmailsStatsURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getUserNotificationsURL = (userId) => {
  return `/users/${userId}/notifications`;
}

const getUserNotifications = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserNotificationsURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getUserNotificationsStatsURL = (userId) => {
  return `/users/${userId}/notifications-stats`;
}

const getUserNotificationsStats = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserNotificationsStatsURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getUserIPsURL = (userId) => {
  return `/users/${userId}/ips`;
}

const getUserIPs = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserIPsURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const saveUserPhoneNumberURL = (userId) => {
  return `/users/${userId}/save-phone-number`;
}

const saveUserPhoneNumber = async ({ userId, data }) => {
  const response = await fetch(`${config.apiBaseUrl}${saveUserPhoneNumberURL(userId)}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return response.json();
}

const getUserNexmoLogsURL = (userId) => {
  return `/users/${userId}/nexmo-logs`;
}

const getUserNexmoLogs = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserNexmoLogsURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getUserIPsWithEmailsURL = (userId) => {
  return `/users/${userId}/ips-with-emails`;
}

const getUserIPsWithEmails = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserIPsWithEmailsURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const updateUserIgnoredMarksURL = (userId) => {
  return `/users/${userId}/ignored-marks`;
}

const updateUserIgnoredMarks = async ({ userId, orderSecurityIgnores }) => {
  const response = await fetch(`${config.apiBaseUrl}${updateUserIgnoredMarksURL(userId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ orderSecurityIgnores }),
  });
  return response.json();
}

const getUserPurchasesURL = (userId) => {
  return `/users/${userId}/purchases`;
}

const getUserPurchases = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserPurchasesURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getUserPublisherPostbackLogsURL = (userId) => {
  return `/users/${userId}/publisher-post-back-logs`;
}

const getUserPublisherPostbackLogs = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserPublisherPostbackLogsURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const fetchReportAsCsvURL = (userId) => {
  return `/users/${userId}/questions-report-csv`;
}

const fetchReportAsCsv = async (params) => {
  const response = await fetch(`${config.apiBaseUrl}${fetchReportAsCsvURL(params.userId)}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });
  const blob = await response.blob();
  const file = window.URL.createObjectURL(blob);
  var a = document.createElement('a');
  a.href = file;
  a.download = "Profiler Questions.csv";
  document.body.appendChild(a);
  a.click();
  a.remove();
}

const fetchReportForTargetedSurveysAsCsvURL = (userId) => {
  return `/users/${userId}/targeted-surveys-csv`;
}

const fetchReportForTargetedSurveysAsCsv = async (params) => {
  const response = await fetch(`${config.apiBaseUrl}${fetchReportForTargetedSurveysAsCsvURL(params.userId)}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });
  const blob = await response.blob();
  const file = window.URL.createObjectURL(blob);
  var a = document.createElement('a');
  a.href = file;
  a.download = "Targeted Surveys.csv";
  document.body.appendChild(a);
  a.click();
  a.remove();
}

const unVerifyEmailURL = (userId) => {
  return `/users/${userId}/un-verify-email`;
}

const unVerifyEmail = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${unVerifyEmailURL(userId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const unVerifyPhoneNumberURL = (userId) => {
  return `/users/${userId}/un-verify-phone-number`;
}

const unVerifyPhoneNumber = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${unVerifyPhoneNumberURL(userId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const setUserRoleURL = (userId) => {
  return `/users/${userId}`;
}

const setUserRole = async ({ userId, userRole }) => {
  const response = await fetch(`${config.apiBaseUrl}${setUserRoleURL(userId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userRole }),
  });
  return response.json();
}

const getUserPaypalLogsURL = (userId) => {
  return `/paypal-logs?userId=${userId}`;
}

const getUserPaypalLogs = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserPaypalLogsURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getUserTransactionsLogURL = (userId) => {
  return `/users/${userId}/transactions-log`;
}

const getUserTransactionsLog = async (userId) => {
  const response = await fetch(`${config.apiBaseUrl}${getUserTransactionsLogURL(userId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const FETCH_REPORT_URL = (userId) => (`/users/${userId}/targeted-surveys`);
const fetchUserReport = (userId) => (fetchReportHelper(FETCH_REPORT_URL(userId)));

export default {
  getUserInformation,
  getUserClicks,
  getUserEarnings,
  getUserOrders,
  updateUserRestrictions,
  shadowUnbanUser,
  updateMassOrderingWhitelistStatus,
  deleteUser,
  getUserProfilerQuestions,
  updateUserIsSingleSurveyShouldBeShown,
  releaseUserOrder,
  getUserEmails,
  getUserEmailsStats,
  getUserNotifications,
  getUserNotificationsStats,
  getUserIPs,
  saveUserPhoneNumber,
  getUserNexmoLogs,
  updateUserIgnoredMarks,
  getUserIPsWithEmails,
  getUserPurchases,
  getUserPublisherPostbackLogs,
  fetchReportAsCsv,
  unVerifyEmail,
  unVerifyPhoneNumber,
  fetchReport: fetchUserReport,
  fetchReportForTargetedSurveysAsCsv,
  setUserRole,
  getUserPaypalLogs,
  getUserTransactionsLog,
};
