import { select, call, put, takeLatest } from "redux-saga/effects";

import api from '~/app/modules/Miscellaneous/QAHelpers/api';
import { actions, actionTypes } from '~/app/modules/Miscellaneous/QAHelpers/actions';

function* createWalletLeadTransaction() {
  try {
    const userId = yield select((state) => state.qaHelpers.userId);
    const amount = yield select((state) => state.qaHelpers.amount);

    const results = yield call(api.createWalletLeadTransaction, { userId, amount });
    yield put(actions.createWalletLeadTransactionSuccess(results));
  } catch (error) {
    console.log(error);
    yield put(actions.createWalletLeadTransactionFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.CreateWalletLeadTransaction, createWalletLeadTransaction),
];


