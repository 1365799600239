import React, { useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import { actions } from '~/app/modules/QuestionsManagement/components/ProfilerOrder/actions';
import { sortCaret } from '~/_metronic/_helpers';
import {
  usePaginationFilteringAndSorting,
} from '~/app/helpers';
import { Card, CardContent } from "@material-ui/core";
import cs from 'classnames';
import { Star, Reorder } from "@material-ui/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import enums from "~/app/helpers/enums";

const sortableColumn = {
  sort: true, sortCaret,
};

const renderStatusField = (fieldValue, type, row, setSelectedValues, setShown) => {
  if (fieldValue && type === 'questionType') {
    const handleClick = (e) => {
      e.stopPropagation();
      setShown(true);
      setSelectedValues(row);
    }
    return (
      <div
        onClick={handleClick}
        className="py-2 px-5 d-flex rounded align-items-center justify-content-center bg-light-info text-info"
      >
        <div>True</div>
      </div>
    );
  }

  return (
    <div className={cs('py-2 px-5 rounded d-flex align-items-center justify-content-center', {
      'bg-light-secondary text-secondary': !fieldValue,
      'bg-light-primary text-primary': fieldValue,
    })}>
      <div>{fieldValue? 'True' : 'False'}</div>
    </div>
  );
}

const renderIdField = (row) => {
  return (
    <div className="d-flex" style={{ marginLeft: row?.questionIsMapped ? '-22px' : '0' }}>
      {row?.questionIsMapped && (
        <span style={{ marginRight: '3px' }} className="svg-icon svg-icon-warning h-20">
          <Star style={{ color: '#ffa701' }}/>
        </span>
      )}
      <div>{row._id}</div>
    </div>
  );
};

const MoveIcon = ({ row }) => {

  return (
    <Draggable key={row._id} draggableId={row._id} index={row.positionToShow}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="cursor-pointer"
        >
          <Reorder className={snapshot.isDragging ? 'text-muted': 'text-primary'} />
        </div>
      )}
    </Draggable>
  )
};

const columns = (setSelectedRow, selectedRow) => [{
  dataField: 'actions', text: ' ',
  formatter: (_cell, row) => <MoveIcon row={row} setSelectedRow={setSelectedRow} selectedRow={selectedRow} />,
  style: { width: '40px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'positionToShow', text: 'Position To Show',
  style: { width: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'positionInTop', text: 'Position In Top',
  style: { width: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: '_id', text: 'Internal Question ID',
  formatter: (_id, row) => renderIdField(row),
  style: { minWidth: '150px', width: '150px', paddingLeft: '20px', textAlign: 'left'},
  headerStyle: { paddingLeft: '20px' },
}, {
  ...sortableColumn,
  dataField: 'name', text: 'Question Name',
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'text', text: 'Question Text',
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'surveysCount', text: 'Number Of Surveys',
  style: { width: '120px', textAlign: 'left' },
}, {
  dataField: 'surveysPercentage', text: 'Percentage Of Surveys',
  formatter: (surveysPercentage) => (surveysPercentage ? `${(surveysPercentage * 100).toFixed(1)}%` : '-'),
  style: {width: '180px', textAlign: 'left'},
},{
  ...sortableColumn,
  dataField: 'inProfiler', text: 'In profiler',
  formatter: (inProfiler) => renderStatusField(inProfiler),
  style: { width: '100px', textAlign: 'left' },
}];

const ProfilerOrderReport = () => {
  const dispatch = useDispatch();
  const {
    sortDirection,
  } = usePaginationFilteringAndSorting(dispatch, 'profilerOrder', actions);
  const report = useSelector(state => state.profilerOrder.report, shallowEqual);
  const [selectedRow, setSelectedRow] = useState({});

  const reportColumns = columns(setSelectedRow, selectedRow);

  const handleOnDragEnd = (e) => {
    const draggableValue = report.find((item) => item.positionToShow === e.source.index);
    const newReportData = [];
    let index = 1;
    const reportValueWithoutDraggableValue = report.filter((item) => item.positionToShow !== e.source.index);
    reportValueWithoutDraggableValue.forEach((item) => {
      if (index !== e.destination.index) {
        const newData = { ...item };
        newData.positionToShow = index;
        newReportData.push(newData);
      } else {
        draggableValue.positionToShow = index;
        newReportData.push(draggableValue);
        index += 1;
        const newData = { ...item };
        newData.positionToShow = index;
        newReportData.push(newData);
      }
      index += 1;
    });
    if (e.destination.index > reportValueWithoutDraggableValue.length) {
      draggableValue.positionToShow = index;
      newReportData.push(draggableValue);
    }
    dispatch(actions.setReportValue(newReportData));
  }

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <BootstrapTable
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  headerClasses="table table-vertical-top"
                  bordered={false}
                  bootstrap4
                  responsive
                  keyField="_id"
                  columns={reportColumns}
                  data={report}
                  defaultSorted={[{
                    dataField: 'positionToShow',
                    order: sortDirection || enums.sortDirections.desc,
                  }]}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </CardContent>
    </Card>
  );
};

export default ProfilerOrderReport;
