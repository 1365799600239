import React  from 'react';
import { shallowEqual, useSelector } from "react-redux";
import { Card, CardContent, Box, Grid } from '@material-ui/core';

const VeriffLogStats = () => {
  const stats = useSelector(state => state.veriffLog.stats, shallowEqual);

  if (!stats) { return null; }

  return (
    <Box mt={2}>
      <Card style={{ backgroundColor: "lightgrey" }}>
        <CardContent style={{ paddingBottom: "16px" }}>
          <Grid container justify="space-between" alignItems="center">
            <div>
              Total Generated Verification Links: <b>{stats.totalGenerated}</b>
            </div>
            <div>
              Total Submitted Verifications: <b>{stats.totalSubmitted}</b>
            </div>
            <div>
              Total Approved IDs: <b>{stats.totalApproved}</b>
            </div>
            <div>
              Total Declined IDs: <b>{stats.totalDeclined}</b>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default VeriffLogStats;

