import config from '~/config';
import fetch from '~/redux/fetch';

const fetchPaypalStatsURL = '/paypal-stats';

const fetchPaypalStats = async () => {
  const response = await fetch(`${config.apiBaseUrl}${fetchPaypalStatsURL}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const fetchRewardsProvidersBalanceSettingsURL = '/reward-balances';

const fetchRewardsProvidersBalanceSettings = async () => {
  const response = await fetch(`${config.apiBaseUrl}${fetchRewardsProvidersBalanceSettingsURL}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

export default {
  fetchPaypalStats,
  fetchRewardsProvidersBalanceSettings
};
