import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
  newDateFormatLong,
  onTableChangeHelper,
  textFilterOnBlurHelper,
  useDidMount,
  usePaginationFilteringAndSorting
} from "~/app/helpers";
import { Card, CardContent } from "@material-ui/core";
import {actions} from "~/app/modules/Rewards/components/AmazonInventory/actions";
import React, { useEffect } from "react";
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator";
import {Pagination} from "~/_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {customFilter, selectFilter} from "react-bootstrap-table2-filter";
import {sortCaret} from "~/_metronic/_helpers";
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];

const amountOptions = [...enums.amazonCardsAmounts.map((amount) => ({
  value: amount, label: `$${amount}`
})), { value: 'all', label: 'All' }];

const sortableColumn = {
  sort: true, sortCaret,
};

const statusOptions = [{
  value: 'all', label: 'All'
}, {
  value: enums.amazonCardsStatuses.REDEEMED, label: 'Redeemed',
}, {
  value: enums.amazonCardsStatuses.NOT_REDEEMED, label: 'Not Redeemed'
}];

const columns = (filters) => [{
  ...sortableColumn,
  dataField: 'batchId', text: 'Batch Id',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "100px" }, defaultValue: filters['batchId'] || ''}),
  style: { width: '120px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: '_id', text: 'Card Id',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "230px" }, defaultValue: filters['_id'] || ''}),
  style: { width: '250px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'serialNumber', text: 'Card Serial Number',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "180px" }, defaultValue: filters['serialNumber'] || ''}),
  style: { width: '200px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'createdAt', text: 'Created Date',
  formatter: (date) => newDateFormatLong(date),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'claimCode', text: 'Claim Code',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "180px" }, defaultValue: filters['claimCode'] || ''}),
  style: { width: '200px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'amount', text: 'Payout',
  filter: selectFilter({
    defaultValue: filters['amount'] || 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: amountOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '120px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'currencyCode', text: 'Currency',
  style: { width: '200px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'usedAt', text: 'Status',
  filter: selectFilter({
    defaultValue: filters['usedAt'] || 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: statusOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '120px', textAlign: 'left' },
  formatter: (usedAt) => usedAt ? 'Redeemed' : 'Not Redeemed',
}, {
  ...sortableColumn,
  dataField: 'usedAt', text: 'Redeemed Date',
  formatter: (usedAt) => newDateFormatLong(usedAt),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'orderId', text: 'Order ID',
  style: { width: '200px', textAlign: 'left' },
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "150px" }}),
}];

const AmazonInventoryReport = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'amazonInventory', actions);
  const report = useSelector(state => state.amazonInventory.report, shallowEqual);
  const didMount = useDidMount();

  useEffect(() => {
    dispatch(actions.generateReport());
  }, [dispatch]);

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: ['batchId', '_id', 'serialNumber', 'claimCode', 'amount', 'usedAt', 'orderId'],
    didMount,
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = columns(filters);

  return (
    <Card className="mb-3">
      <CardContent className="border text-dark border-light-dark rounded">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <Pagination isLoading={false} paginationProps={paginationProps}>
              <BootstrapTable
                remote
                classes="table table-head-custom table-vertical-center overflow-hidden"
                headerClasses="table table-vertical-top"
                bootstrap4
                responsive
                keyField="_id"
                columns={reportColumns}
                data={report}
                filter={ filterFactory() }
                defaultSorted={[{
                  dataField: sortBy || 'createdAt',
                  order: sortDirection || enums.sortDirections.desc,
                }]}
                onTableChange={onTableChange}
                {...paginationTableProps}
              >
              </BootstrapTable>
            </Pagination>
          )}
        </PaginationProvider>
      </CardContent>
    </Card>
  );
};

export default AmazonInventoryReport;
