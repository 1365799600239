import { select, call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/Report/api';
import { actions, actionTypes } from '~/app/modules/Report/actions';

function* requestReport() {
  try {
    const reportParams = yield select(state => ({
      filterDate: {
        start: state.reportBuilder.startDate,
        end: state.reportBuilder.endDate,
      },
      sortBy: state.reportBuilder.sortBy,
      sortDirection: state.reportBuilder.sortDirection,
      dimensions: state.reportBuilder.dimensions,
      measures: state.reportBuilder.measures,
      reportType: state.reportBuilder.reportType,
      filters: state.reportBuilder.filters,
    }));
    const { report, sortBy, sortDirection } = yield call(api.fetchReport, reportParams);
    yield put(actions.generateReportSuccess({ report, sortBy, sortDirection }));
  } catch (error) {
    yield put(actions.generateReportFailure(error.message));
  }
};

function* requestReportAsCsv() {
  try {
    const reportParams = yield select(state => ({
      filterDate: {
        start: state.reportBuilder.startDate,
        end: state.reportBuilder.endDate,
      },
      sortBy: state.reportBuilder.sortBy,
      sortDirection: state.reportBuilder.sortDirection,
      dimensions: state.reportBuilder.dimensions,
      measures: state.reportBuilder.measures,
      reportType: state.reportBuilder.reportType,
      filters: state.reportBuilder.filters,
    }));
    yield call(api.fetchReportAsCsv, reportParams);
  } catch (error) {
    console.error(error);
  }
}

export default [
  takeLatest(actionTypes.RequestReport, requestReport),
  takeLatest(actionTypes.RequestReportAsCsv, requestReportAsCsv),
];

