import React from "react"
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import OrdersTab from "~/app/modules/User/components/ordersReportTabs/OrdersTab";
import PayPalLogs from "~/app/modules/User/components/ordersReportTabs/PayPalLogs";
import TransactionsLogTab from "~/app/modules/User/components/ordersReportTabs/TransactionsLogTab";
import {
  Card, CardContent, Grid
} from "@material-ui/core";
import enums from "~/app/helpers/enums";

export const ordersTabs = [
  {
    view: enums.userTabs.orders,
    name: 'Orders',
    renderComponent: (userId) => <OrdersTab userId={userId} />,
  },
  {
    view: enums.userTabs.transactionsLog,
    name: 'Transaction Log',
    renderComponent: (userId) => <TransactionsLogTab userId={userId} />,
  },
  {
    view: enums.userTabs.paypalLogs,
    name: 'PayPal Logs',
    renderComponent: (userId) => <PayPalLogs userId={userId} />,
  },
];

const SecurityDataNavigation = ({ userId }) => {
  return (
    <>
      <div className="flex-row-fluid">
        <Switch>
          <Redirect
            from={`/users/${userId}/${enums.userTabs.ordersReport}`}
            exact={true}
            to={`/users/${userId}/${enums.userTabs.ordersReport}/${enums.userTabs.orders}`}
          />
          {ordersTabs.map((tab) => (
            <Route
              key={tab.view}
              path={`/users/${userId}/${enums.userTabs.ordersReport}/${tab.view}`}
              render={() => tab.renderComponent(userId)}
            />
          ))}
        </Switch>
      </div>
    </>
  )
}

const SecurityDataMenu = ({ userId }) => {
  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded d-flex align-items-center py-3">
        <div className="card-title font-weight-bolder text-dark mb-0 font-size-h3 mr-10">
          Orders Report
        </div>
        <div className="d-flex navi navi-bold navi-hover navi-active navi-link-rounded">
          {ordersTabs.map((tab) => {
            return (
              <div key={tab.view} className="navi-item mr-5">
                <NavLink
                  to={`/users/${userId}/${enums.userTabs.ordersReport}/${tab.view}`}
                  className="navi-link py-4"
                  activeClassName="active border border-secondary"
                >
                <span className="navi-text font-size-lg">
                  {tab.name}
                </span>
                </NavLink>
              </div>
            )
          })}
        </div>
      </CardContent>
    </Card>
  );
}

const SecurityData = ({ userId }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SecurityDataMenu userId={userId} />
      </Grid>
      <Grid item xs={12}>
        <SecurityDataNavigation userId={userId} />
      </Grid>
    </Grid>
  )
};

export default SecurityData;
