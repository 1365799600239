import {call, put, select, takeLatest} from "redux-saga/effects";
import api from '~/app/modules/User/api';
import { actions, actionTypes } from '~/app/modules/User/actions';

const selectForFetchReport = (state) => ({
    filters: state.user.filters,
    pageNumber: state.user.pageNumber,
    pageSize: state.user.pageSize,
    sortBy: state.user.sortBy,
    sortDirection: state.user.sortDirection,
    userId: state.user.userId
});

const selectForFetchTargetedSurveysReport = (state) => ({
  filters: state.user.filters,
  userId: state.user.userId
})

function* requestReport() {
  try {
    const reportParams = yield select(selectForFetchReport);
    const results = yield call(api.fetchReport(reportParams.userId), reportParams);
    yield put(actions.fetchReportSuccess(results));
  } catch (error) {
    yield put(actions.fetchReportFailure(error.message));
  }
}

function* getUserInformation(action) {
  try {
    const results = yield call(api.getUserInformation, action.payload);
    yield put(actions.getUserInformationSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getUserClicks(action) {
  try {
    const results = yield call(api.getUserClicks, action.payload);
    yield put(actions.getUserClicksSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getUserEarnings(action) {
  try {
    const results = yield call(api.getUserEarnings, action.payload);
    yield put(actions.getUserEarningsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getUserOrders(action) {
  try {
    const results = yield call(api.getUserOrders, action.payload);
    yield put(actions.getUserOrdersSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getUserPaypalLogs(action) {
  try {
    const results = yield call(api.getUserPaypalLogs, action.payload);
    yield put(actions.getUserPaypalLogsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getUserTransactionsLog(action) {
  try {
    const results = yield call(api.getUserTransactionsLog, action.payload);
    yield put(actions.getUserTransactionsLogSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* updateUserRestrictions(action) {
  try {
    const { payload } = action;
    yield call(api.updateUserRestrictions, payload);
    yield put(actions.getUserInformation(payload.userId));
  } catch (error) {
    console.error(error);
  }
}

function* shadowUnbanUser(action) {
  try {
    const { payload } = action;
    yield call(api.shadowUnbanUser, payload.userId);
    yield put(actions.getUserInformation(payload.userId));
  } catch (error) {
    console.error(error);
  }
}

function* updateMassOrderingWhitelistStatus(action) {
  try {
    const { payload } =  action;
    yield call(api.updateMassOrderingWhitelistStatus, payload);
    yield put(actions.getUserInformation(payload.userId));
  } catch (error) {
    console.error(error);
  }
}

function* deleteUser(action) {
  try {
    const results = yield call(api.deleteUser, action.payload);
    yield put(actions.deleteUserSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getUserProfilerQuestions(action) {
  try {
    const results = yield call(api.getUserProfilerQuestions, action.payload);
    yield put(actions.getUserProfilerQuestionsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* updateUserIsSingleSurveyShouldBeShown(action) {
  try {
    const { payload } =  action;
    yield call(api.updateUserIsSingleSurveyShouldBeShown, payload);
    yield put(actions.getUserInformation(payload.userId));
  } catch (error) {
    console.error(error);
  }
}

function* releaseUserOrder(action) {
  try {
    const payload =  action.payload;
    yield call(api.releaseUserOrder, payload.orderId);
    yield put(actions.getUserOrders(payload.userId));
  } catch (error) {
    console.error(error);
  }
}

function* getUserEmails(action) {
  try {
    const results = yield call(api.getUserEmails, action.payload);
    yield put(actions.getUserEmailsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getUserEmailsStats(action) {
  try {
    const results = yield call(api.getUserEmailsStats, action.payload);
    yield put(actions.getUserEmailsStatsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getUserNotifications(action) {
  try {
    const results = yield call(api.getUserNotifications, action.payload);
    yield put(actions.getUserNotificationsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getUserNotificationsStats(action) {
  try {
    const results = yield call(api.getUserNotificationsStats, action.payload);
    yield put(actions.getUserNotificationsStatsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getUserIPs(action) {
  try {
    const results = yield call(api.getUserIPs, action.payload);
    yield put(actions.getUserIPsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* saveUserPhoneNumber(action) {
  try {
    const { payload } =  action;
    yield call(api.saveUserPhoneNumber, payload);
    yield put(actions.getUserInformation(payload.userId));
  } catch (error) {
    console.error(error);
  }
}

function* getUserNexmoLogs(action) {
  try {
    const results = yield call(api.getUserNexmoLogs, action.payload);
    yield put(actions.getUserNexmoLogsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getUserIPsWithEmails(action) {
  try {
    const results = yield call(api.getUserIPsWithEmails, action.payload);
    yield put(actions.getUserIPsWithEmailsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* updateUserIgnoredMarks(action) {
  try {
    const { payload } =  action;
    yield call(api.updateUserIgnoredMarks, payload);
    yield put(actions.getUserInformation(payload.userId));
  } catch (error) {
    console.error(error);
  }
}

function* getUserPublisherPostbackLogs(action) {
  try {
    const results = yield call(api.getUserPublisherPostbackLogs, action.payload);
    yield put(actions.getUserPublisherPostbackLogsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getUserPurchases(action) {
  try {
    const results = yield call(api.getUserPurchases, action.payload);
    yield put(actions.getUserPurchasesSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* fetchReportAsCsv(action) {
  try {
    yield call(api.fetchReportAsCsv, action.payload);
  } catch (error) {
    console.error(error);
  }
}

function* fetchReportForTargetedSurveysAsCsv() {
  try {
    const reportParams = yield select(selectForFetchTargetedSurveysReport);
    yield call(api.fetchReportForTargetedSurveysAsCsv, reportParams);
    yield put(actions.generateReportForTargetedSurveysAsCsvSuccess());
  } catch (error) {
    console.error(error);
  }
}

function* unVerifyEmail(action) {
  try {
    const { payload } =  action;
    yield call(api.unVerifyEmail, action.payload);
    yield put(actions.getUserInformation(payload));
  } catch (error) {
    console.error(error);
  }
}

function* unVerifyPhoneNumber(action) {
  try {
    const { payload } =  action;
    yield call(api.unVerifyPhoneNumber, action.payload);
    yield put(actions.getUserInformation(payload));
  } catch (error) {
    console.error(error);
  }
}

function* setUserRole(action) {
  try {
    const { payload } =  action;
    yield call(api.setUserRole, payload);
    yield put(actions.getUserInformation(payload.userId));
  } catch (error) {
    console.error(error);
  }
}


export default [
  takeLatest([
    actionTypes.GenerateReport,
    actionTypes.UpdateReport,
  ], requestReport),
  takeLatest(actionTypes.GetUserInformation, getUserInformation),
  takeLatest(actionTypes.GetUserClicks, getUserClicks),
  takeLatest(actionTypes.GetUserEarnings, getUserEarnings),
  takeLatest(actionTypes.GetUserOrders, getUserOrders),
  takeLatest(actionTypes.GetUserPaypalLogs, getUserPaypalLogs),
  takeLatest(actionTypes.UpdateUserRestrictions, updateUserRestrictions),
  takeLatest(actionTypes.ShadowUnbanUser, shadowUnbanUser),
  takeLatest(actionTypes.UpdateMassOrderingWhitelistStatus, updateMassOrderingWhitelistStatus),
  takeLatest(actionTypes.DeleteUser, deleteUser),
  takeLatest(actionTypes.GetUserProfilerQuestions, getUserProfilerQuestions),
  takeLatest(actionTypes.UpdateUserIsSingleSurveyShouldBeShown, updateUserIsSingleSurveyShouldBeShown),
  takeLatest(actionTypes.ReleaseUserOrder, releaseUserOrder),
  takeLatest(actionTypes.GetUserEmails, getUserEmails),
  takeLatest(actionTypes.GetUserEmailsStats, getUserEmailsStats),
  takeLatest(actionTypes.GetUserNotifications, getUserNotifications),
  takeLatest(actionTypes.GetUserNotificationsStats, getUserNotificationsStats),
  takeLatest(actionTypes.GetUserIPs, getUserIPs),
  takeLatest(actionTypes.SaveUserPhoneNumber, saveUserPhoneNumber),
  takeLatest(actionTypes.GetUserNexmoLogs, getUserNexmoLogs),
  takeLatest(actionTypes.UpdateUserIgnoredMarks, updateUserIgnoredMarks),
  takeLatest(actionTypes.GetUserIPsWithEmail, getUserIPsWithEmails),
  takeLatest(actionTypes.GetUserPublisherPostbackLogs, getUserPublisherPostbackLogs),
  takeLatest(actionTypes.GetUserPurchases, getUserPurchases),
  takeLatest(actionTypes.GenerateReportAsCSV, fetchReportAsCsv),
  takeLatest(actionTypes.UnVerifyEmail, unVerifyEmail),
  takeLatest(actionTypes.UnVerifyPhoneNumber, unVerifyPhoneNumber),
  takeLatest(actionTypes.SetUserRole, setUserRole),
  takeLatest(actionTypes.GenerateReportForTargetedSurveysAsCsv, fetchReportForTargetedSurveysAsCsv),
  takeLatest(actionTypes.GetUserTransactionsLog, getUserTransactionsLog),
];
