import _ from 'lodash';
import { actionTypes } from '~/app/modules/BannedUsers/actions';
import {format} from "date-fns";
import enums from "~/app/helpers/enums";

const initialState = {
  pageNumber: 1,
  pageSize: 20,
  sortBy: 'bannedAt',
  sortDirection: enums.sortDirections.desc,
  filters: {},
  bannedAtStart: format(new Date(), enums.DATE_FORMAT),
  bannedAtEnd: format(new Date(), enums.DATE_FORMAT),
  useBannedAt: false,

  bannedUserList: [],
  totalCount: 0,
  stats: null,
  useBannedAtSort: true,

  massBanRows: [], // ['userId,banReason']
  massUnbanRows: [], // ['userId']
  serverResult: {},
  serverResultTitle: '',
  serverError: '',
  showServerError: false,
  errorMessage: '',
  showError: false,
  successMessage: '',
  showSuccess: false,

  isLoading: false,
};

const resetPaginationState = {
  pageNumber: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetPagination:
      return {
        ...state,
        ...resetPaginationState,
        pageSize: action.payload.pageSize,
        pageNumber: action.payload.pageNumber,
      };

    case actionTypes.SetSorting:
      return {
        ...state,
        ...resetPaginationState,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };

    case actionTypes.SetFilter:
      const { field, value } = action.payload;
      const curFilters = state.filters;
      if (value) {
        return {
          ...state,
          ...resetPaginationState,
          filters: {
            ...curFilters,
            [field]: value,
          },
        };
      } else {
        return {
          ...state,
          ...resetPaginationState,
          filters: _.omit(curFilters, field),
        };
      }

    case actionTypes.GenerateReport:
    case actionTypes.UpdateReport:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FetchReportSuccess:
      return {
        ...state,
        bannedUserList: action.payload.data,
        totalCount: action.payload.totalCount,
        stats: action.payload.stats,
        isLoading: false,
      };

    case actionTypes.FetchReportError:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.SetUseBannedAt:
      return {
        ...state,
        useBannedAt: action.payload,
      };

    case actionTypes.SetBannedAtStart:
      return {
        ...state,
        bannedAtStart: action.payload,
      };

    case actionTypes.SetBannedAtEnd:
      return {
        ...state,
        bannedAtEnd: action.payload,
      };

    case actionTypes.SetGenerateButtonClick:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SetUseBannedAtSort:
      return {
        ...state,
        useBannedAtSort: action.payload,
      };

    case actionTypes.SetMassBanRows:
      return {
        ...state,
        massBanRows: action.payload,
      };

    case actionTypes.MassBan:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.MassBanSuccess:
      return {
        ...state,
        serverResult: action.payload,
        isLoading: false,
      };

    case actionTypes.MassBanFailure:
      return {
        ...state,
        serverError: action.payload,
        showServerError: true,
        isLoading: false,
      };

    case actionTypes.SetMassUnbanRows:
      return {
        ...state,
        massUnbanRows: action.payload,
      };

    case actionTypes.MassUnban:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.MassUnbanSuccess:
      return {
        ...state,
        serverResult: action.payload,
        isLoading: false,
      };

    case actionTypes.MassUnbanFailure:
      return {
        ...state,
        showServerError: true,
        serverError: action.payload,
        isLoading: false,
      };

    case actionTypes.HideServerError:
      return {
        ...state,
        showServerError: false,
      };

    case actionTypes.ShowError:
      return {
        ...state,
        showError: true,
        errorMessage: action.payload,
      };

    case actionTypes.HideError:
      return {
        ...state,
        showError: false,
      };

    case actionTypes.ShowSuccess:
      return {
        ...state,
        serverResultTitle: action.payload,
        showSuccess: true,
      };

    case actionTypes.HideSuccess:
      return {
        ...state,
        showSuccess: false,
      };

    default:
      return state;
  }
};
