import React, { useEffect } from 'react';
import { Card, CardContent, Table, TableRow, TableCell, TableBody, TableHead } from '@material-ui/core';
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator";
import {Pagination} from "~/_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { selectFilter, customFilter } from 'react-bootstrap-table2-filter';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
  newDateFormatLong,
  onTableChangeHelper,
  textFilterOnBlurHelper,
  useDidMount,
  usePaginationFilteringAndSorting,
  JsonCell, textFilterOnBlurHelperWithAutoComplete,
} from "~/app/helpers";
import { actions } from "~/app/modules/SurveyListResultsLog/actions";
import { sortCaret } from "~/_metronic/_helpers";
import { differenceInMilliseconds } from "date-fns";
import {Link} from "react-router-dom";
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const sortableColumn = {
  sort: true, sortCaret,
};

const rankTypeOptions = [
  { label: "rank17", value: 17 },
  { label: "rank21", value: 21 },
  { label: "rank26", value: 26 },
];

const renderDaysSinceSignUp = (date) => {
  if (!date) {
    return '-';
  }
  const diffInMilliseconds = differenceInMilliseconds(new Date(), new Date(date));
  const millisecondsInSeconds = 1000;
  const secondsInMinutes = 60;
  const minutesInHours = 60;
  const hoursInDay = 24;

  return (diffInMilliseconds/millisecondsInSeconds/secondsInMinutes/minutesInHours/hoursInDay).toFixed(0) || 0;
};

const surveyIdLink = (_id) => (
  <div>
    <Link
      target="_blank"
      to={`/surveys/${_id}`}
    >
      {_id}
    </Link>
  </div>
);

const renderSurveys = (surveys) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Survey Provider</TableCell>
          <TableCell>Survey ID</TableCell>
          <TableCell>Rank</TableCell>
          <TableCell>Provider EPC</TableCell>
          <TableCell>Provider CR</TableCell>
          <TableCell>Our EPC</TableCell>
          <TableCell>Our CR</TableCell>
          <TableCell>Has Preliminary</TableCell>
          <TableCell>Provider CPI</TableCell>
          <TableCell>Provider LOI</TableCell>
          <TableCell>Our LOI</TableCell>
          <TableCell>Last Clicks No Leads</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {surveys?.length ? (
          surveys.map((survey, index) => {
            if (!survey) {
              return <div />;
            }
            return (
              <TableRow key={index}>
                <TableCell>{survey?.provider || '-'}</TableCell>
                <TableCell>{survey?._id ? surveyIdLink(survey?._id) : '-'}</TableCell>
                <TableCell>{survey?.rank?.toFixed(2) || '-'}</TableCell>
                <TableCell>{survey?.statistics?.epc || '-'}</TableCell>
                <TableCell>{survey?.statistics?.cr || '-'}</TableCell>
                <TableCell>{survey?.epc || '-'}</TableCell>
                <TableCell>{survey?.cr || '-'}</TableCell>
                <TableCell>{survey?.additionalQuestions?.length ? 'True' : 'False'}</TableCell>
                <TableCell>{survey?.cpi || '-'}</TableCell>
                <TableCell>{survey?.statistics?.lengthOfInterview || '-'}</TableCell>
                <TableCell>{survey?.ourLOI || '-'}</TableCell>
                <TableCell>{survey?.lastClicksNoLeads || '-'}</TableCell>
              </TableRow>
            )})
        ) : ''}
      </TableBody>
    </Table>
  );
};

const userIdLink = (_id) => (
  <div>
    <Link
      target="_blank"
      to={`/users/${_id}`}
    >
      {_id}
    </Link>
  </div>
);

const columns = (useCountryFieldSort) => [{
  ...sortableColumn,
  dataField: 'timestamp', text: 'Date',
  formatter: (timestamp) => newDateFormatLong(timestamp),
  style: { width: '150px', minWidth: '150px', textAlign: 'left', verticalAlign: 'top' },
}, {
  ...sortableColumn,
  dataField: 'user.userId', text: 'User Id',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "230px" } }),
  formatter: (userId) => userIdLink(userId),
  style: { width: '250px', minWidth: '250px', textAlign: 'left', verticalAlign: 'top' },
}, {
  ...sortableColumn,
  dataField: 'user.countryCode', text: 'Country',
  sort: useCountryFieldSort,
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelperWithAutoComplete(
    onFilter,
    column,
    {style: { width: "50px" }},
    actions,
    'surveyListResultsLogs'
  ),
  style: { width: '80px', minWidth: '80px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'rankType', text: 'Rank',
  style: { width: '90px', minWidth: '90px', textAlign: 'left', verticalAlign: 'top' },
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '70px' },
    options: rankTypeOptions,
    withoutEmptyOption: true,
  }),
}, {
  dataField: 'user.createDate', text: 'Days Since User Signup',
  formatter: (_cell) => renderDaysSinceSignUp(_cell),
  style: { width: '150px', minWidth: '150px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'filteringDebugInfo', text: 'Filtering Debug Info',
  formatter: (filteringDebugInfo) => (filteringDebugInfo
    ? <JsonCell data={JSON.stringify(filteringDebugInfo)} title="Filtering Debug Info" length={20} />
    : ''),
  style: { width: '150px', minWidth: '150px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'surveys', text: 'Surveys',
  formatter: (_cell) => renderSurveys(_cell),
  style: { width: '800px', minWidth: '800px', textAlign: 'left' },
}];

const SurveyListResultsLog = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'surveyListResultsLogs', actions);
  const report = useSelector(state => state.surveyListResultsLogs.report, shallowEqual);
  const useCountryFieldSort = useSelector((state) => state.surveyListResultsLogs.useCountryFieldSort);
  const didMount = useDidMount();

  useEffect(() => {
    dispatch(actions.generateReport());
  }, [dispatch]);

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: ['user.userId', 'user.countryCode', 'rankType'],
    didMount,
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = columns(useCountryFieldSort);

  return (
    <>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded">
          <div className="d-flex justify-content-between align-items-start mb-3">
            <h3>Survey List Results Logs</h3>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <Pagination isLoading={false} paginationProps={paginationProps}>
                <BootstrapTable
                  remote
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  headerClasses="table table-vertical-top"
                  bootstrap4
                  responsive
                  keyField="_id"
                  columns={reportColumns}
                  data={report}
                  filter={ filterFactory() }
                  // rowStyle={ rowStyle }
                  // expandRow={ expandRow }
                  defaultSorted={[{
                    dataField: 'timestamp',
                    order: sortDirection || enums.sortDirections.desc,
                  }]}
                  onTableChange={onTableChange}
                  {...paginationTableProps}
                >
                </BootstrapTable>
              </Pagination>
            )}
          </PaginationProvider>
        </CardContent>
      </Card>
    </>
  )
};

export default SurveyListResultsLog;
