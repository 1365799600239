import React from 'react';
import { ProgressBar } from "react-bootstrap";

const CustomProgressBar = ({ percent = 0, withApproved, approvedPercent = 0 }) => {
  let variant = 'warning';
  if (withApproved || (withApproved === undefined && percent === 100)) {
    variant = 'success';
  }

  if ((!approvedPercent && percent !== 100) || withApproved) {
    return (<ProgressBar className="w-100" variant={variant} now={percent} label={`${percent}%`}/>);
  }

  return (
    <ProgressBar className="w-100">
      <ProgressBar variant="success" now={approvedPercent} label={`${approvedPercent}%`} key={1} />
      <ProgressBar variant="warning" now={percent} label={`${Math.round(percent + approvedPercent)}%`} key={2} />
    </ProgressBar>
  );
};

export default CustomProgressBar;
