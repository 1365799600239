let store;
export function setupFetch(_store) {
  store = _store;
}

function withAuthHeader(options) {
  const newOptions = { ...options };
  const { auth: { authToken } } = store.getState();

  if (authToken) {
    newOptions.headers = {
      ...newOptions.headers,
      Authorization: `Bearer ${authToken}`,
    };
  }
  return newOptions;
}

export default function fetchWithAuth(url, options) {
  return fetch(url, withAuthHeader(options));
}
