import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import {
  Card, CardContent
} from "@material-ui/core";
import { utcToZonedTime, format } from "date-fns-tz";

const dateFormat = (date) => {
  if (!date) {
    return '-';
  }

  const newDate = utcToZonedTime(date, 'Etc/GMT-8');
  return `${format(newDate, 'dd/MM/yyyy HH:mm:ss a')} (GMT-8)`;
}

const DateField = ({ date, title, color }) => (
  <div className="d-flex justify-content-start align-items-start flex-column ml-3 mt-3">
    <div>
      {title}
    </div>
    <div
      className={`rounded w-135px min-h-45px bg-light-${color} text-${color} d-flex align-items-center justify-content-center text-center py-1 px-3`}
    >
      {dateFormat(date)}
    </div>
  </div>
);

function getChartOption(data, categories) {
  const options = {
    series: data,
    chart: {
      height: 450,
      width: 800,
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#93E6CF', '#E693BE'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories,
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return `Count: ${val}`;
        },
      },
    },
  };
  return options;
}

const AreaChart = ({ className, data, title, categories, id, children, lastLead, lastClick }) => {
  useEffect(() => {
    const element = document.getElementById(id);

    if (!element) {
      return;
    }

    const options = getChartOption(data, categories,);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [data, categories, id]);

  return (
    <Card className={className}>
      <CardContent>
        <div className="text-primary mb-3">{title}</div>
        <div className="border border-bottom-light-secondary h-1px w-100" />
        <div className="d-flex">
          <div className="d-flex flex-column min-w-200px">
            <DateField title="Last Click" color="primary" date={lastClick} />
            <DateField title="Last Lead" color="success" date={lastLead} />
          </div>
          <div className="mx-2 w-1px border border-right-secondary h-500px" />
          <div className="d-flex flex-column mt-2 ml-3">
            <div>{children}</div>
            <div
              id={id}
              className="card-rounded-bottom"
              style={{ height: "310px", paddingTop: '10px' }}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default AreaChart;
