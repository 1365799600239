export const actionTypes = {
  GetLanguages: 'languages/get-languages',
  GetLanguagesSuccess: 'languages/get-languages-success',
  GetLanguagesFailure: 'languages/get-languages-failure',
  MassVerifyKeyTranslations: 'languages/mass-verify-key-translations',
  MassVerifyKeyTranslationsSuccess: 'languages/mass-verify-key-translations-success',
  MassVerifyKeyTranslationsFailure: 'languages/mass-verify-key-translations-failure',
  CleanVerificationError: 'language/clean-verification-error',
  CleanVerificationSuccess: 'language/clean-verification-success',
  SetSlackId: 'language/set-slack-id',
  SetSlackIdSuccess: 'language/set-slack-id-success',
  SetSlackIdFailure: 'language/set-slack-id-failure',
};

export const actions = {
  getLanguages: () => ({ type: actionTypes.GetLanguages }),
  getLanguagesSuccess: (languages) => ({ type: actionTypes.GetLanguagesSuccess, payload: languages }),
  getLanguagesFailure: () => ({ type: actionTypes.GetLanguagesFailure }),
  massVerifyKeyTranslations: (data) => ({ type: actionTypes.MassVerifyKeyTranslations, payload: data }),
  massVerifyKeyTranslationsSuccess: () => ({ type: actionTypes.MassVerifyKeyTranslationsSuccess }),
  massVerifyKeyTranslationsFailure: () => ({ type: actionTypes.MassVerifyKeyTranslationsFailure }),
  cleanVerificationError: () => ({ type: actionTypes.CleanVerificationError }),
  cleanVerificationSuccess: () => ({ type: actionTypes.CleanVerificationSuccess }),
  setSlackId: (language) => ({ type: actionTypes.SetSlackId, payload: language }),
  setSlackIdSuccess: () => ({ type: actionTypes.SetSlackIdSuccess }),
  setSlackIdFailure: () => ({ type: actionTypes.SetSlackIdFailure }),
};
