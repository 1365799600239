import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  Button,
  Snackbar,
  Collapse,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CloseOutlined, ExpandLess, ExpandMore } from '@material-ui/icons';
import { actions } from '~/app/modules/BannedUsers/actions';

const MassUsersActionForm = ({
  shown,
  close,
  submit,
  rows,
  title,
  totalsTitle,
  headerTitles,
}) => {
  return (
    <Modal
      scrollable
      centered
      size="lg"
      show={shown}
      onHide={close}
    >
      <Modal.Header className="d-flex justify-content-between align-items-start">
        <Modal.Title>
          {title}
        </Modal.Title>
        <div className="cursor-pointer pb-2 py-2" onClick={close}><CloseOutlined /></div>
      </Modal.Header>
      <Modal.Body className="px-10">
        <div className="pb-2 w-100 border-bottom">
          <h5>{totalsTitle} {rows.length}</h5>
        </div>
        <table className="table table-striped">
          <thead><tr>
            { headerTitles.map((ht, idx1) => (
              <th key={idx1}>{ht}</th>
            ))}
          </tr></thead>
          <tbody>
            { rows.map((row, idx) => {
              const columns = row.split(',');
              return (
                <tr key={idx}>
                  { columns.map((col, subidx) => (
                    <td key={subidx}>{col}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          className="mr-3 btn btn-secondary"
          color="secondary"
          variant="contained"
          onClick={close}
        >
          Cancel
        </Button>
        <Button
          className="mr-3 btn btn-primary"
          color="secondary"
          variant="contained"
          onClick={submit}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const MassActionButton = ({
  formShown,
  showForm,
  closeForm,
  tableRows,
  setParsedRows,
  submit,
  buttonClasses,
  buttonTitle,
  formTitle,
  formTotalsTitle,
  formHeaderTitles,
}) => {
  const dispatch = useDispatch();

  const fileInputId = `file ${buttonTitle}`;

  const handleChange = (e) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        let rows = reader.result.replace(/\r/g, '').split('\n').filter((r) => !!r);
        if (rows[0].toLowerCase().startsWith('user')) {
          rows = rows.slice(1);
        }
        setParsedRows(rows);
        showForm();
      };
      reader.readAsText(e.target.files[0]);
    } catch (error) {
      dispatch(actions.ShowError(error.message));
    }
  };

  const openMassActionFileDialog = () => {
    const fileElem = document.getElementById(fileInputId);
    fileElem.addEventListener('click', () => { fileElem.value = null; }); // allow choose same file
    fileElem.click();
  };

  const submitMassAction = () => {
    closeForm();
    submit();
  };

  return (
    <>
      <Button
        className={buttonClasses}
        color="secondary"
        variant="contained"
        onClick={openMassActionFileDialog}
      >
        {buttonTitle}
      </Button>
      <input id={fileInputId} type="file" onChange={handleChange} className="d-none" />
      <MassUsersActionForm
        shown={formShown}
        close={closeForm}
        submit={submitMassAction}
        rows={tableRows}
        title={formTitle}
        totalsTitle={formTotalsTitle}
        headerTitles={formHeaderTitles}
      />
    </>
  );
};

const MassBanButton = () => {
  const dispatch = useDispatch();
  const [massBanFileFormShown, setMassBanFileFormShown] = useState(false);
  const massBanRows = useSelector(state => state.bannedUsers.massBanRows);

  return (
    <MassActionButton
      formShown={massBanFileFormShown}
      showForm={() => setMassBanFileFormShown(true)}
      closeForm={() => setMassBanFileFormShown(false)}
      tableRows={massBanRows}
      setParsedRows={(rows) => dispatch(actions.setMassBanRows(rows))}
      submit={() => dispatch(actions.massBan())}
      buttonClasses="mr-3 btn btn-info"
      buttonTitle="Mass Ban"
      formTitle="MASS BAN"
      formTotalsTitle="TOTAL USERS TO BAN:"
      formHeaderTitles={[
        "USER ID",
        "BAN REASON",
      ]}
    />
  );
};

const MassUnbanButton = () => {
  const dispatch = useDispatch();
  const [massUnbanFileFormShown, setMassUnbanFileFormShown] = useState(false);
  const massUnbanRows = useSelector(state => state.bannedUsers.massUnbanRows);

  return (
    <MassActionButton
      formShown={massUnbanFileFormShown}
      showForm={() => setMassUnbanFileFormShown(true)}
      closeForm={() => setMassUnbanFileFormShown(false)}
      tableRows={massUnbanRows}
      setParsedRows={(rows) => dispatch(actions.setMassUnbanRows(rows))}
      submit={() => dispatch(actions.massUnban())}
      buttonClasses="mr-3 btn btn-success"
      buttonTitle="Mass Unban"
      formTitle="MASS UNBAN"
      formTotalsTitle="TOTAL USERS TO UNBAN:"
      formHeaderTitles={[
        "USER ID",
      ]}
    />
  );
};

const CollapsableResultList = ({
  titleClass,
  title,
  rows,
}) => {
  const [shown, setShown] = useState(false);

  return (
    <div className="mb-5">
      <h5 className={titleClass} role="button" onClick={() => setShown(!shown)}>
        {title}: {rows.length}
        { rows.length > 0 && (
          <span className="ml-2">
            { shown ? <ExpandLess /> : <ExpandMore /> }
          </span>
        )}
      </h5>
      <Collapse in={shown}>
        <div className="ml-3 mt-2 mb-2">
          { rows.map((row, idx) => (
            <div key={idx}>
              {row}
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

const MassActionResults = ({
  shown, close,
  title,
  serverResult,
}) => {
  if (!shown) { return null; }

  return (
    <Modal
      scrollable
      centered
      size="lg"
      show={true}
      onHide={close}
    >
      <Modal.Header className="d-flex justify-content-between align-items-start">
        <Modal.Title>
          {title}
        </Modal.Title>
        <div className="cursor-pointer pb-2 py-2" onClick={close}><CloseOutlined /></div>
      </Modal.Header>
      <Modal.Body className="px-10">
        <CollapsableResultList
          titleClass="text-success"
          title="Success"
          rows={serverResult.success}
        />
        <CollapsableResultList
          titleClass="text-secondary"
          title="Not Changed"
          rows={serverResult.notChanged}
        />
        <CollapsableResultList
          titleClass="text-info"
          title="Not Found"
          rows={serverResult.notFound}
        />
        <CollapsableResultList
          titleClass="text-danger"
          title="With Error"
          rows={serverResult.withError}
        />
      </Modal.Body>
    </Modal>
  );
};

const MassActionButtons = () => {
  const dispatch = useDispatch();
  const showServerError = useSelector(state => state.bannedUsers.showServerError);
  const serverError = useSelector(state => state.bannedUsers.serverError);
  const errorMessage = useSelector(state => state.bannedUsers.errorMessage);
  const showError = useSelector(state => state.bannedUsers.showError);
  const serverResultTitle = useSelector(state => state.bannedUsers.serverResultTitle);
  const serverResult = useSelector(state => state.bannedUsers.serverResult);
  const showSuccess = useSelector(state => state.bannedUsers.showSuccess);

  return (
    <>
      <MassUnbanButton />
      <MassBanButton />

      <Snackbar open={showServerError} autoHideDuration={10000} onClose={() => dispatch(actions.hideServerError())}>
        <Alert onClose={() => dispatch(actions.hideServerError())} severity="error">
          <AlertTitle>Server Error</AlertTitle>
          {serverError}
        </Alert>
      </Snackbar>
      <Snackbar open={showError} autoHideDuration={10000} onClose={() => dispatch(actions.hideError())}>
        <Alert onClose={() => dispatch(actions.hideError())} severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
      <MassActionResults
        shown={showSuccess}
        close={() => dispatch(actions.hideSuccess())}
        title={serverResultTitle}
        serverResult={serverResult}
      />
    </>
  );
};

export default MassActionButtons;
