import config from '~/config';
import fetch from '~/redux/fetch';

const getSurveyURL = (surveyId) => {
  return `/surveys/${surveyId}`;
}

const getSurvey = async (surveyId) => {
  const response = await fetch(`${config.apiBaseUrl}${getSurveyURL(surveyId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getSurveyStatsURL = (surveyId) => {
  return `/surveys/${surveyId}/stats`;
}

const getSurveyStats = async (surveyId) => {
  const response = await fetch(`${config.apiBaseUrl}${getSurveyStatsURL(surveyId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getClicksStoppedReasonsURL = (surveyId) => {
  return `/surveys/${surveyId}/stopped-reasons`;
}

const getClicksStoppedReasons = async (surveyId) => {
  const response = await fetch(`${config.apiBaseUrl}${getClicksStoppedReasonsURL(surveyId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getClicksPerformanceURL = (surveyId) => {
  return `/surveys/${surveyId}/clicks-performance`;
}

const getClicksPerformance = async ({ surveyId, dateFilter}) => {
  const response = await fetch(`${config.apiBaseUrl}${getClicksPerformanceURL(surveyId)}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ dateFilter }),
  });
  return response.json();
}

const getSurveyCRStatsURL = (surveyId) => {
  return `/surveys/${surveyId}/cr-stats`;
}

const getSurveyCRStats = async ({ surveyId, dateFilter }) => {
  const response = await fetch(`${config.apiBaseUrl}${getSurveyCRStatsURL(surveyId)}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ dateFilter }),
  });
  return response.json();
}

const getSurveyRevenueStatsURL = (surveyId) => {
  return `/surveys/${surveyId}/revenue-stats`;
}

const getSurveyRevenueStats = async ({ surveyId, dateFilter }) => {
  const response = await fetch(`${config.apiBaseUrl}${getSurveyRevenueStatsURL(surveyId)}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ dateFilter }),
  });
  return response.json();
}

const getPreliminaryFailedClicksURL = (surveyId) => {
  return `/surveys/${surveyId}/preliminary-failed`;
}

const getPreliminaryFailedClicks = async (surveyId) => {
  const response = await fetch(`${config.apiBaseUrl}${getPreliminaryFailedClicksURL(surveyId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getSurveyDQStatsURL = (surveyId) => {
  return `/surveys/${surveyId}/dq-stats`;
}

const getSurveyDQStats = async (surveyId) => {
  const response = await fetch(`${config.apiBaseUrl}${getSurveyDQStatsURL(surveyId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getSurveyOriginStatsURL = (surveyId) => {
  return `/surveys/${surveyId}/origin-stats`;
}

const getSurveyOriginStats = async (surveyId) => {
  const response = await fetch(`${config.apiBaseUrl}${getSurveyOriginStatsURL(surveyId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getLastClickAndLeadURL = (surveyId) => {
  return `/surveys/${surveyId}/last-click-lead`;
}

const getLastClickAndLead = async (surveyId) => {
  const response = await fetch(`${config.apiBaseUrl}${getLastClickAndLeadURL(surveyId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getSurveyQuestionsURL = (surveyId) => {
  return `/surveys/${surveyId}/questions`;
}

const getSurveyQuestions = async (surveyId) => {
  const response = await fetch(`${config.apiBaseUrl}${getSurveyQuestionsURL(surveyId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getSurveyQuotasURL = (surveyId) => {
  return `/surveys/${surveyId}/quotas`;
}

const getSurveyQuotas = async (surveyId) => {
  const response = await fetch(`${config.apiBaseUrl}${getSurveyQuotasURL(surveyId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getSurveyTargetedUsersURL = (surveyId) => {
  return `/surveys/${surveyId}/targeted-users`;
}

const getSurveyTargetedUsers = async (params) => {
  const response = await fetch(`${config.apiBaseUrl}${getSurveyTargetedUsersURL(params.surveyId)}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });
  return response.json();
}

const getSurveySsiRexQuotasAndFilters = async (params) => {
  const response = await fetch(`${config.apiBaseUrl}/surveys/${params}/ssirex/quotasandfilters`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getSurveyTolunaIpesData = async (params) => {
  const response = await fetch(`${config.apiBaseUrl}/surveys/${params}/tolunaIpes/data`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getSurveyProdegeQuotas = async (params) => {
  const response = await fetch(`${config.apiBaseUrl}/surveys/${params}/prodege/quotas`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

export default {
  getSurvey,
  getSurveyCRStats,
  getSurveyRevenueStats,
  getSurveyStats,
  getClicksStoppedReasons,
  getClicksPerformance,
  getPreliminaryFailedClicks,
  getLastClickAndLead,
  getSurveyQuestions,
  getSurveyQuotas,
  getSurveyTargetedUsers,
  getSurveyDQStats,
  getSurveyOriginStats,
  getSurveySsiRexQuotasAndFilters,
  getSurveyTolunaIpesData,
  getSurveyProdegeQuotas,
};
