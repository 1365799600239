export const actionTypes = {
  GetFeature: 'feature/get-feature',
  GetFeatureSuccess: 'feature/get-feature-success',
  GetFeatureFailure: 'feature/get-feature-failure',
  UpdateFeature: 'feature/update-feature',
  UpdateFeatureSuccess: 'feature/update-feature-success',
  UpdateFeatureFailure: 'feature/update-feature-failure',
  UpdateKey: 'feature/update-key',
  UpdateKeySuccess: 'feature/update-key-success',
  UpdateKeyFailure: 'feature/update-key-failure',
  GetAvailableParams: 'feature/get-available-params',
  GetAvailableParamsSuccess: 'feature/get-available-params-success',
  GetAvailableParamsFailure: 'feature/get-available-params-failure',
  CreateFeatureLanguage: 'feature/create-feature-language',
  CreateFeatureLanguageSuccess: 'feature/create-feature-language-success',
  CreateFeatureLanguageFailure: 'feature/create-feature-language-failure',
  UpdateFeatureLanguage: 'feature/update-feature-language',
  UpdateFeatureLanguageSuccess: 'feature/update-feature-language-success',
  UpdateFeatureLanguageFailure: 'feature/update-feature-language-failure',
  FindKeys: 'feature/find-keys',
  FindKeysSuccess: 'feature/find-keys-success',
  FindKeysFailure: 'feature/find-keys-failure',
  CreateKeyTranslation: 'feature/create-key-translation',
  CreateKeyTranslationSuccess: 'feature/create-key-translation-success',
  CreateKeyTranslationFailure: 'feature/create-key-translation-failure',
  SaveKeyScreenshot: 'feature/save-key-screenshot',
  SaveKeyScreenshotSuccess: 'feature/save-key-screenshot-success',
  SaveKeyScreenshotFailure: 'feature/save-key-screenshot-failure',
  UpdateKeyTranslation: 'feature/update-key-translation',
  UpdateKeyTranslationSuccess: 'feature/update-key-translation-success',
  UpdateKeyTranslationFailure: 'feature/update-key-translation-failure',
  RemoveKeyTranslations: 'feature/remove-key-translations',
  RemoveKeyTranslationsSuccess: 'feature/remove-key-translations-success',
  RemoveKeyTranslationsFailure: 'feature/remove-key-translations-failure',
  CleanFoundKeys: 'feature/clean-found-keys',
  SetFilterValues: 'feature/set-filter-values',
  SetSelectedLanguages: 'feature/set-selected-languages',
};

export const actions = {
  getFeature: (featureId) => ({ type: actionTypes.GetFeature, payload: featureId }),
  getFeatureSuccess: (feature) => ({ type: actionTypes.GetFeatureSuccess, payload: feature }),
  getFeatureFailure: () => ({ type: actionTypes.GetFeatureFailure }),
  updateFeature: (data) => ({ type: actionTypes.UpdateFeature, payload: data }),
  updateFeatureSuccess: (feature) => ({ type: actionTypes.UpdateFeatureSuccess, payload: feature }),
  updateFeatureFailure: () => ({ type: actionTypes.UpdateFeatureFailure }),
  updateKey: (data) => ({ type: actionTypes.UpdateKey, payload: data }),
  updateKeySuccess: (feature) => ({ type: actionTypes.UpdateKeySuccess, payload: feature }),
  updateKeyFailure: () => ({ type: actionTypes.UpdateKeyFailure }),
  getAvailableParams: () => ({ type: actionTypes.GetAvailableParams }),
  getAvailableParamsSuccess: (availableParams) =>
    ({ type: actionTypes.GetAvailableParamsSuccess, payload: availableParams }),
  getAvailableParamsFailure: () => ({ type: actionTypes.GetAvailableParamsFailure }),
  createFeatureLanguage: (data) => ({ type: actionTypes.CreateFeatureLanguage, payload: data }),
  createFeatureLanguageSuccess: (featureLanguage) => ({ type: actionTypes.CreateFeatureLanguageSuccess, payload: featureLanguage }),
  createFeatureLanguageFailure: () => ({ type: actionTypes.CreateFeatureLanguageFailure }),
  updateFeatureLanguage: (data) => ({ type: actionTypes.UpdateFeatureLanguage, payload: data }),
  updateFeatureLanguageSuccess: (featureLanguage) => ({ type: actionTypes.UpdateFeatureLanguageSuccess, payload: featureLanguage }),
  updateFeatureLanguageFailure: () => ({ type: actionTypes.UpdateFeatureLanguageFailure }),
  findKeys: (data) => ({ type: actionTypes.FindKeys, payload: data }),
  findKeysSuccess: (featureLanguage) => ({ type: actionTypes.FindKeysSuccess, payload: featureLanguage }),
  findKeysFailure: () => ({ type: actionTypes.FindKeysFailure }),
  createKeyTranslation: (data) => ({ type: actionTypes.CreateKeyTranslation, payload: data }),
  createKeyTranslationSuccess: () => ({ type: actionTypes.CreateKeyTranslationSuccess }),
  createKeyTranslationFailure: () => ({ type: actionTypes.CreateKeyTranslationFailure }),
  saveKeyScreenshot: (data) => ({ type: actionTypes.SaveKeyScreenshot, payload: data }),
  saveKeyScreenshotSuccess: () => ({ type: actionTypes.SaveKeyScreenshotSuccess }),
  saveKeyScreenshotFailure: () => ({ type: actionTypes.SaveKeyScreenshotFailure }),
  updateKeyTranslation: (data) => ({ type: actionTypes.UpdateKeyTranslation, payload: data }),
  updateKeyTranslationSuccess: () => ({ type: actionTypes.UpdateKeyTranslationSuccess }),
  updateKeyTranslationFailure: () => ({ type: actionTypes.UpdateKeyTranslationFailure }),
  removeKeyTranslations: (data) => ({ type: actionTypes.RemoveKeyTranslations, payload: data }),
  removeKeyTranslationsSuccess: () => ({ type: actionTypes.RemoveKeyTranslationsSuccess }),
  removeKeyTranslationsFailure: () => ({ type: actionTypes.RemoveKeyTranslationsFailure }),
  cleanFoundKeys: () => ({ type: actionTypes.CleanFoundKeys }),
  setFilterValues: (data) => ({ type: actionTypes.SetFilterValues, payload: data }),
  setSelectedLanguages: (data) => ({ type: actionTypes.SetSelectedLanguages, payload: data }),
};
