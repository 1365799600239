import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from '~/_metronic/layout/_core/MetronicLayout.js';
import {
  Card, CardContent
} from "@material-ui/core";

function getChartOption(layoutProps, data, categories, valueFormat) {
  const options = {
    series: [
      {
        name: '',
        data,
      },
    ],
    chart: {
      type: 'area',
      height: 200,
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    labels: categories,
    yaxis: {
      opposite: true
    },
    legend: {
      horizontalAlign: 'right'
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return `${val}${valueFormat}`;
        },
      },
    },
  }
  return options;
}

const Chart = ({ className, baseColor, data, title, children, categories, id, valueFormat }) => {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${baseColor}`
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${baseColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, baseColor]);

  const sum = data && data.length ? data.reduce((accumulator, currentValue) => (accumulator + Number(currentValue)), 0) : 0;

  useEffect(() => {
    const element = document.getElementById(id);

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps, data, categories, valueFormat);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, data, categories, id, sum, valueFormat]);

  return (
    <Card className={className}>
      <CardContent>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className={`text-${baseColor}`}>
            {title}
          </div>
          {sum && sum !== '-Infinity' ? (<div className={`px-3 py-1 rounded bg-light-${baseColor} text-${baseColor}`}>
            {`${sum.toFixed(2)}${valueFormat}`}
            </div>) : ''
          }
        </div>
        <div className="my-3 border border-bottom-light-secondary h-1px w-100" />
        {children}
        <div
          id={id}
          className="card-rounded-bottom"
          style={{ height: "210px", paddingTop: '10px' }}
        />
      </CardContent>
    </Card>
  );
}

export default Chart;
