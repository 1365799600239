export const actionTypes = {
  GetProducts: 'products/get-products',
  GetProductsSuccess: 'products/get-products-success',
  GetProductsFailure: 'products/get-products-failure',
  CreateProduct: 'products/create-product',
  CreateProductSuccess: 'products/create-product-success',
  CreateProductFailure: 'products/create-product-failure',
  UpdateProduct: 'products/update-product',
  UpdateProductSuccess: 'products/update-product-success',
  UpdateProductFailure: 'products/update-product-failure',
  GetProductTranslation: 'products/get-product-translation',
  GetProductTranslationSuccess: 'products/get-product-translation-success',
  GetProductTranslationFailure: 'products/get-product-translation-failure',
  CreateProductTranslation: 'products/create-product-translation',
  CreateProductTranslationSuccess: 'products/create-product-translation-success',
  CreateProductTranslationFailure: 'products/create-product-translation-failure',
  CleanProductTranslation: 'products/clean-product-translation',
};

export const actions = {
  getProducts: () => ({ type: actionTypes.GetProducts }),
  getProductsSuccess: (products) => ({ type: actionTypes.GetProductsSuccess, payload: products }),
  getProductsFailure: () => ({ type: actionTypes.GetProductsFailure }),
  createProduct: (productName) => ({ type: actionTypes.CreateProduct, payload: productName }),
  createProductSuccess: (product) => ({ type: actionTypes.CreateProductSuccess, payload: product }),
  createProductFailure: () => ({ type: actionTypes.CreateProductFailure }),
  updateProduct: (data) => ({ type: actionTypes.UpdateProduct, payload: data }),
  updateProductSuccess: (product) => ({ type: actionTypes.UpdateProductSuccess, payload: product }),
  updateProductFailure: () => ({ type: actionTypes.UpdateProductFailure }),
  getProductTranslation: (productId) => ({ type: actionTypes.GetProductTranslation, payload: productId }),
  getProductTranslationSuccess: (translation) => ({ type: actionTypes.GetProductTranslationSuccess, payload: translation }),
  getProductTranslationFailure: () => ({ type: actionTypes.GetProductTranslationFailure }),
  createProductTranslation: (productId) => ({ type: actionTypes.CreateProductTranslation, payload: productId }),
  createProductTranslationSuccess: (translation) => ({ type: actionTypes.CreateProductTranslationSuccess, payload: translation }),
  createProductTranslationFailure: () => ({ type: actionTypes.CreateProductTranslationFailure }),
  cleanProductTranslation: () => ({ type: actions.CleanProductTranslation })
};
