import { actionTypes } from '~/app/modules/PPChecksRewardsBalancePopup/actions';

const initialState = {
  paypalStats: {},
  providersSettings: [],
  payPalStatsIsLoading: false,
  rewardsIsLoading: false,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FetchPaypalStats:
      return {
        ...state,
        payPalStatsIsLoading: true,
      };

    case actionTypes.FetchPaypalStatsFailure:
      return {
        ...state,
        payPalStatsIsLoading: false,
      };

    case actionTypes.FetchPaypalStatsSuccess:
      return {
        ...state,
        payPalStatsIsLoading: false,
        paypalStats: action.payload,
      };

    case actionTypes.FetchRewardsProvidersBalanceSettings:
      return {
        ...state,
        rewardsIsLoading: true,
      };

    case actionTypes.FetchRewardsProvidersBalanceSettingsFailure:
      return {
        ...state,
        rewardsIsLoading: false,
      };

    case actionTypes.FetchRewardsProvidersBalanceSettingsSuccess:
      return {
        ...state,
        rewardsIsLoading: false,
        providersSettings: action.payload,
      };

    default:
      return state;
  }
};
