import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Card, CardContent, Button } from '@material-ui/core';
import { actions } from '~/app/modules/Reviews/actions';
import ReviewsReport from "./Reviews.report";
import { Spinner } from "react-bootstrap";
import CreateOrEditReviewModal from "./CreateOrEditReviewModal";

const Reviews = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.userReviews.isLoading);
  const [shown, setShown] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    dispatch(actions.getAllReviews());
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddReview = () => {
    if (!isLoading) {
      setSelectedRow({});
      setShown(true);
    }
  };

  return (
    <>
      <Card>
        <CardContent className="border text-dark border-light-dark rounded">
          <div className="d-flex justify-content-between align-items-start mb-10">
            <h3>Reviews</h3>
            <div className="d-flex flex-column justify-content-end">
              <Button
                color="secondary"
                variant="contained"
                // TODO
                onClick={handleAddReview}
              >
                {isLoading
                  ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )
                  : 'Add Review'}
              </Button>
            </div>
          </div>
          <ReviewsReport setShown={setShown} setSelectedRow={setSelectedRow} />
        </CardContent>
      </Card>
      <CreateOrEditReviewModal shown={shown} setShown={setShown} initialValues={selectedRow} />
    </>
  )
};

export default Reviews;
