import { select, call, put, takeLatest } from "redux-saga/effects";

import api from '~/app/modules/AtomsManagement/api';
import { actions, actionTypes } from '~/app/modules/AtomsManagement/actions';

function* fetchAtomSettings() {
  try {
    const results = yield call(api.fetchAtomSettings);
    yield put(actions.fetchAtomSettingsSuccess(results));
  } catch (error) {
    yield put(actions.fetchAtomSettingsFailure(error.message));
  }
}

function* saveAtomSettings() {
  try {
    const defaultSettings = yield select((state) => state.atomsManagement.atomSettings['default']);
    const currentForm = yield select((state) => state.atomsManagement.currentForm);
    const key = yield select((state) => state.atomsManagement.currentFormKey);

    let settingsToSend;
    if (key === 'default') {
      settingsToSend = currentForm;
    } else {
      settingsToSend = {};
      Object.keys(defaultSettings).forEach((name) => {
        if (currentForm[name] !== defaultSettings[name]) {
          settingsToSend[name] = currentForm[name];
        }
      });
    }

    const params = {
      key,
      settings: settingsToSend,
    }

    const results = yield call(api.updateAtomSettings, params);
    yield put(actions.saveAtomSettingsSuccess(results));
  } catch (error) {
    console.log(error);
    yield put(actions.saveAtomSettingsFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.FetchAtomSettings, fetchAtomSettings),
  takeLatest(actionTypes.SaveCurrentAtomSettings, saveAtomSettings),
];

