export const actionTypes = {
  GetSharedParams: 'shared-view/get-shared-params',
  GetSharedParamsSuccess: 'shared-view/get-shared-params-success',
  SetLoadedParams: 'shared-view/set-loaded-params',
}

export const actions = {
  getSharedParams: (linkId) => ({type: actionTypes.GetSharedParams, payload: linkId}),
  getSharedParamsSuccess: (params) => ({type: actionTypes.GetSharedParamsSuccess, payload: params}),
  setLoadedParams: (loaded) => ({type: actionTypes.SetLoadedParams, payload: loaded}),
}
