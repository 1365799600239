import config from '~/config';
import fetch from '~/redux/fetch';

const FETCH_SETTINGS_PATH = '/atom-settings';
const UPDATE_SETTINGS_PATH = '/atom-settings';

const fetchAtomSettings = async () => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_SETTINGS_PATH}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  return response.json();
};

const updateAtomSettings = async ({ key, settings }) => {
  const response = await fetch(`${config.apiBaseUrl}${UPDATE_SETTINGS_PATH}/${key}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(settings),
  });

  if (response.status !== 200) {
    throw new Error((await response.json()).error);
  }

  return response.json();
};

export default {
  fetchAtomSettings,
  updateAtomSettings,
};
