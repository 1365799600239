import config from '~/config';
import fetch from '~/redux/fetch';

const getRewardsUrl = '/rewards';

const getRewards = async () => {
  const response = await fetch(`${config.apiBaseUrl}${getRewardsUrl}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getFundsHistoryUrl = (provider) => `/funds-history/${provider}`;

const getFundsHistory = async (provider) => {
  const response = await fetch(`${config.apiBaseUrl}${getFundsHistoryUrl(provider)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getCurrentRewardUrl = (id) => `/rewards/${id}`;

const getCurrentReward = async (id) => {
  const response = await fetch(`${config.apiBaseUrl}${getCurrentRewardUrl(id)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getRewardsTranslationsUrl = (id) => `/i18n?productId=${id}`;

const getRewardsTranslations = async (id) => {
  const response = await fetch(`${config.apiBaseUrl}${getRewardsTranslationsUrl(id)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const updateCurrentRewardUrl = (id) => `/rewards/${id}`;

const updateCurrentReward = async (rewardData) => {
  const response = await fetch(`${config.apiBaseUrl}${updateCurrentRewardUrl(rewardData._id)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ rewardData }),
  });
  return response.json();
}

const getRewardProvidersUrl = '/reward-balances/providers';

const getRewardProviders = async () => {
  const response = await fetch(`${config.apiBaseUrl}${getRewardProvidersUrl}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const FIND_KEYS_URL = '/translations/keys';

const findKeys = async ({ key, text, keysForLinkKey, keyId }) => {
  const response = await fetch(`${config.apiBaseUrl}${FIND_KEYS_URL}?key=${key}&text=${text}&productId=rewardcards`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

export default {
  getRewards,
  getCurrentReward,
  getRewardsTranslations,
  updateCurrentReward,
  getFundsHistory,
  getRewardProviders,
  findKeys,
};
