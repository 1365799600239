import { call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/Translations/components/Feature/api';
import { actions, actionTypes } from '~/app/modules/Translations/components/Feature/actions';

function* getFeature(action) {
  try {
    const results = yield call(api.getFeature, action.payload);
    yield put(actions.getFeatureSuccess(results));
  } catch (error) {
    yield put(actions.getFeatureFailure(error.message));
  }
}

function* updateFeature(action) {
  try {
    const { payload } = action;
    const results = yield call(api.updateFeature, payload);
    yield put(actions.updateFeatureSuccess(results));
    yield put(actions.getFeature(payload.featureId));
  } catch (error) {
    yield put(actions.updateFeatureFailure(error.message));
  }
}

function* getAvailableParams() {
  try {
    const results = yield call(api.getAvailableParams);
    yield put(actions.getAvailableParamsSuccess(results));
  } catch (error) {
    yield put(actions.getAvailableParamsFailure(error.message));
  }
}

function* createFeatureLanguage(action) {
  try {
    const { payload } = action;
    const results = yield call(api.createFeatureLanguage, payload);
    yield put(actions.createFeatureLanguageSuccess(results));
    yield put(actions.getFeature(payload.featureId));
  } catch (error) {
    yield put(actions.createFeatureLanguageFailure(error.message));
  }
}

function* updateFeatureLanguage(action) {
  try {
    const { payload } = action;
    const results = yield call(api.updateFeatureLanguage, payload);
    yield put(actions.updateFeatureLanguageSuccess(results));
    yield put(actions.getFeature(payload.featureId));
  } catch (error) {
    yield put(actions.updateFeatureLanguageFailure(error.message));
  }
}

function* findKeys(action) {
  try {
    const { payload } = action;
    const results = yield call(api.findKeys, payload);
    yield put(actions.findKeysSuccess(results));
  } catch (error) {
    yield put(actions.findKeysFailure(error.message));
  }
}

function* createKeyTranslation(action) {
  try {
    const { payload } = action;
    const results = yield call(api.createKeyTranslation, payload);
    yield put(actions.createKeyTranslationSuccess(results));
    yield put(actions.getFeature(payload.featureId));
  } catch (error) {
    yield put(actions.createKeyTranslationFailure(error.message));
  }
}

function* saveKeyScreenshot(action) {
  try {
    const { payload } = action;
    const results = yield call(api.saveKeyScreenshot, payload);
    yield put(actions.saveKeyScreenshotSuccess(results));
    yield put(actions.getFeature(payload.featureId));
  } catch (error) {
    yield put(actions.saveKeyScreenshotFailure(error.message));
  }
}

function* updateKeyTranslation(action) {
  try {
    const { payload } = action;
    const results = yield call(api.updateKeyTranslation, payload);
    yield put(actions.updateKeyTranslationSuccess(results));
    yield put(actions.getFeature(payload.featureId));
  } catch (error) {
    yield put(actions.updateKeyTranslationFailure(error.message));
  }
}

function* removeKeyTranslations(action) {
  try {
    const { payload } = action;
    const results = yield call(api.removeKeyTranslations, payload);
    yield put(actions.removeKeyTranslationsSuccess(results));
    yield put(actions.getFeature(payload.featureId));
  } catch (error) {
    yield put(actions.removeKeyTranslationsFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.GetFeature, getFeature),
  takeLatest(actionTypes.UpdateFeature, updateFeature),
  takeLatest(actionTypes.GetAvailableParams, getAvailableParams),
  takeLatest(actionTypes.CreateFeatureLanguage, createFeatureLanguage),
  takeLatest(actionTypes.UpdateFeatureLanguage, updateFeatureLanguage),
  takeLatest(actionTypes.FindKeys, findKeys),
  takeLatest(actionTypes.CreateKeyTranslation, createKeyTranslation),
  takeLatest(actionTypes.SaveKeyScreenshot, saveKeyScreenshot),
  takeLatest(actionTypes.UpdateKeyTranslation, updateKeyTranslation),
  takeLatest(actionTypes.RemoveKeyTranslations, removeKeyTranslations),
];