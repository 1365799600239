import { call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/Survey/api';
import { actions, actionTypes } from '~/app/modules/Survey/actions';

function* getSurvey(action) {
  try {
    const results = yield call(api.getSurvey, action.payload);
    yield put(actions.getSurveySuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getSurveyCRStats(action) {
  try {
    const results = yield call(api.getSurveyCRStats, action.payload);
    yield put(actions.getSurveyCRStatsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getSurveyRevenueStats(action) {
  try {
    const results = yield call(api.getSurveyRevenueStats, action.payload);
    yield put(actions.getSurveyRevenueStatsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getSurveyStats(action) {
  try {
    const results = yield call(api.getSurveyStats, action.payload);
    yield put(actions.getSurveyStatsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getClicksStoppedReasons(action) {
  try {
    const results = yield call(api.getClicksStoppedReasons, action.payload);
    yield put(actions.getClicksStoppedReasonsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getClicksPerformance(action) {
  try {
    const results = yield call(api.getClicksPerformance, action.payload);
    yield put(actions.getClicksPerformanceSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getPreliminaryFailedClicks(action) {
  try {
    const results = yield call(api.getPreliminaryFailedClicks, action.payload);
    yield put(actions.getPreliminaryFailedClicksSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getSurveyDQStats(action) {
  try {
    const results = yield call(api.getSurveyDQStats, action.payload);
    yield put(actions.getSurveyDQStatsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getSurveyOriginStats(action) {
  try {
    const results = yield call(api.getSurveyOriginStats, action.payload);
    yield put(actions.getSurveyOriginStatsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getLastClickAndLead(action) {
  try {
    const results = yield call(api.getLastClickAndLead, action.payload);
    yield put(actions.getLastClickAndLeadSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getSurveyQuestions(action) {
  try {
    const results = yield call(api.getSurveyQuestions, action.payload);
    yield put(actions.getSurveyQuestionsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getSurveyQuotas(action) {
  try {
    const results = yield call(api.getSurveyQuotas, action.payload);
    yield put(actions.getSurveyQuotasSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

function* getSurveyTargetedUsers(action) {
  try {
    const results = yield call(api.getSurveyTargetedUsers, action.payload);
    yield put(actions.getSurveyTargetedUsersSuccess(results));
  } catch (error) {
    console.error(error);
    yield put(actions.getSurveyTargetedUsersFailed(error));
  }
}

function* getSurveySsiRexQuotasAndFilters(action) {
  try {
    const results = yield call(api.getSurveySsiRexQuotasAndFilters, action.payload);
    yield put(actions.getSurveySsiRexQuotasAndFiltersSuccess(results));
  } catch (error) {
    console.error(error);
    yield put(actions.getSurveySsiRexQuotasAndFiltersFailed(error));
  }
}

function* getSurveyTolunaIpesData(action) {
  try {
    const results = yield call(api.getSurveyTolunaIpesData, action.payload);
    yield put(actions.getSurveyTolunaIpesDataSuccess(results));
  } catch (error) {
    console.error(error);
    yield put(actions.getSurveyTolunaIpesDataFailed(error));
  }
}

function* getSurveyProdegeQuotas(action) {
  try {
    const results = yield call(api.getSurveyProdegeQuotas, action.payload);
    yield put(actions.getSurveyProdegeQuotasSuccess(results));
  } catch (error) {
    console.error(error);
    yield put(actions.getSurveyProdegeQuotasFailed(error));
  }
}

export default [
  takeLatest(actionTypes.GetSurvey, getSurvey),
  takeLatest(actionTypes.GetSurveyCRStats, getSurveyCRStats),
  takeLatest(actionTypes.GetSurveyRevenueStats, getSurveyRevenueStats),
  takeLatest(actionTypes.GetSurveyStats, getSurveyStats),
  takeLatest(actionTypes.GetClicksStoppedReasons, getClicksStoppedReasons),
  takeLatest(actionTypes.GetClicksPerformance, getClicksPerformance),
  takeLatest(actionTypes.GetPreliminaryFailedClicks, getPreliminaryFailedClicks),
  takeLatest(actionTypes.GetLastClickAndLead, getLastClickAndLead),
  takeLatest(actionTypes.GetSurveyQuestions, getSurveyQuestions),
  takeLatest(actionTypes.GetSurveyQuotas, getSurveyQuotas),
  takeLatest(actionTypes.GetSurveyTargetedUsers, getSurveyTargetedUsers),
  takeLatest(actionTypes.GetSurveyDQStats, getSurveyDQStats),
  takeLatest(actionTypes.GetSurveyOriginStats, getSurveyOriginStats),
  takeLatest(actionTypes.GetSurveySsiRexQuotasAndFilters, getSurveySsiRexQuotasAndFilters),
  takeLatest(actionTypes.GetSurveyTolunaIpesData, getSurveyTolunaIpesData),
  takeLatest(actionTypes.GetSurveyProdegeQuotas, getSurveyProdegeQuotas),
];

