import React, {useEffect, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {customFilter, selectFilter} from 'react-bootstrap-table2-filter';
import { actions } from '~/app/modules/Translations/components/Feature/actions';
import { sortCaret } from '~/_metronic/_helpers';
import { useParams } from 'react-router-dom';
import { injectIntl } from "react-intl";
import {
  textFilterOnBlurHelper,
} from '~/app/helpers';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ProgressBar from "../CustomProgressBar";
import {
  Button,
  Card,
  CardContent,
} from "@material-ui/core";
import StatusField from "../StatusField";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "~/_metronic/_helpers";
import {
  PhotoAlbum, ThumbUpAltOutlined, ThumbUpAlt, CheckBox, CheckBoxOutlined
} from "@material-ui/icons";
import FeatureAndKeysModal from "../FeatureAndKeysModal";
import { newDateFormatLong, newDateFormatInUTC } from "~/app/helpers";
import { Input } from "@material-ui/core";
import CreateFeatureModal from "../CreateFeatureModal";
import { DoubleArrow } from '@material-ui/icons';
import { sizePerPageRenderer } from '~/app/helpers';
import LanguagesModal from "~/app/modules/Translations/components/Feature/LanguagesModal";
import enums from "~/app/helpers/enums";

const sortableColumn = {
  sort: true, sortCaret,
};

const renderNameField = (row) => {
  return (
    <div className="d-flex">
      {row.key}
    </div>
  );
};

const statusOptions = [ ...Object.values(enums.projectStatuses).map((projectStatus) => (
  { value: projectStatus, label: projectStatus })),
  { value: '', label: 'All' },
];

const renderActions = ({ row, setModalValues, setShown, setModalType, setModalText }) => {
  const handleEditClick = (e) => {
    e.stopPropagation();
    setModalType('confirmEditing');
    setModalText(`Are you sure want to change the TEXT?
      This will effect the translations of this key of all
      languages.
    `);
    setShown(true);
  };

  const handleRemoveClick = (e) => {
    e.stopPropagation();
    setModalValues(row);
    setModalType('confirmRemoving');
    setModalText(`Are you sure want to delete the ${row?.key} ?`);
    setShown(true);
  };

  return (
    <div>
      <span onClick={handleEditClick} className="svg-icon svg-icon-primary h-20 mr-4">
        <SVG
          src={toAbsoluteUrl('/media/svg/icons/General/Edit.svg')}
        />
      </span>
      <span onClick={handleRemoveClick} className="svg-icon svg-icon-primary h-20">
        <SVG
          src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')}
        />
      </span>
    </div>
  );
};

const renderScreenshot = (row, setShown, setModalType, setModalValues) => {
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setModalValues({ keyId: row._id, screenshot: row?.screenshot, text: row.text });
    setShown(true);
    setModalType('screenshot');
  }

  return (
    <div onClick={handleClick}>
      <PhotoAlbum className={row?.screenshot ? "text-primary" : 'text-muted'} />
    </div>
  )
}

const columns = (setShown, setModalType, setModalText, setModalValues, filter) => [{
  ...sortableColumn,
  dataField: 'key', text: 'Key Name',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }, defaultValue: filter?.key || '' }),
  formatter: (_cell, row) => renderNameField(row),
  style: { width: '300px', minWidth: '300px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'text', text: 'Text',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "300px" }, defaultValue: filter?.text || '' }),
  style: { width: '400px', minWidth: '400px', textAlign: 'left' },
}, {
  dataField: 'screenshot', text: 'Screenshot',
  formatter: (screenshot, row) => renderScreenshot(row, setShown, setModalType, setModalValues),
  style: { width: '100px', minWidth: '100px', textAlign: 'left' },
}, {
  dataField: 'updatedAt', text: 'Last Updated',
  formatter: (updatedAt) => newDateFormatLong(updatedAt, '(PST)'),
  style: { width: '250px', minWidth: '250px', textAlign: 'left' },
}, {
  dataField: 'progress', text: 'Overall Progress',
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
  formatter: (progress, row) => (<ProgressBar percent={row.progress} approvedPercent={row.approvedProgress || 0} />),
}, {
  ...sortableColumn,
  dataField: 'status', text: 'Status',
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '160px' },
    options: statusOptions,
  }),
  formatter: (status) => (<StatusField status={status} />)
}, {
  dataField: 'actions', text: 'Actions',
  style: { width: '120px', textAlign: 'left' },
  formatter: (_cell, row) => renderActions({ row, setModalValues, setShown, setModalType, setModalText }),
}];

const TopCardForFeature = ({ feature, setShown, setModalType, setModalValues, user, setShownEditFeatureModal }) => {
  const [showMore, setShowMore] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'transitions-popper' : undefined;

  const handleEditFeature = () => {
    setShownEditFeatureModal(true);
  }

  const handleClickOnAddLanguage = () => {
    setShown(true);
    setModalType('addLanguage');
  };

  const handleClickEditLanguage = (values) => () => {
    if (!user?.role) {
      setShown(true);
      setModalType('editLanguage');
      setModalValues(values);
    }
  };

  const handleClickOnDueDate = () => {
    if (!user?.role) {
      setModalType('setDueDate');
      setModalValues(feature);
      setShown(true);
    }
  }

  return (
    <Card className="mb-3">
      <CardContent className="border text-dark border-light-dark flex-column rounded d-flex align-item-center min-h-40px">
        <div className="pl-2 d-flex justify-content-between align-items-start w-100">
          <h3>
            Overview
          </h3>
          {(!user?.role) && (
            <Button
              color="primary"
              variant="contained"
              className="text-white bg-primary"
              onClick={handleClickOnAddLanguage}
            >
              Add Language
            </Button>
          )}
        </div>
        <div className="d-flex pl-2">
          <div className="d-flex min-w-300px mr-5">
            <div className="d-flex flex-column mr-8">
              <div className="font-size-h5">
                {feature?.name}
                {feature?.name && (!user?.role) && (
                  <span onClick={handleEditFeature} className="svg-icon svg-icon-primary h-20 ml-3">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/General/Edit.svg')}
                  />
                </span>)}
              </div>
              <div className="font-size-sm">
                {feature?.productName}
              </div>
            </div>
            <div
              className="min-w-120px min-h-50px max-h-70px d-flex flex-column rounded d-flex align-items-center justify-content-center bg-light-primary text-primary cursor-pointer"
              onClick={handleClickOnDueDate}
            >
              Due Date:
              <div>{feature.dueDate ? newDateFormatInUTC(feature.dueDate) : ''}</div>
            </div>
          </div>
          <div className="d-flex max-w-700px w-700px flex-wrap min-w-650px mr-3">
            {feature?.featureLanguages?.length && feature.featureLanguages.map((featureLanguage,index) => {
              if (showMore || (!showMore && index < 6)) {
                return (
                  <div
                    key={index}
                    className="d-flex min-w-200px align-items-center mr-3 mb-1 cursor-pointer"
                    onClick={handleClickEditLanguage(featureLanguage)}
                  >
                    <div className="mr-2 d-flex flex-column">
                      <div className="font-size-h6 mb-0 text-uppercase">{featureLanguage?.language}</div>
                      <div className="font-size-sm text-muted">
                        {featureLanguage?.translator}
                      </div>
                    </div>
                    <div className="ml-3 w-120px">
                      <ProgressBar
                        percent={featureLanguage?.progress}
                        approvedPercent={featureLanguage?.approvedProgress} />
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
          {!user?.role && (
            <div className="d-flex flex-column min-w-200px ml-auto mt-2">
              {/*<Button*/}
              {/*  color="primary"*/}
              {/*  variant="contained"*/}
              {/*  onClick={handleClick}*/}
              {/*  className="bg-primary text-white ml-auto"*/}
              {/*>*/}
              {/*  Export{' '}{open ? <ExpandLess /> : <ExpandMore />}*/}
              {/*</Button>*/}
              {feature.featureLanguages?.length > 6 && (<div
                onClick={() => {setShowMore(!showMore)}}
                className="d-flex mt-auto text-primary border-bottom border-primary cursor-pointer mr-auto"
              >
                {showMore ? 'SHOW LESS' : 'SHOW MORE'}
              </div>)}
              {/*<Popper id={id} open={open} anchorEl={anchorEl} transition>*/}
              {/*  {({ TransitionProps }) => (*/}
              {/*    <Fade {...TransitionProps} timeout={350}>*/}
              {/*      <div className="bg-white border rounded py-4 pl-4 pr-6">*/}
              {/*        <div onClick={() => setAnchorEl(null)} className="mb-4 cursor-pointer">Export to CSV</div>*/}
              {/*        <div onClick={() => setAnchorEl(null)} className="mb-4 cursor-pointer">Export to JSON</div>*/}
              {/*        <div onClick={() => setAnchorEl(null)} className="cursor-pointer">Export to ARB</div>*/}
              {/*      </div>*/}
              {/*    </Fade>*/}
              {/*  )}*/}
              {/*</Popper>*/}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

const renderKeyFieldActions = (keyTranslation, dispatch, params, user, setModalType, setShown) => {
  const handleChangeStatus = (status) => () => {
    if (keyTranslation?.text) {
      dispatch(actions.updateKeyTranslation({ keyTranslationId: keyTranslation._id, status, featureId: params?.featureId }));
    } else {
      setModalType('emptyTranslationConfirm');
      setShown(true);
    }
  };

  switch (keyTranslation.status) {
    case enums.projectStatuses.PENDING_APPROVAL:
      return (
        <div className="d-flex min-w-60px justify-content-between">
          <ThumbUpAlt
            className="cursor-pointer text-success"
            onClick={handleChangeStatus(enums.projectStatuses.IN_PROGRESS)}
          />
          {(user?.role !== enums.translatorRoles.translator) && (
            <CheckBoxOutlined
              className="cursor-pointer text-success"
              onClick={handleChangeStatus(enums.projectStatuses.VERIFIED)}
            />
          )}
        </div>
      );
    case enums.projectStatuses.VERIFIED:
      return (
        <div className="d-flex min-w-60px justify-content-between">
          <ThumbUpAlt
            className="cursor-pointer text-success"
            onClick={handleChangeStatus(enums.projectStatuses.IN_PROGRESS)}
          />
          {(user?.role !== enums.translatorRoles.translator) && (
            <CheckBox
              className="cursor-pointer text-success"
              onClick={handleChangeStatus(enums.projectStatuses.IN_PROGRESS)}
            />
          )}
        </div>
      );

    default:
      return (
        <div className="d-flex min-w-60px justify-content-between">
          <ThumbUpAltOutlined
            className="cursor-pointer text-success"
            onClick={handleChangeStatus(enums.projectStatuses.PENDING_APPROVAL)}
          />
          {(user?.role !== enums.translatorRoles.translator) && (
            <CheckBoxOutlined
              className="cursor-pointer text-success"
              onClick={handleChangeStatus(enums.projectStatuses.VERIFIED)}
            />
          )}
        </div>
      );
  }
}

const InputWithSaveForLanguageKey = ({ keyTranslation }) => {
  const [value, setValue] = useState(keyTranslation?.text || '');
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const handleChangeTextValue = (e) => {
    setValue(e.target.value || '');
  }

  const handleChangeEditMode = () => {
    if (!editMode) {
      setEditMode(true);
    }
  };

  const handleSaveButtonClick = () => {
    dispatch(actions.updateKeyTranslation({
      text: value, keyTranslationId: keyTranslation._id, featureId: params?.featureId,
    }));
    setEditMode(false);
  }

  if (!editMode) {
    return (
      <div
        onClick={handleChangeEditMode}
        className="ml-3 min-w-700px w-700px min-h-40px d-flex align-items-center border border-dark p-1"
      >
        {value}
      </div>
    )
  }

  return (
    <div
      className="ml-3 min-w-700px w-700px min-h-40px d-flex align-items-center border border-dark p-1"
    >
      <Input value={value} className="min-w-600px min-h-40px" onChange={handleChangeTextValue} />
      {(value !== keyTranslation?.text) && (<Button
        color="primary"
        variant="contained"
        onClick={handleSaveButtonClick}
        className="bg-primary text-white ml-auto"
      >
        Save
      </Button>
      )}
    </div>
  )
};

const Feature = ({ intl }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const report = useSelector(state => state.feature.keysStats, shallowEqual);
  const feature = useSelector(state => state.feature.feature);
  const products = useSelector(state => state.feature.products);
  const languages = useSelector(state => state.feature.languages);
  const filter = useSelector(state => state.feature.filter);
  const [dataWithExpanded, setDataWithExpanded] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [shown, setShown] = useState(false);
  const [shownEditFeatureModal, setShownEditFeatureModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalValues, setModalValues] = useState({});
  const [modalText, setModalText] = useState('');
  const [showLanguagesModal, setShowLanguagesModal] = useState(false);
  const params = useParams();
  const selectedLanguages = useSelector((state) => state.feature.selectedLanguages);

  useEffect(() => {
    const newData = report.map((row) => ({
      ...row,
      isExpand: expanded.includes(row._id),
    }));
    setDataWithExpanded(newData);
  }, [setDataWithExpanded, report]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    dispatch(actions.getFeature(params.featureId));
    dispatch(actions.getAvailableParams());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnExpand = (row, isExpand) => {
    if (!row.isExpand) {
      const newData = dataWithExpanded.map((dataRow) => {
        if (row._id === dataRow._id) {
          return {...dataRow, isExpand};
        }
        return dataRow;
      })
      setDataWithExpanded(newData);
      setExpanded([...expanded, row._id]);
    } else {
      const newData = dataWithExpanded.map((dataRow) => {
        if (row._id === dataRow._id && row.isExpand) {
          const newDataRow = {...dataRow};
          delete newDataRow.isExpand;
          return newDataRow;
        }
        return dataRow;
      })
      setDataWithExpanded(newData);
      setExpanded(expanded.filter((_id) => (row._id !== _id)));
    }
  }

  const expandRow = {
    renderer: row => {
      return (
        <div className="my-2">
          <div className="d-flex">
            <h4 className="min-w-180px">Language</h4>
            <h4 className="ml-3 min-w-700px">Translation</h4>
            <h4 className="ml-20 min-w-180px">Translator</h4>
            <h4 className="ml-20 min-w-180px">Status</h4>
          </div>
          {row?.keyTranslations.map((keyTranslation) => {
            if (!selectedLanguages?.includes(keyTranslation.languageId) && selectedLanguages?.length) {
              return null;
            }
            return (
              <div key={keyTranslation._id} className="min-h-40px border-top d-flex w-100 pb-2">
                <div className="min-w-180px w-180px h-40px d-flex align-items-center">{keyTranslation.language}</div>
                <InputWithSaveForLanguageKey keyTranslation={keyTranslation} />
                <div className="ml-20 min-w-180px w-180px h-40px text-muted d-flex align-items-center">
                  {keyTranslation?.translator}
                </div>
                <div className="ml-20 min-w-180px w-180px h-40px d-flex align-items-center">
                  {renderKeyFieldActions(keyTranslation, dispatch, params, user, setModalType, setShown)}
                </div>
              </div>
            )}
          )}
        </div>
      );
    },
    expanded,
    onExpand: handleOnExpand,
    onExpandAll: (isExpandAll) => {
      if(isExpandAll) {
        setExpanded(report.map((row) => row._id));
      } else {
        setExpanded([]);
      }
    },
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (<span className="text-primary min-w-15px">▼</span>);
      } else {
        return (<span className="text-primary min-w-15px">▶</span>);
      }
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return (<DoubleArrow className="text-primary" style={{transform: 'rotate(90deg)'}}/>);
      } else {
        return (<DoubleArrow className="text-primary" style={{transform: 'rotate(-90deg)'}}/>);
      }
    },
  };

  const rowStyle = (row) => {
    if (row.isExpand) {
      return { cursor: 'pointer', background: '#f1f1f1' };
    }
    return { cursor: 'pointer' };
  };

  const reportColumns = columns(setShown, setModalType, setModalText, setModalValues, filter);
  const tableColumns = user?.role ? reportColumns.filter((col, index) => ((index + 1) < reportColumns.length))
    : reportColumns;

  const handleLanguagesClick = () => {
    setShowLanguagesModal(true);
  };

  const handleAddKeyClick = () => {
    setShown(true);
    setModalType('addKey');
  };

  return (
    <div>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center min-h-40px">
          <h3 className="pl-2 d-flex align-items-center">
            Features <span className="text-muted font-size-h6 ml-4 mt-1">{feature?.name}</span>
          </h3>
        </CardContent>
      </Card>
      <TopCardForFeature
        feature={feature}
        setShown={setShown}
        setModalType={setModalType}
        setModalValues={setModalValues}
        setShownEditFeatureModal={setShownEditFeatureModal}
        user={user}
      />
      <div className="d-flex justify-content-center flex-column">
        <Card>
          <CardContent className="border text-dark border-light-dark rounded pb-80">
            <div className="d-flex justify-content-between align-items-start">
              <h3 className="pl-2 d-flex">
                Keys
              </h3>
              {(!user?.role) && (
                <div className="w-100 d-flex justify-content-end">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleLanguagesClick}
                    className="bg-light-primary text-primary"
                  >
                    Languages
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleAddKeyClick}
                    className="bg-primary text-white ml-3"
                  >
                    Add Key
                  </Button>
                </div>
              )}
            </div>
            {dataWithExpanded?.length && (
              <div className="d-flex justify-content-end mb-4">Number of Keys: {dataWithExpanded.length}</div>
            )}
            <BootstrapTable
              classes="table table-head-custom table-vertical-center overflow-hidden"
              headerClasses="table table-vertical-top"
              wrapperClasses="rounded my-2"
              bootstrap4
              responsive
              keyField="_id"
              columns={tableColumns}
              data={dataWithExpanded}
              filter={ filterFactory() }
              defaultSorted={[{
                dataField: 'key',
                order: enums.sortDirections.desc,
              }]}
              rowStyle={ rowStyle }
              expandRow={ expandRow }
              alwaysShowAllBtns
              pagination={paginationFactory({
                sizePerPage: 100,
                sizePerPageRenderer,
                sizePerPageList:[
                  { text: '10', value: 10,},
                  { text: '20', value: 20, },
                  { text: '50', value: 50, },
                  { text: '100', value: 100, },
                  { text: '500', value: 500, }
                ],
              })}
            />
          </CardContent>
        </Card>
      </div>
      {(products?.length && languages?.length && feature?._id) && (
        <CreateFeatureModal
          setShown={setShownEditFeatureModal}
          shown={shownEditFeatureModal}
          intl={intl}
          actions={actions}
          stateKey={'feature'}
          values={feature}
        />
      )}
      <FeatureAndKeysModal
        feature={feature}
        shown={shown}
        setShown={setShown}
        type={modalType}
        values={modalValues}
        text={modalText}
        actions={actions}
        user={user}
      />
      <LanguagesModal setShown={setShowLanguagesModal} shown={showLanguagesModal} />
    </div>
  );
};

export default injectIntl(Feature);
