import React from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  Container, Card, CardContent, Grid,
  Button, Box,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { actions } from '~/app/modules/HighReversalRateBans/actions';
import { sortCaret } from '~/_metronic/_helpers';
import { Pagination } from '~/_metronic/_partials/controls';
import { onTableChangeHelper, usePaginationFilteringAndSorting, useDidMount } from '~/app/helpers';
import { Link } from "react-router-dom";
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const sortableColumn = {
  sort: true, sortCaret,
};

const UserEmailLinkCell = ({ user }) => (
  <div>
    <Link
      target="_blank"
      to={`/users/${user._id}`}
    > {user.email} </Link>
  </div>
);

const columns = () => [{
  ...sortableColumn,
  dataField: 'bannedAt', text: 'Time Of Block',
  type: 'date',
  style: { width: '210px', minWidth: '210px', textAlign: 'left' },
}, {
  dataField: 'user._email', text: 'Email',
  formatter: (_cell, row) => <UserEmailLinkCell user={row} />,
  style: { width: '250px', textAlign: 'right' },
}, {
  ...sortableColumn,
  dataField: 'countryISO', text: 'Country',
  style: { width: '110px', minWidth: '110px', textAlign: 'right' },
}, {
  ...sortableColumn,
  type: 'number',
  dataField: 'reversalsCount', text: 'Reversals',
  style: { width: '110px', minWidth: '110px', textAlign: 'right' },
}, {
  ...sortableColumn,
  type: 'number',
  dataField: 'leadsCount', text: 'Surveys Completions',
  style: { width: '110px', minWidth: '110px', textAlign: 'right' },
}];

const DateSelectors = () => {
  const startDate = useSelector(state => state.highReversalRateBans.startDate);
  const endDate = useSelector(state => state.highReversalRateBans.endDate);
  const dispatch = useDispatch();

  return (
    <>
      <Grid item xs={2}>
        <KeyboardDatePicker
          autoOk
          id="start-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="none"
          label="Start Date"
          value={startDate}
          onChange={(_, v) => dispatch(actions.setStartDate(v))}
        />
      </Grid>
      <Grid item xs={2}>
        <KeyboardDatePicker
          autoOk
          id="end-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="none"
          label="End Date"
          value={endDate}
          onChange={(_, v) => dispatch(actions.setEndDate(v))}
        />
      </Grid>
    </>
  )
};

const ReportViewer = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize,
    totalCount,
  } = usePaginationFilteringAndSorting(dispatch, 'highReversalRateBans', actions);
  const report = useSelector(state => state.highReversalRateBans.report, shallowEqual);

  const didMount = useDidMount();

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    didMount,
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = columns();

  return (
    <Box mt={2}>
      <Card>
        <CardContent>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
              {({ paginationProps, paginationTableProps }) => (
                <Pagination isLoading={false} paginationProps={paginationProps}>
                  <BootstrapTable
                    remote
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    headerClasses="table table-vertical-top"
                    bootstrap4
                    keyField="_id"
                    columns={reportColumns}
                    data={report}
                    onTableChange={onTableChange}
                    defaultSorted={[{
                      dataField: 'bannedAt',
                      order: enums.sortDirections.desc,
                    }]}
                    {...paginationTableProps}
                  >
                  </BootstrapTable>
                </Pagination>
              )}
            </PaginationProvider>
        </CardContent>
      </Card>
    </Box>
  );
};

const ReportParamsForm = () => {
  const dispatch = useDispatch();

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4} alignItems="flex-end">
          <DateSelectors />
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => dispatch(actions.generateReport())}
            >
              Generate
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const HighReversalRateBans = () => (
  <Container>
    <ReportParamsForm />
    <ReportViewer />
  </Container>
);

export default HighReversalRateBans;
