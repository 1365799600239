import _ from 'lodash';
import SVG from 'react-inlinesvg';
import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import {
  Card, CardContent, Grid, FormControl, Select, InputLabel,
  Chip, MenuItem, IconButton
} from '@material-ui/core';
import BootstrapTable from 'react-bootstrap-table-next';
import { CloseOutlined, Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { actions } from '~/app/modules/User/actions';
import { toAbsoluteUrl } from '~/_metronic/_helpers';
import { checkPhoneNumber } from '~/app/helpers/common';
import { markInUserSecurityMarks, newDateFormatLong, FraudScore } from '~/app/helpers';
import enums from '~/app/helpers/enums';
import VerificationStatusField from '../VerificationStatusField';
import ConfirmationModal from '../ConfirmationModal';

const useStyles = makeStyles((theme) => ({
  chipListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const selectMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

const ManuallyVerifyPhoneModal = ({ shown, setShown, dispatch, userId }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState('');

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleVerifyPhoneNumber = () => {
    if(!invalidPhoneNumber && phoneNumber) {
      dispatch(actions.saveUserPhoneNumber(userId, { verifiedPhoneNumber: phoneNumber }));
      setShown(false);
    }
  };

  const handleOnBlur = () => {
    setInvalidPhoneNumber(checkPhoneNumber(phoneNumber));
  };

  return (
    <>
      <Modal
        centered
        size="md"
        show={shown}
        onHide={() => setShown(false)}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            Manually Verify Phone Number
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={() => setShown(false)}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group input-group-lg input-group-solid">
            <input
              type="text"
              placeholder="Phone Number"
              className="form-control form-control-lg form-control-solid"
              name="phone"
              onBlur={handleOnBlur}
              value={phoneNumber}
              onChange={handleChangePhoneNumber}
            />
          </div>
          {invalidPhoneNumber ? (
            <div className="invalid-feedback display-block">
              {invalidPhoneNumber}
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary btn-sm" onClick={handleVerifyPhoneNumber}>Verify</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const PhoneDataField = ({ type, title, value }) => {
  return (
    <div className="d-flex align-items-center mb-10">
      <span className={`bullet bullet-bar bg-${type} align-self-stretch`} />

      <label className={`checkbox checkbox-lg checkbox-light-${type} checkbox-single flex-shrink-0 m-0 mx-4`}>
        <input type="checkbox" name="" onChange={() => {}} value={value} checked={false} />
        <span />
      </label>

      <div className="d-flex flex-column flex-grow-1">
        <span className="text-muted font-weight-bold">{title}</span>
        <div
          className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
        >
          {value}
        </div>
      </div>
    </div>
  );
};

const PhoneDataCard = ({ userId }) => {
  const userInformation = useSelector(state => state.user.userInformation);
  const dispatch = useDispatch();
  const [shown, setShown] = useState(false);
  const [showModalForUnVerifyPhoneNumber, setShowModalForUnVerifyPhoneNumber] = useState(false);

  const handleUnVerifyPhoneNumber = () => {
    dispatch(actions.unVerifyPhoneNumber(userId));
  };

  const handleShowModal = () => {
    setShown(true);
  };

  const buttonClassName = userInformation?.user?.verifiedPhoneNumber
    ? 'btn btn-secondary btn-sm'
    : 'btn btn-primary btn-sm';

  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded pb-5">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          Phone Data
        </h3>
        <div className="d-flex justify-content-between align-items-start">
          <PhoneDataField
            type="success"
            title="Phone Number"
            value={userInformation?.user?.verifiedPhoneNumber || '-'}
          />
          {userInformation?.user?.verifiedPhoneNumber && (
            <IconButton onClick={() => {setShowModalForUnVerifyPhoneNumber(true)}}>
              <Cancel style={{ fill: '#3699FF', width: '25px', height: '25px' }} />
            </IconButton>
          )}
        </div>
        <PhoneDataField
          type="primary"
          title="Phone Type"
          value={userInformation?.user?.phoneNetwork?.type || '-'}
        />
        <PhoneDataField
          type="warning"
          title="Network Name"
          value={userInformation?.user?.phoneNetwork?.name || '-'}
        />
        <PhoneDataField
          type="info"
          title="Network Code"
          value={userInformation?.user?.phoneNetwork?.code || '-'}
        />
        <PhoneDataField
          type="danger"
          title="Verification Tries"
          value={userInformation?.user?.phoneNetwork?.verificationTries || '-'}
        />
        <PhoneDataField
          type="success"
          title="Last Verification Date"
          value={newDateFormatLong(userInformation?.user?.phoneNetwork?.lastVerificationTry)}
        />
        <button
          type="button"
          disabled={userInformation?.user?.verifiedPhoneNumber}
          className={buttonClassName}
          onClick={handleShowModal}
        >
          Manually Verify Phone
        </button>
        <ManuallyVerifyPhoneModal
          setShown={setShown}
          shown={shown}
          dispatch={dispatch}
          userId={userInformation.user._id}
        />
        <ConfirmationModal
          title="Un-verify Phone Number"
          shown={showModalForUnVerifyPhoneNumber}
          setShown={setShowModalForUnVerifyPhoneNumber}
          confirmFunc={handleUnVerifyPhoneNumber}
        />
      </CardContent>
    </Card>
  );
};

function IPsStatisticsTable({entities}) {
  const columns = [
    { dataField: "item", text: "ITEM" },
    { dataField: "amount", text: "AMOUNT" },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={false}
      borderless={true}
      classes="table table-vertical-top overflow-hidden"
      bootstrap4
      size="sm"
      responsive="lg"
      headerClasses="bg-light rounded"
      remote
      keyField="item"
      data={entities}
      columns={columns}
    >
    </BootstrapTable>
  );
};

const IPStatisticsCard = () => {
  const userIPs = useSelector(state => state.user.userIPs);

  const findEntities = () => {
    if(!userIPs.length) {
      return [];
    }

    const uniqueISPs = [];
    const uniqueCountries = [];
    userIPs.forEach((ip) => {
      if(!uniqueISPs.includes(ip.isp)) {
        uniqueISPs.push(ip.isp);
      }
      if(!uniqueCountries.includes(ip.countryISO)) {
        uniqueCountries.push(ip.countryISO);
      }
    });

    return [
      { item: 'Total IPs', amount: userIPs.length },
      { item: 'Total Unique ISPs', amount: uniqueISPs.length },
      { item: 'Total Unique Countries', amount: uniqueCountries.length },
    ];
  };

  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded pb-5">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          IP Statistics
        </h3>
        <IPsStatisticsTable entities={findEntities()} />
      </CardContent>
    </Card>
  );
};

const VeriffVerificationCard = () => {
  const userInformation = useSelector(state => state.user.userInformation);

  let fraudScoreClassName = 'font-weight-boldest';

  const renderVeriffField = () => {
    return (
      <div className="d-flex justify-content-between flex-column ml-2">
        <VerificationStatusField user={userInformation.user} />
        <div className="text-muted">Verification Status</div>
      </div>
    );
  };

  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded pb-5">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          Verification Data
        </h3>
        <div className="d-flex mb-10">
          <span className="d-flex min-w-40px min-h-40px svg-icon svg-icon-warning svg-icon-3x bg-light-secondary rounded align-items-center justify-content-center">
            <SVG
              src={toAbsoluteUrl('/media/svg/icons/General/Star.svg')}
            />
          </span>
          <div className="d-flex justify-content-between flex-column ml-2">
            <div className={fraudScoreClassName}>
              <FraudScore fraudScore={userInformation.user.fraudScore || ' '} level={userInformation.fraudScoreLevel} />
            </div>
            <div className="text-muted">Fraud Score</div>
          </div>
        </div>
        <div className="d-flex mb-10">
          <span className="d-flex min-w-40px min-h-40px svg-icon svg-icon-dark svg-icon-3x bg-light-secondary rounded align-items-center justify-content-center">
            <SVG
              src={toAbsoluteUrl('/media/svg/icons/General/Thunder.svg')}
            />
          </span>
          {renderVeriffField()}
        </div>
        <Link to={`/veriff-log/${userInformation.user._id}`} target="_blank" rel="noopener noreferrer">
          <button type="button" className="btn btn-primary btn-md">
            <div className="d-flex">
              Full Veriff Report
              <span className="d-flex svg-icon svg-icon-white align-items-center justify-content-center ml-2">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Communication/Share.svg')}
              />
            </span>
            </div>
          </button>
        </Link>
      </CardContent>
    </Card>
  );
};

const renderSecurityMarks = (marks, ignoredMarks) => {
  if(!marks || !Object.keys(marks).length) {
    return null;
  }

  const userActiveMarks = Object.keys(marks).filter((key) => marks[key] && !ignoredMarks.includes(key.toUpperCase()));
  if(!userActiveMarks.length) {
    return null;
  }

  return userActiveMarks.map((mark, index) => {
    return (
      <Fragment key={mark}>
        <span className={markInUserSecurityMarks(mark) ? 'text-danger': ''}>
          {mark}
        </span>
        <span>{(index === userActiveMarks.length - 1) ? '' : ` / `}</span>
      </Fragment>
    );
  });
};

const MultipleSelector = ({ selected, setSelected, deleteKey, label, displayableValues }) => {
  const classes = useStyles();

  const labelId = `${label}-label`;

  return (
    <Grid item xs={12}>
      <FormControl margin="normal" className="my-0">
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          id={label}
          labelId={labelId}
          multiple
          MenuProps={selectMenuProps}
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
          renderValue={values => (
            <div className={classes.chipListContainer}>
              {values.map((value) => {
                return (
                  <Chip
                    key={value}
                    label={value.toLowerCase()}
                    size="small"
                    className={classes.chip}
                    onDelete={() => deleteKey(value)}
                    deleteIcon={
                      <Cancel onMouseDown={event => event.stopPropagation()}/>
                    }
                  />
                )
              })}
            </div>
          )}
        >
          { displayableValues.map((value) => (
            <MenuItem key={value} value={value}>{value?.toLowerCase?.()}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

const SecurityMarksCard = () => {
  const userInformation = useSelector(state => state.user.userInformation);
  const dispatch = useDispatch();

  const selectAll = 'Select All';
  const errorsWithStorage = {};
  Object.values(enums.orderVerificationErrors).forEach((key) => {
    errorsWithStorage[`storage_${key}`] = `storage_${key}`;
  });

  const orderSecurityOptions = _.uniq(Object.values({
    ...enums.setAllMark,
    ...enums.orderVerificationErrors,
    ...errorsWithStorage,
  }));

  const handleChangeIgnoredMarks = (key) => {
    if(typeof key === 'string') {
      const newIgnoredMarks = userInformation.user.orderSecurityIgnores
        .filter((mark) => mark.toLowerCase() !== key.toLowerCase());
      dispatch(actions.updateUserIgnoredMarks(userInformation.user._id, newIgnoredMarks));
    } else {
      if (!key?.length) {
        dispatch(actions.updateUserIgnoredMarks(userInformation.user._id, []));
      } else {
        let newIgnoresMarks;
        if (key.includes(selectAll)) {
          newIgnoresMarks = orderSecurityOptions.map((item) => item.toLowerCase());
        } else if (!userInformation.user.orderSecurityIgnores.includes(key[key.length - 1].toLowerCase())) {
          newIgnoresMarks = key.map((item) => item.toLowerCase());
        }
        if (newIgnoresMarks) {
          dispatch(actions.updateUserIgnoredMarks(userInformation.user._id, newIgnoresMarks));
        }
      }
    }
  };

  const handleRemoveAllTags = () => {
    dispatch(actions.updateUserIgnoredMarks(userInformation.user._id, []));
  };

  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded pb-5">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          Security Marks
        </h3>
        <div>User Marks</div>
        <div className="mx-10 my-5">
          {renderSecurityMarks(userInformation.user.marks, userInformation.user.orderSecurityIgnores)}
        </div>
        <div>Marks to ignore</div>
        <div className="mx-10 mb-5 mt-7">
          <MultipleSelector
            label="Ignored Marks"
            selected={userInformation.user?.orderSecurityIgnores?.map((mark) => mark.toLowerCase())}
            setSelected={key => handleChangeIgnoredMarks(key)}
            deleteKey={key => handleChangeIgnoredMarks(key)}
            displayableValues={[selectAll, ...orderSecurityOptions]}
          />
          <button type="button" className="mt-5 btn btn-primary btn-sm" onClick={handleRemoveAllTags}>Remove tags</button>
        </div>
      </CardContent>
    </Card>
  );
};

const StateMatchingCard = () => {
  const userInformation = useSelector(state => state.user.userInformation);
  const columns = [
    { dataField: "item", text: "ITEM" },
    { dataField: "ip", text: "IP" },
    { dataField: "phone", text: "PHONE" },
    { dataField: "profiler", text: "PROFILER" },
  ];

  const entities = [
    {
      item: 'State',
      ip: userInformation.user?.stateMatching?.ip || '-',
      phone: userInformation.user?.stateMatching?.phone || '-',
      profiler: userInformation.user?.stateMatching?.profiler || '-',
    },
  ];

  return (
    <Card>
      <CardContent className="h-100 border text-dark border-light-dark rounded">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          State Matching
        </h3>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          borderless={true}
          classes="table table-vertical-top overflow-hidden"
          bootstrap4
          size="sm"
          responsive="lg"
          headerClasses="bg-light rounded"
          remote
          keyField="item"
          data={entities}
          columns={columns}
        >
        </BootstrapTable>
      </CardContent>
    </Card>
  );
};

const Overview = ({ userId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getUserIPs(userId));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const userInformation = useSelector(state => state.user.userInformation);

  if(!userInformation.user) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={4}>
        <PhoneDataCard userId={userId} />
      </Grid>
      <Grid container item xs={12} md={12} lg={8} spacing={1}>
        <Grid item md={12} lg={6}>
          <IPStatisticsCard />
        </Grid>
        <Grid item md={12} lg={6}>
          <VeriffVerificationCard />
        </Grid>
        <Grid item lg={12}>
          <SecurityMarksCard />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={7} >
        <StateMatchingCard />
      </Grid>
    </Grid>
  );
};

export default Overview;
