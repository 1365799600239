export const actionTypes = {
  GetSurvey: 'survey/get-survey',
  GetSurveySuccess: 'survey/get-survey-success',
  GetSurveyCRStats: 'survey/get-survey-cr-stats',
  GetSurveyCRStatsSuccess: 'survey/get-survey-cr-stats-success',
  GetSurveyRevenueStats: 'survey/get-survey-revenue-stats',
  GetSurveyRevenueStatsSuccess: 'survey/get-survey-revenue-stats-success',
  SetCRStartDate: 'survey/set-cr-start-date',
  SetCREndDate: 'survey/set-cr-end-date',
  SetRevenueStartDate: 'survey/set-revenue-start-date',
  SetRevenueEndDate: 'survey/set-revenue-end-date',
  SetClicksPerformanceStartDate: 'survey/set-clicks-performance-start-date',
  SetClicksPerformanceEndDate: 'survey/set-clicks-performance-end-date',
  GetSurveyStats: 'survey/get-survey-stats',
  GetSurveyStatsSuccess: 'survey/get-survey-stats-success',
  GetClicksStoppedReasons: 'survey/get-clicks-stopped-reasons',
  GetClicksStoppedReasonsSuccess: 'survey/get-clicks-stopped-reasons-success',
  GetClicksPerformance: 'survey/get-clicks-performance',
  GetClicksPerformanceSuccess: 'survey/get-clicks-performance-success',
  GetPreliminaryFailedClicks: 'survey/get-preliminary-failed-clicks',
  GetPreliminaryFailedClicksSuccess: 'survey/get-preliminary-failed-clicks-success',
  GetLastClickAndLead: 'survey/get-last-click-and-lead',
  GetLastClickAndLeadSuccess: 'survey/get-last-click-and-lead-success',
  GetSurveyQuestions: 'survey/get-survey-questions',
  GetSurveyQuestionsSuccess: 'survey/get-survey-questions-success',
  GetSurveySsiRexQuotasAndFilters: 'survey/get-survey-ssi-rex-quotas-and-filters',
  GetSurveySsiRexQuotasAndFiltersSuccess: 'survey/get-survey-ssi-rex-quotas-and-filters-success',
  GetSurveySsiRexQuotasAndFiltersFailed: 'survey/get-survey-ssi-rex-quotas-and-filters-failed',
  GetSurveyTolunaIpesData: 'survey/get-survey-toluna-ipes-data',
  GetSurveyTolunaIpesDataSuccess: 'survey/get-survey-toluna-ipes-data-success',
  GetSurveyTolunaIpesDataFailed: 'survey/get-survey-toluna-ipes-data-failed',
  GetSurveyProdegeQuotas: 'survey/get-survey-prodege-quotas',
  GetSurveyProdegeQuotasSuccess: 'survey/get-survey-prodege-quotas-success',
  GetSurveyProdegeQuotasFailed: 'survey/get-survey-prodege-quotas-failed',
  GetSurveyQuotas: 'survey/get-survey-quotas',
  GetSurveyQuotasSuccess: 'survey/get-survey-quotas-success',
  GetSurveyTargetedUsers: 'survey/get-targeted-users',
  GetSurveyTargetedUsersFailed: 'survey/get-targeted-users-failed',
  GetSurveyTargetedUsersSuccess: 'survey/get-targeted-users-success',
  SetSurveyTargetedUsersPage: 'survey/set-targeted-users-page',
  SetSurveyTargetedUsersPerPage: 'survey/set-targeted-users-per-page',
  GetSurveyDQStats: 'survey/get-dq-stats',
  GetSurveyDQStatsFailed: 'survey/get-dq-stats-failed',
  GetSurveyDQStatsSuccess: 'survey/get-dq-stats-success',
  GetSurveyOriginStats: 'survey/get-origin-stats',
  GetSurveyOriginStatsFailed: 'survey/get-origin-stats-failed',
  GetSurveyOriginStatsSuccess: 'survey/get-origin-stats-success',
};

export const actions = {
  getSurvey: (surveyId) => ({type: actionTypes.GetSurvey, payload: surveyId}),
  getSurveySuccess: (survey) => ({type: actionTypes.GetSurveySuccess, payload: survey}),
  getSurveyCRStats: (surveyId, dateFilter) => ({type: actionTypes.GetSurveyCRStats, payload: { surveyId, dateFilter}}),
  getSurveyCRStatsSuccess: (stats) => ({type: actionTypes.GetSurveyCRStatsSuccess, payload: stats}),
  getSurveyRevenueStats: (surveyId, dateFilter) => ({type: actionTypes.GetSurveyRevenueStats, payload: { surveyId, dateFilter}}),
  getSurveyRevenueStatsSuccess: (stats) => ({type: actionTypes.GetSurveyRevenueStatsSuccess, payload: stats}),
  setCRStartDate: (date) => ({type: actionTypes.SetCRStartDate, payload: date}),
  setCREndDate: (date) => ({type: actionTypes.SetCREndDate, payload: date}),
  setRevenueStartDate: (date) => ({type: actionTypes.SetRevenueStartDate, payload: date}),
  setRevenueEndDate: (date) => ({type: actionTypes.SetRevenueEndDate, payload: date}),
  getSurveyStats: (surveyId) => ({type: actionTypes.GetSurveyStats, payload: surveyId}),
  getSurveyStatsSuccess: (stats) => ({type: actionTypes.GetSurveyStatsSuccess, payload: stats}),
  getClicksStoppedReasons: (surveyId) => ({type: actionTypes.GetClicksStoppedReasons, payload: surveyId}),
  getClicksStoppedReasonsSuccess: (stats) => ({type: actionTypes.GetClicksStoppedReasonsSuccess, payload: stats}),
  getClicksPerformance: (surveyId, dateFilter) => ({type: actionTypes.GetClicksPerformance, payload: { surveyId, dateFilter}}),
  getClicksPerformanceSuccess: (stats) => ({type: actionTypes.GetClicksPerformanceSuccess, payload: stats}),
  setClicksPerformanceStartDate: (date) => ({type: actionTypes.SetClicksPerformanceStartDate, payload: date}),
  setClicksPerformanceEndDate: (date) => ({type: actionTypes.SetClicksPerformanceEndDate, payload: date}),
  getPreliminaryFailedClicks: (surveyId) => ({type: actionTypes.GetPreliminaryFailedClicks, payload: surveyId }),
  getPreliminaryFailedClicksSuccess: (stats) => ({type: actionTypes.GetPreliminaryFailedClicksSuccess, payload: stats}),
  getLastClickAndLead: (surveyId) => ({type: actionTypes.GetLastClickAndLead, payload: surveyId }),
  getLastClickAndLeadSuccess: (stats) => ({type: actionTypes.GetLastClickAndLeadSuccess, payload: stats}),
  getSurveyQuestions: (surveyId) => ({ type: actionTypes.GetSurveyQuestions, payload: surveyId }),
  getSurveyQuestionsSuccess: (data) => ({ type: actionTypes.GetSurveyQuestionsSuccess, payload: data }),
  getSurveySsiRexQuotasAndFilters: (data) => ({ type: actionTypes.GetSurveySsiRexQuotasAndFilters, payload: data }),
  getSurveySsiRexQuotasAndFiltersSuccess: (data) => ({ type: actionTypes.GetSurveySsiRexQuotasAndFiltersSuccess, payload: data }),
  getSurveySsiRexQuotasAndFiltersFailed: () => ({ type: actionTypes.GetSurveySsiRexQuotasAndFiltersFailed }),
  getSurveyTolunaIpesData: (data) => ({ type: actionTypes.GetSurveyTolunaIpesData, payload: data }),
  getSurveyTolunaIpesDataSuccess: (data) => ({ type: actionTypes.GetSurveyTolunaIpesDataSuccess, payload: data }),
  getSurveyTolunaIpesDataFailed: () => ({ type: actionTypes.GetSurveyTolunaIpesDataFailed }),
  getSurveyProdegeQuotas: (data) => ({ type: actionTypes.GetSurveyProdegeQuotas, payload: data }),
  getSurveyProdegeQuotasSuccess: (data) => ({ type: actionTypes.GetSurveyProdegeQuotasSuccess, payload: data }),
  getSurveyProdegeQuotasFailed: () => ({ type: actionTypes.GetSurveyProdegeQuotasFailed }),
  getSurveyQuotas: (surveyId) => ({ type: actionTypes.GetSurveyQuotas, payload: surveyId }),
  getSurveyQuotasSuccess: (data) => ({ type: actionTypes.GetSurveyQuotasSuccess, payload: data }),
  getSurveyTargetedUsers: (params) => ({ type: actionTypes.GetSurveyTargetedUsers, payload: params }),
  getSurveyTargetedUsersFailed: () => ({ type: actionTypes.GetSurveyTargetedUsersFailed }),
  getSurveyTargetedUsersSuccess: (data) => ({ type: actionTypes.GetSurveyTargetedUsersSuccess, payload: data }),
  setSurveyTargetedUsersPage: (page) => ({ type: actionTypes.SetSurveyTargetedUsersPage, payload: page }),
  setSurveyTargetedUsersPerPage: (sizePerPage) => ({ type: actionTypes.SetSurveyTargetedUsersPerPage, payload: sizePerPage }),
  getSurveyDQStats: (surveyId) => ({ type: actionTypes.GetSurveyDQStats, payload: surveyId }),
  getSurveyDQStatsSuccess: (data) => ({ type: actionTypes.GetSurveyDQStatsSuccess, payload: data }),
  getSurveyDQStatsFailed: () => ({ type: actionTypes.GetSurveyDQStatsFailed }),
  getSurveyOriginStats: (surveyId) => ({ type: actionTypes.GetSurveyOriginStats, payload: surveyId }),
  getSurveyOriginStatsSuccess: (data) => ({ type: actionTypes.GetSurveyOriginStatsSuccess, payload: data }),
  getSurveyOriginStatsFailed: () => ({ type: actionTypes.GetSurveyOriginStatsFailed }),
}
