import { actionTypes } from '~/app/modules/SharedView/actions';

const initialState = {
  params: {},
  loaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetSharedParamsSuccess:
      return {
        ...state,
        params: action.payload,
        loaded: true,
      }

    case actionTypes.SetLoadedParams:
      return {
        ...state,
        loaded: action.payload,
      }

    default:
      return state;
  }
};
