import { actionTypes } from '~/app/modules/Translations/components/Products/actions';

const initialState = {
  report: [],
  productTranslation: [],
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetProductsSuccess:
      return {
        ...state,
        report: action.payload,
        isLoading: false,
      };

    case actionTypes.GetProducts:
      return {
        ...state,
        report: [],
        isLoading: true,
      };

    case actionTypes.GetProductsFailure:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.UpdateProduct:
    case actionTypes.CreateProduct:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.UpdateProductSuccess:
    case actionTypes.UpdateProductFailure:
    case actionTypes.CreateProductSuccess:
    case actionTypes.CreateProductFailure:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GetProductTranslationSuccess:
      return {
        ...state,
        productTranslation: action.payload,
      };

    case actionTypes.CleanProductTranslation:
      return {
        ...state,
        productTranslation: [],
      };

    default:
      return state;
  }
};
