import { actionTypes } from '~/app/modules/FaceTecSettings/actions';

const initialState = {
  inited: false,
  isLoading: false,
  showErrorAlert: false,
  showSuccessAlert: false,
  serverError: null,
  faceTecSettings: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FetchFaceTecSettings:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FetchFaceTecSettingsSuccess:
      return {
        ...state,
        inited: true,
        isLoading: false,
        faceTecSettings: action.payload,
      };

    case actionTypes.FetchFaceTecSettingsFailure:
      return {
        ...state,
        isLoading: false,
        showErrorAlert: true,
        serverError: action.payload,
      };

    case actionTypes.ChangeFormField:
      return {
        ...state,
        faceTecSettings: {
          ...state.faceTecSettings,
          ...action.payload,
        },
      };

    case actionTypes.UpdateFaceTecSettings:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.UpdateFaceTecSettingsSuccess:
      return {
        ...state,
        isLoading: false,
        showSuccessAlert: true,
        faceTecSettings: action.payload,
      };

    case actionTypes.UpdateFaceTecSettingsFailure:
      return {
        ...state,
        isLoading: false,
        showErrorAlert: true,
        serverError: action.payload,
      };

    case actionTypes.CloseErrorAlert:
      return {
        ...state,
        showErrorAlert: false,
      };

    case actionTypes.CloseSuccessAlert:
      return {
        ...state,
        showSuccessAlert: false,
      };

    default:
      return state;
  }
};


