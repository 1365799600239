import { actionTypes } from '~/app/modules/User/actions';
import _ from "lodash";
import enums from "~/app/helpers/enums";

const initialState = {
  userInformation: {},
  userClicks: [],
  userEarnings: {},
  userOrders: [],
  userDeleted: false,
  userProfilerQuestions: [],
  userEmails: [],
  userEmailStats: [],
  userNotifications: [],
  userNotificationsStats: [],
  userIPs: [],
  userNexmoLogs: [],
  userIPsWithEmails: [],
  userPurchases: [],
  userPublisherPostbackLogs: [],
  paypalLogs: [],
  transactionsLog: [],

  pageNumber: 1,
  pageSize: 20,
  sortBy: 'preCalculatedRanks.rank17',
  sortDirection: enums.sortDirections.desc,
  filters: {},
  report: [],
  totalCount: 0,
  isLoading: false,

  userId: '',
  isLoadingCSV: false,
};

const resetPaginationState = {
  pageNumber: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetUserInformationSuccess:
      return {
        ...state,
        userInformation: action.payload,
      };

    case actionTypes.GetUserPaypalLogsSuccess:
      return {
        ...state,
        paypalLogs: action.payload,
      };

    case actionTypes.GetUserTransactionsLogSuccess:
      return {
        ...state,
        transactionsLog: action.payload,
      };

    case actionTypes.GetUserClicksSuccess:
      return {
        ...state,
        userClicks: action.payload,
      };

    case actionTypes.GetUserEarningsSuccess:
      return {
        ...state,
        userEarnings: action.payload,
      };

    case actionTypes.GetUserOrdersSuccess:
      return {
        ...state,
        userOrders: action.payload,
      };

    case actionTypes.DeleteUserSuccess:
      return {
        ...state,
        userDeleted: action.payload,
      }

    case actionTypes.GetUserProfilerQuestionsSuccess:
      return {
        ...state,
        userProfilerQuestions: action.payload,
      }

    case actionTypes.GetUserEmailsSuccess:
      return {
        ...state,
        userEmails: action.payload,
      }

    case actionTypes.GetUserEmailsStatsSuccess:
      return {
        ...state,
        userEmailStats: action.payload,
      }

    case actionTypes.GetUserNotificationsSuccess:
      return {
        ...state,
        userNotifications: action.payload,
      }

    case actionTypes.GetUserNotificationsStatsSuccess:
      return {
        ...state,
        userNotificationsStats: action.payload,
      }

    case actionTypes.GetUserIPsSuccess:
      return {
        ...state,
        userIPs: action.payload,
      }

    case actionTypes.GetUserNexmoLogsSuccess:
      return {
        ...state,
        userNexmoLogs: action.payload,
      }

    case actionTypes.GetUserIPsWithEmailSuccess:
      return {
        ...state,
        userIPsWithEmails: action.payload,
      }

    case actionTypes.GetUserPurchasesSuccess:
      return {
        ...state,
        userPurchases: action.payload,
      }

    case actionTypes.GetUserPublisherPostbackLogsSuccess:
      return {
        ...state,
        userPublisherPostbackLogs: action.payload,
      }

    case actionTypes.SetPagination:
      return {
        ...state,
        ...resetPaginationState,
        pageSize: action.payload.pageSize,
        pageNumber: action.payload.pageNumber,
      };

    case actionTypes.SetSorting:
      return {
        ...state,
        ...resetPaginationState,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };

    case actionTypes.SetFilter:
      const { field, value } = action.payload;
      const curFilters = state.filters;
      if (value) {
        return {
          ...state,
          ...resetPaginationState,
          filters: {
            ...curFilters,
            [field]: value,
          },
        };
      } else {
        return {
          ...state,
          ...resetPaginationState,
          filters: _.omit(curFilters, field),
        };
      }

    case actionTypes.GenerateReport:
      return {
        ...state,
        pageNumber: 1,
        pageSize: 20,
        report: [],
        totalCount: 0,
        isLoading: true,
      };

    case actionTypes.FetchReportSuccess:
      return {
        ...state,
        report: action.payload.data,
        totalCount: action.payload.totalCount,
        stats: action.payload.stats,
        isLoading: false,
      };

    case actionTypes.FetchReportError:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.SetUserId:
      return {
        ...state,
        userId: action.payload,
      };

    case actionTypes.GenerateReportForTargetedSurveysAsCsv:
      return {
        ...state,
        isLoadingCSV: true,
      };

    case actionTypes.GenerateReportForTargetedSurveysAsCsvSuccess:
      return {
        ...state,
        isLoadingCSV: false,
      };

    default:
      return state;
  }
};
