import config from '~/config';
import fetch from '~/redux/fetch';

const FETCH_LANGUAGES_URL = '/translations/languages';

const getLanguages = async () => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_LANGUAGES_URL}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
};

const UPDATE_KEY_TRANSLATIONS_URL = '/translations/key-translations';

const massVerifyKeyTranslations = async (data) => {
  const response = await fetch(`${config.apiBaseUrl}${UPDATE_KEY_TRANSLATIONS_URL}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return response.json();
}

const SET_SLACK_ID_URL = (languageId) => `/translations/languages/${languageId}`;

const setSlackId = async (language) => {
  const response = await fetch(`${config.apiBaseUrl}${SET_SLACK_ID_URL(language._id)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(language),
  });
  return response.json();
}

export default {
  getLanguages,
  massVerifyKeyTranslations,
  setSlackId,
};
