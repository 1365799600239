export const userRegistrationDevices = [
  {
    "name": "Desktop - undefined",
    "label": "Desktop - undefined",
    "value": "Desktop - undefined"
  },
  {
    "name": "Desktop - Windows",
    "label": "Desktop - Windows",
    "value": "Desktop - Windows"
  },
  {
    "name": "Mobile Web - Android",
    "label": "Mobile Web - Android",
    "value": "Mobile Web - Android"
  },
  {
    "name": "Desktop - Linux",
    "label": "Desktop - Linux",
    "value": "Desktop - Linux"
  },
  {
    "name": "Desktop - Mac OS",
    "label": "Desktop - Mac OS",
    "value": "Desktop - Mac OS"
  },
  {
    "name": "Mobile Web - iOS",
    "label": "Mobile Web - iOS",
    "value": "Mobile Web - iOS"
  },
  {
    "name": "Desktop - Chromium OS",
    "label": "Desktop - Chromium OS",
    "value": "Desktop - Chromium OS"
  },
  {
    "name": "samsung SM-A217F",
    "label": "samsung SM-A217F",
    "value": "samsung SM-A217F"
  },
  {
    "name": "n/a",
    "label": "n/a",
    "value": "n/a"
  },
  {
    "name": "samsung SM-G781U",
    "label": "samsung SM-G781U",
    "value": "samsung SM-G781U"
  },
  {
    "name": "Desktop - Ubuntu",
    "label": "Desktop - Ubuntu",
    "value": "Desktop - Ubuntu"
  },
  {
    "name": "samsung SM-A125F",
    "label": "samsung SM-A125F",
    "value": "samsung SM-A125F"
  },
  {
    "name": "Xiaomi M2006C3LG",
    "label": "Xiaomi M2006C3LG",
    "value": "Xiaomi M2006C3LG"
  },
  {
    "name": "HUAWEI WAS-LX1A",
    "label": "HUAWEI WAS-LX1A",
    "value": "HUAWEI WAS-LX1A"
  },
  {
    "name": "Apple iPad7,3",
    "label": "Apple iPad7,3",
    "value": "Apple iPad7,3"
  },
  {
    "name": "undefined undefined",
    "label": "undefined undefined",
    "value": "undefined undefined"
  },
  {
    "name": "Apple iPhone13,2",
    "label": "Apple iPhone13,2",
    "value": "Apple iPhone13,2"
  },
  {
    "name": "samsung SM-X800",
    "label": "samsung SM-X800",
    "value": "samsung SM-X800"
  },
  {
    "name": "HUAWEI EML-L29",
    "label": "HUAWEI EML-L29",
    "value": "HUAWEI EML-L29"
  },
  {
    "name": "vivo vivo 1716",
    "label": "vivo vivo 1716",
    "value": "vivo vivo 1716"
  },
  {
    "name": "samsung SM-A037F",
    "label": "samsung SM-A037F",
    "value": "samsung SM-A037F"
  },
  {
    "name": "Hisense Hisense U40",
    "label": "Hisense Hisense U40",
    "value": "Hisense Hisense U40"
  },
  {
    "name": "Xiaomi M2103K19G",
    "label": "Xiaomi M2103K19G",
    "value": "Xiaomi M2103K19G"
  },
  {
    "name": "HUAWEI ATU-L42",
    "label": "HUAWEI ATU-L42",
    "value": "HUAWEI ATU-L42"
  },
  {
    "name": "vivo V2043",
    "label": "vivo V2043",
    "value": "vivo V2043"
  },
  {
    "name": "HUAWEI STK-LX1",
    "label": "HUAWEI STK-LX1",
    "value": "HUAWEI STK-LX1"
  },
  {
    "name": "Xiaomi Redmi Note 9 Pro",
    "label": "Xiaomi Redmi Note 9 Pro",
    "value": "Xiaomi Redmi Note 9 Pro"
  },
  {
    "name": "samsung SM-A032F",
    "label": "samsung SM-A032F",
    "value": "samsung SM-A032F"
  },
  {
    "name": "Apple iPhone11,8",
    "label": "Apple iPhone11,8",
    "value": "Apple iPhone11,8"
  },
  {
    "name": "Xiaomi M2003J15SC",
    "label": "Xiaomi M2003J15SC",
    "value": "Xiaomi M2003J15SC"
  },
  {
    "name": "OPPO CPH2127",
    "label": "OPPO CPH2127",
    "value": "OPPO CPH2127"
  },
  {
    "name": "samsung SM-A037G",
    "label": "samsung SM-A037G",
    "value": "samsung SM-A037G"
  },
  {
    "name": "samsung SM-A326B",
    "label": "samsung SM-A326B",
    "value": "samsung SM-A326B"
  },
  {
    "name": "realme RMX3201",
    "label": "realme RMX3201",
    "value": "realme RMX3201"
  },
  {
    "name": "motorola moto g(9) power",
    "label": "motorola moto g(9) power",
    "value": "motorola moto g(9) power"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X655C",
    "label": "INFINIX MOBILITY LIMITED Infinix X655C",
    "value": "INFINIX MOBILITY LIMITED Infinix X655C"
  },
  {
    "name": "Desktop - PlayStation",
    "label": "Desktop - PlayStation",
    "value": "Desktop - PlayStation"
  },
  {
    "name": "realme RMX2155",
    "label": "realme RMX2155",
    "value": "realme RMX2155"
  },
  {
    "name": "HUAWEI WAS-LX3",
    "label": "HUAWEI WAS-LX3",
    "value": "HUAWEI WAS-LX3"
  },
  {
    "name": "realme RMX2030",
    "label": "realme RMX2030",
    "value": "realme RMX2030"
  },
  {
    "name": "samsung SM-A325F",
    "label": "samsung SM-A325F",
    "value": "samsung SM-A325F"
  },
  {
    "name": "Xiaomi 2201116SG",
    "label": "Xiaomi 2201116SG",
    "value": "Xiaomi 2201116SG"
  },
  {
    "name": "OPPO CPH2059",
    "label": "OPPO CPH2059",
    "value": "OPPO CPH2059"
  },
  {
    "name": "motorola moto e6s",
    "label": "motorola moto e6s",
    "value": "motorola moto e6s"
  },
  {
    "name": "Hisense Hisense Infinity E30 Lite",
    "label": "Hisense Hisense Infinity E30 Lite",
    "value": "Hisense Hisense Infinity E30 Lite"
  },
  {
    "name": "HUAWEI DUA-L22",
    "label": "HUAWEI DUA-L22",
    "value": "HUAWEI DUA-L22"
  },
  {
    "name": "Apple iPhone13,1",
    "label": "Apple iPhone13,1",
    "value": "Apple iPhone13,1"
  },
  {
    "name": "Xiaomi M2101K7BNY",
    "label": "Xiaomi M2101K7BNY",
    "value": "Xiaomi M2101K7BNY"
  },
  {
    "name": "Xiaomi M2010J19CI",
    "label": "Xiaomi M2010J19CI",
    "value": "Xiaomi M2010J19CI"
  },
  {
    "name": "Xiaomi M2004J19C",
    "label": "Xiaomi M2004J19C",
    "value": "Xiaomi M2004J19C"
  },
  {
    "name": "OPPO CPH1801",
    "label": "OPPO CPH1801",
    "value": "OPPO CPH1801"
  },
  {
    "name": "samsung SM-A035F",
    "label": "samsung SM-A035F",
    "value": "samsung SM-A035F"
  },
  {
    "name": "Apple iPhone13,4",
    "label": "Apple iPhone13,4",
    "value": "Apple iPhone13,4"
  },
  {
    "name": "Hisense Hisense F16",
    "label": "Hisense Hisense F16",
    "value": "Hisense Hisense F16"
  },
  {
    "name": "motorola motorola one macro",
    "label": "motorola motorola one macro",
    "value": "motorola motorola one macro"
  },
  {
    "name": "samsung SM-A136B",
    "label": "samsung SM-A136B",
    "value": "samsung SM-A136B"
  },
  {
    "name": "Apple iPhone14,5",
    "label": "Apple iPhone14,5",
    "value": "Apple iPhone14,5"
  },
  {
    "name": "vivo V2026",
    "label": "vivo V2026",
    "value": "vivo V2026"
  },
  {
    "name": "samsung SM-A226B",
    "label": "samsung SM-A226B",
    "value": "samsung SM-A226B"
  },
  {
    "name": "motorola moto e(7)",
    "label": "motorola moto e(7)",
    "value": "motorola moto e(7)"
  },
  {
    "name": "TCL 5033D",
    "label": "TCL 5033D",
    "value": "TCL 5033D"
  },
  {
    "name": "Apple iPhone12,1",
    "label": "Apple iPhone12,1",
    "value": "Apple iPhone12,1"
  },
  {
    "name": "samsung SM-G570F",
    "label": "samsung SM-G570F",
    "value": "samsung SM-G570F"
  },
  {
    "name": "samsung SM-A127M",
    "label": "samsung SM-A127M",
    "value": "samsung SM-A127M"
  },
  {
    "name": "samsung SM-A315G",
    "label": "samsung SM-A315G",
    "value": "samsung SM-A315G"
  },
  {
    "name": "HUAWEI ELE-L29",
    "label": "HUAWEI ELE-L29",
    "value": "HUAWEI ELE-L29"
  },
  {
    "name": "realme RMX3511",
    "label": "realme RMX3511",
    "value": "realme RMX3511"
  },
  {
    "name": "Apple iPhone10,4",
    "label": "Apple iPhone10,4",
    "value": "Apple iPhone10,4"
  },
  {
    "name": "samsung SM-M317F",
    "label": "samsung SM-M317F",
    "value": "samsung SM-M317F"
  },
  {
    "name": "asus ASUS_A001D",
    "label": "asus ASUS_A001D",
    "value": "asus ASUS_A001D"
  },
  {
    "name": "samsung SM-A105FN",
    "label": "samsung SM-A105FN",
    "value": "samsung SM-A105FN"
  },
  {
    "name": "samsung SM-A107F",
    "label": "samsung SM-A107F",
    "value": "samsung SM-A107F"
  },
  {
    "name": "HUAWEI MAR-LX1A",
    "label": "HUAWEI MAR-LX1A",
    "value": "HUAWEI MAR-LX1A"
  },
  {
    "name": "HMD Global Nokia C1",
    "label": "HMD Global Nokia C1",
    "value": "HMD Global Nokia C1"
  },
  {
    "name": "motorola Moto E (4) Plus",
    "label": "motorola Moto E (4) Plus",
    "value": "motorola Moto E (4) Plus"
  },
  {
    "name": "samsung SM-A715F",
    "label": "samsung SM-A715F",
    "value": "samsung SM-A715F"
  },
  {
    "name": "Xiaomi Redmi Note 8 Pro",
    "label": "Xiaomi Redmi Note 8 Pro",
    "value": "Xiaomi Redmi Note 8 Pro"
  },
  {
    "name": "Apple iPhone12,3",
    "label": "Apple iPhone12,3",
    "value": "Apple iPhone12,3"
  },
  {
    "name": "samsung SM-A307FN",
    "label": "samsung SM-A307FN",
    "value": "samsung SM-A307FN"
  },
  {
    "name": "samsung SM-A105M",
    "label": "samsung SM-A105M",
    "value": "samsung SM-A105M"
  },
  {
    "name": "Xiaomi 2109119DG",
    "label": "Xiaomi 2109119DG",
    "value": "Xiaomi 2109119DG"
  },
  {
    "name": "samsung SM-A225M",
    "label": "samsung SM-A225M",
    "value": "samsung SM-A225M"
  },
  {
    "name": "HUAWEI POT-LX1",
    "label": "HUAWEI POT-LX1",
    "value": "HUAWEI POT-LX1"
  },
  {
    "name": "Xiaomi M2006C3MNG",
    "label": "Xiaomi M2006C3MNG",
    "value": "Xiaomi M2006C3MNG"
  },
  {
    "name": "samsung SM-A505FN",
    "label": "samsung SM-A505FN",
    "value": "samsung SM-A505FN"
  },
  {
    "name": "samsung SM-A415F",
    "label": "samsung SM-A415F",
    "value": "samsung SM-A415F"
  },
  {
    "name": "samsung SM-G960F",
    "label": "samsung SM-G960F",
    "value": "samsung SM-G960F"
  },
  {
    "name": "Xiaomi M2101K6G",
    "label": "Xiaomi M2101K6G",
    "value": "Xiaomi M2101K6G"
  },
  {
    "name": "samsung SM-A315F",
    "label": "samsung SM-A315F",
    "value": "samsung SM-A315F"
  },
  {
    "name": "samsung SM-S908B",
    "label": "samsung SM-S908B",
    "value": "samsung SM-S908B"
  },
  {
    "name": "samsung SM-A336E",
    "label": "samsung SM-A336E",
    "value": "samsung SM-A336E"
  },
  {
    "name": "vivo V2109",
    "label": "vivo V2109",
    "value": "vivo V2109"
  },
  {
    "name": "samsung SM-A725F",
    "label": "samsung SM-A725F",
    "value": "samsung SM-A725F"
  },
  {
    "name": "Xiaomi M2102J20SG",
    "label": "Xiaomi M2102J20SG",
    "value": "Xiaomi M2102J20SG"
  },
  {
    "name": "samsung SM-G950F",
    "label": "samsung SM-G950F",
    "value": "samsung SM-G950F"
  },
  {
    "name": "Apple iPhone9,3",
    "label": "Apple iPhone9,3",
    "value": "Apple iPhone9,3"
  },
  {
    "name": "samsung SM-M135F",
    "label": "samsung SM-M135F",
    "value": "samsung SM-M135F"
  },
  {
    "name": "HUAWEI DUB-LX1",
    "label": "HUAWEI DUB-LX1",
    "value": "HUAWEI DUB-LX1"
  },
  {
    "name": "motorola Moto Z3 Play",
    "label": "motorola Moto Z3 Play",
    "value": "motorola Moto Z3 Play"
  },
  {
    "name": "samsung SM-A305GT",
    "label": "samsung SM-A305GT",
    "value": "samsung SM-A305GT"
  },
  {
    "name": "rockchip MXQ-4K",
    "label": "rockchip MXQ-4K",
    "value": "rockchip MXQ-4K"
  },
  {
    "name": "samsung SM-A205G",
    "label": "samsung SM-A205G",
    "value": "samsung SM-A205G"
  },
  {
    "name": "Realme RMX1941",
    "label": "Realme RMX1941",
    "value": "Realme RMX1941"
  },
  {
    "name": "LGE LM-K420",
    "label": "LGE LM-K420",
    "value": "LGE LM-K420"
  },
  {
    "name": "Mobicel TITAN",
    "label": "Mobicel TITAN",
    "value": "Mobicel TITAN"
  },
  {
    "name": "motorola moto g(100)",
    "label": "motorola moto g(100)",
    "value": "motorola moto g(100)"
  },
  {
    "name": "Xiaomi M2006C3LI",
    "label": "Xiaomi M2006C3LI",
    "value": "Xiaomi M2006C3LI"
  },
  {
    "name": "DOOGEE X90",
    "label": "DOOGEE X90",
    "value": "DOOGEE X90"
  },
  {
    "name": "Apple iPhone10,6",
    "label": "Apple iPhone10,6",
    "value": "Apple iPhone10,6"
  },
  {
    "name": "motorola moto g(10)",
    "label": "motorola moto g(10)",
    "value": "motorola moto g(10)"
  },
  {
    "name": "motorola moto g(9) play",
    "label": "motorola moto g(9) play",
    "value": "motorola moto g(9) play"
  },
  {
    "name": "vivo V2111",
    "label": "vivo V2111",
    "value": "vivo V2111"
  },
  {
    "name": "HMD Global Nokia G50",
    "label": "HMD Global Nokia G50",
    "value": "HMD Global Nokia G50"
  },
  {
    "name": "motorola moto g31(w)",
    "label": "motorola moto g31(w)",
    "value": "motorola moto g31(w)"
  },
  {
    "name": "OnePlus AC2003",
    "label": "OnePlus AC2003",
    "value": "OnePlus AC2003"
  },
  {
    "name": "Xiaomi M2007J20CG",
    "label": "Xiaomi M2007J20CG",
    "value": "Xiaomi M2007J20CG"
  },
  {
    "name": "samsung SM-A325M",
    "label": "samsung SM-A325M",
    "value": "samsung SM-A325M"
  },
  {
    "name": "motorola moto g(7) power",
    "label": "motorola moto g(7) power",
    "value": "motorola moto g(7) power"
  },
  {
    "name": "LGE LM-X210",
    "label": "LGE LM-X210",
    "value": "LGE LM-X210"
  },
  {
    "name": "samsung SM-N9750",
    "label": "samsung SM-N9750",
    "value": "samsung SM-N9750"
  },
  {
    "name": "samsung SM-G960U",
    "label": "samsung SM-G960U",
    "value": "samsung SM-G960U"
  },
  {
    "name": "Mobile Web - Windows",
    "label": "Mobile Web - Windows",
    "value": "Mobile Web - Windows"
  },
  {
    "name": "HUAWEI JKM-LX2",
    "label": "HUAWEI JKM-LX2",
    "value": "HUAWEI JKM-LX2"
  },
  {
    "name": "samsung SM-A405FN",
    "label": "samsung SM-A405FN",
    "value": "samsung SM-A405FN"
  },
  {
    "name": "INFINIX Infinix X670",
    "label": "INFINIX Infinix X670",
    "value": "INFINIX Infinix X670"
  },
  {
    "name": "motorola Moto G (5S)",
    "label": "motorola Moto G (5S)",
    "value": "motorola Moto G (5S)"
  },
  {
    "name": "samsung SM-A107M",
    "label": "samsung SM-A107M",
    "value": "samsung SM-A107M"
  },
  {
    "name": "samsung SM-A207M",
    "label": "samsung SM-A207M",
    "value": "samsung SM-A207M"
  },
  {
    "name": "samsung SM-A032M",
    "label": "samsung SM-A032M",
    "value": "samsung SM-A032M"
  },
  {
    "name": "LGE LGV35",
    "label": "LGE LGV35",
    "value": "LGE LGV35"
  },
  {
    "name": "Google Pixel 3 XL",
    "label": "Google Pixel 3 XL",
    "value": "Google Pixel 3 XL"
  },
  {
    "name": "OUKITEL K6",
    "label": "OUKITEL K6",
    "value": "OUKITEL K6"
  },
  {
    "name": "HUAWEI POT-LX1AF",
    "label": "HUAWEI POT-LX1AF",
    "value": "HUAWEI POT-LX1AF"
  },
  {
    "name": "htc HTC Desire 12+",
    "label": "htc HTC Desire 12+",
    "value": "htc HTC Desire 12+"
  },
  {
    "name": "HUAWEI STK-LX3",
    "label": "HUAWEI STK-LX3",
    "value": "HUAWEI STK-LX3"
  },
  {
    "name": "samsung SM-G780F",
    "label": "samsung SM-G780F",
    "value": "samsung SM-G780F"
  },
  {
    "name": "samsung SM-F415F",
    "label": "samsung SM-F415F",
    "value": "samsung SM-F415F"
  },
  {
    "name": "samsung SM-J610G",
    "label": "samsung SM-J610G",
    "value": "samsung SM-J610G"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KE5S",
    "label": "TECNO MOBILE LIMITED TECNO KE5S",
    "value": "TECNO MOBILE LIMITED TECNO KE5S"
  },
  {
    "name": "HUAWEI PIC-LX9",
    "label": "HUAWEI PIC-LX9",
    "value": "HUAWEI PIC-LX9"
  },
  {
    "name": "Realme RMX1821",
    "label": "Realme RMX1821",
    "value": "Realme RMX1821"
  },
  {
    "name": "HUAWEI HRY-LX1",
    "label": "HUAWEI HRY-LX1",
    "value": "HUAWEI HRY-LX1"
  },
  {
    "name": "samsung SM-M307F",
    "label": "samsung SM-M307F",
    "value": "samsung SM-M307F"
  },
  {
    "name": "samsung SM-A022M",
    "label": "samsung SM-A022M",
    "value": "samsung SM-A022M"
  },
  {
    "name": "motorola moto e(6) plus",
    "label": "motorola moto e(6) plus",
    "value": "motorola moto e(6) plus"
  },
  {
    "name": "motorola moto g(8) play",
    "label": "motorola moto g(8) play",
    "value": "motorola moto g(8) play"
  },
  {
    "name": "OPPO CPH2135",
    "label": "OPPO CPH2135",
    "value": "OPPO CPH2135"
  },
  {
    "name": "HUAWEI LDN-LX2",
    "label": "HUAWEI LDN-LX2",
    "value": "HUAWEI LDN-LX2"
  },
  {
    "name": "Xiaomi 21061119AG",
    "label": "Xiaomi 21061119AG",
    "value": "Xiaomi 21061119AG"
  },
  {
    "name": "HUAWEI VCE-L22",
    "label": "HUAWEI VCE-L22",
    "value": "HUAWEI VCE-L22"
  },
  {
    "name": "HUAWEI LDN-L21",
    "label": "HUAWEI LDN-L21",
    "value": "HUAWEI LDN-L21"
  },
  {
    "name": "samsung SM-A305F",
    "label": "samsung SM-A305F",
    "value": "samsung SM-A305F"
  },
  {
    "name": "samsung SM-G930F",
    "label": "samsung SM-G930F",
    "value": "samsung SM-G930F"
  },
  {
    "name": "samsung SM-F711B",
    "label": "samsung SM-F711B",
    "value": "samsung SM-F711B"
  },
  {
    "name": "samsung SM-A525F",
    "label": "samsung SM-A525F",
    "value": "samsung SM-A525F"
  },
  {
    "name": "samsung SM-T290",
    "label": "samsung SM-T290",
    "value": "samsung SM-T290"
  },
  {
    "name": "KTE G12",
    "label": "KTE G12",
    "value": "KTE G12"
  },
  {
    "name": "samsung SM-A022F",
    "label": "samsung SM-A022F",
    "value": "samsung SM-A022F"
  },
  {
    "name": "LGE LM-K520",
    "label": "LGE LM-K520",
    "value": "LGE LM-K520"
  },
  {
    "name": "vivo vivo 1904",
    "label": "vivo vivo 1904",
    "value": "vivo vivo 1904"
  },
  {
    "name": "HONOR FNE-NX9",
    "label": "HONOR FNE-NX9",
    "value": "HONOR FNE-NX9"
  },
  {
    "name": "Xiaomi M2101K9AG",
    "label": "Xiaomi M2101K9AG",
    "value": "Xiaomi M2101K9AG"
  },
  {
    "name": "samsung SM-A536B",
    "label": "samsung SM-A536B",
    "value": "samsung SM-A536B"
  },
  {
    "name": "Apple iPhone9,4",
    "label": "Apple iPhone9,4",
    "value": "Apple iPhone9,4"
  },
  {
    "name": "Xiaomi Redmi Note 9S",
    "label": "Xiaomi Redmi Note 9S",
    "value": "Xiaomi Redmi Note 9S"
  },
  {
    "name": "Desktop - Xbox",
    "label": "Desktop - Xbox",
    "value": "Desktop - Xbox"
  },
  {
    "name": "motorola moto g(6) play",
    "label": "motorola moto g(6) play",
    "value": "motorola moto g(6) play"
  },
  {
    "name": "vivo V2022",
    "label": "vivo V2022",
    "value": "vivo V2022"
  },
  {
    "name": "Xiaomi M2002J9G",
    "label": "Xiaomi M2002J9G",
    "value": "Xiaomi M2002J9G"
  },
  {
    "name": "Xiaomi Redmi Note 8",
    "label": "Xiaomi Redmi Note 8",
    "value": "Xiaomi Redmi Note 8"
  },
  {
    "name": "Xiaomi M2010J19SY",
    "label": "Xiaomi M2010J19SY",
    "value": "Xiaomi M2010J19SY"
  },
  {
    "name": "OnePlus GN2200",
    "label": "OnePlus GN2200",
    "value": "OnePlus GN2200"
  },
  {
    "name": "samsung SM-J730G",
    "label": "samsung SM-J730G",
    "value": "samsung SM-J730G"
  },
  {
    "name": "samsung SM-A127F",
    "label": "samsung SM-A127F",
    "value": "samsung SM-A127F"
  },
  {
    "name": "ELEVATE LUNA G50",
    "label": "ELEVATE LUNA G50",
    "value": "ELEVATE LUNA G50"
  },
  {
    "name": "Xiaomi M2103K19PG",
    "label": "Xiaomi M2103K19PG",
    "value": "Xiaomi M2103K19PG"
  },
  {
    "name": "samsung SM-J701MT",
    "label": "samsung SM-J701MT",
    "value": "samsung SM-J701MT"
  },
  {
    "name": "Apple iPhone8,1",
    "label": "Apple iPhone8,1",
    "value": "Apple iPhone8,1"
  },
  {
    "name": "samsung SM-A013G",
    "label": "samsung SM-A013G",
    "value": "samsung SM-A013G"
  },
  {
    "name": "Lenovo Lenovo L58041",
    "label": "Lenovo Lenovo L58041",
    "value": "Lenovo Lenovo L58041"
  },
  {
    "name": "OPPO CPH2363",
    "label": "OPPO CPH2363",
    "value": "OPPO CPH2363"
  },
  {
    "name": "Desktop - Android",
    "label": "Desktop - Android",
    "value": "Desktop - Android"
  },
  {
    "name": "HMD Global Nokia 2.4",
    "label": "HMD Global Nokia 2.4",
    "value": "HMD Global Nokia 2.4"
  },
  {
    "name": "HUAWEI STK-L21",
    "label": "HUAWEI STK-L21",
    "value": "HUAWEI STK-L21"
  },
  {
    "name": "motorola Moto E (4)",
    "label": "motorola Moto E (4)",
    "value": "motorola Moto E (4)"
  },
  {
    "name": "samsung SM-J410F",
    "label": "samsung SM-J410F",
    "value": "samsung SM-J410F"
  },
  {
    "name": "samsung SM-A528B",
    "label": "samsung SM-A528B",
    "value": "samsung SM-A528B"
  },
  {
    "name": "Asus ASUS_Z01QD",
    "label": "Asus ASUS_Z01QD",
    "value": "Asus ASUS_Z01QD"
  },
  {
    "name": "Xiaomi Redmi 7A",
    "label": "Xiaomi Redmi 7A",
    "value": "Xiaomi Redmi 7A"
  },
  {
    "name": "samsung SM-A725M",
    "label": "samsung SM-A725M",
    "value": "samsung SM-A725M"
  },
  {
    "name": "HUAWEI ANE-LX1",
    "label": "HUAWEI ANE-LX1",
    "value": "HUAWEI ANE-LX1"
  },
  {
    "name": "samsung SM-A025U1",
    "label": "samsung SM-A025U1",
    "value": "samsung SM-A025U1"
  },
  {
    "name": "samsung SM-A515F",
    "label": "samsung SM-A515F",
    "value": "samsung SM-A515F"
  },
  {
    "name": "asus ASUS_X017DA",
    "label": "asus ASUS_X017DA",
    "value": "asus ASUS_X017DA"
  },
  {
    "name": "OnePlus BE2026",
    "label": "OnePlus BE2026",
    "value": "OnePlus BE2026"
  },
  {
    "name": "LGE LM-X410.F",
    "label": "LGE LM-X410.F",
    "value": "LGE LM-X410.F"
  },
  {
    "name": "samsung SM-G781B",
    "label": "samsung SM-G781B",
    "value": "samsung SM-G781B"
  },
  {
    "name": "Xiaomi POCO X2",
    "label": "Xiaomi POCO X2",
    "value": "Xiaomi POCO X2"
  },
  {
    "name": "Kimfly Mito A67",
    "label": "Kimfly Mito A67",
    "value": "Kimfly Mito A67"
  },
  {
    "name": "Apple iPad7,11",
    "label": "Apple iPad7,11",
    "value": "Apple iPad7,11"
  },
  {
    "name": "OPPO CPH1821",
    "label": "OPPO CPH1821",
    "value": "OPPO CPH1821"
  },
  {
    "name": "Apple iPhone11,6",
    "label": "Apple iPhone11,6",
    "value": "Apple iPhone11,6"
  },
  {
    "name": "samsung SM-A705MN",
    "label": "samsung SM-A705MN",
    "value": "samsung SM-A705MN"
  },
  {
    "name": "motorola moto g52",
    "label": "motorola moto g52",
    "value": "motorola moto g52"
  },
  {
    "name": "samsung SM-A035M",
    "label": "samsung SM-A035M",
    "value": "samsung SM-A035M"
  },
  {
    "name": "Apple iPhone14,3",
    "label": "Apple iPhone14,3",
    "value": "Apple iPhone14,3"
  },
  {
    "name": "Sony G8141",
    "label": "Sony G8141",
    "value": "Sony G8141"
  },
  {
    "name": "OPPO CPH2185",
    "label": "OPPO CPH2185",
    "value": "OPPO CPH2185"
  },
  {
    "name": "Apple iPhone",
    "label": "Apple iPhone",
    "value": "Apple iPhone"
  },
  {
    "name": "motorola moto e40",
    "label": "motorola moto e40",
    "value": "motorola moto e40"
  },
  {
    "name": "Xiaomi 2201117SG",
    "label": "Xiaomi 2201117SG",
    "value": "Xiaomi 2201117SG"
  },
  {
    "name": "Xiaomi M2010J19SG",
    "label": "Xiaomi M2010J19SG",
    "value": "Xiaomi M2010J19SG"
  },
  {
    "name": "HUAWEI MRD-LX1N",
    "label": "HUAWEI MRD-LX1N",
    "value": "HUAWEI MRD-LX1N"
  },
  {
    "name": "HUAWEI VOG-AL00",
    "label": "HUAWEI VOG-AL00",
    "value": "HUAWEI VOG-AL00"
  },
  {
    "name": "Xiaomi Redmi 8",
    "label": "Xiaomi Redmi 8",
    "value": "Xiaomi Redmi 8"
  },
  {
    "name": "samsung SM-A536E",
    "label": "samsung SM-A536E",
    "value": "samsung SM-A536E"
  },
  {
    "name": "Xiaomi M2007J22G",
    "label": "Xiaomi M2007J22G",
    "value": "Xiaomi M2007J22G"
  },
  {
    "name": "realme RMX3085",
    "label": "realme RMX3085",
    "value": "realme RMX3085"
  },
  {
    "name": "samsung SM-N975F",
    "label": "samsung SM-N975F",
    "value": "samsung SM-N975F"
  },
  {
    "name": "motorola moto g(7) play",
    "label": "motorola moto g(7) play",
    "value": "motorola moto g(7) play"
  },
  {
    "name": "samsung SM-S906U",
    "label": "samsung SM-S906U",
    "value": "samsung SM-S906U"
  },
  {
    "name": "samsung SM-A235F",
    "label": "samsung SM-A235F",
    "value": "samsung SM-A235F"
  },
  {
    "name": "samsung SM-T295",
    "label": "samsung SM-T295",
    "value": "samsung SM-T295"
  },
  {
    "name": "HUAWEI JAT-LX3",
    "label": "HUAWEI JAT-LX3",
    "value": "HUAWEI JAT-LX3"
  },
  {
    "name": "motorola moto g41",
    "label": "motorola moto g41",
    "value": "motorola moto g41"
  },
  {
    "name": "Xiaomi 2107113SG",
    "label": "Xiaomi 2107113SG",
    "value": "Xiaomi 2107113SG"
  },
  {
    "name": "motorola moto g(20)",
    "label": "motorola moto g(20)",
    "value": "motorola moto g(20)"
  },
  {
    "name": "samsung SM-G610M",
    "label": "samsung SM-G610M",
    "value": "samsung SM-G610M"
  },
  {
    "name": "Google Pixel 2",
    "label": "Google Pixel 2",
    "value": "Google Pixel 2"
  },
  {
    "name": "LENOVO Lenovo TB-X606XA",
    "label": "LENOVO Lenovo TB-X606XA",
    "value": "LENOVO Lenovo TB-X606XA"
  },
  {
    "name": "Xiaomi Mi A2",
    "label": "Xiaomi Mi A2",
    "value": "Xiaomi Mi A2"
  },
  {
    "name": "Xiaomi 2201117PG",
    "label": "Xiaomi 2201117PG",
    "value": "Xiaomi 2201117PG"
  },
  {
    "name": "positivo Twist 4 Pro",
    "label": "positivo Twist 4 Pro",
    "value": "positivo Twist 4 Pro"
  },
  {
    "name": "Desktop - Mint",
    "label": "Desktop - Mint",
    "value": "Desktop - Mint"
  },
  {
    "name": "Xiaomi M2006C3LII",
    "label": "Xiaomi M2006C3LII",
    "value": "Xiaomi M2006C3LII"
  },
  {
    "name": "PAX M30",
    "label": "PAX M30",
    "value": "PAX M30"
  },
  {
    "name": "ASUS ASUS ROG Phone 6D",
    "label": "ASUS ASUS ROG Phone 6D",
    "value": "ASUS ASUS ROG Phone 6D"
  },
  {
    "name": "samsung SM-G998B",
    "label": "samsung SM-G998B",
    "value": "samsung SM-G998B"
  },
  {
    "name": "vivo vivo 1816",
    "label": "vivo vivo 1816",
    "value": "vivo vivo 1816"
  },
  {
    "name": "realme RMX2156",
    "label": "realme RMX2156",
    "value": "realme RMX2156"
  },
  {
    "name": "samsung SM-A530F",
    "label": "samsung SM-A530F",
    "value": "samsung SM-A530F"
  },
  {
    "name": "samsung SM-A520F",
    "label": "samsung SM-A520F",
    "value": "samsung SM-A520F"
  },
  {
    "name": "samsung SM-M215F",
    "label": "samsung SM-M215F",
    "value": "samsung SM-M215F"
  },
  {
    "name": "samsung SM-G980F",
    "label": "samsung SM-G980F",
    "value": "samsung SM-G980F"
  },
  {
    "name": "OnePlus DE2118",
    "label": "OnePlus DE2118",
    "value": "OnePlus DE2118"
  },
  {
    "name": "WIKO View XL",
    "label": "WIKO View XL",
    "value": "WIKO View XL"
  },
  {
    "name": "Apple iPhone8,2",
    "label": "Apple iPhone8,2",
    "value": "Apple iPhone8,2"
  },
  {
    "name": "WIKO W-K610-FRA",
    "label": "WIKO W-K610-FRA",
    "value": "WIKO W-K610-FRA"
  },
  {
    "name": "OPPO CPH2269",
    "label": "OPPO CPH2269",
    "value": "OPPO CPH2269"
  },
  {
    "name": "Xiaomi Redmi Note 7",
    "label": "Xiaomi Redmi Note 7",
    "value": "Xiaomi Redmi Note 7"
  },
  {
    "name": "OPPO CPH1909",
    "label": "OPPO CPH1909",
    "value": "OPPO CPH1909"
  },
  {
    "name": "vivo V2146",
    "label": "vivo V2146",
    "value": "vivo V2146"
  },
  {
    "name": "Sony SOV40",
    "label": "Sony SOV40",
    "value": "Sony SOV40"
  },
  {
    "name": "ZTE ZTE Blade V10",
    "label": "ZTE ZTE Blade V10",
    "value": "ZTE ZTE Blade V10"
  },
  {
    "name": "OPPO CPH2109",
    "label": "OPPO CPH2109",
    "value": "OPPO CPH2109"
  },
  {
    "name": "realme RMX3151",
    "label": "realme RMX3151",
    "value": "realme RMX3151"
  },
  {
    "name": "TCL 9309X_EEA",
    "label": "TCL 9309X_EEA",
    "value": "TCL 9309X_EEA"
  },
  {
    "name": "OPPO RMX1805",
    "label": "OPPO RMX1805",
    "value": "OPPO RMX1805"
  },
  {
    "name": "samsung SM-G975F",
    "label": "samsung SM-G975F",
    "value": "samsung SM-G975F"
  },
  {
    "name": "TCL T781",
    "label": "TCL T781",
    "value": "TCL T781"
  },
  {
    "name": "HUAWEI MAR-LX2",
    "label": "HUAWEI MAR-LX2",
    "value": "HUAWEI MAR-LX2"
  },
  {
    "name": "Desktop - Fedora",
    "label": "Desktop - Fedora",
    "value": "Desktop - Fedora"
  },
  {
    "name": "motorola moto g pure",
    "label": "motorola moto g pure",
    "value": "motorola moto g pure"
  },
  {
    "name": "ITEL MOBILE LIMITED itel W6004",
    "label": "ITEL MOBILE LIMITED itel W6004",
    "value": "ITEL MOBILE LIMITED itel W6004"
  },
  {
    "name": "Xiaomi Redmi Note 8T",
    "label": "Xiaomi Redmi Note 8T",
    "value": "Xiaomi Redmi Note 8T"
  },
  {
    "name": "realme RMX3370",
    "label": "realme RMX3370",
    "value": "realme RMX3370"
  },
  {
    "name": "vivo vivo 1906",
    "label": "vivo vivo 1906",
    "value": "vivo vivo 1906"
  },
  {
    "name": "realme RMX1971",
    "label": "realme RMX1971",
    "value": "realme RMX1971"
  },
  {
    "name": "ZTE ZTE A7030",
    "label": "ZTE ZTE A7030",
    "value": "ZTE ZTE A7030"
  },
  {
    "name": "OPPO CPH2015",
    "label": "OPPO CPH2015",
    "value": "OPPO CPH2015"
  },
  {
    "name": "Mobile Web - Linux",
    "label": "Mobile Web - Linux",
    "value": "Mobile Web - Linux"
  },
  {
    "name": "Xiaomi Redmi 7",
    "label": "Xiaomi Redmi 7",
    "value": "Xiaomi Redmi 7"
  },
  {
    "name": "samsung SM-A105F",
    "label": "samsung SM-A105F",
    "value": "samsung SM-A105F"
  },
  {
    "name": "Apple iPhone10,3",
    "label": "Apple iPhone10,3",
    "value": "Apple iPhone10,3"
  },
  {
    "name": "samsung SM-A736B",
    "label": "samsung SM-A736B",
    "value": "samsung SM-A736B"
  },
  {
    "name": "Apple iPhone10,1",
    "label": "Apple iPhone10,1",
    "value": "Apple iPhone10,1"
  },
  {
    "name": "Xiaomi POCOPHONE F1",
    "label": "Xiaomi POCOPHONE F1",
    "value": "Xiaomi POCOPHONE F1"
  },
  {
    "name": "samsung SM-J600G",
    "label": "samsung SM-J600G",
    "value": "samsung SM-J600G"
  },
  {
    "name": "HUAWEI STK-L22",
    "label": "HUAWEI STK-L22",
    "value": "HUAWEI STK-L22"
  },
  {
    "name": "samsung SM-A025F",
    "label": "samsung SM-A025F",
    "value": "samsung SM-A025F"
  },
  {
    "name": "vivo V2040",
    "label": "vivo V2040",
    "value": "vivo V2040"
  },
  {
    "name": "OPPO CPH2387",
    "label": "OPPO CPH2387",
    "value": "OPPO CPH2387"
  },
  {
    "name": "motorola moto g(60)s",
    "label": "motorola moto g(60)s",
    "value": "motorola moto g(60)s"
  },
  {
    "name": "Apple iPhone11,2",
    "label": "Apple iPhone11,2",
    "value": "Apple iPhone11,2"
  },
  {
    "name": "samsung SM-A600G",
    "label": "samsung SM-A600G",
    "value": "samsung SM-A600G"
  },
  {
    "name": "samsung SM-T225",
    "label": "samsung SM-T225",
    "value": "samsung SM-T225"
  },
  {
    "name": "motorola moto e32",
    "label": "motorola moto e32",
    "value": "motorola moto e32"
  },
  {
    "name": "samsung SM-G780G",
    "label": "samsung SM-G780G",
    "value": "samsung SM-G780G"
  },
  {
    "name": "OPPO CPH2247",
    "label": "OPPO CPH2247",
    "value": "OPPO CPH2247"
  },
  {
    "name": "OPPO CPH2273",
    "label": "OPPO CPH2273",
    "value": "OPPO CPH2273"
  },
  {
    "name": "CUBOT NOTE 7",
    "label": "CUBOT NOTE 7",
    "value": "CUBOT NOTE 7"
  },
  {
    "name": "samsung SM-N980F",
    "label": "samsung SM-N980F",
    "value": "samsung SM-N980F"
  },
  {
    "name": "samsung SM-A505F",
    "label": "samsung SM-A505F",
    "value": "samsung SM-A505F"
  },
  {
    "name": "motorola moto g(8) plus",
    "label": "motorola moto g(8) plus",
    "value": "motorola moto g(8) plus"
  },
  {
    "name": "asus ASUS_X00TDB",
    "label": "asus ASUS_X00TDB",
    "value": "asus ASUS_X00TDB"
  },
  {
    "name": "HUAWEI ELE-L09",
    "label": "HUAWEI ELE-L09",
    "value": "HUAWEI ELE-L09"
  },
  {
    "name": "OPPO CPH1823",
    "label": "OPPO CPH1823",
    "value": "OPPO CPH1823"
  },
  {
    "name": "samsung SM-N960F",
    "label": "samsung SM-N960F",
    "value": "samsung SM-N960F"
  },
  {
    "name": "vivo V2110",
    "label": "vivo V2110",
    "value": "vivo V2110"
  },
  {
    "name": "samsung SM-A013M",
    "label": "samsung SM-A013M",
    "value": "samsung SM-A013M"
  },
  {
    "name": "samsung SM-A037M",
    "label": "samsung SM-A037M",
    "value": "samsung SM-A037M"
  },
  {
    "name": "samsung SM-G991B",
    "label": "samsung SM-G991B",
    "value": "samsung SM-G991B"
  },
  {
    "name": "HUAWEI LYA-AL00",
    "label": "HUAWEI LYA-AL00",
    "value": "HUAWEI LYA-AL00"
  },
  {
    "name": "vivo V2027",
    "label": "vivo V2027",
    "value": "vivo V2027"
  },
  {
    "name": "Apple Chuck iphone",
    "label": "Apple Chuck iphone",
    "value": "Apple Chuck iphone"
  },
  {
    "name": "samsung SM-J260MU",
    "label": "samsung SM-J260MU",
    "value": "samsung SM-J260MU"
  },
  {
    "name": "mediacom 1AZ2T",
    "label": "mediacom 1AZ2T",
    "value": "mediacom 1AZ2T"
  },
  {
    "name": "BLU G5",
    "label": "BLU G5",
    "value": "BLU G5"
  },
  {
    "name": "Mobicel Switch GO",
    "label": "Mobicel Switch GO",
    "value": "Mobicel Switch GO"
  },
  {
    "name": "samsung SM-J415G",
    "label": "samsung SM-J415G",
    "value": "samsung SM-J415G"
  },
  {
    "name": "samsung SM-A207F",
    "label": "samsung SM-A207F",
    "value": "samsung SM-A207F"
  },
  {
    "name": "motorola moto e(7) plus",
    "label": "motorola moto e(7) plus",
    "value": "motorola moto e(7) plus"
  },
  {
    "name": "samsung SM-A205F",
    "label": "samsung SM-A205F",
    "value": "samsung SM-A205F"
  },
  {
    "name": "samsung SM-P555",
    "label": "samsung SM-P555",
    "value": "samsung SM-P555"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X657C",
    "label": "INFINIX MOBILITY LIMITED Infinix X657C",
    "value": "INFINIX MOBILITY LIMITED Infinix X657C"
  },
  {
    "name": "samsung SM-A750FN",
    "label": "samsung SM-A750FN",
    "value": "samsung SM-A750FN"
  },
  {
    "name": "Xiaomi M2006C3MII",
    "label": "Xiaomi M2006C3MII",
    "value": "Xiaomi M2006C3MII"
  },
  {
    "name": "HUAWEI MRD-LX1",
    "label": "HUAWEI MRD-LX1",
    "value": "HUAWEI MRD-LX1"
  },
  {
    "name": "motorola moto g(8) power lite",
    "label": "motorola moto g(8) power lite",
    "value": "motorola moto g(8) power lite"
  },
  {
    "name": "motorola moto e(7i) power",
    "label": "motorola moto e(7i) power",
    "value": "motorola moto e(7i) power"
  },
  {
    "name": "samsung SM-A015M",
    "label": "samsung SM-A015M",
    "value": "samsung SM-A015M"
  },
  {
    "name": "samsung SM-J530Y",
    "label": "samsung SM-J530Y",
    "value": "samsung SM-J530Y"
  },
  {
    "name": "samsung SM-M015G",
    "label": "samsung SM-M015G",
    "value": "samsung SM-M015G"
  },
  {
    "name": "realme RMX2189",
    "label": "realme RMX2189",
    "value": "realme RMX2189"
  },
  {
    "name": "HMD Global Nokia 3.1",
    "label": "HMD Global Nokia 3.1",
    "value": "HMD Global Nokia 3.1"
  },
  {
    "name": "HMD Global Nokia 7.2",
    "label": "HMD Global Nokia 7.2",
    "value": "HMD Global Nokia 7.2"
  },
  {
    "name": "samsung SM-A225F",
    "label": "samsung SM-A225F",
    "value": "samsung SM-A225F"
  },
  {
    "name": "motorola moto e5 play",
    "label": "motorola moto e5 play",
    "value": "motorola moto e5 play"
  },
  {
    "name": "vivo V2131",
    "label": "vivo V2131",
    "value": "vivo V2131"
  },
  {
    "name": "samsung SM-A105G",
    "label": "samsung SM-A105G",
    "value": "samsung SM-A105G"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X682C",
    "label": "INFINIX MOBILITY LIMITED Infinix X682C",
    "value": "INFINIX MOBILITY LIMITED Infinix X682C"
  },
  {
    "name": "samsung SM-J701F",
    "label": "samsung SM-J701F",
    "value": "samsung SM-J701F"
  },
  {
    "name": "Xiaomi M2101K7AG",
    "label": "Xiaomi M2101K7AG",
    "value": "Xiaomi M2101K7AG"
  },
  {
    "name": "OnePlus BE2029",
    "label": "OnePlus BE2029",
    "value": "OnePlus BE2029"
  },
  {
    "name": "samsung SM-G991U",
    "label": "samsung SM-G991U",
    "value": "samsung SM-G991U"
  },
  {
    "name": "samsung SM-A730F",
    "label": "samsung SM-A730F",
    "value": "samsung SM-A730F"
  },
  {
    "name": "samsung SM-G610F",
    "label": "samsung SM-G610F",
    "value": "samsung SM-G610F"
  },
  {
    "name": "samsung SM-J810M",
    "label": "samsung SM-J810M",
    "value": "samsung SM-J810M"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6812B",
    "label": "INFINIX MOBILITY LIMITED Infinix X6812B",
    "value": "INFINIX MOBILITY LIMITED Infinix X6812B"
  },
  {
    "name": "Xiaomi 2201117TG",
    "label": "Xiaomi 2201117TG",
    "value": "Xiaomi 2201117TG"
  },
  {
    "name": "HUAWEI DUB-LX3",
    "label": "HUAWEI DUB-LX3",
    "value": "HUAWEI DUB-LX3"
  },
  {
    "name": "Hisense Hisense E31 Lite",
    "label": "Hisense Hisense E31 Lite",
    "value": "Hisense Hisense E31 Lite"
  },
  {
    "name": "OPPO CPH1853",
    "label": "OPPO CPH1853",
    "value": "OPPO CPH1853"
  },
  {
    "name": "samsung SM-A326K",
    "label": "samsung SM-A326K",
    "value": "samsung SM-A326K"
  },
  {
    "name": "HUAWEI DRA-LX5",
    "label": "HUAWEI DRA-LX5",
    "value": "HUAWEI DRA-LX5"
  },
  {
    "name": "Apple iPhone9,1",
    "label": "Apple iPhone9,1",
    "value": "Apple iPhone9,1"
  },
  {
    "name": "TCL 5002E",
    "label": "TCL 5002E",
    "value": "TCL 5002E"
  },
  {
    "name": "realme RMX1911",
    "label": "realme RMX1911",
    "value": "realme RMX1911"
  },
  {
    "name": "LGE LM-G850",
    "label": "LGE LM-G850",
    "value": "LGE LM-G850"
  },
  {
    "name": "samsung SM-T515",
    "label": "samsung SM-T515",
    "value": "samsung SM-T515"
  },
  {
    "name": "HUAWEI MAR-LX3Bm",
    "label": "HUAWEI MAR-LX3Bm",
    "value": "HUAWEI MAR-LX3Bm"
  },
  {
    "name": "vivo vivo 1820",
    "label": "vivo vivo 1820",
    "value": "vivo vivo 1820"
  },
  {
    "name": "samsung SM-G990E",
    "label": "samsung SM-G990E",
    "value": "samsung SM-G990E"
  },
  {
    "name": "vivo vivo 1907",
    "label": "vivo vivo 1907",
    "value": "vivo vivo 1907"
  },
  {
    "name": "OPPO CPH2159",
    "label": "OPPO CPH2159",
    "value": "OPPO CPH2159"
  },
  {
    "name": "HUAWEI LYA-L29",
    "label": "HUAWEI LYA-L29",
    "value": "HUAWEI LYA-L29"
  },
  {
    "name": "samsung SM-X205",
    "label": "samsung SM-X205",
    "value": "samsung SM-X205"
  },
  {
    "name": "Multilaser M7_3G_A10",
    "label": "Multilaser M7_3G_A10",
    "value": "Multilaser M7_3G_A10"
  },
  {
    "name": "vivo vivo 1807",
    "label": "vivo vivo 1807",
    "value": "vivo vivo 1807"
  },
  {
    "name": "realme RMX3263",
    "label": "realme RMX3263",
    "value": "realme RMX3263"
  },
  {
    "name": "Xiaomi Redmi 5",
    "label": "Xiaomi Redmi 5",
    "value": "Xiaomi Redmi 5"
  },
  {
    "name": "HUAWEI MRD-LX1F",
    "label": "HUAWEI MRD-LX1F",
    "value": "HUAWEI MRD-LX1F"
  },
  {
    "name": "HMD Global Nokia C20",
    "label": "HMD Global Nokia C20",
    "value": "HMD Global Nokia C20"
  },
  {
    "name": "samsung SM-J530G",
    "label": "samsung SM-J530G",
    "value": "samsung SM-J530G"
  },
  {
    "name": "motorola Moto G (5)",
    "label": "motorola Moto G (5)",
    "value": "motorola Moto G (5)"
  },
  {
    "name": "vivo vivo 2018",
    "label": "vivo vivo 2018",
    "value": "vivo vivo 2018"
  },
  {
    "name": "LGE LM-K200",
    "label": "LGE LM-K200",
    "value": "LGE LM-K200"
  },
  {
    "name": "samsung SM-J260M",
    "label": "samsung SM-J260M",
    "value": "samsung SM-J260M"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X689F",
    "label": "INFINIX MOBILITY LIMITED Infinix X689F",
    "value": "INFINIX MOBILITY LIMITED Infinix X689F"
  },
  {
    "name": "vivo V2038",
    "label": "vivo V2038",
    "value": "vivo V2038"
  },
  {
    "name": "samsung SM-A505GT",
    "label": "samsung SM-A505GT",
    "value": "samsung SM-A505GT"
  },
  {
    "name": "Apple iPhone12,8",
    "label": "Apple iPhone12,8",
    "value": "Apple iPhone12,8"
  },
  {
    "name": "Xiaomi Redmi 6",
    "label": "Xiaomi Redmi 6",
    "value": "Xiaomi Redmi 6"
  },
  {
    "name": "WIKO W-V600",
    "label": "WIKO W-V600",
    "value": "WIKO W-V600"
  },
  {
    "name": "HMD Global Nokia C10",
    "label": "HMD Global Nokia C10",
    "value": "HMD Global Nokia C10"
  },
  {
    "name": "OPPO CPH1937",
    "label": "OPPO CPH1937",
    "value": "OPPO CPH1937"
  },
  {
    "name": "OnePlus ONEPLUS A5000",
    "label": "OnePlus ONEPLUS A5000",
    "value": "OnePlus ONEPLUS A5000"
  },
  {
    "name": "Xiaomi Redmi 5 Plus",
    "label": "Xiaomi Redmi 5 Plus",
    "value": "Xiaomi Redmi 5 Plus"
  },
  {
    "name": "samsung SM-P610",
    "label": "samsung SM-P610",
    "value": "samsung SM-P610"
  },
  {
    "name": "motorola motorola edge 20",
    "label": "motorola motorola edge 20",
    "value": "motorola motorola edge 20"
  },
  {
    "name": "Xiaomi 2201117TL",
    "label": "Xiaomi 2201117TL",
    "value": "Xiaomi 2201117TL"
  },
  {
    "name": "motorola moto g22",
    "label": "motorola moto g22",
    "value": "motorola moto g22"
  },
  {
    "name": "samsung SM-G570M",
    "label": "samsung SM-G570M",
    "value": "samsung SM-G570M"
  },
  {
    "name": "LGE LM-X525",
    "label": "LGE LM-X525",
    "value": "LGE LM-X525"
  },
  {
    "name": "motorola moto e5",
    "label": "motorola moto e5",
    "value": "motorola moto e5"
  },
  {
    "name": "realme RMX3521",
    "label": "realme RMX3521",
    "value": "realme RMX3521"
  },
  {
    "name": "samsung SM-A217M",
    "label": "samsung SM-A217M",
    "value": "samsung SM-A217M"
  },
  {
    "name": "samsung SM-J600FN",
    "label": "samsung SM-J600FN",
    "value": "samsung SM-J600FN"
  },
  {
    "name": "Sony G3426",
    "label": "Sony G3426",
    "value": "Sony G3426"
  },
  {
    "name": "asus ASUS_X00TD",
    "label": "asus ASUS_X00TD",
    "value": "asus ASUS_X00TD"
  },
  {
    "name": "motorola moto g200 5G",
    "label": "motorola moto g200 5G",
    "value": "motorola moto g200 5G"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KF6p",
    "label": "TECNO MOBILE LIMITED TECNO KF6p",
    "value": "TECNO MOBILE LIMITED TECNO KF6p"
  },
  {
    "name": "TCL 8092_EEA",
    "label": "TCL 8092_EEA",
    "value": "TCL 8092_EEA"
  },
  {
    "name": "realme RMX3231",
    "label": "realme RMX3231",
    "value": "realme RMX3231"
  },
  {
    "name": "OPPO CPH2083",
    "label": "OPPO CPH2083",
    "value": "OPPO CPH2083"
  },
  {
    "name": "ZTE ZTE Blade A31 Plus",
    "label": "ZTE ZTE Blade A31 Plus",
    "value": "ZTE ZTE Blade A31 Plus"
  },
  {
    "name": "samsung SM-A025M",
    "label": "samsung SM-A025M",
    "value": "samsung SM-A025M"
  },
  {
    "name": "samsung SM-A516B",
    "label": "samsung SM-A516B",
    "value": "samsung SM-A516B"
  },
  {
    "name": "Xiaomi 2201116TG",
    "label": "Xiaomi 2201116TG",
    "value": "Xiaomi 2201116TG"
  },
  {
    "name": "Xiaomi 2201117TY",
    "label": "Xiaomi 2201117TY",
    "value": "Xiaomi 2201117TY"
  },
  {
    "name": "vivo vivo Y85A",
    "label": "vivo vivo Y85A",
    "value": "vivo vivo Y85A"
  },
  {
    "name": "realme RMX3461",
    "label": "realme RMX3461",
    "value": "realme RMX3461"
  },
  {
    "name": "Apple iPhone15,3",
    "label": "Apple iPhone15,3",
    "value": "Apple iPhone15,3"
  },
  {
    "name": "samsung SM-J600GT",
    "label": "samsung SM-J600GT",
    "value": "samsung SM-J600GT"
  },
  {
    "name": "HUAWEI CLT-L29",
    "label": "HUAWEI CLT-L29",
    "value": "HUAWEI CLT-L29"
  },
  {
    "name": "Xiaomi 21061119DG",
    "label": "Xiaomi 21061119DG",
    "value": "Xiaomi 21061119DG"
  },
  {
    "name": "Apple iPad5,4",
    "label": "Apple iPad5,4",
    "value": "Apple iPad5,4"
  },
  {
    "name": "samsung SM-J410G",
    "label": "samsung SM-J410G",
    "value": "samsung SM-J410G"
  },
  {
    "name": "Mobile Web - Windows Phone",
    "label": "Mobile Web - Windows Phone",
    "value": "Mobile Web - Windows Phone"
  },
  {
    "name": "motorola moto g(30)",
    "label": "motorola moto g(30)",
    "value": "motorola moto g(30)"
  },
  {
    "name": "Mobile Web - WEBOS",
    "label": "Mobile Web - WEBOS",
    "value": "Mobile Web - WEBOS"
  },
  {
    "name": "Apple iPhone SE (1st generation)",
    "label": "Apple iPhone SE (1st generation)",
    "value": "Apple iPhone SE (1st generation)"
  },
  {
    "name": "samsung SM-A115M",
    "label": "samsung SM-A115M",
    "value": "samsung SM-A115M"
  },
  {
    "name": "motorola moto g(60)",
    "label": "motorola moto g(60)",
    "value": "motorola moto g(60)"
  },
  {
    "name": "KYOCERA KYV46",
    "label": "KYOCERA KYV46",
    "value": "KYOCERA KYV46"
  },
  {
    "name": "samsung SM-J730GM",
    "label": "samsung SM-J730GM",
    "value": "samsung SM-J730GM"
  },
  {
    "name": "samsung SM-M305M",
    "label": "samsung SM-M305M",
    "value": "samsung SM-M305M"
  },
  {
    "name": "KYOCERA E7110",
    "label": "KYOCERA E7110",
    "value": "KYOCERA E7110"
  },
  {
    "name": "motorola motorola one fusion",
    "label": "motorola motorola one fusion",
    "value": "motorola motorola one fusion"
  },
  {
    "name": "Lenovo Lenovo L38111",
    "label": "Lenovo Lenovo L38111",
    "value": "Lenovo Lenovo L38111"
  },
  {
    "name": "ZTE ZTE Blade L8",
    "label": "ZTE ZTE Blade L8",
    "value": "ZTE ZTE Blade L8"
  },
  {
    "name": "Xiaomi M2101K7BG",
    "label": "Xiaomi M2101K7BG",
    "value": "Xiaomi M2101K7BG"
  },
  {
    "name": "realme RMX2195",
    "label": "realme RMX2195",
    "value": "realme RMX2195"
  },
  {
    "name": "samsung SM-T220",
    "label": "samsung SM-T220",
    "value": "samsung SM-T220"
  },
  {
    "name": "OPPO RMX1811",
    "label": "OPPO RMX1811",
    "value": "OPPO RMX1811"
  },
  {
    "name": "OnePlus DN2101",
    "label": "OnePlus DN2101",
    "value": "OnePlus DN2101"
  },
  {
    "name": "Hisense Hisense E50 Lite",
    "label": "Hisense Hisense E50 Lite",
    "value": "Hisense Hisense E50 Lite"
  },
  {
    "name": "LGE LM-K510",
    "label": "LGE LM-K510",
    "value": "LGE LM-K510"
  },
  {
    "name": "samsung SM-A525M",
    "label": "samsung SM-A525M",
    "value": "samsung SM-A525M"
  },
  {
    "name": "samsung SM-G996W",
    "label": "samsung SM-G996W",
    "value": "samsung SM-G996W"
  },
  {
    "name": "Mobile Web - Mac OS",
    "label": "Mobile Web - Mac OS",
    "value": "Mobile Web - Mac OS"
  },
  {
    "name": "realme RMX2001",
    "label": "realme RMX2001",
    "value": "realme RMX2001"
  },
  {
    "name": "vivo vivo 1819",
    "label": "vivo vivo 1819",
    "value": "vivo vivo 1819"
  },
  {
    "name": "Apple iPad12,1",
    "label": "Apple iPad12,1",
    "value": "Apple iPad12,1"
  },
  {
    "name": "Xiaomi 22041216G",
    "label": "Xiaomi 22041216G",
    "value": "Xiaomi 22041216G"
  },
  {
    "name": "LGE LM-K410",
    "label": "LGE LM-K410",
    "value": "LGE LM-K410"
  },
  {
    "name": "samsung SM-T580",
    "label": "samsung SM-T580",
    "value": "samsung SM-T580"
  },
  {
    "name": "samsung SM-J250G",
    "label": "samsung SM-J250G",
    "value": "samsung SM-J250G"
  },
  {
    "name": "Xiaomi M2012K11AG",
    "label": "Xiaomi M2012K11AG",
    "value": "Xiaomi M2012K11AG"
  },
  {
    "name": "Xiaomi Redmi 8A",
    "label": "Xiaomi Redmi 8A",
    "value": "Xiaomi Redmi 8A"
  },
  {
    "name": "samsung SM-A307GT",
    "label": "samsung SM-A307GT",
    "value": "samsung SM-A307GT"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X662",
    "label": "INFINIX MOBILITY LIMITED Infinix X662",
    "value": "INFINIX MOBILITY LIMITED Infinix X662"
  },
  {
    "name": "Xiaomi 21061119AL",
    "label": "Xiaomi 21061119AL",
    "value": "Xiaomi 21061119AL"
  },
  {
    "name": "NUU S6303L",
    "label": "NUU S6303L",
    "value": "NUU S6303L"
  },
  {
    "name": "itel itel A661W",
    "label": "itel itel A661W",
    "value": "itel itel A661W"
  },
  {
    "name": "HMD Global Nokia 5.4",
    "label": "HMD Global Nokia 5.4",
    "value": "HMD Global Nokia 5.4"
  },
  {
    "name": "OnePlus BE2011",
    "label": "OnePlus BE2011",
    "value": "OnePlus BE2011"
  },
  {
    "name": "KODAK D65LX",
    "label": "KODAK D65LX",
    "value": "KODAK D65LX"
  },
  {
    "name": "Apple iPhone9,2",
    "label": "Apple iPhone9,2",
    "value": "Apple iPhone9,2"
  },
  {
    "name": "TCL 5101J",
    "label": "TCL 5101J",
    "value": "TCL 5101J"
  },
  {
    "name": "Xiaomi Redmi Note 5",
    "label": "Xiaomi Redmi Note 5",
    "value": "Xiaomi Redmi Note 5"
  },
  {
    "name": "samsung SM-J710MN",
    "label": "samsung SM-J710MN",
    "value": "samsung SM-J710MN"
  },
  {
    "name": "Xiaomi Redmi Note 5 Pro",
    "label": "Xiaomi Redmi Note 5 Pro",
    "value": "Xiaomi Redmi Note 5 Pro"
  },
  {
    "name": "HUAWEI POT-LX3",
    "label": "HUAWEI POT-LX3",
    "value": "HUAWEI POT-LX3"
  },
  {
    "name": "HUAWEI COL-L29",
    "label": "HUAWEI COL-L29",
    "value": "HUAWEI COL-L29"
  },
  {
    "name": "LGE LG-M700",
    "label": "LGE LG-M700",
    "value": "LGE LG-M700"
  },
  {
    "name": "motorola motorola edge 20 lite",
    "label": "motorola motorola edge 20 lite",
    "value": "motorola motorola edge 20 lite"
  },
  {
    "name": "samsung SM-N770F",
    "label": "samsung SM-N770F",
    "value": "samsung SM-N770F"
  },
  {
    "name": "OPPO CPH2095",
    "label": "OPPO CPH2095",
    "value": "OPPO CPH2095"
  },
  {
    "name": "Apple iPhone10,2",
    "label": "Apple iPhone10,2",
    "value": "Apple iPhone10,2"
  },
  {
    "name": "samsung SM-A135M",
    "label": "samsung SM-A135M",
    "value": "samsung SM-A135M"
  },
  {
    "name": "FiGi Note_1",
    "label": "FiGi Note_1",
    "value": "FiGi Note_1"
  },
  {
    "name": "HUAWEI SNE-LX1",
    "label": "HUAWEI SNE-LX1",
    "value": "HUAWEI SNE-LX1"
  },
  {
    "name": "motorola moto g31",
    "label": "motorola moto g31",
    "value": "motorola moto g31"
  },
  {
    "name": "samsung SM-P601",
    "label": "samsung SM-P601",
    "value": "samsung SM-P601"
  },
  {
    "name": "motorola moto e22",
    "label": "motorola moto e22",
    "value": "motorola moto e22"
  },
  {
    "name": "motorola motorola one fusion+",
    "label": "motorola motorola one fusion+",
    "value": "motorola motorola one fusion+"
  },
  {
    "name": "samsung SM-A025U",
    "label": "samsung SM-A025U",
    "value": "samsung SM-A025U"
  },
  {
    "name": "HMD Global TA-1053",
    "label": "HMD Global TA-1053",
    "value": "HMD Global TA-1053"
  },
  {
    "name": "Xiaomi Mi A3",
    "label": "Xiaomi Mi A3",
    "value": "Xiaomi Mi A3"
  },
  {
    "name": "OPPO CPH1955",
    "label": "OPPO CPH1955",
    "value": "OPPO CPH1955"
  },
  {
    "name": "motorola moto g(6) plus",
    "label": "motorola moto g(6) plus",
    "value": "motorola moto g(6) plus"
  },
  {
    "name": "motorola motorola one",
    "label": "motorola motorola one",
    "value": "motorola motorola one"
  },
  {
    "name": "ZTE ZTE Blade A5 2020",
    "label": "ZTE ZTE Blade A5 2020",
    "value": "ZTE ZTE Blade A5 2020"
  },
  {
    "name": "Multilaser Multilaser_F_Pro_2",
    "label": "Multilaser Multilaser_F_Pro_2",
    "value": "Multilaser Multilaser_F_Pro_2"
  },
  {
    "name": "realme RMX1921",
    "label": "realme RMX1921",
    "value": "realme RMX1921"
  },
  {
    "name": "HMD Global Nokia C1 Plus",
    "label": "HMD Global Nokia C1 Plus",
    "value": "HMD Global Nokia C1 Plus"
  },
  {
    "name": "OnePlus AC2001",
    "label": "OnePlus AC2001",
    "value": "OnePlus AC2001"
  },
  {
    "name": "Xiaomi 2201116PG",
    "label": "Xiaomi 2201116PG",
    "value": "Xiaomi 2201116PG"
  },
  {
    "name": "HUAWEI RNE-L21",
    "label": "HUAWEI RNE-L21",
    "value": "HUAWEI RNE-L21"
  },
  {
    "name": "samsung SM-M315F",
    "label": "samsung SM-M315F",
    "value": "samsung SM-M315F"
  },
  {
    "name": "samsung SM-A705FN",
    "label": "samsung SM-A705FN",
    "value": "samsung SM-A705FN"
  },
  {
    "name": "Apple iPhone13,3",
    "label": "Apple iPhone13,3",
    "value": "Apple iPhone13,3"
  },
  {
    "name": "Xiaomi 21091116AG",
    "label": "Xiaomi 21091116AG",
    "value": "Xiaomi 21091116AG"
  },
  {
    "name": "HUAWEI MAR-LX3A",
    "label": "HUAWEI MAR-LX3A",
    "value": "HUAWEI MAR-LX3A"
  },
  {
    "name": "Xiaomi M2006C3MG",
    "label": "Xiaomi M2006C3MG",
    "value": "Xiaomi M2006C3MG"
  },
  {
    "name": "motorola moto g stylus (2021)",
    "label": "motorola moto g stylus (2021)",
    "value": "motorola moto g stylus (2021)"
  },
  {
    "name": "motorola moto e(6i)",
    "label": "motorola moto e(6i)",
    "value": "motorola moto e(6i)"
  },
  {
    "name": "samsung SM-M127F",
    "label": "samsung SM-M127F",
    "value": "samsung SM-M127F"
  },
  {
    "name": "itel itel L6502",
    "label": "itel itel L6502",
    "value": "itel itel L6502"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X665B",
    "label": "INFINIX MOBILITY LIMITED Infinix X665B",
    "value": "INFINIX MOBILITY LIMITED Infinix X665B"
  },
  {
    "name": "samsung SM-A125M",
    "label": "samsung SM-A125M",
    "value": "samsung SM-A125M"
  },
  {
    "name": "samsung SM-M205M",
    "label": "samsung SM-M205M",
    "value": "samsung SM-M205M"
  },
  {
    "name": "Xiaomi M2007J17G",
    "label": "Xiaomi M2007J17G",
    "value": "Xiaomi M2007J17G"
  },
  {
    "name": "FUJITSU F-02L",
    "label": "FUJITSU F-02L",
    "value": "FUJITSU F-02L"
  },
  {
    "name": "BLU M8L",
    "label": "BLU M8L",
    "value": "BLU M8L"
  },
  {
    "name": "HUAWEI DRA-LX2",
    "label": "HUAWEI DRA-LX2",
    "value": "HUAWEI DRA-LX2"
  },
  {
    "name": "Infinix Infinix PR652B",
    "label": "Infinix Infinix PR652B",
    "value": "Infinix Infinix PR652B"
  },
  {
    "name": "Xiaomi 220333QAG",
    "label": "Xiaomi 220333QAG",
    "value": "Xiaomi 220333QAG"
  },
  {
    "name": "motorola moto g(9) plus",
    "label": "motorola moto g(9) plus",
    "value": "motorola moto g(9) plus"
  },
  {
    "name": "TCL TECTOY_5029Y",
    "label": "TCL TECTOY_5029Y",
    "value": "TCL TECTOY_5029Y"
  },
  {
    "name": "Hisense Hisense Infinity E30",
    "label": "Hisense Hisense Infinity E30",
    "value": "Hisense Hisense Infinity E30"
  },
  {
    "name": "motorola moto g 5G",
    "label": "motorola moto g 5G",
    "value": "motorola moto g 5G"
  },
  {
    "name": "vivo vivo 1804",
    "label": "vivo vivo 1804",
    "value": "vivo vivo 1804"
  },
  {
    "name": "motorola Moto G (5) Plus",
    "label": "motorola Moto G (5) Plus",
    "value": "motorola Moto G (5) Plus"
  },
  {
    "name": "Xiaomi M2006C3MI",
    "label": "Xiaomi M2006C3MI",
    "value": "Xiaomi M2006C3MI"
  },
  {
    "name": "HTC HTC U11 plus",
    "label": "HTC HTC U11 plus",
    "value": "HTC HTC U11 plus"
  },
  {
    "name": "HUAWEI PCT-L29",
    "label": "HUAWEI PCT-L29",
    "value": "HUAWEI PCT-L29"
  },
  {
    "name": "Mobile App - android",
    "label": "Mobile App - android",
    "value": "Mobile App - android"
  },
  {
    "name": "Realme RMX1825",
    "label": "Realme RMX1825",
    "value": "Realme RMX1825"
  },
  {
    "name": "Apple iPad13,1",
    "label": "Apple iPad13,1",
    "value": "Apple iPad13,1"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KC2",
    "label": "TECNO MOBILE LIMITED TECNO KC2",
    "value": "TECNO MOBILE LIMITED TECNO KC2"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KE6",
    "label": "TECNO MOBILE LIMITED TECNO KE6",
    "value": "TECNO MOBILE LIMITED TECNO KE6"
  },
  {
    "name": "OPPO CPH1819",
    "label": "OPPO CPH1819",
    "value": "OPPO CPH1819"
  },
  {
    "name": "vivo vivo 1938",
    "label": "vivo vivo 1938",
    "value": "vivo vivo 1938"
  },
  {
    "name": "HUAWEI VOG-L29",
    "label": "HUAWEI VOG-L29",
    "value": "HUAWEI VOG-L29"
  },
  {
    "name": "Xiaomi Redmi 5A",
    "label": "Xiaomi Redmi 5A",
    "value": "Xiaomi Redmi 5A"
  },
  {
    "name": "OPPO CPH2325",
    "label": "OPPO CPH2325",
    "value": "OPPO CPH2325"
  },
  {
    "name": "TCL 5061K_EEA",
    "label": "TCL 5061K_EEA",
    "value": "TCL 5061K_EEA"
  },
  {
    "name": "Hisense Hisense E40",
    "label": "Hisense Hisense E40",
    "value": "Hisense Hisense E40"
  },
  {
    "name": "samsung SM-G991N",
    "label": "samsung SM-G991N",
    "value": "samsung SM-G991N"
  },
  {
    "name": "Desktop - Raspbian",
    "label": "Desktop - Raspbian",
    "value": "Desktop - Raspbian"
  },
  {
    "name": "OnePlus GM1903",
    "label": "OnePlus GM1903",
    "value": "OnePlus GM1903"
  },
  {
    "name": "samsung SM-G9600",
    "label": "samsung SM-G9600",
    "value": "samsung SM-G9600"
  },
  {
    "name": "samsung SM-T735",
    "label": "samsung SM-T735",
    "value": "samsung SM-T735"
  },
  {
    "name": "Xiaomi Redmi Y1",
    "label": "Xiaomi Redmi Y1",
    "value": "Xiaomi Redmi Y1"
  },
  {
    "name": "OPPO CPH2161",
    "label": "OPPO CPH2161",
    "value": "OPPO CPH2161"
  },
  {
    "name": "HMD Global Nokia 6.1 Plus",
    "label": "HMD Global Nokia 6.1 Plus",
    "value": "HMD Global Nokia 6.1 Plus"
  },
  {
    "name": "samsung SM-A526B",
    "label": "samsung SM-A526B",
    "value": "samsung SM-A526B"
  },
  {
    "name": "Xiaomi Redmi 6A",
    "label": "Xiaomi Redmi 6A",
    "value": "Xiaomi Redmi 6A"
  },
  {
    "name": "asus ASUS_X01BDA",
    "label": "asus ASUS_X01BDA",
    "value": "asus ASUS_X01BDA"
  },
  {
    "name": "HUAWEI ANE-LX2",
    "label": "HUAWEI ANE-LX2",
    "value": "HUAWEI ANE-LX2"
  },
  {
    "name": "LGE LML212VL",
    "label": "LGE LML212VL",
    "value": "LGE LML212VL"
  },
  {
    "name": "samsung SM-A115F",
    "label": "samsung SM-A115F",
    "value": "samsung SM-A115F"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X688B",
    "label": "INFINIX MOBILITY LIMITED Infinix X688B",
    "value": "INFINIX MOBILITY LIMITED Infinix X688B"
  },
  {
    "name": "OPPO CPH2061",
    "label": "OPPO CPH2061",
    "value": "OPPO CPH2061"
  },
  {
    "name": "OPPO CPH1969",
    "label": "OPPO CPH1969",
    "value": "OPPO CPH1969"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6812",
    "label": "INFINIX MOBILITY LIMITED Infinix X6812",
    "value": "INFINIX MOBILITY LIMITED Infinix X6812"
  },
  {
    "name": "HMD Global TA-1033",
    "label": "HMD Global TA-1033",
    "value": "HMD Global TA-1033"
  },
  {
    "name": "samsung SM-G960N",
    "label": "samsung SM-G960N",
    "value": "samsung SM-G960N"
  },
  {
    "name": "Apple Lebogang’s iPhone",
    "label": "Apple Lebogang’s iPhone",
    "value": "Apple Lebogang’s iPhone"
  },
  {
    "name": "Sony SOV37",
    "label": "Sony SOV37",
    "value": "Sony SOV37"
  },
  {
    "name": "OPPO CPH2349",
    "label": "OPPO CPH2349",
    "value": "OPPO CPH2349"
  },
  {
    "name": "OPPO CPH2235",
    "label": "OPPO CPH2235",
    "value": "OPPO CPH2235"
  },
  {
    "name": "FISE MOSCOW",
    "label": "FISE MOSCOW",
    "value": "FISE MOSCOW"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X693",
    "label": "INFINIX MOBILITY LIMITED Infinix X693",
    "value": "INFINIX MOBILITY LIMITED Infinix X693"
  },
  {
    "name": "OPPO CPH2197",
    "label": "OPPO CPH2197",
    "value": "OPPO CPH2197"
  },
  {
    "name": "samsung SM-M115F",
    "label": "samsung SM-M115F",
    "value": "samsung SM-M115F"
  },
  {
    "name": "samsung SM-J610F",
    "label": "samsung SM-J610F",
    "value": "samsung SM-J610F"
  },
  {
    "name": "samsung SM-G781W",
    "label": "samsung SM-G781W",
    "value": "samsung SM-G781W"
  },
  {
    "name": "HMD Global Nokia 2.2",
    "label": "HMD Global Nokia 2.2",
    "value": "HMD Global Nokia 2.2"
  },
  {
    "name": "samsung SM-A135F",
    "label": "samsung SM-A135F",
    "value": "samsung SM-A135F"
  },
  {
    "name": "samsung SM-N976N",
    "label": "samsung SM-N976N",
    "value": "samsung SM-N976N"
  },
  {
    "name": "HONOR ANY-NX1",
    "label": "HONOR ANY-NX1",
    "value": "HONOR ANY-NX1"
  },
  {
    "name": "Xiaomi Mi A2 Lite",
    "label": "Xiaomi Mi A2 Lite",
    "value": "Xiaomi Mi A2 Lite"
  },
  {
    "name": "STYLO 721",
    "label": "STYLO 721",
    "value": "STYLO 721"
  },
  {
    "name": "vivo vivo 1817",
    "label": "vivo vivo 1817",
    "value": "vivo vivo 1817"
  },
  {
    "name": "OPPO CPH2219",
    "label": "OPPO CPH2219",
    "value": "OPPO CPH2219"
  },
  {
    "name": "vivo vivo V9",
    "label": "vivo vivo V9",
    "value": "vivo vivo V9"
  },
  {
    "name": "asus ASUS_Z012DB",
    "label": "asus ASUS_Z012DB",
    "value": "asus ASUS_Z012DB"
  },
  {
    "name": "samsung SM-N970W",
    "label": "samsung SM-N970W",
    "value": "samsung SM-N970W"
  },
  {
    "name": "vivo V2029",
    "label": "vivo V2029",
    "value": "vivo V2029"
  },
  {
    "name": "OPPO CPH1727",
    "label": "OPPO CPH1727",
    "value": "OPPO CPH1727"
  },
  {
    "name": "HUAWEI JAT-L41",
    "label": "HUAWEI JAT-L41",
    "value": "HUAWEI JAT-L41"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X657B",
    "label": "INFINIX MOBILITY LIMITED Infinix X657B",
    "value": "INFINIX MOBILITY LIMITED Infinix X657B"
  },
  {
    "name": "OnePlus DN2103",
    "label": "OnePlus DN2103",
    "value": "OnePlus DN2103"
  },
  {
    "name": "samsung SM-N935F",
    "label": "samsung SM-N935F",
    "value": "samsung SM-N935F"
  },
  {
    "name": "OPPO CPH1911",
    "label": "OPPO CPH1911",
    "value": "OPPO CPH1911"
  },
  {
    "name": "Xiaomi M2010J19CG",
    "label": "Xiaomi M2010J19CG",
    "value": "Xiaomi M2010J19CG"
  },
  {
    "name": "Xiaomi Redmi S2",
    "label": "Xiaomi Redmi S2",
    "value": "Xiaomi Redmi S2"
  },
  {
    "name": "Xiaomi 2201117TI",
    "label": "Xiaomi 2201117TI",
    "value": "Xiaomi 2201117TI"
  },
  {
    "name": "samsung SM-M326B",
    "label": "samsung SM-M326B",
    "value": "samsung SM-M326B"
  },
  {
    "name": "samsung SM-G970F",
    "label": "samsung SM-G970F",
    "value": "samsung SM-G970F"
  },
  {
    "name": "Xiaomi Mi A1",
    "label": "Xiaomi Mi A1",
    "value": "Xiaomi Mi A1"
  },
  {
    "name": "Mobile Web - Symbian",
    "label": "Mobile Web - Symbian",
    "value": "Mobile Web - Symbian"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KD7S",
    "label": "TECNO MOBILE LIMITED TECNO KD7S",
    "value": "TECNO MOBILE LIMITED TECNO KD7S"
  },
  {
    "name": "samsung SM-A5260",
    "label": "samsung SM-A5260",
    "value": "samsung SM-A5260"
  },
  {
    "name": "ADVAN i5G",
    "label": "ADVAN i5G",
    "value": "ADVAN i5G"
  },
  {
    "name": "vsmart Joy 4",
    "label": "vsmart Joy 4",
    "value": "vsmart Joy 4"
  },
  {
    "name": "vivo vivo 1724",
    "label": "vivo vivo 1724",
    "value": "vivo vivo 1724"
  },
  {
    "name": "samsung SM-N981N",
    "label": "samsung SM-N981N",
    "value": "samsung SM-N981N"
  },
  {
    "name": "Xiaomi Redmi 4A",
    "label": "Xiaomi Redmi 4A",
    "value": "Xiaomi Redmi 4A"
  },
  {
    "name": "HUAWEI WAS-LX1",
    "label": "HUAWEI WAS-LX1",
    "value": "HUAWEI WAS-LX1"
  },
  {
    "name": "samsung SM-S901N",
    "label": "samsung SM-S901N",
    "value": "samsung SM-S901N"
  },
  {
    "name": "Apple iPad6,11",
    "label": "Apple iPad6,11",
    "value": "Apple iPad6,11"
  },
  {
    "name": "vivo vivo 1935",
    "label": "vivo vivo 1935",
    "value": "vivo vivo 1935"
  },
  {
    "name": "Xiaomi M2102J20SI",
    "label": "Xiaomi M2102J20SI",
    "value": "Xiaomi M2102J20SI"
  },
  {
    "name": "samsung SM-F711N",
    "label": "samsung SM-F711N",
    "value": "samsung SM-F711N"
  },
  {
    "name": "samsung SM-T720",
    "label": "samsung SM-T720",
    "value": "samsung SM-T720"
  },
  {
    "name": "vsmart Bee 3",
    "label": "vsmart Bee 3",
    "value": "vsmart Bee 3"
  },
  {
    "name": "OPPO CPH1723",
    "label": "OPPO CPH1723",
    "value": "OPPO CPH1723"
  },
  {
    "name": "Xiaomi Redmi Note 2",
    "label": "Xiaomi Redmi Note 2",
    "value": "Xiaomi Redmi Note 2"
  },
  {
    "name": "motorola moto g42",
    "label": "motorola moto g42",
    "value": "motorola moto g42"
  },
  {
    "name": "OPPO CPH1989",
    "label": "OPPO CPH1989",
    "value": "OPPO CPH1989"
  },
  {
    "name": "vsmart Aris Pro",
    "label": "vsmart Aris Pro",
    "value": "vsmart Aris Pro"
  },
  {
    "name": "Google Pixel 7 Pro",
    "label": "Google Pixel 7 Pro",
    "value": "Google Pixel 7 Pro"
  },
  {
    "name": "Xiaomi MI 8",
    "label": "Xiaomi MI 8",
    "value": "Xiaomi MI 8"
  },
  {
    "name": "Apple XR",
    "label": "Apple XR",
    "value": "Apple XR"
  },
  {
    "name": "samsung SM-G615F",
    "label": "samsung SM-G615F",
    "value": "samsung SM-G615F"
  },
  {
    "name": "HUAWEI PRA-LA1",
    "label": "HUAWEI PRA-LA1",
    "value": "HUAWEI PRA-LA1"
  },
  {
    "name": "Apple Wendy’s iPhone",
    "label": "Apple Wendy’s iPhone",
    "value": "Apple Wendy’s iPhone"
  },
  {
    "name": "Realme RMX1851",
    "label": "Realme RMX1851",
    "value": "Realme RMX1851"
  },
  {
    "name": "HMD Global Nokia C2",
    "label": "HMD Global Nokia C2",
    "value": "HMD Global Nokia C2"
  },
  {
    "name": "realme RMX2151",
    "label": "realme RMX2151",
    "value": "realme RMX2151"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X650C",
    "label": "INFINIX MOBILITY LIMITED Infinix X650C",
    "value": "INFINIX MOBILITY LIMITED Infinix X650C"
  },
  {
    "name": "vivo V2069",
    "label": "vivo V2069",
    "value": "vivo V2069"
  },
  {
    "name": "HUAWEI KSA-LX9",
    "label": "HUAWEI KSA-LX9",
    "value": "HUAWEI KSA-LX9"
  },
  {
    "name": "samsung SM-S9080",
    "label": "samsung SM-S9080",
    "value": "samsung SM-S9080"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KD7",
    "label": "TECNO MOBILE LIMITED TECNO KD7",
    "value": "TECNO MOBILE LIMITED TECNO KD7"
  },
  {
    "name": "vivo vivo 1814",
    "label": "vivo vivo 1814",
    "value": "vivo vivo 1814"
  },
  {
    "name": "Xiaomi 21081111RG",
    "label": "Xiaomi 21081111RG",
    "value": "Xiaomi 21081111RG"
  },
  {
    "name": "HUAWEI AMN-LX2",
    "label": "HUAWEI AMN-LX2",
    "value": "HUAWEI AMN-LX2"
  },
  {
    "name": "vivo V2149",
    "label": "vivo V2149",
    "value": "vivo V2149"
  },
  {
    "name": "OPPO CPH2343",
    "label": "OPPO CPH2343",
    "value": "OPPO CPH2343"
  },
  {
    "name": "samsung SM-G973N",
    "label": "samsung SM-G973N",
    "value": "samsung SM-G973N"
  },
  {
    "name": "samsung SM-J400F",
    "label": "samsung SM-J400F",
    "value": "samsung SM-J400F"
  },
  {
    "name": "vivo vivo Y71A",
    "label": "vivo vivo Y71A",
    "value": "vivo vivo Y71A"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KE5",
    "label": "TECNO MOBILE LIMITED TECNO KE5",
    "value": "TECNO MOBILE LIMITED TECNO KE5"
  },
  {
    "name": "OPPO CPH2001",
    "label": "OPPO CPH2001",
    "value": "OPPO CPH2001"
  },
  {
    "name": "OPPO CPH1729",
    "label": "OPPO CPH1729",
    "value": "OPPO CPH1729"
  },
  {
    "name": "Apple Kimone’s iPhone",
    "label": "Apple Kimone’s iPhone",
    "value": "Apple Kimone’s iPhone"
  },
  {
    "name": "Sony SO-01L",
    "label": "Sony SO-01L",
    "value": "Sony SO-01L"
  },
  {
    "name": "HUAWEI SNE-LX2",
    "label": "HUAWEI SNE-LX2",
    "value": "HUAWEI SNE-LX2"
  },
  {
    "name": "OPPO CPH2365",
    "label": "OPPO CPH2365",
    "value": "OPPO CPH2365"
  },
  {
    "name": "vivo vivo 1920",
    "label": "vivo vivo 1920",
    "value": "vivo vivo 1920"
  },
  {
    "name": "samsung SM-A750GN",
    "label": "samsung SM-A750GN",
    "value": "samsung SM-A750GN"
  },
  {
    "name": "OPPO CPH1803",
    "label": "OPPO CPH1803",
    "value": "OPPO CPH1803"
  },
  {
    "name": "samsung SM-T510",
    "label": "samsung SM-T510",
    "value": "samsung SM-T510"
  },
  {
    "name": "vivo vivo 1802",
    "label": "vivo vivo 1802",
    "value": "vivo vivo 1802"
  },
  {
    "name": "vivo vivo 1901",
    "label": "vivo vivo 1901",
    "value": "vivo vivo 1901"
  },
  {
    "name": "samsung SM-N9500",
    "label": "samsung SM-N9500",
    "value": "samsung SM-N9500"
  },
  {
    "name": "OPPO CPH1923",
    "label": "OPPO CPH1923",
    "value": "OPPO CPH1923"
  },
  {
    "name": "OPPO CPH2203",
    "label": "OPPO CPH2203",
    "value": "OPPO CPH2203"
  },
  {
    "name": "TCL 5030U",
    "label": "TCL 5030U",
    "value": "TCL 5030U"
  },
  {
    "name": "Apple iPhone14,2",
    "label": "Apple iPhone14,2",
    "value": "Apple iPhone14,2"
  },
  {
    "name": "HUAWEI LDN-L01",
    "label": "HUAWEI LDN-L01",
    "value": "HUAWEI LDN-L01"
  },
  {
    "name": "Xiaomi Redmi 4X",
    "label": "Xiaomi Redmi 4X",
    "value": "Xiaomi Redmi 4X"
  },
  {
    "name": "Elephone A6_mini",
    "label": "Elephone A6_mini",
    "value": "Elephone A6_mini"
  },
  {
    "name": "motorola motorola one power",
    "label": "motorola motorola one power",
    "value": "motorola motorola one power"
  },
  {
    "name": "samsung SM-M205G",
    "label": "samsung SM-M205G",
    "value": "samsung SM-M205G"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KF8",
    "label": "TECNO MOBILE LIMITED TECNO KF8",
    "value": "TECNO MOBILE LIMITED TECNO KF8"
  },
  {
    "name": "samsung SM-A260F",
    "label": "samsung SM-A260F",
    "value": "samsung SM-A260F"
  },
  {
    "name": "Xiaomi BASIC",
    "label": "Xiaomi BASIC",
    "value": "Xiaomi BASIC"
  },
  {
    "name": "OPPO CPH2271",
    "label": "OPPO CPH2271",
    "value": "OPPO CPH2271"
  },
  {
    "name": "HUAWEI JSN-L22",
    "label": "HUAWEI JSN-L22",
    "value": "HUAWEI JSN-L22"
  },
  {
    "name": "realme RMX3195",
    "label": "realme RMX3195",
    "value": "realme RMX3195"
  },
  {
    "name": "OPPO CPH2217",
    "label": "OPPO CPH2217",
    "value": "OPPO CPH2217"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X692",
    "label": "INFINIX MOBILITY LIMITED Infinix X692",
    "value": "INFINIX MOBILITY LIMITED Infinix X692"
  },
  {
    "name": "samsung SM-G885F",
    "label": "samsung SM-G885F",
    "value": "samsung SM-G885F"
  },
  {
    "name": "ADVAN 6003",
    "label": "ADVAN 6003",
    "value": "ADVAN 6003"
  },
  {
    "name": "realme RMX2185",
    "label": "realme RMX2185",
    "value": "realme RMX2185"
  },
  {
    "name": "OPPO CPH2209",
    "label": "OPPO CPH2209",
    "value": "OPPO CPH2209"
  },
  {
    "name": "Xiaomi Redmi Note 5A Prime",
    "label": "Xiaomi Redmi Note 5A Prime",
    "value": "Xiaomi Redmi Note 5A Prime"
  },
  {
    "name": "samsung SM-A720F",
    "label": "samsung SM-A720F",
    "value": "samsung SM-A720F"
  },
  {
    "name": "OPPO CPH1901",
    "label": "OPPO CPH1901",
    "value": "OPPO CPH1901"
  },
  {
    "name": "HUAWEI MAR-LX1M",
    "label": "HUAWEI MAR-LX1M",
    "value": "HUAWEI MAR-LX1M"
  },
  {
    "name": "OPPO CPH1717",
    "label": "OPPO CPH1717",
    "value": "OPPO CPH1717"
  },
  {
    "name": "samsung SM-G977N",
    "label": "samsung SM-G977N",
    "value": "samsung SM-G977N"
  },
  {
    "name": "HUAWEI YAL-L21",
    "label": "HUAWEI YAL-L21",
    "value": "HUAWEI YAL-L21"
  },
  {
    "name": "TCL 5028D",
    "label": "TCL 5028D",
    "value": "TCL 5028D"
  },
  {
    "name": "Xiaomi 21121119SG",
    "label": "Xiaomi 21121119SG",
    "value": "Xiaomi 21121119SG"
  },
  {
    "name": "OPPO CPH2371",
    "label": "OPPO CPH2371",
    "value": "OPPO CPH2371"
  },
  {
    "name": "samsung SM-M022F",
    "label": "samsung SM-M022F",
    "value": "samsung SM-M022F"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X689B",
    "label": "INFINIX MOBILITY LIMITED Infinix X689B",
    "value": "INFINIX MOBILITY LIMITED Infinix X689B"
  },
  {
    "name": "samsung SM-G955F",
    "label": "samsung SM-G955F",
    "value": "samsung SM-G955F"
  },
  {
    "name": "LGE LG-H930",
    "label": "LGE LG-H930",
    "value": "LGE LG-H930"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X626B",
    "label": "INFINIX MOBILITY LIMITED Infinix X626B",
    "value": "INFINIX MOBILITY LIMITED Infinix X626B"
  },
  {
    "name": "HUAWEI MAR-LX1B",
    "label": "HUAWEI MAR-LX1B",
    "value": "HUAWEI MAR-LX1B"
  },
  {
    "name": "Xiaomi Redmi Go",
    "label": "Xiaomi Redmi Go",
    "value": "Xiaomi Redmi Go"
  },
  {
    "name": "SNCOIA SP19504",
    "label": "SNCOIA SP19504",
    "value": "SNCOIA SP19504"
  },
  {
    "name": "vivo vivo 1918",
    "label": "vivo vivo 1918",
    "value": "vivo vivo 1918"
  },
  {
    "name": "samsung SM-A505N",
    "label": "samsung SM-A505N",
    "value": "samsung SM-A505N"
  },
  {
    "name": "motorola moto e6",
    "label": "motorola moto e6",
    "value": "motorola moto e6"
  },
  {
    "name": "OPPO CPH1933",
    "label": "OPPO CPH1933",
    "value": "OPPO CPH1933"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X680",
    "label": "INFINIX MOBILITY LIMITED Infinix X680",
    "value": "INFINIX MOBILITY LIMITED Infinix X680"
  },
  {
    "name": "realme RMX3301",
    "label": "realme RMX3301",
    "value": "realme RMX3301"
  },
  {
    "name": "vivo vivo 1611",
    "label": "vivo vivo 1611",
    "value": "vivo vivo 1611"
  },
  {
    "name": "realme RMX2085",
    "label": "realme RMX2085",
    "value": "realme RMX2085"
  },
  {
    "name": "vivo V2043_21",
    "label": "vivo V2043_21",
    "value": "vivo V2043_21"
  },
  {
    "name": "PANASONIC ELUGA X1",
    "label": "PANASONIC ELUGA X1",
    "value": "PANASONIC ELUGA X1"
  },
  {
    "name": "Mobicel BLINK",
    "label": "Mobicel BLINK",
    "value": "Mobicel BLINK"
  },
  {
    "name": "EVERCOSS M6",
    "label": "EVERCOSS M6",
    "value": "EVERCOSS M6"
  },
  {
    "name": "Xiaomi POCO F1",
    "label": "Xiaomi POCO F1",
    "value": "Xiaomi POCO F1"
  },
  {
    "name": "samsung SM-A750F",
    "label": "samsung SM-A750F",
    "value": "samsung SM-A750F"
  },
  {
    "name": "vivo V2059",
    "label": "vivo V2059",
    "value": "vivo V2059"
  },
  {
    "name": "HUAWEI DRA-L21",
    "label": "HUAWEI DRA-L21",
    "value": "HUAWEI DRA-L21"
  },
  {
    "name": "vivo V2031",
    "label": "vivo V2031",
    "value": "vivo V2031"
  },
  {
    "name": "samsung SM-N950F",
    "label": "samsung SM-N950F",
    "value": "samsung SM-N950F"
  },
  {
    "name": "HUAWEI VOG-TL00",
    "label": "HUAWEI VOG-TL00",
    "value": "HUAWEI VOG-TL00"
  },
  {
    "name": "samsung SM-A015F",
    "label": "samsung SM-A015F",
    "value": "samsung SM-A015F"
  },
  {
    "name": "samsung SM-J510GN",
    "label": "samsung SM-J510GN",
    "value": "samsung SM-J510GN"
  },
  {
    "name": "Xiaomi M2007J3SG",
    "label": "Xiaomi M2007J3SG",
    "value": "Xiaomi M2007J3SG"
  },
  {
    "name": "OPPO CPH2113",
    "label": "OPPO CPH2113",
    "value": "OPPO CPH2113"
  },
  {
    "name": "TCL 5102K",
    "label": "TCL 5102K",
    "value": "TCL 5102K"
  },
  {
    "name": "Xiaomi M2010J19SI",
    "label": "Xiaomi M2010J19SI",
    "value": "Xiaomi M2010J19SI"
  },
  {
    "name": "samsung SM-J510FN",
    "label": "samsung SM-J510FN",
    "value": "samsung SM-J510FN"
  },
  {
    "name": "OnePlus GM1911",
    "label": "OnePlus GM1911",
    "value": "OnePlus GM1911"
  },
  {
    "name": "HUAWEI COR-L29",
    "label": "HUAWEI COR-L29",
    "value": "HUAWEI COR-L29"
  },
  {
    "name": "Kleadtone S85",
    "label": "Kleadtone S85",
    "value": "Kleadtone S85"
  },
  {
    "name": "Apple iPhone10,5",
    "label": "Apple iPhone10,5",
    "value": "Apple iPhone10,5"
  },
  {
    "name": "realme RMX3506",
    "label": "realme RMX3506",
    "value": "realme RMX3506"
  },
  {
    "name": "vivo V2120",
    "label": "vivo V2120",
    "value": "vivo V2120"
  },
  {
    "name": "OPPO CPH2069",
    "label": "OPPO CPH2069",
    "value": "OPPO CPH2069"
  },
  {
    "name": "vivo vivo 1811",
    "label": "vivo vivo 1811",
    "value": "vivo vivo 1811"
  },
  {
    "name": "samsung SM-M127G",
    "label": "samsung SM-M127G",
    "value": "samsung SM-M127G"
  },
  {
    "name": "INFINIX Infinix X6823C",
    "label": "INFINIX Infinix X6823C",
    "value": "INFINIX Infinix X6823C"
  },
  {
    "name": "samsung SM-J260F",
    "label": "samsung SM-J260F",
    "value": "samsung SM-J260F"
  },
  {
    "name": "Mobicel HYPE_X",
    "label": "Mobicel HYPE_X",
    "value": "Mobicel HYPE_X"
  },
  {
    "name": "Mobicel switch plus",
    "label": "Mobicel switch plus",
    "value": "Mobicel switch plus"
  },
  {
    "name": "vivo vivo 2007",
    "label": "vivo vivo 2007",
    "value": "vivo vivo 2007"
  },
  {
    "name": "samsung SM-J415F",
    "label": "samsung SM-J415F",
    "value": "samsung SM-J415F"
  },
  {
    "name": "realme RMX3241",
    "label": "realme RMX3241",
    "value": "realme RMX3241"
  },
  {
    "name": "HOTWAV Star",
    "label": "HOTWAV Star",
    "value": "HOTWAV Star"
  },
  {
    "name": "HUAWEI AMN-LX9",
    "label": "HUAWEI AMN-LX9",
    "value": "HUAWEI AMN-LX9"
  },
  {
    "name": "Mobile Web - Tizen",
    "label": "Mobile Web - Tizen",
    "value": "Mobile Web - Tizen"
  },
  {
    "name": "samsung SM-A507FN",
    "label": "samsung SM-A507FN",
    "value": "samsung SM-A507FN"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X682B",
    "label": "INFINIX MOBILITY LIMITED Infinix X682B",
    "value": "INFINIX MOBILITY LIMITED Infinix X682B"
  },
  {
    "name": "Xiaomi Mi Note 10 Lite",
    "label": "Xiaomi Mi Note 10 Lite",
    "value": "Xiaomi Mi Note 10 Lite"
  },
  {
    "name": "samsung SM-G611MT",
    "label": "samsung SM-G611MT",
    "value": "samsung SM-G611MT"
  },
  {
    "name": "realme RMX1973",
    "label": "realme RMX1973",
    "value": "realme RMX1973"
  },
  {
    "name": "HUAWEI LLD-L21",
    "label": "HUAWEI LLD-L21",
    "value": "HUAWEI LLD-L21"
  },
  {
    "name": "vivo vivo 2015",
    "label": "vivo vivo 2015",
    "value": "vivo vivo 2015"
  },
  {
    "name": "samsung SM-G996B",
    "label": "samsung SM-G996B",
    "value": "samsung SM-G996B"
  },
  {
    "name": "TCL 5033G",
    "label": "TCL 5033G",
    "value": "TCL 5033G"
  },
  {
    "name": "samsung SM-J250F",
    "label": "samsung SM-J250F",
    "value": "samsung SM-J250F"
  },
  {
    "name": "Xiaomi HM 1SW",
    "label": "Xiaomi HM 1SW",
    "value": "Xiaomi HM 1SW"
  },
  {
    "name": "HUAWEI RNE-L22",
    "label": "HUAWEI RNE-L22",
    "value": "HUAWEI RNE-L22"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X695D",
    "label": "INFINIX MOBILITY LIMITED Infinix X695D",
    "value": "INFINIX MOBILITY LIMITED Infinix X695D"
  },
  {
    "name": "HUAWEI DUB-LX2",
    "label": "HUAWEI DUB-LX2",
    "value": "HUAWEI DUB-LX2"
  },
  {
    "name": "realme RMX2040",
    "label": "realme RMX2040",
    "value": "realme RMX2040"
  },
  {
    "name": "OnePlus GM1900",
    "label": "OnePlus GM1900",
    "value": "OnePlus GM1900"
  },
  {
    "name": "deejoy GWAT10-1",
    "label": "deejoy GWAT10-1",
    "value": "deejoy GWAT10-1"
  },
  {
    "name": "OPPO CPH2179",
    "label": "OPPO CPH2179",
    "value": "OPPO CPH2179"
  },
  {
    "name": "samsung SM-N950N",
    "label": "samsung SM-N950N",
    "value": "samsung SM-N950N"
  },
  {
    "name": "Xiaomi Mi Note 10",
    "label": "Xiaomi Mi Note 10",
    "value": "Xiaomi Mi Note 10"
  },
  {
    "name": "Apple Aiden Choi.iPhone",
    "label": "Apple Aiden Choi.iPhone",
    "value": "Apple Aiden Choi.iPhone"
  },
  {
    "name": "vivo vivo Y83",
    "label": "vivo vivo Y83",
    "value": "vivo vivo Y83"
  },
  {
    "name": "HUAWEI POT-LX1A",
    "label": "HUAWEI POT-LX1A",
    "value": "HUAWEI POT-LX1A"
  },
  {
    "name": "realme RMX3472",
    "label": "realme RMX3472",
    "value": "realme RMX3472"
  },
  {
    "name": "realme RMX2180",
    "label": "realme RMX2180",
    "value": "realme RMX2180"
  },
  {
    "name": "samsung SM-M127N",
    "label": "samsung SM-M127N",
    "value": "samsung SM-M127N"
  },
  {
    "name": "asus ASUS_Z01HD",
    "label": "asus ASUS_Z01HD",
    "value": "asus ASUS_Z01HD"
  },
  {
    "name": "samsung SM-A505U",
    "label": "samsung SM-A505U",
    "value": "samsung SM-A505U"
  },
  {
    "name": "Mobicel R1PLUS_1",
    "label": "Mobicel R1PLUS_1",
    "value": "Mobicel R1PLUS_1"
  },
  {
    "name": "Apple iPhone14,7",
    "label": "Apple iPhone14,7",
    "value": "Apple iPhone14,7"
  },
  {
    "name": "vivo vivo 1808",
    "label": "vivo vivo 1808",
    "value": "vivo vivo 1808"
  },
  {
    "name": "iPhone12,8 iPhone",
    "label": "iPhone12,8 iPhone",
    "value": "iPhone12,8 iPhone"
  },
  {
    "name": "samsung SM-M105G",
    "label": "samsung SM-M105G",
    "value": "samsung SM-M105G"
  },
  {
    "name": "OPPO CPH1931",
    "label": "OPPO CPH1931",
    "value": "OPPO CPH1931"
  },
  {
    "name": "OPPO CPH1907",
    "label": "OPPO CPH1907",
    "value": "OPPO CPH1907"
  },
  {
    "name": "LGE LM-K500",
    "label": "LGE LM-K500",
    "value": "LGE LM-K500"
  },
  {
    "name": "Hisense Hisense E40 Lite",
    "label": "Hisense Hisense E40 Lite",
    "value": "Hisense Hisense E40 Lite"
  },
  {
    "name": "HUAWEI FIG-LA1",
    "label": "HUAWEI FIG-LA1",
    "value": "HUAWEI FIG-LA1"
  },
  {
    "name": "vivo vivo 1915",
    "label": "vivo vivo 1915",
    "value": "vivo vivo 1915"
  },
  {
    "name": "OPPO CPH2239",
    "label": "OPPO CPH2239",
    "value": "OPPO CPH2239"
  },
  {
    "name": "samsung SM-A705F",
    "label": "samsung SM-A705F",
    "value": "samsung SM-A705F"
  },
  {
    "name": "Apple iPhone14,6",
    "label": "Apple iPhone14,6",
    "value": "Apple iPhone14,6"
  },
  {
    "name": "HUAWEI JNY-LX1",
    "label": "HUAWEI JNY-LX1",
    "value": "HUAWEI JNY-LX1"
  },
  {
    "name": "vivo vivo Y85",
    "label": "vivo vivo Y85",
    "value": "vivo vivo Y85"
  },
  {
    "name": "samsung SM-A505GN",
    "label": "samsung SM-A505GN",
    "value": "samsung SM-A505GN"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BD4a",
    "label": "TECNO MOBILE LIMITED TECNO BD4a",
    "value": "TECNO MOBILE LIMITED TECNO BD4a"
  },
  {
    "name": "Apple iPhone8,4",
    "label": "Apple iPhone8,4",
    "value": "Apple iPhone8,4"
  },
  {
    "name": "samsung SM-A022G",
    "label": "samsung SM-A022G",
    "value": "samsung SM-A022G"
  },
  {
    "name": "Mobicel VIBE",
    "label": "Mobicel VIBE",
    "value": "Mobicel VIBE"
  },
  {
    "name": "samsung SM-G986B",
    "label": "samsung SM-G986B",
    "value": "samsung SM-G986B"
  },
  {
    "name": "Sony 702SO",
    "label": "Sony 702SO",
    "value": "Sony 702SO"
  },
  {
    "name": "HTC HTC Desire 12",
    "label": "HTC HTC Desire 12",
    "value": "HTC HTC Desire 12"
  },
  {
    "name": "samsung SM-M515F",
    "label": "samsung SM-M515F",
    "value": "samsung SM-M515F"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KG5j",
    "label": "TECNO MOBILE LIMITED TECNO KG5j",
    "value": "TECNO MOBILE LIMITED TECNO KG5j"
  },
  {
    "name": "samsung SM-G965F",
    "label": "samsung SM-G965F",
    "value": "samsung SM-G965F"
  },
  {
    "name": "vivo vivo 1718",
    "label": "vivo vivo 1718",
    "value": "vivo vivo 1718"
  },
  {
    "name": "samsung SM-F926B",
    "label": "samsung SM-F926B",
    "value": "samsung SM-F926B"
  },
  {
    "name": "samsung SM-G935F",
    "label": "samsung SM-G935F",
    "value": "samsung SM-G935F"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X663",
    "label": "INFINIX MOBILITY LIMITED Infinix X663",
    "value": "INFINIX MOBILITY LIMITED Infinix X663"
  },
  {
    "name": "Hurricane DZIRE",
    "label": "Hurricane DZIRE",
    "value": "Hurricane DZIRE"
  },
  {
    "name": "samsung SM-G970U",
    "label": "samsung SM-G970U",
    "value": "samsung SM-G970U"
  },
  {
    "name": "Google Pixel XL",
    "label": "Google Pixel XL",
    "value": "Google Pixel XL"
  },
  {
    "name": "OPPO CPH1825",
    "label": "OPPO CPH1825",
    "value": "OPPO CPH1825"
  },
  {
    "name": "Xiaomi M1908C3JGG",
    "label": "Xiaomi M1908C3JGG",
    "value": "Xiaomi M1908C3JGG"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LE7",
    "label": "TECNO MOBILE LIMITED TECNO LE7",
    "value": "TECNO MOBILE LIMITED TECNO LE7"
  },
  {
    "name": "Sony SOV36",
    "label": "Sony SOV36",
    "value": "Sony SOV36"
  },
  {
    "name": "HMD Global Nokia 8.1",
    "label": "HMD Global Nokia 8.1",
    "value": "HMD Global Nokia 8.1"
  },
  {
    "name": "OPPO CPH2237",
    "label": "OPPO CPH2237",
    "value": "OPPO CPH2237"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X650D",
    "label": "INFINIX MOBILITY LIMITED Infinix X650D",
    "value": "INFINIX MOBILITY LIMITED Infinix X650D"
  },
  {
    "name": "samsung SM-M105M",
    "label": "samsung SM-M105M",
    "value": "samsung SM-M105M"
  },
  {
    "name": "Samsung SM-G988B",
    "label": "Samsung SM-G988B",
    "value": "Samsung SM-G988B"
  },
  {
    "name": "HUAWEI LYA-L09",
    "label": "HUAWEI LYA-L09",
    "value": "HUAWEI LYA-L09"
  },
  {
    "name": "asus ASUS_X01AD",
    "label": "asus ASUS_X01AD",
    "value": "asus ASUS_X01AD"
  },
  {
    "name": "samsung SM-G985F",
    "label": "samsung SM-G985F",
    "value": "samsung SM-G985F"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LD7",
    "label": "TECNO MOBILE LIMITED TECNO LD7",
    "value": "TECNO MOBILE LIMITED TECNO LD7"
  },
  {
    "name": "realme RMX3261",
    "label": "realme RMX3261",
    "value": "realme RMX3261"
  },
  {
    "name": "BLU Studio X10+",
    "label": "BLU Studio X10+",
    "value": "BLU Studio X10+"
  },
  {
    "name": "Desktop - Tizen",
    "label": "Desktop - Tizen",
    "value": "Desktop - Tizen"
  },
  {
    "name": "Apple iPhone12,5",
    "label": "Apple iPhone12,5",
    "value": "Apple iPhone12,5"
  },
  {
    "name": "samsung SC-02L",
    "label": "samsung SC-02L",
    "value": "samsung SC-02L"
  },
  {
    "name": "HUAWEI JKM-LX1",
    "label": "HUAWEI JKM-LX1",
    "value": "HUAWEI JKM-LX1"
  },
  {
    "name": "samsung SM-S908E",
    "label": "samsung SM-S908E",
    "value": "samsung SM-S908E"
  },
  {
    "name": "vivo V2032",
    "label": "vivo V2032",
    "value": "vivo V2032"
  },
  {
    "name": "vivo vivo 1801",
    "label": "vivo vivo 1801",
    "value": "vivo vivo 1801"
  },
  {
    "name": "Cherry_Mobile A850",
    "label": "Cherry_Mobile A850",
    "value": "Cherry_Mobile A850"
  },
  {
    "name": "OPPO CPH2211",
    "label": "OPPO CPH2211",
    "value": "OPPO CPH2211"
  },
  {
    "name": "Xiaomi M2007J3SY",
    "label": "Xiaomi M2007J3SY",
    "value": "Xiaomi M2007J3SY"
  },
  {
    "name": "Apple iPad11,6",
    "label": "Apple iPad11,6",
    "value": "Apple iPad11,6"
  },
  {
    "name": "realme RMX2020",
    "label": "realme RMX2020",
    "value": "realme RMX2020"
  },
  {
    "name": "KonnectONE MH-T6000",
    "label": "KonnectONE MH-T6000",
    "value": "KonnectONE MH-T6000"
  },
  {
    "name": "realme RMX3235",
    "label": "realme RMX3235",
    "value": "realme RMX3235"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X680B",
    "label": "INFINIX MOBILITY LIMITED Infinix X680B",
    "value": "INFINIX MOBILITY LIMITED Infinix X680B"
  },
  {
    "name": "HMD Global Nokia 6.2",
    "label": "HMD Global Nokia 6.2",
    "value": "HMD Global Nokia 6.2"
  },
  {
    "name": "ZUUM AURA PLUS JLO",
    "label": "ZUUM AURA PLUS JLO",
    "value": "ZUUM AURA PLUS JLO"
  },
  {
    "name": "motorola motorola one vision",
    "label": "motorola motorola one vision",
    "value": "motorola motorola one vision"
  },
  {
    "name": "HUAWEI RNE-L23",
    "label": "HUAWEI RNE-L23",
    "value": "HUAWEI RNE-L23"
  },
  {
    "name": "Xiaomi M2004J19PI",
    "label": "Xiaomi M2004J19PI",
    "value": "Xiaomi M2004J19PI"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X683",
    "label": "INFINIX MOBILITY LIMITED Infinix X683",
    "value": "INFINIX MOBILITY LIMITED Infinix X683"
  },
  {
    "name": "asus ASUS_X00RD",
    "label": "asus ASUS_X00RD",
    "value": "asus ASUS_X00RD"
  },
  {
    "name": "samsung SM-G930K",
    "label": "samsung SM-G930K",
    "value": "samsung SM-G930K"
  },
  {
    "name": "realme RMX3063",
    "label": "realme RMX3063",
    "value": "realme RMX3063"
  },
  {
    "name": "vivo vivo 1818",
    "label": "vivo vivo 1818",
    "value": "vivo vivo 1818"
  },
  {
    "name": "FISE K6",
    "label": "FISE K6",
    "value": "FISE K6"
  },
  {
    "name": "realme RMX3081",
    "label": "realme RMX3081",
    "value": "realme RMX3081"
  },
  {
    "name": "HUAWEI INE-LX1r",
    "label": "HUAWEI INE-LX1r",
    "value": "HUAWEI INE-LX1r"
  },
  {
    "name": "Apple Priscilla’s iPhone",
    "label": "Apple Priscilla’s iPhone",
    "value": "Apple Priscilla’s iPhone"
  },
  {
    "name": "HUAWEI HLK-L41",
    "label": "HUAWEI HLK-L41",
    "value": "HUAWEI HLK-L41"
  },
  {
    "name": "vivo vivo 1723",
    "label": "vivo vivo 1723",
    "value": "vivo vivo 1723"
  },
  {
    "name": "TCL 5033D_EEA",
    "label": "TCL 5033D_EEA",
    "value": "TCL 5033D_EEA"
  },
  {
    "name": "HMD Global TA-1020",
    "label": "HMD Global TA-1020",
    "value": "HMD Global TA-1020"
  },
  {
    "name": "HUAWEI FIG-LX1",
    "label": "HUAWEI FIG-LX1",
    "value": "HUAWEI FIG-LX1"
  },
  {
    "name": "samsung SM-G9910",
    "label": "samsung SM-G9910",
    "value": "samsung SM-G9910"
  },
  {
    "name": "Sony G3112",
    "label": "Sony G3112",
    "value": "Sony G3112"
  },
  {
    "name": "samsung SM-M105F",
    "label": "samsung SM-M105F",
    "value": "samsung SM-M105F"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KC8",
    "label": "TECNO MOBILE LIMITED TECNO KC8",
    "value": "TECNO MOBILE LIMITED TECNO KC8"
  },
  {
    "name": "Xiaomi MI 8 Pro",
    "label": "Xiaomi MI 8 Pro",
    "value": "Xiaomi MI 8 Pro"
  },
  {
    "name": "vsmart Live 4",
    "label": "vsmart Live 4",
    "value": "vsmart Live 4"
  },
  {
    "name": "HMD Global Nokia 5.1 Plus",
    "label": "HMD Global Nokia 5.1 Plus",
    "value": "HMD Global Nokia 5.1 Plus"
  },
  {
    "name": "motorola moto e20",
    "label": "motorola moto e20",
    "value": "motorola moto e20"
  },
  {
    "name": "Hisense Hisense Infinity E30SE",
    "label": "Hisense Hisense Infinity E30SE",
    "value": "Hisense Hisense Infinity E30SE"
  },
  {
    "name": "samsung SM-P585",
    "label": "samsung SM-P585",
    "value": "samsung SM-P585"
  },
  {
    "name": "HUAWEI JAT-L29",
    "label": "HUAWEI JAT-L29",
    "value": "HUAWEI JAT-L29"
  },
  {
    "name": "samsung SM-A605G",
    "label": "samsung SM-A605G",
    "value": "samsung SM-A605G"
  },
  {
    "name": "Xiaomi M2101K7AI",
    "label": "Xiaomi M2101K7AI",
    "value": "Xiaomi M2101K7AI"
  },
  {
    "name": "samsung SM-M426B",
    "label": "samsung SM-M426B",
    "value": "samsung SM-M426B"
  },
  {
    "name": "samsung SM-T860",
    "label": "samsung SM-T860",
    "value": "samsung SM-T860"
  },
  {
    "name": "samsung SM-N9600",
    "label": "samsung SM-N9600",
    "value": "samsung SM-N9600"
  },
  {
    "name": "motorola motorola one zoom",
    "label": "motorola motorola one zoom",
    "value": "motorola motorola one zoom"
  },
  {
    "name": "realme RMX3268",
    "label": "realme RMX3268",
    "value": "realme RMX3268"
  },
  {
    "name": "samsung SM-G9650",
    "label": "samsung SM-G9650",
    "value": "samsung SM-G9650"
  },
  {
    "name": "Xiaomi Redmi Y2",
    "label": "Xiaomi Redmi Y2",
    "value": "Xiaomi Redmi Y2"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LC6",
    "label": "TECNO MOBILE LIMITED TECNO LC6",
    "value": "TECNO MOBILE LIMITED TECNO LC6"
  },
  {
    "name": "HUAWEI VTR-L09",
    "label": "HUAWEI VTR-L09",
    "value": "HUAWEI VTR-L09"
  },
  {
    "name": "Micromax IN_2b",
    "label": "Micromax IN_2b",
    "value": "Micromax IN_2b"
  },
  {
    "name": "Sony G3221",
    "label": "Sony G3221",
    "value": "Sony G3221"
  },
  {
    "name": "Hisense Hisense U605",
    "label": "Hisense Hisense U605",
    "value": "Hisense Hisense U605"
  },
  {
    "name": "Xiaomi 220233L2G",
    "label": "Xiaomi 220233L2G",
    "value": "Xiaomi 220233L2G"
  },
  {
    "name": "OnePlus MT2111",
    "label": "OnePlus MT2111",
    "value": "OnePlus MT2111"
  },
  {
    "name": "Xiaomi Redmi 8A Dual",
    "label": "Xiaomi Redmi 8A Dual",
    "value": "Xiaomi Redmi 8A Dual"
  },
  {
    "name": "motorola moto e(7) power",
    "label": "motorola moto e(7) power",
    "value": "motorola moto e(7) power"
  },
  {
    "name": "HUAWEI BAH2-W19",
    "label": "HUAWEI BAH2-W19",
    "value": "HUAWEI BAH2-W19"
  },
  {
    "name": "iPhone12,5 iPhone",
    "label": "iPhone12,5 iPhone",
    "value": "iPhone12,5 iPhone"
  },
  {
    "name": "OPPO CPH1809",
    "label": "OPPO CPH1809",
    "value": "OPPO CPH1809"
  },
  {
    "name": "Realme RMX1901",
    "label": "Realme RMX1901",
    "value": "Realme RMX1901"
  },
  {
    "name": "samsung SM-M225FV",
    "label": "samsung SM-M225FV",
    "value": "samsung SM-M225FV"
  },
  {
    "name": "HUAWEI ATU-L21",
    "label": "HUAWEI ATU-L21",
    "value": "HUAWEI ATU-L21"
  },
  {
    "name": "motorola Moto G (4)",
    "label": "motorola Moto G (4)",
    "value": "motorola Moto G (4)"
  },
  {
    "name": "Google Pixel 2 XL",
    "label": "Google Pixel 2 XL",
    "value": "Google Pixel 2 XL"
  },
  {
    "name": "asus ASUS_X00LD",
    "label": "asus ASUS_X00LD",
    "value": "asus ASUS_X00LD"
  },
  {
    "name": "Xiaomi M2007J20CI",
    "label": "Xiaomi M2007J20CI",
    "value": "Xiaomi M2007J20CI"
  },
  {
    "name": "samsung SM-G981B",
    "label": "samsung SM-G981B",
    "value": "samsung SM-G981B"
  },
  {
    "name": "LGE LM-Q630",
    "label": "LGE LM-Q630",
    "value": "LGE LM-Q630"
  },
  {
    "name": "Xiaomi MI MAX 3",
    "label": "Xiaomi MI MAX 3",
    "value": "Xiaomi MI MAX 3"
  },
  {
    "name": " ",
    "label": " ",
    "value": " "
  },
  {
    "name": "samsung SM-A305GN",
    "label": "samsung SM-A305GN",
    "value": "samsung SM-A305GN"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LC8",
    "label": "TECNO MOBILE LIMITED TECNO LC8",
    "value": "TECNO MOBILE LIMITED TECNO LC8"
  },
  {
    "name": "Philco Hit P10",
    "label": "Philco Hit P10",
    "value": "Philco Hit P10"
  },
  {
    "name": "samsung SM-M526B",
    "label": "samsung SM-M526B",
    "value": "samsung SM-M526B"
  },
  {
    "name": "Xiaomi Redmi Note 5A",
    "label": "Xiaomi Redmi Note 5A",
    "value": "Xiaomi Redmi Note 5A"
  },
  {
    "name": "samsung SM-J530F",
    "label": "samsung SM-J530F",
    "value": "samsung SM-J530F"
  },
  {
    "name": "realme RMX2193",
    "label": "realme RMX2193",
    "value": "realme RMX2193"
  },
  {
    "name": "realme RMX3360",
    "label": "realme RMX3360",
    "value": "realme RMX3360"
  },
  {
    "name": "motorola moto e6 play",
    "label": "motorola moto e6 play",
    "value": "motorola moto e6 play"
  },
  {
    "name": "Xiaomi Redmi Note 7S",
    "label": "Xiaomi Redmi Note 7S",
    "value": "Xiaomi Redmi Note 7S"
  },
  {
    "name": "Hisense Hisense U50",
    "label": "Hisense Hisense U50",
    "value": "Hisense Hisense U50"
  },
  {
    "name": "samsung SM-M625F",
    "label": "samsung SM-M625F",
    "value": "samsung SM-M625F"
  },
  {
    "name": "KRIP K57",
    "label": "KRIP K57",
    "value": "KRIP K57"
  },
  {
    "name": "HMD Global TA-1024",
    "label": "HMD Global TA-1024",
    "value": "HMD Global TA-1024"
  },
  {
    "name": "Apple iPad6,12",
    "label": "Apple iPad6,12",
    "value": "Apple iPad6,12"
  },
  {
    "name": "HUAWEI MAR-LX1H",
    "label": "HUAWEI MAR-LX1H",
    "value": "HUAWEI MAR-LX1H"
  },
  {
    "name": "Philco HIT P10a",
    "label": "Philco HIT P10a",
    "value": "Philco HIT P10a"
  },
  {
    "name": "Xiaomi Redmi Note 6 Pro",
    "label": "Xiaomi Redmi Note 6 Pro",
    "value": "Xiaomi Redmi Note 6 Pro"
  },
  {
    "name": "samsung SM-J330G",
    "label": "samsung SM-J330G",
    "value": "samsung SM-J330G"
  },
  {
    "name": "HXY A7S",
    "label": "HXY A7S",
    "value": "HXY A7S"
  },
  {
    "name": "Mobicel RIO_SS",
    "label": "Mobicel RIO_SS",
    "value": "Mobicel RIO_SS"
  },
  {
    "name": "HMD Global Nokia 1 Plus",
    "label": "HMD Global Nokia 1 Plus",
    "value": "HMD Global Nokia 1 Plus"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CG6",
    "label": "TECNO MOBILE LIMITED TECNO CG6",
    "value": "TECNO MOBILE LIMITED TECNO CG6"
  },
  {
    "name": "itel itel W6502",
    "label": "itel itel W6502",
    "value": "itel itel W6502"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LB7",
    "label": "TECNO MOBILE LIMITED TECNO LB7",
    "value": "TECNO MOBILE LIMITED TECNO LB7"
  },
  {
    "name": "HUAWEI NAM-LX9",
    "label": "HUAWEI NAM-LX9",
    "value": "HUAWEI NAM-LX9"
  },
  {
    "name": "Ulefone Note 10",
    "label": "Ulefone Note 10",
    "value": "Ulefone Note 10"
  },
  {
    "name": "ITEL MOBILE LIMITED itel W6004P",
    "label": "ITEL MOBILE LIMITED itel W6004P",
    "value": "ITEL MOBILE LIMITED itel W6004P"
  },
  {
    "name": "ZTE ZTE Blade L210",
    "label": "ZTE ZTE Blade L210",
    "value": "ZTE ZTE Blade L210"
  },
  {
    "name": "itel itel W6002",
    "label": "itel itel W6002",
    "value": "itel itel W6002"
  },
  {
    "name": "Xiaomi Redmi Note 7 Pro",
    "label": "Xiaomi Redmi Note 7 Pro",
    "value": "Xiaomi Redmi Note 7 Pro"
  },
  {
    "name": "incar OKT1",
    "label": "incar OKT1",
    "value": "incar OKT1"
  },
  {
    "name": "Fortune Ship DAYTONA-G12",
    "label": "Fortune Ship DAYTONA-G12",
    "value": "Fortune Ship DAYTONA-G12"
  },
  {
    "name": "samsung SM-G990B",
    "label": "samsung SM-G990B",
    "value": "samsung SM-G990B"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X657",
    "label": "INFINIX MOBILITY LIMITED Infinix X657",
    "value": "INFINIX MOBILITY LIMITED Infinix X657"
  },
  {
    "name": "samsung SM-J610FN",
    "label": "samsung SM-J610FN",
    "value": "samsung SM-J610FN"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CC7",
    "label": "TECNO MOBILE LIMITED TECNO CC7",
    "value": "TECNO MOBILE LIMITED TECNO CC7"
  },
  {
    "name": "HUAWEI INE-LX2",
    "label": "HUAWEI INE-LX2",
    "value": "HUAWEI INE-LX2"
  },
  {
    "name": "samsung SM-A102N",
    "label": "samsung SM-A102N",
    "value": "samsung SM-A102N"
  },
  {
    "name": "DOOGEE X90L",
    "label": "DOOGEE X90L",
    "value": "DOOGEE X90L"
  },
  {
    "name": "Safaricom NEON_RAY_PRO",
    "label": "Safaricom NEON_RAY_PRO",
    "value": "Safaricom NEON_RAY_PRO"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KD7h",
    "label": "TECNO MOBILE LIMITED TECNO KD7h",
    "value": "TECNO MOBILE LIMITED TECNO KD7h"
  },
  {
    "name": "HMD Global Nokia 3.2",
    "label": "HMD Global Nokia 3.2",
    "value": "HMD Global Nokia 3.2"
  },
  {
    "name": "Xiaomi Mi 9T",
    "label": "Xiaomi Mi 9T",
    "value": "Xiaomi Mi 9T"
  },
  {
    "name": "Sony G3223",
    "label": "Sony G3223",
    "value": "Sony G3223"
  },
  {
    "name": "samsung SM-A605GN",
    "label": "samsung SM-A605GN",
    "value": "samsung SM-A605GN"
  },
  {
    "name": "OPPO CPH2195",
    "label": "OPPO CPH2195",
    "value": "OPPO CPH2195"
  },
  {
    "name": "Xiaomi 2201117SY",
    "label": "Xiaomi 2201117SY",
    "value": "Xiaomi 2201117SY"
  },
  {
    "name": "HAFURY NOTE 10",
    "label": "HAFURY NOTE 10",
    "value": "HAFURY NOTE 10"
  },
  {
    "name": "TP-Link Neffos X20",
    "label": "TP-Link Neffos X20",
    "value": "TP-Link Neffos X20"
  },
  {
    "name": "motorola moto g(6)",
    "label": "motorola moto g(6)",
    "value": "motorola moto g(6)"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X688C",
    "label": "INFINIX MOBILITY LIMITED Infinix X688C",
    "value": "INFINIX MOBILITY LIMITED Infinix X688C"
  },
  {
    "name": "motorola moto g(8)",
    "label": "motorola moto g(8)",
    "value": "motorola moto g(8)"
  },
  {
    "name": "realme RMX2101",
    "label": "realme RMX2101",
    "value": "realme RMX2101"
  },
  {
    "name": "TECNO TECNO KG5p",
    "label": "TECNO TECNO KG5p",
    "value": "TECNO TECNO KG5p"
  },
  {
    "name": "OPPO CPH2071",
    "label": "OPPO CPH2071",
    "value": "OPPO CPH2071"
  },
  {
    "name": "motorola Moto G (5S) Plus",
    "label": "motorola Moto G (5S) Plus",
    "value": "motorola Moto G (5S) Plus"
  },
  {
    "name": "samsung SM-A750G",
    "label": "samsung SM-A750G",
    "value": "samsung SM-A750G"
  },
  {
    "name": "OPPO CPH2119",
    "label": "OPPO CPH2119",
    "value": "OPPO CPH2119"
  },
  {
    "name": "samsung SM-M325F",
    "label": "samsung SM-M325F",
    "value": "samsung SM-M325F"
  },
  {
    "name": "HUAWEI WAS-LX2",
    "label": "HUAWEI WAS-LX2",
    "value": "HUAWEI WAS-LX2"
  },
  {
    "name": "samsung SM-A910F",
    "label": "samsung SM-A910F",
    "value": "samsung SM-A910F"
  },
  {
    "name": "vsmart Joy 3",
    "label": "vsmart Joy 3",
    "value": "vsmart Joy 3"
  },
  {
    "name": "OPPO OPPO F5",
    "label": "OPPO OPPO F5",
    "value": "OPPO OPPO F5"
  },
  {
    "name": "HUAWEI INE-LX2r",
    "label": "HUAWEI INE-LX2r",
    "value": "HUAWEI INE-LX2r"
  },
  {
    "name": "OPPO CPH2065",
    "label": "OPPO CPH2065",
    "value": "OPPO CPH2065"
  },
  {
    "name": "HUAWEI EML-L09",
    "label": "HUAWEI EML-L09",
    "value": "HUAWEI EML-L09"
  },
  {
    "name": "Hisense Hisense U963",
    "label": "Hisense Hisense U963",
    "value": "Hisense Hisense U963"
  },
  {
    "name": "HUAWEI HRY-LX1MEB",
    "label": "HUAWEI HRY-LX1MEB",
    "value": "HUAWEI HRY-LX1MEB"
  },
  {
    "name": "realme RMX3363",
    "label": "realme RMX3363",
    "value": "realme RMX3363"
  },
  {
    "name": "samsung SM-A305G",
    "label": "samsung SM-A305G",
    "value": "samsung SM-A305G"
  },
  {
    "name": "HUAWEI LLD-AL20",
    "label": "HUAWEI LLD-AL20",
    "value": "HUAWEI LLD-AL20"
  },
  {
    "name": "Apple iPhone14,4",
    "label": "Apple iPhone14,4",
    "value": "Apple iPhone14,4"
  },
  {
    "name": "ZTE BLADE V Ultra",
    "label": "ZTE BLADE V Ultra",
    "value": "ZTE BLADE V Ultra"
  },
  {
    "name": "samsung SM-S901E",
    "label": "samsung SM-S901E",
    "value": "samsung SM-S901E"
  },
  {
    "name": "LGE LM-X430",
    "label": "LGE LM-X430",
    "value": "LGE LM-X430"
  },
  {
    "name": "Xiaomi 220333QNY",
    "label": "Xiaomi 220333QNY",
    "value": "Xiaomi 220333QNY"
  },
  {
    "name": "LGE LM-X420",
    "label": "LGE LM-X420",
    "value": "LGE LM-X420"
  },
  {
    "name": "samsung SM-G981N",
    "label": "samsung SM-G981N",
    "value": "samsung SM-G981N"
  },
  {
    "name": "vivo vivo 1719",
    "label": "vivo vivo 1719",
    "value": "vivo vivo 1719"
  },
  {
    "name": "Xiaomi M2101K6I",
    "label": "Xiaomi M2101K6I",
    "value": "Xiaomi M2101K6I"
  },
  {
    "name": "Xiaomi M2101K7BI",
    "label": "Xiaomi M2101K7BI",
    "value": "Xiaomi M2101K7BI"
  },
  {
    "name": "HMD Global Nokia 2",
    "label": "HMD Global Nokia 2",
    "value": "HMD Global Nokia 2"
  },
  {
    "name": "vivo vivo 2019",
    "label": "vivo vivo 2019",
    "value": "vivo vivo 2019"
  },
  {
    "name": "HUAWEI ART-L28",
    "label": "HUAWEI ART-L28",
    "value": "HUAWEI ART-L28"
  },
  {
    "name": "Mobicel RUBY",
    "label": "Mobicel RUBY",
    "value": "Mobicel RUBY"
  },
  {
    "name": "samsung SM-A826S",
    "label": "samsung SM-A826S",
    "value": "samsung SM-A826S"
  },
  {
    "name": "Xiaomi POCO F2 Pro",
    "label": "Xiaomi POCO F2 Pro",
    "value": "Xiaomi POCO F2 Pro"
  },
  {
    "name": "Multilaser Multilaser_E_Lite",
    "label": "Multilaser Multilaser_E_Lite",
    "value": "Multilaser Multilaser_E_Lite"
  },
  {
    "name": "Lanix Ilium Alpha 3",
    "label": "Lanix Ilium Alpha 3",
    "value": "Lanix Ilium Alpha 3"
  },
  {
    "name": "HUAWEI JSN-AL00a",
    "label": "HUAWEI JSN-AL00a",
    "value": "HUAWEI JSN-AL00a"
  },
  {
    "name": "Xiaomi M2006C3LVG",
    "label": "Xiaomi M2006C3LVG",
    "value": "Xiaomi M2006C3LVG"
  },
  {
    "name": "Xiaomi 22031116AI",
    "label": "Xiaomi 22031116AI",
    "value": "Xiaomi 22031116AI"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KE7",
    "label": "TECNO MOBILE LIMITED TECNO KE7",
    "value": "TECNO MOBILE LIMITED TECNO KE7"
  },
  {
    "name": "samsung SM-M325FV",
    "label": "samsung SM-M325FV",
    "value": "samsung SM-M325FV"
  },
  {
    "name": "vivo V2046",
    "label": "vivo V2046",
    "value": "vivo V2046"
  },
  {
    "name": "Blackview A80Pro",
    "label": "Blackview A80Pro",
    "value": "Blackview A80Pro"
  },
  {
    "name": "vivo V2060",
    "label": "vivo V2060",
    "value": "vivo V2060"
  },
  {
    "name": "HUAWEI LND-L29",
    "label": "HUAWEI LND-L29",
    "value": "HUAWEI LND-L29"
  },
  {
    "name": "vivo V2141",
    "label": "vivo V2141",
    "value": "vivo V2141"
  },
  {
    "name": "vivo V2045",
    "label": "vivo V2045",
    "value": "vivo V2045"
  },
  {
    "name": "samsung SM-A315N",
    "label": "samsung SM-A315N",
    "value": "samsung SM-A315N"
  },
  {
    "name": "vsmart Joy 2+",
    "label": "vsmart Joy 2+",
    "value": "vsmart Joy 2+"
  },
  {
    "name": "samsung SM-T733",
    "label": "samsung SM-T733",
    "value": "samsung SM-T733"
  },
  {
    "name": "Bmobile B60Pro_MV03",
    "label": "Bmobile B60Pro_MV03",
    "value": "Bmobile B60Pro_MV03"
  },
  {
    "name": "Apple cait",
    "label": "Apple cait",
    "value": "Apple cait"
  },
  {
    "name": "samsung SM-G935S",
    "label": "samsung SM-G935S",
    "value": "samsung SM-G935S"
  },
  {
    "name": "Xiaomi 21061119BI",
    "label": "Xiaomi 21061119BI",
    "value": "Xiaomi 21061119BI"
  },
  {
    "name": "itel itel W6001",
    "label": "itel itel W6001",
    "value": "itel itel W6001"
  },
  {
    "name": "HMD Global Nokia 7 plus",
    "label": "HMD Global Nokia 7 plus",
    "value": "HMD Global Nokia 7 plus"
  },
  {
    "name": "LGE LM-X440ZM",
    "label": "LGE LM-X440ZM",
    "value": "LGE LM-X440ZM"
  },
  {
    "name": "Xiaomi M2101K7BL",
    "label": "Xiaomi M2101K7BL",
    "value": "Xiaomi M2101K7BL"
  },
  {
    "name": "Xiaomi POCO M2 Pro",
    "label": "Xiaomi POCO M2 Pro",
    "value": "Xiaomi POCO M2 Pro"
  },
  {
    "name": "incar SMART_L206",
    "label": "incar SMART_L206",
    "value": "incar SMART_L206"
  },
  {
    "name": "OPPO RMX1801",
    "label": "OPPO RMX1801",
    "value": "OPPO RMX1801"
  },
  {
    "name": "samsung SM-A5360",
    "label": "samsung SM-A5360",
    "value": "samsung SM-A5360"
  },
  {
    "name": "OPPO CPH2321",
    "label": "OPPO CPH2321",
    "value": "OPPO CPH2321"
  },
  {
    "name": "HXY A9 Pro",
    "label": "HXY A9 Pro",
    "value": "HXY A9 Pro"
  },
  {
    "name": "samsung SM-N971N",
    "label": "samsung SM-N971N",
    "value": "samsung SM-N971N"
  },
  {
    "name": "vivo vivo 1916",
    "label": "vivo vivo 1916",
    "value": "vivo vivo 1916"
  },
  {
    "name": "vivo V2050",
    "label": "vivo V2050",
    "value": "vivo V2050"
  },
  {
    "name": "Xiaomi 220233L2I",
    "label": "Xiaomi 220233L2I",
    "value": "Xiaomi 220233L2I"
  },
  {
    "name": "realme RMX2075",
    "label": "realme RMX2075",
    "value": "realme RMX2075"
  },
  {
    "name": "Xiaomi Redmi 6 Pro",
    "label": "Xiaomi Redmi 6 Pro",
    "value": "Xiaomi Redmi 6 Pro"
  },
  {
    "name": "motorola moto g(8) power",
    "label": "motorola moto g(8) power",
    "value": "motorola moto g(8) power"
  },
  {
    "name": "motorola moto g(50) 5G",
    "label": "motorola moto g(50) 5G",
    "value": "motorola moto g(50) 5G"
  },
  {
    "name": "TCL 6025A",
    "label": "TCL 6025A",
    "value": "TCL 6025A"
  },
  {
    "name": "Apple Oratile’s iPhone",
    "label": "Apple Oratile’s iPhone",
    "value": "Apple Oratile’s iPhone"
  },
  {
    "name": "samsung SM-M115M",
    "label": "samsung SM-M115M",
    "value": "samsung SM-M115M"
  },
  {
    "name": "samsung SM-F127G",
    "label": "samsung SM-F127G",
    "value": "samsung SM-F127G"
  },
  {
    "name": "Mobicel VENUS_G",
    "label": "Mobicel VENUS_G",
    "value": "Mobicel VENUS_G"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X653C",
    "label": "INFINIX MOBILITY LIMITED Infinix X653C",
    "value": "INFINIX MOBILITY LIMITED Infinix X653C"
  },
  {
    "name": "LGE LM-G900N",
    "label": "LGE LM-G900N",
    "value": "LGE LM-G900N"
  },
  {
    "name": "vivo vivo 1902",
    "label": "vivo vivo 1902",
    "value": "vivo vivo 1902"
  },
  {
    "name": "Xiaomi M2103K19C",
    "label": "Xiaomi M2103K19C",
    "value": "Xiaomi M2103K19C"
  },
  {
    "name": "Apple Marisol ",
    "label": "Apple Marisol ",
    "value": "Apple Marisol "
  },
  {
    "name": "samsung SM-G770F",
    "label": "samsung SM-G770F",
    "value": "samsung SM-G770F"
  },
  {
    "name": "asus ASUS_Z01MDA",
    "label": "asus ASUS_Z01MDA",
    "value": "asus ASUS_Z01MDA"
  },
  {
    "name": "samsung SM-J260G",
    "label": "samsung SM-J260G",
    "value": "samsung SM-J260G"
  },
  {
    "name": "Apple Anda’s l Phone",
    "label": "Apple Anda’s l Phone",
    "value": "Apple Anda’s l Phone"
  },
  {
    "name": "HUAWEI MRD-LX3",
    "label": "HUAWEI MRD-LX3",
    "value": "HUAWEI MRD-LX3"
  },
  {
    "name": "samsung SM-S906E",
    "label": "samsung SM-S906E",
    "value": "samsung SM-S906E"
  },
  {
    "name": "samsung SM-A516N",
    "label": "samsung SM-A516N",
    "value": "samsung SM-A516N"
  },
  {
    "name": "samsung SM-A307G",
    "label": "samsung SM-A307G",
    "value": "samsung SM-A307G"
  },
  {
    "name": "HMD Global Nokia 2.3",
    "label": "HMD Global Nokia 2.3",
    "value": "HMD Global Nokia 2.3"
  },
  {
    "name": "samsung SM-A137F",
    "label": "samsung SM-A137F",
    "value": "samsung SM-A137F"
  },
  {
    "name": "Xiaomi Redmi Note 9 Pro Max",
    "label": "Xiaomi Redmi Note 9 Pro Max",
    "value": "Xiaomi Redmi Note 9 Pro Max"
  },
  {
    "name": "LGE LM-Q710(FGN)",
    "label": "LGE LM-Q710(FGN)",
    "value": "LGE LM-Q710(FGN)"
  },
  {
    "name": "Apple Jameela Ann’s iPhone",
    "label": "Apple Jameela Ann’s iPhone",
    "value": "Apple Jameela Ann’s iPhone"
  },
  {
    "name": "realme RMX2103",
    "label": "realme RMX2103",
    "value": "realme RMX2103"
  },
  {
    "name": "vivo V2035",
    "label": "vivo V2035",
    "value": "vivo V2035"
  },
  {
    "name": "Apple Mokauleng",
    "label": "Apple Mokauleng",
    "value": "Apple Mokauleng"
  },
  {
    "name": "samsung SM-G973F",
    "label": "samsung SM-G973F",
    "value": "samsung SM-G973F"
  },
  {
    "name": "itel itel A571W",
    "label": "itel itel A571W",
    "value": "itel itel A571W"
  },
  {
    "name": "samsung SM-G988N",
    "label": "samsung SM-G988N",
    "value": "samsung SM-G988N"
  },
  {
    "name": "OPPO CPH2145",
    "label": "OPPO CPH2145",
    "value": "OPPO CPH2145"
  },
  {
    "name": "Smart Products Connection SA GRAVITY 4G",
    "label": "Smart Products Connection SA GRAVITY 4G",
    "value": "Smart Products Connection SA GRAVITY 4G"
  },
  {
    "name": "samsung SM-M236B",
    "label": "samsung SM-M236B",
    "value": "samsung SM-M236B"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X689",
    "label": "INFINIX MOBILITY LIMITED Infinix X689",
    "value": "INFINIX MOBILITY LIMITED Infinix X689"
  },
  {
    "name": "motorola moto g32",
    "label": "motorola moto g32",
    "value": "motorola moto g32"
  },
  {
    "name": "HUAWEI JKM-LX3",
    "label": "HUAWEI JKM-LX3",
    "value": "HUAWEI JKM-LX3"
  },
  {
    "name": "Mobicel R7",
    "label": "Mobicel R7",
    "value": "Mobicel R7"
  },
  {
    "name": "samsung SM-N960N",
    "label": "samsung SM-N960N",
    "value": "samsung SM-N960N"
  },
  {
    "name": "samsung SM-N970F",
    "label": "samsung SM-N970F",
    "value": "samsung SM-N970F"
  },
  {
    "name": "samsung SM-A235M",
    "label": "samsung SM-A235M",
    "value": "samsung SM-A235M"
  },
  {
    "name": "Xiaomi 21051182G",
    "label": "Xiaomi 21051182G",
    "value": "Xiaomi 21051182G"
  },
  {
    "name": "Fortune Ship LOGIC L55S",
    "label": "Fortune Ship LOGIC L55S",
    "value": "Fortune Ship LOGIC L55S"
  },
  {
    "name": "Apple iPhone de Carlos",
    "label": "Apple iPhone de Carlos",
    "value": "Apple iPhone de Carlos"
  },
  {
    "name": "motorola moto g(7) plus",
    "label": "motorola moto g(7) plus",
    "value": "motorola moto g(7) plus"
  },
  {
    "name": "HUAWEI VKY-L09",
    "label": "HUAWEI VKY-L09",
    "value": "HUAWEI VKY-L09"
  },
  {
    "name": "samsung SM-X200",
    "label": "samsung SM-X200",
    "value": "samsung SM-X200"
  },
  {
    "name": "HUAWEI LLD-AL10",
    "label": "HUAWEI LLD-AL10",
    "value": "HUAWEI LLD-AL10"
  },
  {
    "name": "Hisense HLTE300E",
    "label": "Hisense HLTE300E",
    "value": "Hisense HLTE300E"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO F1",
    "label": "TECNO MOBILE LIMITED TECNO F1",
    "value": "TECNO MOBILE LIMITED TECNO F1"
  },
  {
    "name": "LENOVO Lenovo TB-8505X",
    "label": "LENOVO Lenovo TB-8505X",
    "value": "LENOVO Lenovo TB-8505X"
  },
  {
    "name": "samsung SC-03L",
    "label": "samsung SC-03L",
    "value": "samsung SC-03L"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CG8",
    "label": "TECNO MOBILE LIMITED TECNO CG8",
    "value": "TECNO MOBILE LIMITED TECNO CG8"
  },
  {
    "name": "LGE LM-X120",
    "label": "LGE LM-X120",
    "value": "LGE LM-X120"
  },
  {
    "name": "DOOGEE N20Pro",
    "label": "DOOGEE N20Pro",
    "value": "DOOGEE N20Pro"
  },
  {
    "name": "HONOR NTH-NX9",
    "label": "HONOR NTH-NX9",
    "value": "HONOR NTH-NX9"
  },
  {
    "name": "ZTE Blade A3 2019-T",
    "label": "ZTE Blade A3 2019-T",
    "value": "ZTE Blade A3 2019-T"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BC1s",
    "label": "TECNO MOBILE LIMITED TECNO BC1s",
    "value": "TECNO MOBILE LIMITED TECNO BC1s"
  },
  {
    "name": "Yulong Coolpad 3701A",
    "label": "Yulong Coolpad 3701A",
    "value": "Yulong Coolpad 3701A"
  },
  {
    "name": "KLIPAD KL9878A_EEA",
    "label": "KLIPAD KL9878A_EEA",
    "value": "KLIPAD KL9878A_EEA"
  },
  {
    "name": "ZTE ZTE 2050RU",
    "label": "ZTE ZTE 2050RU",
    "value": "ZTE ZTE 2050RU"
  },
  {
    "name": "HUAWEI RNE-L03",
    "label": "HUAWEI RNE-L03",
    "value": "HUAWEI RNE-L03"
  },
  {
    "name": "vivo V2055",
    "label": "vivo V2055",
    "value": "vivo V2055"
  },
  {
    "name": "HUAWEI ATU-L22",
    "label": "HUAWEI ATU-L22",
    "value": "HUAWEI ATU-L22"
  },
  {
    "name": "Positivo Twist Mini",
    "label": "Positivo Twist Mini",
    "value": "Positivo Twist Mini"
  },
  {
    "name": "samsung SM-N986N",
    "label": "samsung SM-N986N",
    "value": "samsung SM-N986N"
  },
  {
    "name": "LGE LG-F700L",
    "label": "LGE LG-F700L",
    "value": "LGE LG-F700L"
  },
  {
    "name": "Apple Tenda’s iPhone",
    "label": "Apple Tenda’s iPhone",
    "value": "Apple Tenda’s iPhone"
  },
  {
    "name": "LGE LM-G710VM",
    "label": "LGE LM-G710VM",
    "value": "LGE LM-G710VM"
  },
  {
    "name": "realme RMX2170",
    "label": "realme RMX2170",
    "value": "realme RMX2170"
  },
  {
    "name": "samsung SM-N950X",
    "label": "samsung SM-N950X",
    "value": "samsung SM-N950X"
  },
  {
    "name": "samsung SM-A805F",
    "label": "samsung SM-A805F",
    "value": "samsung SM-A805F"
  },
  {
    "name": "Mobicel Fendy plus",
    "label": "Mobicel Fendy plus",
    "value": "Mobicel Fendy plus"
  },
  {
    "name": "STG STG_P10",
    "label": "STG STG_P10",
    "value": "STG STG_P10"
  },
  {
    "name": "samsung SM-N976Q",
    "label": "samsung SM-N976Q",
    "value": "samsung SM-N976Q"
  },
  {
    "name": "vivo V2025",
    "label": "vivo V2025",
    "value": "vivo V2025"
  },
  {
    "name": "Yulong Coolpad E2C",
    "label": "Yulong Coolpad E2C",
    "value": "Yulong Coolpad E2C"
  },
  {
    "name": "Coolpad 1901",
    "label": "Coolpad 1901",
    "value": "Coolpad 1901"
  },
  {
    "name": "motorola moto g(7)",
    "label": "motorola moto g(7)",
    "value": "motorola moto g(7)"
  },
  {
    "name": "Google jacuzzi",
    "label": "Google jacuzzi",
    "value": "Google jacuzzi"
  },
  {
    "name": "bq Aquaris V",
    "label": "bq Aquaris V",
    "value": "bq Aquaris V"
  },
  {
    "name": "OnePlus KB2005",
    "label": "OnePlus KB2005",
    "value": "OnePlus KB2005"
  },
  {
    "name": "OPPO CPH1941",
    "label": "OPPO CPH1941",
    "value": "OPPO CPH1941"
  },
  {
    "name": "TECNO MOBILITY LIMITED TECNO PR651",
    "label": "TECNO MOBILITY LIMITED TECNO PR651",
    "value": "TECNO MOBILITY LIMITED TECNO PR651"
  },
  {
    "name": "asus ASUS_I004D",
    "label": "asus ASUS_I004D",
    "value": "asus ASUS_I004D"
  },
  {
    "name": "LGE LM-G910",
    "label": "LGE LM-G910",
    "value": "LGE LM-G910"
  },
  {
    "name": "vivo V2023",
    "label": "vivo V2023",
    "value": "vivo V2023"
  },
  {
    "name": "OUKITEL WP6",
    "label": "OUKITEL WP6",
    "value": "OUKITEL WP6"
  },
  {
    "name": "realme RMX2086",
    "label": "realme RMX2086",
    "value": "realme RMX2086"
  },
  {
    "name": "Hisense Hisense U964",
    "label": "Hisense Hisense U964",
    "value": "Hisense Hisense U964"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KD6",
    "label": "TECNO MOBILE LIMITED TECNO KD6",
    "value": "TECNO MOBILE LIMITED TECNO KD6"
  },
  {
    "name": "TCL T767H",
    "label": "TCL T767H",
    "value": "TCL T767H"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KE6j",
    "label": "TECNO MOBILE LIMITED TECNO KE6j",
    "value": "TECNO MOBILE LIMITED TECNO KE6j"
  },
  {
    "name": "vivo V2206",
    "label": "vivo V2206",
    "value": "vivo V2206"
  },
  {
    "name": "ADVAN X7 Pro",
    "label": "ADVAN X7 Pro",
    "value": "ADVAN X7 Pro"
  },
  {
    "name": "realme RMX3516",
    "label": "realme RMX3516",
    "value": "realme RMX3516"
  },
  {
    "name": "Apple iPhone15,2",
    "label": "Apple iPhone15,2",
    "value": "Apple iPhone15,2"
  },
  {
    "name": "OnePlus CPH2381",
    "label": "OnePlus CPH2381",
    "value": "OnePlus CPH2381"
  },
  {
    "name": "Desktop - android",
    "label": "Desktop - android",
    "value": "Desktop - android"
  },
  {
    "name": "asus ASUS_Z01KD",
    "label": "asus ASUS_Z01KD",
    "value": "asus ASUS_Z01KD"
  },
  {
    "name": "HUAWEI HRY-LX1T",
    "label": "HUAWEI HRY-LX1T",
    "value": "HUAWEI HRY-LX1T"
  },
  {
    "name": "X-TIGI S2",
    "label": "X-TIGI S2",
    "value": "X-TIGI S2"
  },
  {
    "name": "vivo vivo 1806",
    "label": "vivo vivo 1806",
    "value": "vivo vivo 1806"
  },
  {
    "name": "LENOVO Lenovo L19111",
    "label": "LENOVO Lenovo L19111",
    "value": "LENOVO Lenovo L19111"
  },
  {
    "name": "LENOVO Lenovo TB-X705F",
    "label": "LENOVO Lenovo TB-X705F",
    "value": "LENOVO Lenovo TB-X705F"
  },
  {
    "name": "Apple iPad11,3",
    "label": "Apple iPad11,3",
    "value": "Apple iPad11,3"
  },
  {
    "name": "OPPO CPH1859",
    "label": "OPPO CPH1859",
    "value": "OPPO CPH1859"
  },
  {
    "name": "Xiaomi 220333QBI",
    "label": "Xiaomi 220333QBI",
    "value": "Xiaomi 220333QBI"
  },
  {
    "name": "HMD Global Nokia C2 2nd Edition",
    "label": "HMD Global Nokia C2 2nd Edition",
    "value": "HMD Global Nokia C2 2nd Edition"
  },
  {
    "name": "realme RMX3242",
    "label": "realme RMX3242",
    "value": "realme RMX3242"
  },
  {
    "name": "asus ASUS_X018D",
    "label": "asus ASUS_X018D",
    "value": "asus ASUS_X018D"
  },
  {
    "name": "vivo V2037",
    "label": "vivo V2037",
    "value": "vivo V2037"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KE5k",
    "label": "TECNO MOBILE LIMITED TECNO KE5k",
    "value": "TECNO MOBILE LIMITED TECNO KE5k"
  },
  {
    "name": "Mobile Web - Android-x86",
    "label": "Mobile Web - Android-x86",
    "value": "Mobile Web - Android-x86"
  },
  {
    "name": "Xiaomi 220733SG",
    "label": "Xiaomi 220733SG",
    "value": "Xiaomi 220733SG"
  },
  {
    "name": "HUAWEI RNE-L01",
    "label": "HUAWEI RNE-L01",
    "value": "HUAWEI RNE-L01"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BD4h",
    "label": "TECNO MOBILE LIMITED TECNO BD4h",
    "value": "TECNO MOBILE LIMITED TECNO BD4h"
  },
  {
    "name": "motorola moto g82 5G",
    "label": "motorola moto g82 5G",
    "value": "motorola moto g82 5G"
  },
  {
    "name": "EVERCOSS M6A",
    "label": "EVERCOSS M6A",
    "value": "EVERCOSS M6A"
  },
  {
    "name": "samsung SM-A707F",
    "label": "samsung SM-A707F",
    "value": "samsung SM-A707F"
  },
  {
    "name": "samsung SM-G988U",
    "label": "samsung SM-G988U",
    "value": "samsung SM-G988U"
  },
  {
    "name": "Apple Refilwe’s iPhone",
    "label": "Apple Refilwe’s iPhone",
    "value": "Apple Refilwe’s iPhone"
  },
  {
    "name": "realme RMX3092",
    "label": "realme RMX3092",
    "value": "realme RMX3092"
  },
  {
    "name": "samsung SM-S901B",
    "label": "samsung SM-S901B",
    "value": "samsung SM-S901B"
  },
  {
    "name": "HMD Global Nokia 6.1",
    "label": "HMD Global Nokia 6.1",
    "value": "HMD Global Nokia 6.1"
  },
  {
    "name": "Xiaomi SO-52A",
    "label": "Xiaomi SO-52A",
    "value": "Xiaomi SO-52A"
  },
  {
    "name": "vivo vivo 1726",
    "label": "vivo vivo 1726",
    "value": "vivo vivo 1726"
  },
  {
    "name": "Lanix X560",
    "label": "Lanix X560",
    "value": "Lanix X560"
  },
  {
    "name": "Xiaomi Redmi 4",
    "label": "Xiaomi Redmi 4",
    "value": "Xiaomi Redmi 4"
  },
  {
    "name": "samsung SM-A025G",
    "label": "samsung SM-A025G",
    "value": "samsung SM-A025G"
  },
  {
    "name": "Xiaomi Mi 9 Lite",
    "label": "Xiaomi Mi 9 Lite",
    "value": "Xiaomi Mi 9 Lite"
  },
  {
    "name": "Amazon KFMUWI",
    "label": "Amazon KFMUWI",
    "value": "Amazon KFMUWI"
  },
  {
    "name": "LGE LG-H871",
    "label": "LGE LG-H871",
    "value": "LGE LG-H871"
  },
  {
    "name": "HUAWEI ANE-LX3",
    "label": "HUAWEI ANE-LX3",
    "value": "HUAWEI ANE-LX3"
  },
  {
    "name": "OnePlus IV2201",
    "label": "OnePlus IV2201",
    "value": "OnePlus IV2201"
  },
  {
    "name": "Apple roderick sundiam’s iPhone",
    "label": "Apple roderick sundiam’s iPhone",
    "value": "Apple roderick sundiam’s iPhone"
  },
  {
    "name": "samsung SM-G988U1",
    "label": "samsung SM-G988U1",
    "value": "samsung SM-G988U1"
  },
  {
    "name": "realme RMX3311",
    "label": "realme RMX3311",
    "value": "realme RMX3311"
  },
  {
    "name": "OnePlus GM1913",
    "label": "OnePlus GM1913",
    "value": "OnePlus GM1913"
  },
  {
    "name": "samsung SM-G990B2",
    "label": "samsung SM-G990B2",
    "value": "samsung SM-G990B2"
  },
  {
    "name": "DOOGEE X96Pro",
    "label": "DOOGEE X96Pro",
    "value": "DOOGEE X96Pro"
  },
  {
    "name": "LAVA LN9910",
    "label": "LAVA LN9910",
    "value": "LAVA LN9910"
  },
  {
    "name": "samsung SM-G988B",
    "label": "samsung SM-G988B",
    "value": "samsung SM-G988B"
  },
  {
    "name": "samsung SM-G950U",
    "label": "samsung SM-G950U",
    "value": "samsung SM-G950U"
  },
  {
    "name": "samsung SM-N976B",
    "label": "samsung SM-N976B",
    "value": "samsung SM-N976B"
  },
  {
    "name": "samsung SM-J415FN",
    "label": "samsung SM-J415FN",
    "value": "samsung SM-J415FN"
  },
  {
    "name": "LGE LM-X520",
    "label": "LGE LM-X520",
    "value": "LGE LM-X520"
  },
  {
    "name": "vivo V2118",
    "label": "vivo V2118",
    "value": "vivo V2118"
  },
  {
    "name": "HONOR VNE-N41",
    "label": "HONOR VNE-N41",
    "value": "HONOR VNE-N41"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X606",
    "label": "INFINIX MOBILITY LIMITED Infinix X606",
    "value": "INFINIX MOBILITY LIMITED Infinix X606"
  },
  {
    "name": "GIONEE GIONEE_MAX Pro",
    "label": "GIONEE GIONEE_MAX Pro",
    "value": "GIONEE GIONEE_MAX Pro"
  },
  {
    "name": "HUAWEI PAR-LX9",
    "label": "HUAWEI PAR-LX9",
    "value": "HUAWEI PAR-LX9"
  },
  {
    "name": "LENOVO Lenovo TB-8506F",
    "label": "LENOVO Lenovo TB-8506F",
    "value": "LENOVO Lenovo TB-8506F"
  },
  {
    "name": "realme RMX3581",
    "label": "realme RMX3581",
    "value": "realme RMX3581"
  },
  {
    "name": "HXY BISON",
    "label": "HXY BISON",
    "value": "HXY BISON"
  },
  {
    "name": "motorola motorola edge 30",
    "label": "motorola motorola edge 30",
    "value": "motorola motorola edge 30"
  },
  {
    "name": "Google Pixel 3a XL",
    "label": "Google Pixel 3a XL",
    "value": "Google Pixel 3a XL"
  },
  {
    "name": "OPPO unknown",
    "label": "OPPO unknown",
    "value": "OPPO unknown"
  },
  {
    "name": "CORN C55 Pro",
    "label": "CORN C55 Pro",
    "value": "CORN C55 Pro"
  },
  {
    "name": "samsung SM-A307GN",
    "label": "samsung SM-A307GN",
    "value": "samsung SM-A307GN"
  },
  {
    "name": "motorola moto e",
    "label": "motorola moto e",
    "value": "motorola moto e"
  },
  {
    "name": "Mobicel V4",
    "label": "Mobicel V4",
    "value": "Mobicel V4"
  },
  {
    "name": "HUAWEI MAR-AL00",
    "label": "HUAWEI MAR-AL00",
    "value": "HUAWEI MAR-AL00"
  },
  {
    "name": "samsung SM-A426B",
    "label": "samsung SM-A426B",
    "value": "samsung SM-A426B"
  },
  {
    "name": "LGE LM-Q730",
    "label": "LGE LM-Q730",
    "value": "LGE LM-Q730"
  },
  {
    "name": "samsung SM-J415N",
    "label": "samsung SM-J415N",
    "value": "samsung SM-J415N"
  },
  {
    "name": "samsung SM-M536B",
    "label": "samsung SM-M536B",
    "value": "samsung SM-M536B"
  },
  {
    "name": "HUAWEI CLT-L04",
    "label": "HUAWEI CLT-L04",
    "value": "HUAWEI CLT-L04"
  },
  {
    "name": "Mobile Web - KAIOS",
    "label": "Mobile Web - KAIOS",
    "value": "Mobile Web - KAIOS"
  },
  {
    "name": "samsung SM-A045F",
    "label": "samsung SM-A045F",
    "value": "samsung SM-A045F"
  },
  {
    "name": "HUAWEI PRA-LX2",
    "label": "HUAWEI PRA-LX2",
    "value": "HUAWEI PRA-LX2"
  },
  {
    "name": "samsung SM-A326W",
    "label": "samsung SM-A326W",
    "value": "samsung SM-A326W"
  },
  {
    "name": "samsung SM-J710F",
    "label": "samsung SM-J710F",
    "value": "samsung SM-J710F"
  },
  {
    "name": "Xiaomi Redmi Y3",
    "label": "Xiaomi Redmi Y3",
    "value": "Xiaomi Redmi Y3"
  },
  {
    "name": "HUAWEI HMA-L09",
    "label": "HUAWEI HMA-L09",
    "value": "HUAWEI HMA-L09"
  },
  {
    "name": "OnePlus BE2013",
    "label": "OnePlus BE2013",
    "value": "OnePlus BE2013"
  },
  {
    "name": "vivo V2105",
    "label": "vivo V2105",
    "value": "vivo V2105"
  },
  {
    "name": "Reliance RC555L",
    "label": "Reliance RC555L",
    "value": "Reliance RC555L"
  },
  {
    "name": "vivo V2142",
    "label": "vivo V2142",
    "value": "vivo V2142"
  },
  {
    "name": "samsung SM-A920F",
    "label": "samsung SM-A920F",
    "value": "samsung SM-A920F"
  },
  {
    "name": "Apple Kim’s iPhone",
    "label": "Apple Kim’s iPhone",
    "value": "Apple Kim’s iPhone"
  },
  {
    "name": "vivo V2205",
    "label": "vivo V2205",
    "value": "vivo V2205"
  },
  {
    "name": "Ulefone Ulefone_S11",
    "label": "Ulefone Ulefone_S11",
    "value": "Ulefone Ulefone_S11"
  },
  {
    "name": "Mobiwire V23",
    "label": "Mobiwire V23",
    "value": "Mobiwire V23"
  },
  {
    "name": "ZTE P600",
    "label": "ZTE P600",
    "value": "ZTE P600"
  },
  {
    "name": "realme RMX3191",
    "label": "realme RMX3191",
    "value": "realme RMX3191"
  },
  {
    "name": "Apple JSBelcher iPhone",
    "label": "Apple JSBelcher iPhone",
    "value": "Apple JSBelcher iPhone"
  },
  {
    "name": "vivo vivo 1803",
    "label": "vivo vivo 1803",
    "value": "vivo vivo 1803"
  },
  {
    "name": "Xiaomi Mi Note 10 Pro",
    "label": "Xiaomi Mi Note 10 Pro",
    "value": "Xiaomi Mi Note 10 Pro"
  },
  {
    "name": "OPPO CPH2333",
    "label": "OPPO CPH2333",
    "value": "OPPO CPH2333"
  },
  {
    "name": "samsung SM-A405FM",
    "label": "samsung SM-A405FM",
    "value": "samsung SM-A405FM"
  },
  {
    "name": "HUAWEI ARE-L22HN",
    "label": "HUAWEI ARE-L22HN",
    "value": "HUAWEI ARE-L22HN"
  },
  {
    "name": "vivo V2116",
    "label": "vivo V2116",
    "value": "vivo V2116"
  },
  {
    "name": "OPPO CPH2457",
    "label": "OPPO CPH2457",
    "value": "OPPO CPH2457"
  },
  {
    "name": "samsung SM-M013F",
    "label": "samsung SM-M013F",
    "value": "samsung SM-M013F"
  },
  {
    "name": "samsung SM-A202F",
    "label": "samsung SM-A202F",
    "value": "samsung SM-A202F"
  },
  {
    "name": "iHunt iHunt S22 PLUS",
    "label": "iHunt iHunt S22 PLUS",
    "value": "iHunt iHunt S22 PLUS"
  },
  {
    "name": "motorola moto g(50)",
    "label": "motorola moto g(50)",
    "value": "motorola moto g(50)"
  },
  {
    "name": "Xiaomi 2201116SI",
    "label": "Xiaomi 2201116SI",
    "value": "Xiaomi 2201116SI"
  },
  {
    "name": "HUAWEI ANA-NX9",
    "label": "HUAWEI ANA-NX9",
    "value": "HUAWEI ANA-NX9"
  },
  {
    "name": "motorola moto x4",
    "label": "motorola moto x4",
    "value": "motorola moto x4"
  },
  {
    "name": "samsung SM-M307FN",
    "label": "samsung SM-M307FN",
    "value": "samsung SM-M307FN"
  },
  {
    "name": "samsung SM-J400M",
    "label": "samsung SM-J400M",
    "value": "samsung SM-J400M"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A510W",
    "label": "ITEL MOBILE LIMITED itel A510W",
    "value": "ITEL MOBILE LIMITED itel A510W"
  },
  {
    "name": "asus ASUS_X00QD",
    "label": "asus ASUS_X00QD",
    "value": "asus ASUS_X00QD"
  },
  {
    "name": "samsung SM-G973U",
    "label": "samsung SM-G973U",
    "value": "samsung SM-G973U"
  },
  {
    "name": "samsung SM-J700T",
    "label": "samsung SM-J700T",
    "value": "samsung SM-J700T"
  },
  {
    "name": "DOOGEE N40Pro",
    "label": "DOOGEE N40Pro",
    "value": "DOOGEE N40Pro"
  },
  {
    "name": "TCL 5024F_EEA",
    "label": "TCL 5024F_EEA",
    "value": "TCL 5024F_EEA"
  },
  {
    "name": "Apple iPad13,16",
    "label": "Apple iPad13,16",
    "value": "Apple iPad13,16"
  },
  {
    "name": "samsung SM-A236B",
    "label": "samsung SM-A236B",
    "value": "samsung SM-A236B"
  },
  {
    "name": "Apple iPad7,5",
    "label": "Apple iPad7,5",
    "value": "Apple iPad7,5"
  },
  {
    "name": "samsung SM-A205U",
    "label": "samsung SM-A205U",
    "value": "samsung SM-A205U"
  },
  {
    "name": "samsung SM-G973U1",
    "label": "samsung SM-G973U1",
    "value": "samsung SM-G973U1"
  },
  {
    "name": "Lenovo Lenovo L18021",
    "label": "Lenovo Lenovo L18021",
    "value": "Lenovo Lenovo L18021"
  },
  {
    "name": "samsung SM-A336M",
    "label": "samsung SM-A336M",
    "value": "samsung SM-A336M"
  },
  {
    "name": "samsung SM-G986N",
    "label": "samsung SM-G986N",
    "value": "samsung SM-G986N"
  },
  {
    "name": "motorola Moto G Play",
    "label": "motorola Moto G Play",
    "value": "motorola Moto G Play"
  },
  {
    "name": "asus ASUS_Z012DC",
    "label": "asus ASUS_Z012DC",
    "value": "asus ASUS_Z012DC"
  },
  {
    "name": "Sony XQ-AD51",
    "label": "Sony XQ-AD51",
    "value": "Sony XQ-AD51"
  },
  {
    "name": "EVERCOSS M60",
    "label": "EVERCOSS M60",
    "value": "EVERCOSS M60"
  },
  {
    "name": "LGE LM-G710N",
    "label": "LGE LM-G710N",
    "value": "LGE LM-G710N"
  },
  {
    "name": "samsung SM-A526W",
    "label": "samsung SM-A526W",
    "value": "samsung SM-A526W"
  },
  {
    "name": "GIONEE GIONEE M7",
    "label": "GIONEE GIONEE M7",
    "value": "GIONEE GIONEE M7"
  },
  {
    "name": "motorola moto g51 5G",
    "label": "motorola moto g51 5G",
    "value": "motorola moto g51 5G"
  },
  {
    "name": "WIKO W-K610-EEA",
    "label": "WIKO W-K610-EEA",
    "value": "WIKO W-K610-EEA"
  },
  {
    "name": "ZTE ZTE 8030",
    "label": "ZTE ZTE 8030",
    "value": "ZTE ZTE 8030"
  },
  {
    "name": "HMD Global Nokia G10",
    "label": "HMD Global Nokia G10",
    "value": "HMD Global Nokia G10"
  },
  {
    "name": "samsung SM-G611M",
    "label": "samsung SM-G611M",
    "value": "samsung SM-G611M"
  },
  {
    "name": "asus ZE553KL",
    "label": "asus ZE553KL",
    "value": "asus ZE553KL"
  },
  {
    "name": "Hisense Hisense Infinity H50 Lite",
    "label": "Hisense Hisense Infinity H50 Lite",
    "value": "Hisense Hisense Infinity H50 Lite"
  },
  {
    "name": "HUAWEI DUA-AL00",
    "label": "HUAWEI DUA-AL00",
    "value": "HUAWEI DUA-AL00"
  },
  {
    "name": "realme RMX2061",
    "label": "realme RMX2061",
    "value": "realme RMX2061"
  },
  {
    "name": "IFRE S.A. KODAK SMARTWAY L1 PRO",
    "label": "IFRE S.A. KODAK SMARTWAY L1 PRO",
    "value": "IFRE S.A. KODAK SMARTWAY L1 PRO"
  },
  {
    "name": "motorola Moto X Play",
    "label": "motorola Moto X Play",
    "value": "motorola Moto X Play"
  },
  {
    "name": "samsung SM-J701M",
    "label": "samsung SM-J701M",
    "value": "samsung SM-J701M"
  },
  {
    "name": "samsung SM-A528N",
    "label": "samsung SM-A528N",
    "value": "samsung SM-A528N"
  },
  {
    "name": "HMD Global Nokia 5.3",
    "label": "HMD Global Nokia 5.3",
    "value": "HMD Global Nokia 5.3"
  },
  {
    "name": "Xiaomi M2103K19I",
    "label": "Xiaomi M2103K19I",
    "value": "Xiaomi M2103K19I"
  },
  {
    "name": "iPhone11,8 iPhone",
    "label": "iPhone11,8 iPhone",
    "value": "iPhone11,8 iPhone"
  },
  {
    "name": "ITEL MOBILE LIMITED itel W4001",
    "label": "ITEL MOBILE LIMITED itel W4001",
    "value": "ITEL MOBILE LIMITED itel W4001"
  },
  {
    "name": "LENOVO Lenovo TB-X304L",
    "label": "LENOVO Lenovo TB-X304L",
    "value": "LENOVO Lenovo TB-X304L"
  },
  {
    "name": "samsung SM-M336BU",
    "label": "samsung SM-M336BU",
    "value": "samsung SM-M336BU"
  },
  {
    "name": "vivo I2012",
    "label": "vivo I2012",
    "value": "vivo I2012"
  },
  {
    "name": "OnePlus ONEPLUS A6013",
    "label": "OnePlus ONEPLUS A6013",
    "value": "OnePlus ONEPLUS A6013"
  },
  {
    "name": "Xiaomi 2107113SI",
    "label": "Xiaomi 2107113SI",
    "value": "Xiaomi 2107113SI"
  },
  {
    "name": "HMD Global Nokia 1.4",
    "label": "HMD Global Nokia 1.4",
    "value": "HMD Global Nokia 1.4"
  },
  {
    "name": "TCL 5029D_EEA",
    "label": "TCL 5029D_EEA",
    "value": "TCL 5029D_EEA"
  },
  {
    "name": "samsung SM-N981B",
    "label": "samsung SM-N981B",
    "value": "samsung SM-N981B"
  },
  {
    "name": "HMD Global Nokia 4.2",
    "label": "HMD Global Nokia 4.2",
    "value": "HMD Global Nokia 4.2"
  },
  {
    "name": "HMD Global Nokia G20",
    "label": "HMD Global Nokia G20",
    "value": "HMD Global Nokia G20"
  },
  {
    "name": "motorola motorola one hyper",
    "label": "motorola motorola one hyper",
    "value": "motorola motorola one hyper"
  },
  {
    "name": "samsung SM-P615",
    "label": "samsung SM-P615",
    "value": "samsung SM-P615"
  },
  {
    "name": "Xiaomi M2010J19SL",
    "label": "Xiaomi M2010J19SL",
    "value": "Xiaomi M2010J19SL"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BB2",
    "label": "TECNO MOBILE LIMITED TECNO BB2",
    "value": "TECNO MOBILE LIMITED TECNO BB2"
  },
  {
    "name": "samsung SM-E236B",
    "label": "samsung SM-E236B",
    "value": "samsung SM-E236B"
  },
  {
    "name": "HMD Global Nokia 3.1 Plus",
    "label": "HMD Global Nokia 3.1 Plus",
    "value": "HMD Global Nokia 3.1 Plus"
  },
  {
    "name": "Multilaser Multilaser_GMAX_2",
    "label": "Multilaser Multilaser_GMAX_2",
    "value": "Multilaser Multilaser_GMAX_2"
  },
  {
    "name": "samsung SM-F926N",
    "label": "samsung SM-F926N",
    "value": "samsung SM-F926N"
  },
  {
    "name": "LGE LM-G820N",
    "label": "LGE LM-G820N",
    "value": "LGE LM-G820N"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CH7n",
    "label": "TECNO MOBILE LIMITED TECNO CH7n",
    "value": "TECNO MOBILE LIMITED TECNO CH7n"
  },
  {
    "name": "TEM V9",
    "label": "TEM V9",
    "value": "TEM V9"
  },
  {
    "name": "HMD Global Nokia X10",
    "label": "HMD Global Nokia X10",
    "value": "HMD Global Nokia X10"
  },
  {
    "name": "samsung SM-G950N",
    "label": "samsung SM-G950N",
    "value": "samsung SM-G950N"
  },
  {
    "name": "samsung SM-T385",
    "label": "samsung SM-T385",
    "value": "samsung SM-T385"
  },
  {
    "name": "HUAWEI VKY-L29",
    "label": "HUAWEI VKY-L29",
    "value": "HUAWEI VKY-L29"
  },
  {
    "name": "LGE LM-V510N",
    "label": "LGE LM-V510N",
    "value": "LGE LM-V510N"
  },
  {
    "name": "Xiaomi 220733SFG",
    "label": "Xiaomi 220733SFG",
    "value": "Xiaomi 220733SFG"
  },
  {
    "name": "motorola motorola one action",
    "label": "motorola motorola one action",
    "value": "motorola motorola one action"
  },
  {
    "name": "samsung SM-G970N",
    "label": "samsung SM-G970N",
    "value": "samsung SM-G970N"
  },
  {
    "name": "realme RMX3381",
    "label": "realme RMX3381",
    "value": "realme RMX3381"
  },
  {
    "name": "vivo vivo 1815",
    "label": "vivo vivo 1815",
    "value": "vivo vivo 1815"
  },
  {
    "name": "LGE LM-Q510N",
    "label": "LGE LM-Q510N",
    "value": "LGE LM-Q510N"
  },
  {
    "name": "samsung SM-S908N",
    "label": "samsung SM-S908N",
    "value": "samsung SM-S908N"
  },
  {
    "name": "Xiaomi MI 8 Lite",
    "label": "Xiaomi MI 8 Lite",
    "value": "Xiaomi MI 8 Lite"
  },
  {
    "name": "samsung SM-E426S",
    "label": "samsung SM-E426S",
    "value": "samsung SM-E426S"
  },
  {
    "name": "OPPO CPH2375",
    "label": "OPPO CPH2375",
    "value": "OPPO CPH2375"
  },
  {
    "name": "samsung SM-A205GN",
    "label": "samsung SM-A205GN",
    "value": "samsung SM-A205GN"
  },
  {
    "name": "vivo V2036",
    "label": "vivo V2036",
    "value": "vivo V2036"
  },
  {
    "name": "vivo V2061",
    "label": "vivo V2061",
    "value": "vivo V2061"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LC7",
    "label": "TECNO MOBILE LIMITED TECNO LC7",
    "value": "TECNO MOBILE LIMITED TECNO LC7"
  },
  {
    "name": "Xiaomi MI 9",
    "label": "Xiaomi MI 9",
    "value": "Xiaomi MI 9"
  },
  {
    "name": "samsung SM-P550",
    "label": "samsung SM-P550",
    "value": "samsung SM-P550"
  },
  {
    "name": "samsung SM-N950U1",
    "label": "samsung SM-N950U1",
    "value": "samsung SM-N950U1"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BC3",
    "label": "TECNO MOBILE LIMITED TECNO BC3",
    "value": "TECNO MOBILE LIMITED TECNO BC3"
  },
  {
    "name": "realme RMX2027",
    "label": "realme RMX2027",
    "value": "realme RMX2027"
  },
  {
    "name": "ZUUM Stellar Max",
    "label": "ZUUM Stellar Max",
    "value": "ZUUM Stellar Max"
  },
  {
    "name": "TCL T774B",
    "label": "TCL T774B",
    "value": "TCL T774B"
  },
  {
    "name": "ZTE ZTE Blade A3 2020",
    "label": "ZTE ZTE Blade A3 2020",
    "value": "ZTE ZTE Blade A3 2020"
  },
  {
    "name": "HUAWEI FIG-LX3",
    "label": "HUAWEI FIG-LX3",
    "value": "HUAWEI FIG-LX3"
  },
  {
    "name": "motorola motorola one 5G ace",
    "label": "motorola motorola one 5G ace",
    "value": "motorola motorola one 5G ace"
  },
  {
    "name": "GIONEE GIONEE MAX",
    "label": "GIONEE GIONEE MAX",
    "value": "GIONEE GIONEE MAX"
  },
  {
    "name": "OPPO CPH2477",
    "label": "OPPO CPH2477",
    "value": "OPPO CPH2477"
  },
  {
    "name": "vivo V2157",
    "label": "vivo V2157",
    "value": "vivo V2157"
  },
  {
    "name": "Apple Alison’s iPhone",
    "label": "Apple Alison’s iPhone",
    "value": "Apple Alison’s iPhone"
  },
  {
    "name": "HMD Global TA-1032",
    "label": "HMD Global TA-1032",
    "value": "HMD Global TA-1032"
  },
  {
    "name": "realme RMX2002",
    "label": "realme RMX2002",
    "value": "realme RMX2002"
  },
  {
    "name": "OPPO CPH1912",
    "label": "OPPO CPH1912",
    "value": "OPPO CPH1912"
  },
  {
    "name": "ZTE ZTE A2121G",
    "label": "ZTE ZTE A2121G",
    "value": "ZTE ZTE A2121G"
  },
  {
    "name": "Sony SOV39",
    "label": "Sony SOV39",
    "value": "Sony SOV39"
  },
  {
    "name": "positivo Twist 4G",
    "label": "positivo Twist 4G",
    "value": "positivo Twist 4G"
  },
  {
    "name": "motorola moto e5 plus",
    "label": "motorola moto e5 plus",
    "value": "motorola moto e5 plus"
  },
  {
    "name": "SHARP SHV47",
    "label": "SHARP SHV47",
    "value": "SHARP SHV47"
  },
  {
    "name": "samsung SM-G935P",
    "label": "samsung SM-G935P",
    "value": "samsung SM-G935P"
  },
  {
    "name": "vivo V2041",
    "label": "vivo V2041",
    "value": "vivo V2041"
  },
  {
    "name": "HUAWEI DUB-AL20",
    "label": "HUAWEI DUB-AL20",
    "value": "HUAWEI DUB-AL20"
  },
  {
    "name": "OPPO CPH2373",
    "label": "OPPO CPH2373",
    "value": "OPPO CPH2373"
  },
  {
    "name": "samsung SM-J250M",
    "label": "samsung SM-J250M",
    "value": "samsung SM-J250M"
  },
  {
    "name": "vivo vivo 2005",
    "label": "vivo vivo 2005",
    "value": "vivo vivo 2005"
  },
  {
    "name": "samsung SM-M025F",
    "label": "samsung SM-M025F",
    "value": "samsung SM-M025F"
  },
  {
    "name": "Premio X85",
    "label": "Premio X85",
    "value": "Premio X85"
  },
  {
    "name": "Xiaomi 2201123G",
    "label": "Xiaomi 2201123G",
    "value": "Xiaomi 2201123G"
  },
  {
    "name": "samsung SM-G525N",
    "label": "samsung SM-G525N",
    "value": "samsung SM-G525N"
  },
  {
    "name": "vivo vivo 1917",
    "label": "vivo vivo 1917",
    "value": "vivo vivo 1917"
  },
  {
    "name": "Netease MuMu",
    "label": "Netease MuMu",
    "value": "Netease MuMu"
  },
  {
    "name": "vivo vivo 1919",
    "label": "vivo vivo 1919",
    "value": "vivo vivo 1919"
  },
  {
    "name": "samsung SM-A600FN",
    "label": "samsung SM-A600FN",
    "value": "samsung SM-A600FN"
  },
  {
    "name": "OnePlus ONEPLUS A3010",
    "label": "OnePlus ONEPLUS A3010",
    "value": "OnePlus ONEPLUS A3010"
  },
  {
    "name": "OPPO CPH2021",
    "label": "OPPO CPH2021",
    "value": "OPPO CPH2021"
  },
  {
    "name": "HUAWEI MRD-LX2",
    "label": "HUAWEI MRD-LX2",
    "value": "HUAWEI MRD-LX2"
  },
  {
    "name": "realme RMX3197",
    "label": "realme RMX3197",
    "value": "realme RMX3197"
  },
  {
    "name": "LENOVO Lenovo TB-X306X",
    "label": "LENOVO Lenovo TB-X306X",
    "value": "LENOVO Lenovo TB-X306X"
  },
  {
    "name": "ZTE ZTE Blade V10 Vita",
    "label": "ZTE ZTE Blade V10 Vita",
    "value": "ZTE ZTE Blade V10 Vita"
  },
  {
    "name": "BlackBerry BBC100-1",
    "label": "BlackBerry BBC100-1",
    "value": "BlackBerry BBC100-1"
  },
  {
    "name": "itel itel W5001P",
    "label": "itel itel W5001P",
    "value": "itel itel W5001P"
  },
  {
    "name": "samsung SM-G930W8",
    "label": "samsung SM-G930W8",
    "value": "samsung SM-G930W8"
  },
  {
    "name": "realme RMX3269",
    "label": "realme RMX3269",
    "value": "realme RMX3269"
  },
  {
    "name": "vivo V2124",
    "label": "vivo V2124",
    "value": "vivo V2124"
  },
  {
    "name": "HUAWEI MHA-L29",
    "label": "HUAWEI MHA-L29",
    "value": "HUAWEI MHA-L29"
  },
  {
    "name": "OPPO CPH2293",
    "label": "OPPO CPH2293",
    "value": "OPPO CPH2293"
  },
  {
    "name": "OPPO CPH2121",
    "label": "OPPO CPH2121",
    "value": "OPPO CPH2121"
  },
  {
    "name": "samsung SM-G998N",
    "label": "samsung SM-G998N",
    "value": "samsung SM-G998N"
  },
  {
    "name": "samsung SM-A536N",
    "label": "samsung SM-A536N",
    "value": "samsung SM-A536N"
  },
  {
    "name": "Xiaomi 22011119UY",
    "label": "Xiaomi 22011119UY",
    "value": "Xiaomi 22011119UY"
  },
  {
    "name": "vivo V2117",
    "label": "vivo V2117",
    "value": "vivo V2117"
  },
  {
    "name": "HUAWEI unknown",
    "label": "HUAWEI unknown",
    "value": "HUAWEI unknown"
  },
  {
    "name": "asus ASUS_X00HD",
    "label": "asus ASUS_X00HD",
    "value": "asus ASUS_X00HD"
  },
  {
    "name": "samsung SM-A505G",
    "label": "samsung SM-A505G",
    "value": "samsung SM-A505G"
  },
  {
    "name": "Sony F5121",
    "label": "Sony F5121",
    "value": "Sony F5121"
  },
  {
    "name": "positivo Twist 4",
    "label": "positivo Twist 4",
    "value": "positivo Twist 4"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BD4j",
    "label": "TECNO MOBILE LIMITED TECNO BD4j",
    "value": "TECNO MOBILE LIMITED TECNO BD4j"
  },
  {
    "name": "Xiaomi Mi 9T Pro",
    "label": "Xiaomi Mi 9T Pro",
    "value": "Xiaomi Mi 9T Pro"
  },
  {
    "name": "Xiaomi Redmi Note 10 Lite",
    "label": "Xiaomi Redmi Note 10 Lite",
    "value": "Xiaomi Redmi Note 10 Lite"
  },
  {
    "name": "Alldocube Tab_Ultra",
    "label": "Alldocube Tab_Ultra",
    "value": "Alldocube Tab_Ultra"
  },
  {
    "name": "Google octopus",
    "label": "Google octopus",
    "value": "Google octopus"
  },
  {
    "name": "OPPO CPH2421",
    "label": "OPPO CPH2421",
    "value": "OPPO CPH2421"
  },
  {
    "name": "Xiaomi MI 6X",
    "label": "Xiaomi MI 6X",
    "value": "Xiaomi MI 6X"
  },
  {
    "name": "HUAWEI ATU-LX3",
    "label": "HUAWEI ATU-LX3",
    "value": "HUAWEI ATU-LX3"
  },
  {
    "name": "Ulefone Armor 8 Pro",
    "label": "Ulefone Armor 8 Pro",
    "value": "Ulefone Armor 8 Pro"
  },
  {
    "name": "LENOVO Lenovo TB-J606F",
    "label": "LENOVO Lenovo TB-J606F",
    "value": "LENOVO Lenovo TB-J606F"
  },
  {
    "name": "samsung SM-J710K",
    "label": "samsung SM-J710K",
    "value": "samsung SM-J710K"
  },
  {
    "name": "OnePlus ONEPLUS A6003",
    "label": "OnePlus ONEPLUS A6003",
    "value": "OnePlus ONEPLUS A6003"
  },
  {
    "name": "vivo V2153",
    "label": "vivo V2153",
    "value": "vivo V2153"
  },
  {
    "name": "samsung SM-T550",
    "label": "samsung SM-T550",
    "value": "samsung SM-T550"
  },
  {
    "name": "motorola moto e6 (XT2005DL)",
    "label": "motorola moto e6 (XT2005DL)",
    "value": "motorola moto e6 (XT2005DL)"
  },
  {
    "name": "OPPO CPH2205",
    "label": "OPPO CPH2205",
    "value": "OPPO CPH2205"
  },
  {
    "name": "Xiaomi M2101K6P",
    "label": "Xiaomi M2101K6P",
    "value": "Xiaomi M2101K6P"
  },
  {
    "name": "Google Pixel 6",
    "label": "Google Pixel 6",
    "value": "Google Pixel 6"
  },
  {
    "name": "samsung SM-T870",
    "label": "samsung SM-T870",
    "value": "samsung SM-T870"
  },
  {
    "name": "LGE LG-M250",
    "label": "LGE LG-M250",
    "value": "LGE LG-M250"
  },
  {
    "name": "Sony SO-05K",
    "label": "Sony SO-05K",
    "value": "Sony SO-05K"
  },
  {
    "name": "Xiaomi MI 9 SE",
    "label": "Xiaomi MI 9 SE",
    "value": "Xiaomi MI 9 SE"
  },
  {
    "name": "Xiaomi M2002J9S",
    "label": "Xiaomi M2002J9S",
    "value": "Xiaomi M2002J9S"
  },
  {
    "name": "WIKO W-K380-TVM",
    "label": "WIKO W-K380-TVM",
    "value": "WIKO W-K380-TVM"
  },
  {
    "name": "Multilaser Multilaser_E",
    "label": "Multilaser Multilaser_E",
    "value": "Multilaser Multilaser_E"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KF7j",
    "label": "TECNO MOBILE LIMITED TECNO KF7j",
    "value": "TECNO MOBILE LIMITED TECNO KF7j"
  },
  {
    "name": "itel itel W6501",
    "label": "itel itel W6501",
    "value": "itel itel W6501"
  },
  {
    "name": "ZTE ZTE 2050",
    "label": "ZTE ZTE 2050",
    "value": "ZTE ZTE 2050"
  },
  {
    "name": "motorola moto g71 5G",
    "label": "motorola moto g71 5G",
    "value": "motorola moto g71 5G"
  },
  {
    "name": "HMD Global Nokia C01 Plus",
    "label": "HMD Global Nokia C01 Plus",
    "value": "HMD Global Nokia C01 Plus"
  },
  {
    "name": "OPPO CPH2067",
    "label": "OPPO CPH2067",
    "value": "OPPO CPH2067"
  },
  {
    "name": "Sony SOV33",
    "label": "Sony SOV33",
    "value": "Sony SOV33"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6815B",
    "label": "INFINIX MOBILITY LIMITED Infinix X6815B",
    "value": "INFINIX MOBILITY LIMITED Infinix X6815B"
  },
  {
    "name": "Cherry Mobile Magnum 11",
    "label": "Cherry Mobile Magnum 11",
    "value": "Cherry Mobile Magnum 11"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO B1g",
    "label": "TECNO MOBILE LIMITED TECNO B1g",
    "value": "TECNO MOBILE LIMITED TECNO B1g"
  },
  {
    "name": "vivo V2102",
    "label": "vivo V2102",
    "value": "vivo V2102"
  },
  {
    "name": "Google Pixel 4a",
    "label": "Google Pixel 4a",
    "value": "Google Pixel 4a"
  },
  {
    "name": "samsung SM-N986B",
    "label": "samsung SM-N986B",
    "value": "samsung SM-N986B"
  },
  {
    "name": "HUAWEI EVR-L29",
    "label": "HUAWEI EVR-L29",
    "value": "HUAWEI EVR-L29"
  },
  {
    "name": "Apple iPad7,12",
    "label": "Apple iPad7,12",
    "value": "Apple iPad7,12"
  },
  {
    "name": "Mara Phones Mara Phones X1",
    "label": "Mara Phones Mara Phones X1",
    "value": "Mara Phones Mara Phones X1"
  },
  {
    "name": "positivo Twist 4 Fit",
    "label": "positivo Twist 4 Fit",
    "value": "positivo Twist 4 Fit"
  },
  {
    "name": "YES YES Altitude 4",
    "label": "YES YES Altitude 4",
    "value": "YES YES Altitude 4"
  },
  {
    "name": "samsung SM-P580",
    "label": "samsung SM-P580",
    "value": "samsung SM-P580"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X689C",
    "label": "INFINIX MOBILITY LIMITED Infinix X689C",
    "value": "INFINIX MOBILITY LIMITED Infinix X689C"
  },
  {
    "name": "HMD Global Nokia C3",
    "label": "HMD Global Nokia C3",
    "value": "HMD Global Nokia C3"
  },
  {
    "name": "Google Pixel 3",
    "label": "Google Pixel 3",
    "value": "Google Pixel 3"
  },
  {
    "name": "BLU J9L",
    "label": "BLU J9L",
    "value": "BLU J9L"
  },
  {
    "name": "TCL T700X",
    "label": "TCL T700X",
    "value": "TCL T700X"
  },
  {
    "name": "samsung SM-A705YN",
    "label": "samsung SM-A705YN",
    "value": "samsung SM-A705YN"
  },
  {
    "name": "samsung SM-F916B",
    "label": "samsung SM-F916B",
    "value": "samsung SM-F916B"
  },
  {
    "name": "motorola motorola edge 20 pro",
    "label": "motorola motorola edge 20 pro",
    "value": "motorola motorola edge 20 pro"
  },
  {
    "name": "vivo vivo 1727",
    "label": "vivo vivo 1727",
    "value": "vivo vivo 1727"
  },
  {
    "name": "asus ASUS_I005DA",
    "label": "asus ASUS_I005DA",
    "value": "asus ASUS_I005DA"
  },
  {
    "name": "Mobicel FAME",
    "label": "Mobicel FAME",
    "value": "Mobicel FAME"
  },
  {
    "name": "LGE LG-H870AR",
    "label": "LGE LG-H870AR",
    "value": "LGE LG-H870AR"
  },
  {
    "name": "samsung SM-T505",
    "label": "samsung SM-T505",
    "value": "samsung SM-T505"
  },
  {
    "name": "LGE LM-X510L",
    "label": "LGE LM-X510L",
    "value": "LGE LM-X510L"
  },
  {
    "name": "Jio LS1542QWN",
    "label": "Jio LS1542QWN",
    "value": "Jio LS1542QWN"
  },
  {
    "name": "vivo vivo 1812",
    "label": "vivo vivo 1812",
    "value": "vivo vivo 1812"
  },
  {
    "name": "TCL T780H",
    "label": "TCL T780H",
    "value": "TCL T780H"
  },
  {
    "name": "samsung SM-M526BR",
    "label": "samsung SM-M526BR",
    "value": "samsung SM-M526BR"
  },
  {
    "name": "motorola motorola edge",
    "label": "motorola motorola edge",
    "value": "motorola motorola edge"
  },
  {
    "name": "HOMTOM P30 Pro",
    "label": "HOMTOM P30 Pro",
    "value": "HOMTOM P30 Pro"
  },
  {
    "name": "samsung SM-F936N",
    "label": "samsung SM-F936N",
    "value": "samsung SM-F936N"
  },
  {
    "name": "Realme RMX1945",
    "label": "Realme RMX1945",
    "value": "Realme RMX1945"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LE6",
    "label": "TECNO MOBILE LIMITED TECNO LE6",
    "value": "TECNO MOBILE LIMITED TECNO LE6"
  },
  {
    "name": "HUAWEI INE-LX1",
    "label": "HUAWEI INE-LX1",
    "value": "HUAWEI INE-LX1"
  },
  {
    "name": "TCL 6060S",
    "label": "TCL 6060S",
    "value": "TCL 6060S"
  },
  {
    "name": "MITO MITO_A37_Z1",
    "label": "MITO MITO_A37_Z1",
    "value": "MITO MITO_A37_Z1"
  },
  {
    "name": "CUBOT NOTE 20 PRO",
    "label": "CUBOT NOTE 20 PRO",
    "value": "CUBOT NOTE 20 PRO"
  },
  {
    "name": "HUAWEI KSA-LX3",
    "label": "HUAWEI KSA-LX3",
    "value": "HUAWEI KSA-LX3"
  },
  {
    "name": "itel itel W6002E",
    "label": "itel itel W6002E",
    "value": "itel itel W6002E"
  },
  {
    "name": "HYUNDAI E553",
    "label": "HYUNDAI E553",
    "value": "HYUNDAI E553"
  },
  {
    "name": "samsung SM-N9810",
    "label": "samsung SM-N9810",
    "value": "samsung SM-N9810"
  },
  {
    "name": "Xiaomi Redmi K20 Pro",
    "label": "Xiaomi Redmi K20 Pro",
    "value": "Xiaomi Redmi K20 Pro"
  },
  {
    "name": "Xiaomi 211033MI",
    "label": "Xiaomi 211033MI",
    "value": "Xiaomi 211033MI"
  },
  {
    "name": "vivo V2039",
    "label": "vivo V2039",
    "value": "vivo V2039"
  },
  {
    "name": "asus ASUS_I01WD",
    "label": "asus ASUS_I01WD",
    "value": "asus ASUS_I01WD"
  },
  {
    "name": "nubia Nubia M2",
    "label": "nubia Nubia M2",
    "value": "nubia Nubia M2"
  },
  {
    "name": "Mobicel SWITCH1",
    "label": "Mobicel SWITCH1",
    "value": "Mobicel SWITCH1"
  },
  {
    "name": "Xiaomi MI PLAY",
    "label": "Xiaomi MI PLAY",
    "value": "Xiaomi MI PLAY"
  },
  {
    "name": "realme RMX3392",
    "label": "realme RMX3392",
    "value": "realme RMX3392"
  },
  {
    "name": "Apple iPod9,1",
    "label": "Apple iPod9,1",
    "value": "Apple iPod9,1"
  },
  {
    "name": "motorola motorola edge plus",
    "label": "motorola motorola edge plus",
    "value": "motorola motorola edge plus"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A16 Plus",
    "label": "ITEL MOBILE LIMITED itel A16 Plus",
    "value": "ITEL MOBILE LIMITED itel A16 Plus"
  },
  {
    "name": "samsung SM-A536V",
    "label": "samsung SM-A536V",
    "value": "samsung SM-A536V"
  },
  {
    "name": "samsung SM-J510MN",
    "label": "samsung SM-J510MN",
    "value": "samsung SM-J510MN"
  },
  {
    "name": "vivo V2028",
    "label": "vivo V2028",
    "value": "vivo V2028"
  },
  {
    "name": "samsung SM-A605F",
    "label": "samsung SM-A605F",
    "value": "samsung SM-A605F"
  },
  {
    "name": "OnePlus EB2101",
    "label": "OnePlus EB2101",
    "value": "OnePlus EB2101"
  },
  {
    "name": "LENOVO Lenovo TB-X606F",
    "label": "LENOVO Lenovo TB-X606F",
    "value": "LENOVO Lenovo TB-X606F"
  },
  {
    "name": "samsung SM-A037U",
    "label": "samsung SM-A037U",
    "value": "samsung SM-A037U"
  },
  {
    "name": "HMD Global Nokia 3.4",
    "label": "HMD Global Nokia 3.4",
    "value": "HMD Global Nokia 3.4"
  },
  {
    "name": "TCL 5029E",
    "label": "TCL 5029E",
    "value": "TCL 5029E"
  },
  {
    "name": "samsung SM-A605FN",
    "label": "samsung SM-A605FN",
    "value": "samsung SM-A605FN"
  },
  {
    "name": "HUAWEI SNE-LX3",
    "label": "HUAWEI SNE-LX3",
    "value": "HUAWEI SNE-LX3"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CH7",
    "label": "TECNO MOBILE LIMITED TECNO CH7",
    "value": "TECNO MOBILE LIMITED TECNO CH7"
  },
  {
    "name": "Logicom Le_Spark",
    "label": "Logicom Le_Spark",
    "value": "Logicom Le_Spark"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X650",
    "label": "INFINIX MOBILITY LIMITED Infinix X650",
    "value": "INFINIX MOBILITY LIMITED Infinix X650"
  },
  {
    "name": "OnePlus KB2001",
    "label": "OnePlus KB2001",
    "value": "OnePlus KB2001"
  },
  {
    "name": "samsung SM-A805N",
    "label": "samsung SM-A805N",
    "value": "samsung SM-A805N"
  },
  {
    "name": "asus ASUS_Z01RD",
    "label": "asus ASUS_Z01RD",
    "value": "asus ASUS_Z01RD"
  },
  {
    "name": "vivo I2202",
    "label": "vivo I2202",
    "value": "vivo I2202"
  },
  {
    "name": "vivo vivo 1851",
    "label": "vivo vivo 1851",
    "value": "vivo vivo 1851"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X606C",
    "label": "INFINIX MOBILITY LIMITED Infinix X606C",
    "value": "INFINIX MOBILITY LIMITED Infinix X606C"
  },
  {
    "name": "Sony H3113",
    "label": "Sony H3113",
    "value": "Sony H3113"
  },
  {
    "name": "CUBOT X20 PRO",
    "label": "CUBOT X20 PRO",
    "value": "CUBOT X20 PRO"
  },
  {
    "name": "TCL 5002H_EEA",
    "label": "TCL 5002H_EEA",
    "value": "TCL 5002H_EEA"
  },
  {
    "name": "vivo V2048",
    "label": "vivo V2048",
    "value": "vivo V2048"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CG6j",
    "label": "TECNO MOBILE LIMITED TECNO CG6j",
    "value": "TECNO MOBILE LIMITED TECNO CG6j"
  },
  {
    "name": "vivo V2057",
    "label": "vivo V2057",
    "value": "vivo V2057"
  },
  {
    "name": "myPhone Hammer_IRON_3_LTE",
    "label": "myPhone Hammer_IRON_3_LTE",
    "value": "myPhone Hammer_IRON_3_LTE"
  },
  {
    "name": "OnePlus EB2103",
    "label": "OnePlus EB2103",
    "value": "OnePlus EB2103"
  },
  {
    "name": "LGE LM-X540",
    "label": "LGE LM-X540",
    "value": "LGE LM-X540"
  },
  {
    "name": "Xiaomi M2101K9G",
    "label": "Xiaomi M2101K9G",
    "value": "Xiaomi M2101K9G"
  },
  {
    "name": "OnePlus HD1900",
    "label": "OnePlus HD1900",
    "value": "OnePlus HD1900"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BD2p",
    "label": "TECNO MOBILE LIMITED TECNO BD2p",
    "value": "TECNO MOBILE LIMITED TECNO BD2p"
  },
  {
    "name": "realme RMX3491",
    "label": "realme RMX3491",
    "value": "realme RMX3491"
  },
  {
    "name": "Multilaser Multilaser_F",
    "label": "Multilaser Multilaser_F",
    "value": "Multilaser Multilaser_F"
  },
  {
    "name": "SHARP SHV40",
    "label": "SHARP SHV40",
    "value": "SHARP SHV40"
  },
  {
    "name": "vsmart Active 3",
    "label": "vsmart Active 3",
    "value": "vsmart Active 3"
  },
  {
    "name": "Xiaomi 21091116I",
    "label": "Xiaomi 21091116I",
    "value": "Xiaomi 21091116I"
  },
  {
    "name": "LAVA LAVA LMX06",
    "label": "LAVA LAVA LMX06",
    "value": "LAVA LAVA LMX06"
  },
  {
    "name": "WIKO W_K600",
    "label": "WIKO W_K600",
    "value": "WIKO W_K600"
  },
  {
    "name": "HUAWEI AGS2-W09",
    "label": "HUAWEI AGS2-W09",
    "value": "HUAWEI AGS2-W09"
  },
  {
    "name": "Cherry_Mobile Aqua S9",
    "label": "Cherry_Mobile Aqua S9",
    "value": "Cherry_Mobile Aqua S9"
  },
  {
    "name": "samsung SM-G977U",
    "label": "samsung SM-G977U",
    "value": "samsung SM-G977U"
  },
  {
    "name": "samsung SM-G975N",
    "label": "samsung SM-G975N",
    "value": "samsung SM-G975N"
  },
  {
    "name": "Safaricom NEON_RAY",
    "label": "Safaricom NEON_RAY",
    "value": "Safaricom NEON_RAY"
  },
  {
    "name": "samsung SM-E225F",
    "label": "samsung SM-E225F",
    "value": "samsung SM-E225F"
  },
  {
    "name": "Sony I3312",
    "label": "Sony I3312",
    "value": "Sony I3312"
  },
  {
    "name": "HUAWEI JNY-LX2",
    "label": "HUAWEI JNY-LX2",
    "value": "HUAWEI JNY-LX2"
  },
  {
    "name": "Xiaomi 22021211RG",
    "label": "Xiaomi 22021211RG",
    "value": "Xiaomi 22021211RG"
  },
  {
    "name": "Xiaomi 2201117PI",
    "label": "Xiaomi 2201117PI",
    "value": "Xiaomi 2201117PI"
  },
  {
    "name": "TCL 5030D_EEA",
    "label": "TCL 5030D_EEA",
    "value": "TCL 5030D_EEA"
  },
  {
    "name": "realme RMX3031",
    "label": "realme RMX3031",
    "value": "realme RMX3031"
  },
  {
    "name": "HUAWEI MED-LX9",
    "label": "HUAWEI MED-LX9",
    "value": "HUAWEI MED-LX9"
  },
  {
    "name": "vivo V2134",
    "label": "vivo V2134",
    "value": "vivo V2134"
  },
  {
    "name": "Trekstor PEAQ PET 100",
    "label": "Trekstor PEAQ PET 100",
    "value": "Trekstor PEAQ PET 100"
  },
  {
    "name": "motorola XT1635-02",
    "label": "motorola XT1635-02",
    "value": "motorola XT1635-02"
  },
  {
    "name": "SEMP GO5E",
    "label": "SEMP GO5E",
    "value": "SEMP GO5E"
  },
  {
    "name": "INFINIX Infinix X671B",
    "label": "INFINIX Infinix X671B",
    "value": "INFINIX Infinix X671B"
  },
  {
    "name": "vsmart Aris",
    "label": "vsmart Aris",
    "value": "vsmart Aris"
  },
  {
    "name": "bq Aquaris X",
    "label": "bq Aquaris X",
    "value": "bq Aquaris X"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO B1p",
    "label": "TECNO MOBILE LIMITED TECNO B1p",
    "value": "TECNO MOBILE LIMITED TECNO B1p"
  },
  {
    "name": "Realme RMX1943",
    "label": "Realme RMX1943",
    "value": "Realme RMX1943"
  },
  {
    "name": "HUAWEI ALP-L29",
    "label": "HUAWEI ALP-L29",
    "value": "HUAWEI ALP-L29"
  },
  {
    "name": "HXY BISON Pro",
    "label": "HXY BISON Pro",
    "value": "HXY BISON Pro"
  },
  {
    "name": "HUAWEI PPA-LX3",
    "label": "HUAWEI PPA-LX3",
    "value": "HUAWEI PPA-LX3"
  },
  {
    "name": "TCL 5028A",
    "label": "TCL 5028A",
    "value": "TCL 5028A"
  },
  {
    "name": "Xiaomi 21061110AG",
    "label": "Xiaomi 21061110AG",
    "value": "Xiaomi 21061110AG"
  },
  {
    "name": "OnePlus LE2113",
    "label": "OnePlus LE2113",
    "value": "OnePlus LE2113"
  },
  {
    "name": "HUAWEI DRA-LX3",
    "label": "HUAWEI DRA-LX3",
    "value": "HUAWEI DRA-LX3"
  },
  {
    "name": "blackshark SHARK KLE-H0",
    "label": "blackshark SHARK KLE-H0",
    "value": "blackshark SHARK KLE-H0"
  },
  {
    "name": "TCL 5030F_EEA",
    "label": "TCL 5030F_EEA",
    "value": "TCL 5030F_EEA"
  },
  {
    "name": "Xiaomi 2207117BPG",
    "label": "Xiaomi 2207117BPG",
    "value": "Xiaomi 2207117BPG"
  },
  {
    "name": "OPPO PEAM00",
    "label": "OPPO PEAM00",
    "value": "OPPO PEAM00"
  },
  {
    "name": "nubia NX651J",
    "label": "nubia NX651J",
    "value": "nubia NX651J"
  },
  {
    "name": "Apple iPad5,2",
    "label": "Apple iPad5,2",
    "value": "Apple iPad5,2"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO RB6S",
    "label": "TECNO MOBILE LIMITED TECNO RB6S",
    "value": "TECNO MOBILE LIMITED TECNO RB6S"
  },
  {
    "name": "Xiaomi 21121119VL",
    "label": "Xiaomi 21121119VL",
    "value": "Xiaomi 21121119VL"
  },
  {
    "name": "LENOVO Lenovo TB-X505L",
    "label": "LENOVO Lenovo TB-X505L",
    "value": "LENOVO Lenovo TB-X505L"
  },
  {
    "name": "Sony J9110",
    "label": "Sony J9110",
    "value": "Sony J9110"
  },
  {
    "name": "samsung SM-A035G",
    "label": "samsung SM-A035G",
    "value": "samsung SM-A035G"
  },
  {
    "name": "OnePlus KB2003",
    "label": "OnePlus KB2003",
    "value": "OnePlus KB2003"
  },
  {
    "name": "GIONEE M7 Power",
    "label": "GIONEE M7 Power",
    "value": "GIONEE M7 Power"
  },
  {
    "name": "samsung SM-A102U",
    "label": "samsung SM-A102U",
    "value": "samsung SM-A102U"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CE8",
    "label": "TECNO MOBILE LIMITED TECNO CE8",
    "value": "TECNO MOBILE LIMITED TECNO CE8"
  },
  {
    "name": "Southern Telecom ST7160",
    "label": "Southern Telecom ST7160",
    "value": "Southern Telecom ST7160"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X650B",
    "label": "INFINIX MOBILITY LIMITED Infinix X650B",
    "value": "INFINIX MOBILITY LIMITED Infinix X650B"
  },
  {
    "name": "samsung SM-M305F",
    "label": "samsung SM-M305F",
    "value": "samsung SM-M305F"
  },
  {
    "name": "TCL 5048A",
    "label": "TCL 5048A",
    "value": "TCL 5048A"
  },
  {
    "name": "LENOVO Lenovo TB-7305X",
    "label": "LENOVO Lenovo TB-7305X",
    "value": "LENOVO Lenovo TB-7305X"
  },
  {
    "name": "iPhone9,3 iPhone",
    "label": "iPhone9,3 iPhone",
    "value": "iPhone9,3 iPhone"
  },
  {
    "name": "ZTE ZTE Blade A3 2019",
    "label": "ZTE ZTE Blade A3 2019",
    "value": "ZTE ZTE Blade A3 2019"
  },
  {
    "name": "Apple iPad11,1",
    "label": "Apple iPad11,1",
    "value": "Apple iPad11,1"
  },
  {
    "name": "samsung SM-T590",
    "label": "samsung SM-T590",
    "value": "samsung SM-T590"
  },
  {
    "name": "vivo V2130",
    "label": "vivo V2130",
    "value": "vivo V2130"
  },
  {
    "name": "Xiaomi M2101K6R",
    "label": "Xiaomi M2101K6R",
    "value": "Xiaomi M2101K6R"
  },
  {
    "name": "Apple iPad5,1",
    "label": "Apple iPad5,1",
    "value": "Apple iPad5,1"
  },
  {
    "name": "samsung SM-G965U",
    "label": "samsung SM-G965U",
    "value": "samsung SM-G965U"
  },
  {
    "name": "Philco PCS02",
    "label": "Philco PCS02",
    "value": "Philco PCS02"
  },
  {
    "name": "TCL HEYOU70",
    "label": "TCL HEYOU70",
    "value": "TCL HEYOU70"
  },
  {
    "name": "Xiaomi rr_begonia",
    "label": "Xiaomi rr_begonia",
    "value": "Xiaomi rr_begonia"
  },
  {
    "name": "asus ASUS_X00PD",
    "label": "asus ASUS_X00PD",
    "value": "asus ASUS_X00PD"
  },
  {
    "name": "OnePlus CPH2399",
    "label": "OnePlus CPH2399",
    "value": "OnePlus CPH2399"
  },
  {
    "name": "HUAWEI HRY-AL00a",
    "label": "HUAWEI HRY-AL00a",
    "value": "HUAWEI HRY-AL00a"
  },
  {
    "name": "Vanzo A4_Pro",
    "label": "Vanzo A4_Pro",
    "value": "Vanzo A4_Pro"
  },
  {
    "name": "TCL T775B",
    "label": "TCL T775B",
    "value": "TCL T775B"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LA7",
    "label": "TECNO MOBILE LIMITED TECNO LA7",
    "value": "TECNO MOBILE LIMITED TECNO LA7"
  },
  {
    "name": "motorola moto g 5G plus",
    "label": "motorola moto g 5G plus",
    "value": "motorola moto g 5G plus"
  },
  {
    "name": "samsung SM-P205",
    "label": "samsung SM-P205",
    "value": "samsung SM-P205"
  },
  {
    "name": "Lanix Ilium M5",
    "label": "Lanix Ilium M5",
    "value": "Lanix Ilium M5"
  },
  {
    "name": "HUAWEI AMN-LX3",
    "label": "HUAWEI AMN-LX3",
    "value": "HUAWEI AMN-LX3"
  },
  {
    "name": "ZTE ZTE Blade A31 Lite",
    "label": "ZTE ZTE Blade A31 Lite",
    "value": "ZTE ZTE Blade A31 Lite"
  },
  {
    "name": "Lekwene iPad  iPad",
    "label": "Lekwene iPad  iPad",
    "value": "Lekwene iPad  iPad"
  },
  {
    "name": "HUAWEI PPA-LX2",
    "label": "HUAWEI PPA-LX2",
    "value": "HUAWEI PPA-LX2"
  },
  {
    "name": "Quantum LIO+",
    "label": "Quantum LIO+",
    "value": "Quantum LIO+"
  },
  {
    "name": "motorola moto g power (2021)",
    "label": "motorola moto g power (2021)",
    "value": "motorola moto g power (2021)"
  },
  {
    "name": "HUAWEI POT-LX1T",
    "label": "HUAWEI POT-LX1T",
    "value": "HUAWEI POT-LX1T"
  },
  {
    "name": "HMD Global Nokia X6",
    "label": "HMD Global Nokia X6",
    "value": "HMD Global Nokia X6"
  },
  {
    "name": "samsung SM-J730K",
    "label": "samsung SM-J730K",
    "value": "samsung SM-J730K"
  },
  {
    "name": "HMD Global TA-1021",
    "label": "HMD Global TA-1021",
    "value": "HMD Global TA-1021"
  },
  {
    "name": "Smart Products Connection SA GRAVITY_OCTACORE",
    "label": "Smart Products Connection SA GRAVITY_OCTACORE",
    "value": "Smart Products Connection SA GRAVITY_OCTACORE"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X624B",
    "label": "INFINIX MOBILITY LIMITED Infinix X624B",
    "value": "INFINIX MOBILITY LIMITED Infinix X624B"
  },
  {
    "name": "TCL 5002B",
    "label": "TCL 5002B",
    "value": "TCL 5002B"
  },
  {
    "name": "HMD Global Nokia G21",
    "label": "HMD Global Nokia G21",
    "value": "HMD Global Nokia G21"
  },
  {
    "name": "QUEST RAPTOR",
    "label": "QUEST RAPTOR",
    "value": "QUEST RAPTOR"
  },
  {
    "name": "TCL T766J",
    "label": "TCL T766J",
    "value": "TCL T766J"
  },
  {
    "name": "OPPO CPH2385",
    "label": "OPPO CPH2385",
    "value": "OPPO CPH2385"
  },
  {
    "name": "OPPO CPH2043",
    "label": "OPPO CPH2043",
    "value": "OPPO CPH2043"
  },
  {
    "name": "samsung SM-M205F",
    "label": "samsung SM-M205F",
    "value": "samsung SM-M205F"
  },
  {
    "name": "samsung SM-G611F",
    "label": "samsung SM-G611F",
    "value": "samsung SM-G611F"
  },
  {
    "name": "TCL 5001A",
    "label": "TCL 5001A",
    "value": "TCL 5001A"
  },
  {
    "name": "Google ASUS Chromebook Flip C302",
    "label": "Google ASUS Chromebook Flip C302",
    "value": "Google ASUS Chromebook Flip C302"
  },
  {
    "name": "samsung SM-A530W",
    "label": "samsung SM-A530W",
    "value": "samsung SM-A530W"
  },
  {
    "name": "DOOGEE S58Pro",
    "label": "DOOGEE S58Pro",
    "value": "DOOGEE S58Pro"
  },
  {
    "name": "hena M77QF6",
    "label": "hena M77QF6",
    "value": "hena M77QF6"
  },
  {
    "name": "Sony XQ-BT52",
    "label": "Sony XQ-BT52",
    "value": "Sony XQ-BT52"
  },
  {
    "name": "samsung SM-T595",
    "label": "samsung SM-T595",
    "value": "samsung SM-T595"
  },
  {
    "name": "realme RMX2163",
    "label": "realme RMX2163",
    "value": "realme RMX2163"
  },
  {
    "name": "VSUN PTB10R",
    "label": "VSUN PTB10R",
    "value": "VSUN PTB10R"
  },
  {
    "name": "HONOR TFY-LX1",
    "label": "HONOR TFY-LX1",
    "value": "HONOR TFY-LX1"
  },
  {
    "name": "Positivo Twist 2 Fit",
    "label": "Positivo Twist 2 Fit",
    "value": "Positivo Twist 2 Fit"
  },
  {
    "name": "samsung SM-G955U",
    "label": "samsung SM-G955U",
    "value": "samsung SM-G955U"
  },
  {
    "name": "motorola Moto Z2 Play",
    "label": "motorola Moto Z2 Play",
    "value": "motorola Moto Z2 Play"
  },
  {
    "name": "Multilaser Multilaser H",
    "label": "Multilaser Multilaser H",
    "value": "Multilaser Multilaser H"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KB7j",
    "label": "TECNO MOBILE LIMITED TECNO KB7j",
    "value": "TECNO MOBILE LIMITED TECNO KB7j"
  },
  {
    "name": "Desktop - windows",
    "label": "Desktop - windows",
    "value": "Desktop - windows"
  },
  {
    "name": "samsung SM-A715W",
    "label": "samsung SM-A715W",
    "value": "samsung SM-A715W"
  },
  {
    "name": "ZTE ZTE Blade A5 2019",
    "label": "ZTE ZTE Blade A5 2019",
    "value": "ZTE ZTE Blade A5 2019"
  },
  {
    "name": "Philco PTB7R",
    "label": "Philco PTB7R",
    "value": "Philco PTB7R"
  },
  {
    "name": "HONOR CMA-LX2",
    "label": "HONOR CMA-LX2",
    "value": "HONOR CMA-LX2"
  },
  {
    "name": "motorola moto g fast",
    "label": "motorola moto g fast",
    "value": "motorola moto g fast"
  },
  {
    "name": "samsung SM-F936B",
    "label": "samsung SM-F936B",
    "value": "samsung SM-F936B"
  },
  {
    "name": "Xiaomi 2201117SL",
    "label": "Xiaomi 2201117SL",
    "value": "Xiaomi 2201117SL"
  },
  {
    "name": "TCL 6165H",
    "label": "TCL 6165H",
    "value": "TCL 6165H"
  },
  {
    "name": "Chinoe BV4900s",
    "label": "Chinoe BV4900s",
    "value": "Chinoe BV4900s"
  },
  {
    "name": "Mobicel LEGEND MAX",
    "label": "Mobicel LEGEND MAX",
    "value": "Mobicel LEGEND MAX"
  },
  {
    "name": "ZTE Z6400C",
    "label": "ZTE Z6400C",
    "value": "ZTE Z6400C"
  },
  {
    "name": "TCL 5048Y_EEA",
    "label": "TCL 5048Y_EEA",
    "value": "TCL 5048Y_EEA"
  },
  {
    "name": "samsung SM-J330F",
    "label": "samsung SM-J330F",
    "value": "samsung SM-J330F"
  },
  {
    "name": "Umx U696CL",
    "label": "Umx U696CL",
    "value": "Umx U696CL"
  },
  {
    "name": "LENOVO Lenovo TB-X104F",
    "label": "LENOVO Lenovo TB-X104F",
    "value": "LENOVO Lenovo TB-X104F"
  },
  {
    "name": "motorola moto g stylus 5G",
    "label": "motorola moto g stylus 5G",
    "value": "motorola moto g stylus 5G"
  },
  {
    "name": "KODAK SMARTWAY_F1",
    "label": "KODAK SMARTWAY_F1",
    "value": "KODAK SMARTWAY_F1"
  },
  {
    "name": "HUAWEI HRY-AL00Ta",
    "label": "HUAWEI HRY-AL00Ta",
    "value": "HUAWEI HRY-AL00Ta"
  },
  {
    "name": "samsung SM-G930L",
    "label": "samsung SM-G930L",
    "value": "samsung SM-G930L"
  },
  {
    "name": "samsung SM-T560NU",
    "label": "samsung SM-T560NU",
    "value": "samsung SM-T560NU"
  },
  {
    "name": "TCL 5028D_EEA",
    "label": "TCL 5028D_EEA",
    "value": "TCL 5028D_EEA"
  },
  {
    "name": "ADVAN 5505",
    "label": "ADVAN 5505",
    "value": "ADVAN 5505"
  },
  {
    "name": "samsung SM-M022G",
    "label": "samsung SM-M022G",
    "value": "samsung SM-M022G"
  },
  {
    "name": "Apple iPhone 6s",
    "label": "Apple iPhone 6s",
    "value": "Apple iPhone 6s"
  },
  {
    "name": "ZTE ZTE Blade L8RU",
    "label": "ZTE ZTE Blade L8RU",
    "value": "ZTE ZTE Blade L8RU"
  },
  {
    "name": "realme RMX2161",
    "label": "realme RMX2161",
    "value": "realme RMX2161"
  },
  {
    "name": "samsung SM-J720M",
    "label": "samsung SM-J720M",
    "value": "samsung SM-J720M"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6512",
    "label": "INFINIX MOBILITY LIMITED Infinix X6512",
    "value": "INFINIX MOBILITY LIMITED Infinix X6512"
  },
  {
    "name": "Hisense HWCD100E",
    "label": "Hisense HWCD100E",
    "value": "Hisense HWCD100E"
  },
  {
    "name": "samsung SM-G955U1",
    "label": "samsung SM-G955U1",
    "value": "samsung SM-G955U1"
  },
  {
    "name": "Apple Asanda’s iPhone",
    "label": "Apple Asanda’s iPhone",
    "value": "Apple Asanda’s iPhone"
  },
  {
    "name": "samsung SM-G996N",
    "label": "samsung SM-G996N",
    "value": "samsung SM-G996N"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CD8j",
    "label": "TECNO MOBILE LIMITED TECNO CD8j",
    "value": "TECNO MOBILE LIMITED TECNO CD8j"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X680D",
    "label": "INFINIX MOBILITY LIMITED Infinix X680D",
    "value": "INFINIX MOBILITY LIMITED Infinix X680D"
  },
  {
    "name": "OnePlus ONEPLUS A5010",
    "label": "OnePlus ONEPLUS A5010",
    "value": "OnePlus ONEPLUS A5010"
  },
  {
    "name": "Xiaomi Redmi K20",
    "label": "Xiaomi Redmi K20",
    "value": "Xiaomi Redmi K20"
  },
  {
    "name": "samsung SM-G955N",
    "label": "samsung SM-G955N",
    "value": "samsung SM-G955N"
  },
  {
    "name": "realme RMX1919",
    "label": "realme RMX1919",
    "value": "realme RMX1919"
  },
  {
    "name": "LGE LM-G900",
    "label": "LGE LM-G900",
    "value": "LGE LM-G900"
  },
  {
    "name": "Xiaomi 21091116AI",
    "label": "Xiaomi 21091116AI",
    "value": "Xiaomi 21091116AI"
  },
  {
    "name": "Micromax Micromax HS2",
    "label": "Micromax Micromax HS2",
    "value": "Micromax Micromax HS2"
  },
  {
    "name": "Apple iPhone de a0",
    "label": "Apple iPhone de a0",
    "value": "Apple iPhone de a0"
  },
  {
    "name": "samsung SM-J415GN",
    "label": "samsung SM-J415GN",
    "value": "samsung SM-J415GN"
  },
  {
    "name": "iPhone12,1 iPhone",
    "label": "iPhone12,1 iPhone",
    "value": "iPhone12,1 iPhone"
  },
  {
    "name": "CUBOT NOTE 20",
    "label": "CUBOT NOTE 20",
    "value": "CUBOT NOTE 20"
  },
  {
    "name": "Fortune Ship iKU A50",
    "label": "Fortune Ship iKU A50",
    "value": "Fortune Ship iKU A50"
  },
  {
    "name": "HMD Global Nokia 2.1",
    "label": "HMD Global Nokia 2.1",
    "value": "HMD Global Nokia 2.1"
  },
  {
    "name": "OALE db2",
    "label": "OALE db2",
    "value": "OALE db2"
  },
  {
    "name": "asus ASUS_I002D",
    "label": "asus ASUS_I002D",
    "value": "asus ASUS_I002D"
  },
  {
    "name": "LGE LM-G710",
    "label": "LGE LM-G710",
    "value": "LGE LM-G710"
  },
  {
    "name": "vivo V2065",
    "label": "vivo V2065",
    "value": "vivo V2065"
  },
  {
    "name": "TCL 5033E",
    "label": "TCL 5033E",
    "value": "TCL 5033E"
  },
  {
    "name": "itel itel L6005",
    "label": "itel itel L6005",
    "value": "itel itel L6005"
  },
  {
    "name": "HXY RP04",
    "label": "HXY RP04",
    "value": "HXY RP04"
  },
  {
    "name": "Apple Elina’s iPhone",
    "label": "Apple Elina’s iPhone",
    "value": "Apple Elina’s iPhone"
  },
  {
    "name": "ulefone Armor_3",
    "label": "ulefone Armor_3",
    "value": "ulefone Armor_3"
  },
  {
    "name": "Alldocube iPlay_20",
    "label": "Alldocube iPlay_20",
    "value": "Alldocube iPlay_20"
  },
  {
    "name": "LGE LGM-V300L",
    "label": "LGE LGM-V300L",
    "value": "LGE LGM-V300L"
  },
  {
    "name": "Hi Boost Hypa",
    "label": "Hi Boost Hypa",
    "value": "Hi Boost Hypa"
  },
  {
    "name": "HUAWEI JSN-L42",
    "label": "HUAWEI JSN-L42",
    "value": "HUAWEI JSN-L42"
  },
  {
    "name": "samsung SM-A260G",
    "label": "samsung SM-A260G",
    "value": "samsung SM-A260G"
  },
  {
    "name": "LENOVO Lenovo TB-8506X",
    "label": "LENOVO Lenovo TB-8506X",
    "value": "LENOVO Lenovo TB-8506X"
  },
  {
    "name": "realme RMX3430",
    "label": "realme RMX3430",
    "value": "realme RMX3430"
  },
  {
    "name": "itel itel L5002P",
    "label": "itel itel L5002P",
    "value": "itel itel L5002P"
  },
  {
    "name": "Lenovo Lenovo L38012",
    "label": "Lenovo Lenovo L38012",
    "value": "Lenovo Lenovo L38012"
  },
  {
    "name": "samsung SM-A326U",
    "label": "samsung SM-A326U",
    "value": "samsung SM-A326U"
  },
  {
    "name": "LENOVO Lenovo TB-7305F",
    "label": "LENOVO Lenovo TB-7305F",
    "value": "LENOVO Lenovo TB-7305F"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A507LS",
    "label": "ITEL MOBILE LIMITED itel A507LS",
    "value": "ITEL MOBILE LIMITED itel A507LS"
  },
  {
    "name": "samsung SAMSUNG-SM-G930A",
    "label": "samsung SAMSUNG-SM-G930A",
    "value": "samsung SAMSUNG-SM-G930A"
  },
  {
    "name": "TCL T799H",
    "label": "TCL T799H",
    "value": "TCL T799H"
  },
  {
    "name": "HMD Global Nokia 7.1",
    "label": "HMD Global Nokia 7.1",
    "value": "HMD Global Nokia 7.1"
  },
  {
    "name": "HMD Global Nokia 1",
    "label": "HMD Global Nokia 1",
    "value": "HMD Global Nokia 1"
  },
  {
    "name": "Lenovo Lenovo L78071",
    "label": "Lenovo Lenovo L78071",
    "value": "Lenovo Lenovo L78071"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CF7",
    "label": "TECNO MOBILE LIMITED TECNO CF7",
    "value": "TECNO MOBILE LIMITED TECNO CF7"
  },
  {
    "name": "samsung SM-J330FN",
    "label": "samsung SM-J330FN",
    "value": "samsung SM-J330FN"
  },
  {
    "name": "itel itel A571L",
    "label": "itel itel A571L",
    "value": "itel itel A571L"
  },
  {
    "name": "samsung SM-T395",
    "label": "samsung SM-T395",
    "value": "samsung SM-T395"
  },
  {
    "name": "realme RMX1925",
    "label": "realme RMX1925",
    "value": "realme RMX1925"
  },
  {
    "name": "realme RMX3171",
    "label": "realme RMX3171",
    "value": "realme RMX3171"
  },
  {
    "name": "LENOVO Lenovo TB-X606X",
    "label": "LENOVO Lenovo TB-X606X",
    "value": "LENOVO Lenovo TB-X606X"
  },
  {
    "name": "samsung SM-A600F",
    "label": "samsung SM-A600F",
    "value": "samsung SM-A600F"
  },
  {
    "name": "samsung SM-G960U1",
    "label": "samsung SM-G960U1",
    "value": "samsung SM-G960U1"
  },
  {
    "name": "Apple 동욱희정의 iPhone",
    "label": "Apple 동욱희정의 iPhone",
    "value": "Apple 동욱희정의 iPhone"
  },
  {
    "name": "samsung SM-T585",
    "label": "samsung SM-T585",
    "value": "samsung SM-T585"
  },
  {
    "name": "Apple iPad8,9",
    "label": "Apple iPad8,9",
    "value": "Apple iPad8,9"
  },
  {
    "name": "OPPO CPH2341",
    "label": "OPPO CPH2341",
    "value": "OPPO CPH2341"
  },
  {
    "name": "Apple KwanK 6S Plus",
    "label": "Apple KwanK 6S Plus",
    "value": "Apple KwanK 6S Plus"
  },
  {
    "name": "iPad13,10 iPad",
    "label": "iPad13,10 iPad",
    "value": "iPad13,10 iPad"
  },
  {
    "name": "Xiaomi Mi-4c",
    "label": "Xiaomi Mi-4c",
    "value": "Xiaomi Mi-4c"
  },
  {
    "name": "samsung Galaxy J6",
    "label": "samsung Galaxy J6",
    "value": "samsung Galaxy J6"
  },
  {
    "name": "samsung SM-A125U",
    "label": "samsung SM-A125U",
    "value": "samsung SM-A125U"
  },
  {
    "name": "samsung SM-C710F",
    "label": "samsung SM-C710F",
    "value": "samsung SM-C710F"
  },
  {
    "name": "TECLAST P80_EEA",
    "label": "TECLAST P80_EEA",
    "value": "TECLAST P80_EEA"
  },
  {
    "name": "samsung SM-N960U1",
    "label": "samsung SM-N960U1",
    "value": "samsung SM-N960U1"
  },
  {
    "name": "LGE LG-H831",
    "label": "LGE LG-H831",
    "value": "LGE LG-H831"
  },
  {
    "name": "Hisense Hisense E50",
    "label": "Hisense Hisense E50",
    "value": "Hisense Hisense E50"
  },
  {
    "name": "Apple Moonlight🌙",
    "label": "Apple Moonlight🌙",
    "value": "Apple Moonlight🌙"
  },
  {
    "name": "HMD Global Nokia C30",
    "label": "HMD Global Nokia C30",
    "value": "HMD Global Nokia C30"
  },
  {
    "name": "HUAWEI VOG-L09",
    "label": "HUAWEI VOG-L09",
    "value": "HUAWEI VOG-L09"
  },
  {
    "name": "LENOVO Lenovo TB-X505X",
    "label": "LENOVO Lenovo TB-X505X",
    "value": "LENOVO Lenovo TB-X505X"
  },
  {
    "name": "HUAWEI FIG-LX2",
    "label": "HUAWEI FIG-LX2",
    "value": "HUAWEI FIG-LX2"
  },
  {
    "name": "Teclast P20HD_EEA",
    "label": "Teclast P20HD_EEA",
    "value": "Teclast P20HD_EEA"
  },
  {
    "name": "samsung SM-A325N",
    "label": "samsung SM-A325N",
    "value": "samsung SM-A325N"
  },
  {
    "name": "HXY A11",
    "label": "HXY A11",
    "value": "HXY A11"
  },
  {
    "name": "LENOVO Lenovo TB-7104I",
    "label": "LENOVO Lenovo TB-7104I",
    "value": "LENOVO Lenovo TB-7104I"
  },
  {
    "name": "HMD Global TA-1027",
    "label": "HMD Global TA-1027",
    "value": "HMD Global TA-1027"
  },
  {
    "name": "samsung SM-C701F",
    "label": "samsung SM-C701F",
    "value": "samsung SM-C701F"
  },
  {
    "name": "OPPO CPH1979",
    "label": "OPPO CPH1979",
    "value": "OPPO CPH1979"
  },
  {
    "name": "samsung SM-F926U",
    "label": "samsung SM-F926U",
    "value": "samsung SM-F926U"
  },
  {
    "name": "Multilaser M7-3G-PLUS",
    "label": "Multilaser M7-3G-PLUS",
    "value": "Multilaser M7-3G-PLUS"
  },
  {
    "name": "Apple Thor_Redz",
    "label": "Apple Thor_Redz",
    "value": "Apple Thor_Redz"
  },
  {
    "name": "OnePlus GM1901",
    "label": "OnePlus GM1901",
    "value": "OnePlus GM1901"
  },
  {
    "name": "HUAWEI BND-AL10",
    "label": "HUAWEI BND-AL10",
    "value": "HUAWEI BND-AL10"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BD4",
    "label": "TECNO MOBILE LIMITED TECNO BD4",
    "value": "TECNO MOBILE LIMITED TECNO BD4"
  },
  {
    "name": "Apple Nokubonga’s iPhone",
    "label": "Apple Nokubonga’s iPhone",
    "value": "Apple Nokubonga’s iPhone"
  },
  {
    "name": "LENOVO Lenovo K8 Plus",
    "label": "LENOVO Lenovo K8 Plus",
    "value": "LENOVO Lenovo K8 Plus"
  },
  {
    "name": "vivo vivo Y91",
    "label": "vivo vivo Y91",
    "value": "vivo vivo Y91"
  },
  {
    "name": "ZTE P500",
    "label": "ZTE P500",
    "value": "ZTE P500"
  },
  {
    "name": "Apple Siphumelele’s iPhone",
    "label": "Apple Siphumelele’s iPhone",
    "value": "Apple Siphumelele’s iPhone"
  },
  {
    "name": "LGE Nexus 5X",
    "label": "LGE Nexus 5X",
    "value": "LGE Nexus 5X"
  },
  {
    "name": "OPPO CPH2037",
    "label": "OPPO CPH2037",
    "value": "OPPO CPH2037"
  },
  {
    "name": "OPPO CPH2263",
    "label": "OPPO CPH2263",
    "value": "OPPO CPH2263"
  },
  {
    "name": "Shae’s iPad iPad",
    "label": "Shae’s iPad iPad",
    "value": "Shae’s iPad iPad"
  },
  {
    "name": "vivo V2058",
    "label": "vivo V2058",
    "value": "vivo V2058"
  },
  {
    "name": "Apple Unathi Samntha’s iPhone",
    "label": "Apple Unathi Samntha’s iPhone",
    "value": "Apple Unathi Samntha’s iPhone"
  },
  {
    "name": "HMD Global Nokia C1 2nd Edition",
    "label": "HMD Global Nokia C1 2nd Edition",
    "value": "HMD Global Nokia C1 2nd Edition"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6511G",
    "label": "INFINIX MOBILITY LIMITED Infinix X6511G",
    "value": "INFINIX MOBILITY LIMITED Infinix X6511G"
  },
  {
    "name": "Apple Katlego Ratshidi ",
    "label": "Apple Katlego Ratshidi ",
    "value": "Apple Katlego Ratshidi "
  },
  {
    "name": "Teclast P20HD_ROW",
    "label": "Teclast P20HD_ROW",
    "value": "Teclast P20HD_ROW"
  },
  {
    "name": "Apple Lethabo’s iPhone",
    "label": "Apple Lethabo’s iPhone",
    "value": "Apple Lethabo’s iPhone"
  },
  {
    "name": "General Mobile GM 8",
    "label": "General Mobile GM 8",
    "value": "General Mobile GM 8"
  },
  {
    "name": "samsung SM-G9500",
    "label": "samsung SM-G9500",
    "value": "samsung SM-G9500"
  },
  {
    "name": "SHARP SHV43",
    "label": "SHARP SHV43",
    "value": "SHARP SHV43"
  },
  {
    "name": "OnePlus IN2010",
    "label": "OnePlus IN2010",
    "value": "OnePlus IN2010"
  },
  {
    "name": "HUAWEI AUM-AL20",
    "label": "HUAWEI AUM-AL20",
    "value": "HUAWEI AUM-AL20"
  },
  {
    "name": "Apple iPhone 13",
    "label": "Apple iPhone 13",
    "value": "Apple iPhone 13"
  },
  {
    "name": "vivo V2214",
    "label": "vivo V2214",
    "value": "vivo V2214"
  },
  {
    "name": "Apple Busisiwe’s iPhone",
    "label": "Apple Busisiwe’s iPhone",
    "value": "Apple Busisiwe’s iPhone"
  },
  {
    "name": "Apple Yisroel’s iPhone",
    "label": "Apple Yisroel’s iPhone",
    "value": "Apple Yisroel’s iPhone"
  },
  {
    "name": "Apple Funo wa iPhone 12 ",
    "label": "Apple Funo wa iPhone 12 ",
    "value": "Apple Funo wa iPhone 12 "
  },
  {
    "name": "Apple Tshepang’s iPhone",
    "label": "Apple Tshepang’s iPhone",
    "value": "Apple Tshepang’s iPhone"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X690",
    "label": "INFINIX MOBILITY LIMITED Infinix X690",
    "value": "INFINIX MOBILITY LIMITED Infinix X690"
  },
  {
    "name": "TCL 5007U",
    "label": "TCL 5007U",
    "value": "TCL 5007U"
  },
  {
    "name": "Apple Ntebaleng’s iPhone",
    "label": "Apple Ntebaleng’s iPhone",
    "value": "Apple Ntebaleng’s iPhone"
  },
  {
    "name": "Apple iPad14,1",
    "label": "Apple iPad14,1",
    "value": "Apple iPad14,1"
  },
  {
    "name": "Yolanda’s iPad iPad",
    "label": "Yolanda’s iPad iPad",
    "value": "Yolanda’s iPad iPad"
  },
  {
    "name": "TCL 5033J",
    "label": "TCL 5033J",
    "value": "TCL 5033J"
  },
  {
    "name": "Google coral",
    "label": "Google coral",
    "value": "Google coral"
  },
  {
    "name": "HUAWEI CLT-L09",
    "label": "HUAWEI CLT-L09",
    "value": "HUAWEI CLT-L09"
  },
  {
    "name": "vivo V2169",
    "label": "vivo V2169",
    "value": "vivo V2169"
  },
  {
    "name": "TCL 5107G",
    "label": "TCL 5107G",
    "value": "TCL 5107G"
  },
  {
    "name": "Apple Memory’s iPhone",
    "label": "Apple Memory’s iPhone",
    "value": "Apple Memory’s iPhone"
  },
  {
    "name": "HUAWEI ANE-LX2J",
    "label": "HUAWEI ANE-LX2J",
    "value": "HUAWEI ANE-LX2J"
  },
  {
    "name": "samsung SM-G975U",
    "label": "samsung SM-G975U",
    "value": "samsung SM-G975U"
  },
  {
    "name": "Mobicel CLIK",
    "label": "Mobicel CLIK",
    "value": "Mobicel CLIK"
  },
  {
    "name": "Apple Aminul Islam",
    "label": "Apple Aminul Islam",
    "value": "Apple Aminul Islam"
  },
  {
    "name": "Multilaser Multilaser_Elite_2",
    "label": "Multilaser Multilaser_Elite_2",
    "value": "Multilaser Multilaser_Elite_2"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X695",
    "label": "INFINIX MOBILITY LIMITED Infinix X695",
    "value": "INFINIX MOBILITY LIMITED Infinix X695"
  },
  {
    "name": "HUAWEI CLT-AL01",
    "label": "HUAWEI CLT-AL01",
    "value": "HUAWEI CLT-AL01"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BE7",
    "label": "TECNO MOBILE LIMITED TECNO BE7",
    "value": "TECNO MOBILE LIMITED TECNO BE7"
  },
  {
    "name": "HUAWEI WAS-TL10",
    "label": "HUAWEI WAS-TL10",
    "value": "HUAWEI WAS-TL10"
  },
  {
    "name": "Xiaomi 21091116UG",
    "label": "Xiaomi 21091116UG",
    "value": "Xiaomi 21091116UG"
  },
  {
    "name": "samsung SM-A716U",
    "label": "samsung SM-A716U",
    "value": "samsung SM-A716U"
  },
  {
    "name": "samsung SM-A226BR",
    "label": "samsung SM-A226BR",
    "value": "samsung SM-A226BR"
  },
  {
    "name": "HUAWEI JSN-L23",
    "label": "HUAWEI JSN-L23",
    "value": "HUAWEI JSN-L23"
  },
  {
    "name": "Trevor_s iPod iPod touch",
    "label": "Trevor_s iPod iPod touch",
    "value": "Trevor_s iPod iPod touch"
  },
  {
    "name": "Apple Mammoka’s iPhone",
    "label": "Apple Mammoka’s iPhone",
    "value": "Apple Mammoka’s iPhone"
  },
  {
    "name": "Apple Mbali’s iPhone",
    "label": "Apple Mbali’s iPhone",
    "value": "Apple Mbali’s iPhone"
  },
  {
    "name": "asus ASUS_X00DDA",
    "label": "asus ASUS_X00DDA",
    "value": "asus ASUS_X00DDA"
  },
  {
    "name": "Philco Hit Max",
    "label": "Philco Hit Max",
    "value": "Philco Hit Max"
  },
  {
    "name": "Apple Rofhiwa’s iPhone",
    "label": "Apple Rofhiwa’s iPhone",
    "value": "Apple Rofhiwa’s iPhone"
  },
  {
    "name": "samsung SM-A516U",
    "label": "samsung SM-A516U",
    "value": "samsung SM-A516U"
  },
  {
    "name": "Apple Samuke’s iPhone",
    "label": "Apple Samuke’s iPhone",
    "value": "Apple Samuke’s iPhone"
  },
  {
    "name": "LANIX Ilium M9",
    "label": "LANIX Ilium M9",
    "value": "LANIX Ilium M9"
  },
  {
    "name": "motorola moto g play (2021)",
    "label": "motorola moto g play (2021)",
    "value": "motorola moto g play (2021)"
  },
  {
    "name": "samsung SM-S906N",
    "label": "samsung SM-S906N",
    "value": "samsung SM-S906N"
  },
  {
    "name": "samsung SM-J810G",
    "label": "samsung SM-J810G",
    "value": "samsung SM-J810G"
  },
  {
    "name": "OPPO CPH1917",
    "label": "OPPO CPH1917",
    "value": "OPPO CPH1917"
  },
  {
    "name": "samsung SM-G781V",
    "label": "samsung SM-G781V",
    "value": "samsung SM-G781V"
  },
  {
    "name": "OnePlus IN2013",
    "label": "OnePlus IN2013",
    "value": "OnePlus IN2013"
  },
  {
    "name": "itel itel L6501",
    "label": "itel itel L6501",
    "value": "itel itel L6501"
  },
  {
    "name": "UMIDIGI A7 Pro",
    "label": "UMIDIGI A7 Pro",
    "value": "UMIDIGI A7 Pro"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X5516B",
    "label": "INFINIX MOBILITY LIMITED Infinix X5516B",
    "value": "INFINIX MOBILITY LIMITED Infinix X5516B"
  },
  {
    "name": "asus ASUS_Z01QD",
    "label": "asus ASUS_Z01QD",
    "value": "asus ASUS_Z01QD"
  },
  {
    "name": "Apple Fihliwe’s iPhone",
    "label": "Apple Fihliwe’s iPhone",
    "value": "Apple Fihliwe’s iPhone"
  },
  {
    "name": "realme RMX3690",
    "label": "realme RMX3690",
    "value": "realme RMX3690"
  },
  {
    "name": "ZTE ZTE Blade L130",
    "label": "ZTE ZTE Blade L130",
    "value": "ZTE ZTE Blade L130"
  },
  {
    "name": "Premio X86",
    "label": "Premio X86",
    "value": "Premio X86"
  },
  {
    "name": "HUAWEI EBG-AN10",
    "label": "HUAWEI EBG-AN10",
    "value": "HUAWEI EBG-AN10"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6816",
    "label": "INFINIX MOBILITY LIMITED Infinix X6816",
    "value": "INFINIX MOBILITY LIMITED Infinix X6816"
  },
  {
    "name": "HMD Global Nokia 1.3",
    "label": "HMD Global Nokia 1.3",
    "value": "HMD Global Nokia 1.3"
  },
  {
    "name": "Mobicel TRENDY 2",
    "label": "Mobicel TRENDY 2",
    "value": "Mobicel TRENDY 2"
  },
  {
    "name": "LGE LM-Q725K",
    "label": "LGE LM-Q725K",
    "value": "LGE LM-Q725K"
  },
  {
    "name": "Mobile App - Unknown",
    "label": "Mobile App - Unknown",
    "value": "Mobile App - Unknown"
  },
  {
    "name": "samsung SM-G981U",
    "label": "samsung SM-G981U",
    "value": "samsung SM-G981U"
  },
  {
    "name": "TCL T775H",
    "label": "TCL T775H",
    "value": "TCL T775H"
  },
  {
    "name": "Sony 701SO",
    "label": "Sony 701SO",
    "value": "Sony 701SO"
  },
  {
    "name": "Apple Ofir’s iPhone",
    "label": "Apple Ofir’s iPhone",
    "value": "Apple Ofir’s iPhone"
  },
  {
    "name": "OPPO CPH2125",
    "label": "OPPO CPH2125",
    "value": "OPPO CPH2125"
  },
  {
    "name": "HUAWEI BKK-LX2",
    "label": "HUAWEI BKK-LX2",
    "value": "HUAWEI BKK-LX2"
  },
  {
    "name": "along Stratus_C5_Elite",
    "label": "along Stratus_C5_Elite",
    "value": "along Stratus_C5_Elite"
  },
  {
    "name": "vivo V2108",
    "label": "vivo V2108",
    "value": "vivo V2108"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BD2",
    "label": "TECNO MOBILE LIMITED TECNO BD2",
    "value": "TECNO MOBILE LIMITED TECNO BD2"
  },
  {
    "name": "OPPO CPH2357",
    "label": "OPPO CPH2357",
    "value": "OPPO CPH2357"
  },
  {
    "name": "ZUUM GRAVITY M",
    "label": "ZUUM GRAVITY M",
    "value": "ZUUM GRAVITY M"
  },
  {
    "name": "samsung SM-A205W",
    "label": "samsung SM-A205W",
    "value": "samsung SM-A205W"
  },
  {
    "name": "Xiaomi M2012K11AC",
    "label": "Xiaomi M2012K11AC",
    "value": "Xiaomi M2012K11AC"
  },
  {
    "name": "vivo V2042",
    "label": "vivo V2042",
    "value": "vivo V2042"
  },
  {
    "name": "vivo V2101",
    "label": "vivo V2101",
    "value": "vivo V2101"
  },
  {
    "name": "samsung SM-A7050",
    "label": "samsung SM-A7050",
    "value": "samsung SM-A7050"
  },
  {
    "name": "samsung SM-G781U1",
    "label": "samsung SM-G781U1",
    "value": "samsung SM-G781U1"
  },
  {
    "name": "samsung SM-A042F",
    "label": "samsung SM-A042F",
    "value": "samsung SM-A042F"
  },
  {
    "name": "TCL T671H",
    "label": "TCL T671H",
    "value": "TCL T671H"
  },
  {
    "name": "HMD Global Nokia X100",
    "label": "HMD Global Nokia X100",
    "value": "HMD Global Nokia X100"
  },
  {
    "name": "TCL 5024A",
    "label": "TCL 5024A",
    "value": "TCL 5024A"
  },
  {
    "name": "HMD Global Nokia G11",
    "label": "HMD Global Nokia G11",
    "value": "HMD Global Nokia G11"
  },
  {
    "name": "HONOR ANY-LX3",
    "label": "HONOR ANY-LX3",
    "value": "HONOR ANY-LX3"
  },
  {
    "name": "samsung SM-S906B",
    "label": "samsung SM-S906B",
    "value": "samsung SM-S906B"
  },
  {
    "name": "samsung SM-A205YN",
    "label": "samsung SM-A205YN",
    "value": "samsung SM-A205YN"
  },
  {
    "name": "motorola moto e5 cruise",
    "label": "motorola moto e5 cruise",
    "value": "motorola moto e5 cruise"
  },
  {
    "name": "samsung SM-A536U",
    "label": "samsung SM-A536U",
    "value": "samsung SM-A536U"
  },
  {
    "name": "samsung SM-A136U",
    "label": "samsung SM-A136U",
    "value": "samsung SM-A136U"
  },
  {
    "name": "samsung SM-A235N",
    "label": "samsung SM-A235N",
    "value": "samsung SM-A235N"
  },
  {
    "name": "realme RMX2042",
    "label": "realme RMX2042",
    "value": "realme RMX2042"
  },
  {
    "name": "HONOR VNE-LX3",
    "label": "HONOR VNE-LX3",
    "value": "HONOR VNE-LX3"
  },
  {
    "name": "OnePlus CPH2409",
    "label": "OnePlus CPH2409",
    "value": "OnePlus CPH2409"
  },
  {
    "name": "OPPO CPH2173",
    "label": "OPPO CPH2173",
    "value": "OPPO CPH2173"
  },
  {
    "name": "samsung SM-A135U",
    "label": "samsung SM-A135U",
    "value": "samsung SM-A135U"
  },
  {
    "name": "Sony XQ-BQ72",
    "label": "Sony XQ-BQ72",
    "value": "Sony XQ-BQ72"
  },
  {
    "name": "LENOVO Lenovo TB-J616F",
    "label": "LENOVO Lenovo TB-J616F",
    "value": "LENOVO Lenovo TB-J616F"
  },
  {
    "name": "samsung SM-T507",
    "label": "samsung SM-T507",
    "value": "samsung SM-T507"
  },
  {
    "name": "Xiaomi Mi MIX 2S",
    "label": "Xiaomi Mi MIX 2S",
    "value": "Xiaomi Mi MIX 2S"
  },
  {
    "name": "samsung SM-A716S",
    "label": "samsung SM-A716S",
    "value": "samsung SM-A716S"
  },
  {
    "name": "HONOR NTN-L22",
    "label": "HONOR NTN-L22",
    "value": "HONOR NTN-L22"
  },
  {
    "name": "TCL 6025D",
    "label": "TCL 6025D",
    "value": "TCL 6025D"
  },
  {
    "name": "vsmart Live",
    "label": "vsmart Live",
    "value": "vsmart Live"
  },
  {
    "name": "samsung SM-A426N",
    "label": "samsung SM-A426N",
    "value": "samsung SM-A426N"
  },
  {
    "name": "BLU B130DL",
    "label": "BLU B130DL",
    "value": "BLU B130DL"
  },
  {
    "name": "10or G",
    "label": "10or G",
    "value": "10or G"
  },
  {
    "name": "samsung SM-T970",
    "label": "samsung SM-T970",
    "value": "samsung SM-T970"
  },
  {
    "name": "samsung SM-G975U1",
    "label": "samsung SM-G975U1",
    "value": "samsung SM-G975U1"
  },
  {
    "name": "motorola moto g62 5G",
    "label": "motorola moto g62 5G",
    "value": "motorola moto g62 5G"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KC6",
    "label": "TECNO MOBILE LIMITED TECNO KC6",
    "value": "TECNO MOBILE LIMITED TECNO KC6"
  },
  {
    "name": "sky Elite R55",
    "label": "sky Elite R55",
    "value": "sky Elite R55"
  },
  {
    "name": "samsung SM-A037U1",
    "label": "samsung SM-A037U1",
    "value": "samsung SM-A037U1"
  },
  {
    "name": "OPPO CPH2009",
    "label": "OPPO CPH2009",
    "value": "OPPO CPH2009"
  },
  {
    "name": "FISE A70",
    "label": "FISE A70",
    "value": "FISE A70"
  },
  {
    "name": "IPRO S401A",
    "label": "IPRO S401A",
    "value": "IPRO S401A"
  },
  {
    "name": "samsung SM-N970U1",
    "label": "samsung SM-N970U1",
    "value": "samsung SM-N970U1"
  },
  {
    "name": "LGE LM-Q710.FGN",
    "label": "LGE LM-Q710.FGN",
    "value": "LGE LM-Q710.FGN"
  },
  {
    "name": "INFINIX Infinix X6817",
    "label": "INFINIX Infinix X6817",
    "value": "INFINIX Infinix X6817"
  },
  {
    "name": "TCL T501C",
    "label": "TCL T501C",
    "value": "TCL T501C"
  },
  {
    "name": "ITEL itel A631W",
    "label": "ITEL itel A631W",
    "value": "ITEL itel A631W"
  },
  {
    "name": "Vestel Venus V6",
    "label": "Vestel Venus V6",
    "value": "Vestel Venus V6"
  },
  {
    "name": "samsung SM-G990U1",
    "label": "samsung SM-G990U1",
    "value": "samsung SM-G990U1"
  },
  {
    "name": "Xiaomi M2011K2G",
    "label": "Xiaomi M2011K2G",
    "value": "Xiaomi M2011K2G"
  },
  {
    "name": "LGE LM-X210(G)",
    "label": "LGE LM-X210(G)",
    "value": "LGE LM-X210(G)"
  },
  {
    "name": "samsung SM-T865",
    "label": "samsung SM-T865",
    "value": "samsung SM-T865"
  },
  {
    "name": "GIONEE P15 Pro",
    "label": "GIONEE P15 Pro",
    "value": "GIONEE P15 Pro"
  },
  {
    "name": "samsung SM-J810F",
    "label": "samsung SM-J810F",
    "value": "samsung SM-J810F"
  },
  {
    "name": "BLU C5L Max",
    "label": "BLU C5L Max",
    "value": "BLU C5L Max"
  },
  {
    "name": "samsung SM-M135M",
    "label": "samsung SM-M135M",
    "value": "samsung SM-M135M"
  },
  {
    "name": "samsung SM-A908N",
    "label": "samsung SM-A908N",
    "value": "samsung SM-A908N"
  },
  {
    "name": "TCL A509DL",
    "label": "TCL A509DL",
    "value": "TCL A509DL"
  },
  {
    "name": "Xiaomi Redmi 8A Pro",
    "label": "Xiaomi Redmi 8A Pro",
    "value": "Xiaomi Redmi 8A Pro"
  },
  {
    "name": "Apple iPad8,6",
    "label": "Apple iPad8,6",
    "value": "Apple iPad8,6"
  },
  {
    "name": "Xiaomi MIX",
    "label": "Xiaomi MIX",
    "value": "Xiaomi MIX"
  },
  {
    "name": "samsung SM-T505N",
    "label": "samsung SM-T505N",
    "value": "samsung SM-T505N"
  },
  {
    "name": "realme RMX2111",
    "label": "realme RMX2111",
    "value": "realme RMX2111"
  },
  {
    "name": "OpelMobile OpelMobile_SmartX1",
    "label": "OpelMobile OpelMobile_SmartX1",
    "value": "OpelMobile OpelMobile_SmartX1"
  },
  {
    "name": "hena SC777",
    "label": "hena SC777",
    "value": "hena SC777"
  },
  {
    "name": "INFINIX Infinix X665E",
    "label": "INFINIX Infinix X665E",
    "value": "INFINIX Infinix X665E"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X625B",
    "label": "INFINIX MOBILITY LIMITED Infinix X625B",
    "value": "INFINIX MOBILITY LIMITED Infinix X625B"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6816C",
    "label": "INFINIX MOBILITY LIMITED Infinix X6816C",
    "value": "INFINIX MOBILITY LIMITED Infinix X6816C"
  },
  {
    "name": "OPPO CPH2213",
    "label": "OPPO CPH2213",
    "value": "OPPO CPH2213"
  },
  {
    "name": "Qubo X626",
    "label": "Qubo X626",
    "value": "Qubo X626"
  },
  {
    "name": "HMD Global TA-1004",
    "label": "HMD Global TA-1004",
    "value": "HMD Global TA-1004"
  },
  {
    "name": "BLU B131DL",
    "label": "BLU B131DL",
    "value": "BLU B131DL"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BC2",
    "label": "TECNO MOBILE LIMITED TECNO BC2",
    "value": "TECNO MOBILE LIMITED TECNO BC2"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6511",
    "label": "INFINIX MOBILITY LIMITED Infinix X6511",
    "value": "INFINIX MOBILITY LIMITED Infinix X6511"
  },
  {
    "name": "Hisense HLTE223E",
    "label": "Hisense HLTE223E",
    "value": "Hisense HLTE223E"
  },
  {
    "name": "nubia NX659J",
    "label": "nubia NX659J",
    "value": "nubia NX659J"
  },
  {
    "name": "TCL 5024D_EEA",
    "label": "TCL 5024D_EEA",
    "value": "TCL 5024D_EEA"
  },
  {
    "name": "Xiaomi Mi 10",
    "label": "Xiaomi Mi 10",
    "value": "Xiaomi Mi 10"
  },
  {
    "name": "realme RMX1931",
    "label": "realme RMX1931",
    "value": "realme RMX1931"
  },
  {
    "name": "samsung SM-A526U1",
    "label": "samsung SM-A526U1",
    "value": "samsung SM-A526U1"
  },
  {
    "name": "LGE LM-V405",
    "label": "LGE LM-V405",
    "value": "LGE LM-V405"
  },
  {
    "name": "sky Elite J55",
    "label": "sky Elite J55",
    "value": "sky Elite J55"
  },
  {
    "name": "Blackview A100",
    "label": "Blackview A100",
    "value": "Blackview A100"
  },
  {
    "name": "HUAWEI YAL-L41",
    "label": "HUAWEI YAL-L41",
    "value": "HUAWEI YAL-L41"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X690B",
    "label": "INFINIX MOBILITY LIMITED Infinix X690B",
    "value": "INFINIX MOBILITY LIMITED Infinix X690B"
  },
  {
    "name": "Xiaomi 22041219G",
    "label": "Xiaomi 22041219G",
    "value": "Xiaomi 22041219G"
  },
  {
    "name": "vivo I2011",
    "label": "vivo I2011",
    "value": "vivo I2011"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X697",
    "label": "INFINIX MOBILITY LIMITED Infinix X697",
    "value": "INFINIX MOBILITY LIMITED Infinix X697"
  },
  {
    "name": "HONOR TFY-LX3",
    "label": "HONOR TFY-LX3",
    "value": "HONOR TFY-LX3"
  },
  {
    "name": "BLU Studio X10",
    "label": "BLU Studio X10",
    "value": "BLU Studio X10"
  },
  {
    "name": "realme RMX3501",
    "label": "realme RMX3501",
    "value": "realme RMX3501"
  },
  {
    "name": "Meizu meizu C9",
    "label": "Meizu meizu C9",
    "value": "Meizu meizu C9"
  },
  {
    "name": "HONOR TFY-LX2",
    "label": "HONOR TFY-LX2",
    "value": "HONOR TFY-LX2"
  },
  {
    "name": "ZTE ZTE A2020N3 Pro",
    "label": "ZTE ZTE A2020N3 Pro",
    "value": "ZTE ZTE A2020N3 Pro"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X626B LTE",
    "label": "INFINIX MOBILITY LIMITED Infinix X626B LTE",
    "value": "INFINIX MOBILITY LIMITED Infinix X626B LTE"
  },
  {
    "name": "HUAWEI EVA-DL00",
    "label": "HUAWEI EVA-DL00",
    "value": "HUAWEI EVA-DL00"
  },
  {
    "name": "samsung SM-A336B",
    "label": "samsung SM-A336B",
    "value": "samsung SM-A336B"
  },
  {
    "name": "LGE LM-G900TM",
    "label": "LGE LM-G900TM",
    "value": "LGE LM-G900TM"
  },
  {
    "name": "samsung SM-A600N",
    "label": "samsung SM-A600N",
    "value": "samsung SM-A600N"
  },
  {
    "name": "TCL 5029F",
    "label": "TCL 5029F",
    "value": "TCL 5029F"
  },
  {
    "name": "vivo V2127",
    "label": "vivo V2127",
    "value": "vivo V2127"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KF6i",
    "label": "TECNO MOBILE LIMITED TECNO KF6i",
    "value": "TECNO MOBILE LIMITED TECNO KF6i"
  },
  {
    "name": "TCL 5024J",
    "label": "TCL 5024J",
    "value": "TCL 5024J"
  },
  {
    "name": "Mobicel HYPE",
    "label": "Mobicel HYPE",
    "value": "Mobicel HYPE"
  },
  {
    "name": "TCL T774H",
    "label": "TCL T774H",
    "value": "TCL T774H"
  },
  {
    "name": "Google HP Chromebook x360 11 G1 EE",
    "label": "Google HP Chromebook x360 11 G1 EE",
    "value": "Google HP Chromebook x360 11 G1 EE"
  },
  {
    "name": "LGE LM-V500N",
    "label": "LGE LM-V500N",
    "value": "LGE LM-V500N"
  },
  {
    "name": "Apple iPhone14,8",
    "label": "Apple iPhone14,8",
    "value": "Apple iPhone14,8"
  },
  {
    "name": "samsung SM-A526U",
    "label": "samsung SM-A526U",
    "value": "samsung SM-A526U"
  },
  {
    "name": "samsung SM-T227U",
    "label": "samsung SM-T227U",
    "value": "samsung SM-T227U"
  },
  {
    "name": "Xiaomi 22011119TI",
    "label": "Xiaomi 22011119TI",
    "value": "Xiaomi 22011119TI"
  },
  {
    "name": "HUAWEI JEF-NX9",
    "label": "HUAWEI JEF-NX9",
    "value": "HUAWEI JEF-NX9"
  },
  {
    "name": "Xiaomi M2007J3SP",
    "label": "Xiaomi M2007J3SP",
    "value": "Xiaomi M2007J3SP"
  },
  {
    "name": "Quantum Quantum You 2",
    "label": "Quantum Quantum You 2",
    "value": "Quantum Quantum You 2"
  },
  {
    "name": "Multilaser M7sLite",
    "label": "Multilaser M7sLite",
    "value": "Multilaser M7sLite"
  },
  {
    "name": "samsung SM-C9000",
    "label": "samsung SM-C9000",
    "value": "samsung SM-C9000"
  },
  {
    "name": "realme RMX2022",
    "label": "realme RMX2022",
    "value": "realme RMX2022"
  },
  {
    "name": "samsung SM-G986U",
    "label": "samsung SM-G986U",
    "value": "samsung SM-G986U"
  },
  {
    "name": "Xiaomi 22041216I",
    "label": "Xiaomi 22041216I",
    "value": "Xiaomi 22041216I"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X675",
    "label": "INFINIX MOBILITY LIMITED Infinix X675",
    "value": "INFINIX MOBILITY LIMITED Infinix X675"
  },
  {
    "name": "Q-Innovations QS5509A",
    "label": "Q-Innovations QS5509A",
    "value": "Q-Innovations QS5509A"
  },
  {
    "name": "samsung SM-G935T",
    "label": "samsung SM-G935T",
    "value": "samsung SM-G935T"
  },
  {
    "name": "LGE LGL322DL",
    "label": "LGE LGL322DL",
    "value": "LGE LGL322DL"
  },
  {
    "name": "TCL T766A",
    "label": "TCL T766A",
    "value": "TCL T766A"
  },
  {
    "name": "BLU B140DL",
    "label": "BLU B140DL",
    "value": "BLU B140DL"
  },
  {
    "name": "TCL 5087Z",
    "label": "TCL 5087Z",
    "value": "TCL 5087Z"
  },
  {
    "name": "samsung SM-F916U1",
    "label": "samsung SM-F916U1",
    "value": "samsung SM-F916U1"
  },
  {
    "name": "vivo vivo 1933",
    "label": "vivo vivo 1933",
    "value": "vivo vivo 1933"
  },
  {
    "name": "motorola moto e5 supra",
    "label": "motorola moto e5 supra",
    "value": "motorola moto e5 supra"
  },
  {
    "name": "Samsung GT-I9505",
    "label": "Samsung GT-I9505",
    "value": "Samsung GT-I9505"
  },
  {
    "name": "samsung SM-A115U1",
    "label": "samsung SM-A115U1",
    "value": "samsung SM-A115U1"
  },
  {
    "name": "Sony H9493",
    "label": "Sony H9493",
    "value": "Sony H9493"
  },
  {
    "name": "samsung SM-S102DL",
    "label": "samsung SM-S102DL",
    "value": "samsung SM-S102DL"
  },
  {
    "name": "OPPO CPH2285",
    "label": "OPPO CPH2285",
    "value": "OPPO CPH2285"
  },
  {
    "name": "Xiaomi Mi 9 SE",
    "label": "Xiaomi Mi 9 SE",
    "value": "Xiaomi Mi 9 SE"
  },
  {
    "name": "vivo V2066",
    "label": "vivo V2066",
    "value": "vivo V2066"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KG6p",
    "label": "TECNO MOBILE LIMITED TECNO KG6p",
    "value": "TECNO MOBILE LIMITED TECNO KG6p"
  },
  {
    "name": "samsung SM-G998U1",
    "label": "samsung SM-G998U1",
    "value": "samsung SM-G998U1"
  },
  {
    "name": "samsung SM-S767VL",
    "label": "samsung SM-S767VL",
    "value": "samsung SM-S767VL"
  },
  {
    "name": "samsung SM-A3360",
    "label": "samsung SM-A3360",
    "value": "samsung SM-A3360"
  },
  {
    "name": "OPPO CPH1725",
    "label": "OPPO CPH1725",
    "value": "OPPO CPH1725"
  },
  {
    "name": "samsung SM-N9860",
    "label": "samsung SM-N9860",
    "value": "samsung SM-N9860"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X665",
    "label": "INFINIX MOBILITY LIMITED Infinix X665",
    "value": "INFINIX MOBILITY LIMITED Infinix X665"
  },
  {
    "name": "Hisense Hisense V40",
    "label": "Hisense Hisense V40",
    "value": "Hisense Hisense V40"
  },
  {
    "name": "HUAWEI ELE-L04",
    "label": "HUAWEI ELE-L04",
    "value": "HUAWEI ELE-L04"
  },
  {
    "name": "TCL 5033XR",
    "label": "TCL 5033XR",
    "value": "TCL 5033XR"
  },
  {
    "name": "Apple iPad6,8",
    "label": "Apple iPad6,8",
    "value": "Apple iPad6,8"
  },
  {
    "name": "INONE AE9150",
    "label": "INONE AE9150",
    "value": "INONE AE9150"
  },
  {
    "name": "HXY MP01",
    "label": "HXY MP01",
    "value": "HXY MP01"
  },
  {
    "name": "Positivo Twist 2",
    "label": "Positivo Twist 2",
    "value": "Positivo Twist 2"
  },
  {
    "name": "realme RMX3572",
    "label": "realme RMX3572",
    "value": "realme RMX3572"
  },
  {
    "name": "Schok Schok Volt SV55",
    "label": "Schok Schok Volt SV55",
    "value": "Schok Schok Volt SV55"
  },
  {
    "name": "samsung SM-N985F",
    "label": "samsung SM-N985F",
    "value": "samsung SM-N985F"
  },
  {
    "name": "vivo I2127",
    "label": "vivo I2127",
    "value": "vivo I2127"
  },
  {
    "name": "CKT i9000S",
    "label": "CKT i9000S",
    "value": "CKT i9000S"
  },
  {
    "name": "samsung SM-G715U1",
    "label": "samsung SM-G715U1",
    "value": "samsung SM-G715U1"
  },
  {
    "name": "samsung SM-S908U",
    "label": "samsung SM-S908U",
    "value": "samsung SM-S908U"
  },
  {
    "name": "vivo V2204",
    "label": "vivo V2204",
    "value": "vivo V2204"
  },
  {
    "name": "samsung SM-A047F",
    "label": "samsung SM-A047F",
    "value": "samsung SM-A047F"
  },
  {
    "name": "ZTE N9560",
    "label": "ZTE N9560",
    "value": "ZTE N9560"
  },
  {
    "name": "Qlink Scepter 8 Tablet",
    "label": "Qlink Scepter 8 Tablet",
    "value": "Qlink Scepter 8 Tablet"
  },
  {
    "name": "TINNO U318AA",
    "label": "TINNO U318AA",
    "value": "TINNO U318AA"
  },
  {
    "name": "samsung SM-F721B",
    "label": "samsung SM-F721B",
    "value": "samsung SM-F721B"
  },
  {
    "name": "samsung SM-J327U",
    "label": "samsung SM-J327U",
    "value": "samsung SM-J327U"
  },
  {
    "name": "BLU C6L",
    "label": "BLU C6L",
    "value": "BLU C6L"
  },
  {
    "name": "HUAWEI JSN-L21",
    "label": "HUAWEI JSN-L21",
    "value": "HUAWEI JSN-L21"
  },
  {
    "name": "SHARP SHV34",
    "label": "SHARP SHV34",
    "value": "SHARP SHV34"
  },
  {
    "name": "itel itel P651W",
    "label": "itel itel P651W",
    "value": "itel itel P651W"
  },
  {
    "name": "OnePlus CPH2419",
    "label": "OnePlus CPH2419",
    "value": "OnePlus CPH2419"
  },
  {
    "name": "TCL 5148A",
    "label": "TCL 5148A",
    "value": "TCL 5148A"
  },
  {
    "name": "asus ASUS_AI2201_A",
    "label": "asus ASUS_AI2201_A",
    "value": "asus ASUS_AI2201_A"
  },
  {
    "name": "TCL 5030J",
    "label": "TCL 5030J",
    "value": "TCL 5030J"
  },
  {
    "name": "samsung SM-N970U",
    "label": "samsung SM-N970U",
    "value": "samsung SM-N970U"
  },
  {
    "name": "WIKO W-K130-TMV",
    "label": "WIKO W-K130-TMV",
    "value": "WIKO W-K130-TMV"
  },
  {
    "name": "samsung SM-G570Y",
    "label": "samsung SM-G570Y",
    "value": "samsung SM-G570Y"
  },
  {
    "name": "samsung SM-J730F",
    "label": "samsung SM-J730F",
    "value": "samsung SM-J730F"
  },
  {
    "name": "realme RMX3563",
    "label": "realme RMX3563",
    "value": "realme RMX3563"
  },
  {
    "name": "vivo VIVO 1907",
    "label": "vivo VIVO 1907",
    "value": "vivo VIVO 1907"
  },
  {
    "name": "LENOVO Lenovo TB-X6C6F",
    "label": "LENOVO Lenovo TB-X6C6F",
    "value": "LENOVO Lenovo TB-X6C6F"
  },
  {
    "name": "lenovo Lenovo K8 Note",
    "label": "lenovo Lenovo K8 Note",
    "value": "lenovo Lenovo K8 Note"
  },
  {
    "name": "Wingtech TMAF035G",
    "label": "Wingtech TMAF035G",
    "value": "Wingtech TMAF035G"
  },
  {
    "name": "alps S22 Ultra",
    "label": "alps S22 Ultra",
    "value": "alps S22 Ultra"
  },
  {
    "name": "samsung SM-S134DL",
    "label": "samsung SM-S134DL",
    "value": "samsung SM-S134DL"
  },
  {
    "name": "ITEL MOBILE LIMITED itel L6004L",
    "label": "ITEL MOBILE LIMITED itel L6004L",
    "value": "ITEL MOBILE LIMITED itel L6004L"
  },
  {
    "name": "Amazon KFONWI",
    "label": "Amazon KFONWI",
    "value": "Amazon KFONWI"
  },
  {
    "name": "vivo V2139",
    "label": "vivo V2139",
    "value": "vivo V2139"
  },
  {
    "name": "LGE LM-F100",
    "label": "LGE LM-F100",
    "value": "LGE LM-F100"
  },
  {
    "name": "onn 100071481",
    "label": "onn 100071481",
    "value": "onn 100071481"
  },
  {
    "name": "samsung SM-G991U1",
    "label": "samsung SM-G991U1",
    "value": "samsung SM-G991U1"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X623",
    "label": "INFINIX MOBILITY LIMITED Infinix X623",
    "value": "INFINIX MOBILITY LIMITED Infinix X623"
  },
  {
    "name": "motorola moto e32(s)",
    "label": "motorola moto e32(s)",
    "value": "motorola moto e32(s)"
  },
  {
    "name": "TCL T767W",
    "label": "TCL T767W",
    "value": "TCL T767W"
  },
  {
    "name": "Apple iPad14,3",
    "label": "Apple iPad14,3",
    "value": "Apple iPad14,3"
  },
  {
    "name": "JOYAR 100011886",
    "label": "JOYAR 100011886",
    "value": "JOYAR 100011886"
  },
  {
    "name": "TINNO U325AC",
    "label": "TINNO U325AC",
    "value": "TINNO U325AC"
  },
  {
    "name": "samsung SM-G965N",
    "label": "samsung SM-G965N",
    "value": "samsung SM-G965N"
  },
  {
    "name": "Chinoe P13 Blue Max Pro 256 GB",
    "label": "Chinoe P13 Blue Max Pro 256 GB",
    "value": "Chinoe P13 Blue Max Pro 256 GB"
  },
  {
    "name": "TECNO TECNO KH6",
    "label": "TECNO TECNO KH6",
    "value": "TECNO TECNO KH6"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KF6j",
    "label": "TECNO MOBILE LIMITED TECNO KF6j",
    "value": "TECNO MOBILE LIMITED TECNO KF6j"
  },
  {
    "name": "TCL 6062W",
    "label": "TCL 6062W",
    "value": "TCL 6062W"
  },
  {
    "name": "SkyDevices Elite V55",
    "label": "SkyDevices Elite V55",
    "value": "SkyDevices Elite V55"
  },
  {
    "name": "HUAWEI PRA-LX1",
    "label": "HUAWEI PRA-LX1",
    "value": "HUAWEI PRA-LX1"
  },
  {
    "name": "samsung SM-S205DL",
    "label": "samsung SM-S205DL",
    "value": "samsung SM-S205DL"
  },
  {
    "name": "Sony SOV38",
    "label": "Sony SOV38",
    "value": "Sony SOV38"
  },
  {
    "name": "motorola moto g 5G (2022)",
    "label": "motorola moto g 5G (2022)",
    "value": "motorola moto g 5G (2022)"
  },
  {
    "name": "DOOGEE S97Pro",
    "label": "DOOGEE S97Pro",
    "value": "DOOGEE S97Pro"
  },
  {
    "name": "EPIKONE X620",
    "label": "EPIKONE X620",
    "value": "EPIKONE X620"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KG7h",
    "label": "TECNO MOBILE LIMITED TECNO KG7h",
    "value": "TECNO MOBILE LIMITED TECNO KG7h"
  },
  {
    "name": "TCL 5029Y_EEA",
    "label": "TCL 5029Y_EEA",
    "value": "TCL 5029Y_EEA"
  },
  {
    "name": "Lanix Ilium M7s",
    "label": "Lanix Ilium M7s",
    "value": "Lanix Ilium M7s"
  },
  {
    "name": "samsung SM-A115AZ",
    "label": "samsung SM-A115AZ",
    "value": "samsung SM-A115AZ"
  },
  {
    "name": "OnePlus IN2025",
    "label": "OnePlus IN2025",
    "value": "OnePlus IN2025"
  },
  {
    "name": "reeder P13_Blue",
    "label": "reeder P13_Blue",
    "value": "reeder P13_Blue"
  },
  {
    "name": "samsung SM-A4260",
    "label": "samsung SM-A4260",
    "value": "samsung SM-A4260"
  },
  {
    "name": "OPPO CPH2035",
    "label": "OPPO CPH2035",
    "value": "OPPO CPH2035"
  },
  {
    "name": "along SP-001",
    "label": "along SP-001",
    "value": "along SP-001"
  },
  {
    "name": "samsung SM-A536U1",
    "label": "samsung SM-A536U1",
    "value": "samsung SM-A536U1"
  },
  {
    "name": "ZTE ZTE Blade A3 Lite",
    "label": "ZTE ZTE Blade A3 Lite",
    "value": "ZTE ZTE Blade A3 Lite"
  },
  {
    "name": "TCL 4188C",
    "label": "TCL 4188C",
    "value": "TCL 4188C"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CD6",
    "label": "TECNO MOBILE LIMITED TECNO CD6",
    "value": "TECNO MOBILE LIMITED TECNO CD6"
  },
  {
    "name": "samsung SM-S111DL",
    "label": "samsung SM-S111DL",
    "value": "samsung SM-S111DL"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CH6n",
    "label": "TECNO MOBILE LIMITED TECNO CH6n",
    "value": "TECNO MOBILE LIMITED TECNO CH6n"
  },
  {
    "name": "OPPO CPH2137",
    "label": "OPPO CPH2137",
    "value": "OPPO CPH2137"
  },
  {
    "name": "ZTE ZTE Blade A51",
    "label": "ZTE ZTE Blade A51",
    "value": "ZTE ZTE Blade A51"
  },
  {
    "name": "Sky_Devices Elite OctaMax",
    "label": "Sky_Devices Elite OctaMax",
    "value": "Sky_Devices Elite OctaMax"
  },
  {
    "name": "Google grunt",
    "label": "Google grunt",
    "value": "Google grunt"
  },
  {
    "name": "BLU G90",
    "label": "BLU G90",
    "value": "BLU G90"
  },
  {
    "name": "TCL Alcatel_5002R",
    "label": "TCL Alcatel_5002R",
    "value": "TCL Alcatel_5002R"
  },
  {
    "name": "OPPO CPH2207",
    "label": "OPPO CPH2207",
    "value": "OPPO CPH2207"
  },
  {
    "name": "samsung SM-F721N",
    "label": "samsung SM-F721N",
    "value": "samsung SM-F721N"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A23S",
    "label": "ITEL MOBILE LIMITED itel A23S",
    "value": "ITEL MOBILE LIMITED itel A23S"
  },
  {
    "name": "Xiaomi 220333QL",
    "label": "Xiaomi 220333QL",
    "value": "Xiaomi 220333QL"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X609",
    "label": "INFINIX MOBILITY LIMITED Infinix X609",
    "value": "INFINIX MOBILITY LIMITED Infinix X609"
  },
  {
    "name": "samsung SM-G390F",
    "label": "samsung SM-G390F",
    "value": "samsung SM-G390F"
  },
  {
    "name": "Xiaomi M2012K11G",
    "label": "Xiaomi M2012K11G",
    "value": "Xiaomi M2012K11G"
  },
  {
    "name": "HUAWEI CAG-L02",
    "label": "HUAWEI CAG-L02",
    "value": "HUAWEI CAG-L02"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BA2",
    "label": "TECNO MOBILE LIMITED TECNO BA2",
    "value": "TECNO MOBILE LIMITED TECNO BA2"
  },
  {
    "name": "HXY MP02",
    "label": "HXY MP02",
    "value": "HXY MP02"
  },
  {
    "name": "Alcatel 5041C",
    "label": "Alcatel 5041C",
    "value": "Alcatel 5041C"
  },
  {
    "name": "samsung SM-J727T",
    "label": "samsung SM-J727T",
    "value": "samsung SM-J727T"
  },
  {
    "name": "Wingtech REVVL V+ 5G",
    "label": "Wingtech REVVL V+ 5G",
    "value": "Wingtech REVVL V+ 5G"
  },
  {
    "name": "lenovo Lenovo K12 Note",
    "label": "lenovo Lenovo K12 Note",
    "value": "lenovo Lenovo K12 Note"
  },
  {
    "name": "LGE LM-X320",
    "label": "LGE LM-X320",
    "value": "LGE LM-X320"
  },
  {
    "name": "HUAWEI ANE",
    "label": "HUAWEI ANE",
    "value": "HUAWEI ANE"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KG5k",
    "label": "TECNO MOBILE LIMITED TECNO KG5k",
    "value": "TECNO MOBILE LIMITED TECNO KG5k"
  },
  {
    "name": "KYOCERA E6810",
    "label": "KYOCERA E6810",
    "value": "KYOCERA E6810"
  },
  {
    "name": "LGE LM-Q720",
    "label": "LGE LM-Q720",
    "value": "LGE LM-Q720"
  },
  {
    "name": "BLU C6 2019",
    "label": "BLU C6 2019",
    "value": "BLU C6 2019"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X689D",
    "label": "INFINIX MOBILITY LIMITED Infinix X689D",
    "value": "INFINIX MOBILITY LIMITED Infinix X689D"
  },
  {
    "name": "Wingtech TMRVL4G",
    "label": "Wingtech TMRVL4G",
    "value": "Wingtech TMRVL4G"
  },
  {
    "name": "TCL 5033Q",
    "label": "TCL 5033Q",
    "value": "TCL 5033Q"
  },
  {
    "name": "TECNO TECNO CI6",
    "label": "TECNO TECNO CI6",
    "value": "TECNO TECNO CI6"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X653",
    "label": "INFINIX MOBILITY LIMITED Infinix X653",
    "value": "INFINIX MOBILITY LIMITED Infinix X653"
  },
  {
    "name": "OnePlus BE2015",
    "label": "OnePlus BE2015",
    "value": "OnePlus BE2015"
  },
  {
    "name": "samsung SM-M536S",
    "label": "samsung SM-M536S",
    "value": "samsung SM-M536S"
  },
  {
    "name": "vivo V2053",
    "label": "vivo V2053",
    "value": "vivo V2053"
  },
  {
    "name": "TINNO Wiko U316AT",
    "label": "TINNO Wiko U316AT",
    "value": "TINNO Wiko U316AT"
  },
  {
    "name": "samsung SM-G998U",
    "label": "samsung SM-G998U",
    "value": "samsung SM-G998U"
  },
  {
    "name": "Google Pixel 6 Pro",
    "label": "Google Pixel 6 Pro",
    "value": "Google Pixel 6 Pro"
  },
  {
    "name": "samsung SM-G9750",
    "label": "samsung SM-G9750",
    "value": "samsung SM-G9750"
  },
  {
    "name": "samsung SM-T500",
    "label": "samsung SM-T500",
    "value": "samsung SM-T500"
  },
  {
    "name": "TCL 5007G",
    "label": "TCL 5007G",
    "value": "TCL 5007G"
  },
  {
    "name": "TCL 5033M",
    "label": "TCL 5033M",
    "value": "TCL 5033M"
  },
  {
    "name": "samsung SM-J600F",
    "label": "samsung SM-J600F",
    "value": "samsung SM-J600F"
  },
  {
    "name": "HUAWEI ALP-L09",
    "label": "HUAWEI ALP-L09",
    "value": "HUAWEI ALP-L09"
  },
  {
    "name": "HUAWEI DRA-LX9",
    "label": "HUAWEI DRA-LX9",
    "value": "HUAWEI DRA-LX9"
  },
  {
    "name": "Xiaomi M2101K9R",
    "label": "Xiaomi M2101K9R",
    "value": "Xiaomi M2101K9R"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6511B",
    "label": "INFINIX MOBILITY LIMITED Infinix X6511B",
    "value": "INFINIX MOBILITY LIMITED Infinix X6511B"
  },
  {
    "name": "Bmobile AX1078",
    "label": "Bmobile AX1078",
    "value": "Bmobile AX1078"
  },
  {
    "name": "vivo V2143",
    "label": "vivo V2143",
    "value": "vivo V2143"
  },
  {
    "name": "Multilaser M10A_3G",
    "label": "Multilaser M10A_3G",
    "value": "Multilaser M10A_3G"
  },
  {
    "name": "itel itel A509WM",
    "label": "itel itel A509WM",
    "value": "itel itel A509WM"
  },
  {
    "name": "Kalley Black G",
    "label": "Kalley Black G",
    "value": "Kalley Black G"
  },
  {
    "name": "Mobicel GLO",
    "label": "Mobicel GLO",
    "value": "Mobicel GLO"
  },
  {
    "name": "BLU G70",
    "label": "BLU G70",
    "value": "BLU G70"
  },
  {
    "name": "TCL A600DL",
    "label": "TCL A600DL",
    "value": "TCL A600DL"
  },
  {
    "name": "FIH EA211005",
    "label": "FIH EA211005",
    "value": "FIH EA211005"
  },
  {
    "name": "HUAWEI JLN-LX1",
    "label": "HUAWEI JLN-LX1",
    "value": "HUAWEI JLN-LX1"
  },
  {
    "name": "TCL 5030F_TR",
    "label": "TCL 5030F_TR",
    "value": "TCL 5030F_TR"
  },
  {
    "name": "Umx U693CL",
    "label": "Umx U693CL",
    "value": "Umx U693CL"
  },
  {
    "name": "LENOVO Lenovo TB-X306F",
    "label": "LENOVO Lenovo TB-X306F",
    "value": "LENOVO Lenovo TB-X306F"
  },
  {
    "name": "LGE LM-G820",
    "label": "LGE LM-G820",
    "value": "LGE LM-G820"
  },
  {
    "name": "TCL 8088X",
    "label": "TCL 8088X",
    "value": "TCL 8088X"
  },
  {
    "name": "samsung SM-N986U",
    "label": "samsung SM-N986U",
    "value": "samsung SM-N986U"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KE5j",
    "label": "TECNO MOBILE LIMITED TECNO KE5j",
    "value": "TECNO MOBILE LIMITED TECNO KE5j"
  },
  {
    "name": "Sony H3311",
    "label": "Sony H3311",
    "value": "Sony H3311"
  },
  {
    "name": "Xiaomi M2006C3MT",
    "label": "Xiaomi M2006C3MT",
    "value": "Xiaomi M2006C3MT"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BE6",
    "label": "TECNO MOBILE LIMITED TECNO BE6",
    "value": "TECNO MOBILE LIMITED TECNO BE6"
  },
  {
    "name": "Google Acer Chromebook R11 (CB5-132T, C738T)",
    "label": "Google Acer Chromebook R11 (CB5-132T, C738T)",
    "value": "Google Acer Chromebook R11 (CB5-132T, C738T)"
  },
  {
    "name": "TCL 6159A",
    "label": "TCL 6159A",
    "value": "TCL 6159A"
  },
  {
    "name": "BLU C5L",
    "label": "BLU C5L",
    "value": "BLU C5L"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LC6a",
    "label": "TECNO MOBILE LIMITED TECNO LC6a",
    "value": "TECNO MOBILE LIMITED TECNO LC6a"
  },
  {
    "name": "TCL 5033F",
    "label": "TCL 5033F",
    "value": "TCL 5033F"
  },
  {
    "name": "TECNO TECNO LG6n",
    "label": "TECNO TECNO LG6n",
    "value": "TECNO TECNO LG6n"
  },
  {
    "name": "FiGi Note_1_Pro",
    "label": "FiGi Note_1_Pro",
    "value": "FiGi Note_1_Pro"
  },
  {
    "name": "Sony SO-01K",
    "label": "Sony SO-01K",
    "value": "Sony SO-01K"
  },
  {
    "name": "OPPO CPH2471",
    "label": "OPPO CPH2471",
    "value": "OPPO CPH2471"
  },
  {
    "name": "OPPO CPH1951",
    "label": "OPPO CPH1951",
    "value": "OPPO CPH1951"
  },
  {
    "name": "Multilaser Multilaser_F_Pro",
    "label": "Multilaser Multilaser_F_Pro",
    "value": "Multilaser Multilaser_F_Pro"
  },
  {
    "name": "OMIX X400",
    "label": "OMIX X400",
    "value": "OMIX X400"
  },
  {
    "name": "HUAWEI FLA-LX3",
    "label": "HUAWEI FLA-LX3",
    "value": "HUAWEI FLA-LX3"
  },
  {
    "name": "ZUUM AKUS PRO",
    "label": "ZUUM AKUS PRO",
    "value": "ZUUM AKUS PRO"
  },
  {
    "name": "Apple iPad13,4",
    "label": "Apple iPad13,4",
    "value": "Apple iPad13,4"
  },
  {
    "name": "motorola moto g power (2022)",
    "label": "motorola moto g power (2022)",
    "value": "motorola moto g power (2022)"
  },
  {
    "name": "HUAWEI PRA-LX3",
    "label": "HUAWEI PRA-LX3",
    "value": "HUAWEI PRA-LX3"
  },
  {
    "name": "samsung SM-N976U",
    "label": "samsung SM-N976U",
    "value": "samsung SM-N976U"
  },
  {
    "name": "samsung SCV39",
    "label": "samsung SCV39",
    "value": "samsung SCV39"
  },
  {
    "name": "TCL 6125A",
    "label": "TCL 6125A",
    "value": "TCL 6125A"
  },
  {
    "name": "TCL 5007W",
    "label": "TCL 5007W",
    "value": "TCL 5007W"
  },
  {
    "name": "LENOVO P1061X",
    "label": "LENOVO P1061X",
    "value": "LENOVO P1061X"
  },
  {
    "name": "motorola moto g stylus",
    "label": "motorola moto g stylus",
    "value": "motorola moto g stylus"
  },
  {
    "name": "TCL 8091_TR",
    "label": "TCL 8091_TR",
    "value": "TCL 8091_TR"
  },
  {
    "name": "INFINIX Infinix X6823",
    "label": "INFINIX Infinix X6823",
    "value": "INFINIX Infinix X6823"
  },
  {
    "name": "samsung SM-F926U1",
    "label": "samsung SM-F926U1",
    "value": "samsung SM-F926U1"
  },
  {
    "name": "Safaricom NEON RAY 2",
    "label": "Safaricom NEON RAY 2",
    "value": "Safaricom NEON RAY 2"
  },
  {
    "name": "HMD Global TA-1038",
    "label": "HMD Global TA-1038",
    "value": "HMD Global TA-1038"
  },
  {
    "name": "TCL 5002S",
    "label": "TCL 5002S",
    "value": "TCL 5002S"
  },
  {
    "name": "asus ASUS_X00ID",
    "label": "asus ASUS_X00ID",
    "value": "asus ASUS_X00ID"
  },
  {
    "name": "LGE LG-H870",
    "label": "LGE LG-H870",
    "value": "LGE LG-H870"
  },
  {
    "name": "Mobicel R7_1",
    "label": "Mobicel R7_1",
    "value": "Mobicel R7_1"
  },
  {
    "name": "OPPO CPH2077",
    "label": "OPPO CPH2077",
    "value": "OPPO CPH2077"
  },
  {
    "name": "ZTE Z835",
    "label": "ZTE Z835",
    "value": "ZTE Z835"
  },
  {
    "name": "vivo vivo 1951",
    "label": "vivo vivo 1951",
    "value": "vivo vivo 1951"
  },
  {
    "name": "samsung SM-X700",
    "label": "samsung SM-X700",
    "value": "samsung SM-X700"
  },
  {
    "name": "itel itel W5504",
    "label": "itel itel W5504",
    "value": "itel itel W5504"
  },
  {
    "name": "samsung SM-A326BR",
    "label": "samsung SM-A326BR",
    "value": "samsung SM-A326BR"
  },
  {
    "name": "samsung SM-A215U",
    "label": "samsung SM-A215U",
    "value": "samsung SM-A215U"
  },
  {
    "name": "vivo V2126",
    "label": "vivo V2126",
    "value": "vivo V2126"
  },
  {
    "name": "samsung SM-G965U1",
    "label": "samsung SM-G965U1",
    "value": "samsung SM-G965U1"
  },
  {
    "name": "LGE LM-K525",
    "label": "LGE LM-K525",
    "value": "LGE LM-K525"
  },
  {
    "name": "iPad14,1 iPad",
    "label": "iPad14,1 iPad",
    "value": "iPad14,1 iPad"
  },
  {
    "name": "Multilaser MS60Z",
    "label": "Multilaser MS60Z",
    "value": "Multilaser MS60Z"
  },
  {
    "name": "OPPO CPH1835",
    "label": "OPPO CPH1835",
    "value": "OPPO CPH1835"
  },
  {
    "name": "samsung SM-A716U1",
    "label": "samsung SM-A716U1",
    "value": "samsung SM-A716U1"
  },
  {
    "name": "samsung SM-J737S",
    "label": "samsung SM-J737S",
    "value": "samsung SM-J737S"
  },
  {
    "name": "HONOR CMA-LX3",
    "label": "HONOR CMA-LX3",
    "value": "HONOR CMA-LX3"
  },
  {
    "name": "samsung SM-J810Y",
    "label": "samsung SM-J810Y",
    "value": "samsung SM-J810Y"
  },
  {
    "name": "vsmart V350C",
    "label": "vsmart V350C",
    "value": "vsmart V350C"
  },
  {
    "name": "LOGIC T10L",
    "label": "LOGIC T10L",
    "value": "LOGIC T10L"
  },
  {
    "name": "TCL 8092",
    "label": "TCL 8092",
    "value": "TCL 8092"
  },
  {
    "name": "Mobicel Legend",
    "label": "Mobicel Legend",
    "value": "Mobicel Legend"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X606D",
    "label": "INFINIX MOBILITY LIMITED Infinix X606D",
    "value": "INFINIX MOBILITY LIMITED Infinix X606D"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO Mobile CH6n",
    "label": "TECNO MOBILE LIMITED TECNO Mobile CH6n",
    "value": "TECNO MOBILE LIMITED TECNO Mobile CH6n"
  },
  {
    "name": "vivo V2202",
    "label": "vivo V2202",
    "value": "vivo V2202"
  },
  {
    "name": "HUAWEI LLD-L31",
    "label": "HUAWEI LLD-L31",
    "value": "HUAWEI LLD-L31"
  },
  {
    "name": "samsung SM-A042M",
    "label": "samsung SM-A042M",
    "value": "samsung SM-A042M"
  },
  {
    "name": "samsung SAMSUNG-SM-G935A",
    "label": "samsung SAMSUNG-SM-G935A",
    "value": "samsung SAMSUNG-SM-G935A"
  },
  {
    "name": "samsung SM-G988W",
    "label": "samsung SM-G988W",
    "value": "samsung SM-G988W"
  },
  {
    "name": "LGE LM-Q920N",
    "label": "LGE LM-Q920N",
    "value": "LGE LM-Q920N"
  },
  {
    "name": "ZTE Z6621O",
    "label": "ZTE Z6621O",
    "value": "ZTE Z6621O"
  },
  {
    "name": "vivo vivo 1929",
    "label": "vivo vivo 1929",
    "value": "vivo vivo 1929"
  },
  {
    "name": "Apple iPad8,1",
    "label": "Apple iPad8,1",
    "value": "Apple iPad8,1"
  },
  {
    "name": "realme RMX1901",
    "label": "realme RMX1901",
    "value": "realme RMX1901"
  },
  {
    "name": "HUAWEI TAS-L29",
    "label": "HUAWEI TAS-L29",
    "value": "HUAWEI TAS-L29"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CD6S",
    "label": "TECNO MOBILE LIMITED TECNO CD6S",
    "value": "TECNO MOBILE LIMITED TECNO CD6S"
  },
  {
    "name": "realme RMX3471",
    "label": "realme RMX3471",
    "value": "realme RMX3471"
  },
  {
    "name": "HMD Global TA-1039",
    "label": "HMD Global TA-1039",
    "value": "HMD Global TA-1039"
  },
  {
    "name": "Bmobile AX824+",
    "label": "Bmobile AX824+",
    "value": "Bmobile AX824+"
  },
  {
    "name": "itel itel P681L",
    "label": "itel itel P681L",
    "value": "itel itel P681L"
  },
  {
    "name": "realme RMX2083",
    "label": "realme RMX2083",
    "value": "realme RMX2083"
  },
  {
    "name": "HUAWEI JAT-LX1",
    "label": "HUAWEI JAT-LX1",
    "value": "HUAWEI JAT-LX1"
  },
  {
    "name": "HUAWEI WKG-LX9",
    "label": "HUAWEI WKG-LX9",
    "value": "HUAWEI WKG-LX9"
  },
  {
    "name": "asus ASUS_X00DDB",
    "label": "asus ASUS_X00DDB",
    "value": "asus ASUS_X00DDB"
  },
  {
    "name": "HUAWEI ART-L29",
    "label": "HUAWEI ART-L29",
    "value": "HUAWEI ART-L29"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X627",
    "label": "INFINIX MOBILITY LIMITED Infinix X627",
    "value": "INFINIX MOBILITY LIMITED Infinix X627"
  },
  {
    "name": "INOVO I 409",
    "label": "INOVO I 409",
    "value": "INOVO I 409"
  },
  {
    "name": "ZTE BLADE V8Q",
    "label": "ZTE BLADE V8Q",
    "value": "ZTE BLADE V8Q"
  },
  {
    "name": "Apple iPad7,4",
    "label": "Apple iPad7,4",
    "value": "Apple iPad7,4"
  },
  {
    "name": "TECNO TECNO PR651",
    "label": "TECNO TECNO PR651",
    "value": "TECNO TECNO PR651"
  },
  {
    "name": "Logicom Le_Pulse",
    "label": "Logicom Le_Pulse",
    "value": "Logicom Le_Pulse"
  },
  {
    "name": "realme RMX2071",
    "label": "realme RMX2071",
    "value": "realme RMX2071"
  },
  {
    "name": "vsmart Star 5",
    "label": "vsmart Star 5",
    "value": "vsmart Star 5"
  },
  {
    "name": "HUAWEI PAR-LX1M",
    "label": "HUAWEI PAR-LX1M",
    "value": "HUAWEI PAR-LX1M"
  },
  {
    "name": "realme RMP2106",
    "label": "realme RMP2106",
    "value": "realme RMP2106"
  },
  {
    "name": "HMD Global Nokia C21 Plus",
    "label": "HMD Global Nokia C21 Plus",
    "value": "HMD Global Nokia C21 Plus"
  },
  {
    "name": "OUKITEL WP7",
    "label": "OUKITEL WP7",
    "value": "OUKITEL WP7"
  },
  {
    "name": "Xiaomi 2109119DI",
    "label": "Xiaomi 2109119DI",
    "value": "Xiaomi 2109119DI"
  },
  {
    "name": "OPPO CPH2073",
    "label": "OPPO CPH2073",
    "value": "OPPO CPH2073"
  },
  {
    "name": "LGE LM-G910N",
    "label": "LGE LM-G910N",
    "value": "LGE LM-G910N"
  },
  {
    "name": "TP-Link Neffos_Y7",
    "label": "TP-Link Neffos_Y7",
    "value": "TP-Link Neffos_Y7"
  },
  {
    "name": "vivo vivo 1823",
    "label": "vivo vivo 1823",
    "value": "vivo vivo 1823"
  },
  {
    "name": "LENOVO Lenovo PB-6505M",
    "label": "LENOVO Lenovo PB-6505M",
    "value": "LENOVO Lenovo PB-6505M"
  },
  {
    "name": "OnePlus LE2101",
    "label": "OnePlus LE2101",
    "value": "OnePlus LE2101"
  },
  {
    "name": "Multilaser MS60X",
    "label": "Multilaser MS60X",
    "value": "Multilaser MS60X"
  },
  {
    "name": "OnePlus LE2100",
    "label": "OnePlus LE2100",
    "value": "OnePlus LE2100"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BD3",
    "label": "TECNO MOBILE LIMITED TECNO BD3",
    "value": "TECNO MOBILE LIMITED TECNO BD3"
  },
  {
    "name": "Apple ה-iPhone של ליאור",
    "label": "Apple ה-iPhone של ליאור",
    "value": "Apple ה-iPhone של ליאור"
  },
  {
    "name": "itel itel S661W",
    "label": "itel itel S661W",
    "value": "itel itel S661W"
  },
  {
    "name": "Mobicel R7_2",
    "label": "Mobicel R7_2",
    "value": "Mobicel R7_2"
  },
  {
    "name": "HUAWEI AUM-L29",
    "label": "HUAWEI AUM-L29",
    "value": "HUAWEI AUM-L29"
  },
  {
    "name": "ACE France BUZZ 3",
    "label": "ACE France BUZZ 3",
    "value": "ACE France BUZZ 3"
  },
  {
    "name": "STYLO LIFE_2",
    "label": "STYLO LIFE_2",
    "value": "STYLO LIFE_2"
  },
  {
    "name": "samsung SM-G935U",
    "label": "samsung SM-G935U",
    "value": "samsung SM-G935U"
  },
  {
    "name": "samsung SM-T975",
    "label": "samsung SM-T975",
    "value": "samsung SM-T975"
  },
  {
    "name": "Philco HIT P8",
    "label": "Philco HIT P8",
    "value": "Philco HIT P8"
  },
  {
    "name": "Mobicel FAME_DS",
    "label": "Mobicel FAME_DS",
    "value": "Mobicel FAME_DS"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X656",
    "label": "INFINIX MOBILITY LIMITED Infinix X656",
    "value": "INFINIX MOBILITY LIMITED Infinix X656"
  },
  {
    "name": "Mobicel BEAM",
    "label": "Mobicel BEAM",
    "value": "Mobicel BEAM"
  },
  {
    "name": "Xiaomi M2007J17C",
    "label": "Xiaomi M2007J17C",
    "value": "Xiaomi M2007J17C"
  },
  {
    "name": "Meizu meizu note8",
    "label": "Meizu meizu note8",
    "value": "Meizu meizu note8"
  },
  {
    "name": "blackshark SKR-H0",
    "label": "blackshark SKR-H0",
    "value": "blackshark SKR-H0"
  },
  {
    "name": "OPPO CPH1903",
    "label": "OPPO CPH1903",
    "value": "OPPO CPH1903"
  },
  {
    "name": "Sony J8110",
    "label": "Sony J8110",
    "value": "Sony J8110"
  },
  {
    "name": "ipad 5th gen iPad",
    "label": "ipad 5th gen iPad",
    "value": "ipad 5th gen iPad"
  },
  {
    "name": "I-life ITELL_K3102N",
    "label": "I-life ITELL_K3102N",
    "value": "I-life ITELL_K3102N"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KC1",
    "label": "TECNO MOBILE LIMITED TECNO KC1",
    "value": "TECNO MOBILE LIMITED TECNO KC1"
  },
  {
    "name": "samsung SM-T835",
    "label": "samsung SM-T835",
    "value": "samsung SM-T835"
  },
  {
    "name": "vivo V1911A",
    "label": "vivo V1911A",
    "value": "vivo V1911A"
  },
  {
    "name": "Sony XQ-AT52",
    "label": "Sony XQ-AT52",
    "value": "Sony XQ-AT52"
  },
  {
    "name": "HUAWEI ATU-L31",
    "label": "HUAWEI ATU-L31",
    "value": "HUAWEI ATU-L31"
  },
  {
    "name": "TECNO TECNO PR651H",
    "label": "TECNO TECNO PR651H",
    "value": "TECNO TECNO PR651H"
  },
  {
    "name": "HUAWEI HMA-L29",
    "label": "HUAWEI HMA-L29",
    "value": "HUAWEI HMA-L29"
  },
  {
    "name": "Google Samsung Chromebook 3",
    "label": "Google Samsung Chromebook 3",
    "value": "Google Samsung Chromebook 3"
  },
  {
    "name": "nubia NX591J",
    "label": "nubia NX591J",
    "value": "nubia NX591J"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KG6",
    "label": "TECNO MOBILE LIMITED TECNO KG6",
    "value": "TECNO MOBILE LIMITED TECNO KG6"
  },
  {
    "name": "Xiaomi 220333QPG",
    "label": "Xiaomi 220333QPG",
    "value": "Xiaomi 220333QPG"
  },
  {
    "name": "Xiaomi 21121119SC",
    "label": "Xiaomi 21121119SC",
    "value": "Xiaomi 21121119SC"
  },
  {
    "name": "TCL 5008D_EEA",
    "label": "TCL 5008D_EEA",
    "value": "TCL 5008D_EEA"
  },
  {
    "name": "OPPO CPH1805",
    "label": "OPPO CPH1805",
    "value": "OPPO CPH1805"
  },
  {
    "name": "Hisense Hisense E60 Lite",
    "label": "Hisense Hisense E60 Lite",
    "value": "Hisense Hisense E60 Lite"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X663B",
    "label": "INFINIX MOBILITY LIMITED Infinix X663B",
    "value": "INFINIX MOBILITY LIMITED Infinix X663B"
  },
  {
    "name": "Apple Kuhle Amanda’s iPhone",
    "label": "Apple Kuhle Amanda’s iPhone",
    "value": "Apple Kuhle Amanda’s iPhone"
  },
  {
    "name": "Vanzo X50",
    "label": "Vanzo X50",
    "value": "Vanzo X50"
  },
  {
    "name": "Google dedede",
    "label": "Google dedede",
    "value": "Google dedede"
  },
  {
    "name": "Cherry_Mobile Flare S8",
    "label": "Cherry_Mobile Flare S8",
    "value": "Cherry_Mobile Flare S8"
  },
  {
    "name": "vivo I2206",
    "label": "vivo I2206",
    "value": "vivo I2206"
  },
  {
    "name": "vivo V2154",
    "label": "vivo V2154",
    "value": "vivo V2154"
  },
  {
    "name": "STYLO S64_ALPHA",
    "label": "STYLO S64_ALPHA",
    "value": "STYLO S64_ALPHA"
  },
  {
    "name": "OnePlus HD1903",
    "label": "OnePlus HD1903",
    "value": "OnePlus HD1903"
  },
  {
    "name": "Alco VCT9F78Q22EU",
    "label": "Alco VCT9F78Q22EU",
    "value": "Alco VCT9F78Q22EU"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BC2c",
    "label": "TECNO MOBILE LIMITED TECNO BC2c",
    "value": "TECNO MOBILE LIMITED TECNO BC2c"
  },
  {
    "name": "itel itel A509W",
    "label": "itel itel A509W",
    "value": "itel itel A509W"
  },
  {
    "name": "Alco VCT9B06Q22",
    "label": "Alco VCT9B06Q22",
    "value": "Alco VCT9B06Q22"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CD7",
    "label": "TECNO MOBILE LIMITED TECNO CD7",
    "value": "TECNO MOBILE LIMITED TECNO CD7"
  },
  {
    "name": "DOOGEE S88Plus",
    "label": "DOOGEE S88Plus",
    "value": "DOOGEE S88Plus"
  },
  {
    "name": "Xiaomi MI MAX 2",
    "label": "Xiaomi MI MAX 2",
    "value": "Xiaomi MI MAX 2"
  },
  {
    "name": "vivo I1927",
    "label": "vivo I1927",
    "value": "vivo I1927"
  },
  {
    "name": "realme RMX2121",
    "label": "realme RMX2121",
    "value": "realme RMX2121"
  },
  {
    "name": "TCL 5130E",
    "label": "TCL 5130E",
    "value": "TCL 5130E"
  },
  {
    "name": "samsung SM-M135FU",
    "label": "samsung SM-M135FU",
    "value": "samsung SM-M135FU"
  },
  {
    "name": "HMD Global Nokia X71",
    "label": "HMD Global Nokia X71",
    "value": "HMD Global Nokia X71"
  },
  {
    "name": "OnePlus IN2021",
    "label": "OnePlus IN2021",
    "value": "OnePlus IN2021"
  },
  {
    "name": "LGE LGM-X320K",
    "label": "LGE LGM-X320K",
    "value": "LGE LGM-X320K"
  },
  {
    "name": "itel itel L5503S",
    "label": "itel itel L5503S",
    "value": "itel itel L5503S"
  },
  {
    "name": "samsung SM-N950U",
    "label": "samsung SM-N950U",
    "value": "samsung SM-N950U"
  },
  {
    "name": "vivo V2147",
    "label": "vivo V2147",
    "value": "vivo V2147"
  },
  {
    "name": "Cherry_Mobile Omega X",
    "label": "Cherry_Mobile Omega X",
    "value": "Cherry_Mobile Omega X"
  },
  {
    "name": "Xiaomi 2201117SI",
    "label": "Xiaomi 2201117SI",
    "value": "Xiaomi 2201117SI"
  },
  {
    "name": "HUAWEI JDN2-L09",
    "label": "HUAWEI JDN2-L09",
    "value": "HUAWEI JDN2-L09"
  },
  {
    "name": "Google Pixel 7",
    "label": "Google Pixel 7",
    "value": "Google Pixel 7"
  },
  {
    "name": "GIONEE S12 Lite",
    "label": "GIONEE S12 Lite",
    "value": "GIONEE S12 Lite"
  },
  {
    "name": "HMD Global Nokia G11 Plus",
    "label": "HMD Global Nokia G11 Plus",
    "value": "HMD Global Nokia G11 Plus"
  },
  {
    "name": "Xiaomi Redmi Note 4",
    "label": "Xiaomi Redmi Note 4",
    "value": "Xiaomi Redmi Note 4"
  },
  {
    "name": "Positivo Twist 2 Pro",
    "label": "Positivo Twist 2 Pro",
    "value": "Positivo Twist 2 Pro"
  },
  {
    "name": "samsung SM-E135F",
    "label": "samsung SM-E135F",
    "value": "samsung SM-E135F"
  },
  {
    "name": "realme RMX3388",
    "label": "realme RMX3388",
    "value": "realme RMX3388"
  },
  {
    "name": "OPPO CPH2089",
    "label": "OPPO CPH2089",
    "value": "OPPO CPH2089"
  },
  {
    "name": "samsung SM-M236L",
    "label": "samsung SM-M236L",
    "value": "samsung SM-M236L"
  },
  {
    "name": "samsung SM-A115U",
    "label": "samsung SM-A115U",
    "value": "samsung SM-A115U"
  },
  {
    "name": "Azumi V55",
    "label": "Azumi V55",
    "value": "Azumi V55"
  },
  {
    "name": "samsung SM-G9880",
    "label": "samsung SM-G9880",
    "value": "samsung SM-G9880"
  },
  {
    "name": "Lenovo Vibe K5 Plus",
    "label": "Lenovo Vibe K5 Plus",
    "value": "Lenovo Vibe K5 Plus"
  },
  {
    "name": "CUBOT CUBOT J9",
    "label": "CUBOT CUBOT J9",
    "value": "CUBOT CUBOT J9"
  },
  {
    "name": "OPPO CPH1719",
    "label": "OPPO CPH1719",
    "value": "OPPO CPH1719"
  },
  {
    "name": "Android MBOX",
    "label": "Android MBOX",
    "value": "Android MBOX"
  },
  {
    "name": "EVERCOSS S6",
    "label": "EVERCOSS S6",
    "value": "EVERCOSS S6"
  },
  {
    "name": "HMD Global Nokia C21",
    "label": "HMD Global Nokia C21",
    "value": "HMD Global Nokia C21"
  },
  {
    "name": "realme RMX2032",
    "label": "realme RMX2032",
    "value": "realme RMX2032"
  },
  {
    "name": "realme RMX2117",
    "label": "realme RMX2117",
    "value": "realme RMX2117"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LE6h",
    "label": "TECNO MOBILE LIMITED TECNO LE6h",
    "value": "TECNO MOBILE LIMITED TECNO LE6h"
  },
  {
    "name": "Hurricane Iris Plus 2021",
    "label": "Hurricane Iris Plus 2021",
    "value": "Hurricane Iris Plus 2021"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X652C",
    "label": "INFINIX MOBILITY LIMITED Infinix X652C",
    "value": "INFINIX MOBILITY LIMITED Infinix X652C"
  },
  {
    "name": "KYOCERA KYV45",
    "label": "KYOCERA KYV45",
    "value": "KYOCERA KYV45"
  },
  {
    "name": "LENOVO Lenovo TB-8504X",
    "label": "LENOVO Lenovo TB-8504X",
    "value": "LENOVO Lenovo TB-8504X"
  },
  {
    "name": "Fortune Ship GO506",
    "label": "Fortune Ship GO506",
    "value": "Fortune Ship GO506"
  },
  {
    "name": "STYLO BOLD",
    "label": "STYLO BOLD",
    "value": "STYLO BOLD"
  },
  {
    "name": "SHARP SH-04H",
    "label": "SHARP SH-04H",
    "value": "SHARP SH-04H"
  },
  {
    "name": "OnePlus IN2023",
    "label": "OnePlus IN2023",
    "value": "OnePlus IN2023"
  },
  {
    "name": "Infinix Infinix X572",
    "label": "Infinix Infinix X572",
    "value": "Infinix Infinix X572"
  },
  {
    "name": "DOOGEE N20",
    "label": "DOOGEE N20",
    "value": "DOOGEE N20"
  },
  {
    "name": "HXY Power 5",
    "label": "HXY Power 5",
    "value": "HXY Power 5"
  },
  {
    "name": "WIKO W-K211-TH",
    "label": "WIKO W-K211-TH",
    "value": "WIKO W-K211-TH"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6511E",
    "label": "INFINIX MOBILITY LIMITED Infinix X6511E",
    "value": "INFINIX MOBILITY LIMITED Infinix X6511E"
  },
  {
    "name": "iPhone8,4 iPhone",
    "label": "iPhone8,4 iPhone",
    "value": "iPhone8,4 iPhone"
  },
  {
    "name": "Apple Thatohatsi’s iPhone",
    "label": "Apple Thatohatsi’s iPhone",
    "value": "Apple Thatohatsi’s iPhone"
  },
  {
    "name": "LGE LG-Q710AL",
    "label": "LGE LG-Q710AL",
    "value": "LGE LG-Q710AL"
  },
  {
    "name": "revoview MD_G1212U",
    "label": "revoview MD_G1212U",
    "value": "revoview MD_G1212U"
  },
  {
    "name": "HMD Global Nokia 3.1 A",
    "label": "HMD Global Nokia 3.1 A",
    "value": "HMD Global Nokia 3.1 A"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X695C",
    "label": "INFINIX MOBILITY LIMITED Infinix X695C",
    "value": "INFINIX MOBILITY LIMITED Infinix X695C"
  },
  {
    "name": "Kleadtone X84",
    "label": "Kleadtone X84",
    "value": "Kleadtone X84"
  },
  {
    "name": "OPPO PCKM80",
    "label": "OPPO PCKM80",
    "value": "OPPO PCKM80"
  },
  {
    "name": "samsung SM-N981U",
    "label": "samsung SM-N981U",
    "value": "samsung SM-N981U"
  },
  {
    "name": "HUAWEI NEO-L29",
    "label": "HUAWEI NEO-L29",
    "value": "HUAWEI NEO-L29"
  },
  {
    "name": "OnePlus BE2025",
    "label": "OnePlus BE2025",
    "value": "OnePlus BE2025"
  },
  {
    "name": "OPPO RMX1807",
    "label": "OPPO RMX1807",
    "value": "OPPO RMX1807"
  },
  {
    "name": "incar SP5712",
    "label": "incar SP5712",
    "value": "incar SP5712"
  },
  {
    "name": "CUBOT J8",
    "label": "CUBOT J8",
    "value": "CUBOT J8"
  },
  {
    "name": "Apple Batho’s iPhone",
    "label": "Apple Batho’s iPhone",
    "value": "Apple Batho’s iPhone"
  },
  {
    "name": "blackshark SKW-A0",
    "label": "blackshark SKW-A0",
    "value": "blackshark SKW-A0"
  },
  {
    "name": "Blackview A60",
    "label": "Blackview A60",
    "value": "Blackview A60"
  },
  {
    "name": "OPPO CPH1987",
    "label": "OPPO CPH1987",
    "value": "OPPO CPH1987"
  },
  {
    "name": "LOGIC L55B",
    "label": "LOGIC L55B",
    "value": "LOGIC L55B"
  },
  {
    "name": "OPPO CPH2201",
    "label": "OPPO CPH2201",
    "value": "OPPO CPH2201"
  },
  {
    "name": "LEAGOO M13",
    "label": "LEAGOO M13",
    "value": "LEAGOO M13"
  },
  {
    "name": "Apple iPad13,6",
    "label": "Apple iPad13,6",
    "value": "Apple iPad13,6"
  },
  {
    "name": "samsung SM-S515DL",
    "label": "samsung SM-S515DL",
    "value": "samsung SM-S515DL"
  },
  {
    "name": "WIKO W-V680-EEA",
    "label": "WIKO W-V680-EEA",
    "value": "WIKO W-V680-EEA"
  },
  {
    "name": "Sony F5122",
    "label": "Sony F5122",
    "value": "Sony F5122"
  },
  {
    "name": "Realme Realme 5",
    "label": "Realme Realme 5",
    "value": "Realme Realme 5"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CH6h",
    "label": "TECNO MOBILE LIMITED TECNO CH6h",
    "value": "TECNO MOBILE LIMITED TECNO CH6h"
  },
  {
    "name": "CUBOT X30",
    "label": "CUBOT X30",
    "value": "CUBOT X30"
  },
  {
    "name": "Mobell S51",
    "label": "Mobell S51",
    "value": "Mobell S51"
  },
  {
    "name": "BLU G60",
    "label": "BLU G60",
    "value": "BLU G60"
  },
  {
    "name": "ZTE ZTE Blade L210RU",
    "label": "ZTE ZTE Blade L210RU",
    "value": "ZTE ZTE Blade L210RU"
  },
  {
    "name": "vsmart Star",
    "label": "vsmart Star",
    "value": "vsmart Star"
  },
  {
    "name": "incar smart",
    "label": "incar smart",
    "value": "incar smart"
  },
  {
    "name": "samsung SM-G977B",
    "label": "samsung SM-G977B",
    "value": "samsung SM-G977B"
  },
  {
    "name": "Apple iPhone de Déborah",
    "label": "Apple iPhone de Déborah",
    "value": "Apple iPhone de Déborah"
  },
  {
    "name": "iPad7,4 iPad",
    "label": "iPad7,4 iPad",
    "value": "iPad7,4 iPad"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KA7",
    "label": "TECNO MOBILE LIMITED TECNO KA7",
    "value": "TECNO MOBILE LIMITED TECNO KA7"
  },
  {
    "name": "TCL 5007U_EEA",
    "label": "TCL 5007U_EEA",
    "value": "TCL 5007U_EEA"
  },
  {
    "name": "realme RMX3513",
    "label": "realme RMX3513",
    "value": "realme RMX3513"
  },
  {
    "name": "motorola moto g(9)",
    "label": "motorola moto g(9)",
    "value": "motorola moto g(9)"
  },
  {
    "name": "samsung SM-J730FM",
    "label": "samsung SM-J730FM",
    "value": "samsung SM-J730FM"
  },
  {
    "name": "Sony G3121",
    "label": "Sony G3121",
    "value": "Sony G3121"
  },
  {
    "name": "samsung SM-J260Y",
    "label": "samsung SM-J260Y",
    "value": "samsung SM-J260Y"
  },
  {
    "name": "samsung SM-J710GN",
    "label": "samsung SM-J710GN",
    "value": "samsung SM-J710GN"
  },
  {
    "name": "samsung SM-A320F",
    "label": "samsung SM-A320F",
    "value": "samsung SM-A320F"
  },
  {
    "name": "positivo Positivo Q20",
    "label": "positivo Positivo Q20",
    "value": "positivo Positivo Q20"
  },
  {
    "name": "TP-LINK Neffos_C7A",
    "label": "TP-LINK Neffos_C7A",
    "value": "TP-LINK Neffos_C7A"
  },
  {
    "name": "samsung SM-A320FL",
    "label": "samsung SM-A320FL",
    "value": "samsung SM-A320FL"
  },
  {
    "name": "FISE KL602",
    "label": "FISE KL602",
    "value": "FISE KL602"
  },
  {
    "name": "vivo V2044",
    "label": "vivo V2044",
    "value": "vivo V2044"
  },
  {
    "name": "OPPO CPH2091",
    "label": "OPPO CPH2091",
    "value": "OPPO CPH2091"
  },
  {
    "name": "LGE LM-K300",
    "label": "LGE LM-K300",
    "value": "LGE LM-K300"
  },
  {
    "name": "FIGI Note_3",
    "label": "FIGI Note_3",
    "value": "FIGI Note_3"
  },
  {
    "name": "Google Intel Braswell Chromebook",
    "label": "Google Intel Braswell Chromebook",
    "value": "Google Intel Braswell Chromebook"
  },
  {
    "name": "HUAWEI VOG-L04",
    "label": "HUAWEI VOG-L04",
    "value": "HUAWEI VOG-L04"
  },
  {
    "name": "Vodafone VFD 527",
    "label": "Vodafone VFD 527",
    "value": "Vodafone VFD 527"
  },
  {
    "name": "DEXP AS260",
    "label": "DEXP AS260",
    "value": "DEXP AS260"
  },
  {
    "name": "Premio X83",
    "label": "Premio X83",
    "value": "Premio X83"
  },
  {
    "name": "ZTE ZTE Blade A3 2020RU",
    "label": "ZTE ZTE Blade A3 2020RU",
    "value": "ZTE ZTE Blade A3 2020RU"
  },
  {
    "name": "Qnet C32",
    "label": "Qnet C32",
    "value": "Qnet C32"
  },
  {
    "name": "LANIX Ilium M9s",
    "label": "LANIX Ilium M9s",
    "value": "LANIX Ilium M9s"
  },
  {
    "name": "CUBOT C20",
    "label": "CUBOT C20",
    "value": "CUBOT C20"
  },
  {
    "name": "OnePlus IN2011",
    "label": "OnePlus IN2011",
    "value": "OnePlus IN2011"
  },
  {
    "name": "motorola moto g(7) optimo maxx(XT1955DL)",
    "label": "motorola moto g(7) optimo maxx(XT1955DL)",
    "value": "motorola moto g(7) optimo maxx(XT1955DL)"
  },
  {
    "name": "Realme RMX1903",
    "label": "Realme RMX1903",
    "value": "Realme RMX1903"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A14",
    "label": "ITEL MOBILE LIMITED itel A14",
    "value": "ITEL MOBILE LIMITED itel A14"
  },
  {
    "name": "Xiaomi Redmi K30 Pro",
    "label": "Xiaomi Redmi K30 Pro",
    "value": "Xiaomi Redmi K30 Pro"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CE7j",
    "label": "TECNO MOBILE LIMITED TECNO CE7j",
    "value": "TECNO MOBILE LIMITED TECNO CE7j"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO B1f",
    "label": "TECNO MOBILE LIMITED TECNO B1f",
    "value": "TECNO MOBILE LIMITED TECNO B1f"
  },
  {
    "name": "HUAWEI MHA-L09",
    "label": "HUAWEI MHA-L09",
    "value": "HUAWEI MHA-L09"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO AB7",
    "label": "TECNO MOBILE LIMITED TECNO AB7",
    "value": "TECNO MOBILE LIMITED TECNO AB7"
  },
  {
    "name": "YEZZ LIV 1S",
    "label": "YEZZ LIV 1S",
    "value": "YEZZ LIV 1S"
  },
  {
    "name": "Lenovo Lenovo L10041",
    "label": "Lenovo Lenovo L10041",
    "value": "Lenovo Lenovo L10041"
  },
  {
    "name": "OPPO CPH2339",
    "label": "OPPO CPH2339",
    "value": "OPPO CPH2339"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO P704a",
    "label": "TECNO MOBILE LIMITED TECNO P704a",
    "value": "TECNO MOBILE LIMITED TECNO P704a"
  },
  {
    "name": "samsung SM-J400G",
    "label": "samsung SM-J400G",
    "value": "samsung SM-J400G"
  },
  {
    "name": "JOYAR 100011885",
    "label": "JOYAR 100011885",
    "value": "JOYAR 100011885"
  },
  {
    "name": "Longcheer D",
    "label": "Longcheer D",
    "value": "Longcheer D"
  },
  {
    "name": "Apple Katlego’s iPhone",
    "label": "Apple Katlego’s iPhone",
    "value": "Apple Katlego’s iPhone"
  },
  {
    "name": "Apple Sibusiso’s iPhone",
    "label": "Apple Sibusiso’s iPhone",
    "value": "Apple Sibusiso’s iPhone"
  },
  {
    "name": "asus ASUS_I001DE",
    "label": "asus ASUS_I001DE",
    "value": "asus ASUS_I001DE"
  },
  {
    "name": "Apple Risi’s iPhone",
    "label": "Apple Risi’s iPhone",
    "value": "Apple Risi’s iPhone"
  },
  {
    "name": "OPPO CPH2359",
    "label": "OPPO CPH2359",
    "value": "OPPO CPH2359"
  },
  {
    "name": "samsung SM-E625F",
    "label": "samsung SM-E625F",
    "value": "samsung SM-E625F"
  },
  {
    "name": "Apple jpbon’s iPhone",
    "label": "Apple jpbon’s iPhone",
    "value": "Apple jpbon’s iPhone"
  },
  {
    "name": "LGE LG-H870I",
    "label": "LGE LG-H870I",
    "value": "LGE LG-H870I"
  },
  {
    "name": "Apple Katlego's iPhone",
    "label": "Apple Katlego's iPhone",
    "value": "Apple Katlego's iPhone"
  },
  {
    "name": "LGE LG-US996",
    "label": "LGE LG-US996",
    "value": "LGE LG-US996"
  },
  {
    "name": "Hisense Hisense Infinity H50",
    "label": "Hisense Hisense Infinity H50",
    "value": "Hisense Hisense Infinity H50"
  },
  {
    "name": "Xiaomi M2007J17I",
    "label": "Xiaomi M2007J17I",
    "value": "Xiaomi M2007J17I"
  },
  {
    "name": "Apple Chummy",
    "label": "Apple Chummy",
    "value": "Apple Chummy"
  },
  {
    "name": "EVERCOSS U70B",
    "label": "EVERCOSS U70B",
    "value": "EVERCOSS U70B"
  },
  {
    "name": "SSmooth Smooth 6.1",
    "label": "SSmooth Smooth 6.1",
    "value": "SSmooth Smooth 6.1"
  },
  {
    "name": "LGE VS996",
    "label": "LGE VS996",
    "value": "LGE VS996"
  },
  {
    "name": "samsung SM-N975U",
    "label": "samsung SM-N975U",
    "value": "samsung SM-N975U"
  },
  {
    "name": "samsung SM-N950W",
    "label": "samsung SM-N950W",
    "value": "samsung SM-N950W"
  },
  {
    "name": "LAVA LAVA LF9810_2GB",
    "label": "LAVA LAVA LF9810_2GB",
    "value": "LAVA LAVA LF9810_2GB"
  },
  {
    "name": "BullittGroupLimited S31",
    "label": "BullittGroupLimited S31",
    "value": "BullittGroupLimited S31"
  },
  {
    "name": "vivo V2068",
    "label": "vivo V2068",
    "value": "vivo V2068"
  },
  {
    "name": "Cat S52",
    "label": "Cat S52",
    "value": "Cat S52"
  },
  {
    "name": "TCL T770B",
    "label": "TCL T770B",
    "value": "TCL T770B"
  },
  {
    "name": "Siragon SP-5250",
    "label": "Siragon SP-5250",
    "value": "Siragon SP-5250"
  },
  {
    "name": "OPPO CPH2139",
    "label": "OPPO CPH2139",
    "value": "OPPO CPH2139"
  },
  {
    "name": "vivo V2030",
    "label": "vivo V2030",
    "value": "vivo V2030"
  },
  {
    "name": "rockchip X88pro10.jr.q2.0.6330.d4",
    "label": "rockchip X88pro10.jr.q2.0.6330.d4",
    "value": "rockchip X88pro10.jr.q2.0.6330.d4"
  },
  {
    "name": "Apple Iphone 6s",
    "label": "Apple Iphone 6s",
    "value": "Apple Iphone 6s"
  },
  {
    "name": "Apple iPhone 7",
    "label": "Apple iPhone 7",
    "value": "Apple iPhone 7"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CD6j",
    "label": "TECNO MOBILE LIMITED TECNO CD6j",
    "value": "TECNO MOBILE LIMITED TECNO CD6j"
  },
  {
    "name": "Apple Mashoto’s iPhone",
    "label": "Apple Mashoto’s iPhone",
    "value": "Apple Mashoto’s iPhone"
  },
  {
    "name": "Apple iPhone Baby",
    "label": "Apple iPhone Baby",
    "value": "Apple iPhone Baby"
  },
  {
    "name": "vivo vivo 1910",
    "label": "vivo vivo 1910",
    "value": "vivo vivo 1910"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KG6k",
    "label": "TECNO MOBILE LIMITED TECNO KG6k",
    "value": "TECNO MOBILE LIMITED TECNO KG6k"
  },
  {
    "name": "PREMIO S82",
    "label": "PREMIO S82",
    "value": "PREMIO S82"
  },
  {
    "name": "TCL 5032W",
    "label": "TCL 5032W",
    "value": "TCL 5032W"
  },
  {
    "name": "Apple Takudzwa’s iPhone",
    "label": "Apple Takudzwa’s iPhone",
    "value": "Apple Takudzwa’s iPhone"
  },
  {
    "name": "alps K10",
    "label": "alps K10",
    "value": "alps K10"
  },
  {
    "name": "HUAWEI BAC-L03",
    "label": "HUAWEI BAC-L03",
    "value": "HUAWEI BAC-L03"
  },
  {
    "name": "iPhone8,1 iPhone",
    "label": "iPhone8,1 iPhone",
    "value": "iPhone8,1 iPhone"
  },
  {
    "name": "Mobicel R1 Plus",
    "label": "Mobicel R1 Plus",
    "value": "Mobicel R1 Plus"
  },
  {
    "name": "HUAWEI CAG-L03",
    "label": "HUAWEI CAG-L03",
    "value": "HUAWEI CAG-L03"
  },
  {
    "name": "samsung SM-N986U1",
    "label": "samsung SM-N986U1",
    "value": "samsung SM-N986U1"
  },
  {
    "name": "TCL 6125F",
    "label": "TCL 6125F",
    "value": "TCL 6125F"
  },
  {
    "name": "vivo vivo Y81S",
    "label": "vivo vivo Y81S",
    "value": "vivo vivo Y81S"
  },
  {
    "name": "Xiaomi 22041219PG",
    "label": "Xiaomi 22041219PG",
    "value": "Xiaomi 22041219PG"
  },
  {
    "name": "HUAWEI FLA-LX1",
    "label": "HUAWEI FLA-LX1",
    "value": "HUAWEI FLA-LX1"
  },
  {
    "name": "Mobicel R9 LITE",
    "label": "Mobicel R9 LITE",
    "value": "Mobicel R9 LITE"
  },
  {
    "name": "Lenovo Lenovo L79031",
    "label": "Lenovo Lenovo L79031",
    "value": "Lenovo Lenovo L79031"
  },
  {
    "name": "TCL T671E",
    "label": "TCL T671E",
    "value": "TCL T671E"
  },
  {
    "name": "motorola Lenovo K12",
    "label": "motorola Lenovo K12",
    "value": "motorola Lenovo K12"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KG5",
    "label": "TECNO MOBILE LIMITED TECNO KG5",
    "value": "TECNO MOBILE LIMITED TECNO KG5"
  },
  {
    "name": "samsung SM-A025V",
    "label": "samsung SM-A025V",
    "value": "samsung SM-A025V"
  },
  {
    "name": "positivo M10 4G PRO X",
    "label": "positivo M10 4G PRO X",
    "value": "positivo M10 4G PRO X"
  },
  {
    "name": "samsung SM-G715W",
    "label": "samsung SM-G715W",
    "value": "samsung SM-G715W"
  },
  {
    "name": "OPPO CPH2351",
    "label": "OPPO CPH2351",
    "value": "OPPO CPH2351"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO F2LTE",
    "label": "TECNO MOBILE LIMITED TECNO F2LTE",
    "value": "TECNO MOBILE LIMITED TECNO F2LTE"
  },
  {
    "name": "Sony G3116",
    "label": "Sony G3116",
    "value": "Sony G3116"
  },
  {
    "name": "realme RMX2202",
    "label": "realme RMX2202",
    "value": "realme RMX2202"
  },
  {
    "name": "coolpad 2042",
    "label": "coolpad 2042",
    "value": "coolpad 2042"
  },
  {
    "name": "samsung SM-A125U1",
    "label": "samsung SM-A125U1",
    "value": "samsung SM-A125U1"
  },
  {
    "name": "ALLDOCUBE U1006H",
    "label": "ALLDOCUBE U1006H",
    "value": "ALLDOCUBE U1006H"
  },
  {
    "name": "JIMI MX50",
    "label": "JIMI MX50",
    "value": "JIMI MX50"
  },
  {
    "name": "Casper VIA_S48",
    "label": "Casper VIA_S48",
    "value": "Casper VIA_S48"
  },
  {
    "name": "samsung SM-A530N",
    "label": "samsung SM-A530N",
    "value": "samsung SM-A530N"
  },
  {
    "name": "Multilaser M7_WIFI",
    "label": "Multilaser M7_WIFI",
    "value": "Multilaser M7_WIFI"
  },
  {
    "name": "nubia NX629J",
    "label": "nubia NX629J",
    "value": "nubia NX629J"
  },
  {
    "name": "positivo T800",
    "label": "positivo T800",
    "value": "positivo T800"
  },
  {
    "name": "samsung SM-T736B",
    "label": "samsung SM-T736B",
    "value": "samsung SM-T736B"
  },
  {
    "name": "samsung SM-T225N",
    "label": "samsung SM-T225N",
    "value": "samsung SM-T225N"
  },
  {
    "name": "rockchip TV BOX",
    "label": "rockchip TV BOX",
    "value": "rockchip TV BOX"
  },
  {
    "name": "vivo V1901A",
    "label": "vivo V1901A",
    "value": "vivo V1901A"
  },
  {
    "name": "iKU iKU A22",
    "label": "iKU iKU A22",
    "value": "iKU iKU A22"
  },
  {
    "name": "WIKO W-V730-TVM",
    "label": "WIKO W-V730-TVM",
    "value": "WIKO W-V730-TVM"
  },
  {
    "name": "BLU G51 Plus",
    "label": "BLU G51 Plus",
    "value": "BLU G51 Plus"
  },
  {
    "name": "wind Amber7S",
    "label": "wind Amber7S",
    "value": "wind Amber7S"
  },
  {
    "name": "Sony SO-02J",
    "label": "Sony SO-02J",
    "value": "Sony SO-02J"
  },
  {
    "name": "HONOR NTN-LX1",
    "label": "HONOR NTN-LX1",
    "value": "HONOR NTN-LX1"
  },
  {
    "name": "motorola moto e30",
    "label": "motorola moto e30",
    "value": "motorola moto e30"
  },
  {
    "name": "OUKITEL C25",
    "label": "OUKITEL C25",
    "value": "OUKITEL C25"
  },
  {
    "name": "Sony G8142",
    "label": "Sony G8142",
    "value": "Sony G8142"
  },
  {
    "name": "CUBOT X19",
    "label": "CUBOT X19",
    "value": "CUBOT X19"
  },
  {
    "name": "samsung SM-A705W",
    "label": "samsung SM-A705W",
    "value": "samsung SM-A705W"
  },
  {
    "name": "ADVAN 6201",
    "label": "ADVAN 6201",
    "value": "ADVAN 6201"
  },
  {
    "name": "motorola Moto Z (2)",
    "label": "motorola Moto Z (2)",
    "value": "motorola Moto Z (2)"
  },
  {
    "name": "ZTE Z999",
    "label": "ZTE Z999",
    "value": "ZTE Z999"
  },
  {
    "name": "samsung SM-N960U",
    "label": "samsung SM-N960U",
    "value": "samsung SM-N960U"
  },
  {
    "name": "TaiwanMobile Amazing_A55",
    "label": "TaiwanMobile Amazing_A55",
    "value": "TaiwanMobile Amazing_A55"
  },
  {
    "name": "HUAWEI AQM-LX1",
    "label": "HUAWEI AQM-LX1",
    "value": "HUAWEI AQM-LX1"
  },
  {
    "name": "Apple iPad8,12",
    "label": "Apple iPad8,12",
    "value": "Apple iPad8,12"
  },
  {
    "name": "itel itel S33",
    "label": "itel itel S33",
    "value": "itel itel S33"
  },
  {
    "name": "OPPO CPH2251",
    "label": "OPPO CPH2251",
    "value": "OPPO CPH2251"
  },
  {
    "name": "samsung SM-J120G",
    "label": "samsung SM-J120G",
    "value": "samsung SM-J120G"
  },
  {
    "name": "OPPO A83",
    "label": "OPPO A83",
    "value": "OPPO A83"
  },
  {
    "name": "ADVAN ADVANNASAPro",
    "label": "ADVAN ADVANNASAPro",
    "value": "ADVAN ADVANNASAPro"
  },
  {
    "name": "OnePlus IN2015",
    "label": "OnePlus IN2015",
    "value": "OnePlus IN2015"
  },
  {
    "name": "TCL T766H_EEA",
    "label": "TCL T766H_EEA",
    "value": "TCL T766H_EEA"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KG8",
    "label": "TECNO MOBILE LIMITED TECNO KG8",
    "value": "TECNO MOBILE LIMITED TECNO KG8"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X680F",
    "label": "INFINIX MOBILITY LIMITED Infinix X680F",
    "value": "INFINIX MOBILITY LIMITED Infinix X680F"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X687B",
    "label": "INFINIX MOBILITY LIMITED Infinix X687B",
    "value": "INFINIX MOBILITY LIMITED Infinix X687B"
  },
  {
    "name": "Sony 802SO",
    "label": "Sony 802SO",
    "value": "Sony 802SO"
  },
  {
    "name": "SHARP SH-S40P",
    "label": "SHARP SH-S40P",
    "value": "SHARP SH-S40P"
  },
  {
    "name": "Mobicel PULSE_1",
    "label": "Mobicel PULSE_1",
    "value": "Mobicel PULSE_1"
  },
  {
    "name": "LGE LM-Q610.FG",
    "label": "LGE LM-Q610.FG",
    "value": "LGE LM-Q610.FG"
  },
  {
    "name": "TCL 5002M",
    "label": "TCL 5002M",
    "value": "TCL 5002M"
  },
  {
    "name": "samsung SM-G935L",
    "label": "samsung SM-G935L",
    "value": "samsung SM-G935L"
  },
  {
    "name": "Xiaomi Redmi Y1 Lite",
    "label": "Xiaomi Redmi Y1 Lite",
    "value": "Xiaomi Redmi Y1 Lite"
  },
  {
    "name": "LENOVO Lenovo TB-7305I",
    "label": "LENOVO Lenovo TB-7305I",
    "value": "LENOVO Lenovo TB-7305I"
  },
  {
    "name": "Hisense Hisense U965",
    "label": "Hisense Hisense U965",
    "value": "Hisense Hisense U965"
  },
  {
    "name": "sprd TPS_S10_7731E",
    "label": "sprd TPS_S10_7731E",
    "value": "sprd TPS_S10_7731E"
  },
  {
    "name": "ZTE ZTE A2022PG",
    "label": "ZTE ZTE A2022PG",
    "value": "ZTE ZTE A2022PG"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KG7",
    "label": "TECNO MOBILE LIMITED TECNO KG7",
    "value": "TECNO MOBILE LIMITED TECNO KG7"
  },
  {
    "name": "Mobicel R6 PLUS",
    "label": "Mobicel R6 PLUS",
    "value": "Mobicel R6 PLUS"
  },
  {
    "name": "samsung SM-J327V",
    "label": "samsung SM-J327V",
    "value": "samsung SM-J327V"
  },
  {
    "name": "Philco PCS01",
    "label": "Philco PCS01",
    "value": "Philco PCS01"
  },
  {
    "name": "Neo's iPad Pro iPad",
    "label": "Neo's iPad Pro iPad",
    "value": "Neo's iPad Pro iPad"
  },
  {
    "name": "blackshark SHARK PRS-H0",
    "label": "blackshark SHARK PRS-H0",
    "value": "blackshark SHARK PRS-H0"
  },
  {
    "name": "ZTE Z6252CA",
    "label": "ZTE Z6252CA",
    "value": "ZTE Z6252CA"
  },
  {
    "name": "Mobile Web - BlackBerry",
    "label": "Mobile Web - BlackBerry",
    "value": "Mobile Web - BlackBerry"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X624",
    "label": "INFINIX MOBILITY LIMITED Infinix X624",
    "value": "INFINIX MOBILITY LIMITED Infinix X624"
  },
  {
    "name": "Amazon KFTRWI",
    "label": "Amazon KFTRWI",
    "value": "Amazon KFTRWI"
  },
  {
    "name": "Ulefone Armor X5 Pro",
    "label": "Ulefone Armor X5 Pro",
    "value": "Ulefone Armor X5 Pro"
  },
  {
    "name": "Apple Andrea’s iPhone",
    "label": "Apple Andrea’s iPhone",
    "value": "Apple Andrea’s iPhone"
  },
  {
    "name": "samsung SM-G970U1",
    "label": "samsung SM-G970U1",
    "value": "samsung SM-G970U1"
  },
  {
    "name": "asus Zenfone Max Pro M1",
    "label": "asus Zenfone Max Pro M1",
    "value": "asus Zenfone Max Pro M1"
  },
  {
    "name": "samsung SM-G930T",
    "label": "samsung SM-G930T",
    "value": "samsung SM-G930T"
  },
  {
    "name": "Apple iPad14,5",
    "label": "Apple iPad14,5",
    "value": "Apple iPad14,5"
  },
  {
    "name": "ZTE Z965",
    "label": "ZTE Z965",
    "value": "ZTE Z965"
  },
  {
    "name": "LGE LGM-V300S",
    "label": "LGE LGM-V300S",
    "value": "LGE LGM-V300S"
  },
  {
    "name": "motorola Lenovo K13 Note",
    "label": "motorola Lenovo K13 Note",
    "value": "motorola Lenovo K13 Note"
  },
  {
    "name": "samsung SM-G973W",
    "label": "samsung SM-G973W",
    "value": "samsung SM-G973W"
  },
  {
    "name": "samsung SM-A426U1",
    "label": "samsung SM-A426U1",
    "value": "samsung SM-A426U1"
  },
  {
    "name": "TCL T602DL",
    "label": "TCL T602DL",
    "value": "TCL T602DL"
  },
  {
    "name": "BLU M8L 2022",
    "label": "BLU M8L 2022",
    "value": "BLU M8L 2022"
  },
  {
    "name": "motorola moto e22i",
    "label": "motorola moto e22i",
    "value": "motorola moto e22i"
  },
  {
    "name": "LGE LM-X625N",
    "label": "LGE LM-X625N",
    "value": "LGE LM-X625N"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X612",
    "label": "INFINIX MOBILITY LIMITED Infinix X612",
    "value": "INFINIX MOBILITY LIMITED Infinix X612"
  },
  {
    "name": "samsung SM-T385L",
    "label": "samsung SM-T385L",
    "value": "samsung SM-T385L"
  },
  {
    "name": "samsung SM-G930U",
    "label": "samsung SM-G930U",
    "value": "samsung SM-G930U"
  },
  {
    "name": "incar RC7T3G21",
    "label": "incar RC7T3G21",
    "value": "incar RC7T3G21"
  },
  {
    "name": "Xiaomi 220733SL",
    "label": "Xiaomi 220733SL",
    "value": "Xiaomi 220733SL"
  },
  {
    "name": "vsmart V341U",
    "label": "vsmart V341U",
    "value": "vsmart V341U"
  },
  {
    "name": "samsung SM-S901U",
    "label": "samsung SM-S901U",
    "value": "samsung SM-S901U"
  },
  {
    "name": "HUAWEI LIO-L29",
    "label": "HUAWEI LIO-L29",
    "value": "HUAWEI LIO-L29"
  },
  {
    "name": "MAXFONE MF2",
    "label": "MAXFONE MF2",
    "value": "MAXFONE MF2"
  },
  {
    "name": "motorola motorola edge (2021)",
    "label": "motorola motorola edge (2021)",
    "value": "motorola motorola edge (2021)"
  },
  {
    "name": "LENOVO Lenovo TB-8505FS",
    "label": "LENOVO Lenovo TB-8505FS",
    "value": "LENOVO Lenovo TB-8505FS"
  },
  {
    "name": "CHAINWAY c72e",
    "label": "CHAINWAY c72e",
    "value": "CHAINWAY c72e"
  },
  {
    "name": "vsmart Star 4",
    "label": "vsmart Star 4",
    "value": "vsmart Star 4"
  },
  {
    "name": "vivo V2104",
    "label": "vivo V2104",
    "value": "vivo V2104"
  },
  {
    "name": "samsung SM-T377P",
    "label": "samsung SM-T377P",
    "value": "samsung SM-T377P"
  },
  {
    "name": "vivo vivo 2006",
    "label": "vivo vivo 2006",
    "value": "vivo vivo 2006"
  },
  {
    "name": "true S1",
    "label": "true S1",
    "value": "true S1"
  },
  {
    "name": "OPPO CPH2099",
    "label": "OPPO CPH2099",
    "value": "OPPO CPH2099"
  },
  {
    "name": "Google Pixel 3a",
    "label": "Google Pixel 3a",
    "value": "Google Pixel 3a"
  },
  {
    "name": "onn 100003562",
    "label": "onn 100003562",
    "value": "onn 100003562"
  },
  {
    "name": "Multilaser MS50X",
    "label": "Multilaser MS50X",
    "value": "Multilaser MS50X"
  },
  {
    "name": "vivo V2201",
    "label": "vivo V2201",
    "value": "vivo V2201"
  },
  {
    "name": "Wingtech WTCELERO5G",
    "label": "Wingtech WTCELERO5G",
    "value": "Wingtech WTCELERO5G"
  },
  {
    "name": "google G011A",
    "label": "google G011A",
    "value": "google G011A"
  },
  {
    "name": "itel itel W5002",
    "label": "itel itel W5002",
    "value": "itel itel W5002"
  },
  {
    "name": "TCL 5001J",
    "label": "TCL 5001J",
    "value": "TCL 5001J"
  },
  {
    "name": "Xiaomi 2112123AG",
    "label": "Xiaomi 2112123AG",
    "value": "Xiaomi 2112123AG"
  },
  {
    "name": "samsung SM-A750N",
    "label": "samsung SM-A750N",
    "value": "samsung SM-A750N"
  },
  {
    "name": "Mobicel Neo LTE",
    "label": "Mobicel Neo LTE",
    "value": "Mobicel Neo LTE"
  },
  {
    "name": "Micromax IN_Note2",
    "label": "Micromax IN_Note2",
    "value": "Micromax IN_Note2"
  },
  {
    "name": "TCL 5102L",
    "label": "TCL 5102L",
    "value": "TCL 5102L"
  },
  {
    "name": "BLU M6 2021",
    "label": "BLU M6 2021",
    "value": "BLU M6 2021"
  },
  {
    "name": "Fortune Ship Wildfire E Lite",
    "label": "Fortune Ship Wildfire E Lite",
    "value": "Fortune Ship Wildfire E Lite"
  },
  {
    "name": "LENOVO Lenovo TB-8705X",
    "label": "LENOVO Lenovo TB-8705X",
    "value": "LENOVO Lenovo TB-8705X"
  },
  {
    "name": "HMD Global Nokia 5.1",
    "label": "HMD Global Nokia 5.1",
    "value": "HMD Global Nokia 5.1"
  },
  {
    "name": "motorola motorola edge 5G UW (2021)",
    "label": "motorola motorola edge 5G UW (2021)",
    "value": "motorola motorola edge 5G UW (2021)"
  },
  {
    "name": "Sony SOV41",
    "label": "Sony SOV41",
    "value": "Sony SOV41"
  },
  {
    "name": "itel itel A661WP",
    "label": "itel itel A661WP",
    "value": "itel itel A661WP"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KD6a",
    "label": "TECNO MOBILE LIMITED TECNO KD6a",
    "value": "TECNO MOBILE LIMITED TECNO KD6a"
  },
  {
    "name": "SHARP SHV44",
    "label": "SHARP SHV44",
    "value": "SHARP SHV44"
  },
  {
    "name": "Sky_Devices Elite C5",
    "label": "Sky_Devices Elite C5",
    "value": "Sky_Devices Elite C5"
  },
  {
    "name": "ZTE P650 2021",
    "label": "ZTE P650 2021",
    "value": "ZTE P650 2021"
  },
  {
    "name": "motorola motorola edge 30 pro",
    "label": "motorola motorola edge 30 pro",
    "value": "motorola motorola edge 30 pro"
  },
  {
    "name": "Symphony Z40",
    "label": "Symphony Z40",
    "value": "Symphony Z40"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X612B",
    "label": "INFINIX MOBILITY LIMITED Infinix X612B",
    "value": "INFINIX MOBILITY LIMITED Infinix X612B"
  },
  {
    "name": "YEZZ MAX2",
    "label": "YEZZ MAX2",
    "value": "YEZZ MAX2"
  },
  {
    "name": "itel itel L5503",
    "label": "itel itel L5503",
    "value": "itel itel L5503"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X626",
    "label": "INFINIX MOBILITY LIMITED Infinix X626",
    "value": "INFINIX MOBILITY LIMITED Infinix X626"
  },
  {
    "name": "BLU S91",
    "label": "BLU S91",
    "value": "BLU S91"
  },
  {
    "name": "motorola moto g(10) power",
    "label": "motorola moto g(10) power",
    "value": "motorola moto g(10) power"
  },
  {
    "name": "Hisense Hisense U50 Lite",
    "label": "Hisense Hisense U50 Lite",
    "value": "Hisense Hisense U50 Lite"
  },
  {
    "name": "HUAWEI MOA-LX9N",
    "label": "HUAWEI MOA-LX9N",
    "value": "HUAWEI MOA-LX9N"
  },
  {
    "name": "Hisense HLTE103E",
    "label": "Hisense HLTE103E",
    "value": "Hisense HLTE103E"
  },
  {
    "name": "LGE LGL455DL",
    "label": "LGE LGL455DL",
    "value": "LGE LGL455DL"
  },
  {
    "name": "samsung SM-G892A",
    "label": "samsung SM-G892A",
    "value": "samsung SM-G892A"
  },
  {
    "name": "OnePlus HD1907",
    "label": "OnePlus HD1907",
    "value": "OnePlus HD1907"
  },
  {
    "name": "Revoview S5506",
    "label": "Revoview S5506",
    "value": "Revoview S5506"
  },
  {
    "name": "itel itel P13",
    "label": "itel itel P13",
    "value": "itel itel P13"
  },
  {
    "name": "Google Pixel 4a (5G)",
    "label": "Google Pixel 4a (5G)",
    "value": "Google Pixel 4a (5G)"
  },
  {
    "name": "Hisense Hisense F25",
    "label": "Hisense Hisense F25",
    "value": "Hisense Hisense F25"
  },
  {
    "name": "realme RMX2194",
    "label": "realme RMX2194",
    "value": "realme RMX2194"
  },
  {
    "name": "Xiaomi 22021211RC",
    "label": "Xiaomi 22021211RC",
    "value": "Xiaomi 22021211RC"
  },
  {
    "name": "Symphony Z30",
    "label": "Symphony Z30",
    "value": "Symphony Z30"
  },
  {
    "name": "LGE LM-Q710.FG",
    "label": "LGE LM-Q710.FG",
    "value": "LGE LM-Q710.FG"
  },
  {
    "name": "HUAWEI SCM-AL09",
    "label": "HUAWEI SCM-AL09",
    "value": "HUAWEI SCM-AL09"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X573",
    "label": "INFINIX MOBILITY LIMITED Infinix X573",
    "value": "INFINIX MOBILITY LIMITED Infinix X573"
  },
  {
    "name": "OPPO RMX1809",
    "label": "OPPO RMX1809",
    "value": "OPPO RMX1809"
  },
  {
    "name": "Cherry_Mobile Aqua Tab Ultra",
    "label": "Cherry_Mobile Aqua Tab Ultra",
    "value": "Cherry_Mobile Aqua Tab Ultra"
  },
  {
    "name": "realme RMX3630",
    "label": "realme RMX3630",
    "value": "realme RMX3630"
  },
  {
    "name": "realme RMX2021",
    "label": "realme RMX2021",
    "value": "realme RMX2021"
  },
  {
    "name": "OPPO CPH2223",
    "label": "OPPO CPH2223",
    "value": "OPPO CPH2223"
  },
  {
    "name": "wheatek Cyber 8",
    "label": "wheatek Cyber 8",
    "value": "wheatek Cyber 8"
  },
  {
    "name": "FIH EA1002",
    "label": "FIH EA1002",
    "value": "FIH EA1002"
  },
  {
    "name": "Xiaomi 22021211RI",
    "label": "Xiaomi 22021211RI",
    "value": "Xiaomi 22021211RI"
  },
  {
    "name": "TCL T790W",
    "label": "TCL T790W",
    "value": "TCL T790W"
  },
  {
    "name": "LGE LM-Q630N",
    "label": "LGE LM-Q630N",
    "value": "LGE LM-Q630N"
  },
  {
    "name": "samsung SM-M017F",
    "label": "samsung SM-M017F",
    "value": "samsung SM-M017F"
  },
  {
    "name": "blackshark SHARK MBU-H0",
    "label": "blackshark SHARK MBU-H0",
    "value": "blackshark SHARK MBU-H0"
  },
  {
    "name": "OnePlus GM1917",
    "label": "OnePlus GM1917",
    "value": "OnePlus GM1917"
  },
  {
    "name": "HUAWEI HUAWEI NXT-AL10",
    "label": "HUAWEI HUAWEI NXT-AL10",
    "value": "HUAWEI HUAWEI NXT-AL10"
  },
  {
    "name": "Xiaomi 2211133C",
    "label": "Xiaomi 2211133C",
    "value": "Xiaomi 2211133C"
  },
  {
    "name": "HUAWEI FRL-L23",
    "label": "HUAWEI FRL-L23",
    "value": "HUAWEI FRL-L23"
  },
  {
    "name": "iPad13,1 iPad",
    "label": "iPad13,1 iPad",
    "value": "iPad13,1 iPad"
  },
  {
    "name": "itel itel L5006S",
    "label": "itel itel L5006S",
    "value": "itel itel L5006S"
  },
  {
    "name": "samsung SM-A125N",
    "label": "samsung SM-A125N",
    "value": "samsung SM-A125N"
  },
  {
    "name": "ZTE ZTE A71 5G",
    "label": "ZTE ZTE A71 5G",
    "value": "ZTE ZTE A71 5G"
  },
  {
    "name": "SHARP 706SH",
    "label": "SHARP 706SH",
    "value": "SHARP 706SH"
  },
  {
    "name": "samsung SM-A426U",
    "label": "samsung SM-A426U",
    "value": "samsung SM-A426U"
  },
  {
    "name": "OPPO CPH1905",
    "label": "OPPO CPH1905",
    "value": "OPPO CPH1905"
  },
  {
    "name": "Sony G8441",
    "label": "Sony G8441",
    "value": "Sony G8441"
  },
  {
    "name": "motorola moto g play (XT2093DL)",
    "label": "motorola moto g play (XT2093DL)",
    "value": "motorola moto g play (XT2093DL)"
  },
  {
    "name": "OnePlus GM1910",
    "label": "OnePlus GM1910",
    "value": "OnePlus GM1910"
  },
  {
    "name": "realme RMX2205",
    "label": "realme RMX2205",
    "value": "realme RMX2205"
  },
  {
    "name": "Desktop - FreeBSD",
    "label": "Desktop - FreeBSD",
    "value": "Desktop - FreeBSD"
  },
  {
    "name": "OPPO CPH1707",
    "label": "OPPO CPH1707",
    "value": "OPPO CPH1707"
  },
  {
    "name": "samsung SM-G950W",
    "label": "samsung SM-G950W",
    "value": "samsung SM-G950W"
  },
  {
    "name": "Google Pixel 4",
    "label": "Google Pixel 4",
    "value": "Google Pixel 4"
  },
  {
    "name": "samsung SM-G781N",
    "label": "samsung SM-G781N",
    "value": "samsung SM-G781N"
  },
  {
    "name": "samsung SM-M205FN",
    "label": "samsung SM-M205FN",
    "value": "samsung SM-M205FN"
  },
  {
    "name": "Foxx Development Inc. T8Pro",
    "label": "Foxx Development Inc. T8Pro",
    "value": "Foxx Development Inc. T8Pro"
  },
  {
    "name": "HUAWEI BLA-L09",
    "label": "HUAWEI BLA-L09",
    "value": "HUAWEI BLA-L09"
  },
  {
    "name": "Positivo Twist 3",
    "label": "Positivo Twist 3",
    "value": "Positivo Twist 3"
  },
  {
    "name": "itel itel A661L",
    "label": "itel itel A661L",
    "value": "itel itel A661L"
  },
  {
    "name": "OnePlus HD1913",
    "label": "OnePlus HD1913",
    "value": "OnePlus HD1913"
  },
  {
    "name": "Apple iPad12,2",
    "label": "Apple iPad12,2",
    "value": "Apple iPad12,2"
  },
  {
    "name": "vivo vivo 1921",
    "label": "vivo vivo 1921",
    "value": "vivo vivo 1921"
  },
  {
    "name": "iPad de Federico iPad",
    "label": "iPad de Federico iPad",
    "value": "iPad de Federico iPad"
  },
  {
    "name": "Multilaser M7_3G_PLUS",
    "label": "Multilaser M7_3G_PLUS",
    "value": "Multilaser M7_3G_PLUS"
  },
  {
    "name": "YEZZ GO2",
    "label": "YEZZ GO2",
    "value": "YEZZ GO2"
  },
  {
    "name": "Maxwest Nitro 8",
    "label": "Maxwest Nitro 8",
    "value": "Maxwest Nitro 8"
  },
  {
    "name": "coolpad Coolpad COR-I0",
    "label": "coolpad Coolpad COR-I0",
    "value": "coolpad Coolpad COR-I0"
  },
  {
    "name": "STYLO DQR22",
    "label": "STYLO DQR22",
    "value": "STYLO DQR22"
  },
  {
    "name": "OPPO CPH2081",
    "label": "OPPO CPH2081",
    "value": "OPPO CPH2081"
  },
  {
    "name": "OPPO CPH1879",
    "label": "OPPO CPH1879",
    "value": "OPPO CPH1879"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X659B",
    "label": "INFINIX MOBILITY LIMITED Infinix X659B",
    "value": "INFINIX MOBILITY LIMITED Infinix X659B"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO B1",
    "label": "TECNO MOBILE LIMITED TECNO B1",
    "value": "TECNO MOBILE LIMITED TECNO B1"
  },
  {
    "name": "DOOGEE S61Pro",
    "label": "DOOGEE S61Pro",
    "value": "DOOGEE S61Pro"
  },
  {
    "name": "realme RMX3286",
    "label": "realme RMX3286",
    "value": "realme RMX3286"
  },
  {
    "name": "LGE LGM-V300K",
    "label": "LGE LGM-V300K",
    "value": "LGE LGM-V300K"
  },
  {
    "name": "Lenovo Lenovo L38043",
    "label": "Lenovo Lenovo L38043",
    "value": "Lenovo Lenovo L38043"
  },
  {
    "name": "OPPO CPH1943",
    "label": "OPPO CPH1943",
    "value": "OPPO CPH1943"
  },
  {
    "name": "HUAWEI DUA-LX3",
    "label": "HUAWEI DUA-LX3",
    "value": "HUAWEI DUA-LX3"
  },
  {
    "name": "SUGAR SUGAR C60",
    "label": "SUGAR SUGAR C60",
    "value": "SUGAR SUGAR C60"
  },
  {
    "name": "Mintt ULTRAMINTT X3",
    "label": "Mintt ULTRAMINTT X3",
    "value": "Mintt ULTRAMINTT X3"
  },
  {
    "name": "Vodafone VFD 510",
    "label": "Vodafone VFD 510",
    "value": "Vodafone VFD 510"
  },
  {
    "name": "Google PIXEL 2 XL",
    "label": "Google PIXEL 2 XL",
    "value": "Google PIXEL 2 XL"
  },
  {
    "name": "ATVIO MID7015",
    "label": "ATVIO MID7015",
    "value": "ATVIO MID7015"
  },
  {
    "name": "PANASONIC Panasonic P85 NXT",
    "label": "PANASONIC Panasonic P85 NXT",
    "value": "PANASONIC Panasonic P85 NXT"
  },
  {
    "name": "wheatek WP9",
    "label": "wheatek WP9",
    "value": "wheatek WP9"
  },
  {
    "name": "OPPO CPH2249",
    "label": "OPPO CPH2249",
    "value": "OPPO CPH2249"
  },
  {
    "name": "asus ASUS_I003DD",
    "label": "asus ASUS_I003DD",
    "value": "asus ASUS_I003DD"
  },
  {
    "name": "Meizu meizu M8",
    "label": "Meizu meizu M8",
    "value": "Meizu meizu M8"
  },
  {
    "name": "ZTE Z982",
    "label": "ZTE Z982",
    "value": "ZTE Z982"
  },
  {
    "name": "ITEL MOBILE LIMITED itel L6006F",
    "label": "ITEL MOBILE LIMITED itel L6006F",
    "value": "ITEL MOBILE LIMITED itel L6006F"
  },
  {
    "name": "Unihertz Titan pocket",
    "label": "Unihertz Titan pocket",
    "value": "Unihertz Titan pocket"
  },
  {
    "name": "Apple iPad7,1",
    "label": "Apple iPad7,1",
    "value": "Apple iPad7,1"
  },
  {
    "name": "asus ASUS_Z01HDA",
    "label": "asus ASUS_Z01HDA",
    "value": "asus ASUS_Z01HDA"
  },
  {
    "name": "LAVA LAVA LE9820",
    "label": "LAVA LAVA LE9820",
    "value": "LAVA LAVA LE9820"
  },
  {
    "name": "ZTE ZTE Blade A31",
    "label": "ZTE ZTE Blade A31",
    "value": "ZTE ZTE Blade A31"
  },
  {
    "name": "A1 A1 Alpha",
    "label": "A1 A1 Alpha",
    "value": "A1 A1 Alpha"
  },
  {
    "name": "worldchip HT-705XS",
    "label": "worldchip HT-705XS",
    "value": "worldchip HT-705XS"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KF6n",
    "label": "TECNO MOBILE LIMITED TECNO KF6n",
    "value": "TECNO MOBILE LIMITED TECNO KF6n"
  },
  {
    "name": "HYUNDAI E504",
    "label": "HYUNDAI E504",
    "value": "HYUNDAI E504"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A23",
    "label": "ITEL MOBILE LIMITED itel A23",
    "value": "ITEL MOBILE LIMITED itel A23"
  },
  {
    "name": "MobiWire Element 4 Plus",
    "label": "MobiWire Element 4 Plus",
    "value": "MobiWire Element 4 Plus"
  },
  {
    "name": "General Mobile G301",
    "label": "General Mobile G301",
    "value": "General Mobile G301"
  },
  {
    "name": "HUAWEI BAC-L23",
    "label": "HUAWEI BAC-L23",
    "value": "HUAWEI BAC-L23"
  },
  {
    "name": "HUAWEI LDN-LX3",
    "label": "HUAWEI LDN-LX3",
    "value": "HUAWEI LDN-LX3"
  },
  {
    "name": "realme RMX2050",
    "label": "realme RMX2050",
    "value": "realme RMX2050"
  },
  {
    "name": "MIO NANOTECH M1000Q",
    "label": "MIO NANOTECH M1000Q",
    "value": "MIO NANOTECH M1000Q"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BD2d",
    "label": "TECNO MOBILE LIMITED TECNO BD2d",
    "value": "TECNO MOBILE LIMITED TECNO BD2d"
  },
  {
    "name": "Hisense Hisense Infinity H30",
    "label": "Hisense Hisense Infinity H30",
    "value": "Hisense Hisense Infinity H30"
  },
  {
    "name": "Multilaser Multilaser_G_2",
    "label": "Multilaser Multilaser_G_2",
    "value": "Multilaser Multilaser_G_2"
  },
  {
    "name": "Realme RMX1831",
    "label": "Realme RMX1831",
    "value": "Realme RMX1831"
  },
  {
    "name": "realme RMX2063",
    "label": "realme RMX2063",
    "value": "realme RMX2063"
  },
  {
    "name": "asus ASUS_X00LDA",
    "label": "asus ASUS_X00LDA",
    "value": "asus ASUS_X00LDA"
  },
  {
    "name": "HUAWEI FRL-L22",
    "label": "HUAWEI FRL-L22",
    "value": "HUAWEI FRL-L22"
  },
  {
    "name": "Apple iPad11,7",
    "label": "Apple iPad11,7",
    "value": "Apple iPad11,7"
  },
  {
    "name": "OPPO PCEM00",
    "label": "OPPO PCEM00",
    "value": "OPPO PCEM00"
  },
  {
    "name": "ZTE ZTE A7020",
    "label": "ZTE ZTE A7020",
    "value": "ZTE ZTE A7020"
  },
  {
    "name": "Fairphone FP3",
    "label": "Fairphone FP3",
    "value": "Fairphone FP3"
  },
  {
    "name": "LENOVO Lenovo TB-X505F",
    "label": "LENOVO Lenovo TB-X505F",
    "value": "LENOVO Lenovo TB-X505F"
  },
  {
    "name": "OPPO CPH1919",
    "label": "OPPO CPH1919",
    "value": "OPPO CPH1919"
  },
  {
    "name": "LGE LM-V600",
    "label": "LGE LM-V600",
    "value": "LGE LM-V600"
  },
  {
    "name": "realme RMX3203",
    "label": "realme RMX3203",
    "value": "realme RMX3203"
  },
  {
    "name": "samsung SM-A115A",
    "label": "samsung SM-A115A",
    "value": "samsung SM-A115A"
  },
  {
    "name": "Haier M53-52401",
    "label": "Haier M53-52401",
    "value": "Haier M53-52401"
  },
  {
    "name": "itel itel S13",
    "label": "itel itel S13",
    "value": "itel itel S13"
  },
  {
    "name": "HUAWEI STF-L09",
    "label": "HUAWEI STF-L09",
    "value": "HUAWEI STF-L09"
  },
  {
    "name": "ITEL MOBILE LIMITED itel W4002",
    "label": "ITEL MOBILE LIMITED itel W4002",
    "value": "ITEL MOBILE LIMITED itel W4002"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LC7S",
    "label": "TECNO MOBILE LIMITED TECNO LC7S",
    "value": "TECNO MOBILE LIMITED TECNO LC7S"
  },
  {
    "name": "ZUK ZUK Z1",
    "label": "ZUK ZUK Z1",
    "value": "ZUK ZUK Z1"
  },
  {
    "name": "HONOR CMA-LX1",
    "label": "HONOR CMA-LX1",
    "value": "HONOR CMA-LX1"
  },
  {
    "name": "Multilaser M9_Wifi",
    "label": "Multilaser M9_Wifi",
    "value": "Multilaser M9_Wifi"
  },
  {
    "name": "Vodafone VFD 720",
    "label": "Vodafone VFD 720",
    "value": "Vodafone VFD 720"
  },
  {
    "name": "Kalley Silver Max Pro",
    "label": "Kalley Silver Max Pro",
    "value": "Kalley Silver Max Pro"
  },
  {
    "name": "OnePlus BE2028",
    "label": "OnePlus BE2028",
    "value": "OnePlus BE2028"
  },
  {
    "name": "LAVA LH9920",
    "label": "LAVA LH9920",
    "value": "LAVA LH9920"
  },
  {
    "name": "HUAWEI DRA-L01",
    "label": "HUAWEI DRA-L01",
    "value": "HUAWEI DRA-L01"
  },
  {
    "name": "LGE LM-X210APM",
    "label": "LGE LM-X210APM",
    "value": "LGE LM-X210APM"
  },
  {
    "name": "Xiaomi 21121210G",
    "label": "Xiaomi 21121210G",
    "value": "Xiaomi 21121210G"
  },
  {
    "name": "YEZZ MAX2 PLUS",
    "label": "YEZZ MAX2 PLUS",
    "value": "YEZZ MAX2 PLUS"
  },
  {
    "name": "Ruio A870",
    "label": "Ruio A870",
    "value": "Ruio A870"
  },
  {
    "name": "realme RMX3623",
    "label": "realme RMX3623",
    "value": "realme RMX3623"
  },
  {
    "name": "ZTE ZTE 8010",
    "label": "ZTE ZTE 8010",
    "value": "ZTE ZTE 8010"
  },
  {
    "name": "incar Tab_P8",
    "label": "incar Tab_P8",
    "value": "incar Tab_P8"
  },
  {
    "name": "HUAWEI NEN-LX1",
    "label": "HUAWEI NEN-LX1",
    "value": "HUAWEI NEN-LX1"
  },
  {
    "name": "samsung SM-J737T1",
    "label": "samsung SM-J737T1",
    "value": "samsung SM-J737T1"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X620B",
    "label": "INFINIX MOBILITY LIMITED Infinix X620B",
    "value": "INFINIX MOBILITY LIMITED Infinix X620B"
  },
  {
    "name": "TCL 4187D",
    "label": "TCL 4187D",
    "value": "TCL 4187D"
  },
  {
    "name": "HYUNDAI L622",
    "label": "HYUNDAI L622",
    "value": "HYUNDAI L622"
  },
  {
    "name": "samsung SC-42A",
    "label": "samsung SC-42A",
    "value": "samsung SC-42A"
  },
  {
    "name": "LGE LM-X220",
    "label": "LGE LM-X220",
    "value": "LGE LM-X220"
  },
  {
    "name": "TCL 5030A",
    "label": "TCL 5030A",
    "value": "TCL 5030A"
  },
  {
    "name": "TCL 5130J",
    "label": "TCL 5130J",
    "value": "TCL 5130J"
  },
  {
    "name": "LGE LG-LS997",
    "label": "LGE LG-LS997",
    "value": "LGE LG-LS997"
  },
  {
    "name": "vivo vivo 1908",
    "label": "vivo vivo 1908",
    "value": "vivo vivo 1908"
  },
  {
    "name": "TCL 9032X",
    "label": "TCL 9032X",
    "value": "TCL 9032X"
  },
  {
    "name": "HUAWEI MGA-LX9",
    "label": "HUAWEI MGA-LX9",
    "value": "HUAWEI MGA-LX9"
  },
  {
    "name": "HONOR NTN-LX3",
    "label": "HONOR NTN-LX3",
    "value": "HONOR NTN-LX3"
  },
  {
    "name": "Xiaomi 22031116BG",
    "label": "Xiaomi 22031116BG",
    "value": "Xiaomi 22031116BG"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CG8h",
    "label": "TECNO MOBILE LIMITED TECNO CG8h",
    "value": "TECNO MOBILE LIMITED TECNO CG8h"
  },
  {
    "name": "TECNO TECNO P703",
    "label": "TECNO TECNO P703",
    "value": "TECNO TECNO P703"
  },
  {
    "name": "itel itel W5005",
    "label": "itel itel W5005",
    "value": "itel itel W5005"
  },
  {
    "name": "samsung SM-S215DL",
    "label": "samsung SM-S215DL",
    "value": "samsung SM-S215DL"
  },
  {
    "name": "blackshark AWM-A0",
    "label": "blackshark AWM-A0",
    "value": "blackshark AWM-A0"
  },
  {
    "name": "HMD Global TA-1012",
    "label": "HMD Global TA-1012",
    "value": "HMD Global TA-1012"
  },
  {
    "name": "Mobicel EPIC_1",
    "label": "Mobicel EPIC_1",
    "value": "Mobicel EPIC_1"
  },
  {
    "name": "OPPO CPH2005",
    "label": "OPPO CPH2005",
    "value": "OPPO CPH2005"
  },
  {
    "name": "Maxwest Astro 55R",
    "label": "Maxwest Astro 55R",
    "value": "Maxwest Astro 55R"
  },
  {
    "name": "Mobile Web - undefined",
    "label": "Mobile Web - undefined",
    "value": "Mobile Web - undefined"
  },
  {
    "name": "samsung SM-G998W",
    "label": "samsung SM-G998W",
    "value": "samsung SM-G998W"
  },
  {
    "name": "samsung SM-T725",
    "label": "samsung SM-T725",
    "value": "samsung SM-T725"
  },
  {
    "name": "Alcatel 5059S",
    "label": "Alcatel 5059S",
    "value": "Alcatel 5059S"
  },
  {
    "name": "Sony F5321",
    "label": "Sony F5321",
    "value": "Sony F5321"
  },
  {
    "name": "Ulefone Note 9P",
    "label": "Ulefone Note 9P",
    "value": "Ulefone Note 9P"
  },
  {
    "name": "OPPO CPH1893",
    "label": "OPPO CPH1893",
    "value": "OPPO CPH1893"
  },
  {
    "name": "HMR Networks Boost",
    "label": "HMR Networks Boost",
    "value": "HMR Networks Boost"
  },
  {
    "name": "EL D68",
    "label": "EL D68",
    "value": "EL D68"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO SA7S",
    "label": "TECNO MOBILE LIMITED TECNO SA7S",
    "value": "TECNO MOBILE LIMITED TECNO SA7S"
  },
  {
    "name": "BLU C5 2019",
    "label": "BLU C5 2019",
    "value": "BLU C5 2019"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LA7 Pro",
    "label": "TECNO MOBILE LIMITED TECNO LA7 Pro",
    "value": "TECNO MOBILE LIMITED TECNO LA7 Pro"
  },
  {
    "name": "reeder P13 Blue MaxL 2022",
    "label": "reeder P13 Blue MaxL 2022",
    "value": "reeder P13 Blue MaxL 2022"
  },
  {
    "name": "Multilaser Multilaser_G",
    "label": "Multilaser Multilaser_G",
    "value": "Multilaser Multilaser_G"
  },
  {
    "name": "TCL 5002A",
    "label": "TCL 5002A",
    "value": "TCL 5002A"
  },
  {
    "name": "TCL 5102B",
    "label": "TCL 5102B",
    "value": "TCL 5102B"
  },
  {
    "name": "samsung SM-F700N",
    "label": "samsung SM-F700N",
    "value": "samsung SM-F700N"
  },
  {
    "name": "LENOVO Lenovo TB-8505F",
    "label": "LENOVO Lenovo TB-8505F",
    "value": "LENOVO Lenovo TB-8505F"
  },
  {
    "name": "Google Pixel 5",
    "label": "Google Pixel 5",
    "value": "Google Pixel 5"
  },
  {
    "name": "Blackview BV4900",
    "label": "Blackview BV4900",
    "value": "Blackview BV4900"
  },
  {
    "name": "TINNO Wiko U520AS",
    "label": "TINNO Wiko U520AS",
    "value": "TINNO Wiko U520AS"
  },
  {
    "name": "Mobicel BERRY 2",
    "label": "Mobicel BERRY 2",
    "value": "Mobicel BERRY 2"
  },
  {
    "name": "nubia NX679J",
    "label": "nubia NX679J",
    "value": "nubia NX679J"
  },
  {
    "name": "TCL T773O",
    "label": "TCL T773O",
    "value": "TCL T773O"
  },
  {
    "name": "CROSSCALL CORE-M4",
    "label": "CROSSCALL CORE-M4",
    "value": "CROSSCALL CORE-M4"
  },
  {
    "name": "Apple Manoka’s iPhone",
    "label": "Apple Manoka’s iPhone",
    "value": "Apple Manoka’s iPhone"
  },
  {
    "name": "Apple iPhone claire",
    "label": "Apple iPhone claire",
    "value": "Apple iPhone claire"
  },
  {
    "name": "ITEL MOBILE LIMITED itel L6006",
    "label": "ITEL MOBILE LIMITED itel L6006",
    "value": "ITEL MOBILE LIMITED itel L6006"
  },
  {
    "name": "samsung SM-G930S",
    "label": "samsung SM-G930S",
    "value": "samsung SM-G930S"
  },
  {
    "name": "alps k960_mt6580_32_p",
    "label": "alps k960_mt6580_32_p",
    "value": "alps k960_mt6580_32_p"
  },
  {
    "name": "asus ASUS_I006D",
    "label": "asus ASUS_I006D",
    "value": "asus ASUS_I006D"
  },
  {
    "name": "CROSSCALL Core-X3",
    "label": "CROSSCALL Core-X3",
    "value": "CROSSCALL Core-X3"
  },
  {
    "name": "Apple Chanel Kaitlyn’s iPhone",
    "label": "Apple Chanel Kaitlyn’s iPhone",
    "value": "Apple Chanel Kaitlyn’s iPhone"
  },
  {
    "name": "Xiaomi 22071212AG",
    "label": "Xiaomi 22071212AG",
    "value": "Xiaomi 22071212AG"
  },
  {
    "name": "Apple Thabo’s iPhone",
    "label": "Apple Thabo’s iPhone",
    "value": "Apple Thabo’s iPhone"
  },
  {
    "name": "Apple iPhone PV",
    "label": "Apple iPhone PV",
    "value": "Apple iPhone PV"
  },
  {
    "name": "Arch16’s iPad iPad",
    "label": "Arch16’s iPad iPad",
    "value": "Arch16’s iPad iPad"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KA7O",
    "label": "TECNO MOBILE LIMITED TECNO KA7O",
    "value": "TECNO MOBILE LIMITED TECNO KA7O"
  },
  {
    "name": "TCL T779W",
    "label": "TCL T779W",
    "value": "TCL T779W"
  },
  {
    "name": "vivo vivo 1912",
    "label": "vivo vivo 1912",
    "value": "vivo vivo 1912"
  },
  {
    "name": "Apple Lihle’s Iphone 12 Pro",
    "label": "Apple Lihle’s Iphone 12 Pro",
    "value": "Apple Lihle’s Iphone 12 Pro"
  },
  {
    "name": "HMD Global TA-1044",
    "label": "HMD Global TA-1044",
    "value": "HMD Global TA-1044"
  },
  {
    "name": "OnePlus ONEPLUS A6010",
    "label": "OnePlus ONEPLUS A6010",
    "value": "OnePlus ONEPLUS A6010"
  },
  {
    "name": "Mobicel ULTRA",
    "label": "Mobicel ULTRA",
    "value": "Mobicel ULTRA"
  },
  {
    "name": "SHARP SH-04L",
    "label": "SHARP SH-04L",
    "value": "SHARP SH-04L"
  },
  {
    "name": "HUAWEI MAR-LX3Am",
    "label": "HUAWEI MAR-LX3Am",
    "value": "HUAWEI MAR-LX3Am"
  },
  {
    "name": "samsung SM-F711U1",
    "label": "samsung SM-F711U1",
    "value": "samsung SM-F711U1"
  },
  {
    "name": "Sony H4331",
    "label": "Sony H4331",
    "value": "Sony H4331"
  },
  {
    "name": "vivo VIVO 1902",
    "label": "vivo VIVO 1902",
    "value": "vivo VIVO 1902"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6810",
    "label": "INFINIX MOBILITY LIMITED Infinix X6810",
    "value": "INFINIX MOBILITY LIMITED Infinix X6810"
  },
  {
    "name": "iPad6,11 iPad",
    "label": "iPad6,11 iPad",
    "value": "iPad6,11 iPad"
  },
  {
    "name": "Apple Reani’s iPhone",
    "label": "Apple Reani’s iPhone",
    "value": "Apple Reani’s iPhone"
  },
  {
    "name": "Apple Aypon12prumaks",
    "label": "Apple Aypon12prumaks",
    "value": "Apple Aypon12prumaks"
  },
  {
    "name": "Vodafone VFD320",
    "label": "Vodafone VFD320",
    "value": "Vodafone VFD320"
  },
  {
    "name": "Sony H4213",
    "label": "Sony H4213",
    "value": "Sony H4213"
  },
  {
    "name": "Mobicel X4",
    "label": "Mobicel X4",
    "value": "Mobicel X4"
  },
  {
    "name": "DOOGEE N30",
    "label": "DOOGEE N30",
    "value": "DOOGEE N30"
  },
  {
    "name": "samsung SM-F916N",
    "label": "samsung SM-F916N",
    "value": "samsung SM-F916N"
  },
  {
    "name": "samsung SM-A600GN",
    "label": "samsung SM-A600GN",
    "value": "samsung SM-A600GN"
  },
  {
    "name": "Apple Armando Iphone",
    "label": "Apple Armando Iphone",
    "value": "Apple Armando Iphone"
  },
  {
    "name": "samsung SM-G892U",
    "label": "samsung SM-G892U",
    "value": "samsung SM-G892U"
  },
  {
    "name": "realme RMX2081",
    "label": "realme RMX2081",
    "value": "realme RMX2081"
  },
  {
    "name": "YEZZ LIV 3 LTE",
    "label": "YEZZ LIV 3 LTE",
    "value": "YEZZ LIV 3 LTE"
  },
  {
    "name": "samsung SM-A515W",
    "label": "samsung SM-A515W",
    "value": "samsung SM-A515W"
  },
  {
    "name": "BLU T5 Plus",
    "label": "BLU T5 Plus",
    "value": "BLU T5 Plus"
  },
  {
    "name": "iPhone11,2 iPhone",
    "label": "iPhone11,2 iPhone",
    "value": "iPhone11,2 iPhone"
  },
  {
    "name": "Sony H3321",
    "label": "Sony H3321",
    "value": "Sony H3321"
  },
  {
    "name": "vivo V2152",
    "label": "vivo V2152",
    "value": "vivo V2152"
  },
  {
    "name": "wheatek WP19",
    "label": "wheatek WP19",
    "value": "wheatek WP19"
  },
  {
    "name": "OPPO PEYM00",
    "label": "OPPO PEYM00",
    "value": "OPPO PEYM00"
  },
  {
    "name": "BLU C5L 2020",
    "label": "BLU C5L 2020",
    "value": "BLU C5L 2020"
  },
  {
    "name": "HXY MP05",
    "label": "HXY MP05",
    "value": "HXY MP05"
  },
  {
    "name": "Ulefone Note 6P",
    "label": "Ulefone Note 6P",
    "value": "Ulefone Note 6P"
  },
  {
    "name": "BLU Studio X5",
    "label": "BLU Studio X5",
    "value": "BLU Studio X5"
  },
  {
    "name": "DOOGEE S40",
    "label": "DOOGEE S40",
    "value": "DOOGEE S40"
  },
  {
    "name": "Xiaomi Mi MIX 2",
    "label": "Xiaomi Mi MIX 2",
    "value": "Xiaomi Mi MIX 2"
  },
  {
    "name": "LGE LM-Q815L",
    "label": "LGE LM-Q815L",
    "value": "LGE LM-Q815L"
  },
  {
    "name": "Apple iPad8,3",
    "label": "Apple iPad8,3",
    "value": "Apple iPad8,3"
  },
  {
    "name": "TCL 9081X",
    "label": "TCL 9081X",
    "value": "TCL 9081X"
  },
  {
    "name": "samsung SM-G996U",
    "label": "samsung SM-G996U",
    "value": "samsung SM-G996U"
  },
  {
    "name": "HTC HTC_2Q4D100",
    "label": "HTC HTC_2Q4D100",
    "value": "HTC HTC_2Q4D100"
  },
  {
    "name": "SHARP SHV42",
    "label": "SHARP SHV42",
    "value": "SHARP SHV42"
  },
  {
    "name": "TCL 8167",
    "label": "TCL 8167",
    "value": "TCL 8167"
  },
  {
    "name": "ZTE A103ZT",
    "label": "ZTE A103ZT",
    "value": "ZTE A103ZT"
  },
  {
    "name": "BLU G80",
    "label": "BLU G80",
    "value": "BLU G80"
  },
  {
    "name": "vivo V1818CA",
    "label": "vivo V1818CA",
    "value": "vivo V1818CA"
  },
  {
    "name": "samsung SM-T561",
    "label": "samsung SM-T561",
    "value": "samsung SM-T561"
  },
  {
    "name": "OPPO CPH2473",
    "label": "OPPO CPH2473",
    "value": "OPPO CPH2473"
  },
  {
    "name": "motorola motorola edge 30 fusion",
    "label": "motorola motorola edge 30 fusion",
    "value": "motorola motorola edge 30 fusion"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BC1",
    "label": "TECNO MOBILE LIMITED TECNO BC1",
    "value": "TECNO MOBILE LIMITED TECNO BC1"
  },
  {
    "name": "LAVA LAVA Z3 UP",
    "label": "LAVA LAVA Z3 UP",
    "value": "LAVA LAVA Z3 UP"
  },
  {
    "name": "WIKO W-V850-EEA",
    "label": "WIKO W-V850-EEA",
    "value": "WIKO W-V850-EEA"
  },
  {
    "name": "GOME GOME 2017M27A",
    "label": "GOME GOME 2017M27A",
    "value": "GOME GOME 2017M27A"
  },
  {
    "name": "OUKITEL C21 Plus",
    "label": "OUKITEL C21 Plus",
    "value": "OUKITEL C21 Plus"
  },
  {
    "name": "TCL T770H",
    "label": "TCL T770H",
    "value": "TCL T770H"
  },
  {
    "name": "Ulefone Ulefone_Note 7",
    "label": "Ulefone Ulefone_Note 7",
    "value": "Ulefone Ulefone_Note 7"
  },
  {
    "name": "realme RMX3193",
    "label": "realme RMX3193",
    "value": "realme RMX3193"
  },
  {
    "name": "Sony H4311",
    "label": "Sony H4311",
    "value": "Sony H4311"
  },
  {
    "name": "Apple iPad13,8",
    "label": "Apple iPad13,8",
    "value": "Apple iPad13,8"
  },
  {
    "name": "vivo V2207",
    "label": "vivo V2207",
    "value": "vivo V2207"
  },
  {
    "name": "samsung SM-A236M",
    "label": "samsung SM-A236M",
    "value": "samsung SM-A236M"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO SA2S",
    "label": "TECNO MOBILE LIMITED TECNO SA2S",
    "value": "TECNO MOBILE LIMITED TECNO SA2S"
  },
  {
    "name": "samsung SM-A015A",
    "label": "samsung SM-A015A",
    "value": "samsung SM-A015A"
  },
  {
    "name": "samsung SCV40",
    "label": "samsung SCV40",
    "value": "samsung SCV40"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BB4",
    "label": "TECNO MOBILE LIMITED TECNO BB4",
    "value": "TECNO MOBILE LIMITED TECNO BB4"
  },
  {
    "name": "samsung SM-S136DL",
    "label": "samsung SM-S136DL",
    "value": "samsung SM-S136DL"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KB8",
    "label": "TECNO MOBILE LIMITED TECNO KB8",
    "value": "TECNO MOBILE LIMITED TECNO KB8"
  },
  {
    "name": "ELEVATE LUNA G5",
    "label": "ELEVATE LUNA G5",
    "value": "ELEVATE LUNA G5"
  },
  {
    "name": "INFINIX Infinix X6827",
    "label": "INFINIX Infinix X6827",
    "value": "INFINIX Infinix X6827"
  },
  {
    "name": "AGM AGM A9",
    "label": "AGM AGM A9",
    "value": "AGM AGM A9"
  },
  {
    "name": "motorola moto g stylus 5G (2022)",
    "label": "motorola moto g stylus 5G (2022)",
    "value": "motorola moto g stylus 5G (2022)"
  },
  {
    "name": "HUAWEI ANG-LX1",
    "label": "HUAWEI ANG-LX1",
    "value": "HUAWEI ANG-LX1"
  },
  {
    "name": "WIKO W_K300",
    "label": "WIKO W_K300",
    "value": "WIKO W_K300"
  },
  {
    "name": "ZTE Z5158",
    "label": "ZTE Z5158",
    "value": "ZTE Z5158"
  },
  {
    "name": "Hisense Hisense Infinity H12",
    "label": "Hisense Hisense Infinity H12",
    "value": "Hisense Hisense Infinity H12"
  },
  {
    "name": "incar La_Tab_74",
    "label": "incar La_Tab_74",
    "value": "incar La_Tab_74"
  },
  {
    "name": "Xiaomi 22081212UG",
    "label": "Xiaomi 22081212UG",
    "value": "Xiaomi 22081212UG"
  },
  {
    "name": "GIONEE GIONEE S10 lite",
    "label": "GIONEE GIONEE S10 lite",
    "value": "GIONEE GIONEE S10 lite"
  },
  {
    "name": "WIKO W-V830-EEA",
    "label": "WIKO W-V830-EEA",
    "value": "WIKO W-V830-EEA"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X610B",
    "label": "INFINIX MOBILITY LIMITED Infinix X610B",
    "value": "INFINIX MOBILITY LIMITED Infinix X610B"
  },
  {
    "name": "Samsung SM-G996B",
    "label": "Samsung SM-G996B",
    "value": "Samsung SM-G996B"
  },
  {
    "name": "TCL 6025H_EEA",
    "label": "TCL 6025H_EEA",
    "value": "TCL 6025H_EEA"
  },
  {
    "name": "Multilaser M10_4G_PRO",
    "label": "Multilaser M10_4G_PRO",
    "value": "Multilaser M10_4G_PRO"
  },
  {
    "name": "samsung SM-M022M",
    "label": "samsung SM-M022M",
    "value": "samsung SM-M022M"
  },
  {
    "name": "TCL T603DL",
    "label": "TCL T603DL",
    "value": "TCL T603DL"
  },
  {
    "name": "LGE LGL555DL",
    "label": "LGE LGL555DL",
    "value": "LGE LGL555DL"
  },
  {
    "name": "vivo I2126",
    "label": "vivo I2126",
    "value": "vivo I2126"
  },
  {
    "name": "samsung SM-A516V",
    "label": "samsung SM-A516V",
    "value": "samsung SM-A516V"
  },
  {
    "name": "TCL 5159J",
    "label": "TCL 5159J",
    "value": "TCL 5159J"
  },
  {
    "name": "Google Pixel 6a",
    "label": "Google Pixel 6a",
    "value": "Google Pixel 6a"
  },
  {
    "name": "vivo I2208",
    "label": "vivo I2208",
    "value": "vivo I2208"
  },
  {
    "name": "ITEL MOBILE LIMITED itel W5505",
    "label": "ITEL MOBILE LIMITED itel W5505",
    "value": "ITEL MOBILE LIMITED itel W5505"
  },
  {
    "name": "samsung SM-F721U",
    "label": "samsung SM-F721U",
    "value": "samsung SM-F721U"
  },
  {
    "name": "incar X7",
    "label": "incar X7",
    "value": "incar X7"
  },
  {
    "name": "reeder P13_Max_Blue",
    "label": "reeder P13_Max_Blue",
    "value": "reeder P13_Max_Blue"
  },
  {
    "name": "Xiaomi MI 6",
    "label": "Xiaomi MI 6",
    "value": "Xiaomi MI 6"
  },
  {
    "name": "samsung SM-S124DL",
    "label": "samsung SM-S124DL",
    "value": "samsung SM-S124DL"
  },
  {
    "name": "SIRAGON SP-5400",
    "label": "SIRAGON SP-5400",
    "value": "SIRAGON SP-5400"
  },
  {
    "name": "motorola moto g(7) supra",
    "label": "motorola moto g(7) supra",
    "value": "motorola moto g(7) supra"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X5514D",
    "label": "INFINIX MOBILITY LIMITED Infinix X5514D",
    "value": "INFINIX MOBILITY LIMITED Infinix X5514D"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A551L",
    "label": "ITEL MOBILE LIMITED itel A551L",
    "value": "ITEL MOBILE LIMITED itel A551L"
  },
  {
    "name": "ITEL itel S663L",
    "label": "ITEL itel S663L",
    "value": "ITEL itel S663L"
  },
  {
    "name": "GIONEE GIONEE S10CL",
    "label": "GIONEE GIONEE S10CL",
    "value": "GIONEE GIONEE S10CL"
  },
  {
    "name": "WIKO U FEEL PRIME",
    "label": "WIKO U FEEL PRIME",
    "value": "WIKO U FEEL PRIME"
  },
  {
    "name": "samsung SM-C5000",
    "label": "samsung SM-C5000",
    "value": "samsung SM-C5000"
  },
  {
    "name": "realme RMX3393",
    "label": "realme RMX3393",
    "value": "realme RMX3393"
  },
  {
    "name": "HUAWEI MED-LX9N",
    "label": "HUAWEI MED-LX9N",
    "value": "HUAWEI MED-LX9N"
  },
  {
    "name": "DOOGEE X96",
    "label": "DOOGEE X96",
    "value": "DOOGEE X96"
  },
  {
    "name": "samsung SM-J737A",
    "label": "samsung SM-J737A",
    "value": "samsung SM-J737A"
  },
  {
    "name": "itel itel P13 Plus",
    "label": "itel itel P13 Plus",
    "value": "itel itel P13 Plus"
  },
  {
    "name": "ZTE Z3153V",
    "label": "ZTE Z3153V",
    "value": "ZTE Z3153V"
  },
  {
    "name": "TCL T782H",
    "label": "TCL T782H",
    "value": "TCL T782H"
  },
  {
    "name": "samsung SM-S901W",
    "label": "samsung SM-S901W",
    "value": "samsung SM-S901W"
  },
  {
    "name": "KRIP K4m",
    "label": "KRIP K4m",
    "value": "KRIP K4m"
  },
  {
    "name": "LGE LM-Q520N",
    "label": "LGE LM-Q520N",
    "value": "LGE LM-Q520N"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X625C",
    "label": "INFINIX MOBILITY LIMITED Infinix X625C",
    "value": "INFINIX MOBILITY LIMITED Infinix X625C"
  },
  {
    "name": "OPPO OPD2102A",
    "label": "OPPO OPD2102A",
    "value": "OPPO OPD2102A"
  },
  {
    "name": "Hisense Hisense F27",
    "label": "Hisense Hisense F27",
    "value": "Hisense Hisense F27"
  },
  {
    "name": "blackshark SHARK KTUS-H0",
    "label": "blackshark SHARK KTUS-H0",
    "value": "blackshark SHARK KTUS-H0"
  },
  {
    "name": "MobiWire Logic L57",
    "label": "MobiWire Logic L57",
    "value": "MobiWire Logic L57"
  },
  {
    "name": "Xiaomi 22041219NY",
    "label": "Xiaomi 22041219NY",
    "value": "Xiaomi 22041219NY"
  },
  {
    "name": "samsung SM-G887N",
    "label": "samsung SM-G887N",
    "value": "samsung SM-G887N"
  },
  {
    "name": "LGE LM-G810",
    "label": "LGE LM-G810",
    "value": "LGE LM-G810"
  },
  {
    "name": "HUAWEI AGS2-L03",
    "label": "HUAWEI AGS2-L03",
    "value": "HUAWEI AGS2-L03"
  },
  {
    "name": "OPPO CPH2036",
    "label": "OPPO CPH2036",
    "value": "OPPO CPH2036"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO RB7S",
    "label": "TECNO MOBILE LIMITED TECNO RB7S",
    "value": "TECNO MOBILE LIMITED TECNO RB7S"
  },
  {
    "name": "Polytron P551S",
    "label": "Polytron P551S",
    "value": "Polytron P551S"
  },
  {
    "name": "LT C3800",
    "label": "LT C3800",
    "value": "LT C3800"
  },
  {
    "name": "MyPhone myXI1 Plus",
    "label": "MyPhone myXI1 Plus",
    "value": "MyPhone myXI1 Plus"
  },
  {
    "name": "wheatek C17 Pro",
    "label": "wheatek C17 Pro",
    "value": "wheatek C17 Pro"
  },
  {
    "name": "PREMIO X82",
    "label": "PREMIO X82",
    "value": "PREMIO X82"
  },
  {
    "name": "OnePlus HD1901",
    "label": "OnePlus HD1901",
    "value": "OnePlus HD1901"
  },
  {
    "name": "Hurricane H61",
    "label": "Hurricane H61",
    "value": "Hurricane H61"
  },
  {
    "name": "samsung SM-C900F",
    "label": "samsung SM-C900F",
    "value": "samsung SM-C900F"
  },
  {
    "name": "Apple iPad8,7",
    "label": "Apple iPad8,7",
    "value": "Apple iPad8,7"
  },
  {
    "name": "Hisense Hisense Infinity H40 Lite",
    "label": "Hisense Hisense Infinity H40 Lite",
    "value": "Hisense Hisense Infinity H40 Lite"
  },
  {
    "name": "Sony 801SO",
    "label": "Sony 801SO",
    "value": "Sony 801SO"
  },
  {
    "name": "ADVAN 5202",
    "label": "ADVAN 5202",
    "value": "ADVAN 5202"
  },
  {
    "name": "OPPO PDAM10",
    "label": "OPPO PDAM10",
    "value": "OPPO PDAM10"
  },
  {
    "name": "LGE LG-M200",
    "label": "LGE LG-M200",
    "value": "LGE LG-M200"
  },
  {
    "name": "Vodafone VFD 620",
    "label": "Vodafone VFD 620",
    "value": "Vodafone VFD 620"
  },
  {
    "name": "asus ASUS_X00DD",
    "label": "asus ASUS_X00DD",
    "value": "asus ASUS_X00DD"
  },
  {
    "name": "DOOGEE X95",
    "label": "DOOGEE X95",
    "value": "DOOGEE X95"
  },
  {
    "name": "realme RMX3561",
    "label": "realme RMX3561",
    "value": "realme RMX3561"
  },
  {
    "name": "OnePlus HD1911",
    "label": "OnePlus HD1911",
    "value": "OnePlus HD1911"
  },
  {
    "name": "samsung SM-A600P",
    "label": "samsung SM-A600P",
    "value": "samsung SM-A600P"
  },
  {
    "name": "stack n107_6739_66_p",
    "label": "stack n107_6739_66_p",
    "value": "stack n107_6739_66_p"
  },
  {
    "name": "itel itel L5002",
    "label": "itel itel L5002",
    "value": "itel itel L5002"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LB8a",
    "label": "TECNO MOBILE LIMITED TECNO LB8a",
    "value": "TECNO MOBILE LIMITED TECNO LB8a"
  },
  {
    "name": "5052ed39acc19004 c0eb4e8493fd9256",
    "label": "5052ed39acc19004 c0eb4e8493fd9256",
    "value": "5052ed39acc19004 c0eb4e8493fd9256"
  },
  {
    "name": "reeder P13_Blue_Plus_2022",
    "label": "reeder P13_Blue_Plus_2022",
    "value": "reeder P13_Blue_Plus_2022"
  },
  {
    "name": "Sony XQ-AT51",
    "label": "Sony XQ-AT51",
    "value": "Sony XQ-AT51"
  },
  {
    "name": "true SMART 4G P1",
    "label": "true SMART 4G P1",
    "value": "true SMART 4G P1"
  },
  {
    "name": "realme RMP2103",
    "label": "realme RMP2103",
    "value": "realme RMP2103"
  },
  {
    "name": "328fdca8628ff6c3 b2fe0d38b26bb477",
    "label": "328fdca8628ff6c3 b2fe0d38b26bb477",
    "value": "328fdca8628ff6c3 b2fe0d38b26bb477"
  },
  {
    "name": "Sony H8216",
    "label": "Sony H8216",
    "value": "Sony H8216"
  },
  {
    "name": "realme RMX1992",
    "label": "realme RMX1992",
    "value": "realme RMX1992"
  },
  {
    "name": "Chinoe A70 Pro",
    "label": "Chinoe A70 Pro",
    "value": "Chinoe A70 Pro"
  },
  {
    "name": "Apple iPad7,6",
    "label": "Apple iPad7,6",
    "value": "Apple iPad7,6"
  },
  {
    "name": "realme RMX3357",
    "label": "realme RMX3357",
    "value": "realme RMX3357"
  },
  {
    "name": "SHARP SH-Z20",
    "label": "SHARP SH-Z20",
    "value": "SHARP SH-Z20"
  },
  {
    "name": "samsung SM-A908B",
    "label": "samsung SM-A908B",
    "value": "samsung SM-A908B"
  },
  {
    "name": "Mobicel Titan_1",
    "label": "Mobicel Titan_1",
    "value": "Mobicel Titan_1"
  },
  {
    "name": "HUAWEI BLA-L29",
    "label": "HUAWEI BLA-L29",
    "value": "HUAWEI BLA-L29"
  },
  {
    "name": "ITEL MOBILE LIMITED itel W4003",
    "label": "ITEL MOBILE LIMITED itel W4003",
    "value": "ITEL MOBILE LIMITED itel W4003"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X605",
    "label": "INFINIX MOBILITY LIMITED Infinix X605",
    "value": "INFINIX MOBILITY LIMITED Infinix X605"
  },
  {
    "name": "TCL HEYOU60",
    "label": "TCL HEYOU60",
    "value": "TCL HEYOU60"
  },
  {
    "name": "LENOVO Lenovo YT-X705F",
    "label": "LENOVO Lenovo YT-X705F",
    "value": "LENOVO Lenovo YT-X705F"
  },
  {
    "name": "INOI INOI 7 4+64 2021",
    "label": "INOI INOI 7 4+64 2021",
    "value": "INOI INOI 7 4+64 2021"
  },
  {
    "name": "Google HP Chromebook 11 G5",
    "label": "Google HP Chromebook 11 G5",
    "value": "Google HP Chromebook 11 G5"
  },
  {
    "name": "MOBIWIRE UNONU W50",
    "label": "MOBIWIRE UNONU W50",
    "value": "MOBIWIRE UNONU W50"
  },
  {
    "name": "General Mobile G702",
    "label": "General Mobile G702",
    "value": "General Mobile G702"
  },
  {
    "name": "BLU G71",
    "label": "BLU G71",
    "value": "BLU G71"
  },
  {
    "name": "Xiaomi POCO X3",
    "label": "Xiaomi POCO X3",
    "value": "Xiaomi POCO X3"
  },
  {
    "name": "SEMP GO3E",
    "label": "SEMP GO3E",
    "value": "SEMP GO3E"
  },
  {
    "name": "malata Y10G001S4M_EEA",
    "label": "malata Y10G001S4M_EEA",
    "value": "malata Y10G001S4M_EEA"
  },
  {
    "name": "samsung SM-N986W",
    "label": "samsung SM-N986W",
    "value": "samsung SM-N986W"
  },
  {
    "name": "Casper VIA X20",
    "label": "Casper VIA X20",
    "value": "Casper VIA X20"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BE6j",
    "label": "TECNO MOBILE LIMITED TECNO BE6j",
    "value": "TECNO MOBILE LIMITED TECNO BE6j"
  },
  {
    "name": "wheatek A90",
    "label": "wheatek A90",
    "value": "wheatek A90"
  },
  {
    "name": "Kalley Silver Max Lite",
    "label": "Kalley Silver Max Lite",
    "value": "Kalley Silver Max Lite"
  },
  {
    "name": "TCL 5007A",
    "label": "TCL 5007A",
    "value": "TCL 5007A"
  },
  {
    "name": "HUAWEI VKY-AL00",
    "label": "HUAWEI VKY-AL00",
    "value": "HUAWEI VKY-AL00"
  },
  {
    "name": "BLU Advance L4",
    "label": "BLU Advance L4",
    "value": "BLU Advance L4"
  },
  {
    "name": "TCL 9032T",
    "label": "TCL 9032T",
    "value": "TCL 9032T"
  },
  {
    "name": "Mobicel NEO_1",
    "label": "Mobicel NEO_1",
    "value": "Mobicel NEO_1"
  },
  {
    "name": "MobiWire Orange Neva leaf",
    "label": "MobiWire Orange Neva leaf",
    "value": "MobiWire Orange Neva leaf"
  },
  {
    "name": "Coosea SL101AE",
    "label": "Coosea SL101AE",
    "value": "Coosea SL101AE"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO RB8S",
    "label": "TECNO MOBILE LIMITED TECNO RB8S",
    "value": "TECNO MOBILE LIMITED TECNO RB8S"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CC6",
    "label": "TECNO MOBILE LIMITED TECNO CC6",
    "value": "TECNO MOBILE LIMITED TECNO CC6"
  },
  {
    "name": "ADVAN 1010",
    "label": "ADVAN 1010",
    "value": "ADVAN 1010"
  },
  {
    "name": "ZTE ZTE Blade A7 2020",
    "label": "ZTE ZTE Blade A7 2020",
    "value": "ZTE ZTE Blade A7 2020"
  },
  {
    "name": "HUAWEI ATU-L11",
    "label": "HUAWEI ATU-L11",
    "value": "HUAWEI ATU-L11"
  },
  {
    "name": "SSmooth Smooth 5.5 Lite",
    "label": "SSmooth Smooth 5.5 Lite",
    "value": "SSmooth Smooth 5.5 Lite"
  },
  {
    "name": "HUAWEI AKA-L29",
    "label": "HUAWEI AKA-L29",
    "value": "HUAWEI AKA-L29"
  },
  {
    "name": "Fortune Ship M9109",
    "label": "Fortune Ship M9109",
    "value": "Fortune Ship M9109"
  },
  {
    "name": "KRIP K58",
    "label": "KRIP K58",
    "value": "KRIP K58"
  },
  {
    "name": "samsung SM-A515U",
    "label": "samsung SM-A515U",
    "value": "samsung SM-A515U"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CH6",
    "label": "TECNO MOBILE LIMITED TECNO CH6",
    "value": "TECNO MOBILE LIMITED TECNO CH6"
  },
  {
    "name": "Ulefone Note 8P",
    "label": "Ulefone Note 8P",
    "value": "Ulefone Note 8P"
  },
  {
    "name": "vivo V1986A",
    "label": "vivo V1986A",
    "value": "vivo V1986A"
  },
  {
    "name": "TCL 5002J",
    "label": "TCL 5002J",
    "value": "TCL 5002J"
  },
  {
    "name": "TCL T766H",
    "label": "TCL T766H",
    "value": "TCL T766H"
  },
  {
    "name": "vivo V2203",
    "label": "vivo V2203",
    "value": "vivo V2203"
  },
  {
    "name": "vivo V1818A",
    "label": "vivo V1818A",
    "value": "vivo V1818A"
  },
  {
    "name": "uniqcell UNI GO",
    "label": "uniqcell UNI GO",
    "value": "uniqcell UNI GO"
  },
  {
    "name": "HUAWEI BND-L21",
    "label": "HUAWEI BND-L21",
    "value": "HUAWEI BND-L21"
  },
  {
    "name": "BLU G5 Plus",
    "label": "BLU G5 Plus",
    "value": "BLU G5 Plus"
  },
  {
    "name": "Apple Aatiqah’s iPhone",
    "label": "Apple Aatiqah’s iPhone",
    "value": "Apple Aatiqah’s iPhone"
  },
  {
    "name": "Xiaomi dandelion",
    "label": "Xiaomi dandelion",
    "value": "Xiaomi dandelion"
  },
  {
    "name": "Apple Pulleng’s iPhone",
    "label": "Apple Pulleng’s iPhone",
    "value": "Apple Pulleng’s iPhone"
  },
  {
    "name": "HUAWEI LND-AL30",
    "label": "HUAWEI LND-AL30",
    "value": "HUAWEI LND-AL30"
  },
  {
    "name": "PREMIO S83",
    "label": "PREMIO S83",
    "value": "PREMIO S83"
  },
  {
    "name": "Apple Ntombiyesibini’s iPhone",
    "label": "Apple Ntombiyesibini’s iPhone",
    "value": "Apple Ntombiyesibini’s iPhone"
  },
  {
    "name": "Sony XQ-BC72",
    "label": "Sony XQ-BC72",
    "value": "Sony XQ-BC72"
  },
  {
    "name": "Apple Pontsho’s iPhone",
    "label": "Apple Pontsho’s iPhone",
    "value": "Apple Pontsho’s iPhone"
  },
  {
    "name": "Apple Jessica’s iPhone",
    "label": "Apple Jessica’s iPhone",
    "value": "Apple Jessica’s iPhone"
  },
  {
    "name": "Blackview Tab 8",
    "label": "Blackview Tab 8",
    "value": "Blackview Tab 8"
  },
  {
    "name": "Apple Christopher’s iPhone 12 Pro",
    "label": "Apple Christopher’s iPhone 12 Pro",
    "value": "Apple Christopher’s iPhone 12 Pro"
  },
  {
    "name": "STYLO CPE91",
    "label": "STYLO CPE91",
    "value": "STYLO CPE91"
  },
  {
    "name": "OPPO CPH2461",
    "label": "OPPO CPH2461",
    "value": "OPPO CPH2461"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X655F",
    "label": "INFINIX MOBILITY LIMITED Infinix X655F",
    "value": "INFINIX MOBILITY LIMITED Infinix X655F"
  },
  {
    "name": "Yulong cp3705AS",
    "label": "Yulong cp3705AS",
    "value": "Yulong cp3705AS"
  },
  {
    "name": "vsmart V350U",
    "label": "vsmart V350U",
    "value": "vsmart V350U"
  },
  {
    "name": "ZUUM COVET_PRO_LITE",
    "label": "ZUUM COVET_PRO_LITE",
    "value": "ZUUM COVET_PRO_LITE"
  },
  {
    "name": "Apple itumeleng’s Iphone",
    "label": "Apple itumeleng’s Iphone",
    "value": "Apple itumeleng’s Iphone"
  },
  {
    "name": "Cherry_Mobile Flare J8 LTE",
    "label": "Cherry_Mobile Flare J8 LTE",
    "value": "Cherry_Mobile Flare J8 LTE"
  },
  {
    "name": "YEZZ ART1",
    "label": "YEZZ ART1",
    "value": "YEZZ ART1"
  },
  {
    "name": "Apple Faye ",
    "label": "Apple Faye ",
    "value": "Apple Faye "
  },
  {
    "name": "samsung SM-G935V",
    "label": "samsung SM-G935V",
    "value": "samsung SM-G935V"
  },
  {
    "name": "vivo I2018",
    "label": "vivo I2018",
    "value": "vivo I2018"
  },
  {
    "name": "Apple iPad6,3",
    "label": "Apple iPad6,3",
    "value": "Apple iPad6,3"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CH9n",
    "label": "TECNO MOBILE LIMITED TECNO CH9n",
    "value": "TECNO MOBILE LIMITED TECNO CH9n"
  },
  {
    "name": "Premio S86",
    "label": "Premio S86",
    "value": "Premio S86"
  },
  {
    "name": "Apple Evan’s iPhone",
    "label": "Apple Evan’s iPhone",
    "value": "Apple Evan’s iPhone"
  },
  {
    "name": "motorola moto g power",
    "label": "motorola moto g power",
    "value": "motorola moto g power"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X655",
    "label": "INFINIX MOBILITY LIMITED Infinix X655",
    "value": "INFINIX MOBILITY LIMITED Infinix X655"
  },
  {
    "name": "Fortune Ship L503F Plus",
    "label": "Fortune Ship L503F Plus",
    "value": "Fortune Ship L503F Plus"
  },
  {
    "name": "TCL T810S",
    "label": "TCL T810S",
    "value": "TCL T810S"
  },
  {
    "name": "Xiaomi M2012K10C",
    "label": "Xiaomi M2012K10C",
    "value": "Xiaomi M2012K10C"
  },
  {
    "name": "Cherry Mobile Aqua S9 Max",
    "label": "Cherry Mobile Aqua S9 Max",
    "value": "Cherry Mobile Aqua S9 Max"
  },
  {
    "name": "samsung SM-J727T1",
    "label": "samsung SM-J727T1",
    "value": "samsung SM-J727T1"
  },
  {
    "name": "DOOGEE X93",
    "label": "DOOGEE X93",
    "value": "DOOGEE X93"
  },
  {
    "name": "OnePlus OnePlus8Pro",
    "label": "OnePlus OnePlus8Pro",
    "value": "OnePlus OnePlus8Pro"
  },
  {
    "name": "blackshark SKW-H0",
    "label": "blackshark SKW-H0",
    "value": "blackshark SKW-H0"
  },
  {
    "name": "Apple iPhone de Isabel",
    "label": "Apple iPhone de Isabel",
    "value": "Apple iPhone de Isabel"
  },
  {
    "name": "TECLAST M40(N9H3)",
    "label": "TECLAST M40(N9H3)",
    "value": "TECLAST M40(N9H3)"
  },
  {
    "name": "ZTE Blade A5 2019-T",
    "label": "ZTE Blade A5 2019-T",
    "value": "ZTE Blade A5 2019-T"
  },
  {
    "name": "HUAWEI AGR-W09",
    "label": "HUAWEI AGR-W09",
    "value": "HUAWEI AGR-W09"
  },
  {
    "name": "ZTE P545",
    "label": "ZTE P545",
    "value": "ZTE P545"
  },
  {
    "name": "motorola Lenovo K13",
    "label": "motorola Lenovo K13",
    "value": "motorola Lenovo K13"
  },
  {
    "name": "Apple Jessa Nina’s iPhone",
    "label": "Apple Jessa Nina’s iPhone",
    "value": "Apple Jessa Nina’s iPhone"
  },
  {
    "name": "itel itel P682LP",
    "label": "itel itel P682LP",
    "value": "itel itel P682LP"
  },
  {
    "name": "samsung SM-A705GM",
    "label": "samsung SM-A705GM",
    "value": "samsung SM-A705GM"
  },
  {
    "name": "OnePlus ONEPLUS A3003",
    "label": "OnePlus ONEPLUS A3003",
    "value": "OnePlus ONEPLUS A3003"
  },
  {
    "name": "RAVOZ RAVOZ Z5",
    "label": "RAVOZ RAVOZ Z5",
    "value": "RAVOZ RAVOZ Z5"
  },
  {
    "name": "Blackview BV4900Pro",
    "label": "Blackview BV4900Pro",
    "value": "Blackview BV4900Pro"
  },
  {
    "name": "Hisense Hisense Infinity H50 ZOOM",
    "label": "Hisense Hisense Infinity H50 ZOOM",
    "value": "Hisense Hisense Infinity H50 ZOOM"
  },
  {
    "name": "Apple IPHONE DE JOSE ENRIQUE ",
    "label": "Apple IPHONE DE JOSE ENRIQUE ",
    "value": "Apple IPHONE DE JOSE ENRIQUE "
  },
  {
    "name": "Sky_Devices SKY PrestigeX",
    "label": "Sky_Devices SKY PrestigeX",
    "value": "Sky_Devices SKY PrestigeX"
  },
  {
    "name": "KODAK D60LX",
    "label": "KODAK D60LX",
    "value": "KODAK D60LX"
  },
  {
    "name": "A-gold F1",
    "label": "A-gold F1",
    "value": "A-gold F1"
  },
  {
    "name": "Hisense Hisense H50 Lite",
    "label": "Hisense Hisense H50 Lite",
    "value": "Hisense Hisense H50 Lite"
  },
  {
    "name": "xiaomi Redmi 9A",
    "label": "xiaomi Redmi 9A",
    "value": "xiaomi Redmi 9A"
  },
  {
    "name": "samsung SM-S260DL",
    "label": "samsung SM-S260DL",
    "value": "samsung SM-S260DL"
  },
  {
    "name": "along Stratus_C5",
    "label": "along Stratus_C5",
    "value": "along Stratus_C5"
  },
  {
    "name": "WIKO W-V800-EEA",
    "label": "WIKO W-V800-EEA",
    "value": "WIKO W-V800-EEA"
  },
  {
    "name": "motorola moto g stylus (XT2115DL)",
    "label": "motorola moto g stylus (XT2115DL)",
    "value": "motorola moto g stylus (XT2115DL)"
  },
  {
    "name": "TCL 8051",
    "label": "TCL 8051",
    "value": "TCL 8051"
  },
  {
    "name": "LGE LM-Q610.FGN",
    "label": "LGE LM-Q610.FGN",
    "value": "LGE LM-Q610.FGN"
  },
  {
    "name": "vivo V2006",
    "label": "vivo V2006",
    "value": "vivo V2006"
  },
  {
    "name": "sprd m107_9863a_64_q_Natv",
    "label": "sprd m107_9863a_64_q_Natv",
    "value": "sprd m107_9863a_64_q_Natv"
  },
  {
    "name": "nubia NX669J",
    "label": "nubia NX669J",
    "value": "nubia NX669J"
  },
  {
    "name": "Google Mediatek MT8173 Chromebook",
    "label": "Google Mediatek MT8173 Chromebook",
    "value": "Google Mediatek MT8173 Chromebook"
  },
  {
    "name": "Yulong cp3705A",
    "label": "Yulong cp3705A",
    "value": "Yulong cp3705A"
  },
  {
    "name": "sprd S32960E_221_V10_GO_HD_10INCH_Natv",
    "label": "sprd S32960E_221_V10_GO_HD_10INCH_Natv",
    "value": "sprd S32960E_221_V10_GO_HD_10INCH_Natv"
  },
  {
    "name": "Hisense Hisense E50 128G",
    "label": "Hisense Hisense E50 128G",
    "value": "Hisense Hisense E50 128G"
  },
  {
    "name": "LENOVO Lenovo TB125FU",
    "label": "LENOVO Lenovo TB125FU",
    "value": "LENOVO Lenovo TB125FU"
  },
  {
    "name": "ZUUM AKUS Z1",
    "label": "ZUUM AKUS Z1",
    "value": "ZUUM AKUS Z1"
  },
  {
    "name": "Sky_Devices Elite A5",
    "label": "Sky_Devices Elite A5",
    "value": "Sky_Devices Elite A5"
  },
  {
    "name": "Kalley Black_Z",
    "label": "Kalley Black_Z",
    "value": "Kalley Black_Z"
  },
  {
    "name": "Google nami",
    "label": "Google nami",
    "value": "Google nami"
  },
  {
    "name": "ITEL MOBILE LIMITED itel L5007S",
    "label": "ITEL MOBILE LIMITED itel L5007S",
    "value": "ITEL MOBILE LIMITED itel L5007S"
  },
  {
    "name": "Ulefone Armor 8",
    "label": "Ulefone Armor 8",
    "value": "Ulefone Armor 8"
  },
  {
    "name": "motorola motorola edge 30 neo",
    "label": "motorola motorola edge 30 neo",
    "value": "motorola motorola edge 30 neo"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X658E",
    "label": "INFINIX MOBILITY LIMITED Infinix X658E",
    "value": "INFINIX MOBILITY LIMITED Infinix X658E"
  },
  {
    "name": "Xiaomi Redmi Note 3",
    "label": "Xiaomi Redmi Note 3",
    "value": "Xiaomi Redmi Note 3"
  },
  {
    "name": "samsung SM-G9730",
    "label": "samsung SM-G9730",
    "value": "samsung SM-G9730"
  },
  {
    "name": "HUAWEI HWI-AL00",
    "label": "HUAWEI HWI-AL00",
    "value": "HUAWEI HWI-AL00"
  },
  {
    "name": "samsung SM-J327W",
    "label": "samsung SM-J327W",
    "value": "samsung SM-J327W"
  },
  {
    "name": "samsung SM-G930P",
    "label": "samsung SM-G930P",
    "value": "samsung SM-G930P"
  },
  {
    "name": "HUAWEI ELS-AN10",
    "label": "HUAWEI ELS-AN10",
    "value": "HUAWEI ELS-AN10"
  },
  {
    "name": "incar PTB8R",
    "label": "incar PTB8R",
    "value": "incar PTB8R"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X604",
    "label": "INFINIX MOBILITY LIMITED Infinix X604",
    "value": "INFINIX MOBILITY LIMITED Infinix X604"
  },
  {
    "name": "LGE LGL355DL",
    "label": "LGE LGL355DL",
    "value": "LGE LGL355DL"
  },
  {
    "name": "Multilaser Multilaser_GMax_2",
    "label": "Multilaser Multilaser_GMax_2",
    "value": "Multilaser Multilaser_GMax_2"
  },
  {
    "name": "UMIDIGI A5_Pro",
    "label": "UMIDIGI A5_Pro",
    "value": "UMIDIGI A5_Pro"
  },
  {
    "name": "motorola motorola one 5G UW ace",
    "label": "motorola motorola one 5G UW ace",
    "value": "motorola motorola one 5G UW ace"
  },
  {
    "name": "itel itel P32",
    "label": "itel itel P32",
    "value": "itel itel P32"
  },
  {
    "name": "HOTWAV Symbol Max",
    "label": "HOTWAV Symbol Max",
    "value": "HOTWAV Symbol Max"
  },
  {
    "name": "WIKO W-V680-OPE",
    "label": "WIKO W-V680-OPE",
    "value": "WIKO W-V680-OPE"
  },
  {
    "name": "OnePlus LE2115",
    "label": "OnePlus LE2115",
    "value": "OnePlus LE2115"
  },
  {
    "name": "samsung SM-S906U1",
    "label": "samsung SM-S906U1",
    "value": "samsung SM-S906U1"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BB4k",
    "label": "TECNO MOBILE LIMITED TECNO BB4k",
    "value": "TECNO MOBILE LIMITED TECNO BB4k"
  },
  {
    "name": "Multilaser Multilaser_G_Max_2_SE_64",
    "label": "Multilaser Multilaser_G_Max_2_SE_64",
    "value": "Multilaser Multilaser_G_Max_2_SE_64"
  },
  {
    "name": "Sky_Devices Elite E55Max",
    "label": "Sky_Devices Elite E55Max",
    "value": "Sky_Devices Elite E55Max"
  },
  {
    "name": "NUU S6003L",
    "label": "NUU S6003L",
    "value": "NUU S6003L"
  },
  {
    "name": "SSmooth Smooth 6.26 Max",
    "label": "SSmooth Smooth 6.26 Max",
    "value": "SSmooth Smooth 6.26 Max"
  },
  {
    "name": "TCL 5061A",
    "label": "TCL 5061A",
    "value": "TCL 5061A"
  },
  {
    "name": "Positivo Quantum Five",
    "label": "Positivo Quantum Five",
    "value": "Positivo Quantum Five"
  },
  {
    "name": "samsung SM-G991W",
    "label": "samsung SM-G991W",
    "value": "samsung SM-G991W"
  },
  {
    "name": "1100AS 1100AS",
    "label": "1100AS 1100AS",
    "value": "1100AS 1100AS"
  },
  {
    "name": "motorola moto g stylus (2022)",
    "label": "motorola moto g stylus (2022)",
    "value": "motorola moto g stylus (2022)"
  },
  {
    "name": "OPPO PBFM00",
    "label": "OPPO PBFM00",
    "value": "OPPO PBFM00"
  },
  {
    "name": "Sony SO-01J",
    "label": "Sony SO-01J",
    "value": "Sony SO-01J"
  },
  {
    "name": "samsung SM-A205S",
    "label": "samsung SM-A205S",
    "value": "samsung SM-A205S"
  },
  {
    "name": "asus ASUS_X017D",
    "label": "asus ASUS_X017D",
    "value": "asus ASUS_X017D"
  },
  {
    "name": "BLU T6",
    "label": "BLU T6",
    "value": "BLU T6"
  },
  {
    "name": "samsung SM-A305YN",
    "label": "samsung SM-A305YN",
    "value": "samsung SM-A305YN"
  },
  {
    "name": "ITEL MOBILE LIMITED itel W5008",
    "label": "ITEL MOBILE LIMITED itel W5008",
    "value": "ITEL MOBILE LIMITED itel W5008"
  },
  {
    "name": "KYOCERA KYV41",
    "label": "KYOCERA KYV41",
    "value": "KYOCERA KYV41"
  },
  {
    "name": "samsung SM-E5260",
    "label": "samsung SM-E5260",
    "value": "samsung SM-E5260"
  },
  {
    "name": "LGE LGM-G600L",
    "label": "LGE LGM-G600L",
    "value": "LGE LGM-G600L"
  },
  {
    "name": "Xiaomi 21091116UI",
    "label": "Xiaomi 21091116UI",
    "value": "Xiaomi 21091116UI"
  },
  {
    "name": "STYLO X5",
    "label": "STYLO X5",
    "value": "STYLO X5"
  },
  {
    "name": "LENOVO Lenovo TB-7306X",
    "label": "LENOVO Lenovo TB-7306X",
    "value": "LENOVO Lenovo TB-7306X"
  },
  {
    "name": "SHARP 507SH",
    "label": "SHARP 507SH",
    "value": "SHARP 507SH"
  },
  {
    "name": "TCL 5048I",
    "label": "TCL 5048I",
    "value": "TCL 5048I"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X687",
    "label": "INFINIX MOBILITY LIMITED Infinix X687",
    "value": "INFINIX MOBILITY LIMITED Infinix X687"
  },
  {
    "name": "KYOCERA S2",
    "label": "KYOCERA S2",
    "value": "KYOCERA S2"
  },
  {
    "name": "vsmart Star 3",
    "label": "vsmart Star 3",
    "value": "vsmart Star 3"
  },
  {
    "name": "WIKO W-V730-EEA",
    "label": "WIKO W-V730-EEA",
    "value": "WIKO W-V730-EEA"
  },
  {
    "name": "samsung SM-F707N",
    "label": "samsung SM-F707N",
    "value": "samsung SM-F707N"
  },
  {
    "name": "Hurricane Rush Plus",
    "label": "Hurricane Rush Plus",
    "value": "Hurricane Rush Plus"
  },
  {
    "name": "BlackBerry BBB100-2",
    "label": "BlackBerry BBB100-2",
    "value": "BlackBerry BBB100-2"
  },
  {
    "name": "Sony SOV42",
    "label": "Sony SOV42",
    "value": "Sony SOV42"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A16",
    "label": "ITEL MOBILE LIMITED itel A16",
    "value": "ITEL MOBILE LIMITED itel A16"
  },
  {
    "name": "Multilaser M9-3G",
    "label": "Multilaser M9-3G",
    "value": "Multilaser M9-3G"
  },
  {
    "name": "samsung SM-G930V",
    "label": "samsung SM-G930V",
    "value": "samsung SM-G930V"
  },
  {
    "name": "WE WE F20",
    "label": "WE WE F20",
    "value": "WE WE F20"
  },
  {
    "name": "vivo I2009",
    "label": "vivo I2009",
    "value": "vivo I2009"
  },
  {
    "name": "BLU C6 2020",
    "label": "BLU C6 2020",
    "value": "BLU C6 2020"
  },
  {
    "name": "Xiaomi 2203129G",
    "label": "Xiaomi 2203129G",
    "value": "Xiaomi 2203129G"
  },
  {
    "name": "motorola moto g(40) fusion",
    "label": "motorola moto g(40) fusion",
    "value": "motorola moto g(40) fusion"
  },
  {
    "name": "TCL 5002X",
    "label": "TCL 5002X",
    "value": "TCL 5002X"
  },
  {
    "name": "Xiaomi M2012K11I",
    "label": "Xiaomi M2012K11I",
    "value": "Xiaomi M2012K11I"
  },
  {
    "name": "Micromax IN_Note1",
    "label": "Micromax IN_Note1",
    "value": "Micromax IN_Note1"
  },
  {
    "name": "Xiaomi MI 5X",
    "label": "Xiaomi MI 5X",
    "value": "Xiaomi MI 5X"
  },
  {
    "name": "Apple Esonasihle’s iPhone",
    "label": "Apple Esonasihle’s iPhone",
    "value": "Apple Esonasihle’s iPhone"
  },
  {
    "name": "HUAWEI JDN2-AL00HN",
    "label": "HUAWEI JDN2-AL00HN",
    "value": "HUAWEI JDN2-AL00HN"
  },
  {
    "name": "TCL 5033EP",
    "label": "TCL 5033EP",
    "value": "TCL 5033EP"
  },
  {
    "name": "KRIP K66",
    "label": "KRIP K66",
    "value": "KRIP K66"
  },
  {
    "name": "Apple BORAAM'siPhone",
    "label": "Apple BORAAM'siPhone",
    "value": "Apple BORAAM'siPhone"
  },
  {
    "name": "Apple User User’s iPhone",
    "label": "Apple User User’s iPhone",
    "value": "Apple User User’s iPhone"
  },
  {
    "name": "KRONO NET_LITE",
    "label": "KRONO NET_LITE",
    "value": "KRONO NET_LITE"
  },
  {
    "name": "Apple Nomfundo’s iPhone",
    "label": "Apple Nomfundo’s iPhone",
    "value": "Apple Nomfundo’s iPhone"
  },
  {
    "name": "Apple Lutho",
    "label": "Apple Lutho",
    "value": "Apple Lutho"
  },
  {
    "name": "Apple Siphokazi’s iPhone",
    "label": "Apple Siphokazi’s iPhone",
    "value": "Apple Siphokazi’s iPhone"
  },
  {
    "name": "Apple Zama’s iPhone",
    "label": "Apple Zama’s iPhone",
    "value": "Apple Zama’s iPhone"
  },
  {
    "name": "Bmobile BL51_MV05",
    "label": "Bmobile BL51_MV05",
    "value": "Bmobile BL51_MV05"
  },
  {
    "name": "Apple Flora’s iPhone",
    "label": "Apple Flora’s iPhone",
    "value": "Apple Flora’s iPhone"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6811",
    "label": "INFINIX MOBILITY LIMITED Infinix X6811",
    "value": "INFINIX MOBILITY LIMITED Infinix X6811"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A52S Lite",
    "label": "ITEL MOBILE LIMITED itel A52S Lite",
    "value": "ITEL MOBILE LIMITED itel A52S Lite"
  },
  {
    "name": "Welcome S21+ Ultra",
    "label": "Welcome S21+ Ultra",
    "value": "Welcome S21+ Ultra"
  },
  {
    "name": "HUAWEI CTR-LX2",
    "label": "HUAWEI CTR-LX2",
    "value": "HUAWEI CTR-LX2"
  },
  {
    "name": "Apple Lefa’s iPhone",
    "label": "Apple Lefa’s iPhone",
    "value": "Apple Lefa’s iPhone"
  },
  {
    "name": "Deejoy MID_1089IPSA100",
    "label": "Deejoy MID_1089IPSA100",
    "value": "Deejoy MID_1089IPSA100"
  },
  {
    "name": "iPad13,8 iPad",
    "label": "iPad13,8 iPad",
    "value": "iPad13,8 iPad"
  },
  {
    "name": "Xiaomi M2103K19PI",
    "label": "Xiaomi M2103K19PI",
    "value": "Xiaomi M2103K19PI"
  },
  {
    "name": "asus ASUS_Z01MD",
    "label": "asus ASUS_Z01MD",
    "value": "asus ASUS_Z01MD"
  },
  {
    "name": "Samsung GT-I9082",
    "label": "Samsung GT-I9082",
    "value": "Samsung GT-I9082"
  },
  {
    "name": "samsung SM-A520W",
    "label": "samsung SM-A520W",
    "value": "samsung SM-A520W"
  },
  {
    "name": "Apple iPhone Jr",
    "label": "Apple iPhone Jr",
    "value": "Apple iPhone Jr"
  },
  {
    "name": "Siragon SP-6000",
    "label": "Siragon SP-6000",
    "value": "Siragon SP-6000"
  },
  {
    "name": "MyPhone myA16",
    "label": "MyPhone myA16",
    "value": "MyPhone myA16"
  },
  {
    "name": "ZTE P650 Pro",
    "label": "ZTE P650 Pro",
    "value": "ZTE P650 Pro"
  },
  {
    "name": "samsung SM-J260GU",
    "label": "samsung SM-J260GU",
    "value": "samsung SM-J260GU"
  },
  {
    "name": "HYUNDAI E603",
    "label": "HYUNDAI E603",
    "value": "HYUNDAI E603"
  },
  {
    "name": "HUAWEI BKK-AL10",
    "label": "HUAWEI BKK-AL10",
    "value": "HUAWEI BKK-AL10"
  },
  {
    "name": "OPPO CPH2123",
    "label": "OPPO CPH2123",
    "value": "OPPO CPH2123"
  },
  {
    "name": "iPad13,4 iPad",
    "label": "iPad13,4 iPad",
    "value": "iPad13,4 iPad"
  },
  {
    "name": "IMG VISION",
    "label": "IMG VISION",
    "value": "IMG VISION"
  },
  {
    "name": "Infinix Infinix X6511B",
    "label": "Infinix Infinix X6511B",
    "value": "Infinix Infinix X6511B"
  },
  {
    "name": "samsung SM-G950U1",
    "label": "samsung SM-G950U1",
    "value": "samsung SM-G950U1"
  },
  {
    "name": "Premio X80",
    "label": "Premio X80",
    "value": "Premio X80"
  },
  {
    "name": "Lenovo Lenovo L78032",
    "label": "Lenovo Lenovo L78032",
    "value": "Lenovo Lenovo L78032"
  },
  {
    "name": "samsung SM-T875",
    "label": "samsung SM-T875",
    "value": "samsung SM-T875"
  },
  {
    "name": "GHIA GTPND7",
    "label": "GHIA GTPND7",
    "value": "GHIA GTPND7"
  },
  {
    "name": "Masscom Masstel X5",
    "label": "Masscom Masstel X5",
    "value": "Masscom Masstel X5"
  },
  {
    "name": "EVERCOSS M55A",
    "label": "EVERCOSS M55A",
    "value": "EVERCOSS M55A"
  },
  {
    "name": "LGE LG-H990",
    "label": "LGE LG-H990",
    "value": "LGE LG-H990"
  },
  {
    "name": "PCD P55",
    "label": "PCD P55",
    "value": "PCD P55"
  },
  {
    "name": "Mobicel ZOOM_1",
    "label": "Mobicel ZOOM_1",
    "value": "Mobicel ZOOM_1"
  },
  {
    "name": "Google M2003J15SC",
    "label": "Google M2003J15SC",
    "value": "Google M2003J15SC"
  },
  {
    "name": "blackshark SHARK PAR-H0",
    "label": "blackshark SHARK PAR-H0",
    "value": "blackshark SHARK PAR-H0"
  },
  {
    "name": "MobiWire MobiGo2",
    "label": "MobiWire MobiGo2",
    "value": "MobiWire MobiGo2"
  },
  {
    "name": "MobiWire MAGNO C",
    "label": "MobiWire MAGNO C",
    "value": "MobiWire MAGNO C"
  },
  {
    "name": "ADVAN 6501",
    "label": "ADVAN 6501",
    "value": "ADVAN 6501"
  },
  {
    "name": "LGE LM-Q610(FGN)",
    "label": "LGE LM-Q610(FGN)",
    "value": "LGE LM-Q610(FGN)"
  },
  {
    "name": "samsung SM-E025F",
    "label": "samsung SM-E025F",
    "value": "samsung SM-E025F"
  },
  {
    "name": "WIKO W-K380-TVM2G",
    "label": "WIKO W-K380-TVM2G",
    "value": "WIKO W-K380-TVM2G"
  },
  {
    "name": "OPPO OPPO A83",
    "label": "OPPO OPPO A83",
    "value": "OPPO OPPO A83"
  },
  {
    "name": "samsung SM-A505YN",
    "label": "samsung SM-A505YN",
    "value": "samsung SM-A505YN"
  },
  {
    "name": "Hot Pepper Inc HPP-L55B",
    "label": "Hot Pepper Inc HPP-L55B",
    "value": "Hot Pepper Inc HPP-L55B"
  },
  {
    "name": "iHunt iHunt Like 12 PRO",
    "label": "iHunt iHunt Like 12 PRO",
    "value": "iHunt iHunt Like 12 PRO"
  },
  {
    "name": "LGE LM-X410(FG)",
    "label": "LGE LM-X410(FG)",
    "value": "LGE LM-X410(FG)"
  },
  {
    "name": "Sony XQ-AU52",
    "label": "Sony XQ-AU52",
    "value": "Sony XQ-AU52"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X698",
    "label": "INFINIX MOBILITY LIMITED Infinix X698",
    "value": "INFINIX MOBILITY LIMITED Infinix X698"
  },
  {
    "name": "TCL 6102H",
    "label": "TCL 6102H",
    "value": "TCL 6102H"
  },
  {
    "name": "Lenovo Lenovo K520",
    "label": "Lenovo Lenovo K520",
    "value": "Lenovo Lenovo K520"
  },
  {
    "name": "vivo vivo 2010",
    "label": "vivo vivo 2010",
    "value": "vivo vivo 2010"
  },
  {
    "name": "Bmobile AX687",
    "label": "Bmobile AX687",
    "value": "Bmobile AX687"
  },
  {
    "name": "vivo vivo Y93",
    "label": "vivo vivo Y93",
    "value": "vivo vivo Y93"
  },
  {
    "name": "Xiaomi Mi Note 3",
    "label": "Xiaomi Mi Note 3",
    "value": "Xiaomi Mi Note 3"
  },
  {
    "name": "ITEL MOBILE LIMITED itel P552W",
    "label": "ITEL MOBILE LIMITED itel P552W",
    "value": "ITEL MOBILE LIMITED itel P552W"
  },
  {
    "name": "realme RMX1929",
    "label": "realme RMX1929",
    "value": "realme RMX1929"
  },
  {
    "name": "samsung SM-A202K",
    "label": "samsung SM-A202K",
    "value": "samsung SM-A202K"
  },
  {
    "name": "iSWAG iSWAG ACTIV",
    "label": "iSWAG iSWAG ACTIV",
    "value": "iSWAG iSWAG ACTIV"
  },
  {
    "name": "ZUUM AURA PRO",
    "label": "ZUUM AURA PRO",
    "value": "ZUUM AURA PRO"
  },
  {
    "name": "lephone G6_pro",
    "label": "lephone G6_pro",
    "value": "lephone G6_pro"
  },
  {
    "name": "samsung SM-N975U1",
    "label": "samsung SM-N975U1",
    "value": "samsung SM-N975U1"
  },
  {
    "name": "Xiaomi Redmi Note 9",
    "label": "Xiaomi Redmi Note 9",
    "value": "Xiaomi Redmi Note 9"
  },
  {
    "name": "samsung SM-N976V",
    "label": "samsung SM-N976V",
    "value": "samsung SM-N976V"
  },
  {
    "name": "LENOVO Lenovo TB-X606V",
    "label": "LENOVO Lenovo TB-X606V",
    "value": "LENOVO Lenovo TB-X606V"
  },
  {
    "name": "itel itel L6002P",
    "label": "itel itel L6002P",
    "value": "itel itel L6002P"
  },
  {
    "name": "FIH EC211002",
    "label": "FIH EC211002",
    "value": "FIH EC211002"
  },
  {
    "name": "INFINIX Infinix X672",
    "label": "INFINIX Infinix X672",
    "value": "INFINIX Infinix X672"
  },
  {
    "name": "itel itel W6503",
    "label": "itel itel W6503",
    "value": "itel itel W6503"
  },
  {
    "name": "realme RMX2144",
    "label": "realme RMX2144",
    "value": "realme RMX2144"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CA7",
    "label": "TECNO MOBILE LIMITED TECNO CA7",
    "value": "TECNO MOBILE LIMITED TECNO CA7"
  },
  {
    "name": "realme RMX3262",
    "label": "realme RMX3262",
    "value": "realme RMX3262"
  },
  {
    "name": "MOBIWIRE INFINITY_light",
    "label": "MOBIWIRE INFINITY_light",
    "value": "MOBIWIRE INFINITY_light"
  },
  {
    "name": "Sony E6603",
    "label": "Sony E6603",
    "value": "Sony E6603"
  },
  {
    "name": "samsung SM-A226L",
    "label": "samsung SM-A226L",
    "value": "samsung SM-A226L"
  },
  {
    "name": "TCL 5007Z",
    "label": "TCL 5007Z",
    "value": "TCL 5007Z"
  },
  {
    "name": "HMD Global N151DL",
    "label": "HMD Global N151DL",
    "value": "HMD Global N151DL"
  },
  {
    "name": "samsung SM-A606Y",
    "label": "samsung SM-A606Y",
    "value": "samsung SM-A606Y"
  },
  {
    "name": "X-TIGI SHARP_2",
    "label": "X-TIGI SHARP_2",
    "value": "X-TIGI SHARP_2"
  },
  {
    "name": "samsung SC-02K",
    "label": "samsung SC-02K",
    "value": "samsung SC-02K"
  },
  {
    "name": "TCL 5124J",
    "label": "TCL 5124J",
    "value": "TCL 5124J"
  },
  {
    "name": "Amazon KFKAWI",
    "label": "Amazon KFKAWI",
    "value": "Amazon KFKAWI"
  },
  {
    "name": "Sony H8296",
    "label": "Sony H8296",
    "value": "Sony H8296"
  },
  {
    "name": "ITEL itel A512W",
    "label": "ITEL itel A512W",
    "value": "ITEL itel A512W"
  },
  {
    "name": "LGE LM-X420N",
    "label": "LGE LM-X420N",
    "value": "LGE LM-X420N"
  },
  {
    "name": "vivo I2203",
    "label": "vivo I2203",
    "value": "vivo I2203"
  },
  {
    "name": "HMD Global Nokia C20 Plus",
    "label": "HMD Global Nokia C20 Plus",
    "value": "HMD Global Nokia C20 Plus"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X652B",
    "label": "INFINIX MOBILITY LIMITED Infinix X652B",
    "value": "INFINIX MOBILITY LIMITED Infinix X652B"
  },
  {
    "name": "TCL A1 Alpha 21",
    "label": "TCL A1 Alpha 21",
    "value": "TCL A1 Alpha 21"
  },
  {
    "name": "Teclast T20",
    "label": "Teclast T20",
    "value": "Teclast T20"
  },
  {
    "name": "asus ASUS_Z012DA",
    "label": "asus ASUS_Z012DA",
    "value": "asus ASUS_Z012DA"
  },
  {
    "name": "blackshark SHARK KSR-H0",
    "label": "blackshark SHARK KSR-H0",
    "value": "blackshark SHARK KSR-H0"
  },
  {
    "name": "ACE France BUZZ 2 Plus",
    "label": "ACE France BUZZ 2 Plus",
    "value": "ACE France BUZZ 2 Plus"
  },
  {
    "name": "Multilaser M10_3G",
    "label": "Multilaser M10_3G",
    "value": "Multilaser M10_3G"
  },
  {
    "name": "TCL 8094X",
    "label": "TCL 8094X",
    "value": "TCL 8094X"
  },
  {
    "name": "GIONEE M6 mini",
    "label": "GIONEE M6 mini",
    "value": "GIONEE M6 mini"
  },
  {
    "name": "samsung SM-G9550",
    "label": "samsung SM-G9550",
    "value": "samsung SM-G9550"
  },
  {
    "name": "Apple iPad5,3",
    "label": "Apple iPad5,3",
    "value": "Apple iPad5,3"
  },
  {
    "name": "IMG INKOSI_PRO",
    "label": "IMG INKOSI_PRO",
    "value": "IMG INKOSI_PRO"
  },
  {
    "name": "Xiaomi M2007J22C",
    "label": "Xiaomi M2007J22C",
    "value": "Xiaomi M2007J22C"
  },
  {
    "name": "samsung SM-A236U",
    "label": "samsung SM-A236U",
    "value": "samsung SM-A236U"
  },
  {
    "name": "Multilaser M8_4G",
    "label": "Multilaser M8_4G",
    "value": "Multilaser M8_4G"
  },
  {
    "name": "LGE LG-H870DS",
    "label": "LGE LG-H870DS",
    "value": "LGE LG-H870DS"
  },
  {
    "name": "blackshark DLT-H0",
    "label": "blackshark DLT-H0",
    "value": "blackshark DLT-H0"
  },
  {
    "name": "PLUM Z570",
    "label": "PLUM Z570",
    "value": "PLUM Z570"
  },
  {
    "name": "OnePlus LE2123",
    "label": "OnePlus LE2123",
    "value": "OnePlus LE2123"
  },
  {
    "name": "vivo V2140",
    "label": "vivo V2140",
    "value": "vivo V2140"
  },
  {
    "name": "samsung SM-N960W",
    "label": "samsung SM-N960W",
    "value": "samsung SM-N960W"
  },
  {
    "name": "WIKO W-K560-EEA",
    "label": "WIKO W-K560-EEA",
    "value": "WIKO W-K560-EEA"
  },
  {
    "name": "TCL 5002L",
    "label": "TCL 5002L",
    "value": "TCL 5002L"
  },
  {
    "name": "IFRE S.A. KODAK SMARTWAY T3",
    "label": "IFRE S.A. KODAK SMARTWAY T3",
    "value": "IFRE S.A. KODAK SMARTWAY T3"
  },
  {
    "name": "samsung SM-M336B",
    "label": "samsung SM-M336B",
    "value": "samsung SM-M336B"
  },
  {
    "name": "motorola moto e5 (XT1920DL)",
    "label": "motorola moto e5 (XT1920DL)",
    "value": "motorola moto e5 (XT1920DL)"
  },
  {
    "name": "d.light M200",
    "label": "d.light M200",
    "value": "d.light M200"
  },
  {
    "name": "Alco RCT6876Q22N",
    "label": "Alco RCT6876Q22N",
    "value": "Alco RCT6876Q22N"
  },
  {
    "name": "FISE K5",
    "label": "FISE K5",
    "value": "FISE K5"
  },
  {
    "name": "CellAllure Earn",
    "label": "CellAllure Earn",
    "value": "CellAllure Earn"
  },
  {
    "name": "HUAWEI BLN-AL40",
    "label": "HUAWEI BLN-AL40",
    "value": "HUAWEI BLN-AL40"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BD1",
    "label": "TECNO MOBILE LIMITED TECNO BD1",
    "value": "TECNO MOBILE LIMITED TECNO BD1"
  },
  {
    "name": "Ulefone Armor X8",
    "label": "Ulefone Armor X8",
    "value": "Ulefone Armor X8"
  },
  {
    "name": "KRIP K700A",
    "label": "KRIP K700A",
    "value": "KRIP K700A"
  },
  {
    "name": "LINKWIN Neo 4",
    "label": "LINKWIN Neo 4",
    "value": "LINKWIN Neo 4"
  },
  {
    "name": "TECNO TECNO PR651E",
    "label": "TECNO TECNO PR651E",
    "value": "TECNO TECNO PR651E"
  },
  {
    "name": "Google Pixel",
    "label": "Google Pixel",
    "value": "Google Pixel"
  },
  {
    "name": "samsung SAMSUNG-SM-G891A",
    "label": "samsung SAMSUNG-SM-G891A",
    "value": "samsung SAMSUNG-SM-G891A"
  },
  {
    "name": "Blackview A80Plus",
    "label": "Blackview A80Plus",
    "value": "Blackview A80Plus"
  },
  {
    "name": "FROG LOGIC L61",
    "label": "FROG LOGIC L61",
    "value": "FROG LOGIC L61"
  },
  {
    "name": "Sony I4312",
    "label": "Sony I4312",
    "value": "Sony I4312"
  },
  {
    "name": "Apple Lalla’s iPhone.",
    "label": "Apple Lalla’s iPhone.",
    "value": "Apple Lalla’s iPhone."
  },
  {
    "name": "Apple MaMtuse❤️",
    "label": "Apple MaMtuse❤️",
    "value": "Apple MaMtuse❤️"
  },
  {
    "name": "Apple Ntshiuwa’s iPhone",
    "label": "Apple Ntshiuwa’s iPhone",
    "value": "Apple Ntshiuwa’s iPhone"
  },
  {
    "name": "Ulefone Armor 7E",
    "label": "Ulefone Armor 7E",
    "value": "Ulefone Armor 7E"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LB6",
    "label": "TECNO MOBILE LIMITED TECNO LB6",
    "value": "TECNO MOBILE LIMITED TECNO LB6"
  },
  {
    "name": "LGE LG-H872",
    "label": "LGE LG-H872",
    "value": "LGE LG-H872"
  },
  {
    "name": "samsung SM-G965W",
    "label": "samsung SM-G965W",
    "value": "samsung SM-G965W"
  },
  {
    "name": "iPhone9,1 iPhone",
    "label": "iPhone9,1 iPhone",
    "value": "iPhone9,1 iPhone"
  },
  {
    "name": "Sony G3212",
    "label": "Sony G3212",
    "value": "Sony G3212"
  },
  {
    "name": "SSmooth Smooth 6.26 Pro",
    "label": "SSmooth Smooth 6.26 Pro",
    "value": "SSmooth Smooth 6.26 Pro"
  },
  {
    "name": "samsung SM-M215G",
    "label": "samsung SM-M215G",
    "value": "samsung SM-M215G"
  },
  {
    "name": "Apple Saaan",
    "label": "Apple Saaan",
    "value": "Apple Saaan"
  },
  {
    "name": "OnePlus KB2000",
    "label": "OnePlus KB2000",
    "value": "OnePlus KB2000"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X622",
    "label": "INFINIX MOBILITY LIMITED Infinix X622",
    "value": "INFINIX MOBILITY LIMITED Infinix X622"
  },
  {
    "name": "OPPO CPH1920",
    "label": "OPPO CPH1920",
    "value": "OPPO CPH1920"
  },
  {
    "name": "samsung SM-J530K",
    "label": "samsung SM-J530K",
    "value": "samsung SM-J530K"
  },
  {
    "name": "BLU T5",
    "label": "BLU T5",
    "value": "BLU T5"
  },
  {
    "name": "iPad7,11 iPad",
    "label": "iPad7,11 iPad",
    "value": "iPad7,11 iPad"
  },
  {
    "name": "vivo V2129",
    "label": "vivo V2129",
    "value": "vivo V2129"
  },
  {
    "name": "Apple Tshego’s iPhone",
    "label": "Apple Tshego’s iPhone",
    "value": "Apple Tshego’s iPhone"
  },
  {
    "name": "Apple Melizaestrada’s iPhone",
    "label": "Apple Melizaestrada’s iPhone",
    "value": "Apple Melizaestrada’s iPhone"
  },
  {
    "name": "Realme RMX1827",
    "label": "Realme RMX1827",
    "value": "Realme RMX1827"
  },
  {
    "name": "Apple 선별의 iPhone",
    "label": "Apple 선별의 iPhone",
    "value": "Apple 선별의 iPhone"
  },
  {
    "name": "MyPhone myT8",
    "label": "MyPhone myT8",
    "value": "MyPhone myT8"
  },
  {
    "name": "Apple iPhodes",
    "label": "Apple iPhodes",
    "value": "Apple iPhodes"
  },
  {
    "name": "LGE Nexus 5",
    "label": "LGE Nexus 5",
    "value": "LGE Nexus 5"
  },
  {
    "name": "WIKO W-K380-TH",
    "label": "WIKO W-K380-TH",
    "value": "WIKO W-K380-TH"
  },
  {
    "name": "Xiaomi MI PAD 4",
    "label": "Xiaomi MI PAD 4",
    "value": "Xiaomi MI PAD 4"
  },
  {
    "name": "realme RMX3562",
    "label": "realme RMX3562",
    "value": "realme RMX3562"
  },
  {
    "name": "ZTE ZTE A7020RU",
    "label": "ZTE ZTE A7020RU",
    "value": "ZTE ZTE A7020RU"
  },
  {
    "name": "FUJITSU F-05J",
    "label": "FUJITSU F-05J",
    "value": "FUJITSU F-05J"
  },
  {
    "name": "LENOVO Lenovo TB-X605M",
    "label": "LENOVO Lenovo TB-X605M",
    "value": "LENOVO Lenovo TB-X605M"
  },
  {
    "name": "samsung SM-A505FM",
    "label": "samsung SM-A505FM",
    "value": "samsung SM-A505FM"
  },
  {
    "name": "samsung SM-A2070",
    "label": "samsung SM-A2070",
    "value": "samsung SM-A2070"
  },
  {
    "name": "DOOGEE S96Pro",
    "label": "DOOGEE S96Pro",
    "value": "DOOGEE S96Pro"
  },
  {
    "name": "ONN 100015685-A",
    "label": "ONN 100015685-A",
    "value": "ONN 100015685-A"
  },
  {
    "name": "HUAWEI CAG-L22",
    "label": "HUAWEI CAG-L22",
    "value": "HUAWEI CAG-L22"
  },
  {
    "name": "IBRIT i5Plus",
    "label": "IBRIT i5Plus",
    "value": "IBRIT i5Plus"
  },
  {
    "name": "U6 PRIME U6 PRIME",
    "label": "U6 PRIME U6 PRIME",
    "value": "U6 PRIME U6 PRIME"
  },
  {
    "name": "Xiaomi M2104K10AC",
    "label": "Xiaomi M2104K10AC",
    "value": "Xiaomi M2104K10AC"
  },
  {
    "name": "FISE K55h",
    "label": "FISE K55h",
    "value": "FISE K55h"
  },
  {
    "name": "LANIX Ilium M7",
    "label": "LANIX Ilium M7",
    "value": "LANIX Ilium M7"
  },
  {
    "name": "Mobicel Neo_Plus",
    "label": "Mobicel Neo_Plus",
    "value": "Mobicel Neo_Plus"
  },
  {
    "name": "ZTE P651 2021",
    "label": "ZTE P651 2021",
    "value": "ZTE P651 2021"
  },
  {
    "name": "vmos vmos",
    "label": "vmos vmos",
    "value": "vmos vmos"
  },
  {
    "name": "HUAWEI HRY-LX2",
    "label": "HUAWEI HRY-LX2",
    "value": "HUAWEI HRY-LX2"
  },
  {
    "name": "motorola XT1609",
    "label": "motorola XT1609",
    "value": "motorola XT1609"
  },
  {
    "name": "HUAWEI NCO-LX1",
    "label": "HUAWEI NCO-LX1",
    "value": "HUAWEI NCO-LX1"
  },
  {
    "name": "itel itel A16 Plus",
    "label": "itel itel A16 Plus",
    "value": "itel itel A16 Plus"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CG7",
    "label": "TECNO MOBILE LIMITED TECNO CG7",
    "value": "TECNO MOBILE LIMITED TECNO CG7"
  },
  {
    "name": "LeEco LEX829",
    "label": "LeEco LEX829",
    "value": "LeEco LEX829"
  },
  {
    "name": "Hisense Hisense V40i",
    "label": "Hisense Hisense V40i",
    "value": "Hisense Hisense V40i"
  },
  {
    "name": "ZTE Blade A7S 2020-T",
    "label": "ZTE Blade A7S 2020-T",
    "value": "ZTE Blade A7S 2020-T"
  },
  {
    "name": "LGE LM-K610IM",
    "label": "LGE LM-K610IM",
    "value": "LGE LM-K610IM"
  },
  {
    "name": "SEN_1055_ARM SEN_1055_ARM",
    "label": "SEN_1055_ARM SEN_1055_ARM",
    "value": "SEN_1055_ARM SEN_1055_ARM"
  },
  {
    "name": "Positivo Quantum Q20",
    "label": "Positivo Quantum Q20",
    "value": "Positivo Quantum Q20"
  },
  {
    "name": "OnePlus CPH2401",
    "label": "OnePlus CPH2401",
    "value": "OnePlus CPH2401"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KF6",
    "label": "TECNO MOBILE LIMITED TECNO KF6",
    "value": "TECNO MOBILE LIMITED TECNO KF6"
  },
  {
    "name": "itel itel W5006X",
    "label": "itel itel W5006X",
    "value": "itel itel W5006X"
  },
  {
    "name": "vivo V2135",
    "label": "vivo V2135",
    "value": "vivo V2135"
  },
  {
    "name": "Maraphones Mara S",
    "label": "Maraphones Mara S",
    "value": "Maraphones Mara S"
  },
  {
    "name": "motorola motorola razr (2020)",
    "label": "motorola motorola razr (2020)",
    "value": "motorola motorola razr (2020)"
  },
  {
    "name": "FROG LOGIC L65 LITE",
    "label": "FROG LOGIC L65 LITE",
    "value": "FROG LOGIC L65 LITE"
  },
  {
    "name": "realme RMX3061",
    "label": "realme RMX3061",
    "value": "realme RMX3061"
  },
  {
    "name": "Xiaomi Redmi 3X",
    "label": "Xiaomi Redmi 3X",
    "value": "Xiaomi Redmi 3X"
  },
  {
    "name": "vivo V2217",
    "label": "vivo V2217",
    "value": "vivo V2217"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X652",
    "label": "INFINIX MOBILITY LIMITED Infinix X652",
    "value": "INFINIX MOBILITY LIMITED Infinix X652"
  },
  {
    "name": "samsung SM-X706N",
    "label": "samsung SM-X706N",
    "value": "samsung SM-X706N"
  },
  {
    "name": "samsung SM-N9005",
    "label": "samsung SM-N9005",
    "value": "samsung SM-N9005"
  },
  {
    "name": "Meizu 16",
    "label": "Meizu 16",
    "value": "Meizu 16"
  },
  {
    "name": "Sky_Devices Elite B65",
    "label": "Sky_Devices Elite B65",
    "value": "Sky_Devices Elite B65"
  },
  {
    "name": "LENOVO Lenovo TB-7306F",
    "label": "LENOVO Lenovo TB-7306F",
    "value": "LENOVO Lenovo TB-7306F"
  },
  {
    "name": "HTC HTC6535LVW",
    "label": "HTC HTC6535LVW",
    "value": "HTC HTC6535LVW"
  },
  {
    "name": "bq Aquaris C",
    "label": "bq Aquaris C",
    "value": "bq Aquaris C"
  },
  {
    "name": "samsung SM-M336K",
    "label": "samsung SM-M336K",
    "value": "samsung SM-M336K"
  },
  {
    "name": "HUAWEI ART-L29N",
    "label": "HUAWEI ART-L29N",
    "value": "HUAWEI ART-L29N"
  },
  {
    "name": "Bmobile AX688",
    "label": "Bmobile AX688",
    "value": "Bmobile AX688"
  },
  {
    "name": "motorola REVVLRY",
    "label": "motorola REVVLRY",
    "value": "motorola REVVLRY"
  },
  {
    "name": "sanmu A7",
    "label": "sanmu A7",
    "value": "sanmu A7"
  },
  {
    "name": "BLU Vivo XI PLUS",
    "label": "BLU Vivo XI PLUS",
    "value": "BLU Vivo XI PLUS"
  },
  {
    "name": "TCL T810H",
    "label": "TCL T810H",
    "value": "TCL T810H"
  },
  {
    "name": "BLU G8",
    "label": "BLU G8",
    "value": "BLU G8"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LE8",
    "label": "TECNO MOBILE LIMITED TECNO LE8",
    "value": "TECNO MOBILE LIMITED TECNO LE8"
  },
  {
    "name": "positivo T810",
    "label": "positivo T810",
    "value": "positivo T810"
  },
  {
    "name": "SSmooth Smooth 5.5 MAX",
    "label": "SSmooth Smooth 5.5 MAX",
    "value": "SSmooth Smooth 5.5 MAX"
  },
  {
    "name": "ITEL MOBILE LIMITED itel P551W",
    "label": "ITEL MOBILE LIMITED itel P551W",
    "value": "ITEL MOBILE LIMITED itel P551W"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO Mobile KG6k",
    "label": "TECNO MOBILE LIMITED TECNO Mobile KG6k",
    "value": "TECNO MOBILE LIMITED TECNO Mobile KG6k"
  },
  {
    "name": "TECNO TECNO BE8",
    "label": "TECNO TECNO BE8",
    "value": "TECNO TECNO BE8"
  },
  {
    "name": "OnePlus LE2121",
    "label": "OnePlus LE2121",
    "value": "OnePlus LE2121"
  },
  {
    "name": "OUKITEL C21",
    "label": "OUKITEL C21",
    "value": "OUKITEL C21"
  },
  {
    "name": "HXY A11 Pro Max",
    "label": "HXY A11 Pro Max",
    "value": "HXY A11 Pro Max"
  },
  {
    "name": "asus ASUS_I003D",
    "label": "asus ASUS_I003D",
    "value": "asus ASUS_I003D"
  },
  {
    "name": "TCL 8094M",
    "label": "TCL 8094M",
    "value": "TCL 8094M"
  },
  {
    "name": "incar 8DY",
    "label": "incar 8DY",
    "value": "incar 8DY"
  },
  {
    "name": "HYUNDAI L553",
    "label": "HYUNDAI L553",
    "value": "HYUNDAI L553"
  },
  {
    "name": "Mobicel OREO",
    "label": "Mobicel OREO",
    "value": "Mobicel OREO"
  },
  {
    "name": "IMG INKOSI",
    "label": "IMG INKOSI",
    "value": "IMG INKOSI"
  },
  {
    "name": "LENOVO Lenovo YT-J706X",
    "label": "LENOVO Lenovo YT-J706X",
    "value": "LENOVO Lenovo YT-J706X"
  },
  {
    "name": "OUKITEL C21 Pro",
    "label": "OUKITEL C21 Pro",
    "value": "OUKITEL C21 Pro"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A15",
    "label": "ITEL MOBILE LIMITED itel A15",
    "value": "ITEL MOBILE LIMITED itel A15"
  },
  {
    "name": "OPPO CPH2305",
    "label": "OPPO CPH2305",
    "value": "OPPO CPH2305"
  },
  {
    "name": "samsung SM-S901U1",
    "label": "samsung SM-S901U1",
    "value": "samsung SM-S901U1"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CF8",
    "label": "TECNO MOBILE LIMITED TECNO CF8",
    "value": "TECNO MOBILE LIMITED TECNO CF8"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KB7",
    "label": "TECNO MOBILE LIMITED TECNO KB7",
    "value": "TECNO MOBILE LIMITED TECNO KB7"
  },
  {
    "name": "samsung SM-G986U1",
    "label": "samsung SM-G986U1",
    "value": "samsung SM-G986U1"
  },
  {
    "name": "GHIA GA7133",
    "label": "GHIA GA7133",
    "value": "GHIA GA7133"
  },
  {
    "name": "Multilaser Multilaser_GPRO2",
    "label": "Multilaser Multilaser_GPRO2",
    "value": "Multilaser Multilaser_GPRO2"
  },
  {
    "name": "X-TIGI V18 pro_A",
    "label": "X-TIGI V18 pro_A",
    "value": "X-TIGI V18 pro_A"
  },
  {
    "name": "KRIP K5m",
    "label": "KRIP K5m",
    "value": "KRIP K5m"
  },
  {
    "name": "HUAWEI AGS-W09",
    "label": "HUAWEI AGS-W09",
    "value": "HUAWEI AGS-W09"
  },
  {
    "name": "wheatek Digi C2",
    "label": "wheatek Digi C2",
    "value": "wheatek Digi C2"
  },
  {
    "name": "WIKO W-K560-TVM",
    "label": "WIKO W-K560-TVM",
    "value": "WIKO W-K560-TVM"
  },
  {
    "name": "Xiaomi Redmi K30 5G",
    "label": "Xiaomi Redmi K30 5G",
    "value": "Xiaomi Redmi K30 5G"
  },
  {
    "name": "Xiaomi 2201122G",
    "label": "Xiaomi 2201122G",
    "value": "Xiaomi 2201122G"
  },
  {
    "name": "TCL Alpha 20",
    "label": "TCL Alpha 20",
    "value": "TCL Alpha 20"
  },
  {
    "name": "Apple Letisha",
    "label": "Apple Letisha",
    "value": "Apple Letisha"
  },
  {
    "name": "Apple Lorato’s iPhone",
    "label": "Apple Lorato’s iPhone",
    "value": "Apple Lorato’s iPhone"
  },
  {
    "name": "Apple Takunda’s iPhone",
    "label": "Apple Takunda’s iPhone",
    "value": "Apple Takunda’s iPhone"
  },
  {
    "name": "TCL T601DL",
    "label": "TCL T601DL",
    "value": "TCL T601DL"
  },
  {
    "name": "WIKO W-V745-EEA",
    "label": "WIKO W-V745-EEA",
    "value": "WIKO W-V745-EEA"
  },
  {
    "name": "WIKO W-K630-EEA",
    "label": "WIKO W-K630-EEA",
    "value": "WIKO W-K630-EEA"
  },
  {
    "name": "samsung SM-G955W",
    "label": "samsung SM-G955W",
    "value": "samsung SM-G955W"
  },
  {
    "name": "Apple Kefilwe’s iPhone",
    "label": "Apple Kefilwe’s iPhone",
    "value": "Apple Kefilwe’s iPhone"
  },
  {
    "name": "LGE LG-AS993",
    "label": "LGE LG-AS993",
    "value": "LGE LG-AS993"
  },
  {
    "name": "Apple iPhone Xr",
    "label": "Apple iPhone Xr",
    "value": "Apple iPhone Xr"
  },
  {
    "name": "Mara Mara Z d",
    "label": "Mara Mara Z d",
    "value": "Mara Mara Z d"
  },
  {
    "name": "Apple Kholofelo’s iPhone",
    "label": "Apple Kholofelo’s iPhone",
    "value": "Apple Kholofelo’s iPhone"
  },
  {
    "name": "PCSMART QUICKTAB PLUS",
    "label": "PCSMART QUICKTAB PLUS",
    "value": "PCSMART QUICKTAB PLUS"
  },
  {
    "name": "LGE LM-Q815S",
    "label": "LGE LM-Q815S",
    "value": "LGE LM-Q815S"
  },
  {
    "name": "BLU G50 Plus",
    "label": "BLU G50 Plus",
    "value": "BLU G50 Plus"
  },
  {
    "name": "WIKO W-V770",
    "label": "WIKO W-V770",
    "value": "WIKO W-V770"
  },
  {
    "name": "Multilaser MS50G",
    "label": "Multilaser MS50G",
    "value": "Multilaser MS50G"
  },
  {
    "name": "Sony G3423",
    "label": "Sony G3423",
    "value": "Sony G3423"
  },
  {
    "name": "samsung SM-G975W",
    "label": "samsung SM-G975W",
    "value": "samsung SM-G975W"
  },
  {
    "name": "Mobile App - iOS",
    "label": "Mobile App - iOS",
    "value": "Mobile App - iOS"
  },
  {
    "name": "Apple Otsile’s iPhone",
    "label": "Apple Otsile’s iPhone",
    "value": "Apple Otsile’s iPhone"
  },
  {
    "name": "GIONEE GIONEE S11",
    "label": "GIONEE GIONEE S11",
    "value": "GIONEE GIONEE S11"
  },
  {
    "name": "OnePlus CPH2423",
    "label": "OnePlus CPH2423",
    "value": "OnePlus CPH2423"
  },
  {
    "name": "SNCOIA QT2108B",
    "label": "SNCOIA QT2108B",
    "value": "SNCOIA QT2108B"
  },
  {
    "name": "TP-Link Neffos C7 Lite",
    "label": "TP-Link Neffos C7 Lite",
    "value": "TP-Link Neffos C7 Lite"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KF6ks",
    "label": "TECNO MOBILE LIMITED TECNO KF6ks",
    "value": "TECNO MOBILE LIMITED TECNO KF6ks"
  },
  {
    "name": "TINNO SUGAR T35",
    "label": "TINNO SUGAR T35",
    "value": "TINNO SUGAR T35"
  },
  {
    "name": "Sony H8416",
    "label": "Sony H8416",
    "value": "Sony H8416"
  },
  {
    "name": "Maxwest Nitro 55A",
    "label": "Maxwest Nitro 55A",
    "value": "Maxwest Nitro 55A"
  },
  {
    "name": "Multilaser M10-4G",
    "label": "Multilaser M10-4G",
    "value": "Multilaser M10-4G"
  },
  {
    "name": "samsung SM-A013F",
    "label": "samsung SM-A013F",
    "value": "samsung SM-A013F"
  },
  {
    "name": "HUAWEI MGA-LX3",
    "label": "HUAWEI MGA-LX3",
    "value": "HUAWEI MGA-LX3"
  },
  {
    "name": "SHARP SHV41",
    "label": "SHARP SHV41",
    "value": "SHARP SHV41"
  },
  {
    "name": "OnePlus GM1915",
    "label": "OnePlus GM1915",
    "value": "OnePlus GM1915"
  },
  {
    "name": "Apple iPad8,11",
    "label": "Apple iPad8,11",
    "value": "Apple iPad8,11"
  },
  {
    "name": "Sony H9436",
    "label": "Sony H9436",
    "value": "Sony H9436"
  },
  {
    "name": "iHunt Titan P4000 Pro 2021_EEA",
    "label": "iHunt Titan P4000 Pro 2021_EEA",
    "value": "iHunt Titan P4000 Pro 2021_EEA"
  },
  {
    "name": "samsung SM-G9350",
    "label": "samsung SM-G9350",
    "value": "samsung SM-G9350"
  },
  {
    "name": "PREMIO X88",
    "label": "PREMIO X88",
    "value": "PREMIO X88"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO RC6",
    "label": "TECNO MOBILE LIMITED TECNO RC6",
    "value": "TECNO MOBILE LIMITED TECNO RC6"
  },
  {
    "name": "BLU C5 Plus",
    "label": "BLU C5 Plus",
    "value": "BLU C5 Plus"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X608",
    "label": "INFINIX MOBILITY LIMITED Infinix X608",
    "value": "INFINIX MOBILITY LIMITED Infinix X608"
  },
  {
    "name": "LAVA Z61",
    "label": "LAVA Z61",
    "value": "LAVA Z61"
  },
  {
    "name": "Mobicel X1",
    "label": "Mobicel X1",
    "value": "Mobicel X1"
  },
  {
    "name": "Cherry A870",
    "label": "Cherry A870",
    "value": "Cherry A870"
  },
  {
    "name": "Desktop - NetBSD",
    "label": "Desktop - NetBSD",
    "value": "Desktop - NetBSD"
  },
  {
    "name": "PERSONA MyTab_A8",
    "label": "PERSONA MyTab_A8",
    "value": "PERSONA MyTab_A8"
  },
  {
    "name": "Xiaomi REDMI 8",
    "label": "Xiaomi REDMI 8",
    "value": "Xiaomi REDMI 8"
  },
  {
    "name": "samsung SM-A750C",
    "label": "samsung SM-A750C",
    "value": "samsung SM-A750C"
  },
  {
    "name": "DIXON L1",
    "label": "DIXON L1",
    "value": "DIXON L1"
  },
  {
    "name": "samsung SM-G611S",
    "label": "samsung SM-G611S",
    "value": "samsung SM-G611S"
  },
  {
    "name": "OPPO CPH1827",
    "label": "OPPO CPH1827",
    "value": "OPPO CPH1827"
  },
  {
    "name": "realme RMX3265",
    "label": "realme RMX3265",
    "value": "realme RMX3265"
  },
  {
    "name": "CUBOT X19 S",
    "label": "CUBOT X19 S",
    "value": "CUBOT X19 S"
  },
  {
    "name": "ZTE Blade A3 2020-T",
    "label": "ZTE Blade A3 2020-T",
    "value": "ZTE Blade A3 2020-T"
  },
  {
    "name": "Welcome i13 Pro Max",
    "label": "Welcome i13 Pro Max",
    "value": "Welcome i13 Pro Max"
  },
  {
    "name": "LENOVO Lenovo YT-X705L",
    "label": "LENOVO Lenovo YT-X705L",
    "value": "LENOVO Lenovo YT-X705L"
  },
  {
    "name": "ZXD KEMPLER_P1",
    "label": "ZXD KEMPLER_P1",
    "value": "ZXD KEMPLER_P1"
  },
  {
    "name": "incar SILVERSTONE-T10",
    "label": "incar SILVERSTONE-T10",
    "value": "incar SILVERSTONE-T10"
  },
  {
    "name": "Apple Vanessa’s iPhone",
    "label": "Apple Vanessa’s iPhone",
    "value": "Apple Vanessa’s iPhone"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X660B",
    "label": "INFINIX MOBILITY LIMITED Infinix X660B",
    "value": "INFINIX MOBILITY LIMITED Infinix X660B"
  },
  {
    "name": "motorola motorola edge 20 fusion",
    "label": "motorola motorola edge 20 fusion",
    "value": "motorola motorola edge 20 fusion"
  },
  {
    "name": "Apple Bigman Connors Phone",
    "label": "Apple Bigman Connors Phone",
    "value": "Apple Bigman Connors Phone"
  },
  {
    "name": "Apple MADMAXX",
    "label": "Apple MADMAXX",
    "value": "Apple MADMAXX"
  },
  {
    "name": "INCAR A860",
    "label": "INCAR A860",
    "value": "INCAR A860"
  },
  {
    "name": "samsung SM-J727S",
    "label": "samsung SM-J727S",
    "value": "samsung SM-J727S"
  },
  {
    "name": "HUAWEI ALP-AL00",
    "label": "HUAWEI ALP-AL00",
    "value": "HUAWEI ALP-AL00"
  },
  {
    "name": "Apple Abigail’s iPhone",
    "label": "Apple Abigail’s iPhone",
    "value": "Apple Abigail’s iPhone"
  },
  {
    "name": "Apple iPhone 12",
    "label": "Apple iPhone 12",
    "value": "Apple iPhone 12"
  },
  {
    "name": "incar INTERLAGOS-T32",
    "label": "incar INTERLAGOS-T32",
    "value": "incar INTERLAGOS-T32"
  },
  {
    "name": "Desktop - openSUSE",
    "label": "Desktop - openSUSE",
    "value": "Desktop - openSUSE"
  },
  {
    "name": "Xiaomi M2007J3SI",
    "label": "Xiaomi M2007J3SI",
    "value": "Xiaomi M2007J3SI"
  },
  {
    "name": "Apple iPhone 8",
    "label": "Apple iPhone 8",
    "value": "Apple iPhone 8"
  },
  {
    "name": "samsung SM-A045M",
    "label": "samsung SM-A045M",
    "value": "samsung SM-A045M"
  },
  {
    "name": "TCL 4187M",
    "label": "TCL 4187M",
    "value": "TCL 4187M"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KF6k",
    "label": "TECNO MOBILE LIMITED TECNO KF6k",
    "value": "TECNO MOBILE LIMITED TECNO KF6k"
  },
  {
    "name": "TCL 9032Z",
    "label": "TCL 9032Z",
    "value": "TCL 9032Z"
  },
  {
    "name": "samsung SM-A236E",
    "label": "samsung SM-A236E",
    "value": "samsung SM-A236E"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CE9",
    "label": "TECNO MOBILE LIMITED TECNO CE9",
    "value": "TECNO MOBILE LIMITED TECNO CE9"
  },
  {
    "name": "HMD Global N152DL",
    "label": "HMD Global N152DL",
    "value": "HMD Global N152DL"
  },
  {
    "name": "STYLO BRAVE",
    "label": "STYLO BRAVE",
    "value": "STYLO BRAVE"
  },
  {
    "name": "INFINIX Infinix X665C",
    "label": "INFINIX Infinix X665C",
    "value": "INFINIX Infinix X665C"
  },
  {
    "name": "realme RMX3474",
    "label": "realme RMX3474",
    "value": "realme RMX3474"
  },
  {
    "name": "ZUUM STELLAR M1",
    "label": "ZUUM STELLAR M1",
    "value": "ZUUM STELLAR M1"
  },
  {
    "name": "samsung SM-P613",
    "label": "samsung SM-P613",
    "value": "samsung SM-P613"
  },
  {
    "name": "samsung SM-F936W",
    "label": "samsung SM-F936W",
    "value": "samsung SM-F936W"
  },
  {
    "name": "Ulefone T2",
    "label": "Ulefone T2",
    "value": "Ulefone T2"
  },
  {
    "name": "incar Q10107L-ME",
    "label": "incar Q10107L-ME",
    "value": "incar Q10107L-ME"
  },
  {
    "name": "HXY A23",
    "label": "HXY A23",
    "value": "HXY A23"
  },
  {
    "name": "motorola REVVLRY+",
    "label": "motorola REVVLRY+",
    "value": "motorola REVVLRY+"
  },
  {
    "name": "DOOGEE S40Pro",
    "label": "DOOGEE S40Pro",
    "value": "DOOGEE S40Pro"
  },
  {
    "name": "HUAWEI JLN-LX3",
    "label": "HUAWEI JLN-LX3",
    "value": "HUAWEI JLN-LX3"
  },
  {
    "name": "alps Tab_P8",
    "label": "alps Tab_P8",
    "value": "alps Tab_P8"
  },
  {
    "name": "Sony G8341",
    "label": "Sony G8341",
    "value": "Sony G8341"
  },
  {
    "name": "wheatek Cyber 9 Pro",
    "label": "wheatek Cyber 9 Pro",
    "value": "wheatek Cyber 9 Pro"
  },
  {
    "name": "LGE LM-K920",
    "label": "LGE LM-K920",
    "value": "LGE LM-K920"
  },
  {
    "name": "GIONEE M11",
    "label": "GIONEE M11",
    "value": "GIONEE M11"
  },
  {
    "name": "vivo vivo Y11",
    "label": "vivo vivo Y11",
    "value": "vivo vivo Y11"
  },
  {
    "name": "samsung SCV37",
    "label": "samsung SCV37",
    "value": "samsung SCV37"
  },
  {
    "name": "Wingtech WTVIS01",
    "label": "Wingtech WTVIS01",
    "value": "Wingtech WTVIS01"
  },
  {
    "name": "ZTE BLADE V9",
    "label": "ZTE BLADE V9",
    "value": "ZTE BLADE V9"
  },
  {
    "name": "ZUUM STELLAR M3",
    "label": "ZUUM STELLAR M3",
    "value": "ZUUM STELLAR M3"
  },
  {
    "name": "samsung SM-G996U1",
    "label": "samsung SM-G996U1",
    "value": "samsung SM-G996U1"
  },
  {
    "name": "samsung SM-S127DL",
    "label": "samsung SM-S127DL",
    "value": "samsung SM-S127DL"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO BD4i",
    "label": "TECNO MOBILE LIMITED TECNO BD4i",
    "value": "TECNO MOBILE LIMITED TECNO BD4i"
  },
  {
    "name": "TP-Link Neffos_C9",
    "label": "TP-Link Neffos_C9",
    "value": "TP-Link Neffos_C9"
  },
  {
    "name": "Bmobile AX1076+",
    "label": "Bmobile AX1076+",
    "value": "Bmobile AX1076+"
  },
  {
    "name": "samsung SM-J337VPP",
    "label": "samsung SM-J337VPP",
    "value": "samsung SM-J337VPP"
  },
  {
    "name": "samsung SM-A326U1",
    "label": "samsung SM-A326U1",
    "value": "samsung SM-A326U1"
  },
  {
    "name": "HUAWEI CDY-NX9B",
    "label": "HUAWEI CDY-NX9B",
    "value": "HUAWEI CDY-NX9B"
  },
  {
    "name": "LENOVO Lenovo TB-J606L",
    "label": "LENOVO Lenovo TB-J606L",
    "value": "LENOVO Lenovo TB-J606L"
  },
  {
    "name": "positivo YOLO",
    "label": "positivo YOLO",
    "value": "positivo YOLO"
  },
  {
    "name": "realme RMX3686",
    "label": "realme RMX3686",
    "value": "realme RMX3686"
  },
  {
    "name": "Mobile Web - Firefox OS",
    "label": "Mobile Web - Firefox OS",
    "value": "Mobile Web - Firefox OS"
  },
  {
    "name": "Kamogelo’s iPad iPad",
    "label": "Kamogelo’s iPad iPad",
    "value": "Kamogelo’s iPad iPad"
  },
  {
    "name": "Apple Kabelo’s iPhone",
    "label": "Apple Kabelo’s iPhone",
    "value": "Apple Kabelo’s iPhone"
  },
  {
    "name": "Cherry_Mobile Flare_S7_Mini",
    "label": "Cherry_Mobile Flare_S7_Mini",
    "value": "Cherry_Mobile Flare_S7_Mini"
  },
  {
    "name": "Apple Carlie’s iPhone",
    "label": "Apple Carlie’s iPhone",
    "value": "Apple Carlie’s iPhone"
  },
  {
    "name": "Apple Masego’s iPhone",
    "label": "Apple Masego’s iPhone",
    "value": "Apple Masego’s iPhone"
  },
  {
    "name": "TINNO U307AS",
    "label": "TINNO U307AS",
    "value": "TINNO U307AS"
  },
  {
    "name": "LGE LM-V700N",
    "label": "LGE LM-V700N",
    "value": "LGE LM-V700N"
  },
  {
    "name": "Cherry_Mobile Flare_S8_Pro",
    "label": "Cherry_Mobile Flare_S8_Pro",
    "value": "Cherry_Mobile Flare_S8_Pro"
  },
  {
    "name": "Apple iPhone de Mariana",
    "label": "Apple iPhone de Mariana",
    "value": "Apple iPhone de Mariana"
  },
  {
    "name": "TECNO TECNO CI7n",
    "label": "TECNO TECNO CI7n",
    "value": "TECNO TECNO CI7n"
  },
  {
    "name": "Sony SO-04J",
    "label": "Sony SO-04J",
    "value": "Sony SO-04J"
  },
  {
    "name": "itel itel W5005P",
    "label": "itel itel W5005P",
    "value": "itel itel W5005P"
  },
  {
    "name": "TEM LOGIC L50T",
    "label": "TEM LOGIC L50T",
    "value": "TEM LOGIC L50T"
  },
  {
    "name": "ZTE ZTE Blade L9",
    "label": "ZTE ZTE Blade L9",
    "value": "ZTE ZTE Blade L9"
  },
  {
    "name": "Multilaser Multilaser_G_Max",
    "label": "Multilaser Multilaser_G_Max",
    "value": "Multilaser Multilaser_G_Max"
  },
  {
    "name": "INFINIX Infinix X676B",
    "label": "INFINIX Infinix X676B",
    "value": "INFINIX Infinix X676B"
  },
  {
    "name": "incar M40Pro_EEA",
    "label": "incar M40Pro_EEA",
    "value": "incar M40Pro_EEA"
  },
  {
    "name": "General Mobile GM 5 Plus",
    "label": "General Mobile GM 5 Plus",
    "value": "General Mobile GM 5 Plus"
  },
  {
    "name": "Infinix Infinix PR652C",
    "label": "Infinix Infinix PR652C",
    "value": "Infinix Infinix PR652C"
  },
  {
    "name": "HUAWEI ELS-NX9",
    "label": "HUAWEI ELS-NX9",
    "value": "HUAWEI ELS-NX9"
  },
  {
    "name": "HXY RP01",
    "label": "HXY RP01",
    "value": "HXY RP01"
  },
  {
    "name": "TCL 6165A",
    "label": "TCL 6165A",
    "value": "TCL 6165A"
  },
  {
    "name": "Innovatech EX8S1",
    "label": "Innovatech EX8S1",
    "value": "Innovatech EX8S1"
  },
  {
    "name": "Olax Olax Prime 12",
    "label": "Olax Olax Prime 12",
    "value": "Olax Olax Prime 12"
  },
  {
    "name": "ZTE ZTE 9030",
    "label": "ZTE ZTE 9030",
    "value": "ZTE ZTE 9030"
  },
  {
    "name": "TCL 4187U",
    "label": "TCL 4187U",
    "value": "TCL 4187U"
  },
  {
    "name": "nubia NX907J",
    "label": "nubia NX907J",
    "value": "nubia NX907J"
  },
  {
    "name": "OPPO OPPO R11s",
    "label": "OPPO OPPO R11s",
    "value": "OPPO OPPO R11s"
  },
  {
    "name": "samsung SM-J700P",
    "label": "samsung SM-J700P",
    "value": "samsung SM-J700P"
  },
  {
    "name": "OPPO CPH1851",
    "label": "OPPO CPH1851",
    "value": "OPPO CPH1851"
  },
  {
    "name": "Vodafone VFD 730",
    "label": "Vodafone VFD 730",
    "value": "Vodafone VFD 730"
  },
  {
    "name": "BLU G50",
    "label": "BLU G50",
    "value": "BLU G50"
  },
  {
    "name": "CUBOT R15",
    "label": "CUBOT R15",
    "value": "CUBOT R15"
  },
  {
    "name": "motorola moto g pro",
    "label": "motorola moto g pro",
    "value": "motorola moto g pro"
  },
  {
    "name": "samsung SCG02",
    "label": "samsung SCG02",
    "value": "samsung SCG02"
  },
  {
    "name": "magcomm ALIGATOR S6500",
    "label": "magcomm ALIGATOR S6500",
    "value": "magcomm ALIGATOR S6500"
  },
  {
    "name": "HUAWEI NEN-LX3",
    "label": "HUAWEI NEN-LX3",
    "value": "HUAWEI NEN-LX3"
  },
  {
    "name": "TCL 5033FP",
    "label": "TCL 5033FP",
    "value": "TCL 5033FP"
  },
  {
    "name": "samsung SM-A136U1",
    "label": "samsung SM-A136U1",
    "value": "samsung SM-A136U1"
  },
  {
    "name": "Desktop - Solaris",
    "label": "Desktop - Solaris",
    "value": "Desktop - Solaris"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KB2j",
    "label": "TECNO MOBILE LIMITED TECNO KB2j",
    "value": "TECNO MOBILE LIMITED TECNO KB2j"
  },
  {
    "name": "Realme RMX1833",
    "label": "Realme RMX1833",
    "value": "Realme RMX1833"
  },
  {
    "name": "coolpad 1825-I01",
    "label": "coolpad 1825-I01",
    "value": "coolpad 1825-I01"
  },
  {
    "name": "Sony G3226",
    "label": "Sony G3226",
    "value": "Sony G3226"
  },
  {
    "name": "samsung SM-A125W",
    "label": "samsung SM-A125W",
    "value": "samsung SM-A125W"
  },
  {
    "name": "samsung SM-G991Q",
    "label": "samsung SM-G991Q",
    "value": "samsung SM-G991Q"
  },
  {
    "name": "Symphony Z25",
    "label": "Symphony Z25",
    "value": "Symphony Z25"
  },
  {
    "name": "Innovatech A20S",
    "label": "Innovatech A20S",
    "value": "Innovatech A20S"
  },
  {
    "name": "samsung SM-T380",
    "label": "samsung SM-T380",
    "value": "samsung SM-T380"
  },
  {
    "name": "Philco Hit P12",
    "label": "Philco Hit P12",
    "value": "Philco Hit P12"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO AC8",
    "label": "TECNO MOBILE LIMITED TECNO AC8",
    "value": "TECNO MOBILE LIMITED TECNO AC8"
  },
  {
    "name": "samsung SM-A720S",
    "label": "samsung SM-A720S",
    "value": "samsung SM-A720S"
  },
  {
    "name": "SPA Condor Electronics Plume L6 Pro",
    "label": "SPA Condor Electronics Plume L6 Pro",
    "value": "SPA Condor Electronics Plume L6 Pro"
  },
  {
    "name": "Cherry_Mobile Flare_S7",
    "label": "Cherry_Mobile Flare_S7",
    "value": "Cherry_Mobile Flare_S7"
  },
  {
    "name": "samsung SM-J337P",
    "label": "samsung SM-J337P",
    "value": "samsung SM-J337P"
  },
  {
    "name": "motorola XT1650",
    "label": "motorola XT1650",
    "value": "motorola XT1650"
  },
  {
    "name": "TP-LINK Neffos C5 Plus",
    "label": "TP-LINK Neffos C5 Plus",
    "value": "TP-LINK Neffos C5 Plus"
  },
  {
    "name": "TCL 9013A",
    "label": "TCL 9013A",
    "value": "TCL 9013A"
  },
  {
    "name": "samsung SM-J737T",
    "label": "samsung SM-J737T",
    "value": "samsung SM-J737T"
  },
  {
    "name": "vivo V2212",
    "label": "vivo V2212",
    "value": "vivo V2212"
  },
  {
    "name": "Lanix Ilium M9V",
    "label": "Lanix Ilium M9V",
    "value": "Lanix Ilium M9V"
  },
  {
    "name": "vivo vivo 1940",
    "label": "vivo vivo 1940",
    "value": "vivo vivo 1940"
  },
  {
    "name": "ADVAN 8001",
    "label": "ADVAN 8001",
    "value": "ADVAN 8001"
  },
  {
    "name": "SKY_DEVICES Elite_D55",
    "label": "SKY_DEVICES Elite_D55",
    "value": "SKY_DEVICES Elite_D55"
  },
  {
    "name": "samsung SM-T307U",
    "label": "samsung SM-T307U",
    "value": "samsung SM-T307U"
  },
  {
    "name": "BLU Studio X5 Max",
    "label": "BLU Studio X5 Max",
    "value": "BLU Studio X5 Max"
  },
  {
    "name": "Xiaomi Mi 10 Pro",
    "label": "Xiaomi Mi 10 Pro",
    "value": "Xiaomi Mi 10 Pro"
  },
  {
    "name": "motorola Lenovo K10",
    "label": "motorola Lenovo K10",
    "value": "motorola Lenovo K10"
  },
  {
    "name": "FISE K4b",
    "label": "FISE K4b",
    "value": "FISE K4b"
  },
  {
    "name": "FIH EC211003",
    "label": "FIH EC211003",
    "value": "FIH EC211003"
  },
  {
    "name": "TECNO TECNO LF7",
    "label": "TECNO TECNO LF7",
    "value": "TECNO TECNO LF7"
  },
  {
    "name": "OPPO CPH1938",
    "label": "OPPO CPH1938",
    "value": "OPPO CPH1938"
  },
  {
    "name": "Skyworth MEP2Q421G",
    "label": "Skyworth MEP2Q421G",
    "value": "Skyworth MEP2Q421G"
  },
  {
    "name": "Reliance Communications RC545L",
    "label": "Reliance Communications RC545L",
    "value": "Reliance Communications RC545L"
  },
  {
    "name": "YEZZ MAX_2_Ultra",
    "label": "YEZZ MAX_2_Ultra",
    "value": "YEZZ MAX_2_Ultra"
  },
  {
    "name": "Fortune Ship SPC GEN LITE",
    "label": "Fortune Ship SPC GEN LITE",
    "value": "Fortune Ship SPC GEN LITE"
  },
  {
    "name": "Mobicel Clik plus",
    "label": "Mobicel Clik plus",
    "value": "Mobicel Clik plus"
  },
  {
    "name": "IMUZ M101",
    "label": "IMUZ M101",
    "value": "IMUZ M101"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X652A",
    "label": "INFINIX MOBILITY LIMITED Infinix X652A",
    "value": "INFINIX MOBILITY LIMITED Infinix X652A"
  },
  {
    "name": "OPPO CPH1609",
    "label": "OPPO CPH1609",
    "value": "OPPO CPH1609"
  },
  {
    "name": "MobiWire S13",
    "label": "MobiWire S13",
    "value": "MobiWire S13"
  },
  {
    "name": "FIH EC211001",
    "label": "FIH EC211001",
    "value": "FIH EC211001"
  },
  {
    "name": "Xiaomi 220733SI",
    "label": "Xiaomi 220733SI",
    "value": "Xiaomi 220733SI"
  },
  {
    "name": "STYLO V",
    "label": "STYLO V",
    "value": "STYLO V"
  },
  {
    "name": "realme RMP2102",
    "label": "realme RMP2102",
    "value": "realme RMP2102"
  },
  {
    "name": "HUAWEI RVL-AL09",
    "label": "HUAWEI RVL-AL09",
    "value": "HUAWEI RVL-AL09"
  },
  {
    "name": "OnePlus NE2215",
    "label": "OnePlus NE2215",
    "value": "OnePlus NE2215"
  },
  {
    "name": "ZTE Orange Neva jet",
    "label": "ZTE Orange Neva jet",
    "value": "ZTE Orange Neva jet"
  },
  {
    "name": "magcomm P1",
    "label": "magcomm P1",
    "value": "magcomm P1"
  },
  {
    "name": "wheatek A80",
    "label": "wheatek A80",
    "value": "wheatek A80"
  },
  {
    "name": "Apple Faith’s iPhone",
    "label": "Apple Faith’s iPhone",
    "value": "Apple Faith’s iPhone"
  },
  {
    "name": "UMIDIGI A3S",
    "label": "UMIDIGI A3S",
    "value": "UMIDIGI A3S"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X660C",
    "label": "INFINIX MOBILITY LIMITED Infinix X660C",
    "value": "INFINIX MOBILITY LIMITED Infinix X660C"
  },
  {
    "name": "BluewaveGlobal WV8R_N",
    "label": "BluewaveGlobal WV8R_N",
    "value": "BluewaveGlobal WV8R_N"
  },
  {
    "name": "Blackview BV9900",
    "label": "Blackview BV9900",
    "value": "Blackview BV9900"
  },
  {
    "name": "Cherry Mobile Aqua Tab Deluxe",
    "label": "Cherry Mobile Aqua Tab Deluxe",
    "value": "Cherry Mobile Aqua Tab Deluxe"
  },
  {
    "name": "Ulefone Note_7P",
    "label": "Ulefone Note_7P",
    "value": "Ulefone Note_7P"
  },
  {
    "name": "OPPO PBBM30",
    "label": "OPPO PBBM30",
    "value": "OPPO PBBM30"
  },
  {
    "name": "samsung SM-G611K",
    "label": "samsung SM-G611K",
    "value": "samsung SM-G611K"
  },
  {
    "name": "hongyu Stylo_521",
    "label": "hongyu Stylo_521",
    "value": "hongyu Stylo_521"
  },
  {
    "name": "LENOVO Lenovo TB-X605L",
    "label": "LENOVO Lenovo TB-X605L",
    "value": "LENOVO Lenovo TB-X605L"
  },
  {
    "name": "OPPO PBAM00",
    "label": "OPPO PBAM00",
    "value": "OPPO PBAM00"
  },
  {
    "name": "INFINIX Infinix X6819",
    "label": "INFINIX Infinix X6819",
    "value": "INFINIX Infinix X6819"
  },
  {
    "name": "TCL REVVL 2 PLUS",
    "label": "TCL REVVL 2 PLUS",
    "value": "TCL REVVL 2 PLUS"
  },
  {
    "name": "motorola XT2153-1",
    "label": "motorola XT2153-1",
    "value": "motorola XT2153-1"
  },
  {
    "name": "asus ASUS_Z017DB",
    "label": "asus ASUS_Z017DB",
    "value": "asus ASUS_Z017DB"
  },
  {
    "name": "AIDATA ADT1061",
    "label": "AIDATA ADT1061",
    "value": "AIDATA ADT1061"
  },
  {
    "name": "Apple Wandile’s iPhone",
    "label": "Apple Wandile’s iPhone",
    "value": "Apple Wandile’s iPhone"
  },
  {
    "name": "SkyDevices Platinum J5",
    "label": "SkyDevices Platinum J5",
    "value": "SkyDevices Platinum J5"
  },
  {
    "name": "samsung SM-T830",
    "label": "samsung SM-T830",
    "value": "samsung SM-T830"
  },
  {
    "name": "OUKITEL C15 Pro",
    "label": "OUKITEL C15 Pro",
    "value": "OUKITEL C15 Pro"
  },
  {
    "name": "samsung SM-G981W",
    "label": "samsung SM-G981W",
    "value": "samsung SM-G981W"
  },
  {
    "name": "LGE LM-X410.FN",
    "label": "LGE LM-X410.FN",
    "value": "LGE LM-X410.FN"
  },
  {
    "name": "Symphony V98",
    "label": "Symphony V98",
    "value": "Symphony V98"
  },
  {
    "name": "ZTE Blade A51",
    "label": "ZTE Blade A51",
    "value": "ZTE Blade A51"
  },
  {
    "name": "iPhone13,2 iPhone",
    "label": "iPhone13,2 iPhone",
    "value": "iPhone13,2 iPhone"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LB8",
    "label": "TECNO MOBILE LIMITED TECNO LB8",
    "value": "TECNO MOBILE LIMITED TECNO LB8"
  },
  {
    "name": "worldchip One_Mundial",
    "label": "worldchip One_Mundial",
    "value": "worldchip One_Mundial"
  },
  {
    "name": "Apple iPhone de Angelo",
    "label": "Apple iPhone de Angelo",
    "value": "Apple iPhone de Angelo"
  },
  {
    "name": "TCL 5030M",
    "label": "TCL 5030M",
    "value": "TCL 5030M"
  },
  {
    "name": "samsung SM-J337V",
    "label": "samsung SM-J337V",
    "value": "samsung SM-J337V"
  },
  {
    "name": "BLU G91",
    "label": "BLU G91",
    "value": "BLU G91"
  },
  {
    "name": "itel itel A23R",
    "label": "itel itel A23R",
    "value": "itel itel A23R"
  },
  {
    "name": "Sony F8331",
    "label": "Sony F8331",
    "value": "Sony F8331"
  },
  {
    "name": "HUAWEI JAT-AL00",
    "label": "HUAWEI JAT-AL00",
    "value": "HUAWEI JAT-AL00"
  },
  {
    "name": "samsung SM-F7110",
    "label": "samsung SM-F7110",
    "value": "samsung SM-F7110"
  },
  {
    "name": "SSmooth Smooth 5.0",
    "label": "SSmooth Smooth 5.0",
    "value": "SSmooth Smooth 5.0"
  },
  {
    "name": "Lenovo Lenovo L78051",
    "label": "Lenovo Lenovo L78051",
    "value": "Lenovo Lenovo L78051"
  },
  {
    "name": "samsung SM-T295N",
    "label": "samsung SM-T295N",
    "value": "samsung SM-T295N"
  },
  {
    "name": "MobiWire ELEMENT PLAY",
    "label": "MobiWire ELEMENT PLAY",
    "value": "MobiWire ELEMENT PLAY"
  },
  {
    "name": "TCL 5059D",
    "label": "TCL 5059D",
    "value": "TCL 5059D"
  },
  {
    "name": "Sony SO-03L",
    "label": "Sony SO-03L",
    "value": "Sony SO-03L"
  },
  {
    "name": "Realme RMX1942",
    "label": "Realme RMX1942",
    "value": "Realme RMX1942"
  },
  {
    "name": "LGE LM-V409N",
    "label": "LGE LM-V409N",
    "value": "LGE LM-V409N"
  },
  {
    "name": "BLU Studio X10 2022",
    "label": "BLU Studio X10 2022",
    "value": "BLU Studio X10 2022"
  },
  {
    "name": "samsung SM-G935K",
    "label": "samsung SM-G935K",
    "value": "samsung SM-G935K"
  },
  {
    "name": "ANT DAO TECHNOLOGY LIMITED R2022",
    "label": "ANT DAO TECHNOLOGY LIMITED R2022",
    "value": "ANT DAO TECHNOLOGY LIMITED R2022"
  },
  {
    "name": "samsung SM-J530FM",
    "label": "samsung SM-J530FM",
    "value": "samsung SM-J530FM"
  },
  {
    "name": "BioRugged BioWolf 8N",
    "label": "BioRugged BioWolf 8N",
    "value": "BioRugged BioWolf 8N"
  },
  {
    "name": "ADVAN G3 Pro 6002",
    "label": "ADVAN G3 Pro 6002",
    "value": "ADVAN G3 Pro 6002"
  },
  {
    "name": "Google Acer Chromebook R13 (CB5-312T)",
    "label": "Google Acer Chromebook R13 (CB5-312T)",
    "value": "Google Acer Chromebook R13 (CB5-312T)"
  },
  {
    "name": "Mobicel GEO",
    "label": "Mobicel GEO",
    "value": "Mobicel GEO"
  },
  {
    "name": "Apple iPad7,2",
    "label": "Apple iPad7,2",
    "value": "Apple iPad7,2"
  },
  {
    "name": "ZTE ZTE A7040",
    "label": "ZTE ZTE A7040",
    "value": "ZTE ZTE A7040"
  },
  {
    "name": "meizu Note9",
    "label": "meizu Note9",
    "value": "meizu Note9"
  },
  {
    "name": "Apple Alvin’s iphone",
    "label": "Apple Alvin’s iphone",
    "value": "Apple Alvin’s iphone"
  },
  {
    "name": "Apple Eden ",
    "label": "Apple Eden ",
    "value": "Apple Eden "
  },
  {
    "name": "itel itel L5006C",
    "label": "itel itel L5006C",
    "value": "itel itel L5006C"
  },
  {
    "name": "onn 100026191",
    "label": "onn 100026191",
    "value": "onn 100026191"
  },
  {
    "name": "WIKO W-V750BN-EEA",
    "label": "WIKO W-V750BN-EEA",
    "value": "WIKO W-V750BN-EEA"
  },
  {
    "name": "HUAWEI DNN-LX9",
    "label": "HUAWEI DNN-LX9",
    "value": "HUAWEI DNN-LX9"
  },
  {
    "name": "ZTE ZTE BLADE A530",
    "label": "ZTE ZTE BLADE A530",
    "value": "ZTE ZTE BLADE A530"
  },
  {
    "name": "General Mobile General Mobile 4G Dual",
    "label": "General Mobile General Mobile 4G Dual",
    "value": "General Mobile General Mobile 4G Dual"
  },
  {
    "name": "LGE VS988",
    "label": "LGE VS988",
    "value": "LGE VS988"
  },
  {
    "name": "Ulefone Note 6",
    "label": "Ulefone Note 6",
    "value": "Ulefone Note 6"
  },
  {
    "name": "YEZZ LIV1",
    "label": "YEZZ LIV1",
    "value": "YEZZ LIV1"
  },
  {
    "name": "skythtek TK801",
    "label": "skythtek TK801",
    "value": "skythtek TK801"
  },
  {
    "name": "WIKO W-P311-EEA",
    "label": "WIKO W-P311-EEA",
    "value": "WIKO W-P311-EEA"
  },
  {
    "name": "vivo V2052",
    "label": "vivo V2052",
    "value": "vivo V2052"
  },
  {
    "name": "iHunt Like 11",
    "label": "iHunt Like 11",
    "value": "iHunt Like 11"
  },
  {
    "name": "Apple iPhone cookie",
    "label": "Apple iPhone cookie",
    "value": "Apple iPhone cookie"
  },
  {
    "name": "WIKO W-K130-OPE",
    "label": "WIKO W-K130-OPE",
    "value": "WIKO W-K130-OPE"
  },
  {
    "name": "HUAWEI FLA-LX2",
    "label": "HUAWEI FLA-LX2",
    "value": "HUAWEI FLA-LX2"
  },
  {
    "name": "HUAWEI BKL-L09",
    "label": "HUAWEI BKL-L09",
    "value": "HUAWEI BKL-L09"
  },
  {
    "name": "Sony SOV35",
    "label": "Sony SOV35",
    "value": "Sony SOV35"
  },
  {
    "name": "incar MAXTRON Genio",
    "label": "incar MAXTRON Genio",
    "value": "incar MAXTRON Genio"
  },
  {
    "name": "KONROW EASY5",
    "label": "KONROW EASY5",
    "value": "KONROW EASY5"
  },
  {
    "name": "samsung SM-M205N",
    "label": "samsung SM-M205N",
    "value": "samsung SM-M205N"
  },
  {
    "name": "Sony SO-03K",
    "label": "Sony SO-03K",
    "value": "Sony SO-03K"
  },
  {
    "name": "samsung SM-J510F",
    "label": "samsung SM-J510F",
    "value": "samsung SM-J510F"
  },
  {
    "name": "SPA Condor Electronics Allure M3",
    "label": "SPA Condor Electronics Allure M3",
    "value": "SPA Condor Electronics Allure M3"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CC9",
    "label": "TECNO MOBILE LIMITED TECNO CC9",
    "value": "TECNO MOBILE LIMITED TECNO CC9"
  },
  {
    "name": "WALTON Primo EF9",
    "label": "WALTON Primo EF9",
    "value": "WALTON Primo EF9"
  },
  {
    "name": "samsung SM-A205FN",
    "label": "samsung SM-A205FN",
    "value": "samsung SM-A205FN"
  },
  {
    "name": "samsung SM-S906W",
    "label": "samsung SM-S906W",
    "value": "samsung SM-S906W"
  },
  {
    "name": "vivo vivo 2015_21",
    "label": "vivo vivo 2015_21",
    "value": "vivo vivo 2015_21"
  },
  {
    "name": "MobiWire Kicka 5",
    "label": "MobiWire Kicka 5",
    "value": "MobiWire Kicka 5"
  },
  {
    "name": "samsung Pixel 5a",
    "label": "samsung Pixel 5a",
    "value": "samsung Pixel 5a"
  },
  {
    "name": "LAVA Z50",
    "label": "LAVA Z50",
    "value": "LAVA Z50"
  },
  {
    "name": "samsung SM-J720F",
    "label": "samsung SM-J720F",
    "value": "samsung SM-J720F"
  },
  {
    "name": "LAVA T81N_UP",
    "label": "LAVA T81N_UP",
    "value": "LAVA T81N_UP"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CA8S",
    "label": "TECNO MOBILE LIMITED TECNO CA8S",
    "value": "TECNO MOBILE LIMITED TECNO CA8S"
  },
  {
    "name": "Apple iPhone SE (2nd generation)",
    "label": "Apple iPhone SE (2nd generation)",
    "value": "Apple iPhone SE (2nd generation)"
  },
  {
    "name": "Apple Thembelihle’s iPhone",
    "label": "Apple Thembelihle’s iPhone",
    "value": "Apple Thembelihle’s iPhone"
  },
  {
    "name": "Apple Kwandiwe’s iPhone",
    "label": "Apple Kwandiwe’s iPhone",
    "value": "Apple Kwandiwe’s iPhone"
  },
  {
    "name": "Apple Chicelia Slva ❤️",
    "label": "Apple Chicelia Slva ❤️",
    "value": "Apple Chicelia Slva ❤️"
  },
  {
    "name": "TP-Link Neffos C9s",
    "label": "TP-Link Neffos C9s",
    "value": "TP-Link Neffos C9s"
  },
  {
    "name": "Bmobile AX1077+",
    "label": "Bmobile AX1077+",
    "value": "Bmobile AX1077+"
  },
  {
    "name": "SHARP FS8032",
    "label": "SHARP FS8032",
    "value": "SHARP FS8032"
  },
  {
    "name": "HUAWEI AGS-L09",
    "label": "HUAWEI AGS-L09",
    "value": "HUAWEI AGS-L09"
  },
  {
    "name": "BLU Advance 5.2",
    "label": "BLU Advance 5.2",
    "value": "BLU Advance 5.2"
  },
  {
    "name": "OPPO PCAM10",
    "label": "OPPO PCAM10",
    "value": "OPPO PCAM10"
  },
  {
    "name": "samsung SM-G610L",
    "label": "samsung SM-G610L",
    "value": "samsung SM-G610L"
  },
  {
    "name": "ALLVIEW X6_Soul_Xtreme",
    "label": "ALLVIEW X6_Soul_Xtreme",
    "value": "ALLVIEW X6_Soul_Xtreme"
  },
  {
    "name": "Xiaomi M2006C3LC",
    "label": "Xiaomi M2006C3LC",
    "value": "Xiaomi M2006C3LC"
  },
  {
    "name": "Apple iPad13,10",
    "label": "Apple iPad13,10",
    "value": "Apple iPad13,10"
  },
  {
    "name": "samsung SM-A3051",
    "label": "samsung SM-A3051",
    "value": "samsung SM-A3051"
  },
  {
    "name": "CUBOT KING_KONG_3",
    "label": "CUBOT KING_KONG_3",
    "value": "CUBOT KING_KONG_3"
  },
  {
    "name": "HONOR VNE-LX2",
    "label": "HONOR VNE-LX2",
    "value": "HONOR VNE-LX2"
  },
  {
    "name": "vivo V1732T",
    "label": "vivo V1732T",
    "value": "vivo V1732T"
  },
  {
    "name": "Casper VIA_G4",
    "label": "Casper VIA_G4",
    "value": "Casper VIA_G4"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CF7k",
    "label": "TECNO MOBILE LIMITED TECNO CF7k",
    "value": "TECNO MOBILE LIMITED TECNO CF7k"
  },
  {
    "name": "LAVA iris50",
    "label": "LAVA iris50",
    "value": "LAVA iris50"
  },
  {
    "name": "TECNO TECNO KH7",
    "label": "TECNO TECNO KH7",
    "value": "TECNO TECNO KH7"
  },
  {
    "name": "Coolpad 1825",
    "label": "Coolpad 1825",
    "value": "Coolpad 1825"
  },
  {
    "name": "samsung SM-F700F",
    "label": "samsung SM-F700F",
    "value": "samsung SM-F700F"
  },
  {
    "name": "vivo V2151",
    "label": "vivo V2151",
    "value": "vivo V2151"
  },
  {
    "name": "samsung SM-F900F",
    "label": "samsung SM-F900F",
    "value": "samsung SM-F900F"
  },
  {
    "name": "Trekstor PEAQ PET 100 LTE",
    "label": "Trekstor PEAQ PET 100 LTE",
    "value": "Trekstor PEAQ PET 100 LTE"
  },
  {
    "name": "vivo vivo 1907_19",
    "label": "vivo vivo 1907_19",
    "value": "vivo vivo 1907_19"
  },
  {
    "name": "Hisense Hisense E60",
    "label": "Hisense Hisense E60",
    "value": "Hisense Hisense E60"
  },
  {
    "name": "samsung SM-A146B",
    "label": "samsung SM-A146B",
    "value": "samsung SM-A146B"
  },
  {
    "name": "samsung SM-X806B",
    "label": "samsung SM-X806B",
    "value": "samsung SM-X806B"
  },
  {
    "name": "GIONEE GIONEE M15",
    "label": "GIONEE GIONEE M15",
    "value": "GIONEE GIONEE M15"
  },
  {
    "name": "Blackview A55",
    "label": "Blackview A55",
    "value": "Blackview A55"
  },
  {
    "name": "Ulefone Armor_X3",
    "label": "Ulefone Armor_X3",
    "value": "Ulefone Armor_X3"
  },
  {
    "name": "LUNA LUNA G9",
    "label": "LUNA LUNA G9",
    "value": "LUNA LUNA G9"
  },
  {
    "name": "INFINIX Infinix X677",
    "label": "INFINIX Infinix X677",
    "value": "INFINIX Infinix X677"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X663C",
    "label": "INFINIX MOBILITY LIMITED Infinix X663C",
    "value": "INFINIX MOBILITY LIMITED Infinix X663C"
  },
  {
    "name": "TCL 4165J",
    "label": "TCL 4165J",
    "value": "TCL 4165J"
  },
  {
    "name": "ITEL MOBILE LIMITED Orange Nola fun",
    "label": "ITEL MOBILE LIMITED Orange Nola fun",
    "value": "ITEL MOBILE LIMITED Orange Nola fun"
  },
  {
    "name": "HUAWEI BND-L34",
    "label": "HUAWEI BND-L34",
    "value": "HUAWEI BND-L34"
  },
  {
    "name": "vivo V2026_21",
    "label": "vivo V2026_21",
    "value": "vivo V2026_21"
  },
  {
    "name": "samsung SM-J727R4",
    "label": "samsung SM-J727R4",
    "value": "samsung SM-J727R4"
  },
  {
    "name": "vivo vivo 1909",
    "label": "vivo vivo 1909",
    "value": "vivo vivo 1909"
  },
  {
    "name": "samsung SM-F707U",
    "label": "samsung SM-F707U",
    "value": "samsung SM-F707U"
  },
  {
    "name": "samsung SM-P355",
    "label": "samsung SM-P355",
    "value": "samsung SM-P355"
  },
  {
    "name": "TECNO TECNO A571LS",
    "label": "TECNO TECNO A571LS",
    "value": "TECNO TECNO A571LS"
  },
  {
    "name": "INFINIX Infinix X6826",
    "label": "INFINIX Infinix X6826",
    "value": "INFINIX Infinix X6826"
  },
  {
    "name": "SSmooth Smooth 5.5",
    "label": "SSmooth Smooth 5.5",
    "value": "SSmooth Smooth 5.5"
  },
  {
    "name": "TCL 5033MP",
    "label": "TCL 5033MP",
    "value": "TCL 5033MP"
  },
  {
    "name": "Xiaomi 22071219CG",
    "label": "Xiaomi 22071219CG",
    "value": "Xiaomi 22071219CG"
  },
  {
    "name": "Acer B3-A50FHD",
    "label": "Acer B3-A50FHD",
    "value": "Acer B3-A50FHD"
  },
  {
    "name": "bq Aquaris U",
    "label": "bq Aquaris U",
    "value": "bq Aquaris U"
  },
  {
    "name": "ACE France URBAN 1",
    "label": "ACE France URBAN 1",
    "value": "ACE France URBAN 1"
  },
  {
    "name": "Google sdk_gphone64_arm64",
    "label": "Google sdk_gphone64_arm64",
    "value": "Google sdk_gphone64_arm64"
  },
  {
    "name": "onn 100003561",
    "label": "onn 100003561",
    "value": "onn 100003561"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X625D",
    "label": "INFINIX MOBILITY LIMITED Infinix X625D",
    "value": "INFINIX MOBILITY LIMITED Infinix X625D"
  },
  {
    "name": "Cherry_Mobile Flare Tab Pro",
    "label": "Cherry_Mobile Flare Tab Pro",
    "value": "Cherry_Mobile Flare Tab Pro"
  },
  {
    "name": "iPhone10,6 iPhone",
    "label": "iPhone10,6 iPhone",
    "value": "iPhone10,6 iPhone"
  },
  {
    "name": "samsung SM-T866N",
    "label": "samsung SM-T866N",
    "value": "samsung SM-T866N"
  },
  {
    "name": "Sony J9210",
    "label": "Sony J9210",
    "value": "Sony J9210"
  },
  {
    "name": "TCL 6025D_EEA",
    "label": "TCL 6025D_EEA",
    "value": "TCL 6025D_EEA"
  },
  {
    "name": "GHIA GTA7PLUS",
    "label": "GHIA GTA7PLUS",
    "value": "GHIA GTA7PLUS"
  },
  {
    "name": "Apple iPad6,4",
    "label": "Apple iPad6,4",
    "value": "Apple iPad6,4"
  },
  {
    "name": "Ulefone Ulefone S7",
    "label": "Ulefone Ulefone S7",
    "value": "Ulefone Ulefone S7"
  },
  {
    "name": "Blackview A55 Pro",
    "label": "Blackview A55 Pro",
    "value": "Blackview A55 Pro"
  },
  {
    "name": "itel itel W6003",
    "label": "itel itel W6003",
    "value": "itel itel W6003"
  },
  {
    "name": "HUAWEI AGS2-L09",
    "label": "HUAWEI AGS2-L09",
    "value": "HUAWEI AGS2-L09"
  },
  {
    "name": "samsung SM-A217N",
    "label": "samsung SM-A217N",
    "value": "samsung SM-A217N"
  },
  {
    "name": "Hisense Hisense Infinity H40",
    "label": "Hisense Hisense Infinity H40",
    "value": "Hisense Hisense Infinity H40"
  },
  {
    "name": "Apple iPhone 13 Pro Max",
    "label": "Apple iPhone 13 Pro Max",
    "value": "Apple iPhone 13 Pro Max"
  },
  {
    "name": "Apple ofentse’s iPhone",
    "label": "Apple ofentse’s iPhone",
    "value": "Apple ofentse’s iPhone"
  },
  {
    "name": "ZTE ZTE 9000",
    "label": "ZTE ZTE 9000",
    "value": "ZTE ZTE 9000"
  },
  {
    "name": "MobiWire S33",
    "label": "MobiWire S33",
    "value": "MobiWire S33"
  },
  {
    "name": "vsmart Bee Lite",
    "label": "vsmart Bee Lite",
    "value": "vsmart Bee Lite"
  },
  {
    "name": "samsung SM-J700T1",
    "label": "samsung SM-J700T1",
    "value": "samsung SM-J700T1"
  },
  {
    "name": "Kleadtone S84",
    "label": "Kleadtone S84",
    "value": "Kleadtone S84"
  },
  {
    "name": "X-TIGI JOY8 Mate",
    "label": "X-TIGI JOY8 Mate",
    "value": "X-TIGI JOY8 Mate"
  },
  {
    "name": "HUAWEI CAG-L23",
    "label": "HUAWEI CAG-L23",
    "value": "HUAWEI CAG-L23"
  },
  {
    "name": "ITEL MOBILE LIMITED itel W7002",
    "label": "ITEL MOBILE LIMITED itel W7002",
    "value": "ITEL MOBILE LIMITED itel W7002"
  },
  {
    "name": "samsung SM-A3050",
    "label": "samsung SM-A3050",
    "value": "samsung SM-A3050"
  },
  {
    "name": "samsung SM-S506DL",
    "label": "samsung SM-S506DL",
    "value": "samsung SM-S506DL"
  },
  {
    "name": "HUAWEI PAR-LX1",
    "label": "HUAWEI PAR-LX1",
    "value": "HUAWEI PAR-LX1"
  },
  {
    "name": "BLU Advance L5",
    "label": "BLU Advance L5",
    "value": "BLU Advance L5"
  },
  {
    "name": "BQru BQ-6030G",
    "label": "BQru BQ-6030G",
    "value": "BQru BQ-6030G"
  },
  {
    "name": "General Mobile G700",
    "label": "General Mobile G700",
    "value": "General Mobile G700"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A16 Plus RU",
    "label": "ITEL MOBILE LIMITED itel A16 Plus RU",
    "value": "ITEL MOBILE LIMITED itel A16 Plus RU"
  },
  {
    "name": "LANIX X530",
    "label": "LANIX X530",
    "value": "LANIX X530"
  },
  {
    "name": "ZTE Z899VL",
    "label": "ZTE Z899VL",
    "value": "ZTE Z899VL"
  },
  {
    "name": "TCL T676J",
    "label": "TCL T676J",
    "value": "TCL T676J"
  },
  {
    "name": "LGE LG-M255",
    "label": "LGE LG-M255",
    "value": "LGE LG-M255"
  },
  {
    "name": "Yulong REVVLPLUS C3701A",
    "label": "Yulong REVVLPLUS C3701A",
    "value": "Yulong REVVLPLUS C3701A"
  },
  {
    "name": "samsung SM-P619",
    "label": "samsung SM-P619",
    "value": "samsung SM-P619"
  },
  {
    "name": "Apple Theo iPhone",
    "label": "Apple Theo iPhone",
    "value": "Apple Theo iPhone"
  },
  {
    "name": "TCL 6156A",
    "label": "TCL 6156A",
    "value": "TCL 6156A"
  },
  {
    "name": "vivo I2017",
    "label": "vivo I2017",
    "value": "vivo I2017"
  },
  {
    "name": "samsung SM-S908U1",
    "label": "samsung SM-S908U1",
    "value": "samsung SM-S908U1"
  },
  {
    "name": "incar SP4702",
    "label": "incar SP4702",
    "value": "incar SP4702"
  },
  {
    "name": "vivo vivo Y75",
    "label": "vivo vivo Y75",
    "value": "vivo vivo Y75"
  },
  {
    "name": "WALTON PRIMO S8",
    "label": "WALTON PRIMO S8",
    "value": "WALTON PRIMO S8"
  },
  {
    "name": "SHARP 704SH",
    "label": "SHARP 704SH",
    "value": "SHARP 704SH"
  },
  {
    "name": "H800D BELLATRIX",
    "label": "H800D BELLATRIX",
    "value": "H800D BELLATRIX"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO B1c",
    "label": "TECNO MOBILE LIMITED TECNO B1c",
    "value": "TECNO MOBILE LIMITED TECNO B1c"
  },
  {
    "name": "Morep M610",
    "label": "Morep M610",
    "value": "Morep M610"
  },
  {
    "name": "Fortune Ship iKU A36",
    "label": "Fortune Ship iKU A36",
    "value": "Fortune Ship iKU A36"
  },
  {
    "name": "LGE LG-SP200",
    "label": "LGE LG-SP200",
    "value": "LGE LG-SP200"
  },
  {
    "name": "Bmobile BL50",
    "label": "Bmobile BL50",
    "value": "Bmobile BL50"
  },
  {
    "name": "samsung SM-G981V",
    "label": "samsung SM-G981V",
    "value": "samsung SM-G981V"
  },
  {
    "name": "Apple iPad11,2",
    "label": "Apple iPad11,2",
    "value": "Apple iPad11,2"
  },
  {
    "name": "HXY MP09",
    "label": "HXY MP09",
    "value": "HXY MP09"
  },
  {
    "name": "realme RMX3660",
    "label": "realme RMX3660",
    "value": "realme RMX3660"
  },
  {
    "name": "alps M11 Ultra",
    "label": "alps M11 Ultra",
    "value": "alps M11 Ultra"
  },
  {
    "name": "motorola moto z4",
    "label": "motorola moto z4",
    "value": "motorola moto z4"
  },
  {
    "name": "Positivo BGH KC770",
    "label": "Positivo BGH KC770",
    "value": "Positivo BGH KC770"
  },
  {
    "name": "Sony G3123",
    "label": "Sony G3123",
    "value": "Sony G3123"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6816D",
    "label": "INFINIX MOBILITY LIMITED Infinix X6816D",
    "value": "INFINIX MOBILITY LIMITED Infinix X6816D"
  },
  {
    "name": "HUAWEI VTR-L29",
    "label": "HUAWEI VTR-L29",
    "value": "HUAWEI VTR-L29"
  },
  {
    "name": "wheatek W10",
    "label": "wheatek W10",
    "value": "wheatek W10"
  },
  {
    "name": "incar Tab_A6_EEA",
    "label": "incar Tab_A6_EEA",
    "value": "incar Tab_A6_EEA"
  },
  {
    "name": "TCL 5101A",
    "label": "TCL 5101A",
    "value": "TCL 5101A"
  },
  {
    "name": "RED H1A1000",
    "label": "RED H1A1000",
    "value": "RED H1A1000"
  },
  {
    "name": "samsung SM-J737P",
    "label": "samsung SM-J737P",
    "value": "samsung SM-J737P"
  },
  {
    "name": "samsung SM-G525F",
    "label": "samsung SM-G525F",
    "value": "samsung SM-G525F"
  },
  {
    "name": "KONKA N15",
    "label": "KONKA N15",
    "value": "KONKA N15"
  },
  {
    "name": "WIKO W-V745-TVM",
    "label": "WIKO W-V745-TVM",
    "value": "WIKO W-V745-TVM"
  },
  {
    "name": "Desktop - iOS",
    "label": "Desktop - iOS",
    "value": "Desktop - iOS"
  },
  {
    "name": "Desktop - OpenBSD",
    "label": "Desktop - OpenBSD",
    "value": "Desktop - OpenBSD"
  },
  {
    "name": "revoview YUHO_Vast2",
    "label": "revoview YUHO_Vast2",
    "value": "revoview YUHO_Vast2"
  },
  {
    "name": "iPad7,12 iPad",
    "label": "iPad7,12 iPad",
    "value": "iPad7,12 iPad"
  },
  {
    "name": "DOOGEE X95Pro",
    "label": "DOOGEE X95Pro",
    "value": "DOOGEE X95Pro"
  },
  {
    "name": "LGE LG-H873",
    "label": "LGE LG-H873",
    "value": "LGE LG-H873"
  },
  {
    "name": "Apple 5424755",
    "label": "Apple 5424755",
    "value": "Apple 5424755"
  },
  {
    "name": "HMR Networks HMR5012",
    "label": "HMR Networks HMR5012",
    "value": "HMR Networks HMR5012"
  },
  {
    "name": "Apple Zoe’s iPhone",
    "label": "Apple Zoe’s iPhone",
    "value": "Apple Zoe’s iPhone"
  },
  {
    "name": "samsung SM-N981U1",
    "label": "samsung SM-N981U1",
    "value": "samsung SM-N981U1"
  },
  {
    "name": "BND BlueS10",
    "label": "BND BlueS10",
    "value": "BND BlueS10"
  },
  {
    "name": "ZTE ZTE Blade A51RU",
    "label": "ZTE ZTE Blade A51RU",
    "value": "ZTE ZTE Blade A51RU"
  },
  {
    "name": "Lenovo Lenovo L38011",
    "label": "Lenovo Lenovo L38011",
    "value": "Lenovo Lenovo L38011"
  },
  {
    "name": "CHUWI HiPad X",
    "label": "CHUWI HiPad X",
    "value": "CHUWI HiPad X"
  },
  {
    "name": "hena NQT-1013GIQ",
    "label": "hena NQT-1013GIQ",
    "value": "hena NQT-1013GIQ"
  },
  {
    "name": "magcomm dbs",
    "label": "magcomm dbs",
    "value": "magcomm dbs"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CA8",
    "label": "TECNO MOBILE LIMITED TECNO CA8",
    "value": "TECNO MOBILE LIMITED TECNO CA8"
  },
  {
    "name": "vivo V2070",
    "label": "vivo V2070",
    "value": "vivo V2070"
  },
  {
    "name": "Welcome V19 Pro",
    "label": "Welcome V19 Pro",
    "value": "Welcome V19 Pro"
  },
  {
    "name": "ITEL MOBILE LIMITED itel L6004",
    "label": "ITEL MOBILE LIMITED itel L6004",
    "value": "ITEL MOBILE LIMITED itel L6004"
  },
  {
    "name": "Google trogdor",
    "label": "Google trogdor",
    "value": "Google trogdor"
  },
  {
    "name": "samsung SM-A716B",
    "label": "samsung SM-A716B",
    "value": "samsung SM-A716B"
  },
  {
    "name": "STG STG X20",
    "label": "STG STG X20",
    "value": "STG STG X20"
  },
  {
    "name": "YEZZ LIV2LTE",
    "label": "YEZZ LIV2LTE",
    "value": "YEZZ LIV2LTE"
  },
  {
    "name": "HXY A9",
    "label": "HXY A9",
    "value": "HXY A9"
  },
  {
    "name": "YEZZ ART2PRO",
    "label": "YEZZ ART2PRO",
    "value": "YEZZ ART2PRO"
  },
  {
    "name": "IBRIT Power 6",
    "label": "IBRIT Power 6",
    "value": "IBRIT Power 6"
  },
  {
    "name": "FISE 5EQ",
    "label": "FISE 5EQ",
    "value": "FISE 5EQ"
  },
  {
    "name": "samsung SM-A136W",
    "label": "samsung SM-A136W",
    "value": "samsung SM-A136W"
  },
  {
    "name": "LGE LGL722DL",
    "label": "LGE LGL722DL",
    "value": "LGE LGL722DL"
  },
  {
    "name": "samsung SM-A536W",
    "label": "samsung SM-A536W",
    "value": "samsung SM-A536W"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X5515",
    "label": "INFINIX MOBILITY LIMITED Infinix X5515",
    "value": "INFINIX MOBILITY LIMITED Infinix X5515"
  },
  {
    "name": "sky Platinum K55",
    "label": "sky Platinum K55",
    "value": "sky Platinum K55"
  },
  {
    "name": "Xiaomi 2201116TI",
    "label": "Xiaomi 2201116TI",
    "value": "Xiaomi 2201116TI"
  },
  {
    "name": "OMIX OMIX X500",
    "label": "OMIX OMIX X500",
    "value": "OMIX OMIX X500"
  },
  {
    "name": "samsung SM-S236DL",
    "label": "samsung SM-S236DL",
    "value": "samsung SM-S236DL"
  },
  {
    "name": "ITEL MOBILE LIMITED itel P661W",
    "label": "ITEL MOBILE LIMITED itel P661W",
    "value": "ITEL MOBILE LIMITED itel P661W"
  },
  {
    "name": "KYOCERA KYV43",
    "label": "KYOCERA KYV43",
    "value": "KYOCERA KYV43"
  },
  {
    "name": "BLU G91S",
    "label": "BLU G91S",
    "value": "BLU G91S"
  },
  {
    "name": "WIKO W-K211-EEA",
    "label": "WIKO W-K211-EEA",
    "value": "WIKO W-K211-EEA"
  },
  {
    "name": "ZTE ZTE Blade A7 2020RU",
    "label": "ZTE ZTE Blade A7 2020RU",
    "value": "ZTE ZTE Blade A7 2020RU"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KG5m",
    "label": "TECNO MOBILE LIMITED TECNO KG5m",
    "value": "TECNO MOBILE LIMITED TECNO KG5m"
  },
  {
    "name": "LENOVO TB-8704V",
    "label": "LENOVO TB-8704V",
    "value": "LENOVO TB-8704V"
  },
  {
    "name": "motorola Lenovo K14",
    "label": "motorola Lenovo K14",
    "value": "motorola Lenovo K14"
  },
  {
    "name": "SkyDevices X2",
    "label": "SkyDevices X2",
    "value": "SkyDevices X2"
  },
  {
    "name": "motorola moto e (XT2052DL)",
    "label": "motorola moto e (XT2052DL)",
    "value": "motorola moto e (XT2052DL)"
  },
  {
    "name": "Lenovo Lenovo L19041",
    "label": "Lenovo Lenovo L19041",
    "value": "Lenovo Lenovo L19041"
  },
  {
    "name": "TCL T790S",
    "label": "TCL T790S",
    "value": "TCL T790S"
  },
  {
    "name": "samsung SM-A015V",
    "label": "samsung SM-A015V",
    "value": "samsung SM-A015V"
  },
  {
    "name": "TCL 5033X_EEA",
    "label": "TCL 5033X_EEA",
    "value": "TCL 5033X_EEA"
  },
  {
    "name": "samsung SM-T377V",
    "label": "samsung SM-T377V",
    "value": "samsung SM-T377V"
  },
  {
    "name": "SSmooth Smooth 6.5",
    "label": "SSmooth Smooth 6.5",
    "value": "SSmooth Smooth 6.5"
  },
  {
    "name": "Apple Tsoseletso edutrade’s iPhone",
    "label": "Apple Tsoseletso edutrade’s iPhone",
    "value": "Apple Tsoseletso edutrade’s iPhone"
  },
  {
    "name": "KODAK KD50",
    "label": "KODAK KD50",
    "value": "KODAK KD50"
  },
  {
    "name": "STYLO S55 Nova",
    "label": "STYLO S55 Nova",
    "value": "STYLO S55 Nova"
  },
  {
    "name": "STYLO 221",
    "label": "STYLO 221",
    "value": "STYLO 221"
  },
  {
    "name": "TCL 6159K",
    "label": "TCL 6159K",
    "value": "TCL 6159K"
  },
  {
    "name": "BLU M7i",
    "label": "BLU M7i",
    "value": "BLU M7i"
  },
  {
    "name": "samsung SM-M405F",
    "label": "samsung SM-M405F",
    "value": "samsung SM-M405F"
  },
  {
    "name": "HUAWEI BAH2-L09",
    "label": "HUAWEI BAH2-L09",
    "value": "HUAWEI BAH2-L09"
  },
  {
    "name": "samsung SM-G550FY",
    "label": "samsung SM-G550FY",
    "value": "samsung SM-G550FY"
  },
  {
    "name": "samsung SM-A105N",
    "label": "samsung SM-A105N",
    "value": "samsung SM-A105N"
  },
  {
    "name": "CUBOT J5",
    "label": "CUBOT J5",
    "value": "CUBOT J5"
  },
  {
    "name": "WIKO W-V851-EEA",
    "label": "WIKO W-V851-EEA",
    "value": "WIKO W-V851-EEA"
  },
  {
    "name": "HMD Global TA-1025",
    "label": "HMD Global TA-1025",
    "value": "HMD Global TA-1025"
  },
  {
    "name": "samsung SM-A515U1",
    "label": "samsung SM-A515U1",
    "value": "samsung SM-A515U1"
  },
  {
    "name": "Mobicel ASTRO",
    "label": "Mobicel ASTRO",
    "value": "Mobicel ASTRO"
  },
  {
    "name": "IMG 721_PRO",
    "label": "IMG 721_PRO",
    "value": "IMG 721_PRO"
  },
  {
    "name": "vivo vivo NEX A",
    "label": "vivo vivo NEX A",
    "value": "vivo vivo NEX A"
  },
  {
    "name": "KRIP K5c",
    "label": "KRIP K5c",
    "value": "KRIP K5c"
  },
  {
    "name": "BLU F91 5G",
    "label": "BLU F91 5G",
    "value": "BLU F91 5G"
  },
  {
    "name": "Motorola Moto G4 Plus",
    "label": "Motorola Moto G4 Plus",
    "value": "Motorola Moto G4 Plus"
  },
  {
    "name": "X-TIGI A3",
    "label": "X-TIGI A3",
    "value": "X-TIGI A3"
  },
  {
    "name": "Apple Mulalo’s iPhone",
    "label": "Apple Mulalo’s iPhone",
    "value": "Apple Mulalo’s iPhone"
  },
  {
    "name": "Apple GODBASADI’s iPhone",
    "label": "Apple GODBASADI’s iPhone",
    "value": "Apple GODBASADI’s iPhone"
  },
  {
    "name": "Apple Uviwe’s iPhone",
    "label": "Apple Uviwe’s iPhone",
    "value": "Apple Uviwe’s iPhone"
  },
  {
    "name": "Qnet C21",
    "label": "Qnet C21",
    "value": "Qnet C21"
  },
  {
    "name": "Xiaomi M2012K11AI",
    "label": "Xiaomi M2012K11AI",
    "value": "Xiaomi M2012K11AI"
  },
  {
    "name": "motorola motorola razr",
    "label": "motorola motorola razr",
    "value": "motorola motorola razr"
  },
  {
    "name": "OPPO CPH1831",
    "label": "OPPO CPH1831",
    "value": "OPPO CPH1831"
  },
  {
    "name": "BLU G71 PLUS",
    "label": "BLU G71 PLUS",
    "value": "BLU G71 PLUS"
  },
  {
    "name": "OPPO PCNM00",
    "label": "OPPO PCNM00",
    "value": "OPPO PCNM00"
  },
  {
    "name": "TCL T766U",
    "label": "TCL T766U",
    "value": "TCL T766U"
  },
  {
    "name": "LGE LG-US998",
    "label": "LGE LG-US998",
    "value": "LGE LG-US998"
  },
  {
    "name": "Xiaomi M2102K1C",
    "label": "Xiaomi M2102K1C",
    "value": "Xiaomi M2102K1C"
  },
  {
    "name": "revoview IMO Q2 Plus",
    "label": "revoview IMO Q2 Plus",
    "value": "revoview IMO Q2 Plus"
  },
  {
    "name": "LANIX Alpha 950XL",
    "label": "LANIX Alpha 950XL",
    "value": "LANIX Alpha 950XL"
  },
  {
    "name": "LEAGOO Power 2",
    "label": "LEAGOO Power 2",
    "value": "LEAGOO Power 2"
  },
  {
    "name": "Multilaser MS40G",
    "label": "Multilaser MS40G",
    "value": "Multilaser MS40G"
  },
  {
    "name": "Xiaomi 21091116AC",
    "label": "Xiaomi 21091116AC",
    "value": "Xiaomi 21091116AC"
  },
  {
    "name": "HMD Global N1374DL",
    "label": "HMD Global N1374DL",
    "value": "HMD Global N1374DL"
  },
  {
    "name": "wheatek WP17",
    "label": "wheatek WP17",
    "value": "wheatek WP17"
  },
  {
    "name": "samsung SM-T378V",
    "label": "samsung SM-T378V",
    "value": "samsung SM-T378V"
  },
  {
    "name": "COBY MID8072",
    "label": "COBY MID8072",
    "value": "COBY MID8072"
  },
  {
    "name": "motorola Lenovo K11 Power",
    "label": "motorola Lenovo K11 Power",
    "value": "motorola Lenovo K11 Power"
  },
  {
    "name": "samsung SM-G960W",
    "label": "samsung SM-G960W",
    "value": "samsung SM-G960W"
  },
  {
    "name": "BLU Studio X12",
    "label": "BLU Studio X12",
    "value": "BLU Studio X12"
  },
  {
    "name": "Sky_Devices Sky BlackMax",
    "label": "Sky_Devices Sky BlackMax",
    "value": "Sky_Devices Sky BlackMax"
  },
  {
    "name": "Xiaomi 22081283G",
    "label": "Xiaomi 22081283G",
    "value": "Xiaomi 22081283G"
  },
  {
    "name": "GIONEE GIONEE P15",
    "label": "GIONEE GIONEE P15",
    "value": "GIONEE GIONEE P15"
  },
  {
    "name": "Amazon KFMAWI",
    "label": "Amazon KFMAWI",
    "value": "Amazon KFMAWI"
  },
  {
    "name": "IMG STEP_UP",
    "label": "IMG STEP_UP",
    "value": "IMG STEP_UP"
  },
  {
    "name": "vivo vivo Z1",
    "label": "vivo vivo Z1",
    "value": "vivo vivo Z1"
  },
  {
    "name": "SOTEN T71V4",
    "label": "SOTEN T71V4",
    "value": "SOTEN T71V4"
  },
  {
    "name": "LGE LG-LS993",
    "label": "LGE LG-LS993",
    "value": "LGE LG-LS993"
  },
  {
    "name": "walton Primo S8 mini",
    "label": "walton Primo S8 mini",
    "value": "walton Primo S8 mini"
  },
  {
    "name": "samsung SC-03K",
    "label": "samsung SC-03K",
    "value": "samsung SC-03K"
  },
  {
    "name": "TINNO U616AT",
    "label": "TINNO U616AT",
    "value": "TINNO U616AT"
  },
  {
    "name": "samsung SM-A136S",
    "label": "samsung SM-A136S",
    "value": "samsung SM-A136S"
  },
  {
    "name": "Xiaomi MIX 3",
    "label": "Xiaomi MIX 3",
    "value": "Xiaomi MIX 3"
  },
  {
    "name": "Xiaomi M2105K81AC",
    "label": "Xiaomi M2105K81AC",
    "value": "Xiaomi M2105K81AC"
  },
  {
    "name": "LENOVO Lenovo TB-8504F",
    "label": "LENOVO Lenovo TB-8504F",
    "value": "LENOVO Lenovo TB-8504F"
  },
  {
    "name": "Apple iPad13,2",
    "label": "Apple iPad13,2",
    "value": "Apple iPad13,2"
  },
  {
    "name": "samsung SM-A320Y",
    "label": "samsung SM-A320Y",
    "value": "samsung SM-A320Y"
  },
  {
    "name": "realme RMX3612",
    "label": "realme RMX3612",
    "value": "realme RMX3612"
  },
  {
    "name": "HUAWEI AMN-LX1",
    "label": "HUAWEI AMN-LX1",
    "value": "HUAWEI AMN-LX1"
  },
  {
    "name": "samsung SM-T350",
    "label": "samsung SM-T350",
    "value": "samsung SM-T350"
  },
  {
    "name": "Sony E5823",
    "label": "Sony E5823",
    "value": "Sony E5823"
  },
  {
    "name": "Xiaomi XIG01",
    "label": "Xiaomi XIG01",
    "value": "Xiaomi XIG01"
  },
  {
    "name": "WIKO W-K521-EEA",
    "label": "WIKO W-K521-EEA",
    "value": "WIKO W-K521-EEA"
  },
  {
    "name": "TCL 5033A",
    "label": "TCL 5033A",
    "value": "TCL 5033A"
  },
  {
    "name": "HYUNDAI E602",
    "label": "HYUNDAI E602",
    "value": "HYUNDAI E602"
  },
  {
    "name": "vivo vivo X9",
    "label": "vivo vivo X9",
    "value": "vivo vivo X9"
  },
  {
    "name": "TCL SH-C03",
    "label": "TCL SH-C03",
    "value": "TCL SH-C03"
  },
  {
    "name": "vivo V1928A",
    "label": "vivo V1928A",
    "value": "vivo V1928A"
  },
  {
    "name": "vivo V2158",
    "label": "vivo V2158",
    "value": "vivo V2158"
  },
  {
    "name": "vivo V2054",
    "label": "vivo V2054",
    "value": "vivo V2054"
  },
  {
    "name": "alps J33",
    "label": "alps J33",
    "value": "alps J33"
  },
  {
    "name": "Xiaomi Redmi K30i 5G",
    "label": "Xiaomi Redmi K30i 5G",
    "value": "Xiaomi Redmi K30i 5G"
  },
  {
    "name": "Hisense Hisense Rocks 6",
    "label": "Hisense Hisense Rocks 6",
    "value": "Hisense Hisense Rocks 6"
  },
  {
    "name": "samsung SM-S536DL",
    "label": "samsung SM-S536DL",
    "value": "samsung SM-S536DL"
  },
  {
    "name": "Apple Andile’s iPhone",
    "label": "Apple Andile’s iPhone",
    "value": "Apple Andile’s iPhone"
  },
  {
    "name": "samsung SM-A5070",
    "label": "samsung SM-A5070",
    "value": "samsung SM-A5070"
  },
  {
    "name": "LENOVO Lenovo TB-7104F",
    "label": "LENOVO Lenovo TB-7104F",
    "value": "LENOVO Lenovo TB-7104F"
  },
  {
    "name": "TCL 6156D",
    "label": "TCL 6156D",
    "value": "TCL 6156D"
  },
  {
    "name": "HUAWEI CDY-NX9A",
    "label": "HUAWEI CDY-NX9A",
    "value": "HUAWEI CDY-NX9A"
  },
  {
    "name": "KYOCERA 704KC",
    "label": "KYOCERA 704KC",
    "value": "KYOCERA 704KC"
  },
  {
    "name": "OPPO OPPO A83t",
    "label": "OPPO OPPO A83t",
    "value": "OPPO OPPO A83t"
  },
  {
    "name": "TWL X50Pro",
    "label": "TWL X50Pro",
    "value": "TWL X50Pro"
  },
  {
    "name": "VANTEC G5",
    "label": "VANTEC G5",
    "value": "VANTEC G5"
  },
  {
    "name": "realme RMP2108",
    "label": "realme RMP2108",
    "value": "realme RMP2108"
  },
  {
    "name": "TCL 5062Z",
    "label": "TCL 5062Z",
    "value": "TCL 5062Z"
  },
  {
    "name": "YEZZ ART1_PRO",
    "label": "YEZZ ART1_PRO",
    "value": "YEZZ ART1_PRO"
  },
  {
    "name": "Apple JSimon",
    "label": "Apple JSimon",
    "value": "Apple JSimon"
  },
  {
    "name": "magcomm HT80",
    "label": "magcomm HT80",
    "value": "magcomm HT80"
  },
  {
    "name": "LGE LM-V450",
    "label": "LGE LM-V450",
    "value": "LGE LM-V450"
  },
  {
    "name": "samsung SM-A305N",
    "label": "samsung SM-A305N",
    "value": "samsung SM-A305N"
  },
  {
    "name": "Apple Fabricio Q",
    "label": "Apple Fabricio Q",
    "value": "Apple Fabricio Q"
  },
  {
    "name": "KRIP K6b",
    "label": "KRIP K6b",
    "value": "KRIP K6b"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X5515F",
    "label": "INFINIX MOBILITY LIMITED Infinix X5515F",
    "value": "INFINIX MOBILITY LIMITED Infinix X5515F"
  },
  {
    "name": "TCL 5033T",
    "label": "TCL 5033T",
    "value": "TCL 5033T"
  },
  {
    "name": "Apple Anelisa’s iPhone",
    "label": "Apple Anelisa’s iPhone",
    "value": "Apple Anelisa’s iPhone"
  },
  {
    "name": "Mobile Web - android",
    "label": "Mobile Web - android",
    "value": "Mobile Web - android"
  },
  {
    "name": "MobiWire S34",
    "label": "MobiWire S34",
    "value": "MobiWire S34"
  },
  {
    "name": "vivo V2162",
    "label": "vivo V2162",
    "value": "vivo V2162"
  },
  {
    "name": "motorola moto z3",
    "label": "motorola moto z3",
    "value": "motorola moto z3"
  },
  {
    "name": "OPPO PCAT10",
    "label": "OPPO PCAT10",
    "value": "OPPO PCAT10"
  },
  {
    "name": "Alldocube kPad",
    "label": "Alldocube kPad",
    "value": "Alldocube kPad"
  },
  {
    "name": "HUAWEI MAR-LX2J",
    "label": "HUAWEI MAR-LX2J",
    "value": "HUAWEI MAR-LX2J"
  },
  {
    "name": "OPPO CPH2275",
    "label": "OPPO CPH2275",
    "value": "OPPO CPH2275"
  },
  {
    "name": "true SMART 4G P1 Prime",
    "label": "true SMART 4G P1 Prime",
    "value": "true SMART 4G P1 Prime"
  },
  {
    "name": "Casper VIA_F3",
    "label": "Casper VIA_F3",
    "value": "Casper VIA_F3"
  },
  {
    "name": "SHARP SH-Z01",
    "label": "SHARP SH-Z01",
    "value": "SHARP SH-Z01"
  },
  {
    "name": "SPA Condor Electronics Griffe T9 Plus",
    "label": "SPA Condor Electronics Griffe T9 Plus",
    "value": "SPA Condor Electronics Griffe T9 Plus"
  },
  {
    "name": "HMR Networks HMR5450",
    "label": "HMR Networks HMR5450",
    "value": "HMR Networks HMR5450"
  },
  {
    "name": "Google Pixel 4 XL",
    "label": "Google Pixel 4 XL",
    "value": "Google Pixel 4 XL"
  },
  {
    "name": "samsung SM-G9300",
    "label": "samsung SM-G9300",
    "value": "samsung SM-G9300"
  },
  {
    "name": "BQru BQ-7040G",
    "label": "BQru BQ-7040G",
    "value": "BQru BQ-7040G"
  },
  {
    "name": "Blackview BV5900",
    "label": "Blackview BV5900",
    "value": "Blackview BV5900"
  },
  {
    "name": "TCL 9024W",
    "label": "TCL 9024W",
    "value": "TCL 9024W"
  },
  {
    "name": "LGE LGM-G600S",
    "label": "LGE LGM-G600S",
    "value": "LGE LGM-G600S"
  },
  {
    "name": "vivo vivo 1937",
    "label": "vivo vivo 1937",
    "value": "vivo vivo 1937"
  },
  {
    "name": "GIONEE GIONEE M7L",
    "label": "GIONEE GIONEE M7L",
    "value": "GIONEE GIONEE M7L"
  },
  {
    "name": "samsung SM-J260T1",
    "label": "samsung SM-J260T1",
    "value": "samsung SM-J260T1"
  },
  {
    "name": "TECNO TECNO CI8n",
    "label": "TECNO TECNO CI8n",
    "value": "TECNO TECNO CI8n"
  },
  {
    "name": "ITEL MOBILE LIMITED itel L6006S",
    "label": "ITEL MOBILE LIMITED itel L6006S",
    "value": "ITEL MOBILE LIMITED itel L6006S"
  },
  {
    "name": "WIKO W_P200",
    "label": "WIKO W_P200",
    "value": "WIKO W_P200"
  },
  {
    "name": "HUAWEI CLT-AL00",
    "label": "HUAWEI CLT-AL00",
    "value": "HUAWEI CLT-AL00"
  },
  {
    "name": "LT C3600",
    "label": "LT C3600",
    "value": "LT C3600"
  },
  {
    "name": "TCL 6102A",
    "label": "TCL 6102A",
    "value": "TCL 6102A"
  },
  {
    "name": "360 1607-A01",
    "label": "360 1607-A01",
    "value": "360 1607-A01"
  },
  {
    "name": "vivo V2238A",
    "label": "vivo V2238A",
    "value": "vivo V2238A"
  },
  {
    "name": "realme RMX3624",
    "label": "realme RMX3624",
    "value": "realme RMX3624"
  },
  {
    "name": "Siragon SP-7050",
    "label": "Siragon SP-7050",
    "value": "Siragon SP-7050"
  },
  {
    "name": "WIKO W-V750BN-TVM",
    "label": "WIKO W-V750BN-TVM",
    "value": "WIKO W-V750BN-TVM"
  },
  {
    "name": "Xiaomi 21121210C",
    "label": "Xiaomi 21121210C",
    "value": "Xiaomi 21121210C"
  },
  {
    "name": "vivo V2132",
    "label": "vivo V2132",
    "value": "vivo V2132"
  },
  {
    "name": "realme RMX3371",
    "label": "realme RMX3371",
    "value": "realme RMX3371"
  },
  {
    "name": "ZTE ZTE BLADE A521",
    "label": "ZTE ZTE BLADE A521",
    "value": "ZTE ZTE BLADE A521"
  },
  {
    "name": "LGE LM-K400",
    "label": "LGE LM-K400",
    "value": "LGE LM-K400"
  },
  {
    "name": "Apple Margie’s iPhone11",
    "label": "Apple Margie’s iPhone11",
    "value": "Apple Margie’s iPhone11"
  },
  {
    "name": "TCL 5053K",
    "label": "TCL 5053K",
    "value": "TCL 5053K"
  },
  {
    "name": "iPhone10,5 iPhone",
    "label": "iPhone10,5 iPhone",
    "value": "iPhone10,5 iPhone"
  },
  {
    "name": "Apple Do gggh",
    "label": "Apple Do gggh",
    "value": "Apple Do gggh"
  },
  {
    "name": "Apple Aravind iPhone",
    "label": "Apple Aravind iPhone",
    "value": "Apple Aravind iPhone"
  },
  {
    "name": "Apple Tebatso",
    "label": "Apple Tebatso",
    "value": "Apple Tebatso"
  },
  {
    "name": "samsung SM-A505U1",
    "label": "samsung SM-A505U1",
    "value": "samsung SM-A505U1"
  },
  {
    "name": "GOMOBILE GO1452",
    "label": "GOMOBILE GO1452",
    "value": "GOMOBILE GO1452"
  },
  {
    "name": "Hot Pepper Inc HPP-GS1",
    "label": "Hot Pepper Inc HPP-GS1",
    "value": "Hot Pepper Inc HPP-GS1"
  },
  {
    "name": "Colors_Mobile P52_Pride5C",
    "label": "Colors_Mobile P52_Pride5C",
    "value": "Colors_Mobile P52_Pride5C"
  },
  {
    "name": "Xiaomi 21091116C",
    "label": "Xiaomi 21091116C",
    "value": "Xiaomi 21091116C"
  },
  {
    "name": "HAOVM T2000",
    "label": "HAOVM T2000",
    "value": "HAOVM T2000"
  },
  {
    "name": "ZTE ZTE BLADE A0620",
    "label": "ZTE ZTE BLADE A0620",
    "value": "ZTE ZTE BLADE A0620"
  },
  {
    "name": "HMD Global Nokia 8.3 5G",
    "label": "HMD Global Nokia 8.3 5G",
    "value": "HMD Global Nokia 8.3 5G"
  },
  {
    "name": "MyPhone myXI3",
    "label": "MyPhone myXI3",
    "value": "MyPhone myXI3"
  },
  {
    "name": "LAVA Z61_2GB",
    "label": "LAVA Z61_2GB",
    "value": "LAVA Z61_2GB"
  },
  {
    "name": "Cherry_Mobile A890",
    "label": "Cherry_Mobile A890",
    "value": "Cherry_Mobile A890"
  },
  {
    "name": "samsung SM-G9810",
    "label": "samsung SM-G9810",
    "value": "samsung SM-G9810"
  },
  {
    "name": "Apple Candice",
    "label": "Apple Candice",
    "value": "Apple Candice"
  },
  {
    "name": "realme Realme Narzo 30A",
    "label": "realme Realme Narzo 30A",
    "value": "realme Realme Narzo 30A"
  },
  {
    "name": "Billion Capture+",
    "label": "Billion Capture+",
    "value": "Billion Capture+"
  },
  {
    "name": "Apple ‏iPhone ‏Nuha",
    "label": "Apple ‏iPhone ‏Nuha",
    "value": "Apple ‏iPhone ‏Nuha"
  },
  {
    "name": "samsung SM-S326DL",
    "label": "samsung SM-S326DL",
    "value": "samsung SM-S326DL"
  },
  {
    "name": "ADVAN 1013",
    "label": "ADVAN 1013",
    "value": "ADVAN 1013"
  },
  {
    "name": "Multilaser M10A_Lite",
    "label": "Multilaser M10A_Lite",
    "value": "Multilaser M10A_Lite"
  },
  {
    "name": "itel itel A509WP",
    "label": "itel itel A509WP",
    "value": "itel itel A509WP"
  },
  {
    "name": "Alldocube iPlay_40",
    "label": "Alldocube iPlay_40",
    "value": "Alldocube iPlay_40"
  },
  {
    "name": "ADVAN 8003",
    "label": "ADVAN 8003",
    "value": "ADVAN 8003"
  },
  {
    "name": "OPPO PADM00",
    "label": "OPPO PADM00",
    "value": "OPPO PADM00"
  },
  {
    "name": "HUAWEI AUM-L41",
    "label": "HUAWEI AUM-L41",
    "value": "HUAWEI AUM-L41"
  },
  {
    "name": "HUAWEI NEN-L22",
    "label": "HUAWEI NEN-L22",
    "value": "HUAWEI NEN-L22"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KF6m",
    "label": "TECNO MOBILE LIMITED TECNO KF6m",
    "value": "TECNO MOBILE LIMITED TECNO KF6m"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X604B",
    "label": "INFINIX MOBILITY LIMITED Infinix X604B",
    "value": "INFINIX MOBILITY LIMITED Infinix X604B"
  },
  {
    "name": "OnePlus LE2111",
    "label": "OnePlus LE2111",
    "value": "OnePlus LE2111"
  },
  {
    "name": "BLU C5 MAX",
    "label": "BLU C5 MAX",
    "value": "BLU C5 MAX"
  },
  {
    "name": "OUKITEL C18_Pro",
    "label": "OUKITEL C18_Pro",
    "value": "OUKITEL C18_Pro"
  },
  {
    "name": "BLU G50 Mega",
    "label": "BLU G50 Mega",
    "value": "BLU G50 Mega"
  },
  {
    "name": "TECNO TECNO LF7n",
    "label": "TECNO TECNO LF7n",
    "value": "TECNO TECNO LF7n"
  },
  {
    "name": "SHARP S1",
    "label": "SHARP S1",
    "value": "SHARP S1"
  },
  {
    "name": "roco NETWORK",
    "label": "roco NETWORK",
    "value": "roco NETWORK"
  },
  {
    "name": "samsung SM-A015T1",
    "label": "samsung SM-A015T1",
    "value": "samsung SM-A015T1"
  },
  {
    "name": "lenovo Lenovo K13 Pro",
    "label": "lenovo Lenovo K13 Pro",
    "value": "lenovo Lenovo K13 Pro"
  },
  {
    "name": "CUBOT NOTE 9",
    "label": "CUBOT NOTE 9",
    "value": "CUBOT NOTE 9"
  },
  {
    "name": "TCL 5099A",
    "label": "TCL 5099A",
    "value": "TCL 5099A"
  },
  {
    "name": "Xiaomi Mi MIX 3 5G",
    "label": "Xiaomi Mi MIX 3 5G",
    "value": "Xiaomi Mi MIX 3 5G"
  },
  {
    "name": "Haier Haier G32",
    "label": "Haier Haier G32",
    "value": "Haier Haier G32"
  },
  {
    "name": "SKY Devices Elite A6",
    "label": "SKY Devices Elite A6",
    "value": "SKY Devices Elite A6"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KG5n",
    "label": "TECNO MOBILE LIMITED TECNO KG5n",
    "value": "TECNO MOBILE LIMITED TECNO KG5n"
  },
  {
    "name": "incar Q3-EEA",
    "label": "incar Q3-EEA",
    "value": "incar Q3-EEA"
  },
  {
    "name": "LT LT P9",
    "label": "LT LT P9",
    "value": "LT LT P9"
  },
  {
    "name": "OPPO CPH1837",
    "label": "OPPO CPH1837",
    "value": "OPPO CPH1837"
  },
  {
    "name": "samsung SM-T825",
    "label": "samsung SM-T825",
    "value": "samsung SM-T825"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X606B",
    "label": "INFINIX MOBILITY LIMITED Infinix X606B",
    "value": "INFINIX MOBILITY LIMITED Infinix X606B"
  },
  {
    "name": "HUAWEI PPA-LX1",
    "label": "HUAWEI PPA-LX1",
    "value": "HUAWEI PPA-LX1"
  },
  {
    "name": "Azumi Azumi V4",
    "label": "Azumi Azumi V4",
    "value": "Azumi Azumi V4"
  },
  {
    "name": "BLU G61",
    "label": "BLU G61",
    "value": "BLU G61"
  },
  {
    "name": "Siragon SP-6200",
    "label": "Siragon SP-6200",
    "value": "Siragon SP-6200"
  },
  {
    "name": "KONROW SWEET5",
    "label": "KONROW SWEET5",
    "value": "KONROW SWEET5"
  },
  {
    "name": "Apple Ronalyn’s iPhone",
    "label": "Apple Ronalyn’s iPhone",
    "value": "Apple Ronalyn’s iPhone"
  },
  {
    "name": "TECNO TECNO CI8",
    "label": "TECNO TECNO CI8",
    "value": "TECNO TECNO CI8"
  },
  {
    "name": "Hisense Hisense Infinity H40 Rock",
    "label": "Hisense Hisense Infinity H40 Rock",
    "value": "Hisense Hisense Infinity H40 Rock"
  },
  {
    "name": "YEZZ MAX_1_PLUS",
    "label": "YEZZ MAX_1_PLUS",
    "value": "YEZZ MAX_1_PLUS"
  },
  {
    "name": "DUODUOGO tablet",
    "label": "DUODUOGO tablet",
    "value": "DUODUOGO tablet"
  },
  {
    "name": "Apple Adrielle’s iPhone",
    "label": "Apple Adrielle’s iPhone",
    "value": "Apple Adrielle’s iPhone"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KA6",
    "label": "TECNO MOBILE LIMITED TECNO KA6",
    "value": "TECNO MOBILE LIMITED TECNO KA6"
  },
  {
    "name": "Apple 정헌",
    "label": "Apple 정헌",
    "value": "Apple 정헌"
  },
  {
    "name": "TCL 4063F",
    "label": "TCL 4063F",
    "value": "TCL 4063F"
  },
  {
    "name": "samsung SM-A520L",
    "label": "samsung SM-A520L",
    "value": "samsung SM-A520L"
  },
  {
    "name": "Hewlett-Packard HP Compaq dc5800 Microtower",
    "label": "Hewlett-Packard HP Compaq dc5800 Microtower",
    "value": "Hewlett-Packard HP Compaq dc5800 Microtower"
  },
  {
    "name": "ZTE ZTE A2121E",
    "label": "ZTE ZTE A2121E",
    "value": "ZTE ZTE A2121E"
  },
  {
    "name": "Ulefone Armor_X6",
    "label": "Ulefone Armor_X6",
    "value": "Ulefone Armor_X6"
  },
  {
    "name": "Smooth Smooth 6.0",
    "label": "Smooth Smooth 6.0",
    "value": "Smooth Smooth 6.0"
  },
  {
    "name": "TCL 8082",
    "label": "TCL 8082",
    "value": "TCL 8082"
  },
  {
    "name": "HMD Global Nokia C2 Tennen",
    "label": "HMD Global Nokia C2 Tennen",
    "value": "HMD Global Nokia C2 Tennen"
  },
  {
    "name": "HMD Global Nokia X20",
    "label": "HMD Global Nokia X20",
    "value": "HMD Global Nokia X20"
  },
  {
    "name": "samsung SM-A305FN",
    "label": "samsung SM-A305FN",
    "value": "samsung SM-A305FN"
  },
  {
    "name": "Win N4plus",
    "label": "Win N4plus",
    "value": "Win N4plus"
  },
  {
    "name": "motorola Lenovo K15 Plus",
    "label": "motorola Lenovo K15 Plus",
    "value": "motorola Lenovo K15 Plus"
  },
  {
    "name": "Lanix X770",
    "label": "Lanix X770",
    "value": "Lanix X770"
  },
  {
    "name": "TCL 5053D",
    "label": "TCL 5053D",
    "value": "TCL 5053D"
  },
  {
    "name": "Wingtech TMAF025G",
    "label": "Wingtech TMAF025G",
    "value": "Wingtech TMAF025G"
  },
  {
    "name": "LT LT-C3750",
    "label": "LT LT-C3750",
    "value": "LT LT-C3750"
  },
  {
    "name": "TCL A507DL",
    "label": "TCL A507DL",
    "value": "TCL A507DL"
  },
  {
    "name": "samsung SM-C7010",
    "label": "samsung SM-C7010",
    "value": "samsung SM-C7010"
  },
  {
    "name": "ANT DAO TECHNOLOGY LIMITED Air1",
    "label": "ANT DAO TECHNOLOGY LIMITED Air1",
    "value": "ANT DAO TECHNOLOGY LIMITED Air1"
  },
  {
    "name": "Kalley Silver Max Lite 2",
    "label": "Kalley Silver Max Lite 2",
    "value": "Kalley Silver Max Lite 2"
  },
  {
    "name": "LGE LM-Q927L",
    "label": "LGE LM-Q927L",
    "value": "LGE LM-Q927L"
  },
  {
    "name": "TCL 5062W",
    "label": "TCL 5062W",
    "value": "TCL 5062W"
  },
  {
    "name": "TCL T766S",
    "label": "TCL T766S",
    "value": "TCL T766S"
  },
  {
    "name": "BLU C5",
    "label": "BLU C5",
    "value": "BLU C5"
  },
  {
    "name": "Multilaser M7-3G",
    "label": "Multilaser M7-3G",
    "value": "Multilaser M7-3G"
  },
  {
    "name": "JOYAR 100044018G",
    "label": "JOYAR 100044018G",
    "value": "JOYAR 100044018G"
  },
  {
    "name": "Multilaser M10-4G-T3",
    "label": "Multilaser M10-4G-T3",
    "value": "Multilaser M10-4G-T3"
  },
  {
    "name": "Multilaser Multilaser_G_Pro",
    "label": "Multilaser Multilaser_G_Pro",
    "value": "Multilaser Multilaser_G_Pro"
  },
  {
    "name": "positivo Twist Tab",
    "label": "positivo Twist Tab",
    "value": "positivo Twist Tab"
  },
  {
    "name": "LEAGOO M9A Pro",
    "label": "LEAGOO M9A Pro",
    "value": "LEAGOO M9A Pro"
  },
  {
    "name": "Mobiwire Kicka 5 Plus 4G",
    "label": "Mobiwire Kicka 5 Plus 4G",
    "value": "Mobiwire Kicka 5 Plus 4G"
  },
  {
    "name": "ZTE ZTE 8045",
    "label": "ZTE ZTE 8045",
    "value": "ZTE ZTE 8045"
  },
  {
    "name": "WIKO W-V720-EEA",
    "label": "WIKO W-V720-EEA",
    "value": "WIKO W-V720-EEA"
  },
  {
    "name": "HXY A25",
    "label": "HXY A25",
    "value": "HXY A25"
  },
  {
    "name": "samsung SM-J727V",
    "label": "samsung SM-J727V",
    "value": "samsung SM-J727V"
  },
  {
    "name": "sanmu Amber8S",
    "label": "sanmu Amber8S",
    "value": "sanmu Amber8S"
  },
  {
    "name": "HUAWEI AUM-L33",
    "label": "HUAWEI AUM-L33",
    "value": "HUAWEI AUM-L33"
  },
  {
    "name": "vivo V1936AL",
    "label": "vivo V1936AL",
    "value": "vivo V1936AL"
  },
  {
    "name": "HTC HTC U12+",
    "label": "HTC HTC U12+",
    "value": "HTC HTC U12+"
  },
  {
    "name": "TechPad S6",
    "label": "TechPad S6",
    "value": "TechPad S6"
  },
  {
    "name": "HUAWEI JKM-AL00a",
    "label": "HUAWEI JKM-AL00a",
    "value": "HUAWEI JKM-AL00a"
  },
  {
    "name": "Blackview BV9900Pro",
    "label": "Blackview BV9900Pro",
    "value": "Blackview BV9900Pro"
  },
  {
    "name": "Fortune Ship Wildfire E",
    "label": "Fortune Ship Wildfire E",
    "value": "Fortune Ship Wildfire E"
  },
  {
    "name": "LENOVO Lenovo TB-J706F",
    "label": "LENOVO Lenovo TB-J706F",
    "value": "LENOVO Lenovo TB-J706F"
  },
  {
    "name": "LGE LG-LS998",
    "label": "LGE LG-LS998",
    "value": "LGE LG-LS998"
  },
  {
    "name": "HTC HTC U11",
    "label": "HTC HTC U11",
    "value": "HTC HTC U11"
  },
  {
    "name": "Meizu 15 Lite",
    "label": "Meizu 15 Lite",
    "value": "Meizu 15 Lite"
  },
  {
    "name": "Shenzhen E-dong Technology Co.,Ltd T116",
    "label": "Shenzhen E-dong Technology Co.,Ltd T116",
    "value": "Shenzhen E-dong Technology Co.,Ltd T116"
  },
  {
    "name": "General Mobile e-tab 20",
    "label": "General Mobile e-tab 20",
    "value": "General Mobile e-tab 20"
  },
  {
    "name": "Hisense Hisense H40 Lite",
    "label": "Hisense Hisense H40 Lite",
    "value": "Hisense Hisense H40 Lite"
  },
  {
    "name": "TINNO U304AA",
    "label": "TINNO U304AA",
    "value": "TINNO U304AA"
  },
  {
    "name": "samsung SM-G935W8",
    "label": "samsung SM-G935W8",
    "value": "samsung SM-G935W8"
  },
  {
    "name": "Casper VIA_E30",
    "label": "Casper VIA_E30",
    "value": "Casper VIA_E30"
  },
  {
    "name": "STG STG_C10",
    "label": "STG STG_C10",
    "value": "STG STG_C10"
  },
  {
    "name": "Apple Fefe's iPhone",
    "label": "Apple Fefe's iPhone",
    "value": "Apple Fefe's iPhone"
  },
  {
    "name": "OPPO OPPO A73t",
    "label": "OPPO OPPO A73t",
    "value": "OPPO OPPO A73t"
  },
  {
    "name": "HUAWEI VOG-AL10",
    "label": "HUAWEI VOG-AL10",
    "value": "HUAWEI VOG-AL10"
  },
  {
    "name": "Xiaomi 2201122C",
    "label": "Xiaomi 2201122C",
    "value": "Xiaomi 2201122C"
  },
  {
    "name": "Google zork",
    "label": "Google zork",
    "value": "Google zork"
  },
  {
    "name": "ZTE ZTE Blade A5 2020RU",
    "label": "ZTE ZTE Blade A5 2020RU",
    "value": "ZTE ZTE Blade A5 2020RU"
  },
  {
    "name": "wheatek TITAN P15000 PRO 5G",
    "label": "wheatek TITAN P15000 PRO 5G",
    "value": "wheatek TITAN P15000 PRO 5G"
  },
  {
    "name": "Wileyfox Swift 2 Plus",
    "label": "Wileyfox Swift 2 Plus",
    "value": "Wileyfox Swift 2 Plus"
  },
  {
    "name": "WALTON Primo H8 Pro",
    "label": "WALTON Primo H8 Pro",
    "value": "WALTON Primo H8 Pro"
  },
  {
    "name": "OnePlus LE2125",
    "label": "OnePlus LE2125",
    "value": "OnePlus LE2125"
  },
  {
    "name": "samsung SM-F707B",
    "label": "samsung SM-F707B",
    "value": "samsung SM-F707B"
  },
  {
    "name": "Xiaomi 22041216C",
    "label": "Xiaomi 22041216C",
    "value": "Xiaomi 22041216C"
  },
  {
    "name": "Google hatch",
    "label": "Google hatch",
    "value": "Google hatch"
  },
  {
    "name": "YU YU5014",
    "label": "YU YU5014",
    "value": "YU YU5014"
  },
  {
    "name": "Xiaomi 2112123AC",
    "label": "Xiaomi 2112123AC",
    "value": "Xiaomi 2112123AC"
  },
  {
    "name": "samsung SM-G6100",
    "label": "samsung SM-G6100",
    "value": "samsung SM-G6100"
  },
  {
    "name": "TCL 5003A",
    "label": "TCL 5003A",
    "value": "TCL 5003A"
  },
  {
    "name": "realme RMX3372",
    "label": "realme RMX3372",
    "value": "realme RMX3372"
  },
  {
    "name": "TCL Alcatel_5004R",
    "label": "TCL Alcatel_5004R",
    "value": "TCL Alcatel_5004R"
  },
  {
    "name": "samsung SM-A037W",
    "label": "samsung SM-A037W",
    "value": "samsung SM-A037W"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CH9",
    "label": "TECNO MOBILE LIMITED TECNO CH9",
    "value": "TECNO MOBILE LIMITED TECNO CH9"
  },
  {
    "name": "GIONEE GIONEE F6",
    "label": "GIONEE GIONEE F6",
    "value": "GIONEE GIONEE F6"
  },
  {
    "name": "OnePlus CPH2415",
    "label": "OnePlus CPH2415",
    "value": "OnePlus CPH2415"
  },
  {
    "name": "samsung SM-G398FN",
    "label": "samsung SM-G398FN",
    "value": "samsung SM-G398FN"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KC2j",
    "label": "TECNO MOBILE LIMITED TECNO KC2j",
    "value": "TECNO MOBILE LIMITED TECNO KC2j"
  },
  {
    "name": "OPPO CPH2307",
    "label": "OPPO CPH2307",
    "value": "OPPO CPH2307"
  },
  {
    "name": "TCL 5002D_EEA",
    "label": "TCL 5002D_EEA",
    "value": "TCL 5002D_EEA"
  },
  {
    "name": "HUAWEI ELS-N04",
    "label": "HUAWEI ELS-N04",
    "value": "HUAWEI ELS-N04"
  },
  {
    "name": "Xiaomi M2012K11C",
    "label": "Xiaomi M2012K11C",
    "value": "Xiaomi M2012K11C"
  },
  {
    "name": "HUAWEI DUK-L09",
    "label": "HUAWEI DUK-L09",
    "value": "HUAWEI DUK-L09"
  },
  {
    "name": "HUAWEI BAC-L21",
    "label": "HUAWEI BAC-L21",
    "value": "HUAWEI BAC-L21"
  },
  {
    "name": "samsung SC-02H",
    "label": "samsung SC-02H",
    "value": "samsung SC-02H"
  },
  {
    "name": "samsung SM-G9980",
    "label": "samsung SM-G9980",
    "value": "samsung SM-G9980"
  },
  {
    "name": "samsung SM-J337T",
    "label": "samsung SM-J337T",
    "value": "samsung SM-J337T"
  },
  {
    "name": "CROSSCALL Trekker-X4",
    "label": "CROSSCALL Trekker-X4",
    "value": "CROSSCALL Trekker-X4"
  },
  {
    "name": "TCL 9013T",
    "label": "TCL 9013T",
    "value": "TCL 9013T"
  },
  {
    "name": "Sony SOV34",
    "label": "Sony SOV34",
    "value": "Sony SOV34"
  },
  {
    "name": "Apple Tholwanathuli’s iPhone",
    "label": "Apple Tholwanathuli’s iPhone",
    "value": "Apple Tholwanathuli’s iPhone"
  },
  {
    "name": "Morep M850",
    "label": "Morep M850",
    "value": "Morep M850"
  },
  {
    "name": "vivo vivo 1805",
    "label": "vivo vivo 1805",
    "value": "vivo vivo 1805"
  },
  {
    "name": "samsung SM-J337A",
    "label": "samsung SM-J337A",
    "value": "samsung SM-J337A"
  },
  {
    "name": "HUAWEI VCE-AL00",
    "label": "HUAWEI VCE-AL00",
    "value": "HUAWEI VCE-AL00"
  },
  {
    "name": "HOME1 HOME1",
    "label": "HOME1 HOME1",
    "value": "HOME1 HOME1"
  },
  {
    "name": "HMD Global Nokia 3.1 C",
    "label": "HMD Global Nokia 3.1 C",
    "value": "HMD Global Nokia 3.1 C"
  },
  {
    "name": "vivo vivo 1906_20",
    "label": "vivo vivo 1906_20",
    "value": "vivo vivo 1906_20"
  },
  {
    "name": "Mobicel FORCE",
    "label": "Mobicel FORCE",
    "value": "Mobicel FORCE"
  },
  {
    "name": "OnePlus HD1925",
    "label": "OnePlus HD1925",
    "value": "OnePlus HD1925"
  },
  {
    "name": "Multilaser OBASMART_3",
    "label": "Multilaser OBASMART_3",
    "value": "Multilaser OBASMART_3"
  },
  {
    "name": "alps L663",
    "label": "alps L663",
    "value": "alps L663"
  },
  {
    "name": "DOOGEE S59Pro",
    "label": "DOOGEE S59Pro",
    "value": "DOOGEE S59Pro"
  },
  {
    "name": "GIONEE GIONEE S12",
    "label": "GIONEE GIONEE S12",
    "value": "GIONEE GIONEE S12"
  },
  {
    "name": "incar TBT8A10",
    "label": "incar TBT8A10",
    "value": "incar TBT8A10"
  },
  {
    "name": "STG STG S10",
    "label": "STG STG S10",
    "value": "STG STG S10"
  },
  {
    "name": "OnePlus HD1910",
    "label": "OnePlus HD1910",
    "value": "OnePlus HD1910"
  },
  {
    "name": "LGE LM-K830",
    "label": "LGE LM-K830",
    "value": "LGE LM-K830"
  },
  {
    "name": "Microsoft Surface Duo",
    "label": "Microsoft Surface Duo",
    "value": "Microsoft Surface Duo"
  },
  {
    "name": "WIKO W-V745-OPE",
    "label": "WIKO W-V745-OPE",
    "value": "WIKO W-V745-OPE"
  },
  {
    "name": "asus ASUS_X00LDB",
    "label": "asus ASUS_X00LDB",
    "value": "asus ASUS_X00LDB"
  },
  {
    "name": "Infinix Infinix X572-LTE",
    "label": "Infinix Infinix X572-LTE",
    "value": "Infinix Infinix X572-LTE"
  },
  {
    "name": "samsung SM-C900Y",
    "label": "samsung SM-C900Y",
    "value": "samsung SM-C900Y"
  },
  {
    "name": "itel itel L6503",
    "label": "itel itel L6503",
    "value": "itel itel L6503"
  },
  {
    "name": "ONYX NovaAir",
    "label": "ONYX NovaAir",
    "value": "ONYX NovaAir"
  },
  {
    "name": "revoview M671M4",
    "label": "revoview M671M4",
    "value": "revoview M671M4"
  },
  {
    "name": "X-TIGI Hope10 Pro",
    "label": "X-TIGI Hope10 Pro",
    "value": "X-TIGI Hope10 Pro"
  },
  {
    "name": "Quest Amber",
    "label": "Quest Amber",
    "value": "Quest Amber"
  },
  {
    "name": "Premier Code (PRIVATE) LIMITED DS-CL1",
    "label": "Premier Code (PRIVATE) LIMITED DS-CL1",
    "value": "Premier Code (PRIVATE) LIMITED DS-CL1"
  },
  {
    "name": "HUAWEI BLL-L21",
    "label": "HUAWEI BLL-L21",
    "value": "HUAWEI BLL-L21"
  },
  {
    "name": "ZTE ZTE Blade A52",
    "label": "ZTE ZTE Blade A52",
    "value": "ZTE ZTE Blade A52"
  },
  {
    "name": "DEXP BL155",
    "label": "DEXP BL155",
    "value": "DEXP BL155"
  },
  {
    "name": "HUAWEI WAS-AL00",
    "label": "HUAWEI WAS-AL00",
    "value": "HUAWEI WAS-AL00"
  },
  {
    "name": "EPIKONE K605",
    "label": "EPIKONE K605",
    "value": "EPIKONE K605"
  },
  {
    "name": "TCL T790Y",
    "label": "TCL T790Y",
    "value": "TCL T790Y"
  },
  {
    "name": "ZTE Z557BL",
    "label": "ZTE Z557BL",
    "value": "ZTE Z557BL"
  },
  {
    "name": "BQru BQ-5745L",
    "label": "BQru BQ-5745L",
    "value": "BQru BQ-5745L"
  },
  {
    "name": "Nothing A063",
    "label": "Nothing A063",
    "value": "Nothing A063"
  },
  {
    "name": "Apple Itebogeng’s Iphone",
    "label": "Apple Itebogeng’s Iphone",
    "value": "Apple Itebogeng’s Iphone"
  },
  {
    "name": "OPPO PCGM00",
    "label": "OPPO PCGM00",
    "value": "OPPO PCGM00"
  },
  {
    "name": "incar NQT-1013GIQ11",
    "label": "incar NQT-1013GIQ11",
    "value": "incar NQT-1013GIQ11"
  },
  {
    "name": "UMIDIGI Power 3",
    "label": "UMIDIGI Power 3",
    "value": "UMIDIGI Power 3"
  },
  {
    "name": "TP-Link Neffos_X9",
    "label": "TP-Link Neffos_X9",
    "value": "TP-Link Neffos_X9"
  },
  {
    "name": "vsmart Joy 1+",
    "label": "vsmart Joy 1+",
    "value": "vsmart Joy 1+"
  },
  {
    "name": "vivo V2048A",
    "label": "vivo V2048A",
    "value": "vivo V2048A"
  },
  {
    "name": "Crosscall Core-X4",
    "label": "Crosscall Core-X4",
    "value": "Crosscall Core-X4"
  },
  {
    "name": "OUKITEL K15_Plus",
    "label": "OUKITEL K15_Plus",
    "value": "OUKITEL K15_Plus"
  },
  {
    "name": "google G011A0558",
    "label": "google G011A0558",
    "value": "google G011A0558"
  },
  {
    "name": "LEAGOO T8s",
    "label": "LEAGOO T8s",
    "value": "LEAGOO T8s"
  },
  {
    "name": "TCL 5152D",
    "label": "TCL 5152D",
    "value": "TCL 5152D"
  },
  {
    "name": "samsung SM-S357BL",
    "label": "samsung SM-S357BL",
    "value": "samsung SM-S357BL"
  },
  {
    "name": "SHARP 803SH",
    "label": "SHARP 803SH",
    "value": "SHARP 803SH"
  },
  {
    "name": "Desktop - Nintendo",
    "label": "Desktop - Nintendo",
    "value": "Desktop - Nintendo"
  },
  {
    "name": "Sky_Devices Elite D5Max",
    "label": "Sky_Devices Elite D5Max",
    "value": "Sky_Devices Elite D5Max"
  },
  {
    "name": "LGE LM-X600IM",
    "label": "LGE LM-X600IM",
    "value": "LGE LM-X600IM"
  },
  {
    "name": "Apple Ntshunxeko’s iPhone",
    "label": "Apple Ntshunxeko’s iPhone",
    "value": "Apple Ntshunxeko’s iPhone"
  },
  {
    "name": "MobiWire SmartN12",
    "label": "MobiWire SmartN12",
    "value": "MobiWire SmartN12"
  },
  {
    "name": "Apple Rendani’s iPhone",
    "label": "Apple Rendani’s iPhone",
    "value": "Apple Rendani’s iPhone"
  },
  {
    "name": "WIKO W_P130",
    "label": "WIKO W_P130",
    "value": "WIKO W_P130"
  },
  {
    "name": "Foxconn 8788_7",
    "label": "Foxconn 8788_7",
    "value": "Foxconn 8788_7"
  },
  {
    "name": "LAVA LAVA Blaze",
    "label": "LAVA LAVA Blaze",
    "value": "LAVA LAVA Blaze"
  },
  {
    "name": "Acer ACTAB1021_A",
    "label": "Acer ACTAB1021_A",
    "value": "Acer ACTAB1021_A"
  },
  {
    "name": "General Mobile GM 9 Pro d",
    "label": "General Mobile GM 9 Pro d",
    "value": "General Mobile GM 9 Pro d"
  },
  {
    "name": "Positivo Twist Tab Kids",
    "label": "Positivo Twist Tab Kids",
    "value": "Positivo Twist Tab Kids"
  },
  {
    "name": "OPPO CPH1861",
    "label": "OPPO CPH1861",
    "value": "OPPO CPH1861"
  },
  {
    "name": "benco AH9110",
    "label": "benco AH9110",
    "value": "benco AH9110"
  },
  {
    "name": "motorola moto e(6s)",
    "label": "motorola moto e(6s)",
    "value": "motorola moto e(6s)"
  },
  {
    "name": "SPA Condor Electronics Plume H1",
    "label": "SPA Condor Electronics Plume H1",
    "value": "SPA Condor Electronics Plume H1"
  },
  {
    "name": "HP SM-N960F",
    "label": "HP SM-N960F",
    "value": "HP SM-N960F"
  },
  {
    "name": "Ulefone Armor X5",
    "label": "Ulefone Armor X5",
    "value": "Ulefone Armor X5"
  },
  {
    "name": "samsung SM-J727P",
    "label": "samsung SM-J727P",
    "value": "samsung SM-J727P"
  },
  {
    "name": "TECNO TECNO CH6i",
    "label": "TECNO TECNO CH6i",
    "value": "TECNO TECNO CH6i"
  },
  {
    "name": "PCD P40",
    "label": "PCD P40",
    "value": "PCD P40"
  },
  {
    "name": "Xiaomi 220233L2C",
    "label": "Xiaomi 220233L2C",
    "value": "Xiaomi 220233L2C"
  },
  {
    "name": "ZONKO K118",
    "label": "ZONKO K118",
    "value": "ZONKO K118"
  },
  {
    "name": "SPC L70_Lite",
    "label": "SPC L70_Lite",
    "value": "SPC L70_Lite"
  },
  {
    "name": "TCL 5130M",
    "label": "TCL 5130M",
    "value": "TCL 5130M"
  },
  {
    "name": "ZTE P501",
    "label": "ZTE P501",
    "value": "ZTE P501"
  },
  {
    "name": "FUJITSU F-02H",
    "label": "FUJITSU F-02H",
    "value": "FUJITSU F-02H"
  },
  {
    "name": "UMIDIGI Power",
    "label": "UMIDIGI Power",
    "value": "UMIDIGI Power"
  },
  {
    "name": "LGE LM-V350",
    "label": "LGE LM-V350",
    "value": "LGE LM-V350"
  },
  {
    "name": "Symphony Symphony Atom ii",
    "label": "Symphony Symphony Atom ii",
    "value": "Symphony Symphony Atom ii"
  },
  {
    "name": "gionee P61",
    "label": "gionee P61",
    "value": "gionee P61"
  },
  {
    "name": "BLU C6L 2020",
    "label": "BLU C6L 2020",
    "value": "BLU C6L 2020"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CB7",
    "label": "TECNO MOBILE LIMITED TECNO CB7",
    "value": "TECNO MOBILE LIMITED TECNO CB7"
  },
  {
    "name": "GIONEE GIONEE M12",
    "label": "GIONEE GIONEE M12",
    "value": "GIONEE GIONEE M12"
  },
  {
    "name": "evertrade H51",
    "label": "evertrade H51",
    "value": "evertrade H51"
  },
  {
    "name": "Xiaomi Redmi K30",
    "label": "Xiaomi Redmi K30",
    "value": "Xiaomi Redmi K30"
  },
  {
    "name": "Xiaomi M2011K2C",
    "label": "Xiaomi M2011K2C",
    "value": "Xiaomi M2011K2C"
  },
  {
    "name": "WIKO W-K510-EEA",
    "label": "WIKO W-K510-EEA",
    "value": "WIKO W-K510-EEA"
  },
  {
    "name": "360 1713-A01",
    "label": "360 1713-A01",
    "value": "360 1713-A01"
  },
  {
    "name": "samsung SM-G990U",
    "label": "samsung SM-G990U",
    "value": "samsung SM-G990U"
  },
  {
    "name": "ZTE Z851M",
    "label": "ZTE Z851M",
    "value": "ZTE Z851M"
  },
  {
    "name": "Xiaomi ZS660KL",
    "label": "Xiaomi ZS660KL",
    "value": "Xiaomi ZS660KL"
  },
  {
    "name": "Xiaomi vida",
    "label": "Xiaomi vida",
    "value": "Xiaomi vida"
  },
  {
    "name": "TECNO TECNO LG8n",
    "label": "TECNO TECNO LG8n",
    "value": "TECNO TECNO LG8n"
  },
  {
    "name": "OPPO CPH2407",
    "label": "OPPO CPH2407",
    "value": "OPPO CPH2407"
  },
  {
    "name": "samsung SM-S9010",
    "label": "samsung SM-S9010",
    "value": "samsung SM-S9010"
  },
  {
    "name": "NUU N5702L",
    "label": "NUU N5702L",
    "value": "NUU N5702L"
  },
  {
    "name": "Premio S54",
    "label": "Premio S54",
    "value": "Premio S54"
  },
  {
    "name": "ZTE Z6201V",
    "label": "ZTE Z6201V",
    "value": "ZTE Z6201V"
  },
  {
    "name": "OPPO CPH1945",
    "label": "OPPO CPH1945",
    "value": "OPPO CPH1945"
  },
  {
    "name": "hena NovaTab_R10",
    "label": "hena NovaTab_R10",
    "value": "hena NovaTab_R10"
  },
  {
    "name": "WALTON Primo_NF4_2GB",
    "label": "WALTON Primo_NF4_2GB",
    "value": "WALTON Primo_NF4_2GB"
  },
  {
    "name": "Sony XQ-CC54",
    "label": "Sony XQ-CC54",
    "value": "Sony XQ-CC54"
  },
  {
    "name": "TCL 5052Y",
    "label": "TCL 5052Y",
    "value": "TCL 5052Y"
  },
  {
    "name": "wheatek WP12 Pro",
    "label": "wheatek WP12 Pro",
    "value": "wheatek WP12 Pro"
  },
  {
    "name": "HUAWEI LYA-L0C",
    "label": "HUAWEI LYA-L0C",
    "value": "HUAWEI LYA-L0C"
  },
  {
    "name": "samsung SM-J510H",
    "label": "samsung SM-J510H",
    "value": "samsung SM-J510H"
  },
  {
    "name": "Kruger_Matz Flow 9",
    "label": "Kruger_Matz Flow 9",
    "value": "Kruger_Matz Flow 9"
  },
  {
    "name": "TIGERS TIS001_S4",
    "label": "TIGERS TIS001_S4",
    "value": "TIGERS TIS001_S4"
  },
  {
    "name": "samsung SM-J600L",
    "label": "samsung SM-J600L",
    "value": "samsung SM-J600L"
  },
  {
    "name": "Multilaser M7S_PLUS",
    "label": "Multilaser M7S_PLUS",
    "value": "Multilaser M7S_PLUS"
  },
  {
    "name": "DOOGEE Y7",
    "label": "DOOGEE Y7",
    "value": "DOOGEE Y7"
  },
  {
    "name": "iPad8,1 iPad",
    "label": "iPad8,1 iPad",
    "value": "iPad8,1 iPad"
  },
  {
    "name": "RAVOZ RAVOZ Z6 Lite",
    "label": "RAVOZ RAVOZ Z6 Lite",
    "value": "RAVOZ RAVOZ Z6 Lite"
  },
  {
    "name": "Vertex Lion_DC",
    "label": "Vertex Lion_DC",
    "value": "Vertex Lion_DC"
  },
  {
    "name": "Azumi Azumi_V5",
    "label": "Azumi Azumi_V5",
    "value": "Azumi Azumi_V5"
  },
  {
    "name": "iBRIT M8419",
    "label": "iBRIT M8419",
    "value": "iBRIT M8419"
  },
  {
    "name": "General Mobile G310",
    "label": "General Mobile G310",
    "value": "General Mobile G310"
  },
  {
    "name": "realme RMX3312",
    "label": "realme RMX3312",
    "value": "realme RMX3312"
  },
  {
    "name": "Overmax OV10274G",
    "label": "Overmax OV10274G",
    "value": "Overmax OV10274G"
  },
  {
    "name": "TENNO i13 Max",
    "label": "TENNO i13 Max",
    "value": "TENNO i13 Max"
  },
  {
    "name": "samsung SM-T583",
    "label": "samsung SM-T583",
    "value": "samsung SM-T583"
  },
  {
    "name": "BLU Vivo XI",
    "label": "BLU Vivo XI",
    "value": "BLU Vivo XI"
  },
  {
    "name": "samsung SM-T975N",
    "label": "samsung SM-T975N",
    "value": "samsung SM-T975N"
  },
  {
    "name": "Xiaomi 22101316UCP",
    "label": "Xiaomi 22101316UCP",
    "value": "Xiaomi 22101316UCP"
  },
  {
    "name": "vivo V2033",
    "label": "vivo V2033",
    "value": "vivo V2033"
  },
  {
    "name": "HUAWEI BLA-AL00",
    "label": "HUAWEI BLA-AL00",
    "value": "HUAWEI BLA-AL00"
  },
  {
    "name": "vivo vivo X21",
    "label": "vivo vivo X21",
    "value": "vivo vivo X21"
  },
  {
    "name": "Google sdk_gphone_arm64",
    "label": "Google sdk_gphone_arm64",
    "value": "Google sdk_gphone_arm64"
  },
  {
    "name": "iPhone13,4 iPhone",
    "label": "iPhone13,4 iPhone",
    "value": "iPhone13,4 iPhone"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LD7j",
    "label": "TECNO MOBILE LIMITED TECNO LD7j",
    "value": "TECNO MOBILE LIMITED TECNO LD7j"
  },
  {
    "name": "HUAWEI MGA-LX9N",
    "label": "HUAWEI MGA-LX9N",
    "value": "HUAWEI MGA-LX9N"
  },
  {
    "name": "asus ZenFone Max Pro M1",
    "label": "asus ZenFone Max Pro M1",
    "value": "asus ZenFone Max Pro M1"
  },
  {
    "name": "samsung SM-F711W",
    "label": "samsung SM-F711W",
    "value": "samsung SM-F711W"
  },
  {
    "name": "KRIP KRIP_K55",
    "label": "KRIP KRIP_K55",
    "value": "KRIP KRIP_K55"
  },
  {
    "name": "Google rammus",
    "label": "Google rammus",
    "value": "Google rammus"
  },
  {
    "name": "Apple SSon",
    "label": "Apple SSon",
    "value": "Apple SSon"
  },
  {
    "name": "X-TIGI A10c",
    "label": "X-TIGI A10c",
    "value": "X-TIGI A10c"
  },
  {
    "name": "Sony H3123",
    "label": "Sony H3123",
    "value": "Sony H3123"
  },
  {
    "name": "Fortune Ship L601F",
    "label": "Fortune Ship L601F",
    "value": "Fortune Ship L601F"
  },
  {
    "name": "Olike E3",
    "label": "Olike E3",
    "value": "Olike E3"
  },
  {
    "name": "samsung SM-A102U1",
    "label": "samsung SM-A102U1",
    "value": "samsung SM-A102U1"
  },
  {
    "name": "OnePlus ONEPLUS A6000",
    "label": "OnePlus ONEPLUS A6000",
    "value": "OnePlus ONEPLUS A6000"
  },
  {
    "name": "TCL 6002A",
    "label": "TCL 6002A",
    "value": "TCL 6002A"
  },
  {
    "name": "OnePlus KB2007",
    "label": "OnePlus KB2007",
    "value": "OnePlus KB2007"
  },
  {
    "name": "samsung SC-03J",
    "label": "samsung SC-03J",
    "value": "samsung SC-03J"
  },
  {
    "name": "HYUNDAI L610",
    "label": "HYUNDAI L610",
    "value": "HYUNDAI L610"
  },
  {
    "name": "Multilaser M7_3G",
    "label": "Multilaser M7_3G",
    "value": "Multilaser M7_3G"
  },
  {
    "name": "HONOR RMO-NX1",
    "label": "HONOR RMO-NX1",
    "value": "HONOR RMO-NX1"
  },
  {
    "name": "OPPO PDYT20",
    "label": "OPPO PDYT20",
    "value": "OPPO PDYT20"
  },
  {
    "name": "reeder P13 Blue Max Pro",
    "label": "reeder P13 Blue Max Pro",
    "value": "reeder P13 Blue Max Pro"
  },
  {
    "name": "benco AE9010",
    "label": "benco AE9010",
    "value": "benco AE9010"
  },
  {
    "name": "vivo V2144",
    "label": "vivo V2144",
    "value": "vivo V2144"
  },
  {
    "name": "Bullitt Motorola Defy",
    "label": "Bullitt Motorola Defy",
    "value": "Bullitt Motorola Defy"
  },
  {
    "name": "BLU B110DL",
    "label": "BLU B110DL",
    "value": "BLU B110DL"
  },
  {
    "name": "Mobicel EPIC PRO_1",
    "label": "Mobicel EPIC PRO_1",
    "value": "Mobicel EPIC PRO_1"
  },
  {
    "name": "samsung SM-J510FQ",
    "label": "samsung SM-J510FQ",
    "value": "samsung SM-J510FQ"
  },
  {
    "name": "magcomm ALIGATOR S5540",
    "label": "magcomm ALIGATOR S5540",
    "value": "magcomm ALIGATOR S5540"
  },
  {
    "name": "SPA Condor Electronics Plume L2 Pro",
    "label": "SPA Condor Electronics Plume L2 Pro",
    "value": "SPA Condor Electronics Plume L2 Pro"
  },
  {
    "name": "vivo V2183A",
    "label": "vivo V2183A",
    "value": "vivo V2183A"
  },
  {
    "name": "Bmobile B50",
    "label": "Bmobile B50",
    "value": "Bmobile B50"
  },
  {
    "name": "samsung SM-J260FU",
    "label": "samsung SM-J260FU",
    "value": "samsung SM-J260FU"
  },
  {
    "name": "Casper VIA_E4",
    "label": "Casper VIA_E4",
    "value": "Casper VIA_E4"
  },
  {
    "name": "OX TAB Ox-p010",
    "label": "OX TAB Ox-p010",
    "value": "OX TAB Ox-p010"
  },
  {
    "name": "UCT PMT4337_3G_RU",
    "label": "UCT PMT4337_3G_RU",
    "value": "UCT PMT4337_3G_RU"
  },
  {
    "name": "realme RMX2072",
    "label": "realme RMX2072",
    "value": "realme RMX2072"
  },
  {
    "name": "Ulefone Power_5",
    "label": "Ulefone Power_5",
    "value": "Ulefone Power_5"
  },
  {
    "name": "SUGAR SUGAR Y8 MAX",
    "label": "SUGAR SUGAR Y8 MAX",
    "value": "SUGAR SUGAR Y8 MAX"
  },
  {
    "name": "Chinoe C20 Pro",
    "label": "Chinoe C20 Pro",
    "value": "Chinoe C20 Pro"
  },
  {
    "name": "LOGIC LOGIC L65",
    "label": "LOGIC LOGIC L65",
    "value": "LOGIC LOGIC L65"
  },
  {
    "name": "iPhone14,2 iPhone",
    "label": "iPhone14,2 iPhone",
    "value": "iPhone14,2 iPhone"
  },
  {
    "name": "Apple Monicah’s iPhone",
    "label": "Apple Monicah’s iPhone",
    "value": "Apple Monicah’s iPhone"
  },
  {
    "name": "Apple Reneilwe’s iPhone",
    "label": "Apple Reneilwe’s iPhone",
    "value": "Apple Reneilwe’s iPhone"
  },
  {
    "name": "Micromax N8301",
    "label": "Micromax N8301",
    "value": "Micromax N8301"
  },
  {
    "name": "Sony G3416",
    "label": "Sony G3416",
    "value": "Sony G3416"
  },
  {
    "name": "HUAWEI HMA-AL00",
    "label": "HUAWEI HMA-AL00",
    "value": "HUAWEI HMA-AL00"
  },
  {
    "name": "Skyworth SMART 4G Champion",
    "label": "Skyworth SMART 4G Champion",
    "value": "Skyworth SMART 4G Champion"
  },
  {
    "name": "TCL 6102D",
    "label": "TCL 6102D",
    "value": "TCL 6102D"
  },
  {
    "name": "samsung SM-S908W",
    "label": "samsung SM-S908W",
    "value": "samsung SM-S908W"
  },
  {
    "name": "asus ASUS_I001DA",
    "label": "asus ASUS_I001DA",
    "value": "asus ASUS_I001DA"
  },
  {
    "name": "HUAWEI NOH-AN00",
    "label": "HUAWEI NOH-AN00",
    "value": "HUAWEI NOH-AN00"
  },
  {
    "name": "CUBOT C30",
    "label": "CUBOT C30",
    "value": "CUBOT C30"
  },
  {
    "name": "SPA Condor Electronics Plume P8 Pro",
    "label": "SPA Condor Electronics Plume P8 Pro",
    "value": "SPA Condor Electronics Plume P8 Pro"
  },
  {
    "name": "SEMP GO3C",
    "label": "SEMP GO3C",
    "value": "SEMP GO3C"
  },
  {
    "name": "LAVA LAVA Z3 UP 2",
    "label": "LAVA LAVA Z3 UP 2",
    "value": "LAVA LAVA Z3 UP 2"
  },
  {
    "name": "realme RMP2105",
    "label": "realme RMP2105",
    "value": "realme RMP2105"
  },
  {
    "name": "samsung SM-T837T",
    "label": "samsung SM-T837T",
    "value": "samsung SM-T837T"
  },
  {
    "name": "TCL 5003D_EEA",
    "label": "TCL 5003D_EEA",
    "value": "TCL 5003D_EEA"
  },
  {
    "name": "incar M10",
    "label": "incar M10",
    "value": "incar M10"
  },
  {
    "name": "Masstel Masstel Hapi 30",
    "label": "Masstel Masstel Hapi 30",
    "value": "Masstel Masstel Hapi 30"
  },
  {
    "name": "samsung SM-A7070",
    "label": "samsung SM-A7070",
    "value": "samsung SM-A7070"
  },
  {
    "name": "DOOGEE X70",
    "label": "DOOGEE X70",
    "value": "DOOGEE X70"
  },
  {
    "name": "HUAWEI NOH-NX9",
    "label": "HUAWEI NOH-NX9",
    "value": "HUAWEI NOH-NX9"
  },
  {
    "name": "W\\&O Max13",
    "label": "W\\&O Max13",
    "value": "W\\&O Max13"
  },
  {
    "name": "HMR_Networks Boost Ultra",
    "label": "HMR_Networks Boost Ultra",
    "value": "HMR_Networks Boost Ultra"
  },
  {
    "name": "MobiWire Orange Nola play",
    "label": "MobiWire Orange Nola play",
    "value": "MobiWire Orange Nola play"
  },
  {
    "name": "Skyworth SMART TAB 4G e-Biz PRO",
    "label": "Skyworth SMART TAB 4G e-Biz PRO",
    "value": "Skyworth SMART TAB 4G e-Biz PRO"
  },
  {
    "name": "Google volteer",
    "label": "Google volteer",
    "value": "Google volteer"
  },
  {
    "name": "TCL T799B",
    "label": "TCL T799B",
    "value": "TCL T799B"
  },
  {
    "name": "OPPO PEPM00",
    "label": "OPPO PEPM00",
    "value": "OPPO PEPM00"
  },
  {
    "name": "samsung SM-A505W",
    "label": "samsung SM-A505W",
    "value": "samsung SM-A505W"
  },
  {
    "name": "BLU G9",
    "label": "BLU G9",
    "value": "BLU G9"
  },
  {
    "name": "Chinoe P13 Blue Max 128 GB",
    "label": "Chinoe P13 Blue Max 128 GB",
    "value": "Chinoe P13 Blue Max 128 GB"
  },
  {
    "name": "Cherry_Mobile Flare J3s",
    "label": "Cherry_Mobile Flare J3s",
    "value": "Cherry_Mobile Flare J3s"
  },
  {
    "name": "ADVANCE HL9000",
    "label": "ADVANCE HL9000",
    "value": "ADVANCE HL9000"
  },
  {
    "name": "HMD Global Nokia T20",
    "label": "HMD Global Nokia T20",
    "value": "HMD Global Nokia T20"
  },
  {
    "name": "Lava Lava Be_U",
    "label": "Lava Lava Be_U",
    "value": "Lava Lava Be_U"
  },
  {
    "name": "TCL 5028Y_EEA",
    "label": "TCL 5028Y_EEA",
    "value": "TCL 5028Y_EEA"
  },
  {
    "name": "INONE Lenovo L18081",
    "label": "INONE Lenovo L18081",
    "value": "INONE Lenovo L18081"
  },
  {
    "name": "Mobicel V2 LTE",
    "label": "Mobicel V2 LTE",
    "value": "Mobicel V2 LTE"
  },
  {
    "name": "bnd SMART_W10X_EEA",
    "label": "bnd SMART_W10X_EEA",
    "value": "bnd SMART_W10X_EEA"
  },
  {
    "name": "LAVA LE9920_P",
    "label": "LAVA LE9920_P",
    "value": "LAVA LE9920_P"
  },
  {
    "name": "samsung SM-P585Y",
    "label": "samsung SM-P585Y",
    "value": "samsung SM-P585Y"
  },
  {
    "name": "TCL 5059Z",
    "label": "TCL 5059Z",
    "value": "TCL 5059Z"
  },
  {
    "name": "samsung SM-A605K",
    "label": "samsung SM-A605K",
    "value": "samsung SM-A605K"
  },
  {
    "name": "Coolpad Coolpad 3632A",
    "label": "Coolpad Coolpad 3632A",
    "value": "Coolpad Coolpad 3632A"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X683B",
    "label": "INFINIX MOBILITY LIMITED Infinix X683B",
    "value": "INFINIX MOBILITY LIMITED Infinix X683B"
  },
  {
    "name": "DOOGEE S35",
    "label": "DOOGEE S35",
    "value": "DOOGEE S35"
  },
  {
    "name": "TCL 5048U",
    "label": "TCL 5048U",
    "value": "TCL 5048U"
  },
  {
    "name": "Sony I3213",
    "label": "Sony I3213",
    "value": "Sony I3213"
  },
  {
    "name": "Siragon SP-5450",
    "label": "Siragon SP-5450",
    "value": "Siragon SP-5450"
  },
  {
    "name": "Xiaomi MI CC 9e",
    "label": "Xiaomi MI CC 9e",
    "value": "Xiaomi MI CC 9e"
  },
  {
    "name": "XTRATECH COMPUTERS S.A X10MT87",
    "label": "XTRATECH COMPUTERS S.A X10MT87",
    "value": "XTRATECH COMPUTERS S.A X10MT87"
  },
  {
    "name": "HUAWEI VTR",
    "label": "HUAWEI VTR",
    "value": "HUAWEI VTR"
  },
  {
    "name": "samsung SM-A6060",
    "label": "samsung SM-A6060",
    "value": "samsung SM-A6060"
  },
  {
    "name": "lava Lava Z61P",
    "label": "lava Lava Z61P",
    "value": "lava Lava Z61P"
  },
  {
    "name": "Xiaomi 22041219C",
    "label": "Xiaomi 22041219C",
    "value": "Xiaomi 22041219C"
  },
  {
    "name": "realme RMX2051",
    "label": "realme RMX2051",
    "value": "realme RMX2051"
  },
  {
    "name": "iPad de Anna iPad",
    "label": "iPad de Anna iPad",
    "value": "iPad de Anna iPad"
  },
  {
    "name": "Premio 186HS2080531",
    "label": "Premio 186HS2080531",
    "value": "Premio 186HS2080531"
  },
  {
    "name": "OUKITEL WP5 Pro",
    "label": "OUKITEL WP5 Pro",
    "value": "OUKITEL WP5 Pro"
  },
  {
    "name": "DIXON S90",
    "label": "DIXON S90",
    "value": "DIXON S90"
  },
  {
    "name": "ANT DAO TECHNOLOGY LIMITED B2021",
    "label": "ANT DAO TECHNOLOGY LIMITED B2021",
    "value": "ANT DAO TECHNOLOGY LIMITED B2021"
  },
  {
    "name": "Apple iPad13,17",
    "label": "Apple iPad13,17",
    "value": "Apple iPad13,17"
  },
  {
    "name": "OMIX X600",
    "label": "OMIX X600",
    "value": "OMIX X600"
  },
  {
    "name": "LENOVO Lenovo TB-X606FA",
    "label": "LENOVO Lenovo TB-X606FA",
    "value": "LENOVO Lenovo TB-X606FA"
  },
  {
    "name": "Apple María Pg👑",
    "label": "Apple María Pg👑",
    "value": "Apple María Pg👑"
  },
  {
    "name": "motorola XT1710-02",
    "label": "motorola XT1710-02",
    "value": "motorola XT1710-02"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO LE7n",
    "label": "TECNO MOBILE LIMITED TECNO LE7n",
    "value": "TECNO MOBILE LIMITED TECNO LE7n"
  },
  {
    "name": "LGE LG-H932",
    "label": "LGE LG-H932",
    "value": "LGE LG-H932"
  },
  {
    "name": "itel itel A32F",
    "label": "itel itel A32F",
    "value": "itel itel A32F"
  },
  {
    "name": "samsung SM-G885S",
    "label": "samsung SM-G885S",
    "value": "samsung SM-G885S"
  },
  {
    "name": "HXY A26",
    "label": "HXY A26",
    "value": "HXY A26"
  },
  {
    "name": "Hyundai Hyundai_Eternity_G60",
    "label": "Hyundai Hyundai_Eternity_G60",
    "value": "Hyundai Hyundai_Eternity_G60"
  },
  {
    "name": "Todos Tab64",
    "label": "Todos Tab64",
    "value": "Todos Tab64"
  },
  {
    "name": "onn 100043279",
    "label": "onn 100043279",
    "value": "onn 100043279"
  },
  {
    "name": "TCL T781SPP",
    "label": "TCL T781SPP",
    "value": "TCL T781SPP"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A611W",
    "label": "ITEL MOBILE LIMITED itel A611W",
    "value": "ITEL MOBILE LIMITED itel A611W"
  },
  {
    "name": "samsung SM-J600GF",
    "label": "samsung SM-J600GF",
    "value": "samsung SM-J600GF"
  },
  {
    "name": "Multilaser M8_WIFI",
    "label": "Multilaser M8_WIFI",
    "value": "Multilaser M8_WIFI"
  },
  {
    "name": "WIKO View2 Go",
    "label": "WIKO View2 Go",
    "value": "WIKO View2 Go"
  },
  {
    "name": "Coolpad CP3667AT",
    "label": "Coolpad CP3667AT",
    "value": "Coolpad CP3667AT"
  },
  {
    "name": "Bmobile BL50P_TG06",
    "label": "Bmobile BL50P_TG06",
    "value": "Bmobile BL50P_TG06"
  },
  {
    "name": "A-gold BV9500Plus",
    "label": "A-gold BV9500Plus",
    "value": "A-gold BV9500Plus"
  },
  {
    "name": "LGE LM-Q925K",
    "label": "LGE LM-Q925K",
    "value": "LGE LM-Q925K"
  },
  {
    "name": "samsung SM-G715FN",
    "label": "samsung SM-G715FN",
    "value": "samsung SM-G715FN"
  },
  {
    "name": "QUALCOMM Soundphone S2",
    "label": "QUALCOMM Soundphone S2",
    "value": "QUALCOMM Soundphone S2"
  },
  {
    "name": "Xiaomi ASUS_X00QD",
    "label": "Xiaomi ASUS_X00QD",
    "value": "Xiaomi ASUS_X00QD"
  },
  {
    "name": "OPPO CPH1613",
    "label": "OPPO CPH1613",
    "value": "OPPO CPH1613"
  },
  {
    "name": "BLU Studio Mega",
    "label": "BLU Studio Mega",
    "value": "BLU Studio Mega"
  },
  {
    "name": "Casper VIA_E3",
    "label": "Casper VIA_E3",
    "value": "Casper VIA_E3"
  },
  {
    "name": "BLU G71L",
    "label": "BLU G71L",
    "value": "BLU G71L"
  },
  {
    "name": "TINNO U705AA",
    "label": "TINNO U705AA",
    "value": "TINNO U705AA"
  },
  {
    "name": "vivo V2148A",
    "label": "vivo V2148A",
    "value": "vivo V2148A"
  },
  {
    "name": "SSmooth Smooth 6.26 Lite",
    "label": "SSmooth Smooth 6.26 Lite",
    "value": "SSmooth Smooth 6.26 Lite"
  },
  {
    "name": "OPPO CPH1881",
    "label": "OPPO CPH1881",
    "value": "OPPO CPH1881"
  },
  {
    "name": "samsung SM-G970W",
    "label": "samsung SM-G970W",
    "value": "samsung SM-G970W"
  },
  {
    "name": "samsung SM-J737VPP",
    "label": "samsung SM-J737VPP",
    "value": "samsung SM-J737VPP"
  },
  {
    "name": "HUAWEI INE-AL00",
    "label": "HUAWEI INE-AL00",
    "value": "HUAWEI INE-AL00"
  },
  {
    "name": "Apple suho의 iPhone",
    "label": "Apple suho의 iPhone",
    "value": "Apple suho의 iPhone"
  },
  {
    "name": "iPhone13,3 iPhone",
    "label": "iPhone13,3 iPhone",
    "value": "iPhone13,3 iPhone"
  },
  {
    "name": "samsung SM-P585M",
    "label": "samsung SM-P585M",
    "value": "samsung SM-P585M"
  },
  {
    "name": "TECNO TECNO L6502S",
    "label": "TECNO TECNO L6502S",
    "value": "TECNO TECNO L6502S"
  },
  {
    "name": "OnePlus CPH2413",
    "label": "OnePlus CPH2413",
    "value": "OnePlus CPH2413"
  },
  {
    "name": "KYOCERA KYV42",
    "label": "KYOCERA KYV42",
    "value": "KYOCERA KYV42"
  },
  {
    "name": "Sky Devices Elite T8Plus",
    "label": "Sky Devices Elite T8Plus",
    "value": "Sky Devices Elite T8Plus"
  },
  {
    "name": "samsung SM-N9700",
    "label": "samsung SM-N9700",
    "value": "samsung SM-N9700"
  },
  {
    "name": "TINNO U705AC",
    "label": "TINNO U705AC",
    "value": "TINNO U705AC"
  },
  {
    "name": "ZUUM AURA_Z",
    "label": "ZUUM AURA_Z",
    "value": "ZUUM AURA_Z"
  },
  {
    "name": "Xiaomi SM-G975N",
    "label": "Xiaomi SM-G975N",
    "value": "Xiaomi SM-G975N"
  },
  {
    "name": "TCL Orange Rise 54",
    "label": "TCL Orange Rise 54",
    "value": "TCL Orange Rise 54"
  },
  {
    "name": "HUAWEI LON-L29",
    "label": "HUAWEI LON-L29",
    "value": "HUAWEI LON-L29"
  },
  {
    "name": "revoview Orange Sanza touch",
    "label": "revoview Orange Sanza touch",
    "value": "revoview Orange Sanza touch"
  },
  {
    "name": "TECNO TECNO LG7n",
    "label": "TECNO TECNO LG7n",
    "value": "TECNO TECNO LG7n"
  },
  {
    "name": "LENOVO Lenovo TB-J616X",
    "label": "LENOVO Lenovo TB-J616X",
    "value": "LENOVO Lenovo TB-J616X"
  },
  {
    "name": "samsung SM-A135U1",
    "label": "samsung SM-A135U1",
    "value": "samsung SM-A135U1"
  },
  {
    "name": "benco AH9910",
    "label": "benco AH9910",
    "value": "benco AH9910"
  },
  {
    "name": "dingqin M8422",
    "label": "dingqin M8422",
    "value": "dingqin M8422"
  },
  {
    "name": "Free Free_Liberty_Plus",
    "label": "Free Free_Liberty_Plus",
    "value": "Free Free_Liberty_Plus"
  },
  {
    "name": "TCL 5059X",
    "label": "TCL 5059X",
    "value": "TCL 5059X"
  },
  {
    "name": "Google TV BOX",
    "label": "Google TV BOX",
    "value": "Google TV BOX"
  },
  {
    "name": "Blackview BV9600Pro",
    "label": "Blackview BV9600Pro",
    "value": "Blackview BV9600Pro"
  },
  {
    "name": "samsung SM-J337U",
    "label": "samsung SM-J337U",
    "value": "samsung SM-J337U"
  },
  {
    "name": "TCL 5059A",
    "label": "TCL 5059A",
    "value": "TCL 5059A"
  },
  {
    "name": "WELCOME Phone12 Pro Max",
    "label": "WELCOME Phone12 Pro Max",
    "value": "WELCOME Phone12 Pro Max"
  },
  {
    "name": "CORN CORN X4",
    "label": "CORN CORN X4",
    "value": "CORN CORN X4"
  },
  {
    "name": "Sony I3113",
    "label": "Sony I3113",
    "value": "Sony I3113"
  },
  {
    "name": "WIKO W-V755-EEA",
    "label": "WIKO W-V755-EEA",
    "value": "WIKO W-V755-EEA"
  },
  {
    "name": "Apple Clara Myoung’s iPhone",
    "label": "Apple Clara Myoung’s iPhone",
    "value": "Apple Clara Myoung’s iPhone"
  },
  {
    "name": "- iPad",
    "label": "- iPad",
    "value": "- iPad"
  },
  {
    "name": "iPhone9,2 iPhone",
    "label": "iPhone9,2 iPhone",
    "value": "iPhone9,2 iPhone"
  },
  {
    "name": "iPhone12,3 iPhone",
    "label": "iPhone12,3 iPhone",
    "value": "iPhone12,3 iPhone"
  },
  {
    "name": "Apple hyo young의 iPhone",
    "label": "Apple hyo young의 iPhone",
    "value": "Apple hyo young의 iPhone"
  },
  {
    "name": "iPhone10,3 iPhone",
    "label": "iPhone10,3 iPhone",
    "value": "iPhone10,3 iPhone"
  },
  {
    "name": "TCL 5031G",
    "label": "TCL 5031G",
    "value": "TCL 5031G"
  },
  {
    "name": "TCL 8092_TR",
    "label": "TCL 8092_TR",
    "value": "TCL 8092_TR"
  },
  {
    "name": "vivo V1813A",
    "label": "vivo V1813A",
    "value": "vivo V1813A"
  },
  {
    "name": "KXD 6A",
    "label": "KXD 6A",
    "value": "KXD 6A"
  },
  {
    "name": "CMDC M850",
    "label": "CMDC M850",
    "value": "CMDC M850"
  },
  {
    "name": "iHunt Like_Hi10_2021",
    "label": "iHunt Like_Hi10_2021",
    "value": "iHunt Like_Hi10_2021"
  },
  {
    "name": "BQru BQ-5517L",
    "label": "BQru BQ-5517L",
    "value": "BQru BQ-5517L"
  },
  {
    "name": "OnePlus IN2020",
    "label": "OnePlus IN2020",
    "value": "OnePlus IN2020"
  },
  {
    "name": "Teclast P20HD_RUS",
    "label": "Teclast P20HD_RUS",
    "value": "Teclast P20HD_RUS"
  },
  {
    "name": "GIONEE GIONEE GN5008",
    "label": "GIONEE GIONEE GN5008",
    "value": "GIONEE GIONEE GN5008"
  },
  {
    "name": "TCL 9317G",
    "label": "TCL 9317G",
    "value": "TCL 9317G"
  },
  {
    "name": "Meizu M6 Note",
    "label": "Meizu M6 Note",
    "value": "Meizu M6 Note"
  },
  {
    "name": "TCL 5006G",
    "label": "TCL 5006G",
    "value": "TCL 5006G"
  },
  {
    "name": "vivo VIVO Y17",
    "label": "vivo VIVO Y17",
    "value": "vivo VIVO Y17"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KC3",
    "label": "TECNO MOBILE LIMITED TECNO KC3",
    "value": "TECNO MOBILE LIMITED TECNO KC3"
  },
  {
    "name": "Google Pixel 5a",
    "label": "Google Pixel 5a",
    "value": "Google Pixel 5a"
  },
  {
    "name": "ZTE ZTE Blade A7 2019",
    "label": "ZTE ZTE Blade A7 2019",
    "value": "ZTE ZTE Blade A7 2019"
  },
  {
    "name": "SAMSUNG SM-G935",
    "label": "SAMSUNG SM-G935",
    "value": "SAMSUNG SM-G935"
  },
  {
    "name": "Hisense Hisense U30",
    "label": "Hisense Hisense U30",
    "value": "Hisense Hisense U30"
  },
  {
    "name": "samsung SM-T387V",
    "label": "samsung SM-T387V",
    "value": "samsung SM-T387V"
  },
  {
    "name": "samsung SM-M107F",
    "label": "samsung SM-M107F",
    "value": "samsung SM-M107F"
  },
  {
    "name": "Sony SO-03J",
    "label": "Sony SO-03J",
    "value": "Sony SO-03J"
  },
  {
    "name": "BullittGroupLimited S41",
    "label": "BullittGroupLimited S41",
    "value": "BullittGroupLimited S41"
  },
  {
    "name": "DEXP A455",
    "label": "DEXP A455",
    "value": "DEXP A455"
  },
  {
    "name": "samsung SM-G9900",
    "label": "samsung SM-G9900",
    "value": "samsung SM-G9900"
  },
  {
    "name": "HUAWEI ARE-AL00",
    "label": "HUAWEI ARE-AL00",
    "value": "HUAWEI ARE-AL00"
  },
  {
    "name": "Fortune Ship M8416",
    "label": "Fortune Ship M8416",
    "value": "Fortune Ship M8416"
  },
  {
    "name": "Apple Ishan’s iPhone",
    "label": "Apple Ishan’s iPhone",
    "value": "Apple Ishan’s iPhone"
  },
  {
    "name": "UMAX 10C_LTE",
    "label": "UMAX 10C_LTE",
    "value": "UMAX 10C_LTE"
  },
  {
    "name": "iPhone10,1 iPhone",
    "label": "iPhone10,1 iPhone",
    "value": "iPhone10,1 iPhone"
  },
  {
    "name": "Apple Sarie iPhone",
    "label": "Apple Sarie iPhone",
    "value": "Apple Sarie iPhone"
  },
  {
    "name": "Apple 현",
    "label": "Apple 현",
    "value": "Apple 현"
  },
  {
    "name": "iPhone14,5 iPhone",
    "label": "iPhone14,5 iPhone",
    "value": "iPhone14,5 iPhone"
  },
  {
    "name": "s24 s24",
    "label": "s24 s24",
    "value": "s24 s24"
  },
  {
    "name": "SEMP GO3C Plus",
    "label": "SEMP GO3C Plus",
    "value": "SEMP GO3C Plus"
  },
  {
    "name": "OUKITEL WP5",
    "label": "OUKITEL WP5",
    "value": "OUKITEL WP5"
  },
  {
    "name": "realme RMX3571",
    "label": "realme RMX3571",
    "value": "realme RMX3571"
  },
  {
    "name": "Blackview Tab9",
    "label": "Blackview Tab9",
    "value": "Blackview Tab9"
  },
  {
    "name": "Xiaomi M2103K19PY",
    "label": "Xiaomi M2103K19PY",
    "value": "Xiaomi M2103K19PY"
  },
  {
    "name": "Apple iPad6,7",
    "label": "Apple iPad6,7",
    "value": "Apple iPad6,7"
  },
  {
    "name": "Multilaser M10_4G",
    "label": "Multilaser M10_4G",
    "value": "Multilaser M10_4G"
  },
  {
    "name": "ZTE ZTE A2121L",
    "label": "ZTE ZTE A2121L",
    "value": "ZTE ZTE A2121L"
  },
  {
    "name": "asus ZE520KL",
    "label": "asus ZE520KL",
    "value": "asus ZE520KL"
  },
  {
    "name": "Google sdk_goog3_x86_64",
    "label": "Google sdk_goog3_x86_64",
    "value": "Google sdk_goog3_x86_64"
  },
  {
    "name": "HUAWEI SHT-W09",
    "label": "HUAWEI SHT-W09",
    "value": "HUAWEI SHT-W09"
  },
  {
    "name": "WIKO W-K211-TVM",
    "label": "WIKO W-K211-TVM",
    "value": "WIKO W-K211-TVM"
  },
  {
    "name": "vivo vivo Y71",
    "label": "vivo vivo Y71",
    "value": "vivo vivo Y71"
  },
  {
    "name": "Hisense Hisense Infinity H50S 5G",
    "label": "Hisense Hisense Infinity H50S 5G",
    "value": "Hisense Hisense Infinity H50S 5G"
  },
  {
    "name": "samsung SC-02J",
    "label": "samsung SC-02J",
    "value": "samsung SC-02J"
  },
  {
    "name": "samsung SM-J337AZ",
    "label": "samsung SM-J337AZ",
    "value": "samsung SM-J337AZ"
  },
  {
    "name": "ZTE ZTE Blade V1000",
    "label": "ZTE ZTE Blade V1000",
    "value": "ZTE ZTE Blade V1000"
  },
  {
    "name": "samsung SCG08",
    "label": "samsung SCG08",
    "value": "samsung SCG08"
  },
  {
    "name": "CUBOT CUBOT_X18_Plus",
    "label": "CUBOT CUBOT_X18_Plus",
    "value": "CUBOT CUBOT_X18_Plus"
  },
  {
    "name": "TCL 9295G_RU",
    "label": "TCL 9295G_RU",
    "value": "TCL 9295G_RU"
  },
  {
    "name": "Sky_Devices SKY Black2",
    "label": "Sky_Devices SKY Black2",
    "value": "Sky_Devices SKY Black2"
  },
  {
    "name": "HUAWEI PAR-AL00",
    "label": "HUAWEI PAR-AL00",
    "value": "HUAWEI PAR-AL00"
  },
  {
    "name": "Sony I4332",
    "label": "Sony I4332",
    "value": "Sony I4332"
  },
  {
    "name": "Xiaomi IN2023",
    "label": "Xiaomi IN2023",
    "value": "Xiaomi IN2023"
  },
  {
    "name": "iPhone11,6 iPhone",
    "label": "iPhone11,6 iPhone",
    "value": "iPhone11,6 iPhone"
  },
  {
    "name": "iPhone8,2 iPhone",
    "label": "iPhone8,2 iPhone",
    "value": "iPhone8,2 iPhone"
  },
  {
    "name": "iPad7,5 iPad",
    "label": "iPad7,5 iPad",
    "value": "iPad7,5 iPad"
  },
  {
    "name": "iPad5,3 iPad",
    "label": "iPad5,3 iPad",
    "value": "iPad5,3 iPad"
  },
  {
    "name": "Apple Nthabee",
    "label": "Apple Nthabee",
    "value": "Apple Nthabee"
  },
  {
    "name": "asus ASUS_X00TDA",
    "label": "asus ASUS_X00TDA",
    "value": "asus ASUS_X00TDA"
  },
  {
    "name": "ZTE P609",
    "label": "ZTE P609",
    "value": "ZTE P609"
  },
  {
    "name": "Apple 김민주의 iPhone",
    "label": "Apple 김민주의 iPhone",
    "value": "Apple 김민주의 iPhone"
  },
  {
    "name": "samsung SM-A920N",
    "label": "samsung SM-A920N",
    "value": "samsung SM-A920N"
  },
  {
    "name": "reeder P13_Blue_Max",
    "label": "reeder P13_Blue_Max",
    "value": "reeder P13_Blue_Max"
  },
  {
    "name": "Lenovo Lenovo L70081",
    "label": "Lenovo Lenovo L70081",
    "value": "Lenovo Lenovo L70081"
  },
  {
    "name": "X-TIGI A55",
    "label": "X-TIGI A55",
    "value": "X-TIGI A55"
  },
  {
    "name": "General Mobile GM 5 Plus d",
    "label": "General Mobile GM 5 Plus d",
    "value": "General Mobile GM 5 Plus d"
  },
  {
    "name": "TCL ALTICE_S43",
    "label": "TCL ALTICE_S43",
    "value": "TCL ALTICE_S43"
  },
  {
    "name": "TCL HEYOU40",
    "label": "TCL HEYOU40",
    "value": "TCL HEYOU40"
  },
  {
    "name": "KRONO NET_VOLT",
    "label": "KRONO NET_VOLT",
    "value": "KRONO NET_VOLT"
  },
  {
    "name": "samsung SM-A015AZ",
    "label": "samsung SM-A015AZ",
    "value": "samsung SM-A015AZ"
  },
  {
    "name": "OSCAL C80",
    "label": "OSCAL C80",
    "value": "OSCAL C80"
  },
  {
    "name": "OPPO CPH1877",
    "label": "OPPO CPH1877",
    "value": "OPPO CPH1877"
  },
  {
    "name": "LUNA LUNA G6E",
    "label": "LUNA LUNA G6E",
    "value": "LUNA LUNA G6E"
  },
  {
    "name": "ZTE ZTE 8000",
    "label": "ZTE ZTE 8000",
    "value": "ZTE ZTE 8000"
  },
  {
    "name": "HYUNDAI E475",
    "label": "HYUNDAI E475",
    "value": "HYUNDAI E475"
  },
  {
    "name": "motorola motorola edge 30 ultra",
    "label": "motorola motorola edge 30 ultra",
    "value": "motorola motorola edge 30 ultra"
  },
  {
    "name": "samsung SM-A336N",
    "label": "samsung SM-A336N",
    "value": "samsung SM-A336N"
  },
  {
    "name": "asus ASUS_Z017DC",
    "label": "asus ASUS_Z017DC",
    "value": "asus ASUS_Z017DC"
  },
  {
    "name": "ITEL MOBILE LIMITED Itel W7001",
    "label": "ITEL MOBILE LIMITED Itel W7001",
    "value": "ITEL MOBILE LIMITED Itel W7001"
  },
  {
    "name": "SILVER_MAX ST-920",
    "label": "SILVER_MAX ST-920",
    "value": "SILVER_MAX ST-920"
  },
  {
    "name": "Vodafone VFD 710",
    "label": "Vodafone VFD 710",
    "value": "Vodafone VFD 710"
  },
  {
    "name": "HUAWEI TAS-AN00",
    "label": "HUAWEI TAS-AN00",
    "value": "HUAWEI TAS-AN00"
  },
  {
    "name": "Droi MAX 1X",
    "label": "Droi MAX 1X",
    "value": "Droi MAX 1X"
  },
  {
    "name": "samsung SM-F711U",
    "label": "samsung SM-F711U",
    "value": "samsung SM-F711U"
  },
  {
    "name": "Xiaomi M2102K1G",
    "label": "Xiaomi M2102K1G",
    "value": "Xiaomi M2102K1G"
  },
  {
    "name": "incar Tab-1",
    "label": "incar Tab-1",
    "value": "incar Tab-1"
  },
  {
    "name": "motorola motorola one 5G UW",
    "label": "motorola motorola one 5G UW",
    "value": "motorola motorola one 5G UW"
  },
  {
    "name": "Hisense Hisense E20",
    "label": "Hisense Hisense E20",
    "value": "Hisense Hisense E20"
  },
  {
    "name": "samsung SM-S115DL",
    "label": "samsung SM-S115DL",
    "value": "samsung SM-S115DL"
  },
  {
    "name": "Sony H3223",
    "label": "Sony H3223",
    "value": "Sony H3223"
  },
  {
    "name": "TCL 5130I",
    "label": "TCL 5130I",
    "value": "TCL 5130I"
  },
  {
    "name": "OUKITEL C16_Pro",
    "label": "OUKITEL C16_Pro",
    "value": "OUKITEL C16_Pro"
  },
  {
    "name": "Alcatel Alcatel_5005R",
    "label": "Alcatel Alcatel_5005R",
    "value": "Alcatel Alcatel_5005R"
  },
  {
    "name": "Sony H4113",
    "label": "Sony H4113",
    "value": "Sony H4113"
  },
  {
    "name": "realme RMX2076",
    "label": "realme RMX2076",
    "value": "realme RMX2076"
  },
  {
    "name": "Google Dell Chromebook 11 (3180)",
    "label": "Google Dell Chromebook 11 (3180)",
    "value": "Google Dell Chromebook 11 (3180)"
  },
  {
    "name": "CUBOT X50",
    "label": "CUBOT X50",
    "value": "CUBOT X50"
  },
  {
    "name": "iPhone10,4 iPhone",
    "label": "iPhone10,4 iPhone",
    "value": "iPhone10,4 iPhone"
  },
  {
    "name": "incar iT-KSA0012",
    "label": "incar iT-KSA0012",
    "value": "incar iT-KSA0012"
  },
  {
    "name": "Apple Kopano’s iPhone",
    "label": "Apple Kopano’s iPhone",
    "value": "Apple Kopano’s iPhone"
  },
  {
    "name": "Vestel Venus Z20",
    "label": "Vestel Venus Z20",
    "value": "Vestel Venus Z20"
  },
  {
    "name": "BQru BQ-5533G",
    "label": "BQru BQ-5533G",
    "value": "BQru BQ-5533G"
  },
  {
    "name": "Symphony Z28",
    "label": "Symphony Z28",
    "value": "Symphony Z28"
  },
  {
    "name": "QMobile HD Plus",
    "label": "QMobile HD Plus",
    "value": "QMobile HD Plus"
  },
  {
    "name": "bq Aquaris X2",
    "label": "bq Aquaris X2",
    "value": "bq Aquaris X2"
  },
  {
    "name": "Apple Lehuma’s iPhone 12 Pro Max",
    "label": "Apple Lehuma’s iPhone 12 Pro Max",
    "value": "Apple Lehuma’s iPhone 12 Pro Max"
  },
  {
    "name": "Apple Bao’s iphone",
    "label": "Apple Bao’s iphone",
    "value": "Apple Bao’s iphone"
  },
  {
    "name": "Apple iPhone de Enya gabrielle",
    "label": "Apple iPhone de Enya gabrielle",
    "value": "Apple iPhone de Enya gabrielle"
  },
  {
    "name": "Apple Thato lamola’s iPhone",
    "label": "Apple Thato lamola’s iPhone",
    "value": "Apple Thato lamola’s iPhone"
  },
  {
    "name": "OPPO OPPO A73",
    "label": "OPPO OPPO A73",
    "value": "OPPO OPPO A73"
  },
  {
    "name": "iBRIT TIS001-S3",
    "label": "iBRIT TIS001-S3",
    "value": "iBRIT TIS001-S3"
  },
  {
    "name": "bnd SK3401",
    "label": "bnd SK3401",
    "value": "bnd SK3401"
  },
  {
    "name": "samsung SCV43",
    "label": "samsung SCV43",
    "value": "samsung SCV43"
  },
  {
    "name": "TINNO U668AA",
    "label": "TINNO U668AA",
    "value": "TINNO U668AA"
  },
  {
    "name": "TINNO U319AA",
    "label": "TINNO U319AA",
    "value": "TINNO U319AA"
  },
  {
    "name": "motorola moto g go",
    "label": "motorola moto g go",
    "value": "motorola moto g go"
  },
  {
    "name": "Lanix X550",
    "label": "Lanix X550",
    "value": "Lanix X550"
  },
  {
    "name": "OPPO RMX1803",
    "label": "OPPO RMX1803",
    "value": "OPPO RMX1803"
  },
  {
    "name": "vivo V1965A",
    "label": "vivo V1965A",
    "value": "vivo V1965A"
  },
  {
    "name": "Xiaomi 2201116PI",
    "label": "Xiaomi 2201116PI",
    "value": "Xiaomi 2201116PI"
  },
  {
    "name": "SAMSUNG SM-G930FD",
    "label": "SAMSUNG SM-G930FD",
    "value": "SAMSUNG SM-G930FD"
  },
  {
    "name": "Sony SO-02K",
    "label": "Sony SO-02K",
    "value": "Sony SO-02K"
  },
  {
    "name": "samsung SM-J727U",
    "label": "samsung SM-J727U",
    "value": "samsung SM-J727U"
  },
  {
    "name": "HTC HTC Desire 20+",
    "label": "HTC HTC Desire 20+",
    "value": "HTC HTC Desire 20+"
  },
  {
    "name": "BLU G90 PRO",
    "label": "BLU G90 PRO",
    "value": "BLU G90 PRO"
  },
  {
    "name": "INVOVO I03",
    "label": "INVOVO I03",
    "value": "INVOVO I03"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CD8",
    "label": "TECNO MOBILE LIMITED TECNO CD8",
    "value": "TECNO MOBILE LIMITED TECNO CD8"
  },
  {
    "name": "FUJITSU F-04K",
    "label": "FUJITSU F-04K",
    "value": "FUJITSU F-04K"
  },
  {
    "name": "Alcatel Alcatel_5059R",
    "label": "Alcatel Alcatel_5059R",
    "value": "Alcatel Alcatel_5059R"
  },
  {
    "name": "Acer A60",
    "label": "Acer A60",
    "value": "Acer A60"
  },
  {
    "name": "UMIDIGI A3_Pro",
    "label": "UMIDIGI A3_Pro",
    "value": "UMIDIGI A3_Pro"
  },
  {
    "name": "Coolpad Coolpad_2041_U0",
    "label": "Coolpad Coolpad_2041_U0",
    "value": "Coolpad Coolpad_2041_U0"
  },
  {
    "name": "iPhone13,1 iPhone",
    "label": "iPhone13,1 iPhone",
    "value": "iPhone13,1 iPhone"
  },
  {
    "name": "LENOVO Lenovo K8",
    "label": "LENOVO Lenovo K8",
    "value": "LENOVO Lenovo K8"
  },
  {
    "name": "iPhone10,2 iPhone",
    "label": "iPhone10,2 iPhone",
    "value": "iPhone10,2 iPhone"
  },
  {
    "name": "Sigma_mobile X_style_Tab_A1010",
    "label": "Sigma_mobile X_style_Tab_A1010",
    "value": "Sigma_mobile X_style_Tab_A1010"
  },
  {
    "name": "ALLVIEW X5_Soul",
    "label": "ALLVIEW X5_Soul",
    "value": "ALLVIEW X5_Soul"
  },
  {
    "name": "IFRE S.A. KODAK SMARTWAY M2",
    "label": "IFRE S.A. KODAK SMARTWAY M2",
    "value": "IFRE S.A. KODAK SMARTWAY M2"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CF7S",
    "label": "TECNO MOBILE LIMITED TECNO CF7S",
    "value": "TECNO MOBILE LIMITED TECNO CF7S"
  },
  {
    "name": "LAVA iris42",
    "label": "LAVA iris42",
    "value": "LAVA iris42"
  },
  {
    "name": "incar V-E5",
    "label": "incar V-E5",
    "value": "incar V-E5"
  },
  {
    "name": "HUAWEI AGS-L03",
    "label": "HUAWEI AGS-L03",
    "value": "HUAWEI AGS-L03"
  },
  {
    "name": "WOLKI W665",
    "label": "WOLKI W665",
    "value": "WOLKI W665"
  },
  {
    "name": "Azumi A4",
    "label": "Azumi A4",
    "value": "Azumi A4"
  },
  {
    "name": "deejoy Tab_X2",
    "label": "deejoy Tab_X2",
    "value": "deejoy Tab_X2"
  },
  {
    "name": "UMIDIGI A3",
    "label": "UMIDIGI A3",
    "value": "UMIDIGI A3"
  },
  {
    "name": "LAVA LH9810",
    "label": "LAVA LH9810",
    "value": "LAVA LH9810"
  },
  {
    "name": "Xiaomi M2004J7BC",
    "label": "Xiaomi M2004J7BC",
    "value": "Xiaomi M2004J7BC"
  },
  {
    "name": "Apple Tshimo’s iPhone",
    "label": "Apple Tshimo’s iPhone",
    "value": "Apple Tshimo’s iPhone"
  },
  {
    "name": "Apple Sandra ’s iPhone",
    "label": "Apple Sandra ’s iPhone",
    "value": "Apple Sandra ’s iPhone"
  },
  {
    "name": "Apple Dorcas",
    "label": "Apple Dorcas",
    "value": "Apple Dorcas"
  },
  {
    "name": "GIONEE GIONEE S10C",
    "label": "GIONEE GIONEE S10C",
    "value": "GIONEE GIONEE S10C"
  },
  {
    "name": "HAFURY K30 PRO",
    "label": "HAFURY K30 PRO",
    "value": "HAFURY K30 PRO"
  },
  {
    "name": "samsung SAMSUNG-SM-J727A",
    "label": "samsung SAMSUNG-SM-J727A",
    "value": "samsung SAMSUNG-SM-J727A"
  },
  {
    "name": "samsung SM-F707U1",
    "label": "samsung SM-F707U1",
    "value": "samsung SM-F707U1"
  },
  {
    "name": "samsung SM-G9960",
    "label": "samsung SM-G9960",
    "value": "samsung SM-G9960"
  },
  {
    "name": "samsung SM-F7210",
    "label": "samsung SM-F7210",
    "value": "samsung SM-F7210"
  },
  {
    "name": "TECNO TECNO KH7n",
    "label": "TECNO TECNO KH7n",
    "value": "TECNO TECNO KH7n"
  },
  {
    "name": "samsung SM-C7100",
    "label": "samsung SM-C7100",
    "value": "samsung SM-C7100"
  },
  {
    "name": "hena H1010",
    "label": "hena H1010",
    "value": "hena H1010"
  },
  {
    "name": "SHARP 702SH",
    "label": "SHARP 702SH",
    "value": "SHARP 702SH"
  },
  {
    "name": "ZTE Blade A30",
    "label": "ZTE Blade A30",
    "value": "ZTE Blade A30"
  },
  {
    "name": "Desktop - Gentoo",
    "label": "Desktop - Gentoo",
    "value": "Desktop - Gentoo"
  },
  {
    "name": "OPPO CPH2337",
    "label": "OPPO CPH2337",
    "value": "OPPO CPH2337"
  },
  {
    "name": "samsung SM-A215U1",
    "label": "samsung SM-A215U1",
    "value": "samsung SM-A215U1"
  },
  {
    "name": "Sanmu Masstel_X8",
    "label": "Sanmu Masstel_X8",
    "value": "Sanmu Masstel_X8"
  },
  {
    "name": "TITANIC T-2",
    "label": "TITANIC T-2",
    "value": "TITANIC T-2"
  },
  {
    "name": "hena TEL-73GIQA",
    "label": "hena TEL-73GIQA",
    "value": "hena TEL-73GIQA"
  },
  {
    "name": "Apple Emma's iPhone",
    "label": "Apple Emma's iPhone",
    "value": "Apple Emma's iPhone"
  },
  {
    "name": "TCL 5031A",
    "label": "TCL 5031A",
    "value": "TCL 5031A"
  },
  {
    "name": "vivo V1831A",
    "label": "vivo V1831A",
    "value": "vivo V1831A"
  },
  {
    "name": "Hisense Hisense U50 lite",
    "label": "Hisense Hisense U50 lite",
    "value": "Hisense Hisense U50 lite"
  },
  {
    "name": "alps M35",
    "label": "alps M35",
    "value": "alps M35"
  },
  {
    "name": "BQru BQ-8077L",
    "label": "BQru BQ-8077L",
    "value": "BQru BQ-8077L"
  },
  {
    "name": "OPPO CPH1869",
    "label": "OPPO CPH1869",
    "value": "OPPO CPH1869"
  },
  {
    "name": "Treswave TW102",
    "label": "Treswave TW102",
    "value": "Treswave TW102"
  },
  {
    "name": "TP-Link Neffos_C9A",
    "label": "TP-Link Neffos_C9A",
    "value": "TP-Link Neffos_C9A"
  },
  {
    "name": "ALLVIEW H1003_LTE_PRO",
    "label": "ALLVIEW H1003_LTE_PRO",
    "value": "ALLVIEW H1003_LTE_PRO"
  },
  {
    "name": "incar TPC-G1011LTE",
    "label": "incar TPC-G1011LTE",
    "value": "incar TPC-G1011LTE"
  },
  {
    "name": "OPPO OPPO A77t",
    "label": "OPPO OPPO A77t",
    "value": "OPPO OPPO A77t"
  },
  {
    "name": "Acer Acer One 8 T4-82L",
    "label": "Acer Acer One 8 T4-82L",
    "value": "Acer Acer One 8 T4-82L"
  },
  {
    "name": "realme RMX1991",
    "label": "realme RMX1991",
    "value": "realme RMX1991"
  },
  {
    "name": "LGE LM-X415L",
    "label": "LGE LM-X415L",
    "value": "LGE LM-X415L"
  },
  {
    "name": "Apple Uday’s iphone",
    "label": "Apple Uday’s iphone",
    "value": "Apple Uday’s iphone"
  },
  {
    "name": "MITO T8",
    "label": "MITO T8",
    "value": "MITO T8"
  },
  {
    "name": "Fortune Ship M8425",
    "label": "Fortune Ship M8425",
    "value": "Fortune Ship M8425"
  },
  {
    "name": "Yes M685Y4",
    "label": "Yes M685Y4",
    "value": "Yes M685Y4"
  },
  {
    "name": "iPhone9,4 iPhone",
    "label": "iPhone9,4 iPhone",
    "value": "iPhone9,4 iPhone"
  },
  {
    "name": "Apple iPhone de Adriel",
    "label": "Apple iPhone de Adriel",
    "value": "Apple iPhone de Adriel"
  },
  {
    "name": "Apple 나영찌",
    "label": "Apple 나영찌",
    "value": "Apple 나영찌"
  },
  {
    "name": "iPad8,12 iPad",
    "label": "iPad8,12 iPad",
    "value": "iPad8,12 iPad"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CC7S",
    "label": "TECNO MOBILE LIMITED TECNO CC7S",
    "value": "TECNO MOBILE LIMITED TECNO CC7S"
  },
  {
    "name": "General Mobile GM8 go",
    "label": "General Mobile GM8 go",
    "value": "General Mobile GM8 go"
  },
  {
    "name": "samsung SM-X906B",
    "label": "samsung SM-X906B",
    "value": "samsung SM-X906B"
  },
  {
    "name": "vivo V2156A",
    "label": "vivo V2156A",
    "value": "vivo V2156A"
  },
  {
    "name": "reeder P13 Blue 2021",
    "label": "reeder P13 Blue 2021",
    "value": "reeder P13 Blue 2021"
  },
  {
    "name": "DIGMA CITI Octa 10 CS1219PL",
    "label": "DIGMA CITI Octa 10 CS1219PL",
    "value": "DIGMA CITI Octa 10 CS1219PL"
  },
  {
    "name": "Joyar Pixus_Touch_7_3G",
    "label": "Joyar Pixus_Touch_7_3G",
    "value": "Joyar Pixus_Touch_7_3G"
  },
  {
    "name": "LT LT C1400",
    "label": "LT LT C1400",
    "value": "LT LT C1400"
  },
  {
    "name": "OPPO PEQM00",
    "label": "OPPO PEQM00",
    "value": "OPPO PEQM00"
  },
  {
    "name": "SHARP S5-SH",
    "label": "SHARP S5-SH",
    "value": "SHARP S5-SH"
  },
  {
    "name": "asus ASUS_AI2201_F",
    "label": "asus ASUS_AI2201_F",
    "value": "asus ASUS_AI2201_F"
  },
  {
    "name": "BarnesAndNoble NOOK",
    "label": "BarnesAndNoble NOOK",
    "value": "BarnesAndNoble NOOK"
  },
  {
    "name": "INFONE Y05",
    "label": "INFONE Y05",
    "value": "INFONE Y05"
  },
  {
    "name": "Sony XQ-AS52",
    "label": "Sony XQ-AS52",
    "value": "Sony XQ-AS52"
  },
  {
    "name": "Digicel DL4",
    "label": "Digicel DL4",
    "value": "Digicel DL4"
  },
  {
    "name": "ANT DAO TECHNOLOGY LIMITED Air1 Pro",
    "label": "ANT DAO TECHNOLOGY LIMITED Air1 Pro",
    "value": "ANT DAO TECHNOLOGY LIMITED Air1 Pro"
  },
  {
    "name": "TCL 8091",
    "label": "TCL 8091",
    "value": "TCL 8091"
  },
  {
    "name": "CUBOT NOTE 30",
    "label": "CUBOT NOTE 30",
    "value": "CUBOT NOTE 30"
  },
  {
    "name": "DEXP A355",
    "label": "DEXP A355",
    "value": "DEXP A355"
  },
  {
    "name": "General Mobile G501",
    "label": "General Mobile G501",
    "value": "General Mobile G501"
  },
  {
    "name": "TCL 5061U_EEA",
    "label": "TCL 5061U_EEA",
    "value": "TCL 5061U_EEA"
  },
  {
    "name": "Motorola moto tab g70",
    "label": "Motorola moto tab g70",
    "value": "Motorola moto tab g70"
  },
  {
    "name": "alps S51",
    "label": "alps S51",
    "value": "alps S51"
  },
  {
    "name": "Google ASUS Chromebook C202SA",
    "label": "Google ASUS Chromebook C202SA",
    "value": "Google ASUS Chromebook C202SA"
  },
  {
    "name": "samsung SM-G7810",
    "label": "samsung SM-G7810",
    "value": "samsung SM-G7810"
  },
  {
    "name": "SPA Condor Electronics Allure M3 Lite",
    "label": "SPA Condor Electronics Allure M3 Lite",
    "value": "SPA Condor Electronics Allure M3 Lite"
  },
  {
    "name": "FOXXD T55",
    "label": "FOXXD T55",
    "value": "FOXXD T55"
  },
  {
    "name": "Kalley Black_G_2",
    "label": "Kalley Black_G_2",
    "value": "Kalley Black_G_2"
  },
  {
    "name": "LGE LM-K526",
    "label": "LGE LM-K526",
    "value": "LGE LM-K526"
  },
  {
    "name": "revoview S6518",
    "label": "revoview S6518",
    "value": "revoview S6518"
  },
  {
    "name": "LENOVO Lenovo YT-K606F",
    "label": "LENOVO Lenovo YT-K606F",
    "value": "LENOVO Lenovo YT-K606F"
  },
  {
    "name": "Welcome Mate45 Pro+",
    "label": "Welcome Mate45 Pro+",
    "value": "Welcome Mate45 Pro+"
  },
  {
    "name": "iPhone14,3 iPhone",
    "label": "iPhone14,3 iPhone",
    "value": "iPhone14,3 iPhone"
  },
  {
    "name": "Apple iPhone de Ainara Gijon Tejada",
    "label": "Apple iPhone de Ainara Gijon Tejada",
    "value": "Apple iPhone de Ainara Gijon Tejada"
  },
  {
    "name": "iPad8,7 iPad",
    "label": "iPad8,7 iPad",
    "value": "iPad8,7 iPad"
  },
  {
    "name": "Sony G8342",
    "label": "Sony G8342",
    "value": "Sony G8342"
  },
  {
    "name": "STYLO S40_VISTA",
    "label": "STYLO S40_VISTA",
    "value": "STYLO S40_VISTA"
  },
  {
    "name": "G-Touch SPEED_2021",
    "label": "G-Touch SPEED_2021",
    "value": "G-Touch SPEED_2021"
  },
  {
    "name": "OnePlus BE2012",
    "label": "OnePlus BE2012",
    "value": "OnePlus BE2012"
  },
  {
    "name": "wheatek RT1",
    "label": "wheatek RT1",
    "value": "wheatek RT1"
  },
  {
    "name": "Morep M830",
    "label": "Morep M830",
    "value": "Morep M830"
  },
  {
    "name": "HUAWEI WAS-LX2J",
    "label": "HUAWEI WAS-LX2J",
    "value": "HUAWEI WAS-LX2J"
  },
  {
    "name": "samsung SCV43-u",
    "label": "samsung SCV43-u",
    "value": "samsung SCV43-u"
  },
  {
    "name": "Smooth Smooth626",
    "label": "Smooth Smooth626",
    "value": "Smooth Smooth626"
  },
  {
    "name": "Hisense Hisense F23 PLUS",
    "label": "Hisense Hisense F23 PLUS",
    "value": "Hisense Hisense F23 PLUS"
  },
  {
    "name": "wheatek A95",
    "label": "wheatek A95",
    "value": "wheatek A95"
  },
  {
    "name": "LGE LG-SP320",
    "label": "LGE LG-SP320",
    "value": "LGE LG-SP320"
  },
  {
    "name": "LT LT_C3500",
    "label": "LT LT_C3500",
    "value": "LT LT_C3500"
  },
  {
    "name": "Symphony i30",
    "label": "Symphony i30",
    "value": "Symphony i30"
  },
  {
    "name": "Ulefone Armor X7 Pro",
    "label": "Ulefone Armor X7 Pro",
    "value": "Ulefone Armor X7 Pro"
  },
  {
    "name": "HUAWEI DUB-AL00",
    "label": "HUAWEI DUB-AL00",
    "value": "HUAWEI DUB-AL00"
  },
  {
    "name": "OPPO OPPO R11s Plus",
    "label": "OPPO OPPO R11s Plus",
    "value": "OPPO OPPO R11s Plus"
  },
  {
    "name": "sky Platinum_P4",
    "label": "sky Platinum_P4",
    "value": "sky Platinum_P4"
  },
  {
    "name": "Sony SO-52A",
    "label": "Sony SO-52A",
    "value": "Sony SO-52A"
  },
  {
    "name": "HUAWEI KOB2-W09",
    "label": "HUAWEI KOB2-W09",
    "value": "HUAWEI KOB2-W09"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6815",
    "label": "INFINIX MOBILITY LIMITED Infinix X6815",
    "value": "INFINIX MOBILITY LIMITED Infinix X6815"
  },
  {
    "name": "motorola XT1925-3",
    "label": "motorola XT1925-3",
    "value": "motorola XT1925-3"
  },
  {
    "name": "ZUK ZUK Z2121",
    "label": "ZUK ZUK Z2121",
    "value": "ZUK ZUK Z2121"
  },
  {
    "name": "ZTE BLADE A320",
    "label": "ZTE BLADE A320",
    "value": "ZTE BLADE A320"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KC8S",
    "label": "TECNO MOBILE LIMITED TECNO KC8S",
    "value": "TECNO MOBILE LIMITED TECNO KC8S"
  },
  {
    "name": "motorola Moto G5S (XT1792)",
    "label": "motorola Moto G5S (XT1792)",
    "value": "motorola Moto G5S (XT1792)"
  },
  {
    "name": "WIKO W_P200CM",
    "label": "WIKO W_P200CM",
    "value": "WIKO W_P200CM"
  },
  {
    "name": "wheatek BV5500Plus",
    "label": "wheatek BV5500Plus",
    "value": "wheatek BV5500Plus"
  },
  {
    "name": "LAVA iris88",
    "label": "LAVA iris88",
    "value": "LAVA iris88"
  },
  {
    "name": "realme RMX2183",
    "label": "realme RMX2183",
    "value": "realme RMX2183"
  },
  {
    "name": "Infinix Infinix X571",
    "label": "Infinix Infinix X571",
    "value": "Infinix Infinix X571"
  },
  {
    "name": "Apple MermaidKimmie ",
    "label": "Apple MermaidKimmie ",
    "value": "Apple MermaidKimmie "
  },
  {
    "name": "iPhone14,4 iPhone",
    "label": "iPhone14,4 iPhone",
    "value": "iPhone14,4 iPhone"
  },
  {
    "name": "Sony XQ-AD52",
    "label": "Sony XQ-AD52",
    "value": "Sony XQ-AD52"
  },
  {
    "name": "ZTE ZTE 9045",
    "label": "ZTE ZTE 9045",
    "value": "ZTE ZTE 9045"
  },
  {
    "name": "samsung SM-T377T",
    "label": "samsung SM-T377T",
    "value": "samsung SM-T377T"
  },
  {
    "name": "Apple Valentin’s iPhone",
    "label": "Apple Valentin’s iPhone",
    "value": "Apple Valentin’s iPhone"
  },
  {
    "name": "Apple Sisi’s iPhone",
    "label": "Apple Sisi’s iPhone",
    "value": "Apple Sisi’s iPhone"
  },
  {
    "name": "samsung SM-G8850",
    "label": "samsung SM-G8850",
    "value": "samsung SM-G8850"
  },
  {
    "name": "samsung Nexus",
    "label": "samsung Nexus",
    "value": "samsung Nexus"
  },
  {
    "name": "samsung SM-A047M",
    "label": "samsung SM-A047M",
    "value": "samsung SM-A047M"
  },
  {
    "name": "samsung SM-A2360",
    "label": "samsung SM-A2360",
    "value": "samsung SM-A2360"
  },
  {
    "name": "samsung SCV41",
    "label": "samsung SCV41",
    "value": "samsung SCV41"
  },
  {
    "name": "WIKO W-K420-TVM",
    "label": "WIKO W-K420-TVM",
    "value": "WIKO W-K420-TVM"
  },
  {
    "name": "OPPO PACM00",
    "label": "OPPO PACM00",
    "value": "OPPO PACM00"
  },
  {
    "name": "LENOVO Lenovo TB-X6C6X",
    "label": "LENOVO Lenovo TB-X6C6X",
    "value": "LENOVO Lenovo TB-X6C6X"
  },
  {
    "name": "OnePlus GM1925",
    "label": "OnePlus GM1925",
    "value": "OnePlus GM1925"
  },
  {
    "name": "OPPO PFEM10",
    "label": "OPPO PFEM10",
    "value": "OPPO PFEM10"
  },
  {
    "name": "LAVA LAVA LZG402 OM",
    "label": "LAVA LAVA LZG402 OM",
    "value": "LAVA LAVA LZG402 OM"
  },
  {
    "name": "FROG A12",
    "label": "FROG A12",
    "value": "FROG A12"
  },
  {
    "name": "HMD Global Nokia 8 Sirocco",
    "label": "HMD Global Nokia 8 Sirocco",
    "value": "HMD Global Nokia 8 Sirocco"
  },
  {
    "name": "samsung SM-A600T",
    "label": "samsung SM-A600T",
    "value": "samsung SM-A600T"
  },
  {
    "name": "Xiaomi Redmi K30 5G Speed",
    "label": "Xiaomi Redmi K30 5G Speed",
    "value": "Xiaomi Redmi K30 5G Speed"
  },
  {
    "name": "HUAWEI LYA-AL10",
    "label": "HUAWEI LYA-AL10",
    "value": "HUAWEI LYA-AL10"
  },
  {
    "name": "HUAWEI ANE-AL00",
    "label": "HUAWEI ANE-AL00",
    "value": "HUAWEI ANE-AL00"
  },
  {
    "name": "iPad11,6 iPad",
    "label": "iPad11,6 iPad",
    "value": "iPad11,6 iPad"
  },
  {
    "name": "wheatek BV8800",
    "label": "wheatek BV8800",
    "value": "wheatek BV8800"
  },
  {
    "name": "Honeywell EDA51",
    "label": "Honeywell EDA51",
    "value": "Honeywell EDA51"
  },
  {
    "name": "Hurricane Excite",
    "label": "Hurricane Excite",
    "value": "Hurricane Excite"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KC1j",
    "label": "TECNO MOBILE LIMITED TECNO KC1j",
    "value": "TECNO MOBILE LIMITED TECNO KC1j"
  },
  {
    "name": "Jiehong JH103",
    "label": "Jiehong JH103",
    "value": "Jiehong JH103"
  },
  {
    "name": "vivo I2019",
    "label": "vivo I2019",
    "value": "vivo I2019"
  },
  {
    "name": "LGE LM-Q725S",
    "label": "LGE LM-Q725S",
    "value": "LGE LM-Q725S"
  },
  {
    "name": "Hisense Hisense U3 2021",
    "label": "Hisense Hisense U3 2021",
    "value": "Hisense Hisense U3 2021"
  },
  {
    "name": "Casper VIA_F20",
    "label": "Casper VIA_F20",
    "value": "Casper VIA_F20"
  },
  {
    "name": "alps S71",
    "label": "alps S71",
    "value": "alps S71"
  },
  {
    "name": "MAXFONE MAX 5X",
    "label": "MAXFONE MAX 5X",
    "value": "MAXFONE MAX 5X"
  },
  {
    "name": "OMIX OMIX X300",
    "label": "OMIX OMIX X300",
    "value": "OMIX OMIX X300"
  },
  {
    "name": "LENOVO Lenovo TB-8705F",
    "label": "LENOVO Lenovo TB-8705F",
    "value": "LENOVO Lenovo TB-8705F"
  },
  {
    "name": "ITEL MOBILE LIMITED itel S661L",
    "label": "ITEL MOBILE LIMITED itel S661L",
    "value": "ITEL MOBILE LIMITED itel S661L"
  },
  {
    "name": "Sky Elite OctaPlus",
    "label": "Sky Elite OctaPlus",
    "value": "Sky Elite OctaPlus"
  },
  {
    "name": "CUBOT P30",
    "label": "CUBOT P30",
    "value": "CUBOT P30"
  },
  {
    "name": "incar T40 Plus_EEA",
    "label": "incar T40 Plus_EEA",
    "value": "incar T40 Plus_EEA"
  },
  {
    "name": "Realme Realme 5i",
    "label": "Realme Realme 5i",
    "value": "Realme Realme 5i"
  },
  {
    "name": "positivo Twist 4 Mini",
    "label": "positivo Twist 4 Mini",
    "value": "positivo Twist 4 Mini"
  },
  {
    "name": "vivo V2049A",
    "label": "vivo V2049A",
    "value": "vivo V2049A"
  },
  {
    "name": "Qubo X668_64GB",
    "label": "Qubo X668_64GB",
    "value": "Qubo X668_64GB"
  },
  {
    "name": "BLU C5L Plus",
    "label": "BLU C5L Plus",
    "value": "BLU C5L Plus"
  },
  {
    "name": "PREMIO S51",
    "label": "PREMIO S51",
    "value": "PREMIO S51"
  },
  {
    "name": "hena M17QF6_3G",
    "label": "hena M17QF6_3G",
    "value": "hena M17QF6_3G"
  },
  {
    "name": "vivo V2166",
    "label": "vivo V2166",
    "value": "vivo V2166"
  },
  {
    "name": "OPPO CPH2025",
    "label": "OPPO CPH2025",
    "value": "OPPO CPH2025"
  },
  {
    "name": "MobiWire Kicka 5 Plus 4G",
    "label": "MobiWire Kicka 5 Plus 4G",
    "value": "MobiWire Kicka 5 Plus 4G"
  },
  {
    "name": "vsmart Active 1",
    "label": "vsmart Active 1",
    "value": "vsmart Active 1"
  },
  {
    "name": "ELEVATE LUNA G58",
    "label": "ELEVATE LUNA G58",
    "value": "ELEVATE LUNA G58"
  },
  {
    "name": "Sony SO-04K",
    "label": "Sony SO-04K",
    "value": "Sony SO-04K"
  },
  {
    "name": "Amlogic TVBOX",
    "label": "Amlogic TVBOX",
    "value": "Amlogic TVBOX"
  },
  {
    "name": "DOOGEE S86Pro",
    "label": "DOOGEE S86Pro",
    "value": "DOOGEE S86Pro"
  },
  {
    "name": "Apple iPad13,11",
    "label": "Apple iPad13,11",
    "value": "Apple iPad13,11"
  },
  {
    "name": "WIKO W-V750BN-OPE",
    "label": "WIKO W-V750BN-OPE",
    "value": "WIKO W-V750BN-OPE"
  },
  {
    "name": "LT C3700",
    "label": "LT C3700",
    "value": "LT C3700"
  },
  {
    "name": "BLU A5L",
    "label": "BLU A5L",
    "value": "BLU A5L"
  },
  {
    "name": "TENCHHK ISWAG MATRIX",
    "label": "TENCHHK ISWAG MATRIX",
    "value": "TENCHHK ISWAG MATRIX"
  },
  {
    "name": "samsung SM-A205U1",
    "label": "samsung SM-A205U1",
    "value": "samsung SM-A205U1"
  },
  {
    "name": "vivo V2001A",
    "label": "vivo V2001A",
    "value": "vivo V2001A"
  },
  {
    "name": "samsung SM-T517P",
    "label": "samsung SM-T517P",
    "value": "samsung SM-T517P"
  },
  {
    "name": "ZTE ZTE BLADE A330",
    "label": "ZTE ZTE BLADE A330",
    "value": "ZTE ZTE BLADE A330"
  },
  {
    "name": "P3 P3",
    "label": "P3 P3",
    "value": "P3 P3"
  },
  {
    "name": "CORN CORN X55",
    "label": "CORN CORN X55",
    "value": "CORN CORN X55"
  },
  {
    "name": "vivo V1936A",
    "label": "vivo V1936A",
    "value": "vivo V1936A"
  },
  {
    "name": "Bmobile BL50P_TG07",
    "label": "Bmobile BL50P_TG07",
    "value": "Bmobile BL50P_TG07"
  },
  {
    "name": "DOOGEE S98",
    "label": "DOOGEE S98",
    "value": "DOOGEE S98"
  },
  {
    "name": "Sony G8231",
    "label": "Sony G8231",
    "value": "Sony G8231"
  },
  {
    "name": "QMobile View",
    "label": "QMobile View",
    "value": "QMobile View"
  },
  {
    "name": "OPPO PEMM00",
    "label": "OPPO PEMM00",
    "value": "OPPO PEMM00"
  },
  {
    "name": "CUBOT MAX 3",
    "label": "CUBOT MAX 3",
    "value": "CUBOT MAX 3"
  },
  {
    "name": "TCL 6056A",
    "label": "TCL 6056A",
    "value": "TCL 6056A"
  },
  {
    "name": "Xiaomi M2101K9AI",
    "label": "Xiaomi M2101K9AI",
    "value": "Xiaomi M2101K9AI"
  },
  {
    "name": "Qnet K36",
    "label": "Qnet K36",
    "value": "Qnet K36"
  },
  {
    "name": "Qubo X626_4G",
    "label": "Qubo X626_4G",
    "value": "Qubo X626_4G"
  },
  {
    "name": "TCL 4087U_EEA",
    "label": "TCL 4087U_EEA",
    "value": "TCL 4087U_EEA"
  },
  {
    "name": "OPPO PBCM30",
    "label": "OPPO PBCM30",
    "value": "OPPO PBCM30"
  },
  {
    "name": "reeder P13 Blue Max Pro Lite 2022",
    "label": "reeder P13 Blue Max Pro Lite 2022",
    "value": "reeder P13 Blue Max Pro Lite 2022"
  },
  {
    "name": "CORN CORN Note1",
    "label": "CORN CORN Note1",
    "value": "CORN CORN Note1"
  },
  {
    "name": "CMDC Tecno F9",
    "label": "CMDC Tecno F9",
    "value": "CMDC Tecno F9"
  },
  {
    "name": "samsung SM-J260AZ",
    "label": "samsung SM-J260AZ",
    "value": "samsung SM-J260AZ"
  },
  {
    "name": "Ulefone Power Armor 13",
    "label": "Ulefone Power Armor 13",
    "value": "Ulefone Power Armor 13"
  },
  {
    "name": "Vestel V TAB Z1",
    "label": "Vestel V TAB Z1",
    "value": "Vestel V TAB Z1"
  },
  {
    "name": "Joyar V5",
    "label": "Joyar V5",
    "value": "Joyar V5"
  },
  {
    "name": "TCL 8052",
    "label": "TCL 8052",
    "value": "TCL 8052"
  },
  {
    "name": "TCL 9309X",
    "label": "TCL 9309X",
    "value": "TCL 9309X"
  },
  {
    "name": "HUAWEI EVR-AN00",
    "label": "HUAWEI EVR-AN00",
    "value": "HUAWEI EVR-AN00"
  },
  {
    "name": "ZTE BLADE V9 VITA",
    "label": "ZTE BLADE V9 VITA",
    "value": "ZTE BLADE V9 VITA"
  },
  {
    "name": "TCL 5059T",
    "label": "TCL 5059T",
    "value": "TCL 5059T"
  },
  {
    "name": "Chinoe A70",
    "label": "Chinoe A70",
    "value": "Chinoe A70"
  },
  {
    "name": "Desktop - OS/2",
    "label": "Desktop - OS/2",
    "value": "Desktop - OS/2"
  },
  {
    "name": "HMD Global TA-1000",
    "label": "HMD Global TA-1000",
    "value": "HMD Global TA-1000"
  },
  {
    "name": "FUJITSU FMVDH2A0E0",
    "label": "FUJITSU FMVDH2A0E0",
    "value": "FUJITSU FMVDH2A0E0"
  },
  {
    "name": "vivo vivo 1808i",
    "label": "vivo vivo 1808i",
    "value": "vivo vivo 1808i"
  },
  {
    "name": "MyPhone myG1",
    "label": "MyPhone myG1",
    "value": "MyPhone myG1"
  },
  {
    "name": "asus ASUS_I005D",
    "label": "asus ASUS_I005D",
    "value": "asus ASUS_I005D"
  },
  {
    "name": "Xiaomi GM1917",
    "label": "Xiaomi GM1917",
    "value": "Xiaomi GM1917"
  },
  {
    "name": "OnePlus DE2117",
    "label": "OnePlus DE2117",
    "value": "OnePlus DE2117"
  },
  {
    "name": "vivo V2150",
    "label": "vivo V2150",
    "value": "vivo V2150"
  },
  {
    "name": "Xiaomi MI 8 SE",
    "label": "Xiaomi MI 8 SE",
    "value": "Xiaomi MI 8 SE"
  },
  {
    "name": "HUAWEI WAS-L03T",
    "label": "HUAWEI WAS-L03T",
    "value": "HUAWEI WAS-L03T"
  },
  {
    "name": "Teclast T40 PRO_ROW",
    "label": "Teclast T40 PRO_ROW",
    "value": "Teclast T40 PRO_ROW"
  },
  {
    "name": "FIH EC1002",
    "label": "FIH EC1002",
    "value": "FIH EC1002"
  },
  {
    "name": "OPPO PACT00",
    "label": "OPPO PACT00",
    "value": "OPPO PACT00"
  },
  {
    "name": "Movitel M8420",
    "label": "Movitel M8420",
    "value": "Movitel M8420"
  },
  {
    "name": "Teclast P80X_ROW",
    "label": "Teclast P80X_ROW",
    "value": "Teclast P80X_ROW"
  },
  {
    "name": "KRIP K5b",
    "label": "KRIP K5b",
    "value": "KRIP K5b"
  },
  {
    "name": "reeder P13_Blue_Plus",
    "label": "reeder P13_Blue_Plus",
    "value": "reeder P13_Blue_Plus"
  },
  {
    "name": "OPPO PGBM10",
    "label": "OPPO PGBM10",
    "value": "OPPO PGBM10"
  },
  {
    "name": "Chinoe C20",
    "label": "Chinoe C20",
    "value": "Chinoe C20"
  },
  {
    "name": "sky Elite J55US",
    "label": "sky Elite J55US",
    "value": "sky Elite J55US"
  },
  {
    "name": "FIH EA211001",
    "label": "FIH EA211001",
    "value": "FIH EA211001"
  },
  {
    "name": "itel itel P681LM",
    "label": "itel itel P681LM",
    "value": "itel itel P681LM"
  },
  {
    "name": "Apple Reorapetse💖",
    "label": "Apple Reorapetse💖",
    "value": "Apple Reorapetse💖"
  },
  {
    "name": "Apple Nolo’s iPhone",
    "label": "Apple Nolo’s iPhone",
    "value": "Apple Nolo’s iPhone"
  },
  {
    "name": "Apple Atang’s iPhone",
    "label": "Apple Atang’s iPhone",
    "value": "Apple Atang’s iPhone"
  },
  {
    "name": "Apple Zac' iPhone",
    "label": "Apple Zac' iPhone",
    "value": "Apple Zac' iPhone"
  },
  {
    "name": "Apple Mulanga’s iPhone",
    "label": "Apple Mulanga’s iPhone",
    "value": "Apple Mulanga’s iPhone"
  },
  {
    "name": "Apple Noxolo’s iPhone",
    "label": "Apple Noxolo’s iPhone",
    "value": "Apple Noxolo’s iPhone"
  },
  {
    "name": "Apple Boitumelo’s iphone",
    "label": "Apple Boitumelo’s iphone",
    "value": "Apple Boitumelo’s iphone"
  },
  {
    "name": "Apple Madhu",
    "label": "Apple Madhu",
    "value": "Apple Madhu"
  },
  {
    "name": "Apple Amanda’s iPhone",
    "label": "Apple Amanda’s iPhone",
    "value": "Apple Amanda’s iPhone"
  },
  {
    "name": "samsung SCV36",
    "label": "samsung SCV36",
    "value": "samsung SCV36"
  },
  {
    "name": "Apple  iPhone",
    "label": "Apple  iPhone",
    "value": "Apple  iPhone"
  },
  {
    "name": "ZTE Z851",
    "label": "ZTE Z851",
    "value": "ZTE Z851"
  },
  {
    "name": "OPPO CPH1983",
    "label": "OPPO CPH1983",
    "value": "OPPO CPH1983"
  },
  {
    "name": "EastAeon UNONU_W60_PLUS",
    "label": "EastAeon UNONU_W60_PLUS",
    "value": "EastAeon UNONU_W60_PLUS"
  },
  {
    "name": "ZTE Z3352CA",
    "label": "ZTE Z3352CA",
    "value": "ZTE Z3352CA"
  },
  {
    "name": "Ulefone Armor_3W",
    "label": "Ulefone Armor_3W",
    "value": "Ulefone Armor_3W"
  },
  {
    "name": "Shenzhen E-dong Technology Co.,Ltd 770G",
    "label": "Shenzhen E-dong Technology Co.,Ltd 770G",
    "value": "Shenzhen E-dong Technology Co.,Ltd 770G"
  },
  {
    "name": "vivo V1924A",
    "label": "vivo V1924A",
    "value": "vivo V1924A"
  },
  {
    "name": "samsung SM-N975X",
    "label": "samsung SM-N975X",
    "value": "samsung SM-N975X"
  },
  {
    "name": "SHARP 701SH",
    "label": "SHARP 701SH",
    "value": "SHARP 701SH"
  },
  {
    "name": "realme realme 3 Pro",
    "label": "realme realme 3 Pro",
    "value": "realme realme 3 Pro"
  },
  {
    "name": "HOTWAV Pearl K3",
    "label": "HOTWAV Pearl K3",
    "value": "HOTWAV Pearl K3"
  },
  {
    "name": "SHARP SH-C02",
    "label": "SHARP SH-C02",
    "value": "SHARP SH-C02"
  },
  {
    "name": "Sony H3213",
    "label": "Sony H3213",
    "value": "Sony H3213"
  },
  {
    "name": "Vanzo CUBOT R11",
    "label": "Vanzo CUBOT R11",
    "value": "Vanzo CUBOT R11"
  },
  {
    "name": "Apple A2218",
    "label": "Apple A2218",
    "value": "Apple A2218"
  },
  {
    "name": "Lenovo Lenovo L38041",
    "label": "Lenovo Lenovo L38041",
    "value": "Lenovo Lenovo L38041"
  },
  {
    "name": "SMARTEC SmartabS4",
    "label": "SMARTEC SmartabS4",
    "value": "SMARTEC SmartabS4"
  },
  {
    "name": "Gigaset E940-2795-00",
    "label": "Gigaset E940-2795-00",
    "value": "Gigaset E940-2795-00"
  },
  {
    "name": "BLU Vivo One Plus 2019",
    "label": "BLU Vivo One Plus 2019",
    "value": "BLU Vivo One Plus 2019"
  },
  {
    "name": "SUGAR SUGAR S50",
    "label": "SUGAR SUGAR S50",
    "value": "SUGAR SUGAR S50"
  },
  {
    "name": "TCL A502DL",
    "label": "TCL A502DL",
    "value": "TCL A502DL"
  },
  {
    "name": "LGE L-03K",
    "label": "LGE L-03K",
    "value": "LGE L-03K"
  },
  {
    "name": "Lanix Ilium Alpha 7",
    "label": "Lanix Ilium Alpha 7",
    "value": "Lanix Ilium Alpha 7"
  },
  {
    "name": "LGE L-01K",
    "label": "LGE L-01K",
    "value": "LGE L-01K"
  },
  {
    "name": "INFINIX Infinix X6815C",
    "label": "INFINIX Infinix X6815C",
    "value": "INFINIX Infinix X6815C"
  },
  {
    "name": "Xiaomi Poco F1",
    "label": "Xiaomi Poco F1",
    "value": "Xiaomi Poco F1"
  },
  {
    "name": "OPPO PDVM00",
    "label": "OPPO PDVM00",
    "value": "OPPO PDVM00"
  },
  {
    "name": "Xiaomi M2007J1SC",
    "label": "Xiaomi M2007J1SC",
    "value": "Xiaomi M2007J1SC"
  },
  {
    "name": "motorola moto one vision",
    "label": "motorola moto one vision",
    "value": "motorola moto one vision"
  },
  {
    "name": "samsung SM-A215W",
    "label": "samsung SM-A215W",
    "value": "samsung SM-A215W"
  },
  {
    "name": "Apple Hrshdxxp",
    "label": "Apple Hrshdxxp",
    "value": "Apple Hrshdxxp"
  },
  {
    "name": "Apple Kelebogile Cynthia ’s iPhone",
    "label": "Apple Kelebogile Cynthia ’s iPhone",
    "value": "Apple Kelebogile Cynthia ’s iPhone"
  },
  {
    "name": "Apple PANGA",
    "label": "Apple PANGA",
    "value": "Apple PANGA"
  },
  {
    "name": "Apple Mulisa’s iPhone",
    "label": "Apple Mulisa’s iPhone",
    "value": "Apple Mulisa’s iPhone"
  },
  {
    "name": "revoview NEON LITE",
    "label": "revoview NEON LITE",
    "value": "revoview NEON LITE"
  },
  {
    "name": "MAXTRON MAXTRON S8",
    "label": "MAXTRON MAXTRON S8",
    "value": "MAXTRON MAXTRON S8"
  },
  {
    "name": "HMR Networks Boost Volt",
    "label": "HMR Networks Boost Volt",
    "value": "HMR Networks Boost Volt"
  },
  {
    "name": "OnePlus LE2117",
    "label": "OnePlus LE2117",
    "value": "OnePlus LE2117"
  },
  {
    "name": "TECNO Phantom6-Plus",
    "label": "TECNO Phantom6-Plus",
    "value": "TECNO Phantom6-Plus"
  },
  {
    "name": "SkyDevices Platinum H5",
    "label": "SkyDevices Platinum H5",
    "value": "SkyDevices Platinum H5"
  },
  {
    "name": "Xiaomi M2104K10I",
    "label": "Xiaomi M2104K10I",
    "value": "Xiaomi M2104K10I"
  },
  {
    "name": "samsung SM-F907N",
    "label": "samsung SM-F907N",
    "value": "samsung SM-F907N"
  },
  {
    "name": "BLU G6",
    "label": "BLU G6",
    "value": "BLU G6"
  },
  {
    "name": "asus ASUS_X00QSA",
    "label": "asus ASUS_X00QSA",
    "value": "asus ASUS_X00QSA"
  },
  {
    "name": "HUAWEI BLA-A09",
    "label": "HUAWEI BLA-A09",
    "value": "HUAWEI BLA-A09"
  },
  {
    "name": "Symphony G10+",
    "label": "Symphony G10+",
    "value": "Symphony G10+"
  },
  {
    "name": "KXD A8M",
    "label": "KXD A8M",
    "value": "KXD A8M"
  },
  {
    "name": "General Mobile GM 8 d",
    "label": "General Mobile GM 8 d",
    "value": "General Mobile GM 8 d"
  },
  {
    "name": "LENOVO Lenovo TB-8505XS",
    "label": "LENOVO Lenovo TB-8505XS",
    "value": "LENOVO Lenovo TB-8505XS"
  },
  {
    "name": "samsung SM-S367VL",
    "label": "samsung SM-S367VL",
    "value": "samsung SM-S367VL"
  },
  {
    "name": "samsung SM-G9860",
    "label": "samsung SM-G9860",
    "value": "samsung SM-G9860"
  },
  {
    "name": "OnePlus NE2213",
    "label": "OnePlus NE2213",
    "value": "OnePlus NE2213"
  },
  {
    "name": "incar VZ-1",
    "label": "incar VZ-1",
    "value": "incar VZ-1"
  },
  {
    "name": "TEM SQ788Extreme",
    "label": "TEM SQ788Extreme",
    "value": "TEM SQ788Extreme"
  },
  {
    "name": "HMD Global Nokia 3 V",
    "label": "HMD Global Nokia 3 V",
    "value": "HMD Global Nokia 3 V"
  },
  {
    "name": "samsung SM-A405S",
    "label": "samsung SM-A405S",
    "value": "samsung SM-A405S"
  },
  {
    "name": "vsmart Active 1+",
    "label": "vsmart Active 1+",
    "value": "vsmart Active 1+"
  },
  {
    "name": "vivo V2125",
    "label": "vivo V2125",
    "value": "vivo V2125"
  },
  {
    "name": "vivo V2057A",
    "label": "vivo V2057A",
    "value": "vivo V2057A"
  },
  {
    "name": "MobiWire S14",
    "label": "MobiWire S14",
    "value": "MobiWire S14"
  },
  {
    "name": "SAMSUNG N900P",
    "label": "SAMSUNG N900P",
    "value": "SAMSUNG N900P"
  },
  {
    "name": "OPPO CPH1721",
    "label": "OPPO CPH1721",
    "value": "OPPO CPH1721"
  },
  {
    "name": "Trident A23 Max",
    "label": "Trident A23 Max",
    "value": "Trident A23 Max"
  },
  {
    "name": "teksun INTENSE_XT",
    "label": "teksun INTENSE_XT",
    "value": "teksun INTENSE_XT"
  },
  {
    "name": "PCSmart PCSGOB10LTE-V2",
    "label": "PCSmart PCSGOB10LTE-V2",
    "value": "PCSmart PCSGOB10LTE-V2"
  },
  {
    "name": "TCL 5002W",
    "label": "TCL 5002W",
    "value": "TCL 5002W"
  },
  {
    "name": "Micromax Micromax E7533",
    "label": "Micromax Micromax E7533",
    "value": "Micromax Micromax E7533"
  },
  {
    "name": "Apple unreal",
    "label": "Apple unreal",
    "value": "Apple unreal"
  },
  {
    "name": "Joyar LRN10",
    "label": "Joyar LRN10",
    "value": "Joyar LRN10"
  },
  {
    "name": "samsung SM-S9060",
    "label": "samsung SM-S9060",
    "value": "samsung SM-S9060"
  },
  {
    "name": "DOOGEE MIX",
    "label": "DOOGEE MIX",
    "value": "DOOGEE MIX"
  },
  {
    "name": "Schneider Wave 3",
    "label": "Schneider Wave 3",
    "value": "Schneider Wave 3"
  },
  {
    "name": "Blackview BV9900E",
    "label": "Blackview BV9900E",
    "value": "Blackview BV9900E"
  },
  {
    "name": "HONOR ANY-LX1",
    "label": "HONOR ANY-LX1",
    "value": "HONOR ANY-LX1"
  },
  {
    "name": "TCL 9137W",
    "label": "TCL 9137W",
    "value": "TCL 9137W"
  },
  {
    "name": "ZTE ZTE A0622",
    "label": "ZTE ZTE A0622",
    "value": "ZTE ZTE A0622"
  },
  {
    "name": "TELEFUNKEN TEL-1014GIQ",
    "label": "TELEFUNKEN TEL-1014GIQ",
    "value": "TELEFUNKEN TEL-1014GIQ"
  },
  {
    "name": "samsung SM-G981U1",
    "label": "samsung SM-G981U1",
    "value": "samsung SM-G981U1"
  },
  {
    "name": "HMD Global Nokia C5 Endi",
    "label": "HMD Global Nokia C5 Endi",
    "value": "HMD Global Nokia C5 Endi"
  },
  {
    "name": "bnd X72",
    "label": "bnd X72",
    "value": "bnd X72"
  },
  {
    "name": "Airpha Halo4",
    "label": "Airpha Halo4",
    "value": "Airpha Halo4"
  },
  {
    "name": "sprd KT107",
    "label": "sprd KT107",
    "value": "sprd KT107"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO B2",
    "label": "TECNO MOBILE LIMITED TECNO B2",
    "value": "TECNO MOBILE LIMITED TECNO B2"
  },
  {
    "name": "samsung SM-A705X",
    "label": "samsung SM-A705X",
    "value": "samsung SM-A705X"
  },
  {
    "name": "Chini’s iPad iPad",
    "label": "Chini’s iPad iPad",
    "value": "Chini’s iPad iPad"
  },
  {
    "name": "Apple Oni’s Iphone",
    "label": "Apple Oni’s Iphone",
    "value": "Apple Oni’s Iphone"
  },
  {
    "name": "Cecilia's iPad iPad",
    "label": "Cecilia's iPad iPad",
    "value": "Cecilia's iPad iPad"
  },
  {
    "name": "Ulefone Note 8",
    "label": "Ulefone Note 8",
    "value": "Ulefone Note 8"
  },
  {
    "name": "Apple 워니이~^^",
    "label": "Apple 워니이~^^",
    "value": "Apple 워니이~^^"
  },
  {
    "name": "incar GoCAM_G301",
    "label": "incar GoCAM_G301",
    "value": "incar GoCAM_G301"
  },
  {
    "name": "bq Aquaris U Lite",
    "label": "bq Aquaris U Lite",
    "value": "bq Aquaris U Lite"
  },
  {
    "name": "OPPO OPG01",
    "label": "OPPO OPG01",
    "value": "OPPO OPG01"
  },
  {
    "name": "CUBOT Pocket",
    "label": "CUBOT Pocket",
    "value": "CUBOT Pocket"
  },
  {
    "name": "CUBOT KINGKONG 5 Pro",
    "label": "CUBOT KINGKONG 5 Pro",
    "value": "CUBOT KINGKONG 5 Pro"
  },
  {
    "name": "General Mobile G512",
    "label": "General Mobile G512",
    "value": "General Mobile G512"
  },
  {
    "name": "Sony I4113",
    "label": "Sony I4113",
    "value": "Sony I4113"
  },
  {
    "name": "OPPO CPH2023",
    "label": "OPPO CPH2023",
    "value": "OPPO CPH2023"
  },
  {
    "name": "Bmobile BL54Pro",
    "label": "Bmobile BL54Pro",
    "value": "Bmobile BL54Pro"
  },
  {
    "name": "wheatek Cyber 7",
    "label": "wheatek Cyber 7",
    "value": "wheatek Cyber 7"
  },
  {
    "name": "BLU G9 PRO",
    "label": "BLU G9 PRO",
    "value": "BLU G9 PRO"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KC6S",
    "label": "TECNO MOBILE LIMITED TECNO KC6S",
    "value": "TECNO MOBILE LIMITED TECNO KC6S"
  },
  {
    "name": "Morep M610DS",
    "label": "Morep M610DS",
    "value": "Morep M610DS"
  },
  {
    "name": "positivo UP 32",
    "label": "positivo UP 32",
    "value": "positivo UP 32"
  },
  {
    "name": "Sony H4233",
    "label": "Sony H4233",
    "value": "Sony H4233"
  },
  {
    "name": "Realme RMX1925",
    "label": "Realme RMX1925",
    "value": "Realme RMX1925"
  },
  {
    "name": "WALTON Primo E11",
    "label": "WALTON Primo E11",
    "value": "WALTON Primo E11"
  },
  {
    "name": "OPPO OPG02",
    "label": "OPPO OPG02",
    "value": "OPPO OPG02"
  },
  {
    "name": "Essential Products PH-1",
    "label": "Essential Products PH-1",
    "value": "Essential Products PH-1"
  },
  {
    "name": "Apple Cris Iphone",
    "label": "Apple Cris Iphone",
    "value": "Apple Cris Iphone"
  },
  {
    "name": "TCL 5029D",
    "label": "TCL 5029D",
    "value": "TCL 5029D"
  },
  {
    "name": "Positivo Twist Tab",
    "label": "Positivo Twist Tab",
    "value": "Positivo Twist Tab"
  },
  {
    "name": "HUAWEI HRY-AL00",
    "label": "HUAWEI HRY-AL00",
    "value": "HUAWEI HRY-AL00"
  },
  {
    "name": "F2 i8_ROKR",
    "label": "F2 i8_ROKR",
    "value": "F2 i8_ROKR"
  },
  {
    "name": "Amazon KFTRPWI",
    "label": "Amazon KFTRPWI",
    "value": "Amazon KFTRPWI"
  },
  {
    "name": "Realme Realme 5 Pro",
    "label": "Realme Realme 5 Pro",
    "value": "Realme Realme 5 Pro"
  },
  {
    "name": "1099A 1099A",
    "label": "1099A 1099A",
    "value": "1099A 1099A"
  },
  {
    "name": "Ammigo Ni4",
    "label": "Ammigo Ni4",
    "value": "Ammigo Ni4"
  },
  {
    "name": "OPPO PCKM00",
    "label": "OPPO PCKM00",
    "value": "OPPO PCKM00"
  },
  {
    "name": "revoview S6501",
    "label": "revoview S6501",
    "value": "revoview S6501"
  },
  {
    "name": "Apple agi.m♥️😩🤞🏾",
    "label": "Apple agi.m♥️😩🤞🏾",
    "value": "Apple agi.m♥️😩🤞🏾"
  },
  {
    "name": "Apple Andile khanyile",
    "label": "Apple Andile khanyile",
    "value": "Apple Andile khanyile"
  },
  {
    "name": "Apple P",
    "label": "Apple P",
    "value": "Apple P"
  },
  {
    "name": "LAVA Z91",
    "label": "LAVA Z91",
    "value": "LAVA Z91"
  },
  {
    "name": "emdoor TangoTab_X",
    "label": "emdoor TangoTab_X",
    "value": "emdoor TangoTab_X"
  },
  {
    "name": "OALE Apex3",
    "label": "OALE Apex3",
    "value": "OALE Apex3"
  },
  {
    "name": "EastAeon BIOWOLF8F30",
    "label": "EastAeon BIOWOLF8F30",
    "value": "EastAeon BIOWOLF8F30"
  },
  {
    "name": "samsung SM-J3300",
    "label": "samsung SM-J3300",
    "value": "samsung SM-J3300"
  },
  {
    "name": "TECNO TECNO CI6n",
    "label": "TECNO TECNO CI6n",
    "value": "TECNO TECNO CI6n"
  },
  {
    "name": "CUBOT P40",
    "label": "CUBOT P40",
    "value": "CUBOT P40"
  },
  {
    "name": "samsung SM-G990W",
    "label": "samsung SM-G990W",
    "value": "samsung SM-G990W"
  },
  {
    "name": "realme RMX1993",
    "label": "realme RMX1993",
    "value": "realme RMX1993"
  },
  {
    "name": "TCL 9296G_EEA",
    "label": "TCL 9296G_EEA",
    "value": "TCL 9296G_EEA"
  },
  {
    "name": "Google Lenovo ThinkPad 11e 3rd Gen Chromebook",
    "label": "Google Lenovo ThinkPad 11e 3rd Gen Chromebook",
    "value": "Google Lenovo ThinkPad 11e 3rd Gen Chromebook"
  },
  {
    "name": "OPPO PBEM00",
    "label": "OPPO PBEM00",
    "value": "OPPO PBEM00"
  },
  {
    "name": "Siragon SP-6150",
    "label": "Siragon SP-6150",
    "value": "Siragon SP-6150"
  },
  {
    "name": "Starlight C_Note_Pro",
    "label": "Starlight C_Note_Pro",
    "value": "Starlight C_Note_Pro"
  },
  {
    "name": "SONY G2199",
    "label": "SONY G2199",
    "value": "SONY G2199"
  },
  {
    "name": "Apple Mulalo Mafadza ",
    "label": "Apple Mulalo Mafadza ",
    "value": "Apple Mulalo Mafadza "
  },
  {
    "name": "ZTE ZTE BLADE V Ultra Z982",
    "label": "ZTE ZTE BLADE V Ultra Z982",
    "value": "ZTE ZTE BLADE V Ultra Z982"
  },
  {
    "name": "samsung SM-A025A",
    "label": "samsung SM-A025A",
    "value": "samsung SM-A025A"
  },
  {
    "name": "along A23",
    "label": "along A23",
    "value": "along A23"
  },
  {
    "name": "Apple iPad8,10",
    "label": "Apple iPad8,10",
    "value": "Apple iPad8,10"
  },
  {
    "name": "X-TIGI 9C",
    "label": "X-TIGI 9C",
    "value": "X-TIGI 9C"
  },
  {
    "name": "samsung SM-J710FQ",
    "label": "samsung SM-J710FQ",
    "value": "samsung SM-J710FQ"
  },
  {
    "name": "BQru BQ-7038G",
    "label": "BQru BQ-7038G",
    "value": "BQru BQ-7038G"
  },
  {
    "name": "TCL 5053A",
    "label": "TCL 5053A",
    "value": "TCL 5053A"
  },
  {
    "name": "General Mobile G312",
    "label": "General Mobile G312",
    "value": "General Mobile G312"
  },
  {
    "name": "Starlight My_Star_X",
    "label": "Starlight My_Star_X",
    "value": "Starlight My_Star_X"
  },
  {
    "name": "GRAND OMG",
    "label": "GRAND OMG",
    "value": "GRAND OMG"
  },
  {
    "name": "LOGIC LOGIC L63",
    "label": "LOGIC LOGIC L63",
    "value": "LOGIC LOGIC L63"
  },
  {
    "name": "samsung SM-T595N",
    "label": "samsung SM-T595N",
    "value": "samsung SM-T595N"
  },
  {
    "name": "Apple Isabella’s iPhone",
    "label": "Apple Isabella’s iPhone",
    "value": "Apple Isabella’s iPhone"
  },
  {
    "name": "HMD Global TA-1003",
    "label": "HMD Global TA-1003",
    "value": "HMD Global TA-1003"
  },
  {
    "name": "YESTEL Y02",
    "label": "YESTEL Y02",
    "value": "YESTEL Y02"
  },
  {
    "name": "Blackview Tab8",
    "label": "Blackview Tab8",
    "value": "Blackview Tab8"
  },
  {
    "name": "techain Hi9Air",
    "label": "techain Hi9Air",
    "value": "techain Hi9Air"
  },
  {
    "name": "Techain GOME_U9",
    "label": "Techain GOME_U9",
    "value": "Techain GOME_U9"
  },
  {
    "name": "Apple iPhone 12 Pro",
    "label": "Apple iPhone 12 Pro",
    "value": "Apple iPhone 12 Pro"
  },
  {
    "name": "Apple Vukosi Timba",
    "label": "Apple Vukosi Timba",
    "value": "Apple Vukosi Timba"
  },
  {
    "name": "Apple Funie",
    "label": "Apple Funie",
    "value": "Apple Funie"
  },
  {
    "name": "TECLAST M40_EEA",
    "label": "TECLAST M40_EEA",
    "value": "TECLAST M40_EEA"
  },
  {
    "name": "samsung SCV38",
    "label": "samsung SCV38",
    "value": "samsung SCV38"
  },
  {
    "name": "samsung SCV42",
    "label": "samsung SCV42",
    "value": "samsung SCV42"
  },
  {
    "name": "Lanix Ilium M7T",
    "label": "Lanix Ilium M7T",
    "value": "Lanix Ilium M7T"
  },
  {
    "name": "samsung SM-P615N",
    "label": "samsung SM-P615N",
    "value": "samsung SM-P615N"
  },
  {
    "name": "Apple Tatum’s iPhone",
    "label": "Apple Tatum’s iPhone",
    "value": "Apple Tatum’s iPhone"
  },
  {
    "name": "TCL 6125D_TR",
    "label": "TCL 6125D_TR",
    "value": "TCL 6125D_TR"
  },
  {
    "name": "JOYAR 100044018P",
    "label": "JOYAR 100044018P",
    "value": "JOYAR 100044018P"
  },
  {
    "name": "ZTE Z971",
    "label": "ZTE Z971",
    "value": "ZTE Z971"
  },
  {
    "name": "bq Aquaris X5 Plus",
    "label": "bq Aquaris X5 Plus",
    "value": "bq Aquaris X5 Plus"
  },
  {
    "name": "SHARP S7-SH",
    "label": "SHARP S7-SH",
    "value": "SHARP S7-SH"
  },
  {
    "name": "FIH EA211002",
    "label": "FIH EA211002",
    "value": "FIH EA211002"
  },
  {
    "name": "samsung SM-J500M",
    "label": "samsung SM-J500M",
    "value": "samsung SM-J500M"
  },
  {
    "name": "ADVAN ADVAN 1011",
    "label": "ADVAN ADVAN 1011",
    "value": "ADVAN ADVAN 1011"
  },
  {
    "name": "LGE LGM-X401L",
    "label": "LGE LGM-X401L",
    "value": "LGE LGM-X401L"
  },
  {
    "name": "ragentek M5",
    "label": "ragentek M5",
    "value": "ragentek M5"
  },
  {
    "name": "Cherry_Mobile Flare J6 Plus",
    "label": "Cherry_Mobile Flare J6 Plus",
    "value": "Cherry_Mobile Flare J6 Plus"
  },
  {
    "name": "iPhone iPad",
    "label": "iPhone iPad",
    "value": "iPhone iPad"
  },
  {
    "name": "Apple MPHO",
    "label": "Apple MPHO",
    "value": "Apple MPHO"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CE7",
    "label": "TECNO MOBILE LIMITED TECNO CE7",
    "value": "TECNO MOBILE LIMITED TECNO CE7"
  },
  {
    "name": "MobiWire S23",
    "label": "MobiWire S23",
    "value": "MobiWire S23"
  },
  {
    "name": "TCL 5033F_EEA",
    "label": "TCL 5033F_EEA",
    "value": "TCL 5033F_EEA"
  },
  {
    "name": "Cherry Mobile Flare_Y3",
    "label": "Cherry Mobile Flare_Y3",
    "value": "Cherry Mobile Flare_Y3"
  },
  {
    "name": "Apple John Christian’s iPhone",
    "label": "Apple John Christian’s iPhone",
    "value": "Apple John Christian’s iPhone"
  },
  {
    "name": "ADVAN 5503",
    "label": "ADVAN 5503",
    "value": "ADVAN 5503"
  },
  {
    "name": "Xiaomi MI 5",
    "label": "Xiaomi MI 5",
    "value": "Xiaomi MI 5"
  },
  {
    "name": "vivo V2168",
    "label": "vivo V2168",
    "value": "vivo V2168"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X662B",
    "label": "INFINIX MOBILITY LIMITED Infinix X662B",
    "value": "INFINIX MOBILITY LIMITED Infinix X662B"
  },
  {
    "name": "samsung SM-A025AZ",
    "label": "samsung SM-A025AZ",
    "value": "samsung SM-A025AZ"
  },
  {
    "name": "GIONEE GIONEE F6L",
    "label": "GIONEE GIONEE F6L",
    "value": "GIONEE GIONEE F6L"
  },
  {
    "name": "LENOVO Lenovo TB-X104L",
    "label": "LENOVO Lenovo TB-X104L",
    "value": "LENOVO Lenovo TB-X104L"
  },
  {
    "name": "FIGI Note_7_Pro",
    "label": "FIGI Note_7_Pro",
    "value": "FIGI Note_7_Pro"
  },
  {
    "name": "CUBOT NOTE 8",
    "label": "CUBOT NOTE 8",
    "value": "CUBOT NOTE 8"
  },
  {
    "name": "HUAWEI BAH3-W59",
    "label": "HUAWEI BAH3-W59",
    "value": "HUAWEI BAH3-W59"
  },
  {
    "name": "motorola moto g power (XT2041DL)",
    "label": "motorola moto g power (XT2041DL)",
    "value": "motorola moto g power (XT2041DL)"
  },
  {
    "name": "TCL T676K",
    "label": "TCL T676K",
    "value": "TCL T676K"
  },
  {
    "name": "HUAWEI CTR-LX3",
    "label": "HUAWEI CTR-LX3",
    "value": "HUAWEI CTR-LX3"
  },
  {
    "name": "HUAWEI MHA-AL00",
    "label": "HUAWEI MHA-AL00",
    "value": "HUAWEI MHA-AL00"
  },
  {
    "name": "ZTE Blade A31",
    "label": "ZTE Blade A31",
    "value": "ZTE Blade A31"
  },
  {
    "name": "OPay Digital Services Limited M6",
    "label": "OPay Digital Services Limited M6",
    "value": "OPay Digital Services Limited M6"
  },
  {
    "name": "OnePlus CPH2469",
    "label": "OnePlus CPH2469",
    "value": "OnePlus CPH2469"
  },
  {
    "name": "HAFURY M20",
    "label": "HAFURY M20",
    "value": "HAFURY M20"
  },
  {
    "name": "vsmart Joy 1",
    "label": "vsmart Joy 1",
    "value": "vsmart Joy 1"
  },
  {
    "name": "Sony XQ-BQ52",
    "label": "Sony XQ-BQ52",
    "value": "Sony XQ-BQ52"
  },
  {
    "name": "ITEL MOBILE LIMITED itel S661LP",
    "label": "ITEL MOBILE LIMITED itel S661LP",
    "value": "ITEL MOBILE LIMITED itel S661LP"
  },
  {
    "name": "HONOR VNE-LX1",
    "label": "HONOR VNE-LX1",
    "value": "HONOR VNE-LX1"
  },
  {
    "name": "Bmobile B60Pro",
    "label": "Bmobile B60Pro",
    "value": "Bmobile B60Pro"
  },
  {
    "name": "motorola Lenovo K14 Plus",
    "label": "motorola Lenovo K14 Plus",
    "value": "motorola Lenovo K14 Plus"
  },
  {
    "name": "Blackview Tab 6",
    "label": "Blackview Tab 6",
    "value": "Blackview Tab 6"
  },
  {
    "name": "Apple Pot ",
    "label": "Apple Pot ",
    "value": "Apple Pot "
  },
  {
    "name": "motorola Nexus 6",
    "label": "motorola Nexus 6",
    "value": "motorola Nexus 6"
  },
  {
    "name": "asus ASUS_Z012S",
    "label": "asus ASUS_Z012S",
    "value": "asus ASUS_Z012S"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO SA1S Pro",
    "label": "TECNO MOBILE LIMITED TECNO SA1S Pro",
    "value": "TECNO MOBILE LIMITED TECNO SA1S Pro"
  },
  {
    "name": "Blackview BV9100",
    "label": "Blackview BV9100",
    "value": "Blackview BV9100"
  },
  {
    "name": "Fairphone FP4",
    "label": "Fairphone FP4",
    "value": "Fairphone FP4"
  },
  {
    "name": "ZTE ZTE BLADE V9",
    "label": "ZTE ZTE BLADE V9",
    "value": "ZTE ZTE BLADE V9"
  },
  {
    "name": "Sony I4293",
    "label": "Sony I4293",
    "value": "Sony I4293"
  },
  {
    "name": "reeder S19 Max",
    "label": "reeder S19 Max",
    "value": "reeder S19 Max"
  },
  {
    "name": "TECNO TECNO W5006S",
    "label": "TECNO TECNO W5006S",
    "value": "TECNO TECNO W5006S"
  },
  {
    "name": "TCL 8067",
    "label": "TCL 8067",
    "value": "TCL 8067"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X6811B",
    "label": "INFINIX MOBILITY LIMITED Infinix X6811B",
    "value": "INFINIX MOBILITY LIMITED Infinix X6811B"
  },
  {
    "name": "ZTE ZTE B2017G",
    "label": "ZTE ZTE B2017G",
    "value": "ZTE ZTE B2017G"
  },
  {
    "name": "Apple Rebecca’s iPhone",
    "label": "Apple Rebecca’s iPhone",
    "value": "Apple Rebecca’s iPhone"
  },
  {
    "name": "Apple iPad13,18",
    "label": "Apple iPad13,18",
    "value": "Apple iPad13,18"
  },
  {
    "name": "G-Tab C20",
    "label": "G-Tab C20",
    "value": "G-Tab C20"
  },
  {
    "name": "HUAWEI AGS2-AL00HN",
    "label": "HUAWEI AGS2-AL00HN",
    "value": "HUAWEI AGS2-AL00HN"
  },
  {
    "name": "TCL T671O",
    "label": "TCL T671O",
    "value": "TCL T671O"
  },
  {
    "name": "TCL 4087U",
    "label": "TCL 4087U",
    "value": "TCL 4087U"
  },
  {
    "name": "TCL T768S",
    "label": "TCL T768S",
    "value": "TCL T768S"
  },
  {
    "name": "XwirelessLLC V22",
    "label": "XwirelessLLC V22",
    "value": "XwirelessLLC V22"
  },
  {
    "name": "DOOGEE S88Pro",
    "label": "DOOGEE S88Pro",
    "value": "DOOGEE S88Pro"
  },
  {
    "name": "GIONEE GIONEE F205",
    "label": "GIONEE GIONEE F205",
    "value": "GIONEE GIONEE F205"
  },
  {
    "name": "Bleytec BT-840",
    "label": "Bleytec BT-840",
    "value": "Bleytec BT-840"
  },
  {
    "name": "DIXON DN6015",
    "label": "DIXON DN6015",
    "value": "DIXON DN6015"
  },
  {
    "name": "samsung GT-I9505",
    "label": "samsung GT-I9505",
    "value": "samsung GT-I9505"
  },
  {
    "name": "MobiWire Model A",
    "label": "MobiWire Model A",
    "value": "MobiWire Model A"
  },
  {
    "name": "vivo vivo 1908_19",
    "label": "vivo vivo 1908_19",
    "value": "vivo vivo 1908_19"
  },
  {
    "name": "SYMPHONY i74",
    "label": "SYMPHONY i74",
    "value": "SYMPHONY i74"
  },
  {
    "name": "Xiaomi M2102J2SC",
    "label": "Xiaomi M2102J2SC",
    "value": "Xiaomi M2102J2SC"
  },
  {
    "name": "WIKO WIKO T10",
    "label": "WIKO WIKO T10",
    "value": "WIKO WIKO T10"
  },
  {
    "name": "Fortune Ship Le Smooth",
    "label": "Fortune Ship Le Smooth",
    "value": "Fortune Ship Le Smooth"
  },
  {
    "name": "ZTE ZTE A2022L",
    "label": "ZTE ZTE A2022L",
    "value": "ZTE ZTE A2022L"
  },
  {
    "name": "DREAMGATE DREAM_CONNEC4_5",
    "label": "DREAMGATE DREAM_CONNEC4_5",
    "value": "DREAMGATE DREAM_CONNEC4_5"
  },
  {
    "name": "CWOWDEFU F80W",
    "label": "CWOWDEFU F80W",
    "value": "CWOWDEFU F80W"
  },
  {
    "name": "reeder P13 Blue Max Lite 2022",
    "label": "reeder P13 Blue Max Lite 2022",
    "value": "reeder P13 Blue Max Lite 2022"
  },
  {
    "name": "samsung SM-F721U1",
    "label": "samsung SM-F721U1",
    "value": "samsung SM-F721U1"
  },
  {
    "name": "DOOGEE S89Pro",
    "label": "DOOGEE S89Pro",
    "value": "DOOGEE S89Pro"
  },
  {
    "name": "Razer Phone",
    "label": "Razer Phone",
    "value": "Razer Phone"
  },
  {
    "name": "wheatek A80S",
    "label": "wheatek A80S",
    "value": "wheatek A80S"
  },
  {
    "name": "OPPO CPH2013",
    "label": "OPPO CPH2013",
    "value": "OPPO CPH2013"
  },
  {
    "name": "ZTE Z3351S",
    "label": "ZTE Z3351S",
    "value": "ZTE Z3351S"
  },
  {
    "name": "HUAWEI DUK-AL20",
    "label": "HUAWEI DUK-AL20",
    "value": "HUAWEI DUK-AL20"
  },
  {
    "name": "motorola moto g(7) optimo (XT1952DL)",
    "label": "motorola moto g(7) optimo (XT1952DL)",
    "value": "motorola moto g(7) optimo (XT1952DL)"
  },
  {
    "name": "JOYAR 100071483",
    "label": "JOYAR 100071483",
    "value": "JOYAR 100071483"
  },
  {
    "name": "HUAWEI NOH-AN01",
    "label": "HUAWEI NOH-AN01",
    "value": "HUAWEI NOH-AN01"
  },
  {
    "name": "KonnectONE MT-T8B22",
    "label": "KonnectONE MT-T8B22",
    "value": "KonnectONE MT-T8B22"
  },
  {
    "name": "HUAWEI BAC-L22",
    "label": "HUAWEI BAC-L22",
    "value": "HUAWEI BAC-L22"
  },
  {
    "name": "kanji KJ-AC02",
    "label": "kanji KJ-AC02",
    "value": "kanji KJ-AC02"
  },
  {
    "name": "samsung SM-G935FD",
    "label": "samsung SM-G935FD",
    "value": "samsung SM-G935FD"
  },
  {
    "name": "Sony E6853",
    "label": "Sony E6853",
    "value": "Sony E6853"
  },
  {
    "name": "Wings Mobile W4",
    "label": "Wings Mobile W4",
    "value": "Wings Mobile W4"
  },
  {
    "name": "Apple Winile’s iPhone",
    "label": "Apple Winile’s iPhone",
    "value": "Apple Winile’s iPhone"
  },
  {
    "name": "samsung SM-G5520",
    "label": "samsung SM-G5520",
    "value": "samsung SM-G5520"
  },
  {
    "name": "SHARP SHV46",
    "label": "SHARP SHV46",
    "value": "SHARP SHV46"
  },
  {
    "name": "HTC HTC U12 life",
    "label": "HTC HTC U12 life",
    "value": "HTC HTC U12 life"
  },
  {
    "name": "TCL T771K",
    "label": "TCL T771K",
    "value": "TCL T771K"
  },
  {
    "name": "incar T12-EEA",
    "label": "incar T12-EEA",
    "value": "incar T12-EEA"
  },
  {
    "name": "YEZZ GO 3",
    "label": "YEZZ GO 3",
    "value": "YEZZ GO 3"
  },
  {
    "name": "SKY Platinum C4",
    "label": "SKY Platinum C4",
    "value": "SKY Platinum C4"
  },
  {
    "name": "vivo V1930",
    "label": "vivo V1930",
    "value": "vivo V1930"
  },
  {
    "name": "samsung SC-52A",
    "label": "samsung SC-52A",
    "value": "samsung SC-52A"
  },
  {
    "name": "HUAWEI AGS3K-W09",
    "label": "HUAWEI AGS3K-W09",
    "value": "HUAWEI AGS3K-W09"
  },
  {
    "name": "Xiaomi Mi 8",
    "label": "Xiaomi Mi 8",
    "value": "Xiaomi Mi 8"
  },
  {
    "name": "LT LT-NOTE 10S",
    "label": "LT LT-NOTE 10S",
    "value": "LT LT-NOTE 10S"
  },
  {
    "name": "Alco RCT6B06P23",
    "label": "Alco RCT6B06P23",
    "value": "Alco RCT6B06P23"
  },
  {
    "name": "STG STG X2",
    "label": "STG STG X2",
    "value": "STG STG X2"
  },
  {
    "name": "Mobile Web - HarmonyOS",
    "label": "Mobile Web - HarmonyOS",
    "value": "Mobile Web - HarmonyOS"
  },
  {
    "name": "WIKO WIKO T50",
    "label": "WIKO WIKO T50",
    "value": "WIKO WIKO T50"
  },
  {
    "name": "BLU Studio X8 HD",
    "label": "BLU Studio X8 HD",
    "value": "BLU Studio X8 HD"
  },
  {
    "name": "Shenzhen Along Electronics Co., Ltd. a16",
    "label": "Shenzhen Along Electronics Co., Ltd. a16",
    "value": "Shenzhen Along Electronics Co., Ltd. a16"
  },
  {
    "name": "OPPO CPH2131",
    "label": "OPPO CPH2131",
    "value": "OPPO CPH2131"
  },
  {
    "name": "Mundeer M2102K1C",
    "label": "Mundeer M2102K1C",
    "value": "Mundeer M2102K1C"
  },
  {
    "name": "Xiaomi 21091116UC",
    "label": "Xiaomi 21091116UC",
    "value": "Xiaomi 21091116UC"
  },
  {
    "name": "Ulefone Note 11P",
    "label": "Ulefone Note 11P",
    "value": "Ulefone Note 11P"
  },
  {
    "name": "Xiaomi 22087RA4DI",
    "label": "Xiaomi 22087RA4DI",
    "value": "Xiaomi 22087RA4DI"
  },
  {
    "name": "IMG INKULU_PRO",
    "label": "IMG INKULU_PRO",
    "value": "IMG INKULU_PRO"
  },
  {
    "name": "UEG Grand",
    "label": "UEG Grand",
    "value": "UEG Grand"
  },
  {
    "name": "vivo vivo Y79A",
    "label": "vivo vivo Y79A",
    "value": "vivo vivo Y79A"
  },
  {
    "name": "Apple Lord Voldy  ",
    "label": "Apple Lord Voldy  ",
    "value": "Apple Lord Voldy  "
  },
  {
    "name": "HUAWEI HWV32",
    "label": "HUAWEI HWV32",
    "value": "HUAWEI HWV32"
  },
  {
    "name": "FISE EnergyE500",
    "label": "FISE EnergyE500",
    "value": "FISE EnergyE500"
  },
  {
    "name": "sanmu VIA_S30_0",
    "label": "sanmu VIA_S30_0",
    "value": "sanmu VIA_S30_0"
  },
  {
    "name": "incar Hope7_Pro",
    "label": "incar Hope7_Pro",
    "value": "incar Hope7_Pro"
  },
  {
    "name": "Chinoe F9",
    "label": "Chinoe F9",
    "value": "Chinoe F9"
  },
  {
    "name": "SkyDevices Elite P55",
    "label": "SkyDevices Elite P55",
    "value": "SkyDevices Elite P55"
  },
  {
    "name": "MyPhone myWX2",
    "label": "MyPhone myWX2",
    "value": "MyPhone myWX2"
  },
  {
    "name": "samsung SM-A716V",
    "label": "samsung SM-A716V",
    "value": "samsung SM-A716V"
  },
  {
    "name": "samsung SM-T827V",
    "label": "samsung SM-T827V",
    "value": "samsung SM-T827V"
  },
  {
    "name": "Symphony i95",
    "label": "Symphony i95",
    "value": "Symphony i95"
  },
  {
    "name": "Apple nix  ",
    "label": "Apple nix  ",
    "value": "Apple nix  "
  },
  {
    "name": "samsung SM-M136B",
    "label": "samsung SM-M136B",
    "value": "samsung SM-M136B"
  },
  {
    "name": "RUIO S6506",
    "label": "RUIO S6506",
    "value": "RUIO S6506"
  },
  {
    "name": "ZTE ZTE A2021G",
    "label": "ZTE ZTE A2021G",
    "value": "ZTE ZTE A2021G"
  },
  {
    "name": "Droi Digi C1",
    "label": "Droi Digi C1",
    "value": "Droi Digi C1"
  },
  {
    "name": "Sony XQ-BC52",
    "label": "Sony XQ-BC52",
    "value": "Sony XQ-BC52"
  },
  {
    "name": "samsung SM-A810F",
    "label": "samsung SM-A810F",
    "value": "samsung SM-A810F"
  },
  {
    "name": "Sky_Devices Elite 45T",
    "label": "Sky_Devices Elite 45T",
    "value": "Sky_Devices Elite 45T"
  },
  {
    "name": "ZUUM MAGNO C1",
    "label": "ZUUM MAGNO C1",
    "value": "ZUUM MAGNO C1"
  },
  {
    "name": "asus ASUS_I001DD",
    "label": "asus ASUS_I001DD",
    "value": "asus ASUS_I001DD"
  },
  {
    "name": "Amazon KFQUWI",
    "label": "Amazon KFQUWI",
    "value": "Amazon KFQUWI"
  },
  {
    "name": "incar T12",
    "label": "incar T12",
    "value": "incar T12"
  },
  {
    "name": "Coolpad CP3504L",
    "label": "Coolpad CP3504L",
    "value": "Coolpad CP3504L"
  },
  {
    "name": "HUAWEI ANA-LX4",
    "label": "HUAWEI ANA-LX4",
    "value": "HUAWEI ANA-LX4"
  },
  {
    "name": "BLU M8L Plus",
    "label": "BLU M8L Plus",
    "value": "BLU M8L Plus"
  },
  {
    "name": "TCL 5164A",
    "label": "TCL 5164A",
    "value": "TCL 5164A"
  },
  {
    "name": "LT LT-NOTE 30",
    "label": "LT LT-NOTE 30",
    "value": "LT LT-NOTE 30"
  },
  {
    "name": "Apple Jhoren ",
    "label": "Apple Jhoren ",
    "value": "Apple Jhoren "
  },
  {
    "name": "GIONEE GIONEE S11 lite",
    "label": "GIONEE GIONEE S11 lite",
    "value": "GIONEE GIONEE S11 lite"
  },
  {
    "name": "TCL 5053D_EEA",
    "label": "TCL 5053D_EEA",
    "value": "TCL 5053D_EEA"
  },
  {
    "name": "HUAWEI ANG-LX2",
    "label": "HUAWEI ANG-LX2",
    "value": "HUAWEI ANG-LX2"
  },
  {
    "name": "HTC HTC 10",
    "label": "HTC HTC 10",
    "value": "HTC HTC 10"
  },
  {
    "name": "BlackBerry BBE100-4",
    "label": "BlackBerry BBE100-4",
    "value": "BlackBerry BBE100-4"
  },
  {
    "name": "Xiaomi XIG02",
    "label": "Xiaomi XIG02",
    "value": "Xiaomi XIG02"
  },
  {
    "name": "BLU Vivo One Plus",
    "label": "BLU Vivo One Plus",
    "value": "BLU Vivo One Plus"
  },
  {
    "name": "KALLEY SILVER_MAX",
    "label": "KALLEY SILVER_MAX",
    "value": "KALLEY SILVER_MAX"
  },
  {
    "name": "vivo V2145",
    "label": "vivo V2145",
    "value": "vivo V2145"
  },
  {
    "name": "Sky_Devices Platinum View2",
    "label": "Sky_Devices Platinum View2",
    "value": "Sky_Devices Platinum View2"
  },
  {
    "name": "Apple iPad8,5",
    "label": "Apple iPad8,5",
    "value": "Apple iPad8,5"
  },
  {
    "name": "vivo V1813BT",
    "label": "vivo V1813BT",
    "value": "vivo V1813BT"
  },
  {
    "name": "Ulefone Ulefone_S1",
    "label": "Ulefone Ulefone_S1",
    "value": "Ulefone Ulefone_S1"
  },
  {
    "name": "Mobicel ZOOM",
    "label": "Mobicel ZOOM",
    "value": "Mobicel ZOOM"
  },
  {
    "name": "Apple Nkosinathi’s iPhone",
    "label": "Apple Nkosinathi’s iPhone",
    "value": "Apple Nkosinathi’s iPhone"
  },
  {
    "name": "Apple Nkanyi...",
    "label": "Apple Nkanyi...",
    "value": "Apple Nkanyi..."
  },
  {
    "name": "wheatek WP15",
    "label": "wheatek WP15",
    "value": "wheatek WP15"
  },
  {
    "name": "UMIDIGI S5 Pro",
    "label": "UMIDIGI S5 Pro",
    "value": "UMIDIGI S5 Pro"
  },
  {
    "name": "vivo V2111-EG",
    "label": "vivo V2111-EG",
    "value": "vivo V2111-EG"
  },
  {
    "name": "Apple iPad11,4",
    "label": "Apple iPad11,4",
    "value": "Apple iPad11,4"
  },
  {
    "name": "asus ASUS_I005DC",
    "label": "asus ASUS_I005DC",
    "value": "asus ASUS_I005DC"
  },
  {
    "name": "TECNO TECNO BF7",
    "label": "TECNO TECNO BF7",
    "value": "TECNO TECNO BF7"
  },
  {
    "name": "asus ASUS_Z01KDA",
    "label": "asus ASUS_Z01KDA",
    "value": "asus ASUS_Z01KDA"
  },
  {
    "name": "Kalley Black X",
    "label": "Kalley Black X",
    "value": "Kalley Black X"
  },
  {
    "name": "HYUNDAI L604",
    "label": "HYUNDAI L604",
    "value": "HYUNDAI L604"
  },
  {
    "name": "samsung SM-T355Y",
    "label": "samsung SM-T355Y",
    "value": "samsung SM-T355Y"
  },
  {
    "name": "ADVAN 7039",
    "label": "ADVAN 7039",
    "value": "ADVAN 7039"
  },
  {
    "name": "Droi CYGNUSX",
    "label": "Droi CYGNUSX",
    "value": "Droi CYGNUSX"
  },
  {
    "name": "Teclast M40Air_EEA",
    "label": "Teclast M40Air_EEA",
    "value": "Teclast M40Air_EEA"
  },
  {
    "name": "SOHO_STYLE SS5314G",
    "label": "SOHO_STYLE SS5314G",
    "value": "SOHO_STYLE SS5314G"
  },
  {
    "name": "HUAWEI ELS-AN00",
    "label": "HUAWEI ELS-AN00",
    "value": "HUAWEI ELS-AN00"
  },
  {
    "name": "wheatek Tab 10",
    "label": "wheatek Tab 10",
    "value": "wheatek Tab 10"
  },
  {
    "name": "LOGIC T4G",
    "label": "LOGIC T4G",
    "value": "LOGIC T4G"
  },
  {
    "name": "Xiaomi Mi MIX 3",
    "label": "Xiaomi Mi MIX 3",
    "value": "Xiaomi Mi MIX 3"
  },
  {
    "name": "incar Hope7 LTE",
    "label": "incar Hope7 LTE",
    "value": "incar Hope7 LTE"
  },
  {
    "name": "TEM U4001",
    "label": "TEM U4001",
    "value": "TEM U4001"
  },
  {
    "name": "BQru BQ-5535L",
    "label": "BQru BQ-5535L",
    "value": "BQru BQ-5535L"
  },
  {
    "name": "Innovatech F2",
    "label": "Innovatech F2",
    "value": "Innovatech F2"
  },
  {
    "name": "samsung SM-E426B",
    "label": "samsung SM-E426B",
    "value": "samsung SM-E426B"
  },
  {
    "name": "LGE VS995",
    "label": "LGE VS995",
    "value": "LGE VS995"
  },
  {
    "name": "itel itel P651L",
    "label": "itel itel P651L",
    "value": "itel itel P651L"
  },
  {
    "name": "Sony 901SO",
    "label": "Sony 901SO",
    "value": "Sony 901SO"
  },
  {
    "name": "naomicase Nilo",
    "label": "naomicase Nilo",
    "value": "naomicase Nilo"
  },
  {
    "name": "incar ALFA_7MRC",
    "label": "incar ALFA_7MRC",
    "value": "incar ALFA_7MRC"
  },
  {
    "name": "samsung SM-G6200",
    "label": "samsung SM-G6200",
    "value": "samsung SM-G6200"
  },
  {
    "name": "Desktop - Windows Phone",
    "label": "Desktop - Windows Phone",
    "value": "Desktop - Windows Phone"
  },
  {
    "name": "Apple iPhone XR",
    "label": "Apple iPhone XR",
    "value": "Apple iPhone XR"
  },
  {
    "name": "hena NQT-73GIQ",
    "label": "hena NQT-73GIQ",
    "value": "hena NQT-73GIQ"
  },
  {
    "name": "incar A101",
    "label": "incar A101",
    "value": "incar A101"
  },
  {
    "name": "YEZZ LIV2_LTE",
    "label": "YEZZ LIV2_LTE",
    "value": "YEZZ LIV2_LTE"
  },
  {
    "name": "T1B T1B",
    "label": "T1B T1B",
    "value": "T1B T1B"
  },
  {
    "name": "Fortune Ship LS032I",
    "label": "Fortune Ship LS032I",
    "value": "Fortune Ship LS032I"
  },
  {
    "name": "LGE LGM-X320L",
    "label": "LGE LGM-X320L",
    "value": "LGE LGM-X320L"
  },
  {
    "name": "itel itel S13 Pro",
    "label": "itel itel S13 Pro",
    "value": "itel itel S13 Pro"
  },
  {
    "name": "TCL 6127I",
    "label": "TCL 6127I",
    "value": "TCL 6127I"
  },
  {
    "name": "ZTE ZTE 9046",
    "label": "ZTE ZTE 9046",
    "value": "ZTE ZTE 9046"
  },
  {
    "name": "VITEL L651",
    "label": "VITEL L651",
    "value": "VITEL L651"
  },
  {
    "name": "SkyDevices Elite P55Max",
    "label": "SkyDevices Elite P55Max",
    "value": "SkyDevices Elite P55Max"
  },
  {
    "name": "LAVA Z51",
    "label": "LAVA Z51",
    "value": "LAVA Z51"
  },
  {
    "name": "motorola GM1917",
    "label": "motorola GM1917",
    "value": "motorola GM1917"
  },
  {
    "name": "Sony H8276",
    "label": "Sony H8276",
    "value": "Sony H8276"
  },
  {
    "name": "vivo V2115",
    "label": "vivo V2115",
    "value": "vivo V2115"
  },
  {
    "name": "samsung SM-A516U1",
    "label": "samsung SM-A516U1",
    "value": "samsung SM-A516U1"
  },
  {
    "name": "Sony XQ-AS72",
    "label": "Sony XQ-AS72",
    "value": "Sony XQ-AS72"
  },
  {
    "name": "HXY BennyM",
    "label": "HXY BennyM",
    "value": "HXY BennyM"
  },
  {
    "name": "SHARP 808SH",
    "label": "SHARP 808SH",
    "value": "SHARP 808SH"
  },
  {
    "name": "joyar Bluslate7",
    "label": "joyar Bluslate7",
    "value": "joyar Bluslate7"
  },
  {
    "name": "HUAWEI BKK-L21",
    "label": "HUAWEI BKK-L21",
    "value": "HUAWEI BKK-L21"
  },
  {
    "name": "TCL 5033S",
    "label": "TCL 5033S",
    "value": "TCL 5033S"
  },
  {
    "name": "samsung SM-A115W",
    "label": "samsung SM-A115W",
    "value": "samsung SM-A115W"
  },
  {
    "name": "Google Intel Apollo Lake Chromebook",
    "label": "Google Intel Apollo Lake Chromebook",
    "value": "Google Intel Apollo Lake Chromebook"
  },
  {
    "name": "HUAWEI AGS2-W19",
    "label": "HUAWEI AGS2-W19",
    "value": "HUAWEI AGS2-W19"
  },
  {
    "name": "OPPO PENM00",
    "label": "OPPO PENM00",
    "value": "OPPO PENM00"
  },
  {
    "name": "ZUOPU JH786",
    "label": "ZUOPU JH786",
    "value": "ZUOPU JH786"
  },
  {
    "name": "Xiaomi 22041219I",
    "label": "Xiaomi 22041219I",
    "value": "Xiaomi 22041219I"
  },
  {
    "name": "IPRO S501A",
    "label": "IPRO S501A",
    "value": "IPRO S501A"
  },
  {
    "name": "HONOR LGE-NX9",
    "label": "HONOR LGE-NX9",
    "value": "HONOR LGE-NX9"
  },
  {
    "name": "WALTON Primo H10",
    "label": "WALTON Primo H10",
    "value": "WALTON Primo H10"
  },
  {
    "name": "vivo V1916A",
    "label": "vivo V1916A",
    "value": "vivo V1916A"
  },
  {
    "name": "Sky_Devices Elite A65",
    "label": "Sky_Devices Elite A65",
    "value": "Sky_Devices Elite A65"
  },
  {
    "name": "Blackview BV9000Pro-F",
    "label": "Blackview BV9000Pro-F",
    "value": "Blackview BV9000Pro-F"
  },
  {
    "name": "KRIP KRIP_K4",
    "label": "KRIP KRIP_K4",
    "value": "KRIP KRIP_K4"
  },
  {
    "name": "Apple Nathi💕",
    "label": "Apple Nathi💕",
    "value": "Apple Nathi💕"
  },
  {
    "name": "Apple iPhone ka Ciki💝",
    "label": "Apple iPhone ka Ciki💝",
    "value": "Apple iPhone ka Ciki💝"
  },
  {
    "name": "HMR Axe",
    "label": "HMR Axe",
    "value": "HMR Axe"
  },
  {
    "name": "Apple Bongeka’s iPhone",
    "label": "Apple Bongeka’s iPhone",
    "value": "Apple Bongeka’s iPhone"
  },
  {
    "name": "Apple Sithabile’s iPhone",
    "label": "Apple Sithabile’s iPhone",
    "value": "Apple Sithabile’s iPhone"
  },
  {
    "name": "Apple shanarlah’s iPhone",
    "label": "Apple shanarlah’s iPhone",
    "value": "Apple shanarlah’s iPhone"
  },
  {
    "name": "meizu meizu note9",
    "label": "meizu meizu note9",
    "value": "meizu meizu note9"
  },
  {
    "name": "HTC HTC Desire 20 Pro",
    "label": "HTC HTC Desire 20 Pro",
    "value": "HTC HTC Desire 20 Pro"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KG7n",
    "label": "TECNO MOBILE LIMITED TECNO KG7n",
    "value": "TECNO MOBILE LIMITED TECNO KG7n"
  },
  {
    "name": "BLU VIEW 1",
    "label": "BLU VIEW 1",
    "value": "BLU VIEW 1"
  },
  {
    "name": "HUAWEI KNT-AL20",
    "label": "HUAWEI KNT-AL20",
    "value": "HUAWEI KNT-AL20"
  },
  {
    "name": "TP-Link Neffos X20 Pro",
    "label": "TP-Link Neffos X20 Pro",
    "value": "TP-Link Neffos X20 Pro"
  },
  {
    "name": "ZTE K87CA",
    "label": "ZTE K87CA",
    "value": "ZTE K87CA"
  },
  {
    "name": "Ulefone Power Armor14 Pro",
    "label": "Ulefone Power Armor14 Pro",
    "value": "Ulefone Power Armor14 Pro"
  },
  {
    "name": "General Mobile G510",
    "label": "General Mobile G510",
    "value": "General Mobile G510"
  },
  {
    "name": "samsung SM-M045F",
    "label": "samsung SM-M045F",
    "value": "samsung SM-M045F"
  },
  {
    "name": "HMD Global Nokia G60 5G",
    "label": "HMD Global Nokia G60 5G",
    "value": "HMD Global Nokia G60 5G"
  },
  {
    "name": "TCL 5159A",
    "label": "TCL 5159A",
    "value": "TCL 5159A"
  },
  {
    "name": "ZTE ZTE V0840",
    "label": "ZTE ZTE V0840",
    "value": "ZTE ZTE V0840"
  },
  {
    "name": "WALTON Primo H9 Pro",
    "label": "WALTON Primo H9 Pro",
    "value": "WALTON Primo H9 Pro"
  },
  {
    "name": "HUAWEI YAL-AL00",
    "label": "HUAWEI YAL-AL00",
    "value": "HUAWEI YAL-AL00"
  },
  {
    "name": "samsung SM-A520S",
    "label": "samsung SM-A520S",
    "value": "samsung SM-A520S"
  },
  {
    "name": "ITEL MOBILE LIMITED itel W4001S",
    "label": "ITEL MOBILE LIMITED itel W4001S",
    "value": "ITEL MOBILE LIMITED itel W4001S"
  },
  {
    "name": "Safaricom NEON_NOVA",
    "label": "Safaricom NEON_NOVA",
    "value": "Safaricom NEON_NOVA"
  },
  {
    "name": "HUAWEI LRA-LX1",
    "label": "HUAWEI LRA-LX1",
    "value": "HUAWEI LRA-LX1"
  },
  {
    "name": "joyar T3G_04",
    "label": "joyar T3G_04",
    "value": "joyar T3G_04"
  },
  {
    "name": "EVERCOSS M55",
    "label": "EVERCOSS M55",
    "value": "EVERCOSS M55"
  },
  {
    "name": "samsung SM-X706B",
    "label": "samsung SM-X706B",
    "value": "samsung SM-X706B"
  },
  {
    "name": "nubia NX627J",
    "label": "nubia NX627J",
    "value": "nubia NX627J"
  },
  {
    "name": "Apple iPad13,5",
    "label": "Apple iPad13,5",
    "value": "Apple iPad13,5"
  },
  {
    "name": "Xiaomi MI 5s",
    "label": "Xiaomi MI 5s",
    "value": "Xiaomi MI 5s"
  },
  {
    "name": "LANIX Alpha 950",
    "label": "LANIX Alpha 950",
    "value": "LANIX Alpha 950"
  },
  {
    "name": "OPPO PCDM00",
    "label": "OPPO PCDM00",
    "value": "OPPO PCDM00"
  },
  {
    "name": "Mara Phones Mara Phones Z1",
    "label": "Mara Phones Mara Phones Z1",
    "value": "Mara Phones Mara Phones Z1"
  },
  {
    "name": "Apple Aiden",
    "label": "Apple Aiden",
    "value": "Apple Aiden"
  },
  {
    "name": "Apple Simvuyele’s iPhone",
    "label": "Apple Simvuyele’s iPhone",
    "value": "Apple Simvuyele’s iPhone"
  },
  {
    "name": "HUAWEI HWV33",
    "label": "HUAWEI HWV33",
    "value": "HUAWEI HWV33"
  },
  {
    "name": "Wings W1",
    "label": "Wings W1",
    "value": "Wings W1"
  },
  {
    "name": "SPA Condor Electronics Infinity e5 pro",
    "label": "SPA Condor Electronics Infinity e5 pro",
    "value": "SPA Condor Electronics Infinity e5 pro"
  },
  {
    "name": "Acer Acer_one_8_T2",
    "label": "Acer Acer_one_8_T2",
    "value": "Acer Acer_one_8_T2"
  },
  {
    "name": "ACE France BUZZ 1 Plus",
    "label": "ACE France BUZZ 1 Plus",
    "value": "ACE France BUZZ 1 Plus"
  },
  {
    "name": "Realme Realme 5 Series",
    "label": "Realme Realme 5 Series",
    "value": "Realme Realme 5 Series"
  },
  {
    "name": "ANS ACK2326",
    "label": "ANS ACK2326",
    "value": "ANS ACK2326"
  },
  {
    "name": "ACE France URBAN 1 Pro",
    "label": "ACE France URBAN 1 Pro",
    "value": "ACE France URBAN 1 Pro"
  },
  {
    "name": "SPA Condor Electronics Allure M2",
    "label": "SPA Condor Electronics Allure M2",
    "value": "SPA Condor Electronics Allure M2"
  },
  {
    "name": "Sony G3412",
    "label": "Sony G3412",
    "value": "Sony G3412"
  },
  {
    "name": "SSmooth Smooth Note 6.8",
    "label": "SSmooth Smooth Note 6.8",
    "value": "SSmooth Smooth Note 6.8"
  },
  {
    "name": "Droidlogic pendoo X10 plus",
    "label": "Droidlogic pendoo X10 plus",
    "value": "Droidlogic pendoo X10 plus"
  },
  {
    "name": "ELEVATE LUNA V57",
    "label": "ELEVATE LUNA V57",
    "value": "ELEVATE LUNA V57"
  },
  {
    "name": "Apple Linda’s iPhone",
    "label": "Apple Linda’s iPhone",
    "value": "Apple Linda’s iPhone"
  },
  {
    "name": "ZTE ZTE BLADE A320",
    "label": "ZTE ZTE BLADE A320",
    "value": "ZTE ZTE BLADE A320"
  },
  {
    "name": "KRONO NET_ADVANCE",
    "label": "KRONO NET_ADVANCE",
    "value": "KRONO NET_ADVANCE"
  },
  {
    "name": "sanmu Amber8s Pro",
    "label": "sanmu Amber8s Pro",
    "value": "sanmu Amber8s Pro"
  },
  {
    "name": "samsung SM-T515N",
    "label": "samsung SM-T515N",
    "value": "samsung SM-T515N"
  },
  {
    "name": "vivo V1829A",
    "label": "vivo V1829A",
    "value": "vivo V1829A"
  },
  {
    "name": "OPPO OPPO R9s",
    "label": "OPPO OPPO R9s",
    "value": "OPPO OPPO R9s"
  },
  {
    "name": "LGE LM-X212(G)",
    "label": "LGE LM-X212(G)",
    "value": "LGE LM-X212(G)"
  },
  {
    "name": "vivo vivo X9L",
    "label": "vivo vivo X9L",
    "value": "vivo vivo X9L"
  },
  {
    "name": "samsung SM-T820",
    "label": "samsung SM-T820",
    "value": "samsung SM-T820"
  },
  {
    "name": "realme RMX3366",
    "label": "realme RMX3366",
    "value": "realme RMX3366"
  },
  {
    "name": "Apple iPad13,19",
    "label": "Apple iPad13,19",
    "value": "Apple iPad13,19"
  },
  {
    "name": "Xiaomi M2103K19Y",
    "label": "Xiaomi M2103K19Y",
    "value": "Xiaomi M2103K19Y"
  },
  {
    "name": "Apple iPhone de Matias",
    "label": "Apple iPhone de Matias",
    "value": "Apple iPhone de Matias"
  },
  {
    "name": "Apple Miguel’s iPhone",
    "label": "Apple Miguel’s iPhone",
    "value": "Apple Miguel’s iPhone"
  },
  {
    "name": "GIONEE M100",
    "label": "GIONEE M100",
    "value": "GIONEE M100"
  },
  {
    "name": "Apple Omphile’s iPhone",
    "label": "Apple Omphile’s iPhone",
    "value": "Apple Omphile’s iPhone"
  },
  {
    "name": "Apple Tumi’s Iphone",
    "label": "Apple Tumi’s Iphone",
    "value": "Apple Tumi’s Iphone"
  },
  {
    "name": "MobiWire Orange Rise 55",
    "label": "MobiWire Orange Rise 55",
    "value": "MobiWire Orange Rise 55"
  },
  {
    "name": "Innovatech P8",
    "label": "Innovatech P8",
    "value": "Innovatech P8"
  },
  {
    "name": "LANIX Ilium Alpha 1s",
    "label": "LANIX Ilium Alpha 1s",
    "value": "LANIX Ilium Alpha 1s"
  },
  {
    "name": "TABLET T7",
    "label": "TABLET T7",
    "value": "TABLET T7"
  },
  {
    "name": "realme RMX3395",
    "label": "realme RMX3395",
    "value": "realme RMX3395"
  },
  {
    "name": "HUAWEI LYA-AL00P",
    "label": "HUAWEI LYA-AL00P",
    "value": "HUAWEI LYA-AL00P"
  },
  {
    "name": "ITEL itel S662L",
    "label": "ITEL itel S662L",
    "value": "ITEL itel S662L"
  },
  {
    "name": "samsung SM-S918B",
    "label": "samsung SM-S918B",
    "value": "samsung SM-S918B"
  },
  {
    "name": "DIGI Digi_K1",
    "label": "DIGI Digi_K1",
    "value": "DIGI Digi_K1"
  },
  {
    "name": "HUAWEI CMR-AL09",
    "label": "HUAWEI CMR-AL09",
    "value": "HUAWEI CMR-AL09"
  },
  {
    "name": "Xiaomi 22081212C",
    "label": "Xiaomi 22081212C",
    "value": "Xiaomi 22081212C"
  },
  {
    "name": "iHunt S21 Plus 2021_EEA",
    "label": "iHunt S21 Plus 2021_EEA",
    "value": "iHunt S21 Plus 2021_EEA"
  },
  {
    "name": "Xiaomi Mi9 Pro 5G",
    "label": "Xiaomi Mi9 Pro 5G",
    "value": "Xiaomi Mi9 Pro 5G"
  },
  {
    "name": "TCL 5034D",
    "label": "TCL 5034D",
    "value": "TCL 5034D"
  },
  {
    "name": "Fortune Ship M8423",
    "label": "Fortune Ship M8423",
    "value": "Fortune Ship M8423"
  },
  {
    "name": "streambus OS01",
    "label": "streambus OS01",
    "value": "streambus OS01"
  },
  {
    "name": "samsung SM-F926W",
    "label": "samsung SM-F926W",
    "value": "samsung SM-F926W"
  },
  {
    "name": "TCL 5048Y",
    "label": "TCL 5048Y",
    "value": "TCL 5048Y"
  },
  {
    "name": "LGE LG-H850",
    "label": "LGE LG-H850",
    "value": "LGE LG-H850"
  },
  {
    "name": "CUBOT R15_PRO",
    "label": "CUBOT R15_PRO",
    "value": "CUBOT R15_PRO"
  },
  {
    "name": "CORN R40",
    "label": "CORN R40",
    "value": "CORN R40"
  },
  {
    "name": "Centric Centric CM5001",
    "label": "Centric Centric CM5001",
    "value": "Centric Centric CM5001"
  },
  {
    "name": "Valentina의 iPad (2) iPad",
    "label": "Valentina의 iPad (2) iPad",
    "value": "Valentina의 iPad (2) iPad"
  },
  {
    "name": "Apple iPhoneㄷㄴ",
    "label": "Apple iPhoneㄷㄴ",
    "value": "Apple iPhoneㄷㄴ"
  },
  {
    "name": "Hisense Hisense E20s",
    "label": "Hisense Hisense E20s",
    "value": "Hisense Hisense E20s"
  },
  {
    "name": "CMDC M653",
    "label": "CMDC M653",
    "value": "CMDC M653"
  },
  {
    "name": "STYLO Ithemba",
    "label": "STYLO Ithemba",
    "value": "STYLO Ithemba"
  },
  {
    "name": "Blackview BV9800Pro",
    "label": "Blackview BV9800Pro",
    "value": "Blackview BV9800Pro"
  },
  {
    "name": "naomicase LEXI",
    "label": "naomicase LEXI",
    "value": "naomicase LEXI"
  },
  {
    "name": "Prestigio PMT5771_4G",
    "label": "Prestigio PMT5771_4G",
    "value": "Prestigio PMT5771_4G"
  },
  {
    "name": "ITEL itel P682L",
    "label": "ITEL itel P682L",
    "value": "ITEL itel P682L"
  },
  {
    "name": "alps i13 Pro+",
    "label": "alps i13 Pro+",
    "value": "alps i13 Pro+"
  },
  {
    "name": "MOBIWIRE UNONU W57A",
    "label": "MOBIWIRE UNONU W57A",
    "value": "MOBIWIRE UNONU W57A"
  },
  {
    "name": "JIMI S5018",
    "label": "JIMI S5018",
    "value": "JIMI S5018"
  },
  {
    "name": "sprd SB-TM700",
    "label": "sprd SB-TM700",
    "value": "sprd SB-TM700"
  },
  {
    "name": "Xiaomi MI 5C",
    "label": "Xiaomi MI 5C",
    "value": "Xiaomi MI 5C"
  },
  {
    "name": "OnePlus NE2211",
    "label": "OnePlus NE2211",
    "value": "OnePlus NE2211"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO SA6",
    "label": "TECNO MOBILE LIMITED TECNO SA6",
    "value": "TECNO MOBILE LIMITED TECNO SA6"
  },
  {
    "name": "Apple 임경호의 iPhone",
    "label": "Apple 임경호의 iPhone",
    "value": "Apple 임경호의 iPhone"
  },
  {
    "name": "Apple Zimasa’s iPhone",
    "label": "Apple Zimasa’s iPhone",
    "value": "Apple Zimasa’s iPhone"
  },
  {
    "name": "Apple Divya’s iPhone",
    "label": "Apple Divya’s iPhone",
    "value": "Apple Divya’s iPhone"
  },
  {
    "name": "STYLO S50_BOLD",
    "label": "STYLO S50_BOLD",
    "value": "STYLO S50_BOLD"
  },
  {
    "name": "samsung Galaxy J4 Core",
    "label": "samsung Galaxy J4 Core",
    "value": "samsung Galaxy J4 Core"
  },
  {
    "name": "alps TK-E101GC",
    "label": "alps TK-E101GC",
    "value": "alps TK-E101GC"
  },
  {
    "name": "Cherry_Mobile Aqua_Infinity",
    "label": "Cherry_Mobile Aqua_Infinity",
    "value": "Cherry_Mobile Aqua_Infinity"
  },
  {
    "name": "BLUEDIGIT RAVOZ R7",
    "label": "BLUEDIGIT RAVOZ R7",
    "value": "BLUEDIGIT RAVOZ R7"
  },
  {
    "name": "evertrade Iris",
    "label": "evertrade Iris",
    "value": "evertrade Iris"
  },
  {
    "name": "OUKITEL WP8 Pro",
    "label": "OUKITEL WP8 Pro",
    "value": "OUKITEL WP8 Pro"
  },
  {
    "name": "HOTWAV Symbol R60",
    "label": "HOTWAV Symbol R60",
    "value": "HOTWAV Symbol R60"
  },
  {
    "name": "BQru BQ-5514L",
    "label": "BQru BQ-5514L",
    "value": "BQru BQ-5514L"
  },
  {
    "name": "Mobell P41",
    "label": "Mobell P41",
    "value": "Mobell P41"
  },
  {
    "name": "TCL 8088X_EEA",
    "label": "TCL 8088X_EEA",
    "value": "TCL 8088X_EEA"
  },
  {
    "name": "Togocel Togocel_F1_Max_4G",
    "label": "Togocel Togocel_F1_Max_4G",
    "value": "Togocel Togocel_F1_Max_4G"
  },
  {
    "name": "IKU A11",
    "label": "IKU A11",
    "value": "IKU A11"
  },
  {
    "name": "CORN X60",
    "label": "CORN X60",
    "value": "CORN X60"
  },
  {
    "name": "FISE GS3G",
    "label": "FISE GS3G",
    "value": "FISE GS3G"
  },
  {
    "name": "TCL 9009F",
    "label": "TCL 9009F",
    "value": "TCL 9009F"
  },
  {
    "name": "BLU G40",
    "label": "BLU G40",
    "value": "BLU G40"
  },
  {
    "name": "Microsoft Surface Duo 2",
    "label": "Microsoft Surface Duo 2",
    "value": "Microsoft Surface Duo 2"
  },
  {
    "name": "Mobicel RIO_ZAM",
    "label": "Mobicel RIO_ZAM",
    "value": "Mobicel RIO_ZAM"
  },
  {
    "name": "incar M1038",
    "label": "incar M1038",
    "value": "incar M1038"
  },
  {
    "name": "Blackview BV5200 Pro",
    "label": "Blackview BV5200 Pro",
    "value": "Blackview BV5200 Pro"
  },
  {
    "name": "MyPhone myA17",
    "label": "MyPhone myA17",
    "value": "MyPhone myA17"
  },
  {
    "name": "vivo V1934A",
    "label": "vivo V1934A",
    "value": "vivo V1934A"
  },
  {
    "name": "alps MIX4",
    "label": "alps MIX4",
    "value": "alps MIX4"
  },
  {
    "name": "OPPO PCHM10",
    "label": "OPPO PCHM10",
    "value": "OPPO PCHM10"
  },
  {
    "name": "ZTE ZTE BLADE A6",
    "label": "ZTE ZTE BLADE A6",
    "value": "ZTE ZTE BLADE A6"
  },
  {
    "name": "HUAWEI ARS-L22",
    "label": "HUAWEI ARS-L22",
    "value": "HUAWEI ARS-L22"
  },
  {
    "name": "WIKO W-K630-TRU",
    "label": "WIKO W-K630-TRU",
    "value": "WIKO W-K630-TRU"
  },
  {
    "name": "Mobicel ZOOM-H",
    "label": "Mobicel ZOOM-H",
    "value": "Mobicel ZOOM-H"
  },
  {
    "name": "Mara Mara X dual",
    "label": "Mara Mara X dual",
    "value": "Mara Mara X dual"
  },
  {
    "name": "bq Aquaris X Pro",
    "label": "bq Aquaris X Pro",
    "value": "bq Aquaris X Pro"
  },
  {
    "name": "Sony I4213",
    "label": "Sony I4213",
    "value": "Sony I4213"
  },
  {
    "name": "Apple iPhone Barbara ",
    "label": "Apple iPhone Barbara ",
    "value": "Apple iPhone Barbara "
  },
  {
    "name": "LENOVO Lenovo TB-X306V",
    "label": "LENOVO Lenovo TB-X306V",
    "value": "LENOVO Lenovo TB-X306V"
  },
  {
    "name": "Apple Car for life and maken dasend drearer ",
    "label": "Apple Car for life and maken dasend drearer ",
    "value": "Apple Car for life and maken dasend drearer "
  },
  {
    "name": "iOS Device iPad",
    "label": "iOS Device iPad",
    "value": "iOS Device iPad"
  },
  {
    "name": "FISE Note_4CAM",
    "label": "FISE Note_4CAM",
    "value": "FISE Note_4CAM"
  },
  {
    "name": "Apple Lisa K iPhone",
    "label": "Apple Lisa K iPhone",
    "value": "Apple Lisa K iPhone"
  },
  {
    "name": "CORN CORN R60",
    "label": "CORN CORN R60",
    "value": "CORN CORN R60"
  },
  {
    "name": "myPhone Hammer_Energy_2",
    "label": "myPhone Hammer_Energy_2",
    "value": "myPhone Hammer_Energy_2"
  },
  {
    "name": "realme RMX3396",
    "label": "realme RMX3396",
    "value": "realme RMX3396"
  },
  {
    "name": "CORN Tronik_1",
    "label": "CORN Tronik_1",
    "value": "CORN Tronik_1"
  },
  {
    "name": "UNONU W50A",
    "label": "UNONU W50A",
    "value": "UNONU W50A"
  },
  {
    "name": "Ulefone Armor 10 5G",
    "label": "Ulefone Armor 10 5G",
    "value": "Ulefone Armor 10 5G"
  },
  {
    "name": "Coolpad 1902",
    "label": "Coolpad 1902",
    "value": "Coolpad 1902"
  },
  {
    "name": "Apple 신재현의 iPhone",
    "label": "Apple 신재현의 iPhone",
    "value": "Apple 신재현의 iPhone"
  },
  {
    "name": "Apple Benevolence’s iPhone",
    "label": "Apple Benevolence’s iPhone",
    "value": "Apple Benevolence’s iPhone"
  },
  {
    "name": "samsung SM-J330L",
    "label": "samsung SM-J330L",
    "value": "samsung SM-J330L"
  },
  {
    "name": "Apple NyariraJane",
    "label": "Apple NyariraJane",
    "value": "Apple NyariraJane"
  },
  {
    "name": "Tech_Pad Tech Pad X10",
    "label": "Tech_Pad Tech Pad X10",
    "value": "Tech_Pad Tech Pad X10"
  },
  {
    "name": "HUAWEI HW-01K",
    "label": "HUAWEI HW-01K",
    "value": "HUAWEI HW-01K"
  },
  {
    "name": "Cat S42",
    "label": "Cat S42",
    "value": "Cat S42"
  },
  {
    "name": "ITEL MOBILE LIMITED itel S661LPN",
    "label": "ITEL MOBILE LIMITED itel S661LPN",
    "value": "ITEL MOBILE LIMITED itel S661LPN"
  },
  {
    "name": "OPPO PFFM10",
    "label": "OPPO PFFM10",
    "value": "OPPO PFFM10"
  },
  {
    "name": "Hisense Hisense Infinity E6",
    "label": "Hisense Hisense Infinity E6",
    "value": "Hisense Hisense Infinity E6"
  },
  {
    "name": "samsung SM-A300FU",
    "label": "samsung SM-A300FU",
    "value": "samsung SM-A300FU"
  },
  {
    "name": "LGE LM-F100N",
    "label": "LGE LM-F100N",
    "value": "LGE LM-F100N"
  },
  {
    "name": "samsung SM-G611FF",
    "label": "samsung SM-G611FF",
    "value": "samsung SM-G611FF"
  },
  {
    "name": "samsung SM-F9260",
    "label": "samsung SM-F9260",
    "value": "samsung SM-F9260"
  },
  {
    "name": "Xiaomi M2002J9E",
    "label": "Xiaomi M2002J9E",
    "value": "Xiaomi M2002J9E"
  },
  {
    "name": "ZTE ZTE Blade A31RU",
    "label": "ZTE ZTE Blade A31RU",
    "value": "ZTE ZTE Blade A31RU"
  },
  {
    "name": "BQru BQru-6015L",
    "label": "BQru BQru-6015L",
    "value": "BQru BQru-6015L"
  },
  {
    "name": "Qubo BIG1",
    "label": "Qubo BIG1",
    "value": "Qubo BIG1"
  },
  {
    "name": "Mobicel FENDY_DS",
    "label": "Mobicel FENDY_DS",
    "value": "Mobicel FENDY_DS"
  },
  {
    "name": "INFINIX Infinix X668C",
    "label": "INFINIX Infinix X668C",
    "value": "INFINIX Infinix X668C"
  },
  {
    "name": "Hyundai 8LB1",
    "label": "Hyundai 8LB1",
    "value": "Hyundai 8LB1"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO IN1 Pro",
    "label": "TECNO MOBILE LIMITED TECNO IN1 Pro",
    "value": "TECNO MOBILE LIMITED TECNO IN1 Pro"
  },
  {
    "name": "HUSKEE HELIOS",
    "label": "HUSKEE HELIOS",
    "value": "HUSKEE HELIOS"
  },
  {
    "name": "TINNO Wiko U614AS",
    "label": "TINNO Wiko U614AS",
    "value": "TINNO Wiko U614AS"
  },
  {
    "name": "Fortune Ship iPlus P3",
    "label": "Fortune Ship iPlus P3",
    "value": "Fortune Ship iPlus P3"
  },
  {
    "name": "ZTE Z852",
    "label": "ZTE Z852",
    "value": "ZTE Z852"
  },
  {
    "name": "BLU C6L 2021",
    "label": "BLU C6L 2021",
    "value": "BLU C6L 2021"
  },
  {
    "name": "OPPO PFGM00",
    "label": "OPPO PFGM00",
    "value": "OPPO PFGM00"
  },
  {
    "name": "Sony XQ-AU51",
    "label": "Sony XQ-AU51",
    "value": "Sony XQ-AU51"
  },
  {
    "name": "vivo vivo 1913",
    "label": "vivo vivo 1913",
    "value": "vivo vivo 1913"
  },
  {
    "name": "BQru BQ-5518G",
    "label": "BQru BQ-5518G",
    "value": "BQru BQ-5518G"
  },
  {
    "name": "realme RMX2200",
    "label": "realme RMX2200",
    "value": "realme RMX2200"
  },
  {
    "name": "PTAC LYA-AL00",
    "label": "PTAC LYA-AL00",
    "value": "PTAC LYA-AL00"
  },
  {
    "name": "wheatek STELLAR P4",
    "label": "wheatek STELLAR P4",
    "value": "wheatek STELLAR P4"
  },
  {
    "name": "Apple MG",
    "label": "Apple MG",
    "value": "Apple MG"
  },
  {
    "name": "Fortune Ship iKU A45",
    "label": "Fortune Ship iKU A45",
    "value": "Fortune Ship iKU A45"
  },
  {
    "name": "LT LT P8",
    "label": "LT LT P8",
    "value": "LT LT P8"
  },
  {
    "name": "Multilaser Multilaser_F_Max_2",
    "label": "Multilaser Multilaser_F_Max_2",
    "value": "Multilaser Multilaser_F_Max_2"
  },
  {
    "name": "Movitel M9107",
    "label": "Movitel M9107",
    "value": "Movitel M9107"
  },
  {
    "name": "BLU C7",
    "label": "BLU C7",
    "value": "BLU C7"
  },
  {
    "name": "HUAWEI KOB2-L03",
    "label": "HUAWEI KOB2-L03",
    "value": "HUAWEI KOB2-L03"
  },
  {
    "name": "HUAWEI COR-AL00",
    "label": "HUAWEI COR-AL00",
    "value": "HUAWEI COR-AL00"
  },
  {
    "name": "PREMIO S81",
    "label": "PREMIO S81",
    "value": "PREMIO S81"
  },
  {
    "name": "LGE LM-X220N",
    "label": "LGE LM-X220N",
    "value": "LGE LM-X220N"
  },
  {
    "name": "MAZE_SPEED M1582C",
    "label": "MAZE_SPEED M1582C",
    "value": "MAZE_SPEED M1582C"
  },
  {
    "name": "samsung SM-N975W",
    "label": "samsung SM-N975W",
    "value": "samsung SM-N975W"
  },
  {
    "name": "samsung SM-F936U",
    "label": "samsung SM-F936U",
    "value": "samsung SM-F936U"
  },
  {
    "name": "along KP1-A101",
    "label": "along KP1-A101",
    "value": "along KP1-A101"
  },
  {
    "name": "ZTE ZTE Blade A52 Lite",
    "label": "ZTE ZTE Blade A52 Lite",
    "value": "ZTE ZTE Blade A52 Lite"
  },
  {
    "name": "vivo V2221",
    "label": "vivo V2221",
    "value": "vivo V2221"
  },
  {
    "name": "Ulefone Power Armor 14",
    "label": "Ulefone Power Armor 14",
    "value": "Ulefone Power Armor 14"
  },
  {
    "name": "WIKO W-K560-ID",
    "label": "WIKO W-K560-ID",
    "value": "WIKO W-K560-ID"
  },
  {
    "name": "OPPO PCAM00",
    "label": "OPPO PCAM00",
    "value": "OPPO PCAM00"
  },
  {
    "name": "samsung SM-T738U",
    "label": "samsung SM-T738U",
    "value": "samsung SM-T738U"
  },
  {
    "name": "HUAWEI EVE-LX9",
    "label": "HUAWEI EVE-LX9",
    "value": "HUAWEI EVE-LX9"
  },
  {
    "name": "iLA iLA_Silk",
    "label": "iLA iLA_Silk",
    "value": "iLA iLA_Silk"
  },
  {
    "name": "mobiistar mobiistar C1",
    "label": "mobiistar mobiistar C1",
    "value": "mobiistar mobiistar C1"
  },
  {
    "name": "OUKITEL C12 Pro",
    "label": "OUKITEL C12 Pro",
    "value": "OUKITEL C12 Pro"
  },
  {
    "name": "Movitel M9106",
    "label": "Movitel M9106",
    "value": "Movitel M9106"
  },
  {
    "name": "Sony F8332",
    "label": "Sony F8332",
    "value": "Sony F8332"
  },
  {
    "name": "meizu 16s",
    "label": "meizu 16s",
    "value": "meizu 16s"
  },
  {
    "name": "Hisense Hisense E30",
    "label": "Hisense Hisense E30",
    "value": "Hisense Hisense E30"
  },
  {
    "name": "SSmooth S2 Advance",
    "label": "SSmooth S2 Advance",
    "value": "SSmooth S2 Advance"
  },
  {
    "name": "Elephone PX",
    "label": "Elephone PX",
    "value": "Elephone PX"
  },
  {
    "name": "X-TIGI Kids5 mini",
    "label": "X-TIGI Kids5 mini",
    "value": "X-TIGI Kids5 mini"
  },
  {
    "name": "Coolpad DLS-S0",
    "label": "Coolpad DLS-S0",
    "value": "Coolpad DLS-S0"
  },
  {
    "name": "Lava Lava Z66",
    "label": "Lava Lava Z66",
    "value": "Lava Lava Z66"
  },
  {
    "name": "Apple iPhone 양명훈",
    "label": "Apple iPhone 양명훈",
    "value": "Apple iPhone 양명훈"
  },
  {
    "name": "Apple Kristen's iPhone",
    "label": "Apple Kristen's iPhone",
    "value": "Apple Kristen's iPhone"
  },
  {
    "name": "Apple Hayley’s iPhone",
    "label": "Apple Hayley’s iPhone",
    "value": "Apple Hayley’s iPhone"
  },
  {
    "name": "LGE LG-D618",
    "label": "LGE LG-D618",
    "value": "LGE LG-D618"
  },
  {
    "name": "HONOR AGM3-AL09HN",
    "label": "HONOR AGM3-AL09HN",
    "value": "HONOR AGM3-AL09HN"
  },
  {
    "name": "Apple 11 Pro",
    "label": "Apple 11 Pro",
    "value": "Apple 11 Pro"
  },
  {
    "name": "samsung SM-J810GF",
    "label": "samsung SM-J810GF",
    "value": "samsung SM-J810GF"
  },
  {
    "name": "FISE 5E",
    "label": "FISE 5E",
    "value": "FISE 5E"
  },
  {
    "name": "Quest Vibe",
    "label": "Quest Vibe",
    "value": "Quest Vibe"
  },
  {
    "name": "HUAWEI EML-AL00",
    "label": "HUAWEI EML-AL00",
    "value": "HUAWEI EML-AL00"
  },
  {
    "name": "HMD Global Nokia X30 5G",
    "label": "HMD Global Nokia X30 5G",
    "value": "HMD Global Nokia X30 5G"
  },
  {
    "name": "deejoy Q718B",
    "label": "deejoy Q718B",
    "value": "deejoy Q718B"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A507LM",
    "label": "ITEL MOBILE LIMITED itel A507LM",
    "value": "ITEL MOBILE LIMITED itel A507LM"
  },
  {
    "name": "DEXP G450 2021",
    "label": "DEXP G450 2021",
    "value": "DEXP G450 2021"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X660",
    "label": "INFINIX MOBILITY LIMITED Infinix X660",
    "value": "INFINIX MOBILITY LIMITED Infinix X660"
  },
  {
    "name": "OnePlus IN2017",
    "label": "OnePlus IN2017",
    "value": "OnePlus IN2017"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X5516C",
    "label": "INFINIX MOBILITY LIMITED Infinix X5516C",
    "value": "INFINIX MOBILITY LIMITED Infinix X5516C"
  },
  {
    "name": "CMDC M762G",
    "label": "CMDC M762G",
    "value": "CMDC M762G"
  },
  {
    "name": "Droidlogic A95X F3 Air",
    "label": "Droidlogic A95X F3 Air",
    "value": "Droidlogic A95X F3 Air"
  },
  {
    "name": "Apple 동현이",
    "label": "Apple 동현이",
    "value": "Apple 동현이"
  },
  {
    "name": "Apple 예찌 iphone",
    "label": "Apple 예찌 iphone",
    "value": "Apple 예찌 iphone"
  },
  {
    "name": "Apple 김민호의 iPhone",
    "label": "Apple 김민호의 iPhone",
    "value": "Apple 김민호의 iPhone"
  },
  {
    "name": "HOTWAV C60",
    "label": "HOTWAV C60",
    "value": "HOTWAV C60"
  },
  {
    "name": "SPA Condor Electronics Plume L3 Smart",
    "label": "SPA Condor Electronics Plume L3 Smart",
    "value": "SPA Condor Electronics Plume L3 Smart"
  },
  {
    "name": "vivo V1813T",
    "label": "vivo V1813T",
    "value": "vivo V1813T"
  },
  {
    "name": "HOTWAV Pearl K2 2020",
    "label": "HOTWAV Pearl K2 2020",
    "value": "HOTWAV Pearl K2 2020"
  },
  {
    "name": "samsung SM-T976B",
    "label": "samsung SM-T976B",
    "value": "samsung SM-T976B"
  },
  {
    "name": "TCL 5053Y_EEA",
    "label": "TCL 5053Y_EEA",
    "value": "TCL 5053Y_EEA"
  },
  {
    "name": "BQru BQ-6040L",
    "label": "BQru BQ-6040L",
    "value": "BQru BQ-6040L"
  },
  {
    "name": "Lanix Alpha 1V",
    "label": "Lanix Alpha 1V",
    "value": "Lanix Alpha 1V"
  },
  {
    "name": "DOOGEE S89",
    "label": "DOOGEE S89",
    "value": "DOOGEE S89"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO Mobile KG6p",
    "label": "TECNO MOBILE LIMITED TECNO Mobile KG6p",
    "value": "TECNO MOBILE LIMITED TECNO Mobile KG6p"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CG7n",
    "label": "TECNO MOBILE LIMITED TECNO CG7n",
    "value": "TECNO MOBILE LIMITED TECNO CG7n"
  },
  {
    "name": "INFINIX Infinix X6826B",
    "label": "INFINIX Infinix X6826B",
    "value": "INFINIX Infinix X6826B"
  },
  {
    "name": "HTC HTC One A9",
    "label": "HTC HTC One A9",
    "value": "HTC HTC One A9"
  },
  {
    "name": "OALE CC2",
    "label": "OALE CC2",
    "value": "OALE CC2"
  },
  {
    "name": "alps A94",
    "label": "alps A94",
    "value": "alps A94"
  },
  {
    "name": "ZTE ZTE BLADE V9 VITA",
    "label": "ZTE ZTE BLADE V9 VITA",
    "value": "ZTE ZTE BLADE V9 VITA"
  },
  {
    "name": "ZTE Z983",
    "label": "ZTE Z983",
    "value": "ZTE Z983"
  },
  {
    "name": "Sony E6553",
    "label": "Sony E6553",
    "value": "Sony E6553"
  },
  {
    "name": "samsung SM-A015G",
    "label": "samsung SM-A015G",
    "value": "samsung SM-A015G"
  },
  {
    "name": "Xiaomi MI 5s Plus",
    "label": "Xiaomi MI 5s Plus",
    "value": "Xiaomi MI 5s Plus"
  },
  {
    "name": "Maxwest Nitro 55C",
    "label": "Maxwest Nitro 55C",
    "value": "Maxwest Nitro 55C"
  },
  {
    "name": "Maxwest MX-N55E",
    "label": "Maxwest MX-N55E",
    "value": "Maxwest MX-N55E"
  },
  {
    "name": "Max Tel FZE X4",
    "label": "Max Tel FZE X4",
    "value": "Max Tel FZE X4"
  },
  {
    "name": "Lanix X860",
    "label": "Lanix X860",
    "value": "Lanix X860"
  },
  {
    "name": "TCL 5058A",
    "label": "TCL 5058A",
    "value": "TCL 5058A"
  },
  {
    "name": "Xiaomi MIX 2",
    "label": "Xiaomi MIX 2",
    "value": "Xiaomi MIX 2"
  },
  {
    "name": "realme RMX3661",
    "label": "realme RMX3661",
    "value": "realme RMX3661"
  },
  {
    "name": "Kalley ELEMENT_PRO_2",
    "label": "Kalley ELEMENT_PRO_2",
    "value": "Kalley ELEMENT_PRO_2"
  },
  {
    "name": "Hisense Hisense V8",
    "label": "Hisense Hisense V8",
    "value": "Hisense Hisense V8"
  },
  {
    "name": "vivo V2055A",
    "label": "vivo V2055A",
    "value": "vivo V2055A"
  },
  {
    "name": "Yulong CP3320AS2",
    "label": "Yulong CP3320AS2",
    "value": "Yulong CP3320AS2"
  },
  {
    "name": "HOTWAV Pearl K2",
    "label": "HOTWAV Pearl K2",
    "value": "HOTWAV Pearl K2"
  },
  {
    "name": "Apple Moon",
    "label": "Apple Moon",
    "value": "Apple Moon"
  },
  {
    "name": "Vestel Venus Z10",
    "label": "Vestel Venus Z10",
    "value": "Vestel Venus Z10"
  },
  {
    "name": "KODAK D55L",
    "label": "KODAK D55L",
    "value": "KODAK D55L"
  },
  {
    "name": "generalmobile GM6",
    "label": "generalmobile GM6",
    "value": "generalmobile GM6"
  },
  {
    "name": "samsung SM-G610K",
    "label": "samsung SM-G610K",
    "value": "samsung SM-G610K"
  },
  {
    "name": "transsion itel A571WM",
    "label": "transsion itel A571WM",
    "value": "transsion itel A571WM"
  },
  {
    "name": "Mobicel DANDY_ZAM",
    "label": "Mobicel DANDY_ZAM",
    "value": "Mobicel DANDY_ZAM"
  },
  {
    "name": "LGE LM-T600",
    "label": "LGE LM-T600",
    "value": "LGE LM-T600"
  },
  {
    "name": "Along Stratus_C7",
    "label": "Along Stratus_C7",
    "value": "Along Stratus_C7"
  },
  {
    "name": "LEADERPHONE Vyper PRO",
    "label": "LEADERPHONE Vyper PRO",
    "value": "LEADERPHONE Vyper PRO"
  },
  {
    "name": "samsung SAMSUNG-SM-J320A",
    "label": "samsung SAMSUNG-SM-J320A",
    "value": "samsung SAMSUNG-SM-J320A"
  },
  {
    "name": "Sky_Devices Elite Octa",
    "label": "Sky_Devices Elite Octa",
    "value": "Sky_Devices Elite Octa"
  },
  {
    "name": "INFINIX Infinix X676C",
    "label": "INFINIX Infinix X676C",
    "value": "INFINIX Infinix X676C"
  },
  {
    "name": "Sony E6683",
    "label": "Sony E6683",
    "value": "Sony E6683"
  },
  {
    "name": "QMobile View Max Pro",
    "label": "QMobile View Max Pro",
    "value": "QMobile View Max Pro"
  },
  {
    "name": "vivo V1818T",
    "label": "vivo V1818T",
    "value": "vivo V1818T"
  },
  {
    "name": "incar Masstel_Tab8_4G",
    "label": "incar Masstel_Tab8_4G",
    "value": "incar Masstel_Tab8_4G"
  },
  {
    "name": "Cherrymobile Aqua S10 Pro",
    "label": "Cherrymobile Aqua S10 Pro",
    "value": "Cherrymobile Aqua S10 Pro"
  },
  {
    "name": "Apple iPad13,9",
    "label": "Apple iPad13,9",
    "value": "Apple iPad13,9"
  },
  {
    "name": "Multilaser M7S_LITE",
    "label": "Multilaser M7S_LITE",
    "value": "Multilaser M7S_LITE"
  },
  {
    "name": "OPPO CPH1871",
    "label": "OPPO CPH1871",
    "value": "OPPO CPH1871"
  },
  {
    "name": "Google kukui",
    "label": "Google kukui",
    "value": "Google kukui"
  },
  {
    "name": "DIXON TS-M105G-1",
    "label": "DIXON TS-M105G-1",
    "value": "DIXON TS-M105G-1"
  },
  {
    "name": "Siragon SP-5300",
    "label": "Siragon SP-5300",
    "value": "Siragon SP-5300"
  },
  {
    "name": "BLU BOLD N1",
    "label": "BLU BOLD N1",
    "value": "BLU BOLD N1"
  },
  {
    "name": "WIKO W_C800",
    "label": "WIKO W_C800",
    "value": "WIKO W_C800"
  },
  {
    "name": "iPad de Paula iPad",
    "label": "iPad de Paula iPad",
    "value": "iPad de Paula iPad"
  },
  {
    "name": "WALTON PRIMO F10",
    "label": "WALTON PRIMO F10",
    "value": "WALTON PRIMO F10"
  },
  {
    "name": "Xiaomi M2007J20CT",
    "label": "Xiaomi M2007J20CT",
    "value": "Xiaomi M2007J20CT"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO RA6",
    "label": "TECNO MOBILE LIMITED TECNO RA6",
    "value": "TECNO MOBILE LIMITED TECNO RA6"
  },
  {
    "name": "Apple 정신협의 iPhone",
    "label": "Apple 정신협의 iPhone",
    "value": "Apple 정신협의 iPhone"
  },
  {
    "name": "Apple Ingrid’s iPhone",
    "label": "Apple Ingrid’s iPhone",
    "value": "Apple Ingrid’s iPhone"
  },
  {
    "name": "HUAWEI CMR-AL19",
    "label": "HUAWEI CMR-AL19",
    "value": "HUAWEI CMR-AL19"
  },
  {
    "name": "Innovatech V12s",
    "label": "Innovatech V12s",
    "value": "Innovatech V12s"
  },
  {
    "name": "WALTON RX7 Mini",
    "label": "WALTON RX7 Mini",
    "value": "WALTON RX7 Mini"
  },
  {
    "name": "TCL 5033TP",
    "label": "TCL 5033TP",
    "value": "TCL 5033TP"
  },
  {
    "name": "samsung SAMSUNG-SM-J727AZ",
    "label": "samsung SAMSUNG-SM-J727AZ",
    "value": "samsung SAMSUNG-SM-J727AZ"
  },
  {
    "name": "Ulefone Note 10P",
    "label": "Ulefone Note 10P",
    "value": "Ulefone Note 10P"
  },
  {
    "name": "Partner Mobile EV1 PRO",
    "label": "Partner Mobile EV1 PRO",
    "value": "Partner Mobile EV1 PRO"
  },
  {
    "name": "SMART PRODUCTS CONNECTION SA GRAVITY SE",
    "label": "SMART PRODUCTS CONNECTION SA GRAVITY SE",
    "value": "SMART PRODUCTS CONNECTION SA GRAVITY SE"
  },
  {
    "name": "Gigaset GS290",
    "label": "Gigaset GS290",
    "value": "Gigaset GS290"
  },
  {
    "name": "Wings W7",
    "label": "Wings W7",
    "value": "Wings W7"
  },
  {
    "name": "Coolpad 1826",
    "label": "Coolpad 1826",
    "value": "Coolpad 1826"
  },
  {
    "name": "Philco Hit P13",
    "label": "Philco Hit P13",
    "value": "Philco Hit P13"
  },
  {
    "name": "Ulefone Armor 5S",
    "label": "Ulefone Armor 5S",
    "value": "Ulefone Armor 5S"
  },
  {
    "name": "Inter Sales A/S TAQ-102",
    "label": "Inter Sales A/S TAQ-102",
    "value": "Inter Sales A/S TAQ-102"
  },
  {
    "name": "Apple Tlotlang",
    "label": "Apple Tlotlang",
    "value": "Apple Tlotlang"
  },
  {
    "name": "Xiaomi Mi Note 2",
    "label": "Xiaomi Mi Note 2",
    "value": "Xiaomi Mi Note 2"
  },
  {
    "name": "coolpad 1904",
    "label": "coolpad 1904",
    "value": "coolpad 1904"
  },
  {
    "name": "Multilaser Multilaser_E_2",
    "label": "Multilaser Multilaser_E_2",
    "value": "Multilaser Multilaser_E_2"
  },
  {
    "name": "samsung SM-J260A",
    "label": "samsung SM-J260A",
    "value": "samsung SM-J260A"
  },
  {
    "name": "ZTE ZTE Blade A51 Lite RU",
    "label": "ZTE ZTE Blade A51 Lite RU",
    "value": "ZTE ZTE Blade A51 Lite RU"
  },
  {
    "name": "Navon Navon_SPT1100",
    "label": "Navon Navon_SPT1100",
    "value": "Navon Navon_SPT1100"
  },
  {
    "name": "HTC HTV33",
    "label": "HTC HTV33",
    "value": "HTC HTV33"
  },
  {
    "name": "INFINIX Infinix X6825",
    "label": "INFINIX Infinix X6825",
    "value": "INFINIX Infinix X6825"
  },
  {
    "name": "HUAWEI ELE-AL00",
    "label": "HUAWEI ELE-AL00",
    "value": "HUAWEI ELE-AL00"
  },
  {
    "name": "bnd SK5502",
    "label": "bnd SK5502",
    "value": "bnd SK5502"
  },
  {
    "name": "vivo V2072A",
    "label": "vivo V2072A",
    "value": "vivo V2072A"
  },
  {
    "name": "Maxwest Nitro 5P",
    "label": "Maxwest Nitro 5P",
    "value": "Maxwest Nitro 5P"
  },
  {
    "name": "samsung SAMSUNG-SM-J320AZ",
    "label": "samsung SAMSUNG-SM-J320AZ",
    "value": "samsung SAMSUNG-SM-J320AZ"
  },
  {
    "name": "Droi F2",
    "label": "Droi F2",
    "value": "Droi F2"
  },
  {
    "name": "BLU Studio X9 HD",
    "label": "BLU Studio X9 HD",
    "value": "BLU Studio X9 HD"
  },
  {
    "name": "vivo vivo Y83A",
    "label": "vivo vivo Y83A",
    "value": "vivo vivo Y83A"
  },
  {
    "name": "itel itel W5003",
    "label": "itel itel W5003",
    "value": "itel itel W5003"
  },
  {
    "name": "Apple Mujaheed daniels hanover park 81 turfburg walk",
    "label": "Apple Mujaheed daniels hanover park 81 turfburg walk",
    "value": "Apple Mujaheed daniels hanover park 81 turfburg walk"
  },
  {
    "name": "Apple Ongezwa’s iPhone",
    "label": "Apple Ongezwa’s iPhone",
    "value": "Apple Ongezwa’s iPhone"
  },
  {
    "name": "Apple Bandile’s iPhone",
    "label": "Apple Bandile’s iPhone",
    "value": "Apple Bandile’s iPhone"
  },
  {
    "name": "samsung M2102K1C",
    "label": "samsung M2102K1C",
    "value": "samsung M2102K1C"
  },
  {
    "name": "LGE LM-V350N",
    "label": "LGE LM-V350N",
    "value": "LGE LM-V350N"
  },
  {
    "name": "HMD Global Nokia G400 5G",
    "label": "HMD Global Nokia G400 5G",
    "value": "HMD Global Nokia G400 5G"
  },
  {
    "name": "GIONEE GIONEE F205 Pro",
    "label": "GIONEE GIONEE F205 Pro",
    "value": "GIONEE GIONEE F205 Pro"
  },
  {
    "name": "SOHO_STYLE SS5539G",
    "label": "SOHO_STYLE SS5539G",
    "value": "SOHO_STYLE SS5539G"
  },
  {
    "name": "GIONEE S8s",
    "label": "GIONEE S8s",
    "value": "GIONEE S8s"
  },
  {
    "name": "Teclast P10_HD_EEA",
    "label": "Teclast P10_HD_EEA",
    "value": "Teclast P10_HD_EEA"
  },
  {
    "name": "Apple Yonela’s iPhone",
    "label": "Apple Yonela’s iPhone",
    "value": "Apple Yonela’s iPhone"
  },
  {
    "name": "EVERCOSS X9",
    "label": "EVERCOSS X9",
    "value": "EVERCOSS X9"
  },
  {
    "name": "OALE A10",
    "label": "OALE A10",
    "value": "OALE A10"
  },
  {
    "name": "Positivo Twist 2 Go",
    "label": "Positivo Twist 2 Go",
    "value": "Positivo Twist 2 Go"
  },
  {
    "name": "incar H30",
    "label": "incar H30",
    "value": "incar H30"
  },
  {
    "name": "OUKITEL GOME 2018X38A",
    "label": "OUKITEL GOME 2018X38A",
    "value": "OUKITEL GOME 2018X38A"
  },
  {
    "name": "EVERCOSS U6B",
    "label": "EVERCOSS U6B",
    "value": "EVERCOSS U6B"
  },
  {
    "name": "ADVAN 7301",
    "label": "ADVAN 7301",
    "value": "ADVAN 7301"
  },
  {
    "name": "BlackBerry BBF100-2",
    "label": "BlackBerry BBF100-2",
    "value": "BlackBerry BBF100-2"
  },
  {
    "name": "DOOGEE Y9Plus",
    "label": "DOOGEE Y9Plus",
    "value": "DOOGEE Y9Plus"
  },
  {
    "name": "vivo V2024",
    "label": "vivo V2024",
    "value": "vivo V2024"
  },
  {
    "name": "wheatek H1",
    "label": "wheatek H1",
    "value": "wheatek H1"
  },
  {
    "name": "samsung SM-F916U",
    "label": "samsung SM-F916U",
    "value": "samsung SM-F916U"
  },
  {
    "name": "KRIP K68",
    "label": "KRIP K68",
    "value": "KRIP K68"
  },
  {
    "name": "Apple Sweetness’s iPhone",
    "label": "Apple Sweetness’s iPhone",
    "value": "Apple Sweetness’s iPhone"
  },
  {
    "name": "Apple Eom-iPhone",
    "label": "Apple Eom-iPhone",
    "value": "Apple Eom-iPhone"
  },
  {
    "name": "wheatek X7",
    "label": "wheatek X7",
    "value": "wheatek X7"
  },
  {
    "name": "WIKO W_K300S",
    "label": "WIKO W_K300S",
    "value": "WIKO W_K300S"
  },
  {
    "name": "vivo vivo 2004",
    "label": "vivo vivo 2004",
    "value": "vivo vivo 2004"
  },
  {
    "name": "Lanix X750",
    "label": "Lanix X750",
    "value": "Lanix X750"
  },
  {
    "name": "SHARP SHV39",
    "label": "SHARP SHV39",
    "value": "SHARP SHV39"
  },
  {
    "name": "vivo V1913A",
    "label": "vivo V1913A",
    "value": "vivo V1913A"
  },
  {
    "name": "NASCO Hero",
    "label": "NASCO Hero",
    "value": "NASCO Hero"
  },
  {
    "name": "Colors_Mobile F002",
    "label": "Colors_Mobile F002",
    "value": "Colors_Mobile F002"
  },
  {
    "name": "Apple iPhone 12 mini",
    "label": "Apple iPhone 12 mini",
    "value": "Apple iPhone 12 mini"
  },
  {
    "name": "Apple Vunene’s iPhone",
    "label": "Apple Vunene’s iPhone",
    "value": "Apple Vunene’s iPhone"
  },
  {
    "name": "incar Z103",
    "label": "incar Z103",
    "value": "incar Z103"
  },
  {
    "name": "Meizu meizu C9 pro",
    "label": "Meizu meizu C9 pro",
    "value": "Meizu meizu C9 pro"
  },
  {
    "name": "Xiaomi MI CC 9",
    "label": "Xiaomi MI CC 9",
    "value": "Xiaomi MI CC 9"
  },
  {
    "name": "AAA T100",
    "label": "AAA T100",
    "value": "AAA T100"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO B1S",
    "label": "TECNO MOBILE LIMITED TECNO B1S",
    "value": "TECNO MOBILE LIMITED TECNO B1S"
  },
  {
    "name": "WIKO View",
    "label": "WIKO View",
    "value": "WIKO View"
  },
  {
    "name": "OPPO PEXM00",
    "label": "OPPO PEXM00",
    "value": "OPPO PEXM00"
  },
  {
    "name": "samsung SM-T385M",
    "label": "samsung SM-T385M",
    "value": "samsung SM-T385M"
  },
  {
    "name": "MOBIWIRE A50UNS",
    "label": "MOBIWIRE A50UNS",
    "value": "MOBIWIRE A50UNS"
  },
  {
    "name": "HUAWEI BND-L24",
    "label": "HUAWEI BND-L24",
    "value": "HUAWEI BND-L24"
  },
  {
    "name": "Apple Mphe’s iPhone ",
    "label": "Apple Mphe’s iPhone ",
    "value": "Apple Mphe’s iPhone "
  },
  {
    "name": "iPad (2) iPad",
    "label": "iPad (2) iPad",
    "value": "iPad (2) iPad"
  },
  {
    "name": "samsung SM-A102W",
    "label": "samsung SM-A102W",
    "value": "samsung SM-A102W"
  },
  {
    "name": "ZUUM STELLAR P5",
    "label": "ZUUM STELLAR P5",
    "value": "ZUUM STELLAR P5"
  },
  {
    "name": "Bmobile B50Pro",
    "label": "Bmobile B50Pro",
    "value": "Bmobile B50Pro"
  },
  {
    "name": "ZTE ZTE A2322G",
    "label": "ZTE ZTE A2322G",
    "value": "ZTE ZTE A2322G"
  },
  {
    "name": "incar BTOUCH7_PLUS",
    "label": "incar BTOUCH7_PLUS",
    "value": "incar BTOUCH7_PLUS"
  },
  {
    "name": "motorola moto g72",
    "label": "motorola moto g72",
    "value": "motorola moto g72"
  },
  {
    "name": "motorola moto e5 go",
    "label": "motorola moto e5 go",
    "value": "motorola moto e5 go"
  },
  {
    "name": "TCL 6127A",
    "label": "TCL 6127A",
    "value": "TCL 6127A"
  },
  {
    "name": "Apple Nqobile’s iPhone",
    "label": "Apple Nqobile’s iPhone",
    "value": "Apple Nqobile’s iPhone"
  },
  {
    "name": "Apple Kristal Pekolj’s iPhone",
    "label": "Apple Kristal Pekolj’s iPhone",
    "value": "Apple Kristal Pekolj’s iPhone"
  },
  {
    "name": "TCL 6125H_EEA",
    "label": "TCL 6125H_EEA",
    "value": "TCL 6125H_EEA"
  },
  {
    "name": "BQru BQ-6022G",
    "label": "BQru BQ-6022G",
    "value": "BQru BQ-6022G"
  },
  {
    "name": "TP-Link Neffos C7s",
    "label": "TP-Link Neffos C7s",
    "value": "TP-Link Neffos C7s"
  },
  {
    "name": "Meizu Meizu M8c",
    "label": "Meizu Meizu M8c",
    "value": "Meizu Meizu M8c"
  },
  {
    "name": "vivo V2031_21",
    "label": "vivo V2031_21",
    "value": "vivo V2031_21"
  },
  {
    "name": "LENOVO Lenovo TB-X704L",
    "label": "LENOVO Lenovo TB-X704L",
    "value": "LENOVO Lenovo TB-X704L"
  },
  {
    "name": "Blackview A60Pro",
    "label": "Blackview A60Pro",
    "value": "Blackview A60Pro"
  },
  {
    "name": "OnePlus A5010",
    "label": "OnePlus A5010",
    "value": "OnePlus A5010"
  },
  {
    "name": "Apple Junsik의 iPhone",
    "label": "Apple Junsik의 iPhone",
    "value": "Apple Junsik의 iPhone"
  },
  {
    "name": "Apple Gdxvgcf",
    "label": "Apple Gdxvgcf",
    "value": "Apple Gdxvgcf"
  },
  {
    "name": "Apple Song iphone",
    "label": "Apple Song iphone",
    "value": "Apple Song iphone"
  },
  {
    "name": "Apple Maselo’s iPhone",
    "label": "Apple Maselo’s iPhone",
    "value": "Apple Maselo’s iPhone"
  },
  {
    "name": "samsung SM-J510L",
    "label": "samsung SM-J510L",
    "value": "samsung SM-J510L"
  },
  {
    "name": "OPPO PEMT00",
    "label": "OPPO PEMT00",
    "value": "OPPO PEMT00"
  },
  {
    "name": "Xiaomi 2106118C",
    "label": "Xiaomi 2106118C",
    "value": "Xiaomi 2106118C"
  },
  {
    "name": "samsung SM-A7100",
    "label": "samsung SM-A7100",
    "value": "samsung SM-A7100"
  },
  {
    "name": "samsung SM-A146U",
    "label": "samsung SM-A146U",
    "value": "samsung SM-A146U"
  },
  {
    "name": "LAVA T81N",
    "label": "LAVA T81N",
    "value": "LAVA T81N"
  },
  {
    "name": "samsung SM-A146P",
    "label": "samsung SM-A146P",
    "value": "samsung SM-A146P"
  },
  {
    "name": "KYOCERA S4-KC",
    "label": "KYOCERA S4-KC",
    "value": "KYOCERA S4-KC"
  },
  {
    "name": "Apple Noluthando’s iPhone",
    "label": "Apple Noluthando’s iPhone",
    "value": "Apple Noluthando’s iPhone"
  },
  {
    "name": "Apple Jonathan Hung’s iPhone",
    "label": "Apple Jonathan Hung’s iPhone",
    "value": "Apple Jonathan Hung’s iPhone"
  },
  {
    "name": "Apple Farseen",
    "label": "Apple Farseen",
    "value": "Apple Farseen"
  },
  {
    "name": "Apple Nicole’s iPhone",
    "label": "Apple Nicole’s iPhone",
    "value": "Apple Nicole’s iPhone"
  },
  {
    "name": "incar B38",
    "label": "incar B38",
    "value": "incar B38"
  },
  {
    "name": "HMD Global Nokia 2 V",
    "label": "HMD Global Nokia 2 V",
    "value": "HMD Global Nokia 2 V"
  },
  {
    "name": "VOTO V9",
    "label": "VOTO V9",
    "value": "VOTO V9"
  },
  {
    "name": "SPURT K300",
    "label": "SPURT K300",
    "value": "SPURT K300"
  },
  {
    "name": "HUAWEI ARS-AL00",
    "label": "HUAWEI ARS-AL00",
    "value": "HUAWEI ARS-AL00"
  },
  {
    "name": "Apple Siphosihle’s iPhone",
    "label": "Apple Siphosihle’s iPhone",
    "value": "Apple Siphosihle’s iPhone"
  },
  {
    "name": "samsung SM-C7108",
    "label": "samsung SM-C7108",
    "value": "samsung SM-C7108"
  },
  {
    "name": "samsung SAMSUNG-SM-T377A",
    "label": "samsung SAMSUNG-SM-T377A",
    "value": "samsung SAMSUNG-SM-T377A"
  },
  {
    "name": "Allwinner Transpeed_H616",
    "label": "Allwinner Transpeed_H616",
    "value": "Allwinner Transpeed_H616"
  },
  {
    "name": "Gigaset GS370",
    "label": "Gigaset GS370",
    "value": "Gigaset GS370"
  },
  {
    "name": "samsung SM-A6050",
    "label": "samsung SM-A6050",
    "value": "samsung SM-A6050"
  },
  {
    "name": "samsung SM-C7000",
    "label": "samsung SM-C7000",
    "value": "samsung SM-C7000"
  },
  {
    "name": "Teclast P80X_EEA",
    "label": "Teclast P80X_EEA",
    "value": "Teclast P80X_EEA"
  },
  {
    "name": "Nubia Z17",
    "label": "Nubia Z17",
    "value": "Nubia Z17"
  },
  {
    "name": "Xiaomi Redmi Note 8 (2021)",
    "label": "Xiaomi Redmi Note 8 (2021)",
    "value": "Xiaomi Redmi Note 8 (2021)"
  },
  {
    "name": "OPPO OPPO CPH1723",
    "label": "OPPO OPPO CPH1723",
    "value": "OPPO OPPO CPH1723"
  },
  {
    "name": "Hisense Hisense E50i",
    "label": "Hisense Hisense E50i",
    "value": "Hisense Hisense E50i"
  },
  {
    "name": "ALLVIEW X8_Soul_Pro",
    "label": "ALLVIEW X8_Soul_Pro",
    "value": "ALLVIEW X8_Soul_Pro"
  },
  {
    "name": "vivo V2217A",
    "label": "vivo V2217A",
    "value": "vivo V2217A"
  },
  {
    "name": "TCL 4188R",
    "label": "TCL 4188R",
    "value": "TCL 4188R"
  },
  {
    "name": "samsung SM-G986W",
    "label": "samsung SM-G986W",
    "value": "samsung SM-G986W"
  },
  {
    "name": "Famous_Fones Famous_TAB_10",
    "label": "Famous_Fones Famous_TAB_10",
    "value": "Famous_Fones Famous_TAB_10"
  },
  {
    "name": "LGE LGM-G600K",
    "label": "LGE LGM-G600K",
    "value": "LGE LGM-G600K"
  },
  {
    "name": "ADVAN 5063",
    "label": "ADVAN 5063",
    "value": "ADVAN 5063"
  },
  {
    "name": "ZTE MO-01K",
    "label": "ZTE MO-01K",
    "value": "ZTE MO-01K"
  },
  {
    "name": "TCL 5030E",
    "label": "TCL 5030E",
    "value": "TCL 5030E"
  },
  {
    "name": "nubia NX612J",
    "label": "nubia NX612J",
    "value": "nubia NX612J"
  },
  {
    "name": "realme RMX3560",
    "label": "realme RMX3560",
    "value": "realme RMX3560"
  },
  {
    "name": "Apple Lachlans iPhone",
    "label": "Apple Lachlans iPhone",
    "value": "Apple Lachlans iPhone"
  },
  {
    "name": "Apple Tammy’s iPhone",
    "label": "Apple Tammy’s iPhone",
    "value": "Apple Tammy’s iPhone"
  },
  {
    "name": "Apple Onkgopotse’s iPhone",
    "label": "Apple Onkgopotse’s iPhone",
    "value": "Apple Onkgopotse’s iPhone"
  },
  {
    "name": "General Mobile GM 5 d",
    "label": "General Mobile GM 5 d",
    "value": "General Mobile GM 5 d"
  },
  {
    "name": "SKYWORTH M100",
    "label": "SKYWORTH M100",
    "value": "SKYWORTH M100"
  },
  {
    "name": "HUAWEI AGS3-W09",
    "label": "HUAWEI AGS3-W09",
    "value": "HUAWEI AGS3-W09"
  },
  {
    "name": "BLU Studio G4",
    "label": "BLU Studio G4",
    "value": "BLU Studio G4"
  },
  {
    "name": "samsung SM-A600T1",
    "label": "samsung SM-A600T1",
    "value": "samsung SM-A600T1"
  },
  {
    "name": "WALTON Primo H8",
    "label": "WALTON Primo H8",
    "value": "WALTON Primo H8"
  },
  {
    "name": "HUAWEI BAL-L49",
    "label": "HUAWEI BAL-L49",
    "value": "HUAWEI BAL-L49"
  },
  {
    "name": "Mintt ULTRAMINTT A6",
    "label": "Mintt ULTRAMINTT A6",
    "value": "Mintt ULTRAMINTT A6"
  },
  {
    "name": "DIXON TS-M704F-1",
    "label": "DIXON TS-M704F-1",
    "value": "DIXON TS-M704F-1"
  },
  {
    "name": "HOTWAV M6",
    "label": "HOTWAV M6",
    "value": "HOTWAV M6"
  },
  {
    "name": "Hisense Hisense Infinity H30 Lite",
    "label": "Hisense Hisense Infinity H30 Lite",
    "value": "Hisense Hisense Infinity H30 Lite"
  },
  {
    "name": "along Typhoon_C6",
    "label": "along Typhoon_C6",
    "value": "along Typhoon_C6"
  },
  {
    "name": "Sky Devices Elite N55",
    "label": "Sky Devices Elite N55",
    "value": "Sky Devices Elite N55"
  },
  {
    "name": "BLU M8L PLUS",
    "label": "BLU M8L PLUS",
    "value": "BLU M8L PLUS"
  },
  {
    "name": "Southern Telecom ST1020",
    "label": "Southern Telecom ST1020",
    "value": "Southern Telecom ST1020"
  },
  {
    "name": "Xiaomi Redmi 4 Prime",
    "label": "Xiaomi Redmi 4 Prime",
    "value": "Xiaomi Redmi 4 Prime"
  },
  {
    "name": "OnePlus CPH2389",
    "label": "OnePlus CPH2389",
    "value": "OnePlus CPH2389"
  },
  {
    "name": "FISE L55A",
    "label": "FISE L55A",
    "value": "FISE L55A"
  },
  {
    "name": "Apple Gracy’s iPhone",
    "label": "Apple Gracy’s iPhone",
    "value": "Apple Gracy’s iPhone"
  },
  {
    "name": "Apple Nomasonto’s iPhone",
    "label": "Apple Nomasonto’s iPhone",
    "value": "Apple Nomasonto’s iPhone"
  },
  {
    "name": "TCL 4087U_RU",
    "label": "TCL 4087U_RU",
    "value": "TCL 4087U_RU"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X655D",
    "label": "INFINIX MOBILITY LIMITED Infinix X655D",
    "value": "INFINIX MOBILITY LIMITED Infinix X655D"
  },
  {
    "name": "rockchip HK1 MINI",
    "label": "rockchip HK1 MINI",
    "value": "rockchip HK1 MINI"
  },
  {
    "name": "Apple C-IX",
    "label": "Apple C-IX",
    "value": "Apple C-IX"
  },
  {
    "name": "Apple berfin’s iPhone",
    "label": "Apple berfin’s iPhone",
    "value": "Apple berfin’s iPhone"
  },
  {
    "name": "Apple Jaeil의 iPhone",
    "label": "Apple Jaeil의 iPhone",
    "value": "Apple Jaeil의 iPhone"
  },
  {
    "name": "MOBIWIRE S12",
    "label": "MOBIWIRE S12",
    "value": "MOBIWIRE S12"
  },
  {
    "name": "Apple Kishan’s iPhone",
    "label": "Apple Kishan’s iPhone",
    "value": "Apple Kishan’s iPhone"
  },
  {
    "name": "ZTE P652 Pro",
    "label": "ZTE P652 Pro",
    "value": "ZTE P652 Pro"
  },
  {
    "name": "SHARP 605SH",
    "label": "SHARP 605SH",
    "value": "SHARP 605SH"
  },
  {
    "name": "Xiaomi Redmi MT6765 G  Series",
    "label": "Xiaomi Redmi MT6765 G  Series",
    "value": "Xiaomi Redmi MT6765 G  Series"
  },
  {
    "name": "samsung SM-G600S",
    "label": "samsung SM-G600S",
    "value": "samsung SM-G600S"
  },
  {
    "name": "samsung SM-J337W",
    "label": "samsung SM-J337W",
    "value": "samsung SM-J337W"
  },
  {
    "name": "xiaomi Redmi 6A",
    "label": "xiaomi Redmi 6A",
    "value": "xiaomi Redmi 6A"
  },
  {
    "name": "incar E710",
    "label": "incar E710",
    "value": "incar E710"
  },
  {
    "name": "wheatek BV6300Pro",
    "label": "wheatek BV6300Pro",
    "value": "wheatek BV6300Pro"
  },
  {
    "name": "HTC HTC U Ultra",
    "label": "HTC HTC U Ultra",
    "value": "HTC HTC U Ultra"
  },
  {
    "name": "Cherry_Mobile Flare S8 Lite",
    "label": "Cherry_Mobile Flare S8 Lite",
    "value": "Cherry_Mobile Flare S8 Lite"
  },
  {
    "name": "Ulefone Note 7T",
    "label": "Ulefone Note 7T",
    "value": "Ulefone Note 7T"
  },
  {
    "name": "vivo V2166BA",
    "label": "vivo V2166BA",
    "value": "vivo V2166BA"
  },
  {
    "name": "samsung SM-A115AP",
    "label": "samsung SM-A115AP",
    "value": "samsung SM-A115AP"
  },
  {
    "name": "Apple iPhone God",
    "label": "Apple iPhone God",
    "value": "Apple iPhone God"
  },
  {
    "name": "Apple iPhone XS",
    "label": "Apple iPhone XS",
    "value": "Apple iPhone XS"
  },
  {
    "name": "Apple Ms Dukwe",
    "label": "Apple Ms Dukwe",
    "value": "Apple Ms Dukwe"
  },
  {
    "name": "ITEL MOBILE LIMITED itel W4001P",
    "label": "ITEL MOBILE LIMITED itel W4001P",
    "value": "ITEL MOBILE LIMITED itel W4001P"
  },
  {
    "name": "HUAWEI PRA-TL10",
    "label": "HUAWEI PRA-TL10",
    "value": "HUAWEI PRA-TL10"
  },
  {
    "name": "ENERGIZER PowerMaxP600S",
    "label": "ENERGIZER PowerMaxP600S",
    "value": "ENERGIZER PowerMaxP600S"
  },
  {
    "name": "BQru BQ-6631G",
    "label": "BQru BQ-6631G",
    "value": "BQru BQ-6631G"
  },
  {
    "name": "CUBOT P50",
    "label": "CUBOT P50",
    "value": "CUBOT P50"
  },
  {
    "name": "ZTE BLADE A530",
    "label": "ZTE BLADE A530",
    "value": "ZTE BLADE A530"
  },
  {
    "name": "Apple Samsung S20",
    "label": "Apple Samsung S20",
    "value": "Apple Samsung S20"
  },
  {
    "name": "Apple shim의 iPhone",
    "label": "Apple shim의 iPhone",
    "value": "Apple shim의 iPhone"
  },
  {
    "name": "Apple 명인잉",
    "label": "Apple 명인잉",
    "value": "Apple 명인잉"
  },
  {
    "name": "Apple 강동욱의 iPhone12",
    "label": "Apple 강동욱의 iPhone12",
    "value": "Apple 강동욱의 iPhone12"
  },
  {
    "name": "asus Asus Zenfone Max Pro M2",
    "label": "asus Asus Zenfone Max Pro M2",
    "value": "asus Asus Zenfone Max Pro M2"
  },
  {
    "name": "HXY MP08",
    "label": "HXY MP08",
    "value": "HXY MP08"
  },
  {
    "name": "Xiaomi 22041219PI",
    "label": "Xiaomi 22041219PI",
    "value": "Xiaomi 22041219PI"
  },
  {
    "name": "Danew Konnect_608",
    "label": "Danew Konnect_608",
    "value": "Danew Konnect_608"
  },
  {
    "name": "Apple Pako’s iPhone",
    "label": "Apple Pako’s iPhone",
    "value": "Apple Pako’s iPhone"
  },
  {
    "name": "Amlogic TX9",
    "label": "Amlogic TX9",
    "value": "Amlogic TX9"
  },
  {
    "name": "F2 I8_PRO",
    "label": "F2 I8_PRO",
    "value": "F2 I8_PRO"
  },
  {
    "name": "LENOVO Lenovo TB-X304F",
    "label": "LENOVO Lenovo TB-X304F",
    "value": "LENOVO Lenovo TB-X304F"
  },
  {
    "name": "Apple Lilyyyy",
    "label": "Apple Lilyyyy",
    "value": "Apple Lilyyyy"
  },
  {
    "name": "INOI INOI 2 Lite 2021",
    "label": "INOI INOI 2 Lite 2021",
    "value": "INOI INOI 2 Lite 2021"
  },
  {
    "name": "TCL 6156H",
    "label": "TCL 6156H",
    "value": "TCL 6156H"
  },
  {
    "name": "IRIS IS6Plus",
    "label": "IRIS IS6Plus",
    "value": "IRIS IS6Plus"
  },
  {
    "name": "WIKO W-K560-TVM1G",
    "label": "WIKO W-K560-TVM1G",
    "value": "WIKO W-K560-TVM1G"
  },
  {
    "name": "TCL 9032W",
    "label": "TCL 9032W",
    "value": "TCL 9032W"
  },
  {
    "name": "PCD P50",
    "label": "PCD P50",
    "value": "PCD P50"
  },
  {
    "name": "Sony XQ-CT72",
    "label": "Sony XQ-CT72",
    "value": "Sony XQ-CT72"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KG5h",
    "label": "TECNO MOBILE LIMITED TECNO KG5h",
    "value": "TECNO MOBILE LIMITED TECNO KG5h"
  },
  {
    "name": "RT project GLAM",
    "label": "RT project GLAM",
    "value": "RT project GLAM"
  },
  {
    "name": "BLU G50 Mega 2022",
    "label": "BLU G50 Mega 2022",
    "value": "BLU G50 Mega 2022"
  },
  {
    "name": "vivo V2199A",
    "label": "vivo V2199A",
    "value": "vivo V2199A"
  },
  {
    "name": "Joyar LRNS10GIL0",
    "label": "Joyar LRNS10GIL0",
    "value": "Joyar LRNS10GIL0"
  },
  {
    "name": "T12 T12",
    "label": "T12 T12",
    "value": "T12 T12"
  },
  {
    "name": "Great Platinum_B5",
    "label": "Great Platinum_B5",
    "value": "Great Platinum_B5"
  },
  {
    "name": "OPPO PFJM10",
    "label": "OPPO PFJM10",
    "value": "OPPO PFJM10"
  },
  {
    "name": "samsung SM-T555",
    "label": "samsung SM-T555",
    "value": "samsung SM-T555"
  },
  {
    "name": "UMIDIGI BISON GT",
    "label": "UMIDIGI BISON GT",
    "value": "UMIDIGI BISON GT"
  },
  {
    "name": "Apple Olebogeng’s iPhone",
    "label": "Apple Olebogeng’s iPhone",
    "value": "Apple Olebogeng’s iPhone"
  },
  {
    "name": "vivo vivo 1725",
    "label": "vivo vivo 1725",
    "value": "vivo vivo 1725"
  },
  {
    "name": "Philips Xenium S266",
    "label": "Philips Xenium S266",
    "value": "Philips Xenium S266"
  },
  {
    "name": "LGE LM-X410.FGN",
    "label": "LGE LM-X410.FGN",
    "value": "LGE LM-X410.FGN"
  },
  {
    "name": "Xiaomi 22011211C",
    "label": "Xiaomi 22011211C",
    "value": "Xiaomi 22011211C"
  },
  {
    "name": "Apple Rica’s Iphone",
    "label": "Apple Rica’s Iphone",
    "value": "Apple Rica’s Iphone"
  },
  {
    "name": "FISE R10",
    "label": "FISE R10",
    "value": "FISE R10"
  },
  {
    "name": "LGE LM-Q620",
    "label": "LGE LM-Q620",
    "value": "LGE LM-Q620"
  },
  {
    "name": "LGE LM-X510S",
    "label": "LGE LM-X510S",
    "value": "LGE LM-X510S"
  },
  {
    "name": "HYUNDAI E554",
    "label": "HYUNDAI E554",
    "value": "HYUNDAI E554"
  },
  {
    "name": "hena K105",
    "label": "hena K105",
    "value": "hena K105"
  },
  {
    "name": "samsung SM-F936U1",
    "label": "samsung SM-F936U1",
    "value": "samsung SM-F936U1"
  },
  {
    "name": "MyPhone myT6 DTV",
    "label": "MyPhone myT6 DTV",
    "value": "MyPhone myT6 DTV"
  },
  {
    "name": "LAVA LAVA LH9931",
    "label": "LAVA LAVA LH9931",
    "value": "LAVA LAVA LH9931"
  },
  {
    "name": "Apple 박진영의 iPhone",
    "label": "Apple 박진영의 iPhone",
    "value": "Apple 박진영의 iPhone"
  },
  {
    "name": "Apple iPhone 13 Pro",
    "label": "Apple iPhone 13 Pro",
    "value": "Apple iPhone 13 Pro"
  },
  {
    "name": "Apple Cherise iphone",
    "label": "Apple Cherise iphone",
    "value": "Apple Cherise iphone"
  },
  {
    "name": "BQru BQ-5707G",
    "label": "BQru BQ-5707G",
    "value": "BQru BQ-5707G"
  },
  {
    "name": "Google strongbad",
    "label": "Google strongbad",
    "value": "Google strongbad"
  },
  {
    "name": "TCL 5002H",
    "label": "TCL 5002H",
    "value": "TCL 5002H"
  },
  {
    "name": "Apple Benias Amos",
    "label": "Apple Benias Amos",
    "value": "Apple Benias Amos"
  },
  {
    "name": "Hisense Hisense E50 Lite 64GB",
    "label": "Hisense Hisense E50 Lite 64GB",
    "value": "Hisense Hisense E50 Lite 64GB"
  },
  {
    "name": "TCL 5006A",
    "label": "TCL 5006A",
    "value": "TCL 5006A"
  },
  {
    "name": "Blaupunkt SF04 4G",
    "label": "Blaupunkt SF04 4G",
    "value": "Blaupunkt SF04 4G"
  },
  {
    "name": "EPIKONE X610",
    "label": "EPIKONE X610",
    "value": "EPIKONE X610"
  },
  {
    "name": "HTC HTC Desire 21 pro 5G",
    "label": "HTC HTC Desire 21 pro 5G",
    "value": "HTC HTC Desire 21 pro 5G"
  },
  {
    "name": "HUAWEI HLK-L42",
    "label": "HUAWEI HLK-L42",
    "value": "HUAWEI HLK-L42"
  },
  {
    "name": "Cherrymobile Aqua SV",
    "label": "Cherrymobile Aqua SV",
    "value": "Cherrymobile Aqua SV"
  },
  {
    "name": "Maxwest Astro 8R",
    "label": "Maxwest Astro 8R",
    "value": "Maxwest Astro 8R"
  },
  {
    "name": "Bmobile BL40_MV12",
    "label": "Bmobile BL40_MV12",
    "value": "Bmobile BL40_MV12"
  },
  {
    "name": "UMAX 8C_LTE 8C_LTE",
    "label": "UMAX 8C_LTE 8C_LTE",
    "value": "UMAX 8C_LTE 8C_LTE"
  },
  {
    "name": "Apple Xs",
    "label": "Apple Xs",
    "value": "Apple Xs"
  },
  {
    "name": "Apple Karabo",
    "label": "Apple Karabo",
    "value": "Apple Karabo"
  },
  {
    "name": "Apple Jai",
    "label": "Apple Jai",
    "value": "Apple Jai"
  },
  {
    "name": "Apple Leila",
    "label": "Apple Leila",
    "value": "Apple Leila"
  },
  {
    "name": "Apple 이영은의 iPhone",
    "label": "Apple 이영은의 iPhone",
    "value": "Apple 이영은의 iPhone"
  },
  {
    "name": "LENOVO Lenovo TB-X304X",
    "label": "LENOVO Lenovo TB-X304X",
    "value": "LENOVO Lenovo TB-X304X"
  },
  {
    "name": "INOI INOI 2 2021",
    "label": "INOI INOI 2 2021",
    "value": "INOI INOI 2 2021"
  },
  {
    "name": "Win M5",
    "label": "Win M5",
    "value": "Win M5"
  },
  {
    "name": "ZTE ZTE BLADE V0920",
    "label": "ZTE ZTE BLADE V0920",
    "value": "ZTE ZTE BLADE V0920"
  },
  {
    "name": "bq Aquaris V Plus",
    "label": "bq Aquaris V Plus",
    "value": "bq Aquaris V Plus"
  },
  {
    "name": "TP-Link Neffos_C7",
    "label": "TP-Link Neffos_C7",
    "value": "TP-Link Neffos_C7"
  },
  {
    "name": "vivo V2025A",
    "label": "vivo V2025A",
    "value": "vivo V2025A"
  },
  {
    "name": "A-gold BV9700Pro",
    "label": "A-gold BV9700Pro",
    "value": "A-gold BV9700Pro"
  },
  {
    "name": "OPPO PBCT10",
    "label": "OPPO PBCT10",
    "value": "OPPO PBCT10"
  },
  {
    "name": "M4 pro M4 pro",
    "label": "M4 pro M4 pro",
    "value": "M4 pro M4 pro"
  },
  {
    "name": "CUBOT QUEST",
    "label": "CUBOT QUEST",
    "value": "CUBOT QUEST"
  },
  {
    "name": "MAXWEST Astro 5 PRO",
    "label": "MAXWEST Astro 5 PRO",
    "value": "MAXWEST Astro 5 PRO"
  },
  {
    "name": "WIKO ROBBY2",
    "label": "WIKO ROBBY2",
    "value": "WIKO ROBBY2"
  },
  {
    "name": "TCL 5131G",
    "label": "TCL 5131G",
    "value": "TCL 5131G"
  },
  {
    "name": "CUBOT P60",
    "label": "CUBOT P60",
    "value": "CUBOT P60"
  },
  {
    "name": "WHOOP TAB-8US2",
    "label": "WHOOP TAB-8US2",
    "value": "WHOOP TAB-8US2"
  },
  {
    "name": "vivo V2061A",
    "label": "vivo V2061A",
    "value": "vivo V2061A"
  },
  {
    "name": "LeMobile X526",
    "label": "LeMobile X526",
    "value": "LeMobile X526"
  },
  {
    "name": "G-TiDE G-TiDE_H1",
    "label": "G-TiDE G-TiDE_H1",
    "value": "G-TiDE G-TiDE_H1"
  },
  {
    "name": "samsung SM-T387W",
    "label": "samsung SM-T387W",
    "value": "samsung SM-T387W"
  },
  {
    "name": "LGE LGUS997",
    "label": "LGE LGUS997",
    "value": "LGE LGUS997"
  },
  {
    "name": "TCL 9132S",
    "label": "TCL 9132S",
    "value": "TCL 9132S"
  },
  {
    "name": "LGE LML414DL",
    "label": "LGE LML414DL",
    "value": "LGE LML414DL"
  },
  {
    "name": "realme RMX3142",
    "label": "realme RMX3142",
    "value": "realme RMX3142"
  },
  {
    "name": "ZTE Z855",
    "label": "ZTE Z855",
    "value": "ZTE Z855"
  },
  {
    "name": "TCL 5024D_RU",
    "label": "TCL 5024D_RU",
    "value": "TCL 5024D_RU"
  },
  {
    "name": "OPPO PECM30",
    "label": "OPPO PECM30",
    "value": "OPPO PECM30"
  },
  {
    "name": "Apple Jungsoo iPhone",
    "label": "Apple Jungsoo iPhone",
    "value": "Apple Jungsoo iPhone"
  },
  {
    "name": "10or E",
    "label": "10or E",
    "value": "10or E"
  },
  {
    "name": "WIKO W-K610-TVM",
    "label": "WIKO W-K610-TVM",
    "value": "WIKO W-K610-TVM"
  },
  {
    "name": "SHARP S3-SH",
    "label": "SHARP S3-SH",
    "value": "SHARP S3-SH"
  },
  {
    "name": "ZUUM AURA PRO JLO",
    "label": "ZUUM AURA PRO JLO",
    "value": "ZUUM AURA PRO JLO"
  },
  {
    "name": "Mobicel FEVER_PLUS_H",
    "label": "Mobicel FEVER_PLUS_H",
    "value": "Mobicel FEVER_PLUS_H"
  },
  {
    "name": "BQru BQ-7083G",
    "label": "BQru BQ-7083G",
    "value": "BQru BQ-7083G"
  },
  {
    "name": "vivo V1821A",
    "label": "vivo V1821A",
    "value": "vivo V1821A"
  },
  {
    "name": "LeMobile X52X",
    "label": "LeMobile X52X",
    "value": "LeMobile X52X"
  },
  {
    "name": "TCL 5061U",
    "label": "TCL 5061U",
    "value": "TCL 5061U"
  },
  {
    "name": "samsung SM-G930VL",
    "label": "samsung SM-G930VL",
    "value": "samsung SM-G930VL"
  },
  {
    "name": "TCL 8068_EEA",
    "label": "TCL 8068_EEA",
    "value": "TCL 8068_EEA"
  },
  {
    "name": "KRIP K55g",
    "label": "KRIP K55g",
    "value": "KRIP K55g"
  },
  {
    "name": "Ulefone Note 12P",
    "label": "Ulefone Note 12P",
    "value": "Ulefone Note 12P"
  },
  {
    "name": "Sky_Devices Elite F55",
    "label": "Sky_Devices Elite F55",
    "value": "Sky_Devices Elite F55"
  },
  {
    "name": "ZTE ZTE A2021E",
    "label": "ZTE ZTE A2021E",
    "value": "ZTE ZTE A2021E"
  },
  {
    "name": "HUAWEI KOB2-L09",
    "label": "HUAWEI KOB2-L09",
    "value": "HUAWEI KOB2-L09"
  },
  {
    "name": "samsung SM-T878U",
    "label": "samsung SM-T878U",
    "value": "samsung SM-T878U"
  },
  {
    "name": "HMD Global Nokia XR20",
    "label": "HMD Global Nokia XR20",
    "value": "HMD Global Nokia XR20"
  },
  {
    "name": "TCL 5033D_RU",
    "label": "TCL 5033D_RU",
    "value": "TCL 5033D_RU"
  },
  {
    "name": "samsung SM-P610X",
    "label": "samsung SM-P610X",
    "value": "samsung SM-P610X"
  },
  {
    "name": "Apple Gloriya’s iPhone",
    "label": "Apple Gloriya’s iPhone",
    "value": "Apple Gloriya’s iPhone"
  },
  {
    "name": "Hisense Hisense H40",
    "label": "Hisense Hisense H40",
    "value": "Hisense Hisense H40"
  },
  {
    "name": "BQru BQ-6430L",
    "label": "BQru BQ-6430L",
    "value": "BQru BQ-6430L"
  },
  {
    "name": "Mobicel TANGO_LITE",
    "label": "Mobicel TANGO_LITE",
    "value": "Mobicel TANGO_LITE"
  },
  {
    "name": "realme RMX1927",
    "label": "realme RMX1927",
    "value": "realme RMX1927"
  },
  {
    "name": "HUAWEI WLZ-AN00",
    "label": "HUAWEI WLZ-AN00",
    "value": "HUAWEI WLZ-AN00"
  },
  {
    "name": "realme RMX3478",
    "label": "realme RMX3478",
    "value": "realme RMX3478"
  },
  {
    "name": "deejoy M700",
    "label": "deejoy M700",
    "value": "deejoy M700"
  },
  {
    "name": "NINTENDO Switch",
    "label": "NINTENDO Switch",
    "value": "NINTENDO Switch"
  },
  {
    "name": "OPPO CPH2483",
    "label": "OPPO CPH2483",
    "value": "OPPO CPH2483"
  },
  {
    "name": "alps GL-V2PRO",
    "label": "alps GL-V2PRO",
    "value": "alps GL-V2PRO"
  },
  {
    "name": "vivo V2208",
    "label": "vivo V2208",
    "value": "vivo V2208"
  },
  {
    "name": "HUAWEI PRA-AL00",
    "label": "HUAWEI PRA-AL00",
    "value": "HUAWEI PRA-AL00"
  },
  {
    "name": "LGE LM-Q910",
    "label": "LGE LM-Q910",
    "value": "LGE LM-Q910"
  },
  {
    "name": "TCL T776O",
    "label": "TCL T776O",
    "value": "TCL T776O"
  },
  {
    "name": "Haier P20",
    "label": "Haier P20",
    "value": "Haier P20"
  },
  {
    "name": "vivo vivo Y19",
    "label": "vivo vivo Y19",
    "value": "vivo vivo Y19"
  },
  {
    "name": "ZTE P652",
    "label": "ZTE P652",
    "value": "ZTE P652"
  },
  {
    "name": "wind UNONU_W609",
    "label": "wind UNONU_W609",
    "value": "wind UNONU_W609"
  },
  {
    "name": "TCL 5061U_TR",
    "label": "TCL 5061U_TR",
    "value": "TCL 5061U_TR"
  },
  {
    "name": "ADVAN 6004",
    "label": "ADVAN 6004",
    "value": "ADVAN 6004"
  },
  {
    "name": "carlie’s iPad iPad",
    "label": "carlie’s iPad iPad",
    "value": "carlie’s iPad iPad"
  },
  {
    "name": "itel itel A45",
    "label": "itel itel A45",
    "value": "itel itel A45"
  },
  {
    "name": "LENOVO Lenovo TB-X704F",
    "label": "LENOVO Lenovo TB-X704F",
    "value": "LENOVO Lenovo TB-X704F"
  },
  {
    "name": "Symphony i71",
    "label": "Symphony i71",
    "value": "Symphony i71"
  },
  {
    "name": "Sungworld Tablet_DL_3721",
    "label": "Sungworld Tablet_DL_3721",
    "value": "Sungworld Tablet_DL_3721"
  },
  {
    "name": "WALTON Primo NF5",
    "label": "WALTON Primo NF5",
    "value": "WALTON Primo NF5"
  },
  {
    "name": "samsung SCV49",
    "label": "samsung SCV49",
    "value": "samsung SCV49"
  },
  {
    "name": "Sony F8131",
    "label": "Sony F8131",
    "value": "Sony F8131"
  },
  {
    "name": "samsung SM-C5010",
    "label": "samsung SM-C5010",
    "value": "samsung SM-C5010"
  },
  {
    "name": "reeder P13 Blue Max 128 GB 2022",
    "label": "reeder P13 Blue Max 128 GB 2022",
    "value": "reeder P13 Blue Max 128 GB 2022"
  },
  {
    "name": "CROSSCALL Action-X3",
    "label": "CROSSCALL Action-X3",
    "value": "CROSSCALL Action-X3"
  },
  {
    "name": "incar Hope8 LTE",
    "label": "incar Hope8 LTE",
    "value": "incar Hope8 LTE"
  },
  {
    "name": "samsung SM-S911B",
    "label": "samsung SM-S911B",
    "value": "samsung SM-S911B"
  },
  {
    "name": "samsung SM-P200",
    "label": "samsung SM-P200",
    "value": "samsung SM-P200"
  },
  {
    "name": "ZTE MO-01J",
    "label": "ZTE MO-01J",
    "value": "ZTE MO-01J"
  },
  {
    "name": "LGE LM-Q310N",
    "label": "LGE LM-Q310N",
    "value": "LGE LM-Q310N"
  },
  {
    "name": "incar Tab_A6",
    "label": "incar Tab_A6",
    "value": "incar Tab_A6"
  },
  {
    "name": "INFINIX Infinix X666B",
    "label": "INFINIX Infinix X666B",
    "value": "INFINIX Infinix X666B"
  },
  {
    "name": "OPPO CHP1723",
    "label": "OPPO CHP1723",
    "value": "OPPO CHP1723"
  },
  {
    "name": "Azumi A5B",
    "label": "Azumi A5B",
    "value": "Azumi A5B"
  },
  {
    "name": "Xiaomi 22041216UC",
    "label": "Xiaomi 22041216UC",
    "value": "Xiaomi 22041216UC"
  },
  {
    "name": "HUAWEI SCM-W09",
    "label": "HUAWEI SCM-W09",
    "value": "HUAWEI SCM-W09"
  },
  {
    "name": "TWL RENO4",
    "label": "TWL RENO4",
    "value": "TWL RENO4"
  },
  {
    "name": "Apple 김미정의 iPhone",
    "label": "Apple 김미정의 iPhone",
    "value": "Apple 김미정의 iPhone"
  },
  {
    "name": "Apple 신동열의 iPhone",
    "label": "Apple 신동열의 iPhone",
    "value": "Apple 신동열의 iPhone"
  },
  {
    "name": "Apple Jeremiah’s iPhone",
    "label": "Apple Jeremiah’s iPhone",
    "value": "Apple Jeremiah’s iPhone"
  },
  {
    "name": "M4TEL R2_Plus",
    "label": "M4TEL R2_Plus",
    "value": "M4TEL R2_Plus"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO IN1",
    "label": "TECNO MOBILE LIMITED TECNO IN1",
    "value": "TECNO MOBILE LIMITED TECNO IN1"
  },
  {
    "name": "Sony G3421",
    "label": "Sony G3421",
    "value": "Sony G3421"
  },
  {
    "name": "DEXP A350",
    "label": "DEXP A350",
    "value": "DEXP A350"
  },
  {
    "name": "PANASONIC ELUGA_I7_EE",
    "label": "PANASONIC ELUGA_I7_EE",
    "value": "PANASONIC ELUGA_I7_EE"
  },
  {
    "name": "realme RMX3350",
    "label": "realme RMX3350",
    "value": "realme RMX3350"
  },
  {
    "name": "wheatek P13 Blue Max Lite",
    "label": "wheatek P13 Blue Max Lite",
    "value": "wheatek P13 Blue Max Lite"
  },
  {
    "name": "Qubo SP510",
    "label": "Qubo SP510",
    "value": "Qubo SP510"
  },
  {
    "name": "Apple HYEWON의 iPhone",
    "label": "Apple HYEWON의 iPhone",
    "value": "Apple HYEWON의 iPhone"
  },
  {
    "name": "Apple ‏ iPhone الخاص بـ waleed",
    "label": "Apple ‏ iPhone الخاص بـ waleed",
    "value": "Apple ‏ iPhone الخاص بـ waleed"
  },
  {
    "name": "Apple 김영준의 iPhone",
    "label": "Apple 김영준의 iPhone",
    "value": "Apple 김영준의 iPhone"
  },
  {
    "name": "Apple ' '",
    "label": "Apple ' '",
    "value": "Apple ' '"
  },
  {
    "name": "Apple iPhone XS Max",
    "label": "Apple iPhone XS Max",
    "value": "Apple iPhone XS Max"
  },
  {
    "name": "BQru BQ-6042L",
    "label": "BQru BQ-6042L",
    "value": "BQru BQ-6042L"
  },
  {
    "name": "vivo I2022",
    "label": "vivo I2022",
    "value": "vivo I2022"
  },
  {
    "name": "Cat S62 Pro",
    "label": "Cat S62 Pro",
    "value": "Cat S62 Pro"
  },
  {
    "name": "Motorola moto g(7) plus",
    "label": "Motorola moto g(7) plus",
    "value": "Motorola moto g(7) plus"
  },
  {
    "name": "WIKO W-K610-SUI",
    "label": "WIKO W-K610-SUI",
    "value": "WIKO W-K610-SUI"
  },
  {
    "name": "HOMETECH Alfa7LM",
    "label": "HOMETECH Alfa7LM",
    "value": "HOMETECH Alfa7LM"
  },
  {
    "name": "Xiaomi MIX 2S",
    "label": "Xiaomi MIX 2S",
    "value": "Xiaomi MIX 2S"
  },
  {
    "name": "Xiaomi POCO X3 NFC",
    "label": "Xiaomi POCO X3 NFC",
    "value": "Xiaomi POCO X3 NFC"
  },
  {
    "name": "realme Realme G70 Series",
    "label": "realme Realme G70 Series",
    "value": "realme Realme G70 Series"
  },
  {
    "name": "EastAeon LOGIC_X60_PLUS",
    "label": "EastAeon LOGIC_X60_PLUS",
    "value": "EastAeon LOGIC_X60_PLUS"
  },
  {
    "name": "OnePlus GM1920",
    "label": "OnePlus GM1920",
    "value": "OnePlus GM1920"
  },
  {
    "name": "Apple Ma’Bs IPhone ",
    "label": "Apple Ma’Bs IPhone ",
    "value": "Apple Ma’Bs IPhone "
  },
  {
    "name": "samsung SM-T395N",
    "label": "samsung SM-T395N",
    "value": "samsung SM-T395N"
  },
  {
    "name": "Apple Queen’s iPhone",
    "label": "Apple Queen’s iPhone",
    "value": "Apple Queen’s iPhone"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CB7j",
    "label": "TECNO MOBILE LIMITED TECNO CB7j",
    "value": "TECNO MOBILE LIMITED TECNO CB7j"
  },
  {
    "name": "TCL 5059J",
    "label": "TCL 5059J",
    "value": "TCL 5059J"
  },
  {
    "name": "LGE LM-X410UM",
    "label": "LGE LM-X410UM",
    "value": "LGE LM-X410UM"
  },
  {
    "name": "Polaroid Mobility3G",
    "label": "Polaroid Mobility3G",
    "value": "Polaroid Mobility3G"
  },
  {
    "name": "asus ASUS_AI2202",
    "label": "asus ASUS_AI2202",
    "value": "asus ASUS_AI2202"
  },
  {
    "name": "LGE LM-X210CM",
    "label": "LGE LM-X210CM",
    "value": "LGE LM-X210CM"
  },
  {
    "name": "wheatek iHunt Cyber Wolf 5G",
    "label": "wheatek iHunt Cyber Wolf 5G",
    "value": "wheatek iHunt Cyber Wolf 5G"
  },
  {
    "name": "Blackview Tab 11",
    "label": "Blackview Tab 11",
    "value": "Blackview Tab 11"
  },
  {
    "name": "KYOCERA KYV44",
    "label": "KYOCERA KYV44",
    "value": "KYOCERA KYV44"
  },
  {
    "name": "reeder P13 Blue 2022",
    "label": "reeder P13 Blue 2022",
    "value": "reeder P13 Blue 2022"
  },
  {
    "name": "realme RMX3161",
    "label": "realme RMX3161",
    "value": "realme RMX3161"
  },
  {
    "name": "Opay M1 Lite",
    "label": "Opay M1 Lite",
    "value": "Opay M1 Lite"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A23A",
    "label": "ITEL MOBILE LIMITED itel A23A",
    "value": "ITEL MOBILE LIMITED itel A23A"
  },
  {
    "name": "HUAWEI VIE-AL10",
    "label": "HUAWEI VIE-AL10",
    "value": "HUAWEI VIE-AL10"
  },
  {
    "name": "asus ASUS_I001DC",
    "label": "asus ASUS_I001DC",
    "value": "asus ASUS_I001DC"
  },
  {
    "name": "Apple iPhone (Yan)",
    "label": "Apple iPhone (Yan)",
    "value": "Apple iPhone (Yan)"
  },
  {
    "name": "Apple Jacinta’s iPhone",
    "label": "Apple Jacinta’s iPhone",
    "value": "Apple Jacinta’s iPhone"
  },
  {
    "name": "Apple Dylan’s iPhone",
    "label": "Apple Dylan’s iPhone",
    "value": "Apple Dylan’s iPhone"
  },
  {
    "name": "Apple 정상윤의 iPhone",
    "label": "Apple 정상윤의 iPhone",
    "value": "Apple 정상윤의 iPhone"
  },
  {
    "name": "OPPO PADT00",
    "label": "OPPO PADT00",
    "value": "OPPO PADT00"
  },
  {
    "name": "DUODUOGO P8",
    "label": "DUODUOGO P8",
    "value": "DUODUOGO P8"
  },
  {
    "name": "wheatek WP12",
    "label": "wheatek WP12",
    "value": "wheatek WP12"
  },
  {
    "name": "LGE LM-X510K",
    "label": "LGE LM-X510K",
    "value": "LGE LM-X510K"
  },
  {
    "name": "unknown GM1917",
    "label": "unknown GM1917",
    "value": "unknown GM1917"
  },
  {
    "name": "incar T5-EEA",
    "label": "incar T5-EEA",
    "value": "incar T5-EEA"
  },
  {
    "name": "ZTE ZTE Blade A3 2019RU",
    "label": "ZTE ZTE Blade A3 2019RU",
    "value": "ZTE ZTE Blade A3 2019RU"
  },
  {
    "name": "Hisense Altice S62",
    "label": "Hisense Altice S62",
    "value": "Hisense Altice S62"
  },
  {
    "name": "alps X109",
    "label": "alps X109",
    "value": "alps X109"
  },
  {
    "name": "ZTE Z559DL",
    "label": "ZTE Z559DL",
    "value": "ZTE Z559DL"
  },
  {
    "name": "Apple hojeon",
    "label": "Apple hojeon",
    "value": "Apple hojeon"
  },
  {
    "name": "Apple Jasneet’s iPhone",
    "label": "Apple Jasneet’s iPhone",
    "value": "Apple Jasneet’s iPhone"
  },
  {
    "name": "Vertex G1701",
    "label": "Vertex G1701",
    "value": "Vertex G1701"
  },
  {
    "name": "Hisense HLTE230E",
    "label": "Hisense HLTE230E",
    "value": "Hisense HLTE230E"
  },
  {
    "name": "xiaomi Redmi 9",
    "label": "xiaomi Redmi 9",
    "value": "xiaomi Redmi 9"
  },
  {
    "name": "UNONU UN55L",
    "label": "UNONU UN55L",
    "value": "UNONU UN55L"
  },
  {
    "name": "FUJITSU F-01K",
    "label": "FUJITSU F-01K",
    "value": "FUJITSU F-01K"
  },
  {
    "name": "samsung SM-A600AZ",
    "label": "samsung SM-A600AZ",
    "value": "samsung SM-A600AZ"
  },
  {
    "name": "Maxwest Nitro_5R",
    "label": "Maxwest Nitro_5R",
    "value": "Maxwest Nitro_5R"
  },
  {
    "name": "samsung SM-X906N",
    "label": "samsung SM-X906N",
    "value": "samsung SM-X906N"
  },
  {
    "name": "Honeywell EDA50",
    "label": "Honeywell EDA50",
    "value": "Honeywell EDA50"
  },
  {
    "name": "Apple Lungelo’s iPhone",
    "label": "Apple Lungelo’s iPhone",
    "value": "Apple Lungelo’s iPhone"
  },
  {
    "name": "Hisense Hisense F35",
    "label": "Hisense Hisense F35",
    "value": "Hisense Hisense F35"
  },
  {
    "name": "Sony E6633",
    "label": "Sony E6633",
    "value": "Sony E6633"
  },
  {
    "name": "Hisense Hisense V40s",
    "label": "Hisense Hisense V40s",
    "value": "Hisense Hisense V40s"
  },
  {
    "name": "Sony H4133",
    "label": "Sony H4133",
    "value": "Sony H4133"
  },
  {
    "name": "SHARP SH-01K",
    "label": "SHARP SH-01K",
    "value": "SHARP SH-01K"
  },
  {
    "name": "rockchip H96 Max RK3318",
    "label": "rockchip H96 Max RK3318",
    "value": "rockchip H96 Max RK3318"
  },
  {
    "name": "Fortune Ship M9111",
    "label": "Fortune Ship M9111",
    "value": "Fortune Ship M9111"
  },
  {
    "name": "Ulefone Armor 15",
    "label": "Ulefone Armor 15",
    "value": "Ulefone Armor 15"
  },
  {
    "name": "Hannah’s iPad iPad",
    "label": "Hannah’s iPad iPad",
    "value": "Hannah’s iPad iPad"
  },
  {
    "name": "POINT_OF_VIEW TAB-7232-3G-V2",
    "label": "POINT_OF_VIEW TAB-7232-3G-V2",
    "value": "POINT_OF_VIEW TAB-7232-3G-V2"
  },
  {
    "name": "incar PMT1157_4G_RU",
    "label": "incar PMT1157_4G_RU",
    "value": "incar PMT1157_4G_RU"
  },
  {
    "name": "asus ASUS_X008DB",
    "label": "asus ASUS_X008DB",
    "value": "asus ASUS_X008DB"
  },
  {
    "name": "OPPO PDPM00",
    "label": "OPPO PDPM00",
    "value": "OPPO PDPM00"
  },
  {
    "name": "SPA Condor Electronics Griffe T9",
    "label": "SPA Condor Electronics Griffe T9",
    "value": "SPA Condor Electronics Griffe T9"
  },
  {
    "name": "HYUNDAI L681",
    "label": "HYUNDAI L681",
    "value": "HYUNDAI L681"
  },
  {
    "name": "Welcome S21 Ultra",
    "label": "Welcome S21 Ultra",
    "value": "Welcome S21 Ultra"
  },
  {
    "name": "Hisense Hisense H30 Lite",
    "label": "Hisense Hisense H30 Lite",
    "value": "Hisense Hisense H30 Lite"
  },
  {
    "name": "OUKITEL C22",
    "label": "OUKITEL C22",
    "value": "OUKITEL C22"
  },
  {
    "name": "Droidlogic X96Max_Plus2_T",
    "label": "Droidlogic X96Max_Plus2_T",
    "value": "Droidlogic X96Max_Plus2_T"
  },
  {
    "name": "Foxx Development Inc. L590A",
    "label": "Foxx Development Inc. L590A",
    "value": "Foxx Development Inc. L590A"
  },
  {
    "name": "NOA N10",
    "label": "NOA N10",
    "value": "NOA N10"
  },
  {
    "name": "YEZZ 4E",
    "label": "YEZZ 4E",
    "value": "YEZZ 4E"
  },
  {
    "name": "Apple Mel’s iPhone",
    "label": "Apple Mel’s iPhone",
    "value": "Apple Mel’s iPhone"
  },
  {
    "name": "Apple 송승한의 iPhone",
    "label": "Apple 송승한의 iPhone",
    "value": "Apple 송승한의 iPhone"
  },
  {
    "name": "true S1A",
    "label": "true S1A",
    "value": "true S1A"
  },
  {
    "name": "revoview IMO Q2 Pro",
    "label": "revoview IMO Q2 Pro",
    "value": "revoview IMO Q2 Pro"
  },
  {
    "name": "vivo V1838A",
    "label": "vivo V1838A",
    "value": "vivo V1838A"
  },
  {
    "name": "blackshark SHARK PRS-A0",
    "label": "blackshark SHARK PRS-A0",
    "value": "blackshark SHARK PRS-A0"
  },
  {
    "name": "benco AF9020",
    "label": "benco AF9020",
    "value": "benco AF9020"
  },
  {
    "name": "10or 10or G2",
    "label": "10or 10or G2",
    "value": "10or 10or G2"
  },
  {
    "name": "GIONEE GIONEE F11",
    "label": "GIONEE GIONEE F11",
    "value": "GIONEE GIONEE F11"
  },
  {
    "name": "OnePlus PGZ110",
    "label": "OnePlus PGZ110",
    "value": "OnePlus PGZ110"
  },
  {
    "name": "TCL 8084",
    "label": "TCL 8084",
    "value": "TCL 8084"
  },
  {
    "name": "LT LT_S10_Lite",
    "label": "LT LT_S10_Lite",
    "value": "LT LT_S10_Lite"
  },
  {
    "name": "SHARP SH-02M",
    "label": "SHARP SH-02M",
    "value": "SHARP SH-02M"
  },
  {
    "name": "samsung SC-01L",
    "label": "samsung SC-01L",
    "value": "samsung SC-01L"
  },
  {
    "name": "Sony NW-A100Series",
    "label": "Sony NW-A100Series",
    "value": "Sony NW-A100Series"
  },
  {
    "name": "LENOVO Lenovo TB-8704X",
    "label": "LENOVO Lenovo TB-8704X",
    "value": "LENOVO Lenovo TB-8704X"
  },
  {
    "name": "samsung SCG01",
    "label": "samsung SCG01",
    "value": "samsung SCG01"
  },
  {
    "name": "BLU VIVO GO",
    "label": "BLU VIVO GO",
    "value": "BLU VIVO GO"
  },
  {
    "name": "ZTE ZTE BLADE A0621",
    "label": "ZTE ZTE BLADE A0621",
    "value": "ZTE ZTE BLADE A0621"
  },
  {
    "name": "Hisense Hisense U30 32GB",
    "label": "Hisense Hisense U30 32GB",
    "value": "Hisense Hisense U30 32GB"
  },
  {
    "name": "Win M5plus",
    "label": "Win M5plus",
    "value": "Win M5plus"
  },
  {
    "name": "Huawei Nexus 6P",
    "label": "Huawei Nexus 6P",
    "value": "Huawei Nexus 6P"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO IN2",
    "label": "TECNO MOBILE LIMITED TECNO IN2",
    "value": "TECNO MOBILE LIMITED TECNO IN2"
  },
  {
    "name": "Karbonn Platinum P9 Pro",
    "label": "Karbonn Platinum P9 Pro",
    "value": "Karbonn Platinum P9 Pro"
  },
  {
    "name": "OUKITEL WP10",
    "label": "OUKITEL WP10",
    "value": "OUKITEL WP10"
  },
  {
    "name": "Sky_Devices Elite E55",
    "label": "Sky_Devices Elite E55",
    "value": "Sky_Devices Elite E55"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X573S",
    "label": "INFINIX MOBILITY LIMITED Infinix X573S",
    "value": "INFINIX MOBILITY LIMITED Infinix X573S"
  },
  {
    "name": "WALTON Primo R8",
    "label": "WALTON Primo R8",
    "value": "WALTON Primo R8"
  },
  {
    "name": "EPIKONE K503HD",
    "label": "EPIKONE K503HD",
    "value": "EPIKONE K503HD"
  },
  {
    "name": "mobiistar X1 Notch",
    "label": "mobiistar X1 Notch",
    "value": "mobiistar X1 Notch"
  },
  {
    "name": "alps J52",
    "label": "alps J52",
    "value": "alps J52"
  },
  {
    "name": "ROKiT IO Pro",
    "label": "ROKiT IO Pro",
    "value": "ROKiT IO Pro"
  },
  {
    "name": "SHARP A004SH",
    "label": "SHARP A004SH",
    "value": "SHARP A004SH"
  },
  {
    "name": "Razer Phone 2",
    "label": "Razer Phone 2",
    "value": "Razer Phone 2"
  },
  {
    "name": "HUAWEI DUA-LX9",
    "label": "HUAWEI DUA-LX9",
    "value": "HUAWEI DUA-LX9"
  },
  {
    "name": "emdoor E0114",
    "label": "emdoor E0114",
    "value": "emdoor E0114"
  },
  {
    "name": "realme RMX2173",
    "label": "realme RMX2173",
    "value": "realme RMX2173"
  },
  {
    "name": "RAVOZ RAVOZ Z6 Pro",
    "label": "RAVOZ RAVOZ Z6 Pro",
    "value": "RAVOZ RAVOZ Z6 Pro"
  },
  {
    "name": "Apple Montell’s iPhone",
    "label": "Apple Montell’s iPhone",
    "value": "Apple Montell’s iPhone"
  },
  {
    "name": "UMIDIGI A3X",
    "label": "UMIDIGI A3X",
    "value": "UMIDIGI A3X"
  },
  {
    "name": "samsung SM-T575",
    "label": "samsung SM-T575",
    "value": "samsung SM-T575"
  },
  {
    "name": "ASUS_T00J ASUS_T00J",
    "label": "ASUS_T00J ASUS_T00J",
    "value": "ASUS_T00J ASUS_T00J"
  },
  {
    "name": "incar Plus-7_C777",
    "label": "incar Plus-7_C777",
    "value": "incar Plus-7_C777"
  },
  {
    "name": "A-gold Titan",
    "label": "A-gold Titan",
    "value": "A-gold Titan"
  },
  {
    "name": "Apple Iphone♥️",
    "label": "Apple Iphone♥️",
    "value": "Apple Iphone♥️"
  },
  {
    "name": "deejoy Punos 10",
    "label": "deejoy Punos 10",
    "value": "deejoy Punos 10"
  },
  {
    "name": "mobiistar mobiistar E1",
    "label": "mobiistar mobiistar E1",
    "value": "mobiistar mobiistar E1"
  },
  {
    "name": "Ulefone Armor_6",
    "label": "Ulefone Armor_6",
    "value": "Ulefone Armor_6"
  },
  {
    "name": "Google google Pixel 2",
    "label": "Google google Pixel 2",
    "value": "Google google Pixel 2"
  },
  {
    "name": "vivo V2012A",
    "label": "vivo V2012A",
    "value": "vivo V2012A"
  },
  {
    "name": "ZTE Z5156CC",
    "label": "ZTE Z5156CC",
    "value": "ZTE Z5156CC"
  },
  {
    "name": "HUAWEI JEF-AN20",
    "label": "HUAWEI JEF-AN20",
    "value": "HUAWEI JEF-AN20"
  },
  {
    "name": "Sony XQ-CT54",
    "label": "Sony XQ-CT54",
    "value": "Sony XQ-CT54"
  },
  {
    "name": "GSTONE ACE",
    "label": "GSTONE ACE",
    "value": "GSTONE ACE"
  },
  {
    "name": "HXY RP05",
    "label": "HXY RP05",
    "value": "HXY RP05"
  },
  {
    "name": "OPPO PEHM00",
    "label": "OPPO PEHM00",
    "value": "OPPO PEHM00"
  },
  {
    "name": "samsung SM-J530YM",
    "label": "samsung SM-J530YM",
    "value": "samsung SM-J530YM"
  },
  {
    "name": "TCL 5003G",
    "label": "TCL 5003G",
    "value": "TCL 5003G"
  },
  {
    "name": "KXD A1",
    "label": "KXD A1",
    "value": "KXD A1"
  },
  {
    "name": "samsung SM-G615FU",
    "label": "samsung SM-G615FU",
    "value": "samsung SM-G615FU"
  },
  {
    "name": "Xiaomi 22041211AC",
    "label": "Xiaomi 22041211AC",
    "value": "Xiaomi 22041211AC"
  },
  {
    "name": "ITEL itel P10001L",
    "label": "ITEL itel P10001L",
    "value": "ITEL itel P10001L"
  },
  {
    "name": "HUAWEI ANA-AN00",
    "label": "HUAWEI ANA-AN00",
    "value": "HUAWEI ANA-AN00"
  },
  {
    "name": "DOOGEE S86",
    "label": "DOOGEE S86",
    "value": "DOOGEE S86"
  },
  {
    "name": "samsung SAMSUNG-SM-J327A",
    "label": "samsung SAMSUNG-SM-J327A",
    "value": "samsung SAMSUNG-SM-J327A"
  },
  {
    "name": "OMIX X600 NFC",
    "label": "OMIX X600 NFC",
    "value": "OMIX X600 NFC"
  },
  {
    "name": "WOLKI WS041",
    "label": "WOLKI WS041",
    "value": "WOLKI WS041"
  },
  {
    "name": "OnePlus ONEPLUS A3000",
    "label": "OnePlus ONEPLUS A3000",
    "value": "OnePlus ONEPLUS A3000"
  },
  {
    "name": "Qubo T3G_Q07",
    "label": "Qubo T3G_Q07",
    "value": "Qubo T3G_Q07"
  },
  {
    "name": "Apple iPhone van shirley",
    "label": "Apple iPhone van shirley",
    "value": "Apple iPhone van shirley"
  },
  {
    "name": "vivo vivo 1920_20",
    "label": "vivo vivo 1920_20",
    "value": "vivo vivo 1920_20"
  },
  {
    "name": "TCL Alcatel_5002C",
    "label": "TCL Alcatel_5002C",
    "value": "TCL Alcatel_5002C"
  },
  {
    "name": "Apple mapi",
    "label": "Apple mapi",
    "value": "Apple mapi"
  },
  {
    "name": "OTOT Duubee E1",
    "label": "OTOT Duubee E1",
    "value": "OTOT Duubee E1"
  },
  {
    "name": "HMD Global Nokia 7",
    "label": "HMD Global Nokia 7",
    "value": "HMD Global Nokia 7"
  },
  {
    "name": "HUAWEI EVR-N29",
    "label": "HUAWEI EVR-N29",
    "value": "HUAWEI EVR-N29"
  },
  {
    "name": "Xiaomi 2201116SC",
    "label": "Xiaomi 2201116SC",
    "value": "Xiaomi 2201116SC"
  },
  {
    "name": "AndyOS, Inc. AndyWin",
    "label": "AndyOS, Inc. AndyWin",
    "value": "AndyOS, Inc. AndyWin"
  },
  {
    "name": "incar IRA_W801",
    "label": "incar IRA_W801",
    "value": "incar IRA_W801"
  },
  {
    "name": "samsung SM-J727VPP",
    "label": "samsung SM-J727VPP",
    "value": "samsung SM-J727VPP"
  },
  {
    "name": "Xiaomi Redmi 3",
    "label": "Xiaomi Redmi 3",
    "value": "Xiaomi Redmi 3"
  },
  {
    "name": "HUAWEI JKM-AL00",
    "label": "HUAWEI JKM-AL00",
    "value": "HUAWEI JKM-AL00"
  },
  {
    "name": "Cherry_Mobile Flare S7 Lite",
    "label": "Cherry_Mobile Flare S7 Lite",
    "value": "Cherry_Mobile Flare S7 Lite"
  },
  {
    "name": "along Sunshine_T1_Elite",
    "label": "along Sunshine_T1_Elite",
    "value": "along Sunshine_T1_Elite"
  },
  {
    "name": "OPPO oppo6833",
    "label": "OPPO oppo6833",
    "value": "OPPO oppo6833"
  },
  {
    "name": "DL Tablet_DL_3724",
    "label": "DL Tablet_DL_3724",
    "value": "DL Tablet_DL_3724"
  },
  {
    "name": "Deejoy MID_1085",
    "label": "Deejoy MID_1085",
    "value": "Deejoy MID_1085"
  },
  {
    "name": "TCL 5058Y",
    "label": "TCL 5058Y",
    "value": "TCL 5058Y"
  },
  {
    "name": "OUKITEL K7",
    "label": "OUKITEL K7",
    "value": "OUKITEL K7"
  },
  {
    "name": "Apple 김현창의 iPhone",
    "label": "Apple 김현창의 iPhone",
    "value": "Apple 김현창의 iPhone"
  },
  {
    "name": "TCL 5164D",
    "label": "TCL 5164D",
    "value": "TCL 5164D"
  },
  {
    "name": "SPURT K700",
    "label": "SPURT K700",
    "value": "SPURT K700"
  },
  {
    "name": "Apple Hulia Maria",
    "label": "Apple Hulia Maria",
    "value": "Apple Hulia Maria"
  },
  {
    "name": "LAVA ZX",
    "label": "LAVA ZX",
    "value": "LAVA ZX"
  },
  {
    "name": "Apple 바로나 오창현 강 . 림 .",
    "label": "Apple 바로나 오창현 강 . 림 .",
    "value": "Apple 바로나 오창현 강 . 림 ."
  },
  {
    "name": "Apple Okuhles iPhone",
    "label": "Apple Okuhles iPhone",
    "value": "Apple Okuhles iPhone"
  },
  {
    "name": "Apple Vinx",
    "label": "Apple Vinx",
    "value": "Apple Vinx"
  },
  {
    "name": "HUAWEI LDN-AL00",
    "label": "HUAWEI LDN-AL00",
    "value": "HUAWEI LDN-AL00"
  },
  {
    "name": "ZTE ZTE Blade V10 Vita RU",
    "label": "ZTE ZTE Blade V10 Vita RU",
    "value": "ZTE ZTE Blade V10 Vita RU"
  },
  {
    "name": "Movitel M9108",
    "label": "Movitel M9108",
    "value": "Movitel M9108"
  },
  {
    "name": "HUAWEI LND-AL40",
    "label": "HUAWEI LND-AL40",
    "value": "HUAWEI LND-AL40"
  },
  {
    "name": "Hyundai Hyundai_Eternity_G50",
    "label": "Hyundai Hyundai_Eternity_G50",
    "value": "Hyundai Hyundai_Eternity_G50"
  },
  {
    "name": "OPPO CPH2481",
    "label": "OPPO CPH2481",
    "value": "OPPO CPH2481"
  },
  {
    "name": "ZTE Blade A3 2019",
    "label": "ZTE Blade A3 2019",
    "value": "ZTE Blade A3 2019"
  },
  {
    "name": "nubia NX709J",
    "label": "nubia NX709J",
    "value": "nubia NX709J"
  },
  {
    "name": "Apple14 14Pro Max",
    "label": "Apple14 14Pro Max",
    "value": "Apple14 14Pro Max"
  },
  {
    "name": "ZTE ZTE BLADE A0622",
    "label": "ZTE ZTE BLADE A0622",
    "value": "ZTE ZTE BLADE A0622"
  },
  {
    "name": "Sylvania SLTDVD1024",
    "label": "Sylvania SLTDVD1024",
    "value": "Sylvania SLTDVD1024"
  },
  {
    "name": "Kingcomm Kingcomm C500",
    "label": "Kingcomm Kingcomm C500",
    "value": "Kingcomm Kingcomm C500"
  },
  {
    "name": "HTC HTC U20 5G",
    "label": "HTC HTC U20 5G",
    "value": "HTC HTC U20 5G"
  },
  {
    "name": "Positivo Quantum Mini",
    "label": "Positivo Quantum Mini",
    "value": "Positivo Quantum Mini"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X627V",
    "label": "INFINIX MOBILITY LIMITED Infinix X627V",
    "value": "INFINIX MOBILITY LIMITED Infinix X627V"
  },
  {
    "name": "BLU A30",
    "label": "BLU A30",
    "value": "BLU A30"
  },
  {
    "name": "EVERCOSS U70C",
    "label": "EVERCOSS U70C",
    "value": "EVERCOSS U70C"
  },
  {
    "name": "LGE LM-X440IM",
    "label": "LGE LM-X440IM",
    "value": "LGE LM-X440IM"
  },
  {
    "name": "SHARP SH-03J",
    "label": "SHARP SH-03J",
    "value": "SHARP SH-03J"
  },
  {
    "name": "Apple 누림인데용",
    "label": "Apple 누림인데용",
    "value": "Apple 누림인데용"
  },
  {
    "name": "Apple Franz’s iPhone",
    "label": "Apple Franz’s iPhone",
    "value": "Apple Franz’s iPhone"
  },
  {
    "name": "meizu meizu 17 Pro",
    "label": "meizu meizu 17 Pro",
    "value": "meizu meizu 17 Pro"
  },
  {
    "name": "ACE France BUZZ 2",
    "label": "ACE France BUZZ 2",
    "value": "ACE France BUZZ 2"
  },
  {
    "name": "YEZZ GO1",
    "label": "YEZZ GO1",
    "value": "YEZZ GO1"
  },
  {
    "name": "DEXP H370",
    "label": "DEXP H370",
    "value": "DEXP H370"
  },
  {
    "name": "Deejoy Tablet10_X-View",
    "label": "Deejoy Tablet10_X-View",
    "value": "Deejoy Tablet10_X-View"
  },
  {
    "name": "TCL 5024F",
    "label": "TCL 5024F",
    "value": "TCL 5024F"
  },
  {
    "name": "Xiaomi 22111317I",
    "label": "Xiaomi 22111317I",
    "value": "Xiaomi 22111317I"
  },
  {
    "name": "Zebra Technologies TC21",
    "label": "Zebra Technologies TC21",
    "value": "Zebra Technologies TC21"
  },
  {
    "name": "BENEVE TK_E102",
    "label": "BENEVE TK_E102",
    "value": "BENEVE TK_E102"
  },
  {
    "name": "vivo V2254A",
    "label": "vivo V2254A",
    "value": "vivo V2254A"
  },
  {
    "name": "lenovo TB-X103F",
    "label": "lenovo TB-X103F",
    "value": "lenovo TB-X103F"
  },
  {
    "name": "Masscom Masstel Tab 81",
    "label": "Masscom Masstel Tab 81",
    "value": "Masscom Masstel Tab 81"
  },
  {
    "name": "sanmu X-TIGI_JOY7_MAX",
    "label": "sanmu X-TIGI_JOY7_MAX",
    "value": "sanmu X-TIGI_JOY7_MAX"
  },
  {
    "name": "Apple naman’s iPhone",
    "label": "Apple naman’s iPhone",
    "value": "Apple naman’s iPhone"
  },
  {
    "name": "Apple Rhea’s iPhone (2)",
    "label": "Apple Rhea’s iPhone (2)",
    "value": "Apple Rhea’s iPhone (2)"
  },
  {
    "name": "Apple Rehaan’s iPhone",
    "label": "Apple Rehaan’s iPhone",
    "value": "Apple Rehaan’s iPhone"
  },
  {
    "name": "Apple Kayeng☺️",
    "label": "Apple Kayeng☺️",
    "value": "Apple Kayeng☺️"
  },
  {
    "name": "Connie’s iPad iPad",
    "label": "Connie’s iPad iPad",
    "value": "Connie’s iPad iPad"
  },
  {
    "name": "Apple Jessie’s iPhone",
    "label": "Apple Jessie’s iPhone",
    "value": "Apple Jessie’s iPhone"
  },
  {
    "name": "Cellcom M20",
    "label": "Cellcom M20",
    "value": "Cellcom M20"
  },
  {
    "name": "Along EVMFV2",
    "label": "Along EVMFV2",
    "value": "Along EVMFV2"
  },
  {
    "name": "motorola moto g play - 2023",
    "label": "motorola moto g play - 2023",
    "value": "motorola moto g play - 2023"
  },
  {
    "name": "Xiaomi Redmi Note 10",
    "label": "Xiaomi Redmi Note 10",
    "value": "Xiaomi Redmi Note 10"
  },
  {
    "name": "LAVA iris60c",
    "label": "LAVA iris60c",
    "value": "LAVA iris60c"
  },
  {
    "name": "vivo V1950A",
    "label": "vivo V1950A",
    "value": "vivo V1950A"
  },
  {
    "name": "Vonino JAX_M",
    "label": "Vonino JAX_M",
    "value": "Vonino JAX_M"
  },
  {
    "name": "Blackview BV5500Pro",
    "label": "Blackview BV5500Pro",
    "value": "Blackview BV5500Pro"
  },
  {
    "name": "MINT Mint VirGo",
    "label": "MINT Mint VirGo",
    "value": "MINT Mint VirGo"
  },
  {
    "name": "Nicholas’s iPad iPad",
    "label": "Nicholas’s iPad iPad",
    "value": "Nicholas’s iPad iPad"
  },
  {
    "name": "Chris’s iPad iPad",
    "label": "Chris’s iPad iPad",
    "value": "Chris’s iPad iPad"
  },
  {
    "name": "Apple rosemarie’s iPhone",
    "label": "Apple rosemarie’s iPhone",
    "value": "Apple rosemarie’s iPhone"
  },
  {
    "name": "Apple Mpho’s phone",
    "label": "Apple Mpho’s phone",
    "value": "Apple Mpho’s phone"
  },
  {
    "name": "HUAWEI EVA-AL10",
    "label": "HUAWEI EVA-AL10",
    "value": "HUAWEI EVA-AL10"
  },
  {
    "name": "OnePlus MT2110",
    "label": "OnePlus MT2110",
    "value": "OnePlus MT2110"
  },
  {
    "name": "TCL 5001U",
    "label": "TCL 5001U",
    "value": "TCL 5001U"
  },
  {
    "name": "alps A9",
    "label": "alps A9",
    "value": "alps A9"
  },
  {
    "name": "HMD Global Nokia C31",
    "label": "HMD Global Nokia C31",
    "value": "HMD Global Nokia C31"
  },
  {
    "name": "Desktop - tizen",
    "label": "Desktop - tizen",
    "value": "Desktop - tizen"
  },
  {
    "name": "Fortune Ship LOGIC L60",
    "label": "Fortune Ship LOGIC L60",
    "value": "Fortune Ship LOGIC L60"
  },
  {
    "name": "ZTE Z558VL",
    "label": "ZTE Z558VL",
    "value": "ZTE Z558VL"
  },
  {
    "name": "EastAeon F21",
    "label": "EastAeon F21",
    "value": "EastAeon F21"
  },
  {
    "name": "LGE LG-H931",
    "label": "LGE LG-H931",
    "value": "LGE LG-H931"
  },
  {
    "name": "BQru BQ-4030G",
    "label": "BQru BQ-4030G",
    "value": "BQru BQ-4030G"
  },
  {
    "name": "Apple Charmone’s iPhone",
    "label": "Apple Charmone’s iPhone",
    "value": "Apple Charmone’s iPhone"
  },
  {
    "name": "TIGERS TIS001_S3",
    "label": "TIGERS TIS001_S3",
    "value": "TIGERS TIS001_S3"
  },
  {
    "name": "LENOVO Lenovo TB-8704F",
    "label": "LENOVO Lenovo TB-8704F",
    "value": "LENOVO Lenovo TB-8704F"
  },
  {
    "name": "SKY Elite C55",
    "label": "SKY Elite C55",
    "value": "SKY Elite C55"
  },
  {
    "name": "CUBOT QUEST LITE",
    "label": "CUBOT QUEST LITE",
    "value": "CUBOT QUEST LITE"
  },
  {
    "name": "OPPO CPH1921",
    "label": "OPPO CPH1921",
    "value": "OPPO CPH1921"
  },
  {
    "name": "Apple 이중희",
    "label": "Apple 이중희",
    "value": "Apple 이중희"
  },
  {
    "name": "iPad iPad",
    "label": "iPad iPad",
    "value": "iPad iPad"
  },
  {
    "name": "DOOGEE S40Lite",
    "label": "DOOGEE S40Lite",
    "value": "DOOGEE S40Lite"
  },
  {
    "name": "Apple madddiii",
    "label": "Apple madddiii",
    "value": "Apple madddiii"
  },
  {
    "name": "Chinoe P13 Blue Max Pro 2021",
    "label": "Chinoe P13 Blue Max Pro 2021",
    "value": "Chinoe P13 Blue Max Pro 2021"
  },
  {
    "name": "MGT FAST",
    "label": "MGT FAST",
    "value": "MGT FAST"
  },
  {
    "name": "XTOUCH XTOUCH_A5",
    "label": "XTOUCH XTOUCH_A5",
    "value": "XTOUCH XTOUCH_A5"
  },
  {
    "name": "Blackview Tab 7 WiFi",
    "label": "Blackview Tab 7 WiFi",
    "value": "Blackview Tab 7 WiFi"
  },
  {
    "name": "Bmobile AX960",
    "label": "Bmobile AX960",
    "value": "Bmobile AX960"
  },
  {
    "name": "vivo V2020CA",
    "label": "vivo V2020CA",
    "value": "vivo V2020CA"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X663D",
    "label": "INFINIX MOBILITY LIMITED Infinix X663D",
    "value": "INFINIX MOBILITY LIMITED Infinix X663D"
  },
  {
    "name": "samsung SM-J700M",
    "label": "samsung SM-J700M",
    "value": "samsung SM-J700M"
  },
  {
    "name": "Fortune Ship BPRO",
    "label": "Fortune Ship BPRO",
    "value": "Fortune Ship BPRO"
  },
  {
    "name": "Mobicel LEGEND PRO_1",
    "label": "Mobicel LEGEND PRO_1",
    "value": "Mobicel LEGEND PRO_1"
  },
  {
    "name": "NUU S6505L",
    "label": "NUU S6505L",
    "value": "NUU S6505L"
  },
  {
    "name": "INFINIX Infinix X671",
    "label": "INFINIX Infinix X671",
    "value": "INFINIX Infinix X671"
  },
  {
    "name": "samsung SM-S918N",
    "label": "samsung SM-S918N",
    "value": "samsung SM-S918N"
  },
  {
    "name": "Apple Boitumelo’s iPhone",
    "label": "Apple Boitumelo’s iPhone",
    "value": "Apple Boitumelo’s iPhone"
  },
  {
    "name": "Google ASUS Chromebook Flip C100PA",
    "label": "Google ASUS Chromebook Flip C100PA",
    "value": "Google ASUS Chromebook Flip C100PA"
  },
  {
    "name": "Apple Cha’s iPhone",
    "label": "Apple Cha’s iPhone",
    "value": "Apple Cha’s iPhone"
  },
  {
    "name": "LGE LM-Q925L",
    "label": "LGE LM-Q925L",
    "value": "LGE LM-Q925L"
  },
  {
    "name": "Apple Ashwin’s Iphone",
    "label": "Apple Ashwin’s Iphone",
    "value": "Apple Ashwin’s Iphone"
  },
  {
    "name": "Apple Mun2",
    "label": "Apple Mun2",
    "value": "Apple Mun2"
  },
  {
    "name": "Apple iPhone 11 Pro",
    "label": "Apple iPhone 11 Pro",
    "value": "Apple iPhone 11 Pro"
  },
  {
    "name": "Apple Angelika 🐷",
    "label": "Apple Angelika 🐷",
    "value": "Apple Angelika 🐷"
  },
  {
    "name": "incar M40Pro_ROW",
    "label": "incar M40Pro_ROW",
    "value": "incar M40Pro_ROW"
  },
  {
    "name": "CUBOT KINGKONG 7",
    "label": "CUBOT KINGKONG 7",
    "value": "CUBOT KINGKONG 7"
  },
  {
    "name": "Sony E6653",
    "label": "Sony E6653",
    "value": "Sony E6653"
  },
  {
    "name": "HONOR RKY-LX3",
    "label": "HONOR RKY-LX3",
    "value": "HONOR RKY-LX3"
  },
  {
    "name": "incar S7-classic",
    "label": "incar S7-classic",
    "value": "incar S7-classic"
  },
  {
    "name": "Xiaomi 2304FPN6DC",
    "label": "Xiaomi 2304FPN6DC",
    "value": "Xiaomi 2304FPN6DC"
  },
  {
    "name": "Desktop - Chromecast",
    "label": "Desktop - Chromecast",
    "value": "Desktop - Chromecast"
  },
  {
    "name": "Skycell A1",
    "label": "Skycell A1",
    "value": "Skycell A1"
  },
  {
    "name": "Trimble TDC600",
    "label": "Trimble TDC600",
    "value": "Trimble TDC600"
  },
  {
    "name": "Samsung SM-A300FU",
    "label": "Samsung SM-A300FU",
    "value": "Samsung SM-A300FU"
  },
  {
    "name": "ZTE Z839",
    "label": "ZTE Z839",
    "value": "ZTE Z839"
  },
  {
    "name": "LUSH_MINT LM5314G",
    "label": "LUSH_MINT LM5314G",
    "value": "LUSH_MINT LM5314G"
  },
  {
    "name": "Apple Zinhle’s iPhone",
    "label": "Apple Zinhle’s iPhone",
    "value": "Apple Zinhle’s iPhone"
  },
  {
    "name": "BQru BQ-5740G",
    "label": "BQru BQ-5740G",
    "value": "BQru BQ-5740G"
  },
  {
    "name": "Apple Sphelele’s iPhone",
    "label": "Apple Sphelele’s iPhone",
    "value": "Apple Sphelele’s iPhone"
  },
  {
    "name": "Apple iPhone de Fernanda ignacia",
    "label": "Apple iPhone de Fernanda ignacia",
    "value": "Apple iPhone de Fernanda ignacia"
  },
  {
    "name": "Apple Zizi bum❤️",
    "label": "Apple Zizi bum❤️",
    "value": "Apple Zizi bum❤️"
  },
  {
    "name": "MyPhone myX12",
    "label": "MyPhone myX12",
    "value": "MyPhone myX12"
  },
  {
    "name": "WINNOVO T10",
    "label": "WINNOVO T10",
    "value": "WINNOVO T10"
  },
  {
    "name": "EPIKONE X515",
    "label": "EPIKONE X515",
    "value": "EPIKONE X515"
  },
  {
    "name": "Kalley ELEMENT MAX",
    "label": "Kalley ELEMENT MAX",
    "value": "Kalley ELEMENT MAX"
  },
  {
    "name": "TCL 8082_EEA",
    "label": "TCL 8082_EEA",
    "value": "TCL 8082_EEA"
  },
  {
    "name": "FOXXD T8",
    "label": "FOXXD T8",
    "value": "FOXXD T8"
  },
  {
    "name": "FAMIX F10L",
    "label": "FAMIX F10L",
    "value": "FAMIX F10L"
  },
  {
    "name": "IPRO Opal 4S",
    "label": "IPRO Opal 4S",
    "value": "IPRO Opal 4S"
  },
  {
    "name": "Xiaomi MI A3",
    "label": "Xiaomi MI A3",
    "value": "Xiaomi MI A3"
  },
  {
    "name": "Apple iPhone 11",
    "label": "Apple iPhone 11",
    "value": "Apple iPhone 11"
  },
  {
    "name": "Apple Lerato's iPhone💚💙🌍",
    "label": "Apple Lerato's iPhone💚💙🌍",
    "value": "Apple Lerato's iPhone💚💙🌍"
  },
  {
    "name": "ZTE ZTE A2017G",
    "label": "ZTE ZTE A2017G",
    "value": "ZTE ZTE A2017G"
  },
  {
    "name": "BMXC M863-EEA",
    "label": "BMXC M863-EEA",
    "value": "BMXC M863-EEA"
  },
  {
    "name": "Xiaomi 22101316UP",
    "label": "Xiaomi 22101316UP",
    "value": "Xiaomi 22101316UP"
  },
  {
    "name": "INOI INOI 2 Lite 2019",
    "label": "INOI INOI 2 Lite 2019",
    "value": "INOI INOI 2 Lite 2019"
  },
  {
    "name": "TCL 5099D",
    "label": "TCL 5099D",
    "value": "TCL 5099D"
  },
  {
    "name": "Aliah’s iPad iPad",
    "label": "Aliah’s iPad iPad",
    "value": "Aliah’s iPad iPad"
  },
  {
    "name": "Apple Athenkosi’s iPhone",
    "label": "Apple Athenkosi’s iPhone",
    "value": "Apple Athenkosi’s iPhone"
  },
  {
    "name": "ADVAN 8002",
    "label": "ADVAN 8002",
    "value": "ADVAN 8002"
  },
  {
    "name": "ZTE Orange Neva play",
    "label": "ZTE Orange Neva play",
    "value": "ZTE Orange Neva play"
  },
  {
    "name": "Xiaomi Nexus 6P",
    "label": "Xiaomi Nexus 6P",
    "value": "Xiaomi Nexus 6P"
  },
  {
    "name": "alps P50 Pro",
    "label": "alps P50 Pro",
    "value": "alps P50 Pro"
  },
  {
    "name": "magcomm Max80 Plus",
    "label": "magcomm Max80 Plus",
    "value": "magcomm Max80 Plus"
  },
  {
    "name": "X-TIGI X-TIGI S2",
    "label": "X-TIGI X-TIGI S2",
    "value": "X-TIGI X-TIGI S2"
  },
  {
    "name": "HUAWEI AGR-L09",
    "label": "HUAWEI AGR-L09",
    "value": "HUAWEI AGR-L09"
  },
  {
    "name": "Sky_Devices X8A",
    "label": "Sky_Devices X8A",
    "value": "Sky_Devices X8A"
  },
  {
    "name": "Symphony Z33",
    "label": "Symphony Z33",
    "value": "Symphony Z33"
  },
  {
    "name": "BLU Vivo ONE",
    "label": "BLU Vivo ONE",
    "value": "BLU Vivo ONE"
  },
  {
    "name": "Google Rockchip RK3288 Chromebook",
    "label": "Google Rockchip RK3288 Chromebook",
    "value": "Google Rockchip RK3288 Chromebook"
  },
  {
    "name": "LGE LG-H918",
    "label": "LGE LG-H918",
    "value": "LGE LG-H918"
  },
  {
    "name": "incar RC8T3G21",
    "label": "incar RC8T3G21",
    "value": "incar RC8T3G21"
  },
  {
    "name": "Apple Ahniee",
    "label": "Apple Ahniee",
    "value": "Apple Ahniee"
  },
  {
    "name": "Apple iPhone de Victor",
    "label": "Apple iPhone de Victor",
    "value": "Apple iPhone de Victor"
  },
  {
    "name": "PANASONIC ELUGA Ray 610",
    "label": "PANASONIC ELUGA Ray 610",
    "value": "PANASONIC ELUGA Ray 610"
  },
  {
    "name": "Apple In Seon의 iPhone",
    "label": "Apple In Seon의 iPhone",
    "value": "Apple In Seon의 iPhone"
  },
  {
    "name": "Apple Mphoentle’s iPhone",
    "label": "Apple Mphoentle’s iPhone",
    "value": "Apple Mphoentle’s iPhone"
  },
  {
    "name": "Apple 교훈의 iPhone",
    "label": "Apple 교훈의 iPhone",
    "value": "Apple 교훈의 iPhone"
  },
  {
    "name": "TCL 9048S",
    "label": "TCL 9048S",
    "value": "TCL 9048S"
  },
  {
    "name": "TINNO W-U300",
    "label": "TINNO W-U300",
    "value": "TINNO W-U300"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A611WP",
    "label": "ITEL MOBILE LIMITED itel A611WP",
    "value": "ITEL MOBILE LIMITED itel A611WP"
  },
  {
    "name": "SUGAR SUGAR Y12",
    "label": "SUGAR SUGAR Y12",
    "value": "SUGAR SUGAR Y12"
  },
  {
    "name": "HYUNDAI E506",
    "label": "HYUNDAI E506",
    "value": "HYUNDAI E506"
  },
  {
    "name": "TCL 5004S",
    "label": "TCL 5004S",
    "value": "TCL 5004S"
  },
  {
    "name": "samsung SM-J737V",
    "label": "samsung SM-J737V",
    "value": "samsung SM-J737V"
  },
  {
    "name": "OPPO PBDM00",
    "label": "OPPO PBDM00",
    "value": "OPPO PBDM00"
  },
  {
    "name": "samsung SM-G900F",
    "label": "samsung SM-G900F",
    "value": "samsung SM-G900F"
  },
  {
    "name": "Apple Jayde’s iPhone",
    "label": "Apple Jayde’s iPhone",
    "value": "Apple Jayde’s iPhone"
  },
  {
    "name": "Apple DM",
    "label": "Apple DM",
    "value": "Apple DM"
  },
  {
    "name": "TCL Alcatel_5004C",
    "label": "TCL Alcatel_5004C",
    "value": "TCL Alcatel_5004C"
  },
  {
    "name": "X-TIGI V12",
    "label": "X-TIGI V12",
    "value": "X-TIGI V12"
  },
  {
    "name": "STYLO Onyx",
    "label": "STYLO Onyx",
    "value": "STYLO Onyx"
  },
  {
    "name": "LT LT P10I",
    "label": "LT LT P10I",
    "value": "LT LT P10I"
  },
  {
    "name": "INFINIX MOBILITY LIMITED Infinix X609B",
    "label": "INFINIX MOBILITY LIMITED Infinix X609B",
    "value": "INFINIX MOBILITY LIMITED Infinix X609B"
  },
  {
    "name": "KEMPLER_STRAUSS P1 Amateur",
    "label": "KEMPLER_STRAUSS P1 Amateur",
    "value": "KEMPLER_STRAUSS P1 Amateur"
  },
  {
    "name": "Apple iPad14,2",
    "label": "Apple iPad14,2",
    "value": "Apple iPad14,2"
  },
  {
    "name": "vivo V2005A",
    "label": "vivo V2005A",
    "value": "vivo V2005A"
  },
  {
    "name": "realme RMX3492",
    "label": "realme RMX3492",
    "value": "realme RMX3492"
  },
  {
    "name": "Bmobile Bmobile AX751+",
    "label": "Bmobile Bmobile AX751+",
    "value": "Bmobile Bmobile AX751+"
  },
  {
    "name": "ZTE ZTE Blade A5 2019RU",
    "label": "ZTE ZTE Blade A5 2019RU",
    "value": "ZTE ZTE Blade A5 2019RU"
  },
  {
    "name": "Apple Jess",
    "label": "Apple Jess",
    "value": "Apple Jess"
  },
  {
    "name": "vivo vivo Y1s",
    "label": "vivo vivo Y1s",
    "value": "vivo vivo Y1s"
  },
  {
    "name": "motorola motorola one 5G",
    "label": "motorola motorola one 5G",
    "value": "motorola motorola one 5G"
  },
  {
    "name": "xiaomi Redmi 9C",
    "label": "xiaomi Redmi 9C",
    "value": "xiaomi Redmi 9C"
  },
  {
    "name": "HTC HTC 2Q55100",
    "label": "HTC HTC 2Q55100",
    "value": "HTC HTC 2Q55100"
  },
  {
    "name": "GIONEE GIONEE P12",
    "label": "GIONEE GIONEE P12",
    "value": "GIONEE GIONEE P12"
  },
  {
    "name": "Haier HM-N700-FL",
    "label": "Haier HM-N700-FL",
    "value": "Haier HM-N700-FL"
  },
  {
    "name": "BullittGroupLimited Land Rover Explore",
    "label": "BullittGroupLimited Land Rover Explore",
    "value": "BullittGroupLimited Land Rover Explore"
  },
  {
    "name": "Apple Babalwa’s iPhone",
    "label": "Apple Babalwa’s iPhone",
    "value": "Apple Babalwa’s iPhone"
  },
  {
    "name": "incar SXPro",
    "label": "incar SXPro",
    "value": "incar SXPro"
  },
  {
    "name": "samsung SM-N935L",
    "label": "samsung SM-N935L",
    "value": "samsung SM-N935L"
  },
  {
    "name": "Apple 허제영의 iPhone",
    "label": "Apple 허제영의 iPhone",
    "value": "Apple 허제영의 iPhone"
  },
  {
    "name": "Apple Na Hyun의 iPhone",
    "label": "Apple Na Hyun의 iPhone",
    "value": "Apple Na Hyun의 iPhone"
  },
  {
    "name": "AZUMI NOBU A55 PRO",
    "label": "AZUMI NOBU A55 PRO",
    "value": "AZUMI NOBU A55 PRO"
  },
  {
    "name": "HONOR ANY-LX2",
    "label": "HONOR ANY-LX2",
    "value": "HONOR ANY-LX2"
  },
  {
    "name": "Reliance Communications RC608L",
    "label": "Reliance Communications RC608L",
    "value": "Reliance Communications RC608L"
  },
  {
    "name": "Apple  🌺 Ana valerio 🌺",
    "label": "Apple  🌺 Ana valerio 🌺",
    "value": "Apple  🌺 Ana valerio 🌺"
  },
  {
    "name": "EPIKONE K545",
    "label": "EPIKONE K545",
    "value": "EPIKONE K545"
  },
  {
    "name": "apro COOL C1",
    "label": "apro COOL C1",
    "value": "apro COOL C1"
  },
  {
    "name": "LGE LG-H700",
    "label": "LGE LG-H700",
    "value": "LGE LG-H700"
  },
  {
    "name": "Apple Vimbai’s iphone ",
    "label": "Apple Vimbai’s iphone ",
    "value": "Apple Vimbai’s iphone "
  },
  {
    "name": "samsung SM-J737U",
    "label": "samsung SM-J737U",
    "value": "samsung SM-J737U"
  },
  {
    "name": "ZTE A2020N3",
    "label": "ZTE A2020N3",
    "value": "ZTE A2020N3"
  },
  {
    "name": "Allwinner MBOX",
    "label": "Allwinner MBOX",
    "value": "Allwinner MBOX"
  },
  {
    "name": "LGE VS987",
    "label": "LGE VS987",
    "value": "LGE VS987"
  },
  {
    "name": "Blackview BV7100",
    "label": "Blackview BV7100",
    "value": "Blackview BV7100"
  },
  {
    "name": "HUAWEI CET-AL00",
    "label": "HUAWEI CET-AL00",
    "value": "HUAWEI CET-AL00"
  },
  {
    "name": "Invens MAX8",
    "label": "Invens MAX8",
    "value": "Invens MAX8"
  },
  {
    "name": "itel itel W5503",
    "label": "itel itel W5503",
    "value": "itel itel W5503"
  },
  {
    "name": "Sony H8116",
    "label": "Sony H8116",
    "value": "Sony H8116"
  },
  {
    "name": "LAVA LAVA LH9950",
    "label": "LAVA LAVA LH9950",
    "value": "LAVA LAVA LH9950"
  },
  {
    "name": "samsung SM-G610S",
    "label": "samsung SM-G610S",
    "value": "samsung SM-G610S"
  },
  {
    "name": "HONOR RKY-LX2",
    "label": "HONOR RKY-LX2",
    "value": "HONOR RKY-LX2"
  },
  {
    "name": "samsung SM-9005",
    "label": "samsung SM-9005",
    "value": "samsung SM-9005"
  },
  {
    "name": "HUAWEI PCT-AL10",
    "label": "HUAWEI PCT-AL10",
    "value": "HUAWEI PCT-AL10"
  },
  {
    "name": "Apple Si 2",
    "label": "Apple Si 2",
    "value": "Apple Si 2"
  },
  {
    "name": "ALCATEL A574BL",
    "label": "ALCATEL A574BL",
    "value": "ALCATEL A574BL"
  },
  {
    "name": "Xchengtech O1",
    "label": "Xchengtech O1",
    "value": "Xchengtech O1"
  },
  {
    "name": "ALLVIEW X7_Soul_Pro",
    "label": "ALLVIEW X7_Soul_Pro",
    "value": "ALLVIEW X7_Soul_Pro"
  },
  {
    "name": "alps S21+ Ultra",
    "label": "alps S21+ Ultra",
    "value": "alps S21+ Ultra"
  },
  {
    "name": "CORN CORN R40",
    "label": "CORN CORN R40",
    "value": "CORN CORN R40"
  },
  {
    "name": "DigiDragon DS6261",
    "label": "DigiDragon DS6261",
    "value": "DigiDragon DS6261"
  },
  {
    "name": "ITEL itel A511LQ",
    "label": "ITEL itel A511LQ",
    "value": "ITEL itel A511LQ"
  },
  {
    "name": "ZTE K92",
    "label": "ZTE K92",
    "value": "ZTE K92"
  },
  {
    "name": "HIKING A22",
    "label": "HIKING A22",
    "value": "HIKING A22"
  },
  {
    "name": "Apple 정혜인의 iPhone",
    "label": "Apple 정혜인의 iPhone",
    "value": "Apple 정혜인의 iPhone"
  },
  {
    "name": "Cherry_Mobile Flare J3 mini",
    "label": "Cherry_Mobile Flare J3 mini",
    "value": "Cherry_Mobile Flare J3 mini"
  },
  {
    "name": "TURKCELL TURKCELL T80",
    "label": "TURKCELL TURKCELL T80",
    "value": "TURKCELL TURKCELL T80"
  },
  {
    "name": "ATM R5",
    "label": "ATM R5",
    "value": "ATM R5"
  },
  {
    "name": "MOBULAA S11",
    "label": "MOBULAA S11",
    "value": "MOBULAA S11"
  },
  {
    "name": "samsung SM-A9200",
    "label": "samsung SM-A9200",
    "value": "samsung SM-A9200"
  },
  {
    "name": "iQandT G3",
    "label": "iQandT G3",
    "value": "iQandT G3"
  },
  {
    "name": "LEAGOO S8 Pro",
    "label": "LEAGOO S8 Pro",
    "value": "LEAGOO S8 Pro"
  },
  {
    "name": "incar X10",
    "label": "incar X10",
    "value": "incar X10"
  },
  {
    "name": "sprd myA15",
    "label": "sprd myA15",
    "value": "sprd myA15"
  },
  {
    "name": "asus ASUS_AI2202_B",
    "label": "asus ASUS_AI2202_B",
    "value": "asus ASUS_AI2202_B"
  },
  {
    "name": "SWIPE Elite Power",
    "label": "SWIPE Elite Power",
    "value": "SWIPE Elite Power"
  },
  {
    "name": "wheatek Digi R2",
    "label": "wheatek Digi R2",
    "value": "wheatek Digi R2"
  },
  {
    "name": "Apple Lyka’s iPhone",
    "label": "Apple Lyka’s iPhone",
    "value": "Apple Lyka’s iPhone"
  },
  {
    "name": "Apple 김신형의 iPhone",
    "label": "Apple 김신형의 iPhone",
    "value": "Apple 김신형의 iPhone"
  },
  {
    "name": "incar Tab S5e",
    "label": "incar Tab S5e",
    "value": "incar Tab S5e"
  },
  {
    "name": "samsung SM-T387T",
    "label": "samsung SM-T387T",
    "value": "samsung SM-T387T"
  },
  {
    "name": "Google sdk_gphone64_x86_64",
    "label": "Google sdk_gphone64_x86_64",
    "value": "Google sdk_gphone64_x86_64"
  },
  {
    "name": "teksun RIO STAR",
    "label": "teksun RIO STAR",
    "value": "teksun RIO STAR"
  },
  {
    "name": "ELEVATE G55 LITE",
    "label": "ELEVATE G55 LITE",
    "value": "ELEVATE G55 LITE"
  },
  {
    "name": "Ulefone Armor 7",
    "label": "Ulefone Armor 7",
    "value": "Ulefone Armor 7"
  },
  {
    "name": "HMD Global Oy TA-1000",
    "label": "HMD Global Oy TA-1000",
    "value": "HMD Global Oy TA-1000"
  },
  {
    "name": "LOGIC T3G",
    "label": "LOGIC T3G",
    "value": "LOGIC T3G"
  },
  {
    "name": "Apple MOHD’sabeer iPhone",
    "label": "Apple MOHD’sabeer iPhone",
    "value": "Apple MOHD’sabeer iPhone"
  },
  {
    "name": "UNISOC M7",
    "label": "UNISOC M7",
    "value": "UNISOC M7"
  },
  {
    "name": "LT LT_C1500",
    "label": "LT LT_C1500",
    "value": "LT LT_C1500"
  },
  {
    "name": "M101 M101",
    "label": "M101 M101",
    "value": "M101 M101"
  },
  {
    "name": "bq Aquaris U2",
    "label": "bq Aquaris U2",
    "value": "bq Aquaris U2"
  },
  {
    "name": "Apple Mutshidzi’s iPhone",
    "label": "Apple Mutshidzi’s iPhone",
    "value": "Apple Mutshidzi’s iPhone"
  },
  {
    "name": "Apple Akash’s iPhone",
    "label": "Apple Akash’s iPhone",
    "value": "Apple Akash’s iPhone"
  },
  {
    "name": "Apple Iphone 7",
    "label": "Apple Iphone 7",
    "value": "Apple Iphone 7"
  },
  {
    "name": "motorola moto g(6) (XT1925DL)",
    "label": "motorola moto g(6) (XT1925DL)",
    "value": "motorola moto g(6) (XT1925DL)"
  },
  {
    "name": "Hisense Hisense Infinity E MAX",
    "label": "Hisense Hisense Infinity E MAX",
    "value": "Hisense Hisense Infinity E MAX"
  },
  {
    "name": "ERGO B502 Basic",
    "label": "ERGO B502 Basic",
    "value": "ERGO B502 Basic"
  },
  {
    "name": "rockchip Kingbox",
    "label": "rockchip Kingbox",
    "value": "rockchip Kingbox"
  },
  {
    "name": "samsung SM-G900MD",
    "label": "samsung SM-G900MD",
    "value": "samsung SM-G900MD"
  },
  {
    "name": "Joyar Fashion2Plus",
    "label": "Joyar Fashion2Plus",
    "value": "Joyar Fashion2Plus"
  },
  {
    "name": "LT LT C3100",
    "label": "LT LT C3100",
    "value": "LT LT C3100"
  },
  {
    "name": "TEM X40",
    "label": "TEM X40",
    "value": "TEM X40"
  },
  {
    "name": "MOBIWIRE LOGIC X57A",
    "label": "MOBIWIRE LOGIC X57A",
    "value": "MOBIWIRE LOGIC X57A"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A14S",
    "label": "ITEL MOBILE LIMITED itel A14S",
    "value": "ITEL MOBILE LIMITED itel A14S"
  },
  {
    "name": "vivo vivo X9Plus",
    "label": "vivo vivo X9Plus",
    "value": "vivo vivo X9Plus"
  },
  {
    "name": "EPIKONE K546",
    "label": "EPIKONE K546",
    "value": "EPIKONE K546"
  },
  {
    "name": "TCL 5002H_TR",
    "label": "TCL 5002H_TR",
    "value": "TCL 5002H_TR"
  },
  {
    "name": "Apple Gerly22",
    "label": "Apple Gerly22",
    "value": "Apple Gerly22"
  },
  {
    "name": "Apple Angela’s iPhone",
    "label": "Apple Angela’s iPhone",
    "value": "Apple Angela’s iPhone"
  },
  {
    "name": "samsung SM-F907B",
    "label": "samsung SM-F907B",
    "value": "samsung SM-F907B"
  },
  {
    "name": "sky Elite H55",
    "label": "sky Elite H55",
    "value": "sky Elite H55"
  },
  {
    "name": "Xiaomi 2107119DC",
    "label": "Xiaomi 2107119DC",
    "value": "Xiaomi 2107119DC"
  },
  {
    "name": "Fortune Ship SPC SMART PLUS",
    "label": "Fortune Ship SPC SMART PLUS",
    "value": "Fortune Ship SPC SMART PLUS"
  },
  {
    "name": "stack BDF-M107",
    "label": "stack BDF-M107",
    "value": "stack BDF-M107"
  },
  {
    "name": "INFINIX Infinix X668",
    "label": "INFINIX Infinix X668",
    "value": "INFINIX Infinix X668"
  },
  {
    "name": "samsung SM-G990W2",
    "label": "samsung SM-G990W2",
    "value": "samsung SM-G990W2"
  },
  {
    "name": "realme unknown",
    "label": "realme unknown",
    "value": "realme unknown"
  },
  {
    "name": "teksun C_Note",
    "label": "teksun C_Note",
    "value": "teksun C_Note"
  },
  {
    "name": "HMD Global TA-1028",
    "label": "HMD Global TA-1028",
    "value": "HMD Global TA-1028"
  },
  {
    "name": "TCL 5158A",
    "label": "TCL 5158A",
    "value": "TCL 5158A"
  },
  {
    "name": "samsung SM-A135N",
    "label": "samsung SM-A135N",
    "value": "samsung SM-A135N"
  },
  {
    "name": "Apple iPhone de Jennifer",
    "label": "Apple iPhone de Jennifer",
    "value": "Apple iPhone de Jennifer"
  },
  {
    "name": "Apple 강성훈의 iPhone",
    "label": "Apple 강성훈의 iPhone",
    "value": "Apple 강성훈의 iPhone"
  },
  {
    "name": "Apple KyleKhyleKayle",
    "label": "Apple KyleKhyleKayle",
    "value": "Apple KyleKhyleKayle"
  },
  {
    "name": "Apple Angie’s",
    "label": "Apple Angie’s",
    "value": "Apple Angie’s"
  },
  {
    "name": "Apple mira’s",
    "label": "Apple mira’s",
    "value": "Apple mira’s"
  },
  {
    "name": "Apple Miniee",
    "label": "Apple Miniee",
    "value": "Apple Miniee"
  },
  {
    "name": "iPad Beltri iPad",
    "label": "iPad Beltri iPad",
    "value": "iPad Beltri iPad"
  },
  {
    "name": "Apple LARAHMARISTELLA RAMOS",
    "label": "Apple LARAHMARISTELLA RAMOS",
    "value": "Apple LARAHMARISTELLA RAMOS"
  },
  {
    "name": "Apple Kirsten Carmela’s iPhone",
    "label": "Apple Kirsten Carmela’s iPhone",
    "value": "Apple Kirsten Carmela’s iPhone"
  },
  {
    "name": "Apple Nadz Balmeo",
    "label": "Apple Nadz Balmeo",
    "value": "Apple Nadz Balmeo"
  },
  {
    "name": "Apple iPhone de Daniela",
    "label": "Apple iPhone de Daniela",
    "value": "Apple iPhone de Daniela"
  },
  {
    "name": "wheatek R5 Pro",
    "label": "wheatek R5 Pro",
    "value": "wheatek R5 Pro"
  },
  {
    "name": "samsung GT-I9300",
    "label": "samsung GT-I9300",
    "value": "samsung GT-I9300"
  },
  {
    "name": "incar Z1",
    "label": "incar Z1",
    "value": "incar Z1"
  },
  {
    "name": "DIXON DFM48",
    "label": "DIXON DFM48",
    "value": "DIXON DFM48"
  },
  {
    "name": "J_LINK BQru-6631G_A11",
    "label": "J_LINK BQru-6631G_A11",
    "value": "J_LINK BQru-6631G_A11"
  },
  {
    "name": "HMR Network Boost_Max",
    "label": "HMR Network Boost_Max",
    "value": "HMR Network Boost_Max"
  },
  {
    "name": "Greatwall Regal TAB 10.1",
    "label": "Greatwall Regal TAB 10.1",
    "value": "Greatwall Regal TAB 10.1"
  },
  {
    "name": "Apple iPhone 8 Plus",
    "label": "Apple iPhone 8 Plus",
    "value": "Apple iPhone 8 Plus"
  },
  {
    "name": "YU YU5040",
    "label": "YU YU5040",
    "value": "YU YU5040"
  },
  {
    "name": "Desktop - Red Hat",
    "label": "Desktop - Red Hat",
    "value": "Desktop - Red Hat"
  },
  {
    "name": "Gigaset GS195",
    "label": "Gigaset GS195",
    "value": "Gigaset GS195"
  },
  {
    "name": "TCL 8068",
    "label": "TCL 8068",
    "value": "TCL 8068"
  },
  {
    "name": "Fortune Ship E601F",
    "label": "Fortune Ship E601F",
    "value": "Fortune Ship E601F"
  },
  {
    "name": "bnd MPGIO-10",
    "label": "bnd MPGIO-10",
    "value": "bnd MPGIO-10"
  },
  {
    "name": "HYUNDAI E485",
    "label": "HYUNDAI E485",
    "value": "HYUNDAI E485"
  },
  {
    "name": "OSCAL S60Pro",
    "label": "OSCAL S60Pro",
    "value": "OSCAL S60Pro"
  },
  {
    "name": "HUAWEI KSA-LX2",
    "label": "HUAWEI KSA-LX2",
    "value": "HUAWEI KSA-LX2"
  },
  {
    "name": "Apple gab’s iPhone 7",
    "label": "Apple gab’s iPhone 7",
    "value": "Apple gab’s iPhone 7"
  },
  {
    "name": "Apple iPhone de daniela",
    "label": "Apple iPhone de daniela",
    "value": "Apple iPhone de daniela"
  },
  {
    "name": "Apple Khyan’s iPhone",
    "label": "Apple Khyan’s iPhone",
    "value": "Apple Khyan’s iPhone"
  },
  {
    "name": "A-M8032S A-M8032S",
    "label": "A-M8032S A-M8032S",
    "value": "A-M8032S A-M8032S"
  },
  {
    "name": "Apple kath",
    "label": "Apple kath",
    "value": "Apple kath"
  },
  {
    "name": "Apple Jasmine’s iPhone",
    "label": "Apple Jasmine’s iPhone",
    "value": "Apple Jasmine’s iPhone"
  },
  {
    "name": "Apple John darrel’s iPhone",
    "label": "Apple John darrel’s iPhone",
    "value": "Apple John darrel’s iPhone"
  },
  {
    "name": "Apple jenny의 iPhone",
    "label": "Apple jenny의 iPhone",
    "value": "Apple jenny의 iPhone"
  },
  {
    "name": "Apple nevetyrala",
    "label": "Apple nevetyrala",
    "value": "Apple nevetyrala"
  },
  {
    "name": "LGE LM-X220QMA",
    "label": "LGE LM-X220QMA",
    "value": "LGE LM-X220QMA"
  },
  {
    "name": "ACE France URBAN 3",
    "label": "ACE France URBAN 3",
    "value": "ACE France URBAN 3"
  },
  {
    "name": "Cellution IRIS",
    "label": "Cellution IRIS",
    "value": "Cellution IRIS"
  },
  {
    "name": "samsung SM-T727R4",
    "label": "samsung SM-T727R4",
    "value": "samsung SM-T727R4"
  },
  {
    "name": "Sony SO-41A",
    "label": "Sony SO-41A",
    "value": "Sony SO-41A"
  },
  {
    "name": "W&O Max20",
    "label": "W&O Max20",
    "value": "W&O Max20"
  },
  {
    "name": "wheatek iHunt S60 Discovery Pro 2022",
    "label": "wheatek iHunt S60 Discovery Pro 2022",
    "value": "wheatek iHunt S60 Discovery Pro 2022"
  },
  {
    "name": "TECLAST P80_ROW",
    "label": "TECLAST P80_ROW",
    "value": "TECLAST P80_ROW"
  },
  {
    "name": "Sony G3125",
    "label": "Sony G3125",
    "value": "Sony G3125"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO KB2",
    "label": "TECNO MOBILE LIMITED TECNO KB2",
    "value": "TECNO MOBILE LIMITED TECNO KB2"
  },
  {
    "name": "Casper VIA_A3_Plus",
    "label": "Casper VIA_A3_Plus",
    "value": "Casper VIA_A3_Plus"
  },
  {
    "name": "Xiaomi ASUS_I001DA",
    "label": "Xiaomi ASUS_I001DA",
    "value": "Xiaomi ASUS_I001DA"
  },
  {
    "name": "HUAWEI IVY-LX2",
    "label": "HUAWEI IVY-LX2",
    "value": "HUAWEI IVY-LX2"
  },
  {
    "name": "realme RMX3503",
    "label": "realme RMX3503",
    "value": "realme RMX3503"
  },
  {
    "name": "HUAWEI COL-AL10",
    "label": "HUAWEI COL-AL10",
    "value": "HUAWEI COL-AL10"
  },
  {
    "name": "Symphony Z35",
    "label": "Symphony Z35",
    "value": "Symphony Z35"
  },
  {
    "name": "samsung SM-X900",
    "label": "samsung SM-X900",
    "value": "samsung SM-X900"
  },
  {
    "name": "Apple Anj",
    "label": "Apple Anj",
    "value": "Apple Anj"
  },
  {
    "name": "Apple Kylie",
    "label": "Apple Kylie",
    "value": "Apple Kylie"
  },
  {
    "name": "Apple iPhone de Gabriela",
    "label": "Apple iPhone de Gabriela",
    "value": "Apple iPhone de Gabriela"
  },
  {
    "name": "Apple iPhone paula",
    "label": "Apple iPhone paula",
    "value": "Apple iPhone paula"
  },
  {
    "name": "Apple 잰득폰",
    "label": "Apple 잰득폰",
    "value": "Apple 잰득폰"
  },
  {
    "name": "이예진의 iPad iPad",
    "label": "이예진의 iPad iPad",
    "value": "이예진의 iPad iPad"
  },
  {
    "name": "Apple Dominic Hyriel",
    "label": "Apple Dominic Hyriel",
    "value": "Apple Dominic Hyriel"
  },
  {
    "name": "Apple Perla Herrera ",
    "label": "Apple Perla Herrera ",
    "value": "Apple Perla Herrera "
  },
  {
    "name": "Apple iPhone de Marco",
    "label": "Apple iPhone de Marco",
    "value": "Apple iPhone de Marco"
  },
  {
    "name": "Apple iPhone de Yeimar",
    "label": "Apple iPhone de Yeimar",
    "value": "Apple iPhone de Yeimar"
  },
  {
    "name": "HelloPro A900",
    "label": "HelloPro A900",
    "value": "HelloPro A900"
  },
  {
    "name": "Apple pia🦋",
    "label": "Apple pia🦋",
    "value": "Apple pia🦋"
  },
  {
    "name": "360 1801-A01",
    "label": "360 1801-A01",
    "value": "360 1801-A01"
  },
  {
    "name": "alps J90",
    "label": "alps J90",
    "value": "alps J90"
  },
  {
    "name": "vivo V2056A",
    "label": "vivo V2056A",
    "value": "vivo V2056A"
  },
  {
    "name": "General Mobile GM 9 Pro",
    "label": "General Mobile GM 9 Pro",
    "value": "General Mobile GM 9 Pro"
  },
  {
    "name": "smartisan OC105",
    "label": "smartisan OC105",
    "value": "smartisan OC105"
  },
  {
    "name": "NUU S5502L",
    "label": "NUU S5502L",
    "value": "NUU S5502L"
  },
  {
    "name": "OPPO PCLM10",
    "label": "OPPO PCLM10",
    "value": "OPPO PCLM10"
  },
  {
    "name": "FIGI Note_11",
    "label": "FIGI Note_11",
    "value": "FIGI Note_11"
  },
  {
    "name": "LAVA LE9910",
    "label": "LAVA LE9910",
    "value": "LAVA LE9910"
  },
  {
    "name": "EL D60 Pro",
    "label": "EL D60 Pro",
    "value": "EL D60 Pro"
  },
  {
    "name": "Alldocube iPlay20S",
    "label": "Alldocube iPlay20S",
    "value": "Alldocube iPlay20S"
  },
  {
    "name": "samsung M2006J10C",
    "label": "samsung M2006J10C",
    "value": "samsung M2006J10C"
  },
  {
    "name": "Tahnia’s iPad iPad",
    "label": "Tahnia’s iPad iPad",
    "value": "Tahnia’s iPad iPad"
  },
  {
    "name": "ZTE ZTE BLADE A321",
    "label": "ZTE ZTE BLADE A321",
    "value": "ZTE ZTE BLADE A321"
  },
  {
    "name": "Morep M521",
    "label": "Morep M521",
    "value": "Morep M521"
  },
  {
    "name": "Kruger_Matz Live 9",
    "label": "Kruger_Matz Live 9",
    "value": "Kruger_Matz Live 9"
  },
  {
    "name": "Rockchip SENIC",
    "label": "Rockchip SENIC",
    "value": "Rockchip SENIC"
  },
  {
    "name": "OPPO PFFM20",
    "label": "OPPO PFFM20",
    "value": "OPPO PFFM20"
  },
  {
    "name": "Sky PLATINUM_VIEW2",
    "label": "Sky PLATINUM_VIEW2",
    "value": "Sky PLATINUM_VIEW2"
  },
  {
    "name": "TCL T766H_TR",
    "label": "TCL T766H_TR",
    "value": "TCL T766H_TR"
  },
  {
    "name": "Apple 오선우의 iPhone",
    "label": "Apple 오선우의 iPhone",
    "value": "Apple 오선우의 iPhone"
  },
  {
    "name": "Apple iPhone de Adialis",
    "label": "Apple iPhone de Adialis",
    "value": "Apple iPhone de Adialis"
  },
  {
    "name": "Bmobile AX751+",
    "label": "Bmobile AX751+",
    "value": "Bmobile AX751+"
  },
  {
    "name": "Mobicel Cosmo LTE",
    "label": "Mobicel Cosmo LTE",
    "value": "Mobicel Cosmo LTE"
  },
  {
    "name": "vivo I1928",
    "label": "vivo I1928",
    "value": "vivo I1928"
  },
  {
    "name": "TCL T671F",
    "label": "TCL T671F",
    "value": "TCL T671F"
  },
  {
    "name": "LAVA iris65",
    "label": "LAVA iris65",
    "value": "LAVA iris65"
  },
  {
    "name": "ZTE N9517",
    "label": "ZTE N9517",
    "value": "ZTE N9517"
  },
  {
    "name": "SKY Elite M45",
    "label": "SKY Elite M45",
    "value": "SKY Elite M45"
  },
  {
    "name": "Coolpad CP3669AS",
    "label": "Coolpad CP3669AS",
    "value": "Coolpad CP3669AS"
  },
  {
    "name": "vsmart Bee",
    "label": "vsmart Bee",
    "value": "vsmart Bee"
  },
  {
    "name": "BLU BOLD N2",
    "label": "BLU BOLD N2",
    "value": "BLU BOLD N2"
  },
  {
    "name": "KMG FUEGUINA S.A. PIXPRO L1 PRO",
    "label": "KMG FUEGUINA S.A. PIXPRO L1 PRO",
    "value": "KMG FUEGUINA S.A. PIXPRO L1 PRO"
  },
  {
    "name": "RAVOZ RAVOZ Z7 Pro",
    "label": "RAVOZ RAVOZ Z7 Pro",
    "value": "RAVOZ RAVOZ Z7 Pro"
  },
  {
    "name": "Apple Shalati",
    "label": "Apple Shalati",
    "value": "Apple Shalati"
  },
  {
    "name": "Apple iPhone de Lau ",
    "label": "Apple iPhone de Lau ",
    "value": "Apple iPhone de Lau "
  },
  {
    "name": "Apple 채연이꺼",
    "label": "Apple 채연이꺼",
    "value": "Apple 채연이꺼"
  },
  {
    "name": "KRIP K5d",
    "label": "KRIP K5d",
    "value": "KRIP K5d"
  },
  {
    "name": "MAXWEST Astro 4 PRO",
    "label": "MAXWEST Astro 4 PRO",
    "value": "MAXWEST Astro 4 PRO"
  },
  {
    "name": "Apple AAT",
    "label": "Apple AAT",
    "value": "Apple AAT"
  },
  {
    "name": "Apple Jake’s iPhone",
    "label": "Apple Jake’s iPhone",
    "value": "Apple Jake’s iPhone"
  },
  {
    "name": "Apple iPhone de Lidia natalia",
    "label": "Apple iPhone de Lidia natalia",
    "value": "Apple iPhone de Lidia natalia"
  },
  {
    "name": "Apple Reymart’s iPhone",
    "label": "Apple Reymart’s iPhone",
    "value": "Apple Reymart’s iPhone"
  },
  {
    "name": "Apple iPhone de Gisselle",
    "label": "Apple iPhone de Gisselle",
    "value": "Apple iPhone de Gisselle"
  },
  {
    "name": "Apple Tamson’s iPhone",
    "label": "Apple Tamson’s iPhone",
    "value": "Apple Tamson’s iPhone"
  },
  {
    "name": "Coolpad CP3503L",
    "label": "Coolpad CP3503L",
    "value": "Coolpad CP3503L"
  },
  {
    "name": "Azom Desert2",
    "label": "Azom Desert2",
    "value": "Azom Desert2"
  },
  {
    "name": "along Kil-82WFDC",
    "label": "along Kil-82WFDC",
    "value": "along Kil-82WFDC"
  },
  {
    "name": "OPPO OPPO R11 Plus",
    "label": "OPPO OPPO R11 Plus",
    "value": "OPPO OPPO R11 Plus"
  },
  {
    "name": "Apple tiffy",
    "label": "Apple tiffy",
    "value": "Apple tiffy"
  },
  {
    "name": "DEXP A250",
    "label": "DEXP A250",
    "value": "DEXP A250"
  },
  {
    "name": "INNJOO Superb",
    "label": "INNJOO Superb",
    "value": "INNJOO Superb"
  },
  {
    "name": "Apple iPad14,6",
    "label": "Apple iPad14,6",
    "value": "Apple iPad14,6"
  },
  {
    "name": "LAVA iris51",
    "label": "LAVA iris51",
    "value": "LAVA iris51"
  },
  {
    "name": "Zeke's iPad iPad",
    "label": "Zeke's iPad iPad",
    "value": "Zeke's iPad iPad"
  },
  {
    "name": "Apple MIO",
    "label": "Apple MIO",
    "value": "Apple MIO"
  },
  {
    "name": "Apple WTF",
    "label": "Apple WTF",
    "value": "Apple WTF"
  },
  {
    "name": "alps Y1",
    "label": "alps Y1",
    "value": "alps Y1"
  },
  {
    "name": "samsung SM-M015F",
    "label": "samsung SM-M015F",
    "value": "samsung SM-M015F"
  },
  {
    "name": "alps S72",
    "label": "alps S72",
    "value": "alps S72"
  },
  {
    "name": "ITEL MOBILE LIMITED itel S42",
    "label": "ITEL MOBILE LIMITED itel S42",
    "value": "ITEL MOBILE LIMITED itel S42"
  },
  {
    "name": "Apple Sbahle👩🏼‍🦰",
    "label": "Apple Sbahle👩🏼‍🦰",
    "value": "Apple Sbahle👩🏼‍🦰"
  },
  {
    "name": "Xiaomi Redmi 3S",
    "label": "Xiaomi Redmi 3S",
    "value": "Xiaomi Redmi 3S"
  },
  {
    "name": "Wingtech 2014819",
    "label": "Wingtech 2014819",
    "value": "Wingtech 2014819"
  },
  {
    "name": "samsung SM-N9760",
    "label": "samsung SM-N9760",
    "value": "samsung SM-N9760"
  },
  {
    "name": "nubia NX666J",
    "label": "nubia NX666J",
    "value": "nubia NX666J"
  },
  {
    "name": "Ulefone Armor X7",
    "label": "Ulefone Armor X7",
    "value": "Ulefone Armor X7"
  },
  {
    "name": "JONALYN’s iPad iPad",
    "label": "JONALYN’s iPad iPad",
    "value": "JONALYN’s iPad iPad"
  },
  {
    "name": "Apple nix (◕‿◕｡)",
    "label": "Apple nix (◕‿◕｡)",
    "value": "Apple nix (◕‿◕｡)"
  },
  {
    "name": "Apple Lindy’s iPhone",
    "label": "Apple Lindy’s iPhone",
    "value": "Apple Lindy’s iPhone"
  },
  {
    "name": "Apple iPhone s",
    "label": "Apple iPhone s",
    "value": "Apple iPhone s"
  },
  {
    "name": "Apple 7iPhone",
    "label": "Apple 7iPhone",
    "value": "Apple 7iPhone"
  },
  {
    "name": "Apple iPhone de Moad",
    "label": "Apple iPhone de Moad",
    "value": "Apple iPhone de Moad"
  },
  {
    "name": "Desktop - Firefox OS",
    "label": "Desktop - Firefox OS",
    "value": "Desktop - Firefox OS"
  },
  {
    "name": "Apple 김하민의 iPhone",
    "label": "Apple 김하민의 iPhone",
    "value": "Apple 김하민의 iPhone"
  },
  {
    "name": "Casper VIA G5",
    "label": "Casper VIA G5",
    "value": "Casper VIA G5"
  },
  {
    "name": "Apple Tshoane's iPhone",
    "label": "Apple Tshoane's iPhone",
    "value": "Apple Tshoane's iPhone"
  },
  {
    "name": "Micromax ione",
    "label": "Micromax ione",
    "value": "Micromax ione"
  },
  {
    "name": "CORN C55",
    "label": "CORN C55",
    "value": "CORN C55"
  },
  {
    "name": "samsung SM-J530S",
    "label": "samsung SM-J530S",
    "value": "samsung SM-J530S"
  },
  {
    "name": "Apple 이주미의 iPhone",
    "label": "Apple 이주미의 iPhone",
    "value": "Apple 이주미의 iPhone"
  },
  {
    "name": "Apple iPhone de Katherine",
    "label": "Apple iPhone de Katherine",
    "value": "Apple iPhone de Katherine"
  },
  {
    "name": "Apple iPhone de Estefani",
    "label": "Apple iPhone de Estefani",
    "value": "Apple iPhone de Estefani"
  },
  {
    "name": "Apple Barbara’s iPhone",
    "label": "Apple Barbara’s iPhone",
    "value": "Apple Barbara’s iPhone"
  },
  {
    "name": "Apple iPhone Mia",
    "label": "Apple iPhone Mia",
    "value": "Apple iPhone Mia"
  },
  {
    "name": "Apple yaii",
    "label": "Apple yaii",
    "value": "Apple yaii"
  },
  {
    "name": "Apple PAUL",
    "label": "Apple PAUL",
    "value": "Apple PAUL"
  },
  {
    "name": "samsung SM-T825N0",
    "label": "samsung SM-T825N0",
    "value": "samsung SM-T825N0"
  },
  {
    "name": "Apple Lebs’ iPhone",
    "label": "Apple Lebs’ iPhone",
    "value": "Apple Lebs’ iPhone"
  },
  {
    "name": "Apple Manue 💫",
    "label": "Apple Manue 💫",
    "value": "Apple Manue 💫"
  },
  {
    "name": "OnePlus PGKM10",
    "label": "OnePlus PGKM10",
    "value": "OnePlus PGKM10"
  },
  {
    "name": "motorola moto g6 play",
    "label": "motorola moto g6 play",
    "value": "motorola moto g6 play"
  },
  {
    "name": "samsung SM-J327VPP",
    "label": "samsung SM-J327VPP",
    "value": "samsung SM-J327VPP"
  },
  {
    "name": "Apple HR‘◡’✿",
    "label": "Apple HR‘◡’✿",
    "value": "Apple HR‘◡’✿"
  },
  {
    "name": "Apple ey",
    "label": "Apple ey",
    "value": "Apple ey"
  },
  {
    "name": "Apple Yujeong’s iPhone",
    "label": "Apple Yujeong’s iPhone",
    "value": "Apple Yujeong’s iPhone"
  },
  {
    "name": "Apple 애옹진",
    "label": "Apple 애옹진",
    "value": "Apple 애옹진"
  },
  {
    "name": "Apple iPhone Monica",
    "label": "Apple iPhone Monica",
    "value": "Apple iPhone Monica"
  },
  {
    "name": "Apple 김영랑의 iPhone",
    "label": "Apple 김영랑의 iPhone",
    "value": "Apple 김영랑의 iPhone"
  },
  {
    "name": "Apple Pi",
    "label": "Apple Pi",
    "value": "Apple Pi"
  },
  {
    "name": "Apple Rutendo’s iPhone",
    "label": "Apple Rutendo’s iPhone",
    "value": "Apple Rutendo’s iPhone"
  },
  {
    "name": "Apple Leslie’s iPhone",
    "label": "Apple Leslie’s iPhone",
    "value": "Apple Leslie’s iPhone"
  },
  {
    "name": "Apple iPhone12Mini",
    "label": "Apple iPhone12Mini",
    "value": "Apple iPhone12Mini"
  },
  {
    "name": "Xiaomi 2207122MC",
    "label": "Xiaomi 2207122MC",
    "value": "Xiaomi 2207122MC"
  },
  {
    "name": "incar VE1983",
    "label": "incar VE1983",
    "value": "incar VE1983"
  },
  {
    "name": "Apple Veena’s iPhone",
    "label": "Apple Veena’s iPhone",
    "value": "Apple Veena’s iPhone"
  },
  {
    "name": "LAVA Z51_Plus",
    "label": "LAVA Z51_Plus",
    "value": "LAVA Z51_Plus"
  },
  {
    "name": "vivo V1730EA",
    "label": "vivo V1730EA",
    "value": "vivo V1730EA"
  },
  {
    "name": "vivo V2020A",
    "label": "vivo V2020A",
    "value": "vivo V2020A"
  },
  {
    "name": "Sky_Devices Elite D5",
    "label": "Sky_Devices Elite D5",
    "value": "Sky_Devices Elite D5"
  },
  {
    "name": "vivo V2229",
    "label": "vivo V2229",
    "value": "vivo V2229"
  },
  {
    "name": "OnePlus OnePlus 9",
    "label": "OnePlus OnePlus 9",
    "value": "OnePlus OnePlus 9"
  },
  {
    "name": "IPRO Soda6S",
    "label": "IPRO Soda6S",
    "value": "IPRO Soda6S"
  },
  {
    "name": "EPIKONE K503",
    "label": "EPIKONE K503",
    "value": "EPIKONE K503"
  },
  {
    "name": "Apple iPhone de Claudia",
    "label": "Apple iPhone de Claudia",
    "value": "Apple iPhone de Claudia"
  },
  {
    "name": "Apple iPhone de Lord Paul",
    "label": "Apple iPhone de Lord Paul",
    "value": "Apple iPhone de Lord Paul"
  },
  {
    "name": "Apple iPhone de Eloy",
    "label": "Apple iPhone de Eloy",
    "value": "Apple iPhone de Eloy"
  },
  {
    "name": "Apple Redddevil",
    "label": "Apple Redddevil",
    "value": "Apple Redddevil"
  },
  {
    "name": "Apple iPhone de Tamara",
    "label": "Apple iPhone de Tamara",
    "value": "Apple iPhone de Tamara"
  },
  {
    "name": "HUAWEI HLK-AL00",
    "label": "HUAWEI HLK-AL00",
    "value": "HUAWEI HLK-AL00"
  },
  {
    "name": "Xiaomi POCO X3 NFC (surya)",
    "label": "Xiaomi POCO X3 NFC (surya)",
    "value": "Xiaomi POCO X3 NFC (surya)"
  },
  {
    "name": "samsung SM-J250Y",
    "label": "samsung SM-J250Y",
    "value": "samsung SM-J250Y"
  },
  {
    "name": "Symphony Z55",
    "label": "Symphony Z55",
    "value": "Symphony Z55"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO SC7S",
    "label": "TECNO MOBILE LIMITED TECNO SC7S",
    "value": "TECNO MOBILE LIMITED TECNO SC7S"
  },
  {
    "name": "OPPO CPH2241",
    "label": "OPPO CPH2241",
    "value": "OPPO CPH2241"
  },
  {
    "name": "ROCO Net_Max_Go",
    "label": "ROCO Net_Max_Go",
    "value": "ROCO Net_Max_Go"
  },
  {
    "name": "Apple Lorraine IPHONE",
    "label": "Apple Lorraine IPHONE",
    "value": "Apple Lorraine IPHONE"
  },
  {
    "name": "Apple Zamangcobo’s iPhone",
    "label": "Apple Zamangcobo’s iPhone",
    "value": "Apple Zamangcobo’s iPhone"
  },
  {
    "name": "OnePlus IN2019",
    "label": "OnePlus IN2019",
    "value": "OnePlus IN2019"
  },
  {
    "name": "magcomm Gravity Z2",
    "label": "magcomm Gravity Z2",
    "value": "magcomm Gravity Z2"
  },
  {
    "name": "incar Star8_3G",
    "label": "incar Star8_3G",
    "value": "incar Star8_3G"
  },
  {
    "name": "EPIKONE TX800",
    "label": "EPIKONE TX800",
    "value": "EPIKONE TX800"
  },
  {
    "name": "TCL 6056D",
    "label": "TCL 6056D",
    "value": "TCL 6056D"
  },
  {
    "name": "Vonino Magnet_G50",
    "label": "Vonino Magnet_G50",
    "value": "Vonino Magnet_G50"
  },
  {
    "name": "Sony I4193",
    "label": "Sony I4193",
    "value": "Sony I4193"
  },
  {
    "name": "Apple Atlegang’s iPhone",
    "label": "Apple Atlegang’s iPhone",
    "value": "Apple Atlegang’s iPhone"
  },
  {
    "name": "ANS UL40",
    "label": "ANS UL40",
    "value": "ANS UL40"
  },
  {
    "name": "samsung SM-T597V",
    "label": "samsung SM-T597V",
    "value": "samsung SM-T597V"
  },
  {
    "name": "TabletEXO_Wave_i101R TabletEXO_Wave_i101R",
    "label": "TabletEXO_Wave_i101R TabletEXO_Wave_i101R",
    "value": "TabletEXO_Wave_i101R TabletEXO_Wave_i101R"
  },
  {
    "name": "LGE LM-X410L",
    "label": "LGE LM-X410L",
    "value": "LGE LM-X410L"
  },
  {
    "name": "Apple iPhone de Josh",
    "label": "Apple iPhone de Josh",
    "value": "Apple iPhone de Josh"
  },
  {
    "name": "Apple Unknown",
    "label": "Apple Unknown",
    "value": "Apple Unknown"
  },
  {
    "name": "Apple Mimi",
    "label": "Apple Mimi",
    "value": "Apple Mimi"
  },
  {
    "name": "Apple 형섭",
    "label": "Apple 형섭",
    "value": "Apple 형섭"
  },
  {
    "name": "Apple jes",
    "label": "Apple jes",
    "value": "Apple jes"
  },
  {
    "name": "realme GM1917",
    "label": "realme GM1917",
    "value": "realme GM1917"
  },
  {
    "name": "Apple Jade’s iPhone",
    "label": "Apple Jade’s iPhone",
    "value": "Apple Jade’s iPhone"
  },
  {
    "name": "Apple Cotecita💛",
    "label": "Apple Cotecita💛",
    "value": "Apple Cotecita💛"
  },
  {
    "name": "Blackview BV9600E",
    "label": "Blackview BV9600E",
    "value": "Blackview BV9600E"
  },
  {
    "name": "Sky ELITE T8",
    "label": "Sky ELITE T8",
    "value": "Sky ELITE T8"
  },
  {
    "name": "DOOGEE V20",
    "label": "DOOGEE V20",
    "value": "DOOGEE V20"
  },
  {
    "name": "VIRZO VT701",
    "label": "VIRZO VT701",
    "value": "VIRZO VT701"
  },
  {
    "name": "Apple Megan’s iPhone",
    "label": "Apple Megan’s iPhone",
    "value": "Apple Megan’s iPhone"
  },
  {
    "name": "TCL 5024D",
    "label": "TCL 5024D",
    "value": "TCL 5024D"
  },
  {
    "name": "LAVA Z81",
    "label": "LAVA Z81",
    "value": "LAVA Z81"
  },
  {
    "name": "RT_PROJECT SHOCK5.5",
    "label": "RT_PROJECT SHOCK5.5",
    "value": "RT_PROJECT SHOCK5.5"
  },
  {
    "name": "Mobicel R4 LTE",
    "label": "Mobicel R4 LTE",
    "value": "Mobicel R4 LTE"
  },
  {
    "name": "Motorola Moto X4",
    "label": "Motorola Moto X4",
    "value": "Motorola Moto X4"
  },
  {
    "name": "Vanzo ELEMENT PLUS",
    "label": "Vanzo ELEMENT PLUS",
    "value": "Vanzo ELEMENT PLUS"
  },
  {
    "name": "Sony H8166",
    "label": "Sony H8166",
    "value": "Sony H8166"
  },
  {
    "name": "vivo V2121A",
    "label": "vivo V2121A",
    "value": "vivo V2121A"
  },
  {
    "name": "Apple Saris",
    "label": "Apple Saris",
    "value": "Apple Saris"
  },
  {
    "name": "Apple IPP",
    "label": "Apple IPP",
    "value": "Apple IPP"
  },
  {
    "name": "Apple OH!",
    "label": "Apple OH!",
    "value": "Apple OH!"
  },
  {
    "name": "Apple Sachin jaat",
    "label": "Apple Sachin jaat",
    "value": "Apple Sachin jaat"
  },
  {
    "name": "bnd T7G",
    "label": "bnd T7G",
    "value": "bnd T7G"
  },
  {
    "name": "vivo V1981A",
    "label": "vivo V1981A",
    "value": "vivo V1981A"
  },
  {
    "name": "HUAWEI FRD-L09",
    "label": "HUAWEI FRD-L09",
    "value": "HUAWEI FRD-L09"
  },
  {
    "name": "Apple Pritish’s iPhone",
    "label": "Apple Pritish’s iPhone",
    "value": "Apple Pritish’s iPhone"
  },
  {
    "name": "Apple Oarabiles iPhone ",
    "label": "Apple Oarabiles iPhone ",
    "value": "Apple Oarabiles iPhone "
  },
  {
    "name": "ADVANCE HL6577",
    "label": "ADVANCE HL6577",
    "value": "ADVANCE HL6577"
  },
  {
    "name": "samsung SM-J327R4",
    "label": "samsung SM-J327R4",
    "value": "samsung SM-J327R4"
  },
  {
    "name": "meizu 16s Pro",
    "label": "meizu 16s Pro",
    "value": "meizu 16s Pro"
  },
  {
    "name": "samsung SM-N900T",
    "label": "samsung SM-N900T",
    "value": "samsung SM-N900T"
  },
  {
    "name": "mobiistar C1 Lite",
    "label": "mobiistar C1 Lite",
    "value": "mobiistar C1 Lite"
  },
  {
    "name": "Alco RCT6873W42BMF9A",
    "label": "Alco RCT6873W42BMF9A",
    "value": "Alco RCT6873W42BMF9A"
  },
  {
    "name": "AIDATA ADT1012L",
    "label": "AIDATA ADT1012L",
    "value": "AIDATA ADT1012L"
  },
  {
    "name": "DIGICEL DL3",
    "label": "DIGICEL DL3",
    "value": "DIGICEL DL3"
  },
  {
    "name": "PCBOX Kova 2 Plus PCB-T732",
    "label": "PCBOX Kova 2 Plus PCB-T732",
    "value": "PCBOX Kova 2 Plus PCB-T732"
  },
  {
    "name": "eksX X6",
    "label": "eksX X6",
    "value": "eksX X6"
  },
  {
    "name": "Apple MALLELY❤️✨",
    "label": "Apple MALLELY❤️✨",
    "value": "Apple MALLELY❤️✨"
  },
  {
    "name": "Apple iPhone Natalia 👑",
    "label": "Apple iPhone Natalia 👑",
    "value": "Apple iPhone Natalia 👑"
  },
  {
    "name": "wind Horizon_S",
    "label": "wind Horizon_S",
    "value": "wind Horizon_S"
  },
  {
    "name": "Apple iPhone de Ro🐢",
    "label": "Apple iPhone de Ro🐢",
    "value": "Apple iPhone de Ro🐢"
  },
  {
    "name": "Apple iPhone de Nikole",
    "label": "Apple iPhone de Nikole",
    "value": "Apple iPhone de Nikole"
  },
  {
    "name": "Apple iPhone de ANA MARIA",
    "label": "Apple iPhone de ANA MARIA",
    "value": "Apple iPhone de ANA MARIA"
  },
  {
    "name": "Apple andrea",
    "label": "Apple andrea",
    "value": "Apple andrea"
  },
  {
    "name": "BLU G91 Pro",
    "label": "BLU G91 Pro",
    "value": "BLU G91 Pro"
  },
  {
    "name": "Symphony i99",
    "label": "Symphony i99",
    "value": "Symphony i99"
  },
  {
    "name": "Apple 은혜",
    "label": "Apple 은혜",
    "value": "Apple 은혜"
  },
  {
    "name": "Apple iPhone de Alexandra",
    "label": "Apple iPhone de Alexandra",
    "value": "Apple iPhone de Alexandra"
  },
  {
    "name": "Apple iPhone valen",
    "label": "Apple iPhone valen",
    "value": "Apple iPhone valen"
  },
  {
    "name": "Apple lala_ineng",
    "label": "Apple lala_ineng",
    "value": "Apple lala_ineng"
  },
  {
    "name": "Apple iPhone Humbert",
    "label": "Apple iPhone Humbert",
    "value": "Apple iPhone Humbert"
  },
  {
    "name": "Cherry Mobile Flare_S8_Prime",
    "label": "Cherry Mobile Flare_S8_Prime",
    "value": "Cherry Mobile Flare_S8_Prime"
  },
  {
    "name": "Fortune Ship Wildfire E3",
    "label": "Fortune Ship Wildfire E3",
    "value": "Fortune Ship Wildfire E3"
  },
  {
    "name": "Blackview BV5800",
    "label": "Blackview BV5800",
    "value": "Blackview BV5800"
  },
  {
    "name": "SPURT K200",
    "label": "SPURT K200",
    "value": "SPURT K200"
  },
  {
    "name": "incar WH10S9863232",
    "label": "incar WH10S9863232",
    "value": "incar WH10S9863232"
  },
  {
    "name": "JADE JADE",
    "label": "JADE JADE",
    "value": "JADE JADE"
  },
  {
    "name": "BlackBerry BBD100-2",
    "label": "BlackBerry BBD100-2",
    "value": "BlackBerry BBD100-2"
  },
  {
    "name": "teracube Teracube 2e",
    "label": "teracube Teracube 2e",
    "value": "teracube Teracube 2e"
  },
  {
    "name": "motorola XT2201-2",
    "label": "motorola XT2201-2",
    "value": "motorola XT2201-2"
  },
  {
    "name": "OPPO PCHM30",
    "label": "OPPO PCHM30",
    "value": "OPPO PCHM30"
  },
  {
    "name": "WOLKI W626 LITE",
    "label": "WOLKI W626 LITE",
    "value": "WOLKI W626 LITE"
  },
  {
    "name": "LGE LG-F800S",
    "label": "LGE LG-F800S",
    "value": "LGE LG-F800S"
  },
  {
    "name": "CloudFone Next Infinity Plus",
    "label": "CloudFone Next Infinity Plus",
    "value": "CloudFone Next Infinity Plus"
  },
  {
    "name": "Great Like_8",
    "label": "Great Like_8",
    "value": "Great Like_8"
  },
  {
    "name": "SKY Elite OctaX",
    "label": "SKY Elite OctaX",
    "value": "SKY Elite OctaX"
  },
  {
    "name": "Apple Atli iPhone",
    "label": "Apple Atli iPhone",
    "value": "Apple Atli iPhone"
  },
  {
    "name": "Apple Boikanyo’s iPhone",
    "label": "Apple Boikanyo’s iPhone",
    "value": "Apple Boikanyo’s iPhone"
  },
  {
    "name": "Apple Mary Joyce’s iPhone 7+",
    "label": "Apple Mary Joyce’s iPhone 7+",
    "value": "Apple Mary Joyce’s iPhone 7+"
  },
  {
    "name": "Apple Giljin의 iPhone",
    "label": "Apple Giljin의 iPhone",
    "value": "Apple Giljin의 iPhone"
  },
  {
    "name": "Apple Joshua’s iPhone",
    "label": "Apple Joshua’s iPhone",
    "value": "Apple Joshua’s iPhone"
  },
  {
    "name": "Apple 수나의 아이퐁ㅎㅎ",
    "label": "Apple 수나의 아이퐁ㅎㅎ",
    "value": "Apple 수나의 아이퐁ㅎㅎ"
  },
  {
    "name": "Apple Pfukani’s iPhone",
    "label": "Apple Pfukani’s iPhone",
    "value": "Apple Pfukani’s iPhone"
  },
  {
    "name": "Cherry_Mobile Flare_J2_2018",
    "label": "Cherry_Mobile Flare_J2_2018",
    "value": "Cherry_Mobile Flare_J2_2018"
  },
  {
    "name": "Apple Adolf’s iPhone",
    "label": "Apple Adolf’s iPhone",
    "value": "Apple Adolf’s iPhone"
  },
  {
    "name": "Apple 준형 iPhone",
    "label": "Apple 준형 iPhone",
    "value": "Apple 준형 iPhone"
  },
  {
    "name": "Realme Realme 3 Pro",
    "label": "Realme Realme 3 Pro",
    "value": "Realme Realme 3 Pro"
  },
  {
    "name": "Symphony Z15",
    "label": "Symphony Z15",
    "value": "Symphony Z15"
  },
  {
    "name": "revoview Neon Storm",
    "label": "revoview Neon Storm",
    "value": "revoview Neon Storm"
  },
  {
    "name": "blackshark SKR-A0",
    "label": "blackshark SKR-A0",
    "value": "blackshark SKR-A0"
  },
  {
    "name": "Sky Devices Premier",
    "label": "Sky Devices Premier",
    "value": "Sky Devices Premier"
  },
  {
    "name": "Casper CASPER_VIA_G1",
    "label": "Casper CASPER_VIA_G1",
    "value": "Casper CASPER_VIA_G1"
  },
  {
    "name": "LQ-M7863 LQ-M7863",
    "label": "LQ-M7863 LQ-M7863",
    "value": "LQ-M7863 LQ-M7863"
  },
  {
    "name": "HYUNDAI Ultra Vision",
    "label": "HYUNDAI Ultra Vision",
    "value": "HYUNDAI Ultra Vision"
  },
  {
    "name": "Google Acer Chromebook 15 (CB3-532)",
    "label": "Google Acer Chromebook 15 (CB3-532)",
    "value": "Google Acer Chromebook 15 (CB3-532)"
  },
  {
    "name": "OPPO PFVM10",
    "label": "OPPO PFVM10",
    "value": "OPPO PFVM10"
  },
  {
    "name": "ZTE ZTE A2021L",
    "label": "ZTE ZTE A2021L",
    "value": "ZTE ZTE A2021L"
  },
  {
    "name": "A-gold BV9800",
    "label": "A-gold BV9800",
    "value": "A-gold BV9800"
  },
  {
    "name": "Apple iPad14,4",
    "label": "Apple iPad14,4",
    "value": "Apple iPad14,4"
  },
  {
    "name": "Ulefone Power_6",
    "label": "Ulefone Power_6",
    "value": "Ulefone Power_6"
  },
  {
    "name": "Xiaomi 22120RN86G",
    "label": "Xiaomi 22120RN86G",
    "value": "Xiaomi 22120RN86G"
  },
  {
    "name": "Apple Desisderia’s iPhone",
    "label": "Apple Desisderia’s iPhone",
    "value": "Apple Desisderia’s iPhone"
  },
  {
    "name": "DEXP S670",
    "label": "DEXP S670",
    "value": "DEXP S670"
  },
  {
    "name": "Apple 민준의 iPhone",
    "label": "Apple 민준의 iPhone",
    "value": "Apple 민준의 iPhone"
  },
  {
    "name": "Apple iPhone de Valeria",
    "label": "Apple iPhone de Valeria",
    "value": "Apple iPhone de Valeria"
  },
  {
    "name": "Hyundai 8LAB1",
    "label": "Hyundai 8LAB1",
    "value": "Hyundai 8LAB1"
  },
  {
    "name": "Amlogic X96mini",
    "label": "Amlogic X96mini",
    "value": "Amlogic X96mini"
  },
  {
    "name": "motorola motorola one vision plus",
    "label": "motorola motorola one vision plus",
    "value": "motorola motorola one vision plus"
  },
  {
    "name": "GREAT ARTISANS Sugoi SM1",
    "label": "GREAT ARTISANS Sugoi SM1",
    "value": "GREAT ARTISANS Sugoi SM1"
  },
  {
    "name": "Apple Keitumetse’s iPhone",
    "label": "Apple Keitumetse’s iPhone",
    "value": "Apple Keitumetse’s iPhone"
  },
  {
    "name": "Apple Lebohang’s iPhone",
    "label": "Apple Lebohang’s iPhone",
    "value": "Apple Lebohang’s iPhone"
  },
  {
    "name": "Apple Jenniffer❤️‍🩹",
    "label": "Apple Jenniffer❤️‍🩹",
    "value": "Apple Jenniffer❤️‍🩹"
  },
  {
    "name": "Apple Yamisa’s iPhone",
    "label": "Apple Yamisa’s iPhone",
    "value": "Apple Yamisa’s iPhone"
  },
  {
    "name": "Apple iPhone de Vanesa",
    "label": "Apple iPhone de Vanesa",
    "value": "Apple iPhone de Vanesa"
  },
  {
    "name": "Apple iPhone de Candy",
    "label": "Apple iPhone de Candy",
    "value": "Apple iPhone de Candy"
  },
  {
    "name": "Apple Annarose’s iPhone",
    "label": "Apple Annarose’s iPhone",
    "value": "Apple Annarose’s iPhone"
  },
  {
    "name": "Apple 건들지마세요 물어요",
    "label": "Apple 건들지마세요 물어요",
    "value": "Apple 건들지마세요 물어요"
  },
  {
    "name": "Apple Ye Yeong의 iPhone",
    "label": "Apple Ye Yeong의 iPhone",
    "value": "Apple Ye Yeong의 iPhone"
  },
  {
    "name": "Apple iPhone🚬",
    "label": "Apple iPhone🚬",
    "value": "Apple iPhone🚬"
  },
  {
    "name": "LGE LGM-K120L",
    "label": "LGE LGM-K120L",
    "value": "LGE LGM-K120L"
  },
  {
    "name": "WINSTAR S-3",
    "label": "WINSTAR S-3",
    "value": "WINSTAR S-3"
  },
  {
    "name": "Apple Just’s iPhone",
    "label": "Apple Just’s iPhone",
    "value": "Apple Just’s iPhone"
  },
  {
    "name": "Apple Agcobile’s iPhone",
    "label": "Apple Agcobile’s iPhone",
    "value": "Apple Agcobile’s iPhone"
  },
  {
    "name": "Droi Wigor V4",
    "label": "Droi Wigor V4",
    "value": "Droi Wigor V4"
  },
  {
    "name": "DIXON KT5512",
    "label": "DIXON KT5512",
    "value": "DIXON KT5512"
  },
  {
    "name": "skythtek TitaniumColorsMax",
    "label": "skythtek TitaniumColorsMax",
    "value": "skythtek TitaniumColorsMax"
  },
  {
    "name": "Apple 이정원의 iPhone",
    "label": "Apple 이정원의 iPhone",
    "value": "Apple 이정원의 iPhone"
  },
  {
    "name": "Apple 미지남",
    "label": "Apple 미지남",
    "value": "Apple 미지남"
  },
  {
    "name": "Apple 전종현",
    "label": "Apple 전종현",
    "value": "Apple 전종현"
  },
  {
    "name": "Cherry_Mobile Omega Lite 3s",
    "label": "Cherry_Mobile Omega Lite 3s",
    "value": "Cherry_Mobile Omega Lite 3s"
  },
  {
    "name": "Apple Kool의 iPhone",
    "label": "Apple Kool의 iPhone",
    "value": "Apple Kool의 iPhone"
  },
  {
    "name": "iPad de Fj y Vl iPad",
    "label": "iPad de Fj y Vl iPad",
    "value": "iPad de Fj y Vl iPad"
  },
  {
    "name": "Apple 김찬형의 iPhone",
    "label": "Apple 김찬형의 iPhone",
    "value": "Apple 김찬형의 iPhone"
  },
  {
    "name": "Apple Jeon. ",
    "label": "Apple Jeon. ",
    "value": "Apple Jeon. "
  },
  {
    "name": "WOWI SQ788",
    "label": "WOWI SQ788",
    "value": "WOWI SQ788"
  },
  {
    "name": "Realme RMX1822",
    "label": "Realme RMX1822",
    "value": "Realme RMX1822"
  },
  {
    "name": "vivo V1921A",
    "label": "vivo V1921A",
    "value": "vivo V1921A"
  },
  {
    "name": "LENOVO Lenovo YT-J706F",
    "label": "LENOVO Lenovo YT-J706F",
    "value": "LENOVO Lenovo YT-J706F"
  },
  {
    "name": "Apple ה-iPhone של אליהב👑",
    "label": "Apple ה-iPhone של אליהב👑",
    "value": "Apple ה-iPhone של אליהב👑"
  },
  {
    "name": "Blackview BL5000",
    "label": "Blackview BL5000",
    "value": "Blackview BL5000"
  },
  {
    "name": "Apple user’s iPhone",
    "label": "Apple user’s iPhone",
    "value": "Apple user’s iPhone"
  },
  {
    "name": "Apple Lemmy’s iPhone",
    "label": "Apple Lemmy’s iPhone",
    "value": "Apple Lemmy’s iPhone"
  },
  {
    "name": "Apple TISH",
    "label": "Apple TISH",
    "value": "Apple TISH"
  },
  {
    "name": "Apple Gxneral’s iPhone✅k",
    "label": "Apple Gxneral’s iPhone✅k",
    "value": "Apple Gxneral’s iPhone✅k"
  },
  {
    "name": "OnePlus NE2217",
    "label": "OnePlus NE2217",
    "value": "OnePlus NE2217"
  },
  {
    "name": "Blackview BV7200",
    "label": "Blackview BV7200",
    "value": "Blackview BV7200"
  },
  {
    "name": "MAZE_SPEED MS5424G",
    "label": "MAZE_SPEED MS5424G",
    "value": "MAZE_SPEED MS5424G"
  },
  {
    "name": "Apple TRIX 🌹",
    "label": "Apple TRIX 🌹",
    "value": "Apple TRIX 🌹"
  },
  {
    "name": "Apple iPhone de Nicol",
    "label": "Apple iPhone de Nicol",
    "value": "Apple iPhone de Nicol"
  },
  {
    "name": "Apple iPhone de Andres",
    "label": "Apple iPhone de Andres",
    "value": "Apple iPhone de Andres"
  },
  {
    "name": "Lanix Alpha 5V",
    "label": "Lanix Alpha 5V",
    "value": "Lanix Alpha 5V"
  },
  {
    "name": "TECLAST M40_ROW",
    "label": "TECLAST M40_ROW",
    "value": "TECLAST M40_ROW"
  },
  {
    "name": "vivo vivo 1939",
    "label": "vivo vivo 1939",
    "value": "vivo vivo 1939"
  },
  {
    "name": "Azumi A5",
    "label": "Azumi A5",
    "value": "Azumi A5"
  },
  {
    "name": "TCL 6165S",
    "label": "TCL 6165S",
    "value": "TCL 6165S"
  },
  {
    "name": "DOOGEE S95",
    "label": "DOOGEE S95",
    "value": "DOOGEE S95"
  },
  {
    "name": "Apple iPhone de Delfina",
    "label": "Apple iPhone de Delfina",
    "value": "Apple iPhone de Delfina"
  },
  {
    "name": "Apple iPhone de Alan",
    "label": "Apple iPhone de Alan",
    "value": "Apple iPhone de Alan"
  },
  {
    "name": "Apple Karin’s iPhone",
    "label": "Apple Karin’s iPhone",
    "value": "Apple Karin’s iPhone"
  },
  {
    "name": "Xiaomi Redmi K20 Pro Premium Edition",
    "label": "Xiaomi Redmi K20 Pro Premium Edition",
    "value": "Xiaomi Redmi K20 Pro Premium Edition"
  },
  {
    "name": "Apple S musharraf’s iPhone",
    "label": "Apple S musharraf’s iPhone",
    "value": "Apple S musharraf’s iPhone"
  },
  {
    "name": "positivo Twist Metal 32GB",
    "label": "positivo Twist Metal 32GB",
    "value": "positivo Twist Metal 32GB"
  },
  {
    "name": "nubia NX709S",
    "label": "nubia NX709S",
    "value": "nubia NX709S"
  },
  {
    "name": "SHARP SHV45",
    "label": "SHARP SHV45",
    "value": "SHARP SHV45"
  },
  {
    "name": "OPPO OPPO R11",
    "label": "OPPO OPPO R11",
    "value": "OPPO OPPO R11"
  },
  {
    "name": "Apple 이원겸의 iPhone",
    "label": "Apple 이원겸의 iPhone",
    "value": "Apple 이원겸의 iPhone"
  },
  {
    "name": "Apple martin",
    "label": "Apple martin",
    "value": "Apple martin"
  },
  {
    "name": "Apple Mitch 🥀",
    "label": "Apple Mitch 🥀",
    "value": "Apple Mitch 🥀"
  },
  {
    "name": "TCL 8491X_EEA",
    "label": "TCL 8491X_EEA",
    "value": "TCL 8491X_EEA"
  },
  {
    "name": "OnePlus LE2110",
    "label": "OnePlus LE2110",
    "value": "OnePlus LE2110"
  },
  {
    "name": "VSUN tab0024c",
    "label": "VSUN tab0024c",
    "value": "VSUN tab0024c"
  },
  {
    "name": "Xiaomi LG V30",
    "label": "Xiaomi LG V30",
    "value": "Xiaomi LG V30"
  },
  {
    "name": "W\\&O Max16",
    "label": "W\\&O Max16",
    "value": "W\\&O Max16"
  },
  {
    "name": "Xiaomi SM-G965F",
    "label": "Xiaomi SM-G965F",
    "value": "Xiaomi SM-G965F"
  },
  {
    "name": "Apple Misael 24",
    "label": "Apple Misael 24",
    "value": "Apple Misael 24"
  },
  {
    "name": "A800 P10",
    "label": "A800 P10",
    "value": "A800 P10"
  },
  {
    "name": "Apple iPhone de jhossy",
    "label": "Apple iPhone de jhossy",
    "value": "Apple iPhone de jhossy"
  },
  {
    "name": "Apple 서뇽🌙",
    "label": "Apple 서뇽🌙",
    "value": "Apple 서뇽🌙"
  },
  {
    "name": "Apple 김목요의 iPhone",
    "label": "Apple 김목요의 iPhone",
    "value": "Apple 김목요의 iPhone"
  },
  {
    "name": "Apple iPhone de Luisan",
    "label": "Apple iPhone de Luisan",
    "value": "Apple iPhone de Luisan"
  },
  {
    "name": "Apple iPhone de Elmer",
    "label": "Apple iPhone de Elmer",
    "value": "Apple iPhone de Elmer"
  },
  {
    "name": "Apple Cyril’s iPhone",
    "label": "Apple Cyril’s iPhone",
    "value": "Apple Cyril’s iPhone"
  },
  {
    "name": "ALDO XS",
    "label": "ALDO XS",
    "value": "ALDO XS"
  },
  {
    "name": "hena NoteTab_Pro",
    "label": "hena NoteTab_Pro",
    "value": "hena NoteTab_Pro"
  },
  {
    "name": "hena M17QF7_4G",
    "label": "hena M17QF7_4G",
    "value": "hena M17QF7_4G"
  },
  {
    "name": "motorola XT1635-01",
    "label": "motorola XT1635-01",
    "value": "motorola XT1635-01"
  },
  {
    "name": "Sony J8210",
    "label": "Sony J8210",
    "value": "Sony J8210"
  },
  {
    "name": "Apple Kelma’s iPhone",
    "label": "Apple Kelma’s iPhone",
    "value": "Apple Kelma’s iPhone"
  },
  {
    "name": "vivo V2036A",
    "label": "vivo V2036A",
    "value": "vivo V2036A"
  },
  {
    "name": "GIONEE F6",
    "label": "GIONEE F6",
    "value": "GIONEE F6"
  },
  {
    "name": "samsung SM-P555M",
    "label": "samsung SM-P555M",
    "value": "samsung SM-P555M"
  },
  {
    "name": "Archos Archos T101 HD 4G",
    "label": "Archos Archos T101 HD 4G",
    "value": "Archos Archos T101 HD 4G"
  },
  {
    "name": "Premio S87",
    "label": "Premio S87",
    "value": "Premio S87"
  },
  {
    "name": "OLAX Magic Q1",
    "label": "OLAX Magic Q1",
    "value": "OLAX Magic Q1"
  },
  {
    "name": "OPPO CPH2031",
    "label": "OPPO CPH2031",
    "value": "OPPO CPH2031"
  },
  {
    "name": "Apple Nati🌸",
    "label": "Apple Nati🌸",
    "value": "Apple Nati🌸"
  },
  {
    "name": "WIKO W-V720-OPE",
    "label": "WIKO W-V720-OPE",
    "value": "WIKO W-V720-OPE"
  },
  {
    "name": "Apple Mumthaz’s iPhone",
    "label": "Apple Mumthaz’s iPhone",
    "value": "Apple Mumthaz’s iPhone"
  },
  {
    "name": "TCL 5007S",
    "label": "TCL 5007S",
    "value": "TCL 5007S"
  },
  {
    "name": "HUAWEI JER-AN10",
    "label": "HUAWEI JER-AN10",
    "value": "HUAWEI JER-AN10"
  },
  {
    "name": "OnePlus CPH2411",
    "label": "OnePlus CPH2411",
    "value": "OnePlus CPH2411"
  },
  {
    "name": "Apple Bridget’s iPhone",
    "label": "Apple Bridget’s iPhone",
    "value": "Apple Bridget’s iPhone"
  },
  {
    "name": "Alek’s iPad iPad",
    "label": "Alek’s iPad iPad",
    "value": "Alek’s iPad iPad"
  },
  {
    "name": "Takumi KAZ-N20",
    "label": "Takumi KAZ-N20",
    "value": "Takumi KAZ-N20"
  },
  {
    "name": "MBI S10",
    "label": "MBI S10",
    "value": "MBI S10"
  },
  {
    "name": "vernee X2_euro",
    "label": "vernee X2_euro",
    "value": "vernee X2_euro"
  },
  {
    "name": "CORN Y60",
    "label": "CORN Y60",
    "value": "CORN Y60"
  },
  {
    "name": "naomicase DAMMAR",
    "label": "naomicase DAMMAR",
    "value": "naomicase DAMMAR"
  },
  {
    "name": "TCL Smarttab8_4G",
    "label": "TCL Smarttab8_4G",
    "value": "TCL Smarttab8_4G"
  },
  {
    "name": "Apple Chantel’s iPhone",
    "label": "Apple Chantel’s iPhone",
    "value": "Apple Chantel’s iPhone"
  },
  {
    "name": "meizu meizu 17",
    "label": "meizu meizu 17",
    "value": "meizu meizu 17"
  },
  {
    "name": "HMD Global Nokia 8 V 5G UW",
    "label": "HMD Global Nokia 8 V 5G UW",
    "value": "HMD Global Nokia 8 V 5G UW"
  },
  {
    "name": "alps Rino 9pro",
    "label": "alps Rino 9pro",
    "value": "alps Rino 9pro"
  },
  {
    "name": "vivo vivo X21A",
    "label": "vivo vivo X21A",
    "value": "vivo vivo X21A"
  },
  {
    "name": "LAVA iris88_go",
    "label": "LAVA iris88_go",
    "value": "LAVA iris88_go"
  },
  {
    "name": "Apple Kimberly’s iPhone",
    "label": "Apple Kimberly’s iPhone",
    "value": "Apple Kimberly’s iPhone"
  },
  {
    "name": "Apple Nathach’s iPhone",
    "label": "Apple Nathach’s iPhone",
    "value": "Apple Nathach’s iPhone"
  },
  {
    "name": "Apple 김정은의 iPhone",
    "label": "Apple 김정은의 iPhone",
    "value": "Apple 김정은의 iPhone"
  },
  {
    "name": "GIONEE P10m",
    "label": "GIONEE P10m",
    "value": "GIONEE P10m"
  },
  {
    "name": "BLU T6L",
    "label": "BLU T6L",
    "value": "BLU T6L"
  },
  {
    "name": "OPPO PEDM00",
    "label": "OPPO PEDM00",
    "value": "OPPO PEDM00"
  },
  {
    "name": "Teclast P25_T_ROW",
    "label": "Teclast P25_T_ROW",
    "value": "Teclast P25_T_ROW"
  },
  {
    "name": "TCL T781S",
    "label": "TCL T781S",
    "value": "TCL T781S"
  },
  {
    "name": "itel itel A62",
    "label": "itel itel A62",
    "value": "itel itel A62"
  },
  {
    "name": "LEXAND SC7 PRO HD",
    "label": "LEXAND SC7 PRO HD",
    "value": "LEXAND SC7 PRO HD"
  },
  {
    "name": "Innovatech C3",
    "label": "Innovatech C3",
    "value": "Innovatech C3"
  },
  {
    "name": "Apple Baroron",
    "label": "Apple Baroron",
    "value": "Apple Baroron"
  },
  {
    "name": "Apple iPhone 12 Pro Max ",
    "label": "Apple iPhone 12 Pro Max ",
    "value": "Apple iPhone 12 Pro Max "
  },
  {
    "name": "magcomm X6",
    "label": "magcomm X6",
    "value": "magcomm X6"
  },
  {
    "name": "PREMIO S53",
    "label": "PREMIO S53",
    "value": "PREMIO S53"
  },
  {
    "name": "HUAWEI BLN-L22",
    "label": "HUAWEI BLN-L22",
    "value": "HUAWEI BLN-L22"
  },
  {
    "name": "Apple iPhone azucena",
    "label": "Apple iPhone azucena",
    "value": "Apple iPhone azucena"
  },
  {
    "name": "OUKITEL WP16",
    "label": "OUKITEL WP16",
    "value": "OUKITEL WP16"
  },
  {
    "name": "FISE PowerMaxP490S_EU",
    "label": "FISE PowerMaxP490S_EU",
    "value": "FISE PowerMaxP490S_EU"
  },
  {
    "name": "Blackview Tab 7",
    "label": "Blackview Tab 7",
    "value": "Blackview Tab 7"
  },
  {
    "name": "360 1711-A01",
    "label": "360 1711-A01",
    "value": "360 1711-A01"
  },
  {
    "name": "Apple Hloni’s iPhone.",
    "label": "Apple Hloni’s iPhone.",
    "value": "Apple Hloni’s iPhone."
  },
  {
    "name": "Xiaomi 2107113SR",
    "label": "Xiaomi 2107113SR",
    "value": "Xiaomi 2107113SR"
  },
  {
    "name": "A81 A81",
    "label": "A81 A81",
    "value": "A81 A81"
  },
  {
    "name": "Hisense Hisense E32 Lite",
    "label": "Hisense Hisense E32 Lite",
    "value": "Hisense Hisense E32 Lite"
  },
  {
    "name": "Apple Sumon Kumar Sarkar@i’phone6s",
    "label": "Apple Sumon Kumar Sarkar@i’phone6s",
    "value": "Apple Sumon Kumar Sarkar@i’phone6s"
  },
  {
    "name": "Apple iPhone de Tony👁",
    "label": "Apple iPhone de Tony👁",
    "value": "Apple iPhone de Tony👁"
  },
  {
    "name": "Apple iPhone de Hans",
    "label": "Apple iPhone de Hans",
    "value": "Apple iPhone de Hans"
  },
  {
    "name": "Apple May💕",
    "label": "Apple May💕",
    "value": "Apple May💕"
  },
  {
    "name": "Apple 옌",
    "label": "Apple 옌",
    "value": "Apple 옌"
  },
  {
    "name": "LGE LG-F800L",
    "label": "LGE LG-F800L",
    "value": "LGE LG-F800L"
  },
  {
    "name": "AB PRO-M2",
    "label": "AB PRO-M2",
    "value": "AB PRO-M2"
  },
  {
    "name": "incar ADT1061_1",
    "label": "incar ADT1061_1",
    "value": "incar ADT1061_1"
  },
  {
    "name": "LGE LM-V500",
    "label": "LGE LM-V500",
    "value": "LGE LM-V500"
  },
  {
    "name": "TINNO U328AA",
    "label": "TINNO U328AA",
    "value": "TINNO U328AA"
  },
  {
    "name": "ZTE Nubia 8011",
    "label": "ZTE Nubia 8011",
    "value": "ZTE Nubia 8011"
  },
  {
    "name": "Apple iPhone de Cristina",
    "label": "Apple iPhone de Cristina",
    "value": "Apple iPhone de Cristina"
  },
  {
    "name": "evertrade Flame_Plus",
    "label": "evertrade Flame_Plus",
    "value": "evertrade Flame_Plus"
  },
  {
    "name": "Hisense Hisense Infinity H60 5G",
    "label": "Hisense Hisense Infinity H60 5G",
    "value": "Hisense Hisense Infinity H60 5G"
  },
  {
    "name": "vivo vivo X21i A",
    "label": "vivo vivo X21i A",
    "value": "vivo vivo X21i A"
  },
  {
    "name": "Xiaomi lancelot",
    "label": "Xiaomi lancelot",
    "value": "Xiaomi lancelot"
  },
  {
    "name": "HUAWEI LON-AL00",
    "label": "HUAWEI LON-AL00",
    "value": "HUAWEI LON-AL00"
  },
  {
    "name": "Apple Nande’s iPhone",
    "label": "Apple Nande’s iPhone",
    "value": "Apple Nande’s iPhone"
  },
  {
    "name": "Apple Hubert Damen",
    "label": "Apple Hubert Damen",
    "value": "Apple Hubert Damen"
  },
  {
    "name": "Apple kyung",
    "label": "Apple kyung",
    "value": "Apple kyung"
  },
  {
    "name": "Apple Telshia’s iPhone",
    "label": "Apple Telshia’s iPhone",
    "value": "Apple Telshia’s iPhone"
  },
  {
    "name": "Apple macbook’s iPhone",
    "label": "Apple macbook’s iPhone",
    "value": "Apple macbook’s iPhone"
  },
  {
    "name": "Apple Uju’s iPhone",
    "label": "Apple Uju’s iPhone",
    "value": "Apple Uju’s iPhone"
  },
  {
    "name": "Symphony Symphony Z32",
    "label": "Symphony Symphony Z32",
    "value": "Symphony Symphony Z32"
  },
  {
    "name": "Ulefone Armor 9E",
    "label": "Ulefone Armor 9E",
    "value": "Ulefone Armor 9E"
  },
  {
    "name": "Xwireless LLC TAB8",
    "label": "Xwireless LLC TAB8",
    "value": "Xwireless LLC TAB8"
  },
  {
    "name": "Xiaomi Redmi Note 10 Pro",
    "label": "Xiaomi Redmi Note 10 Pro",
    "value": "Xiaomi Redmi Note 10 Pro"
  },
  {
    "name": "asus ASUS_AI2201_C",
    "label": "asus ASUS_AI2201_C",
    "value": "asus ASUS_AI2201_C"
  },
  {
    "name": "samsung SM-F900U1",
    "label": "samsung SM-F900U1",
    "value": "samsung SM-F900U1"
  },
  {
    "name": "MAXFONE MAX30S",
    "label": "MAXFONE MAX30S",
    "value": "MAXFONE MAX30S"
  },
  {
    "name": "LENOVO Lenovo PB-6505Y",
    "label": "LENOVO Lenovo PB-6505Y",
    "value": "LENOVO Lenovo PB-6505Y"
  },
  {
    "name": "deejoy TP7A6",
    "label": "deejoy TP7A6",
    "value": "deejoy TP7A6"
  },
  {
    "name": "WALTON Primo GH10",
    "label": "WALTON Primo GH10",
    "value": "WALTON Primo GH10"
  },
  {
    "name": "samsung SM-G600F",
    "label": "samsung SM-G600F",
    "value": "samsung SM-G600F"
  },
  {
    "name": "Multilaser M7SQC_Plus",
    "label": "Multilaser M7SQC_Plus",
    "value": "Multilaser M7SQC_Plus"
  },
  {
    "name": "OPPO PGCM10",
    "label": "OPPO PGCM10",
    "value": "OPPO PGCM10"
  },
  {
    "name": "PREMIO S73",
    "label": "PREMIO S73",
    "value": "PREMIO S73"
  },
  {
    "name": "HOW 1001-G Go",
    "label": "HOW 1001-G Go",
    "value": "HOW 1001-G Go"
  },
  {
    "name": "Apple Sukuna desu~~",
    "label": "Apple Sukuna desu~~",
    "value": "Apple Sukuna desu~~"
  },
  {
    "name": "Apple bakugo katsuki",
    "label": "Apple bakugo katsuki",
    "value": "Apple bakugo katsuki"
  },
  {
    "name": "Apple JUDY",
    "label": "Apple JUDY",
    "value": "Apple JUDY"
  },
  {
    "name": "Apple Alfredo Montoya",
    "label": "Apple Alfredo Montoya",
    "value": "Apple Alfredo Montoya"
  },
  {
    "name": "Apple 윤다연의 iPhone",
    "label": "Apple 윤다연의 iPhone",
    "value": "Apple 윤다연의 iPhone"
  },
  {
    "name": "iPad Air (4th generation) iPad",
    "label": "iPad Air (4th generation) iPad",
    "value": "iPad Air (4th generation) iPad"
  },
  {
    "name": "JOYAR TAB10A3G02",
    "label": "JOYAR TAB10A3G02",
    "value": "JOYAR TAB10A3G02"
  },
  {
    "name": "Shenzhen E-dong Technology Co.,Ltd TAB4",
    "label": "Shenzhen E-dong Technology Co.,Ltd TAB4",
    "value": "Shenzhen E-dong Technology Co.,Ltd TAB4"
  },
  {
    "name": "Apple iPhone ",
    "label": "Apple iPhone ",
    "value": "Apple iPhone "
  },
  {
    "name": "vivo V2171A",
    "label": "vivo V2171A",
    "value": "vivo V2171A"
  },
  {
    "name": "TCL 9060X",
    "label": "TCL 9060X",
    "value": "TCL 9060X"
  },
  {
    "name": "TCL T767G",
    "label": "TCL T767G",
    "value": "TCL T767G"
  },
  {
    "name": "TCL 5033X",
    "label": "TCL 5033X",
    "value": "TCL 5033X"
  },
  {
    "name": "General Mobile G300",
    "label": "General Mobile G300",
    "value": "General Mobile G300"
  },
  {
    "name": "samsung SC-05L",
    "label": "samsung SC-05L",
    "value": "samsung SC-05L"
  },
  {
    "name": "KONROW SKY_55_EEA",
    "label": "KONROW SKY_55_EEA",
    "value": "KONROW SKY_55_EEA"
  },
  {
    "name": "Brava DU-600",
    "label": "Brava DU-600",
    "value": "Brava DU-600"
  },
  {
    "name": "Crosscall Core-M5",
    "label": "Crosscall Core-M5",
    "value": "Crosscall Core-M5"
  },
  {
    "name": "incar Hope7 Max",
    "label": "incar Hope7 Max",
    "value": "incar Hope7 Max"
  },
  {
    "name": "TCL 5001D_EEA",
    "label": "TCL 5001D_EEA",
    "value": "TCL 5001D_EEA"
  },
  {
    "name": "HUAWEI LDN-TL10",
    "label": "HUAWEI LDN-TL10",
    "value": "HUAWEI LDN-TL10"
  },
  {
    "name": "LENOVO Lenovo TB-X605F",
    "label": "LENOVO Lenovo TB-X605F",
    "value": "LENOVO Lenovo TB-X605F"
  },
  {
    "name": "HTC HTV32",
    "label": "HTC HTV32",
    "value": "HTC HTV32"
  },
  {
    "name": "joyar MT003-20",
    "label": "joyar MT003-20",
    "value": "joyar MT003-20"
  },
  {
    "name": "HUAWEI FRL-AN00a",
    "label": "HUAWEI FRL-AN00a",
    "value": "HUAWEI FRL-AN00a"
  },
  {
    "name": "LAVA LAVA LF9820",
    "label": "LAVA LAVA LF9820",
    "value": "LAVA LAVA LF9820"
  },
  {
    "name": "Apple iPhone de Alba",
    "label": "Apple iPhone de Alba",
    "value": "Apple iPhone de Alba"
  },
  {
    "name": "Apple 지봉준의 iPhone",
    "label": "Apple 지봉준의 iPhone",
    "value": "Apple 지봉준의 iPhone"
  },
  {
    "name": "Apple pox",
    "label": "Apple pox",
    "value": "Apple pox"
  },
  {
    "name": "Apple Prasanta Ranjan Sethi’s iPhone ",
    "label": "Apple Prasanta Ranjan Sethi’s iPhone ",
    "value": "Apple Prasanta Ranjan Sethi’s iPhone "
  },
  {
    "name": "GOODTEL G2",
    "label": "GOODTEL G2",
    "value": "GOODTEL G2"
  },
  {
    "name": "vivo V2118A",
    "label": "vivo V2118A",
    "value": "vivo V2118A"
  },
  {
    "name": "unknown Phh-Treble vanilla",
    "label": "unknown Phh-Treble vanilla",
    "value": "unknown Phh-Treble vanilla"
  },
  {
    "name": "Gear_Mobile GM10",
    "label": "Gear_Mobile GM10",
    "value": "Gear_Mobile GM10"
  },
  {
    "name": "samsung SM-N900",
    "label": "samsung SM-N900",
    "value": "samsung SM-N900"
  },
  {
    "name": "samsung SM-G770U1",
    "label": "samsung SM-G770U1",
    "value": "samsung SM-G770U1"
  },
  {
    "name": "Cherry_Mobile Pulse",
    "label": "Cherry_Mobile Pulse",
    "value": "Cherry_Mobile Pulse"
  },
  {
    "name": "X-TIGI JOY10 Mate",
    "label": "X-TIGI JOY10 Mate",
    "value": "X-TIGI JOY10 Mate"
  },
  {
    "name": "Apple 홍차",
    "label": "Apple 홍차",
    "value": "Apple 홍차"
  },
  {
    "name": "Apple GP’s iPhone11",
    "label": "Apple GP’s iPhone11",
    "value": "Apple GP’s iPhone11"
  },
  {
    "name": "Apple iPhone de me",
    "label": "Apple iPhone de me",
    "value": "Apple iPhone de me"
  },
  {
    "name": "Apple 신소희의 iPhone",
    "label": "Apple 신소희의 iPhone",
    "value": "Apple 신소희의 iPhone"
  },
  {
    "name": "Apple izmeee",
    "label": "Apple izmeee",
    "value": "Apple izmeee"
  },
  {
    "name": "Apple U+Net",
    "label": "Apple U+Net",
    "value": "Apple U+Net"
  },
  {
    "name": "iPad de Cory iPad",
    "label": "iPad de Cory iPad",
    "value": "iPad de Cory iPad"
  },
  {
    "name": "QMobile QTab Y1",
    "label": "QMobile QTab Y1",
    "value": "QMobile QTab Y1"
  },
  {
    "name": "GHIA GTVR10S",
    "label": "GHIA GTVR10S",
    "value": "GHIA GTVR10S"
  },
  {
    "name": "rockchip TVBOX",
    "label": "rockchip TVBOX",
    "value": "rockchip TVBOX"
  },
  {
    "name": "ZATEC JOY_PLUS",
    "label": "ZATEC JOY_PLUS",
    "value": "ZATEC JOY_PLUS"
  },
  {
    "name": "Sony XQ-AS62",
    "label": "Sony XQ-AS62",
    "value": "Sony XQ-AS62"
  },
  {
    "name": "Xiaomi MI 9 Transparent Edition",
    "label": "Xiaomi MI 9 Transparent Edition",
    "value": "Xiaomi MI 9 Transparent Edition"
  },
  {
    "name": "Fortune Ship SPC GEN PLUS",
    "label": "Fortune Ship SPC GEN PLUS",
    "value": "Fortune Ship SPC GEN PLUS"
  },
  {
    "name": "Xiaomi MI PAD 4 PLUS",
    "label": "Xiaomi MI PAD 4 PLUS",
    "value": "Xiaomi MI PAD 4 PLUS"
  },
  {
    "name": "Movitel M9105",
    "label": "Movitel M9105",
    "value": "Movitel M9105"
  },
  {
    "name": "EXCEED EX10SL4",
    "label": "EXCEED EX10SL4",
    "value": "EXCEED EX10SL4"
  },
  {
    "name": "TechPad Techpad_10xx",
    "label": "TechPad Techpad_10xx",
    "value": "TechPad Techpad_10xx"
  },
  {
    "name": "DEXP A440",
    "label": "DEXP A440",
    "value": "DEXP A440"
  },
  {
    "name": "Apple iPhone Karla",
    "label": "Apple iPhone Karla",
    "value": "Apple iPhone Karla"
  },
  {
    "name": "inovo I07",
    "label": "inovo I07",
    "value": "inovo I07"
  },
  {
    "name": "TCL 5058I",
    "label": "TCL 5058I",
    "value": "TCL 5058I"
  },
  {
    "name": "TWL F2X",
    "label": "TWL F2X",
    "value": "TWL F2X"
  },
  {
    "name": "Xiaomi Redmi 8 Series",
    "label": "Xiaomi Redmi 8 Series",
    "value": "Xiaomi Redmi 8 Series"
  },
  {
    "name": "GRAND B0EING",
    "label": "GRAND B0EING",
    "value": "GRAND B0EING"
  },
  {
    "name": "GIONEE GIONEE S11 Lite",
    "label": "GIONEE GIONEE S11 Lite",
    "value": "GIONEE GIONEE S11 Lite"
  },
  {
    "name": "HUAWEI JDN2-W09",
    "label": "HUAWEI JDN2-W09",
    "value": "HUAWEI JDN2-W09"
  },
  {
    "name": "OPPO PBBM00",
    "label": "OPPO PBBM00",
    "value": "OPPO PBBM00"
  },
  {
    "name": "sanmu S2",
    "label": "sanmu S2",
    "value": "sanmu S2"
  },
  {
    "name": "Qnet K56",
    "label": "Qnet K56",
    "value": "Qnet K56"
  },
  {
    "name": "Apple iPhone Nairuby",
    "label": "Apple iPhone Nairuby",
    "value": "Apple iPhone Nairuby"
  },
  {
    "name": "Apple 🥀athan",
    "label": "Apple 🥀athan",
    "value": "Apple 🥀athan"
  },
  {
    "name": "Apple iPhone de Daniel",
    "label": "Apple iPhone de Daniel",
    "value": "Apple iPhone de Daniel"
  },
  {
    "name": "Apple 박영규의 iPhone",
    "label": "Apple 박영규의 iPhone",
    "value": "Apple 박영규의 iPhone"
  },
  {
    "name": "Apple 동주의 iPhone 12 mini",
    "label": "Apple 동주의 iPhone 12 mini",
    "value": "Apple 동주의 iPhone 12 mini"
  },
  {
    "name": "Apple iPhone de Khadija",
    "label": "Apple iPhone de Khadija",
    "value": "Apple iPhone de Khadija"
  },
  {
    "name": "Apple iPhone x",
    "label": "Apple iPhone x",
    "value": "Apple iPhone x"
  },
  {
    "name": "Apple 김재철의 iPhone",
    "label": "Apple 김재철의 iPhone",
    "value": "Apple 김재철의 iPhone"
  },
  {
    "name": "iPod touch iPod touch",
    "label": "iPod touch iPod touch",
    "value": "iPod touch iPod touch"
  },
  {
    "name": "Apple Tanaka’s iPhone",
    "label": "Apple Tanaka’s iPhone",
    "value": "Apple Tanaka’s iPhone"
  },
  {
    "name": "Mobicel HERO",
    "label": "Mobicel HERO",
    "value": "Mobicel HERO"
  },
  {
    "name": "Bridget’s iPad iPad",
    "label": "Bridget’s iPad iPad",
    "value": "Bridget’s iPad iPad"
  },
  {
    "name": "Joyar Benny",
    "label": "Joyar Benny",
    "value": "Joyar Benny"
  },
  {
    "name": "Xiaomi XQ-AS72",
    "label": "Xiaomi XQ-AS72",
    "value": "Xiaomi XQ-AS72"
  },
  {
    "name": "skythtek ALFA_8RC",
    "label": "skythtek ALFA_8RC",
    "value": "skythtek ALFA_8RC"
  },
  {
    "name": "LGE LML413DL",
    "label": "LGE LML413DL",
    "value": "LGE LML413DL"
  },
  {
    "name": "Amlogic AMAZE AX-50",
    "label": "Amlogic AMAZE AX-50",
    "value": "Amlogic AMAZE AX-50"
  },
  {
    "name": "IPRO S501 Plus",
    "label": "IPRO S501 Plus",
    "value": "IPRO S501 Plus"
  },
  {
    "name": "MAXIM S31960C15_216_V2_0_WXGA_10INCH_native",
    "label": "MAXIM S31960C15_216_V2_0_WXGA_10INCH_native",
    "value": "MAXIM S31960C15_216_V2_0_WXGA_10INCH_native"
  },
  {
    "name": "Lanix X540",
    "label": "Lanix X540",
    "value": "Lanix X540"
  },
  {
    "name": "iHunt Titan P8000 PRO 2021",
    "label": "iHunt Titan P8000 PRO 2021",
    "value": "iHunt Titan P8000 PRO 2021"
  },
  {
    "name": "Invens MAX5",
    "label": "Invens MAX5",
    "value": "Invens MAX5"
  },
  {
    "name": "AIRPHA Sense2_Pro",
    "label": "AIRPHA Sense2_Pro",
    "value": "AIRPHA Sense2_Pro"
  },
  {
    "name": "Win M3",
    "label": "Win M3",
    "value": "Win M3"
  },
  {
    "name": "Fortune Ship HTC Wildfire E1",
    "label": "Fortune Ship HTC Wildfire E1",
    "value": "Fortune Ship HTC Wildfire E1"
  },
  {
    "name": "Gigaset GS270 plus",
    "label": "Gigaset GS270 plus",
    "value": "Gigaset GS270 plus"
  },
  {
    "name": "iHunt Titan_P6000_PRO",
    "label": "iHunt Titan_P6000_PRO",
    "value": "iHunt Titan_P6000_PRO"
  },
  {
    "name": "CloudFone Next Infinity",
    "label": "CloudFone Next Infinity",
    "value": "CloudFone Next Infinity"
  },
  {
    "name": "Samsung Galaxy S10e",
    "label": "Samsung Galaxy S10e",
    "value": "Samsung Galaxy S10e"
  },
  {
    "name": "Xiaomi Redmi Note 8  (2021)",
    "label": "Xiaomi Redmi Note 8  (2021)",
    "value": "Xiaomi Redmi Note 8  (2021)"
  },
  {
    "name": "INFINIX Infinix X6821",
    "label": "INFINIX Infinix X6821",
    "value": "INFINIX Infinix X6821"
  },
  {
    "name": "Multilaser MS80X",
    "label": "Multilaser MS80X",
    "value": "Multilaser MS80X"
  },
  {
    "name": "OPPO PEEM00",
    "label": "OPPO PEEM00",
    "value": "OPPO PEEM00"
  },
  {
    "name": "vivo V1809T",
    "label": "vivo V1809T",
    "value": "vivo V1809T"
  },
  {
    "name": "MLS iQA27X_E",
    "label": "MLS iQA27X_E",
    "value": "MLS iQA27X_E"
  },
  {
    "name": "Apple Lolita’s iPhone",
    "label": "Apple Lolita’s iPhone",
    "value": "Apple Lolita’s iPhone"
  },
  {
    "name": "Apple Vuyolwethu Azile’s iPhone",
    "label": "Apple Vuyolwethu Azile’s iPhone",
    "value": "Apple Vuyolwethu Azile’s iPhone"
  },
  {
    "name": "Apple iPhone de Maria camila",
    "label": "Apple iPhone de Maria camila",
    "value": "Apple iPhone de Maria camila"
  },
  {
    "name": "samsung SM-J530GM",
    "label": "samsung SM-J530GM",
    "value": "samsung SM-J530GM"
  },
  {
    "name": "DOOGEE V10",
    "label": "DOOGEE V10",
    "value": "DOOGEE V10"
  },
  {
    "name": "Masstel Masstel Tab",
    "label": "Masstel Masstel Tab",
    "value": "Masstel Masstel Tab"
  },
  {
    "name": "SUGAR SUGAR S20",
    "label": "SUGAR SUGAR S20",
    "value": "SUGAR SUGAR S20"
  },
  {
    "name": "ACCENT FAST73G",
    "label": "ACCENT FAST73G",
    "value": "ACCENT FAST73G"
  },
  {
    "name": "OPPO CPH2495",
    "label": "OPPO CPH2495",
    "value": "OPPO CPH2495"
  },
  {
    "name": "motorola motorola edge (2022)",
    "label": "motorola motorola edge (2022)",
    "value": "motorola motorola edge (2022)"
  },
  {
    "name": "wheatek P13 Blue Max Lite 32GB",
    "label": "wheatek P13 Blue Max Lite 32GB",
    "value": "wheatek P13 Blue Max Lite 32GB"
  },
  {
    "name": "IMG X7",
    "label": "IMG X7",
    "value": "IMG X7"
  },
  {
    "name": "Apple iPhone X",
    "label": "Apple iPhone X",
    "value": "Apple iPhone X"
  },
  {
    "name": "Apple iPhone de Carmen Teresa",
    "label": "Apple iPhone de Carmen Teresa",
    "value": "Apple iPhone de Carmen Teresa"
  },
  {
    "name": "AZUMI Azumi V51",
    "label": "AZUMI Azumi V51",
    "value": "AZUMI Azumi V51"
  },
  {
    "name": "UNONU UT3G",
    "label": "UNONU UT3G",
    "value": "UNONU UT3G"
  },
  {
    "name": "bnd X8MT16",
    "label": "bnd X8MT16",
    "value": "bnd X8MT16"
  },
  {
    "name": "SSmooth Smooth 5.0 2022",
    "label": "SSmooth Smooth 5.0 2022",
    "value": "SSmooth Smooth 5.0 2022"
  },
  {
    "name": "GIONEE GIONEE S11L",
    "label": "GIONEE GIONEE S11L",
    "value": "GIONEE GIONEE S11L"
  },
  {
    "name": "CASPER VIA A4",
    "label": "CASPER VIA A4",
    "value": "CASPER VIA A4"
  },
  {
    "name": "BQru BQ-5047L",
    "label": "BQru BQ-5047L",
    "value": "BQru BQ-5047L"
  },
  {
    "name": "samsung SM-T727A",
    "label": "samsung SM-T727A",
    "value": "samsung SM-T727A"
  },
  {
    "name": "Apple iPhone de Kiara",
    "label": "Apple iPhone de Kiara",
    "value": "Apple iPhone de Kiara"
  },
  {
    "name": "Apple iPhone de Dafna",
    "label": "Apple iPhone de Dafna",
    "value": "Apple iPhone de Dafna"
  },
  {
    "name": "Apple Karen Herrera iPhone",
    "label": "Apple Karen Herrera iPhone",
    "value": "Apple Karen Herrera iPhone"
  },
  {
    "name": "Apple iPhone de Ainara",
    "label": "Apple iPhone de Ainara",
    "value": "Apple iPhone de Ainara"
  },
  {
    "name": "TechPad NOTE 4CAM",
    "label": "TechPad NOTE 4CAM",
    "value": "TechPad NOTE 4CAM"
  },
  {
    "name": "Apple A",
    "label": "Apple A",
    "value": "Apple A"
  },
  {
    "name": "samsung SM-T377W",
    "label": "samsung SM-T377W",
    "value": "samsung SM-T377W"
  },
  {
    "name": "NVIDIA SHIELD Tablet K1",
    "label": "NVIDIA SHIELD Tablet K1",
    "value": "NVIDIA SHIELD Tablet K1"
  },
  {
    "name": "samsung SM-N935S",
    "label": "samsung SM-N935S",
    "value": "samsung SM-N935S"
  },
  {
    "name": "Apple Tojal’s iPhone",
    "label": "Apple Tojal’s iPhone",
    "value": "Apple Tojal’s iPhone"
  },
  {
    "name": "SIRAGON SP-6100",
    "label": "SIRAGON SP-6100",
    "value": "SIRAGON SP-6100"
  },
  {
    "name": "Apple Xs18.18",
    "label": "Apple Xs18.18",
    "value": "Apple Xs18.18"
  },
  {
    "name": "Apple iPhone de Noelia",
    "label": "Apple iPhone de Noelia",
    "value": "Apple iPhone de Noelia"
  },
  {
    "name": "Apple 공미나의 iPhone",
    "label": "Apple 공미나의 iPhone",
    "value": "Apple 공미나의 iPhone"
  },
  {
    "name": "worldchip M1092RV5",
    "label": "worldchip M1092RV5",
    "value": "worldchip M1092RV5"
  },
  {
    "name": "motorola XT1565",
    "label": "motorola XT1565",
    "value": "motorola XT1565"
  },
  {
    "name": "BKAV Bphone A Series",
    "label": "BKAV Bphone A Series",
    "value": "BKAV Bphone A Series"
  },
  {
    "name": "HUAWEI SEA-AL10",
    "label": "HUAWEI SEA-AL10",
    "value": "HUAWEI SEA-AL10"
  },
  {
    "name": "LENOVO Lenovo YT-X705X",
    "label": "LENOVO Lenovo YT-X705X",
    "value": "LENOVO Lenovo YT-X705X"
  },
  {
    "name": "Dunns_Mobile ETAB_M9041G",
    "label": "Dunns_Mobile ETAB_M9041G",
    "value": "Dunns_Mobile ETAB_M9041G"
  },
  {
    "name": "Dcode Cypher DS-CR1",
    "label": "Dcode Cypher DS-CR1",
    "value": "Dcode Cypher DS-CR1"
  },
  {
    "name": "nubia NX616J",
    "label": "nubia NX616J",
    "value": "nubia NX616J"
  },
  {
    "name": "Innovatech M20S",
    "label": "Innovatech M20S",
    "value": "Innovatech M20S"
  },
  {
    "name": "Apple Jin’s iPhone",
    "label": "Apple Jin’s iPhone",
    "value": "Apple Jin’s iPhone"
  },
  {
    "name": "myPhone Hammer_Explorer",
    "label": "myPhone Hammer_Explorer",
    "value": "myPhone Hammer_Explorer"
  },
  {
    "name": "Apple iPhone de Natalia",
    "label": "Apple iPhone de Natalia",
    "value": "Apple iPhone de Natalia"
  },
  {
    "name": "Apple iPhone de Eddy",
    "label": "Apple iPhone de Eddy",
    "value": "Apple iPhone de Eddy"
  },
  {
    "name": "Apple iPhone de Luis",
    "label": "Apple iPhone de Luis",
    "value": "Apple iPhone de Luis"
  },
  {
    "name": "Apple iPhone de Oswaldo Luis",
    "label": "Apple iPhone de Oswaldo Luis",
    "value": "Apple iPhone de Oswaldo Luis"
  },
  {
    "name": "Apple Chulumanco’s iPhone",
    "label": "Apple Chulumanco’s iPhone",
    "value": "Apple Chulumanco’s iPhone"
  },
  {
    "name": "SPC SMART_ULTIMATE",
    "label": "SPC SMART_ULTIMATE",
    "value": "SPC SMART_ULTIMATE"
  },
  {
    "name": "Chitech BYYBUO_SmartPad_A10",
    "label": "Chitech BYYBUO_SmartPad_A10",
    "value": "Chitech BYYBUO_SmartPad_A10"
  },
  {
    "name": "vanzo iQ&T N8",
    "label": "vanzo iQ&T N8",
    "value": "vanzo iQ&T N8"
  },
  {
    "name": "XGODY K40",
    "label": "XGODY K40",
    "value": "XGODY K40"
  },
  {
    "name": "samsung SM-S916N",
    "label": "samsung SM-S916N",
    "value": "samsung SM-S916N"
  },
  {
    "name": "MobiWire Vodafone Lite 4G",
    "label": "MobiWire Vodafone Lite 4G",
    "value": "MobiWire Vodafone Lite 4G"
  },
  {
    "name": "Sony XQ-BC62",
    "label": "Sony XQ-BC62",
    "value": "Sony XQ-BC62"
  },
  {
    "name": "HUAWEI EVR-AL00",
    "label": "HUAWEI EVR-AL00",
    "value": "HUAWEI EVR-AL00"
  },
  {
    "name": "Mobile Web - windows",
    "label": "Mobile Web - windows",
    "value": "Mobile Web - windows"
  },
  {
    "name": "HUAWEI JAD-LX9",
    "label": "HUAWEI JAD-LX9",
    "value": "HUAWEI JAD-LX9"
  },
  {
    "name": "motorola motorola razr 5G",
    "label": "motorola motorola razr 5G",
    "value": "motorola motorola razr 5G"
  },
  {
    "name": "Apple iPhone de Nerea",
    "label": "Apple iPhone de Nerea",
    "value": "Apple iPhone de Nerea"
  },
  {
    "name": "mimi's iPad ✨ iPad",
    "label": "mimi's iPad ✨ iPad",
    "value": "mimi's iPad ✨ iPad"
  },
  {
    "name": "LGE LG-LK460",
    "label": "LGE LG-LK460",
    "value": "LGE LG-LK460"
  },
  {
    "name": "Sony SO-01M",
    "label": "Sony SO-01M",
    "value": "Sony SO-01M"
  },
  {
    "name": "Pixus Pixus_Joker",
    "label": "Pixus Pixus_Joker",
    "value": "Pixus Pixus_Joker"
  },
  {
    "name": "gstone Quartzo UP",
    "label": "gstone Quartzo UP",
    "value": "gstone Quartzo UP"
  },
  {
    "name": "Cherry_Mobile Flare_J2_Prime",
    "label": "Cherry_Mobile Flare_J2_Prime",
    "value": "Cherry_Mobile Flare_J2_Prime"
  },
  {
    "name": "Apple iPhone de Mireia",
    "label": "Apple iPhone de Mireia",
    "value": "Apple iPhone de Mireia"
  },
  {
    "name": "PANASONIC Eluga I8",
    "label": "PANASONIC Eluga I8",
    "value": "PANASONIC Eluga I8"
  },
  {
    "name": "SPA Condor Electronics Plume L2",
    "label": "SPA Condor Electronics Plume L2",
    "value": "SPA Condor Electronics Plume L2"
  },
  {
    "name": "motorola moto e22s",
    "label": "motorola moto e22s",
    "value": "motorola moto e22s"
  },
  {
    "name": "Xiaomi A001XM",
    "label": "Xiaomi A001XM",
    "value": "Xiaomi A001XM"
  },
  {
    "name": "TCL 9295G",
    "label": "TCL 9295G",
    "value": "TCL 9295G"
  },
  {
    "name": "SHARP SH-03K",
    "label": "SHARP SH-03K",
    "value": "SHARP SH-03K"
  },
  {
    "name": "HTC HTC 5G Hub",
    "label": "HTC HTC 5G Hub",
    "value": "HTC HTC 5G Hub"
  },
  {
    "name": "Fortune Ship IKU A40",
    "label": "Fortune Ship IKU A40",
    "value": "Fortune Ship IKU A40"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO F4",
    "label": "TECNO MOBILE LIMITED TECNO F4",
    "value": "TECNO MOBILE LIMITED TECNO F4"
  },
  {
    "name": "vivo vivo X9s",
    "label": "vivo vivo X9s",
    "value": "vivo vivo X9s"
  },
  {
    "name": "asus ASUS_Z017DA",
    "label": "asus ASUS_Z017DA",
    "value": "asus ASUS_Z017DA"
  },
  {
    "name": "Apple iPhone de Rocio",
    "label": "Apple iPhone de Rocio",
    "value": "Apple iPhone de Rocio"
  },
  {
    "name": "Apple iPhone de Elver Enrique",
    "label": "Apple iPhone de Elver Enrique",
    "value": "Apple iPhone de Elver Enrique"
  },
  {
    "name": "lephone PRO5584PGE01",
    "label": "lephone PRO5584PGE01",
    "value": "lephone PRO5584PGE01"
  },
  {
    "name": "Apple iPhone de Ana Gabriela",
    "label": "Apple iPhone de Ana Gabriela",
    "value": "Apple iPhone de Ana Gabriela"
  },
  {
    "name": "Ritik’s iPad iPad",
    "label": "Ritik’s iPad iPad",
    "value": "Ritik’s iPad iPad"
  },
  {
    "name": "Apple iPhone de AIDA",
    "label": "Apple iPhone de AIDA",
    "value": "Apple iPhone de AIDA"
  },
  {
    "name": "Apple iPhone de Rit",
    "label": "Apple iPhone de Rit",
    "value": "Apple iPhone de Rit"
  },
  {
    "name": "wheatek W10 Pro",
    "label": "wheatek W10 Pro",
    "value": "wheatek W10 Pro"
  },
  {
    "name": "incar X18_Ultra",
    "label": "incar X18_Ultra",
    "value": "incar X18_Ultra"
  },
  {
    "name": "Chinoe BV6600E",
    "label": "Chinoe BV6600E",
    "value": "Chinoe BV6600E"
  },
  {
    "name": "ILA E3",
    "label": "ILA E3",
    "value": "ILA E3"
  },
  {
    "name": "TCL 5059D_RU",
    "label": "TCL 5059D_RU",
    "value": "TCL 5059D_RU"
  },
  {
    "name": "ZUUM ASTRO PLUS",
    "label": "ZUUM ASTRO PLUS",
    "value": "ZUUM ASTRO PLUS"
  },
  {
    "name": "Alcatel A502DL",
    "label": "Alcatel A502DL",
    "value": "Alcatel A502DL"
  },
  {
    "name": "Xiaomi 2203121C",
    "label": "Xiaomi 2203121C",
    "value": "Xiaomi 2203121C"
  },
  {
    "name": "samsung SM-G990U2",
    "label": "samsung SM-G990U2",
    "value": "samsung SM-G990U2"
  },
  {
    "name": "HUAWEI YAL-AL10",
    "label": "HUAWEI YAL-AL10",
    "value": "HUAWEI YAL-AL10"
  },
  {
    "name": "WIKO W-P311-TVM",
    "label": "WIKO W-P311-TVM",
    "value": "WIKO W-P311-TVM"
  },
  {
    "name": "Premio X85 PRO",
    "label": "Premio X85 PRO",
    "value": "Premio X85 PRO"
  },
  {
    "name": "Apple דביר קנפו",
    "label": "Apple דביר קנפו",
    "value": "Apple דביר קנפו"
  },
  {
    "name": "Apple iPhone KariB",
    "label": "Apple iPhone KariB",
    "value": "Apple iPhone KariB"
  },
  {
    "name": "Apple hello",
    "label": "Apple hello",
    "value": "Apple hello"
  },
  {
    "name": "BQru BQ-5519L",
    "label": "BQru BQ-5519L",
    "value": "BQru BQ-5519L"
  },
  {
    "name": "samsung Galaxy J4+",
    "label": "samsung Galaxy J4+",
    "value": "samsung Galaxy J4+"
  },
  {
    "name": "Amlogic TX6",
    "label": "Amlogic TX6",
    "value": "Amlogic TX6"
  },
  {
    "name": "itel itel A22",
    "label": "itel itel A22",
    "value": "itel itel A22"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO CE9h",
    "label": "TECNO MOBILE LIMITED TECNO CE9h",
    "value": "TECNO MOBILE LIMITED TECNO CE9h"
  },
  {
    "name": "Sky Devices Premier5",
    "label": "Sky Devices Premier5",
    "value": "Sky Devices Premier5"
  },
  {
    "name": "OPPO CPH2505",
    "label": "OPPO CPH2505",
    "value": "OPPO CPH2505"
  },
  {
    "name": "Movitel M9110",
    "label": "Movitel M9110",
    "value": "Movitel M9110"
  },
  {
    "name": "vivo V2114",
    "label": "vivo V2114",
    "value": "vivo V2114"
  },
  {
    "name": "samsung SM-S9180",
    "label": "samsung SM-S9180",
    "value": "samsung SM-S9180"
  },
  {
    "name": "HMR Networks AXE_II",
    "label": "HMR Networks AXE_II",
    "value": "HMR Networks AXE_II"
  },
  {
    "name": "sky Platinum K5",
    "label": "sky Platinum K5",
    "value": "sky Platinum K5"
  },
  {
    "name": "MobiWire S24",
    "label": "MobiWire S24",
    "value": "MobiWire S24"
  },
  {
    "name": "sanmu T-FASHION",
    "label": "sanmu T-FASHION",
    "value": "sanmu T-FASHION"
  },
  {
    "name": "TCL T676H",
    "label": "TCL T676H",
    "value": "TCL T676H"
  },
  {
    "name": "Apple ✧",
    "label": "Apple ✧",
    "value": "Apple ✧"
  },
  {
    "name": "Apple AT_307_RAC_112800_WW_DA",
    "label": "Apple AT_307_RAC_112800_WW_DA",
    "value": "Apple AT_307_RAC_112800_WW_DA"
  },
  {
    "name": "Apple 범 iPhone",
    "label": "Apple 범 iPhone",
    "value": "Apple 범 iPhone"
  },
  {
    "name": "wheatek WP20 Pro",
    "label": "wheatek WP20 Pro",
    "value": "wheatek WP20 Pro"
  },
  {
    "name": "vivo vivo 1902_19",
    "label": "vivo vivo 1902_19",
    "value": "vivo vivo 1902_19"
  },
  {
    "name": "INFINIX Infinix X6826C",
    "label": "INFINIX Infinix X6826C",
    "value": "INFINIX Infinix X6826C"
  },
  {
    "name": "Xiaomi M2006J10C",
    "label": "Xiaomi M2006J10C",
    "value": "Xiaomi M2006J10C"
  },
  {
    "name": "Cherry Mobile Flare Tab Pro",
    "label": "Cherry Mobile Flare Tab Pro",
    "value": "Cherry Mobile Flare Tab Pro"
  },
  {
    "name": "samsung SCV46",
    "label": "samsung SCV46",
    "value": "samsung SCV46"
  },
  {
    "name": "Apple iPhone de Maria fernanda",
    "label": "Apple iPhone de Maria fernanda",
    "value": "Apple iPhone de Maria fernanda"
  },
  {
    "name": "Apple WG’s iPhone",
    "label": "Apple WG’s iPhone",
    "value": "Apple WG’s iPhone"
  },
  {
    "name": "Apple iPhone de fany",
    "label": "Apple iPhone de fany",
    "value": "Apple iPhone de fany"
  },
  {
    "name": "alps J46",
    "label": "alps J46",
    "value": "alps J46"
  },
  {
    "name": "MAXFONE Max80 Plus",
    "label": "MAXFONE Max80 Plus",
    "value": "MAXFONE Max80 Plus"
  },
  {
    "name": "KYOCERA E6910",
    "label": "KYOCERA E6910",
    "value": "KYOCERA E6910"
  },
  {
    "name": "logicom La_tab_link_74",
    "label": "logicom La_tab_link_74",
    "value": "logicom La_tab_link_74"
  },
  {
    "name": "Apple iPhone Ana",
    "label": "Apple iPhone Ana",
    "value": "Apple iPhone Ana"
  },
  {
    "name": "vivo V1930A",
    "label": "vivo V1930A",
    "value": "vivo V1930A"
  },
  {
    "name": "KRIP K50",
    "label": "KRIP K50",
    "value": "KRIP K50"
  },
  {
    "name": "teksun S49_POISE",
    "label": "teksun S49_POISE",
    "value": "teksun S49_POISE"
  },
  {
    "name": "HXY S19 Max Pro",
    "label": "HXY S19 Max Pro",
    "value": "HXY S19 Max Pro"
  },
  {
    "name": "Win Q9",
    "label": "Win Q9",
    "value": "Win Q9"
  },
  {
    "name": "HONOR VNA-LX2",
    "label": "HONOR VNA-LX2",
    "value": "HONOR VNA-LX2"
  },
  {
    "name": "samsung SM-S916B",
    "label": "samsung SM-S916B",
    "value": "samsung SM-S916B"
  },
  {
    "name": "Xiaomi 2201123C",
    "label": "Xiaomi 2201123C",
    "value": "Xiaomi 2201123C"
  },
  {
    "name": "vsmart Joy 3+",
    "label": "vsmart Joy 3+",
    "value": "vsmart Joy 3+"
  },
  {
    "name": "Movitel M8424",
    "label": "Movitel M8424",
    "value": "Movitel M8424"
  },
  {
    "name": "OnePlus A0001",
    "label": "OnePlus A0001",
    "value": "OnePlus A0001"
  },
  {
    "name": "ZTE Blade A5 2020-T",
    "label": "ZTE Blade A5 2020-T",
    "value": "ZTE Blade A5 2020-T"
  },
  {
    "name": "LGE LG-F800K",
    "label": "LGE LG-F800K",
    "value": "LGE LG-F800K"
  },
  {
    "name": "OPPO A101OP",
    "label": "OPPO A101OP",
    "value": "OPPO A101OP"
  },
  {
    "name": "Apple iPhone de Marleen estrella",
    "label": "Apple iPhone de Marleen estrella",
    "value": "Apple iPhone de Marleen estrella"
  },
  {
    "name": "MOBIWIRE MAGNO C",
    "label": "MOBIWIRE MAGNO C",
    "value": "MOBIWIRE MAGNO C"
  },
  {
    "name": "Xiaomi 21051182C",
    "label": "Xiaomi 21051182C",
    "value": "Xiaomi 21051182C"
  },
  {
    "name": "AGM AGM A10",
    "label": "AGM AGM A10",
    "value": "AGM AGM A10"
  },
  {
    "name": "Apple ibok",
    "label": "Apple ibok",
    "value": "Apple ibok"
  },
  {
    "name": "Danica’s iPad iPad",
    "label": "Danica’s iPad iPad",
    "value": "Danica’s iPad iPad"
  },
  {
    "name": "Apple iPhone de Juliana alejandra",
    "label": "Apple iPhone de Juliana alejandra",
    "value": "Apple iPhone de Juliana alejandra"
  },
  {
    "name": "LAVA iris88_lite",
    "label": "LAVA iris88_lite",
    "value": "LAVA iris88_lite"
  },
  {
    "name": "incar Q11-EEA",
    "label": "incar Q11-EEA",
    "value": "incar Q11-EEA"
  },
  {
    "name": "Apple Wanya’s iPhone",
    "label": "Apple Wanya’s iPhone",
    "value": "Apple Wanya’s iPhone"
  },
  {
    "name": "alps SUGAR A100",
    "label": "alps SUGAR A100",
    "value": "alps SUGAR A100"
  },
  {
    "name": "OPPO PHA120",
    "label": "OPPO PHA120",
    "value": "OPPO PHA120"
  },
  {
    "name": "X_TIGI Kids7 Pro",
    "label": "X_TIGI Kids7 Pro",
    "value": "X_TIGI Kids7 Pro"
  },
  {
    "name": "EPIKONE X655",
    "label": "EPIKONE X655",
    "value": "EPIKONE X655"
  },
  {
    "name": "ALLVIEW A10_Max",
    "label": "ALLVIEW A10_Max",
    "value": "ALLVIEW A10_Max"
  },
  {
    "name": "Sky_Devices Platinum_B4P",
    "label": "Sky_Devices Platinum_B4P",
    "value": "Sky_Devices Platinum_B4P"
  },
  {
    "name": "BQru BQ-8068L",
    "label": "BQru BQ-8068L",
    "value": "BQru BQ-8068L"
  },
  {
    "name": "ZTE Z7540",
    "label": "ZTE Z7540",
    "value": "ZTE Z7540"
  },
  {
    "name": "EVERCOSS M55B",
    "label": "EVERCOSS M55B",
    "value": "EVERCOSS M55B"
  },
  {
    "name": "Meizu meizu M8 lite",
    "label": "Meizu meizu M8 lite",
    "value": "Meizu meizu M8 lite"
  },
  {
    "name": "samsung SM-G5700",
    "label": "samsung SM-G5700",
    "value": "samsung SM-G5700"
  },
  {
    "name": "Apple iPhone de Itzel",
    "label": "Apple iPhone de Itzel",
    "value": "Apple iPhone de Itzel"
  },
  {
    "name": "Apple JB Bae",
    "label": "Apple JB Bae",
    "value": "Apple JB Bae"
  },
  {
    "name": "Apple Vuelwa’s iPhone",
    "label": "Apple Vuelwa’s iPhone",
    "value": "Apple Vuelwa’s iPhone"
  },
  {
    "name": "LGE LM-X220PM",
    "label": "LGE LM-X220PM",
    "value": "LGE LM-X220PM"
  },
  {
    "name": "Apple iPhone de Sara Patricia",
    "label": "Apple iPhone de Sara Patricia",
    "value": "Apple iPhone de Sara Patricia"
  },
  {
    "name": "samsung SM-N935K",
    "label": "samsung SM-N935K",
    "value": "samsung SM-N935K"
  },
  {
    "name": "Apple iPhone Juank",
    "label": "Apple iPhone Juank",
    "value": "Apple iPhone Juank"
  },
  {
    "name": "vivo V2034",
    "label": "vivo V2034",
    "value": "vivo V2034"
  },
  {
    "name": "INOI A126",
    "label": "INOI A126",
    "value": "INOI A126"
  },
  {
    "name": "WALTON PRIMO N4",
    "label": "WALTON PRIMO N4",
    "value": "WALTON PRIMO N4"
  },
  {
    "name": "X-TIGI X-TIGI_V15",
    "label": "X-TIGI X-TIGI_V15",
    "value": "X-TIGI X-TIGI_V15"
  },
  {
    "name": "Apple iPhone de Vania",
    "label": "Apple iPhone de Vania",
    "value": "Apple iPhone de Vania"
  },
  {
    "name": "Apple mac의 iPhone",
    "label": "Apple mac의 iPhone",
    "value": "Apple mac의 iPhone"
  },
  {
    "name": "SOHO_STYLE SS5424G",
    "label": "SOHO_STYLE SS5424G",
    "value": "SOHO_STYLE SS5424G"
  },
  {
    "name": "INFINIX Infinix X6517",
    "label": "INFINIX Infinix X6517",
    "value": "INFINIX Infinix X6517"
  },
  {
    "name": "Kurio 01618",
    "label": "Kurio 01618",
    "value": "Kurio 01618"
  },
  {
    "name": "Innovatech A20S PRO",
    "label": "Innovatech A20S PRO",
    "value": "Innovatech A20S PRO"
  },
  {
    "name": "samsung SM-A136M",
    "label": "samsung SM-A136M",
    "value": "samsung SM-A136M"
  },
  {
    "name": "Sky_Devices SKY Black",
    "label": "Sky_Devices SKY Black",
    "value": "Sky_Devices SKY Black"
  },
  {
    "name": "Apple Lilac😈",
    "label": "Apple Lilac😈",
    "value": "Apple Lilac😈"
  },
  {
    "name": "Xiaomi Xiaomi 11 Lite NE",
    "label": "Xiaomi Xiaomi 11 Lite NE",
    "value": "Xiaomi Xiaomi 11 Lite NE"
  },
  {
    "name": "TCL 6027A",
    "label": "TCL 6027A",
    "value": "TCL 6027A"
  },
  {
    "name": "Alco RCT6613W23P",
    "label": "Alco RCT6613W23P",
    "value": "Alco RCT6613W23P"
  },
  {
    "name": "Bmobile BL61",
    "label": "Bmobile BL61",
    "value": "Bmobile BL61"
  },
  {
    "name": "Apple 박지호의 iPhone",
    "label": "Apple 박지호의 iPhone",
    "value": "Apple 박지호의 iPhone"
  },
  {
    "name": "Apple Zee🧸",
    "label": "Apple Zee🧸",
    "value": "Apple Zee🧸"
  },
  {
    "name": "Apple Jayzelle’s iPhone",
    "label": "Apple Jayzelle’s iPhone",
    "value": "Apple Jayzelle’s iPhone"
  },
  {
    "name": "Apple iPhone de Pol Conesa",
    "label": "Apple iPhone de Pol Conesa",
    "value": "Apple iPhone de Pol Conesa"
  },
  {
    "name": "Apple Jemee Joe iPhone",
    "label": "Apple Jemee Joe iPhone",
    "value": "Apple Jemee Joe iPhone"
  },
  {
    "name": "Apple Jinkyung의 iPhone",
    "label": "Apple Jinkyung의 iPhone",
    "value": "Apple Jinkyung의 iPhone"
  },
  {
    "name": "Apple iPhone de Juli",
    "label": "Apple iPhone de Juli",
    "value": "Apple iPhone de Juli"
  },
  {
    "name": "WIKO WIM Lite",
    "label": "WIKO WIM Lite",
    "value": "WIKO WIM Lite"
  },
  {
    "name": "Symphony Symphony Z22",
    "label": "Symphony Symphony Z22",
    "value": "Symphony Symphony Z22"
  },
  {
    "name": "Mobile Web - ANDROID",
    "label": "Mobile Web - ANDROID",
    "value": "Mobile Web - ANDROID"
  },
  {
    "name": "incar IQU_T10_4G",
    "label": "incar IQU_T10_4G",
    "value": "incar IQU_T10_4G"
  },
  {
    "name": "samsung SM-G975X",
    "label": "samsung SM-G975X",
    "value": "samsung SM-G975X"
  },
  {
    "name": "SHARP SH-02J",
    "label": "SHARP SH-02J",
    "value": "SHARP SH-02J"
  },
  {
    "name": "vivo V2109-EG",
    "label": "vivo V2109-EG",
    "value": "vivo V2109-EG"
  },
  {
    "name": "TURK TELEKOM TT175",
    "label": "TURK TELEKOM TT175",
    "value": "TURK TELEKOM TT175"
  },
  {
    "name": "LGE LG-M320",
    "label": "LGE LG-M320",
    "value": "LGE LG-M320"
  },
  {
    "name": "OPPO CPH1605",
    "label": "OPPO CPH1605",
    "value": "OPPO CPH1605"
  },
  {
    "name": "asus ASUS_Z01BD",
    "label": "asus ASUS_Z01BD",
    "value": "asus ASUS_Z01BD"
  },
  {
    "name": "CUBOT KINGKONG 5",
    "label": "CUBOT KINGKONG 5",
    "value": "CUBOT KINGKONG 5"
  },
  {
    "name": "Vestel Venus E2 Plus",
    "label": "Vestel Venus E2 Plus",
    "value": "Vestel Venus E2 Plus"
  },
  {
    "name": "FIGI Note_11_Pro",
    "label": "FIGI Note_11_Pro",
    "value": "FIGI Note_11_Pro"
  },
  {
    "name": "Apple frans’s iPhone",
    "label": "Apple frans’s iPhone",
    "value": "Apple frans’s iPhone"
  },
  {
    "name": "Apple Melvon Khanyile’s iPhone",
    "label": "Apple Melvon Khanyile’s iPhone",
    "value": "Apple Melvon Khanyile’s iPhone"
  },
  {
    "name": "Apple Motladi’s iPhone",
    "label": "Apple Motladi’s iPhone",
    "value": "Apple Motladi’s iPhone"
  },
  {
    "name": "Apple mishibishi",
    "label": "Apple mishibishi",
    "value": "Apple mishibishi"
  },
  {
    "name": "Apple iPhone de Veronica",
    "label": "Apple iPhone de Veronica",
    "value": "Apple iPhone de Veronica"
  },
  {
    "name": "Apple iPhone de Nicole",
    "label": "Apple iPhone de Nicole",
    "value": "Apple iPhone de Nicole"
  },
  {
    "name": "Apple iPhone de Marc",
    "label": "Apple iPhone de Marc",
    "value": "Apple iPhone de Marc"
  },
  {
    "name": "skythtek TechPad_10Z",
    "label": "skythtek TechPad_10Z",
    "value": "skythtek TechPad_10Z"
  },
  {
    "name": "Luisa’s iPad iPad",
    "label": "Luisa’s iPad iPad",
    "value": "Luisa’s iPad iPad"
  },
  {
    "name": "iPad (7) iPad",
    "label": "iPad (7) iPad",
    "value": "iPad (7) iPad"
  },
  {
    "name": "Apple Sarah’s iPhone",
    "label": "Apple Sarah’s iPhone",
    "value": "Apple Sarah’s iPhone"
  },
  {
    "name": "iPad de Yael iPad",
    "label": "iPad de Yael iPad",
    "value": "iPad de Yael iPad"
  },
  {
    "name": "HTC EXODUS 1",
    "label": "HTC EXODUS 1",
    "value": "HTC EXODUS 1"
  },
  {
    "name": "SKY SKY Prestige",
    "label": "SKY SKY Prestige",
    "value": "SKY SKY Prestige"
  },
  {
    "name": "Apple Harry’s iPhone",
    "label": "Apple Harry’s iPhone",
    "value": "Apple Harry’s iPhone"
  },
  {
    "name": "Apple Yeom’s iPhone12 mini",
    "label": "Apple Yeom’s iPhone12 mini",
    "value": "Apple Yeom’s iPhone12 mini"
  },
  {
    "name": "Lenovo Lenovo L39051",
    "label": "Lenovo Lenovo L39051",
    "value": "Lenovo Lenovo L39051"
  },
  {
    "name": "Apple iPhone 7 Plus",
    "label": "Apple iPhone 7 Plus",
    "value": "Apple iPhone 7 Plus"
  },
  {
    "name": "Apple EunJeong의 iPhone",
    "label": "Apple EunJeong의 iPhone",
    "value": "Apple EunJeong의 iPhone"
  },
  {
    "name": "Apple 이채연의 iPhone",
    "label": "Apple 이채연의 iPhone",
    "value": "Apple 이채연의 iPhone"
  },
  {
    "name": "Apple 이수현의 iPhone",
    "label": "Apple 이수현의 iPhone",
    "value": "Apple 이수현의 iPhone"
  },
  {
    "name": "stack T12",
    "label": "stack T12",
    "value": "stack T12"
  },
  {
    "name": "Apple summiee",
    "label": "Apple summiee",
    "value": "Apple summiee"
  },
  {
    "name": "General Mobile General Mobile 4G",
    "label": "General Mobile General Mobile 4G",
    "value": "General Mobile General Mobile 4G"
  },
  {
    "name": "unknown SO-52A",
    "label": "unknown SO-52A",
    "value": "unknown SO-52A"
  },
  {
    "name": "TCL 5002H_RU",
    "label": "TCL 5002H_RU",
    "value": "TCL 5002H_RU"
  },
  {
    "name": "vivo V1914A",
    "label": "vivo V1914A",
    "value": "vivo V1914A"
  },
  {
    "name": "NUU N5502L",
    "label": "NUU N5502L",
    "value": "NUU N5502L"
  },
  {
    "name": "HONOR RKY-AN00",
    "label": "HONOR RKY-AN00",
    "value": "HONOR RKY-AN00"
  },
  {
    "name": "Innovatech A20C",
    "label": "Innovatech A20C",
    "value": "Innovatech A20C"
  },
  {
    "name": "samsung SM-A520K",
    "label": "samsung SM-A520K",
    "value": "samsung SM-A520K"
  },
  {
    "name": "samsung SM-J320V",
    "label": "samsung SM-J320V",
    "value": "samsung SM-J320V"
  },
  {
    "name": "ALLVIEW X8_Soul_Style",
    "label": "ALLVIEW X8_Soul_Style",
    "value": "ALLVIEW X8_Soul_Style"
  },
  {
    "name": "HUAWEI JKM-AL00b",
    "label": "HUAWEI JKM-AL00b",
    "value": "HUAWEI JKM-AL00b"
  },
  {
    "name": "Starlight Star_Mix_2",
    "label": "Starlight Star_Mix_2",
    "value": "Starlight Star_Mix_2"
  },
  {
    "name": "MyPhone myX2",
    "label": "MyPhone myX2",
    "value": "MyPhone myX2"
  },
  {
    "name": "Apple 손아람의 iPhone",
    "label": "Apple 손아람의 iPhone",
    "value": "Apple 손아람의 iPhone"
  },
  {
    "name": "Apple iPhone de R.B🔥",
    "label": "Apple iPhone de R.B🔥",
    "value": "Apple iPhone de R.B🔥"
  },
  {
    "name": "DOOGEE S55_Lite",
    "label": "DOOGEE S55_Lite",
    "value": "DOOGEE S55_Lite"
  },
  {
    "name": "bnd Tech Pad i700",
    "label": "bnd Tech Pad i700",
    "value": "bnd Tech Pad i700"
  },
  {
    "name": "Apple 💤",
    "label": "Apple 💤",
    "value": "Apple 💤"
  },
  {
    "name": "Apple iPhone de Paula Andrea",
    "label": "Apple iPhone de Paula Andrea",
    "value": "Apple iPhone de Paula Andrea"
  },
  {
    "name": "Apple Mamane’s iphone",
    "label": "Apple Mamane’s iphone",
    "value": "Apple Mamane’s iphone"
  },
  {
    "name": "HUAWEI STF-L09S",
    "label": "HUAWEI STF-L09S",
    "value": "HUAWEI STF-L09S"
  },
  {
    "name": "motorola XT2125-4",
    "label": "motorola XT2125-4",
    "value": "motorola XT2125-4"
  },
  {
    "name": "Hyundai HT1004LI16",
    "label": "Hyundai HT1004LI16",
    "value": "Hyundai HT1004LI16"
  },
  {
    "name": "BQru BQ-1085L",
    "label": "BQru BQ-1085L",
    "value": "BQru BQ-1085L"
  },
  {
    "name": "OPPO PBBT00",
    "label": "OPPO PBBT00",
    "value": "OPPO PBBT00"
  },
  {
    "name": "TCL T782P",
    "label": "TCL T782P",
    "value": "TCL T782P"
  },
  {
    "name": "Meberry M7",
    "label": "Meberry M7",
    "value": "Meberry M7"
  },
  {
    "name": "samsung SM-A600A",
    "label": "samsung SM-A600A",
    "value": "samsung SM-A600A"
  },
  {
    "name": "Kalley Black",
    "label": "Kalley Black",
    "value": "Kalley Black"
  },
  {
    "name": "Apple Sharlene’s iPhone",
    "label": "Apple Sharlene’s iPhone",
    "value": "Apple Sharlene’s iPhone"
  },
  {
    "name": "Apple pants",
    "label": "Apple pants",
    "value": "Apple pants"
  },
  {
    "name": "Apple 하정연의 iPhone",
    "label": "Apple 하정연의 iPhone",
    "value": "Apple 하정연의 iPhone"
  },
  {
    "name": "Hisense Hisense Infinity E8",
    "label": "Hisense Hisense Infinity E8",
    "value": "Hisense Hisense Infinity E8"
  },
  {
    "name": "Blackview A20Pro",
    "label": "Blackview A20Pro",
    "value": "Blackview A20Pro"
  },
  {
    "name": "SYMPHONY i68",
    "label": "SYMPHONY i68",
    "value": "SYMPHONY i68"
  },
  {
    "name": "LGE LML211BL",
    "label": "LGE LML211BL",
    "value": "LGE LML211BL"
  },
  {
    "name": "ZTE Nubia 6010",
    "label": "ZTE Nubia 6010",
    "value": "ZTE Nubia 6010"
  },
  {
    "name": "WIKO WC300",
    "label": "WIKO WC300",
    "value": "WIKO WC300"
  },
  {
    "name": "Yulong CP3706AS",
    "label": "Yulong CP3706AS",
    "value": "Yulong CP3706AS"
  },
  {
    "name": "Coolpad Coolpad_N3",
    "label": "Coolpad Coolpad_N3",
    "value": "Coolpad Coolpad_N3"
  },
  {
    "name": "Micromax IN1",
    "label": "Micromax IN1",
    "value": "Micromax IN1"
  },
  {
    "name": "ITEL MOBILE LIMITED itel W5004D",
    "label": "ITEL MOBILE LIMITED itel W5004D",
    "value": "ITEL MOBILE LIMITED itel W5004D"
  },
  {
    "name": "Gigaset GS190",
    "label": "Gigaset GS190",
    "value": "Gigaset GS190"
  },
  {
    "name": "Apple iPhone de Maku",
    "label": "Apple iPhone de Maku",
    "value": "Apple iPhone de Maku"
  },
  {
    "name": "CORN CORN R10",
    "label": "CORN CORN R10",
    "value": "CORN CORN R10"
  },
  {
    "name": "Apple Bless",
    "label": "Apple Bless",
    "value": "Apple Bless"
  },
  {
    "name": "Apple 20161633",
    "label": "Apple 20161633",
    "value": "Apple 20161633"
  },
  {
    "name": "Meizu meizu X8",
    "label": "Meizu meizu X8",
    "value": "Meizu meizu X8"
  },
  {
    "name": "Apple Sandeep’s iPhone",
    "label": "Apple Sandeep’s iPhone",
    "value": "Apple Sandeep’s iPhone"
  },
  {
    "name": "Apple iPhone de Tobías",
    "label": "Apple iPhone de Tobías",
    "value": "Apple iPhone de Tobías"
  },
  {
    "name": "Xiaomi Redmi K30 Pro Zoom Edition",
    "label": "Xiaomi Redmi K30 Pro Zoom Edition",
    "value": "Xiaomi Redmi K30 Pro Zoom Edition"
  },
  {
    "name": "Fortune Ship Wildfire E1 Plus",
    "label": "Fortune Ship Wildfire E1 Plus",
    "value": "Fortune Ship Wildfire E1 Plus"
  },
  {
    "name": "Apple Kelly’s iPhone",
    "label": "Apple Kelly’s iPhone",
    "value": "Apple Kelly’s iPhone"
  },
  {
    "name": "Hisense huawei p20",
    "label": "Hisense huawei p20",
    "value": "Hisense huawei p20"
  },
  {
    "name": "magcomm H10",
    "label": "magcomm H10",
    "value": "magcomm H10"
  },
  {
    "name": "Xiaomi 22101316C",
    "label": "Xiaomi 22101316C",
    "value": "Xiaomi 22101316C"
  },
  {
    "name": "Apple Antía",
    "label": "Apple Antía",
    "value": "Apple Antía"
  },
  {
    "name": "Apple 🦉Anguiano🦉",
    "label": "Apple 🦉Anguiano🦉",
    "value": "Apple 🦉Anguiano🦉"
  },
  {
    "name": "Apple iPhone de Mariajose",
    "label": "Apple iPhone de Mariajose",
    "value": "Apple iPhone de Mariajose"
  },
  {
    "name": "Apple iPhone de Admin",
    "label": "Apple iPhone de Admin",
    "value": "Apple iPhone de Admin"
  },
  {
    "name": "Apple Pieter",
    "label": "Apple Pieter",
    "value": "Apple Pieter"
  },
  {
    "name": "TCL 5030I",
    "label": "TCL 5030I",
    "value": "TCL 5030I"
  },
  {
    "name": "LGE LM-X510WM",
    "label": "LGE LM-X510WM",
    "value": "LGE LM-X510WM"
  },
  {
    "name": "QMobile LT950",
    "label": "QMobile LT950",
    "value": "QMobile LT950"
  },
  {
    "name": "realme RMX3512",
    "label": "realme RMX3512",
    "value": "realme RMX3512"
  },
  {
    "name": "Apple Iphone❤️",
    "label": "Apple Iphone❤️",
    "value": "Apple Iphone❤️"
  },
  {
    "name": "Vestel Venus Z40",
    "label": "Vestel Venus Z40",
    "value": "Vestel Venus Z40"
  },
  {
    "name": "Xiaomi Redmi 5 plus",
    "label": "Xiaomi Redmi 5 plus",
    "value": "Xiaomi Redmi 5 plus"
  },
  {
    "name": "Apple 인내",
    "label": "Apple 인내",
    "value": "Apple 인내"
  },
  {
    "name": "Blackview BV9500",
    "label": "Blackview BV9500",
    "value": "Blackview BV9500"
  },
  {
    "name": "UNIFONE TW801",
    "label": "UNIFONE TW801",
    "value": "UNIFONE TW801"
  },
  {
    "name": "Apple nutzeeel 💜",
    "label": "Apple nutzeeel 💜",
    "value": "Apple nutzeeel 💜"
  },
  {
    "name": "Apple Lisolomzi’s iPhone",
    "label": "Apple Lisolomzi’s iPhone",
    "value": "Apple Lisolomzi’s iPhone"
  },
  {
    "name": "Apple Da iPhone",
    "label": "Apple Da iPhone",
    "value": "Apple Da iPhone"
  },
  {
    "name": "TCL 4065F",
    "label": "TCL 4065F",
    "value": "TCL 4065F"
  },
  {
    "name": "Gigaset GS110",
    "label": "Gigaset GS110",
    "value": "Gigaset GS110"
  },
  {
    "name": "vivo V2046A",
    "label": "vivo V2046A",
    "value": "vivo V2046A"
  },
  {
    "name": "Blackview BV8000Pro",
    "label": "Blackview BV8000Pro",
    "value": "Blackview BV8000Pro"
  },
  {
    "name": "TCL 5102O",
    "label": "TCL 5102O",
    "value": "TCL 5102O"
  },
  {
    "name": "FROG UNI 10X",
    "label": "FROG UNI 10X",
    "value": "FROG UNI 10X"
  },
  {
    "name": "teksun Zafiro",
    "label": "teksun Zafiro",
    "value": "teksun Zafiro"
  },
  {
    "name": "Sony SOV42-u",
    "label": "Sony SOV42-u",
    "value": "Sony SOV42-u"
  },
  {
    "name": "vivo V2002A",
    "label": "vivo V2002A",
    "value": "vivo V2002A"
  },
  {
    "name": "HUAWEI EVA-AL00",
    "label": "HUAWEI EVA-AL00",
    "value": "HUAWEI EVA-AL00"
  },
  {
    "name": "Elephone PX_Pro",
    "label": "Elephone PX_Pro",
    "value": "Elephone PX_Pro"
  },
  {
    "name": "BQru BQ-5016G",
    "label": "BQru BQ-5016G",
    "value": "BQru BQ-5016G"
  },
  {
    "name": "Lenovo Lenovo TB-9707F",
    "label": "Lenovo Lenovo TB-9707F",
    "value": "Lenovo Lenovo TB-9707F"
  },
  {
    "name": "Gigaset E940-2796-00",
    "label": "Gigaset E940-2796-00",
    "value": "Gigaset E940-2796-00"
  },
  {
    "name": "FUJITSU F-03K",
    "label": "FUJITSU F-03K",
    "value": "FUJITSU F-03K"
  },
  {
    "name": "Symphony V141",
    "label": "Symphony V141",
    "value": "Symphony V141"
  },
  {
    "name": "asus ZB602KL",
    "label": "asus ZB602KL",
    "value": "asus ZB602KL"
  },
  {
    "name": "Vertex Luck",
    "label": "Vertex Luck",
    "value": "Vertex Luck"
  },
  {
    "name": "Vodafone VFD 528",
    "label": "Vodafone VFD 528",
    "value": "Vodafone VFD 528"
  },
  {
    "name": "Sony H8266",
    "label": "Sony H8266",
    "value": "Sony H8266"
  },
  {
    "name": "vivo vivo Y75A",
    "label": "vivo vivo Y75A",
    "value": "vivo vivo Y75A"
  },
  {
    "name": "WALTON PRIMO RX9",
    "label": "WALTON PRIMO RX9",
    "value": "WALTON PRIMO RX9"
  },
  {
    "name": "DEXP N370",
    "label": "DEXP N370",
    "value": "DEXP N370"
  },
  {
    "name": "KRONO NET_K7",
    "label": "KRONO NET_K7",
    "value": "KRONO NET_K7"
  },
  {
    "name": "Casper VIA_S",
    "label": "Casper VIA_S",
    "value": "Casper VIA_S"
  },
  {
    "name": "revoview S5006",
    "label": "revoview S5006",
    "value": "revoview S5006"
  },
  {
    "name": "iPad12,1 iPad",
    "label": "iPad12,1 iPad",
    "value": "iPad12,1 iPad"
  },
  {
    "name": "iBall Slide iBall Slide Cleo S9",
    "label": "iBall Slide iBall Slide Cleo S9",
    "value": "iBall Slide iBall Slide Cleo S9"
  },
  {
    "name": "ITEL MOBILE LIMITED itel L5505",
    "label": "ITEL MOBILE LIMITED itel L5505",
    "value": "ITEL MOBILE LIMITED itel L5505"
  },
  {
    "name": "CORN CORN X5",
    "label": "CORN CORN X5",
    "value": "CORN CORN X5"
  },
  {
    "name": "ZTE ZTE Blade A31 Plus RU",
    "label": "ZTE ZTE Blade A31 Plus RU",
    "value": "ZTE ZTE Blade A31 Plus RU"
  },
  {
    "name": "Technopc S21LA",
    "label": "Technopc S21LA",
    "value": "Technopc S21LA"
  },
  {
    "name": "FIH EC211004",
    "label": "FIH EC211004",
    "value": "FIH EC211004"
  },
  {
    "name": "OSCAL C60",
    "label": "OSCAL C60",
    "value": "OSCAL C60"
  },
  {
    "name": "Xiaomi M2004J7AC",
    "label": "Xiaomi M2004J7AC",
    "value": "Xiaomi M2004J7AC"
  },
  {
    "name": "ZTE ZTE C2017",
    "label": "ZTE ZTE C2017",
    "value": "ZTE ZTE C2017"
  },
  {
    "name": "NEONIQ NQT-74GIQ",
    "label": "NEONIQ NQT-74GIQ",
    "value": "NEONIQ NQT-74GIQ"
  },
  {
    "name": "Emdoor DX10-66-LTE",
    "label": "Emdoor DX10-66-LTE",
    "value": "Emdoor DX10-66-LTE"
  },
  {
    "name": "samsung SM-T735N",
    "label": "samsung SM-T735N",
    "value": "samsung SM-T735N"
  },
  {
    "name": "Droi X81",
    "label": "Droi X81",
    "value": "Droi X81"
  },
  {
    "name": "Blackview Tab 13",
    "label": "Blackview Tab 13",
    "value": "Blackview Tab 13"
  },
  {
    "name": "Sky_Devices Platinum_G55",
    "label": "Sky_Devices Platinum_G55",
    "value": "Sky_Devices Platinum_G55"
  },
  {
    "name": "iPad8,10 iPad",
    "label": "iPad8,10 iPad",
    "value": "iPad8,10 iPad"
  },
  {
    "name": "I13 Pro  Max I13 Pro  Max",
    "label": "I13 Pro  Max I13 Pro  Max",
    "value": "I13 Pro  Max I13 Pro  Max"
  },
  {
    "name": "Jessi’s iPad iPad",
    "label": "Jessi’s iPad iPad",
    "value": "Jessi’s iPad iPad"
  },
  {
    "name": "Apple Paballo’s iPhone",
    "label": "Apple Paballo’s iPhone",
    "value": "Apple Paballo’s iPhone"
  },
  {
    "name": "WALTON Primo RX7",
    "label": "WALTON Primo RX7",
    "value": "WALTON Primo RX7"
  },
  {
    "name": "Tech_Pad X6",
    "label": "Tech_Pad X6",
    "value": "Tech_Pad X6"
  },
  {
    "name": "Umx U683CL",
    "label": "Umx U683CL",
    "value": "Umx U683CL"
  },
  {
    "name": "BLU G51",
    "label": "BLU G51",
    "value": "BLU G51"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A44 PowerRU",
    "label": "ITEL MOBILE LIMITED itel A44 PowerRU",
    "value": "ITEL MOBILE LIMITED itel A44 PowerRU"
  },
  {
    "name": "MOBIWIRE A60UNS",
    "label": "MOBIWIRE A60UNS",
    "value": "MOBIWIRE A60UNS"
  },
  {
    "name": "CUBOT CUBOT_POWER",
    "label": "CUBOT CUBOT_POWER",
    "value": "CUBOT CUBOT_POWER"
  },
  {
    "name": "unknown unknown",
    "label": "unknown unknown",
    "value": "unknown unknown"
  },
  {
    "name": "LAVA LAVA LXX501",
    "label": "LAVA LAVA LXX501",
    "value": "LAVA LAVA LXX501"
  },
  {
    "name": "realme RMX2025",
    "label": "realme RMX2025",
    "value": "realme RMX2025"
  },
  {
    "name": "vivo V2166A",
    "label": "vivo V2166A",
    "value": "vivo V2166A"
  },
  {
    "name": "ITEL MOBILE LIMITED itel A52 Lite",
    "label": "ITEL MOBILE LIMITED itel A52 Lite",
    "value": "ITEL MOBILE LIMITED itel A52 Lite"
  },
  {
    "name": "TWL 12Pro",
    "label": "TWL 12Pro",
    "value": "TWL 12Pro"
  },
  {
    "name": "vivo vivo X20Plus A",
    "label": "vivo vivo X20Plus A",
    "value": "vivo vivo X20Plus A"
  },
  {
    "name": "Apple Suhas’s Iphone",
    "label": "Apple Suhas’s Iphone",
    "value": "Apple Suhas’s Iphone"
  },
  {
    "name": "OPPO PCAT00",
    "label": "OPPO PCAT00",
    "value": "OPPO PCAT00"
  },
  {
    "name": "iKU A6",
    "label": "iKU A6",
    "value": "iKU A6"
  },
  {
    "name": "HUAWEI KNT-UL10",
    "label": "HUAWEI KNT-UL10",
    "value": "HUAWEI KNT-UL10"
  },
  {
    "name": "Symphony helio 30",
    "label": "Symphony helio 30",
    "value": "Symphony helio 30"
  },
  {
    "name": "emdoor ACRUX",
    "label": "emdoor ACRUX",
    "value": "emdoor ACRUX"
  },
  {
    "name": "bq Aquaris U2 Lite",
    "label": "bq Aquaris U2 Lite",
    "value": "bq Aquaris U2 Lite"
  },
  {
    "name": "positivo T2040",
    "label": "positivo T2040",
    "value": "positivo T2040"
  },
  {
    "name": "Symphony ATOM",
    "label": "Symphony ATOM",
    "value": "Symphony ATOM"
  },
  {
    "name": "HUAWEI AGRK-L09",
    "label": "HUAWEI AGRK-L09",
    "value": "HUAWEI AGRK-L09"
  },
  {
    "name": "TCL 5133A",
    "label": "TCL 5133A",
    "value": "TCL 5133A"
  },
  {
    "name": "HUAWEI CDY-AN00",
    "label": "HUAWEI CDY-AN00",
    "value": "HUAWEI CDY-AN00"
  },
  {
    "name": "HTC HTC Desire 22 pro",
    "label": "HTC HTC Desire 22 pro",
    "value": "HTC HTC Desire 22 pro"
  },
  {
    "name": "starlight venus",
    "label": "starlight venus",
    "value": "starlight venus"
  },
  {
    "name": "incar TR7998",
    "label": "incar TR7998",
    "value": "incar TR7998"
  },
  {
    "name": "LAVA LH9910",
    "label": "LAVA LH9910",
    "value": "LAVA LH9910"
  },
  {
    "name": "Kalley Silver_Max_Pro_2",
    "label": "Kalley Silver_Max_Pro_2",
    "value": "Kalley Silver_Max_Pro_2"
  },
  {
    "name": "OPPO PEMM20",
    "label": "OPPO PEMM20",
    "value": "OPPO PEMM20"
  },
  {
    "name": "iHunt S21 Ultra 4G 2021_EEA",
    "label": "iHunt S21 Ultra 4G 2021_EEA",
    "value": "iHunt S21 Ultra 4G 2021_EEA"
  },
  {
    "name": "Bmobile BL50P_TG05",
    "label": "Bmobile BL50P_TG05",
    "value": "Bmobile BL50P_TG05"
  },
  {
    "name": "BLU S91 Pro",
    "label": "BLU S91 Pro",
    "value": "BLU S91 Pro"
  },
  {
    "name": "emporia S3",
    "label": "emporia S3",
    "value": "emporia S3"
  },
  {
    "name": "ZUK Z2 Plus",
    "label": "ZUK Z2 Plus",
    "value": "ZUK Z2 Plus"
  },
  {
    "name": "OUKITEL WP5000",
    "label": "OUKITEL WP5000",
    "value": "OUKITEL WP5000"
  },
  {
    "name": "LGE LG-US601",
    "label": "LGE LG-US601",
    "value": "LGE LG-US601"
  },
  {
    "name": "D-Tech D5S",
    "label": "D-Tech D5S",
    "value": "D-Tech D5S"
  },
  {
    "name": "BQru BQ-4501G",
    "label": "BQru BQ-4501G",
    "value": "BQru BQ-4501G"
  },
  {
    "name": "Welcome S22 Ultra",
    "label": "Welcome S22 Ultra",
    "value": "Welcome S22 Ultra"
  },
  {
    "name": "Vanzo Star_Plus",
    "label": "Vanzo Star_Plus",
    "value": "Vanzo Star_Plus"
  },
  {
    "name": "incar 77-EV",
    "label": "incar 77-EV",
    "value": "incar 77-EV"
  },
  {
    "name": "RAVOZ RAVOZ Z3 Pro",
    "label": "RAVOZ RAVOZ Z3 Pro",
    "value": "RAVOZ RAVOZ Z3 Pro"
  },
  {
    "name": "SHARP A003SH",
    "label": "SHARP A003SH",
    "value": "SHARP A003SH"
  },
  {
    "name": "vivo vivo V15",
    "label": "vivo vivo V15",
    "value": "vivo vivo V15"
  },
  {
    "name": "BLU Tank Xtreme",
    "label": "BLU Tank Xtreme",
    "value": "BLU Tank Xtreme"
  },
  {
    "name": "KYOCERA 705KC",
    "label": "KYOCERA 705KC",
    "value": "KYOCERA 705KC"
  },
  {
    "name": "LEAGOO S9",
    "label": "LEAGOO S9",
    "value": "LEAGOO S9"
  },
  {
    "name": "motorola motorola g8play",
    "label": "motorola motorola g8play",
    "value": "motorola motorola g8play"
  },
  {
    "name": "ZTE BLADE A3",
    "label": "ZTE BLADE A3",
    "value": "ZTE BLADE A3"
  },
  {
    "name": "Hisense Hisense H60 Smart",
    "label": "Hisense Hisense H60 Smart",
    "value": "Hisense Hisense H60 Smart"
  },
  {
    "name": "VIVAX VIVAX Fly6",
    "label": "VIVAX VIVAX Fly6",
    "value": "VIVAX VIVAX Fly6"
  },
  {
    "name": "LGE LML713DL",
    "label": "LGE LML713DL",
    "value": "LGE LML713DL"
  },
  {
    "name": "MOBIWIRE LOGIC X50",
    "label": "MOBIWIRE LOGIC X50",
    "value": "MOBIWIRE LOGIC X50"
  },
  {
    "name": "Casper VIA_G3",
    "label": "Casper VIA_G3",
    "value": "Casper VIA_G3"
  },
  {
    "name": "incar iTAB-A1",
    "label": "incar iTAB-A1",
    "value": "incar iTAB-A1"
  },
  {
    "name": "Cherry_Mobile Superion_S2_Plus",
    "label": "Cherry_Mobile Superion_S2_Plus",
    "value": "Cherry_Mobile Superion_S2_Plus"
  },
  {
    "name": "FUJITSU F-01L",
    "label": "FUJITSU F-01L",
    "value": "FUJITSU F-01L"
  },
  {
    "name": "evertrade VortexPlus",
    "label": "evertrade VortexPlus",
    "value": "evertrade VortexPlus"
  },
  {
    "name": "Blackview BL8800Pro",
    "label": "Blackview BL8800Pro",
    "value": "Blackview BL8800Pro"
  },
  {
    "name": "vivo V1809A",
    "label": "vivo V1809A",
    "value": "vivo V1809A"
  },
  {
    "name": "P40_Pro_Max P40 Pro Max",
    "label": "P40_Pro_Max P40 Pro Max",
    "value": "P40_Pro_Max P40 Pro Max"
  },
  {
    "name": "Blackview Tab 12",
    "label": "Blackview Tab 12",
    "value": "Blackview Tab 12"
  },
  {
    "name": "OPPO PDNM00",
    "label": "OPPO PDNM00",
    "value": "OPPO PDNM00"
  },
  {
    "name": "Hisense Hisense V50",
    "label": "Hisense Hisense V50",
    "value": "Hisense Hisense V50"
  },
  {
    "name": "EPIKONE X516",
    "label": "EPIKONE X516",
    "value": "EPIKONE X516"
  },
  {
    "name": "vernee T3 Pro",
    "label": "vernee T3 Pro",
    "value": "vernee T3 Pro"
  },
  {
    "name": "bnd POMDTB005",
    "label": "bnd POMDTB005",
    "value": "bnd POMDTB005"
  },
  {
    "name": "HUAWEI PAR-TL00",
    "label": "HUAWEI PAR-TL00",
    "value": "HUAWEI PAR-TL00"
  },
  {
    "name": "ZUUM MAGNO MINI",
    "label": "ZUUM MAGNO MINI",
    "value": "ZUUM MAGNO MINI"
  },
  {
    "name": "mediacom 1AZ2P",
    "label": "mediacom 1AZ2P",
    "value": "mediacom 1AZ2P"
  },
  {
    "name": "Sony XQ-CQ54",
    "label": "Sony XQ-CQ54",
    "value": "Sony XQ-CQ54"
  },
  {
    "name": "alps R7",
    "label": "alps R7",
    "value": "alps R7"
  },
  {
    "name": "KRONO NET_TITAN",
    "label": "KRONO NET_TITAN",
    "value": "KRONO NET_TITAN"
  },
  {
    "name": "Apple Mmatshepo’s iPhone",
    "label": "Apple Mmatshepo’s iPhone",
    "value": "Apple Mmatshepo’s iPhone"
  },
  {
    "name": "Droi X-treme_PQ54 MAX",
    "label": "Droi X-treme_PQ54 MAX",
    "value": "Droi X-treme_PQ54 MAX"
  },
  {
    "name": "WIKO W_K400",
    "label": "WIKO W_K400",
    "value": "WIKO W_K400"
  },
  {
    "name": "Zelumobile C400",
    "label": "Zelumobile C400",
    "value": "Zelumobile C400"
  },
  {
    "name": "IMG ARCTIC",
    "label": "IMG ARCTIC",
    "value": "IMG ARCTIC"
  },
  {
    "name": "GHIA GTA7WF",
    "label": "GHIA GTA7WF",
    "value": "GHIA GTA7WF"
  },
  {
    "name": "incar AOYODKG_A38",
    "label": "incar AOYODKG_A38",
    "value": "incar AOYODKG_A38"
  },
  {
    "name": "Rockchip H96_Max_V11",
    "label": "Rockchip H96_Max_V11",
    "value": "Rockchip H96_Max_V11"
  },
  {
    "name": "Fortune Ship iKU A70",
    "label": "Fortune Ship iKU A70",
    "value": "Fortune Ship iKU A70"
  },
  {
    "name": "ZTE Z-Ultra",
    "label": "ZTE Z-Ultra",
    "value": "ZTE Z-Ultra"
  },
  {
    "name": "incar HZ0010J",
    "label": "incar HZ0010J",
    "value": "incar HZ0010J"
  },
  {
    "name": "samsung SM-T567V",
    "label": "samsung SM-T567V",
    "value": "samsung SM-T567V"
  },
  {
    "name": "BLU C6",
    "label": "BLU C6",
    "value": "BLU C6"
  },
  {
    "name": "vivo V1818CT",
    "label": "vivo V1818CT",
    "value": "vivo V1818CT"
  },
  {
    "name": "Google Samsung Chromebook Plus (V2)",
    "label": "Google Samsung Chromebook Plus (V2)",
    "value": "Google Samsung Chromebook Plus (V2)"
  },
  {
    "name": "samsung SM-A236U1",
    "label": "samsung SM-A236U1",
    "value": "samsung SM-A236U1"
  },
  {
    "name": "WIKO View Prime",
    "label": "WIKO View Prime",
    "value": "WIKO View Prime"
  },
  {
    "name": "WOLKI W626 VITAL",
    "label": "WOLKI W626 VITAL",
    "value": "WOLKI W626 VITAL"
  },
  {
    "name": "Wingtech T Phone Pro",
    "label": "Wingtech T Phone Pro",
    "value": "Wingtech T Phone Pro"
  },
  {
    "name": "Hisense Hisense V5",
    "label": "Hisense Hisense V5",
    "value": "Hisense Hisense V5"
  },
  {
    "name": "vivo V1955A",
    "label": "vivo V1955A",
    "value": "vivo V1955A"
  },
  {
    "name": "samsung SM-T385S",
    "label": "samsung SM-T385S",
    "value": "samsung SM-T385S"
  },
  {
    "name": "SPA Condor Electronics Allure M1 Plus",
    "label": "SPA Condor Electronics Allure M1 Plus",
    "value": "SPA Condor Electronics Allure M1 Plus"
  },
  {
    "name": "samsung SM-G935R4",
    "label": "samsung SM-G935R4",
    "value": "samsung SM-G935R4"
  },
  {
    "name": "ZTE ZTE Blade L9RU",
    "label": "ZTE ZTE Blade L9RU",
    "value": "ZTE ZTE Blade L9RU"
  },
  {
    "name": "ILA ILA X3",
    "label": "ILA ILA X3",
    "value": "ILA ILA X3"
  },
  {
    "name": "HUAWEI VKY_AL00",
    "label": "HUAWEI VKY_AL00",
    "value": "HUAWEI VKY_AL00"
  },
  {
    "name": "Vonino iMart_Pro",
    "label": "Vonino iMart_Pro",
    "value": "Vonino iMart_Pro"
  },
  {
    "name": "ZTE Z610DL",
    "label": "ZTE Z610DL",
    "value": "ZTE Z610DL"
  },
  {
    "name": "DOOGEE X60",
    "label": "DOOGEE X60",
    "value": "DOOGEE X60"
  },
  {
    "name": "Micromax Micromax E6523",
    "label": "Micromax Micromax E6523",
    "value": "Micromax Micromax E6523"
  },
  {
    "name": "iPad8,9 iPad",
    "label": "iPad8,9 iPad",
    "value": "iPad8,9 iPad"
  },
  {
    "name": "Apple B’s iPhone",
    "label": "Apple B’s iPhone",
    "value": "Apple B’s iPhone"
  },
  {
    "name": "TCL 6125D",
    "label": "TCL 6125D",
    "value": "TCL 6125D"
  },
  {
    "name": "DOOGEE N100",
    "label": "DOOGEE N100",
    "value": "DOOGEE N100"
  },
  {
    "name": "vivo V2111A",
    "label": "vivo V2111A",
    "value": "vivo V2111A"
  },
  {
    "name": "Siragon SP-7100",
    "label": "Siragon SP-7100",
    "value": "Siragon SP-7100"
  },
  {
    "name": "alps JD30",
    "label": "alps JD30",
    "value": "alps JD30"
  },
  {
    "name": "BLU S5",
    "label": "BLU S5",
    "value": "BLU S5"
  },
  {
    "name": "OnePlus CPH2487",
    "label": "OnePlus CPH2487",
    "value": "OnePlus CPH2487"
  },
  {
    "name": "Casper VIA_S30",
    "label": "Casper VIA_S30",
    "value": "Casper VIA_S30"
  },
  {
    "name": "benco AE9950",
    "label": "benco AE9950",
    "value": "benco AE9950"
  },
  {
    "name": "OPPO PEGT10",
    "label": "OPPO PEGT10",
    "value": "OPPO PEGT10"
  },
  {
    "name": "Positivo Twist SE",
    "label": "Positivo Twist SE",
    "value": "Positivo Twist SE"
  },
  {
    "name": "BullittGroupLimited S61",
    "label": "BullittGroupLimited S61",
    "value": "BullittGroupLimited S61"
  },
  {
    "name": "Prestigio PSP7546DUO",
    "label": "Prestigio PSP7546DUO",
    "value": "Prestigio PSP7546DUO"
  },
  {
    "name": "DOOGEE S95Pro",
    "label": "DOOGEE S95Pro",
    "value": "DOOGEE S95Pro"
  },
  {
    "name": "Foxx Development Inc T8 Plus",
    "label": "Foxx Development Inc T8 Plus",
    "value": "Foxx Development Inc T8 Plus"
  },
  {
    "name": "LGE LGM-X600L",
    "label": "LGE LGM-X600L",
    "value": "LGE LGM-X600L"
  },
  {
    "name": "Foxx Development Inc. P8",
    "label": "Foxx Development Inc. P8",
    "value": "Foxx Development Inc. P8"
  },
  {
    "name": "samsung SM-T585N0",
    "label": "samsung SM-T585N0",
    "value": "samsung SM-T585N0"
  },
  {
    "name": "asus ASUS_A006",
    "label": "asus ASUS_A006",
    "value": "asus ASUS_A006"
  },
  {
    "name": "Great BL350",
    "label": "Great BL350",
    "value": "Great BL350"
  },
  {
    "name": "ITEL itel A662L",
    "label": "ITEL itel A662L",
    "value": "ITEL itel A662L"
  },
  {
    "name": "samsung SCV45",
    "label": "samsung SCV45",
    "value": "samsung SCV45"
  },
  {
    "name": "samsung SM-A750X",
    "label": "samsung SM-A750X",
    "value": "samsung SM-A750X"
  },
  {
    "name": "Libre W808",
    "label": "Libre W808",
    "value": "Libre W808"
  },
  {
    "name": "Reeder P13 Blue Max PL",
    "label": "Reeder P13 Blue Max PL",
    "value": "Reeder P13 Blue Max PL"
  },
  {
    "name": "FCC 7A",
    "label": "FCC 7A",
    "value": "FCC 7A"
  },
  {
    "name": "Sony I3123",
    "label": "Sony I3123",
    "value": "Sony I3123"
  },
  {
    "name": "Sony H4493",
    "label": "Sony H4493",
    "value": "Sony H4493"
  },
  {
    "name": "iHunt S20 Ultra ApeX 2021",
    "label": "iHunt S20 Ultra ApeX 2021",
    "value": "iHunt S20 Ultra ApeX 2021"
  },
  {
    "name": "STG STG A2 Pro",
    "label": "STG STG A2 Pro",
    "value": "STG STG A2 Pro"
  },
  {
    "name": "alps P50 pro",
    "label": "alps P50 pro",
    "value": "alps P50 pro"
  },
  {
    "name": "vivo V2029_PK",
    "label": "vivo V2029_PK",
    "value": "vivo V2029_PK"
  },
  {
    "name": "Xiaomi Redmi 8 / 8A / 8A Dual",
    "label": "Xiaomi Redmi 8 / 8A / 8A Dual",
    "value": "Xiaomi Redmi 8 / 8A / 8A Dual"
  },
  {
    "name": "Tagtech Tagphone plus",
    "label": "Tagtech Tagphone plus",
    "value": "Tagtech Tagphone plus"
  },
  {
    "name": "Joyar KODAK_SMARTWAY_L1",
    "label": "Joyar KODAK_SMARTWAY_L1",
    "value": "Joyar KODAK_SMARTWAY_L1"
  },
  {
    "name": "samsung GT-N8010",
    "label": "samsung GT-N8010",
    "value": "samsung GT-N8010"
  },
  {
    "name": "CUBOT KINGKONG MINI2",
    "label": "CUBOT KINGKONG MINI2",
    "value": "CUBOT KINGKONG MINI2"
  },
  {
    "name": "LGE 802LG",
    "label": "LGE 802LG",
    "value": "LGE 802LG"
  },
  {
    "name": "OPPO CPH2455",
    "label": "OPPO CPH2455",
    "value": "OPPO CPH2455"
  },
  {
    "name": "Xiaomi IN2013",
    "label": "Xiaomi IN2013",
    "value": "Xiaomi IN2013"
  },
  {
    "name": "Movitel M9113",
    "label": "Movitel M9113",
    "value": "Movitel M9113"
  },
  {
    "name": "vivo V2196A",
    "label": "vivo V2196A",
    "value": "vivo V2196A"
  },
  {
    "name": "bnd TF501_EC",
    "label": "bnd TF501_EC",
    "value": "bnd TF501_EC"
  },
  {
    "name": "XTR T4",
    "label": "XTR T4",
    "value": "XTR T4"
  },
  {
    "name": "Hisense KS964",
    "label": "Hisense KS964",
    "value": "Hisense KS964"
  },
  {
    "name": "samsung SM-G930R4",
    "label": "samsung SM-G930R4",
    "value": "samsung SM-G930R4"
  },
  {
    "name": "bnd PAD-7-V5",
    "label": "bnd PAD-7-V5",
    "value": "bnd PAD-7-V5"
  },
  {
    "name": "DOOGEE X95i",
    "label": "DOOGEE X95i",
    "value": "DOOGEE X95i"
  },
  {
    "name": "samsung SM-A5160",
    "label": "samsung SM-A5160",
    "value": "samsung SM-A5160"
  },
  {
    "name": "LGE LGM-X600K",
    "label": "LGE LGM-X600K",
    "value": "LGE LGM-X600K"
  },
  {
    "name": "SOHO_STYLE SS5514G",
    "label": "SOHO_STYLE SS5514G",
    "value": "SOHO_STYLE SS5514G"
  },
  {
    "name": "Xiaomi M2102K1AC",
    "label": "Xiaomi M2102K1AC",
    "value": "Xiaomi M2102K1AC"
  },
  {
    "name": "Multilaser M9_3G",
    "label": "Multilaser M9_3G",
    "value": "Multilaser M9_3G"
  },
  {
    "name": "EKINOX K5",
    "label": "EKINOX K5",
    "value": "EKINOX K5"
  },
  {
    "name": "WIKO W-P611-EEA",
    "label": "WIKO W-P611-EEA",
    "value": "WIKO W-P611-EEA"
  },
  {
    "name": "vivo vivo X9s L",
    "label": "vivo vivo X9s L",
    "value": "vivo vivo X9s L"
  },
  {
    "name": "Fortune Ship Wildfire E2",
    "label": "Fortune Ship Wildfire E2",
    "value": "Fortune Ship Wildfire E2"
  },
  {
    "name": "DIXON ACK1010",
    "label": "DIXON ACK1010",
    "value": "DIXON ACK1010"
  },
  {
    "name": "OALE db1",
    "label": "OALE db1",
    "value": "OALE db1"
  },
  {
    "name": "LGE LG-G710",
    "label": "LGE LG-G710",
    "value": "LGE LG-G710"
  },
  {
    "name": "Allwinner TAB-H8",
    "label": "Allwinner TAB-H8",
    "value": "Allwinner TAB-H8"
  },
  {
    "name": "incar 1100AS",
    "label": "incar 1100AS",
    "value": "incar 1100AS"
  },
  {
    "name": "motorola moto g53 5G",
    "label": "motorola moto g53 5G",
    "value": "motorola moto g53 5G"
  },
  {
    "name": "LOGIC LOGIC L4T",
    "label": "LOGIC LOGIC L4T",
    "value": "LOGIC LOGIC L4T"
  },
  {
    "name": "Teclast M40SE_ROW",
    "label": "Teclast M40SE_ROW",
    "value": "Teclast M40SE_ROW"
  },
  {
    "name": "KRONO NET9",
    "label": "KRONO NET9",
    "value": "KRONO NET9"
  },
  {
    "name": "DEXP G255",
    "label": "DEXP G255",
    "value": "DEXP G255"
  },
  {
    "name": "Hurricane Storm",
    "label": "Hurricane Storm",
    "value": "Hurricane Storm"
  },
  {
    "name": "BQru BQ-5514G",
    "label": "BQru BQ-5514G",
    "value": "BQru BQ-5514G"
  },
  {
    "name": "I12 Pro I12 Pro",
    "label": "I12 Pro I12 Pro",
    "value": "I12 Pro I12 Pro"
  },
  {
    "name": "HUAWEI EDI-AL10",
    "label": "HUAWEI EDI-AL10",
    "value": "HUAWEI EDI-AL10"
  },
  {
    "name": "samsung SM-S918U1",
    "label": "samsung SM-S918U1",
    "value": "samsung SM-S918U1"
  },
  {
    "name": "Droi Rigid Triton",
    "label": "Droi Rigid Triton",
    "value": "Droi Rigid Triton"
  },
  {
    "name": "Cherry_Mobile Flare S7 Max",
    "label": "Cherry_Mobile Flare S7 Max",
    "value": "Cherry_Mobile Flare S7 Max"
  },
  {
    "name": "G-Tab P733",
    "label": "G-Tab P733",
    "value": "G-Tab P733"
  },
  {
    "name": "incar S10_ROW",
    "label": "incar S10_ROW",
    "value": "incar S10_ROW"
  },
  {
    "name": "IPRO WePad",
    "label": "IPRO WePad",
    "value": "IPRO WePad"
  },
  {
    "name": "Doppio U503",
    "label": "Doppio U503",
    "value": "Doppio U503"
  },
  {
    "name": "vivo V2145A",
    "label": "vivo V2145A",
    "value": "vivo V2145A"
  },
  {
    "name": "motorola XT1562",
    "label": "motorola XT1562",
    "value": "motorola XT1562"
  },
  {
    "name": "samsung SM-S918U",
    "label": "samsung SM-S918U",
    "value": "samsung SM-S918U"
  },
  {
    "name": "ZTE K83CA",
    "label": "ZTE K83CA",
    "value": "ZTE K83CA"
  },
  {
    "name": "Desktop - GNU",
    "label": "Desktop - GNU",
    "value": "Desktop - GNU"
  },
  {
    "name": "26969bcf86747d4e 47c86d4f8486a44c",
    "label": "26969bcf86747d4e 47c86d4f8486a44c",
    "value": "26969bcf86747d4e 47c86d4f8486a44c"
  },
  {
    "name": "BQru BQ-6645L",
    "label": "BQru BQ-6645L",
    "value": "BQru BQ-6645L"
  },
  {
    "name": "Apple 12 Pro Max",
    "label": "Apple 12 Pro Max",
    "value": "Apple 12 Pro Max"
  },
  {
    "name": "FISE MAX1",
    "label": "FISE MAX1",
    "value": "FISE MAX1"
  },
  {
    "name": "EXO TabletEXO Wave i101H",
    "label": "EXO TabletEXO Wave i101H",
    "value": "EXO TabletEXO Wave i101H"
  },
  {
    "name": "iPad6,12 iPad",
    "label": "iPad6,12 iPad",
    "value": "iPad6,12 iPad"
  },
  {
    "name": "iPad5,4 iPad",
    "label": "iPad5,4 iPad",
    "value": "iPad5,4 iPad"
  },
  {
    "name": "LGE LGL423DL",
    "label": "LGE LGL423DL",
    "value": "LGE LGL423DL"
  },
  {
    "name": "Bmobile AX830",
    "label": "Bmobile AX830",
    "value": "Bmobile AX830"
  },
  {
    "name": "ANT DAO TECHNOLOGY LIMITED H2022",
    "label": "ANT DAO TECHNOLOGY LIMITED H2022",
    "value": "ANT DAO TECHNOLOGY LIMITED H2022"
  },
  {
    "name": "Symphony Z42",
    "label": "Symphony Z42",
    "value": "Symphony Z42"
  },
  {
    "name": "BLU Vivo XL4",
    "label": "BLU Vivo XL4",
    "value": "BLU Vivo XL4"
  },
  {
    "name": "iPad8,6 iPad",
    "label": "iPad8,6 iPad",
    "value": "iPad8,6 iPad"
  },
  {
    "name": "Apple Kea’s iPhone ",
    "label": "Apple Kea’s iPhone ",
    "value": "Apple Kea’s iPhone "
  },
  {
    "name": "Apple Nkululeko’s iPhone",
    "label": "Apple Nkululeko’s iPhone",
    "value": "Apple Nkululeko’s iPhone"
  },
  {
    "name": "HTC HTC_U-3u",
    "label": "HTC HTC_U-3u",
    "value": "HTC HTC_U-3u"
  },
  {
    "name": "HUAWEI BNE-LX1",
    "label": "HUAWEI BNE-LX1",
    "value": "HUAWEI BNE-LX1"
  },
  {
    "name": "Ulefone Armor_6E",
    "label": "Ulefone Armor_6E",
    "value": "Ulefone Armor_6E"
  },
  {
    "name": "BlackBerry BBF100-1",
    "label": "BlackBerry BBF100-1",
    "value": "BlackBerry BBF100-1"
  },
  {
    "name": "ZOOM LitePro",
    "label": "ZOOM LitePro",
    "value": "ZOOM LitePro"
  },
  {
    "name": "samsung SM-S911N",
    "label": "samsung SM-S911N",
    "value": "samsung SM-S911N"
  },
  {
    "name": "acer ATAB1422E",
    "label": "acer ATAB1422E",
    "value": "acer ATAB1422E"
  },
  {
    "name": "asus ASUS_Z01KS",
    "label": "asus ASUS_Z01KS",
    "value": "asus ASUS_Z01KS"
  },
  {
    "name": "Sony E6883",
    "label": "Sony E6883",
    "value": "Sony E6883"
  },
  {
    "name": "MobiWire Kicka_4_Plus",
    "label": "MobiWire Kicka_4_Plus",
    "value": "MobiWire Kicka_4_Plus"
  },
  {
    "name": "Apple Joleen’s Iphone",
    "label": "Apple Joleen’s Iphone",
    "value": "Apple Joleen’s Iphone"
  },
  {
    "name": "SMART PRODUCTS CONNECTION SA S22_EEA",
    "label": "SMART PRODUCTS CONNECTION SA S22_EEA",
    "value": "SMART PRODUCTS CONNECTION SA S22_EEA"
  },
  {
    "name": "asus ZB633KL",
    "label": "asus ZB633KL",
    "value": "asus ZB633KL"
  },
  {
    "name": "LGE LM-Q925S",
    "label": "LGE LM-Q925S",
    "value": "LGE LM-Q925S"
  },
  {
    "name": "iPad11,3 iPad",
    "label": "iPad11,3 iPad",
    "value": "iPad11,3 iPad"
  },
  {
    "name": "HYUNDAI Ultra_Vision_Plus",
    "label": "HYUNDAI Ultra_Vision_Plus",
    "value": "HYUNDAI Ultra_Vision_Plus"
  },
  {
    "name": "Mobicel VX19",
    "label": "Mobicel VX19",
    "value": "Mobicel VX19"
  },
  {
    "name": "Tech Pad X9",
    "label": "Tech Pad X9",
    "value": "Tech Pad X9"
  },
  {
    "name": "Droidlogic X96Max_Plus2",
    "label": "Droidlogic X96Max_Plus2",
    "value": "Droidlogic X96Max_Plus2"
  },
  {
    "name": "incar AZIMUT 3 Lite",
    "label": "incar AZIMUT 3 Lite",
    "value": "incar AZIMUT 3 Lite"
  },
  {
    "name": "Qtouch Qtouch_Q81",
    "label": "Qtouch Qtouch_Q81",
    "value": "Qtouch Qtouch_Q81"
  },
  {
    "name": "Apple Thato’s iPhone",
    "label": "Apple Thato’s iPhone",
    "value": "Apple Thato’s iPhone"
  },
  {
    "name": "QMobile Rocket Pro",
    "label": "QMobile Rocket Pro",
    "value": "QMobile Rocket Pro"
  },
  {
    "name": "Fortune Ship Gplus X10",
    "label": "Fortune Ship Gplus X10",
    "value": "Fortune Ship Gplus X10"
  },
  {
    "name": "alps i14 Pro Max",
    "label": "alps i14 Pro Max",
    "value": "alps i14 Pro Max"
  },
  {
    "name": "Sony SO-04H",
    "label": "Sony SO-04H",
    "value": "Sony SO-04H"
  },
  {
    "name": "Cherry_Mobile Omega_HD_Duo",
    "label": "Cherry_Mobile Omega_HD_Duo",
    "value": "Cherry_Mobile Omega_HD_Duo"
  },
  {
    "name": "PREMIO X87",
    "label": "PREMIO X87",
    "value": "PREMIO X87"
  },
  {
    "name": "samsung SM-T865N",
    "label": "samsung SM-T865N",
    "value": "samsung SM-T865N"
  },
  {
    "name": "SHARP SH-01M",
    "label": "SHARP SH-01M",
    "value": "SHARP SH-01M"
  },
  {
    "name": "Lanix Ilium Alpha 1T",
    "label": "Lanix Ilium Alpha 1T",
    "value": "Lanix Ilium Alpha 1T"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO IA5",
    "label": "TECNO MOBILE LIMITED TECNO IA5",
    "value": "TECNO MOBILE LIMITED TECNO IA5"
  },
  {
    "name": "Xiaomi camellia",
    "label": "Xiaomi camellia",
    "value": "Xiaomi camellia"
  },
  {
    "name": "Along VNT_Tab8",
    "label": "Along VNT_Tab8",
    "value": "Along VNT_Tab8"
  },
  {
    "name": "Argos Alba7Q",
    "label": "Argos Alba7Q",
    "value": "Argos Alba7Q"
  },
  {
    "name": "HOME3 HOME3",
    "label": "HOME3 HOME3",
    "value": "HOME3 HOME3"
  },
  {
    "name": "meizu meizu M10",
    "label": "meizu meizu M10",
    "value": "meizu meizu M10"
  },
  {
    "name": "reeder P13_Blue_Maks",
    "label": "reeder P13_Blue_Maks",
    "value": "reeder P13_Blue_Maks"
  },
  {
    "name": "Condor Infinity_i4",
    "label": "Condor Infinity_i4",
    "value": "Condor Infinity_i4"
  },
  {
    "name": "Archos Alba 57",
    "label": "Archos Alba 57",
    "value": "Archos Alba 57"
  },
  {
    "name": "incar E101GC",
    "label": "incar E101GC",
    "value": "incar E101GC"
  },
  {
    "name": "WIKO W-K510-SFC",
    "label": "WIKO W-K510-SFC",
    "value": "WIKO W-K510-SFC"
  },
  {
    "name": "Apple Christy’s iPhone",
    "label": "Apple Christy’s iPhone",
    "value": "Apple Christy’s iPhone"
  },
  {
    "name": "TCL 5052A",
    "label": "TCL 5052A",
    "value": "TCL 5052A"
  },
  {
    "name": "Blackview BV6800Pro",
    "label": "Blackview BV6800Pro",
    "value": "Blackview BV6800Pro"
  },
  {
    "name": "deejoy POMDTB006",
    "label": "deejoy POMDTB006",
    "value": "deejoy POMDTB006"
  },
  {
    "name": "Apple Deepak's iphone 🤣",
    "label": "Apple Deepak's iphone 🤣",
    "value": "Apple Deepak's iphone 🤣"
  },
  {
    "name": "Apple Ethan’s iPhone",
    "label": "Apple Ethan’s iPhone",
    "value": "Apple Ethan’s iPhone"
  },
  {
    "name": "Mobicel EPIC PRO_2",
    "label": "Mobicel EPIC PRO_2",
    "value": "Mobicel EPIC PRO_2"
  },
  {
    "name": "mPTech Hammer_Explorer_Pro",
    "label": "mPTech Hammer_Explorer_Pro",
    "value": "mPTech Hammer_Explorer_Pro"
  },
  {
    "name": "BQ BQ-6051G",
    "label": "BQ BQ-6051G",
    "value": "BQ BQ-6051G"
  },
  {
    "name": "HAFURY A7",
    "label": "HAFURY A7",
    "value": "HAFURY A7"
  },
  {
    "name": "Xiaomi Pixel",
    "label": "Xiaomi Pixel",
    "value": "Xiaomi Pixel"
  },
  {
    "name": "Mobile Web - Windows Mobile",
    "label": "Mobile Web - Windows Mobile",
    "value": "Mobile Web - Windows Mobile"
  },
  {
    "name": "Archos Archos Oxygen 63",
    "label": "Archos Archos Oxygen 63",
    "value": "Archos Archos Oxygen 63"
  },
  {
    "name": "incar P2000",
    "label": "incar P2000",
    "value": "incar P2000"
  },
  {
    "name": "Elephone E10_Pro",
    "label": "Elephone E10_Pro",
    "value": "Elephone E10_Pro"
  },
  {
    "name": "STG STG S30",
    "label": "STG STG S30",
    "value": "STG STG S30"
  },
  {
    "name": "X-TIGI X_TIGI_A2",
    "label": "X-TIGI X_TIGI_A2",
    "value": "X-TIGI X_TIGI_A2"
  },
  {
    "name": "OPPO PBAT00",
    "label": "OPPO PBAT00",
    "value": "OPPO PBAT00"
  },
  {
    "name": "samsung SM-J700F",
    "label": "samsung SM-J700F",
    "value": "samsung SM-J700F"
  },
  {
    "name": "evertrade pulse",
    "label": "evertrade pulse",
    "value": "evertrade pulse"
  },
  {
    "name": "OPPO oppo6779",
    "label": "OPPO oppo6779",
    "value": "OPPO oppo6779"
  },
  {
    "name": "DOOGEE S90",
    "label": "DOOGEE S90",
    "value": "DOOGEE S90"
  },
  {
    "name": "MAXFONE MAX10",
    "label": "MAXFONE MAX10",
    "value": "MAXFONE MAX10"
  },
  {
    "name": "SUGAR SUGAR Y15",
    "label": "SUGAR SUGAR Y15",
    "value": "SUGAR SUGAR Y15"
  },
  {
    "name": "BLU M7L",
    "label": "BLU M7L",
    "value": "BLU M7L"
  },
  {
    "name": "SKY Platinum_UNO",
    "label": "SKY Platinum_UNO",
    "value": "SKY Platinum_UNO"
  },
  {
    "name": "Aspera Nitro",
    "label": "Aspera Nitro",
    "value": "Aspera Nitro"
  },
  {
    "name": "WALTON Primo S7 Pro",
    "label": "WALTON Primo S7 Pro",
    "value": "WALTON Primo S7 Pro"
  },
  {
    "name": "BDF BDF",
    "label": "BDF BDF",
    "value": "BDF BDF"
  },
  {
    "name": "vivo vivo Z1i",
    "label": "vivo vivo Z1i",
    "value": "vivo vivo Z1i"
  },
  {
    "name": "ASUS Max Pro M2",
    "label": "ASUS Max Pro M2",
    "value": "ASUS Max Pro M2"
  },
  {
    "name": "CUBOT CUBOT_NOVA",
    "label": "CUBOT CUBOT_NOVA",
    "value": "CUBOT CUBOT_NOVA"
  },
  {
    "name": "OPPO OPPO A79",
    "label": "OPPO OPPO A79",
    "value": "OPPO OPPO A79"
  },
  {
    "name": "motorola XT1799-2",
    "label": "motorola XT1799-2",
    "value": "motorola XT1799-2"
  },
  {
    "name": "Joyar F10_PRIME",
    "label": "Joyar F10_PRIME",
    "value": "Joyar F10_PRIME"
  },
  {
    "name": "SPA Condor Electronics Plume L4 Pro",
    "label": "SPA Condor Electronics Plume L4 Pro",
    "value": "SPA Condor Electronics Plume L4 Pro"
  },
  {
    "name": "Casper VIA F30",
    "label": "Casper VIA F30",
    "value": "Casper VIA F30"
  },
  {
    "name": "Desktop - ANDROID",
    "label": "Desktop - ANDROID",
    "value": "Desktop - ANDROID"
  },
  {
    "name": "Apple iPhone 11 pro",
    "label": "Apple iPhone 11 pro",
    "value": "Apple iPhone 11 pro"
  },
  {
    "name": "Qnet K37+",
    "label": "Qnet K37+",
    "value": "Qnet K37+"
  },
  {
    "name": "JOYAR 100092980",
    "label": "JOYAR 100092980",
    "value": "JOYAR 100092980"
  },
  {
    "name": "HUAWEI FRD-DL00",
    "label": "HUAWEI FRD-DL00",
    "value": "HUAWEI FRD-DL00"
  },
  {
    "name": "Xiaomi pomelo",
    "label": "Xiaomi pomelo",
    "value": "Xiaomi pomelo"
  },
  {
    "name": "incar L211",
    "label": "incar L211",
    "value": "incar L211"
  },
  {
    "name": "STYLO S49 ECO",
    "label": "STYLO S49 ECO",
    "value": "STYLO S49 ECO"
  },
  {
    "name": "Xiaomi 2212ARNC4L",
    "label": "Xiaomi 2212ARNC4L",
    "value": "Xiaomi 2212ARNC4L"
  },
  {
    "name": "LGE LM-X210LMW",
    "label": "LGE LM-X210LMW",
    "value": "LGE LM-X210LMW"
  },
  {
    "name": "^~^ iPad",
    "label": "^~^ iPad",
    "value": "^~^ iPad"
  },
  {
    "name": "Hisense KS605",
    "label": "Hisense KS605",
    "value": "Hisense KS605"
  },
  {
    "name": "OnePlus LE2127",
    "label": "OnePlus LE2127",
    "value": "OnePlus LE2127"
  },
  {
    "name": "magcomm Max70 Plus",
    "label": "magcomm Max70 Plus",
    "value": "magcomm Max70 Plus"
  },
  {
    "name": "Yulong Coolpad 3310A",
    "label": "Yulong Coolpad 3310A",
    "value": "Yulong Coolpad 3310A"
  },
  {
    "name": "HUAWEI PAR-TL20",
    "label": "HUAWEI PAR-TL20",
    "value": "HUAWEI PAR-TL20"
  },
  {
    "name": "Cyrus CS45XA",
    "label": "Cyrus CS45XA",
    "value": "Cyrus CS45XA"
  },
  {
    "name": "Apple Camiel’s iPhone",
    "label": "Apple Camiel’s iPhone",
    "value": "Apple Camiel’s iPhone"
  },
  {
    "name": "samsung SC-41A",
    "label": "samsung SC-41A",
    "value": "samsung SC-41A"
  },
  {
    "name": "samsung SM-J320W8",
    "label": "samsung SM-J320W8",
    "value": "samsung SM-J320W8"
  },
  {
    "name": "vivo vivo Y17",
    "label": "vivo vivo Y17",
    "value": "vivo vivo Y17"
  },
  {
    "name": "Casper L20",
    "label": "Casper L20",
    "value": "Casper L20"
  },
  {
    "name": "TECNO TECNO BF6",
    "label": "TECNO TECNO BF6",
    "value": "TECNO TECNO BF6"
  },
  {
    "name": "Sony H8314",
    "label": "Sony H8314",
    "value": "Sony H8314"
  },
  {
    "name": "DEXP K41",
    "label": "DEXP K41",
    "value": "DEXP K41"
  },
  {
    "name": "HMD Global Nokia X5",
    "label": "HMD Global Nokia X5",
    "value": "HMD Global Nokia X5"
  },
  {
    "name": "EPIKONE K406",
    "label": "EPIKONE K406",
    "value": "EPIKONE K406"
  },
  {
    "name": "samsung SM-G935VC",
    "label": "samsung SM-G935VC",
    "value": "samsung SM-G935VC"
  },
  {
    "name": "BlackBerry BBB100-3",
    "label": "BlackBerry BBB100-3",
    "value": "BlackBerry BBB100-3"
  },
  {
    "name": "myPhone Prime_4_Lite",
    "label": "myPhone Prime_4_Lite",
    "value": "myPhone Prime_4_Lite"
  },
  {
    "name": "Newstart W40",
    "label": "Newstart W40",
    "value": "Newstart W40"
  },
  {
    "name": "HUAWEI EBG-AN00",
    "label": "HUAWEI EBG-AN00",
    "value": "HUAWEI EBG-AN00"
  },
  {
    "name": "LENOVO Lenovo TB-J607Z",
    "label": "LENOVO Lenovo TB-J607Z",
    "value": "LENOVO Lenovo TB-J607Z"
  },
  {
    "name": "PRITOM Volentex_70",
    "label": "PRITOM Volentex_70",
    "value": "PRITOM Volentex_70"
  },
  {
    "name": "iBRIT i5+",
    "label": "iBRIT i5+",
    "value": "iBRIT i5+"
  },
  {
    "name": "bq Aquaris U Plus",
    "label": "bq Aquaris U Plus",
    "value": "bq Aquaris U Plus"
  },
  {
    "name": "KX20 X10",
    "label": "KX20 X10",
    "value": "KX20 X10"
  },
  {
    "name": "TINNO SUGAR T30",
    "label": "TINNO SUGAR T30",
    "value": "TINNO SUGAR T30"
  },
  {
    "name": "LeEco LEX821",
    "label": "LeEco LEX821",
    "value": "LeEco LEX821"
  },
  {
    "name": "Google asurada",
    "label": "Google asurada",
    "value": "Google asurada"
  },
  {
    "name": "OnePlus LE2120",
    "label": "OnePlus LE2120",
    "value": "OnePlus LE2120"
  },
  {
    "name": "DOOGEE S55",
    "label": "DOOGEE S55",
    "value": "DOOGEE S55"
  },
  {
    "name": "vivo V2164A",
    "label": "vivo V2164A",
    "value": "vivo V2164A"
  },
  {
    "name": "FISE M5GO",
    "label": "FISE M5GO",
    "value": "FISE M5GO"
  },
  {
    "name": "Symphony Z30 pro",
    "label": "Symphony Z30 pro",
    "value": "Symphony Z30 pro"
  },
  {
    "name": "Elephone A7H",
    "label": "Elephone A7H",
    "value": "Elephone A7H"
  },
  {
    "name": "SPA Condor Electronics TB717G",
    "label": "SPA Condor Electronics TB717G",
    "value": "SPA Condor Electronics TB717G"
  },
  {
    "name": "LENOVO LIFETAB E1070X",
    "label": "LENOVO LIFETAB E1070X",
    "value": "LENOVO LIFETAB E1070X"
  },
  {
    "name": "motorola moto e13",
    "label": "motorola moto e13",
    "value": "motorola moto e13"
  },
  {
    "name": "Gtel Gtel Infinity 9",
    "label": "Gtel Gtel Infinity 9",
    "value": "Gtel Gtel Infinity 9"
  },
  {
    "name": "ZTE ZTE 7540N",
    "label": "ZTE ZTE 7540N",
    "value": "ZTE ZTE 7540N"
  },
  {
    "name": "Quantum Qrash",
    "label": "Quantum Qrash",
    "value": "Quantum Qrash"
  },
  {
    "name": "MyPhone myWX1 Plus",
    "label": "MyPhone myWX1 Plus",
    "value": "MyPhone myWX1 Plus"
  },
  {
    "name": "Artel Artel Quadro Pro",
    "label": "Artel Artel Quadro Pro",
    "value": "Artel Artel Quadro Pro"
  },
  {
    "name": "positivo TabQ8-T800",
    "label": "positivo TabQ8-T800",
    "value": "positivo TabQ8-T800"
  },
  {
    "name": "lenovo Lenovo K12 Pro",
    "label": "lenovo Lenovo K12 Pro",
    "value": "lenovo Lenovo K12 Pro"
  },
  {
    "name": "KODAK Smartway_T1",
    "label": "KODAK Smartway_T1",
    "value": "KODAK Smartway_T1"
  },
  {
    "name": "Xiaomi 23013RK75C",
    "label": "Xiaomi 23013RK75C",
    "value": "Xiaomi 23013RK75C"
  },
  {
    "name": "PANASONIC Tab 8",
    "label": "PANASONIC Tab 8",
    "value": "PANASONIC Tab 8"
  },
  {
    "name": "LANIX Ilium Alpha 5s",
    "label": "LANIX Ilium Alpha 5s",
    "value": "LANIX Ilium Alpha 5s"
  },
  {
    "name": "ELECTRONICS Mate45 Pro+",
    "label": "ELECTRONICS Mate45 Pro+",
    "value": "ELECTRONICS Mate45 Pro+"
  },
  {
    "name": "samsung G950F",
    "label": "samsung G950F",
    "value": "samsung G950F"
  },
  {
    "name": "Vestel Venus V7",
    "label": "Vestel Venus V7",
    "value": "Vestel Venus V7"
  },
  {
    "name": "hulkbuster GeneralMobile hulkbuser",
    "label": "hulkbuster GeneralMobile hulkbuser",
    "value": "hulkbuster GeneralMobile hulkbuser"
  },
  {
    "name": "DEXP M110",
    "label": "DEXP M110",
    "value": "DEXP M110"
  },
  {
    "name": "Teclast P85_EEA",
    "label": "Teclast P85_EEA",
    "value": "Teclast P85_EEA"
  },
  {
    "name": "samsung SM-A810S",
    "label": "samsung SM-A810S",
    "value": "samsung SM-A810S"
  },
  {
    "name": "AIWA TAB_1003G",
    "label": "AIWA TAB_1003G",
    "value": "AIWA TAB_1003G"
  },
  {
    "name": "TCL 9029Z",
    "label": "TCL 9029Z",
    "value": "TCL 9029Z"
  },
  {
    "name": "LAVA LAVA LE9810",
    "label": "LAVA LAVA LE9810",
    "value": "LAVA LAVA LE9810"
  },
  {
    "name": "Apple Aakansha’s iPhone",
    "label": "Apple Aakansha’s iPhone",
    "value": "Apple Aakansha’s iPhone"
  },
  {
    "name": "Apple Ashish Ashu’s iPhone",
    "label": "Apple Ashish Ashu’s iPhone",
    "value": "Apple Ashish Ashu’s iPhone"
  },
  {
    "name": "Apple A.T.M",
    "label": "Apple A.T.M",
    "value": "Apple A.T.M"
  },
  {
    "name": "Apple Sunaina’s iPhone",
    "label": "Apple Sunaina’s iPhone",
    "value": "Apple Sunaina’s iPhone"
  },
  {
    "name": "Apple Imar’s iPhone",
    "label": "Apple Imar’s iPhone",
    "value": "Apple Imar’s iPhone"
  },
  {
    "name": "incar P8",
    "label": "incar P8",
    "value": "incar P8"
  },
  {
    "name": "Coolpad cp3648A",
    "label": "Coolpad cp3648A",
    "value": "Coolpad cp3648A"
  },
  {
    "name": "Prestigio PMT4208_3G_E_RU",
    "label": "Prestigio PMT4208_3G_E_RU",
    "value": "Prestigio PMT4208_3G_E_RU"
  },
  {
    "name": "TINNO U626AA",
    "label": "TINNO U626AA",
    "value": "TINNO U626AA"
  },
  {
    "name": "Android S22 Ultra 5G",
    "label": "Android S22 Ultra 5G",
    "value": "Android S22 Ultra 5G"
  },
  {
    "name": "Apple Gemma’s iPhone",
    "label": "Apple Gemma’s iPhone",
    "value": "Apple Gemma’s iPhone"
  },
  {
    "name": "Fortune Ship SICO Topaz",
    "label": "Fortune Ship SICO Topaz",
    "value": "Fortune Ship SICO Topaz"
  },
  {
    "name": "NGADU MILIK NGADU MILIK",
    "label": "NGADU MILIK NGADU MILIK",
    "value": "NGADU MILIK NGADU MILIK"
  },
  {
    "name": "Libre W101",
    "label": "Libre W101",
    "value": "Libre W101"
  },
  {
    "name": "SHARP FS8010",
    "label": "SHARP FS8010",
    "value": "SHARP FS8010"
  },
  {
    "name": "Qubo P19",
    "label": "Qubo P19",
    "value": "Qubo P19"
  },
  {
    "name": "MITO Mito_A35",
    "label": "MITO Mito_A35",
    "value": "MITO Mito_A35"
  },
  {
    "name": "samsung SM-T545",
    "label": "samsung SM-T545",
    "value": "samsung SM-T545"
  },
  {
    "name": "vivo I2212",
    "label": "vivo I2212",
    "value": "vivo I2212"
  },
  {
    "name": "wheatek BV6300",
    "label": "wheatek BV6300",
    "value": "wheatek BV6300"
  },
  {
    "name": "Deejoy V8X",
    "label": "Deejoy V8X",
    "value": "Deejoy V8X"
  },
  {
    "name": "MOBIWIRE X4G",
    "label": "MOBIWIRE X4G",
    "value": "MOBIWIRE X4G"
  },
  {
    "name": "Archos Archos Oxygen 57",
    "label": "Archos Archos Oxygen 57",
    "value": "Archos Archos Oxygen 57"
  },
  {
    "name": "KODAK D50L",
    "label": "KODAK D50L",
    "value": "KODAK D50L"
  },
  {
    "name": "LENOVO TB-X704V",
    "label": "LENOVO TB-X704V",
    "value": "LENOVO TB-X704V"
  },
  {
    "name": "MyPhone myA18",
    "label": "MyPhone myA18",
    "value": "MyPhone myA18"
  },
  {
    "name": "Apple Nay’s iPhone 13",
    "label": "Apple Nay’s iPhone 13",
    "value": "Apple Nay’s iPhone 13"
  },
  {
    "name": "inovo I02",
    "label": "inovo I02",
    "value": "inovo I02"
  },
  {
    "name": "motorola XT1789-05",
    "label": "motorola XT1789-05",
    "value": "motorola XT1789-05"
  },
  {
    "name": "Sony G8232",
    "label": "Sony G8232",
    "value": "Sony G8232"
  },
  {
    "name": "Xiaomi surya",
    "label": "Xiaomi surya",
    "value": "Xiaomi surya"
  },
  {
    "name": "samsung SM-T387AA",
    "label": "samsung SM-T387AA",
    "value": "samsung SM-T387AA"
  },
  {
    "name": "realme RMX3627",
    "label": "realme RMX3627",
    "value": "realme RMX3627"
  },
  {
    "name": "Blackview BV5500",
    "label": "Blackview BV5500",
    "value": "Blackview BV5500"
  },
  {
    "name": "vivo V2154A",
    "label": "vivo V2154A",
    "value": "vivo V2154A"
  },
  {
    "name": "Multilaser MLX8_4G",
    "label": "Multilaser MLX8_4G",
    "value": "Multilaser MLX8_4G"
  },
  {
    "name": "asus G553KL",
    "label": "asus G553KL",
    "value": "asus G553KL"
  },
  {
    "name": "WALTON Primo E10 Plus",
    "label": "WALTON Primo E10 Plus",
    "value": "WALTON Primo E10 Plus"
  },
  {
    "name": "HUAWEI BLN-AL10",
    "label": "HUAWEI BLN-AL10",
    "value": "HUAWEI BLN-AL10"
  },
  {
    "name": "KYOCERA KYV48",
    "label": "KYOCERA KYV48",
    "value": "KYOCERA KYV48"
  },
  {
    "name": "TCL T431D",
    "label": "TCL T431D",
    "value": "TCL T431D"
  },
  {
    "name": "strawberry SX",
    "label": "strawberry SX",
    "value": "strawberry SX"
  },
  {
    "name": "alps Mate 50 Pro",
    "label": "alps Mate 50 Pro",
    "value": "alps Mate 50 Pro"
  },
  {
    "name": "Mobicel Chrome LTE",
    "label": "Mobicel Chrome LTE",
    "value": "Mobicel Chrome LTE"
  },
  {
    "name": "SPA Condor Electronics Plume L4",
    "label": "SPA Condor Electronics Plume L4",
    "value": "SPA Condor Electronics Plume L4"
  },
  {
    "name": "OPPO PDYM20",
    "label": "OPPO PDYM20",
    "value": "OPPO PDYM20"
  },
  {
    "name": "Along MC32020",
    "label": "Along MC32020",
    "value": "Along MC32020"
  },
  {
    "name": "Xiaomi Redmi 9",
    "label": "Xiaomi Redmi 9",
    "value": "Xiaomi Redmi 9"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO R8O",
    "label": "TECNO MOBILE LIMITED TECNO R8O",
    "value": "TECNO MOBILE LIMITED TECNO R8O"
  },
  {
    "name": "LP LP W65",
    "label": "LP LP W65",
    "value": "LP LP W65"
  },
  {
    "name": "DEXP G550",
    "label": "DEXP G550",
    "value": "DEXP G550"
  },
  {
    "name": "WIKO W-K130-EEA",
    "label": "WIKO W-K130-EEA",
    "value": "WIKO W-K130-EEA"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO ID6",
    "label": "TECNO MOBILE LIMITED TECNO ID6",
    "value": "TECNO MOBILE LIMITED TECNO ID6"
  },
  {
    "name": "samsung SM-J3308",
    "label": "samsung SM-J3308",
    "value": "samsung SM-J3308"
  },
  {
    "name": "Meitu MP1701",
    "label": "Meitu MP1701",
    "value": "Meitu MP1701"
  },
  {
    "name": "ALLVIEW A30_Plus",
    "label": "ALLVIEW A30_Plus",
    "value": "ALLVIEW A30_Plus"
  },
  {
    "name": "Xiaomi viva",
    "label": "Xiaomi viva",
    "value": "Xiaomi viva"
  },
  {
    "name": "samsung SM-G611L",
    "label": "samsung SM-G611L",
    "value": "samsung SM-G611L"
  },
  {
    "name": "teksun X11S",
    "label": "teksun X11S",
    "value": "teksun X11S"
  },
  {
    "name": "benco AF9030",
    "label": "benco AF9030",
    "value": "benco AF9030"
  },
  {
    "name": "Cherry_Mobile Flare_S7_Power",
    "label": "Cherry_Mobile Flare_S7_Power",
    "value": "Cherry_Mobile Flare_S7_Power"
  },
  {
    "name": "HUAWEI MRX-W09",
    "label": "HUAWEI MRX-W09",
    "value": "HUAWEI MRX-W09"
  },
  {
    "name": "samsung SC-02M",
    "label": "samsung SC-02M",
    "value": "samsung SC-02M"
  },
  {
    "name": "Realmi R11",
    "label": "Realmi R11",
    "value": "Realmi R11"
  },
  {
    "name": "realme RMX3121",
    "label": "realme RMX3121",
    "value": "realme RMX3121"
  },
  {
    "name": "LENOVO Lenovo TB-X605FC",
    "label": "LENOVO Lenovo TB-X605FC",
    "value": "LENOVO Lenovo TB-X605FC"
  },
  {
    "name": "SONY G2299",
    "label": "SONY G2299",
    "value": "SONY G2299"
  },
  {
    "name": "Google Dell Latitude 5400 Chromebook Enterprise",
    "label": "Google Dell Latitude 5400 Chromebook Enterprise",
    "value": "Google Dell Latitude 5400 Chromebook Enterprise"
  },
  {
    "name": "teksun X1",
    "label": "teksun X1",
    "value": "teksun X1"
  },
  {
    "name": "incar P20S_EEA",
    "label": "incar P20S_EEA",
    "value": "incar P20S_EEA"
  },
  {
    "name": "SUNSTECH TAB1081",
    "label": "SUNSTECH TAB1081",
    "value": "SUNSTECH TAB1081"
  },
  {
    "name": "EPIKONE X507T",
    "label": "EPIKONE X507T",
    "value": "EPIKONE X507T"
  },
  {
    "name": "realme RMX3122",
    "label": "realme RMX3122",
    "value": "realme RMX3122"
  },
  {
    "name": "Vodafone VFD 630",
    "label": "Vodafone VFD 630",
    "value": "Vodafone VFD 630"
  },
  {
    "name": "Alldocube iPlay_30",
    "label": "Alldocube iPlay_30",
    "value": "Alldocube iPlay_30"
  },
  {
    "name": "SMT_HK Helium Pro",
    "label": "SMT_HK Helium Pro",
    "value": "SMT_HK Helium Pro"
  },
  {
    "name": "HOTWAV C60 Ultra",
    "label": "HOTWAV C60 Ultra",
    "value": "HOTWAV C60 Ultra"
  },
  {
    "name": "deltainno DT1901A",
    "label": "deltainno DT1901A",
    "value": "deltainno DT1901A"
  },
  {
    "name": "Apple Sihle’s iPhone",
    "label": "Apple Sihle’s iPhone",
    "value": "Apple Sihle’s iPhone"
  },
  {
    "name": "TCL 5102M",
    "label": "TCL 5102M",
    "value": "TCL 5102M"
  },
  {
    "name": "TEM V356",
    "label": "TEM V356",
    "value": "TEM V356"
  },
  {
    "name": "Logicom Le_Prime",
    "label": "Logicom Le_Prime",
    "value": "Logicom Le_Prime"
  },
  {
    "name": "TECHNOPC UP10_SH36LAG",
    "label": "TECHNOPC UP10_SH36LAG",
    "value": "TECHNOPC UP10_SH36LAG"
  },
  {
    "name": "TCL 5031D",
    "label": "TCL 5031D",
    "value": "TCL 5031D"
  },
  {
    "name": "Infinix Infinix HOT 4 Pro",
    "label": "Infinix Infinix HOT 4 Pro",
    "value": "Infinix Infinix HOT 4 Pro"
  },
  {
    "name": "ZUUM AURA X",
    "label": "ZUUM AURA X",
    "value": "ZUUM AURA X"
  },
  {
    "name": "Innovatech V12",
    "label": "Innovatech V12",
    "value": "Innovatech V12"
  },
  {
    "name": "Apple jess",
    "label": "Apple jess",
    "value": "Apple jess"
  },
  {
    "name": "Apple Shruti’s iPhone",
    "label": "Apple Shruti’s iPhone",
    "value": "Apple Shruti’s iPhone"
  },
  {
    "name": "DEXP Q210",
    "label": "DEXP Q210",
    "value": "DEXP Q210"
  },
  {
    "name": "vivo V2069A",
    "label": "vivo V2069A",
    "value": "vivo V2069A"
  },
  {
    "name": "Ulefone Armor X10",
    "label": "Ulefone Armor X10",
    "value": "Ulefone Armor X10"
  },
  {
    "name": "WIKO W_C860",
    "label": "WIKO W_C860",
    "value": "WIKO W_C860"
  },
  {
    "name": "LAVA LAVA LZG403",
    "label": "LAVA LAVA LZG403",
    "value": "LAVA LAVA LZG403"
  },
  {
    "name": "HUAWEI P40",
    "label": "HUAWEI P40",
    "value": "HUAWEI P40"
  },
  {
    "name": "sanmu VIA_S48_0",
    "label": "sanmu VIA_S48_0",
    "value": "sanmu VIA_S48_0"
  },
  {
    "name": "SI369 SI369",
    "label": "SI369 SI369",
    "value": "SI369 SI369"
  },
  {
    "name": "TCL 5199I",
    "label": "TCL 5199I",
    "value": "TCL 5199I"
  },
  {
    "name": "Stylo Stylo S501",
    "label": "Stylo Stylo S501",
    "value": "Stylo Stylo S501"
  },
  {
    "name": "OPPO PBBT30",
    "label": "OPPO PBBT30",
    "value": "OPPO PBBT30"
  },
  {
    "name": "lenovo Lenovo K13 Note",
    "label": "lenovo Lenovo K13 Note",
    "value": "lenovo Lenovo K13 Note"
  },
  {
    "name": "ZTE ZTE Blade A31 Lite RU",
    "label": "ZTE ZTE Blade A31 Lite RU",
    "value": "ZTE ZTE Blade A31 Lite RU"
  },
  {
    "name": "Nubia Z11",
    "label": "Nubia Z11",
    "value": "Nubia Z11"
  },
  {
    "name": "incar M13",
    "label": "incar M13",
    "value": "incar M13"
  },
  {
    "name": "WIKO Tommy2 Plus",
    "label": "WIKO Tommy2 Plus",
    "value": "WIKO Tommy2 Plus"
  },
  {
    "name": "TCL 9032X_EEA",
    "label": "TCL 9032X_EEA",
    "value": "TCL 9032X_EEA"
  },
  {
    "name": "BlackBerry BBB100-1",
    "label": "BlackBerry BBB100-1",
    "value": "BlackBerry BBB100-1"
  },
  {
    "name": "Dixon S5790",
    "label": "Dixon S5790",
    "value": "Dixon S5790"
  },
  {
    "name": "DEXP K51",
    "label": "DEXP K51",
    "value": "DEXP K51"
  },
  {
    "name": "Hisense Hisense H30",
    "label": "Hisense Hisense H30",
    "value": "Hisense Hisense H30"
  },
  {
    "name": "S_TELL P760",
    "label": "S_TELL P760",
    "value": "S_TELL P760"
  },
  {
    "name": "Vertex Impress_Luck4G_NFC",
    "label": "Vertex Impress_Luck4G_NFC",
    "value": "Vertex Impress_Luck4G_NFC"
  },
  {
    "name": "OPPO PAAM00",
    "label": "OPPO PAAM00",
    "value": "OPPO PAAM00"
  },
  {
    "name": "vivo V1816T",
    "label": "vivo V1816T",
    "value": "vivo V1816T"
  },
  {
    "name": "HUAWEI BKL-L04",
    "label": "HUAWEI BKL-L04",
    "value": "HUAWEI BKL-L04"
  },
  {
    "name": "TRUE Alpha 5G",
    "label": "TRUE Alpha 5G",
    "value": "TRUE Alpha 5G"
  },
  {
    "name": "Blackview BV5100",
    "label": "Blackview BV5100",
    "value": "Blackview BV5100"
  },
  {
    "name": "Apple Athandwa ’s iPhone",
    "label": "Apple Athandwa ’s iPhone",
    "value": "Apple Athandwa ’s iPhone"
  },
  {
    "name": "Premio S75",
    "label": "Premio S75",
    "value": "Premio S75"
  },
  {
    "name": "Xiaomi M2007J3SC",
    "label": "Xiaomi M2007J3SC",
    "value": "Xiaomi M2007J3SC"
  },
  {
    "name": "Fortune Ship iKU X2",
    "label": "Fortune Ship iKU X2",
    "value": "Fortune Ship iKU X2"
  },
  {
    "name": "RAVOZ RAVOZ Z5 Lite",
    "label": "RAVOZ RAVOZ Z5 Lite",
    "value": "RAVOZ RAVOZ Z5 Lite"
  },
  {
    "name": "WALTON PRIMO RX8",
    "label": "WALTON PRIMO RX8",
    "value": "WALTON PRIMO RX8"
  },
  {
    "name": "Xiaomi Mi 5",
    "label": "Xiaomi Mi 5",
    "value": "Xiaomi Mi 5"
  },
  {
    "name": "unknown Phh-Treble GSI",
    "label": "unknown Phh-Treble GSI",
    "value": "unknown Phh-Treble GSI"
  },
  {
    "name": "Innovatech SNOOK-X80",
    "label": "Innovatech SNOOK-X80",
    "value": "Innovatech SNOOK-X80"
  },
  {
    "name": "JOYAR GSR2",
    "label": "JOYAR GSR2",
    "value": "JOYAR GSR2"
  },
  {
    "name": "OUKITEL C23 Pro",
    "label": "OUKITEL C23 Pro",
    "value": "OUKITEL C23 Pro"
  },
  {
    "name": "ZTE ZTE Blade A7s",
    "label": "ZTE ZTE Blade A7s",
    "value": "ZTE ZTE Blade A7s"
  },
  {
    "name": "CUBOT CUBOT_J3",
    "label": "CUBOT CUBOT_J3",
    "value": "CUBOT CUBOT_J3"
  },
  {
    "name": "HMR S70",
    "label": "HMR S70",
    "value": "HMR S70"
  },
  {
    "name": "Apple Ofek’s iPhone",
    "label": "Apple Ofek’s iPhone",
    "value": "Apple Ofek’s iPhone"
  },
  {
    "name": "Xiaomi 11 Lite 5G NE",
    "label": "Xiaomi 11 Lite 5G NE",
    "value": "Xiaomi 11 Lite 5G NE"
  },
  {
    "name": "Lenovo TB328FU",
    "label": "Lenovo TB328FU",
    "value": "Lenovo TB328FU"
  },
  {
    "name": "samsung SM-G980F/DS",
    "label": "samsung SM-G980F/DS",
    "value": "samsung SM-G980F/DS"
  },
  {
    "name": "TCL T766H_RU",
    "label": "TCL T766H_RU",
    "value": "TCL T766H_RU"
  },
  {
    "name": "Viumee EV7_Pro",
    "label": "Viumee EV7_Pro",
    "value": "Viumee EV7_Pro"
  },
  {
    "name": "vivo vivo Y66i A",
    "label": "vivo vivo Y66i A",
    "value": "vivo vivo Y66i A"
  },
  {
    "name": "samsung SM-P355M",
    "label": "samsung SM-P355M",
    "value": "samsung SM-P355M"
  },
  {
    "name": "HMD Global TA-1052",
    "label": "HMD Global TA-1052",
    "value": "HMD Global TA-1052"
  },
  {
    "name": "TCL 5030D_RU",
    "label": "TCL 5030D_RU",
    "value": "TCL 5030D_RU"
  },
  {
    "name": "KONROW SOFT5",
    "label": "KONROW SOFT5",
    "value": "KONROW SOFT5"
  },
  {
    "name": "ADVAN 7037",
    "label": "ADVAN 7037",
    "value": "ADVAN 7037"
  },
  {
    "name": "samsung SM-S916U",
    "label": "samsung SM-S916U",
    "value": "samsung SM-S916U"
  },
  {
    "name": "SPRD P10",
    "label": "SPRD P10",
    "value": "SPRD P10"
  },
  {
    "name": "Sky_Devices Platinum_B4",
    "label": "Sky_Devices Platinum_B4",
    "value": "Sky_Devices Platinum_B4"
  },
  {
    "name": "TCL 9032X_TR",
    "label": "TCL 9032X_TR",
    "value": "TCL 9032X_TR"
  },
  {
    "name": "WIKO W-K360-TV",
    "label": "WIKO W-K360-TV",
    "value": "WIKO W-K360-TV"
  },
  {
    "name": "Coosea SL104D",
    "label": "Coosea SL104D",
    "value": "Coosea SL104D"
  },
  {
    "name": "G-Tab C10X",
    "label": "G-Tab C10X",
    "value": "G-Tab C10X"
  },
  {
    "name": "Shenzhen E-dong Technology Co.,Ltd Tab2",
    "label": "Shenzhen E-dong Technology Co.,Ltd Tab2",
    "value": "Shenzhen E-dong Technology Co.,Ltd Tab2"
  },
  {
    "name": "BlackBerry BBF100-6",
    "label": "BlackBerry BBF100-6",
    "value": "BlackBerry BBF100-6"
  },
  {
    "name": "along BETELGEUSE",
    "label": "along BETELGEUSE",
    "value": "along BETELGEUSE"
  },
  {
    "name": "Shenzhen Along Electronics Co., Ltd. SDQ-55044L",
    "label": "Shenzhen Along Electronics Co., Ltd. SDQ-55044L",
    "value": "Shenzhen Along Electronics Co., Ltd. SDQ-55044L"
  },
  {
    "name": "Coolpad 1851",
    "label": "Coolpad 1851",
    "value": "Coolpad 1851"
  },
  {
    "name": "LENOVO LIFETAB P1091X",
    "label": "LENOVO LIFETAB P1091X",
    "value": "LENOVO LIFETAB P1091X"
  },
  {
    "name": "Wingtech WTCKT01",
    "label": "Wingtech WTCKT01",
    "value": "Wingtech WTCKT01"
  },
  {
    "name": "CUBOT J10",
    "label": "CUBOT J10",
    "value": "CUBOT J10"
  },
  {
    "name": "Premier Code (PRIVATE) LIMITED DS-BD1",
    "label": "Premier Code (PRIVATE) LIMITED DS-BD1",
    "value": "Premier Code (PRIVATE) LIMITED DS-BD1"
  },
  {
    "name": "Vodafone VFD 525",
    "label": "Vodafone VFD 525",
    "value": "Vodafone VFD 525"
  },
  {
    "name": "HUAWEI INE-TL00",
    "label": "HUAWEI INE-TL00",
    "value": "HUAWEI INE-TL00"
  },
  {
    "name": "ENERGIZER EnergyE500S_US",
    "label": "ENERGIZER EnergyE500S_US",
    "value": "ENERGIZER EnergyE500S_US"
  },
  {
    "name": "Apple SMayixale’s iPhone",
    "label": "Apple SMayixale’s iPhone",
    "value": "Apple SMayixale’s iPhone"
  },
  {
    "name": "Vanzo Cellacom_S62",
    "label": "Vanzo Cellacom_S62",
    "value": "Vanzo Cellacom_S62"
  },
  {
    "name": "Hisense HLW",
    "label": "Hisense HLW",
    "value": "Hisense HLW"
  },
  {
    "name": "Apple Motlatso’s iPhone",
    "label": "Apple Motlatso’s iPhone",
    "value": "Apple Motlatso’s iPhone"
  },
  {
    "name": "Micromax Micromax Q4204",
    "label": "Micromax Micromax Q4204",
    "value": "Micromax Micromax Q4204"
  },
  {
    "name": "Desktop - WIndows",
    "label": "Desktop - WIndows",
    "value": "Desktop - WIndows"
  },
  {
    "name": "bq Aquaris M5",
    "label": "bq Aquaris M5",
    "value": "bq Aquaris M5"
  },
  {
    "name": "COBY MID7052",
    "label": "COBY MID7052",
    "value": "COBY MID7052"
  },
  {
    "name": "OALE db x",
    "label": "OALE db x",
    "value": "OALE db x"
  },
  {
    "name": "MINTT Mintt M3",
    "label": "MINTT Mintt M3",
    "value": "MINTT Mintt M3"
  },
  {
    "name": "TECNO MOBILE LIMITED XYZ",
    "label": "TECNO MOBILE LIMITED XYZ",
    "value": "TECNO MOBILE LIMITED XYZ"
  },
  {
    "name": "Hyundai 10LB1",
    "label": "Hyundai 10LB1",
    "value": "Hyundai 10LB1"
  },
  {
    "name": "Apple iPhone 12 Pro Max",
    "label": "Apple iPhone 12 Pro Max",
    "value": "Apple iPhone 12 Pro Max"
  },
  {
    "name": "incar U221",
    "label": "incar U221",
    "value": "incar U221"
  },
  {
    "name": "ITEL MOBILE LIMITED itel L5502",
    "label": "ITEL MOBILE LIMITED itel L5502",
    "value": "ITEL MOBILE LIMITED itel L5502"
  },
  {
    "name": "TCL 5039D_EEA",
    "label": "TCL 5039D_EEA",
    "value": "TCL 5039D_EEA"
  },
  {
    "name": "ZOJI Z33",
    "label": "ZOJI Z33",
    "value": "ZOJI Z33"
  },
  {
    "name": "incar G10_EEA",
    "label": "incar G10_EEA",
    "value": "incar G10_EEA"
  },
  {
    "name": "HUAWEI TAS-AL00",
    "label": "HUAWEI TAS-AL00",
    "value": "HUAWEI TAS-AL00"
  },
  {
    "name": "incar MLAB_GLOWY10",
    "label": "incar MLAB_GLOWY10",
    "value": "incar MLAB_GLOWY10"
  },
  {
    "name": "vivo vivo Y79",
    "label": "vivo vivo Y79",
    "value": "vivo vivo Y79"
  },
  {
    "name": "Mobicel Legend Pro LTE",
    "label": "Mobicel Legend Pro LTE",
    "value": "Mobicel Legend Pro LTE"
  },
  {
    "name": "Casper VIA_M4",
    "label": "Casper VIA_M4",
    "value": "Casper VIA_M4"
  },
  {
    "name": "Xiaomi 22101316I",
    "label": "Xiaomi 22101316I",
    "value": "Xiaomi 22101316I"
  },
  {
    "name": "ITEL MOBILE LIMITED itel L5002",
    "label": "ITEL MOBILE LIMITED itel L5002",
    "value": "ITEL MOBILE LIMITED itel L5002"
  },
  {
    "name": "UTPL G1032A",
    "label": "UTPL G1032A",
    "value": "UTPL G1032A"
  },
  {
    "name": "HUAWEI JNY-AL10",
    "label": "HUAWEI JNY-AL10",
    "value": "HUAWEI JNY-AL10"
  },
  {
    "name": "ZTE ZTE A2019G Pro",
    "label": "ZTE ZTE A2019G Pro",
    "value": "ZTE ZTE A2019G Pro"
  },
  {
    "name": "Google scarlet",
    "label": "Google scarlet",
    "value": "Google scarlet"
  },
  {
    "name": "Vortex V22S",
    "label": "Vortex V22S",
    "value": "Vortex V22S"
  },
  {
    "name": "Teclast T30_ROW",
    "label": "Teclast T30_ROW",
    "value": "Teclast T30_ROW"
  },
  {
    "name": "Joyar BRIO_X31",
    "label": "Joyar BRIO_X31",
    "value": "Joyar BRIO_X31"
  },
  {
    "name": "Elephone E10",
    "label": "Elephone E10",
    "value": "Elephone E10"
  },
  {
    "name": "meizu MEIZU 18s",
    "label": "meizu MEIZU 18s",
    "value": "meizu MEIZU 18s"
  },
  {
    "name": "Sony SOG01",
    "label": "Sony SOG01",
    "value": "Sony SOG01"
  },
  {
    "name": "motorola moto g23",
    "label": "motorola moto g23",
    "value": "motorola moto g23"
  },
  {
    "name": "BlackBerry BBB100-5",
    "label": "BlackBerry BBB100-5",
    "value": "BlackBerry BBB100-5"
  },
  {
    "name": "Samsung SM-A507FN",
    "label": "Samsung SM-A507FN",
    "value": "Samsung SM-A507FN"
  },
  {
    "name": "Sony XQ-CC72",
    "label": "Sony XQ-CC72",
    "value": "Sony XQ-CC72"
  },
  {
    "name": "samsung SM-A146M",
    "label": "samsung SM-A146M",
    "value": "samsung SM-A146M"
  },
  {
    "name": "SHARP STTM21VAPP",
    "label": "SHARP STTM21VAPP",
    "value": "SHARP STTM21VAPP"
  },
  {
    "name": "Lava Lava Z93P",
    "label": "Lava Lava Z93P",
    "value": "Lava Lava Z93P"
  },
  {
    "name": "Bmobile AX687+",
    "label": "Bmobile AX687+",
    "value": "Bmobile AX687+"
  },
  {
    "name": "TEM S502",
    "label": "TEM S502",
    "value": "TEM S502"
  },
  {
    "name": "Dixon TS_M103A",
    "label": "Dixon TS_M103A",
    "value": "Dixon TS_M103A"
  },
  {
    "name": "Archos Archos Oxygen 68XL",
    "label": "Archos Archos Oxygen 68XL",
    "value": "Archos Archos Oxygen 68XL"
  },
  {
    "name": "Masstel Masstel Hapi 15",
    "label": "Masstel Masstel Hapi 15",
    "value": "Masstel Masstel Hapi 15"
  },
  {
    "name": "HPADIA10 GACRUX",
    "label": "HPADIA10 GACRUX",
    "value": "HPADIA10 GACRUX"
  },
  {
    "name": "alps Edge 30 Pro",
    "label": "alps Edge 30 Pro",
    "value": "alps Edge 30 Pro"
  },
  {
    "name": "Xiaomi 22111317PG",
    "label": "Xiaomi 22111317PG",
    "value": "Xiaomi 22111317PG"
  },
  {
    "name": "Hisense Hisense M50 Lite 2021",
    "label": "Hisense Hisense M50 Lite 2021",
    "value": "Hisense Hisense M50 Lite 2021"
  },
  {
    "name": "samsung SM-G8870",
    "label": "samsung SM-G8870",
    "value": "samsung SM-G8870"
  },
  {
    "name": "samsung SM-S911U",
    "label": "samsung SM-S911U",
    "value": "samsung SM-S911U"
  },
  {
    "name": "GHIA_KIDS GTKIDS7",
    "label": "GHIA_KIDS GTKIDS7",
    "value": "GHIA_KIDS GTKIDS7"
  },
  {
    "name": "OnePlus HD1905",
    "label": "OnePlus HD1905",
    "value": "OnePlus HD1905"
  },
  {
    "name": "I-KALL Z6",
    "label": "I-KALL Z6",
    "value": "I-KALL Z6"
  },
  {
    "name": "LAVA LAVA LZX404",
    "label": "LAVA LAVA LZX404",
    "value": "LAVA LAVA LZX404"
  },
  {
    "name": "BMAX I10_EEA",
    "label": "BMAX I10_EEA",
    "value": "BMAX I10_EEA"
  },
  {
    "name": "HXY A24",
    "label": "HXY A24",
    "value": "HXY A24"
  },
  {
    "name": "MobiWire SmartV22",
    "label": "MobiWire SmartV22",
    "value": "MobiWire SmartV22"
  },
  {
    "name": "Ulefone S10_Pro",
    "label": "Ulefone S10_Pro",
    "value": "Ulefone S10_Pro"
  },
  {
    "name": "TCL 8088M",
    "label": "TCL 8088M",
    "value": "TCL 8088M"
  },
  {
    "name": "SAMSUNG Galaxy S20",
    "label": "SAMSUNG Galaxy S20",
    "value": "SAMSUNG Galaxy S20"
  },
  {
    "name": "Lenovo Lenovo TB-J716F",
    "label": "Lenovo Lenovo TB-J716F",
    "value": "Lenovo Lenovo TB-J716F"
  },
  {
    "name": "vivo V2036_21",
    "label": "vivo V2036_21",
    "value": "vivo V2036_21"
  },
  {
    "name": "BullittGroupLimited S48c",
    "label": "BullittGroupLimited S48c",
    "value": "BullittGroupLimited S48c"
  },
  {
    "name": "Amazon KFRAWI",
    "label": "Amazon KFRAWI",
    "value": "Amazon KFRAWI"
  },
  {
    "name": "DOOGEE S80Lite",
    "label": "DOOGEE S80Lite",
    "value": "DOOGEE S80Lite"
  },
  {
    "name": "HUAWEI CTR-LX1",
    "label": "HUAWEI CTR-LX1",
    "value": "HUAWEI CTR-LX1"
  },
  {
    "name": "samsung SM-T503",
    "label": "samsung SM-T503",
    "value": "samsung SM-T503"
  },
  {
    "name": "DEXP A360",
    "label": "DEXP A360",
    "value": "DEXP A360"
  },
  {
    "name": "TECNO TECNO AD8",
    "label": "TECNO TECNO AD8",
    "value": "TECNO TECNO AD8"
  },
  {
    "name": "shuomingdingsheng K12pro",
    "label": "shuomingdingsheng K12pro",
    "value": "shuomingdingsheng K12pro"
  },
  {
    "name": "Bmobile AX1017",
    "label": "Bmobile AX1017",
    "value": "Bmobile AX1017"
  },
  {
    "name": "asus ASUS_I01WDX",
    "label": "asus ASUS_I01WDX",
    "value": "asus ASUS_I01WDX"
  },
  {
    "name": "yuntab LadPad_10.1_PRO",
    "label": "yuntab LadPad_10.1_PRO",
    "value": "yuntab LadPad_10.1_PRO"
  },
  {
    "name": "PANASONIC Eluga I6",
    "label": "PANASONIC Eluga I6",
    "value": "PANASONIC Eluga I6"
  },
  {
    "name": "SHARP 603SH",
    "label": "SHARP 603SH",
    "value": "SHARP 603SH"
  },
  {
    "name": "PREMIO S80",
    "label": "PREMIO S80",
    "value": "PREMIO S80"
  },
  {
    "name": "TCL T671G",
    "label": "TCL T671G",
    "value": "TCL T671G"
  },
  {
    "name": "TINNO U304AC",
    "label": "TINNO U304AC",
    "value": "TINNO U304AC"
  },
  {
    "name": "samsung SM-T297",
    "label": "samsung SM-T297",
    "value": "samsung SM-T297"
  },
  {
    "name": "magcomm X_TIGI_V16_LTE",
    "label": "magcomm X_TIGI_V16_LTE",
    "value": "magcomm X_TIGI_V16_LTE"
  },
  {
    "name": "Realme RMX1921",
    "label": "Realme RMX1921",
    "value": "Realme RMX1921"
  },
  {
    "name": "TCL 8076_EEA",
    "label": "TCL 8076_EEA",
    "value": "TCL 8076_EEA"
  },
  {
    "name": "Symphony Symphony Z45",
    "label": "Symphony Symphony Z45",
    "value": "Symphony Symphony Z45"
  },
  {
    "name": "Xiaomi Android",
    "label": "Xiaomi Android",
    "value": "Xiaomi Android"
  },
  {
    "name": "Mara Mara Z",
    "label": "Mara Mara Z",
    "value": "Mara Mara Z"
  },
  {
    "name": "incar Q3_EEA",
    "label": "incar Q3_EEA",
    "value": "incar Q3_EEA"
  },
  {
    "name": "LGE LG-H910",
    "label": "LGE LG-H910",
    "value": "LGE LG-H910"
  },
  {
    "name": "Hisense Hisense Infinity H60 Lite",
    "label": "Hisense Hisense Infinity H60 Lite",
    "value": "Hisense Hisense Infinity H60 Lite"
  },
  {
    "name": "samsung SM-G715A",
    "label": "samsung SM-G715A",
    "value": "samsung SM-G715A"
  },
  {
    "name": "HUAWEI HUAWEI NXT-TL00",
    "label": "HUAWEI HUAWEI NXT-TL00",
    "value": "HUAWEI HUAWEI NXT-TL00"
  },
  {
    "name": "Joyar G101-OG",
    "label": "Joyar G101-OG",
    "value": "Joyar G101-OG"
  },
  {
    "name": "BLU C6x",
    "label": "BLU C6x",
    "value": "BLU C6x"
  },
  {
    "name": "Hisense Hisense F18",
    "label": "Hisense Hisense F18",
    "value": "Hisense Hisense F18"
  },
  {
    "name": "Mobicel TRENDY_LITE",
    "label": "Mobicel TRENDY_LITE",
    "value": "Mobicel TRENDY_LITE"
  },
  {
    "name": "TCL T776H",
    "label": "TCL T776H",
    "value": "TCL T776H"
  },
  {
    "name": "ECS TG08RK",
    "label": "ECS TG08RK",
    "value": "ECS TG08RK"
  },
  {
    "name": "ALLVIEW V4_Viper_PRO",
    "label": "ALLVIEW V4_Viper_PRO",
    "value": "ALLVIEW V4_Viper_PRO"
  },
  {
    "name": "Ulefone Armor X10 Pro",
    "label": "Ulefone Armor X10 Pro",
    "value": "Ulefone Armor X10 Pro"
  },
  {
    "name": "Alco VCT9F8A68RD",
    "label": "Alco VCT9F8A68RD",
    "value": "Alco VCT9F8A68RD"
  },
  {
    "name": "LGE LM-X410PM",
    "label": "LGE LM-X410PM",
    "value": "LGE LM-X410PM"
  },
  {
    "name": "SMART PRODUCTS CONNECTION SA GRAVITY2-4G",
    "label": "SMART PRODUCTS CONNECTION SA GRAVITY2-4G",
    "value": "SMART PRODUCTS CONNECTION SA GRAVITY2-4G"
  },
  {
    "name": "TCL T790Z",
    "label": "TCL T790Z",
    "value": "TCL T790Z"
  },
  {
    "name": "Xiaomi whyred",
    "label": "Xiaomi whyred",
    "value": "Xiaomi whyred"
  },
  {
    "name": "samsung GT-P5200",
    "label": "samsung GT-P5200",
    "value": "samsung GT-P5200"
  },
  {
    "name": "WIKO W-K630-TVM",
    "label": "WIKO W-K630-TVM",
    "value": "WIKO W-K630-TVM"
  },
  {
    "name": "KODAK SMARTWAY L2",
    "label": "KODAK SMARTWAY L2",
    "value": "KODAK SMARTWAY L2"
  },
  {
    "name": "LT LT_C3300",
    "label": "LT LT_C3300",
    "value": "LT LT_C3300"
  },
  {
    "name": "SMART PRODUCTS CONNECTION SA T13_EEA",
    "label": "SMART PRODUCTS CONNECTION SA T13_EEA",
    "value": "SMART PRODUCTS CONNECTION SA T13_EEA"
  },
  {
    "name": "BLU G51S",
    "label": "BLU G51S",
    "value": "BLU G51S"
  },
  {
    "name": "QMobile QMobile i5i 2018",
    "label": "QMobile QMobile i5i 2018",
    "value": "QMobile QMobile i5i 2018"
  },
  {
    "name": "ODYS SPACE_ONE10",
    "label": "ODYS SPACE_ONE10",
    "value": "ODYS SPACE_ONE10"
  },
  {
    "name": "HUAWEI SNE-AL00",
    "label": "HUAWEI SNE-AL00",
    "value": "HUAWEI SNE-AL00"
  },
  {
    "name": "Multilaser M10_4G_T3",
    "label": "Multilaser M10_4G_T3",
    "value": "Multilaser M10_4G_T3"
  },
  {
    "name": "incar Tab-A6_EEA",
    "label": "incar Tab-A6_EEA",
    "value": "incar Tab-A6_EEA"
  },
  {
    "name": "Multilaser Multilaser_E_Pro",
    "label": "Multilaser Multilaser_E_Pro",
    "value": "Multilaser Multilaser_E_Pro"
  },
  {
    "name": "samsung SM-J327R7",
    "label": "samsung SM-J327R7",
    "value": "samsung SM-J327R7"
  },
  {
    "name": "Xiaomi Redmi 9T",
    "label": "Xiaomi Redmi 9T",
    "value": "Xiaomi Redmi 9T"
  },
  {
    "name": "PANASONIC P101",
    "label": "PANASONIC P101",
    "value": "PANASONIC P101"
  },
  {
    "name": "vivo V2168A",
    "label": "vivo V2168A",
    "value": "vivo V2168A"
  },
  {
    "name": "TOUCHMATE TM-MID1065",
    "label": "TOUCHMATE TM-MID1065",
    "value": "TOUCHMATE TM-MID1065"
  },
  {
    "name": "isafemobile IS530",
    "label": "isafemobile IS530",
    "value": "isafemobile IS530"
  },
  {
    "name": "samsung SM-J320F",
    "label": "samsung SM-J320F",
    "value": "samsung SM-J320F"
  },
  {
    "name": "Shenzhen Along Electronics Co,. Ltd. Xplorer_2",
    "label": "Shenzhen Along Electronics Co,. Ltd. Xplorer_2",
    "value": "Shenzhen Along Electronics Co,. Ltd. Xplorer_2"
  },
  {
    "name": "Qlink Scepter8",
    "label": "Qlink Scepter8",
    "value": "Qlink Scepter8"
  },
  {
    "name": "TCL 5030F",
    "label": "TCL 5030F",
    "value": "TCL 5030F"
  },
  {
    "name": "ATVIO MID1032",
    "label": "ATVIO MID1032",
    "value": "ATVIO MID1032"
  },
  {
    "name": "blackshark SHARK KLE-A0",
    "label": "blackshark SHARK KLE-A0",
    "value": "blackshark SHARK KLE-A0"
  },
  {
    "name": "Fortune Ship SMART 2",
    "label": "Fortune Ship SMART 2",
    "value": "Fortune Ship SMART 2"
  },
  {
    "name": "SPA Condor Electronics Griffe T8",
    "label": "SPA Condor Electronics Griffe T8",
    "value": "SPA Condor Electronics Griffe T8"
  },
  {
    "name": "OPPO OPPO F1s",
    "label": "OPPO OPPO F1s",
    "value": "OPPO OPPO F1s"
  },
  {
    "name": "Android P2",
    "label": "Android P2",
    "value": "Android P2"
  },
  {
    "name": "ACE France BUZZ 1",
    "label": "ACE France BUZZ 1",
    "value": "ACE France BUZZ 1"
  },
  {
    "name": "vivo V2023A",
    "label": "vivo V2023A",
    "value": "vivo V2023A"
  },
  {
    "name": "Chinoe A50",
    "label": "Chinoe A50",
    "value": "Chinoe A50"
  },
  {
    "name": "ALLVIEW A20_Lite",
    "label": "ALLVIEW A20_Lite",
    "value": "ALLVIEW A20_Lite"
  },
  {
    "name": "MobiWire Akus Z",
    "label": "MobiWire Akus Z",
    "value": "MobiWire Akus Z"
  },
  {
    "name": "Maxwest Astro 5T",
    "label": "Maxwest Astro 5T",
    "value": "Maxwest Astro 5T"
  },
  {
    "name": "OPPO PEGM10",
    "label": "OPPO PEGM10",
    "value": "OPPO PEGM10"
  },
  {
    "name": "HUAWEI CLT-AL00l",
    "label": "HUAWEI CLT-AL00l",
    "value": "HUAWEI CLT-AL00l"
  },
  {
    "name": "DigiDragon DS502",
    "label": "DigiDragon DS502",
    "value": "DigiDragon DS502"
  },
  {
    "name": "Movitel M8421",
    "label": "Movitel M8421",
    "value": "Movitel M8421"
  },
  {
    "name": "LENOVO 81FS",
    "label": "LENOVO 81FS",
    "value": "LENOVO 81FS"
  },
  {
    "name": "realme RMX2176",
    "label": "realme RMX2176",
    "value": "realme RMX2176"
  },
  {
    "name": "HUAWEI LIO-AL00",
    "label": "HUAWEI LIO-AL00",
    "value": "HUAWEI LIO-AL00"
  },
  {
    "name": "HUAWEI BND-AL00",
    "label": "HUAWEI BND-AL00",
    "value": "HUAWEI BND-AL00"
  },
  {
    "name": "BLU D10",
    "label": "BLU D10",
    "value": "BLU D10"
  },
  {
    "name": "NVIDIA Switch",
    "label": "NVIDIA Switch",
    "value": "NVIDIA Switch"
  },
  {
    "name": "I 953 Rainbow U2",
    "label": "I 953 Rainbow U2",
    "value": "I 953 Rainbow U2"
  },
  {
    "name": "Sungworld Tablet_DL_3723",
    "label": "Sungworld Tablet_DL_3723",
    "value": "Sungworld Tablet_DL_3723"
  },
  {
    "name": "wintouch M16",
    "label": "wintouch M16",
    "value": "wintouch M16"
  },
  {
    "name": "Mobicel VENUS",
    "label": "Mobicel VENUS",
    "value": "Mobicel VENUS"
  },
  {
    "name": "TENCHHK LOGIC L68",
    "label": "TENCHHK LOGIC L68",
    "value": "TENCHHK LOGIC L68"
  },
  {
    "name": "TCL 6002J",
    "label": "TCL 6002J",
    "value": "TCL 6002J"
  },
  {
    "name": "INONE HTC Wildfire X",
    "label": "INONE HTC Wildfire X",
    "value": "INONE HTC Wildfire X"
  },
  {
    "name": "magcomm X_TIGI_V16",
    "label": "magcomm X_TIGI_V16",
    "value": "magcomm X_TIGI_V16"
  },
  {
    "name": "joyar Pr5650",
    "label": "joyar Pr5650",
    "value": "joyar Pr5650"
  },
  {
    "name": "OPPO PDSM00",
    "label": "OPPO PDSM00",
    "value": "OPPO PDSM00"
  },
  {
    "name": "Wileyfox Swift 2 X",
    "label": "Wileyfox Swift 2 X",
    "value": "Wileyfox Swift 2 X"
  },
  {
    "name": "QMobile GLi",
    "label": "QMobile GLi",
    "value": "QMobile GLi"
  },
  {
    "name": "vivo V1832A",
    "label": "vivo V1832A",
    "value": "vivo V1832A"
  },
  {
    "name": "samsung SM-T735C",
    "label": "samsung SM-T735C",
    "value": "samsung SM-T735C"
  },
  {
    "name": "incar BDL1064PR001",
    "label": "incar BDL1064PR001",
    "value": "incar BDL1064PR001"
  },
  {
    "name": "MAXWEST Astro 5X",
    "label": "MAXWEST Astro 5X",
    "value": "MAXWEST Astro 5X"
  },
  {
    "name": "HUAWEI AGS3-L09",
    "label": "HUAWEI AGS3-L09",
    "value": "HUAWEI AGS3-L09"
  },
  {
    "name": "rockchip R1",
    "label": "rockchip R1",
    "value": "rockchip R1"
  },
  {
    "name": "TECNO MOBILE LIMITED TECNO RA8",
    "label": "TECNO MOBILE LIMITED TECNO RA8",
    "value": "TECNO MOBILE LIMITED TECNO RA8"
  },
  {
    "name": "LAVA Z1",
    "label": "LAVA Z1",
    "value": "LAVA Z1"
  },
  {
    "name": "HUAWEI hi3660",
    "label": "HUAWEI hi3660",
    "value": "HUAWEI hi3660"
  },
  {
    "name": "HONOR KOZ-AL00",
    "label": "HONOR KOZ-AL00",
    "value": "HONOR KOZ-AL00"
  },
  {
    "name": "LENOVO Lenovo TB-X605LC",
    "label": "LENOVO Lenovo TB-X605LC",
    "value": "LENOVO Lenovo TB-X605LC"
  },
  {
    "name": "vivo vivo X20A",
    "label": "vivo vivo X20A",
    "value": "vivo vivo X20A"
  },
  {
    "name": "KYOCERA 602KC",
    "label": "KYOCERA 602KC",
    "value": "KYOCERA 602KC"
  },
  {
    "name": "LGE LGM-X320S",
    "label": "LGE LGM-X320S",
    "value": "LGE LGM-X320S"
  },
  {
    "name": "Wistron CELL",
    "label": "Wistron CELL",
    "value": "Wistron CELL"
  },
  {
    "name": "Allwinner P50",
    "label": "Allwinner P50",
    "value": "Allwinner P50"
  },
  {
    "name": "Samsung SM-G998U",
    "label": "Samsung SM-G998U",
    "value": "Samsung SM-G998U"
  },
  {
    "name": "SHARP SH-01L",
    "label": "SHARP SH-01L",
    "value": "SHARP SH-01L"
  },
  {
    "name": "incar Fenix8_3G",
    "label": "incar Fenix8_3G",
    "value": "incar Fenix8_3G"
  },
  {
    "name": "AGM AGM_H3",
    "label": "AGM AGM_H3",
    "value": "AGM AGM_H3"
  },
  {
    "name": "SUNMAX Model S 4G",
    "label": "SUNMAX Model S 4G",
    "value": "SUNMAX Model S 4G"
		}
];
