export const actionTypes = {
  GetFeatures: 'features/get-features',
  GetFeaturesSuccess: 'features/get-features-success',
  GetFeaturesFailure: 'features/get-features-failure',
  CreateFeature: 'features/create-feature',
  CreateFeatureSuccess: 'features/create-feature-success',
  CreateFeatureFailure: 'features/create-feature-failure',
  UpdateFeature: 'features/update-feature',
  UpdateFeatureSuccess: 'features/update-feature-success',
  UpdateFeatureFailure: 'features/update-feature-failure',
  GetAvailableParams: 'features/get-available-params',
  GetAvailableParamsSuccess: 'features/get-available-params-success',
  GetAvailableParamsFailure: 'features/get-available-params-failure',
  SetFiltersForTable: 'features/set-filters-for-table',
};

export const actions = {
  getFeatures: () => ({ type: actionTypes.GetFeatures }),
  getFeaturesSuccess: (features) => ({ type: actionTypes.GetFeaturesSuccess, payload: features }),
  getFeaturesFailure: () => ({ type: actionTypes.GetFeaturesFailure }),
  createFeature: (data) => ({ type: actionTypes.CreateFeature, payload: data }),
  createFeatureSuccess: (feature) => ({ type: actionTypes.CreateFeatureSuccess, payload: feature }),
  createFeatureFailure: () => ({ type: actionTypes.CreateFeatureFailure }),
  updateFeature: (data) => ({ type: actionTypes.UpdateFeature, payload: data }),
  updateFeatureSuccess: (feature) => ({ type: actionTypes.UpdateFeatureSuccess, payload: feature }),
  updateFeatureFailure: () => ({ type: actionTypes.UpdateFeatureFailure }),
  getAvailableParams: () => ({ type: actionTypes.GetAvailableParams }),
  getAvailableParamsSuccess: (availableParams) =>
    ({ type: actionTypes.GetAvailableParamsSuccess, payload: availableParams }),
  getAvailableParamsFailure: () => ({ type: actionTypes.GetAvailableParamsFailure }),
  setFiltersForTable: (filters) => ({ type: actionTypes.SetFiltersForTable, payload: filters }),
};
