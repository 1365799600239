import { format } from 'date-fns';
import { actionTypes } from '~/app/modules/HighReversalRateBans/actions';
import enums from "~/app/helpers/enums";

const initialState = {
  startDate: format(new Date(), enums.DATE_FORMAT),
  endDate: format(new Date(), enums.DATE_FORMAT),
  pageNumber: 1,
  pageSize: 20,
  sortBy: 'createdAt',
  sortDirection: enums.sortDirections.desc,

  report: [],
  totalCount: 0,
  isLoading: false,
};

const resetPaginationState = {
  pageNumber: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetStartDate:
      return {
        ...state,
        ...resetPaginationState,
        startDate: action.payload,
      };

    case actionTypes.SetEndDate:
      return {
        ...state,
        ...resetPaginationState,
        endDate: action.payload,
      };

    case actionTypes.SetPagination:
      return {
        ...state,
        ...resetPaginationState,
        pageSize: action.payload.pageSize,
        pageNumber: action.payload.pageNumber,
      };

    case actionTypes.SetSorting:
      return {
        ...state,
        ...resetPaginationState,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };

    case actionTypes.UpdateReport:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GenerateReport:
      return {
        ...state,
        pageNumber: 1,
        pageSize: 20,
        report: [],
        totalCount: 0,
        isLoading: true,
      };

    case actionTypes.FetchReportSuccess:
      return {
        ...state,
        report: action.payload.data,
        totalCount: action.payload.totalCount,
        isLoading: false,
      };

    case actionTypes.FetchReportError:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
