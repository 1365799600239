export const actionTypes = {
  FetchPaypalStats: 'checksAndRewardsInfo/fetch-paypal-stats',
  FetchPaypalStatsSuccess: 'checksAndRewardsInfo/fetch-paypal-stats-success',
  FetchPaypalStatsFailure: 'checksAndRewardsInfo/fetch-paypal-stats-failure',
  FetchRewardsProvidersBalanceSettings: 'checksAndRewardsInfo/fetch-rewards-providers-balance-settings',
  FetchRewardsProvidersBalanceSettingsSuccess: 'checksAndRewardsInfo/fetch-rewards-providers-balance-settings-success',
  FetchRewardsProvidersBalanceSettingsFailure: 'checksAndRewardsInfo/fetch-rewards-providers-balance-settings-failure',
};

export const actions = {
  fetchPaypalStats: () => ({ type: actionTypes.FetchPaypalStats, payload: {}}),
  fetchPaypalStatsSuccess: (data) => ({ type: actionTypes.FetchPaypalStatsSuccess, payload: data }),
  fetchPaypalStatsFailure: (errorMessage) => ({ type: actionTypes.FetchPaypalStatsFailure, payload: errorMessage }),
  fetchRewardsProvidersBalanceSettings: () => ({ type: actionTypes.FetchRewardsProvidersBalanceSettings, payload: {}}),
  fetchRewardsProvidersBalanceSettingsSuccess: (data) => ({ type: actionTypes.FetchRewardsProvidersBalanceSettingsSuccess, payload: data }),
  fetchRewardsProvidersBalanceSettingsFailure: (errorMessage) => ({ type: actionTypes.FetchRewardsProvidersBalanceSettingsFailure, payload: errorMessage }),
};