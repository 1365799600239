import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('clicksReport');

actionTypes.GenerateReportAsCsv = 'clicksReport/generate-report-as-csv';
actionTypes.GenerateReportAsCsvSuccess = 'clicksReport/generate-report-as-csv-success';
actionTypes.SetClickStartDate = 'clicksReport/set-click-start-date';
actionTypes.SetClickEndDate = 'clicksReport/set-click-end-date';
actionTypes.SetLeadStartDate = 'clicksReport/set-lead-start-date';
actionTypes.SetLeadEndDate = 'clicksReport/set-lead-end-date';
actionTypes.SetUseClickDate = 'clicksReport/set-use-click-date';
actionTypes.SetUseLeadDate = 'clicksReport/set-use-last-lead-date';
actionTypes.SetUserId = 'clicksReport/set-user-id';
actionTypes.SetSurveyId = 'clicksReport/set-survey-id';
actionTypes.CreateSharedLink = 'clicksReport/create-shared-link';
actionTypes.CreateSharedLinkFailed = 'clicksReport/create-shared-link-failed';
actionTypes.CreateSharedLinkSuccess = 'clicksReport/create-shared-link-success';
actionTypes.SetParams = 'clicksReport/set-params';
actionTypes.ClearSharedLink = 'clicksReport/clear-shared-link';

actions.createSharedLink = (params) => ({type: actionTypes.CreateSharedLink, payload: params});
actions.createSharedLinkSuccess = (link) => ({type: actionTypes.CreateSharedLinkSuccess, payload: link});
actions.createSharedLinkFailed = (error) => ({type: actionTypes.CreateSharedLinkFailed, payload: error});
actions.setParams = (params) => ({type: actionTypes.SetParams, payload: params});
actions.clearSharedLink = () => ({type: actionTypes.ClearSharedLink});

actions.generateReportAsCsv = () => ({
  type: actionTypes.GenerateReportAsCsv,
  payload: {}
});

actions.generateReportAsCsvSuccess = () => ({
  type: actionTypes.GenerateReportAsCsvSuccess,
  payload: {}
});

actions.setClickStartDate = (date) => ({
  type: actionTypes.SetClickStartDate,
  payload: date
});

actions.setClickEndDate = (date) => ({
  type: actionTypes.SetClickEndDate,
  payload: date
});

actions.setLeadStartDate = (date) => ({
  type: actionTypes.SetLeadStartDate,
  payload: date
});

actions.setLeadEndDate = (date) => ({
  type: actionTypes.SetLeadEndDate,
  payload: date
});

actions.setUseClickDate = (value) => ({
  type: actionTypes.SetUseClickDate,
  payload: value
});

actions.setUseLeadDate = (value) => ({
  type: actionTypes.SetUseLeadDate,
  payload: value
});

actions.setUserId = (value) => ({
  type: actionTypes.SetUserId,
  payload: value
});

actions.setSurveyId = (value) => ({
  type: actionTypes.SetSurveyId,
  payload: value
});


export {
  actionTypes,
  actions,
};
