import { call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/Translations/components/Features/api';
import { actions, actionTypes } from '~/app/modules/Translations/components/Features/actions';

function* getFeatures() {
  try {
    const results = yield call(api.getFeatures);
    yield put(actions.getFeaturesSuccess(results));
  } catch (error) {
    yield put(actions.getFeaturesFailure(error.message));
  }
}

function* createFeature(action) {
  try {
    const results = yield call(api.createFeature, action.payload);
    yield put(actions.createFeatureSuccess(results));
    yield put(actions.getFeatures());
  } catch (error) {
    yield put(actions.createFeatureFailure(error.message));
  }
}

function* updateFeature(action) {
  try {
    const results = yield call(api.updateFeature, action.payload);
    yield put(actions.updateFeatureSuccess(results));
    yield put(actions.getFeatures());
  } catch (error) {
    yield put(actions.updateFeatureFailure(error.message));
  }
}

function* getAvailableParams() {
  try {
    const results = yield call(api.getAvailableParams);
    yield put(actions.getAvailableParamsSuccess(results));
  } catch (error) {
    yield put(actions.getAvailableParamsFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.GetFeatures, getFeatures),
  takeLatest(actionTypes.CreateFeature, createFeature),
  takeLatest(actionTypes.UpdateFeature, updateFeature),
  takeLatest(actionTypes.GetAvailableParams, getAvailableParams),
];