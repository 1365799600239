import { actionTypes } from '~/app/modules/Miscellaneous/QAHelpers/actions';

const initialState = {
  userId: '',
  amount: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetUserId:
      return {
        ...state,
        userId: action.payload,
      };

    case actionTypes.SetAmount:
      return {
        ...state,
        amount: action.payload,
      };

    default:
      return state;
  }
};
