import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Container, Card, CardContent, Grid, Button } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { actions } from '~/app/modules/APILog/actions';
import APILogReport from '~/app/modules/APILog/APILog.report';
import { Spinner } from "react-bootstrap";
import { usePaginationFilteringAndSorting } from '~/app/helpers';
import enums from "~/app/helpers/enums";

const DateSelectors = () => {
  const startDate = useSelector(state => state.apiLog.startDate);
  const endDate = useSelector(state => state.apiLog.endDate);
  const dispatch = useDispatch();

  return (
    <>
      <Grid item xs={2}>
        <KeyboardDatePicker
          autoOk
          id="start-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="none"
          label="Start Date"
          value={startDate}
          onChange={(_, v) => dispatch(actions.setStartDate(v))}
        />
      </Grid>
      <Grid item xs={2}>
        <KeyboardDatePicker
          autoOk
          id="end-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="none"
          label="End Date"
          value={endDate}
          onChange={(_, v) => dispatch(actions.setEndDate(v))}
        />
      </Grid>
    </>
  )
};

const ReportParamsForm = () => {
  const dispatch = useDispatch();
  const isLoadingCSV = useSelector(state => state.apiLog.isLoadingCSV);
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'apiLog', actions);
  const startDate = useSelector(state => state.apiLog.startDate);
  const endDate = useSelector(state => state.apiLog.endDate);
  const isLoadingSharedView = useSelector((state) => state.apiLog.isLoadingSharedView);
  const sharedLink = useSelector((state) => state.apiLog.sharedLink);
  const collapsed = useSelector((state) => state.apiLog.collapsed);
  const columnsCollapsed = useSelector((state) => state.apiLog.collapsed);

  useEffect(() => {
    if (sharedLink) {
      navigator.clipboard.writeText(sharedLink).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
      dispatch(actions.clearSharedLink());
    }
  }, [sharedLink, dispatch]);

  const handleSharedView = () => {
    const sentParams = {
      sortBy,
      sortDirection,
      pageNumber,
      pageSize,
      filters,
      totalCount,
      startDate,
      endDate,
      collapsed,
      page: 'api-log',
    };

    dispatch(actions.createSharedLink(sentParams));
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4} alignItems="flex-end">
          <DateSelectors />
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => dispatch(actions.generateReport())}
            >
              Generate
            </Button>
          </Grid>
          <Grid item style={{ flexGrow: 1 }} />
          <Grid item>
            <Button
              color="default"
              variant="contained"
              onClick={() => dispatch(actions.setCollapsedColumns(!columnsCollapsed))}
            >
              { columnsCollapsed ?  "Expand columns" : "Collapse columns" }
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="default"
              variant="contained"
              onClick={handleSharedView}
              className="bg-info text-white"
            >
              {isLoadingSharedView
                ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )
                : 'Share View'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => dispatch(actions.generateReportAsCsv())}
            >
              {isLoadingCSV
                ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )
                : 'Export To CSV'}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const APILog = () => {
  return (
    <Container>
      <ReportParamsForm />
      <APILogReport />
    </Container>
  );
};

export default APILog;
