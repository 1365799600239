import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, CardContent} from '@material-ui/core';
import { actions } from '~/app/modules/QuestionsManagement/components/MappedQuestion/actions';
import {
  HelpOutline,
  AccountCircleOutlined,
  LanguageOutlined,
  LocationOnOutlined,
  ListAltOutlined
} from '@material-ui/icons';
import MappingQuestionsModal from './MappingQuestionsModal';
import MappingAnswers from './MappingAnswers';
import { useHistory } from 'react-router-dom';


const DescriptionSection = () => {
  const mappingQuestion = useSelector((state) => state.mappedQuestion.selectedQuestion);

  return (
    <Card className="my-5">
      <CardContent className="border text-dark border-light-dark rounded">
        <div>
          <div className="font-weight-boldest font-size-lg">Mapping Question:</div>
          <div>{mappingQuestion.text}</div>
          <div className="d-flex">
            <div className="d-flex mr-10">
              <HelpOutline className="text-primary mr-2" />
              <div>
                {mappingQuestion._id}
              </div>
            </div>
            <div className="d-flex mr-10">
              <AccountCircleOutlined className="text-primary mr-2" />
              <div>
                {mappingQuestion.provider}
              </div>
            </div>
            <div className="d-flex mr-10">
              <LanguageOutlined className="text-primary mr-2" />
              <div>
                {mappingQuestion.languageCode}
              </div>
            </div>
            <div className="d-flex mr-10">
              <LocationOnOutlined className="text-primary mr-2" />
              <div>
                {mappingQuestion.languageCode}
              </div>
            </div>
            <div className="d-flex mr-10">
              <ListAltOutlined className="text-primary mr-2" />
              <div>
                {mappingQuestion?.answers?.length || 0}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
};


const MappedQuestion = () => {
  const [shown, setShown] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dispatch = useDispatch();
  const mapping = useSelector((state) => state.mappedQuestion.mapping);
  const [mappingThatNeedSave, setMappingThatNeedSave] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const unblock = history.block(() => {
      if (mappingThatNeedSave.length) {
        return window.confirm("Are you sure you want to leave the page without saving?");
      }
      return true;
    });

    return () => {
      unblock();
    };
  });

  const handleAddNewMapping = () => {
    dispatch(actions.addNewMapping([...mapping, {}]));
  };

  return (
    <div>
      <Card>
        <CardContent className="border text-dark border-light-dark rounded">
          <div className="d-flex justify-content-between align-items-start">
            <h3>Questions</h3>
          </div>
        </CardContent>
      </Card>
      <DescriptionSection />
      <MappingQuestionsModal index={selectedIndex} shown={shown} setShown={setShown} />
      {mapping?.map((item, index) => (
        <MappingAnswers
          key={index}
          index={index + 1}
          setShown={setShown}
          setSelectedIndex={setSelectedIndex}
          mappingThatNeedSave={mappingThatNeedSave}
          setMappingThatNeedSave={setMappingThatNeedSave}
        />
      ))}
      <Button
        color="primary"
        variant="contained"
        className="max-h-30px bg-light-primary text-primary"
        onClick={handleAddNewMapping}
      >
        + MAP TO ANOTHER QUESTION
      </Button>
    </div>
  )
};

export default MappedQuestion;
