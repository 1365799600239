import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import { reducer as authReducer, saga as authSaga } from "../app/modules/Auth/_redux/authRedux";
import reportBuilderReducer from '~/app/modules/ReportBuilder/reducer';
import reportBuilderSaga from '~/app/modules/ReportBuilder/saga';
import reportReducer from '~/app/modules/Report/reducer';
import reportSaga from '~/app/modules/Report/saga';
import highReversalRateBansSaga from '~/app/modules/HighReversalRateBans/saga';
import highReversalRateBansReducer from '~/app/modules/HighReversalRateBans/reducer';
import veriffLogSaga from '~/app/modules/VeriffLog/saga';
import veriffLogReducer from '~/app/modules/VeriffLog/reducer';
import apiLogSaga from '~/app/modules/APILog/saga';
import apiLogReducer from '~/app/modules/APILog/reducer';
import miscellaneousExportUserDataSaga from '~/app/modules/Miscellaneous/ExportUserData/saga';
import miscellaneousExportUserDataReducer from '~/app/modules/Miscellaneous/ExportUserData/reducer';
import qaHelpersSaga from '~/app/modules/Miscellaneous/QAHelpers/saga';
import qaHelpersReducer from '~/app/modules/Miscellaneous/QAHelpers/reducer';
import userReducer from '~/app/modules/User/reducer';
import userSaga from '~/app/modules/User/saga';
import usersReducer from '~/app/modules/Users/reducer';
import bannedUsersReducer from '~/app/modules/BannedUsers/reducer';
import usersSaga from '~/app/modules/Users/saga';
import bannedUsersSaga from '~/app/modules/BannedUsers/saga';
import surveySaga from '~/app/modules/Survey/saga';
import surveyReducer from '~/app/modules/Survey/reducer';
import surveysSaga from '~/app/modules/Surveys/saga';
import surveysReducer from '~/app/modules/Surveys/reducer';
import clicksReportReducer from '~/app/modules/ClicksReport/reducer';
import clicksReportSaga from '~/app/modules/ClicksReport/saga';
import sharedViewReducer from '~/app/modules/SharedView/reducer';
import sharedViewSaga from '~/app/modules/SharedView/saga';
import languagesReducer from '~/app/modules/Translations/components/Languages/reducer';
import languagesSaga from '~/app/modules/Translations/components/Languages/saga';
import translatorsReducer from '~/app/modules/Translations/components/Translators/reducer';
import translatorsSaga from '~/app/modules/Translations/components/Translators/saga';
import productsReducer from '~/app/modules/Translations/components/Products/reducer';
import productsSaga from '~/app/modules/Translations/components/Products/saga';
import featureReducer from '~/app/modules/Translations/components/Feature/reducer';
import featureSaga from '~/app/modules/Translations/components/Feature/saga';
import featuresReducer from '~/app/modules/Translations/components/Features/reducer';
import featuresSaga from '~/app/modules/Translations/components/Features/saga';
import keysReducer from '~/app/modules/Translations/components/Keys/reducer';
import keysSaga from '~/app/modules/Translations/components/Keys/saga';
import questionsSaga from '~/app/modules/QuestionsManagement/components/Questions/saga';
import questionsReducer from '~/app/modules/QuestionsManagement/components/Questions/reducer';
import profilerOrderSaga from '~/app/modules/QuestionsManagement/components/ProfilerOrder/saga';
import profilerOrderReducer from '~/app/modules/QuestionsManagement/components/ProfilerOrder/reducer';
import mappedQuestionReducer from '~/app/modules/QuestionsManagement/components/MappedQuestion/reducer';
import mappedQuestionSaga from '~/app/modules/QuestionsManagement/components/MappedQuestion/saga';
import reviewsReducer from '~/app/modules/Reviews/reducer';
import reviewsSaga from '~/app/modules/Reviews/saga';
import surveyListResultsLogReducer from '~/app/modules/SurveyListResultsLog/reducer';
import surveyListResultsLogSaga from '~/app/modules/SurveyListResultsLog/saga';
import surveyUpdatesLogReducer from '~/app/modules/SurveyUpdatesLog/reducer';
import surveyUpdatesLogSaga from '~/app/modules/SurveyUpdatesLog/saga';
import rewardsReducer from '~/app/modules/Rewards/components/RewardsManagement/reducer';
import rewardsSaga from '~/app/modules/Rewards/components/RewardsManagement/saga';
import amazonInventoryReducer from '~/app/modules/Rewards/components/AmazonInventory/reducer';
import amazonInventorySaga from '~/app/modules/Rewards/components/AmazonInventory/saga';
import bitcoinRecordsReducer from '~/app/modules/Rewards/components/BitcoinRecords/reducer';
import bitcoinRecordsSaga from '~/app/modules/Rewards/components/BitcoinRecords/saga';
import checksAndRewardsReducer from '~/app/modules/PPChecksRewardsBalancePopup/reducer';
import checksAndRewardsSaga from '~/app/modules/PPChecksRewardsBalancePopup/saga';
import ordersReducer from '~/app/modules/Orders/reducer';
import ordersSaga from '~/app/modules/Orders/saga';
import tolunaNotificationsLogReducer from '~/app/modules/TolunaNotificationsLog/reducer';
import tolunaNotificationsLogSaga from '~/app/modules/TolunaNotificationsLog/saga';
import atomsManagementReducer from '~/app/modules/AtomsManagement/reducer';
import atomsManagementSaga from '~/app/modules/AtomsManagement/saga';
import emailLimitsManagementReducer from '~/app/modules/EmailLimitsManagement/reducer';
import emailLimitsManagementSaga from '~/app/modules/EmailLimitsManagement/saga';
import faceTecSettingsReducer from '~/app/modules/FaceTecSettings/reducer';
import faceTecSettingsSaga from '~/app/modules/FaceTecSettings/saga';

export const rootReducer = combineReducers({
  auth: authReducer,
  report: reportReducer,
  reportBuilder: reportBuilderReducer,
  highReversalRateBans: highReversalRateBansReducer,
  veriffLog: veriffLogReducer,
  apiLog: apiLogReducer,
  miscellaneousExportUserData: miscellaneousExportUserDataReducer,
  qaHelpers: qaHelpersReducer,
  user: userReducer,
  users: usersReducer,
  bannedUsers: bannedUsersReducer,
  survey: surveyReducer,
  surveys: surveysReducer,
  clicksReport: clicksReportReducer,
  sharedView: sharedViewReducer,
  languages: languagesReducer,
  translators: translatorsReducer,
  products: productsReducer,
  feature: featureReducer,
  features: featuresReducer,
  keys: keysReducer,
  questions: questionsReducer,
  profilerOrder: profilerOrderReducer,
  mappedQuestion: mappedQuestionReducer,
  userReviews: reviewsReducer,
  surveyListResultsLogs: surveyListResultsLogReducer,
  surveyUpdatesLogs: surveyUpdatesLogReducer,
  rewards: rewardsReducer,
  amazonInventory: amazonInventoryReducer,
  bitcoinRecords: bitcoinRecordsReducer,
  checksAndRewards: checksAndRewardsReducer,
  orders: ordersReducer,
  tolunaNotificationsLog: tolunaNotificationsLogReducer,
  atomsManagement: atomsManagementReducer,
  emailLimitsManagement: emailLimitsManagementReducer,
  faceTecSettings: faceTecSettingsReducer,
});

export function* rootSaga() {
  yield all([
    authSaga(),
    ...reportBuilderSaga,
    ...reportSaga,
    highReversalRateBansSaga(),
    ...veriffLogSaga,
    ...apiLogSaga,
    ...miscellaneousExportUserDataSaga,
    ...qaHelpersSaga,
    ...userSaga,
    ...usersSaga,
    ...bannedUsersSaga,
    ...surveySaga,
    ...surveysSaga,
    ...clicksReportSaga,
    ...sharedViewSaga,
    ...languagesSaga,
    ...translatorsSaga,
    ...productsSaga,
    ...featureSaga,
    ...featuresSaga,
    ...keysSaga,
    ...questionsSaga,
    ...profilerOrderSaga,
    ...mappedQuestionSaga,
    ...reviewsSaga,
    ...surveyListResultsLogSaga,
    ...surveyUpdatesLogSaga,
    ...rewardsSaga,
    ...amazonInventorySaga,
    ...bitcoinRecordsSaga,
    ...checksAndRewardsSaga,
    ...ordersSaga,
    ...tolunaNotificationsLogSaga,
    ...atomsManagementSaga,
    ...emailLimitsManagementSaga,
    ...faceTecSettingsSaga,
  ]);
}
