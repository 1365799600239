import config from '~/config';
import fetch from '~/redux/fetch';

const FETCH_REPORT_URL = '/high-reversal-rate-bans';

const fetchReport = async (params) => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_REPORT_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  const results = await response.json();
  return results;
}

export default {
  fetchReport,
};
