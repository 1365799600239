import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "~/app/modules/User/actions";
import {
  Card, CardContent, Grid, Select, FormControl, MenuItem,
} from "@material-ui/core";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "~/_metronic/_helpers";
import config from '~/config';
import SwitchField from "./SwitchField";

const NotificationsCard = () => {
  const userInformation = useSelector(state => state.user.userInformation);
  return (
    <Card className="container-fluid px-0 mb-2">
      <CardContent className="border text-dark border-light-dark rounded h-100">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          Notifications
        </h3>
        <SwitchField label={'Email Notifications'} status={!!userInformation.user?.settings?.emailNotifications} />
        <SwitchField label={'Browser Notifications'} status={!!userInformation.user?.settings?.notificationWhenNewGoodSurvey} />
        <SwitchField label={'Mobile App Notifications'} status={!!userInformation?.user?.settings?.mobileAppPush} />
      </CardContent>
    </Card>
  )
}

const PaypalAccountCard = () => {
  const userInformation = useSelector(state => state.user.userInformation);
  const userOrders = useSelector(state => state.user.userOrders);
  const paidPaypalOrders = userOrders.filter((order) => order?.status === 'paid' && order?.card?.service === 'paypal');
  const emailInLastPaidOrder = paidPaypalOrders.length ? paidPaypalOrders[paidPaypalOrders.length - 1]?.user?.payPalEmail : '';
  const currentAccountInfo = userInformation.user?.payPalAccountEmails
    ?.find((account) => account.email_address === userInformation.user?.payPalEmail);
  const checkIconClassName = `svg-icon svg-icon-2x ${
    (userInformation.user?.payPalEmail === emailInLastPaidOrder) ? 'svg-icon-success' : 'svg-icon-secondary'
  }`

  return (
    <Card className="container-fluid px-0">
      <CardContent className="border text-dark border-light-dark rounded h-100">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          Paypal Account
        </h3>
        <div className="px-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>{userInformation.user?.payPalEmail || '-'}</div>
            {!!userInformation.user?.payPalEmail && (
              <span className={checkIconClassName}>
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                />
              </span>
            )}

          </div>
          <h5 className="font-weight-bolder text-dark mb-5 mt-5">
            Account Data
          </h5>
          <div className="bg-secondary d-flex py-2 rounded mb-3 font-weight-bold">
            <div className="min-w-50 ml-3">PARAMETER</div>
            <div>VALUE</div>
          </div>
          <div className="pl-3">
            <div className="d-flex mb-2">
              <div className="min-w-50">account_id</div>
              <div>
                {currentAccountInfo?.account_id || '-'}
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="min-w-50">email_address</div>
              <div>
                {currentAccountInfo?.email_address || '-'}
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="min-w-50">address_status</div>
              <div>
                {currentAccountInfo?.address_status || '-'}
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="min-w-50">payer_status</div>
              <div>
                {currentAccountInfo?.payer_status || '-'}
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="min-w-50">country_code</div>
              <div>
                {currentAccountInfo?.country_code || '-'}
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="min-w-50">payer_name</div>
              <div>
                <div>given_name:"{currentAccountInfo?.payer_name?.given_name || '-'}"</div>
                <div>surname:"{currentAccountInfo?.payer_name?.surname || '-'}"</div>
                {currentAccountInfo?.payer_name?.middle_name && <div>middle_name:"{currentAccountInfo?.payer_name?.middle_name || '-'}"</div>}
                <div>alternate_full_name:"{currentAccountInfo?.payer_name?.alternate_full_name || '-'}"</div>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const AmbassadorAccountCard = () => {
  const userInformation = useSelector(state => state.user.userInformation);

  const onShadowLogin = (publisherId) => () => {
    if(publisherId) {
      window.open(`${config.apiBaseUrl}/publishers/${publisherId}/shadowLogin`);
    }
  };

  return (
    <Card className="container-fluid px-0 mb-2">
      <CardContent className="border text-dark border-light-dark rounded h-100 pb-11">
        <div className="d-flex justify-content-between align-items-start mb-5">
          <h3 className="card-title font-weight-bolder text-dark">
            Ambassador Account
          </h3>
          <button
            disabled={!userInformation.user?.ambassador?.publisherId}
            type="button"
            className="btn btn-primary btn-sm ml-10"
            onClick={onShadowLogin(userInformation.user?.ambassador?.publisherId)}
          >
              Shadow login
          </button>
        </div>
        <SwitchField label={'Eligible for Ambassador Account'} status={!!userInformation.user?.ambassador?.eligibleLink} />
        <SwitchField label={'Has Ambassador Account'} status={!!userInformation.user?.ambassador?.publisherId} />
      </CardContent>
    </Card>
  )
}

const BitcoinAccountCard = () => {
  const userInformation = useSelector(state => state.user.userInformation);
  const userOrders = useSelector(state => state.user.userOrders);
  const paidBitcoinOrder = userOrders.find((order) => order?.status === 'paid' && order?.card?.service === 'bitcoin');
  const checkIconClassName = `svg-icon svg-icon-2x ${
    !!paidBitcoinOrder ? 'svg-icon-success' : 'svg-icon-secondary'
  }`

  return (
    <Card className="container-fluid px-0 mb-2">
      <CardContent className="border text-dark border-light-dark rounded h-100">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          Bitcoin Account
        </h3>
        <div className="d-flex justify-content-between align-items-center">
          <div>{userInformation.user?.coinbaseEmail || '-'}</div>
          {!!userInformation.user?.coinbaseEmail && (
            <span className={checkIconClassName}>
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

const SurveysViewCard = ({ userId }) => {
  const dispatch = useDispatch();
  const userInformation = useSelector(state => state.user.userInformation);

  const handleChangeSingleSurveyShouldBeShown = (userId, isSingleSurveyShouldBeShown) => () => {
    if(userInformation.user.isSingleSurveyShouldBeShown !== isSingleSurveyShouldBeShown) {
      dispatch(actions.updateUserIsSingleSurveyShouldBeShown(userId, { isSingleSurveyShouldBeShown }));
    }
  }

  const listButtonClassName = `btn btn-sm min-w-100px d-flex justify-content-center align-items-center font-weight-bold ${
    userInformation.user?.isSingleSurveyShouldBeShown ? 'btn-secondary' : 'btn-success'
  }`;
  const singleButtonClassName = `btn btn-sm ml-5 min-w-100px d-flex justify-content-center align-items-center font-weight-bold ${
    userInformation.user?.isSingleSurveyShouldBeShown ? 'btn-success' : 'btn-secondary'
  }`;

  const renderCheckMarkIcon = (show) => (
    show && (
      <span className="svg-icon svg-icon ml-2">
        <SVG
          src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
        />
      </span>
  ))

  return (
    <Card className="container-fluid px-0 mb-2">
      <CardContent className="border text-dark border-light-dark rounded h-100">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          Surveys View
        </h3>
        <div className="d-flex align-items-start">
          <button
            type="button"
            className={listButtonClassName}
            onClick={handleChangeSingleSurveyShouldBeShown(userId, false)}
          >
            List
            {renderCheckMarkIcon(!userInformation.user?.isSingleSurveyShouldBeShown)}
          </button>
          <button
            type="button"
            className={singleButtonClassName}
            onClick={handleChangeSingleSurveyShouldBeShown(userId, true)}
          >
            Single
            {renderCheckMarkIcon(userInformation.user?.isSingleSurveyShouldBeShown)}
          </button>
        </div>
      </CardContent>
    </Card>
  )
}

const RoleCard = ({ userInformation }) => {
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState(userInformation.user?.roles?.[0] || 'user');

  const handleChange = (e) => {
    setUserRole(e.target.value);
  };

  const handleSave = () => {
    if (userRole === 'admin') {
      dispatch(actions.setUserRole({ userId: userInformation?.user?._id, userRole: ['admin']}));
    } else {
      dispatch(actions.setUserRole({ userId: userInformation?.user?._id, userRole: [] }));
    }
  };

  return (
    <Card className="container-fluid px-0 mt-2">
      <CardContent className="border text-dark border-light-dark rounded">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          Role
        </h3>
        <div className="d-flex justify-content-between align-items-center">
          <FormControl margin="normal" className="my-0">
            <Select onChange={handleChange} value={userRole}>
              <MenuItem value={'user'}>User</MenuItem>
              <MenuItem value={'admin'}>Admin</MenuItem>
            </Select>
          </FormControl>
          <button
            type="button"
            className="btn btn-primary btn-md ml-20"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </CardContent>
    </Card>
  )
};

const ProviderPanelistIDs = () => {
  const userInformation = useSelector(state => state.user.userInformation);

  return (
    <Card className="container-fluid px-0">
      <CardContent className="border text-dark border-light-dark rounded h-100">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          Provider Panelist IDs
        </h3>
        <div className="d-flex flex-column">
          <div className="d-flex px-3 py-2 bg-secondary rounded text-uppercase font-weight-bold w-100">
            <div className="mr-10 min-w-200px">Provider (parameter)</div>
            <div className="min-w-50">ID</div>
          </div>
          {userInformation?.pureSpectrumPSID ? (
            <div className="d-flex mt-3 px-3">
              <div className="mr-10 min-w-200px">Pure Spectrum (ps_psid)</div>
              <div className="min-w-50">{userInformation?.pureSpectrumPSID || ''}</div>
          </div>) : ''}
        </div>
      </CardContent>
    </Card>
  );
};

const AccountInformation = ({ userId }) => {
  const userInformation = useSelector(state => state.user.userInformation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getUserOrders(userId));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if(!userInformation.user) {
    return null;
  }

  return (
    <Grid container spacing={1} className="flex-row-fluid">
      <Grid item xs={12} md={12} lg={6}>
        <NotificationsCard />
        <PaypalAccountCard />
        <RoleCard userInformation={userInformation} />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <AmbassadorAccountCard />
        <BitcoinAccountCard />
        <SurveysViewCard userId={userId} />
        <ProviderPanelistIDs />
      </Grid>
    </Grid>
  )
};

export default AccountInformation;
