import { select, call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/SurveyListResultsLog/api';
import { actions, actionTypes } from '~/app/modules/SurveyListResultsLog/actions';

const selectForFetchReport = (state) => ({
  filters: state.surveyListResultsLogs.filters,
  pageNumber: state.surveyListResultsLogs.pageNumber,
  pageSize: state.surveyListResultsLogs.pageSize,
  sortBy: state.surveyListResultsLogs.sortBy,
  sortDirection: state.surveyListResultsLogs.sortDirection,
});

function* requestReport() {
  try {
    const reportParams = yield select(selectForFetchReport);
    const { results } = yield call(api.fetchReport, reportParams);
    yield put(actions.fetchReportSuccess(results));
  } catch (error) {
    yield put(actions.fetchReportFailure(error.message));
  }
}

export default [
  takeLatest([
    actionTypes.GenerateReport,
    actionTypes.UpdateReport,
  ], requestReport),
];

