import React, { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Card, CardContent, Button, Grid } from '@material-ui/core';
import { actions } from '~/app/modules/Orders/actions';
import OrdersReport from "./Orders.report";
import {
  usePaginationFilteringAndSorting,
} from '~/app/helpers';
import {KeyboardDatePicker} from "@material-ui/pickers";
import { useHistory, useParams } from "react-router-dom";
import enums from "~/app/helpers/enums";

const DateSelectors = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  switchName,
  onChangeSwitch,
  switchValue
}) => {
  return (
    <Grid container spacing={1} alignItems="center" className="max-w-600px">
      <Grid item md={2}>
        <span className="switch switch-sm switch-icon-success">
          <label>
            <input
              type="checkbox"
              name={switchName}
              checked={switchValue}
              onChange={onChangeSwitch}
            />
            <span />
          </label>
        </span>
      </Grid>
      <Grid item md={5}>
        <KeyboardDatePicker
          autoOk
          id="start-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          disabled={!switchValue}
          margin="none"
          label="Start Date"
          value={startDate}
          onChange={(_, v) => onChangeStartDate(v)}
        />
      </Grid>
      <Grid item md={5}>
        <KeyboardDatePicker
          autoOk
          id="end-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          disabled={!switchValue}
          margin="none"
          label="End Date"
          value={endDate}
          onChange={(_, v) => onChangeEndDate(v)}
        />
      </Grid>
    </Grid>
  )
};

const Orders = () => {
  const dispatch = useDispatch();
  const { totalCount } = usePaginationFilteringAndSorting(dispatch, 'orders', actions);
  const createdStartDate = useSelector(state => state.orders.createdStartDate);
  const createdEndDate = useSelector(state => state.orders.createdEndDate);
  const useCreatedDate = useSelector(state => state.orders.useCreatedDate);
  const params = useParams();
  const history = useHistory();


  useEffect(() => {
    if (!params.userId) {
      dispatch(actions.generateReport());
    } else {
      dispatch(actions.setParams({ filters: { 'user._id': params.userId }, useCreatedDate: false }));
      history.push('/orders');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeUseCreatedDate = () => {
    dispatch(actions.setUseCreatedDate(!useCreatedDate));
  }

  const onChangeDate = (key) => (v) => {
    if (key === 'createdStart') {
      dispatch(actions.setCreatedStartDate(v));
    } else {
      dispatch(actions.setCreatedEndDate(v));
    }
  };

  const handleGenerateButtonClick = () => {
    dispatch(actions.setGenerateButtonClick());
    dispatch(actions.generateReport());
  }

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="d-flex justify-content-between align-items-start mb-10">
          <h3>Orders</h3>
          <div className="d-flex flex-column justify-content-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => dispatch(actions.generateReportAsCsv())}
            >
              Export To CSV
            </Button>
            <div>Count of Orders: {totalCount}</div>
          </div>
        </div>
        <div className="my-3 d-flex align-bottom">
          <DateSelectors
            startDate={createdStartDate}
            endDate={createdEndDate}
            onChangeEndDate={onChangeDate('createdEnd')}
            onChangeStartDate={onChangeDate('createdStart')}
            onChangeSwitch={handleChangeUseCreatedDate}
            switchName={'create date'}
            switchValue={useCreatedDate}
          />
          <Button
            className="ml-5 mt-auto"
            color="secondary"
            variant="contained"
            onClick={handleGenerateButtonClick}
          >
            Generate
          </Button>
        </div>
        <OrdersReport />
      </CardContent>
    </Card>
  )
};

export default Orders;
