import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fieldsList, placeholder, operators } from './data';

export const Filter = props => {
  const [view, setView] = useState('');
  const [selField, setSelField] = useState();
  const [selOperator, setSelOperator] = useState();
  const [allChipsSelected, setAllChipsSelected] = useState(false);
  const [fieldChipSelected, setFieldChipSelected] = useState(false);
  const [operatorChipSelected, setOperatorChipSelected] = useState(false);
  const [options, setOptions] = useState(fieldsList[props.reportType]);
  const [fieldValues, setFieldValues] = useState([]);
  const [filterChips, setFilterChips] = useState((props && props.value) || []);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState('');

  function onInputFocus() {
    if (view === '') {
      if (selOperator) {
        setView('values');
      } else if (selField) {
        setView('operators');
      } else {
        setView('fields');
      }
    }
  }

  function onInputBlur() {
  }

  function onFieldClick(field) {
    setSelField(field);
    let mappedValues = [];
    if (!field) {
      return;
    }
    mappedValues.push(...field.choicesMap)

    setFieldValues(mappedValues);
    setOptions(operators[field.type]);
    setView('operators');
    setInputValue('');
    setValue('');
  }

  function onOperatorClick(operator) {
    setSelOperator(operator);
    setOptions(fieldValues);
    setView('values');
    setInputValue('');
    setValue('')
  }

  function onValueSelect(val) {
    if (!val) {
      return;
    }
    let chipValue;
    if (typeof val === 'string') {
      chipValue = val;
    } else {
      chipValue = val.value;
    }

    const unmutableChip = {
      key: uuidv4(),
      label: `${selField.label} ${selOperator.label} ${chipValue}`,
      field: selField.name,
      operator: selOperator.value,
      value: chipValue,
    } 


    if (!filterChips.some((ch) => ch.key === unmutableChip.label)) {
      setFilterChips([
        ...filterChips,
        unmutableChip,
      ]);

      if (props && props.setFilter) {
        props.setFilter(unmutableChip)
      }
    }
    resetAllViews();
    setInputValue('');
    setValue('');
  }

  function onChipDelete(chip) {
    const newChips = filterChips.filter((ch) => ch.key !== chip.key)

    setFilterChips([...newChips]);

    if (props && props.deleteFilter) {
      props.deleteFilter(chip.key)
    }
  }

  function resetAllViews() {
    setSelField();
    setFieldChipSelected(false);
    setSelOperator();
    setOperatorChipSelected(false);
    setAllChipsSelected(false);
    setOptions(fieldsList[props.reportType]);
    setView('fields');
  }

  function onSelectChange(value) {
    if (view === 'fields') {
      onFieldClick(value);
    } else if (view === 'operators') {
      onOperatorClick(value);
    } else if (view === 'values') {
      onValueSelect(value);
    }
  }

  function onInputChange(value) {
    setInputValue(value);
    onKeyUp(value);
  }

  function onKeyDown(e) {
    const value = e.target.value;
    if (value === '') {
      if ((e.key === 'a' && e.metaKey) || (e.key === 'a' && e.ctrlKey)) {
        setAllChipsSelected(true);
        setFieldChipSelected(true);
        setOperatorChipSelected(true);
      }

      if (e.key === 'Backspace') {
        if (allChipsSelected) {
          resetAllViews();
        } else if (view === 'operators') {
          if (fieldChipSelected || e.remove) {
            resetAllViews();
          } else {
            setFieldChipSelected(true);
          }
        } else if (view === 'values') {
          if (operatorChipSelected || e.remove) {
            setSelOperator();
            setView('operators');
            setOperatorChipSelected(false);
            setOptions(operators[selField.type]);
          } else {
            setOperatorChipSelected(true);
          }
        }
      }
    }
  }

  function onKeyUp(value) {
    if (value !== '') {
      setAllChipsSelected(false);
      setFieldChipSelected(false);
      setOperatorChipSelected(false);
    }
  }

  return (
    <div>
      {filterChips.map(chip => (
        <div style={{marginBottom:'5px'}}>
          <Chip
            key={`chip-${chip.key}`}
            label={chip.label}
            size="small"
            color="secondary"
            onDelete={() => onChipDelete(chip)}
          />
        </div>
      ))}
      <div>
        {selField && (
          <Chip
            label={selField.label}
            size="small"
            color={fieldChipSelected ? 'secondary' : 'default'}
            onDelete={() => onKeyDown({key: 'Backspace', target: {value: ''}, remove: true})}
          />
        )}

        {selOperator && (
          <Chip
            label={selOperator.label}
            size="small"
            color={operatorChipSelected ? 'secondary' : 'default'}
            onDelete={() => onKeyDown({key: 'Backspace', target: {value: ''}, remove: true})}
          />
        )}

        <Autocomplete
          options={options}
          onChange={(_, value) => onSelectChange(value)}
          onInputChange={(_, value) => onInputChange(value)}
          getOptionLabel={option => option.label || ''}
          onFocus={() => onInputFocus()}
          onBlur={() => onInputBlur()}
          onKeyDown={e => onKeyDown(e)}
          inputValue={inputValue}
          value={value}
          disableClearable={false}
          freeSolo={view === 'values' && selField.type !== 'enum'}
          openOnFocus
          autoFocus
          renderInput={params => (
            <TextField
              {...params}
              variant='standard'
              label='Filter'
              placeholder={placeholder[view] || placeholder['fields']}
            />
          )}
        />
      </div>
    </div>
  );
};
