import { call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/Rewards/components/RewardsManagement/api';
import { actions, actionTypes } from '~/app/modules/Rewards/components/RewardsManagement/actions';

function* getRewards() {
  try {
    const results = yield call(api.getRewards);
    yield put(actions.getRewardsSuccess(results));
  } catch (error) {
    yield put(actions.getRewardsFailure(error.message));
  }
}

function* getRewardProviders() {
  try {
    const results = yield call(api.getRewardProviders);
    yield put(actions.getRewardProvidersSuccess(results));
  } catch (error) {
    yield put(actions.getRewardProvidersFailure(error.message));
  }
}

function* getCurrentReward(action) {
  try {
    const results = yield call(api.getCurrentReward, action.payload);
    yield put(actions.getCurrentRewardSuccess(results));
  } catch (error) {
    yield put(actions.getCurrentRewardFailure(error.message));
  }
}

function* getFundsHistory(action) {
  try {
    const results = yield call(api.getFundsHistory, action.payload);
    yield put(actions.getFundsHistorySuccess(results));
  } catch (error) {
    yield put(actions.getFundsHistoryFailure(error.message));
  }
}

function* getRewardsTranslations(action) {
  try {
    const results = yield call(api.getRewardsTranslations, action.payload);
    yield put(actions.getRewardsTranslationsSuccess(results));
  } catch (error) {
    yield put(actions.getRewardsTranslationsFailure(error.message));
  }
}


function* updateCurrentReward(action) {
  try {
    const results = yield call(api.updateCurrentReward, action.payload);
    yield put(actions.updateCurrentRewardSuccess(results));
  } catch (error) {
    yield put(actions.updateCurrentRewardFailure(error.message));
  }
}

function* findKeys(action) {
  try {
    const results = yield call(api.findKeys, action.payload);
    yield put(actions.findKeysSuccess(results));
  } catch (error) {
    yield put(actions.findKeysFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.GetRewards, getRewards),
  takeLatest(actionTypes.GetCurrentReward, getCurrentReward),
  takeLatest(actionTypes.GetRewardsTranslations, getRewardsTranslations),
  takeLatest(actionTypes.UpdateCurrentReward, updateCurrentReward),
  takeLatest(actionTypes.GetFundsHistory, getFundsHistory),
  takeLatest(actionTypes.GetRewardProviders, getRewardProviders),
  takeLatest(actionTypes.FindKeys, findKeys),
];

