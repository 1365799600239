import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('questions');

actionTypes.SetLanguageCode = 'questions/set-language-code';
actionTypes.SetCountryCode = 'questions/set-country-code';
actionTypes.SetProvider = 'questions/set-provider';

actions.setLanguageCode = (languageCode) => ({ type: actionTypes.SetLanguageCode, payload: languageCode });
actions.setCountryCode = (countryCode) => ({ type: actionTypes.SetCountryCode, payload: countryCode });
actions.setProvider = (provider) => ({ type: actionTypes.SetProvider, payload: provider });

export {
  actionTypes,
  actions,
};
