import { select, call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/Miscellaneous/ExportUserData/api';
import { actions, actionTypes } from '~/app/modules/Miscellaneous/ExportUserData/actions';


const selectForFetchReportAsCsv = (state) => ({
  filterDate: {
    start: state.miscellaneousExportUserData.startDate,
    end: state.miscellaneousExportUserData.endDate,
  },
  countryCode: state.miscellaneousExportUserData.countryCode,
  userType: state.miscellaneousExportUserData.userType,
  registrationPublisherId: state.miscellaneousExportUserData.registrationPublisherId,
});

const selectForFetchReportAsCsvByIds = (state) => ({
  userIds: state.miscellaneousExportUserData.userIds,
});

function* requestReportAsCsv() {
  try {
    const reportParams = yield select(selectForFetchReportAsCsv);
    yield call(api.exportUserData, reportParams);
    yield put(actions.exportToCsvSuccess())
  } catch (error) {
    console.error(error);
  }
}

function* requestReportAsCsvByIds() {
  try {
    const reportParams = yield select(selectForFetchReportAsCsvByIds);
    yield call(api.exportUserDataByIds, reportParams);
    yield put(actions.exportToCsvByIdsSuccess())
  } catch (error) {
    console.error(error);
  }
}

function* getCountryCodes() {
  try {
    const results = yield call(api.getCountryCodes);
    yield put(actions.getCountryCodesSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

export default [
  takeLatest(actionTypes.ExportToCsv, requestReportAsCsv),
  takeLatest(actionTypes.ExportToCsvByIds, requestReportAsCsvByIds),
  takeLatest(actionTypes.GetCountryCodes, getCountryCodes),
];
