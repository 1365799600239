import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { TextField, Table, TableHead, TableCell, TableRow, TableBody, Tooltip } from "@material-ui/core";
import { ArrowForwardIos } from '@material-ui/icons';
import { actions } from "~/app/modules/Rewards/components/RewardsManagement/actions";
import api from "~/app/modules/Reviews/api";
import { newDateFormatLong } from "~/app/helpers";
import { makeStyles } from '@material-ui/core/styles';
import SVG from "react-inlinesvg";
import PopupWithColorPicker from "~/app/modules/Rewards/components/RewardsManagement/PopupWithColorPicker";
import Texts from './Texts';
import Additions from './Additions';

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 529,
  },
}));

const RewardsLogo = ({ onChange }) => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const classes = useStyles();
  const splitImageUrlByDot = currentReward?.rewardsTabLogo?.split('.');
  const imageType = splitImageUrlByDot?.[splitImageUrlByDot?.length - 1] || '';

  return (
    <div className="d-flex w-700px align-items-center mb-5">
      <div className="w-200px">Logo (Rewards)</div>
      <div className="d-flex align-items-center">
        <input type="file" onChange={onChange} />
        {currentReward?.rewardsTabLogo
          ? (
            <Tooltip
              arrow
              classes={{ tooltip: classes.customWidth }}
              title={(
                <div className="font-size-lg line-height-md min-w-500px min-h-500px p-2">
                  {imageType === 'svg' ? (
                      <SVG className="w-500px h-500px bg-light-secondary mb-2" src={currentReward?.rewardsTabLogo} />
                    ) :
                    (<img
                      alt="webLogo" src={currentReward?.rewardsTabLogo}
                      className="w-500px h-500px bg-light-secondary mb-2"
                    />)}
                  {currentReward?.rewardsTabLogo || 'Image was not uploaded'}
                </div>
              )}
            >
              <div className="w-50px h-50px bg-light-secondary d-flex ml-3">
                {imageType === 'svg' ? (
                  <SVG className="w-50px h-50px" src={currentReward?.rewardsTabLogo} />
                ) : (<img
                  src={currentReward?.rewardsTabLogo}
                  alt={currentReward?.name || ''}
                  className="w-50px h-50px"
                />)}
              </div>
            </Tooltip>
          )
          : ''}
      </div>
    </div>
  );
};

const OrdersLogo = ({ onChange }) => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const classes = useStyles();
  const splitImageUrlByDot = currentReward?.redeemCardLogo?.split('.');
  const imageType = splitImageUrlByDot?.[splitImageUrlByDot?.length - 1] || '';

  return (
    <div className="d-flex w-700px align-items-center mb-5">
      <div className="w-200px">Logo (Orders)</div>
      <div className="d-flex align-items-center">
        <input type="file" onChange={onChange} />
        {currentReward?.redeemCardLogo
          ? (
            <Tooltip
              arrow
              classes={{ tooltip: classes.customWidth }}
              title={(
                <div className="font-size-lg line-height-md min-w-500px min-h-500px p-2">
                  {imageType === 'svg' ? (
                      <SVG className="w-500px h-500px bg-light-secondary mb-2" src={currentReward?.redeemCardLogo} />
                    ) :
                    (<img
                      alt="webLogo" src={currentReward?.redeemCardLogo}
                      className="w-500px h-500px bg-light-secondary mb-2"
                    />)}
                  {currentReward?.redeemCardLogo || 'Image was not uploaded'}
                </div>
              )}
            >
              <div className="w-50px h-50px bg-light-secondary d-flex ml-3">
                {imageType === 'svg' ? (
                  <SVG className="w-50px h-50px" src={currentReward?.redeemCardLogo} />
                ) : (<img
                  src={currentReward?.redeemCardLogo}
                  alt={currentReward?.name || ''}
                  className="w-50px h-50px"
                />)}
              </div>
            </Tooltip>
          )
          : ''}
      </div>
    </div>
  );
};

const WebRewardsLogo = ({ onChange }) => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const classes = useStyles();
  const splitImageUrlByDot = currentReward?.webRewardsLogo?.split('.');
  const imageType = splitImageUrlByDot?.[splitImageUrlByDot?.length - 1] || '';

  return (
    <div className="d-flex w-700px align-items-center mb-5">
      <div className="w-200px">Web Logo (Rewards)</div>
      <div className="d-flex align-items-center">
        <input type="file" onChange={onChange} />
        {currentReward?.webRewardsLogo
          ? (
            <Tooltip
              arrow
              classes={{ tooltip: classes.customWidth }}
              title={(
                <div className="font-size-lg line-height-md min-w-500px min-h-500px p-2">
                  {imageType === 'svg' ? (
                    <SVG className="w-500px h-500px bg-light-secondary mb-2" src={currentReward?.webRewardsLogo} />
                    ) :
                    (<img
                    alt="webLogo" src={currentReward?.webRewardsLogo}
                    className="w-500px h-500px bg-light-secondary mb-2"
                  />)}
                  {currentReward?.webRewardsLogo || 'Image was not uploaded'}
                </div>
              )}
            >
              <div className="w-50px h-50px bg-light-secondary d-flex ml-3">
                {imageType === 'svg' ? (
                  <SVG className="w-50px h-50px" src={currentReward?.webRewardsLogo} />
                ) : (<img
                  src={currentReward?.webRewardsLogo}
                  alt={currentReward?.name || ''}
                  className="w-50px h-50px"
                />)}
              </div>
            </Tooltip>
          )
          : ''}
      </div>
    </div>
  );
};

const EmailLogo = ({ onChange }) => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const classes = useStyles();
  const splitImageUrlByDot = currentReward?.emailLogo?.split('.');
  const imageType = splitImageUrlByDot?.[splitImageUrlByDot?.length - 1] || '';

  return (
    <div className="d-flex w-700px align-items-center mb-5">
      <div className="w-200px">Reward Image (Email)</div>
      <div className="d-flex align-items-center">
        <input type="file" onChange={onChange} />
        {currentReward?.emailLogo
          ? (
            <Tooltip
              arrow
              classes={{ tooltip: classes.customWidth }}
              title={(
                <div className="font-size-lg line-height-md min-w-500px min-h-500px p-2">
                  {imageType === 'svg' ? (
                      <SVG className="w-500px h-500px bg-light-secondary mb-2" src={currentReward?.emailLogo} />
                    ) :
                    (<img
                      alt="webLogo" src={currentReward?.emailLogo}
                      className="w-500px h-500px bg-light-secondary mb-2"
                    />)}
                  {currentReward?.emailLogo || 'Image was not uploaded'}
                </div>
              )}
            >
              <div className="w-50px h-50px bg-light-secondary d-flex ml-3">
                {imageType === 'svg' ? (
                  <SVG className="w-50px h-50px" src={currentReward?.emailLogo} />
                ) : (<img
                  src={currentReward?.emailLogo}
                  alt={currentReward?.name || ''}
                  className="w-50px h-50px"
                />)}
              </div>
            </Tooltip>
          )
          : ''}
      </div>
    </div>
  );
};

const ColorField = () => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#ffffff');
  const [selectedColorType, setSelectedColorType] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const handleChangeColor = (key) => (color) => {
    dispatch(actions.changeCurrentRewardFields({ [key]: color }));
  };

  const handleEditColor = (color, type, title) => () => {
    setShowModal(true);
    setSelectedColor(color);
    setSelectedColorType(type);
    setModalTitle(title)
  }

  return (
    <>
      {showModal && (<PopupWithColorPicker
        shown={showModal}
        setShown={setShowModal}
        color={selectedColor}
        onChange={handleChangeColor(selectedColorType)}
        title={modalTitle}
      />)}
      <div className="d-flex w-700px align-items-center mb-3">
        <div className="w-200px">Background (main)</div>
        <div className="w-25px h-25px mr-3" style={{ backgroundColor: currentReward?.backgroundColor }} />
        <TextField
          variant="outlined"
          disabled
          className="p-0 w-100px"
          onChange={() => {}}
          value={currentReward?.backgroundColor || ''}
        />
        <button
          type="button"
          className="btn btn-primary btn-sm ml-5"
          onClick={handleEditColor(
            currentReward?.backgroundColor,
            'backgroundColor',
            'Choose background color for card')}
        >
          Edit
        </button>
      </div>
      <div className="d-flex w-700px align-items-center mb-3">
        <div className="w-200px">Background (hovered/focused)</div>
        <div className="w-25px h-25px mr-3" style={{ backgroundColor: currentReward?.hoveredBackgroundColor }} />
        <TextField
          variant="outlined"
          disabled
          className="p-0 w-100px"
          onChange={() => {}}
          value={currentReward?.hoveredBackgroundColor || ''}
        />
        <button
          type="button"
          className="btn btn-primary btn-sm ml-5"
          onClick={handleEditColor(
            currentReward?.hoveredBackgroundColor,
            'hoveredBackgroundColor',
            'Choose hovered background color for card')}
        >
          Edit
        </button>
      </div>
      <div className="d-flex w-700px align-items-center mb-3">
        <div className="w-200px">Border Color</div>
        <div className="w-25px h-25px mr-3" style={{ backgroundColor: currentReward?.borderColor }} />
        <TextField
          variant="outlined"
          disabled
          className="p-0 w-100px"
          onChange={() => {}}
          value={currentReward?.borderColor || ''}
        />
        <button
          type="button"
          className="btn btn-primary btn-sm ml-5"
          onClick={handleEditColor(
            currentReward?.borderColor,
            'borderColor',
            'Choose border color for card')}
        >
          Edit
        </button>
      </div>
    </>
  );
};

const renderRow = (row, index) => (
  <TableRow key={index}>
    <TableCell>{newDateFormatLong(row.createdAt)}</TableCell>
    <TableCell>{`${row?.currency || ''}${row?.amount}`}</TableCell>
  </TableRow>
);

const About = () => {
  const dispatch = useDispatch();
  const [showFunds, setShowFunds] = useState(false);
  const fundsHistory = useSelector(state => state.rewards.fundsHistory);

  const handleChangeLogo = (field) => async (event) => {
    event.persist();
    let file = null;
    if (event.target.files.length) {
      file = new FormData();
      file.append('userImage', event.target.files[0]);
      const data = await api.uploadImage(file);
      dispatch(actions.changeCurrentRewardFields({ [field]: data.imageURL }));
    }
  };

  const handleOnClickIcon = () => {
    setShowFunds(!showFunds);
  };

  return (
    <>
      <h4 className="mb-5">Display:</h4>
      <RewardsLogo onChange={handleChangeLogo('rewardsTabLogo')} />
      <OrdersLogo onChange={handleChangeLogo('redeemCardLogo')} />
      <WebRewardsLogo onChange={handleChangeLogo('webRewardsLogo')} />
      <EmailLogo onChange={handleChangeLogo('emailLogo')} />
      <ColorField />
      <Texts />
      <Additions />
      <div className="pb-10 mb-10 w-700px border-bottom border-secondary" />
      <div className="w-100 d-flex align-items-center justify-content-between transform-rotate-90">
        <h4 className="mb-5 w-50">Funds History:</h4>
        <ArrowForwardIos
          onClick={handleOnClickIcon}
          style={showFunds ? { transform: 'rotate(90deg)' }: {}}
          className="cursor-pointer"
        />
      </div>
      {showFunds ? (
        <Table className="w-500px ml-auto">
          <TableHead>
            <TableRow className="text-secondary text-uppercase">
              <TableCell>Upload Date</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fundsHistory.map(renderRow)}
          </TableBody>
        </Table>
      ) : <div />}
    </>
  );
};

export default About;
