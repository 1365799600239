import React from "react"
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import NotificationsEmail from "./notificationsTabs/NotificationsEmail";
import NotificationsBrowser from "./notificationsTabs/NotificationsBrowser";
import NotificationsMobile from "./notificationsTabs/NotificationsMobile";

import {
  Card, CardContent, Grid
} from "@material-ui/core";
import enums from "~/app/helpers/enums";

export const notificationsTabs = [
  {
    view: enums.userTabs.email,
    name: 'Email',
    renderComponent: (userId) => <NotificationsEmail userId={userId} />,
  },
  {
    view: enums.userTabs.browser,
    name: 'Browser',
    renderComponent: (userId) => <NotificationsBrowser userId={userId} />,
  },
  {
    view: enums.userTabs.mobile,
    name: 'Mobile',
    renderComponent: (userId) => <NotificationsMobile userId={userId} />,
  },
];

const NotificationsNavigation = ({ userId }) => {
  return (
    <>
      <div className="flex-row-fluid">
        <Switch>
          <Redirect
            from={`/users/${userId}/${enums.userTabs.notifications}`}
            exact={true}
            to={`/users/${userId}/${enums.userTabs.notifications}/${enums.userTabs.email}`}
          />
          {notificationsTabs.map((tab) => (
            <Route
              key={tab.view}
              path={`/users/${userId}/${enums.userTabs.notifications}/${tab.view}`}
              render={() => tab.renderComponent(userId)}
            />
          ))}
        </Switch>
      </div>
    </>
  )
}

const NotificationsMenu = ({ userId }) => {
  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded d-flex align-items-center py-3">
        <div className="card-title font-weight-bolder text-dark mb-0 font-size-h3 mr-10">
          Notifications
        </div>
        <div className="d-flex navi navi-bold navi-hover navi-active navi-link-rounded">
          {notificationsTabs.map((tab) => {
            return (
              <div key={tab.view} className="navi-item mr-5">
                <NavLink
                  to={`/users/${userId}/${enums.userTabs.notifications}/${tab.view}`}
                  className="navi-link py-4"
                  activeClassName="active border border-secondary"
                >
                <span className="navi-text font-size-lg">
                  {tab.name}
                </span>
                </NavLink>
              </div>
            )
          })}
        </div>
      </CardContent>
    </Card>
  );
}

const Notifications = ({ userId }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <NotificationsMenu userId={userId} />
      </Grid>
      <Grid item xs={12}>
        <NotificationsNavigation userId={userId} />
      </Grid>
    </Grid>
  )
};

export default Notifications;
