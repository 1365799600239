import React, { useEffect } from "react";
import { Card, CardContent, Accordion, AccordionDetails, AccordionSummary  } from "@material-ui/core";
import { useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { actions } from "~/app/modules/Survey/actions";
import { renderMappedField, renderPreCodes, renderAnswerOptions } from "~/app/helpers";

const columns = () => [{
  dataField: 'id', text: 'ID',
  style: { width: '150px', textAlign: 'left' },
}, {
  dataField: 'numberOfRespondents', text: 'Quota Amount',
  style: { width: '150px', textAlign: 'left' },
},{
  dataField: 'questionId', text: 'Internal Question ID',
  style: { width: '150px', textAlign: 'left' },
}, {
  dataField: 'providerQuestionId', text: 'Provider Question ID',
  style: { width: '150px', textAlign: 'left' },
}, {
  dataField: 'mapped', text: 'Mapped',
  style: { width: '100px', textAlign: 'left' },
  formatter: (mapped) => renderMappedField(mapped),
}, {
  dataField: 'baseQuestionId', text: 'Mapped Question ID',
  style: { width: '100px', textAlign: 'left' },
}, {
  dataField: 'name', text: 'Question Name',
  style: { width: '200px', textAlign: 'left' },
}, {
  dataField: 'text', text: 'Question Text',
  style: { width: '200px', textAlign: 'left' },
}, {
  dataField: 'precodes', text: 'Approved Answer ID',
  style: { width: '100px', textAlign: 'left' },
  formatter: (_cell, row) => renderPreCodes(row?.precodes, row?.options),
}, {
  dataField: 'options', text: 'Approved Answers Text',
  style: { width: '400px', textAlign: 'left' },
  formatter: (_cell, row) => renderAnswerOptions(row?.precodes, row?.options),
}];

const getQuotasTable = (data) => {

  const quotasTableColumns = columns();

  return (
    <Card className="mb-3">
      <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center">
        <BootstrapTable
          remote
          classes="table table-head-custom table-vertical-center overflow-hidden"
          headerClasses="table table-vertical-top"
          bootstrap4
          responsive
          keyField="idForTable"
          columns={quotasTableColumns}
          data={data}
          filter={ filterFactory() }
        >
        </BootstrapTable>
      </CardContent>
    </Card>
  );
};

const QuotasView = () => {
  const tolunaIpes = useSelector((state) => state.survey.tolunaIpes);

  if (!tolunaIpes || !tolunaIpes.quotas) {
    return null;
  }

  return (
    <>
      <h2>Quotas</h2>
      {tolunaIpes.quotas.map((data, index) => {
        return (
         <Accordion>
          <AccordionSummary
            id={`quota-data-${index}`}
            key={`quota-data-${index}`}
          >
            Quota #{index} - numberOfRespondents: {data.numberOfRespondents ? data.numberOfRespondents : 0}
          </AccordionSummary>
          <AccordionDetails style={{'display':'block'}}>
            {data.layers.map((layer, i) => {
                return (
                    <Accordion>
                      <AccordionSummary>Layer #{i}</AccordionSummary>
                      <AccordionDetails style={{'display':'block'}} >
                        {layer.subQuotas.map((subQuota, i) => {
                            return (
                                <Accordion>
                                  <AccordionSummary>subQuota #{i}</AccordionSummary>
                                  <AccordionDetails>
                                    {getQuotasTable(subQuota)}
                                  </AccordionDetails>
                                </Accordion>
                            )
                          })}
                      </AccordionDetails>
                    </Accordion>
                )
              })}
          </AccordionDetails>
        </Accordion>
        )
      })} 
    </>
  );
}

const TolunaIpesData = () => { 
  const dispatch = useDispatch();
  const params = useParams();
  console.log(params);

  useEffect(() => {
    dispatch(actions.getSurveyTolunaIpesData(params.surveyId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center">
          <h2 className="d-flex align-items-center">
            Toluna IPES survey Data
            <div className="ml-4 mb-0 h5 text-muted">Quotas</div>
          </h2>
        </CardContent>
      </Card>
      <QuotasView />
    </div>
  );
};

export default TolunaIpesData;
