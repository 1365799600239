import React, { useEffect } from 'react';
import {Button, Card, CardContent, Grid} from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "~/app/modules/TolunaNotificationsLog/actions";
import {KeyboardDatePicker} from "@material-ui/pickers";
import TolunaNotificationsLogReport from "~/app/modules/TolunaNotificationsLog/TolunaNotificationsLog.report";
import enums from "~/app/helpers/enums";

const DateSelectors = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  switchName,
  onChangeSwitch,
  switchValue,
  dispatch,
}) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item md={2}>
        <span className="switch switch-sm switch-icon-success">
          <label>
            <input
              type="checkbox"
              name={switchName}
              checked={switchValue}
              onChange={onChangeSwitch}
            />
            <span />
          </label>
        </span>
      </Grid>
      <Grid item md={3}>
        <KeyboardDatePicker
          autoOk
          id="start-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          disabled={!switchValue}
          margin="none"
          label="Start Date"
          value={startDate}
          onChange={(_, v) => onChangeStartDate(v)}
        />
      </Grid>
      <Grid item md={3}>
        <KeyboardDatePicker
          autoOk
          id="end-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          disabled={!switchValue}
          margin="none"
          label="End Date"
          value={endDate}
          onChange={(_, v) => onChangeEndDate(v)}
        />
      </Grid>
      <Grid item md={4}>
        <Button
          color="primary"
          variant="contained"
          className="ml-5 max-h-30px"
          onClick={() => dispatch(actions.generateReport())}
        >
          Generate
        </Button>
      </Grid>
    </Grid>
  )
};

const TolunaNotificationsLog = () => {
  const dispatch = useDispatch();
  const useCreatedDate = useSelector(state => state.tolunaNotificationsLog.useCreatedDate);
  const createdStartDate = useSelector(state => state.tolunaNotificationsLog.createdStartDate);
  const createdEndDate = useSelector(state => state.tolunaNotificationsLog.createdEndDate);

  useEffect(() => {
    dispatch(actions.generateReport());
  }, [dispatch]);

  const handleChangeUseCreatedDate = () => {
    dispatch(actions.setUseCreatedDate(!useCreatedDate));
  };

  const handleChangeDate = (key) => (v) => {
    if (key === 'createdStart') {
      dispatch(actions.setCreatedStartDate(v));
    } else {
      dispatch(actions.setCreatedEndDate(v));
    }
  }

  return (
    <>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded">
          <div className="d-flex justify-content-between flex-column align-items-start mb-3">
            <h3>Toluna Notifications Log</h3>
            <DateSelectors
              switchName="created-date"
              onChangeSwitch={handleChangeUseCreatedDate}
              switchValue={useCreatedDate}
              startDate={createdStartDate}
              endDate={createdEndDate}
              onChangeStartDate={handleChangeDate('createdStart')}
              onChangeEndDate={handleChangeDate('createdEnd')}
              dispatch={dispatch}
            />
          </div>
        </CardContent>
      </Card>
      <TolunaNotificationsLogReport />
    </>
  )
};

export default TolunaNotificationsLog;

