import { call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/Translations/components/Translators/api';
import { actions, actionTypes } from '~/app/modules/Translations/components/Translators/actions';

function* getTranslators() {
  try {
    const results = yield call(api.getTranslators);
    yield put(actions.getTranslatorsSuccess(results));
  } catch (error) {
    yield put(actions.getTranslatorsFailure(error.message));
  }
}

function* createTranslators(action) {
  try {
    const results = yield call(api.createTranslator, action.payload);
    yield put(actions.createTranslatorSuccess(results));
    yield put(actions.getTranslators());
  } catch (error) {
    yield put(actions.createTranslatorFailure(error.message));
  }
}

function* updateTranslators(action) {
  try {
    const results = yield call(api.updateTranslator, action.payload);
    yield put(actions.updateTranslatorSuccess(results));
    yield put(actions.getTranslators());
  } catch (error) {
    yield put(actions.updateTranslatorFailure(error.message));
  }
}

function* getAvailableParams() {
  try {
    const results = yield call(api.getAvailableParams);
    yield put(actions.getAvailableParamsSuccess(results));
  } catch (error) {
    yield put(actions.getAvailableParamsFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.GetTranslators, getTranslators),
  takeLatest(actionTypes.CreateTranslator, createTranslators),
  takeLatest(actionTypes.UpdateTranslator, updateTranslators),
  takeLatest(actionTypes.GetAvailableParams, getAvailableParams),
];