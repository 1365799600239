import config from '~/config';
import fetch from '~/redux/fetch';

const GET_SHARED_LINK_URL = (linkId) => (`/shared-link/${linkId}`);

const getSharedParams = async (linkId) => {
  const response = await fetch(`${config.apiBaseUrl}${GET_SHARED_LINK_URL(linkId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

export default {
  getSharedParams,
};

