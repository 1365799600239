export const actionTypes = {
  GetKeys: 'keys/get-keys',
  GetKeysSuccess: 'keys/get-keys-success',
  GetKeysFailure: 'keys/get-keys-failure',
  GetAvailableParams: 'keys/get-available-params',
  GetAvailableParamsSuccess: 'keys/get-available-params-success',
  GetAvailableParamsFailure: 'keys/get-available-params-failure',
  CreateFeature: 'keys/create-feature',
  CreateFeatureSuccess: 'keys/create-feature-success',
  CreateFeatureFailure: 'keys/create-feature-failure',
  SaveKeyScreenshot: 'keys/save-key-screenshot',
  SaveKeyScreenshotSuccess: 'keys/save-key-screenshot-success',
  SaveKeyScreenshotFailure: 'keys/save-key-screenshot-failure',
  UpdateKeyTranslation: 'keys/update-key-translation',
  UpdateKeyTranslationSuccess: 'keys/update-key-translation-success',
  UpdateKeyTranslationFailure: 'keys/update-key-translation-failure',
  FindKeys: 'keys/find-keys',
  FindKeysSuccess: 'keys/find-keys-success',
  FindKeysFailure: 'keys/find-keys-failure',
  UpdateLinkKey: 'keys/update-link-key',
  UpdateLinkKeySuccess: 'keys/update-link-key-success',
  UpdateLinkKeyFailure: 'keys/update-link-key-failure',
  CleanFoundKeys: 'keys/clean-found-keys',
};

export const actions = {
  getKeys: () => ({ type: actionTypes.GetKeys }),
  getKeysSuccess: (keys) => ({ type: actionTypes.GetKeysSuccess, payload: keys }),
  getKeysFailure: () => ({ type: actionTypes.GetKeysFailure }),
  getAvailableParams: () => ({ type: actionTypes.GetAvailableParams }),
  getAvailableParamsSuccess: (availableParams) =>
    ({ type: actionTypes.GetAvailableParamsSuccess, payload: availableParams }),
  getAvailableParamsFailure: () => ({ type: actionTypes.GetAvailableParamsFailure }),
  createFeature: (data) => ({ type: actionTypes.CreateFeature, payload: data }),
  createFeatureSuccess: (feature) => ({ type: actionTypes.CreateFeatureSuccess, payload: feature }),
  createFeatureFailure: () => ({ type: actionTypes.CreateFeatureFailure }),
  saveKeyScreenshot: (data) => ({ type: actionTypes.SaveKeyScreenshot, payload: data }),
  saveKeyScreenshotSuccess: () => ({ type: actionTypes.SaveKeyScreenshotSuccess }),
  saveKeyScreenshotFailure: () => ({ type: actionTypes.SaveKeyScreenshotFailure }),
  updateKeyTranslation: (data) => ({ type: actionTypes.UpdateKeyTranslation, payload: data }),
  updateKeyTranslationSuccess: () => ({ type: actionTypes.UpdateKeyTranslationSuccess }),
  updateKeyTranslationFailure: () => ({ type: actionTypes.UpdateKeyTranslationFailure }),
  findKeys: (data) => ({ type: actionTypes.FindKeys, payload: data }),
  findKeysSuccess: (featureLanguage) => ({ type: actionTypes.FindKeysSuccess, payload: featureLanguage }),
  findKeysFailure: () => ({ type: actionTypes.FindKeysFailure }),
  updateLinkKey: (data) => ({ type: actionTypes.UpdateLinkKey, payload: data }),
  updateLinkKeySuccess: () => ({ type: actionTypes.UpdateLinkKeySuccess }),
  updateLinkKeyFailure: () => ({ type: actionTypes.UpdateLinkKeyFailure }),
  cleanFoundKeys: () => ({ type: actionTypes.CleanFoundKeys }),
};
