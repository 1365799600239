import React from 'react';
import { useDispatch } from "react-redux";
import {useSelector} from "react-redux";
import cs from 'classnames';
import { newDateFormatLong } from "~/app/helpers";
import { TextField } from "@material-ui/core";
import { actions } from "~/app/modules/Rewards/components/RewardsManagement/actions";

const CurrentBalanceField = () => {
  const currentReward = useSelector(state => state.rewards.currentReward);

  return (
    <div className="d-flex w-700px align-items-center mb-3">
      <div className="w-200px">Current Balance</div>
      <div className={cs('p-2 rounded border bg-light-secondary w-500px', {
        'text-danger': currentReward?.currentBalance < currentReward?.threshold
      })}>
        {currentReward?.currency}{currentReward?.currentBalance}
      </div>
    </div>
  );
};

const LastBalanceCheck = () => {
  const currentReward = useSelector(state => state.rewards.currentReward);

  return (
    <div className="d-flex w-700px align-items-center mb-3">
      <div className="w-200px">Last Balance Check</div>
      <div className="p-2 rounded border bg-light-secondary w-500px">{newDateFormatLong(currentReward?.checkedAt)}</div>
    </div>
  );
};

const CurrentThreshold = () => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const dispatch = useDispatch();

  const handleChangeThreshold = (e) => {
    dispatch(actions.changeCurrentRewardFields({ threshold: Number(e.target.value) }));
  };

  return (
    <div className="d-flex w-700px align-items-center mb-3">
      <div className="w-200px">Notification Amount</div>
      <TextField
        className={cs('w-500px p-0', {
          'text-danger': currentReward?.currentBalance < currentReward?.threshold
        })}
        variant="outlined"
        onChange={handleChangeThreshold}
        value={currentReward?.threshold} />
    </div>
  );
};

const BalanceInfo = () => {
  return (
    <>
      <h4 className="mb-3">Balance Info:</h4>
      <CurrentBalanceField />
      <LastBalanceCheck />
      <CurrentThreshold />
      <div className="pb-10 mb-10 w-700px border-bottom border-secondary" />
    </>
  );
};

export default BalanceInfo;
