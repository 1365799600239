import { actionTypes } from '~/app/modules/EmailLimitsManagement/actions';

const initialState = {
  inited: false,
  isLoading: false,
  showErrorAlert: false,
  showSuccessAlert: false,
  serverError: null,
  emailLimits: null,
  currentFormKey: 'default',
  currentForm: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetFormKey:
      return {
        ...state,
        currentFormKey: action.payload,
        currentForm: {
          ...state.emailLimits['default'],
          ...state.emailLimits[action.payload]
        },
      };

    case actionTypes.FetchEmailLimits:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FetchEmailLimitsSuccess:
      return {
        ...state,
        inited: true,
        isLoading: false,
        emailLimits: action.payload,
      };

    case actionTypes.FetchEmailLimitsFailure:
      return {
        ...state,
        isLoading: false,
        showErrorAlert: true,
        serverError: action.payload,
      };

    case actionTypes.ChangeFormField:
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          ...action.payload,
        },
      };

    case actionTypes.SetCurrentAsDefault:
      return {
        ...state,
        currentForm: state.emailLimits['default'],
      };

    case actionTypes.SaveCurrentEmailLimits:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SaveEmailLimitsSuccess:
      return {
        ...state,
        isLoading: false,
        showSuccessAlert: true,
        emailLimits: action.payload,
      };

    case actionTypes.SaveEmailLimitsFailure:
      return {
        ...state,
        isLoading: false,
        showErrorAlert: true,
        serverError: action.payload,
      };

    case actionTypes.CloseErrorAlert:
      return {
        ...state,
        showErrorAlert: false,
      };

    case actionTypes.CloseSuccessAlert:
      return {
        ...state,
        showSuccessAlert: false,
      };

    default:
      return state;
  }
};

