import { actionTypes } from '~/app/modules/AtomsManagement/actions';

const initialState = {
  inited: false,
  isLoading: false,
  showErrorAlert: false,
  showSuccessAlert: false,
  serverError: null,
  atomSettings: null,
  currentFormKey: 'default',
  currentForm: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetFormKey:
      return {
        ...state,
        currentFormKey: action.payload,
        currentForm: {
          ...state.atomSettings['default'],
          ...state.atomSettings[action.payload]
        },
      };

    case actionTypes.FetchAtomSettings:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FetchAtomSettingsSuccess:
      return {
        ...state,
        inited: true,
        isLoading: false,
        atomSettings: action.payload,
      };

    case actionTypes.FetchAtomSettingsFailure:
      return {
        ...state,
        isLoading: false,
        showErrorAlert: true,
        serverError: action.payload,
      };

    case actionTypes.ChangeFormField:
      return {
        ...state,
        currentForm: {
          ...state.currentForm,
          ...action.payload,
        },
      };

    case actionTypes.SetCurrentAsDefault:
      return {
        ...state,
        currentForm: state.atomSettings['default'],
      };

    case actionTypes.SaveCurrentAtomSettings:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SaveAtomSettingsSuccess:
      return {
        ...state,
        isLoading: false,
        showSuccessAlert: true,
        atomSettings: action.payload,
      };

    case actionTypes.SaveAtomSettingsFailure:
      console.log('action', action)
      return {
        ...state,
        isLoading: false,
        showErrorAlert: true,
        serverError: action.payload,
      };

    case actionTypes.CloseErrorAlert:
      return {
        ...state,
        showErrorAlert: false,
      };

    case actionTypes.CloseSuccessAlert:
      return {
        ...state,
        showSuccessAlert: false,
      };

    default:
      return state;
  }
};
