import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { actions } from '~/app/modules/SharedView/actions';
import { actions as surveysActions } from '~/app/modules/Surveys/actions';
import { actions as clicksActions } from '~/app/modules/ClicksReport/actions';
import { actions as apiLogActions } from '~/app/modules/APILog/actions';
import { actions as reportBuilderActions } from '~/app/modules/ReportBuilder/actions';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';

const SharedView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const sharedViewParams = useSelector((state) => state.sharedView.params);
  const loaded = useSelector((state) => state.sharedView.loaded);

  useEffect(() => {
    if (params.linkId && !loaded) {
      dispatch(actions.getSharedParams(params.linkId));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(sharedViewParams).length && loaded) {
      dispatch(actions.setLoadedParams(false));
      switch (sharedViewParams.page) {
        case 'surveys':
          const surveysParams = _.cloneDeep(sharedViewParams);
          delete surveysParams.page;
          dispatch(surveysActions.setParams(surveysParams))
          history.push(`/${sharedViewParams.page}`);
          break;

        case 'clicks':
          const clicksParams = _.cloneDeep(sharedViewParams);
          delete clicksParams.page;
          dispatch(clicksActions.setParams(clicksParams))
          history.push(`/${sharedViewParams.page}`);
          break;

        case 'api-log':
          const apiLogParams = _.cloneDeep(sharedViewParams);
          delete apiLogParams.page;
          dispatch(apiLogActions.setParams(apiLogParams))
          history.push(`/${sharedViewParams.page}`);
          break;

        case 'report-builder':
          const reportBuilderParams = _.cloneDeep(sharedViewParams);
          delete reportBuilderParams.page;
          dispatch(reportBuilderActions.setParams(reportBuilderParams))
          history.push(`/${sharedViewParams.page}`);
          break;

        default:
          break;
      }
    }
  }, [sharedViewParams, dispatch, history, loaded])

  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100 flex-column">
      <CircularProgress className="splash-screen-spinner" />
    </div>
  )
};

export default SharedView;
