export const actionTypes = {
  GetTranslators: 'translators/get-translators',
  GetTranslatorsSuccess: 'translators/get-translators-success',
  GetTranslatorsFailure: 'translators/get-translators-failure',
  CreateTranslator: 'translators/create-translator',
  CreateTranslatorSuccess: 'translators/create-translator-success',
  CreateTranslatorFailure: 'translators/create-translator-failure',
  UpdateTranslator: 'translators/update-translator',
  UpdateTranslatorSuccess: 'translators/update-translator-success',
  UpdateTranslatorFailure: 'translators/update-translator-failure',
  GetAvailableParams: 'translators/get-available-params',
  GetAvailableParamsSuccess: 'translators/get-available-params-success',
  GetAvailableParamsFailure: 'translators/get-available-params-failure',
  SetFilter: 'translators/set-filter',
};

export const actions = {
  getTranslators: () => ({ type: actionTypes.GetTranslators }),
  getTranslatorsSuccess: (translator) => ({ type: actionTypes.GetTranslatorsSuccess, payload: translator }),
  getTranslatorsFailure: () => ({ type: actionTypes.GetTranslatorsFailure }),
  createTranslator: (translator) => ({ type: actionTypes.CreateTranslator, payload: translator }),
  createTranslatorSuccess: () => ({ type: actionTypes.CreateTranslatorSuccess }),
  createTranslatorFailure: () => ({ type: actionTypes.CreateTranslatorFailure }),
  updateTranslator: (translator) => ({ type: actionTypes.UpdateTranslator, payload: translator }),
  updateTranslatorSuccess: () => ({ type: actionTypes.UpdateTranslatorSuccess }),
  updateTranslatorFailure: () => ({ type: actionTypes.UpdateTranslatorFailure }),
  getAvailableParams: () => ({ type: actionTypes.GetAvailableParams }),
  getAvailableParamsSuccess: (availableParams) =>
    ({ type: actionTypes.GetAvailableParamsSuccess, payload: availableParams }),
  getAvailableParamsFailure: () => ({ type: actionTypes.GetAvailableParamsFailure }),
  setFilter: (filters) => ({ type: actionTypes.SetFilter, payload: filters }),
};
