import React from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { selectFilter, customFilter } from 'react-bootstrap-table2-filter';
import { Pagination } from '~/_metronic/_partials/controls';
import { actions } from '~/app/modules/Orders/actions';
import {sortCaret, toAbsoluteUrl} from '~/_metronic/_helpers';
import {
  newDateFormatLong,
  onTableChangeHelper,
  usePaginationFilteringAndSorting,
  textFilterOnBlurHelper,
  useDidMount, textFilterOnBlurHelperWithAutoComplete,
} from '~/app/helpers';
import {Link} from "react-router-dom";
import SVG from "react-inlinesvg";
import enums from "~/app/helpers/enums";


const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const sortableColumn = {
  sort: true, sortCaret,
};

const yesNoOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
  { value: 'all', label: 'All' },
];

const platformOptions = [
  { value: 'mobileApp', label: 'Mobile App' },
  { value: 'website', label: 'Website' },
  { value: 'all', label: 'All' },
];

const orderStatusOptions = [...Object.values(enums.orderStatus).map((status) => ({ value: status, label: status })),
  { value: 'all', label: 'All' }];

const partnerBatchIdOptions = [
  { value: 'all', label: 'All' },
  { value: 'empty', label: 'None' },
  { value: 'present', label: 'Valid ID' },
];

const securityErrorsOptions = [...Object.keys(enums.orderVerificationErrors).map((key) => ({
  value: enums.orderVerificationErrors[key], label: key,
})),  { value: 'all', label: 'All' }];

const orderTypes = [...enums.rewardsTypes.map((type) => ({ value: type, label: type })), { value: 'all', label: 'All' }];

const userIdLink = (_id) => (
  <div>
    <Link
      target="_blank"
      to={`/users/${_id}`}
    >
      {_id}
    </Link>
  </div>
);

const renderCountryField = (countryCode) => {
  if (typeof countryCode !== 'string') {
    return '';
  }
  const foundItem = enums.countriesWithCode.find((item) => item.code.toUpperCase() === countryCode);
  const countryFlag = foundItem?.name ? `/media/svg/flags/${foundItem.name.toLowerCase()}.svg` : '';
  return (
    <div className="d-flex align-items-center">
      {countryFlag && (
        <SVG
          alt="Logo"
          className="min-w-20px mr-3 max-w-20px"
          src={toAbsoluteUrl(countryFlag)}
        />
      )}
      {foundItem.name}
    </div>
  );
};

const renderSecurityErrors = (securityErrors) => {
  if (!securityErrors?.length || typeof securityErrors?.[securityErrors?.length - 1] !== "string") {
    return '';
  }

  return securityErrors.join(', ');
};

const renderRewardName = (row) => {
  if (!row?.card?.cardId) {
    return '';
  }

  return (
    <div>
      <Link
        target="_blank"
        to={`/rewards-management/${row.card._id}`}
      >
        {`${row?.card?.name} - ${row.card.cardId}`}
      </Link>
    </div>
  );
};

const renderPlatform = (value) => {
  if (value === true) {
    return 'Mobile App';
  } else if (value === false) {
    return 'Website';
  }

  return '';
}

const columns = (filters) => [{
  ...sortableColumn,
  dataField: 'createdAt', text: 'Order Date',
  formatter: (date) => (date ? newDateFormatLong(date) : ''),
  style: { width: '150px', minWidth: '150px', textAlign: 'left' },
}, {
  dataField: 'user._id', text: 'Publisher User Id',
  formatter: (_id) => userIdLink(_id),
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "150px" }, defaultValue: filters?.['user._id'] || '' }),
  style: { width: '150px', textAlign: 'left' },
}, {
  dataField: 'user.email', text: 'Email',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "150px" }}),
  style: { width: '150px', minWidth: '80px', textAlign: 'left' },
}, {
  dataField: 'user.countryISO', text: 'Country',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelperWithAutoComplete(
    onFilter,
    column,
    {style: { width: "100px" }, defaultValue: filters['user.countryISO'] || ''},
    actions,
    'orders'
  ),
  formatter: (countryISO) => renderCountryField(countryISO),
  style: { width: '100px', textAlign: 'left' },
}, {
  dataField: 'status', text: 'Order Status',
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: orderStatusOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  dataField: 'batchId', text: 'Partner Batch Id',
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: partnerBatchIdOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
},
{
  dataField: 'securityErrors', text: 'Rejected Reason',
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: securityErrorsOptions,
    withoutEmptyOption: true,
  }),
  formatter:(securityErrors) => renderSecurityErrors(securityErrors),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'card.service', text: 'Order Type',
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
    filter: selectFilter({
      defaultValue: 'all',
      placeholder: ' ',
      style: { width: '100px' },
      options: orderTypes,
      withoutEmptyOption: true,
    }),
}, {
    dataField: 'user.payPalEmail', text: 'PayPal Email',
    filter: customFilter(),
    filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
      onFilter, column, {style: { width: "120px" }}),
    style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
    dataField: 'user.coinbaseEmail', text: 'Coinbase Email',
    filter: customFilter(),
    filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
      onFilter, column, {style: { width: "120px" }}),
    style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  dataField: 'card.cardId', text: 'Reward Name',
  formatter: (_cell, row) => renderRewardName(row),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'amount', text: 'Order Amount',
  formatter: (amount) => (amount ? (Math.round(amount * 100) / 100).toFixed(2) : ''),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  dataField: 'user.registrationPubId', text: 'Registration Pub Id',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "150px" }}),
  style: { width: '120px', textAlign: 'left' },
}, {
  dataField: 'daysPassed', text: 'Days Passed',
  style: { width: '120px', textAlign: 'left' },
}, {
  dataField: 'user.fraudScore', text: 'Fraud Score',
  style: { width: '150px', textAlign: 'center' },
}, {
  dataField: 'user.isFraud', text: 'Fraud block',
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: yesNoOptions,
    withoutEmptyOption: true,
  }),
  formatter: (isFraud) => (isFraud ? 'True': 'False'),
  style: { width: '150px', textAlign: 'left' },
}, {
  dataField: 'isMobileApp', text: 'Platform',
  formatter: (isMobileApp) => renderPlatform(isMobileApp),
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: platformOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '100px', minWidth: '100px', textAlign: 'left' },
}, {
  dataField: 'userInfo.lastActivityTrackedAt', text: 'Last Seen',
  formatter: (date) => (date ? newDateFormatLong(date) : ''),
  style: { width: '150px', minWidth: '150px', textAlign: 'left' },
}
];

const OrdersReport = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'orders', actions);
  const report = useSelector(state => state.orders.data, shallowEqual);

  const didMount = useDidMount();

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: ['user._id', 'user.email', 'status', 'batchId', 'user.countryISO', 'user.registrationPubId',
      'user.isFraud', 'user.verifiedPhoneNumber', 'securityErrors', 'user.payPalEmail',
      'user.coinbaseEmail', 'card.service', 'isMobileApp'],
    didMount,
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = columns(filters);

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => (
        <Pagination isLoading={false} paginationProps={paginationProps}>
          <BootstrapTable
            remote
            classes="table table-head-custom table-vertical-center overflow-hidden"
            headerClasses="table table-vertical-top"
            bootstrap4
            keyField="_id"
            columns={reportColumns}
            data={report}
            filter={ filterFactory() }
            defaultSorted={[{
              dataField: sortBy,
              order: enums.sortDirections.desc,
            }]}
            onTableChange={onTableChange}
            {...paginationTableProps}
          >
          </BootstrapTable>
        </Pagination>
      )}
    </PaginationProvider>
  );
};

export default OrdersReport;
