import { select, call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/ClicksReport/api';
import { actions, actionTypes } from '~/app/modules/ClicksReport/actions';

const addFilterDates = (data, state) => {
  const dataWithFilterDates = { ...data };
  if (state.clicksReport.useClickDate) {
    dataWithFilterDates.filterDate = {
      clickStart: state.clicksReport.clickStartDate,
      clickEnd: state.clicksReport.clickEndDate,
    }
  }
  if (state.clicksReport.useLeadDate) {
    if (data.filterDate) {
      dataWithFilterDates.filterDate = {
        ...dataWithFilterDates.filterDate,
        leadStart: state.clicksReport.leadStartDate,
        leadEnd: state.clicksReport.leadEndDate,
      }
    } else {
      dataWithFilterDates.filterDate = {
        leadStart: state.clicksReport.leadStartDate,
        leadEnd: state.clicksReport.leadEndDate,
      }
    }
  }
  return dataWithFilterDates;
}

const selectForFetchReport = (state) => {
  const data = {
    filters: state.clicksReport.filters,
    pageNumber: state.clicksReport.pageNumber,
    pageSize: state.clicksReport.pageSize,
    sortBy: state.clicksReport.sortBy,
    sortDirection: state.clicksReport.sortDirection,
  };

  return addFilterDates(data, state);
};

function* requestReport() {
  try {
    const reportParams = yield select(selectForFetchReport);
    const { results } = yield call(api.fetchReport, reportParams);
    yield put(actions.fetchReportSuccess(results));
  } catch (error) {
    yield put(actions.fetchReportFailure(error.message));
  }
}

const selectForFetchReportAsCsv = (state) => {
  const data = {
    filters: state.clicksReport.filters,
  };

  return addFilterDates(data, state);
};

function* requestReportAsCsv() {
  try {
    const reportParams = yield select(selectForFetchReportAsCsv);
    yield call(api.fetchReportAsCsv, reportParams);
    yield put(actions.generateReportAsCsvSuccess());
  } catch (error) {
    console.error(error);
  }
}

function* createSharedLink(action) {
  try {
    const results = yield call(api.createSharedLink, action.payload);
    yield put(actions.createSharedLinkSuccess(results));
  } catch (error) {
    console.error(error);
    yield put(actions.createSharedLinkFailed(error));
  }
}

export default [
  takeLatest([
    actionTypes.GenerateReport,
    actionTypes.UpdateReport,
  ], requestReport),
  takeLatest(actionTypes.GenerateReportAsCsv, requestReportAsCsv),
  takeLatest(actionTypes.CreateSharedLink, createSharedLink),
];

