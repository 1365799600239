import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent } from "@material-ui/core";
import cs from "classnames";
import { useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import { actions } from "~/app/modules/Survey/actions";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import _ from 'lodash';
import TopInformationSection from "./TopInformationSection";
import {Modal} from "react-bootstrap";
import {CloseOutlined} from "@material-ui/icons";
import {format} from "date-fns-tz";
import { nth, renderMappedField, renderPreCodes, renderAnswerOptions } from '~/app/helpers';

const TopInfoBlock = () => {
  const surveyInformation = useSelector((state) => state.survey.surveyInformation);

  return (
    <Card className="mb-3">
      <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center">
        <TopInformationSection surveyInformation={surveyInformation} alignChildren="start">
          <Button
            color="secondary"
            variant="contained"
            className="max-h-30px min-w-110px"
            onClick={() => {}}
          >
            History Log
          </Button>
        </TopInformationSection>
      </CardContent>
    </Card>
  );
};

const renderQuotaLogStatusField = (status) => {
  return (
    <div className={cs('px-2 py-2 rounded d-flex justify-content-center align-items-center', {
      'text-success bg-light-success': status === 'success',
      'text-danger bg-light-anger': status === 'failed',
    })}>
      {_.capitalize(status)}
    </div>
  )
}

const newDateFormatLong = (date, timezone) => {
  if(!date) {
    return '-'
  }
  const dateInPSTFormat = new Date(date).toLocaleString("en-US", {timeZone: 'America/Los_Angeles'});
  const newDate = new Date(dateInPSTFormat);
  const newSplitDate  = format(newDate, "MMMM dd, yyyy, HH:mm:ss aaaaa'm'").split(',');
  const day = newSplitDate[0].split(' ')[1]
  return <div>{newSplitDate[0]}<sup>{nth(Number(day))}</sup>{`${newSplitDate[1]}, ${newSplitDate[2]}${timezone || ''}`}</div>;
}

const columns = () => [{
  dataField: 'quotaId', text: 'Quota ID',
  style: { width: '150px', textAlign: 'left' },
}, {
  dataField: 'numberOfRespondents', text: 'Quota Amount',
  style: { width: '150px', textAlign: 'left' },
},{
  dataField: 'questionId', text: 'Internal Question ID',
  style: { width: '150px', textAlign: 'left' },
}, {
  dataField: 'providerQuestionId', text: 'Provider Question ID',
  style: { width: '150px', textAlign: 'left' },
}, {
  dataField: 'mapped', text: 'Mapped',
  style: { width: '100px', textAlign: 'left' },
  formatter: (mapped) => renderMappedField(mapped),
}, {
  dataField: 'baseQuestionId', text: 'Mapped Question ID',
  style: { width: '100px', textAlign: 'left' },
}, {
  dataField: 'name', text: 'Question Name',
  style: { width: '200px', textAlign: 'left' },
}, {
  dataField: 'text', text: 'Question Text',
  style: { width: '200px', textAlign: 'left' },
}, {
  dataField: 'precodes', text: 'Approved Answer ID',
  style: { width: '100px', textAlign: 'left' },
  formatter: (_cell, row) => renderPreCodes(row?.precodes, row?.options),
}, {
  dataField: 'options', text: 'Approved Answers Text',
  style: { width: '400px', textAlign: 'left' },
  formatter: (_cell, row) => renderAnswerOptions(row?.precodes, row?.options),
}];

const QuotasTable = () => {
  const quotas = useSelector((state) => state.survey.quotas);

  const quotasTableColumns = columns();

  return (
    <Card className="mb-3">
      <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center">
        <BootstrapTable
          remote
          classes="table table-head-custom table-vertical-center overflow-hidden"
          headerClasses="table table-vertical-top"
          bootstrap4
          responsive
          keyField="idForTable"
          columns={quotasTableColumns}
          data={quotas}
          filter={ filterFactory() }
        >
        </BootstrapTable>
      </CardContent>
    </Card>
  );
};

const renderQuotaLogChanges = (changes) => {
  if (['Quota closed', 'Quota deleted'].includes(changes)) {
    return <div className="text-danger">{changes}</div>
  }
  return <div>{changes}</div>
}

const renderFullDetailsButton = (logs, setDetails, setShow, setSelectedDate) => {
  if (!logs?.quotas?.length) {
    return <div />;
  }

  const handleOnClick = () => {
    setDetails(logs.quotas);
    setSelectedDate(logs.logDate);
    setShow(true);
  }

  return (
    <button
      type="button"
      className="btn btn-light-primary btn-sm bg-light-info text-info"
      onClick={handleOnClick}
    >
      Full Details
    </button>
  );
}

const logColumns = (setSelectedQuotas, setShown, setSelectedQuotaDate) => [{
  dataField: 'logDate', text: 'Date And Time',
  style: { width: '100px', textAlign: 'center' },
  formatter: (logDate) => newDateFormatLong(logDate,' (PST)'),
}, {
  dataField: 'status', text: 'Status',
  style: { width: '100px', textAlign: 'left' },
  formatter: (mapped) => renderQuotaLogStatusField(mapped),
},{
  dataField: 'errorMessage', text: 'Error Message',
  style: { width: '150px', textAlign: 'left' },
}, {
  dataField: 'quotaId', text: 'Change Quota ID',
  style: { width: '150px', textAlign: 'left' },
}, {
  dataField: 'quotaChangeAmount', text: 'Change Quota Amount',
  style: { width: '100px', textAlign: 'left' },
}, {
  dataField: 'quotaChange', text: 'Changes',
  style: { width: '100px', textAlign: 'left' },
  formatter: (quotaChange) => renderQuotaLogChanges(quotaChange),
}, {
  dataField: 'name', text: 'Quota Details',
  style: { width: '100px', textAlign: 'left' },
  formatter: (_cell, row) => renderFullDetailsButton(row, setSelectedQuotas, setShown, setSelectedQuotaDate),
}];

const convertQuotasLogs = (quotasLogs) => {
  if (!quotasLogs?.length) {
    return [];
  }
  const data = [];
  let mainElementWithLogDateForQuotaGroup = -1;
  quotasLogs.forEach((log, logIndex) => {
    if (log?.quotas?.length) {
      log.quotas.forEach((quota, index) => {
        const quotaLog = {...quota};
        let quotaChange = '';
        let quotaChangeAmount = '';
        if (logIndex) {
          const prevQuotaAmount = quotasLogs[logIndex - 1]?.quotas?.[index]?.numberOfRespondents;
          if (!(quotaLog?.numberOfRespondents && prevQuotaAmount && quotaLog?.numberOfRespondents === prevQuotaAmount)) {
            quotaChangeAmount = `${prevQuotaAmount} ➝ ${quotaLog?.numberOfRespondents}`;
            if (quotaLog?.numberOfRespondents) {
              if (quotaLog?.numberOfRespondents < prevQuotaAmount) {
                quotaChange = 'Decreased quota';
              } else {
                quotaChange = 'Increased quota';
              }
            } else if (quotaLog?.numberOfRespondents === 0 && prevQuotaAmount) {
              quotaChange = 'Quota closed';
            } else if (!quotaLog?.numberOfRespondents && prevQuotaAmount) {
              quotaChange = 'Quota deleted'
            }
          }
        }
        quotaLog.quotaChangeAmount = quotaChangeAmount;
        quotaLog.quotaChange = quotaChange;
        quotaLog.id = `${logIndex}_${index}`;
        quotaLog.status = 'success';
        if (quotaChange) {
          if (mainElementWithLogDateForQuotaGroup !== logIndex) {
            mainElementWithLogDateForQuotaGroup = logIndex;
            quotaLog.quotas = log.quotas;
            quotaLog.logDate = log?.logDate;
          }
          data.push(quotaLog);
        }
      })
    }
  });

  return data;
}

const renderUpdatedQuotaAmount = (quota) => {
  return (
    <div className={cs({ 'text-primary': quota?.prevQuota !== quota?.numberOfRespondents })}>
      {quota?.numberOfRespondents}
    </div>
  );
};

const modalColumns = () => ([
{
  dataField: 'quotaId', text: 'Quota ID',
  style: { width: '250px', textAlign: 'left' },
},{
  dataField: 'prevQuota', text: 'Previous Quota Amount',
  style: { width: '100px', textAlign: 'left' },
},{
  dataField: 'numberOfRespondents', text: 'Updated Quota Amount',
  style: { width: '100px', textAlign: 'left' },
  formatter: (_cell, row) => renderUpdatedQuotaAmount(row),
}])

const QuotaLogModal = ({ shown, setShown, selectedDate, selectedQuota, logs }) => {
  const currentIndexInLogs = _.findIndex(logs, (log) => (log.logDate === selectedDate));
  let prevQuotas = selectedQuota;
  if (currentIndexInLogs) {
    prevQuotas = logs?.[currentIndexInLogs - 1]?.quotas;
  }
  const quotaLog = selectedQuota.map((quota, index) => ({
    ...quota,
    prevQuota: prevQuotas?.[index]?.numberOfRespondents,
  }));
  const QuotaLogColumns = modalColumns();
  return (
    <>
      <Modal
        scrollable
        centered
        size="lg"
        show={shown}
        onHide={() => setShown(false)}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            Quota Full Details
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={() => setShown(false)}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body>
          <div className="my-3 mx-2">
            {newDateFormatLong(selectedDate,' (PST)')}
            <BootstrapTable
              classes="table table-head-custom table-vertical-center overflow-hidden"
              headerClasses="table table-vertical-top"
              bootstrap4
              responsive
              keyField="quotaId"
              columns={QuotaLogColumns}
              data={quotaLog}
            />
          </div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
}

const QuotasHistoryLog = () => {
  const surveyInformation = useSelector((state) => state.survey.surveyInformation);
  const [shown, setShown] = useState(false);
  const [selectedQuotas, setSelectedQuotas] = useState([]);
  const [selectedQuotaDate, setSelectedQuotaDate] = useState('');

  const quotasLogs = convertQuotasLogs(surveyInformation.quotaLogs);

  const quotasTableColumns = logColumns(setSelectedQuotas, setShown, setSelectedQuotaDate);

  return (
    <Card className="mb-3">
      <CardContent className="border text-dark border-light-dark rounded d-flex flex-column align-item-center">
        <h3>Quotas History Log</h3>
        <BootstrapTable
          remote
          classes="table table-head-custom table-vertical-center overflow-hidden"
          headerClasses="table table-vertical-top"
          bootstrap4
          responsive
          keyField="id"
          columns={quotasTableColumns}
          data={quotasLogs}
          filter={ filterFactory() }
        >
        </BootstrapTable>
        <QuotaLogModal
          setShown={setShown}
          shown={shown}
          selectedDate={selectedQuotaDate}
          selectedQuota={selectedQuotas}
          logs={surveyInformation.quotaLogs}
        />
      </CardContent>
    </Card>
  );
};

const Quotas = () => {
  const surveyInformation = useSelector((state) => state.survey.surveyInformation);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(actions.getSurvey(params.surveyId));
    dispatch(actions.getSurveyQuotas(params.surveyId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  if (!surveyInformation._id) {
    return null;
  }

  return (
    <div>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center">
          <h2 className="d-flex align-items-center">
            Survey Data
            <div className="ml-4 mb-0 h5 text-muted">Quotas</div>
          </h2>
        </CardContent>
      </Card>
      <TopInfoBlock />
      <QuotasTable />
      <QuotasHistoryLog />
    </div>
  );
};

export default Quotas;
