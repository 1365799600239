import { select, call, put, takeLatest } from "redux-saga/effects";

import api from '~/app/modules/EmailLimitsManagement/api';
import { actions, actionTypes } from '~/app/modules/EmailLimitsManagement/actions';

function* fetchEmailLimits() {
  try {
    const results = yield call(api.fetchEmailLimits);
    yield put(actions.fetchEmailLimitsSuccess(results));
  } catch (error) {
    yield put(actions.fetchEmailLimitsFailure(error.message));
  }
}

function* saveEmailLimits() {
  try {
    const defaultLimits = yield select((state) => state.emailLimitsManagement.emailLimits['default']);
    const currentForm = yield select((state) => state.emailLimitsManagement.currentForm);
    const key = yield select((state) => state.emailLimitsManagement.currentFormKey);

    let limitsToSend;
    if (key === 'default') {
      limitsToSend = currentForm;
    } else {
      limitsToSend = {};
      Object.keys(defaultLimits).forEach((name) => {
        if (currentForm[name] !== defaultLimits[name]) {
          limitsToSend[name] = currentForm[name];
        }
      });
    }

    const params = {
      key,
      limits: limitsToSend,
    }

    const results = yield call(api.updateEmailLimits, params);
    yield put(actions.saveEmailLimitsSuccess(results));
  } catch (error) {
    yield put(actions.saveEmailLimitsFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.FetchEmailLimits, fetchEmailLimits),
  takeLatest(actionTypes.SaveCurrentEmailLimits, saveEmailLimits),
];


