import { select, call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/Users/api';
import { actions, actionTypes } from '~/app/modules/Users/actions';

const selectForFetchReport = (state) => ({
  filters: state.users.filters,
  pageNumber: state.users.pageNumber,
  pageSize: state.users.pageSize,
  sortBy: state.users.sortBy,
  sortDirection: state.users.sortDirection,
  createdStartDate: state.users.createdStartDate,
  createdEndDate: state.users.createdEndDate,
  useCreatedDate: state.users.useCreatedDate,
});

function* requestReport() {
  try {
    const reportParams = yield select(selectForFetchReport);
    const results = yield call(api.fetchReport, reportParams);
    yield put(actions.fetchReportSuccess(results));
  } catch (error) {
    yield put(actions.fetchReportFailure(error.message));
  }
}

const selectForFetchReportAsCsv = (state) => ({
  filters: state.users.filters,
  createdStartDate: state.users.createdStartDate,
  createdEndDate: state.users.createdEndDate,
  useCreatedDate: state.users.useCreatedDate,
});

function* requestReportAsCsv() {
  try {
    const reportParams = yield select(selectForFetchReportAsCsv);
    yield call(api.fetchReportAsCsv, reportParams);
  } catch (error) {
    console.error(error);
  }
}

export default [
  takeLatest([
    actionTypes.GenerateReport,
    actionTypes.UpdateReport,
  ], requestReport),
  takeLatest(actionTypes.GenerateReportAsCsv, requestReportAsCsv),
];

