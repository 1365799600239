import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "~/_metronic/layout";
import Translations from "~/app/modules/Translations/Translations";
import Languages from "~/app/modules/Translations/components/Languages/Languages";
import Translators from "~/app/modules/Translations/components/Translators/Translators";
import TranslatorPayments from "~/app/modules/Translations/components/TranslatorPayments";
import Products from "~/app/modules/Translations/components/Products/Products";
import Features from "~/app/modules/Translations/components/Features/Features";
import Feature from "~/app/modules/Translations/components/Feature/Feature";
import Keys from "~/app/modules/Translations/components/Keys/Keys";

export default function TranslationsPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/translations" />
        <ContentRoute exact path='/translations' component={Translations} />
        <ContentRoute exact path='/translations/languages' component={Languages} />
        <ContentRoute exact path='/translations/translators' component={Translators} />
        <ContentRoute exact path='/translations/translator-payments' component={TranslatorPayments} />
        <ContentRoute exact path='/translations/products' component={Products} />
        <ContentRoute exact path='/translations/features' component={Features} />
        <ContentRoute exact path='/translations/features/:featureId' component={Feature} />
        <ContentRoute exact path='/translations/keys' component={Keys} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
