import React, {useEffect, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {customFilter, selectFilter} from 'react-bootstrap-table2-filter';
import { actions } from '~/app/modules/Translations/components/Translators/actions';
import { sortCaret } from '~/_metronic/_helpers';
import { injectIntl } from "react-intl";
import {
  textFilterOnBlurHelper,
} from '~/app/helpers';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ProgressBar from "../CustomProgressBar";
import {
  Button,
  Card,
  CardContent,
  MenuItem,
  Select as MUISelect,
  FormControl,
  Chip,
} from "@material-ui/core";
import StatusField from "../StatusField";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "~/_metronic/_helpers";
import {Modal} from "react-bootstrap";
import {Cancel, CloseOutlined} from "@material-ui/icons";
import {useFormik} from "formik";
import * as Yup from "yup";
import { Input, Select } from "~/_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core/styles";
import { sizePerPageRenderer } from '~/app/helpers';
import enums from "~/app/helpers/enums";

const useStyles = makeStyles((theme) => ({
  chipListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const sortableColumn = {
  sort: true, sortCaret,
};

const renderLanguagesField = (languages) => {
  if (!languages?.length) {
    return <div />;
  }

  return (
    <div className="d-flex flex-wrap">
      {languages.map((language, index) => (
        <span
          key={language}
        >
          {language}{index + 1 < languages.length && ', '}
        </span>
      ))}
    </div>
  )
};

const renderNameField = (row) => {
  if (row.isExpand) {
    return (
      <div className="d-flex">
        <span className="text-primary min-w-15px">▼</span>
        {row.fullName}
      </div>
    );
  }

  return (
    <div className="d-flex">
      <span className="text-primary min-w-15px">▶</span>
      {row.fullName}
    </div>
  );
};

const roleOptions = [ ...Object.values(enums.translatorRoles).map((name) => ({
  value: name, label: name,
})), { value: '', label: 'All'}];

const statusOptions = [
  { value: '', label: 'All' },
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' }
];

const renderEditButton = (selectedRow, setSelectedRow, openPopup) => {
  const handleClick = (e) => {
    e.stopPropagation();
    setSelectedRow(selectedRow);
    openPopup(true);
  }
  return (
    <span onClick={handleClick} className="svg-icon svg-icon-primary h-20">
      <SVG
        src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
      />
    </span>
  );
};

const columns = (filters, setSelectedRow, setShown) => [{
  ...sortableColumn,
  dataField: 'fullName', text: 'Name',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }, defaultValue: filters.name || '' }),
  formatter: (_cell, row) => renderNameField(row),
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'email', text: 'Email Address',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" } }),
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
}, {
  dataField: 'password', text: 'Password',
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'role', text: 'Role',
  filter: selectFilter({
    defaultValue: '',
    placeholder: ' ',
    style: { width: '100px' },
    options: roleOptions,
  }),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  dataField: 'languages', text: 'Languages',
  formatter: (languages) => renderLanguagesField(languages),
  style: { width: '200px', minWidth: '200px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'featuresCount', text: 'Features',
  style: { width: '100px', minWidth: '100px', textAlign: 'left' },
}, {
  dataField: 'progress', text: 'Overall Progress',
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
  formatter: (progress, row) => (<ProgressBar percent={row.progress} approvedPercent={row.approvedProgress} />),
}, {
  ...sortableColumn,
  dataField: 'status', text: 'Status',
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
  filter: selectFilter({
    defaultValue: '',
    placeholder: ' ',
    style: { width: '100px' },
    options: statusOptions,
    withoutEmptyOption: true,
  }),
  formatter: (status) => (<StatusField status={status} />)
}, {
  dataField: 'rate', text: 'Rate',
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
  formatter: (rate) => (rate ? `$${rate}` : ''),
}, {
  dataField: 'actions', text: 'Actions',
  style: { width: '120px', textAlign: 'left' },
  formatter: (_cell, row) => renderEditButton(row, setSelectedRow, setShown),
}];

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  role: '',
  languages: [],
  rate: '',
  status: '',
};

const selectMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

const CreateAndEditTranslatorModal = ({ selectedRow, shown, setShown, intl }) => {
  const dispatch = useDispatch();
  const languages = useSelector(state => state.translators.languages);
  const TranslatorSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Minimum 3 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastName: Yup.string()
      .min(2, "Minimum 3 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    slackHandleId: Yup.string(),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    role: Yup.string()
      .oneOf(Object.values(enums.translatorRoles))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    languages: Yup.array()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    rate: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    status: Yup.string()
      .oneOf(['Active', 'Inactive'])
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const onSubmit = (values) => {
    if(selectedRow?._id) {
      console.log('values', values);
      const translatorLanguages = values.languages.map((language) => {
        const currentLanguage = languages.find((lang) => (lang.name === language || lang.englishTitle === language));
        if (currentLanguage) {
          return currentLanguage._id;
        } else {
          return language;
        }
      })
      dispatch(actions.updateTranslator({ ...values, languages: translatorLanguages, translatorId: selectedRow._id }))
    } else {
      dispatch(actions.createTranslator({ ...values }));
    }
  }

  const formik = useFormik({
    initialValues: selectedRow?._id ? selectedRow : initialValues,
    validationSchema: TranslatorSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        onSubmit(values);
        setShown(false);
        formik.resetForm(initialValues);
      } catch (error) {
        setSubmitting(false);
        setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_LOGIN",
          })
        );
      }
    }
  });

  useEffect(() => {
    if (shown) {
      formik.resetForm();
    }
  }, [shown]); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  const handleChangeLanguages = (key) => {
    if(typeof key === 'string') {
      const newLanguages = formik.values.languages
        .filter((language) => language !== key);
      formik.setFieldValue('languages', newLanguages, true);
    } else {
      if (!key?.length) {
        formik.setFieldValue('languages', [], true);
      } else {
        formik.setFieldValue('languages', key, true);
      }
    }
  }

  const handleCloseModal = () => {
    setShown(false);
    formik.resetForm(initialValues);
  }

  return (
    <>
      <Modal
        scrollable
        centered
        size="lg"
        show={shown}
        onHide={handleCloseModal}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            {Object.keys(selectedRow).length ? 'Edit Translator' : 'Add Translator'}
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={handleCloseModal}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body className="px-0">
          <form
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            className="form d-flex my-2 mx-2 flex-column m-0"
          >
            <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
              <div className="w-150px min-w-150px mr-5">First Name</div>
              <Input
                withFeedbackLabel={false}
                type="text"
                name="firstName"
                placeholder="Enter first name"
                form={formik}
                field={formik?.getFieldProps('firstName')}
                {...formik?.getFieldProps("firstName")}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
              <div className="w-150px min-w-150px mr-5">Last Name</div>
              <Input
                withFeedbackLabel={false}
                type="text"
                name="lastName"
                placeholder="Enter last name"
                form={formik}
                field={formik?.getFieldProps('lastName')}
                {...formik?.getFieldProps("lastName")}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
              <div className="w-150px min-w-150px mr-5">Slack Handle ID</div>
              <Input
                withFeedbackLabel={false}
                type="text"
                name="slackHandleId"
                placeholder="Enter Slack handle id"
                form={formik}
                field={formik?.getFieldProps('slackHandleId')}
                {...formik?.getFieldProps("slackHandleId")}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
              <div className="w-150px min-w-150px mr-5">Email Address</div>
              <Input
                withFeedbackLabel={false}
                type="email"
                name="email"
                placeholder="Enter email address"
                form={formik}
                field={formik?.getFieldProps('email')}
                {...formik?.getFieldProps("email")}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
              <div className="w-150px min-w-150px mr-5">Password</div>
              <Input
                withFeedbackLabel={false}
                type="text"
                name="password"
                placeholder="Enter password"
                form={formik}
                field={formik?.getFieldProps('password')}
                {...formik?.getFieldProps("password")}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
              <div className="w-150px min-w-150px mr-5">Role</div>
              <Select
                placeholder="Choose role"
                name="role"
                className="bg-white border-secondary"
                variant="outlined"
                withFeedbackLabel={false}
                form={formik}
                field={formik?.getFieldProps('role')}
                {...formik?.getFieldProps("role")}
              >
                <option value="" />
                <option value="Translator">Translator</option>
                <option value="Proofreader">Proofreader</option>
              </Select>
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
              <div className="w-150px min-w-150px mr-5">Languages</div>
              <FormControl margin="normal" className="my-0">
                <MUISelect
                  id="languages"
                  multiple
                  className="bg-white border-secondary"
                  classes={{ root: 'p-0 min-h-40px' }}
                  variant="outlined"
                  MenuProps={selectMenuProps}
                  value={formik.values.languages}
                  onChange={(e) => handleChangeLanguages(e.target.value)}
                  renderValue={(values, index) => (
                    <div key={index} className={classes.chipListContainer}>
                      {values.map((value) => {
                        const language = languages.find((language) => language._id === value);
                        return (
                          <Chip
                            key={value}
                            label={language?.name || value}
                            size="medium"
                            className={`${classes.chip} bg-primary text-white`}
                            onDelete={() => handleChangeLanguages(value)}
                            deleteIcon={
                              <Cancel onMouseDown={event => event.stopPropagation()}/>
                            }
                          />
                        )
                      })}
                    </div>
                  )}
                >
                  {languages.map((language, index) => (
                    <MenuItem key={index} value={language._id}>{language.name}</MenuItem>
                  ))}
                </MUISelect>
              </FormControl>
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
              <div className="w-150px min-w-150px mr-5">Rate</div>
              <Input
                withFeedbackLabel={false}
                type="text"
                name="rate"
                placeholder="Enter rate"
                form={formik}
                field={formik?.getFieldProps('rate')}
                {...formik?.getFieldProps("rate")}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
              <div className="w-150px min-w-150px mr-5">Status</div>
              <Select
                placeholder="Choose status"
                withFeedbackLabel={false}
                name="status"
                className="bg-white border-secondary"
                form={formik}
                field={formik?.getFieldProps('status')}
                {...formik?.getFieldProps("status")}
              >
                <option value="" />
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Select>
            </div>
            <div className="d-flex justify-content-end w-100 border-top pt-3">
              <Button
                color="default"
                variant="contained"
                className="mr-3 bg-light-primary text-primary"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className="bg-primary text-white mr-5"
              >
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

const Translators = ({ intl }) => {
  const dispatch = useDispatch();
  const translators = useSelector(state => state.translators.translators, shallowEqual);
  const filters = useSelector(state => state.translators.filters);
  const [dataWithExpanded, setDataWithExpanded] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [shown, setShown] = useState(false);
  const languages = useSelector(state => state.translators.languages);

  useEffect(() => {
    dispatch(actions.getTranslators());
    dispatch(actions.getAvailableParams());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDataWithExpanded(translators);
  }, [setDataWithExpanded, translators]);

  const handleOnExpand = (row, isExpand) => {
    if (!row.isExpand) {
      const newData = dataWithExpanded.map((dataRow) => {
        if (row._id === dataRow._id) {
          return {...dataRow, isExpand};
        }
        return dataRow;
      })
      setDataWithExpanded(newData);
      setExpanded([...expanded, row._id]);
    } else {
      const newData = dataWithExpanded.map((dataRow) => {
        if (row._id === dataRow._id && row.isExpand) {
          const newDataRow = {...dataRow};
          delete newDataRow.isExpand;
          return newDataRow;
        }
        return dataRow;
      })
      setDataWithExpanded(newData);
      setExpanded(expanded.filter((_id) => (row._id !== _id)));
    }
  }

  const expandRow = {
    renderer: row => {
      return (
        <div>
          <div className="d-flex">
            <h4 className="min-w-180px">Feature</h4>
            <h4 className="ml-3 min-w-180px">Language</h4>
            <h4 className="ml-3 min-w-180px">Progress</h4>
            <h4 className="ml-20 min-w-180px">Status</h4>
          </div>
          {row?.featureLanguages?.map((featureLanguage) => (
            <div key={featureLanguage._id} className="min-h-40px border-top d-flex w-100">
              <div className="min-w-180px w-180px h-40px text-muted d-flex align-items-center">
                {featureLanguage.featureName}
              </div>
              <div className="ml-3 min-w-180px w-180px h-40px d-flex align-items-center">
                {featureLanguage.language}
              </div>
              <div className="ml-3 min-w-180px w-180px h-40px d-flex align-items-center">
                <ProgressBar
                  key={featureLanguage._id}
                  percent={featureLanguage?.progress}
                  approvedPercent={featureLanguage?.approvedProgress}
                />
              </div>
              <div className="ml-20 min-w-180px w-180px h-40px d-flex align-items-center">
                <StatusField status={featureLanguage.status} />
              </div>
            </div>
          ))}
        </div>
      );
    },
    expanded,
    onExpand: handleOnExpand,
  };

  const rowStyle = (row) => {
    if (row.isExpand) {
      return { cursor: 'pointer', background: '#f1f1f1' };
    }
    return { cursor: 'pointer' };
  };

  const reportColumns = columns(filters, setSelectedRow, setShown);
  const handleAddTranslator = () => {
    if (selectedRow._id) {
      setSelectedRow({});
    }
    setShown(true);
  }


  return (
    <div>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center min-h-40px">
          <h3 className="pl-2">Translators</h3>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-center flex-column">
        <Card>
          <CardContent className="border text-dark border-light-dark rounded">
            <div className="w-100 d-flex justify-content-end">
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddTranslator}
                className="bg-primary text-white"
              >
                Add Translator
              </Button>
            </div>
            <BootstrapTable
              classes="table table-head-custom table-vertical-center overflow-hidden"
              headerClasses="table table-vertical-top"
              wrapperClasses="rounded my-2"
              bootstrap4
              responsive
              keyField="_id"
              columns={reportColumns}
              data={dataWithExpanded}
              filter={ filterFactory() }
              defaultSorted={[{
                dataField: 'language',
                order: enums.sortDirections.desc,
              }]}
              rowStyle={ rowStyle }
              expandRow={ expandRow }
              pagination={paginationFactory({
                sizePerPage: 10,
                sizePerPageRenderer,
                sizePerPageList:[
                  { text: '10', value: 10,},
                  { text: '20', value: 20, },
                  { text: '50', value: 50, },
                  { text: '100', value: 100, },
                  { text: '500', value: 500, }
                ],
              })}
            />
          </CardContent>
        </Card>
      </div>
      {languages?.length && (<CreateAndEditTranslatorModal
        setShown={setShown}
        shown={shown}
        selectedRow={selectedRow}
        intl={intl}
      />)}
    </div>
  );
};

export default injectIntl(Translators);
