import { call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/PPChecksRewardsBalancePopup/api';
import { actions, actionTypes } from '~/app/modules/PPChecksRewardsBalancePopup/actions';

function* fetchPaypalStats() {
  try {
    const results = yield call(api.fetchPaypalStats);
    yield put(actions.fetchPaypalStatsSuccess(results));
  } catch (error) {
    yield put(actions.fetchPaypalStatsFailure(error.message));
  }
}


function* fetchRewardsProvidersBalanceSettings() {
  try {
    const results = yield call(api.fetchRewardsProvidersBalanceSettings);
    yield put(actions.fetchRewardsProvidersBalanceSettingsSuccess(results));
  } catch (error) {
    yield put(actions.fetchRewardsProvidersBalanceSettingsFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.FetchPaypalStats, fetchPaypalStats),
  takeLatest(actionTypes.FetchRewardsProvidersBalanceSettings, fetchRewardsProvidersBalanceSettings),
];
