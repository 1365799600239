import React from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import cs from 'classnames';

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control", "form-control-solid"];
  if (touched && errors) {
    classes.push("is-invalid-select");
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid-select");
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Select({
  label,
  withFeedbackLabel = true,
  type = "text",
  form: { touched, errors },
  customFeedbackLabel,
  children,
  field,
  className,
  ...props
}) {
  return (
    <>
      {label && <label>Select {label}</label>}
      <select
        className={cs(`${getFieldCSSClasses(touched[field.name], errors[field.name])}`, className)}
        {...props}
      >
        {children}
      </select>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          erros={errors[field.name]}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
