import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, Link } from 'react-router-dom';
import { Card, CardContent, Box } from '@material-ui/core';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { selectFilter, customFilter } from 'react-bootstrap-table2-filter';
import JSONPretty from "react-json-pretty";
import { Pagination } from '~/_metronic/_partials/controls';
import { actions } from '~/app/modules/VeriffLog/actions';
import { sortCaret } from '~/_metronic/_helpers';
import {
  onTableChangeHelper,
  usePaginationFilteringAndSorting,
  useDidMount,
  decisionCodeToDisplay,
  textFilterOnBlurHelper, textFilterOnBlurHelperWithAutoComplete,
} from '~/app/helpers';
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const sortableColumn = {
  sort: true, sortCaret,
};

const verificationStatusOptions = Object.entries(enums.veriffCodes).map(([codeStr, codeNum]) => (
  { value: codeNum, label: _.camelCase(codeStr) }
));

const verificationReasonOptions = enums.veriffReasonsDisplayOrder.map(code => (
  { value: code, label: enums.veriffReasons[code] }
));

const VeriffLogJsonCell = ({ data }) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => setExpanded(!expanded);

  const caret = expanded ? (
    <span className="fa fa-caret-down" />
  ) : (
    <span className="fa fa-caret-right" />
  );

  return (
    <>
      <div onClick={toggle} style={{ cursor: "pointer" }}>
        { caret } &nbsp;
        Show
      </div>
      { expanded && (
        <JSONPretty
          style={{ display: 'inline-block' }}
          id="json-pretty-paypal"
          json={data}
        />
      )}
    </>
  );
};

const UserFraudScoreCell = ({ fraudScore }) => {
  const score = Number.parseFloat(fraudScore);
  return <div title={score}>{score.toPrecision(3)}</div>
};

const UserIdLinkCell = ({ userId }) => (
  <div>
    <Link
      target="_blank"
      to={`/users/${userId}`}
    > {userId} </Link>
  </div>
);

const columns = (filters, useCountryFieldSort) => [{
  dataField: 'user._id', text: 'User ID',
  formatter: (_cell, row) => <UserIdLinkCell userId={row.user._id} />,
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "230px" }}),
  style: { width: '250px', textAlign: 'left' },
}, {
  ...sortableColumn,
  type: 'number',
  dataField: 'user.fraudScore', text: 'Fraud Score',
  formatter: (_cell, row) => <UserFraudScoreCell fraudScore={row.user.fraudScore} />,
  style: { width: '150px', textAlign: 'right' },
}, {
  ...sortableColumn,
  sort: useCountryFieldSort,
  dataField: 'user.countryISO', text: 'Country',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelperWithAutoComplete(
    onFilter,
    column,
    {style: { width: "50px" }},
    actions,
    'veriffLog'
    ),
  style: { width: '110px', minWidth: '110px', textAlign: 'right' },
}, {
  dataField: 'verification.attemptNumber', text: 'Attempt',
  formatter: (_cell, row) => <div>#{row.verification.attemptNumber}</div>,
  style: { width: '110px', textAlign: 'right' },
}, {
  ...sortableColumn,
  dataField: 'createdAt', text: 'Date',
  type: 'date',
  style: { width: '210px', minWidth: '210px', textAlign: 'right' },
}, {
  ...sortableColumn,
  type: 'number',
  dataField: 'verification.code', text: 'Status',
  formatter: (_cell, row) => <div>{decisionCodeToDisplay(row.verification.status||row.verification.action)}</div>,
  style: { width: '210px', textAlign: 'right' },
  filter: selectFilter({
    defaultValue: filters['verification.code'],
    placeholder: ' ',
    style: { width: '150px' },
    options: verificationStatusOptions,
  }),
}, {
  ...sortableColumn,
  type: 'number',
  dataField: 'verification.reasonCode', text: 'Decline Reason',
  style: { width: '210px', textAlign: 'right' },
  formatter: (_cell, row) => <div>{row.verification.reason}</div>,
  filter: selectFilter({
    defaultValue: filters['verification.reasonCode'],
    placeholder: ' ',
    style: { width: '150px' },
    options: verificationReasonOptions,
  }),
}, {
  dataField: 'verification', text: 'Response Log',
  formatter: (_cell, row) => <VeriffLogJsonCell data={row.verification} />,
  style: { width: '150px' },
}];

const useUrlUserId = (dispatch) => {
  const didMount = useDidMount();
  const { userId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!didMount && userId) {
      dispatch(actions.showUser(userId));
      history.push('/veriff-log');
    }
  }, [didMount, userId, history, dispatch]);
};

const VeriffLogReport = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'veriffLog', actions);
  const report = useSelector(state => state.veriffLog.report, shallowEqual);
  const useCountryFieldSort = useSelector((state) => state.veriffLog.useCountryFieldSort);

  useUrlUserId(dispatch);
  const didMount = useDidMount();

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: ['user._id', 'user.countryISO', 'verification.code', 'verification.reasonCode'],
    numberFilterFields: ['verification.code', 'verification.reasonCode'],
    didMount,
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = columns(filters, useCountryFieldSort);

  return (
    <Box mt={2}>
      <Card>
        <CardContent>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
              {({ paginationProps, paginationTableProps }) => (
                <Pagination isLoading={false} paginationProps={paginationProps}>
                  <BootstrapTable
                    remote
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    headerClasses="table table-vertical-top"
                    bootstrap4
                    keyField="_id"
                    columns={reportColumns}
                    data={report}
                    filter={ filterFactory() }
                    defaultSorted={[{
                      dataField: 'createdAt',
                      order: enums.sortDirections.desc,
                    }]}
                    onTableChange={onTableChange}
                    {...paginationTableProps}
                  >
                  </BootstrapTable>
                </Pagination>
              )}
            </PaginationProvider>
        </CardContent>
      </Card>
    </Box>
  );
};

export default VeriffLogReport;
