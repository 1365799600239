import React, { useEffect, useState } from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { Card, CardContent, Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { actions } from '~/app/modules/QuestionsManagement/components/ProfilerOrder/actions';
import ProfilerOrderReportTable from "./ProfilerOrder.report";
import { Spinner } from "react-bootstrap";
import _ from 'lodash';
import {useHistory} from "react-router-dom";

const mockedReportData = [
  { _id: '99124115', languageCode: 'ENG', countryCode: 'US', name: 'birthdate',
    text: 'Please tell us your birth date (Month/Day/Year):', inProfiler: true, positionInTop: 1,
    positionToShow: 1, surveysCount: 40000, surveysPercentage: 0.95, questionIsMapped: true,
  },
  { _id: '43', languageCode: 'ENG', countryCode: 'US', name: 'GENDER',
    text: 'What is your gender?', inProfiler: true, positionInTop: 2,
    positionToShow: 2, surveysCount: 37772, surveysPercentage: 0.95, questionIsMapped: true,
  },
  { _id: '113', languageCode: 'ENG', countryCode: 'US', name: 'ETHNICITY',
    text: 'What is your race?', inProfiler: true, positionInTop: 3,
    positionToShow: 3, surveysCount: 20000, surveysPercentage: 0.80, questionIsMapped: true,
  },
  { _id: '47', languageCode: 'ENG', countryCode: 'US', name: 'HISPANIC',
    text: 'Are you of Hispanic, Latino, or Spanish origin?', inProfiler: true, positionInTop: 4,
    positionToShow: 4, surveysCount: 18000, surveysPercentage: 0.78, questionIsMapped: true,
  },
  { _id: '14785', languageCode: 'ENG', countryCode: 'US', name: 'STANDART_HHI_US',
    text: 'How much total combined income do all members of your household earn before taxes?', inProfiler: true,
    positionInTop: 5, positionToShow: 5, surveysCount: 9500, surveysPercentage: 0.55, questionIsMapped: true,
  },
  { _id: '48471', languageCode: 'ENG', countryCode: 'US', name: 'STANDART_EDUCATION_v2',
    text: 'What is the highest level of education you have completed?', inProfiler: true, positionInTop: 6,
    positionToShow: 6, surveysCount: 9000, surveysPercentage: 0.5, questionIsMapped: true,
  }
];

const FiltersSection = ({ setShowSavingAlert }) => {
  const dispatch = useDispatch();
  const isLoading = false;

  const report = useSelector(state => state.profilerOrder.report, shallowEqual);
  const defaultReport = useSelector(state => state.profilerOrder.defaultReport, shallowEqual);
  const countryCodes = useSelector(state => state.profilerOrder.countryCodes);
  const countryCode = useSelector(state => state.profilerOrder.countryCode);
  const languageCodes = useSelector(state => state.profilerOrder.languageCodes);
  const languageCode = useSelector(state => state.profilerOrder.languageCode);

  const languageCodeItems = languageCodes.map((language) => (
    <MenuItem key={language.name} value={language.code}>
      {language.name} ({language.code})
    </MenuItem>
  ));

  const countryCodeItems = countryCodes.map((country) => (
    <MenuItem key={country.name} value={country.code}>
      {country.name} ({country.code})
    </MenuItem>
  ));

  const disabledSaveButton = _.isEqual(report, defaultReport);

  const closeSavingAlert = () => {
    setShowSavingAlert(false);
  };

  const handleOnGenerateClick = () => {
    dispatch(actions.setReportData(mockedReportData));
  };

  const handleSaveNewOrder = () => {
    dispatch(actions.setDefaultReportValue(report));
    setShowSavingAlert(true);
    setTimeout(closeSavingAlert, 3000);
  };

  return (
    <Card className="my-5">
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="d-flex align-items-end">
          <Grid className="max-w-600px" container justify="space-between">
            <Grid item xs={5}>
              <FormControl margin="normal">
                <InputLabel id="type-label">Language</InputLabel>
                <Select
                  id="type"
                  labelId="type-label"
                  value={languageCode}
                  onChange={e => dispatch(actions.setLanguageCode(e.target.value))}
                >
                  {languageCodeItems}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl margin="normal">
                <InputLabel id="country-codes-label">Country</InputLabel>
                <Select
                  id="country-codes"
                  labelId="country-codes-label"
                  value={countryCode}
                  onChange={e => dispatch(actions.setCountryCode(e.target.value))}
                >
                  {countryCodeItems}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {isLoading ? (<div className="ml-7"><Spinner animation="border" variant="primary" /></div>) : (
            <Button
              color="primary"
              variant="contained"
              className="ml-10 mb-3 max-h-30px"
              onClick={handleOnGenerateClick}
              disabled={!languageCode || !countryCode}
            >
              Generate
            </Button>
          )}

          {isLoading ? (<div className="ml-auto"><Spinner animation="border" variant="primary" /></div>) : (
            <div className="d-flex flex-column justify-content-end ml-auto">
              <button
                type="button"
                className="btn btn-primary btn-sm ml-5"
                onClick={handleSaveNewOrder}
                disabled={disabledSaveButton}
              >
                Save
              </button>
              <div>
                Number of Questions: 150
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
};

const ProfilerOrder = () => {
  const history = useHistory();
  const report = useSelector(state => state.profilerOrder.report, shallowEqual);
  const defaultReport = useSelector(state => state.profilerOrder.defaultReport, shallowEqual);
  const [showSavingAlert, setShowSavingAlert] = useState(false);

  useEffect(() => {
    const unblock = history.block(() => {
      if (!_.isEqual(report, defaultReport)) {
        return window.confirm("Are you sure you want to leave the page without saving?");
      }
      return true;
    });

    return () => {
      unblock();
    };
  });

  return (
    <div>
      <Card>
        {showSavingAlert && (
          <div style={{ position: "fixed", top: '30%', zIndex: '1000', left: '45%' }}>
            <Alert onClose={() => setShowSavingAlert(false)} severity="success" variant="filled">
              Order Saved
            </Alert>
          </div>)}
        <CardContent className="border text-dark border-light-dark rounded">
          <div className="d-flex justify-content-between align-items-start">
            <h3>Profiler Order</h3>
          </div>
        </CardContent>
      </Card>
      <FiltersSection setShowSavingAlert={setShowSavingAlert} />
      <ProfilerOrderReportTable />
    </div>
  )
};

export default ProfilerOrder;
