import { select, call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/APILog/api';
import { actions, actionTypes } from '~/app/modules/APILog/actions';

const selectForFetchReport = (state) => ({
  filterDate: {
    start: state.apiLog.startDate,
    end: state.apiLog.endDate,
  },
  filters: state.apiLog.filters,
  pageNumber: state.apiLog.pageNumber,
  pageSize: state.apiLog.pageSize,
  sortBy: state.apiLog.sortBy,
  sortDirection: state.apiLog.sortDirection,
});

function* requestReport() {
  try {
    const reportParams = yield select(selectForFetchReport);
    const { results } = yield call(api.fetchReport, reportParams);
    yield put(actions.fetchReportSuccess(results));
  } catch (error) {
    yield put(actions.fetchReportFailure(error.message));
  }
}

const selectForFetchReportAsCsv = (state) => ({
  filterDate: {
    start: state.apiLog.startDate,
    end: state.apiLog.endDate,
  },
  filters: state.apiLog.filters,
});

function* requestReportAsCsv() {
  try {
    const reportParams = yield select(selectForFetchReportAsCsv);
    yield call(api.fetchReportAsCsv, reportParams);
    yield put(actions.generateReportAsCsvSuccess());
  } catch (error) {
    console.error(error);
  }
}

function* createSharedLink(action) {
  try {
    const results = yield call(api.createSharedLink, action.payload);
    yield put(actions.createSharedLinkSuccess(results));
  } catch (error) {
    console.error(error);
    yield put(actions.createSharedLinkFailed(error));
  }
}

export default [
  takeLatest([
    actionTypes.GenerateReport,
    actionTypes.UpdateReport,
  ], requestReport),
  takeLatest(actionTypes.GenerateReportAsCsv, requestReportAsCsv),
  takeLatest(actionTypes.CreateSharedLink, createSharedLink),
];


