import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardContent,
  Button,
  Grid,
} from '@material-ui/core';
import { actions } from '~/app/modules/BannedUsers/actions';
import BannedUsersReport from "./BannedUsers.report";
import {
  usePaginationFilteringAndSorting,
} from '~/app/helpers';
import { KeyboardDatePicker } from "@material-ui/pickers";
import enums from "~/app/helpers/enums";
import MassActionButtons from "./MassUserActions";

const DateSelectors = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  switchName,
  onChangeSwitch,
  switchValue
}) => {
  return (
    <Grid container spacing={1} alignItems="center" className="max-w-600px">
      <Grid item md={2}>
        <span className="switch switch-sm switch-icon-success">
          <label>
            <input
              type="checkbox"
              name={switchName}
              checked={switchValue}
              onChange={onChangeSwitch}
            />
            <span />
          </label>
        </span>
      </Grid>
      <Grid item md={5}>
        <KeyboardDatePicker
          autoOk
          id="start-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          disabled={!switchValue}
          margin="none"
          label="Start Date"
          value={startDate}
          onChange={(_, v) => onChangeStartDate(v)}
        />
      </Grid>
      <Grid item md={5}>
        <KeyboardDatePicker
          autoOk
          id="end-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          disabled={!switchValue}
          margin="none"
          label="End Date"
          value={endDate}
          onChange={(_, v) => onChangeEndDate(v)}
        />
      </Grid>
    </Grid>
  )
};

const BannedUsers = () => {
  const dispatch = useDispatch();
  const { totalCount } = usePaginationFilteringAndSorting(dispatch, 'bannedUsers', actions);
  const bannedAtStart = useSelector(state => state.bannedUsers.bannedAtStart);
  const bannedAtEnd = useSelector(state => state.bannedUsers.bannedAtEnd);
  const useBannedAt = useSelector(state => state.bannedUsers.useBannedAt);

  useEffect(() => {
    dispatch(actions.generateReport());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeUseBannedAt = () => {
    dispatch(actions.setUseBannedAt(!useBannedAt));
  }

  const onChangeDate = (key) => (v) => {
    if (key === 'bannedAtStart') {
      dispatch(actions.setBannedAtStart(v));
    } else {
      dispatch(actions.setBannedAtEnd(v));
    }
  };

  const handleGenerateButtonClick = () => {
    dispatch(actions.setGenerateButtonClick());
    dispatch(actions.generateReport());
  }

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="d-flex justify-content-between align-items-start mb-10">
          <h3>Banned Users List</h3>
          <div className="d-flex flex-column justify-content-end align-items-end">
            <div className="d-flex flex-row justify-content-end">
              <MassActionButtons />
              <Button
                color="secondary"
                variant="contained"
                onClick={() => dispatch(actions.generateReportAsCsv())}
              >
                Export To CSV
              </Button>
            </div>
            <div className="mt-3">Count of Banned Users: {totalCount}</div>
          </div>
        </div>
        <div className="my-3 d-flex align-bottom">
          <DateSelectors
            startDate={bannedAtStart}
            endDate={bannedAtEnd}
            onChangeEndDate={onChangeDate('bannedAtEnd')}
            onChangeStartDate={onChangeDate('bannedAtStart')}
            onChangeSwitch={handleChangeUseBannedAt}
            switchName={'ban date'}
            switchValue={useBannedAt}
          />
          <Button
            className="ml-5 mt-auto"
            color="secondary"
            variant="contained"
            onClick={handleGenerateButtonClick}
          >
            Generate
          </Button>
        </div>
        <BannedUsersReport />
      </CardContent>
    </Card>
  )
};

export default BannedUsers;
