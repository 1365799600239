import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import {
  Card, CardContent
} from "@material-ui/core";

function getChartOption(data, categories, onClick, legendPosition = 'right', showOnlyValue) {
  const options = {
    series: data,
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          if (onClick) {
            onClick(config.w.config.labels[config.dataPointIndex])
          }
        },
      },
      height: 300,
      type: "pie",
    },
    legend: {
      show: true,
      position: legendPosition,
    },
    labels: categories,
    dataLabels: {
      formatter: (val, opts) => {
        if (showOnlyValue) {
          const { seriesIndex } = opts;
          return opts.w.config.series[seriesIndex];
        }
        return `${val.toFixed(0)}%`;
      },
    },
  };

  return options;
}

const PieChart = ({ className, data, title, categories, id, onClick, legendPosition, showOnlyValue }) => {
  useEffect(() => {
    const element = document.getElementById(id);

    if (!element) {
      return;
    }

    const options = getChartOption(data, categories, onClick, legendPosition, showOnlyValue);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [data, categories, id, onClick, legendPosition, showOnlyValue]);

  return (
    <Card className={className}>
      <CardContent>
        <div className="text-primary">{title}</div>
        <div className="my-3 border border-bottom-light-secondary h-1px w-100" />
        <div
          id={id}
          className="card-rounded-bottom"
          style={{ height: "310px", paddingTop: '10px' }}
        />
      </CardContent>
    </Card>
  );
}

export default PieChart;
