import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import { CloseOutlined } from "@material-ui/icons";
import { Modal } from 'react-bootstrap';
import { newDateFormatLong } from "../../../../helpers";

const URLModal = ({ url, shown, setShown }) => {
  return (
    <>
      <Modal
        scrollable
        centered
        size="md"
        show={shown}
        onHide={() => setShown(false)}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            Postback URL
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={() => setShown(false)}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex my-10 justify-content-center mx-5">{url}</div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
}

const Postback = () => {
  const userPublisherPostbackLogs = useSelector(state => state.user.userPublisherPostbackLogs);
  const [shown, setShown] = useState(false);
  const [selectedURL, setSelectedURL] = useState('');

  const handleButtonForURLClick = (url) => () => {
    setSelectedURL(url);
    setShown(true);
  }

  const buttonForURL = (url) => {
    const className = url ? "btn btn-light-primary text-dark btn-sm" : "btn btn-secondary btn-sm"
    return (
      <button
        type="button"
        disabled={!url}
        className={className}
        onClick={handleButtonForURLClick(url)}
      >
        Show URL
      </button>
    )
  }

  const columns = [
    {  dataField: "date", text: "Date", formatter: (date) => newDateFormatLong(date,' (PST)') },
    { dataField: "postbackId", text: "Postback ID" },
    {  dataField: "type", text: "Event Type" },
    {  dataField: "internalClickId", text: "Internal Click ID"  },
    {  dataField: "publisherClickId", text: "Publisher Click ID"  },
    {  dataField: "url", text: "URL", formatter: (url) => buttonForURL(url) },
  ];

  const findEntities = () => {

    if(!userPublisherPostbackLogs.length) {
      return [];
    }

    const entities = [];

    userPublisherPostbackLogs.forEach((log) => {
      entities.push({
        date: log.createdAt,
        postbackId: log._id,
        type: log.eventType,
        internalClickId: log.purchaseId,
        publisherClickId: log.purchaseExternaId,
        url: log.url,
      })
    })

    return entities;
  }

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={true}
        classes="table table-vertical-top overflow-hidden"
        bootstrap4
        size="sm"
        responsive="lg"
        remote
        keyField="postbackId"
        data={findEntities()}
        columns={columns}
      >
      </BootstrapTable>
      <URLModal setShown={setShown} shown={shown} url={selectedURL} />
    </>
  );
};

export default Postback;
