import React, {useMemo, useState} from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { customFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Pagination } from '~/_metronic/_partials/controls';
import { actions } from '~/app/modules/Surveys/actions';
import { sortCaret } from '~/_metronic/_helpers';
import {
  newDateFormatLong,
  onTableChangeHelper,
  usePaginationFilteringAndSorting,
  textFilterOnBlurHelper,
  useDidMount,
  textFilterOnBlurHelperWithAutoComplete,
} from '~/app/helpers';
import { Link } from "react-router-dom";
import cs from 'classnames';
import {Modal} from "react-bootstrap";
import {CloseOutlined} from "@material-ui/icons";
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const sortableColumn = {
  sort: true, sortCaret,
};

const statusOption = [
  { value: 'Active', label: 'Active' },
  { value: 'Not active', label: 'Not Active' },
  { value: 'all', label: 'All' },
];

const simpleOptions = [
  { value: 'True', label: 'True' },
  { value: 'False', label: 'False' },
  { value: 'all', label: 'All' },
];

const verticalsOptions = [ ...enums.verticals.map((name) => ({
  value: name, label: name,
})), { value: 'all', label: 'All'}];

const providersOptions = [...enums.surveysProvidersValues.map((name) => ({
  value: name, label: name,
})), { value: 'all', label: 'All'}];

const surveyIdLink = (_id) => (
  <div>
    <Link
      target="_blank"
      to={`/surveys/${_id}`}
    >
      {_id}
    </Link>
  </div>
);

const SurveyStatus = ({ status }) => {
  return (
    <div
      className={cs('px-1 py-2 rounded text-center', {
        'bg-light-success text-success': status,
        'bg-light-danger text-danger': !status,
      })}
    >
      {status ? 'Active' : 'Not Active'}
    </div>
  );
};

const SurveysGroupIdsModal = ({ groupIds, shown, setShown }) => {
  return (
    <>
      <Modal
        scrollable
        centered
        size="md"
        show={shown}
        onHide={() => setShown(false)}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            Survey group IDs
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={() => setShown(false)}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex my-2 mx-2 flex-wrap max-w-400px w-400px">
            {groupIds?.map((id) => (
              <span className="mr-2" key={id}>{id};</span>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
}

const renderShowGroupsIdsButton = ({ setShown, groupIds, setSelectedGroupIds }) => {
  const handleButtonClick = () => {
    setSelectedGroupIds(groupIds);
    setShown(true);
  }

  return (
    <button
      type="button"
      className="btn btn-light-primary btn-sm bg-light-info text-info"
      onClick={handleButtonClick}
    >
      Show Group IDs
    </button>
  )
}

const renderMobileDropoutCounts = (row) => {
  if (!row.mobileDropoutCounts) {
    return (
      <div>
        <Link
          target="_blank"
          to={`/clicks/survey/${row._id}`}
        >
          0
        </Link>
      </div>
    );
  }

  return (
    <div>
      <Link
        target="_blank"
        to={`/clicks/survey/${row._id}`}
      >
        {row?.mobileDropoutCounts}
      </Link>
    </div>
  );
};

const columns = (filters, setShown, setSelectedGroupIds, useCountryFieldSort) => [{
  ...sortableColumn,
  dataField: '_id', text: 'Survey ID',
  filter: customFilter(),
  formatter: (id) => surveyIdLink(id),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "230px" }, defaultValue: filters['_id'] || ''}),
  style: { width: '250px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'uniqueNumber', text: 'Unique Number',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "180px" }, defaultValue: filters['uniqueNumber'] || ''}),
  style: { width: '200px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'createDate', text: 'Create Date',
  formatter: (date) => newDateFormatLong(date, ' (PST)'),
  style: { width: '150px', minWidth: '150px', textAlign: 'left' },
}, {
  dataField: 'name', text: 'Name',
  style: { width: '150px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'active', text: 'Status',
  filter: selectFilter({
    defaultValue: filters['active'] || 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: statusOption,
    withoutEmptyOption: true,
  }),
  formatter: (active) => (<SurveyStatus status={active} />),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  ...sortableColumn,
  sort: useCountryFieldSort,
  dataField: 'countryCode', text: 'Country',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelperWithAutoComplete(
    onFilter,
    column,
    {style: { width: "100px" }, defaultValue: filters['countryCode'] || ''},
    actions,
    'surveys',
  ),
  style: { width: '120px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'countryLanguageCode', text: 'Country Language Code',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "100px" }, defaultValue: filters['countryLanguageCode'] || ''}),
  style: { width: '150px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'provider', text: 'Provider',
  filter: selectFilter({
    defaultValue: filters['provider'] || 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: providersOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '120px', textAlign: 'left' },
},{
  dataField: 'targetedUsers', text: 'Targeted Users',
  style: { width: '150px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'mobileResponsive', text: 'Mobile Responsive',
  filter: selectFilter({
    defaultValue: filters['mobileResponsive'] || 'all',
    placeholder: ' ',
    style: { width: '80px' },
    options: simpleOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '100px', textAlign: 'left' },
  formatter: (mobileResponsive) => mobileResponsive ? 'True': 'False'
},{
  ...sortableColumn,
  dataField: 'mobileOnly', text: 'Mobile Only',
  filter: selectFilter({
    defaultValue: filters['mobileOnly'] || 'all',
    placeholder: ' ',
    style: { width: '80px' },
    options: simpleOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '100px', textAlign: 'left' },
  formatter: (mobileOnly) => mobileOnly ? 'True': 'False'
},{
  ...sortableColumn,
  dataField: 'reContact', text: 'ReContact',
  filter: selectFilter({
    defaultValue: filters['reContact'] || 'all',
    placeholder: ' ',
    style: { width: '80px' },
    options: simpleOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '100px', textAlign: 'left' },
  formatter: (reContact) => reContact ? 'True': 'False'
},{
  ...sortableColumn,
  dataField: 'rank', text: 'Rank',
  type: 'number',
  style: { width: '100px', textAlign: 'left' },
  formatter: (rank) => rank?.toFixed(2)
},{
  ...sortableColumn,
  dataField: 'preCalculatedRanks.rank21', text: 'Rank 21',
  type: 'number',
  style: { width: '100px', textAlign: 'left' },
  formatter: (rank) => rank?.toFixed(2)
},{
  ...sortableColumn,
  dataField: 'preCalculatedRanks.rank17', text: 'Rank 17',
  type: 'number',
  style: { width: '100px', textAlign: 'left' },
  formatter: (rank) => rank?.toFixed(2)
},{
  ...sortableColumn,
  dataField: 'cpi', text: 'CPI',
  type: 'number',
  style: { width: '100px', textAlign: 'left' },
  formatter: (cpi) => cpi?.toFixed(2)
},{
  dataField: 'clicks', text: 'Clicks',
  type: 'number',
  formatter: (cell, row) => ((row.clicksCount || 0) + (row.stoppedClicksCount || 0)) || 0,
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'clicksCount', text: 'Qualified Clicks',
  type: 'number',
  formatter: (clicksCount) => clicksCount?.toFixed(0) || 0,
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'leadsCount', text: 'Leads',
  type: 'number',
  formatter: (leadsCount) => leadsCount?.toFixed(0) || 0,
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'cr', text: 'Conversion Rate',
  formatter: (cr) => (cr ? `${(cr * 100).toFixed(2)}%` : '0%'),
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'predictedCR', text: 'Predicted CR',
  formatter: (cr) => (cr ? `${(cr * 100).toFixed(2)}%` : '0%'),
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'statistics.cr', text: 'Provider CR',
  formatter: (cr) => (cr ? `${(cr * 100).toFixed(2)}%` : '0%'),
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'statistics.epc', text: 'Provider EPC',
  type: 'number',
  formatter: (field) => field?.toFixed(2),
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'epc', text: 'EPC',
  type: 'number',
  formatter: (field) => field?.toFixed(2),
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'lengthOfInterview', text: 'Initial LOI',
  type: 'number',
  formatter: (field) => field?.toFixed(2),
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'successLOI', text: 'Actual LOI',
  type: 'number',
  formatter: (field) => field?.toFixed(2),
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'dqCount', text: 'DQ Count',
  type: 'number',
  formatter: (field) => field?.toFixed(0) || 0,
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'dqLOI', text: 'DQ LOI',
  type: 'number',
  formatter: (field) => field?.toFixed(2),
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'stoppedClicksCount', text: 'Stopped Clicks',
  type: 'number',
  formatter: (field) => field?.toFixed(0) || 0,
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'preliminaryFailedClicksCount', text: 'Preliminary Fails',
  type: 'number',
  formatter: (field) => field?.toFixed(0) || 0,
  style: { width: '100px', textAlign: 'left' },
},{
  dataField: 'reversals', text: 'Reversals',
  formatter: (field) => field?.toFixed(0),
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'vertical', text: 'Vertical',
  filter: selectFilter({
    defaultValue: filters['vertical'] || 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: verticalsOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '120px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'sentAsGood', text: 'Sent as Good',
  style: { width: '100px', textAlign: 'left' },
  filter: selectFilter({
    defaultValue: filters['sentAsGood'] || 'all',
    placeholder: ' ',
    style: { width: '80px' },
    options: simpleOptions,
    withoutEmptyOption: true,
  }),
  formatter: (sentAsGood) => sentAsGood ? 'True' : 'False',
},{
  ...sortableColumn,
  dataField: 'accountName', text: 'Subprovider',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "130px" }, defaultValue: filters['accountName'] || ''}),
  style: { width: '150px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'surveyGroupsIds', text: 'Survey Group ID',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }, defaultValue: filters['surveyGroupsIds'] || ''}),
  formatter: (surveyGroupsIds) => renderShowGroupsIdsButton({
    setShown, groupIds:surveyGroupsIds, setSelectedGroupIds
  }),
  style: { width: '150px', minWidth: '150px',  textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'mobileConversion', text: 'Mobile Conversion',
  formatter: (field) => field?.toFixed(2),
  style: { width: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'mobileDropoutCounts', text: 'Mobile Dropout Count',
  type: 'number',
  formatter: (mobileDropoutCounts, row) => renderMobileDropoutCounts(row),
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'lastUpdated', text: 'Last Updated',
  formatter: (date) => newDateFormatLong(date, ' (PST)'),
  style: { width: '150px', minWidth: '150px', textAlign: 'left' },
}];

const SurveysReport = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'surveys', actions);
  const report = useSelector(state => state.surveys.report, shallowEqual);
  const didMount = useDidMount();
  const [shown, setShown] = useState(false);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const useCountryFieldSort = useSelector((state) => state.surveys.useCountryFieldSort);

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: ['_id', 'uniqueNumber', 'createDate', 'active', 'countryCode', 'countryLanguageCode', 'provider',
      'mobileResponsive', 'mobileOnly', 'reContact', 'vertical', 'sentAsGood', 'surveyGroupsIds', 'accountName', 'lastUpdated'],
    didMount,
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = useMemo(() =>
   columns(filters, setShown, setSelectedGroupIds, useCountryFieldSort), [filters, setShown, setSelectedGroupIds, useCountryFieldSort]);

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => (
          <Pagination isLoading={false} paginationProps={paginationProps}>
            <BootstrapTable
              remote
              classes="table table-head-custom table-vertical-center overflow-hidden"
              headerClasses="table table-vertical-top"
              bootstrap4
              responsive
              keyField="_id"
              columns={reportColumns}
              data={report}
              filter={ filterFactory() }
              defaultSorted={[{
                dataField: sortBy || 'createDate',
                order: sortDirection || enums.sortDirections.desc,
              }]}
              onTableChange={onTableChange}
              {...paginationTableProps}
            >
            </BootstrapTable>
          </Pagination>
        )}
      </PaginationProvider>
      <SurveysGroupIdsModal shown={shown} setShown={setShown} groupIds={selectedGroupIds} />
    </>
  );
};

export default SurveysReport;
