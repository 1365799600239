import React, { useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { customFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Pagination } from '~/_metronic/_partials/controls';
import { actions } from '~/app/modules/QuestionsManagement/components/Questions/actions';
import { actions as mappedQuestionActions } from '~/app/modules/QuestionsManagement/components/MappedQuestion/actions';
import { sortCaret } from '~/_metronic/_helpers';
import {
  newDateFormatLong,
  usePaginationFilteringAndSorting,
  textFilterOnBlurHelper,
} from '~/app/helpers';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "~/_metronic/_helpers";
import { useHistory } from "react-router-dom";
import { Card, CardContent } from "@material-ui/core";
import cs from 'classnames';
import {Modal} from "react-bootstrap";
import {CloseOutlined, Star} from "@material-ui/icons";
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const sortableColumn = {
  sort: true, sortCaret,
};

const questionTypesOptions = [...Object.values(enums.questionTypes), 'All'].map((type) => ({
  value: type, label: type
}));

const inProfilerOptions = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
  { value: 'All', label: 'All' },
];

const providerOptions = [
  { value: 'Federated', label: 'Federated' },
  { value: 'Market Cube', label: 'Market Cube' },
  { value: 'All', label: 'All' },
];

const renderActions = (row, history, dispatch) => {
  const handleClick = (e) => {
    e.stopPropagation();
    dispatch(mappedQuestionActions.setSelectedQuestion(row))
    history.push(`/questions/${row?._id}`);
  }
  return (
    <div onClick={handleClick}>
      <SVG
        className="text-primary"
        src={toAbsoluteUrl('/media/svg/icons/General/Attachment2.svg')}
      />
    </div>
  )
};

const renderStatusField = (fieldValue, type, row, setSelectedValues, setShown) => {
  if (fieldValue && type === 'questionType') {
    const handleClick = (e) => {
      e.stopPropagation();
      setShown(true);
      setSelectedValues(row);
    }
    return (
      <div
        onClick={handleClick}
        className="py-2 px-5 d-flex rounded align-items-center justify-content-center bg-light-info text-info"
      >
        <div>True</div>
      </div>
    );
  }

  return (
    <div className={cs('py-2 px-5 rounded d-flex align-items-center justify-content-center', {
      'bg-light-secondary text-secondary': !fieldValue,
      'bg-light-primary text-primary': fieldValue,
    })}>
      <div>{fieldValue? 'True' : 'False'}</div>
    </div>
  );
}

const renderIdField = (row) => {
  return (
    <div className="d-flex" style={{ marginLeft: row?.questionIsMapped ? '-22px' : '0' }}>
      {row?.questionIsMapped && (
        <span style={{ marginRight: '3px' }} className="svg-icon svg-icon-warning h-20">
          <Star style={{ color: '#ffa701' }}/>
        </span>
      )}
      <div>{row._id}</div>
    </div>
  );
};

const columns = (setSelectedValues, setShown, history, dispatch) => [{
  ...sortableColumn,
  dataField: '_id', text: 'Internal Question ID',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "100px" } }),
  formatter: (_id, row) => renderIdField(row),
  style: { minWidth: '150px', width: '150px', paddingLeft: '20px', textAlign: 'left'},
  headerStyle: { paddingLeft: '20px' },
}, {
  ...sortableColumn,
  dataField: 'questionId', text: 'Provider Question ID',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "100px" } }),
  style: { width: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  filter: selectFilter({
    defaultValue: 'All',
    placeholder: ' ',
    style: { width: '100px' },
    options: providerOptions,
    withoutEmptyOption: true,
  }),
  dataField: 'provider', text: 'Provider Name',
  style: { width: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'languageCode', text: 'Language',
  style: { width: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'countryCode', text: 'Country',
  style: { width: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'name', text: 'Question Name',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "150px" } }),
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'text', text: 'Question Text',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "150px" } }),
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'type', text: 'Question Type',
  filter: selectFilter({
    defaultValue: 'All',
    placeholder: ' ',
    style: { width: '100px' },
    options: questionTypesOptions,
    withoutEmptyOption: true,
  }),
},{
  ...sortableColumn,
  dataField: 'inProfiler', text: 'In profiler',
  filter: selectFilter({
    defaultValue: 'All',
    placeholder: ' ',
    style: { width: '100px' },
    options: inProfilerOptions,
    withoutEmptyOption: true,
  }),
  formatter: (inProfiler) => renderStatusField(inProfiler),
  style: { width: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'positionInTop', text: 'Position In Top',
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'positionToShow', text: 'Position To Show',
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'mapped', text: 'Mapped',
  filter: selectFilter({
    defaultValue: 'All',
    placeholder: ' ',
    style: { width: '100px' },
    options: inProfilerOptions,
    withoutEmptyOption: true,
  }),
  formatter: (mapped, row) => renderStatusField(mapped, 'questionType', row, setSelectedValues, setShown),
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'surveysCount', text: 'Number Of Surveys',
  style: { width: '120px', textAlign: 'left' },
},{
  dataField: 'surveysPercentage', text: 'Percentage Of Surveys',
  formatter: (surveysPercentage) => (surveysPercentage ? `${(surveysPercentage * 100).toFixed(1)}%`: '-'),
  style: { width: '180px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'createdAt', text: 'Created Date',
  formatter: (date) => newDateFormatLong(date),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
},{
  dataField: 'mappedAt', text: 'Mapped Date',
  formatter: (date) => newDateFormatLong(date),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
},{
  dataField: 'actions', text: 'Actions',
  formatter: (_cell, row) => renderActions(row, history, dispatch),
  style: { width: '100px', textAlign: 'left' },
}];

const mappedQuestionColumns = () => [{
  dataField: '_id', text: 'Internal Question Id',
  style: { width: '100px', minWidth: '100px', textAlign: 'left' }
}, {
  dataField: 'name', text: 'Question Name',
  style: { width: '150px', minWidth: '150px', textAlign: 'left' }
}, {
  dataField: 'text', text: 'Question Text',
  style: { width: '250px', minWidth: '250px', textAlign: 'left' }
}, {
  dataField: 'mappingType', text: 'Mapping Type',
  style: { width: '100px', minWidth: '100px', textAlign: 'left' }
}];

const ModalForMappedQuestion = ({ shown, setShown, values }) => {
  const handleCloseModal = () => {
    setShown(false);
  }

  const columns = mappedQuestionColumns();

  return (
    <Modal
      scrollable
      centered
      size="lg"
      show={shown}
      onHide={handleCloseModal}
    >
      <Modal.Header className="d-flex justify-content-between align-items-start">
        <Modal.Title>
          Mapped Questions
        </Modal.Title>
        <div className="cursor-pointer pb-2 py-2" onClick={handleCloseModal}><CloseOutlined /></div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex">
            <div className="text-muted mr-2">Question ID:</div>
            <div>{values._id}</div>
          </div>
          <div className="d-flex mb-10">
            <div className="text-muted mr-2">Question Text:</div>
            <div>{values.text}</div>
          </div>
          <BootstrapTable
            classes="table table-head-custom table-vertical-center overflow-hidden"
            headerClasses="table table-vertical-top"
            bootstrap4
            responsive
            keyField="_id"
            columns={columns}
            data={values.mappedQuestions}
          />
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  )
};

const QuestionsReport = () => {
  const dispatch = useDispatch();
  const {
    pageNumber, pageSize, totalCount,
  } = usePaginationFilteringAndSorting(dispatch, 'questions', actions);
  const history = useHistory();
  const isLoading = useSelector(state => state.questions.isLoading);
  const report = useSelector(state => state.questions.report, shallowEqual);
  const [shown, setShown] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const expandRow = {
    renderer: row => {
      return (
        <div className="my-2 ml-7">
          <div className="d-flex pl-3">
            <h6 className="min-w-180px">ANSWER ID</h6>
            <h6 className="ml-3 min-w-700px">ANSWER TEXT</h6>
          </div>
          {row?.answers?.length && row.answers.map((answer) => (
            <div key={answer._id} className="min-h-40px border-top d-flex w-100 py-2 pl-3">
              <div className="min-w-180px">{answer._id}</div>
              <div className="ml-3 min-w-700px">{answer.text}</div>
            </div>
          ))}
        </div>
      );
    },
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (<span className="text-primary min-w-15px">▼</span>);
      } else {
        return (<span className="text-primary min-w-15px">▶</span>);
      }
    },
    expandHeaderColumnRenderer: () => {
      return <div onClick={(e) => { e.stopPropagation() } } />;
    },
  };

  const rowStyle = (row) => {
    if (row.isExpand) {
      return { cursor: 'pointer', background: '#f1f1f1' };
    }
    return { cursor: 'pointer' };
  };

  const reportColumns = columns(setSelectedValues, setShown, history, dispatch);

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps }) => (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                classes="table table-head-custom table-vertical-center overflow-hidden"
                headerClasses="table table-vertical-top"
                bordered={false}
                bootstrap4
                responsive
                keyField="_id"
                columns={reportColumns}
                data={report}
                filter={ filterFactory() }
                defaultSorted={[{
                  dataField: '_id',
                  order: enums.sortDirections.desc,
                }]}
                rowStyle={ rowStyle }
                expandRow={ expandRow }
              >
              </BootstrapTable>
            </Pagination>
          )}
        </PaginationProvider>
      </CardContent>
      <ModalForMappedQuestion shown={shown} setShown={setShown} values={selectedValues} />
    </Card>
  );
};

export default QuestionsReport;
