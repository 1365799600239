const actionTypes = {
  SetUserId: 'qa-helpers/set-user-id',
  SetAmount: 'qa-helpers/set-amount',

  CreateWalletLeadTransaction: 'qa-helpers/create-wallet-lead-transaction',
  CreateWalletLeadTransactionSuccess: 'qa-helpers/create-wallet-lead-transaction-success',
  CreateWalletLeadTransactionFailure: 'qa-helpers/create-wallet-lead-transaction-failure',
};

const actions = {
  setUserId: (userId) => ({
    type: actionTypes.SetUserId,
    payload: userId,
  }),

  setAmount: (amount) => ({
    type: actionTypes.SetAmount,
    payload: amount,
  }),

  createWalletLeadTransaction: (userId, amount) => ({
    type: actionTypes.CreateWalletLeadTransaction,
    payload: { userId, amount },
  }),
  createWalletLeadTransactionSuccess: (data) => ({
    type: actionTypes.CreateWalletLeadTransactionSuccess, payload: data,
  }),
  createWalletLeadTransactionFailure: (error) => ({
    type: actionTypes.CreateWalletLeadTransactionFailure, payload: error,
  }),
};

export {
  actionTypes,
  actions,
};
