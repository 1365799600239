import React from 'react';
import cs from 'classnames';

const StatusField = ({status}) => {
  let statusText = '';
  switch (status) {
    case 'assigned':
      statusText = 'Assigned';
      break;
    case 'unassigned':
      statusText = 'Unassigned';
      break;
    case 'Created':
      statusText = 'Created';
      break;
    case 'In Progress':
      statusText = 'In Progress';
      break;
    case 'Pending Approval':
      statusText = 'Pending Approval';
      break;
    case 'Verified':
      statusText = 'Verified';
      break;

    default:
      statusText = status;
      break;
  }

  return (
    <div className={cs('rounded d-flex justify-content-center align-items-center text-center', {
      'px-2 py-2 bg-light-success text-success': status === 'Active',
      'px-2 py-2 bg-light-danger text-danger': status === 'Inactive',
      'px-2 py-1 bg-light-primary text-primary min-w-120px': status === 'assigned',
      'px-2 py-1 bg-light-danger text-danger min-w-120px': status === 'unassigned',
      'px-2 py-1 bg-light-secondary text-secondary': status === 'Created',
      'px-2 py-1 bg-light-warning text-warning': status === 'In Progress',
      'px-2 py-1 bg-light-info text-info': status === 'Pending Approval',
      'px-2 py-1 bg-light-success text-success': status === 'Verified',
    })}>
      {statusText}
    </div>
  )
};

export default StatusField;
