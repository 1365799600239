import React from 'react';
import { Modal } from "react-bootstrap";

const ConfirmationModal = ({ title, confirmFunc, shown, setShown }) => {
  const handleConfirm = () => {
    if(confirmFunc) {
      confirmFunc()
    }
    setShown(false)
  }

  return (
    <>
      <Modal
        scrollable
        centered
        size="md"
        show={shown}
        onHide={() => setShown(false)}
      >
        <Modal.Header>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure?
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={() => setShown(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm ml-5"
              onClick={handleConfirm}
            >
              Confirm
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
