import { actionTypes } from '~/app/modules/Translations/components/Feature/actions';

const initialState = {
  feature: {},
  keysStats: [],
  languages: [],
  products: [],
  translators: [],
  foundKeys: [],
  filter: {},
  selectedLanguages: [],
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetFeatureSuccess:
      return {
        ...state,
        feature: action.payload,
        keysStats: action.payload?.keysStats,
        languagesForFeature: action.payload?.languagesForFeature || [],
        isLoading: false,
      };

    case actionTypes.GetFeature:
      return {
        ...state,
        feature: {},
        isLoading: true,
      };

    case actionTypes.GetFeatureFailure:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.UpdateFeature:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.UpdateFeatureSuccess:
    case actionTypes.UpdateFeatureFailure:
    case actionTypes.GetAvailableParamsFailure:
    case actionTypes.FindKeysFailure:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GetAvailableParams:
      return {
        ...state,
        isLoading: true,
        languages: [],
        translators: [],
        products: [],
      };

    case actionTypes.GetAvailableParamsSuccess:
      return {
        ...state,
        isLoading: false,
        languages: action.payload?.languages,
        translators: action.payload?.translators,
        products: action.payload?.products,
      };

    case actionTypes.FindKeys:
      return {
        ...state,
        isLoading: true,
        foundKeys: [],
      };

    case actionTypes.FindKeysSuccess:
      return {
        ...state,
        isLoading: false,
        foundKeys: action.payload,
      };

    case actionTypes.CleanFoundKeys:
      return {
        ...state,
        foundKeys: [],
      };

    case actionTypes.SetFilterValues:
      return {
        ...state,
        filter: action.payload,
      };

    case actionTypes.SetSelectedLanguages:
      return {
        ...state,
        selectedLanguages: action.payload,
      };

    default:
      return state;
  }
};
