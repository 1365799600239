import config from '~/config';
import fetch from '~/redux/fetch';
import { format } from 'date-fns';

const EXPORT_USER_DATA_URL = '/miscellaneous/export-user-data';
const EXPORT_USER_DATA_BY_IDS_URL = '/miscellaneous/export-user-data-by-ids';
const GET_COUNTRY_CODES_URL = '/miscellaneous/country-codes';

const fetchUserDataFromURL = async (requestPath, params, fileName) => {
  const response = await fetch(`${config.apiBaseUrl}${requestPath}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });
  const blob = await response.blob();
  const file = window.URL.createObjectURL(blob);
  var a = document.createElement('a');
  a.href = file;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const exportUserData = async (params) => {
  const timeNow = format(new Date(), 'HHmmss');
  const startDate = format(new Date(params.filterDate.start), 'dd-MM-yyyy');
  const endDate = format(new Date(params.filterDate.end), 'dd-MM-yyyy');
  const fileName = `ST Users Dataset - ${params.countryCode} ${startDate} to ${endDate} FILE${timeNow}.csv`;
  fetchUserDataFromURL(EXPORT_USER_DATA_URL, params, fileName);
};

const exportUserDataByIds = async (params) => {
  const timeNow = format(new Date(), 'HHmmss');
  const fileName = `ST Users Dataset - user IDs - FILE${timeNow}.csv`;
  fetchUserDataFromURL(EXPORT_USER_DATA_BY_IDS_URL, params, fileName);
};

const getCountryCodes = async () => {
  const response = await fetch(`${config.apiBaseUrl}${GET_COUNTRY_CODES_URL}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
};

export default {
  exportUserData,
  exportUserDataByIds,
  getCountryCodes,
};
