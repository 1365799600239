import config from '~/config';
import fetch from '~/redux/fetch';
import { fetchReportHelper } from "~/app/helpers";

const FETCH_REPORT_URL = '/amazon-paid-cards';
const getAmazonCardsUrl = '/amazon-paid-cards';

const getAmazonCards = async () => {
  const response = await fetch(`${config.apiBaseUrl}${getAmazonCardsUrl}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const getAmazonBatchesHistoryUrl = '/amazon-batches-history';

const getAmazonBatchesHistory = async () => {
  const response = await fetch(`${config.apiBaseUrl}${getAmazonBatchesHistoryUrl}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const uploadCSVUrl = '/upload-csv';

const uploadCSV = async (formData) => {
  const response = await fetch(`${config.apiBaseUrl}${uploadCSVUrl}`, {
    method: 'POST',
    body: formData,
  });
  return response.json();
}

export default {
  fetchReport: fetchReportHelper(FETCH_REPORT_URL),
  getAmazonCards,
  uploadCSV,
  getAmazonBatchesHistory,
};
