import { select, call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/HighReversalRateBans/api';
import { actions, actionTypes } from '~/app/modules/HighReversalRateBans/actions';

const selectForFetchReport = (state) => ({
  filterDate: {
    start: state.highReversalRateBans.startDate,
    end: state.highReversalRateBans.endDate,
  },
  filters: state.highReversalRateBans.filters,
  pageNumber: state.highReversalRateBans.pageNumber,
  pageSize: state.highReversalRateBans.pageSize,
  sortBy: state.highReversalRateBans.sortBy,
  sortDirection: state.highReversalRateBans.sortDirection,
});

export default function* saga() {
  yield takeLatest([
    actionTypes.GenerateReport,
    actionTypes.UpdateReport,
  ], function* requestReport() {
    try {
      const reportParams = yield select(selectForFetchReport);
      const { results } = yield call(api.fetchReport, reportParams);
      yield put(actions.fetchReportSuccess(results));
    } catch (error) {
      yield put(actions.fetchReportFailure(error.message));
    }
  });
}
