
import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('amazonInventory');

actionTypes.GetAmazonCards = 'amazonInventory/get-amazon-cards';
actionTypes.GetAmazonCardsSuccess = 'amazonInventory/get-amazon-cards-success';
actionTypes.GetAmazonCardsFailure = 'amazonInventory/get-amazon-cards-failure';
actionTypes.UploadCSV = 'amazonInventory/upload-csv';
actionTypes.UploadCSVSuccess = 'amazonInventory/upload-csv-success';
actionTypes.UploadCSVFailure = 'amazonInventory/upload-csv-failure';
actionTypes.GetAmazonBatchesHistory = 'amazonInventory/get-amazon-batches-history';
actionTypes.GetAmazonBatchesHistorySuccess = 'amazonInventory/get-amazon-batches-history-success';
actionTypes.GetAmazonBatchesHistoryFailure = 'amazonInventory/get-amazon-batches-history-failure';
actionTypes.CleanUploadError = 'amazonInventory/clean-upload-error';

actions.getAmazonCards = () => ({ type: actionTypes.GetAmazonCards });
actions.getAmazonCardsSuccess = (data) => ({ type: actionTypes.GetAmazonCardsSuccess, payload: data });
actions.getAmazonCardsFailure = () => ({ type: actionTypes.GetAmazonCardsFailure });
actions.uploadCSV = (data) => ({ type: actionTypes.UploadCSV, payload: data });
actions.uploadCSVSuccess = (data) => ({ type: actionTypes.UploadCSVSuccess, payload: data });
actions.uploadCSVFailure = (data) => ({ type: actionTypes.UploadCSVFailure, payload: data });
actions.getAmazonBatchesHistory = () => ({ type: actionTypes.GetAmazonBatchesHistory });
actions.getAmazonBatchesHistorySuccess = (data) => ({ type: actionTypes.GetAmazonBatchesHistorySuccess, payload: data });
actions.getAmazonBatchesHistoryFailure = () => ({ type: actionTypes.GetAmazonBatchesHistoryFailure });
actions.cleanUploadError = () => ({ type: actionTypes.CleanUploadError });

export {
  actionTypes,
  actions,
};
