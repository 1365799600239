import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TopInformationSection from "./TopInformationSection";
import { Card, CardContent } from "@material-ui/core";
import {Link, useParams} from "react-router-dom";
import { actions } from "~/app/modules/Survey/actions";
import { renderMappedField, useDidMount, renderPreCodes } from '~/app/helpers';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Pagination } from '~/_metronic/_partials/controls';
import { Spinner } from "react-bootstrap";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const TopInfoBlock = () => {
  const surveyInformation = useSelector((state) => state.survey.surveyInformation);
  const targetedUsersCount = useSelector((state) => state.survey.targetedUsersCount);
  const isLoading = useSelector((state) => state.survey.targetedUsersIsLoading);

  return (
    <Card className="mb-3">
      <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center">
        <TopInformationSection surveyInformation={surveyInformation} alignChildren="start">
          <div className="d-flex flex-column align-items-center">
            <div>Targeted Users</div>
            <div className="mt-2 font-size-h6 font-weight-bolder">
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> )  : targetedUsersCount}
            </div>
          </div>
        </TopInformationSection>
      </CardContent>
    </Card>
  );
};

const userIdLink = (_id) => (
  <div>
    <Link
      target="_blank"
      to={`/users/${_id}`}
    >
      {_id}
    </Link>
  </div>
);

const renderPreCodeField = (userInfo) => {
  if (userInfo?.options) {
    return renderPreCodes(userInfo?.answers, userInfo?.options);
  }

  return <div/>;
};

const renderAnswers = (userInfo) => {
  if (!userInfo?.options?.length) {
    if (userInfo?.answers?.length) {
      return (
        <div className="d-flex flex-wrap">
          {userInfo.answers.map((answer) => (
            <span key={answer} className="mr-1">{answer};</span>
          ))}
        </div>
      )
    }
    return <div/>;
  }

  let approvedOptions = userInfo.options.filter((option) => userInfo?.answers?.includes(option?.precode));
  if (!approvedOptions?.length) {
    approvedOptions = userInfo.options.filter((option, index) => userInfo?.answers?.includes(String(index + 1)));
  }

  return (
    <div className="d-flex flex-column">
      {approvedOptions?.map((option) => (
        <div key={option.precode}>{option.optionText};</div>
      ))}
    </div>
  )
}

const columns = () => [{
  dataField: 'userId', text: 'User ID',
  style: { width: '150px', textAlign: 'center' },
  formatter: (userId) => userIdLink(userId),
}, {
  dataField: 'targetedDateAndTime', text: 'Targeted Date and Time',
  style: { width: '150px', textAlign: 'center' },
}, {
  dataField: 'questionId', text: 'Internal Question ID',
  style: { width: '150px', textAlign: 'left' },
}, {
  dataField: 'providerQuestionId', text: 'Provider Question ID',
  style: { width: '150px', textAlign: 'left' },
}, {
  dataField: 'mapped', text: 'Mapped',
  style: { width: '100px', textAlign: 'left' },
  formatter: (mapped) => renderMappedField(mapped),
}, {
  dataField: 'baseQuestionId', text: 'Mapped Question ID',
  style: { width: '100px', textAlign: 'left' },
}, {
  dataField: 'name', text: 'Question Name',
  style: { width: '200px', textAlign: 'left' },
}, {
  dataField: 'text', text: 'Question Text',
  style: { width: '200px', textAlign: 'left' },
}, {
  dataField: 'precodes', text: 'Approved Answer ID',
  style: { width: '100px', textAlign: 'left' },
  formatter: (_cell, row) => renderPreCodeField(row),
}, {
  dataField: 'options', text: 'Approved Answers Text',
  style: { width: '400px', textAlign: 'left' },
  formatter: (_cell, row) => renderAnswers(row),
}];

const TargetedUsersTable = ({ targetedUsersCount,pageSize, pageNumber, targetedUsers, isLoading }) => {
  const dispatch = useDispatch();

  const paginationOptions = {
    custom: true,
    totalSize: +targetedUsersCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const tableColumns = columns();
  const didMount = useDidMount();

  const onTableChange = (eventType, { sizePerPage, page }) => {
    if (didMount && eventType === 'pagination') {
      if (sizePerPage && sizePerPage !== pageSize) {
        dispatch(actions.setSurveyTargetedUsersPerPage(sizePerPage));
      }
      if (page && page !== pageNumber) {
        dispatch(actions.setSurveyTargetedUsersPage(page));
      }
    }
  };

  const rowStyle = (row) => {
    if (row.userId) {
      return { borderTop: '2px solid black', background: '#f1f1f1' };
    }
    return row;
  };

  return (
    <Card className="mb-3">
      <CardContent className="border text-dark border-light-dark rounded">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                remote
                classes="table table-head-custom table-vertical-center overflow-hidden"
                headerClasses="table table-vertical-top"
                bootstrap4
                responsive
                keyField="id"
                columns={tableColumns}
                data={targetedUsers}
                onTableChange={onTableChange}
                rowStyle={ rowStyle }
                {...paginationTableProps}
              >
              </BootstrapTable>
            </Pagination>
          )}
        </PaginationProvider>
      </CardContent>
    </Card>
  );
}

const TargetedUsers = () => {
  const surveyInformation = useSelector((state) => state.survey.surveyInformation);
  const targetedUsersCount = useSelector((state) => state.survey.targetedUsersCount);
  const isLoading = useSelector((state) => state.survey.targetedUsersIsLoading);
  const pageNumber = useSelector((state) => state.survey.targetedUsersCurrentPage);
  const pageSize = useSelector((state) => state.survey.targetedUsersPerPage);
  const targetedUsers = useSelector((state) => state.survey.targetedUsers);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(actions.getSurvey(params.surveyId));
    dispatch(actions.getSurveyQuestions(params.surveyId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const sendParams = {
      surveyId: params.surveyId,
      pageSize,
      pageNumber,
    }
    dispatch(actions.getSurveyTargetedUsers(sendParams));
  }, [dispatch, pageNumber, pageSize, params])

  if (!surveyInformation._id) {
    return null;
  }

  return (
    <div>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center">
          <h2 className="d-flex align-items-center">
            Survey Data
            <div className="ml-4 mb-0 h5 text-muted">Targeted Users</div>
          </h2>
        </CardContent>
      </Card>
      <TopInfoBlock />
      <TargetedUsersTable
        isLoading={isLoading}
        pageNumber={pageNumber}
        pageSize={pageSize}
        targetedUsers={targetedUsers}
        targetedUsersCount={targetedUsersCount}
      />
    </div>
  );
};

export default TargetedUsers;
