import React from "react";
import {sortCaret} from "~/_metronic/_helpers";
import {differenceInMilliseconds} from "date-fns";
import {Link} from "react-router-dom";
import {
  newDateFormatLong,
  onTableChangeHelper,
  textFilterOnBlurHelper,
  useDidMount,
  usePaginationFilteringAndSorting
} from "~/app/helpers";
import filterFactory, {customFilter} from "react-bootstrap-table2-filter";
import {Card, CardContent} from "@material-ui/core";
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator";
import {Pagination} from "~/_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { actions } from "~/app/modules/SurveyUpdatesLog/actions";
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const sortableColumn = {
  sort: true, sortCaret,
};

const renderDaysSinceSignUp = (date) => {
  if (!date) {
    return '-';
  }
  const diffInMilliseconds = differenceInMilliseconds(new Date(), new Date(date));
  const millisecondsInSeconds = 1000;
  const secondsInMinutes = 60;
  const minutesInHours = 60;
  const hoursInDay = 24;

  return (diffInMilliseconds/millisecondsInSeconds/secondsInMinutes/minutesInHours/hoursInDay).toFixed(0) || 0;
};

const surveyIdLink = (_id) => (
  <div>
    <Link
      target="_blank"
      to={`/surveys/${_id}`}
    >
      {_id}
    </Link>
  </div>
);

const renderCR = (cr) => {
  if (cr) {
    return (<div className="d-flex align-items-center">
      <div>{cr?.was}</div>
      <div className="mx-5">→</div>
      <div>{cr?.new}</div>
    </div>);
  }

  return '-';
};

const renderLastClicksNoLeads = (lastClicksNoLeads) => {
  if (lastClicksNoLeads) {
    return (<div className="d-flex align-items-center">
      <div>{lastClicksNoLeads?.was ? 'True' : 'False'}</div>
      <div className="mx-5">→</div>
      <div>{lastClicksNoLeads?.new ? 'True' : 'False'}</div>
    </div>);
  }

  return '-';
};

const renderRank = (rank) => {
  if (!rank) {
    return '-';
  }

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-column">
        <div>rank17: {rank?.was?.rank17?.toFixed(2)}</div>
        <div>rank21: {rank?.was?.rank21?.toFixed(2)}</div>
        <div>rank22: {rank?.was?.rank22?.toFixed(2)}</div>
      </div>
      <div className="mx-5">→</div>
      <div className="d-flex flex-column">
        <div>rank17: {rank?.new?.rank17?.toFixed(2)}</div>
        <div>rank21: {rank?.new?.rank21?.toFixed(2)}</div>
        <div>rank22: {rank?.new?.rank22?.toFixed(2)}</div>
      </div>
    </div>
  );
}

const columns = () => [{
  ...sortableColumn,
  dataField: 'timestamp', text: 'Date',
  formatter: (timestamp) => newDateFormatLong(timestamp),
  style: { width: '150px', minWidth: '150px', textAlign: 'left', verticalAlign: 'top' },
}, {
  ...sortableColumn,
  dataField: 'surveyId', text: 'Survey Id',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "230px" } }),
  formatter: (userId) => surveyIdLink(userId),
  style: { width: '250px', minWidth: '250px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'countryCode', text: 'Country',
  style: { width: '80px', minWidth: '80px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'provider', text: 'Provider',
  style: { width: '120px', minWidth: '120px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'createDate', text: 'Days Since Survey Created',
  formatter: (_cell) => renderDaysSinceSignUp(_cell),
  style: { width: '80px', minWidth: '80px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'reason', text: 'Reason',
  style: { width: '150px', minWidth: '150px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'cr', text: 'CR',
  formatter: (cr) => renderCR(cr),
  style: { width: '150px', minWidth: '150px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'preCalculatedRanks', text: 'Rank',
  formatter: (preCalculatedRanks) => renderRank(preCalculatedRanks),
  style: { width: '250px', minWidth: '250px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'lastClicksNoLeads', text: 'Last Clicks No Leads',
  formatter: (lastClicksNoLeads) => renderLastClicksNoLeads(lastClicksNoLeads),
  style: { width: '80px', minWidth: '80px', textAlign: 'left', verticalAlign: 'top' },
}];

const SurveyUpdatesLogReport = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'surveyUpdatesLogs', actions);
  const report = useSelector(state => state.surveyUpdatesLogs.report, shallowEqual);
  const didMount = useDidMount();

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: ['surveyId'],
    didMount,
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = columns();

  return (
    <Card>
      <CardContent>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <Pagination isLoading={false} paginationProps={paginationProps}>
              <BootstrapTable
                remote
                classes="table table-head-custom table-vertical-center overflow-hidden"
                headerClasses="table table-vertical-top"
                bootstrap4
                responsive
                keyField="_id"
                columns={reportColumns}
                data={report}
                filter={ filterFactory() }
                // rowStyle={ rowStyle }
                // expandRow={ expandRow }
                defaultSorted={[{
                  dataField: 'timestamp',
                  order: sortDirection || enums.sortDirections.desc,
                }]}
                onTableChange={onTableChange}
                {...paginationTableProps}
              >
              </BootstrapTable>
            </Pagination>
          )}
        </PaginationProvider>
      </CardContent>
    </Card>
  );
};

export default SurveyUpdatesLogReport;
