import config from '~/config';
import fetch from '~/redux/fetch';

const CREATE_WALLET_LEAD_TRANSACTION_PATH = '/miscellaneous/create-lead-transaction';

const createWalletLeadTransaction = async ({ userId, amount }) => {
  const response = await fetch(`${config.apiBaseUrl}${CREATE_WALLET_LEAD_TRANSACTION_PATH}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId,
      amount: Number(amount),
    }),
  });

  if (response.status !== 200) {
    throw new Error((await response.json()).error);
  }

  return response.json();
};

export default {
  createWalletLeadTransaction,
};

