import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('veriff-log');

actionTypes.ShowUser = 'veriff-log/show-user';
actionTypes.GenerateReportAsCsv = 'veriff-log/generate-report-as-csv';

actions.showUser = (userId) => ({
  type: actionTypes.ShowUser,
  payload: userId
});
actions.generateReportAsCsv = () => ({
  type: actionTypes.GenerateReportAsCsv,
  payload: {}
});

export {
  actionTypes,
  actions,
};
