import { fetchReportAsCsvHelper, fetchReportHelper } from '~/app/helpers';
import config from '~/config';
import fetch from '~/redux/fetch';

const FETCH_REPORT_URL = '/api-log';
const FETCH_REPORT_AS_CSV_URL = '/api-log-csv';
const CREATE_SHARED_LINK_URL = '/shared-link';

const createSharedLink = async (params) => {
  const response = await fetch(`${config.apiBaseUrl}${CREATE_SHARED_LINK_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ params }),
  });
  return response.json();
}

export default {
  fetchReport: fetchReportHelper(FETCH_REPORT_URL),
  fetchReportAsCsv: fetchReportAsCsvHelper(FETCH_REPORT_AS_CSV_URL,'apiLog'),
  createSharedLink,
};


