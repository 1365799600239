import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Redirect, Route,
  Switch,
  useParams,
} from "react-router-dom";
import {
  Card, CardContent, Grid
} from "@material-ui/core";
import SVG from "react-inlinesvg";
import { isAfter, parseISO } from 'date-fns';
import { actions } from "~/app/modules/User/actions";
import { toAbsoluteUrl } from "~/_metronic/_helpers";
import UserMenu from "./components/UserMenu";
import UserOverview from "./components/UserOverview"
import Profiler from "./components/Profiler";
import AccountInformation from "./components/AccountInformation";
import ClicksReport from "./components/ClicksReport";
import OrdersReport from "./components/OrdersReport";
import Notifications from "./components/Notifications";
import SecurityData from "./components/SecurityData";
import TargetedSurveys from "./components/TargetedSurveys";
import Acquisition from "./components/Acquisition";
import MobileAppApiLogs from './components/MobileAppApiLogs';
import config from '~/config';
import { newDateFormatLong, newDateFormat } from "../../helpers";
import { IconButton } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import ConfirmationModal from "./components/ConfirmationModal";
import enums from "~/app/helpers/enums";

export const tabs = [
  {
    view: enums.userTabs.userOverview,
    name: 'User Overview',
    component: (userId) => (<UserOverview userId={userId} />),
    icon: '/media/svg/icons/Layout/Layout-top-panel-5.svg'
  },
  {
    view: enums.userTabs.profiler,
    name: 'Profiler',
    component: (userId) => (<Profiler userId={userId} />),
    icon: '/media/svg/icons/General/User.svg'
  },
  {
    view: enums.userTabs.accountInformation,
    name: 'Account Information',
    component: (userId) => (<AccountInformation userId={userId} />),
    icon: '/media/svg/icons/Code/Info-circle.svg'
  },
  {
    view: enums.userTabs.targetedSurveys,
    name: 'Targeted Surveys',
    component: (userId) => (<TargetedSurveys userId={userId} />),
    icon: '/media/svg/icons/Map/Marker1.svg'
  },
  {
    view: enums.userTabs.clicksReport,
    name: 'Clicks Report',
    component: (userId) => (<ClicksReport userId={userId} />),
    icon: '/media/svg/icons/Files/File-done.svg'
  },
  {
    view: enums.userTabs.ordersReport,
    name: 'Orders Report',
    component: (userId) => (<OrdersReport userId={userId} />),
    icon: '/media/svg/icons/Shopping/Cart1.svg'
  },
  {
    view: enums.userTabs.notifications,
    name: 'Notifications',
    component: (userId) => (<Notifications userId={userId} />),
    icon: '/media/svg/icons/General/Notifications1.svg'
  },
  {
    view: enums.userTabs.securityData,
    name: 'Security Data',
    component: (userId) => (<SecurityData userId={userId} />),
    icon: '/media/svg/icons/General/Shield-protected.svg'
  },
  {
    view: enums.userTabs.acquisition,
    name: 'Acquisition',
    component: (userId) => (<Acquisition userId={userId} />),
    icon: '/media/svg/icons/General/Like.svg'
  },
  {
    view: enums.userTabs.mobileAppApiLogs,
    name: 'Mobile App API Logs',
    component: (userId) => (<MobileAppApiLogs userId={userId} />),
    icon: '/media/svg/icons/Text/Bullet-list.svg'
  },
];

const UserNavigation = () => {
  const params = useParams();
  const { userId } = params;

  return (
    <>
      <div className="flex-row-fluid">
        <Switch>
          <Redirect
            from={`/users/${userId}`}
            exact={true}
            to={`/users/${userId}/${tabs[0].view}`}
          />
          {tabs.map((tab) => (
            <Route
              key={tab.view}
              path={`/users/${userId}/${tab.view}`}
              render={() => tab.component(userId)}
            />
          ))}
        </Switch>
      </div>
    </>
  )
}

const renderEmailRow = (
  email,
  emailVerified,
  showModalForUnVerifyEmail,
  setShowModalForUnVerifyEmail,
  handleUnVerifyEmail
) => {
  if(!email) {
    return null
  }
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>{email}</Grid>
      {emailVerified && (
        <Grid item>
          <span className="svg-icon svg-icon-success h-20">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
            />
          </span>
          <IconButton onClick={() => {setShowModalForUnVerifyEmail(true)}}>
            <CancelIcon style={{ fill: '#3699FF', width: '25px', height: '25px' }} />
          </IconButton>
          <ConfirmationModal
            title="Un-verify Email Address"
            shown={showModalForUnVerifyEmail}
            setShown={setShowModalForUnVerifyEmail}
            confirmFunc={handleUnVerifyEmail}
          />
        </Grid>
      )}
    </Grid>
  )
}

const renderUserNames = (firstName, lastName) => {
  if(!firstName && !lastName) {
    return null;
  }
  return (
    <Grid container>
      {firstName} {lastName}
    </Grid>
  )
}

const renderUserAgeAndGender = (age, gender) => {
  if(!age && !gender) {
    return null;
  }
  return (
    <Grid container>
      {age && `${age}, `}{gender}
    </Grid>
  )
}

const onShadowLogin = (userId) => () => {
  window.open(`${config.apiBaseUrl}/users/${userId}/shadowLogin`);
};

const renderCountryAndShadowLogin = (countryCode, countryName, userId) => {
  const countryFlag = countryName ? `/media/svg/flags/${countryName.toLowerCase()}.svg` : ''
  return (
    <Grid className="mt-2 mb-5" container alignItems="center" justifyContent="space-between" direction="row">
      <span className="d-flex align-items-center">
        {countryCode}
        {countryFlag && (
          <SVG
            alt="Logo"
            className="min-w-20px ml-3 max-w-20px"
            src={toAbsoluteUrl(countryFlag)}
          />
        )}
      </span>
      <button type="button" className="btn btn-primary btn-sm" onClick={onShadowLogin(userId)}>Shadow login</button>
    </Grid>
  )
}

const UserBanStatus = () => {
  const userInformation = useSelector(state => state.user.userInformation);

  let userStatus = 'Not active';

  if (userInformation.shadowBanned) {
    userStatus = 'Banned';
  } else if(userInformation.user.emailVerifiedAt) {
    userStatus = 'Active';
  }

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>User status</Grid>
        <Grid item>{userStatus}</Grid>
      </Grid>
      { userInformation.shadowBanned && (
        <span className="d-flex justify-content-center align-items-center bg-light-danger border border-danger rounded py-3 text-danger my-2 flex-wrap">
          { userInformation.user.bannedAt ? (
            <span>
              Hard banned
              {(userInformation.user?.marks?.manually_banned === true) && '(manually)'}
              on <span className="ml-1">{newDateFormatLong(userInformation.user.bannedAt, '', true)}</span>
            </span>
          ) : (
            <span>
              Shadow banned
            </span>
          )}
        </span>
      )}
    </>
  );
}

const MainButtonsRow = () => {
  const dispatch = useDispatch();
  const userInformation = useSelector(state => state.user.userInformation);
  const [showModalForWhitelist, setShowModalForWhitelist] = useState(false);
  const [showModalForBanUser, setShowModalForBanUser] = useState(false);
  const [showModalForDeleteUser, setShowModalForDeleteUser] = useState(false);

  const handleWhitelistClick = () => {
    setShowModalForWhitelist(true);
  };

  const confirmChangeWhitelist = () => {
    dispatch(
      actions.updateMassOrderingWhitelistStatus(
        userInformation.user._id, { whitelistStatus: !Boolean(userInformation.user?.marks?.mass_ordering_whitelist) },
      ),
    );
  };

  const handleBanClick = () => {
    setShowModalForBanUser(true);
  };

  const confirmBanUser = () => {
    if (userInformation.user.bannedAt) {
      dispatch(
        actions.updateUserRestrictions(userInformation.user._id, { restrictionType: 'unban' }),
      );
    } else if (userInformation.shadowBanned) {
      dispatch(
        actions.shadowUnbanUser(userInformation.user._id),
      );
    } else {
      dispatch(
        actions.updateUserRestrictions(userInformation.user._id, { restrictionType: 'ban' }),
      );
    }
  };

  const handleDeleteClick = () => {
    setShowModalForDeleteUser(true);
  };

  const confirmDeleteUser = () => {
    dispatch(actions.deleteUser(userInformation.user._id));
  };

  return (
    <Grid container justifyContent="space-between">
      <button
        type="button"
        className="btn btn-success btn-md min-w-30%"
        onClick={handleWhitelistClick}
      >
        Whitelist
      </button>
      <button
        type="button"
        className="btn btn-success btn-md min-w-30%"
        onClick={handleBanClick}
      >
        { userInformation.user.bannedAt || userInformation.shadowBanned ? 'Unban' : 'Ban' }
      </button>
      <button
        type="button"
        className="btn btn-success btn-md min-w-30%"
        onClick={handleDeleteClick}
      >
        Delete
      </button>
      <ConfirmationModal
        title="Whitelist change"
        shown={showModalForWhitelist}
        setShown={setShowModalForWhitelist}
        confirmFunc={confirmChangeWhitelist}
      />
      <ConfirmationModal
        title="Ban/Unban user"
        shown={showModalForBanUser}
        setShown={setShowModalForBanUser}
        confirmFunc={confirmBanUser}
      />
      <ConfirmationModal
        title="Delete user"
        shown={showModalForDeleteUser}
        setShown={setShowModalForDeleteUser}
        confirmFunc={confirmDeleteUser}
      />
    </Grid>
  );
};

const LeftColumn = () => {
  const userInformation = useSelector(state => state.user.userInformation);
  const dispatch = useDispatch();
  const params = useParams();
  const { userId } = params;

  const [showModalForUnVerifyEmail, setShowModalForUnVerifyEmail] = useState(false);

  const handleUnVerifyEmail = () => {
    dispatch(actions.unVerifyEmail(userId));
  }

  if(!userInformation.user) {
    return null
  }

  const calcLastSeenDate = (user) => {
    if (user) {
      if (user.updatedAt && user.lastActivityTrackedAt) {
        if (isAfter(parseISO(user.updatedAt), parseISO(user.lastActivityTrackedAt))) {
          return newDateFormat(userInformation.user.updatedAt);
        }
        return newDateFormat(userInformation.user.lastActivityTrackedAt);
      }
      if (user.updatedAt) {
        return newDateFormat(userInformation.user.updatedAt);
      }

      if (user.lastActivityTrackedAt) {
        return newDateFormat(userInformation.user.lastActivityTrackedAt);
      }
    }

    return '-';
  }

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        {renderEmailRow(
          userInformation.user.email,
          !!userInformation.user.emailVerifiedAt,
          showModalForUnVerifyEmail,
          setShowModalForUnVerifyEmail,
          handleUnVerifyEmail
        )}
        <Grid item>
          {userInformation.user._id}
        </Grid>
        {renderUserNames(userInformation.user.firstName, userInformation.user.lastName)}
        {renderUserAgeAndGender(userInformation.user.age, userInformation.user.gender)}
        {renderCountryAndShadowLogin(userInformation.user.countryISO, userInformation.user.countryName, userId)}
        <Grid container justifyContent="space-between">
          <Grid item>Sign up date</Grid>
          <Grid item>{newDateFormat(userInformation.user.createdAt)}</Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>Last seen date</Grid>
          {userInformation.user && (
            <Grid item>{calcLastSeenDate(userInformation.user)}</Grid>
          )}
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>User segment</Grid>
          <Grid item>-</Grid>
        </Grid>
        <UserBanStatus />
        <UserMenu />
        <MainButtonsRow />
      </CardContent>
    </Card>
  )
}

const User = () => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(actions.getUserInformation(params.userId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6} lg={3}>
        <LeftColumn />
      </Grid>
      <Grid item xs={12} md={6} lg={9}>
        <UserNavigation />
      </Grid>
    </Grid>
  );
}

export default User;
