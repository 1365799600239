import React, {useEffect, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory  from 'react-bootstrap-table2-paginator';
import filterFactory, {customFilter, selectFilter} from 'react-bootstrap-table2-filter';
import { actions } from '~/app/modules/Translations/components/Features/actions';
import { actions as featureActions } from '~/app/modules/Translations/components/Feature/actions';
import { sortCaret } from '~/_metronic/_helpers';
import { injectIntl } from "react-intl";
import {
  textFilterOnBlurHelper,
  newDateFormatInUTC
} from '~/app/helpers';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ProgressBar from "../CustomProgressBar";
import {
  Button,
  Card,
  CardContent,
} from "@material-ui/core";
import StatusField from "../StatusField";
import {Link} from "react-router-dom";
import CreateFeatureModal from "../CreateFeatureModal";
import { sizePerPageRenderer } from '~/app/helpers';
import { isAfter } from 'date-fns';
import enums from "~/app/helpers/enums";

const sortableColumn = {
  sort: true, sortCaret,
};

const renderLanguagesField = (languages) => {
  if (!languages?.length) {
    return <div />;
  }

  return (
    <div className="d-flex flex-wrap">
      {languages.map((language, index) => (
        <span
          key={index}
        >
          {language}{index + 1 < languages.length && ', '}
        </span>
      ))}
    </div>
  )
};

const renderNameField = (row, user, dispatch) => {
  const handleLinkClick = () => {
    dispatch(featureActions.setSelectedLanguages([]));
  }

  if (user?.role) {
    return (
      <div className="d-flex">
        <Link onClick={handleLinkClick} to={`/translations/features/${row._id}`}>
          {row.name}
        </Link>
      </div>
    );
  }

  if (row.isExpand) {
    return (
      <div className="d-flex">
        <span className="text-primary min-w-15px">▼</span>
        <Link onClick={handleLinkClick} to={`/translations/features/${row._id}`}>
          {row.name}
        </Link>
      </div>
    );
  }

  return (
    <div className="d-flex">
      <span className="text-primary min-w-15px">▶</span>
      <Link onClick={handleLinkClick} to={`/translations/features/${row._id}`}>
        {row.name}
      </Link>
    </div>
  );
};

const statusOptions = [ ...Object.values(enums.projectStatuses).map((projectStatus) => (
  { value: projectStatus, label: projectStatus })),
  { value: '', label: 'All' },
];

const renderDueDateField = (dueDate, row) => {
  if (!dueDate) {
    return '';
  }

  if (row.progress < 100 && !isAfter(new Date (dueDate), new Date())) {
    return (
      <div className="p-3 text-danger bg-light-danger d-flex justify-content-center align-items-center rounded">
        {newDateFormatInUTC(dueDate)}
      </div>
    );
  }

  return (
    <div className="p-3 text-primary bg-light-primary d-flex justify-content-center align-items-center rounded">
      {newDateFormatInUTC(dueDate)}
    </div>
  );
};

const columns = (filters, productOptions, user, dispatch) => [{
  ...sortableColumn,
  dataField: 'name', text: 'Feature Name',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }}),
  formatter: (_cell, row) => renderNameField(row, user, dispatch),
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'productName', text: 'Product',
  filter: selectFilter({
    defaultValue: filters['productName'] || '',
    placeholder: ' ',
    style: { width: '100px' },
    options: productOptions,
  }),
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
}, {
  dataField: 'languages', text: 'Languages',
  formatter: (languages) => renderLanguagesField(languages),
  style: { width: '200px', minWidth: '200px', textAlign: 'left' },
}, {
  dataField: 'keyCount', text: 'Keys',
  style: { width: '100px', minWidth: '100px', textAlign: 'left' },
}, {
  dataField: 'progress', text: 'Overall Progress',
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
  formatter: (progress, row) => (<ProgressBar percent={row.progress} approvedPercent={row.approvedProgress} />),
}, {
  ...sortableColumn,
  dataField: 'dueDate', text: 'Due Date',
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
  formatter: (dueDate, row) => renderDueDateField(dueDate, row),
}, {
  ...sortableColumn,
  dataField: 'status', text: 'Status',
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: statusOptions,
  }),
  formatter: (status) => (<StatusField status={status} />)
}];

const Features = ({ intl }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const report = useSelector(state => state.features.report, shallowEqual);
  const products = useSelector(state => state.features.products);
  const filters = useSelector(state => state.features.filters);
  const [dataWithExpanded, setDataWithExpanded] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [shown, setShown] = useState(false);

  const productOptions = [
    ...products.map((product) => ({ value: product.name, label: product.name })), { value: '', label: 'All' }
    ];

  useEffect(() => {
    dispatch(actions.getFeatures());
    dispatch(actions.getAvailableParams());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDataWithExpanded(report);
  }, [setDataWithExpanded, report]);

  const handleOnExpand = (row, isExpand) => {
    if (!row.isExpand) {
      const newData = dataWithExpanded.map((dataRow) => {
        if (row._id === dataRow._id) {
          return {...dataRow, isExpand};
        }
        return dataRow;
      })
      setDataWithExpanded(newData);
      setExpanded([...expanded, row._id]);
    } else {
      const newData = dataWithExpanded.map((dataRow) => {
        if (row._id === dataRow._id && row.isExpand) {
          const newDataRow = {...dataRow};
          delete newDataRow.isExpand;
          return newDataRow;
        }
        return dataRow;
      })
      setDataWithExpanded(newData);
      setExpanded(expanded.filter((_id) => (row._id !== _id)));
    }
  }

  const expandRow = {
    renderer: row => {
      return (
        <div>
          <div className="d-flex">
            <h4 className="min-w-180px">Language</h4>
            <h4 className="ml-3 min-w-180px">Progress</h4>
            <h4 className="ml-20 min-w-180px">Translator</h4>
            <h4 className="ml-20 min-w-180px">Status</h4>
          </div>
          {row.featureLanguages.map((featureLanguage) => (
            <div key={featureLanguage?.language} className="min-h-40px border-top d-flex w-100">
              <div className="min-w-180px w-180px h-40px d-flex align-items-center">{featureLanguage?.language}</div>
              <div className="ml-3 min-w-180px w-180px h-40px d-flex align-items-center">
                <ProgressBar percent={featureLanguage?.progress} approvedPercent={featureLanguage?.approvedProgress}/>
              </div>
              <div className="ml-20 min-w-180px w-180px h-40px text-muted d-flex align-items-center">
                {featureLanguage?.translator}
              </div>
              <div className="ml-20 min-w-180px w-180px h-40px d-flex align-items-center">
                <StatusField status={featureLanguage?.status}/>
              </div>
            </div>
          ))}
        </div>
      );
    },
    expanded,
    onExpand: handleOnExpand,
  };

  const rowStyle = (row) => {
    if (row.isExpand) {
      return { cursor: 'pointer', background: '#f1f1f1' };
    }
    return { cursor: 'pointer' };
  };

  const reportColumns = productOptions.length > 1 && columns(filters, productOptions, user, dispatch);
  const handleAddTranslator = () => {
    if (selectedRow._id) {
      setSelectedRow({});
    }
    setShown(true);
  }

  return (
    <div>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center min-h-40px">
          <h3 className="pl-2">Features</h3>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-center flex-column">
        <Card>
          <CardContent className="border text-dark border-light-dark rounded">
            {!user.role && (
              <div className="w-100 d-flex justify-content-end">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleAddTranslator}
                  className="bg-primary text-white"
                >
                  Create Feature
                </Button>
              </div>
            )}
            {reportColumns && (
              <BootstrapTable
                classes="table table-head-custom table-vertical-center overflow-hidden"
                headerClasses="table table-vertical-top"
                wrapperClasses="rounded my-2"
                bootstrap4
                responsive
                keyField="_id"
                columns={reportColumns}
                data={dataWithExpanded}
                filter={ filterFactory() }
                defaultSorted={[{
                  dataField: 'name',
                  order: enums.sortDirections.desc,
                }]}
                rowStyle={ user?.role ? {} : rowStyle }
                expandRow={ user?.role ? {} : expandRow }
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageRenderer,
                  sizePerPageList:[
                    { text: '10', value: 10,},
                    { text: '20', value: 20, },
                    { text: '50', value: 50, },
                    { text: '100', value: 100, },
                    { text: '500', value: 500, }
                  ],
                })}
              />
            )}
          </CardContent>
        </Card>
      </div>
      <CreateFeatureModal
        setShown={setShown}
        shown={shown}
        selectedRow={selectedRow}
        intl={intl}
        actions={actions}
        stateKey={'features'}
      />
    </div>
  );
};

export default injectIntl(Features);
