import React, { useState } from "react";
import JSONPretty from "react-json-pretty";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { selectFilter, customFilter } from 'react-bootstrap-table2-filter';
import { Card, CardContent } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Pagination } from "~/_metronic/_partials/controls";
import { sortCaret } from "~/_metronic/_helpers";
import {
  newDateFormatLong,
  onTableChangeHelper,
  textFilterOnBlurHelper,
  useDidMount,
  usePaginationFilteringAndSorting
} from "~/app/helpers";
import { actions } from "~/app/modules/TolunaNotificationsLog/actions";
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const endpointOptions = [
  { value: 'quota', label: 'quota' },
  { value: 'survey', label: 'survey' },
];

const sortableColumn = {
  sort: true, sortCaret,
};

const formatMessages = (messages) => {
  return (
    <>
    { messages.map((m, idx) => (
      <div key={idx}>{m}</div>
    ))}
    </>
  );
};

const RawNotificatonJsonCell = ({ data }) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => setExpanded(!expanded);

  const caret = expanded ? (
    <span className="fa fa-caret-down" />
  ) : (
    <span className="fa fa-caret-right" />
  );

  return (
    <>
      <div onClick={toggle} style={{ cursor: "pointer" }}>
        { caret } &nbsp;
        Show
      </div>
      { expanded && (
        <JSONPretty
          style={{ display: 'inline-block' }}
          id="json-pretty-paypal"
          json={data}
        />
      )}
    </>
  );
};

const columns = () => [{
  ...sortableColumn,
  dataField: 'timestamp', text: 'Date',
  formatter: (timestamp) => newDateFormatLong(timestamp),
  style: { width: '150px', minWidth: '150px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'endpoint', text: 'Endpoint',
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '120px' },
    options: endpointOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '80px', minWidth: '80px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'surveyId', text: 'SurveyID',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" } }),
  style: { width: '120px', minWidth: '120px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'waveId', text: 'WaveID',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" } }),
  style: { width: '120px', minWidth: '120px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'quotaId', text: 'QuotaID',
  style: { width: '120px', minWidth: '120px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'messages', text: 'Messages',
  formatter: (messages) => formatMessages(messages),
  style: { width: '250px', minWidth: '250px', textAlign: 'left', verticalAlign: 'top' },
}, {
  dataField: 'rawJson', text: 'Raw Notification',
  formatter: (json) => <RawNotificatonJsonCell data={json} />,
  style: { width: '150px' },
}];

const TolunaNotificationsLogReport = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'tolunaNotificationsLog', actions);
  const report = useSelector(state => state.tolunaNotificationsLog.report, shallowEqual);
  const didMount = useDidMount();

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: ['surveyId', 'waveId', 'endpoint'],
    didMount,
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = columns();

  return (
    <Card>
      <CardContent>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <Pagination isLoading={false} paginationProps={paginationProps}>
              <BootstrapTable
                remote
                classes="table table-head-custom table-vertical-center overflow-hidden"
                headerClasses="table table-vertical-top"
                bootstrap4
                responsive
                keyField="_id"
                columns={reportColumns}
                data={report}
                filter={ filterFactory() }
                // rowStyle={ rowStyle }
                // expandRow={ expandRow }
                defaultSorted={[{
                  dataField: 'timestamp',
                  order: sortDirection || enums.sortDirections.desc,
                }]}
                onTableChange={onTableChange}
                {...paginationTableProps}
              >
              </BootstrapTable>
            </Pagination>
          )}
        </PaginationProvider>
      </CardContent>
    </Card>
  );
};

export default TolunaNotificationsLogReport;

