import React, {useEffect} from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { customFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Pagination } from '~/_metronic/_partials/controls';
import { actions } from '~/app/modules/ClicksReport/actions';
import { sortCaret } from '~/_metronic/_helpers';
import {
  newDateFormatLong,
  onTableChangeHelper,
  usePaginationFilteringAndSorting,
  textFilterOnBlurHelper,
  JsonCell,
  useDidMount, textFilterOnBlurHelperWithAutoComplete,
} from '~/app/helpers';
import _ from 'lodash';
import cs from 'classnames';
import { findTimeFromMS } from '~/app/helpers/common';
import { Tooltip } from '@material-ui/core';
import {Link, useHistory, useParams} from "react-router-dom";
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const statusOptions = [...Object.values(enums.clickStatus), 'all'].map((status) => ({
  value: status, label: status
}));

const sortableColumn = {
  sort: true, sortCaret,
};

const providersOptions = [...enums.surveysProvidersValues.map((name) => ({
  value: name, label: name,
})), { value: 'all', label: 'All'}];

const appIdOptions = [...Object.keys(enums.appNameById).map((id) => ({ value: id, label: enums.appNameById[id] })), {
  value: 'all', label: 'All'
}];

const platformOptions = ['all', ...Object.values(enums.clickFamousPlatform), 'other'].map((option) => ({
  value: option, label: option,
}));

const dropoutTopicIdOptions = [
  ...Object.keys(enums.surveyFeedbackOptions).map((key) => ({ value: Number(key), label: enums.surveyFeedbackOptions[key] })),
  { value: 'all', label: 'All' }
];

const hasLeadsOptions = [{
  value: 'all', label: 'All',
}, {
  value: 'true', label: 'True',
}, {
  value: 'false', label: 'False'
}];

const userIdLink = (_id) => (
  <div>
    <Link
      target="_blank"
      to={`/users/${_id}`}
    >
      {_id}
    </Link>
  </div>
);

const renderStatusField = (status) => {
  if (!status) {
    return '';
  }
  return (
    <div
      className={cs('px-3 py-2 rounded d-flex justify-content-center align-items-center', {
        'bg-light-secondary text-secondary': status === 'pending' || status === 'banned' || status === 'stopped',
        'bg-light-warning text-warning': status === 'preliminaryFailed',
        'bg-light-info text-info': status === 'dq',
        'bg-light-success text-success': status === 'success',
        'bg-light-danger text-danger': status === 'reversed',
      })}
    >
      {_.capitalize(status)}
    </div>
  );
};

const renderSurveyIdField = (surveyId) => (
  <div>
    <Link
      target="_blank"
      to={`/surveys/${surveyId}`}
    >
      {surveyId}
    </Link>
  </div>
);

const renderDropoutTopicField = (row) => {
  if (enums.surveyFeedbackOptions[row?.feedbackTopicId] === enums.surveyFeedbackOptions[4]) {
    return (
      <Tooltip title={<div className="font-size-lg p-2">{row?.feedbackText}</div> || ''} placement="top-start">
        <div>{enums.surveyFeedbackOptions[row?.feedbackTopicId]}</div>
      </Tooltip>
    );
  }

  return <div>{enums.surveyFeedbackOptions[row?.feedbackTopicId]}</div>;
}

const columns = (filters, useCountryFieldSort) => [{
  ...sortableColumn,
  dataField: 'clickDate', text: 'Click Date',
  formatter: (date) => newDateFormatLong(date),
  style: { minWidth: '120px', width: '120px', textAlign: 'left'},
}, {
  ...sortableColumn,
  dataField: '_id', text: 'Internal Click ID',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "150px" }, defaultValue: filters['_id'] || ''}),
  style: { width: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'internalUserId', text: 'Internal User ID',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "150px" }, defaultValue: filters['internalUserId'] || ''}),
  style: { width: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'clickId', text: 'Publisher Click ID',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "150px" }, defaultValue: filters['clickId'] || ''}),
  style: { width: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'userId', text: 'Publisher User ID',
  formatter: (userId) => userIdLink(userId),
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "150px" }, defaultValue: filters['userId'] || ''}),
  style: { width: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'appId', text: 'App Name',
  filter: selectFilter({
    defaultValue: filters['appId'] || 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: appIdOptions,
    withoutEmptyOption: true,
  }),
  formatter: (appId) => (enums.appNameById[appId]),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'userCountryCode', text: 'User Country',
  sort: useCountryFieldSort,
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelperWithAutoComplete(
    onFilter,
    column,
    {style: { width: "100px" }, defaultValue: filters['userCountryCode'] || ''},
    actions,
    'clicksReport'
  ),
  style: { width: '120px', textAlign: 'left' },
},{
  dataField: 'hasLeads', text: 'Has Leads',
  filter: selectFilter({
    defaultValue: filters['hasLeads'] || 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: hasLeadsOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '120px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'surveyId', text: 'Internal Survey ID',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }, defaultValue: filters['surveyId'] || ''}),
  formatter: (surveyId) => renderSurveyIdField(surveyId),
  style: { width: '150px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'surveyUniqueNumber', text: 'Provider Survey ID',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }, defaultValue: filters['surveyUniqueNumber'] || ''}),
  style: { width: '150px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'status', text: 'Status',
  filter: selectFilter({
    defaultValue: filters['status'] || 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: statusOptions,
    withoutEmptyOption: true,
  }),
  formatter: (status) => renderStatusField(status),
  style: { width: '120px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'surveyCPI', text: 'CPI',
  style: { width: '100px', textAlign: 'left' },
  formatter: (cpi) => cpi ? cpi?.toFixed(2) : ''
},{
  ...sortableColumn,
  filter: selectFilter({
    defaultValue: filters['surveyProvider'] || 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: providersOptions,
    withoutEmptyOption: true,
  }),
  dataField: 'surveyProvider', text: 'Survey Provider',
  style: { width: '100px', textAlign: 'left' },
},{
  dataField: 'surveyAccountName', text: 'Survey Sub Provider',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }, defaultValue: filters['surveyAccountName'] || ''}),
  style: { width: '150px', textAlign: 'left' },
},{
  dataField: 'origin', text: 'Origin',
  style: { width: '150px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'platform', text: 'Platform',
  filter: selectFilter({
    defaultValue: filters['platform'] || 'all',
    placeholder: ' ',
    style: { width: '130px' },
    options: platformOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '150px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'mobileAppVersion', text: 'App version',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }, defaultValue: filters['mobileAppVersion'] || ''}),
  style: { width: '150px', textAlign: 'left' },
},{
  dataField: 'experimentId', text: 'Experiment',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }, defaultValue: filters['experimentId'] || ''}),
  style: { width: '150px', textAlign: 'left' },
},{
  dataField: 'ip', text: 'User IP',
  style: { width: '100px', textAlign: 'left' },
},{
  dataField: 'clientInfo', text: 'Client Info',
  formatter: (clientInfo) => <JsonCell
    data={clientInfo}
    title="Client Info"
    length={25}
    styles={{ fontSize: '15px' }}
  />,
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'surveyRank', text: 'Survey Rank',
  type: 'number',
  formatter: (surveyRank) => (surveyRank?.toFixed(2) || ''),
  style: { width: '100px', textAlign: 'left' },
}, {
  dataField: 'surveyRank21', text: 'Rank 21',
  type: 'number',
  style: { width: '100px', textAlign: 'left' },
  formatter: (rank) => (rank?.toFixed(2) || '-'),
}, {
  dataField: 'surveyRank17', text: 'Rank 17',
  type: 'number',
  style: { width: '100px', textAlign: 'left' },
  formatter: (rank) => (rank?.toFixed(2) || '-'),
}, {
  ...sortableColumn,
  dataField: 'surveyRankType', text: 'Sorting Algo',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "100px" }, defaultValue: filters['surveyRankType'] || ''}),
  style: { width: '120px', textAlign: 'left' },
},{
  dataField: 'redirectSource', text: 'Click Origin',
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
},{
  dataField: 'clickInitiator', text: 'Click Initiator',
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'leadDate', text: 'Lead Date',
  formatter: (date) => newDateFormatLong(date),
  style: { minWidth: '120px', width: '120px', textAlign: 'left'},
},{
  ...sortableColumn,
  dataField: 'elapsedTimeMs', text: 'Actual LOI',
  type: 'number',
  formatter: (elapsedTimeMs) => findTimeFromMS(elapsedTimeMs),
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'initialStatus', text: 'Provider Click Status',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }, defaultValue: filters['initialStatus'] || ''}),
  style: { width: '150px', textAlign: 'left' },
},{
  dataField: 'dqReason', text: 'Provider Click Term',
  style: { width: '100px', textAlign: 'left' },
},{
  dataField: 'termQualificationId', text: 'DQ Qualification ID',
  style: { width: '100px', textAlign: 'left' },
},{
  dataField: 'dqQuotaId', text: 'DQ Quota ID',
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'providerRedirectUrl', text: 'Provider Redirect URL',
  type: 'number',
  formatter: (providerRedirectUrl) => <JsonCell
    data={providerRedirectUrl}
    length={25}
    title="Provider Redirect URL"
    styles={{ fontSize: '15px' }}
  />,
  style: { width: '100px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'stoppedReason', text: 'Stopped Reason',
  style: { width: '100px', textAlign: 'left' },
},{
  filter: selectFilter({
    defaultValue: filters['dropoutTopic'] || 'all',
    placeholder: ' ',
    style: { width: '180px' },
    options: dropoutTopicIdOptions,
    withoutEmptyOption: true,
  }),
  dataField: 'feedbackTopicId', text: 'Dropout Topic',
  style: { width: '200px', textAlign: 'left' },
  formatter: (feedbackTopicId, row) => renderDropoutTopicField(row),
},{
  dataField: 'feedbackText', text: 'Dropout Text',
  style: { width: '200px', minWidth: '200px', textAlign: 'left' },
  formatter: (providerRedirectUrl) => <JsonCell
    data={providerRedirectUrl}
    length={200}
    title="Dropout Text"
    styles={{ fontSize: '15px' }}
  />,
},{
  dataField: 'failRedHerring', text: 'Fail Red Herring',
  formatter: (failRedHerring) => (_.capitalize(String(failRedHerring))),
  style: { width: '100px', textAlign: 'left' },
},{
  dataField: 'redirectUrl', text: 'Redirect URL',
  formatter: (redirectUrl) => <JsonCell
    data={redirectUrl}
    length={25}
    title="Redirect URL"
    styles={{ fontSize: '15px' }}
  />,
  style: { width: '100px', textAlign: 'left' },
}];


const useUrlUserId = (dispatch) => {
  const didMount = useDidMount();
  const { userId, surveyId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!didMount && userId) {
      dispatch(actions.setUserId(userId));
      history.push('/clicks');
    }
    if (!didMount && surveyId) {
      dispatch(actions.setSurveyId(surveyId));
      history.push('/clicks');
    }
  }, [didMount, userId, surveyId, history, dispatch]);
};

const ClicksReportReport = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'clicksReport', actions);
  const report = useSelector(state => state.clicksReport.report, shallowEqual);
  const useCountryFieldSort = useSelector((state) => state.clicksReport.useCountryFieldSort);

  useEffect(() => {
    const clicksReportData = window.localStorage.getItem('clicksReportFilters');
    if(clicksReportData) {
      dispatch(actions.setParams(JSON.parse(clicksReportData)));
      window.localStorage.removeItem('clicksReportFilters');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useUrlUserId(dispatch);

  const didMount = useDidMount();

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: ['_id', 'internalUserId', 'clickId', 'userId', 'appId', 'userCountryCode', 'surveyId',
      'experimentId', 'surveyUniqueNumber', 'surveyProvider', 'surveyName', 'publisherId', 'initialStatus', 'status',
      'platform', 'mobileAppVersion','surveyAccountName', 'hasLeads', 'surveyRankType', 'feedbackTopicId'],
    didMount,
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = columns(filters, useCountryFieldSort);

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => (
        <Pagination isLoading={false} paginationProps={paginationProps}>
          <BootstrapTable
            remote
            classes="table table-head-custom table-vertical-center overflow-hidden"
            headerClasses="table table-vertical-top"
            bootstrap4
            responsive
            keyField="_id"
            columns={reportColumns}
            data={report}
            filter={ filterFactory() }
            defaultSorted={[{
              dataField: sortBy || 'clickDate',
              order: sortDirection || enums.sortDirections.desc,
            }]}
            onTableChange={onTableChange}
            {...paginationTableProps}
          >
          </BootstrapTable>
        </Pagination>
      )}
    </PaginationProvider>
  );
};

export default ClicksReportReport;
