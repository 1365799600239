import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { actions } from "~/app/modules/User/actions";
import {
  Card, CardContent, Grid
} from "@material-ui/core";
import { newDateFormat } from "../../../../helpers";
import BootstrapTable from "react-bootstrap-table-next";

function BrowserStatisticsTable({entities}) {
  const columns = [
    { dataField: "item", text: "ITEM" },
    { dataField: "sent", text: "SENT" },
    { dataField: "clicked", text: "CLICKED" },
    { dataField: "convertedToLeads", text: "CONVERTED" },
    { dataField: "cr", text: "CR" },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={false}
      borderless={true}
      classes="table table-vertical-top overflow-hidden"
      bootstrap4
      size="sm"
      responsive="lg"
      headerClasses="bg-light rounded"
      remote
      keyField="item"
      data={entities}
      columns={columns}
    >
    </BootstrapTable>
  );
}

const BrowserStatisticsCard = () => {
  const userNotificationsStats = useSelector((state) => state.user.userNotificationsStats);
  const userNotifications = useSelector((state) => state.user.userNotifications);

  const findEntities = () => {
    if(!userNotifications.length || !userNotificationsStats.length) {
      return [];
    }

    return [{
      item: 'Total Notifications',
      sent: userNotifications.length,
      clicked: userNotificationsStats[0].pushClicksCount,
      convertedToLeads: userNotificationsStats[0].pushLeadsCount,
      cr: `${(userNotificationsStats[0].pushLeadsCount / userNotificationsStats.length * 100).toFixed(0)}%`
    }];
  }

  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded pb-20">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          Browser Notifications Statistics
        </h3>
        <BrowserStatisticsTable entities={findEntities()} />
      </CardContent>
    </Card>
  )
}

function BrowserReportTable({ entities }) {
  const columns = [
    { dataField: "_id", text: "Notification ID" },
    { dataField: "sent", text: "Send Date", formatter: (value) => newDateFormat(value) },
    { dataField: "clicked", text: "Clicked", formatter: (clicked) => !!clicked ? 'Yes' : 'No' },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={true}
      classes="table table-vertical-top overflow-hidden"
      bootstrap4
      size="sm"
      responsive="lg"
      remote
      keyField="_id"
      data={entities}
      columns={columns}
    >
    </BootstrapTable>
  );
}

const BrowserReportCard = () => {
  const userNotifications = useSelector((state) => state.user.userNotifications);

  const findReportEntities = () => {
    if(!userNotifications.length) {
      return [];
    }

    return userNotifications.map((notification) => {
      return {
        _id: notification._id,
        sent: notification.createdAt,
        clicked: notification.clickedAt,
      }
    })
  }

  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded pb-20">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          Browser Notifications Report
        </h3>
        <BrowserReportTable entities={findReportEntities()} />
      </CardContent>
    </Card>
  )
}


const NotificationsBrowser = ({ userId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getUserNotifications(userId));
    dispatch(actions.getUserNotificationsStats(userId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <BrowserStatisticsCard />
      </Grid>
      <Grid item xs={12}>
        <BrowserReportCard />
      </Grid>
    </Grid>
  )
};

export default NotificationsBrowser;
