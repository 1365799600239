import React, { useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {actions} from "~/app/modules/Rewards/components/RewardsManagement/actions";
import {CheckBox, CheckBoxOutlineBlank, CloseOutlined} from "@material-ui/icons";
import PopupWithKeySelecting from "~/app/modules/Rewards/components/RewardsManagement/PopupWithKeySelecting";

const RibbonField = () => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const dispatch = useDispatch();

  const handleClickRibbon = () => {
    dispatch(actions.changeCurrentRewardFields({ featured: !currentReward?.featured }));
  };

  return (
    <div onClick={handleClickRibbon} className="d-flex w-700px align-items-center mb-3 cursor-pointer">
      <div className="w-200px">Ribbon</div>
      <div>
        {currentReward?.featured ? <CheckBox color="secondary" /> : <CheckBoxOutlineBlank color="secondary" /> }
      </div>
    </div>
  );
};

const GenerateBarcodeField = () => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const dispatch = useDispatch();

  const handleClickRibbon = () => {
    dispatch(actions.changeCurrentRewardFields({ generateBarcode: !currentReward?.generateBarcode }));
  };

  return (
    <div onClick={handleClickRibbon} className="d-flex w-700px align-items-center mb-3 cursor-pointer">
      <div className="w-200px">Generate barcode</div>
      <div>
        {currentReward?.generateBarcode ? <CheckBox color="secondary" /> : <CheckBoxOutlineBlank color="secondary" /> }
      </div>
    </div>
  );
};

const RibbonTextField = () => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const dispatch = useDispatch();
  const [shown, setShown] = useState(false);

  const handleRemoveKey = () => {
    dispatch(actions.changeCurrentRewardFields({ ribbonTextKey: '' }));
  }

  const handleChangeRibbonText = (key) => {
    dispatch(actions.changeCurrentRewardFields({ ribbonTextKey: key }));
  };

  return (
    <>
      <div className="d-flex w-700px align-items-center mb-3">
        <div className="w-300px">Ribbon Text</div>
        <button
          type="button"
          className="btn w-100px h-40px btn-primary btn-md ml-5 mr-3"
          onClick={() => {setShown(true)}}
        >
          Add Key
        </button>
        <div className="w-300px h-40px p-2 rounded border bg-light-secondary d-flex align-items-center">
          {currentReward?.ribbonTextKey || ''}
          {currentReward?.ribbonTextKey && (
            <CloseOutlined className="ml-auto cursor-pointer" onClick={handleRemoveKey} />
          )}
        </div>
      </div>
      {shown && (
        <PopupWithKeySelecting
          defaultKey={'ribbonTextKey'}
          title={'Choose key for *Ribbon*'}
          shown={shown}
          setShown={setShown}
          onChange={handleChangeRibbonText}
        />
      )}
    </>
  );
};

const ExpirationDateField = () => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const dispatch = useDispatch();

  const handleClickRibbon = () => {
    dispatch(actions.changeCurrentRewardFields({ expirationDate: !currentReward?.expirationDate }));
  };

  return (
    <div onClick={handleClickRibbon} className="d-flex w-700px align-items-center mb-3 cursor-pointer">
      <div className="w-200px">Expiration Date</div>
      <div>
        {currentReward?.expirationDate ? <CheckBox color="secondary" /> : <CheckBoxOutlineBlank color="secondary" /> }
      </div>
    </div>
  );
};

const Additions = () => {

  return (
    <>
      <div className="pb-10 mb-10 w-700px border-bottom border-secondary" />
      <h4 className="mb-5">Additions:</h4>
      <RibbonField />
      <RibbonTextField />
      <GenerateBarcodeField />
      <ExpirationDateField />
    </>
  );
};

export default Additions;
