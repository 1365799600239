import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('rewardsManagement');

actionTypes.GetRewards = 'rewardsManagement/get-rewards';
actionTypes.GetRewardsSuccess = 'rewardsManagement/get-rewards-success';
actionTypes.GetRewardsFailure = 'rewardsManagement/get-rewards-failure';
actionTypes.GetCurrentReward = 'rewardsManagement/get-current-reward';
actionTypes.GetCurrentRewardSuccess = 'rewardsManagement/get-current-reward-success';
actionTypes.GetCurrentRewardFailure = 'rewardsManagement/get-current-reward-failure';
actionTypes.ChangeCurrentRewardFields = 'rewardsManagement/change-current-reward-fields';
actionTypes.GetRewardsTranslations = 'rewardsManagement/get-rewards-translations';
actionTypes.GetRewardsTranslationsSuccess = 'rewardsManagement/get-rewards-translations-success';
actionTypes.GetRewardsTranslationsFailure = 'rewardsManagement/get-rewards-translations-failure';
actionTypes.UpdateCurrentReward = 'rewardsManagement/update-current-reward';
actionTypes.UpdateCurrentRewardSuccess = 'rewardsManagement/update-current-reward-success';
actionTypes.UpdateCurrentRewardFailure = 'rewardsManagement/update-current-reward-failure';
actionTypes.GetFundsHistory = 'rewardsManagement/get-funds-history';
actionTypes.GetFundsHistorySuccess = 'rewardsManagement/get-funds-history-success';
actionTypes.GetFundsHistoryFailure = 'rewardsManagement/get-funds-history-failure';
actionTypes.GetRewardProviders = 'rewardsManagement/get-reward-providers';
actionTypes.GetRewardProvidersSuccess = 'rewardsManagement/get-reward-providers-success';
actionTypes.GetRewardProvidersFailure = 'rewardsManagement/get-reward-providers-failure';
actionTypes.SetUseCountryFieldSort = 'rewardsManagement/set-use-country-field-sort';
actionTypes.FindKeys = 'rewardsManagement/find-keys';
actionTypes.FindKeysSuccess = 'rewardsManagement/find-keys-success';
actionTypes.FindKeysFailure = 'rewardsManagement/find-keys-failure';
actionTypes.CleanFoundKeys = 'rewardsManagement/clean-found-keys';

actions.cleanFoundKeys = () => ({ type: actionTypes.CleanFoundKeys });
actions.findKeys = (data) => ({ type: actionTypes.FindKeys, payload: data });
actions.findKeysSuccess = (featureLanguage) => ({ type: actionTypes.FindKeysSuccess, payload: featureLanguage });
actions.findKeysFailure = () => ({ type: actionTypes.FindKeysFailure });
actions.getRewards = () => ({ type: actionTypes.GetRewards });
actions.getRewardsSuccess = (rewards) => ({ type: actionTypes.GetRewardsSuccess, payload: rewards });
actions.getRewardsFailure = () => ({ type: actionTypes.GetRewardsFailure });
actions.getCurrentReward = (id) => ({ type: actionTypes.GetCurrentReward, payload: id });
actions.getCurrentRewardSuccess = (currentReward) => ({ type: actionTypes.GetCurrentRewardSuccess, payload: currentReward });
actions.getCurrentRewardFailure = () => ({ type: actionTypes.GetCurrentRewardFailure });
actions.changeCurrentRewardFields = (data) => ({ type: actionTypes.ChangeCurrentRewardFields, payload: data });
actions.getRewardsTranslations = (productId) => ({ type: actionTypes.GetRewardsTranslations, payload: productId });
actions.getRewardsTranslationsSuccess = (data) => ({ type: actionTypes.GetRewardsTranslationsSuccess, payload: data });
actions.getRewardsTranslationsFailure = () => ({ type: actionTypes.GetRewardsTranslationsFailure });
actions.updateCurrentReward = (data) => ({ type: actionTypes.UpdateCurrentReward, payload: data });
actions.updateCurrentRewardSuccess = (data) => ({ type: actionTypes.UpdateCurrentRewardSuccess, payload: data });
actions.updateCurrentRewardFailure = () => ({ type: actionTypes.UpdateCurrentRewardFailure });
actions.getFundsHistory = (provider) => ({ type: actionTypes.GetFundsHistory, payload: provider });
actions.getFundsHistorySuccess = (data) => ({ type: actionTypes.GetFundsHistorySuccess, payload: data });
actions.getFundsHistoryFailure = () => ({ type: actionTypes.GetFundsHistoryFailure });
actions.getRewardProviders = () => ({ type: actionTypes.GetRewardProviders });
actions.getRewardProvidersSuccess = (data) => ({ type: actionTypes.GetRewardProvidersSuccess, payload: data });
actions.getRewardProvidersFailure = () => ({ type: actionTypes.GetRewardProvidersFailure });
actions.setUseCountryFieldSort = (value) => ({
  type: actionTypes.SetUseCountryFieldSort,
  payload: value
});


export {
  actionTypes,
  actions,
};
