import { call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/Translations/components/Languages/api';
import { actions, actionTypes } from '~/app/modules/Translations/components/Languages/actions';

function* getLanguages() {
  try {
    const results = yield call(api.getLanguages);
    yield put(actions.getLanguagesSuccess(results));
  } catch (error) {
    yield put(actions.getLanguagesFailure(error.message));
  }
}

function* massVerifyKeyTranslations(action) {
  try {
    const results = yield call(api.massVerifyKeyTranslations, action.payload);
    yield put(actions.getLanguages());
    yield put(actions.massVerifyKeyTranslationsSuccess(results));
  } catch (error) {
    yield put(actions.massVerifyKeyTranslationsFailure(error.message));
  }
}

function* setSlackId(action) {
  try {
    const results = yield call(api.setSlackId, action.payload);
    yield put(actions.getLanguages());
    yield put(actions.setSlackIdSuccess(results));
  } catch (error) {
    yield put(actions.setSlackIdFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.GetLanguages, getLanguages),
  takeLatest(actionTypes.MassVerifyKeyTranslations, massVerifyKeyTranslations),
  takeLatest(actionTypes.SetSlackId, setSlackId),
];