import React, {useEffect, useState} from 'react';
import { Button, Card, CardContent, TextField } from "@material-ui/core";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { actions } from "~/app/modules/Rewards/components/BitcoinRecords/actions";
import { newDateFormatLong } from "~/app/helpers";
import {CloseOutlined, Delete} from '@material-ui/icons';
import cs from 'classnames';
import {Modal} from "react-bootstrap";

const renderFields = (dispatch, rows, setSelectedItem, setSelectedIndex, setShown) => {
  if (!rows?.length) {
    return <div />;
  }

  return rows.map((row, index) => {
    const handleRemove = () => {
      setSelectedIndex(index);
      setSelectedItem(row);
      setShown(true);
    }

    return (
      <div key={index} className={cs('d-flex w-100 pb-3 mb-3 border-bottom border-light-secondary', {
        'border-none mb-10': index === rows?.length - 1,
      })}>
        <div className="w-250px mr-8">{newDateFormatLong(row?.setAt, '(PST)')}</div>
        <div className="w-250px mr-7">{row?.price || ''}</div>
        <div className="w-150px d-flex justify-content-center cursor-pointer" onClick={handleRemove}>
          <Delete color="secondary" />
        </div>
      </div>
    );
  });
};

const BitcoinRemoveModal = ({ selectedItem, selectedIndex, setSelectedItem, setSelectedIndex, shown, setShown }) => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setShown(false);
  };

  const handleSave = () => {
    if (selectedIndex > -1) {
      dispatch(actions.removeBitcoinRecord(selectedIndex, selectedItem));
    }

    setShown(false)
    setSelectedItem({});
    setSelectedIndex(-1);
  };

  return (
    <Modal
      scrollable
      centered
      size="md"
      show={shown}
      onHide={() => setShown(false)}
    >
      <Modal.Header className="d-flex justify-content-between align-items-start">
        <Modal.Title>
          Are you sure you want to remove this item?
        </Modal.Title>
        <div className="cursor-pointer pb-2 py-2" onClick={() => setShown(false)}><CloseOutlined /></div>
      </Modal.Header>
      <Modal.Body>
        <div>
          Date: {newDateFormatLong(selectedItem?.setAt)}
          Price: {selectedItem?.price}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm ml-5"
            onClick={handleSave}
          >
            Remove
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const BitcoinTable = () => {
  const report = useSelector(state => state.bitcoinRecords.report, shallowEqual);
  const balance = useSelector(state => state.bitcoinRecords.balance);
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedItem, setSelectedItem] = useState({});
  const [shown, setShown] = useState(false);

  useEffect(() => {
    dispatch(actions.getBitcoinRecords());
    dispatch(actions.getBitcoinBalance());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleUpload = () => {
    if (value) {
      const data = {
        price: Number(value),
        setAt: new Date(),
      };
      dispatch(actions.createBitcoinRecord(data));
    }
  }

  return (
    <Card className="mb-5 max-w-1000px mx-auto">
      <CardContent className="border text-dark border-light-dark rounded">
        <div
          className="d-flex justify-content-between align-items-center pb-3 border-bottom border-light-secondary mb5"
        >
          <h3>Bitcoin Records</h3>
        </div>
        <div className="d-flex flex-column w-700px mx-auto">
          <div className="d-flex align-items-center mt-3">
            Current Balance:<div className={cs('p-3 rounded ml-3', {
              'bg-light-danger text-danger': balance?.threshold > balance?.currentBalance,
              'bg-light-success text-success': !(balance?.threshold > balance?.currentBalance),
          })}>
            ${balance?.currentBalance || 0}
          </div>
          </div>
          <div className="font-weight-bold">Update Rate</div>
          <div
            className="d-flex justify-content-between align-items-center pb-3 border-bottom border-light-secondary mb-3"
          >
            <div>BTC Amount per $1</div>
            <TextField variant="outlined" value={value} onChange={handleChange} className="w-300px mx-5"/>
            <Button size="medium" className="bg-primary text-white" onClick={handleUpload}>Update</Button>
          </div>
          <div className="d-flex w-100 pb-3 mb-3 border-bottom border-light-secondary">
            <div className="w-250px mr-8">CREATED DATE</div>
            <div className="w-250px mr-7">BTC AMOUNT PER $1 USD</div>
            <div className="w-150px d-flex justify-content-center">ACTION</div>
          </div>
          {renderFields(dispatch, report, setSelectedItem, setSelectedIndex, setShown)}
        </div>
        <BitcoinRemoveModal
          selectedItem={selectedItem}
          setSelectedIndex={setSelectedIndex}
          setSelectedItem={setSelectedItem}
          selectedIndex={selectedIndex}
          setShown={setShown}
          shown={shown}
        />
      </CardContent>
    </Card>
  );
};

const BitcoinRecords = () => {
  return (
    <>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded">
          <div className="d-flex align-items-center">
            <h3>Rewards</h3>
            <div className="ml-3 text-muted">Bitcoin Records</div>
          </div>
        </CardContent>
      </Card>
      <BitcoinTable />
    </>
  );
};

export default BitcoinRecords;
