import enums from '~/app/helpers/enums';

export const isNumericColumn = (column) => {
  return [
    ...Object.keys(enums.measures),
    ...[
      'userAge',
      'userLastProfilerQuestionId',
      'userFraudScoreBucket',
      'clickSurveyCPIBucket',
      'clickNettoEarned',
      'clickBruttoEarned',
      'mediaSpend',
      'clicksCount',
      'ordersCount',
    ],
  ].includes(column)
  || column.startsWith('cohort')
  || column.startsWith('external');
};
