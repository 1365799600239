import React, { useEffect } from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { Card, CardContent } from "@material-ui/core";
import RewardsManagementReport from "~/app/modules/Rewards/components/RewardsManagement/RewardsManagement.report";
import { actions } from "~/app/modules/Rewards/components/RewardsManagement/actions";

const RewardsManagement = () => {
  const dispatch = useDispatch();
  const report = useSelector(state => state.rewards.data, shallowEqual);

  useEffect(() => {
    dispatch(actions.getRewardProviders());
    dispatch(actions.getRewards());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded">
          <div className="d-flex align-items-center">
            <h3>Rewards</h3>
            <div className="ml-3 text-muted">Rewards Management</div>
            <div className="ml-auto">amount: {report?.length}</div>
          </div>
        </CardContent>
      </Card>
      <RewardsManagementReport />
    </>
  )
};

export default RewardsManagement;
