/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "~/_metronic/layout";
import BasePage from "./BasePage";
import TranslationsPage from "./TranslationsPage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

const calcIsLoading = (state) => {
  const clicksReportIsLoading = state.clicksReport.isLoading;
  const usersIsLoading = state.users.isLoading;
  const bannedUsersIsLoading = state.bannedUsers.isLoading;
  const userIsLoading = state.user.isLoading;
  const surveysIsLoading = state.surveys.isLoading;
  const apiLogIsLoading = state.apiLog.isLoading;
  const highReversalRateBansIsLoading = state.highReversalRateBans.isLoading;
  const veriffLogIsLoading = state.veriffLog.isLoading;
  const featuresIsLoading = state.features.isLoading;
  const languagesIsLoading = state.languages.isLoading;
  const keysIsLoading = state.keys.isLoading;
  const keysGetParamsIsLoading = state.keys.getParamsIsLoading;
  const productsIsLoading = state.products.isLoading;
  const translatorsIsLoading = state.translators.isLoading;
  const surveyListResultsLogsIsLoading = state.surveyListResultsLogs.isLoading;
  const surveyUpdatesLogsIsLoading = state.surveyUpdatesLogs.isLoading;
  const rewardsIsLoading = state.rewards.isLoading;
  const rewardProvidersIsLoading = state.rewards.rewardProvidersIsLoading;
  const currentRewardIsLoading = state.rewards.currentIsLoading;
  const updatingCurrentRewardIsLoading = state.rewards.updatingIsLoading;
  const fundsHistoryIsLoading = state.rewards.fundsHistoryIsLoading;
  const amazonInventoryIsLoading = state.amazonInventory.isLoading;
  const amazonInventoryDataIsLoading = state.amazonInventory.dataIsLoading;
  const amazonInventoryUploadCardIsLoading = state.amazonInventory.uploadCardIsLoading;
  const bitcoinRecordsIsLoading = state.bitcoinRecords.isLoading;
  const bitcoinRecordsDataIsLoading = state.bitcoinRecords.dataIsLoading;
  const bitcoinBalanceIsLoading = state.bitcoinRecords.bitcoinBalanceIsLoading;
  const batchesHistoryIsLoading = state.amazonInventory.batchesHistoryIsLoading;
  const ordersIsLoading = state.orders.isLoading;
  const atomsManagementIsLoading = state.atomsManagement.isLoading;
  const emailLimitsManagementIsLoading = state.emailLimitsManagement.isLoading;
  const faceTecSettingsIsLoading = state.faceTecSettings.isLoading;

  return clicksReportIsLoading || keysIsLoading || usersIsLoading || bannedUsersIsLoading || surveysIsLoading || apiLogIsLoading
    || highReversalRateBansIsLoading || veriffLogIsLoading || featuresIsLoading || languagesIsLoading
    || productsIsLoading || translatorsIsLoading || keysGetParamsIsLoading
    || surveyListResultsLogsIsLoading || surveyUpdatesLogsIsLoading || productsIsLoading || translatorsIsLoading
    || keysGetParamsIsLoading || rewardsIsLoading || userIsLoading
    || currentRewardIsLoading || updatingCurrentRewardIsLoading || amazonInventoryIsLoading
    || amazonInventoryDataIsLoading || amazonInventoryUploadCardIsLoading || bitcoinRecordsIsLoading
    || bitcoinRecordsDataIsLoading || bitcoinBalanceIsLoading || batchesHistoryIsLoading || fundsHistoryIsLoading
    || rewardProvidersIsLoading || ordersIsLoading || atomsManagementIsLoading || emailLimitsManagementIsLoading
    || faceTecSettingsIsLoading;
};

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  const user = useSelector(({ auth }) => auth.user);

  const isLoading = useSelector((state) => calcIsLoading(state));

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout isLoading={isLoading}>
          {user?.role ?
            (<TranslationsPage />)
            : (<BasePage />)}
        </Layout>
      )}
    </Switch>
  );
}
