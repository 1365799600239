export const actionTypes = {
  SetStartDate: 'report-builder-form/set-start-date',
  SetEndDate: 'report-builder-form/set-end-date',
  SetSortBy: 'report-builder-form/set-sort-by',
  SetSortDirection: 'report-builder-form/set-sort-direciton',
  SetDimensions: 'report-builder-form/set-dimensions',
  SetMeasures: 'report-builder-form/set-measures',
  SetReportType: 'report-builder-form/set-report-type',
  SetFilter: 'report-builder-form/set-filter',
  DeleteDimension: 'report-builder-form/delete-dimension',
  DeleteMeasure: 'report-builder-form/delete-measure',
  DeleteFilter: 'report-builder-form/delete-filter',
};

actionTypes.CreateSharedLink = 'report-builder-form/create-shared-link';
actionTypes.CreateSharedLinkFailed = 'report-builder-form/create-shared-link-failed';
actionTypes.CreateSharedLinkSuccess = 'report-builder-form/create-shared-link-success';
actionTypes.ClearSharedLink = 'report-builder-form/clear-shared-link';
actionTypes.SetParams = 'report-builder-form/set-params';

export const actions = {
  setStartDate: (startDate) => ({ type: actionTypes.SetStartDate, payload: startDate }),
  setEndDate: (endDate) => ({ type: actionTypes.SetEndDate, payload: endDate }),
  setSortBy: (sortBy) => ({ type: actionTypes.SetSortBy, payload: sortBy }),
  setSortDirection: (sortDirection) => ({ type: actionTypes.SetSortDirection, payload: sortDirection }),
  setDimensions: (dimensions) => ({ type: actionTypes.SetDimensions, payload: dimensions }),
  setMeasures: (measures) => ({ type: actionTypes.SetMeasures, payload: measures }),
  setReportType: (reportType) => ({type: actionTypes.SetReportType, payload: reportType }),
  setFilter: (filter) => ({type: actionTypes.SetFilter, payload: filter}),

  deleteDimension: (key) => ({ type: actionTypes.DeleteDimension, payload: key }),
  deleteMeasure: (key) => ({ type: actionTypes.DeleteMeasure, payload: key }),
  deleteFilter: (filter) => ({type: actionTypes.DeleteFilter, payload: filter}),
};

actions.createSharedLink = (params) => ({type: actionTypes.CreateSharedLink, payload: params});
actions.createSharedLinkSuccess = (link) => ({type: actionTypes.CreateSharedLinkSuccess, payload: link});
actions.createSharedLinkFailed = (error) => ({type: actionTypes.CreateSharedLinkFailed, payload: error});
actions.setParams = (params) => ({type: actionTypes.SetParams, payload: params});
actions.clearSharedLink = () => ({type: actionTypes.ClearSharedLink});

