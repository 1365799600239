const actionTypes = {
  SetFormKey: 'emailLimitsManagement/set-form-key',
  FetchEmailLimits: 'emailLimitsManagement/fetch-email-limits',
  FetchEmailLimitsSuccess: 'emailLimitsManagement/fetch-email-limits-success',
  FetchEmailLimitsFailure: 'emailLimitsManagement/fetch-email-limits-failure',
  ChangeFormField: 'emailLimitsManagement/change-form-field',
  SetCurrentAsDefault: 'emailLimitsManagement/set-current-as-default',
  SaveCurrentEmailLimits: 'emailLimitsManagement/save-current-email-limits',
  SaveEmailLimitsSuccess: 'emailLimitsManagement/save-email-limits-success',
  SaveEmailLimitsFailure: 'emailLimitsManagement/save-email-limits-failure',

  CloseErrorAlert: 'emailLimitsManagement/close-error-alert',
  CloseSuccessAlert: 'emailLimitsManagement/close-success-alert',
};

const actions = {
  setFormKey: (key) => ({ type: actionTypes.SetFormKey, payload: key }),
  fetchEmailLimits: () => ({ type: actionTypes.FetchEmailLimits }),
  fetchEmailLimitsSuccess: (data) => ({ type: actionTypes.FetchEmailLimitsSuccess, payload: data }),
  fetchEmailLimitsFailure: (error) => ({ type: actionTypes.FetchEmailLimitsFailure, payload: error }),
  changeFormField: (data) => ({ type: actionTypes.ChangeFormField, payload: data }),
  setCurrentAsDefault: () => ({ type: actionTypes.SetCurrentAsDefault }),
  saveCurrentEmailLimits: () => ({ type: actionTypes.SaveCurrentEmailLimits }),
  saveEmailLimitsSuccess: (data) => ({ type: actionTypes.SaveEmailLimitsSuccess, payload: data }),
  saveEmailLimitsFailure: (error) => ({ type: actionTypes.SaveEmailLimitsFailure, payload: error }),

  closeErrorAlert: () => ({ type: actionTypes.CloseErrorAlert }),
  closeSuccessAlert: () => ({ type: actionTypes.CloseSuccessAlert }),
};

export {
  actionTypes,
  actions,
};

