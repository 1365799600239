import { actionTypes } from '~/app/modules/Rewards/components/AmazonInventory/actions';
import _ from "lodash";
import enums from "~/app/helpers/enums";

const initialState = {
  data: {},
  report: [],
  pageNumber: 1,
  pageSize: 50,
  sortBy: 'createdAt',
  sortDirection: enums.sortDirections.desc,
  filters: {},
  batchHistory: [],
  error: undefined,

  dataIsLoading: false,
  isLoading: false,
  uploadCardIsLoading: false,
  batchesHistoryIsLoading: false,
};

const resetPaginationState = {
  pageNumber: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetPagination:
      return {
        ...state,
        ...resetPaginationState,
        pageSize: action.payload.pageSize,
        pageNumber: action.payload.pageNumber,
      };

    case actionTypes.SetSorting:
      return {
        ...state,
        ...resetPaginationState,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };

    case actionTypes.SetFilter:
      const { field, value } = action.payload;
      const curFilters = state.filters;
      if (value) {
        return {
          ...state,
          ...resetPaginationState,
          filters: {
            ...curFilters,
            [field]: value,
          },
        };
      } else {
        return {
          ...state,
          ...resetPaginationState,
          filters: _.omit(curFilters, field),
        };
      }

    case actionTypes.GenerateReport:
      return {
        ...state,
        pageNumber: 1,
        pageSize: 50,
        report: [],
        totalCount: 0,
        isLoading: true,
      };

    case actionTypes.UpdateReport:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FetchReportSuccess:
      return {
        ...state,
        report: action.payload.data,
        totalCount: action.payload.totalCount,
        stats: action.payload.stats,
        isLoading: false,
      };

    case actionTypes.FetchReportError:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GetAmazonCards:
      return {
        ...state,
        dataIsLoading: true,
      };

    case actionTypes.GetAmazonCardsFailure:
      return {
        ...state,
        dataIsLoading: false,
      };

    case actionTypes.GetAmazonCardsSuccess:
      return {
        ...state,
        dataIsLoading: false,
        data: action.payload,
      };

    case actionTypes.UploadCSV:
      return {
        ...state,
        uploadCardIsLoading: true,
      };

    case actionTypes.UploadCSVFailure:
    case actionTypes.UploadCSVSuccess:
      return {
        ...state,
        uploadCardIsLoading: false,
        error: action.payload,
      };

    case actionTypes.GetAmazonBatchesHistory:
      return {
        ...state,
        batchesHistoryIsLoading: true,
      };

    case actionTypes.GetAmazonBatchesHistorySuccess:
      return {
        ...state,
        batchesHistoryIsLoading: false,
        batchHistory: action.payload,
      };

    case actionTypes.GetAmazonBatchesHistoryFailure:
      return {
        ...state,
        batchesHistoryIsLoading: false,
      };

    case actionTypes.CleanUploadError:
      return {
        ...state,
        error: undefined,
      };

    default:
      return state;
  }
};
