import { fetchReportHelper } from '~/app/helpers';
import config from "~/config";
import fetch from '~/redux/fetch';
import flatten from "flat";

const FETCH_REPORT_URL = '/banned-users';
const fetchReportAsCsvURL = (params) => {
  const storage = JSON.parse(window.localStorage?.getItem('persist:st-admin-auth'));
  let url = `${config.apiBaseUrl}/banned-users/csv-report/${storage.authToken?.substring(1, storage.authToken?.length - 1)}`;
  const newParams = {...params};

  if (!Object.keys(params?.filters)?.length) {
    delete newParams.filters;
  }
  const flattenParams = flatten(newParams);
  if (Object.keys(flattenParams).length) {
    Object.keys(flattenParams).forEach((key, index) => {
      if (index === 0) {
        url = `${url}?${key}=${flattenParams[key]}`;
      } else {
        url = `${url}&${key}=${flattenParams[key]}`;
      }
    });
  }

  return url;
};

const fetchReportAsCsv = async (params) => {
  var a = document.createElement('a');
  a.href = `${fetchReportAsCsvURL(params)}`;
  a.download = 'surveys.csv';
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const massBan = async ({ rows }) => {
  const response = await fetch(`${config.apiBaseUrl}/banned-users/mass-ban`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(rows),
  });
  return response.json();
};

const massUnban = async ({ rows }) => {
  const response = await fetch(`${config.apiBaseUrl}/banned-users/mass-unban`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(rows),
  });
  return response.json();
};

export default {
  fetchReport: fetchReportHelper(FETCH_REPORT_URL),
  fetchReportAsCsv,
  massBan,
  massUnban,
};

