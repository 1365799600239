import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('user');

actionTypes.GetUserInformation = 'user/get-user-information';
actionTypes.GetUserInformationSuccess = 'user/get-user-information-success';
actionTypes.GetUserClicks = 'user/get-user-clicks';
actionTypes.GetUserClicksSuccess = 'user/get-user-clicks-success';
actionTypes.GetUserEarnings = 'user/get-user-earnings';
actionTypes.GetUserEarningsSuccess = 'user/get-user-earnings-success';
actionTypes.GetUserOrders = 'user/get-user-orders';
actionTypes.GetUserOrdersSuccess = 'user/get-user-orders-success';
actionTypes.UpdateUserRestrictions = 'user/update-user-restrictions';
actionTypes.ShadowUnbanUser = 'user/shadow-unban-user';
actionTypes.UpdateMassOrderingWhitelistStatus = 'user/update-mass-ordering-whitelist-status';
actionTypes.DeleteUser = 'user/delete-user';
actionTypes.DeleteUserSuccess = 'user/delete-user-success';
actionTypes.GetUserProfilerQuestions = 'user/get-user-profiler-questions';
actionTypes.GetUserProfilerQuestionsSuccess = 'user/get-user-profiler-questions-success';
actionTypes.GetUserEmails = 'user/get-user-emails';
actionTypes.GetUserEmailsSuccess = 'user/get-user-emails-success';
actionTypes.UpdateUserIsSingleSurveyShouldBeShown = 'user/update-user-is-single-survey-should-be-shown';
actionTypes.UpdateUserIsSingleSurveyShouldBeShownSuccess = 'user/update-user-is-single-survey-should-be-shown-success';
actionTypes.ReleaseUserOrder = 'user/release-user-order';
actionTypes.GetUserEmailsStats = 'user/get-user-emails-stats';
actionTypes.GetUserEmailsStatsSuccess = 'user/get-user-emails-stats-success';
actionTypes.GetUserNotifications = 'user/get-user-notifications';
actionTypes.GetUserNotificationsSuccess = 'user/get-user-notifications-success';
actionTypes.GetUserNotificationsStats = 'user/get-user-notifications-stats';
actionTypes.GetUserNotificationsStatsSuccess = 'user/get-user-notifications-stats-success';
actionTypes.GetUserIPs = 'user/get-user-ips';
actionTypes.GetUserIPsSuccess = 'user/get-user-ips-success';
actionTypes.SaveUserPhoneNumber = 'user/update-user-phone-number';
actionTypes.GetUserNexmoLogs = 'user/get-user-nexmo-logs';
actionTypes.GetUserNexmoLogsSuccess = 'user/get-user-nexmo-logs-success';
actionTypes.UpdateUserIgnoredMarks = 'user/update-user-ignored-marks';
actionTypes.GetUserIPsWithEmail = 'user/get-user-ips-with-emails';
actionTypes.GetUserIPsWithEmailSuccess = 'user/get-user-ips-with-emails-success';
actionTypes.GetUserPurchases = 'user/get-user-purchases';
actionTypes.GetUserPurchasesSuccess = 'user/get-user-purchases-success';
actionTypes.GetUserPublisherPostbackLogs = 'user/get-user-publisher-postback-logs';
actionTypes.GetUserPublisherPostbackLogsSuccess = 'user/get-user-publisher-postback-logs-success';
actionTypes.GenerateReportAsCSV = 'user/generate-report-as-csv';
actionTypes.UnVerifyEmail = 'user/un-verify-email';
actionTypes.UnVerifyPhoneNumber = 'user/un-verify-phone-number';
actionTypes.SetUserId = 'user/set-user-id';
actionTypes.GenerateReportForTargetedSurveysAsCsv = 'user/generate-report-targeted-surveys-as-csv';
actionTypes.GenerateReportForTargetedSurveysAsCsvSuccess = 'user/generate-report-targeted-surveys-as-csv-success';
actionTypes.SetUserRole = 'user/set-user-role';
actionTypes.SetUserRoleSuccess = 'user/set-user-role-success';
actionTypes.SetUserRoleFailure = 'user/set-user-role-failure';
actionTypes.GetUserPaypalLogs = 'user/get-user-paypal-logs';
actionTypes.GetUserPaypalLogsSuccess = 'user/get-user-paypal-logs-success';
actionTypes.GetUserPaypalLogsFailure = 'user/get-user-paypal-logs-failure';
actionTypes.GetUserTransactionsLog = 'user/get-user-transactions-log';
actionTypes.GetUserTransactionsLogSuccess = 'user/get-user-transactions-log-success';
actionTypes.GetUserTransactionsLogFailure = 'user/get-user-transactions-log-failure';

actions.generateReportForTargetedSurveysAsCsv = () => ({ type: actionTypes.GenerateReportForTargetedSurveysAsCsv, payload: {} });
actions.generateReportForTargetedSurveysAsCsvSuccess = () => ({ type: actionTypes.GenerateReportForTargetedSurveysAsCsvSuccess, payload: {} });
actions.getUserInformation = (userId) => ({ type: actionTypes.GetUserInformation, payload: userId });
actions.getUserInformationSuccess = (user) => ({ type: actionTypes.GetUserInformationSuccess, payload: user });
actions.getUserClicks = (userId) => ({ type: actionTypes.GetUserClicks, payload: userId });
actions.getUserClicksSuccess = (user) => ({ type: actionTypes.GetUserClicksSuccess, payload: user });
actions.getUserEarnings = (userId) => ({ type: actionTypes.GetUserEarnings, payload: userId });
actions.getUserEarningsSuccess = (user) => ({ type: actionTypes.GetUserEarningsSuccess, payload: user });
actions.getUserOrders = (userId) => ({ type: actionTypes.GetUserOrders, payload: userId });
actions.getUserOrdersSuccess = (user) => ({ type: actionTypes.GetUserOrdersSuccess, payload: user });
actions.updateUserRestrictions = (userId, params) => ({ type: actionTypes.UpdateUserRestrictions, payload: { userId, params } });
actions.shadowUnbanUser = (userId) => ({ type: actionTypes.ShadowUnbanUser, payload: { userId } });
actions.updateMassOrderingWhitelistStatus = (userId, params) => ({ type: actionTypes.UpdateMassOrderingWhitelistStatus, payload: { userId, params } });
actions.deleteUser = (userId) => ({ type: actionTypes.DeleteUser, payload: userId });
actions.deleteUserSuccess = (user) => ({ type: actionTypes.DeleteUserSuccess, payload: user });
actions.getUserProfilerQuestions = (userId) => ({ type: actionTypes.GetUserProfilerQuestions, payload: userId });
actions.getUserProfilerQuestionsSuccess = (questions) => ({ type: actionTypes.GetUserProfilerQuestionsSuccess, payload: questions });
actions.getUserEmails = (userId) => ({ type: actionTypes.GetUserEmails, payload: userId });
actions.getUserEmailsSuccess = (emails) => ({ type: actionTypes.GetUserEmailsSuccess, payload: emails });
actions.updateUserIsSingleSurveyShouldBeShown = (userId, params) => ({
  type: actionTypes.UpdateUserIsSingleSurveyShouldBeShown,
  payload: { userId, params }
});
actions.releaseUserOrder = (userId, orderId) => ({ type: actionTypes.ReleaseUserOrder, payload: { userId, orderId } });
actions.getUserEmailsStats = (userId) => ({ type: actionTypes.GetUserEmailsStats, payload: userId });
actions.getUserEmailsStatsSuccess = (emailStats) => ({ type: actionTypes.GetUserEmailsStatsSuccess, payload: emailStats });
actions.getUserNotifications = (userId) => ({ type: actionTypes.GetUserNotifications, payload: userId });
actions.getUserNotificationsSuccess = (notifications) => ({ type: actionTypes.GetUserNotificationsSuccess, payload: notifications });
actions.getUserNotificationsStats = (userId) => ({ type: actionTypes.GetUserNotificationsStats, payload: userId });
actions.getUserNotificationsStatsSuccess = (stats) => ({ type: actionTypes.GetUserNotificationsStatsSuccess, payload: stats });
actions.getUserIPs = (userId) => ({ type: actionTypes.GetUserIPs, payload: userId });
actions.getUserIPsSuccess = (stats) => ({ type: actionTypes.GetUserIPsSuccess, payload: stats });
actions.saveUserPhoneNumber = (userId, data) => ({ type: actionTypes.SaveUserPhoneNumber, payload: { userId, data } });
actions.getUserNexmoLogs = (userId) => ({ type: actionTypes.GetUserNexmoLogs, payload: userId });
actions.getUserNexmoLogsSuccess = (logs) => ({ type: actionTypes.GetUserNexmoLogsSuccess, payload: logs });
actions.updateUserIgnoredMarks = (userId, orderSecurityIgnores) =>
  ({ type: actionTypes.UpdateUserIgnoredMarks, payload: { userId, orderSecurityIgnores } });
actions.getUserIPsWithEmails = (userId) => ({ type: actionTypes.GetUserIPsWithEmail, payload: userId });
actions.getUserIPsWithEmailsSuccess = (ips) => ({ type: actionTypes.GetUserIPsWithEmailSuccess, payload: ips });
actions.getUserPurchases = (userId) => ({ type: actionTypes.GetUserPurchases, payload: userId });
actions.getUserPurchasesSuccess = (purchases) => ({ type: actionTypes.GetUserPurchasesSuccess, payload: purchases });
actions.getUserPublisherPostbackLogs = (userId) => ({ type: actionTypes.GetUserPublisherPostbackLogs, payload: userId });
actions.getUserPublisherPostbackLogsSuccess = (logs) => ({ type: actionTypes.GetUserPublisherPostbackLogsSuccess, payload: logs });
actions.unVerifyEmail = (userId) => ({ type: actionTypes.UnVerifyEmail, payload: userId });
actions.unVerifyPhoneNumber = (userId) => ({ type: actionTypes.UnVerifyPhoneNumber, payload: userId });
actions.generateReportAsCSV = (userId, profilerAnsweredIds, sortDirection) => ({
  type: actionTypes.GenerateReportAsCSV,
  payload: { userId, profilerAnsweredIds, sortDirection },
});
actions.setUserId = (userId) => ({ type: actionTypes.SetUserId, payload:userId });
actions.setUserRole = (data) => ({ type: actionTypes.SetUserRole, payload:data });
actions.setUserRoleSuccess = () => ({ type: actionTypes.SetUserRoleSuccess });
actions.setUserRoleFailure = () => ({ type: actionTypes.SetUserRoleFailure });
actions.getUserPaypalLogs = (userId) => ({ type: actionTypes.GetUserPaypalLogs, payload: userId });
actions.getUserPaypalLogsSuccess = (data) => ({ type: actionTypes.GetUserPaypalLogsSuccess, payload: data });
actions.getUserPaypalLogsFailure = () => ({ type: actionTypes.GetUserPaypalLogs });
actions.getUserTransactionsLog = (userId) => ({ type: actionTypes.GetUserTransactionsLog, payload: userId });
actions.getUserTransactionsLogSuccess = (data) => ({ type: actionTypes.GetUserTransactionsLogSuccess, payload: data });
actions.getUserTransactionsLogFailure = () => ({ type: actionTypes.GetUserTransactionsLogFailure });

export {
  actionTypes,
  actions,
};
