import { actionTypes } from '~/app/modules/Survey/actions';
import { format } from 'date-fns'
import enums from "~/app/helpers/enums";

const initialState = {
  surveyInformation: {},
  crStats: [],
  crStatsIsLoading: false,
  revenueStats: [],
  revenueStatsIsLoading: false,
  crStartDate: format(new Date(), enums.DATE_FORMAT),
  crEndDate: format(new Date(), enums.DATE_FORMAT),
  clicksPerformanceStartDate: format(new Date(), enums.DATE_FORMAT),
  clicksPerformanceEndDate: format(new Date(), enums.DATE_FORMAT),
  revenueStartDate: format(new Date(), enums.DATE_FORMAT),
  revenueEndDate: format(new Date(), enums.DATE_FORMAT),
  surveyStats: {},
  clicksSinceLastLead: 0,
  clicksStoppedReasons: [],
  clicksPerformance: {},
  preliminaryFailedClicks: [],
  lastClickAndLead: {},
  questions: [],
  quotas: [],
  ssiRex: {},
  ssiRexQuotasAndFiltersIsLoading: false,
  tolunaIpes: {},
  tolunaIpesIsLoading: false,
  prodege: [],
  prodegeIsLoading: false,
  targetedUsers: [],
  targetedUsersCount: 0,
  targetedUsersCurrentPage: 1,
  targetedUsersPerPage: 10,
  targetedUsersIsLoading: false,
  dqStats: [],
  originStats: {},

  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetSurveySuccess:
      return {
        ...state,
        surveyInformation: action.payload,
        isLoading: false
      };

    case actionTypes.GetSurvey:
      return {
        ...state,
        isLoading: true
      };

    case actionTypes.GetSurveyCRStats:
      return {
        ...state,
        crStatsIsLoading: true
      };

    case actionTypes.GetSurveyCRStatsSuccess:
      return {
        ...state,
        crStats: action.payload,
        crStatsIsLoading: false
      };

    case actionTypes.SetCRStartDate:
      return {
        ...state,
        crStartDate: action.payload,
      };

    case actionTypes.SetCREndDate:
      return {
        ...state,
        crEndDate: action.payload,
      };

    case actionTypes.SetClicksPerformanceStartDate:
      return {
        ...state,
        clicksPerformanceStartDate: action.payload,
      };

    case actionTypes.SetClicksPerformanceEndDate:
      return {
        ...state,
        clicksPerformanceEndDate: action.payload,
      };

    case actionTypes.SetRevenueStartDate:
      return {
        ...state,
        revenueStartDate: action.payload,
      };

    case actionTypes.SetRevenueEndDate:
      return {
        ...state,
        revenueEndDate: action.payload,
      };

    case actionTypes.GetSurveyRevenueStats:
      return {
        ...state,
        revenueStatsIsLoading: action.payload,
      }

    case actionTypes.GetSurveyRevenueStatsSuccess:
      return {
        ...state,
        revenueStats: action.payload,
      }

    case actionTypes.GetSurveyStatsSuccess:
      return {
        ...state,
        surveyStats: action.payload?.clicksStats,
        clicksSinceLastLead: action.payload?.clicksSinceLastLead,
      }

    case actionTypes.GetClicksStoppedReasonsSuccess:
      return {
        ...state,
        clicksStoppedReasons: action.payload,
      }

    case actionTypes.GetClicksPerformanceSuccess:
      return {
        ...state,
        clicksPerformance: action.payload,
      }

    case actionTypes.GetPreliminaryFailedClicksSuccess:
      return {
        ...state,
        preliminaryFailedClicks: action.payload,
      }

    case actionTypes.GetSurveyDQStatsSuccess:
      return {
        ...state,
        dqStats: action.payload,
      }

    case actionTypes.GetLastClickAndLeadSuccess:
      return {
        ...state,
        lastClickAndLead: action.payload,
      }

    case actionTypes.GetSurveyQuestionsSuccess:
      return {
        ...state,
        questions: action.payload,
      }

    case actionTypes.GetSurveyQuotasSuccess:
      return {
        ...state,
        quotas: action.payload,
      }

    case actionTypes.GetSurveyTargetedUsers:
      return {
        ...state,
        targetedUsersIsLoading: true,
      }

    case actionTypes.GetSurveyTargetedUsersFailed:
      return {
        ...state,
        targetedUsersIsLoading: false,
      }

    case actionTypes.GetSurveyTargetedUsersSuccess:
      return {
        ...state,
        targetedUsers: action.payload.data,
        targetedUsersCount: action.payload.totalCount,
        targetedUsersIsLoading: false,
      }

    case actionTypes.GetSurveySsiRexQuotasAndFilters:
      return {
        ...state,
        ssiRexQuotasAndFiltersIsLoading: true,
      }

    case actionTypes.GetSurveySsiRexQuotasAndFiltersFailed:
      return {
        ...state,
        ssiRexQuotasAndFiltersIsLoading: false,
      }

    case actionTypes.GetSurveySsiRexQuotasAndFiltersSuccess:
      return {
        ...state,
        ssiRex: action.payload,
        ssiRexQuotasAndFiltersIsLoading: false,
      }

    case actionTypes.GetSurveyTolunaIpesData:
      return {
        ...state,
        tolunaIpesIsLoading: true,
      }

    case actionTypes.GetSurveyTolunaIpesDataFailed:
      return {
        ...state,
        tolunaIpesIsLoading: false,
      }

    case actionTypes.GetSurveyTolunaIpesDataSuccess:
      return {
        ...state,
        tolunaIpes: action.payload,
        tolunaIpesIsLoading: false,
      }

    case actionTypes.GetSurveyProdegeQuotas:
      return {
        ...state,
        prodegeIsLoading: true,
      }

    case actionTypes.GetSurveyProdegeQuotasFailed:
      return {
        ...state,
        prodegeIsLoading: false,
      }

    case actionTypes.GetSurveyProdegeQuotasSuccess:
      return {
        ...state,
        prodege: action.payload,
        prodegeIsLoading: false,
      }

    case actionTypes.SetSurveyTargetedUsersPage:
      return {
        ...state,
        targetedUsersCurrentPage: action.payload,
      }

    case actionTypes.SetSurveyTargetedUsersPerPage:
      return {
        ...state,
        targetedUsersPerPage: action.payload,
      }

    case actionTypes.GetSurveyOriginStatsSuccess:
      return {
        ...state,
        originStats: action.payload,
      }

    default:
      return state;
  }
};
