import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { actions } from '~/app/modules/Translations/components/Languages/actions';
import React from "react";
import {Modal, Spinner} from "react-bootstrap";
import { CloseOutlined } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import cs from 'classnames';

const ModalForVerifyKeyTranslations = ({ language, setShown, shown, verify }) => {
  const dispatch = useDispatch();
  const verificationIsLoading = useSelector((state) => state.languages.verificationIsLoading);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (!shown && selectedProducts.length) {
      setSelectedProducts([]);
    }
  }, [shown, selectedProducts]);

  const handleCloseModal = () => {
    setShown(false);
    setSelectedProducts([])
  }

  const handleClickVerify = () => {
    dispatch(actions.massVerifyKeyTranslations({ productIds: selectedProducts, languageId: language._id, verify }));
  };

  const handleClickOnProduct = (productId) => () => {
    if (selectedProducts.includes(productId)) {
      setSelectedProducts([...selectedProducts.filter((id) => id !== productId)]);
    } else {
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  const renderProduct = (product) => {
    return (
      <div
        onClick={handleClickOnProduct(product._id)}
        key={product._id}
        className="d-flex mx-3 align-items-center cursor-pointer mb-5"
      >
        <div className={cs('min-w-15px min-h-15px border border-primary rounded mr-3', {
          'bg-primary': selectedProducts.includes(product._id),
          })}
        />
        <div>{product.name}</div>
      </div>
    )
  }

  const renderButtonContent = (isLoading) => {
    if (isLoading) {
      return (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      );
    }
    if (verify) {
      return 'Verify';
    }

    return 'Un-Verify';
  }

  return (
    <>
      <Modal
        scrollable
        centered
        size="md"
        show={shown}
        onHide={handleCloseModal}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            {verify ? 'Verify Language' : 'Un-Verify Language'}
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={handleCloseModal}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column p-5">
            <div className="font-size-lg font-weight-bold mb-3">{language.name}</div>
            <div className="mb-3">Select the products to {verify ? 'verify' : 'un-verify'}:</div>
            {language?.products?.map(renderProduct)}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button
              color="default"
              variant="contained"
              className="mr-3 bg-light-primary text-primary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={!selectedProducts.length}
              variant="contained"
              onClick={handleClickVerify}
              className={cs('bg-primary text-white mr-5', { 'bg-secondary': !selectedProducts.length })}
            >
              {renderButtonContent(verificationIsLoading)}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalForVerifyKeyTranslations;
