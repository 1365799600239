import { fetchReportHelper } from '~/app/helpers';
import config from '~/config';
import fetch from '~/redux/fetch';
import flatten from 'flat';

const FETCH_REPORT_URL = '/clicks';
const CREATE_SHARED_LINK_URL = '/shared-link';

const createSharedLink = async (params) => {
  const response = await fetch(`${config.apiBaseUrl}${CREATE_SHARED_LINK_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ params }),
  });
  return response.json();
}

const fetchReportAsCsvURL = (params) => {
  const storage = JSON.parse(window.localStorage?.getItem('persist:st-admin-auth'));
  let url = `${config.apiBaseUrl}/clicks-csv/${storage.authToken?.substring(1, storage.authToken?.length - 1)}`;
  const newParams = {...params};

  if (!Object.keys(params?.filters)?.length) {
    delete newParams.filters;
  }
  const flattenParams = flatten(newParams);
  if (Object.keys(flattenParams).length) {
    Object.keys(flattenParams).forEach((key, index) => {
      if (index === 0) {
        url = `${url}?${key}=${flattenParams[key]}`;
      } else {
        url = `${url}&${key}=${flattenParams[key]}`;
      }
    });
  }

  return url;
};

const fetchReportAsCsv = async (params) => {
  var a = document.createElement('a');
  a.href = `${fetchReportAsCsvURL(params)}`;
  a.download = 'clicksReport.csv';
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export default {
  fetchReport: fetchReportHelper(FETCH_REPORT_URL),
  fetchReportAsCsv,
  createSharedLink,
};

