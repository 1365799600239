import React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { selectFilter, customFilter } from 'react-bootstrap-table2-filter';
import { Pagination } from '~/_metronic/_partials/controls';
import { actions } from '~/app/modules/BannedUsers/actions';
import { sortCaret, toAbsoluteUrl } from '~/_metronic/_helpers';
import {
  newDateFormatLong,
  onTableChangeHelper,
  usePaginationFilteringAndSorting,
  textFilterOnBlurHelper,
  textFilterOnBlurHelperWithAutoComplete,
  useDidMount,
} from '~/app/helpers';
import SVG from "react-inlinesvg";
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const banReasons = ['all', ...Object.values(enums.banReasons), 'other'].map((option) => ({
  value: option, label: option,
}));

const sortableColumn = {
  sort: true, sortCaret,
};

const userIdLink = (_id) => (
  <div>
    <Link
      target="_blank"
      to={`/users/${_id}`}
    >
      {_id}
    </Link>
  </div>
);

const renderCountryField = (country) => (
  <div className="d-flex justify-content-between align-items-center">
    <div>{country?.countryISO}</div>
    {country?.countryName && (
      <SVG
        alt="Logo"
        className="min-w-20px max-w-20px ml-3"
        src={toAbsoluteUrl(`/media/svg/flags/${country?.countryName?.toLowerCase()}.svg`)}
      />
    )}
  </div>
)

const columns = (useBannedAtSort) => [{
    ...sortableColumn,
    sort: useBannedAtSort,
    dataField: 'bannedAt', text: 'Ban time',
    formatter: (date) => newDateFormatLong(date),
    filter: customFilter(),
    filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "230px" }}),
    style: { width: '250px', textAlign: 'left' },
  }, {
  ...sortableColumn,
  dataField: '_id', text: 'Publisher user ID',
  formatter: (_id) => userIdLink(_id),
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "230px" }}),
  style: { width: '250px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'email', text: 'Email Address',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "180px" }}),
  style: { width: '200px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'country', text: 'Country',
  formatter: (country) => renderCountryField(country),
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelperWithAutoComplete(
    onFilter,
    column,
    {style: { width: "80px" }},
    actions,
    'bannedUsers'
  ),
  style: { width: '100px', minWidth: '80px', textAlign: 'left' },
}, {
  dataField: 'banReason', text: 'Ban Reason',
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '120px' },
    options: banReasons,
    withoutEmptyOption: true,
  }),
  style: { width: '150px', textAlign: 'left' },
}];

const BannedUsersReport = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'bannedUsers', actions);
  const report = useSelector(state => state.bannedUsers.bannedUserList, shallowEqual);
  const useBannedAtSort = useSelector((state) => state.bannedUsers.useBannedAtSort);

  const didMount = useDidMount();

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: ['bannedAt', '_id', 'email', 'country', 'banReason'],
    didMount,
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = columns(useBannedAtSort);

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => (
        <Pagination isLoading={false} paginationProps={paginationProps}>
          <BootstrapTable
            remote
            classes="table table-head-custom table-vertical-center overflow-hidden"
            headerClasses="table table-vertical-top"
            bootstrap4
            keyField="bannedAt"
            columns={reportColumns}
            data={report}
            filter={ filterFactory() }
            defaultSorted={[{
              dataField: sortBy,
              order: enums.sortDirections.desc,
            }]}
            onTableChange={onTableChange}
            {...paginationTableProps}
          >
          </BootstrapTable>
        </Pagination>
      )}
    </PaginationProvider>
  );
};

export default BannedUsersReport;
