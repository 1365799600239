import config from '~/config';
import fetch from '~/redux/fetch';

const FETCH_LIMITS_PATH = '/email-limits';
const UPDATE_LIMITS_PATH = '/email-limits';

const fetchEmailLimits = async () => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_LIMITS_PATH}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  return response.json();
};

const updateEmailLimits = async ({ key, limits }) => {
  const response = await fetch(`${config.apiBaseUrl}${UPDATE_LIMITS_PATH}/${key}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(limits),
  });

  if (response.status !== 200) {
    throw new Error((await response.json()).error);
  }

  return response.json();
};

export default {
  fetchEmailLimits,
  updateEmailLimits,
};

