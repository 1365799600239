import config from '~/config';
import fetch from '~/redux/fetch';

const FETCH_TRANSLATORS_URL = '/translations/translators';
const CREATE_TRANSLATOR_URL = '/translations/translators';
const UPDATE_TRANSLATOR_URL = (featureId) => `/translations/translators/${featureId}`;
const FETCH_AVAILABLE_PARAMS_URL = '/translations/translators/new';

const getTranslators = async () => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_TRANSLATORS_URL}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const createTranslator = async ({
  firstName,
  lastName,
  email,
  password,
  role,
  languages,
  rate,
  status,
  slackHandleId,
}) => {
  const response = await fetch(`${config.apiBaseUrl}${CREATE_TRANSLATOR_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      password,
      role,
      languages,
      rate,
      status,
      slackHandleId,
    }),
  });
  return response.json();
};

const updateTranslator = async ({
  firstName,
  lastName,
  email,
  password,
  role,
  languages,
  rate,
  status,
  translatorId,
  slackHandleId,
}) => {
  const response = await fetch(`${config.apiBaseUrl}${UPDATE_TRANSLATOR_URL(translatorId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      password,
      role,
      languages,
      rate,
      status,
      slackHandleId,
    }),
  });
  return response.json();
}

const getAvailableParams = async () => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_AVAILABLE_PARAMS_URL}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

export default {
  getTranslators,
  createTranslator,
  updateTranslator,
  getAvailableParams,
};
