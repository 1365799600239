import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import React, {useEffect} from "react";
import {Modal} from "react-bootstrap";
import {Cancel, CloseOutlined} from "@material-ui/icons";
import {Button, Chip, FormControl, MenuItem, Select as MUISelect} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import { Input, Select } from "~/_metronic/_partials/controls";

const useStyles = makeStyles((theme) => ({
  chipListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const selectMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

const initialValues = {
  name: '',
  productId: '',
  languages: [],
};

const CreateFeatureModal = ({ shown, setShown, intl, actions, stateKey, selectedKeyIds, values }) => {
  const products = useSelector(state => state[stateKey].products);
  const languages = useSelector(state => state[stateKey].languages);
  const dispatch = useDispatch();

  const TranslatorSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Minimum 3 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    productId: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    languages: Yup.array()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const onSubmit = ({ name, productId, languages }) => {
    if (values) {
      dispatch(actions.updateFeature({ name, targetedLanguages: languages, featureId: values?._id, productId }));
    } else if (selectedKeyIds?.length) {
        dispatch(actions.createFeature({
          name, productId, targetedLanguages: languages, keyIds:selectedKeyIds
        }));
    } else {
    dispatch(actions.createFeature({ name, productId, targetedLanguages: languages }));
    }
  };

  const formik = useFormik({
    initialValues: values || initialValues,
    validationSchema: TranslatorSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        onSubmit(values)
        setShown(false);
        formik.resetForm(initialValues);
      } catch (error) {
        setSubmitting(false);
        setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_LOGIN",
          })
        );
      }
    }
  });

  useEffect(() => {
    if (shown) {
      formik.resetForm();
    }
  }, [shown]); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  const handleChangeLanguages = (key) => {
    if(typeof key === 'string') {
      const newLanguages = formik.values.languages
        .filter((language) => language !== key);
      formik.setFieldValue('languages', newLanguages, true);
    } else {
      if (!key?.length) {
        formik.setFieldValue('languages', [], true);
      } else {
        formik.setFieldValue('languages', key, true);
      }
    }
  }

  const handleCloseModal = () => {
    setShown(false);
    formik.resetForm(initialValues);
  }

  return (
    <>
      <Modal
        scrollable
        centered
        size="lg"
        show={shown}
        onHide={handleCloseModal}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            Create Feature
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={handleCloseModal}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body className="px-0">
          <form
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            className="form d-flex my-2 mx-2 flex-column m-0"
          >
            <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
              <div className="w-150px min-w-150px mr-5">Feature Name</div>
              <Input
                withFeedbackLabel={false}
                type="text"
                name="name"
                placeholder="Enter feature name"
                form={formik}
                field={formik?.getFieldProps('name')}
                {...formik?.getFieldProps("name")}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
              <div className="w-150px min-w-150px mr-5">Product</div>
              <Select
                placeholder="Select Product"
                name="productId"
                className="bg-white border-secondary"
                variant="outlined"
                withFeedbackLabel={false}
                form={formik}
                field={formik?.getFieldProps('productId')}
                {...formik?.getFieldProps("productId")}
              >
                <option value="" />
                {products.map((product, index) => (
                  <option key={index} value={product?._id}>{product?.name}</option>
                ))}
              </Select>
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
              <div className="w-150px min-w-150px mr-5">Languages</div>
              <FormControl margin="normal" className="my-0">
                <MUISelect
                  id="languages"
                  multiple
                  className="bg-white border-secondary"
                  classes={{ root: 'p-0 min-h-40px' }}
                  variant="outlined"
                  MenuProps={selectMenuProps}
                  value={formik.values.languages || []}
                  onChange={(e) => handleChangeLanguages(e.target.value)}
                  renderValue={(values, index) => (
                    <div key={index} className={classes.chipListContainer}>
                      {values.map((value) => {
                        const language = languages.find((language) => language._id === value);
                        return (
                          <Chip
                            key={value}
                            label={language?.name || value}
                            size="medium"
                            className={`${classes.chip} bg-primary text-white`}
                            onDelete={() => handleChangeLanguages(value)}
                            deleteIcon={
                              <Cancel onMouseDown={event => event.stopPropagation()}/>
                            }
                          />
                        )
                      })}
                    </div>
                  )}
                >
                  {languages?.map((language, index) => (
                    <MenuItem key={index} value={language?._id}>{language.name}</MenuItem>
                  ))}
                </MUISelect>
              </FormControl>
            </div>
            <div className="d-flex justify-content-end w-100 border-top pt-3">
              <Button
                color="default"
                variant="contained"
                className="mr-3 bg-light-primary text-primary"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className="bg-primary text-white mr-5"
              >
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateFeatureModal;
