import React, {useEffect, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import { actions } from '~/app/modules/Translations/components/Languages/actions';
import { sortCaret } from '~/_metronic/_helpers';
import {
  textFilterOnBlurHelper,
} from '~/app/helpers';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ProgressBar from "../CustomProgressBar";
import { Link } from "react-router-dom";
import { Card, CardContent } from "@material-ui/core";
import { actions as translatorsAction } from '~/app/modules/Translations/components/Translators/actions';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import UndoIcon from '@material-ui/icons/Undo';
import ModalForVerifyKeyTranslations from './ModalForVerifyKeyTranslations';
import { Alert, AlertTitle } from '@material-ui/lab';
import ModalForSlackId from "./ModalForSlackId";
import enums from "~/app/helpers/enums";

const sortableColumn = {
  sort: true, sortCaret,
};

const renderTranslators = (translators, dispatch) => {
  if (!translators?.length) {
    return <div />;
  }

  const handleClickOnLink = (name) => () => {
    dispatch(translatorsAction.setFilter({ name }));
  };

  return (
    <div className="d-flex flex-wrap">
      {translators.map((translator, index) => (
        <Link
          onClick={handleClickOnLink(translator.fullName)}
          to={`/translations/translators`}
          key={translator._id}
        >
          {translator.fullName}{index + 1 < translators.length && ', '}
        </Link>
      ))}
    </div>
  )
};

const renderLanguageField = (row) => {
  if (row.isExpand) {
    return (
      <div className="d-flex">
        <span className="text-primary min-w-15px">▼</span> {row.name}
      </div>
    );
  }

  return (
    <div className="d-flex">
      <span className="text-primary min-w-15px">▶</span>{row.name}
    </div>
  );
};

const renderActionsField = (row, setShown, setSelectedRow, setVerifyType, setShowSlackIdModal, setSelectedLanguage) => {
  const handleClick = (verifyType) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedRow(row);
    setVerifyType(verifyType);
    setShown(true);
  };

  const handleOpenSlackId = () => {
    setSelectedLanguage(row);
    setShowSlackIdModal(true);
  };

  return (
    <div className="d-flex">
      <div onClick={handleOpenSlackId} className="cursor-pointer mr-2">
        <i className="fab fa-slack text-primary" />
      </div>
      <div onClick={handleClick(true)} className="cursor-pointer mr-2">
        <CheckBoxIcon color="secondary" />
      </div>
      <div onClick={handleClick(false)} className="cursor-pointer">
        <UndoIcon color="secondary" />
      </div>
    </div>
  )
};

const columns = (dispatch, setShown, setSelectedRow, setVerifyType, setShowSlackIdModal, setSelectedLanguage) => [{
  ...sortableColumn,
  dataField: 'name', text: 'Language',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }, }),
  formatter: (_cell, row) => renderLanguageField(row),
  style: { width: '200px', minWidth: '200px', textAlign: 'left' },
}, {
  dataField: 'translatorsWithFullName', text: 'Translators',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }, }),
  formatter: (translatorsWithFullName) => renderTranslators(translatorsWithFullName, dispatch),
  style: { width: '300px', minWidth: '300px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'featuresCount', text: 'Features',
  style: { width: '150px', minWidth: '150px', textAlign: 'left' },
}, {
  dataField: 'progress', text: 'Overall Progress',
  style: { width: '150px', minWidth: '150px', textAlign: 'left' },
  formatter: (progress, row) => (<ProgressBar percent={row.progress} approvedPercent={row.approvedProgress} />),
}, {
  dataField: 'actions', text: 'Actions',
  formatter: (_cell, row) => renderActionsField(row, setShown, setSelectedRow, setVerifyType, setShowSlackIdModal, setSelectedLanguage),
  style: { width: '80px', minWidth: '80px', textAlign: 'left' },
}];

const Languages = () => {
  const dispatch = useDispatch();
  const report = useSelector(state => state.languages.report, shallowEqual);
  const [dataWithExpanded, setDataWithExpanded] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [shown, setShown] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [verifyType, setVerifyType] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [showSlackIdModal, setShowSlackIdModal] = useState(false);
  const verificationError = useSelector((state) => state.languages.verificationError);
  const verificationSuccess = useSelector((state) => state.languages.verificationSuccess);

  const handleCloseErrorAlert = () => {
    setShowErrorAlert(false);
  }

  const handleCloseSuccessAlert = () => {
    setShowSuccessAlert(false);
  }

  useEffect(() => {
    setDataWithExpanded(report);
  }, [setDataWithExpanded, report]);

  useEffect(() => {
    if(verificationSuccess) {
      setShown(false);
      dispatch(actions.cleanVerificationSuccess());
      setShowSuccessAlert(true);
      setTimeout(handleCloseSuccessAlert, 3000);
    }
  }, [dispatch,verificationSuccess])

  useEffect(() => {
    if(verificationError) {
      setShown(false);
      setShowErrorAlert(true);
      dispatch(actions.cleanVerificationError());
      setTimeout(handleCloseErrorAlert, 3000);
    }
  }, [dispatch,verificationError])

  useEffect(() => {
    dispatch(actions.getLanguages());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnExpand = (row, isExpand) => {
    if (!row.isExpand) {
      const newData = dataWithExpanded.map((dataRow) => {
        if (row._id === dataRow._id) {
          return {...dataRow, isExpand};
        }
        return dataRow;
      })
      setDataWithExpanded(newData);
      setExpanded([...expanded, row._id]);
    } else {
      const newData = dataWithExpanded.map((dataRow) => {
        if (row._id === dataRow._id && row.isExpand) {
          const newDataRow = {...dataRow};
          delete newDataRow.isExpand;
          return newDataRow;
        }
        return dataRow;
      })
      setDataWithExpanded(newData);
      setExpanded(expanded.filter((_id) => (row._id !== _id)));
    }
  }

  const expandRow = {
    renderer: row => {
      return (
        <div>
          <div className="d-flex">
            <h4 className="min-w-180px">Feature</h4>
            <h4 className="ml-2 min-w-180px">Progress</h4>
          </div>
          {row.features.map((feature) => (
            <div key={feature._id} className="min-h-40px border-top d-flex w-100">
              <div className="min-w-180px w-180px h-40px text-muted d-flex align-items-center">{feature.name}</div>
              <div className="ml-2 min-w-180px w-180px h-40px d-flex align-items-center">
                <ProgressBar
                  key={feature._id}
                  percent={feature?.progress}
                  approvedPercent={feature?.approvedProgress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    },
    expanded,
    onExpand: handleOnExpand,
  };

  const rowStyle = (row) => {
    if (row.isExpand) {
      return { cursor: 'pointer', background: '#f1f1f1' };
    }
    return { cursor: 'pointer' };
  };

  const reportColumns = columns(dispatch, setShown, setSelectedRow, setVerifyType, setShowSlackIdModal, setSelectedLanguage);

  return (
    <div>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center min-h-40px">
          <h3 className="pl-2">Languages</h3>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-center flex-column min-w-850px max-w-950px mx-auto">
        <Card>
          <CardContent className="border text-dark border-light-dark rounded">
            <BootstrapTable
              classes="table table-head-custom table-vertical-center overflow-hidden"
              headerClasses="table table-vertical-top"
              wrapperClasses="rounded my-2"
              bootstrap4
              responsive
              keyField="_id"
              columns={reportColumns}
              data={dataWithExpanded}
              filter={ filterFactory() }
              defaultSorted={[{
                dataField: 'name',
                order: enums.sortDirections.desc,
              }]}
              rowStyle={ rowStyle }
              expandRow={ expandRow }
            />
          </CardContent>
        </Card>
      </div>
      <ModalForSlackId language={selectedLanguage} shown={showSlackIdModal} setShown={setShowSlackIdModal} />
      <ModalForVerifyKeyTranslations verify={verifyType} language={selectedRow} setShown={setShown} shown={shown} />
      {showErrorAlert && (
        <div style={{ position: "fixed", top: '30%', zIndex: '1000', left: '45%' }}>
          <Alert onClose={handleCloseErrorAlert} severity="error" variant="filled">
            <AlertTitle>Something Went Wrong</AlertTitle>
          </Alert>
        </div>
      )}
      {showSuccessAlert && (
        <div style={{ position: "fixed", top: '30%', zIndex: '1000', left: '45%' }}>
          <Alert onClose={handleCloseSuccessAlert} severity="success" variant="filled">
            <AlertTitle>{verifyType ? 'Keys Verified Successfully!' : 'Keys Un-verified Successfully!'}</AlertTitle>
          </Alert>
        </div>
      )}
    </div>
  );
};

export default Languages;
