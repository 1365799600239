import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('surveys');

actionTypes.GenerateReportAsCsv = 'surveys/generate-report-as-csv';
actionTypes.GenerateReportAsCsvSuccess = 'surveys/generate-report-as-csv-success';
actionTypes.SetCreatedStartDate = 'surveys/set-created-start-date';
actionTypes.SetCreatedEndDate = 'surveys/set-created-end-date';
actionTypes.SetUpdatedStartDate = 'surveys/set-updated-start-date';
actionTypes.SetUpdatedEndDate = 'surveys/set-updated-end-date';
actionTypes.SetUseCreatedDate = 'surveys/set-use-created-date';
actionTypes.SetUseLastUpdatedDate = 'surveys/set-use-last-updated-date';
actionTypes.CreateSharedLink = 'surveys/create-shared-link';
actionTypes.CreateSharedLinkFailed = 'surveys/create-shared-link-failed';
actionTypes.CreateSharedLinkSuccess = 'surveys/create-shared-link-success';
actionTypes.SetParams = 'surveys/set-params';
actionTypes.SetSurveyGroupsIds = 'surveys/set-survey-groups-ids';
actionTypes.ClearSharedLink = 'surveys/clear-shared-link';

actions.createSharedLink = (params) => ({type: actionTypes.CreateSharedLink, payload: params});
actions.createSharedLinkSuccess = (link) => ({type: actionTypes.CreateSharedLinkSuccess, payload: link});
actions.createSharedLinkFailed = (error) => ({type: actionTypes.CreateSharedLinkFailed, payload: error});
actions.setParams = (params) => ({type: actionTypes.SetParams, payload: params});
actions.clearSharedLink = () => ({type: actionTypes.ClearSharedLink});
actions.setSurveyGroupsIds = (groupId) => ({ type: actionTypes.SetSurveyGroupsIds, payload: groupId });

actions.generateReportAsCsv = () => ({
  type: actionTypes.GenerateReportAsCsv,
  payload: {}
});

actions.generateReportAsCsvSuccess = () => ({
  type: actionTypes.GenerateReportAsCsvSuccess,
  payload: {}
});

actions.setCreatedStartDate = (date) => ({
  type: actionTypes.SetCreatedStartDate,
  payload: date
});

actions.setCreatedEndDate = (date) => ({
  type: actionTypes.SetCreatedEndDate,
  payload: date
});

actions.setUpdatedStartDate = (date) => ({
  type: actionTypes.SetUpdatedStartDate,
  payload: date
});

actions.setUpdatedEndDate = (date) => ({
  type: actionTypes.SetUpdatedEndDate,
  payload: date
});

actions.setUseCreatedDate = (value) => ({
  type: actionTypes.SetUseCreatedDate,
  payload: value
});

actions.setUseLastUpdatedDate = (value) => ({
  type: actionTypes.SetUseLastUpdatedDate,
  payload: value
});


export {
  actionTypes,
  actions,
};
