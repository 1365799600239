import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('export-user-data');

actionTypes.ExportToCsv = 'export-user-data/export-to-csv';
actionTypes.ExportToCsvSuccess = 'export-user-data/export-to-csv-success';
actionTypes.SetCountryCode = 'export-user-data/set-language-codes';
actionTypes.SetUserType = 'export-user-data/set-user-type';
actionTypes.SetRegistrationPublisherId = 'export-user-data/set-registration-pub-id';
actionTypes.GetCountryCodes = 'export-user-data/get-country-codes';
actionTypes.GetCountryCodesSuccess = 'export-user-data/get-country-codes-success';

actionTypes.SetShowUserIdsForm = 'export-user-data/set-show-user-ids-form';
actionTypes.ExportToCsvByIds = 'export-user-data/export-to-csv-by-ids';
actionTypes.ExportToCsvByIdsSuccess = 'export-user-data/export-to-csv-by-ids-success';
actionTypes.SetUserIds = 'export-user-data/set-user-ids';

actions.exportToCsv = () => ({
  type: actionTypes.ExportToCsv,
  payload: {}
});

actions.exportToCsvSuccess = () => ({
  type: actionTypes.ExportToCsvSuccess,
  payload: {}
});

actions.getCountryCodes = (results) => ({
  type: actionTypes.GetCountryCodes,
  payload: results
});

actions.getCountryCodesSuccess = (results) => ({
  type: actionTypes.GetCountryCodesSuccess,
  payload: results
});

actions.setCountryCode = (languageCode) => ({
  type: actionTypes.SetCountryCode,
  payload: languageCode
});

actions.setUserType = (userType) => ({
  type: actionTypes.SetUserType,
  payload: userType
});

actions.setRegistrationPublisherId = (registrationPublisherId) => ({
  type: actionTypes.SetRegistrationPublisherId,
  payload: registrationPublisherId
});

actions.setShowUserIdsForm = (showUserIdsForm) => ({
  type: actionTypes.SetShowUserIdsForm,
  payload: showUserIdsForm,
});

actions.exportToCsvByIds = () => ({
  type: actionTypes.ExportToCsvByIds,
  payload: {}
});

actions.exportToCsvByIdsSuccess = () => ({
  type: actionTypes.ExportToCsvByIdsSuccess,
  payload: {}
});

actions.setUserIds = (userIds) => ({
  type: actionTypes.SetUserIds,
  payload: userIds
});

export {
  actionTypes,
  actions,
};
