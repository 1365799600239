import { fetchReportAsCsvHelper, fetchReportHelper } from '~/app/helpers';

const FETCH_REPORT_URL = '/veriff-log';
const FETCH_REPORT_AS_CSV_URL = '/veriff-log-csv';

export default {
  fetchReport: fetchReportHelper(FETCH_REPORT_URL),
  fetchReportAsCsv: fetchReportAsCsvHelper(FETCH_REPORT_AS_CSV_URL, 'veriffLog'),
};

