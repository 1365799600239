import config from '~/config';
import fetch from '~/redux/fetch';

const CREATE_SHARED_LINK_URL = '/shared-link';

const createSharedLink = async (params) => {
  const response = await fetch(`${config.apiBaseUrl}${CREATE_SHARED_LINK_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ params }),
  });
  return response.json();
}

export default {
  createSharedLink,
};

