import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { actions } from "~/app/modules/User/actions";
import {
  Card, CardContent, Grid
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "~/_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { Modal } from 'react-bootstrap';
import JSONPretty from 'react-json-pretty';
import { newDateFormatLong } from "~/app/helpers";

const EmailFieldWithIcon = ({ icon, title, description, bounced }) => {
  let classNameForDescription = '';
  let descriptionText = description;
  if(bounced) {
    if(description) {
      classNameForDescription = 'text-danger'
      descriptionText = 'True'
    } else {
      classNameForDescription = 'text-success'
      descriptionText = 'False'
    }
  }

  return (
    <div className="d-flex mb-10">
        <span className="d-flex min-w-40px min-h-40px svg-icon svg-icon-dark svg-icon-3x bg-light-primary rounded align-items-center justify-content-center">
          <SVG
            src={toAbsoluteUrl(icon)}
          />
        </span>
      <div className="d-flex justify-content-between flex-column ml-2">
        <div className="text-muted mb-2">{title}</div>
        <div className={classNameForDescription}>{descriptionText}</div>
      </div>
    </div>
  )
}

const EmailsDatesCard = () => {
  const userEmails = useSelector((state) => state.user.userEmails);
  const userInformation = useSelector(state => state.user.userInformation);
  let classNameForCheckMark = "d-flex min-w-40px min-h-40px svg-icon svg-icon-3x rounded align-items-center justify-content-center";
  const lastEmailSent = userEmails?.find((email) => !!email.sentAt)
  if(lastEmailSent?.deliveredAt) {
    classNameForCheckMark += ' svg-icon-success';
  }
  const lastEmailOpened = userEmails?.find((email) => !!email.openedAt);
  const lastEmailClicked = userEmails?.find((email) => !!email.clickedAt);
  console.log('userInformation', userInformation);

  const renderDescriptionForGmailInstructions = (gmailInstructions) => {
    if (!gmailInstructions || !gmailInstructions?.status) {
      return "Wasn't shown";
    } else if (gmailInstructions?.status === 'closed') {
      return 'Skipped';
    }

    return (<span className="d-flex">
      <span className="mr-2">Email Sent</span>
      {newDateFormatLong(gmailInstructions?.notifiedAt)}
    </span>);
  };

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          Emails Dates
        </h3>
        <div className="d-flex justify-content-between align-items-start">
          <EmailFieldWithIcon
            title="Last Email Sent"
            description={newDateFormatLong(lastEmailSent?.sentAt)}
            icon="/media/svg/icons/Communication/Send.svg"
          />
          <span className={classNameForCheckMark}>
            <SVG
              src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')}
            />
          </span>
        </div>
        <EmailFieldWithIcon
          title="Last Email Opened"
          description={newDateFormatLong(lastEmailOpened?.openedAt)}
          icon="/media/svg/icons/Communication/Mail-opened.svg"
        />
        <EmailFieldWithIcon
          title="Last Email Clicked"
          description={newDateFormatLong(lastEmailClicked?.clickedAt)}
          icon="/media/svg/icons/General/Cursor.svg"
        />
        <EmailFieldWithIcon
          title="Email Bounced"
          description={userEmails?.[0]?.bouncedAt}
          bounced
          icon="/media/svg/icons/Code/Warning-1-circle.svg"
        />
        <EmailFieldWithIcon
          title="Tips Email Notification"
          description={renderDescriptionForGmailInstructions(userInformation?.user?.gmailInstructions)}
          icon="/media/svg/icons/Communication/Mail-notification.svg"
        />
      </CardContent>
    </Card>
  )
};

function StatisticsTable({entities}) {
  const columns = [
    { dataField: "item", text: "ITEM" },
    { dataField: "sent", text: "SENT" },
    { dataField: "opened", text: "OPENED" },
    { dataField: "clicked", text: "CLICKED" },
    { dataField: "ctr", text: "CR" },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={false}
      borderless={true}
      classes="table table-vertical-top overflow-hidden"
      bootstrap4
      size="sm"
      responsive="lg"
      headerClasses="bg-light rounded"
      remote
      keyField="item"
      data={entities}
      columns={columns}
    >
    </BootstrapTable>
  );
}

const EmailsStatisticsCard = () => {
  const userEmailStats = useSelector((state) => state.user.userEmailStats);

  const findStatisticsEntities = () => {
    if(!userEmailStats.length) {
      return [];
    }

    return [
      {
        item: 'Total Emails',
        sent: userEmailStats[0].totalEmailSent,
        opened: userEmailStats[0].totalEmailOpened,
        clicked: userEmailStats[0].totalEmailClicked,
        ctr: `${(userEmailStats[0].totalEmailCTR * 100).toFixed(2)}%`,
      },
      {
        item: 'Surveys Emails',
        sent: userEmailStats[0].surveyEmailSent,
        opened: userEmailStats[0].surveyEmailOpened,
        clicked: userEmailStats[0].surveyEmailClicked,
        ctr: `${(userEmailStats[0].surveyEmailCTR * 100).toFixed(2)}%`,
      },
      {
        item: 'Good Surveys Emails',
        sent: userEmailStats[0].goodSurveyEmailSent,
        opened: userEmailStats[0].goodSurveyEmailOpened,
        clicked: userEmailStats[0].goodSurveyEmailClicked,
        ctr:`${(userEmailStats[0].goodSurveyEmailCTR * 100).toFixed(2)}%`,
      },
    ]
  }

  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded pb-20">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          Emails Dates
        </h3>
        <StatisticsTable entities={findStatisticsEntities()} />
      </CardContent>
    </Card>
  )
};

function ReportTable({ entities, setSNSLogs }) {
  const columns = [
    { dataField: "_id", text: "Email ID" },
    { dataField: "templateName", text: "Email Type" },
    { dataField: "sentAt", text: "Send Date", formatter: (value) => newDateFormatLong(value) },
    { dataField: "openedAt", text: "Opened Date", formatter: (value) => newDateFormatLong(value) },
    { dataField: "clickedAt", text: "Clicked Date", formatter: (value) => newDateFormatLong(value) },
    { dataField: "status", text: "Rejected", formatter: (status) => status === 'Rejected' ? 'Yes' : 'No' },
    {
      dataField: "snsLogs",
      text: "SNS log",
      formatter: (snsLogs) => (
        <button
          type="button"
          className="btn btn-light-primary text-dark btn-sm"
          onClick={setSNSLogs(snsLogs)}
        >
          Show SNS Log
        </button>
      )
    },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive max-h-400px mb-7"
      bordered={true}
      classes="table table-vertical-top overflow-hidden"
      bootstrap4
      size="sm"
      responsive="lg"
      remote
      keyField="_id"
      data={entities}
      columns={columns}
    >
    </BootstrapTable>
  );
}

const SNSModal = ({ description, shown, setShown }) => {
  return (
    <>
      <Modal
        scrollable
        centered
        size="lg"
        show={shown}
        onHide={() => setShown(false)}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            SNS Log
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={() => setShown(false)}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body>
          <JSONPretty
            json={description}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

const EmailsReportCard = ({ setShow, setModalDescription }) => {
  const userEmails = useSelector((state) => state.user.userEmails);

  const handleSNSLogClick = (snsLog) => () => {
    setModalDescription(JSON.stringify(snsLog));
    setShow(true);
  }

  return (
    <Card className="mt-2">
      <CardContent className="border text-dark border-light-dark rounded">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          Email Report
        </h3>
        <ReportTable entities={userEmails} setSNSLogs={handleSNSLogClick} />
      </CardContent>
    </Card>
  )
};

const NotificationsEmail = ({ userId }) => {
  const dispatch = useDispatch();
  const [shown, setShown] = useState(false);
  const [modalDescription, setModalDescription] = useState('');

  useEffect(() => {
    dispatch(actions.getUserEmails(userId));
    dispatch(actions.getUserEmailsStats(userId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={4}>
        <EmailsDatesCard />
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <EmailsStatisticsCard />
      </Grid>
      <Grid item xs={12}>
        <EmailsReportCard setModalDescription={setModalDescription} setShow={setShown} />
      </Grid>
      <SNSModal shown={shown} setShown={setShown} description={modalDescription} />
    </Grid>
  )
};

export default NotificationsEmail;
