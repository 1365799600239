import { useSelector } from "react-redux";
import {Modal} from "react-bootstrap";
import {CloseOutlined} from "@material-ui/icons";
import {newDateFormat} from "~/app/helpers";
import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

const BatchHistoryModal = ({ shown, setShown }) => {
  const batchHistory = useSelector(state => state.amazonInventory.batchHistory);

  const handleCloseModal = () => {
    setShown(false);
  };

  const renderRow = (item, index) => (
    <TableRow key={index}>
      <TableCell>{item?.createdAt ? newDateFormat(item.createdAt) : '-'}</TableCell>
      <TableCell>{item?._id}</TableCell>
      <TableCell>{item?.count}</TableCell>
    </TableRow>
  )

  return (
    <Modal
      scrollable
      centered
      size="lg"
      show={shown}
      onHide={handleCloseModal}
    >
      <Modal.Header className="d-flex justify-content-between align-items-start">
        <Modal.Title>
          History
        </Modal.Title>
        <div className="cursor-pointer pb-2 py-2" onClick={handleCloseModal}><CloseOutlined /></div>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <TableHead>
            <TableRow className="text-uppercase text-secondary font-weight-bold">
              <TableCell>
                Upload Date
              </TableCell>
              <TableCell>
                Batch Id
              </TableCell>
              <TableCell>
                Upload Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {batchHistory.map(renderRow)}
          </TableBody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default BatchHistoryModal;
