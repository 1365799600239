import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { actions } from "~/app/modules/User/actions";
import {
  Grid, Button
} from "@material-ui/core";
import { ProgressBar } from "react-bootstrap";
import { Card, CardBody } from "~/_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import { sortCaret, headerSortingClasses } from "~/_metronic/_helpers";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import {textFilterOnBlurHelper} from "~/app/helpers";

export function CustomersTable({entities, setOrder}) {
  const [tableData, setTableData] = useState([]);
  const userInformation = useSelector((state) => state.user.userInformation);
  const paymentMethodAnswer = userInformation?.user?.profiler?.find((q) => q.questionId === -1)?.answers?.[0] || '';
  const paymentMethodAnswerOrder = userInformation?.user?.profiler?.findIndex((q) => q?.questionId === -1);

  useEffect(() => {
    const newEntities = [...entities];
    if (paymentMethodAnswer) {
      newEntities.push({
        questionId: -1,
        questionText: 'Was is your payment method?',
        answersText: paymentMethodAnswer,
        questionType: 'Profiler',
        order: paymentMethodAnswerOrder + 1,
      });
    }
    setTableData(newEntities);
  }, [entities, paymentMethodAnswer, paymentMethodAnswerOrder]);

  const columns = [
    {  dataField: "questionType", text: "Question Type", sortCaret, headerSortingClasses, sort: true},
    { dataField: "questionId", text: "Questions #", filter: customFilter(),
      filterRenderer:(onFilter, column) =>
        textFilterOnBlurHelper(onFilter, column, {style: { width: "180px" }}), },
    {  dataField: "questionText", text: "Question Text", filter: customFilter(),
      filterRenderer:(onFilter, column) =>
        textFilterOnBlurHelper(onFilter, column, {style: { width: "180px" }}), },
    {  dataField: "answerNumber", text: "Answer #"  },
    {  dataField: "answersText", text: "Answer Text"  },
    {  dataField: "surveyId", text: "Surveys ID" },
    { dataField: "order",  text: "Order", sortCaret, headerSortingClasses, sort: true },
    { dataField: "source", text: "Source"  },
  ];


  const getHandlerTableChange = (setData) => {
    return (type, { sortField, sortOrder }) => {
      setOrder(sortOrder);
      const newData = tableData.sort((a, b) => (
        sortOrder === 'asc' ? (a[sortField] - b[sortField]) : (b[sortField] - a[sortField])
      ))
      setData(newData)
    };
  }

  return (
    <BootstrapTable
      wrapperClasses="table-responsive max-h-500px mb-7"
      bordered={true}
      classes="table table-vertical-top overflow-hidden"
      bootstrap4
      size="sm"
      onTableChange={getHandlerTableChange(setTableData)}
      defaultSorted={[{
        dataField: 'order',
        order: 'asc'
      }]}
      pagination={ paginationFactory({
        showTotal: true,
        pageStartIndex: 1,
        sizePerPageList: [
          { text: '20', value: 20},
          { text: 'All', value: tableData.length },
        ]}) }
      keyField="questionId"
      filter={ filterFactory() }
      data={tableData}
      columns={columns}
    >
    </BootstrapTable>
  );
}


const Profiler = ({ userId }) => {
  const userInformation = useSelector(state => state.user.userInformation);
  const userProfilerQuestions = useSelector(state => state.user.userProfilerQuestions);
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc')

  useEffect(() => {
    dispatch(actions.getUserProfilerQuestions(userId));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if(!userInformation.user) {
    return null;
  }

  const initIds = new Set(userInformation.user?.initialQuestions?.map(q => q.questionId))
  const profilerIds = new Set(userInformation.user?.profiler?.map(q => q.questionId))

  const intersection = (setA, setB) => {
    const _intersection = new Set();
    for (let elem of setB) {
      if (setA.has(elem)) {
        _intersection.add(elem);
      }
    }
    return _intersection;
  }

  const profilerAnsweredIds = intersection(initIds, profilerIds);
  const preliminaryIds = profilerIds.size - profilerAnsweredIds.size;

  const percentageOfQuestionsAnswered = (profilerAnsweredIds.size / userInformation.user?.initialQuestions?.length * 100).toFixed(2) || 0;

  const tableEntities = () => {
    if(!userProfilerQuestions.length) {
      return [];
    }
    const entities = userProfilerQuestions.map((question) => {
      const answerForCurrentQuestion = userInformation.user?.profiler?.find((q) => q.questionId === question.questionId)?.answers || [];
      const answersText = () => {
        return !question.options.length ?
          answerForCurrentQuestion[0]
          : question.options.filter((option) => answerForCurrentQuestion.includes(option.precode)).map((option) => option.optionText).join(' ');
      }
      return ({
        questionType: profilerAnsweredIds.has(question.questionId) ? 'Profiler' : 'Preliminary',
        questionId: question.questionId,
        questionText: question.text,
        answerNumber: question.options.length ?
          answerForCurrentQuestion?.join(' ') || ''
          : '',
        answersText: answersText(),
        surveyId: '',
        order: question.order,
        source: ''
      })
    })
    entities.sort((a,b) => {
      return a.questionType - b.questionType
    });
    return entities;
  }

  const handleClickOnExportToCSV = () => {
    dispatch(actions.generateReportAsCSV(userId, Array.from(profilerAnsweredIds), order))
  }

  return (
    <Grid container spacing={2} className="flex-row-fluid">
      <Grid item xs={12} md={6} lg={6}>
        <div className="rounded bg-light-success min-h-80px px-5 py-5">
          <div className="font-weight-boldest font-size-h5 text-success">Profiler Progress</div>
          <div className="mb-2">
            <span className="font-weight-boldest font-size-h2">{!isNaN(percentageOfQuestionsAnswered) ? percentageOfQuestionsAnswered : 0 } %</span>
            <span className="ml-3 text-muted font-size-md">
              {profilerAnsweredIds.size}/{userInformation.user?.initialQuestions?.length || 0}
            </span>
          </div>
          <div><ProgressBar now={percentageOfQuestionsAnswered} variant="success" /></div>
        </div>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <div className="rounded bg-light-success min-h-80px px-5 pt-5 pb-11">
          <div className="font-weight-boldest font-size-h5 text-success">Preliminary Questions</div>
          <div>
            <span className="font-weight-boldest font-size-h2">{preliminaryIds}</span>
            <span className="ml-5 text-muted font-size-md">answered</span>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="d-flex justify-content-end pb-3">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleClickOnExportToCSV}
              >
                Export To CSV
              </Button>
            </div>
            <CustomersTable entities={tableEntities()} setOrder={setOrder} />
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
};

export default Profiler;
