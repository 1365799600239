import React, {useEffect, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import { actions } from '~/app/modules/Translations/components/Products/actions';
import { sortCaret } from '~/_metronic/_helpers';
import {
  textFilterOnBlurHelper,
} from '~/app/helpers';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ProgressBar from "../CustomProgressBar";
import { Button, Card, CardContent } from "@material-ui/core";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "~/_metronic/_helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { CloseOutlined } from "@material-ui/icons";
import { Input } from "~/_metronic/_partials/controls";
import {Link} from "react-router-dom";
import { actions as featuresActions } from '~/app/modules/Translations/components/Features/actions';
import ModalForPublishing from "./ModalForPublishing";
import enums from "~/app/helpers/enums";

const sortableColumn = {
  sort: true, sortCaret,
};

const initialValues = {
  name: '',
}

const CreateAndEditProductModal = ({ selectedRow, shown, setShown, setSelectedRow }) => {
  const dispatch = useDispatch();
  const ProductSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Minimum 3 symbols")
      .required(),
  });

  const onSubmit = (name, productId) => {
    if (productId) {
      dispatch(actions.updateProduct({ name, productId }));
    } else {
      dispatch(actions.createProduct(name));
    }
  }

  const formik = useFormik({
    initialValues: selectedRow?._id ? selectedRow : initialValues,
    validationSchema: ProductSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        onSubmit(values.name, selectedRow?._id);
        setShown(false);
        setSelectedRow({});
        formik.resetForm(initialValues);
      } catch (error) {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (shown) {
      formik.resetForm();
    }
  }, [shown]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = () => {
    setShown(false);
    formik.resetForm(initialValues);
  }

  return (
    <>
      <Modal
        scrollable
        centered
        size="md"
        show={shown}
        onHide={handleCloseModal}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            {Object.keys(selectedRow).length ? 'Edit Product' : 'Create Product'}
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={handleCloseModal}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body className="px-0">
          <form
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            className="form d-flex my-2 mx-2 flex-column m-0"
          >
            <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
              <div className="w-150px min-w-150px mr-5">Product</div>
              <Input
                withFeedbackLabel={false}
                type="text"
                name="product"
                placeholder="Enter product name"
                form={formik}
                field={formik?.getFieldProps('name')}
                {...formik?.getFieldProps('name')}
              />
            </div>
            <div className="d-flex justify-content-end w-100 border-top pt-3">
              <Button
                color="default"
                variant="contained"
                className="mr-3 bg-light-primary text-primary"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className="bg-primary text-white mr-5"
              >
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

const productIdLink = (product, dispatch) => {
  const handleClick = () => {
    dispatch(featuresActions.setFiltersForTable({ productName: product }));
  };


  return (
    <div>
      <Link
        onClick={handleClick}
        to={`/translations/features`}
      >
        {product}
      </Link>
    </div>
    );
};

const renderActionButtons = ({ row, setSelectedRow, setShown, setShownModalForPublishing }) => {
  const handleClickEdit = (e) => {
    e.stopPropagation();
    setSelectedRow(row);
    setShown(true);
  }

  const handleClickPublish = (e) => {
    e.stopPropagation();

    setSelectedRow(row);
    setShownModalForPublishing(true);
  }
  return (
    <div>
      <span onClick={handleClickEdit} className="svg-icon svg-icon-primary h-20 cursor-pointer">
        <SVG
          src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
        />
      </span>
      <span onClick={handleClickPublish} className="svg-icon svg-icon-primary h-20 cursor-pointer">
        <SVG
          src={toAbsoluteUrl('/media/svg/icons/Communication/Share.svg')}
        />
      </span>
    </div>
  );
};

const columns = (setSelectedRow, setShown, dispatch, setShownModalForPublishing) => [{
  ...sortableColumn,
  dataField: 'name', text: 'Product',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }}),
  formatter: (product) => productIdLink(product, dispatch),
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'featuresCount', text: 'Features',
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'keyCount', text: 'Keys',
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  dataField: 'progress', text: 'Overall Progress',
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
  formatter: (progress, row) => (<ProgressBar percent={row.progress} approvedPercent={row.approvedProgress} />),
}, {
  dataField: 'actions', text: 'Actions',
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
  formatter: (_cell, row) => renderActionButtons({
    row, setSelectedRow, setShown, dispatch, setShownModalForPublishing,
  }),
}];

const Products = () => {
  const dispatch = useDispatch();
  const report = useSelector(state => state.products.report, shallowEqual);
  const [selectedRow, setSelectedRow] = useState({});
  const [shown, setShown] = useState(false);
  const [shownModalForPublishing, setShownModalForPublishing] = useState(false);

  useEffect(() => {
    dispatch(actions.getProducts());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const reportColumns = columns(setSelectedRow, setShown, dispatch, setShownModalForPublishing);

  const handleAddProduct = () => {
    if (selectedRow?._id) {
      setSelectedRow({});
    }
    setShown(true)
  }

  return (
    <div>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center min-h-40px">
          <h3 className="pl-2">Products</h3>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-center flex-column min-w-750px max-w-800px mx-auto">
        <Card>
          <CardContent className="border text-dark border-light-dark rounded">
            <div className="w-100 d-flex justify-content-end">
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddProduct}
                className="bg-primary text-white"
              >
                Create Product
              </Button>
            </div>
            <BootstrapTable
              classes="table table-head-custom table-vertical-center overflow-hidden"
              headerClasses="table table-vertical-top"
              wrapperClasses="rounded my-2"
              bootstrap4
              responsive
              keyField="_id"
              columns={reportColumns}
              data={report}
              filter={ filterFactory() }
              defaultSorted={[{
                dataField: 'name',
                order: enums.sortDirections.desc,
              }]}
              pagination={paginationFactory({ sizePerPage: 10 })}
            >
            </BootstrapTable>
          </CardContent>
        </Card>
      </div>
      <CreateAndEditProductModal
        selectedRow={selectedRow}
        shown={shown}
        setShown={setShown}
        setSelectedRow={setSelectedRow}
      />
      <ModalForPublishing
        setShown={setShownModalForPublishing}
        shown={shownModalForPublishing}
        product={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </div>
  );
};

export default Products;
