import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from "~/app/modules/User/actions";
import { CloseOutlined } from "@material-ui/icons";
import {
  Card, CardContent, Grid
} from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import { Modal } from 'react-bootstrap';
import { newDateFormatLong } from "../../../../helpers";
import {Link} from "react-router-dom";

const UsersWithTheSameIPModal = ({ users, shown, setShown }) => {
  const userInformation = useSelector(state => state.user.userInformation);
  return (
    <>
      <Modal
        scrollable
        centered
        size="md"
        show={shown}
        onHide={() => setShown(false)}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            Users with the same IP
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={() => setShown(false)}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body className="py-10 pl-20">
          {users.filter((user) => user._id !== userInformation.user._id).map((user) => (
              <Link key={user._id} to={`/users/${user._id}`} target="_blank" rel="noopener noreferrer">
                <div className="my-3 text-primary cursor-pointer" >{user.email || user._id}</div>
              </Link>
          ))}
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
}

function LogsTable({entities}) {
  const [shown, setShown] = useState(false);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);

  const handleShowLogButtonClick = (users) => () => {
    setSelectedUsersIds(users);
    setShown(true);
  }

  const renderLogButton = (users) => {
    let className = users.length < 2 ? "btn btn-secondary btn-sm" : "btn btn-light-primary btn-sm"
    return (
      <button
        type="button"
        className={className}
        disabled={users.length < 2}
        onClick={handleShowLogButtonClick(users)}
      >
        Show Users
      </button>
    )
  }

  const columns = [
    { dataField: "checkedAt", text: "Date", formatter: (value) => newDateFormatLong(value)},
    { dataField: "ip", text: "IP"  },
    { dataField: "countryISO", text: "Country" },
    {  dataField: "proxyScore", text: "Proxy Score" },
    {  dataField: "isp", text: "ISP" },
    {  dataField: "netSpeed", text: "Net Speed" },
    {  dataField: "users", text: "User with the same IP", formatter: (users) => renderLogButton(users.map((user) => ({ _id: user._id, email: user.email }))) },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        borderless={true}
        classes="table table-vertical-top overflow-hidden"
        bootstrap4
        size="sm"
        responsive="lg"
        headerClasses="bg-light rounded"
        remote
        keyField="_id"
        data={entities}
        columns={columns}
      >
      </BootstrapTable>
      <UsersWithTheSameIPModal shown={shown} setShown={setShown} users={selectedUsersIds} />
    </>
  );
}

const ReportCard = () => {
  const userIPsWithEmails = useSelector(state => state.user.userIPsWithEmails);

  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded pb-20">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          IPs Report
        </h3>
        <LogsTable entities={userIPsWithEmails} />
      </CardContent>
    </Card>
  )
}


const IPsReport = ({ userId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getUserIPsWithEmails(userId));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const userInformation = useSelector(state => state.user.userInformation);

  if(!userInformation.user) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ReportCard />
      </Grid>
    </Grid>
  )
};

export default IPsReport;
