import { fetchReportAsCsvHelper, fetchReportHelper } from '~/app/helpers';

const FETCH_REPORT_URL = '/orders';
const FETCH_REPORT_AS_CSV_URL = '/orders/csv-report';

export default {
  fetchReport: fetchReportHelper(FETCH_REPORT_URL),
  fetchReportAsCsv: fetchReportAsCsvHelper(FETCH_REPORT_AS_CSV_URL, 'orders'),
};

