import React, {useEffect} from 'react';
import { Button, Card, CardContent } from "@material-ui/core";
import { useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import { actions } from "~/app/modules/Survey/actions";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import TopInformationSection from "./TopInformationSection";
import { renderMappedField, renderPreCodes, renderAnswerOptions } from '~/app/helpers';

const TopInfoBlock = () => {
  const surveyInformation = useSelector((state) => state.survey.surveyInformation);

  return (
    <Card className="mb-3">
      <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center">
        <TopInformationSection surveyInformation={surveyInformation} alignChildren="start">
          <Button
            color="secondary"
            variant="contained"
            className="max-h-30px min-w-110px"
            onClick={() => {}}
          >
            History Log
          </Button>
        </TopInformationSection>
      </CardContent>
    </Card>
  );
};

const renderLogicalOperatorField = (value) => {
  if (value) {
    return value.toUpperCase();
  }

  return '';
};

const columns = () => [{
  dataField: 'questionId', text: 'Internal Question ID',
  style: { width: '150px', textAlign: 'center' },
}, {
  dataField: 'providerQuestionId', text: 'Provider Question ID',
  style: { width: '150px', textAlign: 'center' },
}, {
  dataField: 'mapped', text: 'Mapped',
  style: { width: '100px', textAlign: 'center' },
  formatter: (mapped) => renderMappedField(mapped),
}, {
  dataField: 'baseQuestionId', text: 'Mapped Question ID',
  style: { width: '100px', textAlign: 'center' },
}, {
  dataField: 'questionType', text: 'Question Type',
  style: { width: '200px', textAlign: 'left' },
}, {
  dataField: 'name', text: 'Question Name',
  style: { width: '200px', textAlign: 'left' },
}, {
  dataField: 'type', text: 'Question Type',
  style: { width: '100px', textAlign: 'left' },
}, {
  dataField: 'logicalOperator', text: 'Logical Operator',
  style: { width: '100px', textAlign: 'left' },
  formatter: (logicalOperator) => renderLogicalOperatorField(logicalOperator)
}, {
  dataField: 'text', text: 'Question Text',
  style: { width: '200px', textAlign: 'left' },
}, {
  dataField: 'precodes', text: 'Approved Answer ID',
  style: { width: '100px', textAlign: 'left' },
  formatter: (_cell, row) => renderPreCodes(row?.precodes, row?.options),
}, {
  dataField: 'options', text: 'Approved Answers Text',
  style: { width: '400px', textAlign: 'left' },
  formatter: (_cell, row) => renderAnswerOptions(row?.precodes, row?.options),
}];

const QualificationsTable = () => {
  const questions = useSelector((state) => state.survey.questions);

  const reportColumns = columns();

  return (
    <Card className="mb-3">
      <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center">
          <BootstrapTable
            remote
            classes="table table-head-custom table-vertical-center overflow-hidden"
            headerClasses="table table-vertical-top"
            bootstrap4
            responsive
            keyField="questionId"
            columns={reportColumns}
            data={questions}
            filter={ filterFactory() }
          >
          </BootstrapTable>
      </CardContent>
    </Card>
  );
};

const Qualifications = () => {
  const surveyInformation = useSelector((state) => state.survey.surveyInformation);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(actions.getSurvey(params.surveyId));
    dispatch(actions.getSurveyQuestions(params.surveyId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  if (!surveyInformation._id) {
    return null;
  }

  return (
    <div>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center">
          <h2 className="d-flex align-items-center">
            Survey Data
            <div className="ml-4 mb-0 h5 text-muted">Qualifications</div>
          </h2>
        </CardContent>
      </Card>
      <TopInfoBlock />
      <QualificationsTable />
    </div>
  );
};

export default Qualifications;
