import _ from 'lodash';
import { actionTypes } from '~/app/modules/QuestionsManagement/components/Questions/actions';
import enums from "~/app/helpers/enums";

const initialState = {
  pageNumber: 1,
  pageSize: 20,
  sortBy: 'clickDate',
  sortDirection: enums.sortDirections.desc,
  filters: {},
  languageCode: '',
  countryCode: '',
  provider: '',
  languageCodes: [{ name: 'English', code: 'ENG' }, { name: 'Portuguese', code: 'POR' }],
  countryCodes: [{ name: 'United States', code: 'US' }, { name: 'Brazil', code: 'BR' }],
  providers: ['Federated', 'Market Cube'],

  report: [
    { _id: '43', questionId: '43', provider: 'Federated', languageCode: 'ENG', countryCode: 'US', name: 'GENDER',
      text: 'What is your gender?', type: enums.questionTypes.SINGLE_PUNCH, inProfiler: true, positionInTop: 2,
      positionToShow: 2, mapped: false, surveysCount: 37772, surveysPercentage: 0.95,
      createdAt: '2017-01-01T04:05:00.286+00:00', mappedAt: undefined, questionIsMapped: true,
      answers: [{ _id: '1', text: 'Male' }, { _id: '2', text: 'Female' }],
      mappedQuestions: [],
    },
    { _id: '99951368', questionId: '60', provider: 'Market Cube', languageCode: 'ENG', countryCode: 'US', name: 'GENDER',
      text: 'What is your gender?', type: enums.questionTypes.SINGLE_PUNCH, inProfiler: false, positionInTop: 2,
      positionToShow: 2, mapped: true, surveysCount: 25000, surveysPercentage: 0.95,
      createdAt: '2017-01-01T04:05:00.286+00:00', mappedAt: '2017-01-16T04:05:00.286+00:00', questionIsMapped: false,
      answers: [{ _id: '3', text: 'Male' }, { _id: '4', text: 'Female' }],
      mappedQuestions: [{ _id: '43', name: 'GENDER', text: "What's your gender?", mappingType: 'Full'}]
    },
    { _id: '99954315', questionId: '60', provider: 'Market Cube', languageCode: 'POR', countryCode: 'BR', name: 'GENDER',
      text: 'Sexo?', type: enums.questionTypes.SINGLE_PUNCH, inProfiler: false, positionInTop: 2,
      positionToShow: 2, mapped: true, surveysCount: 20000, surveysPercentage: 0.95,
      createdAt: '2017-01-01T04:05:00.286+00:00', mappedAt: '2017-07-05T03:50:00.286+00:00', questionIsMapped: false,
      answers: [{ _id: '5', text: 'Male' }, { _id: '6', text: 'Female' }],
      mappedQuestions: [{ _id: '43', name: 'GENDER', text: "What's your gender?", mappingType: 'Full'}]
    },
  ],
  totalCount: 0,
  stats: null,

  isLoading: false,
  isLoadingCSV: false,
};

const resetPaginationState = {
  pageNumber: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetPagination:
      return {
        ...state,
        ...resetPaginationState,
        pageSize: action.payload.pageSize,
        pageNumber: action.payload.pageNumber,
      };

    case actionTypes.SetSorting:
      return {
        ...state,
        ...resetPaginationState,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };

    case actionTypes.SetFilter:
      const { field, value } = action.payload;
      const curFilters = state.filters;
      if (value) {
        return {
          ...state,
          ...resetPaginationState,
          filters: {
            ...curFilters,
            [field]: value,
          },
        };
      } else {
        return {
          ...state,
          ...resetPaginationState,
          filters: _.omit(curFilters, field),
        };
      }

    case actionTypes.PaginateReport:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SortReport:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FilterReport:
      return {
        ...state,
        isLoading: true,
      }

    case actionTypes.GenerateReport:
      return {
        ...state,
        pageNumber: 1,
        pageSize: 20,
        report: [],
        totalCount: 0,
        isLoading: true,
      };

    case actionTypes.FetchReportSuccess:
      return {
        ...state,
        report: action.payload.data,
        totalCount: action.payload.totalCount,
        stats: action.payload.stats,
        isLoading: false,
      };

    case actionTypes.FetchReportError:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GenerateReportAsCsv:
      return {
        ...state,
        isLoadingCSV: true,
      };

    case actionTypes.GenerateReportAsCsvSuccess:
      return {
        ...state,
        isLoadingCSV: false,
      };

    case actionTypes.SetLanguageCode:
      return {
        ...state,
        languageCode: action.payload,
      };

    case actionTypes.SetCountryCode:
      return {
        ...state,
        countryCode: action.payload,
      };

    case actionTypes.SetProvider:
      return {
        ...state,
        provider: action.payload,
      };

    default:
      return state;
  }
};
