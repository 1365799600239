const checkPhoneNumber = (value) => (
  value && !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,9}$/im.test(value)
    ? 'Invalid phone number'
    : undefined
);

const findTimeFromMS = (ms) => {
  if (!ms) {
    return '';
  }
  let seconds = Math.floor((ms / 1000) % 60);
  let minutes = Math.floor((ms / (1000 * 60)) % 60);
  let hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? `0${hours}` : hours;
  minutes = (minutes < 10) ? `0${minutes}` : minutes;
  seconds = (seconds < 10) ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}`;
};

const textToTranslatorAboutNewKeys = ({ features, slackHandleId, baseUrl }) => {
  if (!features || !features.length) {
    return '';
  }

  const renderFeaturesSection = features.map((feature) => {
    return `• ${feature.name}`;
  }).join('\n');

  return `Hi <@${slackHandleId}>, \nThere are new keys assigned to you for translation in the following Feature(s):\n${renderFeaturesSection}\n\nPlease log into our <${baseUrl}/auth/login|translation platform> and search for these features.Inside each one, filter the keys by Status as 'Created' and you will see the new keys that need to be translated.
  \nLet us know that you saw this message and please confirm when you will be able to work on this task.
  \nWe’re here if there are any questions :blush:`;
};

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
};

module.exports = {
  checkPhoneNumber,
  findTimeFromMS,
  textToTranslatorAboutNewKeys,
  groupBy,
};

