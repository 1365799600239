import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Button, Grid } from '@material-ui/core';
import { actions } from '~/app/modules/ClicksReport/actions';
import ClicksReportTable from "./ClicksReport.report";
import {
  usePaginationFilteringAndSorting,
} from '~/app/helpers';
import { KeyboardDatePicker } from "@material-ui/pickers";
import {Spinner} from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import enums from "~/app/helpers/enums";

const DateSelectors = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  switchName,
  onChangeSwitch,
  switchValue
}) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item md={2}>
        <span className="switch switch-sm switch-icon-success">
          <label>
            <input
              type="checkbox"
              name={switchName}
              checked={switchValue}
              onChange={onChangeSwitch}
            />
            <span />
          </label>
        </span>
      </Grid>
      <Grid item md={5}>
        <KeyboardDatePicker
          autoOk
          id="start-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          disabled={!switchValue}
          margin="none"
          label="Start Date"
          value={startDate}
          onChange={(_, v) => onChangeStartDate(v)}
        />
      </Grid>
      <Grid item md={5}>
        <KeyboardDatePicker
          autoOk
          id="end-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          disabled={!switchValue}
          margin="none"
          label="End Date"
          value={endDate}
          onChange={(_, v) => onChangeEndDate(v)}
        />
      </Grid>
    </Grid>
  )
};

const ClicksReport = () => {
  const dispatch = useDispatch();
  const clickStartDate = useSelector(state => state.clicksReport.clickStartDate);
  const clickEndDate = useSelector(state => state.clicksReport.clickEndDate);
  const leadStartDate = useSelector(state => state.clicksReport.leadStartDate);
  const leadEndDate = useSelector(state => state.clicksReport.leadEndDate);
  const {
    sortBy, sortDirection, pageNumber, pageSize, totalCount, filters,
  } = usePaginationFilteringAndSorting(dispatch, 'clicksReport', actions);
  const useClickDate = useSelector((state) => state.clicksReport.useClickDate);
  const useLeadDate = useSelector((state) => state.clicksReport.useLeadDate);
  const isLoadingCSV = useSelector((state) => state.clicksReport.isLoadingCSV);
  const isLoadingSharedView = useSelector((state) => state.clicksReport.isLoadingSharedView);
  const sharedLink = useSelector((state) => state.clicksReport.sharedLink);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    if (sharedLink) {
      navigator.clipboard.writeText(sharedLink).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
      dispatch(actions.clearSharedLink());
    }
  }, [sharedLink, dispatch]);

  useEffect(() => {
    if (params.clickId) {
      dispatch(actions.setParams({ filters: { clickId: params.clickId }, useClickDate: false }));
      history.push('/clicks');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSharedView = () => {
    const sentParams = {
      sortBy,
      sortDirection,
      pageNumber,
      pageSize,
      filters,
      totalCount,
      useClickDate,
      useLeadDate,
      clickStartDate,
      clickEndDate,
      leadStartDate,
      leadEndDate,
      page: 'clicks',
    };

    dispatch(actions.createSharedLink(sentParams));
  }

  const handleChangeUseClickDate = () => {
    dispatch(actions.setUseClickDate(!useClickDate));
  }

  const handleChangeUseLeadDate = () => {
    dispatch(actions.setUseLeadDate(!useLeadDate));
  }

  const onChangeDate = (key) => (v) => {
    switch (key) {
      case 'clickStart':
        dispatch(actions.setClickStartDate(v));
        break;
      case 'clickEnd':
        dispatch(actions.setClickEndDate(v));
        break;
      case 'leadStart':
        dispatch(actions.setLeadStartDate(v));
        break;
      case 'leadEnd':
        dispatch(actions.setLeadEndDate(v));
        break;

      default:
        break;
    }
  }

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="d-flex justify-content-between align-items-start mb-3">
          <h3>Clicks</h3>
          <div className="d-flex align-items-start">
            <Button
              color="default"
              variant="contained"
              onClick={handleSharedView}
              className="bg-info mr-5 text-white"
            >
              {isLoadingSharedView
                ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )
                : 'Share View'}
            </Button>
            <div className="d-flex flex-column justify-content-end">
              <Button
                color="secondary"
                variant="contained"
                // TODO
                onClick={() => dispatch(actions.generateReportAsCsv())}
              >
                {isLoadingCSV
                  ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )
                  : 'Export To CSV'}
              </Button>
              <div>Count of Clicks: {totalCount}</div>
            </div>
          </div>
        </div>
        <div className="d-flex mb-10 align-items-end">
          <div className="d-flex flex-column">
            <div>Click Date</div>
            <DateSelectors
              switchName="click-date"
              onChangeSwitch={handleChangeUseClickDate}
              switchValue={useClickDate}
              startDate={clickStartDate}
              endDate={clickEndDate}
              onChangeStartDate={onChangeDate('clickStart')}
              onChangeEndDate={onChangeDate('clickEnd')}
            />
            <div>Lead Date</div>
            <DateSelectors
              switchName="lead-date"
              switchValue={useLeadDate}
              onChangeSwitch={handleChangeUseLeadDate}
              startDate={leadStartDate}
              endDate={leadEndDate}
              onChangeStartDate={onChangeDate('leadStart')}
              onChangeEndDate={onChangeDate('leadEnd')}
            />
          </div>
          <Button
            color="primary"
            variant="contained"
            className="ml-5 max-h-30px"
            onClick={() => dispatch(actions.generateReport())}
          >
            Generate
          </Button>
        </div>
        <ClicksReportTable />
      </CardContent>
    </Card>
  )
};

export default ClicksReport;
