import { call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/Translations/components/Products/api';
import { actions, actionTypes } from '~/app/modules/Translations/components/Products/actions';

function* getProducts() {
  try {
    const results = yield call(api.getProducts);
    yield put(actions.getProductsSuccess(results));
  } catch (error) {
    yield put(actions.getProductsFailure(error.message));
  }
}

function* createProduct(action) {
  try {
    const results = yield call(api.createProduct, action.payload);
    yield put(actions.createProductSuccess(results));
    yield put(actions.getProducts());
  } catch (error) {
    yield put(actions.createProductFailure(error.message));
  }
}

function* updateProduct(action) {
  try {
    const results = yield call(api.updateProduct, action.payload);
    yield put(actions.updateProductSuccess(results));
    yield put(actions.getProducts());
  } catch (error) {
    yield put(actions.updateProductFailure(error.message));
  }
}

function* getProductTranslation(action) {
  try {
    const results = yield call(api.getProductTranslation, action.payload);
    yield put(actions.getProductTranslationSuccess(results));
  } catch (error) {
    yield put(actions.getProductTranslationFailure(error.message));
  }
}

function* createProductTranslation(action) {
  try {
    const results = yield call(api.createProductTranslation, action.payload);
    yield put(actions.createProductTranslationSuccess(results));
  } catch (error) {
    yield put(actions.createProductTranslationFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.GetProducts, getProducts),
  takeLatest(actionTypes.CreateProduct, createProduct),
  takeLatest(actionTypes.UpdateProduct, updateProduct),
  takeLatest(actionTypes.GetProductTranslation, getProductTranslation),
  takeLatest(actionTypes.CreateProductTranslation, createProductTranslation),
];