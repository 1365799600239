import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('mappedQuestion');

actionTypes.AddNewMapping = 'mappedQuestion/add-new-mapping';
actionTypes.SetSelectedQuestion = 'mappedQuestion/set-selected-question';

actions.addNewMapping = (data) => ({ type: actionTypes.AddNewMapping, payload: data });
actions.setSelectedQuestion = (data) => ({ type: actionTypes.SetSelectedQuestion, payload: data });

export {
  actionTypes,
  actions,
};
