import React, {useEffect} from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Card, CardContent, Box } from '@material-ui/core';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { customFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Pagination } from '~/_metronic/_partials/controls';
import { actions } from '~/app/modules/APILog/actions';
import { sortCaret } from '~/_metronic/_helpers';
import {
  onTableChangeHelper,
  usePaginationFilteringAndSorting,
  beautifyColumnHeaders,
  textFilterOnBlurHelper,
  JsonCell,
  useDidMount,
} from '~/app/helpers';
import { Link } from "react-router-dom";
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const sortableColumn = {
  sort: true, sortCaret,
};

const collapsableColumn = {
  collapsable: true,
}

const platformOptions = [
  { value: 'mobile', label: 'Mobile' },
  { value: 'mobileWeb', label: 'Mobile Web' },
  { value: 'desktop', label: 'Desktop' },
];

const mobilePlatformOptions = [
  { value: 'android', label: 'Android' },
  { value: 'ios', label: 'iOS' },
];

const UserIdLinkCell = ({ userId }) => (
  <div>
    <Link
      target="_blank"
      to={`/users/${userId}`}
    > {userId} </Link>
  </div>
);

const columns = (filters, columnsCollapsed) => {
  const columns = [{
    ...sortableColumn,
    dataField: 'createdAt', text: 'Date',
    type: 'date',
    style: { width: '210px', minWidth: '210px', textAlign: 'left' },
  }, {
    ...collapsableColumn,
    dataField: 'requestIP', text: 'IP',
    filter: customFilter(),
    filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
      onFilter, column, {style: { width: "170px" }, defaultValue: filters['requestIP'] || ''}
      ),
    style: { width: '180px', textAlign: 'right' },
  }, {
    dataField: 'userId', text: 'User ID',
    formatter: (_cell, row) => <UserIdLinkCell userId={row.userId} />,
    filter: customFilter(),
    filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
      onFilter, column, {style: { width: "210px" }, defaultValue: filters['userId'] || ''}
      ),
    style: { width: '230px', textAlign: 'right' },
  }, {
    ...collapsableColumn,
    ...sortableColumn,
    dataField: 'country', text: 'Country',
    filter: customFilter(),
    filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
      onFilter, column, {style: { width: "230px" }, defaultValue: filters['country'] || ''}
      ),
    style: { width: '80px', minWidth: '110px', textAlign: 'right' },
  }, {
    ...collapsableColumn,
    ...sortableColumn,
    dataField: 'platform', text: 'Platform',
    filter: selectFilter({
      defaultValue: filters['platform'],
      style: { width: '80px' },
      placeholder: ' ',
      options: platformOptions,
    }),
    style: { width: '90px', minWidth: '90px', textAlign: 'right' },
  }, {
    ...collapsableColumn,
    ...sortableColumn,
    dataField: 'mobileOsName', text: 'Mobile OS',
    filter: selectFilter({
      defaultValue: filters['mobileOsName'],
      style: { width: '80px' },
      placeholder: ' ',
      options: mobilePlatformOptions,
    }),
    style: { width: '90px', minWidth: '90px', textAlign: 'right' },
  }, {
    ...collapsableColumn,
    ...sortableColumn,
    dataField: 'mobileOsVersion',
    text: 'Mobile OS Version',
    filter: customFilter(),
    filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
      onFilter, column, {style: { width: "80px" }, defaultValue: filters['mobileOsVersion'] || ''}
      ),
    style: { width: '90px', minWidth: '90px', textAlign: 'right' },
  }, {
    ...collapsableColumn,
    ...sortableColumn,
    dataField: 'appVersion', text: 'App Version',
    filter: customFilter(),
    filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
      onFilter, column, {style: { width: "50px" }, defaultValue: filters['appVersion'] || ''}
      ),
    style: { width: '80px', minWidth: '110px', textAlign: 'right' },
  }, {
    ...collapsableColumn,
    ...sortableColumn,
    dataField: 'apiVersion', text: 'API Version',
    filter: customFilter(),
    filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
      onFilter, column, {style: { width: "50px" }, defaultValue: filters['apiVersion'] || ''}
      ),
    style: { width: '80px', minWidth: '110px', textAlign: 'right' },
  }, {
    ...sortableColumn,
    dataField: 'requestMethod', text: 'HTTP Method',
    filter: customFilter(),
    filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
      onFilter, column, {style: { width: "50px" }, defaultValue: filters['requestMethod'] || ''}
      ),
    style: { width: '80px', minWidth: '110px', textAlign: 'right' },
  }, {
    ...sortableColumn,
    dataField: 'requestPath', text: 'Request Path',
    filter: customFilter(),
    filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
      onFilter, column, {style: { width: "100px" }, defaultValue: filters['requestPath'] || ''}
      ),
    formatter: (_cell, row) => <JsonCell data={row.requestPath} title="Request Path" length={20} />,
    style: { width: '110px',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
      minWidth: '110px', maxWidth: '170px', textAlign: 'right' },
  }, {
    ...sortableColumn,
    dataField: 'requestParams', text: 'Request Params',
    filter: customFilter(),
    filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
      onFilter, column, {style: { width: "120px" }, defaultValue: filters['requestParams'] || ''}
      ),
    formatter: (_cell, row) => <JsonCell data={row.requestParams} title="Request Params" />,
    style: { width: '150px' },
  }, {
    ...sortableColumn,
    dataField: 'responseStatus', text: 'Response Status',
    filter: customFilter(),
    filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
      onFilter, column, {style: { width: "50px" }, defaultValue: filters['responseStatus'] || ''}
      ),
    style: { width: '110px', minWidth: '110px', textAlign: 'right' },
  }, {
    dataField: 'verification', text: 'Response',
    formatter: (_cell, row) => <JsonCell data={row.responseBody} title="Response Body" />,
    style: { width: '150px' },
  },{
    dataField: 'failReason', text: 'Empty List reason',
    formatter: (_cell, row) => <JsonCell data={row.failReason} title="Empty List reason" />,
    style: { width: '150px' },
  }];

  if (columnsCollapsed) {
    return columns.filter(column => !column.collapsable);
  }
  return columns;
};

const APILogReport = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'apiLog', actions);
  const report = useSelector(state => state.apiLog.report, shallowEqual);
  const columnsCollapsed = useSelector((state) => state.apiLog.collapsed);

  useEffect(() => {
    const newData = localStorage.getItem('api-log');
    if (newData) {
      dispatch(actions.setParams(JSON.parse(newData)));
      localStorage.removeItem('api-log');
    }
  }, [dispatch]);

  const didMount = useDidMount();

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: [
      'userId', 'requestIP', 'platform',
      'mobileOsName', 'mobileOsVersion', 'country', 'appVersion',
      'apiVersion', 'requestMethod', 'requestPath',
      'requestParams', 'responseStatus',
    ],
    numberFilterFields: [],
    didMount,
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = beautifyColumnHeaders(columns(filters, columnsCollapsed));

  return (
    <Box mt={2}>
      <Card>
        <CardContent>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
              {({ paginationProps, paginationTableProps }) => (
                <Pagination isLoading={false} paginationProps={paginationProps}>
                  <BootstrapTable
                    remote
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden tableheighthack"
                    headerClasses="table table-vertical-top"
                    bootstrap4
                    keyField="_id"
                    columns={reportColumns}
                    data={report}
                    filter={ filterFactory() }
                    defaultSorted={[{
                      dataField: sortBy || 'createdAt',
                      order: sortDirection || enums.sortDirections.desc,
                    }]}
                    onTableChange={onTableChange}
                    {...paginationTableProps}
                  >
                  </BootstrapTable>
                </Pagination>
              )}
            </PaginationProvider>
        </CardContent>
      </Card>
    </Box>
  );
};

export default APILogReport;

