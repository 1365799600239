import React, { useEffect, useState, useRef } from 'react';
import { Modal } from "react-bootstrap";
import {Cancel, CloseOutlined, PhotoAlbum} from "@material-ui/icons";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Chip, FormControl, MenuItem, Select as MUISelect } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Input, Select } from "~/_metronic/_partials/controls";
import cs from "classnames";
import ImageEditor from "@toast-ui/react-image-editor";
import 'tui-image-editor/dist/tui-image-editor.css';
import {useDispatch, useSelector} from "react-redux";
import { useParams } from 'react-router-dom';
import { newDateFormatLong } from '~/app/helpers';
import { Star } from '@material-ui/icons';
import { KeyboardDatePicker } from "@material-ui/pickers";
import enums from "~/app/helpers/enums";

const useStyles = makeStyles((theme) => ({
  chipListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const selectMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

const handleCloseModal = (onClose, formik, initialValues) => () => {
  onClose();
  formik.resetForm(initialValues);
};

const LanguageModalBody = ({ initialValues, onClose, shown, actions, feature }) => {
  const languageSchema = Yup.object().shape({
    languageId: Yup.string()
      .required(),
  });

  const params = useParams();

  const languages = useSelector((state) => state.feature.languages);
  const translators = useSelector((state) => state.feature.translators);
  const dispatch = useDispatch();

  const onSubmit = ({ languageId, translatorId, _id }) => {
    if (_id) {
      dispatch(actions.updateFeatureLanguage({
        languageId,
        translatorId,
        featureLanguageId: _id,
        featureId: params?.featureId,
      }));
    } else {
      dispatch(actions.createFeatureLanguage({ languageId, translatorId, featureId: params?.featureId }));
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: languageSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        onSubmit(values);
        onClose()
        formik.resetForm(initialValues);
      } catch (error) {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (shown) {
      formik.resetForm();
    }
  }, [shown]); // eslint-disable-line react-hooks/exhaustive-deps

  const featureLanguageIds = feature?.languages?.filter((languageId) => initialValues?.languageId !== languageId);
  const filterLanguages = languages?.filter((languageId) => !featureLanguageIds?.includes(String(languageId?._id)));

  const filterTranslator = formik?.values?.languageId
    ? translators.filter((translator) => translator?.languages?.includes(formik?.values?.languageId))
    : translators;

  return (
    <form
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      className="form d-flex my-2 mx-2 flex-column m-0"
    >
      <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
        <div className="w-150px min-w-150px mr-5">Language</div>
        <Select
          placeholder="Select Language"
          name="lanuageId"
          className="bg-white border-secondary"
          variant="outlined"
          withFeedbackLabel={false}
          form={formik}
          field={formik?.getFieldProps('languageId')}
          {...formik?.getFieldProps("languageId")}
        >
          <option value="" />
          {filterLanguages.map((language, index) => (
            <option key={index} value={language._id}>{language.name}</option>
          ))}
        </Select>
      </div>
      <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
        <div className="w-150px min-w-150px mr-5">Translator</div>
        <Select
          placeholder="Select Translator"
          name="translatorId"
          className="bg-white border-secondary"
          variant="outlined"
          withFeedbackLabel={false}
          form={formik}
          field={formik?.getFieldProps('translatorId')}
          {...formik?.getFieldProps("translatorId")}
        >
          <option value="" />
          {filterTranslator.map((translator, index) => (
            <option key={index} value={translator._id}>{translator.firstName} {translator.lastName}</option>
          ))}
        </Select>
      </div>
      <div className="d-flex justify-content-end w-100 border-top pt-3">
        {initialValues?.language ? (
          <>
            <Button
              color="default"
              variant="contained"
              className="mr-3 bg-light-primary text-primary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className="bg-primary text-white mr-5"
            >
              Save
            </Button>
          </>
        ) : (
          <Button
            color="primary"
            variant="contained"
            type="submit"
            className="bg-primary text-white mr-5"
          >
            Add
          </Button>
        )}
      </div>
    </form>
  );
}

const SendToTranslationModalBody = ({ initialValues, onClose }) => {
  const [languages, setLanguages] = useState( []);
  const handleSendAll = () => {};
  const renderLanguageOptions = (featureLanguages) => {
    if (featureLanguages?.length) {
     return featureLanguages.map((fl) => (
       <MenuItem key={fl.languageId} value={fl.languageId}>{fl.language}</MenuItem>
      ));
    }

    return null;
  }

  const classes = useStyles();

  const handleChangeLanguages = (event) => {
    const key = event.target.value;
    if(typeof key === 'string') {
      const newLanguages = languages
        .filter((language) => language !== key);
      setLanguages(newLanguages);
    } else {
      if (!key?.length) {
        setLanguages([]);
      } else {
        setLanguages(key);
      }
    }
  }

  return (
    <div className="pl-3 pt-2">
      <div className="d-flex justify-content-between">
        <div>Send ALL unverified translations</div>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSendAll}
          className="bg-primary text-white mr-10"
        >
          Send
        </Button>
      </div>
      <div className="d-flex flex-column w-100 mb-5">
        <div>Send unverified translations in languages</div>
        <div className="d-flex justify-content-between align-items-center">
          <FormControl margin="normal" className="my-0">
            <MUISelect
              id="languages"
              multiple
              className="bg-white border-secondary"
              classes={{ root: 'p-0 min-h-40px' }}
              variant="outlined"
              MenuProps={selectMenuProps}
              value={languages}
              onChange={handleChangeLanguages}
              renderValue={(values, index) => (
                <div key={index} className={classes.chipListContainer}>
                  {values.map((value) => {
                    const foundLanguage = initialValues.find((fl) => fl.languageId === value);
                    return (
                      <Chip
                        key={value}
                        label={foundLanguage.language}
                        size="medium"
                        className={`${classes.chip} bg-primary text-white`}
                        onDelete={() => handleChangeLanguages(value)}
                        deleteIcon={
                          <Cancel onMouseDown={event => event.stopPropagation()}/>
                        }
                      />
                    )
                  })}
                </div>
              )}
            >
              {renderLanguageOptions(initialValues)}
            </MUISelect>
          </FormControl>
          <Button
            color="primary"
            variant="contained"
            onClick={onClose}
            className="bg-primary text-white ml-5 mr-10"
          >
            Send
          </Button>
        </div>
      </div>
      <div className="d-flex justify-content-end w-100 border-top pt-3">
        <Button
          color="default"
          variant="contained"
          className="mr-3 bg-light-primary text-primary"
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

const KeyModalBody = ({ initialValues, onClose, shown, actions }) => {
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState('');
  const foundKeys = useSelector((state) => state.feature.foundKeys);
  const languageSchema = Yup.object().shape({
    key: Yup.string(),
    text: Yup.string(),
  });
  const params = useParams();

  const handleOnKeyClick = (id) => () => {
    if (selectedKey === id) {
      setSelectedKey('');
    } else {
      setSelectedKey(id);
    }
  };

  const onSubmit = (formik, { key, text }) => {
    dispatch(actions.findKeys({ key, text, featureId: params?.featureId }));
  };

  const handleAddKey = () => {
    if (selectedKey) {
      dispatch(actions.createKeyTranslation({
        keyId: selectedKey, featureId: initialValues?.featureId || params?.featureId
      }));
      dispatch(actions.cleanFoundKeys());
      onClose();
      formik.resetForm(initialValues);
      setSelectedKey('');
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: languageSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        onSubmit(formik, values);
      } catch (error) {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (shown) {
      formik.resetForm();
    }
  }, [shown]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScreenshotClick = (screenshot) => (e) => {
    e.stopPropagation();
  }

  return (
    <form
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      className="form d-flex my-2 mx-2 flex-column m-0"
    >
      <div className="d-flex justify-content-end pr-30">
        <div className="w-100px mr-3 text-uppercase text-muted">Screenshot</div>
        <div className="w-120px mr-5 text-uppercase text-muted">Create Date</div>
        <div className="w-100px mr-3 text-uppercase text-muted">Translated Languages</div>
      </div>
      <div className="d-flex align-items-center w-100 mb-5 px-5 justify-content-between">
        <div className="d-flex align-items-center">
          <Input
            withFeedbackLabel={false}
            className="w-200px"
            type="text"
            name="key"
            placeholder="Enter key name"
            form={formik}
            field={formik?.getFieldProps('key')}
            {...formik?.getFieldProps("key")}
          />
          <Input
            withFeedbackLabel={false}
            className="ml-5 w-350px"
            type="text"
            name="text"
            placeholder="Enter key text"
            form={formik}
            field={formik?.getFieldProps('text')}
            {...formik?.getFieldProps("text")}
          />
        </div>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          className="bg-success text-white"
        >
          Search
        </Button>
      </div>
      <div className="mb-3 border-bottom border-bottom-light-secondary" />
      <div className="min-h-40px">
        {foundKeys?.length ? foundKeys.map((item) => (
          <div
            key={item._id}
            className={cs("d-flex cursor-pointer p-3 align-items-center", {
              'bg-light-primary': selectedKey === item._id
            })}
            onClick={handleOnKeyClick(item._id)}
          >
            <div className="w-200px ml-2 text-break">{item.key}</div>
            <div className="w-350px ml-5 text-break">{item.text}</div>
            <div className="w-100px ml-6 mr-4 d-flex justify-content-center">
              <PhotoAlbum
                onClick={handleScreenshotClick(item?.screenshot)}
                className={item?.screenshot ? "text-primary" : 'text-muted'}
              />
            </div>
            <div className="w-120px mr-2">{newDateFormatLong(item?.createdAt, '(PST)')}</div>
            <div className="w-100px mr-2 d-flex justify-content-center">{item?.languagesCount || 0}</div>
          </div>
        ) ) : ''}
      </div>
      <div className="d-flex justify-content-end w-100 border-top pt-3 mt-3">
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddKey}
          disabled={!selectedKey}
          className={cs('text-white mr-5', { 'bg-primary': selectedKey, 'bg-secondary': !selectedKey })}
        >
          ADD
        </Button>
      </div>
    </form>
  );
};

const initialValuesForLinkedKeyModal = {
  key: '',
  text: '',
}

const LinkedKeyModalBody = ({ values, onClose, shown, actions }) => {
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState(values?.masterKey?._id || '');
  const foundKeys = useSelector((state) => state.keys.foundKeys);
  const languageSchema = Yup.object().shape({
    key: Yup.string(),
    text: Yup.string(),
  });

  const handleOnKeyClick = (id) => () => {
    if (selectedKey === id) {
      setSelectedKey('');
    } else {
      setSelectedKey(id);
    }
  };

  const onSubmit = (formik, { key, text }) => {
    if (key || text) dispatch(actions.findKeys({ key, text, keysForLinkKey: true, keyId: values?._id }));
  };

  const handleLinkKey = () => {
    if (selectedKey) {
      dispatch(actions.updateLinkKey({ keyId: values?._id, aliasOfKeyId: selectedKey }));
      dispatch(actions.cleanFoundKeys());
      onClose();
      formik.resetForm(initialValuesForLinkedKeyModal);
      setSelectedKey('');
    }
  }

  const formik = useFormik({
    initialValues: initialValuesForLinkedKeyModal,
    validationSchema: languageSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        onSubmit(formik, values);
      } catch (error) {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (shown) {
      formik.resetForm();
    }
  }, [shown]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScreenshotClick = (screenshot) => (e) => {
    e.stopPropagation();
  }

  const renderKey = (item) => (
    <div
      key={item._id}
      className={cs("d-flex cursor-pointer p-3 align-items-center", {
        'bg-light-primary': selectedKey === item._id
      })}
      onClick={handleOnKeyClick(item._id)}
    >
      <div className="min-w-30px w-30px">
        {item?.masterKeyAliases?.length ? <Star style={{ color: '#ffa701' }} /> : ''}
      </div>
      <div className="w-150px ml-2 text-break">{item.key}</div>
      <div className="w-250px ml-5 text-break">{item.text}</div>
      <div className="w-150px ml-12 text-break min-w-150px">{item.featureName}</div>
      <div className="w-100px mr-4 d-flex justify-content-center">
        <PhotoAlbum
          onClick={handleScreenshotClick(item?.screenshot)}
          className={item?.screenshot ? "text-primary" : 'text-muted'}
        />
      </div>
      <div className="w-120px mr-2">{newDateFormatLong(item?.createdAt, '(PST)')}</div>
      <div className="w-100px mr-2 d-flex justify-content-center">{item?.masterKeyAliases?.length || 0}</div>
    </div>
  );

  return (
    <form
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      className="form d-flex my-2 mx-2 flex-column m-0"
    >
      <div className="border-bottom border-bottom-light-secondary pr-5 pl-10 pt-2 pb-4 mb-3 d-flex">
        <div className="mr-3 font-weight-bold">Key Text:</div>
        <div>{values?.text}</div>
      </div>
      <div className="d-flex justify-content-end pr-22">
        <div className="w-150px mr-3 text-uppercase text-muted">Feature</div>
        <div className="w-100px mr-3 text-uppercase text-muted">Screenshot</div>
        <div className="w-120px mr-5 text-uppercase text-muted">Create Date</div>
        <div className="w-100px mr-3 text-uppercase text-muted">Linked Keys</div>
      </div>
      <div className="d-flex align-items-center w-100 mb-5 pr-5 justify-content-between pl-13">
        <div className="d-flex align-items-center">
          <Input
            withFeedbackLabel={false}
            className="w-150px"
            type="text"
            name="key"
            placeholder="Enter key name"
            form={formik}
            field={formik?.getFieldProps('key')}
            {...formik?.getFieldProps("key")}
          />
          <Input
            withFeedbackLabel={false}
            className="ml-5 w-250px"
            type="text"
            name="text"
            placeholder="Enter key text"
            form={formik}
            field={formik?.getFieldProps('text')}
            {...formik?.getFieldProps("text")}
          />
        </div>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          className="bg-success text-white"
        >
          Search
        </Button>
      </div>
      <div className="mb-3 border-bottom border-bottom-light-secondary" />
      <div className="min-h-40px">
        {!formik.values?.key && !formik.values?.text && values?.masterKey && renderKey(values?.masterKey)}
        {foundKeys?.length ? foundKeys.map(renderKey) : ''}
      </div>
      <div className="d-flex justify-content-end w-100 border-top pt-3 mt-3">
        <Button
          color="primary"
          variant="contained"
          onClick={handleLinkKey}
          disabled={!selectedKey}
          className={cs('text-white mr-5', { 'bg-primary': selectedKey, 'bg-secondary': !selectedKey })}
        >
          Link
        </Button>
      </div>
    </form>
  );
};


const ScreenshotModalBody = ({ initialValues, actions, onClose, user }) => {
  const [editMode, setEditMode] = useState(false);
  const imageEditorEl = useRef(null);
  const dispatch = useDispatch();
  const params = useParams();
  const { screenshot } = initialValues;

  const handleSaveImage = () => {
    const imageBase64 = imageEditorEl?.current?.imageEditorInst?.toDataURL();
    const fileName = `${(Math.random() + 1).toString(36).substring(2)}.png`;
    dispatch(actions.saveKeyScreenshot({
      keyId: initialValues?.keyId || initialValues?._id, fileName, attachment: imageBase64, featureId: params?.featureId,
    }));
    onClose();
  };

  const includeUI = {
    initMenu: 'shape',
    shape: {
      options: {
        strokeWidth: 8,
        stroke: 'red',
      },
    },
    uiSize: {
      width: "100%",
      height: "600px",
    },
    menuBarPosition: "left",
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      {screenshot && (
        <img
          id="selectedUrl"
          alt="screenshot"
          className={cs('max-w-50 max-h-50', {'position-absolute z-index-0 opacity-5': editMode})}
          src={screenshot}
        />
      )}
      {editMode ? (
        <>
          <ImageEditor
            ref={imageEditorEl}
            includeUI={includeUI}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 70,
              lineWidth: 8,
              borderColor: '#ff0000',
            }}
          />
          <div className="w-100 mt-5 d-flex justify-content-center align-items-center">
            <Button
              color="primary"
              variant="contained"
              onClick={handleSaveImage}
              className="bg-primary text-white w-200px"
            >
              Save
            </Button>
          </div>
        </>
      ) : !user?.role && !initialValues?.aliasOfKeyId && (
        <div className="w-100 mt-5 d-flex justify-content-center align-items-center">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {setEditMode(true)}}
            className="bg-primary text-white w-200px"
          >
            Edit
          </Button>
        </div>
      )}
    </div>
  )
};

const ConfirmModalBody = ({ text, onClose, values, actions }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const handleRemove = () => {
    if (values?.keyTranslations && params?.featureId) {
      const keyTranslationIds = values.keyTranslations.map((keyTranslation) => keyTranslation._id);
      dispatch(actions.removeKeyTranslations({ keyTranslationIds, featureId: params?.featureId }));
      onClose();
    }
  }
  return (
    <div>
      <div className="text-danger text-center px-4 py-4">{text}</div>
      <div className="d-flex justify-content-end w-100 border-top pt-3">
        <Button
          color="default"
          variant="contained"
          className="mr-3 bg-light-primary text-primary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleRemove}
          className="bg-primary text-white mr-5"
        >
          Yes
        </Button>
      </div>
    </div>
  )
}

const EmptyTranslationModalBody = ({ onClose }) => {
  const onOkButtonClick = () => {
    onClose();
  }
  return (
    <div>
      <div className="text-danger text-center px-4 py-4">
        You cannot confirm a translation without text
      </div>
      <div className="d-flex justify-content-end w-100 border-top pt-3">
        <Button
          color="primary"
          variant="contained"
          onClick={onOkButtonClick}
          className="bg-primary text-white mr-5"
        >
          OK
        </Button>
      </div>
    </div>
  )
}

const DueDateBody = ({ initialValues, onClose, actions }) => {
  const [dueDate, setDueDate] = useState(initialValues.dueDate || undefined);
  const dispatch = useDispatch();

  const onOkButtonClick = () => {
    if (dueDate) {
      dispatch(actions.updateFeature({
        featureId: initialValues._id,
        productId: initialValues.productId,
        name: initialValues.name,
        dueDate,
      }))
    }
    onClose();
  }
  const handleDueDateChange = (value) => {
    setDueDate(value);
  };

  return (
    <div className="p-4">
      <div className="d-flex align-items-center justify-content-center w-100 min-h-100px">
        <KeyboardDatePicker
          autoOk
          className="mr-2"
          id="start-date"
          disableToolbar
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="none"
          label=""
          value={dueDate}
          onChange={(_, v) => {handleDueDateChange(v)}}
        />
      </div>
      <div className="d-flex justify-content-end w-100 border-top pt-3">
        <Button
          color="default"
          variant="contained"
          className="mr-3 bg-light-primary text-primary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onOkButtonClick}
          className="bg-primary text-white mr-5"
        >
          Save
        </Button>
      </div>
    </div>
  )
}

const FeatureAndKeysModal = ({ type, values, shown, setShown, onSave, text, actions, user, feature }) => {
  const dispatch = useDispatch();
  let modalHeader = '';
  let modalBody = <div />;
  const handleClose = () => {
    setShown(false);
    if (actions.cleanFoundKeys) {
      dispatch(actions.cleanFoundKeys());
    }
  };

  switch (type) {
    case 'addLanguage':
      modalHeader = 'Add Language';
      modalBody = (
        <LanguageModalBody
          shown={shown}
          initialValues={{ language: '', translator: '' }}
          onClose={handleClose}
          onSave={onSave}
          actions={actions}
          feature={feature}
        />);
      break;
    case 'editLanguage':
      modalHeader = 'Edit Language';
      modalBody = (
        <LanguageModalBody
          feature={feature}
          shown={shown}
          initialValues={values}
          onClose={handleClose}
          onSave={onSave}
          actions={actions}
        />);
      break;
    case 'sendToTranslation':
      modalHeader = 'Sent To Translation';
      modalBody = (<SendToTranslationModalBody onClose={handleClose} initialValues={values} />);
      break;
    case 'addKey':
      modalHeader = 'Add Key';
      modalBody = (
        <KeyModalBody
          initialValues={{
            key: '',
            text: ''
          }}
          onClose={handleClose}
          shown={shown}
          actions={actions}
        />);
      break;
    case 'addExitingKey':
      modalHeader = 'Add Exiting Key';
      modalBody = (
        <KeyModalBody
          initialValues={values}
          onClose={handleClose}
          shown={shown}
          actions={actions}
        />);
      break;
    case 'linkedKey':
      modalHeader = 'Linked Key';
      modalBody = (
        <LinkedKeyModalBody
          values={values}
          onClose={handleClose}
          shown={shown}
          actions={actions}
        />);
      break;
    case 'screenshot':
      modalHeader = `Screenshot Mark "${values.text}"`;
      modalBody = (<ScreenshotModalBody user={user} initialValues={values} actions={actions} onClose={handleClose} />);
      break;
    case 'confirmRemoving':
      modalHeader = 'Are you sure?';
      modalBody = (<ConfirmModalBody onClose={handleClose} text={text} actions={actions} values={values} />);
      break;

    case 'emptyTranslationConfirm':
      modalHeader = 'Cannot Confirm';
      modalBody = (<EmptyTranslationModalBody onClose={handleClose} />);
      break;

    case 'setDueDate':
      modalHeader = 'Set Due Date';
      modalBody = (<DueDateBody onClose={handleClose} initialValues={values} actions={actions} />)
      break;

    default:
      break;
  }

  const calculateSize = () => {
    if (type === 'confirmRemoving' || type === 'confirmEditing' || type === 'emptyTranslationConfirm' || type === 'setDueDate') {
      return 'md';
    }
    if (type === 'screenshot' || type === 'addKey' || type === 'linkedKey') {
      return 'xl';
    }

    return 'lg';
  }

  return (
    <Modal
      scrollable
      centered
      size={calculateSize()}
      show={shown}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title>
          {modalHeader}
        </Modal.Title>
        <div className="cursor-pointer pb-2 py-2" onClick={handleClose}><CloseOutlined /></div>
      </Modal.Header>
      <Modal.Body className="px-0 pt-0 pb-5">
        {modalBody}
      </Modal.Body>
    </Modal>
  );
};

export default FeatureAndKeysModal;
