import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "~/app/modules/User/actions";
import {
  Card, CardContent
} from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import { newDateFormatLong } from "~/app/helpers";

const columns = [
  {
    dataField: "createdAt",
    text: "Date and Time",
    formatter: (value) => newDateFormatLong(value),
  },
  { dataField: "_id", text: "Transaction ID" },
  {
    dataField: "transactionType",
    text: "Type",
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: (amount, row) => {
      if (row.transactionType === 'cashout') {
        return `-$${amount}`
      }

      return `$${amount}`
    }
  },
  {
    dataField: "balance",
    text: "Balance",
    formatter: (balance) => (`$${balance}`)
  }
];

const rowClasses = (row) => {
  if (row?.transactionType === 'cashout') {
    return 'bg-light-danger text-danger'
  }

  return 'bg-light-success text-success'
}

const TransactionsLogTable = ({ entities }) => {
  return (
    <BootstrapTable
      wrapperClasses="table-responsive max-h-400px mb-7"
      bordered={true}
      classes="table table-vertical-top overflow-hidden"
      bootstrap4
      size="sm"
      rowClasses={rowClasses}
      responsive="lg"
      keyField="_id"
      data={entities}
      columns={columns}
    >
    </BootstrapTable>
  );
}

const UserTransactionsLog = () => {
  const transactionsLog = useSelector(state => state.user.transactionsLog);

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          Transactions Log
        </h3>
        <TransactionsLogTable entities={transactionsLog} />
      </CardContent>
    </Card>
  )
}

const TransactionsLog = ({ userId }) => {
  const userInformation = useSelector(state => state.user.userInformation);
  const dispatch = useDispatch();
  const transactionsLog = useSelector(state => state.user.transactionsLog);

  useEffect(() => {
    dispatch(actions.getUserTransactionsLog(userId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if(!userInformation.user || !transactionsLog.length) {
    return null;
  }

  return (
    <UserTransactionsLog userId={userId} />
  )
};

export default TransactionsLog;
