import React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { selectFilter, customFilter } from 'react-bootstrap-table2-filter';
import { Pagination } from '~/_metronic/_partials/controls';
import { actions } from '~/app/modules/Users/actions';
import { sortCaret, toAbsoluteUrl } from '~/_metronic/_helpers';
import cs from 'classnames';
import {
  newDateFormatLong,
  onTableChangeHelper,
  usePaginationFilteringAndSorting,
  textFilterOnBlurHelper,
  textFilterOnBlurHelperWithAutoComplete,
  useDidMount,
  FraudScore,
} from '~/app/helpers';
import _ from 'lodash';
import config from '~/config';
import SVG from "react-inlinesvg";
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const sortableColumn = {
  sort: true, sortCaret,
};

const leadsAndFraudScoreOption = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'all', label: 'All' },
];

const hasMobileAppOptions = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
  { value: 'all', label: 'All' },
];

const statusOption = [
  { value: 'Banned', label: 'Banned' },
  { value: 'Not Banned', label: 'Not Banned' },
  { value: 'all', label: 'All' },
];

const platformOptions = ['all', ...Object.values(enums.userFamousPlatform), 'other'].map((option) => ({
  value: option, label: option,
}));


const onShadowLogin = (userId) => () => {
  window.open(`${config.apiBaseUrl}/users/${userId}/shadowLogin`);
};

const userIdLink = (_id) => (
  <div>
    <Link
      target="_blank"
      to={`/users/${_id}`}
    >
      {_id}
    </Link>
  </div>
);

const renderCountryField = (country) => (
  <div className="d-flex justify-content-between align-items-center">
    <div>{country?.countryISO}</div>
    {country?.countryName && (
      <SVG
        alt="Logo"
        className="min-w-20px max-w-20px ml-3"
        src={toAbsoluteUrl(`/media/svg/flags/${country?.countryName?.toLowerCase()}.svg`)}
      />
    )}
  </div>
)

const renderHasMobileApp = (value) => (
  <div className={cs('rounded px-2 py-2 d-flex justify-content-center align-items-center', {
    'bg-light-success text-success': value,
    'bg-light-danger text-danger': !value,
  })}>
    {_.capitalize(String(!!value))}
  </div>
);

const columns = (useCountryFieldSort) => [{
  ...sortableColumn,
  dataField: '_id', text: 'User ID',
  formatter: (_id) => userIdLink(_id),
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "230px" }}),
  style: { width: '250px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'email', text: 'Email Address',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "180px" }}),
  style: { width: '200px', textAlign: 'left' },
}, {
  ...sortableColumn,
  sort: useCountryFieldSort,
  dataField: 'country', text: 'Country',
  formatter: (country) => renderCountryField(country),
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelperWithAutoComplete(
    onFilter,
    column,
    {style: { width: "80px" }},
    actions,
    'users'
  ),
  style: { width: '100px', minWidth: '80px', textAlign: 'left' },
}, {
  ...sortableColumn,
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "120px" }}),
  dataField: 'registrationPublisherId', text: 'Publisher ID',
  style: { width: '150px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'createdAt', text: 'Registration Date & Time',
  formatter: (date) => newDateFormatLong(date),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  dataField: 'registrationPlatform', text: 'Registration Platform',
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '120px' },
    options: platformOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  dataField: 'hasMobileApp', text: 'Has Mobile App',
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: hasMobileAppOptions,
    withoutEmptyOption: true,
  }),
  formatter: (date) => renderHasMobileApp(date),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'hasLeads', text: 'Has Leads',
  style: { width: '120px', textAlign: 'left' },
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: leadsAndFraudScoreOption,
    withoutEmptyOption: true,
  }),
}, {
  ...sortableColumn,
  dataField: 'fraudScore', text: 'Fraud Score',
  style: { width: '150px', textAlign: 'left' },
  formatter: (value, row) => <FraudScore fraudScore={value} level={row.fraudScoreLevel} />,
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '120px' },
    options: leadsAndFraudScoreOption,
    withoutEmptyOption: true,
  }),
},{
  dataField: 'status', text: 'Status',
  style: { width: '120px', textAlign: 'left' },
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '120px' },
    options: statusOption,
    withoutEmptyOption: true,
  }),
},{
  dataField: 'userId', text: 'Action',
  style: { width: '150px', textAlign: 'center' },
  formatter: (userId) => (
    <button type="button" className="btn btn-primary btn-sm" onClick={onShadowLogin(userId)}>Shadow login</button>
  ),
}];

const UsersReport = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'users', actions);
  const report = useSelector(state => state.users.userList, shallowEqual);
  const useCountryFieldSort = useSelector((state) => state.users.useCountryFieldSort);

  const didMount = useDidMount();

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: [
      '_id', 'email', 'country', 'registrationPublisherId', 'hasLeads', 'fraudScore', 'status',
      'hasMobileApp', 'registrationPlatform'],
    didMount,
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = columns(useCountryFieldSort);

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => (
        <Pagination isLoading={false} paginationProps={paginationProps}>
          <BootstrapTable
            remote
            classes="table table-head-custom table-vertical-center overflow-hidden"
            headerClasses="table table-vertical-top"
            bootstrap4
            keyField="_id"
            columns={reportColumns}
            data={report}
            filter={ filterFactory() }
            defaultSorted={[{
              dataField: sortBy,
              order: enums.sortDirections.desc,
            }]}
            onTableChange={onTableChange}
            {...paginationTableProps}
          >
          </BootstrapTable>
        </Pagination>
      )}
    </PaginationProvider>
  );
};

export default UsersReport;
