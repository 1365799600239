import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { differenceInMonths, parseISO, format, addMonths, startOfMonth } from 'date-fns';
import { useHtmlClassService } from '~/_metronic/layout/_core/MetronicLayout.js';
import {
  Card, CardContent
} from "@material-ui/core";

function getChartOption(layoutProps, data, maxOrderCountInMonth, categories) {
  const options = {
    series: [
      {
        name: '',
        data,
      },
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseSuccess],
    },
    xaxis: {
      categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    yaxis: {
      min: 0,
      max: maxOrderCountInMonth,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function(val) {
          return `Orders: ${val}`;
        },
      },
    },
    colors: [layoutProps.colorsThemeLightSuccess],
    markers: {
      colors: [layoutProps.colorsThemeLightSuccess],
      strokeColor: [layoutProps.colorsThemeBaseSuccess],
      strokeWidth: 3,
    },
  };
  return options;
}

const MonthChart = ({ className, baseColor, data }) => {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${baseColor}`
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${baseColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, baseColor]);
  const firstOrderDate = startOfMonth(parseISO(data[data.length - 1].createdAt));

  const differenceInMonthsFromFirstToLastOrders =
    differenceInMonths(startOfMonth(parseISO(data[0].createdAt)), firstOrderDate);

  const gropedData = new Array(differenceInMonthsFromFirstToLastOrders + 1);
  gropedData.fill(0);

  const categories = gropedData.map((item, index) => {
    return format(addMonths(firstOrderDate, index), 'MMMM yyyy')
  });

  data.forEach((order) => {
    ++gropedData[differenceInMonths(parseISO(order.createdAt), firstOrderDate)]
  })

  const maxOrderCountInMonth = Math.max(...gropedData);

  useEffect(() => {
    const element = document.getElementById("kt_stats_order_month_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps, gropedData, maxOrderCountInMonth, categories);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, gropedData, maxOrderCountInMonth, categories]);

  return (
    <Card className={className}>
      <CardContent>
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          Orders per Month
        </h3>
        <div
          id="kt_stats_order_month_chart"
          className="card-rounded-bottom"
          style={{ height: "150px" }}
         />
      </CardContent>
    </Card>
  );
}

export default MonthChart;
