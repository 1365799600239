import { actionTypes } from '~/app/modules/Translations/components/Keys/actions';

const initialState = {
  report: [],
  products: [],
  languages: [],
  isLoading: false,
  getParamsIsLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetKeysSuccess:
      return {
        ...state,
        report: action.payload,
        isLoading: false,
      };

    case actionTypes.GetKeys:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GetKeysFailure:
    case actionTypes.CreateFeatureFailure:
    case actionTypes.CreateFeatureSuccess:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GetAvailableParamsFailure:
      return {
        ...state,
        getParamsIsLoading: false,
      };

    case actionTypes.CreateFeature:
      return {
        ...state,
        isLoading: true,
      }

    case actionTypes.GetAvailableParams:
      return {
        ...state,
        getParamsIsLoading: true,
        languages: [],
        products: [],
      };

    case actionTypes.GetAvailableParamsSuccess:
      return {
        ...state,
        getParamsIsLoading: false,
        languages: action.payload?.languages,
        products: action.payload?.products,
      };

    case actionTypes.FindKeys:
      return {
        ...state,
        isLoading: true,
        foundKeys: [],
      };

    case actionTypes.FindKeysSuccess:
      return {
        ...state,
        isLoading: false,
        foundKeys: action.payload,
      };

    case actionTypes.CleanFoundKeys:
      return {
        ...state,
        foundKeys: [],
      };

    default:
      return state;
  }
};
