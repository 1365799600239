import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid, Card, CardContent
} from "@material-ui/core";
import { actions } from "~/app/modules/User/actions";
import TouchPoint from "./acquisitionTabs/TouchPoint";
import Postback from "./acquisitionTabs/Postback";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import enums from "~/app/helpers/enums";


export const acquisitionTabs = [
  {
    view: enums.userTabs.touchPoint,
    name: 'Touch Point',
    renderComponent: (userId) => <TouchPoint userId={userId} />,
  },
  {
    view: enums.userTabs.postback,
    name: 'Postback',
    renderComponent: (userId) => <Postback userId={userId} />,
  }
];

const AcquisitionNavigation = ({ userId }) => {
  return (
    <>
      <div className="flex-row-fluid">
        <Switch>
          <Redirect
            from={`/users/${userId}/${enums.userTabs.acquisition}`}
            exact={true}
            to={`/users/${userId}/${enums.userTabs.acquisition}/${enums.userTabs.touchPoint}`}
          />
          {acquisitionTabs.map((tab) => (
            <Route
              key={tab.view}
              path={`/users/${userId}/${enums.userTabs.acquisition}/${tab.view}`}
              render={() => tab.renderComponent(userId)}
            />
          ))}
        </Switch>
      </div>
    </>
  )
}

const AcquisitionMenu = ({ userId }) => {
  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded py-3">
        <div className="d-flex align-items-center navi navi-bold navi-hover navi-active navi-link-rounded mb-10">
          {acquisitionTabs.map((tab) => {
            return (
              <div key={tab.view} className="navi-item mr-5">
                <NavLink
                  to={`/users/${userId}/${enums.userTabs.acquisition}/${tab.view}`}
                  className="navi-link py-4"
                  activeClassName="active border border-secondary"
                >
                <span className="navi-text font-size-lg">
                  {tab.name}
                </span>
                </NavLink>
              </div>
            )
          })}
        </div>
        <AcquisitionNavigation userId={userId} />
      </CardContent>
    </Card>
  );
}

const Acquisition = ({ userId }) => {
  const userInformation = useSelector(state => state.user.userInformation);
  const userPurchases = useSelector(state => state.user.userPurchases);
  const userPublisherPostbackLogs = useSelector(state => state.user.userPublisherPostbackLogs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getUserPurchases(userId));
    dispatch(actions.getUserPublisherPostbackLogs(userId));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if(!userInformation.user) {
    return null;
  }

  return (
    <Grid container spacing={2} className="flex-row-fluid">
      <Grid item xs={12} md={6} lg={6}>
        <div className="rounded bg-light-primary min-h-80px px-5 py-8">
          <div className="font-weight-boldest font-size-h5 text-primary">Touch Points</div>
          <div className="mb-2">
            <span className="font-weight-boldest font-size-h2">{userPurchases.length}</span>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <div className="rounded bg-light-primary min-h-80px px-5 py-8">
          <div className="font-weight-boldest font-size-h5 text-primary">Postbacks Sent</div>
          <div className="mb-2">
            <span className="font-weight-boldest font-size-h2">{userPublisherPostbackLogs.length}</span>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <AcquisitionMenu userId={userId} />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
      </Grid>
    </Grid>
  )
};

export default Acquisition;
