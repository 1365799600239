import { useDispatch } from "react-redux";
import {useEffect, useState} from 'react';
import { actions } from '~/app/modules/Translations/components/Languages/actions';
import React from "react";
import { Modal } from "react-bootstrap";
import { CloseOutlined } from "@material-ui/icons";
import {Button, Input} from "@material-ui/core";
import cs from 'classnames';

const ModalForVerifyKeyTranslations = ({ language, shown, setShown }) => {
  const dispatch = useDispatch();
  const [selectedLanguageId, setSelectedLanguageId] = useState(language?._id || '');
  const [slackId, setSlackId] = useState(language?.slackId || '');

  useEffect(() => {
    if (language?._id && selectedLanguageId !== language?._id) {
      setSlackId(language?.slackId || '');
      setSelectedLanguageId(language?._id);
    }
  }, [language, selectedLanguageId]);

  const handleCloseModal = () => {
    setShown(false);
    setSlackId('');
  }

  const handleSave = () => {
    dispatch(actions.setSlackId({ _id: language._id, slackId}));
    handleCloseModal();
  };

  const handleChange = (e) => {
    setSlackId(e.target.value);
  };

  return (
    <>
      <Modal
        scrollable
        centered
        size="md"
        show={shown}
        onHide={handleCloseModal}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            Slack Channel ID
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={handleCloseModal}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex p-5 justify-content-between">
            <div>{language?.englishTitle}</div>
            <Input value={slackId} className="ml-5 w-300px" onChange={handleChange} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button
              color="default"
              variant="contained"
              className="mr-3 bg-light-primary text-primary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSave}
              className={cs('bg-primary text-white mr-5')}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalForVerifyKeyTranslations;
