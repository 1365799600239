import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('bannedUsers');

actionTypes.GenerateReportAsCsv = 'banned-users/generate-report-as-csv';
actionTypes.SetBannedAtStart = 'banned-users/set-created-start-date';
actionTypes.SetBannedAtEnd = 'banned-users/set-created-end-date';
actionTypes.SetUseBannedAt = 'banned-users/set-use-created-date';
actionTypes.SetGenerateButtonClick = 'banned-users/set-generate-button-click';

actionTypes.SetMassBanRows = 'banned-users/set-mass-ban-rows';
actionTypes.MassBan = 'banned-users/mass-ban';
actionTypes.MassBanSuccess = 'banned-users/mass-ban-success';
actionTypes.MassBanFailure = 'banned-users/mass-ban-failure';
actionTypes.SetMassUnbanRows = 'banned-users/set-mass-unban-rows';
actionTypes.MassUnban = 'banned-users/mass-unban';
actionTypes.MassUnbanSuccess = 'banned-users/mass-unban-success';
actionTypes.MassUnbanFailure = 'banned-users/mass-unban-failure';
actionTypes.HideServerError = 'banned-users/hide-server-error';

actionTypes.ShowError = 'bannes-users/show-error';
actionTypes.HideError = 'bannes-users/hide-error';
actionTypes.ShowSuccess = 'bannes-users/show-success';
actionTypes.HideSuccess = 'bannes-users/hide-success';

actions.generateReportAsCsv = () => ({
  type: actionTypes.GenerateReportAsCsv,
  payload: {}
});

actions.setBannedAtStart = (date) => ({
  type: actionTypes.SetBannedAtStart,
  payload: date
});

actions.setBannedAtEnd = (date) => ({
  type: actionTypes.SetBannedAtEnd,
  payload: date
});

actions.setUseBannedAt = (value) => ({
  type: actionTypes.SetUseBannedAt,
  payload: value
});

actions.setGenerateButtonClick = () => ({ type: actionTypes.SetGenerateButtonClick });

actions.setMassBanRows = (value) => ({
  type: actionTypes.SetMassBanRows,
  payload: value
});

actions.massBan = () => ({ type: actionTypes.MassBan });

actions.massBanSuccess = (value) => ({
  type: actionTypes.MassBanSuccess,
  payload: value
});

actions.massBanFailure = (value) => ({
  type: actionTypes.MassBanFailure,
  payload: value
});

actions.setMassUnbanRows = (value) => ({
  type: actionTypes.SetMassUnbanRows,
  payload: value
});

actions.massUnban = () => ({ type: actionTypes.MassUnban });

actions.massUnbanSuccess = (value) => ({
  type: actionTypes.MassUnbanSuccess,
  payload: value
});

actions.massUnbanFailure = (value) => ({
  type: actionTypes.MassUnbanFailure,
  payload: value
});

actions.hideServerError = () => ({ type: actionTypes.HideServerError });

actions.showError = (value) => ({ type: actionTypes.ShowError, payload: value });
actions.hideError = () => ({ type: actionTypes.HideError });
actions.showSuccess = (value) => ({ type: actionTypes.ShowSuccess, payload: value });
actions.hideSuccess = () => ({ type: actionTypes.HideSuccess });

export {
  actionTypes,
  actions,
};
