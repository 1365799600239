import { call, put, takeLatest, select } from "redux-saga/effects";
import api from '~/app/modules/Rewards/components/AmazonInventory/api';
import { actions, actionTypes } from '~/app/modules/Rewards/components/AmazonInventory/actions';

const selectForFetchReport = (state) => ({
  filters: state.amazonInventory.filters,
  pageNumber: state.amazonInventory.pageNumber,
  pageSize: state.amazonInventory.pageSize,
  sortBy: state.amazonInventory.sortBy,
  sortDirection: state.amazonInventory.sortDirection,
});

function* requestReport() {
  try {
    const reportParams = yield select(selectForFetchReport);
    const results = yield call(api.fetchReport, reportParams);
    yield put(actions.fetchReportSuccess(results));
  } catch (error) {
    yield put(actions.fetchReportFailure(error.message));
  }
}

function* getAmazonCards() {
  try {
    const results = yield call(api.getAmazonCards);
    yield put(actions.getAmazonCardsSuccess(results));
  } catch (error) {
    yield put(actions.getAmazonCardsFailure(error.message));
  }
}

function* getAmazonBatchesHistory() {
  try {
    const results = yield call(api.getAmazonBatchesHistory);
    yield put(actions.getAmazonBatchesHistorySuccess(results));
  } catch (error) {
    yield put(actions.getAmazonBatchesHistoryFailure(error.message));
  }
}

function* uploadCSV(action) {
  try {
    const results = yield call(api.uploadCSV, action.payload);
    yield put(actions.uploadCSVSuccess(results));
    yield put(actions.getAmazonCards());
    yield put(actions.generateReport());
  } catch (error) {
    yield put(actions.uploadCSVFailure(error));
  }
}

export default [
  takeLatest([
    actionTypes.GenerateReport,
    actionTypes.UpdateReport,
  ], requestReport),
  takeLatest(actionTypes.GetAmazonCards, getAmazonCards),
  takeLatest(actionTypes.UploadCSV, uploadCSV),
  takeLatest(actionTypes.GetAmazonBatchesHistory, getAmazonBatchesHistory),
];

