import { actionTypes } from '~/app/modules/Reviews/actions';

const initialState = {
  reviews: [],
  reviewLanguages: [],
  imageURL: '',

  imageIsLoading: false,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetAllReviewsSuccess:
      return {
        ...state,
        isLoading: false,
        reviews: action.payload.reviews,
        reviewLanguages: action.payload.reviewLanguages,
      };

    case actionTypes.GetAllReviews:
    case actionTypes.CreateReview:
    case actionTypes.UpdateReview:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GetAllReviewsFailure:
    case actionTypes.CreateReviewFailure:
    case actionTypes.UpdateReviewFailure:
    case actionTypes.CreateReviewSuccess:
    case actionTypes.UpdateReviewSuccess:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.UploadImage:
      return {
        ...state,
        imageIsLoading: true,
      };

    case actionTypes.UploadImageSuccess:
      return {
        ...state,
        imageIsLoading: false,
        imageURL: action.payload,
      };

    case actionTypes.UploadImageFailure:
      return {
        ...state,
        imageIsLoading: false,
      };

    default:
      return state;
  }
};
