import React from 'react';


const SwitchField = ({ label, status, onChange }) => {
  return (
    <div className="d-flex justify-content-between mb-3">
      <div className="mr-2">{label}</div>
      <span className="switch switch-sm switch-icon-success">
        <label>
          <input
            type="checkbox"
            name={label}
            checked={status}
            onChange={onChange ? onChange : () => {}}
          />
          <span />
        </label>
      </span>
    </div>
  )
}

export default SwitchField;
