import config from '~/config';
import fetch from '~/redux/fetch';

const LOGIN_URL = "/auth/login";
const ME_URL = "/auth/me";

export const login = async (email, password) => {
  const response = await fetch(`${config.apiBaseUrl}${LOGIN_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  });
  return await response.json();
}

export const getUser = async () => {
  const response = await fetch(`${config.apiBaseUrl}${ME_URL}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  return await response.json();
}
