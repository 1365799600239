import React from 'react';
import {
  Card, CardContent, Grid
} from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";

function StatisticsTable({entities}) {
  const columns = [
    { dataField: "item", text: "ITEM"  },
    { dataField: "sent", text: "SENT"  },
    { dataField: "viewed", text: "VIEWED" },
    {  dataField: "clicked", text: "CLICKED"  },
    { dataField: "convertedToLeads", text: "CONVERTED" },
    {  dataField: "cr", text: "CR"  },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={false}
      borderless={true}
      classes="table table-vertical-top overflow-hidden"
      bootstrap4
      size="sm"
      responsive="lg"
      headerClasses="bg-light rounded"
      remote
      keyField="item"
      data={entities}
      columns={columns}
    >
    </BootstrapTable>
  );
}

const StatisticsCard = () => {
  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded pb-20">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          Mobile App Notification Statistics
        </h3>
        <StatisticsTable entities={[]} />
      </CardContent>
    </Card>
  )
}

function ReportTable({ entities }) {
  const columns = [
    { dataField: "_id", text: "Notification ID" },
    {  dataField: "sent", text: "Send Date" },
    {  dataField: "viewed", text: "Viewed"  },
    {  dataField: "clicked", text: "Clicked"  },
    {  dataField: "converted", text: "Converted To Lead" },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={true}
      classes="table table-vertical-top overflow-hidden"
      bootstrap4
      size="sm"
      responsive="lg"
      remote
      keyField="_id"
      data={entities}
      columns={columns}
    >
    </BootstrapTable>
  );
}

const ReportCard = () => {
  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded pb-20">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          Mobile App Notifications Report
        </h3>
        <ReportTable entities={[]}/>
      </CardContent>
    </Card>
  )
}


const NotificationsMobile = () => {

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <StatisticsCard />
      </Grid>
      <Grid item xs={12}>
        <ReportCard />
      </Grid>
    </Grid>
  )
};

export default NotificationsMobile;
