import { actionTypes } from '~/app/modules/Translations/components/Translators/actions';

const initialState = {
  translators: [],
  filters: {},
  isLoading: false,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetTranslatorsSuccess:
      return {
        ...state,
        translators: action.payload,
        isLoading: false,
      };

    case actionTypes.GetTranslators:
    case actionTypes.GetAvailableParams:
    case actionTypes.CreateTranslator:
    case actionTypes.UpdateTranslator:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GetTranslatorsFailure:
    case actionTypes.GetAvailableParamsFailure:
    case actionTypes.CreateTranslatorFailure:
    case actionTypes.UpdateTranslatorFailure:
    case actionTypes.CreateTranslatorSuccess:
    case actionTypes.UpdateTranslatorSuccess:
      return {
        ...state,
        isLoading: false,
      }

    case actionTypes.GetAvailableParamsSuccess:
      return {
        ...state,
        languages: action.payload?.languages,
        isLoading: false,
      };

    case actionTypes.SetFilter:
      return {
        ...state,
        filters: action.payload,
      };

    default:
      return state;
  }
};
