import React, {useEffect, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {customFilter, selectFilter} from 'react-bootstrap-table2-filter';
import { actions } from '~/app/modules/Translations/components/Keys/actions';
import { actions as featureActions } from '~/app/modules/Translations/components/Feature/actions';
import { sortCaret } from '~/_metronic/_helpers';
import cs from 'classnames';
import {
  textFilterOnBlurHelper,
} from '~/app/helpers';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ProgressBar from "../CustomProgressBar";
import {
  Button,
  Card,
  CardContent,
} from "@material-ui/core";
import StatusField from "../StatusField";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "~/_metronic/_helpers";
import {
  PhotoAlbum, ThumbUpAltOutlined, ThumbUpAlt, CheckBox, CheckBoxOutlined
} from "@material-ui/icons";
import FeaturetAndKeysModal from "../FeatureAndKeysModal";
import { Star, Link } from '@material-ui/icons';
import { newDateFormatLong } from "~/app/helpers";
import { Input } from "@material-ui/core";
import CreateFeatureModal from "../CreateFeatureModal";
import { injectIntl } from "react-intl";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';
import { sizePerPageRenderer } from '~/app/helpers';
import enums from "~/app/helpers/enums";

const sortableColumn = {
  sort: true, sortCaret,
};

const renderNameField = (row) => {
  const renderKeyItem = () => {
    if (row.aliasOfKeyId) {
      return (
        <span className="svg-icon svg-icon-primary h-20 mr-4">
          <Link />
        </span>
      );
    }
    if (row.aliasKeys?.length) {
      return (
        <span className="svg-icon svg-icon-warning h-20 mr-4">
          <Star style={{ color: '#ffa701' }}/>
        </span>
      );
    }

    return (
      <div />
    );
  }

  if (row.isExpand) {
    return (
      <div className="d-flex">
        <span className="text-primary min-w-15px">▼</span>
        {renderKeyItem()}
        <span>
          {row.key}
        </span>
      </div>
    );
  }

  return (
    <div className="d-flex">
      <span className="text-primary min-w-15px">▶</span>
      {renderKeyItem()}
      <span>
        {row.key}
      </span>
    </div>
  );
};

const renderText = (row) => {
  if (row?.aliasOfKeyId) {
    return <div>{row?.masterKey?.text}</div>;
  }

  return <div>{row.text}</div>;
}

const statusOptions = [ ...Object.values(enums.projectStatuses).map((projectStatus) => (
  { value: projectStatus, label: projectStatus })),
  { value: '', label: 'All' },
];

const renderActions = (row, setShown, setModalType, setModalValues) => {
  const handleClick = () => {
    setModalType('linkedKey');
    setModalValues(row);
    setShown(true);
  }

  if (row.aliasOfKeyId) {
    return (
      <div onClick={handleClick}>
        <span className="svg-icon svg-icon-primary h-20 mr-4">
          <SVG
            src={toAbsoluteUrl('/media/svg/icons/General/Attachment2.svg')}
          />
        </span>
      </div>
    );
  }

  if (!row.aliasKeys?.length && !row?.features?.length) {
    return (
      <div onClick={handleClick}>
        <span className="svg-icon svg-icon-secondary h-20 mr-4">
          <SVG
            src={toAbsoluteUrl('/media/svg/icons/General/Attachment1.svg')}
          />
        </span>
      </div>
    );
  }

  return <div />;
};

const renderScreenshot = (row, setShown, setModalType, setModalValues) => {
  const screenshot = row?.aliasOfKeyId ? row?.masterKey?.screenshot : row?.screenshot;
  const handleClick = (e) => {
    e.stopPropagation();
    setModalValues({ ...row, screenshot });
    setShown(true);
    setModalType('screenshot')
  }

  return (
    <div onClick={handleClick}>
      <PhotoAlbum className={screenshot ? "text-primary" : 'text-muted'} />
    </div>
  )
};

const renderFeatureField = (features, row, dispatch, history) => {
  if (!features?.length) {
    return <div />;
  }

  const handleClickOnFeature = (feature) => () => {
    dispatch(featureActions.setFilterValues({ key: row.key, text: row.text }));
    history.push(`/translations/features/${feature._id}`)
  };

  return (
    <div className="d-flex max-w-250px flex-wrap">
      {features.map((feature) => (
        <div
          onClick={handleClickOnFeature(feature)}
          className="px-4 py-2 text-dark rounded bg-secondary mr-3 mb-3 text-center"
          key={feature._id}
        >
          {feature.name}
        </div>
      ))}
    </div>
  );
}

const renderProductField = (productName) => {
  if (!productName) {
    return <div />;
  }
  return (
    <div className="px-4 py-2 text-dark rounded bg-secondary mr-3 mb-3 text-center" key={productName}>
      {productName}
    </div>
  );
}

const columns = (setShown, setModalType, setModalValues, dispatch, history) => [{
  ...sortableColumn,
  dataField: 'key', text: 'Key Name',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "120px" }}),
  formatter: (_cell, row) => renderNameField(row),
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'text', text: 'Text',
  filter: customFilter(),
  formatter: (_cell, row) => renderText(row),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(
    onFilter, column, {style: { width: "300px" }}),
  style: { width: '360px', minWidth: '360px', textAlign: 'left' },
}, {
  dataField: 'screenshot', text: 'Screenshot',
  formatter: (screenshot, row) => renderScreenshot(row, setShown, setModalType, setModalValues),
  style: { width: '100px', minWidth: '100px', textAlign: 'left' },
}, {
  dataField: 'progress', text: 'Overall Progress',
  style: { width: '180px', minWidth: '180px', textAlign: 'left' },
  formatter: (progress, row) => (<ProgressBar percent={row.progress} approvedPercent={row.approvedProgress} />),
}, {
  ...sortableColumn,
  dataField: 'status', text: 'Status',
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: statusOptions,
  }),
  formatter: (status) => (<StatusField status={status} />)
},{
  ...sortableColumn,
  dataField: 'features', text: 'Feature',
  formatter: (features, row) => renderFeatureField(features, row, dispatch, history),
  style: { width: '150px', minWidth: '150px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'product', text: 'Product',
  formatter: (products) => renderProductField(products),
  style: { width: '150px', minWidth: '250px', textAlign: 'left' },
},{
  dataField: 'createdAt', text: 'Created',
  formatter: (createdAt) => newDateFormatLong(createdAt, '(PST)'),
  style: { width: '150px', minWidth: '150px', textAlign: 'left' },
},{
  dataField: 'updatedAt', text: 'Last Updated',
  formatter: (updatedAt) => newDateFormatLong(updatedAt, '(PST)'),
  style: { width: '150px', minWidth: '150px', textAlign: 'left' },
}, {
  dataField: 'actions', text: 'Actions',
  style: { width: '120px', textAlign: 'left' },
  formatter: (_cell, row) => renderActions(row, setShown, setModalType, setModalValues),
}];

const renderKeyFieldActions = (keyTranslation, dispatch, user, setModalType, setShown) => {
  const handleChangeStatus = (status) => () => {
    if (keyTranslation?.text) {
      dispatch(actions.updateKeyTranslation({ keyTranslationId: keyTranslation._id, status }));
    } else {
      setModalType('emptyTranslationConfirm');
      setShown(true);
    }
  };

  switch (keyTranslation.status) {
    case enums.projectStatuses.PENDING_APPROVAL:
      return (
        <div className="d-flex min-w-60px justify-content-between">
          <ThumbUpAlt
            className="cursor-pointer text-success"
            onClick={handleChangeStatus(enums.projectStatuses.IN_PROGRESS)}
          />
          {(user?.role !== enums.translatorRoles.translator) && (
            <CheckBoxOutlined
              className="cursor-pointer text-success"
              onClick={handleChangeStatus(enums.projectStatuses.VERIFIED)}
            />
          )}
        </div>
      );
    case enums.projectStatuses.VERIFIED:
      return (
        <div className="d-flex min-w-60px justify-content-between">
          <ThumbUpAlt
            className="cursor-pointer text-success"
            onClick={handleChangeStatus(enums.projectStatuses.IN_PROGRESS)}
          />
          {(user?.role !== enums.translatorRoles.translator) && (
            <CheckBox
              className="cursor-pointer text-success"
              onClick={handleChangeStatus(enums.projectStatuses.IN_PROGRESS)}
            />
          )}
        </div>
      );

    default:
      return (
        <div className="d-flex min-w-60px justify-content-between">
          <ThumbUpAltOutlined
            className="cursor-pointer text-success"
            onClick={handleChangeStatus(enums.projectStatuses.PENDING_APPROVAL)}
          />
          {(user?.role !== enums.translatorRoles.translator) && (
            <CheckBoxOutlined
              className="cursor-pointer text-success"
              onClick={handleChangeStatus(enums.projectStatuses.VERIFIED)}
            />
          )}
        </div>
      );
  }
}

const InputWithSaveForLanguageKey = ({ keyTranslation, alias }) => {
  const [value, setValue] = useState(keyTranslation.text || '');
  const [editMode, setEditMode] = useState(false)
  const dispatch = useDispatch();

  const handleChangeTextValue = (e) => {
    if (!alias) {
      setValue(e.target.value || '');
    }
  }

  const handleChangeEditMode = () => {
    if (!editMode) {
      setEditMode(true);
    }
  };

  const handleSaveButtonClick = () => {
    dispatch(actions.updateKeyTranslation({ text: value, keyTranslationId: keyTranslation._id  }));
    setEditMode(false);
  }

  if (!editMode) {
    return (
      <div
        onClick={handleChangeEditMode}
        className="ml-3 min-w-700px w-700px min-h-40px d-flex align-items-center border border-dark p-1"
      >
        {value}
      </div>
    )
  }

  return (
    <div
      className="ml-3 min-w-700px w-700px min-h-40px d-flex align-items-center border border-dark p-1"
    >
      <Input disabled={alias} value={value} className="min-w-600px min-h-40px" onChange={handleChangeTextValue} />
      {(value !== keyTranslation?.text && !alias) && (<Button
          color="primary"
          variant="contained"
          onClick={handleSaveButtonClick}
          className="bg-primary text-white ml-auto"
        >
          Save
        </Button>
      )}
    </div>
  )
};

const Keys = ({ intl }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const report = useSelector(state => state.keys.report, shallowEqual);
  const history = useHistory();
  const [dataWithExpanded, setDataWithExpanded] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selectedKeyIds, setSelectedKeyIds] = useState([]);
  const [shown, setShown] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalValues, setModalValues] = useState({});
  const [shownCreateFeatureModal, setShownCreateFeatureModal] = useState(false);

  useEffect(() => {
    dispatch(actions.getKeys());
    dispatch(actions.getAvailableParams());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newData = report.map((row) => ({
      ...row,
      isExpand: expanded.includes(row._id),
    }));
    setDataWithExpanded(newData);
  }, [setDataWithExpanded, report]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnExpand = (row, isExpand) => {
    if (!row.isExpand) {
      const newData = dataWithExpanded.map((dataRow) => {
        if (row._id === dataRow._id) {
          return {...dataRow, isExpand};
        }
        return dataRow;
      })
      setDataWithExpanded(newData);
      setExpanded([...expanded, row._id]);
    } else {
      const newData = dataWithExpanded.map((dataRow) => {
        if (row._id === dataRow._id && row.isExpand) {
          const newDataRow = {...dataRow};
          delete newDataRow.isExpand;
          return newDataRow;
        }
        return dataRow;
      })
      setDataWithExpanded(newData);
      setExpanded(expanded.filter((_id) => (row._id !== _id)));
    }
  }

  const expandRow = {
    renderer: row => {
      return (
        <div className="my-2">
          <div className="d-flex">
            <h4 className="min-w-180px">Language</h4>
            <h4 className="ml-3 min-w-700px">Translation</h4>
            <h4 className="ml-20 min-w-180px">Translator</h4>
            {!row?.aliasOfKeyId && (<h4 className="ml-20 min-w-180px">Status</h4>)}
          </div>
          {row.keyTranslations.map((keyTranslation) => {
            return (
              <div key={keyTranslation._id} className="min-h-40px border-top d-flex w-100 pb-2">
                <div className="min-w-180px w-180px h-40px d-flex align-items-center">{keyTranslation.language}</div>
                <InputWithSaveForLanguageKey keyTranslation={keyTranslation} alias={!!row?.aliasOfKeyId} />
                <div className="ml-20 min-w-180px w-180px h-40px text-muted d-flex align-items-center">
                  {keyTranslation?.translator}
                </div>
                {!row?.aliasOfKeyId && (
                  <div className="ml-20 min-w-180px w-180px h-40px d-flex align-items-center">
                    {renderKeyFieldActions(keyTranslation, dispatch, user, setModalType, setShown)}
                  </div>
                )}
              </div>
            )}
          )}
        </div>
      );
    },
    expanded,
    onExpand: handleOnExpand,
  };

  const rowStyle = (row) => {
    if (row.isExpand) {
      return { cursor: 'pointer', background: '#f1f1f1' };
    }
    return { cursor: 'pointer' };
  };

  const reportColumns = columns(setShown, setModalType, setModalValues, dispatch, history);
  const tableColumns = user?.role ? reportColumns.filter((col, index) => ((index + 1) < reportColumns.length))
    : reportColumns;

  const selectRow = {
    mode: 'checkbox',
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (!selectedKeyIds.includes(row._id) && isSelect) {
        const newSelectedKeyIds = [...selectedKeyIds, row._id];
        setSelectedKeyIds(newSelectedKeyIds);
      } else if (selectedKeyIds.includes(row._id) && !isSelect) {
        const newSelectedKeyIds = selectedKeyIds.filter((keyId) => keyId !== row._id);
        setSelectedKeyIds(newSelectedKeyIds);
      }
    },
    onSelectAll: (isSelect, rows) => {
      const rowsIds = rows.map((row) => (row._id));
      if (isSelect) {
        if (rowsIds.length) {
          const newSelectedKeyIds = [...selectedKeyIds];
          rowsIds.forEach((_id) => {
            if (!newSelectedKeyIds.includes(_id)) {
              newSelectedKeyIds.push(_id);
            }
          });
          setSelectedKeyIds(newSelectedKeyIds);
        }
      } else {
        const newSelectedKeyIds = selectedKeyIds.filter((_id) => (!rowsIds.includes(_id)));
        setSelectedKeyIds(newSelectedKeyIds);
      }
    }
  };

  const handleCreateFeature = () => {
    setShownCreateFeatureModal(true);
  };

  return (
    <div>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded d-flex align-item-center min-h-40px">
          <h3 className="pl-2 d-flex align-items-center">
            Keys
          </h3>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-center flex-column">
        <Card>
          <CardContent className="border text-dark border-light-dark rounded">
            <div className="d-flex justify-content-between align-items-start">
              <div className="w-100 d-flex justify-content-end">
                {!(user?.role) && (
                  <div className="d-flex min-w-200px ml-auto mt-2">
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={!selectedKeyIds.length}
                      onClick={handleCreateFeature}
                      className={cs('text-white ml-auto', {
                        'bg-primary': selectedKeyIds.length,
                        'bg-secondary': !selectedKeyIds.length,
                      })}
                    >
                      Create Feature
                    </Button>
                    {/*<Button*/}
                    {/*  color="primary"*/}
                    {/*  variant="contained"*/}
                    {/*  onClick={handleClick}*/}
                    {/*  className="bg-primary text-white ml-5"*/}
                    {/*>*/}
                    {/*  Export{' '}{open ? <ExpandLess /> : <ExpandMore />}*/}
                    {/*</Button>*/}
                    {/*<Popper id={id} open={open} anchorEl={anchorEl} transition>*/}
                    {/*  {({ TransitionProps }) => (*/}
                    {/*    <Fade {...TransitionProps} timeout={350}>*/}
                    {/*      <div className="bg-white border rounded py-4 pl-4 pr-6">*/}
                    {/*        <div onClick={() => setAnchorEl(null)} className="mb-4 cursor-pointer">Export to CSV</div>*/}
                    {/*        <div onClick={() => setAnchorEl(null)} className="mb-4 cursor-pointer">Export to JSON</div>*/}
                    {/*        <div onClick={() => setAnchorEl(null)} className="cursor-pointer">Export to ARB</div>*/}
                    {/*      </div>*/}
                    {/*    </Fade>*/}
                    {/*  )}*/}
                    {/*</Popper>*/}
                  </div>
                )}
              </div>
            </div>
            {dataWithExpanded?.length ? (
              <div className="d-flex justify-content-end mb-4">Number of Keys: {dataWithExpanded.length}</div>
            ) : ''}
            <BootstrapTable
              classes="table table-head-custom table-vertical-center overflow-hidden"
              headerClasses="table table-vertical-top"
              wrapperClasses="rounded my-2"
              bootstrap4
              responsive
              keyField="_id"
              columns={tableColumns}
              data={dataWithExpanded}
              filter={ filterFactory() }
              selectRow={ user?.role ? undefined : selectRow }
              defaultSorted={[{
                dataField: 'key',
                order: enums.sortDirections.desc,
              }]}
              rowStyle={ rowStyle }
              expandRow={ expandRow }
              pagination={paginationFactory({
                sizePerPage: 100,
                sizePerPageRenderer,
                sizePerPageList:[
                  { text: '10', value: 10,},
                  { text: '20', value: 20, },
                  { text: '50', value: 50, },
                  { text: '100', value: 100, },
                  { text: '500', value: 500, }
                ],
              })}
            />
          </CardContent>
        </Card>
      </div>
      <FeaturetAndKeysModal
        shown={shown}
        setShown={setShown}
        type={modalType}
        values={modalValues}
        actions={actions}
        user={user}
      />
      <CreateFeatureModal
        setShown={setShownCreateFeatureModal}
        shown={shownCreateFeatureModal}
        selectedKeyIds={selectedKeyIds}
        intl={intl}
        actions={actions}
        stateKey={'keys'}
      />
    </div>
  );
};

export default injectIntl(Keys);
