import { call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/SharedView/api';
import { actions, actionTypes } from '~/app/modules/SharedView/actions';

function* getSharedParams(action) {
  try {
    const results = yield call(api.getSharedParams, action.payload);
    yield put(actions.getSharedParamsSuccess(results));
  } catch (error) {
    console.error(error);
  }
}

export default [
  takeLatest(actionTypes.GetSharedParams, getSharedParams),
];

