const actionTypes = {
  RemoveBitcoinRecord: 'bitcoinRecords/remove-bitcoin-record',
  RemoveBitcoinRecordSuccess: 'bitcoinRecords/remove-bitcoin-record-success',
  RemoveBitcoinRecordFailure: 'bitcoinRecords/remove-bitcoin-record-failure',
  CreateBitcoinRecord: 'bitcoinRecords/create-bitcoin-record',
  CreateBitcoinRecordSuccess: 'bitcoinRecords/create-bitcoin-record-success',
  CreateBitcoinRecordFailure: 'bitcoinRecords/create-bitcoin-record-failure',
  GetBitcoinRecords: 'bitcoinRecords/get-bitcoin-records',
  GetBitcoinRecordsSuccess: 'bitcoinRecords/get-bitcoin-records-success',
  GetBitcoinRecordsFailure: 'bitcoinRecords/get-bitcoin-records-failure',
  GetBitcoinBalance: 'bitcoinRecords/get-bitcoin-balance',
  GetBitcoinBalanceSuccess: 'bitcoinRecords/get-bitcoin-balance-success',
  GetBitcoinBalanceFailure: 'bitcoinRecords/get-bitcoin-balance-failure',
};

const actions = {
  removeBitcoinRecord: (index, data) => ({ type: actionTypes.RemoveBitcoinRecord, payload: { index, data } }),
  removeBitcoinRecordSuccess: () => ({ type: actionTypes.RemoveBitcoinRecordSuccess }),
  removeBitcoinRecordFailure: () => ({ type: actionTypes.RemoveBitcoinRecordFailure }),
  createBitcoinRecord: (data) => ({ type: actionTypes.CreateBitcoinRecord, payload: data }),
  createBitcoinRecordSuccess: () => ({ type: actionTypes.CreateBitcoinRecordSuccess }),
  createBitcoinRecordFailure: () => ({ type: actionTypes.CreateBitcoinRecordFailure }),
  getBitcoinRecords: () => ({ type: actionTypes.GetBitcoinRecords }),
  getBitcoinRecordsSuccess: (data) => ({ type: actionTypes.GetBitcoinRecordsSuccess, payload: data }),
  getBitcoinRecordsFailure: () => ({ type: actionTypes.GetBitcoinRecordsFailure }),
  getBitcoinBalance: () => ({ type: actionTypes.GetBitcoinBalance }),
  getBitcoinBalanceSuccess: (data) => ({ type: actionTypes.GetBitcoinBalanceSuccess, payload: data }),
  getBitcoinBalanceFailure: () => ({ type: actionTypes.GetBitcoinBalanceFailure }),
};

export {
  actionTypes,
  actions,
};
