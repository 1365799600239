export const actionTypes = {
  GetAllReviews: 'reviews/get-all',
  GetAllReviewsSuccess: 'reviews/get-all-success',
  GetAllReviewsFailure: 'reviews/get-all-failure',
  CreateReview: 'reviews/create-new',
  CreateReviewSuccess: 'reviews/create-new-success',
  CreateReviewFailure: 'reviews/create-new-failure',
  UpdateReview: 'reviews/update-review',
  UpdateReviewSuccess: 'reviews/update-review-success',
  UpdateReviewFailure: 'reviews/update-review-failure',
  UploadImage: 'reviews/upload-image',
  UploadImageSuccess: 'reviews/upload-image-success',
  UploadImageFailure: 'reviews/upload-image-failure',
};

export const actions = {
  getAllReviews: () => ({ type: actionTypes.GetAllReviews }),
  getAllReviewsSuccess: (data) => ({ type: actionTypes.GetAllReviewsSuccess, payload: data }),
  getAllReviewsFailure: () => ({ type: actionTypes.GetAllReviewsFailure }),
  createReview: (data) => ({ type: actionTypes.CreateReview, payload: data }),
  createReviewSuccess: () => ({ type: actionTypes.CreateReviewSuccess }),
  createReviewFailure: () => ({ type: actionTypes.CreateReviewFailure }),
  updateReview: (userReviewId, data) => ({ type: actionTypes.UpdateReview, payload: { userReviewId, data } }),
  updateReviewSuccess: () => ({ type: actionTypes.UpdateReviewSuccess }),
  updateReviewFailure: () => ({ type: actionTypes.UpdateReviewFailure }),
  uploadImage: (file) => ({ type: actionTypes.UploadImage, payload: file }),
  uploadImageSuccess: (imageURL) => ({ type: actionTypes.UploadImageSuccess, payload: imageURL }),
  uploadImageFailure: () => ({ type: actionTypes.UploadImageFailure }),
};
