/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
        <NavLink className="menu-link" to="/dashboard">
          <span className="menu-text">Dashboard</span>
          {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
        </NavLink>
      </li>

      <li className={`menu-item menu-item-rel ${getMenuItemActive('/report-builder')}`}>
        <NavLink className="menu-link" to="/report-builder">
          <span className="menu-text">Report Builder</span>
        </NavLink>
      </li>

      <li className={`menu-item menu-item-rel ${getMenuItemActive('/high-reversal-rate-bans')}`}>
        <NavLink className="menu-link" to="/high-reversal-rate-bans">
          <span className="menu-text">High Reversal Rate Bans</span>
        </NavLink>
      </li>
    </ul>
  </div>;
}
