import { format } from 'date-fns';
import { actionTypes } from '~/app/modules/ReportBuilder/actions';
import enums from "~/app/helpers/enums";

const initialState = {
  startDate: format(new Date(), enums.DATE_FORMAT),
  endDate: format(new Date(), enums.DATE_FORMAT),
  reportType: 'activity',
  sortBy: '',
  sortDirection: enums.defaultSortDirection,
  measures: [],
  dimensions: [],
  filters: [],

  isLoading: false,
  isLoadingCSV: false,
  isLoadingSharedView: false,
  sharedLink: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetStartDate:
      return {
        ...state,
        startDate: action.payload,
      };

    case actionTypes.SetEndDate:
      return {
        ...state,
        endDate: action.payload,
      };

    case actionTypes.SetSortBy:
      return {
        ...state,
        sortBy: action.payload,
      };

    case actionTypes.SetSortDirection:
      return {
        ...state,
        sortDirection: action.payload,
      };

    case actionTypes.SetDimensions:
      return {
        ...state,
        dimensions: action.payload,
      };

    case actionTypes.SetMeasures:
      return {
        ...state,
        measures: action.payload,
      };

    case actionTypes.DeleteDimension:
      const dimensionToDelete = action.payload;
      const newDimensions = state.dimensions.filter(key => key !== dimensionToDelete);

      return {
        ...state,
        dimensions: newDimensions,
        sortBy: dimensionToDelete === state.sortBy ? null : state.sortBy,
      };

    case actionTypes.DeleteMeasure:
      const measureToDelete = action.payload;
      const newMeasures = state.measures.filter(key => key !== measureToDelete);

      return {
        ...state,
        measures: newMeasures,
        sortBy: measureToDelete === state.sortBy ? null : state.sortBy,
      };

    case actionTypes.SetReportType:
      const reportType = action.payload;

      return {
        ...initialState,
        reportType,
      }

    case actionTypes.SetFilter:
      const filter = action.payload;
      return {
        ...state,
        filters: [
          ...state.filters, 
          filter,
        ],
      }

    case actionTypes.DeleteFilter:
      const key = action.payload;
      return {
        ...state,
        filters: [...state.filters.filter(ch => ch.key !== key)], 
      }

    case actionTypes.CreateSharedLink:
      return {
        ...state,
        isLoadingSharedView: true,
      }

    case actionTypes.CreateSharedLinkFailed:
      return {
        ...state,
        isLoadingSharedView: false,
      }

    case actionTypes.CreateSharedLinkSuccess:
      return {
        ...state,
        isLoadingSharedView: false,
        sharedLink: action.payload.link,
      }

    case actionTypes.SetParams:
      return {
        ...state,
        ...action.payload,
      }

    case actionTypes.ClearSharedLink:
      return {
        ...state,
        sharedLink: '',
      }

    default:
      return state;
  }
};
