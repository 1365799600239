import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Card, CardContent, Button, TextField, TextareaAutosize } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { actions } from '~/app/modules/FaceTecSettings/actions';

const FaceTecSettingsForm = () => {
  const dispatch = useDispatch();
  const inited = useSelector(state => state.faceTecSettings.inited);
  const faceTecSettings = useSelector(state => state.faceTecSettings.faceTecSettings);

  if (!inited) { return null; }

  const handleChange = (field) => (e) => {
    dispatch(actions.changeFormField({ [field]: e.target.value }));
  };

  const handleSaveChanges = () => {
    dispatch(actions.updateFaceTecSettings());
  };

  const inputWrapper = (key, label, disabled = false) => (
    <div className="d-flex align-items-center mb-3" key={key}>
      <div className="w-300px">{label}</div>
      <div className="d-flex flex-grow-1 align-items-center space-between">
        <TextField
          className="flex-grow-1 p-0"
          variant="outlined"
          margin="dense"
          disabled={disabled}
          onChange={handleChange(key)}
          value={faceTecSettings?.[key] || ''} />
      </div>
    </div>
  );

  const areaWrapper = (key, label, disabled = false) => (
    <div className="d-flex align-items-center mb-3" key={key}>
      <div className="w-300px">{label}</div>
      <div className="d-flex flex-grow-1 align-items-center space-between">
        <TextareaAutosize
          className="flex-grow-1 p-3"
          variant="outlined"
          margin="dense"
          disabled={disabled}
          onChange={handleChange(key)}
          value={faceTecSettings?.[key] || ''} />
      </div>
    </div>
  );

  return (
    <Card className="mb-5 max-w-1000px mx-auto">
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="d-flex justify-content-between align-items-center pb-3 border-bottom border-light-secondary mb5">
          <div className="d-flex">
            <Button className="btn btn-primary w-150px" onClick={handleSaveChanges}>Save Changes</Button>
          </div>
        </div>
        {inputWrapper('baseUrl', 'ID Check Service Base URL', true)}
        {areaWrapper('publicFaceScanEncryptionKey', 'Public Face Scan Encryption Key', true)}
        {inputWrapper('deviceKeyIdentifier', 'Device Key Identifier', true)}
        {inputWrapper('productionKeyText', 'Production Key Text')}
      </CardContent>
    </Card>
  );
};

const FaceTecSettings = () => {
  const dispatch = useDispatch();
  const showErrorAlert = useSelector(state => state.faceTecSettings.showErrorAlert);
  const showSuccessAlert = useSelector(state => state.faceTecSettings.showSuccessAlert);
  const serverError = useSelector(state => state.faceTecSettings.serverError);

  useEffect(() => {
    dispatch(actions.fetchFaceTecSettings());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showSuccessAlert) {
      setTimeout(() => dispatch(actions.closeSuccessAlert()), 3000);
    }
  }, [showSuccessAlert]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded pt-6 pb-6">
          <div className="d-flex align-items-center">
            <h3 className="mb-0" >FaceTec Settings</h3>
          </div>
        </CardContent>
      </Card>
      <FaceTecSettingsForm />
      {showErrorAlert && (
        <div style={{ position: "fixed", top: '30%', zIndex: '1000', left: '45%' }}>
          <Alert onClose={() => dispatch(actions.closeErrorAlert())} severity="error" variant="filled">
            <AlertTitle>Something Went Wrong</AlertTitle>
            {serverError}
          </Alert>
        </div>
      )}
      {showSuccessAlert && (
        <div style={{ position: "fixed", top: '30%', zIndex: '1000', left: '45%' }}>
          <Alert onClose={() => dispatch(actions.closeSuccessAlert())} severity="success" variant="filled">
            <AlertTitle>Saved Successfully!</AlertTitle>
          </Alert>
        </div>
      )}
    </>
  );
};
export default FaceTecSettings;

