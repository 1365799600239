import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, CardContent, Button, Grid, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import { actions } from '~/app/modules/QuestionsManagement/components/Questions/actions';
import QuestionsReportTable from "./Questions.report";
import {Spinner} from "react-bootstrap";

const FiltersSection = () => {
  const dispatch = useDispatch();
  const isLoading = false;

  const countryCodes = useSelector(state => state.questions.countryCodes);
  const countryCode = useSelector(state => state.questions.countryCode);
  const languageCodes = useSelector(state => state.questions.languageCodes);
  const languageCode = useSelector(state => state.questions.languageCode);
  const providers = useSelector(state => state.questions.providers);
  const provider = useSelector(state => state.questions.provider);

  const languageCodeItems = [...languageCodes, { name: 'All values', code: '' }].map((language) => (
    <MenuItem key={language.name} value={language.code}>
      {language.name} {language.code && ` (${language.code})`}
    </MenuItem>
  ));

  const countryCodeItems = [ ...countryCodes, { name: 'All values', code: '' }].map((country) => (
    <MenuItem key={country.name} value={country.code}>
      {country.name} {country.code && ` (${country.code})`}
    </MenuItem>
  ));

  const providerItems = [ ...providers, { name: 'All values', value: '' }].map((currentProvider) => {
    if (currentProvider?.name) {
      return (
        <MenuItem key={currentProvider?.name} value={currentProvider?.value}>
          {currentProvider?.name}
        </MenuItem>
      );
    }

    return (
      <MenuItem key={currentProvider} value={currentProvider}>
        {currentProvider}
      </MenuItem>
    );
  });

  return (
    <Card className="my-5">
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="d-flex align-items-end">
          <Grid className="max-w-1000px" container justify="space-between">
            <Grid item xs={3}>
              <FormControl margin="normal">
                <InputLabel id="type-label">Language</InputLabel>
                <Select
                  id="type"
                  labelId="type-label"
                  value={languageCode}
                  onChange={e => dispatch(actions.setLanguageCode(e.target.value))}
                >
                  {languageCodeItems}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl margin="normal">
                <InputLabel id="country-codes-label">Country</InputLabel>
                <Select
                  id="country-codes"
                  labelId="country-codes-label"
                  value={countryCode}
                  onChange={e => dispatch(actions.setCountryCode(e.target.value))}
                >
                  {countryCodeItems}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl margin="normal">
                <InputLabel id="country-codes-label">Provider</InputLabel>
                <Select
                  id="provider"
                  labelId="provider-label"
                  value={provider}
                  onChange={e => dispatch(actions.setProvider(e.target.value))}
                >
                  {providerItems}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {isLoading ? (<div className="ml-7"><Spinner animation="border" variant="primary" /></div>) : (
            <Button
              color="primary"
              variant="contained"
              className="ml-10 mb-3 max-h-30px"
              onClick={() => {}}
            >
              Search
            </Button>
          )}

          {isLoading ? (<div className="ml-auto"><Spinner animation="border" variant="primary" /></div>) : (
            <div className="d-flex flex-column justify-content-end ml-auto">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {}}
              >
                EXPORT TO CSV
              </Button>
              <div>
                Number of Questions: 10000
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
};

const Questions = () => {

  return (
    <div>
      <Card>
        <CardContent className="border text-dark border-light-dark rounded">
          <div className="d-flex justify-content-between align-items-start">
            <h3>Questions</h3>
          </div>
        </CardContent>
      </Card>
      <FiltersSection />
      <QuestionsReportTable />
    </div>
  )
};

export default Questions;
