import React, {useEffect, useState} from "react"
import {useSelector, useDispatch, shallowEqual} from "react-redux";
import {
  Button,
  Card, CardContent
} from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { customFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Pagination } from '~/_metronic/_partials/controls';
import { actions } from '~/app/modules/User/actions';
import { sortCaret } from '~/_metronic/_helpers';
import {
  newDateFormatLong,
  onTableChangeHelper,
  usePaginationFilteringAndSorting,
  textFilterOnBlurHelper,
  useDidMount,
} from '~/app/helpers';
import { Link } from "react-router-dom";
import _ from 'lodash';
import {Modal, Spinner} from "react-bootstrap";
import {CloseOutlined} from "@material-ui/icons";
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const sortableColumn = {
  sort: true, sortCaret,
};

const providersOptions = [...enums.surveysProvidersValues.map((name) => ({
  value: name, label: name,
})), { value: 'all', label: 'All'}];

const simpleOptions = [
  { value: 'True', label: 'True' },
  { value: 'False', label: 'False' },
  { value: 'all', label: 'All' },
];

const surveyIdLink = (_id) => (
  <div>
    <Link
      target="_blank"
      to={`/surveys/${_id}`}
    >
      {_id}
    </Link>
  </div>
);

const columnsForQualifications = () => [{
  dataField: 'questionId', text: 'QUESTION ID',
  style: { width: '100px', textAlign: 'center' },
}, {
  dataField: 'text', text: 'QUESTION TEXT',
  style: { width: '200px',  minWidth: '000px', textAlign: 'center' },
}, {
  dataField: 'answerId', text: 'ANSWER ID',
  style: { width: '100px', textAlign: 'center' },
}, {
  dataField: 'answerText', text: 'ANSWER TEXT',
  style: { width: '150px',  minWidth: '150px', textAlign: 'center' },
}]

const QualificationsModal = ({ qualifications, shown, setShown, surveyId }) => {
  const columnsForTable = columnsForQualifications();

  return (
    <>
      <Modal
        scrollable
        centered
        size="md"
        show={shown}
        onHide={() => setShown(false)}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            User Qualifications
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={() => setShown(false)}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="mb-2 ml-1">
              {surveyId}
            </div>
            <BootstrapTable
              remote
              classes="table table-head-custom table-vertical-center overflow-hidden"
              headerClasses="table table-vertical-top"
              bootstrap4
              responsive
              keyField="questionId"
              columns={columnsForTable}
              data={qualifications}
              filter={ filterFactory() }
            >
            </BootstrapTable>
          </>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
}

const columns = (buttonForQualifications) => [{
  ...sortableColumn,
  dataField: '_id', text: 'Survey ID',
  filter: customFilter(),
  formatter: (id) => surveyIdLink(id),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "230px" }}),
  style: { width: '250px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'uniqueNumber', text: 'Unique Number',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "180px" }}),
  style: { width: '200px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'provider', text: 'Provider',
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: providersOptions,
    withoutEmptyOption: true,
  }),
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
}, {
  dataField: 'targetedDate', text: 'Date & Time of Targeting',
  formatter: (date) => newDateFormatLong(date, ' (PST)'),
  style: { width: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'mobileResponsive', text: 'Mobile Responsive',
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: simpleOptions,
    withoutEmptyOption: true,
  }),
  formatter: (mobileResponsive) => _.capitalize(`${!!mobileResponsive}`),
  style: { width: '100px', minWidth: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'mobileOnly', text: 'Mobile Only',
  filter: selectFilter({
    defaultValue: 'all',
    placeholder: ' ',
    style: { width: '100px' },
    options: simpleOptions,
    withoutEmptyOption: true,
  }),
  formatter: (mobileOnly) => _.capitalize(`${!!mobileOnly}`),
  style: { width: '100px', minWidth: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'rank', text: 'Rank',
  formatter: (rank) => rank?.toFixed(2) || '-',
  style: { width: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'preCalculatedRanks.rank17', text: 'Rank 17',
  formatter: (rank) => rank?.toFixed(2) || '-',
  style: { width: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'cpi', text: 'CPI',
  formatter: (cpi) => cpi ? `$${cpi.toFixed(2)}` : '-',
  style: { width: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'surveyQuestions', text: 'Qualifications',
  formatter: (_cell, row) => buttonForQualifications(row.surveyQuestions, row._id),
  style: { width: '150px', textAlign: 'left' },
}];

const TargetedSurveysTable = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'user', actions);
  const report = useSelector(state => state.user.report, shallowEqual);
  const [shown, setShown] = useState(false);
  const [selectedQualifications, setSelectedQualifications] = useState([]);
  const [selectedSurveyId, setSelectedSurveyId] = useState('');

  const handleButtonForQualificationsClick = (qualifications, surveyId) => () => {
    setSelectedQualifications(qualifications);
    setSelectedSurveyId(surveyId);
    setShown(true);
  }

  const buttonForQualifications = (qualifications, surveyId) => {
    return (
      <button
        type="button"
        className="btn btn-light-primary text-dark btn-sm"
        onClick={handleButtonForQualificationsClick(qualifications, surveyId)}
      >
        Show Qualifications
      </button>
    )
  }

  const didMount = useDidMount();

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: ['_id', 'uniqueNumber', 'provider', 'mobileResponsive', 'mobileOnly'],
    didMount
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = columns(buttonForQualifications);

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => (
          <Pagination isLoading={false} paginationProps={paginationProps}>
            <BootstrapTable
              remote
              classes="table table-head-custom table-vertical-center overflow-hidden"
              headerClasses="table table-vertical-top"
              bootstrap4
              keyField="_id"
              columns={reportColumns}
              data={report}
              filter={ filterFactory() }
              defaultSorted={[{
                dataField: 'preCalculatedRanks.rank17',
                order: enums.sortDirections.desc,
              }]}
              onTableChange={onTableChange}
              {...paginationTableProps}
            >
            </BootstrapTable>
          </Pagination>
        )}
      </PaginationProvider>
      <QualificationsModal
        setShown={setShown}
        shown={shown}
        qualifications={selectedQualifications}
        surveyId={selectedSurveyId}
      />
    </>
  );
};

const TargetedSurveys = ({ userId }) => {
  const storedUserId = useSelector(state => state.user.userId);
  const isLoadingCSV = useSelector(state => state.user.isLoadingCSV);
  const totalCount = useSelector(state => state.user.totalCount);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setUserId(userId));
    dispatch(actions.generateReport());
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if(!storedUserId) {
    return null;
  }

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="card-title font-weight-bolder text-dark mb-5">
            Targeted Surveys
          </h3>
          <div className="d-flesx flex-column justify-content-end">
            <Button
              color="secondary"
              variant="contained"
              // TODO
              onClick={() => dispatch(actions.generateReportForTargetedSurveysAsCsv())}
            >
              {isLoadingCSV
                ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )
                : 'Export To CSV'}
            </Button>
            <div>Count of Surveys: {totalCount}</div>
          </div>
        </div>
        <TargetedSurveysTable />
      </CardContent>
    </Card>
  )
};

export default TargetedSurveys;
