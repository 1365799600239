import React from 'react';
import cs from "classnames";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";

const TopInformationSection = ({ surveyInformation, children, alignChildren = 'end' }) => (
  <div className={cs("d-flex w-100", {
    'align-items-end': alignChildren === 'end',
    'align-items-start': alignChildren === 'start',
  })}>
    <div
      className={cs(
        "rounded w-100px min-h-60px font-weight-bolder font-size-h6 bg-light-success text-success d-flex align-items-center justify-content-center",
        {
          'bg-light-success text-success': surveyInformation.active,
          'bg-light-danger text-danger': !surveyInformation.active && !surveyInformation.forceInactive,
          'bg-light-warning text-warning': surveyInformation.forceInactive,
        }
      )}
    >
      {(!surveyInformation.active || surveyInformation.forceInactive) ? 'NOT ACTIVE' : 'ACTIVE'}
    </div>
    <div className="mb-3 ml-3">
      <div className="d-flex mb-3">
        <div>{surveyInformation._id}</div>
        <div className="ml-5">({surveyInformation.name})</div>
      </div>
      <div className="d-flex align-items-center">
        <div className="mr-10">
              <span className="svg-icon h-20 mr-3">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Communication/Chat-check.svg")}
                />
              </span>
          {surveyInformation.uniqueNumber}
        </div>
        <div className="mr-10">
              <span className="svg-icon h-20 mr-3">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                />
              </span>
          {surveyInformation.provider}
        </div>
        <div className="mr-10">
              <span className="svg-icon h-20 mr-3">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Language.svg")}
                />
              </span>
          {surveyInformation.countryLanguageCode}
        </div>
        <div className="mr-10">
              <span className="svg-icon h-20 mr-3">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")}
                />
              </span>
          {surveyInformation.countryCode}
        </div>
      </div>
    </div>
    <div className="d-flex ml-auto">
      {children}
    </div>
  </div>
)

export default TopInformationSection;
