import React from "react";
import {NavLink, useParams} from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { tabs } from "../User"
import { addDays, format } from 'date-fns';
import enums from "~/app/helpers/enums";

const UserMenu = () => {
  const params = useParams();
  const { userId } = params;

  return (
    <div className="navi navi-bold navi-hover navi-active navi-link-rounded mt-3 mb-5">
      {tabs.map((tab) => {
        let linkTo = `/users/${userId}/${tab.view}`;
        let target = '';
        switch (tab.view) {
          case enums.userTabs.clicksReport:
            linkTo = `/clicks/${userId}`;
            target = 'black';
            break;

          case enums.userTabs.mobileAppApiLogs:
            const startDate = addDays(new Date(), -7);
            const newData = {
              filters: { userId },
              startDate: format(startDate, enums.DATE_FORMAT),
              endDate: format(new Date(), enums.DATE_FORMAT),
            };
            localStorage.setItem('api-log', JSON.stringify(newData));
            target = 'black';
            linkTo = `/api-log`;
            break;

          default:
            break;
        }

        return (
          <div key={tab.view} className="navi-item mb-2">
            <NavLink
              target={target}
              to={linkTo}
              className="navi-link py-4"
              activeClassName="active border border-secondary"
            >
            <span className="navi-icon mr-2">
              <span className="svg-icon">
                <SVG
                  src={toAbsoluteUrl(
                    tab.icon
                  )}
                ></SVG>{" "}
              </span>
            </span>
              <span className="navi-text font-size-lg">
              {tab.name}
            </span>
            </NavLink>
          </div>
        )
      })}
    </div>
  );
}

export default UserMenu;
