import config from '~/config';
import fetch from '~/redux/fetch';

const allReviewsURL = '/user-reviews';

const getAllReviews = async () => {
  const response = await fetch(`${config.apiBaseUrl}${allReviewsURL}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const newReviewsURL = '/user-reviews';

const createReview = async (data) => {
  const response = await fetch(`${config.apiBaseUrl}${newReviewsURL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data }),
  });
  return response.json();
}

const getUpdateCurrentReviewURL = (userReviewId) => (
  `/user-reviews/${userReviewId}`
);

const updateReview = async ({ userReviewId, data }) => {
  const response = await fetch(`${config.apiBaseUrl}${getUpdateCurrentReviewURL(userReviewId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data }),
  });
  return response.json();
}

const uploadImageURL = '/user-reviews/upload-image';

const uploadImage = async (formData) => {
  const response = await fetch(`${config.apiBaseUrl}${uploadImageURL}`, {
    method: 'POST',
    body: formData,
  });
  return response.json();
}

export default {
  getAllReviews,
  createReview,
  updateReview,
  uploadImage,
};

