import React, {useEffect, useState} from 'react';
import { Modal } from "react-bootstrap";
import {CloseOutlined, PhotoAlbum} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Input} from "~/_metronic/_partials/controls";
import {Button} from "@material-ui/core";
import cs from "classnames";
import { newDateFormatLong } from "~/app/helpers";
import { actions } from '~/app/modules/Rewards/components/RewardsManagement/actions'

const PopupWithKeySelecting = ({ defaultKey = '', onChange, shown, setShown, title }) => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const [selectedKey, setSelectedKey] = useState(currentReward[defaultKey] || '');

  useEffect(() => {
    if (defaultKey && currentReward[defaultKey]) {
      dispatch(actions.findKeys({ key: currentReward[defaultKey], text: '' }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useDispatch();
  const foundKeys = useSelector((state) => state.rewards.foundKeys);
  const languageSchema = Yup.object().shape({
    key: Yup.string(),
    text: Yup.string(),
  });

  const handleOnKeyClick = (key) => () => {
    if (selectedKey === key) {
      setSelectedKey('');
    } else {
      setSelectedKey(key);
    }
  };

  const onSubmit = ({ key, text }) => {
    dispatch(actions.findKeys({ key, text }));
  };

  const formik = useFormik({
    initialValues: {
      key: '',
      text: '',
    },
    validationSchema: languageSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        onSubmit(values);
      } catch (error) {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (shown) {
      formik.resetForm();
    }
  }, [shown]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScreenshotClick = (screenshot) => (e) => {
    e.stopPropagation();
  }

  const handleConfirm = () => {
    onChange(selectedKey);
    dispatch(actions.cleanFoundKeys());
    setShown(false);
  };

  const handleClose = () => {
    dispatch(actions.cleanFoundKeys());
    setShown(false);
  };

  return (
    <Modal
      scrollable
      centered
      size="xl"
      show={shown}
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between align-items-start">
        <Modal.Title>
          {title}
        </Modal.Title>
        <div className="cursor-pointer pb-2 py-2" onClick={handleClose}><CloseOutlined /></div>
      </Modal.Header>
      <Modal.Body>
        <form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          className="form d-flex my-2 mx-2 flex-column m-0"
        >
          <div className="d-flex justify-content-end pr-30">
            <div className="w-100px mr-3 text-uppercase text-muted">Screenshot</div>
            <div className="w-120px mr-5 text-uppercase text-muted">Create Date</div>
            <div className="w-100px mr-3 text-uppercase text-muted">Translated Languages</div>
          </div>
          <div className="d-flex align-items-center w-100 mb-5 px-5 justify-content-between">
            <div className="d-flex align-items-center">
              <Input
                withFeedbackLabel={false}
                className="w-200px"
                type="text"
                name="key"
                placeholder="Enter key name"
                form={formik}
                field={formik?.getFieldProps('key')}
                {...formik?.getFieldProps("key")}
              />
              <Input
                withFeedbackLabel={false}
                className="ml-5 w-350px"
                type="text"
                name="text"
                placeholder="Enter key text"
                form={formik}
                field={formik?.getFieldProps('text')}
                {...formik?.getFieldProps("text")}
              />
            </div>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className="bg-success text-white"
            >
              Search
            </Button>
          </div>
          <div className="mb-3 border-bottom border-bottom-light-secondary" />
          <div className="min-h-40px">
            {foundKeys?.length ? foundKeys.map((item) => (
              <div
                key={item._id}
                className={cs("d-flex cursor-pointer p-3 align-items-center", {
                  'bg-light-primary': selectedKey === item.key
                })}
                onClick={handleOnKeyClick(item.key)}
              >
                <div className="w-200px ml-2 text-break">{item.key}</div>
                <div className="w-350px ml-5 text-break">{item.text}</div>
                <div className="w-100px ml-6 mr-4 d-flex justify-content-center">
                  <PhotoAlbum
                    onClick={handleScreenshotClick(item?.screenshot)}
                    className={item?.screenshot ? "text-primary" : 'text-muted'}
                  />
                </div>
                <div className="w-120px mr-2">{newDateFormatLong(item?.createdAt, '(PST)')}</div>
                <div className="w-100px mr-2 d-flex justify-content-center">{item?.languagesCount || 0}</div>
              </div>
            ) ) : ''}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className={cs('btn btn-sm ml-5', { 'btn-primary': selectedKey, 'btn-secondary': !selectedKey })}
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupWithKeySelecting;
