import { actionTypes } from '~/app/modules/Translations/components/Languages/actions';

const initialState = {
  report: [],
  isLoading: false,
  verificationIsLoading: false,
  verificationSuccess: false,
  verificationError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetLanguagesSuccess:
      return {
        ...state,
        report: action.payload,
        isLoading: false,
      };

    case actionTypes.GetLanguages:
      return {
        ...state,
        report: [],
        isLoading: true,
      };

    case actionTypes.GetLanguagesFailure:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.MassVerifyKeyTranslations:
      return {
        ...state,
        verificationIsLoading: true,
      };

    case actionTypes.MassVerifyKeyTranslationsSuccess:
      return {
        ...state,
        verificationIsLoading: false,
        verificationSuccess: true,
      };

    case actionTypes.MassVerifyKeyTranslationsFailure:
      return {
        ...state,
        verificationIsLoading: false,
        verificationError: true,
      };

    case actionTypes.CleanVerificationError:
      return {
        ...state,
        verificationError: false,
      };

    case actionTypes.CleanVerificationSuccess:
      return {
        ...state,
        verificationSuccess: false,
      };

    case actionTypes.SetSlackId:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SetSlackIdFailure:
    case actionTypes.SetSlackIdSuccess:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
