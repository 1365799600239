import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, TextareaAutosize } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CloseOutlined } from "@material-ui/icons";
import { Input, Select } from "~/_metronic/_partials/controls";
import { actions } from '~/app/modules/Reviews/actions';
import api from '~/app/modules/Reviews/api';
import enums from "~/app/helpers/enums";

const defaultInitialValues = {
  name: '',
  text: '',
  subject: '',
  score: 0,
  language: '',
  imageURL: '',
};

const CreateOrEditReviewModal = ({ initialValues, shown, setShown }) => {
  const dispatch = useDispatch();
  const ReviewSchema = Yup.object().shape({
    name: Yup.string()
      .required(),
    subject: Yup.string()
      .required(),
    text: Yup.string()
      .required(),
    language: Yup.string()
      .required(),
    score: Yup.number()
      .min(1, "Minimum score is 1").max(5, "Maximum score is 5")
      .required(),
    imageURL: Yup.string(),
  });

  const onSubmit = (values) => {
    const newValues = { ...values };
    if (!newValues.imageURL) {
      delete newValues?.imageURL;
    }
    if (initialValues._id) {
      dispatch(actions.updateReview(initialValues._id, newValues));
    } else {
      dispatch(actions.createReview(newValues));
    }
  }

  const formik = useFormik({
    initialValues: initialValues?._id ? initialValues : defaultInitialValues,
    validationSchema: ReviewSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        onSubmit(values);
        setShown(false);
        formik.resetForm(defaultInitialValues);
      } catch (error) {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (shown) {
      formik.resetForm();
    }
  }, [shown]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = () => {
    setShown(false);
    formik.resetForm(defaultInitialValues);
  }

  const onUploadImage = async (event) => {
    event.persist();
    let file = null;
    if (event.target.files.length) {
      file = new FormData();
      file.append('userImage', event.target.files[0]);
      const data = await api.uploadImage(file);
      formik.setFieldValue('imageURL', data.imageURL);
    }
  };

  return (
    <Modal
      scrollable
      centered
      size="xl"
      show={shown}
      onHide={handleCloseModal}
    >
      <Modal.Header className="d-flex justify-content-between align-items-start">
        <Modal.Title>
          {initialValues?._id ? 'Edit Review' : 'Create Review'}
        </Modal.Title>
        <div className="cursor-pointer pb-2 py-2" onClick={handleCloseModal}><CloseOutlined /></div>
      </Modal.Header>
      <Modal.Body className="px-0">
        <form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          className="form d-flex my-2 mx-2 flex-column m-0"
        >
          <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
            <div className="w-150px min-w-150px mr-5">Name</div>
            <Input
              withFeedbackLabel={false}
              type="text"
              name="name"
              placeholder="Enter review name"
              form={formik}
              field={formik?.getFieldProps('name')}
              {...formik?.getFieldProps("name")}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
            <div className="w-150px min-w-150px mr-5">Subject</div>
            <Input
              withFeedbackLabel={false}
              type="text"
              name="subject"
              placeholder="Enter review subject"
              form={formik}
              field={formik?.getFieldProps('subject')}
              {...formik?.getFieldProps("subject")}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
            <div className="w-150px min-w-150px mr-5">Text</div>
            <TextareaAutosize
              rowsMin={3}
              placeholder="Enter review text"
              className="w-100 border rounded"
              form={formik}
              field={formik?.getFieldProps('text')}
              {...formik?.getFieldProps("text")}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
            <div className="w-150px min-w-150px mr-5">User Image</div>
            <div className="d-flex flex-column w-100">
              <input
                type="file"
                onChange={onUploadImage}
              />
              {formik?.values?.imageURL ?
                <img className="max-w-100px max-h-100px" src={formik?.values?.imageURL} alt="avatar" />
                : ''
              }
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
            <div className="w-150px min-w-150px mr-5">Score</div>
            <Input
              withFeedbackLabel={false}
              type="number"
              name="score"
              placeholder="Enter review score"
              form={formik}
              field={formik?.getFieldProps('score')}
              {...formik?.getFieldProps("score")}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 mb-5 px-5">
            <div className="w-150px min-w-150px mr-5">Language</div>
            <Select
              placeholder="Select Language"
              name="language"
              className="bg-white border-secondary"
              variant="outlined"
              withFeedbackLabel={false}
              form={formik}
              field={formik?.getFieldProps('language')}
              {...formik?.getFieldProps("language")}
            >
              <option value="" />
              {enums.languagesWithCode.map((language) => (
                <option key={language?.code} value={language?.code}>
                  {language?.name} ({language?.code.toUpperCase()})
                </option>
              ))}
            </Select>
          </div>
          <div className="d-flex justify-content-end w-100 border-top pt-3">
            <Button
              color="default"
              variant="contained"
              className="mr-3 bg-light-primary text-primary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className="bg-primary text-white mr-5"
            >
              {initialValues?._id ? 'EDIT' : 'ADD' }
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateOrEditReviewModal;
