import { select, call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/BannedUsers/api';
import { actions, actionTypes } from '~/app/modules/BannedUsers/actions';

const selectForFetchReport = (state) => ({
  filters: state.bannedUsers.filters,
  pageNumber: state.bannedUsers.pageNumber,
  pageSize: state.bannedUsers.pageSize,
  sortBy: state.bannedUsers.sortBy,
  sortDirection: state.bannedUsers.sortDirection,
  bannedAtStart: state.bannedUsers.bannedAtStart,
  bannedAtEnd: state.bannedUsers.bannedAtEnd,
  useBannedAt: state.bannedUsers.useBannedAt,
});

function* requestReport() {
  try {
    const reportParams = yield select(selectForFetchReport);
    const results = yield call(api.fetchReport, reportParams);
    yield put(actions.fetchReportSuccess(results));
  } catch (error) {
    yield put(actions.fetchReportFailure(error.message));
  }
}

const selectForFetchReportAsCsv = (state) => ({
  filters: state.bannedUsers.filters,
  bannedAtStart: state.bannedUsers.bannedAtStart,
  bannedAtEnd: state.bannedUsers.bannedAtEnd,
  useBannedAt: state.bannedUsers.useBannedAt,
});

function* requestReportAsCsv() {
  try {
    const reportParams = yield select(selectForFetchReportAsCsv);
    yield call(api.fetchReportAsCsv, reportParams);
  } catch (error) {
    console.error(error);
  }
}

const selectForMassBan = (state) => ({ rows: state.bannedUsers.massBanRows });

function* massBan() {
  try {
    const params = yield select(selectForMassBan);
    const result = yield call(api.massBan, params);
    yield put(actions.massBanSuccess(result));
    yield put(actions.showSuccess('Mass Ban Results'));
    yield put(actions.generateReport());
  } catch (error) {
    yield put(actions.massBanFailure(error.message));
  }
}

const selectForMassUnban = (state) => ({ rows: state.bannedUsers.massUnbanRows });

function* massUnban() {
  try {
    const params = yield select(selectForMassUnban);
    const result = yield call(api.massUnban, params);
    yield put(actions.massUnbanSuccess(result));
    yield put(actions.showSuccess('Mass Unban Results'));
    yield put(actions.generateReport());
  } catch (error) {
    yield put(actions.massUnbanFailure(error.message));
  }
}

export default [
  takeLatest([
    actionTypes.GenerateReport,
    actionTypes.UpdateReport,
  ], requestReport),
  takeLatest(actionTypes.GenerateReportAsCsv, requestReportAsCsv),
  takeLatest(actionTypes.MassBan, massBan),
  takeLatest(actionTypes.MassUnban, massUnban),
];

