import config from '~/config';
import fetch from '~/redux/fetch';

const FETCH_FEATURES_URL = '/translations/features';
const FETCH_AVAILABLE_PARAMS_URL = '/translations/features/new';
const CREATE_FEATURE_URL = '/translations/features';
const UPDATE_FEATURE_URL = (featureId) => `/translations/features/${featureId}`;

const getFeatures = async () => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_FEATURES_URL}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const createFeature = async ({ productId, name, targetedLanguages }) => {
  const response = await fetch(`${config.apiBaseUrl}${CREATE_FEATURE_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ productId, name, targetedLanguages }),
  });
  return response.json();
};

const updateFeature = async ({ featureId, productId, name, targetedLanguages}) => {
  const response = await fetch(`${config.apiBaseUrl}${UPDATE_FEATURE_URL(featureId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ productId, name, targetedLanguages }),
  });
  return response.json();
}

const getAvailableParams = async () => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_AVAILABLE_PARAMS_URL}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

export default {
  getFeatures,
  createFeature,
  updateFeature,
  getAvailableParams,
};
