import config from '~/config';
import fetch from '~/redux/fetch';
import { dataURLtoFile } from "~/app/helpers";

const FETCH_FEATURE_URL = (featureId) => `/translations/features/${featureId}`;
const UPDATE_FEATURE_URL = (featureId) => `/translations/features/${featureId}`;
const FETCH_AVAILABLE_PARAMS_URL = '/translations/features/new';
const UPDATE_KEY_TEXT_URL = (keyId) => `/translations/keys/${keyId}`;
const CREATE_FEATURE_LANGUAGE_URL = '/translations/feature-languages';
const UPDATE_FEATURE_LANGUAGE_URL = (featureLanguageId) => `/translations/feature-languages/${featureLanguageId}`;
const FIND_KEYS_URL = '/translations/keys';
const CREATE_KEY_TRANSLATION_URL = '/translations/key-translations';
const SAVE_SCREENSHOT_URL = (keyId) => `/translations/keys/${keyId}/attachment`;
const UPDATE_KEY_TRANSLATION_TEXT = (keyTranslationId) => `/translations/key-translations/${keyTranslationId}`;
const DELETE_KEY_TRANSLATIONS_URL = '/translations/key-translations/batch-delete';

const getFeature = async (featureId) => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_FEATURE_URL(featureId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const updateFeature = async ({ featureId, productId, name, targetedLanguages, dueDate }) => {
  const response = await fetch(`${config.apiBaseUrl}${UPDATE_FEATURE_URL(featureId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ productId, name, targetedLanguages, dueDate }),
  });
  return response.json();
}

const updateKeyText = async ({ keyId, key, text }) => {
  const response = await fetch(`${config.apiBaseUrl}${UPDATE_KEY_TEXT_URL(keyId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ key, text }),
  });
  return response.json();
}

const getAvailableParams = async () => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_AVAILABLE_PARAMS_URL}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const createFeatureLanguage = async ({ featureId, languageId, translatorId }) => {
  const response = await fetch(`${config.apiBaseUrl}${CREATE_FEATURE_LANGUAGE_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ featureId, languageId, translatorId }),
  });
  return response.json();
}

const updateFeatureLanguage = async ({ featureLanguageId, languageId, translatorId }) => {
  const response = await fetch(`${config.apiBaseUrl}${UPDATE_FEATURE_LANGUAGE_URL(featureLanguageId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ languageId, translatorId }),
  });
  return response.json();
}

const findKeys = async ({ key, text, featureId }) => {
  const response = await fetch(`${config.apiBaseUrl}${FIND_KEYS_URL}?key=${key}&text=${text}&featureId=${featureId}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
}

const createKeyTranslation = async({ keyId, featureId }) => {
  const response = await fetch(`${config.apiBaseUrl}${CREATE_KEY_TRANSLATION_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ keyId, featureId }),
  });
  return response.json();
}

const saveKeyScreenshot = async({ keyId, attachment, fileName }) => {
  const data = new FormData();
  const file = dataURLtoFile(attachment, fileName);
  data.append("file", file);

  const response = await fetch(`${config.apiBaseUrl}${SAVE_SCREENSHOT_URL(keyId)}`, {
    method: 'POST',
    body: data,
  });
  return response.json();
}

const updateKeyTranslation = async({ keyTranslationId, text, status }) => {
  const response = await fetch(`${config.apiBaseUrl}${UPDATE_KEY_TRANSLATION_TEXT(keyTranslationId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text, status }),
  });
  return response.json();
};

const removeKeyTranslations = async({ keyTranslationIds }) => {
  const response = await fetch(`${config.apiBaseUrl}${DELETE_KEY_TRANSLATIONS_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ keyTranslationIds }),
  });
  return response.json();
}

export default {
  getFeature,
  updateFeature,
  updateKeyText,
  getAvailableParams,
  createFeatureLanguage,
  updateFeatureLanguage,
  findKeys,
  createKeyTranslation,
  saveKeyScreenshot,
  updateKeyTranslation,
  removeKeyTranslations,
};
