import config from '~/config';
import fetch from '~/redux/fetch';

const getBitcoinRecordsUrl = '/bitcoin-records';

const getBitcoinRecords = async () => {
  const response = await fetch(`${config.apiBaseUrl}${getBitcoinRecordsUrl}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
};

const getBitcoinBalanceUrl = `/reward-balances/bitcoin`;

const getBitcoinBalance = async () => {
  const response = await fetch(`${config.apiBaseUrl}${getBitcoinBalanceUrl}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
};

const createBitcoinRecordUrl = '/bitcoin-records';

const createBitcoinRecord = async (data) => {
  const response = await fetch(`${config.apiBaseUrl}${createBitcoinRecordUrl}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data }),
  });
  return response.json();
};

const removeBitcoinRecordUrl = (index) => `/bitcoin-records/${index}`;

const removeBitcoinRecord = async ({ index, data }) => {
  const response = await fetch(`${config.apiBaseUrl}${removeBitcoinRecordUrl(index)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data }),
  });
  return response.json({ data });
};

export default {
  getBitcoinRecords,
  createBitcoinRecord,
  removeBitcoinRecord,
  getBitcoinBalance,
};
