import { call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/Reviews/api';
import { actions, actionTypes } from '~/app/modules/Reviews/actions';

function* getAllReviews() {
  try {
    const results = yield call(api.getAllReviews);
    yield put(actions.getAllReviewsSuccess(results));
  } catch (error) {
    yield put(actions.getAllReviewsFailure(error.message));
  }
}

function* createReview(action) {
  try {
    const results = yield call(api.createReview, action.payload);
    yield put(actions.createReviewSuccess(results));
    yield put(actions.getAllReviews());
  } catch (error) {
    yield put(actions.createReviewFailure(error.message));
  }
}

function* updateReview(action) {
  try {
    const results = yield call(api.updateReview, action.payload);
    yield put(actions.updateReviewSuccess(results));
    yield put(actions.getAllReviews());
  } catch (error) {
    yield put(actions.updateReviewFailure(error.message));
  }
}

function* uploadImage(action) {
  try {
    const results = yield call(api.uploadImage, action.payload);
    yield put(actions.uploadImageSuccess(results));
  } catch (error) {
    yield put(actions.uploadImageFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.GetAllReviews, getAllReviews),
  takeLatest(actionTypes.CreateReview, createReview),
  takeLatest(actionTypes.UpdateReview, updateReview),
  takeLatest(actionTypes.UploadImage, uploadImage),
];

