import config from '~/config';
import fetch from '~/redux/fetch';

const FETCH_PRODUCTS_URL = '/translations/products';
const CREATE_PRODUCT_URL = '/translations/products';
const FETCH_PRODUCT_TRANSLATION_URL = (productId) => `/translations/${productId}`;
const CREATE_PRODUCT_TRANSLATION_URL = '/translations';
const UPDATE_PRODUCT_URL = (productId) => `/translations/products/${productId}`;

const getProducts = async () => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_PRODUCTS_URL}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
};

const createProduct = async (name) => {
  const response = await fetch(`${config.apiBaseUrl}${CREATE_PRODUCT_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name }),
  });
  return response.json();
};

const updateProduct = async ({ productId, name }) => {
  const response = await fetch(`${config.apiBaseUrl}${UPDATE_PRODUCT_URL(productId)}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name }),
  });
  return response.json();
};

const getProductTranslation = async (productId) => {
  const response = await fetch(`${config.apiBaseUrl}${FETCH_PRODUCT_TRANSLATION_URL(productId)}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
};

const createProductTranslation = async (productId) => {
  const response = await fetch(`${config.apiBaseUrl}${CREATE_PRODUCT_TRANSLATION_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ productId }),
  });
  return response.json();
}

export default {
  getProducts,
  createProduct,
  updateProduct,
  getProductTranslation,
  createProductTranslation,
};
