import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { CloseOutlined } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { actions } from "./actions";
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';

const LanguageCheckBox = ({ value, label, onClick }) => (
  <div onClick={onClick} className="d-flex min-w-200px align-items-center">
    {value ? <CheckBox className="cursor-pointer text-primary" /> : <CheckBoxOutlineBlank className="cursor-pointer text-primary" />}
    <div className="ml-3 cursor-pointer">{label}</div>
  </div>
)

const ModalForPublishing = ({ setShown, shown }) => {
  const dispatch = useDispatch();
  const languagesForFeature = useSelector((state) => state.feature.languagesForFeature);
  const selectedLanguages = useSelector((state) => state.feature.selectedLanguages);
  const [languageIDs, setLanguageIds] = useState(selectedLanguages);

  const handleCloseModal = () => {
    setShown(false);
    setLanguageIds(selectedLanguages);
  };

  const handleChangeSwitch = (id) => () => {
    if (languageIDs.includes(id)) {
      const newSelectedLanguages = languageIDs.filter((langId) => langId !== id);
      setLanguageIds(newSelectedLanguages);
    } else {
      setLanguageIds([...languageIDs, id]);
    }
  };

  const renderSwitchesForLanguages = (languages) => {
    if (!languages?.length) {
      return '';
    }

    return languages.map((l) => (
      <LanguageCheckBox
        key={String(l._id)}
        label={l.englishTitle}
        onClick={handleChangeSwitch(String(l._id))}
        value={languageIDs?.includes(String(l._id))}
      />
    ));
  };

  const handleClickOnAllCheckbox = () => {
    if (languageIDs.length === languagesForFeature?.length) {
      setLanguageIds([]);
    } else {
      setLanguageIds(languagesForFeature.map((l) => String(l._id)));
    }
  };

  const handleApply = () => {
    dispatch(actions.setSelectedLanguages(languageIDs));
    setShown(false);
  };

  return (
    <>
      <Modal
        scrollable
        centered
        size="md"
        show={shown}
        onHide={handleCloseModal}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            View Languages
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={handleCloseModal}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body>
          <div>Select languages to view:</div>
          <div className="d-flex flex-wrap">
            <LanguageCheckBox
              label="ALL"
              onClick={handleClickOnAllCheckbox}
              value={languageIDs?.length === languagesForFeature?.length}
            />
            {renderSwitchesForLanguages(languagesForFeature)}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button
              color="default"
              variant="contained"
              className="mr-3 bg-light-primary text-primary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleApply}
              className="bg-primary text-white mr-5"
            >
              Apply
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalForPublishing;
