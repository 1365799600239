import { call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/Translations/components/Keys/api';
import { actions, actionTypes } from '~/app/modules/Translations/components/Keys/actions';

function* getKeys() {
  try {
    const results = yield call(api.getKeys);
    yield put(actions.getKeysSuccess(results));
  } catch (error) {
    yield put(actions.getKeysFailure(error.message));
  }
}

function* getAvailableParams() {
  try {
    const results = yield call(api.getAvailableParams);
    yield put(actions.getAvailableParamsSuccess(results));
  } catch (error) {
    yield put(actions.getAvailableParamsFailure(error.message));
  }
}

function* createFeature(action) {
  try {
    const results = yield call(api.createFeature, action.payload);
    yield put(actions.createFeatureSuccess(results));
    yield put(actions.getKeys());
  } catch (error) {
    yield put(actions.createFeatureFailure(error.message));
  }
}

function* saveKeyScreenshot(action) {
  try {
    const { payload } = action;
    const results = yield call(api.saveKeyScreenshot, payload);
    yield put(actions.saveKeyScreenshotSuccess(results));
    yield put(actions.getKeys());
  } catch (error) {
    yield put(actions.saveKeyScreenshotFailure(error.message));
  }
}

function* updateKeyTranslation(action) {
  try {
    const { payload } = action;
    const results = yield call(api.updateKeyTranslation, payload);
    yield put(actions.updateKeyTranslationSuccess(results));
    yield put(actions.getKeys());
  } catch (error) {
    yield put(actions.updateKeyTranslationFailure(error.message));
  }
}

function* findKeys(action) {
  try {
    const { payload } = action;
    const results = yield call(api.findKeys, payload);
    yield put(actions.findKeysSuccess(results));
  } catch (error) {
    yield put(actions.findKeysFailure(error.message));
  }
}

function* updateLinkKey(action) {
  try {
    const { payload } = action;
    const results = yield call(api.updateLinkKey, payload);
    yield put(actions.updateLinkKeySuccess(results));
    yield put(actions.getKeys());
  } catch (error) {
    yield put(actions.updateLinkKeyFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.GetKeys, getKeys),
  takeLatest(actionTypes.GetAvailableParams, getAvailableParams),
  takeLatest(actionTypes.CreateFeature, createFeature),
  takeLatest(actionTypes.SaveKeyScreenshot, saveKeyScreenshot),
  takeLatest(actionTypes.UpdateKeyTranslation, updateKeyTranslation),
  takeLatest(actionTypes.FindKeys, findKeys),
  takeLatest(actionTypes.UpdateLinkKey, updateLinkKey),
];