import React, { useEffect } from 'react';
import {Card, CardContent, Chip, FormControl, Select as MUISelect, Button, TextField,} from "@material-ui/core";
import capitalize from "lodash/capitalize";
import { actions } from "~/app/modules/Rewards/components/RewardsManagement/actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Select, MenuItem } from "@material-ui/core";
import {Cancel} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import BalanceInfo from "~/app/modules/Rewards/components/RewardsManagement/BalanceInfo";
import About from "~/app/modules/Rewards/components/RewardsManagement/About";
import enums from "~/app/helpers/enums";

const useStyles = makeStyles((theme) => ({
  chipListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const selectMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

const Payouts = () => {
  const classes = useStyles();
  const currentReward = useSelector(state => state.rewards.currentReward);

  return (
    <div className="d-flex">
      <div className="d-flex flex-column w-200px">
        <div className="h-50px d-flex align-items-center mb-4">Min Price</div>
        <div className="h-50px d-flex align-items-center mb-4">Max Price</div>
        <div className="h-50px d-flex align-items-center mb-4">Min Payout (USD)</div>
        <div className="h-50px d-flex align-items-center mb-4">Max Payout (USD)</div>
        <div className="h-40px d-flex align-items-center mb-7">Prices</div>
        <div className="h-40px d-flex align-items-center mb-7">Payouts (USD)</div>
        <div className="h-50px d-flex align-items-center" >Local Currency</div>
      </div>
      <div className="d-flex flex-column w-500px">
        <div className="bg-light-secondary w-500px min-h-50px mb-4 p-3 border border-light-secondary rounded d-flex align-items-center">
          {currentReward?.payoutMinOriginal?.toFixed?.(2) || '-'} {currentReward?.currencyCode}
        </div>
        <div className="bg-light-secondary w-500px min-h-50px mb-4 p-3 border border-light-secondary rounded d-flex align-items-center">
          {currentReward?.payoutMaxOriginal?.toFixed?.(2) || '-'} {currentReward?.currencyCode}
        </div>
        <div className="bg-light-secondary w-500px min-h-50px mb-4 p-3 border border-light-secondary rounded d-flex align-items-center">
          {currentReward?.payoutMin?.toFixed?.(2) || '-'}
        </div>
        <div className="bg-light-secondary w-500px min-h-50px mb-4 p-3 border border-light-secondary rounded d-flex align-items-center">
          {currentReward?.payoutMax?.toFixed?.(2) || '-'}
        </div>
        <div className="h-40px d-flex align-items-center mb-7">
          <div className={classes.chipListContainer}>
            {currentReward?.payoutsOriginal?.map((value) => {
              return (
                <Chip
                  key={value}
                  label={value.toFixed(2)}
                  size="medium"
                  className={`${classes.chip} bg-primary text-white`}
                />
              )
            })}
          </div>
        </div>
        <div className="h-40px d-flex align-items-center mb-7">
          <div className={classes.chipListContainer}>
            {currentReward?.payouts?.map((value) => {
              return (
                <Chip
                  key={value}
                  label={value.toFixed(2)}
                  size="medium"
                  className={`${classes.chip} bg-primary text-white`}
                />
              )
            })}
          </div>
        </div>
        <div className="w-500px p-2 rounded border d-flex align-items-center min-h-50px mt-5 bg-light-secondary">
          {currentReward?.currencyCode === 'USD' ? '-' : currentReward?.currencyCode}
        </div>
      </div>
    </div>
  );
}

const CountriesField = () => {
  const classes = useStyles();
  const currentReward = useSelector(state => state.rewards.currentReward);
  const dispatch = useDispatch();

  const renderCountryOptions = (featureLanguages) => {
    if (featureLanguages?.length) {
      return featureLanguages.map((country) => (
        <MenuItem key={country.code.toUpperCase()} value={country.code.toUpperCase()}>
          {`${country.name}(${country.code.toUpperCase()})`}
        </MenuItem>
      ));
    }

    return null;
  }

  const handleChangeCountryType = (e) => {
    dispatch(actions.changeCurrentRewardFields({ countryListPredicate: e.target.value }));
  };

  const handleChangeLanguages = (event) => {
    const key = event.target.value;
    if(typeof key === 'string') {
      const newLanguages = currentReward?.countryList?.filter((language) => language !== key);
      dispatch(actions.changeCurrentRewardFields({ countryList: newLanguages }));
    } else {
      if (!key?.length) {
        dispatch(actions.changeCurrentRewardFields({ countryList: [] }));
      } else {
        dispatch(actions.changeCurrentRewardFields({ countryList: key }));
      }
    }
  }

  return (
    <div className="d-flex">
      <div className="d-flex flex-column w-200px mt-2">
        Countries
      </div>
      <div className="d-flex flex-column w-500px">
        <Select
          className="pt-10px pb-9px min-h-30px max-h-35px mb-5"
          id="status-select"
          value={currentReward?.countryListPredicate || ''}
          onChange={handleChangeCountryType}
          variant="outlined"
        >
          <MenuItem value="ignored">Worldwide</MenuItem>
          <MenuItem value="blacklist">Worldwide except</MenuItem>
          <MenuItem value="whitelist">Specific country(s)</MenuItem>
        </Select>
        {currentReward?.countryListPredicate !== 'ignored' && (<FormControl margin="normal" className="my-0">
          <MUISelect
            id="languages"
            multiple
            className="bg-white border-secondary"
            classes={{ root: 'p-0 min-h-40px' }}
            variant="outlined"
            MenuProps={selectMenuProps}
            value={currentReward?.countryList || []}
            onChange={handleChangeLanguages}
            renderValue={(values, index) => (
              <div key={index} className={classes.chipListContainer}>
                {values.map((value) => {
                  return (
                    <Chip
                      key={value}
                      label={value}
                      size="medium"
                      className={`${classes.chip} bg-primary text-white`}
                      onDelete={() => handleChangeLanguages({ target: { value } })}
                      deleteIcon={
                        <Cancel onMouseDown={event => event.stopPropagation()}/>
                      }
                    />
                  )
                })}
              </div>
            )}
          >
            {renderCountryOptions(enums.countriesWithCode)}
          </MUISelect>
        </FormControl>)}
      </div>
    </div>
  );
};

const StatusField = () => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const value = currentReward.blocked ? 'disabled' : 'enabled';
  const dispatch = useDispatch();

  const handleChangeStatus = (e) => {
    dispatch(actions.changeCurrentRewardFields({ blocked: e.target.value === 'disabled' }));
  };

  return (
    <div className="d-flex my-5">
      <div className="d-flex flex-column w-200px">
        Status
      </div>
      <div className="d-flex flex-column w-500px">
        <Select
          className="pt-10px pb-9px min-h-30px max-h-35px"
          id="status-select"
          value={value}
          onChange={handleChangeStatus}
          variant="outlined"
        >
          <MenuItem value={'disabled'}>Disabled</MenuItem>
          <MenuItem value={'enabled'}>Enabled</MenuItem>
        </Select>
        <div className="w-500px p-2 rounded border mt-5 bg-light-secondary">
          {(!currentReward?.currentBalance || currentReward?.currentBalance < 0.5)
            ? 'Unavailable' : 'Available'}
        </div>
      </div>
    </div>
  );
};

const RewardInfo = () => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(actions.changeCurrentRewardFields({ name: e.target.value }));
  };

  return (
    <>
      <h4 className="mt-5">Reward Info:</h4>
      <div className="d-flex w-700px align-items-center mb-3">
        <div className="w-200px">Presented name</div>
        <TextField
          className="w-500px p-0"
          variant="outlined"
          onChange={handleChange}
          value={currentReward?.name || ''} />
      </div>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div>Type</div>
        <div className="w-500px p-2 rounded border bg-light-secondary">
          {capitalize(currentReward?.service)}
        </div>
      </div>
      <CountriesField />
      <StatusField />
      <Payouts />
      <div className="pb-10 mb-10 w-700px border-bottom border-secondary" />
    </>
  );
};

const DetailsCard = () => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const history = useHistory();
  const dispatch = useDispatch();

  if (!currentReward?._id) {
    return '';
  }

  const handleCancelClick = () => {
    history.push('/rewards-management');
    dispatch(actions.getCurrentRewardSuccess({}));
  };

  const handleSaveChanges = () => {
    dispatch(actions.updateCurrentReward(currentReward));
  };

  return (
    <Card className="mb-5 max-w-1000px mx-auto">
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="d-flex justify-content-between align-items-center pb-3 border-bottom border-light-secondary mb5">
          <h3>{currentReward?.name}</h3>
          <div className="d-flex">
            <Button color="secondary" className="bg-light-primary mr-3" onClick={handleCancelClick}>Cancel</Button>
            <Button color="primary" className="bg-primary text-white" onClick={handleSaveChanges}>Save Changes</Button>
          </div>
        </div>
        <div className="d-flex flex-column w-700px mx-auto">
          <RewardInfo />
          <BalanceInfo />
          <About />
        </div>
      </CardContent>
    </Card>
  );
};

const RewardsDetails = () => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const needToLoadFundsHistory = useSelector(state => state.rewards.needToLoadFundsHistory);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(actions.getCurrentReward(params.rewardId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(needToLoadFundsHistory && currentReward.provider) {
      dispatch(actions.getFundsHistory(currentReward.provider));
    }
  }, [needToLoadFundsHistory, dispatch, currentReward.provider])

  return (
    <>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded">
          <div className="d-flex align-items-center">
            <h3>Rewards</h3>
            <div className="ml-3 text-muted">Rewards Management</div>
            {currentReward?.name ? <div className="ml-3 text-muted">| {currentReward?.name}</div> :''}
          </div>
        </CardContent>
      </Card>
      <DetailsCard />
    </>
  )
};

export default RewardsDetails;
