import _ from 'lodash';
import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Card,
  CardContent,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Chip,
  Button,
  Box,
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { actions as formActions } from '~/app/modules/ReportBuilder/actions';
import { actions as reportActions } from '~/app/modules/Report/actions';
import { Spinner } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from '~/_metronic/_helpers';
import enums from '~/app/helpers/enums';
import { isNumericColumn } from '~/app/helpers/consts';
import { Filter } from '~/app/modules/Filter';

const formatFloat = (f) => Number(f).toFixed(2);
const formatNumber = (n) => String(Number(formatFloat(n)));

const useStyles = makeStyles(theme => ({
  chipListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#671efb',
  },
}));

const selectMenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
};

const DateSelectors = () => {
  const startDate = useSelector(state => state.reportBuilder.startDate);
  const endDate = useSelector(state => state.reportBuilder.endDate);
  const dispatch = useDispatch();

  return (
    <>
      <Grid item xs={2}>
        <KeyboardDatePicker
          autoOk
          id="start-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="normal"
          label="Start Date"
          value={startDate}
          onChange={(_, v) => dispatch(formActions.setStartDate(v))}
        />
      </Grid>
      <Grid item xs={2}>
        <KeyboardDatePicker
          autoOk
          id="end-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="normal"
          label="End Date"
          value={endDate}
          onChange={(_, v) => dispatch(formActions.setEndDate(v))}
        />
      </Grid>
    </>
  );
};

const SortBySelectors = () => {
  const measures = useSelector(state => state.reportBuilder.measures);
  const dimensions = useSelector(state => state.reportBuilder.dimensions);
  const sortBy = useSelector(state => state.reportBuilder.sortBy);
  const sortDirection = useSelector(state => state.reportBuilder.sortDirection);
  const dispatch = useDispatch();
  const displayableSortBys = _.merge(
    _.pick(enums.displayableDimensions, dimensions),
    _.pick(enums.displayableMeasures, measures)
  );

  const sortByOptions = Object.entries(displayableSortBys).map(
    ([sortByOption, sortByOptionDisplay]) => (
      <MenuItem key={sortByOption} value={sortByOption}>
        {sortByOptionDisplay}
      </MenuItem>
    )
  );

  return (
    <>
      <Grid item xs={2}>
        <FormControl margin="normal" disabled={sortByOptions.length === 0}>
          <InputLabel id="sort-by-label">Sort By</InputLabel>
          <Select
            id="sort-by"
            labelId="sort-by-label"
            MenuProps={selectMenuProps}
            value={sortBy}
            onChange={e => dispatch(formActions.setSortBy(e.target.value))}
          >
            {sortByOptions}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl margin="normal">
          <InputLabel id="sort-direction-label">Direction</InputLabel>
          <Select
            id="sort-direction"
            labelId="sort-direction-label"
            MenuProps={selectMenuProps}
            value={sortDirection}
            onChange={e =>
              dispatch(formActions.setSortDirection(e.target.value))
            }
          >
            {Object.entries(enums.sortDirections).map(
              ([directionOption, directionOptionDisplay]) => (
                <MenuItem key={directionOption} value={directionOption}>
                  {directionOptionDisplay}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

const MultipleSelector = ({
  selected,
  setSelected,
  deleteKey,
  label,
  displayableValues
}) => {
  const classes = useStyles();

  const labelId = `${label}-label`;

  return (
    <Grid item xs={6}>
      <FormControl margin="normal">
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          id={label}
          labelId={labelId}
          multiple
          MenuProps={selectMenuProps}
          value={selected}
          onChange={e => setSelected(e.target.value)}
          renderValue={values => (
            <div className={classes.chipListContainer}>
              {values.map(value => (
                <Chip
                  key={value}
                  label={displayableValues[value]}
                  size="small"
                  className={classes.chip}
                  onDelete={() => deleteKey(value)}
                  deleteIcon={
                    <Cancel onMouseDown={event => event.stopPropagation()} />
                  }
                />
              ))}
            </div>
          )}
        >
          {Object.entries(displayableValues).map(([value, valueDisplay]) => (
            <MenuItem key={value} value={value}>
              {valueDisplay}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

const ShareViewButton = ({
  isLoadingSharedView,
  handleSharedView,
}) => {
  return (
      <Button
        color="default"
        variant="contained"
        onClick={handleSharedView}
        className="bg-info mr-5 text-white"
      >
        {isLoadingSharedView
          ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )
          : 'Share View'}
      </Button>
  );
};

const tryNormalizeReportColumnName = (name) => {
  if (name.startsWith('cohort')) {
    const withoutCohort = name.slice(6);
    const splitDay = withoutCohort.split('_')
    return `Cohort ${splitDay.join(' ')}`;
  }

  return name;
};

const joinROASDataSingleRow = (reportRow) => {
  const newRow = {};
  Object.keys(reportRow).forEach((key) => {
    if (key.startsWith('cohortROAS_USD')) {
      const keyParts = key.split('_');
      const dayPart = keyParts[keyParts.length - 1];
      const prcnt = formatNumber(reportRow[`cohortROAS_%_${dayPart}`]);
      const usd = formatNumber(reportRow[key]);
      newRow[`cohortROAS_${dayPart}`] = `${prcnt}% ($${usd})`;
    } else if (key.startsWith('externalROAS_USD')) {
      const prcnt = formatNumber(reportRow[`externalROAS_%`]);
      const usd = formatNumber(reportRow[key]);
      newRow['externalROAS'] = `${prcnt}% ($${usd})`;
    } else if (
      !key.startsWith('cohortROAS_%')
      && !key.startsWith('externalROAS_%')
    ) {
      newRow[key] = reportRow[key];
    }
  });
  return newRow;
};

const joinROASColumnsMeta = (report) => {
  const newReport = {...report};
  newReport.meta = report.meta.filter(
    (col) => !col.name.startsWith('cohortROAS_%') && !col.name.startsWith('externalROAS_%')
  ).map((col) => {
    if (
      col.name.startsWith('cohortROAS_USD')
      || col.name.startsWith('externalROAS_USD')
    ) {
      col.name = col.name.replace('_USD', '');
    }
    return col;
  });
  newReport.totals = joinROASDataSingleRow(report.totals);
  return newReport;
};

const joinROASColumnsData = (data) => {
  return data.map((d) => joinROASDataSingleRow(d));
};

const normalizeReport = (report) => {
  if (!report.totals) {
    const newData = [];
    report.data.forEach((d) => {
      const newD = _.omit(d, 'isTotalsRow');
      if (Number(d.isTotalsRow)) {
        report.totals = newD;
      } else {
        newData.push(newD);
      }
    });
    report.data = newData;

    report.meta = report.meta.filter((col) => col.name !== 'isTotalsRow');

    if (!report.totals) {
      report.totals = {};
      report.meta.forEach((col, idx) => {
        report.totals[col.name] = idx === 0 ? 'Totals' : '';
      });
    }
  }

  return joinROASColumnsMeta(report);
};

const formatFooterCell = (colName, totalsData) => {
  if (totalsData === 'Totals') { return totalsData; }
  if (isNumericColumn(colName)) {
    if (colName.startsWith('cohortROAS') || colName.startsWith('externalROAS')) {
      return totalsData;
    }
    return formatNumber(totalsData);
  }

  return totalsData;
};

const prepareColumns = (report, reportType) => {
  const displayableDimensions = reportType === 'cohort' ? enums.displayableCohortDimensions : enums.displayableDimensions;
  const displayableMeasures = reportType === 'cohort' ? enums.displayableCohortMeasures : enums.displayableMeasures;

  const normalizedReport = normalizeReport(report);
  const columns = normalizedReport.meta.map(col => ({
    dataField: col.name,
    text: displayableDimensions[col.name] || displayableMeasures[col.name] || tryNormalizeReportColumnName(col.name),
    sort: true,
    sortCaret,
    footer: formatFooterCell(col.name, normalizedReport.totals[col.name]),
  }));

  columns.forEach(col => {
    if (!isNumericColumn(col.dataField)) {
      col.sortFunc = (a, b, order) => {
        if (order === 'asc') {
          return String(a).localeCompare(String(b));
        }
        return String(b).localeCompare(String(a));
      };
      return;
    }

    col.sortFunc = (a, b, order) => {
      let cutValueBy = '%';
      if (col.dataField.endsWith('Bucket')) {
        cutValueBy = ' ';
      }
      const aa = Number(String(a).split(cutValueBy)[0]);
      const bb = Number(String(b).split(cutValueBy)[0]);
      if (order === 'desc') {
        return bb - aa;
      }
      return aa - bb;
    };
  });

  return columns;
}

const prepareData = (report) => {
  const data = joinROASColumnsData(report.data).map((d, idx) => {
    const newD = { ...d };

    Object.keys(d).forEach((key) => {
      if (key.startsWith('cohortNettoEarned')
        || key.startsWith('cohortActualNettoEarned')
        || key.startsWith('cohortBruttoEarned')
        || key.startsWith('cohortMediaSpend')
        || key.startsWith('cohortOrderPaid')
        || key === 'clickNettoEarned'
        || key === 'clickBruttoEarned'
        || key === 'mediaSpend'
        || key === 'cohortUsersCount'
        || key === 'cohortClicksCount'
        || key === 'cohortOrdersCount'
        || key === 'externalMediaSpend'
        || key === 'externalGrossRevenue') {
        newD[key] = formatNumber(d[key] || 0);
      }
      if (key.endsWith('Bucket')) {
        const bucketLower = d[key] || 0;
        let bucketUpper;
        if (key === 'userFraudScoreBucket') {
          bucketUpper = Number(bucketLower) + 0.04;
        }
        if (key === 'clickSurveyCPIBucket') {
          bucketUpper = Number(bucketLower) + 0.24;
        }
        newD[key] = `${formatFloat(bucketLower)} - ${formatFloat(bucketUpper)}`;
      }
    });

    if (Object.keys(d).includes('cohortRetention_D0')) {
      const hundredPercentValue = d.cohortRetention_D0;
      Object.keys(d).forEach((key) => {
        if (key.startsWith('cohortRetention_D')) {
          const columnValue = d[key];
          const ratio = hundredPercentValue > 0 ? columnValue / hundredPercentValue : 0;
          newD[key] = `${formatNumber(ratio * 100)}% (${columnValue})`;
        }
      });
    }

    return {
      idx: idx,
      ...newD
    };
  });

  return data;
};

const ReportViewer = () => {
  const isLoading = useSelector(state => state.report.isLoading);
  const report = useSelector(state => state.report.report, shallowEqual);
  const defaultSortBy = useSelector(state => state.report.sortBy);
  const defaultSortDirection = useSelector(state => state.report.sortDirection);
  const reportType = useSelector(state => state.reportBuilder.reportType);

  if (isLoading) {
    return <PleaseWaitMessage />;
  }

  if (!report || !report.data || !report.meta) {
    return null;
  }

  const columns = prepareColumns(report, reportType);
  const data = prepareData(report);

  return (
    <Box mt={2}>
      <Card>
        <CardContent>
          <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            keyField="idx"
            columns={columns}
            data={data}
            defaultSorted={[
              {
                dataField: defaultSortBy,
                order: defaultSortDirection
              }
            ]}
          >
            <NoRecordsFoundMessage entities={data} />
          </BootstrapTable>
        </CardContent>
      </Card>
    </Box>
  );
};

const ReportParamsForm = ({
  isLoadingSharedView,
  handleSharedView,
}) => {
  const measures = useSelector(state => state.reportBuilder.measures);
  const dimensions = useSelector(state => state.reportBuilder.dimensions);
  const filters = useSelector(state => state.reportBuilder.filters);

  const dispatch = useDispatch();

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4}>
          <DateSelectors />
          <SortBySelectors />
        </Grid>
        <Grid container spacing={4} alignItems="flex-end">
          <MultipleSelector
            label="Group By"
            selected={dimensions}
            setSelected={d => dispatch(formActions.setDimensions(d))}
            deleteKey={key => dispatch(formActions.deleteDimension(key))}
            displayableValues={enums.displayableDimensions}
          />
          <MultipleSelector
            label="Values"
            selected={measures}
            setSelected={m => dispatch(formActions.setMeasures(m))}
            deleteKey={key => dispatch(formActions.deleteMeasure(key))}
            displayableValues={enums.displayableMeasures}
          />
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={3} >
            <Filter
              setFilter={(val) => dispatch(formActions.setFilter(val))}
              deleteFilter={(val) => dispatch(formActions.deleteFilter(val))}
              value={filters}
              reportType={enums.reportBuilderType.ACTIVITY}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={measures.length === 0 || dimensions.length === 0}
              onClick={() => dispatch(reportActions.generateReport())}
            >
              Generate
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={measures.length === 0 || dimensions.length === 0}
              onClick={() => dispatch(reportActions.requestReportAsCsv())}
            >
              Export to CSV
            </Button>
          </Grid>
          <Grid item>
            <ShareViewButton
              isLoadingSharedView={isLoadingSharedView}
              handleSharedView={handleSharedView}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const ReportCohortParamsForm = ({
  isLoadingSharedView,
  handleSharedView,
}) => {
  const measures = useSelector(state => state.reportBuilder.measures);
  const dimensions = useSelector(state => state.reportBuilder.dimensions);
  const filters = useSelector(state => state.reportBuilder.filters);

  const dispatch = useDispatch();

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4}>
          <DateSelectors />
          <SortBySelectors />
        </Grid>
        <Grid container spacing={4} alignItems="flex-end">
          <MultipleSelector
            label="Group By"
            selected={dimensions}
            setSelected={d => dispatch(formActions.setDimensions(d))}
            deleteKey={key => dispatch(formActions.deleteDimension(key))}
            displayableValues={enums.displayableCohortDimensions}
          />
          <MultipleSelector
            label="Values"
            selected={measures}
            setSelected={m => dispatch(formActions.setMeasures(m))}
            deleteKey={key => dispatch(formActions.deleteMeasure(key))}
            displayableValues={enums.displayableCohortMeasures}
          />
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Filter
              setFilter={(val) => dispatch(formActions.setFilter(val))}
              deleteFilter={(val) => dispatch(formActions.deleteFilter(val))}
              value={filters}
              reportType={enums.reportBuilderType.COHORT}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={measures.length === 0 || dimensions.length === 0}
              onClick={() => dispatch(reportActions.generateReport())}
            >
              Generate
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={measures.length === 0 || dimensions.length === 0}
              onClick={() => dispatch(reportActions.requestReportAsCsv())}
            >
              Export to CSV
            </Button>
          </Grid>
          <Grid item>
            <ShareViewButton
              isLoadingSharedView={isLoadingSharedView}
              handleSharedView={handleSharedView}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const ReportMediaSpendParamsForm = ({
  isLoadingSharedView,
  handleSharedView,
}) => {
  const measures = useSelector(state => state.reportBuilder.measures);
  const dimensions = useSelector(state => state.reportBuilder.dimensions);
  const filters = useSelector(state => state.reportBuilder.filters);

  const dispatch = useDispatch();

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4}>
          <DateSelectors />
          <SortBySelectors />
        </Grid>
        <Grid container spacing={4} alignItems="flex-end">
          <MultipleSelector
            label="Group By"
            selected={dimensions}
            setSelected={d => dispatch(formActions.setDimensions(d))}
            deleteKey={key => dispatch(formActions.deleteDimension(key))}
            displayableValues={enums.displayableMediaSpendDimensions}
          />
          <MultipleSelector
            label="Values"
            selected={measures}
            setSelected={m => dispatch(formActions.setMeasures(m))}
            deleteKey={key => dispatch(formActions.deleteMeasure(key))}
            displayableValues={enums.displayableMediaSpendMeasures}
          />
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Filter
              setFilter={(val) => dispatch(formActions.setFilter(val))}
              deleteFilter={(val) => dispatch(formActions.deleteFilter(val))}
              value={filters}
              reportType={enums.reportBuilderType.MEDIA_SPEND}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={measures.length === 0 || dimensions.length === 0}
              onClick={() => dispatch(reportActions.generateReport())}
            >
              Generate
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={measures.length === 0 || dimensions.length === 0}
              onClick={() => dispatch(reportActions.requestReportAsCsv())}
            >
              Export to CSV
            </Button>
          </Grid>
          <Grid item>
            <ShareViewButton
              isLoadingSharedView={isLoadingSharedView}
              handleSharedView={handleSharedView}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          p={3}
          style={{ padding: "24px 0 0 0" }}
        >{children}</Box>
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `report-tab-${index}`,
    'aria-controls': `report-tabpanel-${index}`
  };
}

const ReportBuilder = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reportType = useSelector(state => state.reportBuilder.reportType);
  const startDate = useSelector(state => state.reportBuilder.startDate);
  const endDate = useSelector(state => state.reportBuilder.endDate);
  const measures = useSelector(state => state.reportBuilder.measures);
  const dimensions = useSelector(state => state.reportBuilder.dimensions);
  const sortBy = useSelector(state => state.reportBuilder.sortBy);
  const sortDirection = useSelector(state => state.reportBuilder.sortDirection);
  const filters = useSelector(state => state.reportBuilder.filters);
  const isLoading = useSelector(state => state.report.isLoading);
  const isLoadingSharedView = useSelector((state) => state.reportBuilder.isLoadingSharedView);
  const sharedLink = useSelector((state) => state.reportBuilder.sharedLink);
  const error = useSelector(state => state.report.error);
  const showError = useSelector(state => state.report.showError);

  useEffect(() => {
    if (sharedLink) {
      navigator.clipboard.writeText(sharedLink).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
      dispatch(formActions.clearSharedLink());
    }
  }, [sharedLink, dispatch])

  const handleSharedView = () => {
    const sentParams = {
      reportType,
      startDate,
      endDate,
      measures,
      dimensions,
      sortBy,
      sortDirection,
      filters,

      page: 'report-builder',
    };

    dispatch(formActions.createSharedLink(sentParams));
  }

  return (
    <>
      <Container>
        <Tabs value={reportType} onChange={(_, v) => dispatch(formActions.setReportType(v))}>
          <Tab label="Activity Report" value={enums.reportBuilderType.ACTIVITY} {...a11yProps(enums.reportBuilderType.ACTIVITY)} />
          <Tab label="Cohort" value={enums.reportBuilderType.COHORT} {...a11yProps(enums.reportBuilderType.COHORT)} />
          <Tab label="Media Spend" value={enums.reportBuilderType.MEDIA_SPEND} {...a11yProps(enums.reportBuilderType.MEDIA_SPEND)} />
        </Tabs>
        <TabPanel value={reportType} index={enums.reportBuilderType.ACTIVITY}>
          <ReportParamsForm
            isLoadingSharedView={isLoadingSharedView}
            handleSharedView={handleSharedView}
          />
        </TabPanel>
        <TabPanel value={reportType} index={enums.reportBuilderType.COHORT}>
          <ReportCohortParamsForm
            isLoadingSharedView={isLoadingSharedView}
            handleSharedView={handleSharedView}
          />
        </TabPanel>
        <TabPanel value={reportType} index={enums.reportBuilderType.MEDIA_SPEND}>
          <ReportMediaSpendParamsForm
            isLoadingSharedView={isLoadingSharedView}
            handleSharedView={handleSharedView}
          />
        </TabPanel>
        <ReportViewer />
      </Container>
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={ showError } autoHideDuration={6000} onClose={() => dispatch(reportActions.hideError())}>
        <Alert onClose={() => dispatch(reportActions.hideError())} severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ReportBuilder;
