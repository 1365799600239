import { call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/Rewards/components/BitcoinRecords/api';
import { actions, actionTypes } from '~/app/modules/Rewards/components/BitcoinRecords/actions';


function* getBitcoinRecords() {
  try {
    const results = yield call(api.getBitcoinRecords);
    yield put(actions.getBitcoinRecordsSuccess(results));
  } catch (error) {
    yield put(actions.getBitcoinRecordsFailure(error.message));
  }
}

function* getBitcoinBalance() {
  try {
    const results = yield call(api.getBitcoinBalance);
    yield put(actions.getBitcoinBalanceSuccess(results));
  } catch (error) {
    yield put(actions.getBitcoinBalanceFailure(error.message));
  }
}

function* createBitcoinRecord(action) {
  try {
    const results = yield call(api.createBitcoinRecord, action.payload);
    yield put(actions.createBitcoinRecordSuccess(results));
    yield put(actions.getBitcoinRecords());
  } catch (error) {
    yield put(actions.createBitcoinRecordFailure(error.message));
  }
}

function* removeBitcoinRecord(action) {
  try {
    const results = yield call(api.removeBitcoinRecord, action.payload);
    yield put(actions.removeBitcoinRecordSuccess(results));
    yield put(actions.getBitcoinRecords());
  } catch (error) {
    yield put(actions.removeBitcoinRecordFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.GetBitcoinRecords, getBitcoinRecords),
  takeLatest(actionTypes.GetBitcoinBalance, getBitcoinBalance),
  takeLatest(actionTypes.CreateBitcoinRecord, createBitcoinRecord),
  takeLatest(actionTypes.RemoveBitcoinRecord, removeBitcoinRecord),
];

