import { call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/ReportBuilder/api';
import { actions, actionTypes } from '~/app/modules/ReportBuilder/actions';

function* createSharedLink(action) {
  try {
    const results = yield call(api.createSharedLink, action.payload);
    yield put(actions.createSharedLinkSuccess(results));
  } catch (error) {
    console.error(error);
    yield put(actions.createSharedLinkFailed(error));
  }
}

export default [
  takeLatest(actionTypes.CreateSharedLink, createSharedLink),
];

