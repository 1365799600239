import React from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import { sortCaret } from '~/_metronic/_helpers';
import {
  textFilterOnBlurHelper,
  newDateFormatLong,
  textFilterOnBlurHelperWithAutoCompleteMulti, usePaginationFilteringAndSorting, onTableChangeHelper, useDidMount
} from '~/app/helpers';
import { Pagination } from '~/_metronic/_partials/controls';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Card, CardContent } from "@material-ui/core";
import filterFactory, { customFilter, selectFilter } from "react-bootstrap-table2-filter";
import capitalize from "lodash/capitalize";
import { Link } from 'react-router-dom';
import {actions} from "~/app/modules/Rewards/components/RewardsManagement/actions";
import SVG from "react-inlinesvg";
import enums from "~/app/helpers/enums";

const sortableColumn = {
  sort: true, sortCaret,
};

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const renderCountryField = (countries) => {
  if (countries?.length > 1) {
    return <div>Multiple</div>
  }

  return <div>{countries?.[0] || ''}</div>
};

const typeOptions = [...enums.rewardsTypes.map((type) => ({
  value: type, label: capitalize(type),
})), { value: '', label: 'All' } ];

const balanceOptions = [{
  value: '', label: 'All'
}, {
  value: 'under', label: 'Under threshold',
}, {
  value: 'over', label: 'Over threshold'
}];

const renderBalance = (row) => {
  if (row.blocked) {
    return '-';
  }

  if (row.fallenBelowThreshold) {
    return (<div className="text-danger">{row.currency}{row.currentBalance}</div>);
  }

  return (<div>{row.currency}{row.currentBalance}</div>);
};

const renderStatus = (status) => {
  if (status === 'Available') {
    return (<div className="px-5 py-3 bg-light-success text-success rounded d-flex justify-content-center">{status}</div>);
  }

  if (status === 'Unavailable') {
    return (<div className="px-5 py-3 bg-light-danger text-danger rounded d-flex justify-content-center">{status}</div>);
  }

  return (<div className="px-5 py-3 bg-light-info text-info rounded d-flex justify-content-center">{status}</div>)
};

const statusOptions = [
  { value: '', label: 'All'},
  ...enums.rewardsCardStatus.map((status) => ({ value: status, label: status })),
];

const renderNameField = (row) => {
  const splitImageUrlByDot = row?.redeemCardLogo?.split('.');
  const imageType = splitImageUrlByDot?.[splitImageUrlByDot?.length - 1] || '';
  return (
    <div className="d-flex align-items-center">
      {imageType === 'svg' ? (
        <SVG className="w-40px h-40px mr-2" src={row?.redeemCardLogo} />
      ) : (<img
        src={row?.redeemCardLogo}
        alt=""
        className="w-40px h-40px mr-2"
      />)}
      <Link
        target="_blank"
        to={`/rewards-management/${row._id}`}
      >
        <div>{row?.name}</div>
      </Link>
    </div>
  );
};

const columns = () => [{
  ...sortableColumn,
  dataField: 'name', text: 'Reward Name',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "180px" }}),
  formatter: (name, row) => renderNameField(row),
  style: { width: '200px', textAlign: 'left' },
}, {
  dataField: 'cardId', text: 'Reward ID',
  style: { width: '130px', textAlign: 'left' },
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "150px" }}),
}, {
  ...sortableColumn,
  dataField: 'countryList', text: 'Country',
  filter: customFilter(),
  filterRenderer: (onFilter, column) => textFilterOnBlurHelperWithAutoCompleteMulti(
    onFilter,
    column,
    {style: { width: "180px" }},
    actions,
    'rewards'
  ),
  formatter: (countryList) => renderCountryField(countryList),
  style: { minWidth: '150px', width: '150px', paddingLeft: '20px', textAlign: 'left'},
  headerStyle: { paddingLeft: '20px' },
}, {
  ...sortableColumn,
  dataField: 'service', text: 'Type',
  style: { width: '120px', minWidth: '120px', textAlign: 'left' },
  filter: selectFilter({
    defaultValue: '',
    placeholder: ' ',
    style: { width: '100px' },
    options: typeOptions,
    withoutEmptyOption: false,
  }),
  formatter: (service) => capitalize(service),
}, {
  ...sortableColumn,
  dataField: 'balanceWithThreshold', text: 'Balance',
  filter: selectFilter({
    defaultValue: '',
    placeholder: ' ',
    style: { width: '100px' },
    options: balanceOptions,
    withoutEmptyOption: false,
  }),
  formatter: (balanceWithThreshold, row) => renderBalance(row),
  style: { width: '150px', minWidth: '150px', textAlign: 'left' },
},{
  ...sortableColumn,
  dataField: 'checkedAt', text: 'Last Balance Check',
  formatter: (checkedAt) => newDateFormatLong(checkedAt),
  style: { width: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  filter: selectFilter({
    defaultValue: '',
    placeholder: ' ',
    style: { width: '100px' },
    options: statusOptions,
    withoutEmptyOption: false,
  }),
  dataField: 'status', text: 'Status',
  formatter: (status) => renderStatus(status),
  style: {width: '120px', textAlign: 'left'},
}];

const RewardsManagementReport = () => {
  const report = useSelector(state => state.rewards.filterAndSortedData, shallowEqual);
  const data = useSelector(state => state.rewards.data, shallowEqual);
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'rewards', actions);
  const didMount = useDidMount();

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: ['name', 'cardId', 'countryList', 'service', 'balanceWithThreshold', 'status'],
    didMount,
  });
  const reportColumns = columns();

  const paginationOptions = {
    custom: true,
    totalSize: +(data.length),
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <Pagination isLoading={false} paginationProps={paginationProps}>
              <BootstrapTable
                classes="table table-head-custom table-vertical-center overflow-hidden"
                headerClasses="table table-vertical-top"
                bordered={false}
                bootstrap4
                defaultSorted={[{
                  dataField: sortBy || 'name',
                  order: sortDirection || enums.sortDirections.desc,
                }]}
                keyField="_id"
                columns={reportColumns}
                data={report}
                remote
                onTableChange={onTableChange}
                filter={ filterFactory() }
                {...paginationTableProps}
              />
            </Pagination>
          )}
        </PaginationProvider>
      </CardContent>
    </Card>
  );
};

export default RewardsManagementReport;
