import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('users');

actionTypes.GenerateReportAsCsv = 'users/generate-report-as-csv';
actionTypes.SetCreatedStartDate = 'users/set-created-start-date';
actionTypes.SetCreatedEndDate = 'users/set-created-end-date';
actionTypes.SetUseCreatedDate = 'users/set-use-created-date';
actionTypes.SetGenerateButtonClick = 'users/set-generate-button-click';

actions.generateReportAsCsv = () => ({
  type: actionTypes.GenerateReportAsCsv,
  payload: {}
});

actions.setCreatedStartDate = (date) => ({
  type: actionTypes.SetCreatedStartDate,
  payload: date
});

actions.setCreatedEndDate = (date) => ({
  type: actionTypes.SetCreatedEndDate,
  payload: date
});
actions.setUseCreatedDate = (value) => ({
  type: actionTypes.SetUseCreatedDate,
  payload: value
});

actions.setGenerateButtonClick = () => ({ type: actionTypes.SetGenerateButtonClick });

export {
  actionTypes,
  actions,
};
