import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {CloseButton, Modal} from "react-bootstrap";
import { actions } from '~/app/modules/QuestionsManagement/components/MappedQuestion/actions';
import _ from 'lodash';
import Alert from "@material-ui/lab/Alert";
import cs from 'classnames';

const ModalForPartMapping = ({ shown, setShown, setShowSavingAlert, cleanBlockChangeUrl }) => {
  const handleCloseModal = () => {
    setShown(false);
  };

  const closeSavingAlert = () => {
    setShowSavingAlert(false);
  };

  const handleSave = () => {
    setShowSavingAlert(true);
    setTimeout(closeSavingAlert, 3000);
    cleanBlockChangeUrl();
    setShown(false);
  };

  return (
    <Modal
      scrollable
      centered
      size="md"
      show={shown}
      onHide={handleCloseModal}
    >
      <Modal.Header className="font-weight-boldest">Partial Mapping</Modal.Header>
      <Modal.Body>
        <div>Are you sure you want to save only a partial mapping?</div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm ml-5"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const MappingAnswers = ({ index, setShown, setSelectedIndex, setMappingThatNeedSave, mappingThatNeedSave }) => {
  const dispatch = useDispatch();
  const questionForMapping = useSelector((state) => state.mappedQuestion.mapping)
    ?.[index - 1] || {};
  const selectedQuestion = useSelector((state) => state.mappedQuestion.selectedQuestion);
  const [showSavingAlert, setShowSavingAlert] = useState(false);
  const [partMapping, setPartMapping] = useState(false);
  const [showModalForPartMapping, setShowModalForPartMapping] = useState(false);

  const mappingKey = `mapping_${index}`;

  const handleOnSelectQuestion = () => {
    setShown(true);
    setSelectedIndex(index);
  };

  const cleanBlockChangeUrl = () => {
    if (mappingThatNeedSave.includes(index)) {
      setMappingThatNeedSave(mappingThatNeedSave.filter((item) => (item !== index)));
    }
  }

  const handleRemoveMappedAnswer = (answerIndex) => (e) => {
    e.stopPropagation();
    const newSelectedQuestion = _.cloneDeep(selectedQuestion);
    newSelectedQuestion.answers = newSelectedQuestion.answers.map((item, itemIndex) => {
      if (itemIndex === answerIndex) {

        const newData = { ...item };
        delete newData[mappingKey];
        return newData;
      }

      return item;
    });

    setPartMapping(true);

    dispatch(actions.setSelectedQuestion(newSelectedQuestion));
  };

  const closeSavingAlert = () => {
    setShowSavingAlert(false);
  }

  const handleSaveMapping = () => {
    if(!partMapping) {
      setShowSavingAlert(true);
      setTimeout(closeSavingAlert, 3000);
      cleanBlockChangeUrl();
    } else {
      setShowModalForPartMapping(true);
    }
  }

  const renderAnswersForMappingQuestion = (question) => {
    if (!question?.answers?.length){
      return <div />;
    }

    return (
       <div className="d-flex flex-column">
         <div className="border min-w-400px max-w-400px d-flex min-h-30px border-secondary">
           <div className="min-w-200px max-w-200px border-right border-secondary text-muted pl-2">ANSWERS</div>
           <div className="min-w-200px max-w-200px pl-2">MAPPED ANSWERS</div>
         </div>
         {question?.answers?.map((answer, answerIndex) => (
           <Draggable
             key={answerIndex}
             draggableId={question._id + answer._id}
             index={answerIndex}
           >
             {(provided) => (
                 <div
                   ref={provided.innerRef}
                   {...provided.dragHandleProps}
                   className="border min-w-400px max-w-400px d-flex min-h-30px border-secondary"
                 >
                   <div className="min-w-200px max-w-200px border-right border-secondary pl-2">{answer.text}</div>
                   <div className="min-w-200px max-w-200px px-2">
                     {answer?.[`mapping_${index}`] ? (
                       <div>
                         {answer?.[`mapping_${index}`]?.text}
                         <CloseButton onClick={handleRemoveMappedAnswer(answerIndex)} className="ml-5 cursor-pointer" />
                       </div>
                     ) : null}
                   </div>
                 </div>
               )
             }
           </Draggable>
         ))}
       </div>
    );
  };

  const renderAnswersForQuestion = (question) => {
    if (!question?.answers?.length){
      return <div />;
    }

    const mappingQuestionAnswerLength = selectedQuestion?.answers?.length || 0;

    return (
      <div className="d-flex flex-column">
        <div className="border min-w-200px max-w-200px d-flex min-h-30px border-secondary">
          <div className="min-w-200px max-w-200px text-muted pl-2">ANSWERS</div>
        </div>
        {question?.answers?.map((answer, answerIndex) => (
          <Draggable
            key={answerIndex + mappingQuestionAnswerLength}
            draggableId={question._id + answer._id}
            index={answerIndex + mappingQuestionAnswerLength}>
            {(provided) => {
              const mapped = selectedQuestion?.answers?.some((item) => (_.isEqual(item?.[mappingKey], answer)));
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className="border min-w-200px max-w-200px d-flex min-h-30px border-secondary"
                >
                  <div className={cs('min-w-200px max-w-200px pl-2', { 'bg-light-success': mapped })}>
                    {answer.text}
                  </div>
                </div>
              )}}
          </Draggable>
        ))}
      </div>
    );
  };

  const handleOnDragEnd = (e) => {
    const newSelectedQuestion = _.cloneDeep(selectedQuestion);
    let allMapped = true;
    newSelectedQuestion.answers = newSelectedQuestion.answers.map((answer, answerIndex) => {
      if (answerIndex === e.destination.index) {
        const mappingQuestionAnswerLength = selectedQuestion?.answers?.length || 0;
        const currentAnswer = questionForMapping.answers.find((item, itemIndex) => (
          itemIndex + mappingQuestionAnswerLength === e.source.index
        ));
        return { ...answer, [mappingKey]: currentAnswer };
      }
      if (!answer?.[mappingKey]) {
        allMapped = false;
      }

      return answer;
    })
    setPartMapping(!allMapped);
    if(!mappingThatNeedSave.includes(index)) {
      const newData = [...mappingThatNeedSave];
      newData.push(index);
      setMappingThatNeedSave(newData);
    }
    dispatch(actions.setSelectedQuestion(newSelectedQuestion));
  };

  return (
    <Card className="mb-3">
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="d-flex justify-content-between">
          <div className="font-weight-boldest">Mapping #{index}</div>
          <div className="d-flex flex-column align-items-end">
            <Button
              color="primary"
              variant="contained"
              className="mt-3 max-h-30px bg-light-primary text-primary"
              onClick={handleOnSelectQuestion}
            >
              SELECT QUESTION
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="mt-3 max-h-30px"
            >
              AUTO MAP
            </Button>
          </div>
        </div>
        <div className="d-flex w-100">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="d-flex justify-content-between w-100"
                >
                  <div className="max-w-50 min-w-50 ml-10">
                    <div>
                      <div className="font-weight-bold my-3">Mapping Question:</div>
                      <div>{selectedQuestion?.text}</div>
                    </div>
                    {renderAnswersForMappingQuestion(selectedQuestion)}
                  </div>
                  <div className="max-w-50 min-w-50 ml-10">
                    <div>
                      <div className="font-weight-bold my-3">To Question:</div>
                      <div>{questionForMapping?.text}</div>
                    </div>
                    {renderAnswersForQuestion(questionForMapping)}
                  </div>
                  {provided.placeholder}
                </div>)}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            variant="contained"
            className="mt-3 max-h-30px bg-light-primary text-primary"
            onClick={handleSaveMapping}
          >
            Save
          </Button>
        </div>
        <ModalForPartMapping
          shown={showModalForPartMapping}
          setShown={setShowModalForPartMapping}
          setShowSavingAlert={setShowSavingAlert}
          cleanBlockChangeUrl={cleanBlockChangeUrl}
        />
        {showSavingAlert && (
          <div
            style={{ position: "fixed", top: '30%', zIndex: '1000', left: '45%', transition: 'opacity 500ms linear' }}
          >
            <Alert onClose={() => setShowSavingAlert(false)} severity="success" variant="filled">
              Order Saved
            </Alert>
          </div>)}
      </CardContent>
    </Card>
  );
};

export default MappingAnswers;
