import React from 'react';
import {shallowEqual, useSelector} from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {customFilter, selectFilter} from 'react-bootstrap-table2-filter';
import { sortCaret } from '~/_metronic/_helpers';
import {
  textFilterOnBlurHelper,
} from '~/app/helpers';
import {Spinner} from "react-bootstrap";
import enums from "~/app/helpers/enums";

const sortableColumn = {
  sort: true, sortCaret,
};

const renderImg = (imageURL) => {
  if (!imageURL) {
    return '';
  }
  return (
    <div>
      <img className="max-w-100px max-h-100px" src={imageURL} alt="review avatar" />
    </div>
  );
};

const renderLanguage = (languageCode) => {
  if (!languageCode) {
    return '';
  }
  const currentLanguage = enums.languagesWithCode.find((lang) => lang.code === languageCode);

  if (!currentLanguage) {
    return '';
  }

  return (
    <div>
      {currentLanguage?.name} ({languageCode.toUpperCase()})
    </div>
  )
};

const handleOnEditClick = (setSelectedRow, setShown, row) => () => {
  setSelectedRow(row);
  setShown(true);
};

const calcLanguageOptions = (reviewLanguages) => {
  if (!reviewLanguages?.length) {
    return [
      ...enums.languagesWithCode.map((l) => ({ value: l.code, label: `${l.name} (${l.code})` })),
      { value: '', label: 'all' }
    ];
  }

  return [
    ...enums.languagesWithCode.filter((l) => reviewLanguages.includes(l.code))
      .map((l) => ({ value: l.code, label: `${l.name} (${l.code})` })),
    { value: '', label: 'all' }
  ];
}

const getColumns = (setSelectedRow, setShown, reviewLanguages) => [{
  dataField: 'imageURL', text: 'Image Url',
  formatter: (imageURL) => renderImg(imageURL),
  style: { width: '120px', minWidth: '100px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'language', text: 'Language',
  formatter: (language) => renderLanguage(language),
  filter: selectFilter({
    defaultValue: '',
    placeholder: ' ',
    style: { width: '100px' },
    options: calcLanguageOptions(reviewLanguages),
  }),
  style: { width: '120px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'name', text: 'Name',
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "180px" }}),
  style: { width: '200px', minWidth: '180px', textAlign: 'left' },
}, {
  ...sortableColumn,
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "120px" }}),
  dataField: 'subject', text: 'Subject',
  style: { width: '150px', textAlign: 'left' },
}, {
  ...sortableColumn,
  dataField: 'text', text: 'Text',
  style: { width: '500px', minWidth: '500px', textAlign: 'left' },
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "120px" }}),
}, {
  ...sortableColumn,
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelper(onFilter, column, {style: { width: "120px" }}),
  dataField: 'score', text: 'Score',
  style: { width: '80px', minWidth: '80px', textAlign: 'left' },
}, {
  dataField: '_id', text: 'Action',
  style: { width: '150px', textAlign: 'center' },
  formatter: (_id, row) => (
    <button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={handleOnEditClick(setSelectedRow, setShown, row)}>
      Edit
    </button>
  ),
}];

const ReviewsReport = ({ setSelectedRow, setShown }) => {
  const report = useSelector(state => state.userReviews.reviews, shallowEqual);
  const isLoading = useSelector(state => state.userReviews.isLoading);
  const reviewLanguages = useSelector(state => state.userReviews.reviewLanguages);

  return (isLoading ? (
    <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      ) : (
        <BootstrapTable
          classes="table table-head-custom table-vertical-center overflow-hidden"
          headerClasses="table table-vertical-top"
          bootstrap4
          keyField="_id"
          columns={getColumns(setSelectedRow, setShown, reviewLanguages)}
          data={report}
          filter={ filterFactory() }
        />
  ));
};

export default ReviewsReport;
