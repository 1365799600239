import React  from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Container, Card, CardContent, Grid, Button } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { actions } from '~/app/modules/VeriffLog/actions';
import VeriffLogReport from '~/app/modules/VeriffLog/VeriffLog.report';
import VeriffLogStats from '~/app/modules/VeriffLog/VeriffLog.stats';
import enums from "~/app/helpers/enums";

const DateSelectors = () => {
  const startDate = useSelector(state => state.veriffLog.startDate);
  const endDate = useSelector(state => state.veriffLog.endDate);
  const dispatch = useDispatch();

  return (
    <>
      <Grid item xs={2}>
        <KeyboardDatePicker
          autoOk
          id="start-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="none"
          label="Start Date"
          value={startDate}
          onChange={(_, v) => dispatch(actions.setStartDate(v))}
        />
      </Grid>
      <Grid item xs={2}>
        <KeyboardDatePicker
          autoOk
          id="end-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="none"
          label="End Date"
          value={endDate}
          onChange={(_, v) => dispatch(actions.setEndDate(v))}
        />
      </Grid>
    </>
  )
};

const ReportParamsForm = () => {
  const dispatch = useDispatch();

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4} alignItems="flex-end">
          <DateSelectors />
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => dispatch(actions.generateReport())}
            >
              Generate
            </Button>
          </Grid>
          <Grid item style={{ flexGrow: 1 }} />
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => dispatch(actions.generateReportAsCsv())}
            >
              Export To CSV
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const VeriffLog = () => (
  <Container>
    <VeriffLogStats />
    <ReportParamsForm />
    <VeriffLogReport />
  </Container>
);

export default VeriffLog;

