import React, { useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import PopupWithKeySelecting from "~/app/modules/Rewards/components/RewardsManagement/PopupWithKeySelecting";
import {actions} from "~/app/modules/Rewards/components/RewardsManagement/actions";
import { CloseOutlined } from "@material-ui/icons";

const TextHowTo = ({ setShown, setSelectedKey, setSelectedTitle }) => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const dispatch = useDispatch();

  const handleRemoveKey = () => {
    dispatch(actions.changeCurrentRewardFields({ whereIsRedeemableKey: '' }));
  }

  const handleClick = () => {
    setShown(true);
    setSelectedKey('whereIsRedeemableKey');
    setSelectedTitle('Choose key for *Where is Redeemable (Order Confirmation)*');
  };

  return (
    <div className="d-flex w-700px align-items-center mb-3">
      <div className="w-300px">Where is Redeemable (Order Confirmation)</div>
      <button
        type="button"
        className="btn w-100px h-40px btn-primary btn-md ml-5 mr-3"
        onClick={handleClick}
      >
        Add Key
      </button>
      <div className="w-300px h-40px p-2 rounded border bg-light-secondary d-flex align-items-center">
        {currentReward?.whereIsRedeemableKey || ''}
        {currentReward?.whereIsRedeemableKey && (
          <CloseOutlined className="ml-auto cursor-pointer" onClick={handleRemoveKey} />
        )}
      </div>
    </div>
  );
};

const TitleTermsField = ({ setShown, setSelectedKey, setSelectedTitle }) => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const dispatch = useDispatch();

  const handleRemoveKey = () => {
    dispatch(actions.changeCurrentRewardFields({ howToGetPaymentKey: '' }));
  }

  const handleClick = () => {
    setShown(true);
    setSelectedKey('howToGetPaymentKey');
    setSelectedTitle('Choose key for *How to Get Payment (Rewards Tab)*');
  };

  return (
    <div className="d-flex w-700px align-items-center mb-3">
      <div className="w-300px">How to Get Payment (Rewards Tab)</div>
      <button
        type="button"
        className="btn w-100px h-40px btn-primary btn-md ml-5 mr-3"
        onClick={handleClick}
      >
        Add Key
      </button>
      <div className="w-300px h-40px p-2 rounded border bg-light-secondary d-flex align-items-center">
        {currentReward?.howToGetPaymentKey || ''}
        {currentReward?.howToGetPaymentKey && (
          <CloseOutlined className="ml-auto cursor-pointer" onClick={handleRemoveKey} />
        )}
      </div>
    </div>
  );
};

const TextTermsField = ({ setShown, setSelectedKey, setSelectedTitle }) => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const dispatch = useDispatch();

  const handleRemoveKey = () => {
    dispatch(actions.changeCurrentRewardFields({ termsKey: '' }));
  }

  const handleClick = () => {
    setShown(true);
    setSelectedKey('termsKey');
    setSelectedTitle('Choose key for *Terms (Rewards Tab)*');
  };

  return (
    <div className="d-flex w-700px align-items-center mb-3">
      <div className="w-300px">Terms (Rewards Tab)</div>
      <button
        type="button"
        className="btn w-100px h-40px btn-primary btn-md ml-5 mr-3"
        onClick={handleClick}
      >
        Add Key
      </button>
      <div className="w-300px h-40px p-2 rounded border bg-light-secondary d-flex align-items-center">
        {currentReward?.termsKey || ''}
        {currentReward?.termsKey && (
          <CloseOutlined className="ml-auto cursor-pointer" onClick={handleRemoveKey} />
        )}
      </div>
    </div>
  );
};


const HowToRedeemEmail = ({ setShown, setSelectedKey, setSelectedTitle }) => {
  const currentReward = useSelector(state => state.rewards.currentReward);
  const dispatch = useDispatch();

  const handleRemoveKey = () => {
    dispatch(actions.changeCurrentRewardFields({ howToRedeemEmailKey: '' }));
  }

  const handleClick = () => {
    setShown(true);
    setSelectedKey('howToRedeemEmailKey');
    setSelectedTitle('Choose key for *How to Redeem (Email)*');
  };

  return (
    <div className="d-flex w-700px align-items-center mb-3">
      <div className="w-300px">How to Redeem (Email)</div>
      <button
        type="button"
        className="btn w-100px h-40px btn-primary btn-md ml-5 mr-3"
        onClick={handleClick}
      >
        Add Key
      </button>
      <div className="w-300px h-40px p-2 rounded border bg-light-secondary d-flex align-items-center">
        {currentReward?.howToRedeemEmailKey || ''}
        {currentReward?.howToRedeemEmailKey && (
          <CloseOutlined className="ml-auto cursor-pointer" onClick={handleRemoveKey} />
        )}
      </div>
    </div>
  );
};

const Texts = () => {
  const [shown, setShown] = useState(false);
  const [selectedKey, setSelectedKey] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');
  const dispatch = useDispatch();

  const handleChangeKey = (field) => (key) => {
    dispatch(actions.changeCurrentRewardFields({ [field]: key }));
  };

  return (
    <>
      <div className="pb-10 mb-10 w-700px border-bottom border-secondary" />
      <h4 className="mb-5">Texts:</h4>
      <TextHowTo setShown={setShown} setSelectedTitle={setSelectedTitle} setSelectedKey={setSelectedKey} />
      <TitleTermsField setShown={setShown} setSelectedTitle={setSelectedTitle} setSelectedKey={setSelectedKey} />
      <TextTermsField setShown={setShown} setSelectedTitle={setSelectedTitle} setSelectedKey={setSelectedKey} />
      <HowToRedeemEmail setShown={setShown} setSelectedTitle={setSelectedTitle} setSelectedKey={setSelectedKey} />
      {shown && (
        <PopupWithKeySelecting
          defaultKey={selectedKey}
          title={selectedTitle}
          shown={shown}
          setShown={setShown}
          onChange={handleChangeKey(selectedKey)}
        />
      )}
    </>
  );
};

export default Texts;
