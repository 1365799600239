export const userRegistrationPlatform = [
  {
    "name": "Desktop - Unknown",
    "label": "Desktop - Unknown",
    "value": "Desktop - Unknown"
  },
  {
    "name": "Desktop - Windows",
    "label": "Desktop - Windows",
    "value": "Desktop - Windows"
  },
  {
    "name": "Desktop - Ubuntu",
    "label": "Desktop - Ubuntu",
    "value": "Desktop - Ubuntu"
  },
  {
    "name": "Mobile Web - Android",
    "label": "Mobile Web - Android",
    "value": "Mobile Web - Android"
  },
  {
    "name": "Mobile App - Android",
    "label": "Mobile App - Android",
    "value": "Mobile App - Android"
  },
  {
    "name": "Mobile Web - iOS",
    "label": "Mobile Web - iOS",
    "value": "Mobile Web - iOS"
  },
  {
    "name": "Mobile App - iOS",
    "label": "Mobile App - iOS",
    "value": "Mobile App - iOS"
  },
  {
    "name": "Desktop - Chromium OS",
    "label": "Desktop - Chromium OS",
    "value": "Desktop - Chromium OS"
  },
  {
    "name": "Unknown - Unknown",
    "label": "Unknown - Unknown",
    "value": "Unknown - Unknown"
  },
  {
    "name": "Desktop - Mac OS",
    "label": "Desktop - Mac OS",
    "value": "Desktop - Mac OS"
  },
  {
    "name": "Desktop - Raspbian",
    "label": "Desktop - Raspbian",
    "value": "Desktop - Raspbian"
  },
  {
    "name": "Desktop - Linux",
    "label": "Desktop - Linux",
    "value": "Desktop - Linux"
  },
  {
    "name": "Mobile Web - Symbian",
    "label": "Mobile Web - Symbian",
    "value": "Mobile Web - Symbian"
  },
  {
    "name": "Mobile Web - Linux",
    "label": "Mobile Web - Linux",
    "value": "Mobile Web - Linux"
  },
  {
    "name": "Mobile App - undefined",
    "label": "Mobile App - undefined",
    "value": "Mobile App - undefined"
  },
  {
    "name": "Desktop - Android",
    "label": "Desktop - Android",
    "value": "Desktop - Android"
  },
  {
    "name": "Mobile Web - Tizen",
    "label": "Mobile Web - Tizen",
    "value": "Mobile Web - Tizen"
  },
  {
    "name": "Desktop - Tizen",
    "label": "Desktop - Tizen",
    "value": "Desktop - Tizen"
  },
  {
    "name": "Mobile App - Unknown",
    "label": "Mobile App - Unknown",
    "value": "Mobile App - Unknown"
  },
  {
    "name": "Desktop - PlayStation",
    "label": "Desktop - PlayStation",
    "value": "Desktop - PlayStation"
  },
  {
    "name": "Desktop - Fedora",
    "label": "Desktop - Fedora",
    "value": "Desktop - Fedora"
  },
  {
    "name": "Mobile Web - Windows Phone",
    "label": "Mobile Web - Windows Phone",
    "value": "Mobile Web - Windows Phone"
  },
  {
    "name": "Mobile Web - WEBOS",
    "label": "Mobile Web - WEBOS",
    "value": "Mobile Web - WEBOS"
  },
  {
    "name": "Mobile Web - Android-x86",
    "label": "Mobile Web - Android-x86",
    "value": "Mobile Web - Android-x86"
  },
  {
    "name": "Desktop - Xbox",
    "label": "Desktop - Xbox",
    "value": "Desktop - Xbox"
  },
  {
    "name": "Mobile Web - KAIOS",
    "label": "Mobile Web - KAIOS",
    "value": "Mobile Web - KAIOS"
  },
  {
    "name": "Mobile Web - HarmonyOS",
    "label": "Mobile Web - HarmonyOS",
    "value": "Mobile Web - HarmonyOS"
  },
  {
    "name": "Desktop - windows",
    "label": "Desktop - windows",
    "value": "Desktop - windows"
  },
  {
    "name": "Mobile Web - Windows",
    "label": "Mobile Web - Windows",
    "value": "Mobile Web - Windows"
  },
  {
    "name": "Desktop - Mint",
    "label": "Desktop - Mint",
    "value": "Desktop - Mint"
  },
  {
    "name": "Mobile Web - Mac OS",
    "label": "Mobile Web - Mac OS",
    "value": "Mobile Web - Mac OS"
  },
  {
    "name": "Mobile Web - BlackBerry",
    "label": "Mobile Web - BlackBerry",
    "value": "Mobile Web - BlackBerry"
  },
  {
    "name": "Desktop - FreeBSD",
    "label": "Desktop - FreeBSD",
    "value": "Desktop - FreeBSD"
  },
  {
    "name": "Mobile Web - Unknown",
    "label": "Mobile Web - Unknown",
    "value": "Mobile Web - Unknown"
  },
  {
    "name": "Desktop - Solaris",
    "label": "Desktop - Solaris",
    "value": "Desktop - Solaris"
  },
  {
    "name": "Mobile Web - Firefox OS",
    "label": "Mobile Web - Firefox OS",
    "value": "Mobile Web - Firefox OS"
  },
  {
    "name": "Desktop - openSUSE",
    "label": "Desktop - openSUSE",
    "value": "Desktop - openSUSE"
  },
  {
    "name": "Desktop - NetBSD",
    "label": "Desktop - NetBSD",
    "value": "Desktop - NetBSD"
  },
  {
    "name": "Desktop - iOS",
    "label": "Desktop - iOS",
    "value": "Desktop - iOS"
  },
  {
    "name": "Mobile App - macos",
    "label": "Mobile App - macos",
    "value": "Mobile App - macos"
  },
  {
    "name": "Desktop - OpenBSD",
    "label": "Desktop - OpenBSD",
    "value": "Desktop - OpenBSD"
  },
  {
    "name": "Desktop - Nintendo",
    "label": "Desktop - Nintendo",
    "value": "Desktop - Nintendo"
  },
  {
    "name": "Desktop - Gentoo",
    "label": "Desktop - Gentoo",
    "value": "Desktop - Gentoo"
  },
  {
    "name": "Desktop - OS/2",
    "label": "Desktop - OS/2",
    "value": "Desktop - OS/2"
  },
  {
    "name": "Desktop - Windows Phone",
    "label": "Desktop - Windows Phone",
    "value": "Desktop - Windows Phone"
  },
  {
    "name": "Desktop - tizen",
    "label": "Desktop - tizen",
    "value": "Desktop - tizen"
  },
  {
    "name": "Desktop - Chromecast",
    "label": "Desktop - Chromecast",
    "value": "Desktop - Chromecast"
  },
  {
    "name": "Desktop - Firefox OS",
    "label": "Desktop - Firefox OS",
    "value": "Desktop - Firefox OS"
  },
  {
    "name": "Desktop - Red Hat",
    "label": "Desktop - Red Hat",
    "value": "Desktop - Red Hat"
  },
  {
    "name": "Mobile Web - windows",
    "label": "Mobile Web - windows",
    "value": "Mobile Web - windows"
  },
  {
    "name": "Mobile Web - Windows Mobile",
    "label": "Mobile Web - Windows Mobile",
    "value": "Mobile Web - Windows Mobile"
  },
  {
    "name": "Desktop - GNU",
    "label": "Desktop - GNU",
    "value": "Desktop - GNU"
  },
  {
    "name": "Desktop - WIndows",
    "label": "Desktop - WIndows",
    "value": "Desktop - WIndows"
  }
];
