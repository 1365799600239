import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions} from "~/app/modules/Rewards/components/AmazonInventory/actions";
import { Card, CardContent, Button } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import AmazonInventoryReport from "~/app/modules/Rewards/components/AmazonInventory/AmazonInventory.report";
import BatchHistoryModal from "~/app/modules/Rewards/components/AmazonInventory/BatchHistoryModal";

const InventoryCard = () => {
  const amazonInventoryData = useSelector(state => state.amazonInventory.data);

  return (
    <Card className="mr-5 max-w-50 w-100">
      <CardContent className="pb-11 border text-dark border-light-dark rounded">
        <div className="pb-3 mb-3 w-100 border-bottom">
          <h4>Amazon.com Inventory</h4>
        </div>
        <div className="d-flex mt-7">
          <div className="mr-5">Total Cards:</div>
          <div>{amazonInventoryData?.totalCards}</div>
        </div>
        <div className="d-flex">
          <div className="mr-5">Total Redeemed Cards:</div>
          <div>{amazonInventoryData?.totalRedeemedCards}</div>
        </div>
        <div className="d-flex mb-3">
          <div className="mr-5">Total Not Redeemed Cards:</div>
          <div className="text-danger">
            {amazonInventoryData?.totalNotRedeemedCards}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const UploadCards = () => {
  const dispatch = useDispatch();
  const [shown, setShown] = useState(false);

  const formData = new FormData();
  const handleChange = (e) => {
    formData.append('csv', e.target.files[0]);
  };

  const handleUpload = () => {
    dispatch(actions.uploadCSV(formData));
  };

  const handleHistoryButtonClick = () => {
    setShown(true);
  };

  return (
    <Card className="max-w-50 w-100">
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="pb-3 mb-5 w-100 border-bottom d-flex align-items-center justify-content-between">
          <h4>Upload Amazon.com Cards</h4>
          <Button
            size="medium"
            className="bg-light-primary text-primary font-weight-bold"
            onClick={handleHistoryButtonClick}
          >
            History
          </Button>
        </div>
        <div className="w-100 d-flex justify-content-center mt-4 mb-3">
          <input type="file" onChange={handleChange} />
        </div>
        <div className="w-100 mb-5 d-flex justify-content-end">
          <Button className="mr-30 bg-primary text-white" onClick={handleUpload} size="medium">Upload</Button>
        </div>
        <BatchHistoryModal shown={shown} setShown={setShown} />
      </CardContent>
    </Card>
  );
};

const AmazonInventory = () => {
  const dispatch = useDispatch();
  const uploadError = useSelector(state => state.amazonInventory.error);

  useEffect(() => {
    if (uploadError) {
      setTimeout(() => {dispatch(actions.cleanUploadError())}, 5000);
    }
  }, [dispatch, uploadError]);

  useEffect(() => {
    dispatch(actions.getAmazonCards());
    dispatch(actions.getAmazonBatchesHistory());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderUploadResult = () => {
    if (uploadError?.error) {
      return (
        <Alert severity="error" onClose={() => dispatch(actions.cleanUploadError())}>
          <div className="font-size-lg">
            {`error: ${uploadError?.error}`}
          </div>
        </Alert>
      );
    }
    return (
      <Alert severity="warning" onClose={() => dispatch(actions.cleanUploadError())}>
        <div className="font-size-lg">
          {`Cards Added: ${uploadError.added}; Cards Skipped: ${uploadError?.skipped}`}
        </div>
      </Alert>
    );
  }

  return (
    <>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded">
          <div className="d-flex align-items-center">
            <h3>Rewards</h3>
            <div className="ml-3 text-muted">Amazon.com Inventory</div>
          </div>
        </CardContent>
      </Card>
      <div className="d-flex my-3 w-100">
        <InventoryCard />
        <UploadCards />
      </div>
      <AmazonInventoryReport />
      {(uploadError?.error || uploadError?.skipped) && (
        <div
          style={{
            position: 'fixed',
            zIndex: '1111',
            left: '0',
            top: '0',
            width: '100vw',
            height: '100vh',
        }}
          className="d-flex align-items-center justify-content-center"
        >
          {renderUploadResult()}
        </div>
      )}
    </>
  )
};

export default AmazonInventory;
