import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('high-reversal-rate-bans');

actionTypes.SetStartDate = 'high-reversal-rate-bans/set-start-date';
actionTypes.SetEndDate = 'high-reversal-rate-bans/set-end-date';

actionTypes.SetPagination = 'high-reversal-rate-bans/set-pagination';
actionTypes.SetSorting = 'high-reversal-rate-bans/set-sorting';
actionTypes.SetFilter = 'high-reversal-rate-bans/set-filter';

actionTypes.UpdateReport = 'high-reversal-rate-bans/update-report';
actionTypes.GenerateReport = 'high-reversal-rate-bans/generate-report';
actionTypes.FetchReportSuccess = 'high-reversal-rate-bans/fetch-report-success';
actionTypes.FetchReportError = 'high-reversal-rate-bans/fetch-report-failure';

actions.setStartDate = (startDate) => ({
    type: actionTypes.SetStartDate,
    payload: startDate
  });
actions.setEndDate = (endDate) => ({
    type: actionTypes.SetEndDate,
    payload: endDate
  });

actions.setPagination = ({ pageNumber, pageSize }) => ({
    type: actionTypes.SetPagination,
    payload: { pageNumber, pageSize }
  });
actions.setSorting = ({ sortBy, sortDirection }) => ({
    type: actionTypes.SetSorting,
    payload: { sortBy, sortDirection }
  });
actions.setFilter = (field, value) => ({
    type: actionTypes.SetFilter,
    payload: { field, value }
  });

// actions.paginateReport = () => ({
//     type: actionTypes.PaginateReport,
//     payload: {}
//   });
// actions.sortReport = () => ({
//     type: actionTypes.SortReport,
//     payload: {}
//   });
// actions.filterReport = () => ({
//     type: actionTypes.FilterReport,
//     payload: {}
//   });
actions.generateReport = () => ({
    type: actionTypes.GenerateReport,
    payload: {}
  });
actions.fetchReportSuccess = (results) => ({
    type: actionTypes.FetchReportSuccess,
    payload: results
  });
actions.fetchReportFailure = (errorMessage) => ({
    type: actionTypes.FetchReportError,
    payload: errorMessage
  });

export {
  actionTypes,
  actions,
};
