const actionTypes = {
  FetchFaceTecSettings: 'facetec-settings/fetch',
  FetchFaceTecSettingsSuccess: 'facetec-settings/fetch-success',
  FetchFaceTecSettingsFailure: 'facetec-settings/fetch-failure',

  ChangeFormField: 'facetec-settings/change-form-field',

  UpdateFaceTecSettings: 'facetec-settings/update',
  UpdateFaceTecSettingsSuccess: 'facetec-settings/update-success',
  UpdateFaceTecSettingsFailure: 'facetec-settings/update-failure',

  CloseErrorAlert: 'emailLimitsManagement/close-error-alert',
  CloseSuccessAlert: 'emailLimitsManagement/close-success-alert',
};

const actions = {
  fetchFaceTecSettings: () => ({ type: actionTypes.FetchFaceTecSettings }),
  fetchFaceTecSettingsSuccess: (data) => ({ type: actionTypes.FetchFaceTecSettingsSuccess, payload: data }),
  fetchFaceTecSettingsFailure: (error) => ({ type: actionTypes.FetchFaceTecSettingsFailure, payload: error }),

  changeFormField: (data) => ({ type: actionTypes.ChangeFormField, payload: data }),

  updateFaceTecSettings: (productionKeyText) => ({
    type: actionTypes.UpdateFaceTecSettings,
    payload: { productionKeyText },
  }),
  updateFaceTecSettingsSuccess: (data) => ({ type: actionTypes.UpdateFaceTecSettingsSuccess, payload: data }),
  updateFaceTecSettingsFailure: (error) => ({ type: actionTypes.UpdateFaceTecSettingsFailure, payload: error }),

  closeErrorAlert: () => ({ type: actionTypes.CloseErrorAlert }),
  closeSuccessAlert: () => ({ type: actionTypes.CloseSuccessAlert }),
};

export {
  actionTypes,
  actions,
};
