import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Card, CardContent, Button, TextField } from '@material-ui/core';

import { actions } from '~/app/modules/Miscellaneous/QAHelpers/actions';

const LeadTransactionForm = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.qaHelpers.userId);
  const amount = useSelector(state => state.qaHelpers.amount);

  const handleChangeUserId = (e) => {
    dispatch(actions.setUserId(e.target.value));
  };

  const handleChangeAmount = (e) => {
    dispatch(actions.setAmount(e.target.value));
  };

  const handleSubmit = () => {
    dispatch(actions.createWalletLeadTransaction());
  };

  return (
    <Card className="mb-5 max-w-1000px mx-auto">
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="d-flex justify-content-between align-items-center pb-3 border-bottom border-light-secondary mb5">
          <h3 className="mb-0">Create Wallet Lead Transaction</h3>
        </div>
        <div className="d-flex flex-column w-700px mx-auto pt-10">
          <div className="d-flex w-700px align-items-center mb-3">
            <div className="w-400px">User ID</div>
            <TextField
              className="w-100px p-0"
              variant="outlined"
              margin="dense"
              onChange={handleChangeUserId}
              value={userId} />
          </div>
        </div>
        <div className="d-flex flex-column w-700px mx-auto pt-10">
          <div className="d-flex w-700px align-items-center mb-3">
            <div className="w-400px">Amount</div>
            <TextField
              className="w-100px p-0"
              variant="outlined"
              margin="dense"
              onChange={handleChangeAmount}
              value={amount} />
          </div>
        </div>
        <Button className="btn btn-primary w-150px" onClick={handleSubmit}>Submit</Button>
      </CardContent>
    </Card>
  );
};

const QAHelpers = () => {
  return (
    <LeadTransactionForm />
  );
};

export default QAHelpers;
