import { select, call, put, takeLatest } from "redux-saga/effects";

import api from '~/app/modules/FaceTecSettings/api';
import { actions, actionTypes } from '~/app/modules/FaceTecSettings/actions';

function* fetchFaceTecSettings() {
  try {
    const results = yield call(api.fetchFaceTecSettings);
    yield put(actions.fetchFaceTecSettingsSuccess(results));
  } catch (error) {
    yield put(actions.fetchFaceTecSettingsFailure(error.message));
  }
}

function* updateFaceTecSettings() {
  try {
    const currentSettings = yield select((state) => state.faceTecSettings.faceTecSettings);
    const results = yield call(api.updateFaceTecSettings, currentSettings);
    yield put(actions.updateFaceTecSettingsSuccess(results));
  } catch (error) {
    yield put(actions.updateFaceTecSettingsFailure(error.message));
  }
}

export default [
  takeLatest(actionTypes.FetchFaceTecSettings, fetchFaceTecSettings),
  takeLatest(actionTypes.UpdateFaceTecSettings, updateFaceTecSettings),
];



