import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('profilerOrder');

actionTypes.SetLanguageCode = 'profilerOrder/set-language-code';
actionTypes.SetCountryCode = 'profilerOrder/set-country-code';
actionTypes.SetProvider = 'profilerOrder/set-provider';
actionTypes.SetReportValue = 'profilerOrder/set-report-value';
actionTypes.SetDefaultReportValue = 'profilerOrder/set-default-report-value';
actionTypes.SetReportData = 'profilerOrder/set-report-data';

actions.setLanguageCode = (languageCode) => ({ type: actionTypes.SetLanguageCode, payload: languageCode });
actions.setCountryCode = (countryCode) => ({ type: actionTypes.SetCountryCode, payload: countryCode });
actions.setProvider = (provider) => ({ type: actionTypes.SetProvider, payload: provider });
actions.setReportValue = (data) => ({ type: actionTypes.SetReportValue, payload: data });
actions.setDefaultReportValue = (data) => ({ type: actionTypes.SetDefaultReportValue, payload: data });
actions.setReportData = (data) => ({ type: actionTypes.SetReportData, payload: data });

export {
  actionTypes,
  actions,
};
