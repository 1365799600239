import _ from 'lodash';
import React from 'react';
import cs from 'classnames';

import enums from "~/app/helpers/enums";

const calculateVerificationStatus = (user) => {
  if (user?.facetecStatus) {
    if (user.facetecStatus === enums.faceTecStatuses.DUPES_FOUND) {
      return 'Denied';
    }
    if (user.facetecStatus === enums.faceTecStatuses.VERIFIED) {
      return 'Approved';
    }

    return _.capitalize(user.facetecStatus);
  }

  let veriffStatus = '';
  if (user?.lastIDVerificationStatus) {
    if (user?.lastIDVerificationStatus !== enums.veriffNegativeDecisionCodes.EXPIRED) {
      veriffStatus = enums.veriffStatusByCodes[user?.lastIDVerificationStatus];
    } else if (user.veriffLog?.length) {
      const foundVeriffStatus = user.veriffLog.find((veriff) => veriff.code === enums.veriffNegativeDecisionCodes.EXPIRED)
      if (foundVeriffStatus) {
        veriffStatus = _.capitalize(foundVeriffStatus?.status);
      }
    }
  } else if (user.veriffLog?.length) {
    const foundLastStatusIndex = _.findLastIndex(user.veriffLog, (veriff) => (!!veriff.status));
    if (foundLastStatusIndex > -1) {
      if (user.veriffLog[foundLastStatusIndex].status === 'created') {
        veriffStatus = 'Generated';
      } else {
        veriffStatus =_.capitalize(user.veriffLog[foundLastStatusIndex].status);
      }
    }
  }

  return veriffStatus;
};

const VerificationStatusField = ({ user }) => {
  const statusStr = calculateVerificationStatus(user);
  const cls = cs('font-weight-boldest', {
    'text-success': statusStr === enums.decisionCodes.APPROVED,
    'text-danger': statusStr === enums.decisionCodes.DECLINED,
  });

  return (
    <div className={cls}>
      {statusStr}
    </div>
  );
};

export default VerificationStatusField;
