import config from '~/config';
import fetch from '~/redux/fetch';

const FACETEC_SETTINGS_PATH = '/facetec-settings';

const fetchFaceTecSettings = async () => {
  const response = await fetch(`${config.apiBaseUrl}${FACETEC_SETTINGS_PATH}`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.json();
};

const updateFaceTecSettings = async (faceTecSettings) => {
  const response = await fetch(`${config.apiBaseUrl}${FACETEC_SETTINGS_PATH}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(faceTecSettings),
  });

  if (response.status !== 200) {
    throw new Error((await response.json()).error);
  }

  return response.json();
};

export default {
  fetchFaceTecSettings,
  updateFaceTecSettings,
};

