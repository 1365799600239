import React from 'react';
import { useSelector } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import { newDateFormatLong } from "../../../../helpers";

const TouchPoint = () => {
  const userPurchases = useSelector(state => state.user.userPurchases);

  const columns = [
    {  dataField: "date", text: "Date", formatter: (date) => newDateFormatLong(date,' (PST)') },
    { dataField: "internalClickId", text: "Internal Click ID" },
    {  dataField: "publisherClickId", text: "Publisher Click ID" },
    {  dataField: "publisherId", text: "Publisher ID"  },
    {  dataField: "pubId", text: "Pub ID"  },
    {  dataField: "subPubId", text: "Sub Pub ID" },
    { dataField: "leads",  text: "Leads" },
  ];

  const findEntities = () => {
    if(!userPurchases.length) {
      return [];
    }

    const entities = [];

    userPurchases.forEach((purchase) => {
      entities.push({
        date: purchase.createdAt,
        internalClickId: purchase._id,
        publisherClickId: purchase.purchaseId,
        publisherId: purchase.publisherId || '',
        pubId: purchase.pubId,
        subPubId: purchase.subPubId,
        leads: purchase.leadsCount,
      })
    })

    return entities;
  }


  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={true}
      classes="table table-vertical-top overflow-hidden"
      bootstrap4
      size="sm"
      responsive="lg"
      remote
      keyField="internalClickId"
      data={findEntities()}
      columns={columns}
    >
    </BootstrapTable>
  );
};

export default TouchPoint;
