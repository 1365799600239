import React, { useState, useEffect } from "react"
import { actions } from "~/app/modules/User/actions";
import { useSelector, useDispatch } from 'react-redux';
import {
  Card, CardContent, Grid, Tooltip, Collapse
} from "@material-ui/core";
import { FraudScore, slashesDateFormat } from '../../../helpers';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "~/_metronic/_helpers";
import { newDateFormat, newDateFormatLong } from "~/app/helpers";
import SwitchField from "./SwitchField";
import { markInUserSecurityMarks } from '~/app/helpers';
import { Link } from "react-router-dom";
import VerificationStatusField from "./VerificationStatusField";
import enums from "~/app/helpers/enums";

const findUserPlatform = (platform) => {
  const platformInLowerCase = platform.toLowerCase();
  if (platformInLowerCase.includes('macos') || platformInLowerCase.includes('ios') || platformInLowerCase.includes('mac os')) {
    if (platformInLowerCase.includes('mobile app')) {
      return (
        <SVG
          className="h-25px w-25px"
          src={toAbsoluteUrl('/media/svg/icons/Devices/iOS.svg')}
        />
      );
    }
    return (
      <SVG
        className="h-25px w-25px"
        src={toAbsoluteUrl('/media/svg/icons/Devices/iOS_W.svg')}
      />
    );
  }
  if (platformInLowerCase.includes('android')) {
    if (platformInLowerCase.includes('mobile app')) {
      return (
        <SVG
          className="h-25px w-25px"
          src={toAbsoluteUrl('/media/svg/icons/Devices/Android.svg')}
        />
      );
    }
    return (
      <SVG
        className="h-25px w-25px"
        src={toAbsoluteUrl('/media/svg/icons/Devices/Android_W.svg')}
      />
    );
  }
  if (platformInLowerCase.includes('linux')) {
    return (<i className="fab text-secondary fa-linux icon-md w-25px h-25px" />)
  }
  if (platformInLowerCase.includes('chrom')) {
    return (<i className="fab text-secondary fa-chrome icon-md w-25px h-25px" />)
  }
  if (platformInLowerCase.includes('ubuntu')) {
    return (<i className="fab text-secondary fa-ubuntu icon-md w-25px h-25px" />)
  }
  if (platformInLowerCase.includes('windows')) {
    return (<i className="fab text-secondary fa-windows icon-md w-25px h-25px" />)
  }
  if (platformInLowerCase.includes('fedora')) {
    return (<i className="fab text-secondary fa-fedora icon-md w-25px h-25px" />)
  }
  if (platformInLowerCase.includes('blackberry')) {
    return (<i className="fab text-secondary fa-blackberry icon-md w-25px h-25px" />)
  }
  if (platformInLowerCase.includes('playStation')) {
    return (<i className="fab text-secondary fa-playStation icon-md w-25px h-25px" />)
  }
  if (platformInLowerCase.includes('firefox')) {
    return (<i className="fab text-secondary fa-firefox icon-md w-25px h-25px" />)
  }

  if (platformInLowerCase.includes('mobile')) {
    return (
      <SVG
        className="h-25px w-25px"
        src={toAbsoluteUrl('/media/svg/icons/Devices/mobile.svg')}
      />
    );
  }

  return (
    <SVG
      className="h-25px w-25px"
      src={toAbsoluteUrl('/media/svg/icons/Devices/desktop.svg')}
    />
  );
};

const RegistrationDataCard = () => {
  const userInformation = useSelector(state => state.user.userInformation);
  const [openBrowserInfo, setOpenBrowserInfo] = useState(false);

  const handleSetOpenBrowserInfo = () => {
    setOpenBrowserInfo(!openBrowserInfo);
  }

  const renderRegistrationType  = () => {
    let iconUrl = '/media/svg/icons/Communication/Mail.svg';
    if(userInformation.user.facebook) {
      iconUrl = '/media/svg/logos/facebook-3.svg';
    }

    if(userInformation.user.google) {
      iconUrl = '/media/svg/logos/google-icon.svg';
    }

    if(userInformation.user.twitter) {
      iconUrl = '/media/svg/logos/twitter.svg';
    }

    if (userInformation.user.apple) {
      iconUrl = '/media/svg/logos/iOS.svg';
    }

    return (
      <span className="svg-icon svg-icon-black h-20">
        <SVG
          src={toAbsoluteUrl(iconUrl)}
        />
      </span>
    );
  }

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          Registration Data
        </h3>
        <div className="d-flex justify-content-between mb-7">
          <div className="mr-2">Publisher</div>
          <Tooltip arrow placement="top" title={userInformation.userPublisherChannel || 'Organic'}>
            <div>
              {userInformation.userPublisherName || '-'}
            </div>
          </Tooltip>
        </div>
        <div className="d-flex justify-content-between mb-6">
          <div className="min-w-50">Registration Type</div>
          <div>
            {renderRegistrationType()}
          </div>
        </div>
        <div className="d-flex justify-content-between mb-5">
          <div className="min-w-50">Device</div>
          <Tooltip arrow placement="top" title={userInformation?.appleDeviceType ? (
            <div className="font-size-lg line-height-md">{userInformation?.appleDeviceType}</div>
          ) : ''}
          >
            <span className="svg-icon svg-icon-primary h-20">
              <SVG
                src={toAbsoluteUrl(
                  `/media/svg/icons/Devices/${userInformation?.userDevice}.svg`
                )}
              />
            </span>
          </Tooltip>
        </div>
        <div className="d-flex justify-content-between mb-5">
          <div className="min-w-50">Registration IP</div>
          <div>
            {userInformation?.user?.ip || '-'}
          </div>
        </div>
        <div className="d-flex justify-content-between mb-5">
          <div className="mr-2 min-w-50">Main Browser Language</div>
          <div>
            {userInformation?.user?.browserInfo?.languages?.[0] || '-'}
          </div>
        </div>
        <div className="d-flex justify-content-between mb-5">
          <div className="mr-2 min-w-50">All Browser Language</div>
          <div>
            {userInformation?.user?.browserInfo?.languages?.join(',') || userInformation.user.preferredLanguageCode}
          </div>
        </div>
        <div className="d-flex justify-content-between mb-5">
          <div className="mr-2 min-w-50">Full Browser Info</div>
          {userInformation.user?.browserInfo ? (
              <div onClick={handleSetOpenBrowserInfo} className="cursor-pointer">
                {openBrowserInfo ? <ExpandLess /> : <ExpandMore />}
              </div>
            )
            : '-'
          }
        </div>
        <Collapse in={openBrowserInfo} timeout="auto" unmountOnExit>
          <div className="bg-secondary d-flex py-2 rounded mb-3 pl-5 font-weight-bold">
            <div className="mr-2 min-w-50">PARAMETER</div>
            <div>VALUE</div>
          </div>
          <div className="d-flex mb-2">
            <div className="mr-2 min-w-50">App Code Name</div>
            <div>
              {userInformation.user?.browserInfo?.appCodeName || '-'}
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="mr-2 min-w-50">App Name</div>
            <div>
              {userInformation.user?.browserInfo?.appName  || '-'}
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="mr-2 min-w-50">App Version</div>
            <div>
              {userInformation.user?.browserInfo?.appVersion || '-'}
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="mr-2 min-w-50">Cookie Enabled</div>
            <div>
              {`${!!userInformation.user?.browserInfo?.cookieEnabled}`}
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="mr-2 min-w-50">Platform</div>
            <div>
              {userInformation.user?.browserInfo?.platform || '-'}
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="mr-2 min-w-50">Javascript Enabled</div>
            <div>
              {`${!!userInformation.user?.browserInfo?.javaEnabled}`}
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="mr-2 min-w-50">Inner Height</div>
            <div>
              {userInformation.user?.browserInfo?.innerHeight || '-'}
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="mr-2 min-w-50">Inner Width</div>
            <div>
              {userInformation.user?.browserInfo?.innerWidth || '-'}
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="mr-2 min-w-50">Screen Width</div>
            <div>
              {userInformation.user?.browserInfo?.screenWidth || '-'}
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="mr-2 min-w-50">Screen Height</div>
            <div>
              {userInformation.user?.browserInfo?.screenHeight || '-'}
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="mr-2 min-w-50">User Agent</div>
            <div>
              {userInformation.user?.browserInfo?.userAgent || '-'}
            </div>
          </div>
        </Collapse>
      </CardContent>
    </Card>
  );
}

const UserDataCard = () => {
  const userInformation = useSelector(state => state.user.userInformation);
  const userOrders = useSelector(state => state.user.userOrders);
  const platformType = userInformation?.user?.platform?.type || '';

  const findUserDevices = () => {
    const deviceType = () => {
      if (platformType.toLowerCase().includes('mobile')) {
        return 'mobile';
      }

      return 'desktop';
    };

    return (
      <div>
        <span className="svg-icon svg-icon-primary svg-icon-2x">
          <SVG
            src={toAbsoluteUrl(`/media/svg/icons/Devices/${deviceType()}.svg`)}
          />
        </span>
      </div>
    )
  }

  const findUserPaymentMethods = () => {
    const paymentMethods = [];

    if(userOrders.length) {
      userOrders.forEach((order) => {
        if(order?.card?.service && !paymentMethods.includes(order?.card?.service)) {
          paymentMethods.push(order.card.service);
        }
      })
    }

    if(!paymentMethods.length) {
      return '-'
    }

    return (
      <div>
        {paymentMethods.map((paymentMethod) => (
          <SVG
            key={paymentMethod}
            className="h-25px w-25px ml-1"
            src={toAbsoluteUrl(enums.paymentMethodIcons[paymentMethod])}
          />
        ))}
      </div>
    )
  }

  const checkThatUserHasDeviceID = () => {
    let deviceID = false;
    if (userInformation.user?.mobileInfoLog?.length) {
      userInformation.user.mobileInfoLog.forEach((log) => {
        if(!deviceID && (log.gaid || log.idfa)) {
          deviceID = true;
        }
      });
    }

    return deviceID;
  }

  return (
    <Card className="mt-3">
      <CardContent className="border text-dark border-light-dark rounded">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          User Data
        </h3>
        <div className="d-flex justify-content-between mb-3">
          <div className="mr-2">Devices</div>
          {findUserDevices()}
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="mr-2">Platforms</div>
          {findUserPlatform(platformType)}
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="mr-2">Payment Methods</div>
          {findUserPaymentMethods()}
        </div>
        <SwitchField label="Mobile App" status={!!userInformation.user?.hasMobileApp} />
        <SwitchField label="Device ID" status={checkThatUserHasDeviceID()} />
        <SwitchField label="Mobile App Notifications" status={!!userInformation?.user?.settings?.mobileAppPush} />
      </CardContent>
    </Card>
    );
}

const UserActivity = () => {
  const userClicks = useSelector(state => state.user.userClicks);
  const userOrders = useSelector(state => state.user.userOrders);
  const userStreakData = useSelector(state => state.user.userInformation.user.streak);
  const userAtomBalance = useSelector(state => state.user.userInformation.atomBalance);

  const renderUserActivityValue = (value, type) => {
    let className = 'border rounded py-1 min-w-65px text-center font-weight-boldest';
    switch (type) {
      case 'danger':
        className += ' bg-light-danger border-danger text-danger';
        break;

      case 'primary':
        className += ' bg-light-primary border-primary text-primary';
        break;

      case 'warning':
        className += ' bg-light-warning border-warning text-warning';
        break;

      case 'success':
        className += ' bg-light-success border-success text-success';
        break;

      default:
        className += ' bg-light-info border-info text-info';
        break;

    }

    return (
      <div className={className}>
        {value}
      </div>
    )
  }

  const findClicksCountByType = (type) => {
    if(!userClicks.length) {
      return []
    }

    if ([enums.clickStatus.SUCCESS, enums.clickStatus.REVERSAL, enums.clickStatus.DQ, enums.clickStatus.PRELIMINARY_FAILED].includes(type)) {
      return userClicks.filter((click) => click.status === type).sort((a, b) => {
        const dateA = new Date(a.clickDate);
        const dateB = new Date(b.clickDate);
        return dateB - dateA;
      }) || [];
    }
    return userClicks;
  }

  const leadClicks = findClicksCountByType(enums.clickStatus.SUCCESS);
  const reversalClicks = findClicksCountByType(enums.clickStatus.REVERSAL);
  const tooltipForOrdersField = () => {
    if(!userOrders.length) {
      return '';
    }

    const status = userOrders[0].status;

    let statusClassName = 'rounded py-1 px-1 text-center';

    if ([enums.orderStatus.PAID].includes(status)) {
      statusClassName += ' text-success bg-light-success';
    } else if ([enums.orderStatus.PAYOUT_PENDING, enums.orderStatus.CREATED, enums.orderStatus.PENDING_ID_CHECK, enums.orderStatus.SECURITY_PENDING].includes(status)) {
      statusClassName += ' text-warning bg-light-warning';
    } else if ([enums.orderStatus.SECURITY_REJECTED, enums.orderStatus.PAYOUT_REJECTED].includes(status)) {
      statusClassName += ' text-danger bg-light-danger';
    } else {
      statusClassName += ' text-info bg-light-info';
    }

    return (
      userOrders.length ? (
        <div className="font-size-lg line-height-md">
          Last Order Date {newDateFormat(userOrders[0].createdAt)}
          <div>Last Order Status
            <div className={statusClassName}>
              {userOrders[0].status}
            </div>
          </div>
        </div>
      ) : ''
    )
  }

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          User Activity
        </h3>
        <div className="d-flex justify-content-between mb-3">
          <div className="mr-2">Clicks</div>
          <Tooltip arrow placement="top" title={userClicks.length ? (
            <div className="font-size-lg line-height-md">Last Click Date {newDateFormat(userClicks[0].clickDate)}</div>
            ) : ''}
          >
            {renderUserActivityValue(userClicks.length, 'primary')}
          </Tooltip>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="mr-2">Leads</div>
          <Tooltip arrow placement="top" title={leadClicks.length ? (
            <div className="font-size-lg line-height-md">Last Lead Date {newDateFormat(leadClicks[0].clickDate)}</div>
          ) : ''}
          >
            {renderUserActivityValue(leadClicks.length + reversalClicks.length, 'success')}
          </Tooltip>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="mr-2">Reversals</div>
          {renderUserActivityValue(reversalClicks.length, 'danger')}
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="mr-2">DQ</div>
          {renderUserActivityValue(findClicksCountByType(enums.clickStatus.DQ).length, 'warning')}
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="mr-2">Preliminary</div>
          {renderUserActivityValue(findClicksCountByType(enums.clickStatus.PRELIMINARY_FAILED).length, 'warning')}
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="mr-2">Orders</div>
          <Tooltip arrow placement="top" title={tooltipForOrdersField()}>
            {renderUserActivityValue(userOrders.length)}
          </Tooltip>
        </div>
        <hr style={{
          marginTop: "2em",
          marginBottom: "2em",
          borderColor: "darkgrey",
        }} />
        <div className="d-flex justify-content-between mb-3">
          <div className="mr-2">Atoms</div>
          {renderUserActivityValue(userAtomBalance, 'success')}
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="mr-2">Current Streak</div>
          {renderUserActivityValue(userStreakData?.day || '-', 'warning')}
        </div>
      </CardContent>
    </Card>
  );
}

const EarningField = ({ icon, iconColor, iconBg, value, description }) => {
  const iconClassName = `svg-icon svg-icon-${iconColor}`
  return (
    <div className="d-flex min-w-50 min-h-50px mb-1">
      <div className={`svg-icon mw-39px mb-auto mx-h-39px svg-icon-3x rounded align-items-center justify-content-center bg-${iconBg}`}>
        <span className={iconClassName}>
          <SVG
            src={toAbsoluteUrl(icon)}
          />
        </span>
      </div>
      <div className="d-flex flex-column ml-1">
        <div className="font-weight-boldest">{value}</div>
        <div className="text-secondary">{description}</div>
      </div>
    </div>
  )
}

const EarningCard = () => {
  const userEarnings = useSelector(state => state.user.userEarnings);

  const paidForUser = userEarnings?.[0]?.[0]?.total?.toFixed(2) || 0;
  const userEarned = userEarnings?.[1]?.[0]?.total?.toFixed(2) || 0;
  const reversedFromUser = userEarnings?.[2]?.[0]?.total?.toFixed(2) || 0;
  const paidToUser = userEarnings?.[3]?.[0]?.total?.toFixed(2) || 0;
  const reversalRate = userEarnings?.[4] * 100 || 0;
  const netEarned = (userEarned - paidForUser - paidToUser).toFixed(2) || 0;
  const walletBalance = (userEarnings?.[5])?.toFixed(2) || 0;

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <h3 className="card-title font-weight-bolder text-dark mb-2">
          Earnings
        </h3>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between mt-1">
            <EarningField
              icon="/media/svg/icons/Shopping/Wallet.svg"
              iconColor="success"
              iconBg="light-success"
              value={`$ ${walletBalance}`}
              description="Wallet Balance"
            />
          </div>
          <div className="d-flex justify-content-between mt-1">
            <EarningField
              icon="/media/svg/icons/Shopping/Dollar.svg"
              iconColor="warning"
              iconBg="light-warning"
              value={`$ ${paidToUser}`}
              description="Paid to User"
            />
            <EarningField
              icon="/media/svg/icons/Shopping/Dollar.svg"
              iconColor="warning"
              iconBg="light-warning"
              value={`$ ${paidForUser}`}
              description="Paid for User"
            />
          </div>
          <div className="d-flex justify-content-between mt-1">
            <EarningField
              icon="/media/svg/icons/Text/Undo.svg"
              iconColor="danger"
              iconBg="light-danger"
              value={`$ ${reversedFromUser}`}
              description="Reversed"
            />
            <EarningField
              icon="/media/svg/icons/Shopping/Sale1.svg"
              iconColor="danger"
              iconBg="light-danger"
              value={`${reversalRate}%`}
              description="Reversal Rate"
            />
          </div>
          <div className="d-flex justify-content-between mt-1">
            <EarningField
              icon="/media/svg/icons/Shopping/Safe.svg"
              iconColor="primary"
              iconBg="light-primary"
              value={`$ ${userEarned}`}
              description="Brutto Earned"
            />
            <EarningField
              icon="/media/svg/icons/Shopping/Safe.svg"
              iconColor="primary"
              iconBg="light-primary"
              value={`$ ${netEarned}`}
              description="Net Earned"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const SecurityDataCard = () => {
  const userInformation = useSelector(state => state.user.userInformation);

  let fraudScoreClassName = 'font-weight-boldest';

  const renderSimilarUsers = () => {
    if(!userInformation.user?.storageUserIds || !userInformation.user.storageUserIds.length) {
      return '';
    }

    return userInformation.user.storageUserIds.filter((id) => id !== userInformation.user._id)
      .map((id) => (
        <div key={id}>
          <Link
            target="_blank"
            to={`/users/${id}`}
          >
            {id}
          </Link>
        </div>
      ))
  }

  const renderSecurityMarks = () => {
    if(!userInformation.user.marks || !Object.keys(userInformation.user.marks).length) {
      return null;
    }
    const userActiveMarks = Object.keys(userInformation.user.marks).filter((key) => userInformation.user.marks[key]);

    const markTimestampsByMark = {};

    // eslint-disable-next-line no-unused-expressions
    userInformation?.userMarkTimestamps?.forEach((item) => {
      if (!markTimestampsByMark[item.mark]) {
        markTimestampsByMark[item.mark] = item.createdAt;
      }
    });

    if (!userActiveMarks.length) {
      return null;
    }

    return userActiveMarks.map((mark, index) => {
      return (
        <Tooltip
          arrow
          key={mark}
          placement="top"
          title={markTimestampsByMark[mark]
            ? <div className="font-size-lg line-height-md">{newDateFormatLong(markTimestampsByMark[mark])}</div>
            : ''}
        >
           <span className={markInUserSecurityMarks(mark) ? 'text-danger': ''}>
             {(index === userActiveMarks.length - 1) ? mark : `${mark} / `}
           </span>
        </Tooltip>);
    })
  }

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          Security Data
        </h3>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <div className="bg-light-danger rounded px-5 pt-15 pb-3 mb-5">
              <div className={fraudScoreClassName}>
                <FraudScore fraudScore={userInformation.user.fraudScore || ' '} level={userInformation.fraudScoreLevel}/>
              </div>
              <div>Fraud Score</div>
            </div>
            <div className="bg-light-success rounded px-5 pt-15 pb-3 mb-5">
              <VerificationStatusField user={userInformation.user} />
              <div>Verification Status</div>
            </div>
            <div className="d-flex flex-column bg-light-info rounded px-5 py-5 mb-5">
              <div className="d-flex flex-column justify-content-between mb-7">
                <div className="d-flex align-items-start justify-content-between" style={{ height: "40px" }}>
                  <div>Federated Deny</div>
                  <div className="font-weight-boldest">{userInformation?.lucidBlacklisted ? 'TRUE' : ' '}</div>
                </div>
                <div className="d-flex align-items-start justify-content-between" style={{ height: "40px" }}>
                  <div>Federated Temp Block</div>
                  <div className="d-flex flex-column align-items-end">
                    {userInformation?.lucidTempBlocked && (
                      <>
                        <div className="font-weight-boldest" style={{ lineHeight: "1" }}>TRUE</div>
                        <div style={{ lineHeight: "1" }}>{slashesDateFormat(userInformation.lucidTempBlockedAt)}</div>
                      </>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-start justify-content-between" style={{ height: "40px" }}>
                  <div>PS Blacklist</div>
                  <div className="font-weight-boldest">{userInformation?.pureSpectrumBlacklisted ? 'TRUE' : ' '}</div>
                </div>
                <div className="d-flex align-items-start justify-content-between" style={{ height: "40px" }}>
                  <div>MC Blocked</div>
                  <div className="font-weight-boldest">{userInformation?.marketCubeBlocked ? 'TRUE' : ' '}</div>
                </div>
                <div className="d-flex align-items-start justify-content-between" style={{ height: "40px" }}>
                  <div>REX Blocked</div>
                  <div className="font-weight-boldest">{userInformation?.blockedBySSIRex ? 'TRUE' : ' '}</div>
                </div>
                <div className="d-flex align-items-start justify-content-between" style={{ height: "40px" }}>
                  <div>Prodege Blocked</div>
                  <div className="font-weight-boldest">{userInformation?.blockedByProdege ? 'TRUE' : ' '}</div>
                </div>
                <div className="d-flex align-items-start justify-content-between" style={{ height: "40px" }}>
                  <div>Toluna Blocked</div>
                  <div className="font-weight-boldest">{userInformation?.tolunaBlocked ? 'TRUE' : ' '}</div>
                </div>
              </div>
              <div style={{ color: "#000" }}>
                Provider Blocks
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div className="bg-light-warning rounded px-5 py-3 mb-5">
              <div className="mb-12">{renderSecurityMarks()}</div>
              <div>Security Marks</div>
            </div>
            <div className="bg-light-primary rounded px-5 py-3 mb-5">
              <div className="mb-12 text-primary">
                {renderSimilarUsers()}
              </div>
              <div>Similar Users</div>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const UserOverview = ({ userId }) => {
  const userInformation = useSelector(state => state.user.userInformation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getUserOrders(userId));
    dispatch(actions.getUserClicks(userId));
    dispatch(actions.getUserEarnings(userId));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if(!userInformation.user) {
    return null;
  }

  return (
    <Grid container spacing={1} className="flex-row-fluid">
      <Grid item xs={12} md={6} lg={4}>
        <RegistrationDataCard />
        <UserDataCard />
      </Grid>
      <Grid container item xs={12} md={12} lg={8} spacing={1}>
        <Grid item xs={12} md={6}>
          <UserActivity />
        </Grid>
        <Grid item xs={12} md={6}>
          <EarningCard />
        </Grid>
        <Grid item xs={12} md={12}>
          <SecurityDataCard />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UserOverview;


