import React from 'react';
import { useSelector } from 'react-redux';
import {newDateFormatLong} from "~/app/helpers";

const PPChecksRewardsBalancePopup = () => {
  const paypalStats = useSelector(state => state.checksAndRewards.paypalStats);
  const providersSettings = useSelector(state => state.checksAndRewards.providersSettings);
  const {
    paypalWaitingCheck = 0,
    paypalPending = 0,
  } = paypalStats;

  const providersSettingsAlerts = providersSettings?.filter(
    settings => settings.currentBalance < settings.threshold
  ) || [];

  // const formattedServerTime = format(serverTime, 'MMMM dd YYYY, HH:mm');
  // let tooltip = `current server time: ${formattedServerTime}`;

  // if (paypalReportLastUpdated) {
  //   const paypalRefreshedFromNow = formatDistanceToNow(new Date(paypalReportLastUpdated));
  //   // tooltip = `PP report last updated ${paypalRefreshedFromNow}, ` + tooltip;
  // }

  return (
    <div
      style={{ fontSize: '14px', lineHeight: '18px' }}
      className="p-3 rounded flex-column d-flex min-w-200px min-h-50px bg-white text-secondary">
      <div className="d-flex text-lg">PP Waiting Checks:
        <span
          className="ml-2"
          style={paypalWaitingCheck > 500 ? { color: 'red' } : {}}
        >{paypalWaitingCheck}</span>
      </div>
      <div className="d-flex text-lg">Pending Payment:
        <span
          className="ml-2"
          style={paypalPending > 500 ? { color: 'red' } : {}}
        >{paypalPending}</span>
      </div>
      {providersSettingsAlerts.length > 0 && (
        <div className="d-flex flex-column mt-5">
          Rewards Balance Alert:
          {providersSettingsAlerts.map(
          ({ provider, displayName, currency, currentBalance, threshold }) => (
            <div key={provider} className="d-flex">
              {displayName}: {' '}
              <span className="ml-2" style={{ color: 'red' }}>
                    {currency} {currentBalance}
                {' '} ({Math.round(currentBalance / threshold * 100)}%) {' '}
                  </span>
            </div>
          ))}
        </div>
      )}
      <span className="text-lg mt-5">
        Current Server Time: {newDateFormatLong(new Date(), '', true)}
      </span>
    </div>
  );
};

export default PPChecksRewardsBalancePopup;
