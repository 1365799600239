export const actionTypes = {
  RequestReport: 'reports/fetch-report',
  ReportLoadSuccess: 'reports/fetch-report-success',
  ReportLoadError: 'reports/fetch-report-failure',
  RequestReportAsCsv: 'reports/generate-report-as-csv',
  HideError: 'reports/hide-error',
};

export const actions = {
  generateReport: () => ({ type: actionTypes.RequestReport, payload: {}}),
  generateReportSuccess: ({ report, sortBy, sortDirection }) => ({ type: actionTypes.ReportLoadSuccess, payload: { report, sortBy, sortDirection } }),
  generateReportFailure: (errorMessage) => ({ type: actionTypes.ReportLoadError, payload: errorMessage }),

  requestReportAsCsv: () => ({ type: actionTypes.RequestReportAsCsv, payload: {}}),
  hideError: () => ({ type: actionTypes.HideError, payload: {}}),
};



