import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from "~/app/modules/User/actions";
import { CloseOutlined } from "@material-ui/icons";
import {
  Card, CardContent, Grid
} from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import JSONPretty from 'react-json-pretty';
import { Modal } from 'react-bootstrap';
import { newDateFormatLong } from "../../../../helpers";

const NexmoLogModal = ({ description, shown, setShown }) => {
  return (
    <>
      <Modal
        scrollable
        centered
        size="lg"
        show={shown}
        onHide={() => setShown(false)}
      >
        <Modal.Header className="d-flex justify-content-between align-items-start">
          <Modal.Title>
            Nexmo Log
          </Modal.Title>
          <div className="cursor-pointer pb-2 py-2" onClick={() => setShown(false)}><CloseOutlined /></div>
        </Modal.Header>
        <Modal.Body>
          <JSONPretty
            json={description}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
}

function LogsTable({entities}) {
  const [shown, setShown] = useState(false);
  const [selectedLogs, setSelectedLogs] = useState('');

  const handleShowLogButtonClick = (logs) => () => {
    setSelectedLogs(logs);
    setShown(true);
  }

  const renderLogButton = (logs) => {
    return (
      <button
        type="button"
        className="btn btn-light-primary btn-sm"
        onClick={handleShowLogButtonClick(logs)}
      >
        Show Log
      </button>
    )
  }

  const columns = [
    { dataField: "date", text: "Date", formatter: (value) => newDateFormatLong(value)},
    { dataField: "type", text: "Type"  },
    { dataField: "phoneNumber", text: "Phone number" },
    {  dataField: "code", text: "Verification code"  },
    { dataField: "logs", text: "Log", formatter: (logs) => renderLogButton(logs) },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        borderless={true}
        classes="table table-vertical-top overflow-hidden"
        bootstrap4
        size="sm"
        responsive="lg"
        headerClasses="bg-light rounded"
        remote
        keyField="date"
        data={entities}
        columns={columns}
      >
      </BootstrapTable>
      <NexmoLogModal shown={shown} setShown={setShown} description={selectedLogs} />
    </>
  );
}

const StatisticsCard = () => {
  const userNexmoLogs = useSelector(state => state.user.userNexmoLogs);

  const findEntities = () => {
    if(!userNexmoLogs.length) {
      return [];
    }

    return userNexmoLogs.map((log) => ({
        date: log.createdAt,
        type: log.logType,
        phoneNumber: log.phoneNumber,
        code: log?.verificationCode || '',
        logs: log.log
    }))
  }

  return (
    <Card className="h-100">
      <CardContent className="h-100 border text-dark border-light-dark rounded pb-20">
        <h3 className="card-title font-weight-bolder text-dark mb-10">
          Nexmo Logs
        </h3>
        <LogsTable entities={findEntities()} />
      </CardContent>
    </Card>
  )
}


const NexmoLogs = ({ userId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getUserNexmoLogs(userId));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const userInformation = useSelector(state => state.user.userInformation);

  if(!userInformation.user) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <StatisticsCard />
      </Grid>
    </Grid>
  )
};

export default NexmoLogs;
