import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Button, Grid } from '@material-ui/core';
import { actions } from '~/app/modules/Surveys/actions';
import SurveysReport from "./Surveys.report";
import {
  usePaginationFilteringAndSorting,
} from '~/app/helpers';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Spinner } from "react-bootstrap";
import { useHistory, useParams } from 'react-router-dom';
import enums from "~/app/helpers/enums";

const DateSelectors = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  switchName,
  onChangeSwitch,
  switchValue
}) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item md={2}>
        <span className="switch switch-sm switch-icon-success">
          <label>
            <input
              type="checkbox"
              name={switchName}
              checked={switchValue}
              onChange={onChangeSwitch}
            />
            <span />
          </label>
        </span>
      </Grid>
      <Grid item md={5}>
        <KeyboardDatePicker
          autoOk
          id="start-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          disabled={!switchValue}
          margin="none"
          label="Start Date"
          value={startDate}
          onChange={(_, v) => onChangeStartDate(v)}
        />
      </Grid>
      <Grid item md={5}>
        <KeyboardDatePicker
          autoOk
          id="end-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          disabled={!switchValue}
          margin="none"
          label="End Date"
          value={endDate}
          onChange={(_, v) => onChangeEndDate(v)}
        />
      </Grid>
    </Grid>
  )
};

const Surveys = () => {
  const dispatch = useDispatch();
  const createdStartDate = useSelector(state => state.surveys.createdStartDate);
  const createdEndDate = useSelector(state => state.surveys.createdEndDate);
  const updatedStartDate = useSelector(state => state.surveys.updatedStartDate);
  const updatedEndDate = useSelector(state => state.surveys.updatedEndDate);
  const {
    sortBy, sortDirection, pageNumber, pageSize, totalCount, filters,
  } = usePaginationFilteringAndSorting(dispatch, 'surveys', actions);
  const useCreatedDate = useSelector((state) => state.surveys.useCreatedDate);
  const useLastUpdatedDate = useSelector((state) => state.surveys.useLastUpdatedDate);
  const isLoadingCSV = useSelector((state) => state.surveys.isLoadingCSV);
  const isLoadingSharedView = useSelector((state) => state.surveys.isLoadingSharedView);
  const sharedLink = useSelector((state) => state.surveys.sharedLink);
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (params?.groupId) {
      dispatch(actions.setSurveyGroupsIds(params?.groupId));
      history.push('/surveys');
    }
  }, [dispatch, history, params]);

  useEffect(() => {
    if (sharedLink) {
      navigator.clipboard.writeText(sharedLink).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
      dispatch(actions.clearSharedLink());
    }
  }, [sharedLink, dispatch])

  const handleSharedView = () => {
    const sentParams = {
      sortBy,
      sortDirection,
      pageNumber,
      pageSize,
      filters,
      totalCount,
      createdStartDate,
      createdEndDate,
      updatedStartDate,
      updatedEndDate,
      useCreatedDate,
      useLastUpdatedDate,
      page: 'surveys',
    };

    dispatch(actions.createSharedLink(sentParams));
  }

  const handleChangeUseCreatedDate = () => {
    dispatch(actions.setUseCreatedDate(!useCreatedDate));
  }

  const handleChangeUseLastUpdatedDate = () => {
    dispatch(actions.setUseLastUpdatedDate(!useLastUpdatedDate));
  }

  const onChangeDate = (key) => (v) => {
    switch (key) {
      case 'createdStart':
        dispatch(actions.setCreatedStartDate(v));
        break;
      case 'createdEnd':
        dispatch(actions.setCreatedEndDate(v));
        break;
      case 'updatedStart':
        dispatch(actions.setUpdatedStartDate(v));
        break;
      case 'updatedEnd':
        dispatch(actions.setUpdatedEndDate(v));
        break;

      default:
        break;
    }
  }

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="d-flex justify-content-between align-items-start mb-3">
          <h3>Surveys</h3>
          <div className="d-flex align-items-start">
            <Button
              color="default"
              variant="contained"
              onClick={handleSharedView}
              className="bg-info mr-5 text-white"
            >
              {isLoadingSharedView
                ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )
                : 'Share View'}
            </Button>
            <div className="d-flesx flex-column justify-content-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => dispatch(actions.generateReportAsCsv())}
              >
                {isLoadingCSV
                  ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )
                  : 'Export To CSV'}
              </Button>
              <div>Count of Surveys: {totalCount}</div>
            </div>
          </div>
        </div>
        <div className="d-flex mb-10 align-items-end">
          <div className="d-flex flex-column">
            <div>Create Date</div>
            <DateSelectors
              switchName="created-date"
              onChangeSwitch={handleChangeUseCreatedDate}
              switchValue={useCreatedDate}
              startDate={createdStartDate}
              endDate={createdEndDate}
              onChangeStartDate={onChangeDate('createdStart')}
              onChangeEndDate={onChangeDate('createdEnd')}
            />
            <div>Last Update Date</div>
            <DateSelectors
              switchName="last-updated-date"
              switchValue={useLastUpdatedDate}
              onChangeSwitch={handleChangeUseLastUpdatedDate}
              startDate={updatedStartDate}
              endDate={updatedEndDate}
              onChangeStartDate={onChangeDate('updatedStart')}
              onChangeEndDate={onChangeDate('updatedEnd')}
            />
          </div>
          <Button
            color="primary"
            variant="contained"
            className="ml-5 max-h-30px"
            onClick={() => dispatch(actions.generateReport())}
          >
            Generate
          </Button>
        </div>
        <SurveysReport />
      </CardContent>
    </Card>
  )
};

export default Surveys;
