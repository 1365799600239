import _ from 'lodash';
import { actionTypes } from '~/app/modules/Surveys/actions';
import { format } from 'date-fns';
import enums from "~/app/helpers/enums";

const initialState = {
  createdStartDate: format(new Date(), enums.DATE_FORMAT),
  createdEndDate: format(new Date(), enums.DATE_FORMAT),
  updatedStartDate: format(new Date(), enums.DATE_FORMAT),
  updatedEndDate: format(new Date(), enums.DATE_FORMAT),
  pageNumber: 1,
  pageSize: 20,
  sortBy: 'createDate',
  sortDirection: enums.sortDirections.desc,
  filters: {},
  useCreatedDate: true,
  useLastUpdatedDate: false,

  report: [],
  totalCount: 0,
  stats: null,

  isLoading: false,
  isLoadingCSV: false,
  isLoadingSharedView: false,
  sharedLink: '',
  useCountryFieldSort: true,
};

const resetPaginationState = {
  pageNumber: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetPagination:
      return {
        ...state,
        ...resetPaginationState,
        pageSize: action.payload.pageSize,
        pageNumber: action.payload.pageNumber,
      };

    case actionTypes.SetCreatedStartDate:
      return {
        ...state,
       createdStartDate: action.payload,
      };

    case actionTypes.SetCreatedEndDate:
      return {
        ...state,
       createdEndDate: action.payload,
      };

    case actionTypes.SetUpdatedStartDate:
      return {
        ...state,
        updatedStartDate: action.payload,
      };

    case actionTypes.SetUpdatedEndDate:
      return {
        ...state,
        updatedEndDate: action.payload,
      };

    case actionTypes.SetSorting:
      return {
        ...state,
        ...resetPaginationState,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      };

    case actionTypes.SetFilter:
      const { field, value } = action.payload;
      const curFilters = state.filters;
      if (value) {
        return {
          ...state,
          ...resetPaginationState,
          filters: {
            ...curFilters,
            [field]: value,
          },
        };
      } else {
        return {
          ...state,
          ...resetPaginationState,
          filters: _.omit(curFilters, field),
        };
      }

    case actionTypes.GenerateReport:
      return {
        ...state,
        pageNumber: 1,
        pageSize: 20,
        report: [],
        totalCount: 0,
        isLoading: true,
      };

    case actionTypes.UpdateReport:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FetchReportSuccess:
      return {
        ...state,
        report: action.payload.data,
        totalCount: action.payload.totalCount,
        stats: action.payload.stats,
        isLoading: false,
      };

    case actionTypes.FetchReportError:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.SetUseCreatedDate:
      return {
        ...state,
        useCreatedDate: action.payload,
      };

    case actionTypes.SetUseLastUpdatedDate:
      return {
        ...state,
        useLastUpdatedDate: action.payload,
      };

    case actionTypes.GenerateReportAsCsv:
      return {
        ...state,
        isLoadingCSV: true
      }

    case actionTypes.GenerateReportAsCsvSuccess:
      return {
        ...state,
        isLoadingCSV: false
      }

    case actionTypes.CreateSharedLink:
      return {
        ...state,
        isLoadingSharedView: true,
      }

    case actionTypes.CreateSharedLinkFailed:
      return {
        ...state,
        isLoadingSharedView: false,
      }

    case actionTypes.CreateSharedLinkSuccess:
      return {
        ...state,
        isLoadingSharedView: false,
        sharedLink: action.payload.link,
      }

    case actionTypes.SetParams:
      return {
        ...state,
        ...action.payload,
      }

    case actionTypes.ClearSharedLink:
      return {
        ...state,
        sharedLink: '',
      }

    case actionTypes.SetSurveyGroupsIds:
      return {
        ...state,
        filters: {
          ...state.filters,
          surveyGroupsIds: action.payload,
        },
        useCreatedDate: false,
      }

    case actionTypes.SetUseCountryFieldSort:
      return {
        ...state,
        useCountryFieldSort: action.payload,
      };

    default:
      return state;
  }
};
