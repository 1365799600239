import { select, call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/TolunaNotificationsLog/api';
import { actions, actionTypes } from '~/app/modules/TolunaNotificationsLog/actions';

const addFilterDates = (data, state) => {
  const dataWithFilterDates = { ...data };
  if (state.tolunaNotificationsLog.useCreatedDate) {
    dataWithFilterDates.filterDate = {
      createdStart: state.tolunaNotificationsLog.createdStartDate,
      createdEnd: state.tolunaNotificationsLog.createdEndDate,
    }
  }
  return dataWithFilterDates;
}

const selectForFetchReport = (state) => {
  const data = {
    filters: state.tolunaNotificationsLog.filters,
    pageNumber: state.tolunaNotificationsLog.pageNumber,
    pageSize: state.tolunaNotificationsLog.pageSize,
    sortBy: state.tolunaNotificationsLog.sortBy,
    sortDirection: state.tolunaNotificationsLog.sortDirection,
  };

  return addFilterDates(data, state);
};

function* requestReport() {
  try {
    const reportParams = yield select(selectForFetchReport);
    const { results } = yield call(api.fetchReport, reportParams);
    yield put(actions.fetchReportSuccess(results));
  } catch (error) {
    yield put(actions.fetchReportFailure(error.message));
  }
}

export default [
  takeLatest([
    actionTypes.GenerateReport,
    actionTypes.UpdateReport,
  ], requestReport),
];


