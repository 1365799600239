import { actionTypes } from '~/app/modules/Rewards/components/BitcoinRecords/actions';

const initialState = {
  report: [],
  balance: {},

  dataIsLoading: false,
  isLoading: false,
  bitcoinBalanceIsLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CreateBitcoinRecordSuccess:
    case actionTypes.CreateBitcoinRecordFailure:
    case actionTypes.RemoveBitcoinRecordFailure:
    case actionTypes.RemoveBitcoinRecordSuccess:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.CreateBitcoinRecord:
    case actionTypes.RemoveBitcoinRecord:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GetBitcoinRecords:
      return {
        ...state,
        dataIsLoading: true,
      };

    case actionTypes.GetBitcoinRecordsFailure:
      return {
        ...state,
        dataIsLoading: false,
      };

    case actionTypes.GetBitcoinRecordsSuccess:
      return {
        ...state,
        dataIsLoading: false,
        report: action.payload?.sort((a,b) => (new Date(b?.setAt) - new Date(a?.setAt))),
      };

    case actionTypes.GetBitcoinBalance:
      return {
        ...state,
        bitcoinBalanceIsLoading: true,
      };

    case actionTypes.GetBitcoinBalanceSuccess:
      return {
        ...state,
        bitcoinBalanceIsLoading: false,
        balance: action.payload,
      };

    case actionTypes.GetBitcoinBalanceFailure:
      return {
        ...state,
        bitcoinBalanceIsLoading: false,
      };

    default:
      return state;
  }
};
