import React, { useEffect }  from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  Container, Card, CardContent, Grid, Select, FormControl,
  InputLabel, MenuItem, Button, TextField,
} from '@material-ui/core';
import { Spinner } from "react-bootstrap";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { actions } from '~/app/modules/Miscellaneous/ExportUserData/actions';
import enums from "~/app/helpers/enums";

const DateSelectors = () => {
  const startDate = useSelector(state => state.miscellaneousExportUserData.startDate);
  const endDate = useSelector(state => state.miscellaneousExportUserData.endDate);
  const dispatch = useDispatch();

  return (
    <>
      <Grid item xs={2}>
        <KeyboardDatePicker
          autoOk
          id="start-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="none"
          label="Start Date"
          value={startDate}
          onChange={(_, v) => dispatch(actions.setStartDate(v))}
        />
      </Grid>
      <Grid item xs={2}>
        <KeyboardDatePicker
          autoOk
          id="end-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="none"
          label="End Date"
          value={endDate}
          onChange={(_, v) => dispatch(actions.setEndDate(v))}
        />
      </Grid>
    </>
  )
};

const userTypes = ['Registrations','Leads', 'Orders']

const Filters = () => {
  const countryCodes = useSelector(state => state.miscellaneousExportUserData.countryCodes);
  const countryCode = useSelector(state => state.miscellaneousExportUserData.countryCode);
  const userType = useSelector(state => state.miscellaneousExportUserData.userType);
  const registrationPublisherId = useSelector(state => state.miscellaneousExportUserData.registrationPublisherId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getCountryCodes());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const countryCodeItems = countryCodes.map((country) => (
    <MenuItem key={country.name} value={country.code}>
      {country.name} ({country.code})
    </MenuItem>
  ));

  const userTypesItems = userTypes.map((userType) => (
    <MenuItem key={userType} value={userType}>
      {userType}
    </MenuItem>
  ));

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={3}>
          <FormControl margin="normal">
            <InputLabel id="country-codes-label">Country</InputLabel>
            <Select
              id="country-codes"
              labelId="country-codes-label"
              value={countryCode}
              onChange={e => dispatch(actions.setCountryCode(e.target.value))}
            >
              {countryCodeItems}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl margin="normal">
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              id="type"
              labelId="type-label"
              value={userType}
              onChange={e => dispatch(actions.setUserType(e.target.value))}
            >
              {userTypesItems}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl margin="normal">
            <TextField
              id="publisher"
              label="Publisher"
              value={registrationPublisherId}
              onChange={e => dispatch(actions.setRegistrationPublisherId(e.target.value))}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
};

const ReportParamsForm = () => {
  const dispatch = useDispatch();
  const showUserIdsForm = useSelector(state => state.miscellaneousExportUserData.showUserIdsForm);
  const countryCode = useSelector(state => state.miscellaneousExportUserData.countryCode);
  const reportAsCsvCreating = useSelector(state => state.miscellaneousExportUserData.reportAsCsvCreating);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4} alignItems="flex-end">
          <DateSelectors />
          <Grid item style={{ flexGrow: 1 }} />
          <Grid item>
            <Button
              color="default"
              variant="contained"
              className="bg-info mr-5 text-white"
              onClick={() => dispatch(actions.setShowUserIdsForm(!showUserIdsForm))}
            >
              Enter User IDs
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              disabled={!countryCode}
              onClick={() => {!reportAsCsvCreating && dispatch(actions.exportToCsv())}}
            >
              {reportAsCsvCreating
                ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )
                : 'Export To CSV'}
            </Button>
          </Grid>
        </Grid>
        <Filters />
      </CardContent>
    </Card>
  );
};

const ShowUserIdsForm = () => {
  const dispatch = useDispatch();
  const showUserIdsForm = useSelector(state => state.miscellaneousExportUserData.showUserIdsForm);
  const reportAsCsvByIdsCreating = useSelector(state => state.miscellaneousExportUserData.reportAsCsvCreating);
  const userIds = useSelector(state => state.miscellaneousExportUserData.userIds);

  if (!showUserIdsForm) {
    return <span />;
  }

  return (
    <Card className="mt-3">
      <CardContent>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item>Export by User IDs</Grid>
          <Grid item style={{ flexGrow: 1 }} />
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              disabled={!userIds?.length}
              onClick={() => {!reportAsCsvByIdsCreating && dispatch(actions.exportToCsvByIds())}}
            >
              {reportAsCsvByIdsCreating
                ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )
                : 'Export To CSV'}
            </Button>
          </Grid>
        </Grid>
        <Grid className="mt-3">
          <Grid item>
            <TextField
              variant="outlined"
              multiline
              fullWidth
              rows={10}
              placeholder=""
              value={userIds}
              onChange={e => dispatch(actions.setUserIds(e.target.value))}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const ExportUserData = () => {
  return (
    <Container>
      <ReportParamsForm />
      <ShowUserIdsForm />
    </Container>
  );
};

export default ExportUserData;
