import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Modal } from "react-bootstrap";
import { CloseOutlined } from "@material-ui/icons";

const PopupWithColorPicker = ({ color = '#123123', onChange, shown, setShown, title }) => {
  const [currentColor, setCurrentColor] = useState(color);

  const handleChangeColor = (color) => {
    console.log('color', color);
    setCurrentColor(color.hex);
  }

  const handleConfirm = () => {
    setShown(false);
    console.log('currentColor', currentColor);
    onChange(currentColor);
  }

  return (
    <Modal
      scrollable
      centered
      size="md"
      show={shown}
      onHide={() => setShown(false)}
    >
      <Modal.Header className="d-flex justify-content-between align-items-start">
        <Modal.Title>
          {title}
        </Modal.Title>
        <div className="cursor-pointer pb-2 py-2" onClick={() => setShown(false)}><CloseOutlined /></div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <SketchPicker width="260px" color={currentColor} onChange={handleChangeColor} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={() => setShown(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm ml-5"
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupWithColorPicker;
