import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('api-log');

actionTypes.GenerateReportAsCsv = 'api-log/generate-report-as-csv';
actionTypes.GenerateReportAsCsvSuccess = 'api-log/generate-report-as-csv-success';
actionTypes.CreateSharedLink = 'api-log/create-shared-link';
actionTypes.CreateSharedLinkFailed = 'api-log/create-shared-link-failed';
actionTypes.CreateSharedLinkSuccess = 'api-log/create-shared-link-success';
actionTypes.SetParams = 'api-log/set-params';
actionTypes.ClearSharedLink = 'api-log/clear-shared-link';
actionTypes.SetCollapsedColumns = 'api-log/set-collapsed-columns';

actions.createSharedLink = (params) => ({type: actionTypes.CreateSharedLink, payload: params});
actions.createSharedLinkSuccess = (link) => ({type: actionTypes.CreateSharedLinkSuccess, payload: link});
actions.createSharedLinkFailed = (error) => ({type: actionTypes.CreateSharedLinkFailed, payload: error});
actions.setParams = (params) => ({type: actionTypes.SetParams, payload: params});
actions.clearSharedLink = () => ({type: actionTypes.ClearSharedLink});
actions.generateReportAsCsv = () => ({
  type: actionTypes.GenerateReportAsCsv,
  payload: {}
});
actions.generateReportAsCsvSuccess = () => ({
  type: actionTypes.GenerateReportAsCsvSuccess,
  payload: {}
});
actions.setCollapsedColumns = (collapsed) => ({
  type: actionTypes.SetCollapsedColumns,
  payload: collapsed
});


export {
  actionTypes,
  actions,
};
