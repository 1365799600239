import { actionTypes } from '~/app/modules/Report/actions';

const initialState = {
  report: null,
  sortBy: null,
  sortDirection: null,
  isLoading: false,
  error: null,
  showError: false,
};

export default (state = initialState, action) => {
  switch (action.type) { case actionTypes.ReportLoadSuccess:
      return {
        ...state,
        report: action.payload.report,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
        error: null,
        isLoading: false,
      };

    case actionTypes.RequestReport:
      return {
        ...state,
        ...initialState,
        isLoading: true,
        error: null,
      };

    case actionTypes.HideError:
      return {
        ...state,
        error: null,
        showError: false,
      }

    case actionTypes.ReportLoadError:
    case actionTypes.RequestLoadError:
      return {
        ...state,
        error: action.payload,
        showError: true,
        isLoading: false,
      };

    default:
      return state;
  }
};
