import { format } from 'date-fns';
import { actionTypes } from '~/app/modules/Miscellaneous/ExportUserData/actions';
import enums from "~/app/helpers/enums";

const initialState = {
  startDate: format(new Date(), enums.DATE_FORMAT),
  endDate: format(new Date(), enums.DATE_FORMAT),
  countryCode: '',
  countryCodes: [],
  userType: 'Registrations',
  registrationPublisherId: '',
  reportAsCsvCreating: false,

  showUserIdsForm: false,
  userIds: '',
  reportAsCsvByIdsCreating: false,

  isLoading: false,
};

const resetPaginationState = {
  pageNumber: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetStartDate:
      return {
        ...state,
        ...resetPaginationState,
        startDate: action.payload,
      };

    case actionTypes.SetEndDate:
      return {
        ...state,
        ...resetPaginationState,
        endDate: action.payload,
      };

    case actionTypes.SetCountryCode:
      return {
        ...state,
        countryCode: action.payload,
      };

    case actionTypes.GetCountryCodesSuccess:
      return {
        ...state,
        countryCodes: action.payload,
      };

    case actionTypes.SetUserType:
      return {
        ...state,
        userType: action.payload,
      };

    case actionTypes.SetRegistrationPublisherId:
      return {
        ...state,
        registrationPublisherId: action.payload ,
      };

    case actionTypes.ExportToCsv:
      return {
        ...state,
        reportAsCsvCreating: true,
      };

    case actionTypes.ExportToCsvSuccess:
      return {
        ...state,
        reportAsCsvCreating: false,
      };

    case actionTypes.SetShowUserIdsForm:
      return {
        ...state,
        showUserIdsForm: action.payload,
      };

    case actionTypes.ExportToCsvByIds:
      return {
        ...state,
        reportAsCsvByIdsCreating: true,
      };

    case actionTypes.ExportToCsvByIdsSuccess:
      return {
        ...state,
        reportAsCsvByIdsCreating: false,
      };

    case actionTypes.SetUserIds:
      return {
        ...state,
        userIds: action.payload,
      };

    default:
      return state;
  }
};
