import { select, call, put, takeLatest } from "redux-saga/effects";
import api from '~/app/modules/VeriffLog/api';
import { actions, actionTypes } from '~/app/modules/VeriffLog/actions';

const selectForFetchReport = (state) => ({
  filterDate: {
    start: state.veriffLog.startDate,
    end: state.veriffLog.endDate,
  },
  filters: state.veriffLog.filters,
  pageNumber: state.veriffLog.pageNumber,
  pageSize: state.veriffLog.pageSize,
  sortBy: state.veriffLog.sortBy,
  sortDirection: state.veriffLog.sortDirection,
});

function* requestReport() {
  try {
    const reportParams = yield select(selectForFetchReport);
    const { results } = yield call(api.fetchReport, reportParams);
    yield put(actions.fetchReportSuccess(results));
  } catch (error) {
    yield put(actions.fetchReportFailure(error.message));
  }
}

const selectForFetchReportAsCsv = (state) => ({
  filterDate: {
    start: state.veriffLog.startDate,
    end: state.veriffLog.endDate,
  },
  filters: state.veriffLog.filters,
});

function* requestReportAsCsv() {
  try {
    const reportParams = yield select(selectForFetchReportAsCsv);
    yield call(api.fetchReportAsCsv, reportParams);
  } catch (error) {
    console.error(error);
  }
}

export default [
  takeLatest([
    actionTypes.ShowUser,
    actionTypes.GenerateReport,
    actionTypes.UpdateReport,
  ], requestReport),
  takeLatest(actionTypes.GenerateReportAsCsv, requestReportAsCsv),
];

