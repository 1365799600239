import { actionsForPaginatedSortedFilteredFetchableReport } from '~/app/helpers';

const [actionTypes, actions] = actionsForPaginatedSortedFilteredFetchableReport('tolunaNotificationsLog');
actionTypes.SetCreatedStartDate = 'tolunaNotificationsLog/set-created-start-date';
actionTypes.SetCreatedEndDate = 'tolunaNotificationsLog/set-created-end-date';
actionTypes.SetUseCreatedDate = 'tolunaNotificationsLog/set-use-created-date';

actions.setCreatedStartDate = (date) => ({
  type: actionTypes.SetCreatedStartDate,
  payload: date
});

actions.setCreatedEndDate = (date) => ({
  type: actionTypes.SetCreatedEndDate,
  payload: date
});

actions.setUseCreatedDate = (value) => ({
  type: actionTypes.SetUseCreatedDate,
  payload: value
});

export {
  actionTypes,
  actions,
};

