import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import {
  Card, CardContent
} from "@material-ui/core";
import enums from "~/app/helpers/enums";

const findReasonTextByKey = (provider, w, dataPointIndex) => {
  if (enums.surveysProviders.FEDERATED === provider) {
    return enums.federatedOrLucidDQReasonsByNumber[w.globals.labels[dataPointIndex]] || w.globals.labels[dataPointIndex];
  } else if (enums.surveysProviders.INNOVATE === provider) {
    return enums.innovateDQReasonsByText[w.globals.labels[dataPointIndex]] || w.globals.labels[dataPointIndex];
  } else if ([enums.surveysProviders.MARKET_CUBE, enums.surveysProviders.MARKET_CUBE_2].includes(provider)) {
    return enums.MCDQReasonsByNumber[w.globals.labels[dataPointIndex]] || w.globals.labels[dataPointIndex];
  } else if (enums.surveysProviders.PURE_SPECTRUM === provider) {
    return enums.pureSpectrumDQReasonsByNumber[w.globals.labels[dataPointIndex]] || w.globals.labels[dataPointIndex];
  } else if (enums.surveysProviders.SSI === provider) {
    return enums.SSIDQReasonsByValue[w.globals.labels[dataPointIndex]] || w.globals.labels[dataPointIndex];
  } else if (enums.surveysProviders.SSI2 === provider) {
    return enums.SSI2DQReasonsByValue[w.globals.labels[dataPointIndex]] || w.globals.labels[dataPointIndex];
  }
}

function getChartOption(data, categories, customTooltipTitle,provider, toolbarShow = false) {
  const options = {
    series: [{
      name: '',
      data,
    }],
    chart: {
      height: 300,
      type: 'bar',
      toolbar: {
        show: toolbarShow,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: '12px'
        },
      },
    },
    tooltip: {
      y: {
        formatter: function(val, { dataPointIndex, w }) {
          if (customTooltipTitle && provider) {
            return ('<div>' +
              '<div>Count: ' + val + '</div>' +
              '<div>Description: ' + findReasonTextByKey(provider, w, dataPointIndex) + '</div>' +
            '</div>');
          }
          return `Count: ${val}`;
        },
      },
    },
  };
  return options;
}

const ColumnChart = ({ className, data, title, categories, id, customTooltipTitle, provider, toolbarShow }) => {
  useEffect(() => {
    const element = document.getElementById(id);

    if (!element) {
      return;
    }

    const options = getChartOption(data, categories, customTooltipTitle, provider, toolbarShow);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [data, categories, id, customTooltipTitle, provider, toolbarShow]);

  return (
    <Card className={className}>
      <CardContent>
        <div className="text-primary">{title}</div>
        <div className="my-3 border border-bottom-light-secondary h-1px w-100" />
        <div
          id={id}
          className="card-rounded-bottom"
          style={{ height: "310px", paddingTop: '10px' }}
        />
      </CardContent>
    </Card>
  );
}

export default ColumnChart;
