import React from "react"
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import IPsReport from "./securityDataTabs/IPsReport";
import Overview from "./securityDataTabs/Overview";
import NexmoLogs from "./securityDataTabs/NexmoLogs";
import Identity from "./securityDataTabs/Identity";
import {
  Card, CardContent, Grid
} from "@material-ui/core";
import enums from "~/app/helpers/enums";

export const securityDataTabs = [
  {
    view: enums.userTabs.overview,
    name: 'Overview',
    renderComponent: (userId) => <Overview userId={userId} />,
  },
  {
    view: enums.userTabs.nexmoLogs,
    name: 'Nexmo Logs',
    renderComponent: (userId) => <NexmoLogs userId={userId} />,
  },
  {
    view: enums.userTabs.ipsReport,
    name: 'IPs Report',
    renderComponent: (userId) => <IPsReport userId={userId} />,
  },
  {
    view: enums.userTabs.veriffInformation,
    name: 'Identity Information',
    renderComponent: () => <Identity />
  },
];

const SecurityDataNavigation = ({ userId }) => {
  return (
    <>
      <div className="flex-row-fluid">
        <Switch>
          <Redirect
            from={`/users/${userId}/${enums.userTabs.securityData}`}
            exact={true}
            to={`/users/${userId}/${enums.userTabs.securityData}/${enums.userTabs.overview}`}
          />
          {securityDataTabs.map((tab) => (
            <Route
              key={tab.view}
              path={`/users/${userId}/${enums.userTabs.securityData}/${tab.view}`}
              render={() => tab.renderComponent(userId)}
            />
          ))}
        </Switch>
      </div>
    </>
  )
}

const SecurityDataMenu = ({ userId }) => {
  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded d-flex align-items-center py-3">
        <div className="card-title font-weight-bolder text-dark mb-0 font-size-h3 mr-10">
          Security Data
        </div>
        <div className="d-flex navi navi-bold navi-hover navi-active navi-link-rounded">
          {securityDataTabs.map((tab) => {
            return (
              <div key={tab.view} className="navi-item mr-5">
                <NavLink
                  to={`/users/${userId}/${enums.userTabs.securityData}/${tab.view}`}
                  className="navi-link py-4"
                  activeClassName="active border border-secondary"
                >
                <span className="navi-text font-size-lg">
                  {tab.name}
                </span>
                </NavLink>
              </div>
            )
          })}
        </div>
      </CardContent>
    </Card>
  );
}

const SecurityData = ({ userId }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SecurityDataMenu userId={userId} />
      </Grid>
      <Grid item xs={12}>
        <SecurityDataNavigation userId={userId} />
      </Grid>
    </Grid>
  )
};

export default SecurityData;
