import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "~/app/modules/User/actions";
import {
  Card, CardContent
} from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import {JsonCell, newDateFormatLong} from "~/app/helpers";

const PayPalLogsTable = ({ entities }) => {

  const columns = [
    {
      dataField: "createdAt",
      text: "Created Date",
      formatter: (value) => newDateFormatLong(value),
    },
    { dataField: "orderId", text: "Order ID" },
    {
      dataField: "transactionId",
      text: "PayPal Transaction ID",
    },
    {
      dataField: "detail",
      text: "Amount",
      formatter: (detail) => (detail?.resource?.payout_item?.amount?.value
        ? `$${detail?.resource?.payout_item?.amount?.value}` : ''
      ),
    },
    {
      dataField: "detail.resource.transaction_status",
      text: "Status",
    },
    {
      dataField: "type",
      text: "Event Type",
    },
    {  dataField: "action", text: "Log", formatter: (action, row) => (
      <JsonCell size="xl" button data={JSON.stringify(row)} title="PayPal Log" subTitle={`Transaction ID: ${row?.transactionId}`} />
      ),
    }
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive max-h-400px mb-7"
      bordered={true}
      classes="table table-vertical-top overflow-hidden"
      bootstrap4
      size="sm"
      responsive="lg"
      keyField="_id"
      data={entities}
      columns={columns}
    >
    </BootstrapTable>
  );
}

const UserPayPalLogs = () => {
  const paypalLogs = useSelector(state => state.user.paypalLogs);

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          User's PayPal Transaction logs
        </h3>
        <PayPalLogsTable entities={paypalLogs} />
      </CardContent>
    </Card>
  )
}

const PayPalLogs = ({ userId }) => {
  const userInformation = useSelector(state => state.user.userInformation);
  const dispatch = useDispatch();
  const paypalLogs = useSelector(state => state.user.paypalLogs);

  useEffect(() => {
    dispatch(actions.getUserPaypalLogs(userId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if(!userInformation.user || !paypalLogs.length) {
    return null;
  }

  return (
    <UserPayPalLogs userId={userId} />
  )
};

export default PayPalLogs;
